import { Component, OnInit } from '@angular/core';
import {PolicyToDoRes, UnderwritingToDoRes} from '../to-do-types';
import { PendingReply, PendingDetail, Instance } from '../../api/types';
import {ToDoService} from '../to-do.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {UnderwritingService} from '../../underwriting/underwriting.service';
import {EventRecordComponent} from '../components/event-record/event-record.component';
import {ContractDetails} from '../../contract/contract-type';
import {PolicyService} from '../../policy/policy.service';
import { WorkbenchService } from '../../workbench/workbench.service';

@Component({
  selector: 'app-policy-to-do',
  templateUrl: './policy-to-do.component.html',
  styleUrls: ['./policy-to-do.component.less']
})
export class PolicyToDoComponent implements OnInit {
  spinning = false;
  loading = false;
  toDoList: PolicyToDoRes[] = [];

  pendingDetail: PendingDetail = new PendingDetail();
  pendingReply: PendingReply[] = [];
  instance: Instance = new Instance();
  constructor(private toDoService: ToDoService,
              private drawerService: NzDrawerService,
              private workbenchService: WorkbenchService,
              private policyService: PolicyService) { }

  ngOnInit(): void {
    this.onReload();
    this.instance = this.workbenchService.mockInstance;
  }

  onReload(): void {
    this.loading = true;
    this.toDoService.policyPending()
      .subscribe(data => {
          this.toDoList = data;
          this.loading = false;
        }, error => {
          this.loading = false;
        }
      );
  }

  onDetail(list): void {
    this.onPendingDetail(list.id).then( res => {
      this.policyService.serviceReply(list.id)
        .subscribe(
          pendReply => {
            this.pendingReply = pendReply;
            if (this.pendingReply && this.pendingReply.length) {
              this.pendingReply.map(item => item.content = item.content ? item.content : '');
            }
            const drawerRef = this.drawerService.create<EventRecordComponent, { value: ContractDetails }, string>({
              nzWidth: 800,
              nzContent: EventRecordComponent,
              nzContentParams: {
                pendingDetail: res,
                policyInfo: list,
                pendingReply: this.pendingReply,
                type: 'policy'
              }
            });
            drawerRef.afterOpen.subscribe(() => {
              const component = drawerRef.getContentComponent();
              // component.underwritingSearch
              //   .subscribe(
              //     search => {
              //       this.search = search;
              //       this.onSearch(this.search.pageNum);
              //       drawerRef.close();
              //     }
              //   );
            });
            drawerRef.afterClose.subscribe(data => {
              if (typeof data === 'string') {
              }
            });
          });
    }).catch(err => {
      console.log(err);
    });
  }

  onPendingDetail(id: number): Promise<PendingDetail> {
    return new Promise((resolve, reject) => {
      this.policyService.serviceDetail(id)
        .subscribe(
          pendDetail => {
            resolve(pendDetail);
          },
          error => {
            reject(error);
          });
    });
  }
}
