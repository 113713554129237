import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PagedResp } from '../../api/types';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { ProductService } from '../../product/product.service';
import { debounceTime } from 'rxjs/operators';
import { ProductSelectionType } from '../../shared/component/product-selection/product-selection.component';
import { ProposalService } from '../proposal.service';
import { Product, ProductCategory, ProductCompany } from '../../product/product-types';
import { Proposal, ProposalListResp, ProposalSearch } from '../proposal-types';
import { ProposalApplyComponent } from '../proposal-apply/proposal-apply.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NewFnaDetail } from '../../new-fna/new-fna-types';
import { RecommendProduct } from '../../fna/fna-types';

@Component({
  selector: 'app-proposal-selection',
  templateUrl: './proposal-selection.component.html',
  styleUrls: ['./proposal-selection.component.less']
})
export class ProposalSelectionComponent implements OnInit {

  @Input()
  type = ProductSelectionType.DEFAULT;
  @Input() isNewFna = false; // 新版fna关联计划书
  @Input()
  newFna: NewFnaDetail = new NewFnaDetail();
  productsForProposalVisible = false;

  @Output()
  proposalSelected: EventEmitter<Proposal | Proposal[]> = new EventEmitter<Proposal | Proposal[]>();
  @Output()
  proposalApplyFna: EventEmitter<any> = new EventEmitter<any>();

  searchParamChange: EventEmitter<any> = new EventEmitter<any>();

  search = new ProposalSearch();

  enableOptions = true;
  spinning = false;
  loading = false;

  proposals: PagedResp<ProposalListResp>;
  categories: ProductCategory[];
  companies: ProductCompany[];

  categoryOptions: PropertySelectOption[] = [];
  companyOptions: PropertySelectOption[] = [];

  constructor(private productService: ProductService,
              private proposalService: ProposalService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
    this.productService.categories()
      .subscribe(
        data => {
          this.categories = data;
          data.forEach(category => {
            this.categoryOptions?.push(new PropertySelectOption(category.name, category.code));
          });
        },
        error => {
        });

    this.productService.companies()
      .subscribe(
        data => {
          this.companies = data;
          data.forEach(company => {
            this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
          });

        },
        error => {
        });

    this.searchParamChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          this.onSearch(1);
        }
      );

  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.proposalService.list(this.search, false)
      .subscribe(
      data => {
        this.loading = false;
        this.proposals = data;
      },
      error => {
        this.loading = false;
      });

  }

  onSearchParamChange(): void {
    this.searchParamChange.emit('');
  }

  onReload(): void {
    this.onSearch(1);
  }

  onProposalSelected(proposalId: string, i: number): void {
    this.loading = true;
    this.proposalService.info(proposalId)
      .subscribe(
        data => {
          this.loading = false;
          this.proposalSelected?.emit(data);
        },
        error => {
          this.loading = false;
        });
  }

  onProposalApply(): void {
    if (this.newFna?.products?.length > 1) {
      this.productsForProposalVisible = !this.productsForProposalVisible;
      return;
    } else if (this.newFna?.products?.length === 1) {
      this.proposalApplyWithProduct(this.newFna?.products[0]);
    } else {
      this.proposalApply();
    }

  }

  proposalApplyWithProduct(recommendProduct: RecommendProduct): void {
    this.productsForProposalVisible = false;
    this.loading = true;
    this.productService.detail(recommendProduct.productCode)
      .subscribe(
        product => {
          this.loading = false;
          this.proposalApply(product);
        },
        error => {
          this.loading = false;
        });
  }

  proposalApply(product: Product = null): void {
    const drawerRef = this.drawerService.create<ProposalApplyComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: ProposalApplyComponent,
      nzContentParams: {}
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.applyReq.newFnaId = this.newFna.id; // 关联FNA和计划书
      if (product != null) {
        component.productSelected(product);
      }
      component.proposalApplied
        .subscribe(data => {
          this.proposalApplyFna?.emit(data);
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

}
