import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Currency, Rider } from '../../../api/types';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ProductRiderEditComponent } from '../product-rider-edit/product-rider-edit.component';
import { ProductService } from '../../../product/product.service';
import { Product } from '../../../product/product-types';
import { RecommendProduct } from '../../../fna/fna-types';

export enum RiderMode {
  DEFAULT = 'DEFAULT',
  FNA = 'FNA'
}

@Component({
  selector: 'app-product-riders-edit',
  templateUrl: './product-riders-edit.component.html',
  styleUrls: ['./product-riders-edit.component.less']
})
export class ProductRidersEditComponent implements OnInit {

  loading = false;

  @Input()
  mode = RiderMode.DEFAULT;

  @Input()
  primaryProduct: Product = new Product(); // 主險

  @Input()
  currency: Currency; // 貨幣

  @Input()
  riders: Rider[];
  @Input()
  isNewFna = false;
  @Input()
  product: RecommendProduct;

  @Output()
  ridersChange: EventEmitter<Rider[]> = new EventEmitter<Rider[]>();

  constructor(private drawerService: NzDrawerService, private productService: ProductService) {
  }

  ngOnInit(): void {
  }

  onAdd(): void {

    const drawerRef = this.drawerService.create<ProductRiderEditComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: ProductRiderEditComponent,
      nzContentParams: {
        mode: this.mode,
        primaryProduct: this.primaryProduct,
        currency: this.currency,
        isNewFna: this.isNewFna,
        recommendProduct: this.product
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.riderSaved.subscribe(
        data => {
          if (!this.riders) {
            this.riders = [];
          }
          this.riders.push(data.rider);
          this.ridersChange.emit(this.riders);
        }
      );
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onDelete(i: number): void {
    this.riders.splice(i, 1);
    this.ridersChange.emit(this.riders);
  }

  onEdit(i: number): void {

    const rider = this.riders[i];

    this.loading = true;
    this.productService.detail(rider.productCode)
      .subscribe(
        product => {
          this.loading = false;

          const drawerRef = this.drawerService.create<ProductRiderEditComponent, { value: any }, string>({
            nzWidth: 600,
            nzMaskClosable: true,
            nzContent: ProductRiderEditComponent,
            nzContentParams: {
              mode: this.mode,
              primaryProduct: this.primaryProduct,
              currency: this.currency,
              rider: this.riders[i],
              isNewFna: this.isNewFna,
              recommendProduct: this.product
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
            component.productSelected(product);
            component.riderSaved.subscribe(
              data => {
                if (!this.riders) {
                  this.riders = [];
                }
                if (data.index < this.riders.length) {
                  this.riders[data.index] = data.rider;
                }
                this.ridersChange.emit(this.riders);
              }
            );
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        });
  }
}
