import {Component, OnInit} from '@angular/core';
import {ContractDetails, ContractList, ContractSearch} from '../contract-type';
import {PagedResp} from '../../api/types';
import {ContractService} from '../contract.service';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {MetadataService} from '../../shared/service/metadata.service';
import {formatDate} from '@angular/common';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {UnderwritingSearchComponent} from '../../underwriting/underwriting-search/underwriting-search.component';
import {UnderwritingSearch} from '../../underwriting/underwriting-types';
import {ContractDetailComponent} from '../contract-detail/contract-detail.component';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.less']
})
export class ContractListComponent implements OnInit {
  spinning = false;
  search: ContractSearch = new ContractSearch();
  contractList: ContractList[];
  filterPopoverVisible = false;
  typeOptions: PropertySelectOption[] = [];
  EffectDates: number[];
  deadline: number[];

  constructor(private contractService: ContractService,
              private metadataService: MetadataService,
              private nz: NzDrawerService) {
  }

  ngOnInit(): void {
    this.typeOptions = [];
    const contractType = this.metadataService.values('contractType');

    for (const contract of contractType) {
      this.typeOptions.push(new PropertySelectOption(contract.value, contract.key));
    }
    this.spinning = true;
    this.contractService.list(this.search).subscribe(
      data => {
        console.log(data);
        this.contractList = data;
        this.spinning = false;
      },
      error => {
        this.spinning = false;
      }
    );

  }

  onSearch($event: number): void {

    this.openSearch();
  }

  onReload(): void {
    this.search = new ContractSearch();
    this.EffectDates = undefined;
    this.deadline = undefined;
    this.spinning = true;
    this.contractService.list(this.search).subscribe(
      data => {
        this.contractList = data;
        this.spinning = false;
      },
      error => {
        this.spinning = false;
      }
    );
  }

  openSearch(): void {
    this.spinning = true;
    this.contractService.list(this.search).subscribe(
      data => {
        this.contractList = data;
        this.spinning = false;
      },
      error => {
        this.spinning = false;
      }
    );
  }

  onSearchDateChange(time: number[]): void {
    console.log(time);
    if (time) {
      this.deadline = time;
      this.search.effectiveStartDate = formatDate(time[0], 'yyyy-MM-dd', 'en-US');
      this.search.effectiveEndDate = formatDate(time[1], 'yyyy-MM-dd', 'en-US');
    }

  }

  onInspectDateChange(time: number[]): void {
    if (time) {
      this.EffectDates = time;
      this.search.invalidStartDate = formatDate(time[0], 'yyyy-MM-dd', 'en-US');
      this.search.invalidEndDate = formatDate(time[1], 'yyyy-MM-dd', 'en-US');
    }
  }

  onDetail(list: ContractList): void {
    console.log(list.contractMode);
    const drawerRef = this.nz.create<ContractDetailComponent, { value: ContractDetails }, string>({
      nzWidth: 1000,
      nzContent: ContractDetailComponent,
      nzContentParams: {
        contractList: list,
        contractMode: list.contractMode,
        title: list.name
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      // component.underwritingSearch
      //   .subscribe(
      //     search => {
      //       this.search = search;
      //       this.onSearch(this.search.pageNum);
      //       drawerRef.close();
      //     }
      //   );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }
}
