<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{'BankDetail' | translate}}
  </div>

  <div class="section-wrapper"
       style=" padding: 15px">
    <div *ngIf="edit">
      <app-property-input [title]="'BankName' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('BankName' | translate)"
                          [required]="true"
                          [(value)]="bankInfo.name"></app-property-input>

      <!--      銀行編號-->
      <app-property-input [title]="'BankCode' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('BankCode' | translate)"
                          [required]="true"
                          [(value)]="bankInfo.code"></app-property-input>
      <!--      銀行卡號-->
      <app-property-input [title]="'BankNumber' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('BankNumber' | translate)"
                          [required]="true"
                          [(value)]="bankInfo.cardNo"></app-property-input>
      <!--   持卡人姓名   -->
      <app-property-input [title]="'OwnerName' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('OwnerName' | translate)"
                          [required]="true"
                          [(value)]="bankInfo.ownerName"></app-property-input>
      <!--   身份證號碼   -->
      <app-property-input [title]="'OwnerIdCard' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('OwnerIdCard' | translate)"
                          [required]="true"
                          [(value)]="bankInfo.ownerIdCard"></app-property-input>
      <!--  備註  -->
      <app-property-input-area [title]="'Remark' | translate"
                               [required]="true"
                               placeholder=""
                               [(value)]="bankInfo.remark"></app-property-input-area>
    </div>
    <div *ngIf="!edit">
      <app-property-text [title]="'BankName' | translate"
                         [value]="bankInfo.name "></app-property-text>
      <app-property-text [title]="'BankCode' | translate"
                         [value]="bankInfo.code "></app-property-text>
      <app-property-text [title]="'BankNumber' | translate"
                         [value]="bankInfo.cardNo "></app-property-text>
      <app-property-text [title]="'OwnerName' | translate"
                         [value]="bankInfo.ownerName "></app-property-text>
      <app-property-text [title]="'OwnerIdCard' | translate"
                         [value]="bankInfo.ownerIdCard "></app-property-text>
      <app-property-text [title]="'Remark' | translate"
                         [textType]="'html'"
                         [value]="bankInfo.remark "></app-property-text>
    </div>


  </div>

  <div class="footer" *ngIf="edit">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [style]="!canInspect ? 'opacity: 0.5' : ''"
           [disabled]="!canInspect"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>



