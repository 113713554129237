import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnaComponent } from './fna.component';
import { FnaMainComponent } from './fna-main.component';
import { FnaListComponent } from './fna-list/fna-list.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { FnaDetailComponent } from './fna-detail/fna-detail.component';
import { FnaClientComponent } from './fna-client/fna-client.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormsModule } from '@angular/forms';
import { FnaSourcesComponent } from './fna-sources/fna-sources.component';
import { FnaFormComponent } from './fna-form/fna-form.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { FnaAnswerInputBoxesComponent } from './components/fna-answer-input-boxes/fna-answer-input-boxes.component';
import { FnaQuestionComponent } from './components/fna-question/fna-question.component';
import { FnaProductComponent } from './fna-product/fna-product.component';
import { FnaProductSelectionComponent } from './fna-product-selection/fna-product-selection.component';
import { FnaProductItemComponent } from './components/fna-product-item/fna-product-item.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { FnaProductListComponent } from './fna-product-list/fna-product-list.component';
import { FnaSignatureComponent } from './fna-signature/fna-signature.component';
import { FnaSignaturePadComponent } from './fna-signature-pad/fna-signature-pad.component';
import { NzListModule } from 'ng-zorro-antd/list';
import { FnaProductEditComponent } from './fna-product-edit/fna-product-edit.component';
import { FnaSelectionComponent } from './fna-selection/fna-selection.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzModalModule } from 'ng-zorro-antd/modal';


@NgModule({
    declarations: [
        FnaComponent,
        FnaMainComponent,
        FnaListComponent,
        FnaDetailComponent,
        FnaClientComponent,
        FnaSourcesComponent,
        FnaFormComponent,
        FnaAnswerInputBoxesComponent,
        FnaQuestionComponent,
        FnaProductComponent,
        FnaProductSelectionComponent,
        FnaProductItemComponent,
        FnaProductListComponent,
        FnaSignatureComponent,
        FnaSignaturePadComponent,
        FnaProductEditComponent,
        FnaSelectionComponent
    ],
    exports: [
        FnaProductItemComponent
    ],
    imports: [
        CommonModule,
        NzLayoutModule,
        SharedModule,
        RouterModule,
        TranslateModule,
        NzSpinModule,
        NzTableModule,
        NzSkeletonModule,
        NzIconModule,
        NzButtonModule,
        NzGridModule,
        NzToolTipModule,
        NzPopoverModule,
        NzInputModule,
        FormsModule,
        NzCheckboxModule,
        NzRadioModule,
        NzInputNumberModule,
        NzTagModule,
        NzListModule,
        NzTabsModule,
        NzModalModule
    ]
})
export class FnaModule { }
