<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 40px">
    {{'UnderwritingDetail' | translate}}
  </div>

  <div style="display: flex; flex-direction: row; align-items: end">
    <div style="flex: 1; font-weight: bold; font-size: 18px; color: #404040">
      #{{underwritingDetail.underwritingNo}}</div>
    <div>
      <div [ngSwitch]="underwritingDetail.status">
        <div>
          <span *ngSwitchCase="'CHECKING'" class="text-warning">●</span>
          <span *ngSwitchCase="'UNDERWRITING'" class="text-warning">●</span>
          <span *ngSwitchCase="'SENDING'" class="text-warning">●</span>
          <span *ngSwitchCase="'PENDING_PREMIUM'" class="text-warning">●</span>
          <span *ngSwitchCase="'FINISHED'" class="text-success">●</span>
          <span *ngSwitchCase="'EFFECTIVE'" class="text-success">●</span>
          <span *ngSwitchCase="'DECLINED'" class="text-cancel">●</span>
          <span *ngSwitchCase="'DELETED'" class="text-warning">●</span>
          <span> {{ underwritingDetail.status | metadataTranslate: 'underwritingStatus' }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="booking-detail-header">
    <div>
      <img width="60" style="border-radius: 3px; margin-right: 5px" [src]="underwritingDetail.companyLogo" alt=""/>
    </div>
    <div style="flex: 1">
      <!-- 产品名称 -->
      <app-property-text [title]="'ClientName' | translate"
                         [isEncryption]="isDynamicEncryption('name')"
                         [value]="(underwritingDetail.ownerName + ' ' + underwritingDetail.ownerPinyin)"></app-property-text>
      <!-- 产品名称 -->
      <app-property-text [title]="'ProductName' | translate"
                         [value]="underwritingDetail.productName"></app-property-text>
      <!-- 保单号 -->
      <app-property-text [title]="'PolicyNumber' | translate"
                         [value]="underwritingDetail.policyNo"></app-property-text>
      <!-- 供款年期 -->
      <app-property-text *ngIf="!underwritingDetail.custom" [title]="'PaymentTerm' | translate"
                         [value]="underwritingDetail.productPlan?.productYear"></app-property-text>
      <!-- 资管产品供款年期 -->
      <app-property-text *ngIf="underwritingDetail.custom" [title]="'CustomPaymentTerm' | translate"
                         [value]="underwritingDetail.productPlan?.customPaymentTerm | yearConversion"></app-property-text>
      <!-- 銷售渠道 -->
      <app-property-text [title]="'Referee' | translate"
                         [isEncryption]="isDynamicEncryption('salesName')"
                         [value]="underwritingDetail.accountName"></app-property-text>
      <!-- 銷售渠道 -->
      <app-property-text *ngIf="workbench.secondAccountName && underwritingDetail.secondAccountName" [title]="('Referee' | translate) + '2'"
                         [isEncryption]="isDynamicEncryption('salesName')"
                         [value]="underwritingDetail.secondAccountName"></app-property-text>
    </div>
  </div>

  <nz-tabset [nzSize]="'large'" [nzCentered]="false" (nzSelectChange)="tabsIndex($event)">
    <nz-tab [nzTitle]=underwritingStatus>
      <app-time-line [timeLineVOS]="underwriting.timeLineVOS" [uploadTokenReq]="uploadTokenReq"
                     [pendingDetail]="pendingDetail"
                     [pendingReply]='pendingReply'
                     (pendingChange)="onPendingDetail($event)"
                     (underwritingPendingReply)="onPenddingReplyDetail($event)"
                     [showAttachments]="attachmentAuth.display"
                     [modeType]="'underwriting'"
                     [mode]="mode"
                     [type]="'timeLine'" #timeLint></app-time-line>
    </nz-tab>
    <ng-template #underwritingStatus>
      <nz-badge nzDot [nzShowDot]="messageRemind.status > 0">
        <span>
          {{'UnderwritingStatus' | translate }}
        </span>
      </nz-badge>
    </ng-template>
    <nz-tab [nzTitle]=underwritingOverview>
      <app-underwriting-overview [underwritingDetail]="underwritingDetail"
                                 [mode]="mode"
                                 [dataDesensitization]="dataDesensitization"
                                 [underwriting]="underwriting"></app-underwriting-overview>
    </nz-tab>
    <ng-template #underwritingOverview>
      <nz-badge nzDot [nzShowDot]="messageRemind.info > 0">
        <span>
          {{'UnderwritingInfo' | translate}}
        </span>
      </nz-badge>
    </ng-template>
    <nz-tab *ngIf="attachmentAuth.display" [nzTitle]=UnderwritingAttachments>
      <app-attachments [attachments]="underwriting.attachments"
                       [uploadTokenReq]="uploadTokenReq"
                       [readonly]="attachmentAuth.readonly"
                       [isGroup]="true"
                       [showEmpty]="false"
                       [isPreview]="true"
                       (completeAll)="reloadDetail($event)"
                       (valueChange)="onAttachmentsChange($event)"></app-attachments>
    </nz-tab>
    <ng-template #UnderwritingAttachments>
      <nz-badge nzDot [nzShowDot]="messageRemind.attachment > 0">
        <span>
          {{'UnderwritingAttachment' | translate}}
        </span>
      </nz-badge>
    </ng-template>

    <nz-tab [nzTitle]=underwritingMatter>
      <app-underwriting-pending [underwriting]="underwriting"
                                (putSendMessage)="getMessageRemind()"
                                [mode]="mode">
      </app-underwriting-pending>
    </nz-tab>
    <ng-template #underwritingMatter>
      <nz-badge nzDot [nzShowDot]="messageRemind.pending > 0 || messageRemind.pendingReply > 0">
        <span>
          {{ instance.isFone() ? ('UnderwritingMatter'| translate) : ('UnderwritingPending'| translate)}}
        </span>
      </nz-badge>
    </ng-template>

    <nz-tab [nzTitle]=Forms *ngIf="isShowItem && underwriting.forms && underwriting.forms.length > 0">
      <div class="booking-section-wrapper" *ngFor="let i of underwriting.forms" appThrottleClick (throttleClick)="onFormDetail(i)">
        <div class="property-row" >
          <div class="property-title-row">{{ i.name }}</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>
    </nz-tab>
    <ng-template #Forms>
      {{'Forms'| translate}}
    </ng-template>
  </nz-tabset>


  <!--  form下载-->
  <nz-drawer [nzWidth]="800" [nzMaskClosable]="true" [nzVisible]="formVisible"
             (nzOnClose)="formClose()">
    <ng-container *nzDrawerContent>
      <div class="drawer-container" *ngIf="formItem" >
        <div class="common-title" style="margin-bottom: 40px">
          {{formItem.bookingFormName}}
        </div>
        <div class="flex">
          <div>{{formItem.signedDate ? formItem.signedDate : time | date:'yyyy-MM-dd'}}</div>
          <div *ngIf="formItem.status === 'UNCOMPLETED'">{{'Unsigned' | translate}}</div>
          <div *ngIf="formItem.status === 'COMPLETED'">{{'Signed' | translate}}</div>
        </div>
        <div class="section-wrapper">
        <div
          style="padding: 15px 0 15px 0;display: flex; flex-direction: row; align-items: center; justify-content: start;">
          <div style="flex: 1; display: flex; flex-direction: row; align-items: center;">
            <span style="margin-left: 5px; font-weight: bold; font-size: 16px"> {{formItem.bookingFormName}}</span>
          </div>
          <a *ngIf="formPdf" nz-button nzType="link" nzSize="small" nz-tooltip
             [nzTooltipTitle]="'Preview' | translate"
             appThrottleClick (throttleClick)="onPreviewNormalResult(); $event.stopPropagation();">
            <img alt="" width="20" src="../../../../assets/images/ic_view.svg">
          </a>
          <a *ngIf="formPdf" nz-button nzType="link" nzSize="small" nz-tooltip
             [nzTooltipTitle]="'Download' | translate"
             appThrottleClick (throttleClick)="onDownloadNormalResult(); $event.stopPropagation();">
            <img alt="" width="20" src="../../../../assets/images/ic_download.svg">
          </a>
        </div>
        </div>
      </div>
    </ng-container>
  </nz-drawer>
</div>
