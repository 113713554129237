import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from '../base/base.service';
import {
  Fna, FnaAssessAdviceUpdateReq,
  FnaCreateReq,
  FnaCustomerUpdateReq,
  FnaDraft,
  FnaListRes,
  FnaProductRecommended, FnaRecommendedUpdateReq,
  FnaSearch, FnaSignatureUpdateReq
} from '../fna/fna-types';
import { ApiResponse, DataMode, PagedResp } from '../api/types';
import { Observable } from 'rxjs/internal/Observable';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { plainToClass, plainToInstance } from 'class-transformer';
import {
  BasicInfoSave,
  FinancialSave, FnaFormConfig, FnaPDFQuery, FnaSystemSave,
  NewFnaAssessAdviceUpdateReq, NewFnaChooseProductReq,
  NewFnaDetail,
  NewFnaList,
  NewFnaSearch
} from './new-fna-types';
import { PDFDownload } from '../sales-pact/sales-pact-types';

@Injectable({
  providedIn: 'root'
})
export class NewFnaService extends BaseService{

  constructor(private http: HttpService, private httpClient: HttpClient) {
    super();
  }
  /**
   * FNA列表
   * @param search 查询条件
   * @param type 类型
   */
  list(search: NewFnaSearch, type: DataMode): Observable<PagedResp<NewFnaList>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.accountName != null) {
      httpParams = httpParams.set('name', search.accountName.toString());
    }
    if (search.customerName != null) {
      httpParams = httpParams.set('customerName', search.customerName.toString());
    }
    if (search.likeBookingNo != null) {
      httpParams = httpParams.set('name', search.likeBookingNo.toString());
    }
    if (search.orderColumn != null) {
      httpParams = httpParams.set('orderColumn', search.orderColumn.toString());
    }
    if (search.orderAspect != null) {
      httpParams = httpParams.set('orderAspect', search.orderAspect.toString());
    }
    if (search.createDateBegin != null) {
      httpParams = httpParams.set('createDateBegin', search.createDateBegin.toString());
    }
    if (search.createDateEnd != null) {
      httpParams = httpParams.set('createDateEnd', search.createDateEnd.toString());
    }
    if (search.productCode != null) {
      httpParams = httpParams.set('productCode', search.productCode.toString());
    }
    if (search.likePolicyNo != null) {
      httpParams = httpParams.set('likePolicyNo', search.likePolicyNo.toString());
    }
    if (search.likeUnderwritingNo != null) {
      httpParams = httpParams.set('likeUnderwritingNo', search.likeUnderwritingNo.toString());
    }
    if (search.policyIds != null) {
      httpParams = httpParams.set('underwritingNo', search.policyIds.toString());
    }
    let uri = '';
    switch (type) {
      case DataMode.OWN:
        uri = '/list';
        break;
      case DataMode.SUBORDINATE:
        uri = '/list-subordinate';
        break;
      case DataMode.FOLLOWUP:
        uri = '/list-consultant';
        break;
      // case DataMode.SALESGROUP:
      //   uri = '/list-sales-group';
      //   break;
    }
    return this.http.get<ApiResponse<PagedResp<NewFnaList>>>(API.newFnaV3 + uri, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
      // const resp = plainToClass(PagedResp, data.body.data) as PagedResp<NewFnaList>;
      // for (let i = 0; i < resp.list.length; i++) {
      //   resp.list[i] = plainToClass(NewFnaList, resp.list[i]);
      // }
      // return resp;
    }));
  }

  /**
   * FNA詳情
   * @param id FNA詳情
   */
  info(id: number): Observable<NewFnaDetail> {
    return this.http.get<ApiResponse<NewFnaDetail>>(API.newFnaV3 + '/info/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(NewFnaDetail, data.body.data, {exposeDefaultValues: true});
    }));
  }

  /**
   * 添加/更新 基础资料
   * @param createReq FNA詳情
   */
  basicInfo(createReq: BasicInfoSave): Observable<any> {
    return this.http.post<ApiResponse<any>>(API.newFnaV3 + '/basic-information', createReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新财务需求分析
   * @param updateReq 客戶數據
   */
  updateFinancial(updateReq: FinancialSave): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newFnaV3 + '/financial-analysis', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 更新附加资料分析
   * @param updateReq 客戶數據
   */
  updateAnalysis(updateReq: FinancialSave): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newFnaV3 + '/additional-data-analysis', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新FNA下载表单信息
   * @param updateReq 客戶數據
   */
  updateFnaFormNos(updateReq: FnaPDFQuery): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newFnaV3 + '/category-nos', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * .FNA pdf下载
   * @param updateReq FNA詳情
   */
  viewFnaPDF(updateReq: FnaPDFQuery): Observable<any> {
    let httpParams = new HttpParams();
    if (updateReq.id != null) {
      httpParams = httpParams.set('id', updateReq.id.toString());
    }
    if (updateReq.merge != null) {
      httpParams = httpParams.set('merge', updateReq.merge.toString());
    }
    if (updateReq.formLanguage != null) {
      httpParams = httpParams.set('formLanguage', updateReq.formLanguage.toString());
    }
    if (updateReq.categoryNos != null) {
      httpParams = httpParams.set('categoryNos', updateReq.categoryNos.toString());
    }
    return this.http
      .download(API.newFnaV4 + '/download', {
        params: httpParams,
        responseType: 'blob',
        observe: 'response'
      }).pipe(map((data: any) => {
        return data;
      }));
  }

  /**
   * 删除FNA
   * @param id FNA Id
   */
  deleteFna(id: number): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.newFnaV3 + '/delete/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 产品推荐
   * @param id FNA Id
   */
  fnaProductRecommended(id: number): Observable<FnaProductRecommended> {
    return this.http.get<ApiResponse<FnaProductRecommended>>(API.newFnaV3 + '/recommend/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      const sources = data.body.data;
      return plainToClass(FnaProductRecommended, sources, {exposeDefaultValues: true});
    }));
  }


  /**
   * 推荐产品保存
   * @param updateReq 推荐表单
   */
  updateFnaRecommended(updateReq: FnaRecommendedUpdateReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newFnaV3 + '/recommend', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新产品选择和评估建议表单
   * @param updateReq 评估建议數據
   */
  updateFnaAdvice(updateReq: NewFnaAssessAdviceUpdateReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newFnaV3 + '/assess-advice', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新产品选择和评估建议表单 new
   * @param updateReq 评估建议數據
   */
  updateFnaProduct(updateReq: NewFnaChooseProductReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newFnaV4 + '/choose-product', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新FNA签名
   * @param updateReq 签名數據
   */
  updateFnaSignature(updateReq: FnaSignatureUpdateReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newFnaV3 + '/signature', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * FNA提交最終簽名
   * @param id FNA Id
   */
  fnaEnd(id: number): Observable<boolean> {
    let httpParams = new HttpParams();
    if (id) {
      httpParams = httpParams.set('id', id.toString());
    }
    return this.http.put<ApiResponse<boolean>>(API.newFnaV3 + '/submit-signature', null, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * FNA重新簽名
   * @param id FNA Id
   */
  reFnaSign(id: number): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newFnaV3 + '/re-sign/' + id, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * FNA重置理财顾问签名
   * @param id FNA Id
   */
  reFnaSalesSign(id: number): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newFnaV3 + '/re-sales-sign/' + id, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * FNA pdf下载
   * @param id id
   */
  viewFna(id: number): Observable<Uint8Array> {
    return this.httpClient
      .get(API.newFnaV3 + '/download/' + id, {
        responseType: 'arraybuffer'
      }).pipe(map((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer)));
  }


  /**
   * .更新FNA系统信息
   * @param updateReq 客戶數據
   */
  updateFnaSystem(updateReq: FnaSystemSave): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newFnaV3 + '/system-data', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * .更新特别客户声明
   * @param updateReq 客戶數據
   */
  updateFnaDeclaration(updateReq: FinancialSave): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newFnaV3 + '/declaration', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * FNA提交最終簽名
   * @param updateReq FNA Id
   * Query: id proposalId
   */
  relatedProposalNewFna(updateReq): Observable<boolean> {
    let httpParams = new HttpParams();
    if (updateReq.id != null) {
      httpParams = httpParams.set('id', updateReq.id.toString());
    }
    if (updateReq.proposalId != null) {
      httpParams = httpParams.set('proposalId', updateReq.proposalId.toString());
    }
    return this.http.put<ApiResponse<boolean>>(API.newFnaV3 + '/related-proposal', null, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 复用FNA
   * @param id FNA ID
   */
  reuseNewFna(id: number): Observable<FnaDraft> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('id', id.toString());
    return this.http.get<ApiResponse<FnaDraft>>(API.newFnaV3 + '/reuse', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(FnaDraft, data.body.data, {excludeExtraneousValues: true, exposeDefaultValues: true});
    }));
  }

  /**
   * 获取FNA表单配置
   * formLanguage
   */
  fnaFormConfig(formLanguage: string): Observable<FnaFormConfig[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('formLanguage', formLanguage.toString());
    return this.http.get<ApiResponse<FnaFormConfig[]>>(API.newFnaV4 + '/form-config', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 检查FNA是否能签名
   * @param id FNA詳情
   */
  checkSign(id: number): Observable<any> {
    return this.http.get<ApiResponse<any>>(API.newFnaV3 + '/check-is-sign/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }



}
