import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContactService } from '../../../contact/contact.service';
import { Tree } from '../../../contact/contact-types';
import { NzCascaderFilter } from 'ng-zorro-antd/cascader';
import { lowerFirst } from 'lodash';
import { Phone } from '../../../api/types';

const filter: NzCascaderFilter = (i, p) => {
  return p.some((o) => {
    console.log(o.name);
    console.log(i);
    const label = o.name;
    const labelName = o.accountName;
    return !!label && label.toLowerCase().indexOf(i.toLowerCase()) !== -1 || !!labelName && labelName.toLowerCase().indexOf(i.toLowerCase()) !== -1;
  });
};

@Component({
  selector: 'app-sub-sales-channel',
  templateUrl: './sub-sales-channel.component.html',
  styleUrls: ['./sub-sales-channel.component.less']
})
export class SubSalesChannelComponent implements OnInit {
  accountName: any;
  NzShowSearchOptions = {
    filter
  };
  nzOptions: Tree[] = [];
  @Input()
  title: string;

  search: string;
  returnData: Tree[] = [];

  @Output()
  strSaved: EventEmitter<any> = new EventEmitter<any>();

  constructor(private contactService: ContactService) {
  }

  ngOnInit(): void {
    this.contactService.sub()
      .subscribe(
        data => {
          console.log(data);
          this.returnData = data || [];
        }
      );
  }

  flatten(res, arr): void {
    arr.map(v => {
      res.push(v);
      if (v.children instanceof Array && v.children.length > 0) {
        this.flatten(res, v.children);
      }
    });
  }

  onSave(info: Tree): void {
    const res = [];
    this.flatten(res, this.nzOptions);
    console.log('onSave');
    // const para = Array.isArray(this.accountName) && this.accountName.length ? res.find(item => item.accountName === this.accountName[this.accountName.length - 1]) : undefined;
    this.strSaved.emit(info);
  }

  onChanges(values: string[]): void {
    console.log(values, this.accountName);
  }

  onSearch(): void{
    this.contactService.sub({keywords: this.search})
      .subscribe(
        data => {
          console.log(data);
          this.returnData = data || [];
        }
      );
  }
}
