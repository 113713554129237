import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DocumentComponent } from './document.component';
import { DocumentMainComponent } from './document-main.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { DocumentsComponent } from './documents/documents.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { DocumentCreateComponent } from './document-create/document-create.component';
import { DocumentUsedListComponent } from './document-used-list/document-used-list.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    DocumentComponent,
    DocumentMainComponent,
    DocumentsComponent,
    DocumentCreateComponent,
    DocumentUsedListComponent,
    DocumentUploadComponent
  ],
    imports: [
        CommonModule,
        NzLayoutModule,
        RouterModule,
        TranslateModule,
        SharedModule,
        NzTabsModule,
        NzSpinModule,
        NzIconModule,
        NzSkeletonModule,
        NzTableModule,
        NzGridModule,
        NzToolTipModule,
        NzButtonModule,
        NzPopoverModule,
        NzBreadCrumbModule,
        FileUploadModule,
        NzProgressModule,
        NzModalModule,
        NzProgressModule,
        FormsModule
    ]
})
export class DocumentModule {
}
