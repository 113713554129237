<div>
  <ng-template #shareTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="shareTemplate" [nzSpinning]="dataSource.spinning">
    <div *ngIf="!dataSource">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="dataSource" style="margin-top: 30px" class="share-list">
      <div class="share-list-header">
        <nz-row>
          <nz-col [nzSpan]="12">
            <div class="list-header-title">{{'AmountShare'  |translate:{condition: dataSource.getLength()} }}
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                 style="margin-left: 5px"
                 appThrottleClick (throttleClick)="onReload()">
                <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
              </a>
            </div>

          </nz-col>
          <nz-col [nzSpan]="12">
            <div style="width: 100%; display: flex; justify-content: flex-end;">

              <button nz-button nzType="default" nzSize="large" nzShape="circle" style=" margin-left: 10px;"
                      nz-popover [nzPopoverContent]="filterTemplate"
                      [(nzPopoverVisible)]="filterPopoverVisible"
                      nzPopoverTrigger="click"
                      nzPopoverPlacement="bottomRight">
                <img alt="" width="22" src="assets/images/ic_filter.svg">
              </button>
            </div>
          </nz-col>
        </nz-row>
      </div>
      <cdk-virtual-scroll-viewport itemSize="140" class="demo-infinite-container">
        <nz-list>
          <nz-list-item *cdkVirtualFor="let item of dataSource">
            <nz-skeleton *ngIf="!item" [nzAvatar]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>

            <div *ngIf="item" style="display: flex;flex:1;cursor: pointer;" appThrottleClick
                 (throttleClick)="onDetail(item)">
              <div>
                <img style="width: 160px;" [src]="item.cover">
              </div>
              <div
                style="margin-left: 30px;flex: 1;display: flex;flex-direction: column;justify-content: space-between;">
                <p
                  style="font-weight: 500;color: #404040;font-size: 16px;">{{ item.shareSources | metadataTranslate:'shareSources'}}
                  &nbsp; | &nbsp; {{item.title}}</p>
                <div style="flex-direction: row">
                <span *ngFor="let i of item.tags">
                  <nz-tag [nzColor]="'blue'">{{ i }}</nz-tag>
                </span>
                </div>
                <div style="font-size: 14px;font-weight: 400;color: #A6A6A6;">
                  {{item.addTime | date: "yyyy-MM-dd HH:mm:ss"}}
                </div>
                <div>
                  <div style="flex:1;display: flex;">
                    <img alt="" width="20" src="assets/images/ic_eyes.svg">
                    <span
                      style="margin-left:10px; font-size: 14px;font-weight: 400;color: #A6A6A6"> {{item.browseNumber}} {{"Person"| translate}}</span>
                    <img style="margin-left: 32px" src="assets/images/ic_frequency.svg" alt="">
                    <span
                      style="margin-left:10px; font-size: 14px;font-weight: 400;color: #A6A6A6"> {{item.frequency}} {{ "Count" | translate}}</span>
                    <div style="margin-left: auto;">
                    <span
                      style="color: #4B84E5;font-size: 14px;cursor: pointer;">  {{"View"| translate}}</span>
                      <span><i nz-icon nzType="right" nzTheme="outline" style="color: #4B84E5"></i></span>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </nz-list-item>
        </nz-list>
      </cdk-virtual-scroll-viewport>
    </div>
  </nz-spin>

</div>
<ng-template #filterTemplate>
  <div style="width: 270px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>

    <!-- 分享來源 -->
    <app-property-select [title]="'Platform' | translate"
                         [options]="regionOptions"
                         [(value)]="dataSource.search.shareSources"></app-property-select>
    <!-- 是否系統內的分享記錄 -->
    <app-property-yes-or-not [title]="'IsFormSystem' | translate" [(value)]="dataSource.search.inSystem">
    </app-property-yes-or-not>
    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onReload()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
