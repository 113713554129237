import { Component, OnInit, TemplateRef } from '@angular/core';
import { AmlArtificialStatusUpdateReq, AmlInfo, AmlResultItem } from '../../../api/types';
import { MetadataKeyValue, MetadataService } from '../../../shared/service/metadata.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AmlService } from '../../aml.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { WorkbenchService } from '../../../workbench/workbench.service';
import { Workbench } from '../../../workbench/workbench-types';

@Component({
  selector: 'app-aml-search-detail',
  templateUrl: './aml-search-detail.component.html',
  styleUrls: ['./aml-search-detail.component.less']
})
export class AmlSearchDetailComponent implements OnInit {

  loading = false;

  info: AmlInfo;

  statusKeyValues: MetadataKeyValue[];

  stateOfArtificialLoading = false;

  normalPdf: Uint8Array;
  workbench: Workbench;

  stateModified = false;

  constructor(private metadataService: MetadataService,
              private modalService: NzModalService,
              private workbenchService: WorkbenchService,
              private drawerService: NzDrawerService,
              private amlService: AmlService) {

  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.statusKeyValues = this.metadataService.values('amlArtificialStatus');
    this.statusKeyValues.map(item => {
      switch (item.key) {
        case 'MATCHED':
          item.text = 'true-positive';
          break;
        case 'NOT_MATCHED':
          item.text = 'false-positive';
          break;
      }
    });
    if (!this.info.searchResult || this.info.searchResult.length === 0) {
      this.loading = true;
      this.amlService.downloadNormalProfile(this.info.searchRecordId)
        .subscribe(
          byteArray => {
            this.loading = false;
            this.normalPdf = byteArray;
          },
          error => {
            this.loading = false;
          }
        );
    }
  }

  loadDetail(id: number): void {
    this.loading = true;
    this.amlService.detail(id)
      .subscribe(info => {
          this.info = info;
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  onPreview(result: AmlResultItem): void {
    this.loading = true;
    this.amlService.downloadAbnormalProfile({id: this.info.searchRecordId,  thirdPartId: result.id})
      .subscribe(
        byteArray => {
          this.loading = false;

          const url = window.URL.createObjectURL(new Blob([byteArray], {type: 'application/pdf'}));
          window.open(url);

          //
          // const drawerRef = this.drawerService.create<PdfViewerComponent, { value: string }, string>({
          //   nzWidth: 800,
          //   nzContent: PdfViewerComponent,
          //   nzContentParams: {
          //     src: byteArray,
          //   }
          // });
          //
          // drawerRef.afterOpen.subscribe(() => {
          //   const pdfViewerComponent = drawerRef.getContentComponent();
          //   pdfViewerComponent.autoresize = true;
          //   pdfViewerComponent.showAll = true;
          //   pdfViewerComponent.originalSize = false;
          //   pdfViewerComponent.fitToPage = true;
          // });
          //
          // drawerRef.afterClose.subscribe(data => {
          //   if (typeof data === 'string') {
          //   }
          // });
        },
        error => {
          this.loading = false;
        }
      );
  }

  onArtificialStatusUpdate(result: AmlResultItem, status: string, tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {

    if (result.artificialStatus === status) {
      return;
    }

    const req = new AmlArtificialStatusUpdateReq();
    req.id = this.info.searchRecordId;
    req.thirdPartyId = result.id;
    req.artificialStatus = status;

    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: req,
      nzOnOk: () => {
      }
    });
  }

  onArtificialStatusUpdateConfirm(ref: NzModalRef, req: AmlArtificialStatusUpdateReq): void {
    if (this.stateOfArtificialLoading) {
      return;
    }
    this.stateOfArtificialLoading = true;
    this.amlService.updateArtificialStatus(req)
      .subscribe(
        data => {
          ref.close();
          this.stateOfArtificialLoading = false;
          this.stateModified = true;
          this.loadDetail(this.info.searchRecordId);
        },
        error => {
          ref.close();
          this.stateOfArtificialLoading = false;
        }
      );
  }

  onPreviewNormalResult(): void {
    const url = window.URL.createObjectURL(new Blob([this.normalPdf], {type: 'application/pdf'}));
    window.open(url);
  }

  onDownloadNormalResult(): void {
    const a = document.createElement('a');
    a.download = this.info.searchRecordId + '.pdf';
    a.href = window.URL.createObjectURL(new Blob([this.normalPdf], {type: 'application/pdf'}));
    a.click();
  }

}
