import { Injectable } from '@angular/core';
import { DataMode, DialogReply, Instance, InstanceAccount, Passport, Pivot } from '../../api/types';
import { ProductSelectionType } from '../component/product-selection/product-selection.component';
import { plainToInstance } from 'class-transformer';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { AppInjector } from '../../core/app-injector';
import { Constant } from '../../base/constant';
import { UUID } from 'angular2-uuid';
import { plainToArray } from '../utils/class-transform';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  static KEY_PROPOSAL_MESSAGE_DRAFT = 'KEY_PROPOSAL_MESSAGE_DRAFT';
  static KEY_BOOKING_MESSAGE_DRAFT = 'KEY_BOOKING_MESSAGE_DRAFT';
  static KEY_UNDERWRITING_MESSAGE_DRAFT = 'KEY_UNDERWRITING_MESSAGE_DRAFT';
  static KEY_POLICY_MESSAGE_DRAFT = 'KEY_POLICY_MESSAGE_DRAFT';

  deviceInfo: DeviceInfo = null;

  deviceService: DeviceDetectorService;

  constructor() {
    const injector = AppInjector.injector;
    this.deviceService = injector.get(DeviceDetectorService);
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  // DataMode
  dataModeOwn = DataMode.OWN;
  dataModeSubordinate = DataMode.SUBORDINATE;
  dataModeFollowUp = DataMode.FOLLOWUP;
  dataModeSalesGroup = DataMode.SALESGROUP;

  // Pivot
  pivotOwn = Pivot.OWN;
  pivotDirectSubordinate = Pivot.DIRECT_SUBORDINATE;
  pivotAllSubordinate = Pivot.ALL_SUBORDINATE;

  // Product Selection Mode
  productSelectionModeDefault = ProductSelectionType.DEFAULT;
  productSelectionModeProposal = ProductSelectionType.PROPOSAL;

  get redirectUrl(): string {
    return localStorage.getItem(Constant.LOCAL_STORAGE_KEY_REDIRECT_URL);
  }

  set redirectUrl(val: string) {
    localStorage.setItem(Constant.LOCAL_STORAGE_KEY_REDIRECT_URL, val);
  }

  get uuid(): string {
    let value: string = localStorage.getItem(Constant.LOCAL_STORAGE_KEY_UUID);
    if (value === null) {
      value = UUID.UUID();
      this.uuid = value;
    }
    return value;
  }

  set uuid(val: string) {
    localStorage.setItem(Constant.LOCAL_STORAGE_KEY_UUID, val);
  }

  get instance(): Instance {
    const value: string = localStorage.getItem(Constant.LOCAL_STORAGE_KEY_INSTANCE);
    return plainToInstance(Instance, JSON.parse(value));
  }

  set instance(val: Instance) {
    localStorage.setItem(Constant.LOCAL_STORAGE_KEY_INSTANCE, JSON.stringify(val));
  }

  get passport(): Passport {
    const value: string = localStorage.getItem(Constant.LOCAL_STORAGE_KEY_PASSPORT);
    return plainToInstance(Passport, JSON.parse(value));
  }

  set passport(val: Passport) {
    localStorage.setItem(Constant.LOCAL_STORAGE_KEY_PASSPORT, JSON.stringify(val));
  }

  get accounts(): InstanceAccount[] {
    const value: string = localStorage.getItem(Constant.LOCAL_STORAGE_KEY_ACCOUNTS);
    console.log(value);
    let list: InstanceAccount[] = [];
    if (value) {
      const array = JSON.parse(value);
      list = plainToArray(InstanceAccount, array);
    }
    return list;
  }

  set accounts(val: InstanceAccount[]) {
    localStorage.setItem(Constant.LOCAL_STORAGE_KEY_ACCOUNTS, JSON.stringify(val));
  }

  getProposalMessageDraft(proposalId: string): DialogReply {
    const value: string = localStorage.getItem(DataService.KEY_PROPOSAL_MESSAGE_DRAFT + '-' + proposalId);
    return plainToInstance(DialogReply, JSON.parse(value));
  }

  setProposalMessageDraft(proposalId: string, val: DialogReply): void {
    localStorage.setItem(DataService.KEY_PROPOSAL_MESSAGE_DRAFT + '-' + proposalId, JSON.stringify(val));
  }

  removeProposalMessageDraft(proposalId: string): void {
    localStorage.removeItem(DataService.KEY_PROPOSAL_MESSAGE_DRAFT + '-' + proposalId);
  }

  getBookingMessageDraft(bookingNo: string): DialogReply {
    const value: string = localStorage.getItem(DataService.KEY_BOOKING_MESSAGE_DRAFT + '-' + bookingNo);
    return plainToInstance(DialogReply, JSON.parse(value));
  }

  setBookingMessageDraft(bookingNo: string, val: DialogReply): void {
    localStorage.setItem(DataService.KEY_BOOKING_MESSAGE_DRAFT + '-' + bookingNo, JSON.stringify(val));
  }

  removeBookingMessageDraft(bookingNo: string): void {
    localStorage.removeItem(DataService.KEY_BOOKING_MESSAGE_DRAFT + '-' + bookingNo);
  }

  getUnderwritingMessageDraft(underwritingNo: string): DialogReply {
    const value: string = localStorage.getItem(DataService.KEY_UNDERWRITING_MESSAGE_DRAFT + '-' + underwritingNo);
    return plainToInstance(DialogReply, JSON.parse(value));
  }

  setUnderwritingMessageDraft(underwritingNo: string, val: DialogReply): void {
    localStorage.setItem(DataService.KEY_UNDERWRITING_MESSAGE_DRAFT + '-' + underwritingNo, JSON.stringify(val));
  }

  removeUnderwritingMessageDraft(underwritingNo: string): void {
    localStorage.removeItem(DataService.KEY_UNDERWRITING_MESSAGE_DRAFT + '-' + underwritingNo);
  }

  getPolicyMessageDraft(policyId: string): DialogReply {
    const value: string = localStorage.getItem(DataService.KEY_POLICY_MESSAGE_DRAFT + '-' + policyId);
    return plainToInstance(DialogReply, JSON.parse(value));
  }

  setPolicyMessageDraft(policyId: string, val: DialogReply): void {
    localStorage.setItem(DataService.KEY_POLICY_MESSAGE_DRAFT + '-' + policyId, JSON.stringify(val));
  }

  removePolicyMessageDraft(policyId: string): void {
    localStorage.removeItem(DataService.KEY_POLICY_MESSAGE_DRAFT + '-' + policyId);
  }


}
