import { PagedReq } from '../api/types';
import { Expose, Type } from 'class-transformer';

export class BvSearch extends PagedReq {
  @Expose() policyNo: string; // 保单号
  @Expose() underwritingNo: string; // 新单号
  @Expose() accountName: string; // 销售渠道
  @Expose() accountNames: string[]; // 销售渠道列表
  @Expose() companyCode: string; // 公司码
  @Expose() productCode: string; // 产品码
  @Expose() productCodes: string[]; // 产品码列表
  @Expose() policyDateStart: string; // 保单日期开始
  @Expose() policyDateEnd: string; // 保单日期结束
  @Expose() mySelf: string; // 查询自己
  @Expose() orderColumn: string; // 排序字段
  @Expose() orderAspect: string; // 排序类型 DESC/ASC
}

export class BvList {
  @Expose() id: number; // 记录ID
  @Expose() policyId: string; // 保单ID
  @Expose() policyNo: string; // 保单号
  @Expose() underwritingNo: string; // 新单号
  @Expose() accountName: string; // 销售渠道
  @Expose() productName: string; // 产品名称
  @Expose() ownerName: string; // 客户姓名
  @Expose() ownerNameEn: string; // 客户姓名
  @Expose() companyName: string; // 公司名称
  @Expose() policyDate: string; // 保单日期
  @Expose() firstYearBv: number; // 首年BV
  @Expose() totalBv: number; // 首年BV
  @Expose() aibv: number; // AIBV
}
