import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FnaInputBox } from '../../../fna/fna-types';

@Component({
  selector: 'app-answer-input-boxes',
  templateUrl: './answer-input-boxes.component.html',
  styleUrls: ['./answer-input-boxes.component.less']
})
export class AnswerInputBoxesComponent implements OnInit {

  @Input()
  inputBox: FnaInputBox[]; // 文本框
  @Input() disabled = false;

  @Output()
  inputBoxChange: EventEmitter<FnaInputBox[]> = new EventEmitter<FnaInputBox[]>();
  constructor() { }

  ngOnInit(): void {
  }

}
