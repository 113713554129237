<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{ 'Other' | translate }}
  </div>
  <div class="section-wrapper">
    <div *ngFor="let item of domainMetadata; let i = index">
      <app-property-text *ngIf="item.fieldType == 'TextField'" [title]="item.fieldName"
                         [value]="item.value"></app-property-text>

      <app-property-text *ngIf="item.fieldType == 'TextareaField'" [title]="item.fieldName"
                         [value]="item.value"></app-property-text>

      <app-property-text *ngIf="item.fieldType == 'MoneyField'" [title]="item.fieldName"
                         [value]="item.value"></app-property-text>

      <app-property-text *ngIf="item.fieldType == 'NumberField'" [title]="item.fieldName"
                         [value]="item.value"></app-property-text>

      <app-property-text *ngIf="item.fieldType == 'DateField'" [title]="item.fieldName"
                         [value]="item.value"></app-property-text>

      <app-property-text *ngIf="item.fieldType == 'DateTimeField'" [title]="item.fieldName"
                         [value]="item.value"></app-property-text>

      <app-property-text *ngIf="item.fieldType == 'SingleBox'" [title]="item.fieldName"
                         [value]="onSingleBox(item.value, item.values)"></app-property-text>

      <app-property-text *ngIf="item.fieldType == 'MultiCheckBoxes'" [title]="item.fieldName"
                         [value]="onMultiCheckBoxes(item)"></app-property-text>

    </div>
  </div>
</div>
