import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FnaInputBox} from '../../fna-types';

@Component({
  selector: 'app-fna-answer-input-boxes',
  templateUrl: './fna-answer-input-boxes.component.html',
  styleUrls: ['./fna-answer-input-boxes.component.less']
})
export class FnaAnswerInputBoxesComponent implements OnInit {

  @Input()
  inputBox: FnaInputBox[]; // 文本框
  @Input() disabled = false;

  @Output()
  inputBoxChange: EventEmitter<FnaInputBox[]> = new EventEmitter<FnaInputBox[]>();

  constructor() {
  }

  ngOnInit(): void {
  }

}
