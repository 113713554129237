import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-promotion-main',
  templateUrl: './product-promotion-main.component.html',
  styleUrls: ['./product-promotion-main.component.less']
})
export class ProductPromotionMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
