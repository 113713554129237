<div class="drawer-container" style="padding-bottom: 10px">
  <div class="common-title" style="margin-bottom: 40px">
    {{'HedgeProposalPreview' | translate}}
  </div>
  <div class="proposal-apply-section-title">
    <span style="margin-left: 8px">{{'HedgeProposalTotal' | translate}}</span>
  </div>
  <nz-row [nzGutter]="15">
    <nz-col [nzSpan]="12">

      <app-property-text [displayMode]="'vertical'" [title]="'Age' | translate"
                         [bottomLineVisible]="false"
                         [value]="(hedgeData.beginAge + '-' + hedgeData.endAge)"></app-property-text>
    </nz-col>
    <nz-col [nzSpan]="12">

      <app-property-text [displayMode]="'vertical'" [title]="'TotalAmount' | translate"
                         [bottomLineVisible]="false"
                         [value]="(hedgeData.totalAmount + (hedgeData.currency | metadataTranslate:'currency'))"></app-property-text>
    </nz-col>
  </nz-row>
  <div class="proposal-apply-section-title">
    <span style="margin-left: 8px">{{'Detail' | translate}}</span>
  </div>
  <div style="height: 70vh;overflow: hidden;overflow-y: auto;padding: 0  20px 20px 0">
    <div *ngFor="let amount of hedgeData.amounts" class="amounts-box">
      <div class="amounts-box-left">
        {{amount.age}}
        <span class="amounts-box-ls">{{'YearsOld' | translate}}</span>
      </div>
      <div class="amounts-box-right">
        <span>{{'PolicyYear' | translate}}:{{amount.policyYear}}</span>
        <span class="amounts-box-rt">{{amount.amount}}{{hedgeData.currency | metadataTranslate:'currency'}}</span>
      </div>
    </div>
  </div>
</div>
