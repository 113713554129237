<div class="drawer-container">
  <div class="drawer-title" style="margin-bottom: 40px; font-size: 20px;">
    {{'PolicyList' | translate}}
  </div>
<!--  <app-water-mark [xWidth]="60" [yHeight]="50"></app-water-mark>-->
  <div style="position: relative;">
    <button nz-button nzType="default" nzSize="large" nzShape="circle" class="fix-button"
            nz-popover [nzPopoverContent]="filterTemplate"
            [(nzPopoverVisible)]="filterPopoverVisible"
            nzPopoverTrigger="click"
            nzPopoverPlacement="bottomRight">
      <img alt="" width="22" src="assets/images/ic_filter.svg">
    </button>
  </div>
  <nz-empty *ngIf="!info.classifications && !info.items" nzNotFoundImage="simple"></nz-empty>
  <div *ngFor="let list of info.classifications; let i=index" >
    <div class="section-title pointer"
         style="margin: 20px 0px 24px;display: flex;justify-content: space-between;"
         (click)="retract(i)"
    >
      <span style="margin-left: 8px">{{list.name}}</span>
      <i style="color: #096df1;" nz-icon [nzType]="isExpands[i] ? 'down' : 'up'" nzTheme="outline"></i>
    </div>

    <div *ngIf="isExpands[i]">
      <div *ngFor="let item of list.details let j=index" class="card pointer"
           [style]=" styleBorder(item.id) ? 'border: 3px solid #1D64C4' : ''"
           appThrottleClick (throttleClick)="onCommissionDetail(item.id)">
        <div class="card-top">
          <div [ngSwitch]="item.status">
            <span *ngSwitchCase="'PRIMED'" class="text-cancel">●</span>
            <span *ngSwitchCase="'PUBLISH'" class="text-warning">●</span>
            <span *ngSwitchCase="'CONFIRMED'" class="text-processing">●</span>
            <span *ngSwitchCase="'PAID'" class="text-success">●</span>
            <span *ngSwitchCase="'DELETED'" class="text-cancel">●</span>
            <span> {{ item.status | metadataTranslate: 'billStatus' }}</span>
          </div>

          <!--      实发佣金-->
          <div class="cardMoney">HKD {{toDecimal(item.finalCommission | number)}}</div>
        </div>
        <div class="card-middle">
          <!--      保险公司logo-->
          <div>
            <img width="60" style="border-radius: 3px; margin-right: 5px" [src]="item.companyLogo" alt=""/>
          </div>

          <div class="cardMiddle" style="flex: 1">
            <div style="font-size: 16px; font-weight: 500;">{{item.ownerName}}</div>
            <div>{{item.productName}}</div>
            <div><span style="color: #A6A6A6">{{'PolicyNumber' | translate}}：</span>{{item.policyNo}}</div>
            <div><span style="color: #A6A6A6">{{'ReferralFeeType' | translate}}：</span>
              {{item.commissionType | metadataTranslate: 'commissionType'}}
            </div>
            <div *ngIf="workbench.showExchangeRate"><span style="color: #A6A6A6">{{'SettlementRate' | translate}}：</span>{{item.exchangeRate}}</div>
          </div>
        </div>
        <div *ngIf="item.status == 'PAID'" class="card-bottom">
          <span style="color: #A6A6A6">{{'PaymentDate' | translate}}：</span>{{item.paidDate}}
        </div>
      </div>
    </div>

<!--    搜索后的发放日期-->
    <div *ngIf="filterDate && filterData.paidDate" class="total">
      <span style="color: #A6A6A6">{{'PaymentDate' | translate}}：</span>{{filterData.paidDate}}
    </div>
    <!--    统计-->
    <div class="total"><span style="color: #A6A6A6; font-weight: 500">
      {{'Statistics' | translate}}：</span>HKD {{toDecimal(list.subTotal | number)}}
    </div>
  </div>

  <div *ngIf="info.items && info.items.length > 0">
    <div class="section-title" style="margin: 20px 0px 24px"
         style="margin: 20px 0px 24px;display: flex;justify-content: space-between;"
         (click)="retractLast()"
    >
      <span style="margin-left: 8px">{{'CustomNilling' | translate}}</span>
      <i style="color: #096df1;" nz-icon [nzType]="isExpands[isExpands.length - 1] ? 'down' : 'up'" nzTheme="outline"></i>
    </div>
    <div *ngIf="isExpands[isExpands.length - 1]">
      <div *ngFor="let item of info.items; let j=index" class="card"
           [style]=" styleBorder(item.id) ? 'border: 3px solid #1D64C4' : ''"
           appThrottleClick (throttleClick)="onCommissionDetail(item.id)">
        <div class="card-top">
          <div><span>{{'BillName' | translate}}: </span>{{item.billName}}</div>

          <div [ngSwitch]="item.status">
            <span *ngSwitchCase="'PRIMED'" class="text-cancel">●</span>
            <span *ngSwitchCase="'PUBLISH'" class="text-warning">●</span>
            <span *ngSwitchCase="'CONFIRMED'" class="text-processing">●</span>
            <span *ngSwitchCase="'PAID'" class="text-success">●</span>
            <span *ngSwitchCase="'DELETED'" class="text-cancel">●</span>
            <span> {{ item.status | metadataTranslate: 'billStatus' }}</span>
          </div>
        </div>
        <div class="card-middle">
          <div class="cardMiddle" style="flex: 1">
            <div><span style="color: #A6A6A6">{{'PolicyNumber' | translate}}：</span>{{item.policyNo}}</div>
            <div><span style="color: #A6A6A6">{{'BillAmounts' | translate}}：</span>{{toDecimal(item.finalCommission | number)}}</div>
            <div><span style="color: #A6A6A6">{{'Remark' | translate}}：</span>{{item.remark}}</div>
          </div>
        </div>
        <div *ngIf="item.status == 'PAID'" class="card-bottom">
          <span style="color: #A6A6A6">{{'PaymentDate' | translate}}：</span>{{item.paidDate}}
        </div>
      </div>
    </div>
    <!-- 搜索后的发放日期 -->
    <div *ngIf="filterDate && filterData.paidDate" class="total">
      <span style="color: #A6A6A6">{{'PaymentDate' | translate}}：</span>{{filterData.paidDate}}
    </div>
    <!--    统计-->
    <div class="total"><span style="color: #A6A6A6; font-weight: 500">
      {{'Statistics' | translate}}：</span>HKD {{toDecimal(info.itemsAmount | number)}}
    </div>
  </div>

  <!--  按钮-->
  <div *ngIf="info.pendingCount > 0 && !edit" class="commissionListButton">
    <div nz-col nzSpan="24" class="footer-button-primary">
      <a nz-button nzType="link"
         appThrottleClick (throttleClick)="showBtn()"
         nzBlock nzSize="large">
        <span>{{'BatchConfirmationCommission' | translate}}</span>
      </a>
    </div>
  </div>
  <div *ngIf="info.pendingCount > 0 && edit" class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onCancel()">
          <span>{{'Cancel' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link"
           appThrottleClick (throttleClick)="confirm()"
           nzBlock nzSize="large">
          <span>{{'Confirm' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>

<ng-template #filterTemplate>
  <div style="width: 300px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <!-- PaymentDate -->
    <app-property-datetime [title]="'PaymentDate' | translate"
                           [formatter]="'yyyy-MM-dd'"
                           [placeholder]="('PleaseSelect' | translate) + ('PaymentDate' | translate)"
                           [(value)]="filterData.paidDate"></app-property-datetime>
    <!-- 保單號 -->
    <app-property-input [title]="'PolicyNo' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('PolicyNo' | translate)"
                        [(value)]="filterData.policyNo"></app-property-input>
    <div>
      <!-- 保险公司 -->
      <app-property-select [title]="'Insurer' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Insurer' | translate)"
                           [(value)]="filterData.companyCode"
                           [options]="companyOptions"></app-property-select>
      <!-- 状态 -->
      <app-property-select [placeholder]="('PleaseSelect' | translate) + ('Status' | translate)"
                           [title]="'Status' | translate"
                           [options]="statusOptions"
                           [(value)]="filterData.status"></app-property-select>
    </div>
    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onReload()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onSearch()">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
