<div class="drawer-container">
  <div class="common-title">
    {{'Contacts' | translate}}
  </div>
  <nz-row [nzGutter]="12">
    <nz-col [nzSpan]="24">
      <app-property-input [displayMode]="'vertical'"
                          [title]="'ContactName' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('ContactName' | translate)"
                          [(value)]="search.name"
                          (valueChange)="onSearchParamChange()"></app-property-input>
    </nz-col>
  </nz-row>
  <div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
      <div *ngIf="!contacts">
        <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
      </div>
      <div *ngIf="contacts" style="margin-top: 30px; ">
        <nz-table [nzBordered]="false"
                  [nzOuterBordered]="true"
                  [nzShowPagination]="true"
                  [nzLoading]="loading"
                  [nzLoadingIndicator]="indicatorTemplate"
                  [nzFrontPagination]="false"
                  [nzData]="contacts.list"
                  [nzPageIndex]="contacts.pageNum"
                  [nzPageSize]="contacts.pageSize"
                  [nzTotal]="contacts.total"
                  [nzTitle]="tableHeader"
                  [nzFooter]="tableFooter"
                  (nzPageIndexChange)="onSearch($event)">
          <ng-template #tableHeader>
            <nz-row>
              <nz-col [nzSpan]="12">
                <div class="list-header-title">{{'ContactsList' | translate}}
                  <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                     style="margin-left: 5px"
                     appThrottleClick (throttleClick)="onReload()">
                    <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                  </a>
                </div>
              </nz-col>
            </nz-row>
          </ng-template>
          <ng-template #tableFooter>
            <div class="table-title">
              {{'TotalRows' | translate}} : <span
              style="color: #002140; font-weight: bold;">{{ contacts.total }}</span>
            </div>
          </ng-template>

          <thead>
          <tr>
            <th>
              <span nz-tooltip
                    [nzTooltipTitle]="'Name' | translate">{{'Name' | translate}}</span>
            </th>
            <th>
              <span nz-tooltip [nzTooltipTitle]="'Gender' | translate">{{'Gender' | translate}}</span>
            </th>
            <th>
              <span nz-tooltip [nzTooltipTitle]="'Age' | translate">{{'Age' | translate}}</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let contacts of contacts.list; let i = index"
              appThrottleClick (throttleClick)="onContactsSelected(contacts, i)"
          >
            <td>
              <nz-avatar class="list-top-avatar" style="margin-right: 20px;" [nzSize]="36" [nzSrc]="contacts.avatar"></nz-avatar>
              <span style="font-weight: bold; font-size: 12px">
              {{ contacts.name }}
            </span>
            </td>
            <td><span>{{ contacts.gender | metadataTranslate: 'gender' }}</span></td>
            <td><span>{{ contacts.age }} {{'YearsOld' | translate}}</span></td>
<!--            <td>-->
<!--              <div class="functions">-->
<!--                <a nz-button nzType="link" nzSize="small"-->
<!--                   appThrottleClick (throttleClick)="onDetail(contacts.id); $event.stopPropagation();"-->
<!--                   nz-tooltip [nzTooltipTitle]="'Detail' | translate">-->
<!--                  <img alt="" width="20" src="../../../assets/images/ic_details.svg">-->
<!--                </a>-->
<!--              </div>-->
<!--            </td>-->
          </tr>
          </tbody>
        </nz-table>
      </div>
    </nz-spin>
  </div>
</div>
