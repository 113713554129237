<div *ngFor="let box of inputBox">
  <div *ngIf="box.title" style="margin: 5px 0;">{{ box.title | translate}}</div>
  <div
    style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start; align-items: center; padding: 0 0 0 0"
  >
    <span style="flex-shrink: 1;margin-right: 5px">{{box.prefix | translate}}</span>
    <div [ngSwitch]="box.boxType">
      <div *ngSwitchCase="'TEXT'">
        <input (ngModelChange)="inputBoxChange.emit(inputBox)" [(ngModel)]="box.value" [disabled]="disabled"
               [placeholder]="box.placeholder || ''"
               [nzBorderless]="true" nz-input
               style="width: 200px"/>
      </div>
      <div *ngSwitchCase="'NUMBER'">
        <nz-input-number (ngModelChange)="inputBoxChange.emit(inputBox)" [(ngModel)]="box.value" [disabled]="disabled"
                         [nzPlaceHolder]="box.placeholder || ''"
                         style="width: 200px"></nz-input-number>
      </div>
      <div *ngSwitchCase="'MONEY'">
        <nz-input-number (ngModelChange)="inputBoxChange.emit(inputBox)" [(ngModel)]="box.value" [disabled]="disabled"
                         [nzPlaceHolder]="box.placeholder || ''"
                         style="width: 200px"></nz-input-number>
      </div>
    </div>
    <span style="flex-shrink: 1;margin-left: 5px">{{box.suffix | translate}}</span>
  </div>
</div>
