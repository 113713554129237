import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-knowledge-main',
  templateUrl: './knowledge-main.component.html',
  styleUrls: ['./knowledge-main.component.less']
})
export class KnowledgeMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
