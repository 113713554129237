import { Component, OnInit } from '@angular/core';
import { DataDesensitization, DataMode, Frequency, Instance } from '../../../api/types';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { MetadataService } from '../../../shared/service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { Policy } from '../../policy-types';
import { Product } from '../../../product/product-types';
import { AccountService } from '../../../account/account.service';
import { Workbench } from '../../../workbench/workbench-types';
import { WorkbenchService } from '../../../workbench/workbench.service';

@Component({
  selector: 'app-policy-product-plan',
  templateUrl: './policy-product-plan.component.html',
  styleUrls: ['./policy-product-plan.component.less']
})
export class PolicyProductPlanComponent implements OnInit {


  policy: Policy;
  product: Product;
  mode = DataMode.OWN;
  instance: Instance;
  dataDesensitization: DataDesensitization = new DataDesensitization();
  workbench: Workbench;

  currencyOptions: PropertySelectOption[] = [];
  paymentTermOptions: PropertySelectOption[] = [];
  paymentFrequencyOptions: PropertySelectOption[] = [];
  paymentTypeOptions: PropertySelectOption[] = [];
  dividendOptions: PropertySelectOption[] = [];

  constructor(private metadataService: MetadataService,
              private workbenchService: WorkbenchService,
              private accountService: AccountService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    console.log(this.policy);
    console.log(this.product);
    this.workbench = this.workbenchService.workbench;

    this.dataDesensitization = this.accountService.dataDesensitization;


    setTimeout(() => {
        if (this.product) {
          this.productSelected(this.product);
        }
      },
      100);
    this.instance = this.metadataService.mockInstance;
  }

  productSelected(product: Product): void {
    this.product = product;

    this.currencyOptions = [];
    this.product.currencies?.forEach(currency => {
      this.currencyOptions.push(new PropertySelectOption(currency, currency));
    });
    if (!this.policy.productPlan.currency) {
      this.policy.productPlan.currency = this.product.currencies ? this.product.currencies[0] : null;
    }

    this.paymentTermOptions = [];
    this.product.paymentTerm?.forEach(term => {
      this.paymentTermOptions.push(new PropertySelectOption(term, term));
    });
    if (!this.policy.productPlan.productYear) {
      this.policy.productPlan.productYear = this.product.paymentTerm ? this.product.paymentTerm[0] : null;
    }

    this.paymentFrequencyOptions = [];
    this.product.frequencies?.forEach(freuency => {
      this.paymentFrequencyOptions.push(new PropertySelectOption(this.paymentFrequencyTranslate(freuency), freuency));
    });
    if (!this.policy.productPlan.frequency) {
      this.policy.productPlan.frequency = this.product.frequencies ? this.product.frequencies[0] : null;
    }

    this.policy.productCode = product.productCode;
  }

  paymentFrequencyTranslate(frequency: Frequency): string {
    return this.metadataService.translate('frequency', frequency);
  }

  onProductSelection(): void {}

  addSafe(agm): string {
    if (!agm || !agm.length) {
      return 'N/A';
    } else {
      const res = agm.map(item => this.metadataService.translate('supplementaryBenefit', item));
      return res.join('');
    }
  }

  isDynamicEncryption(keyName): boolean {
    let isShow = false;
    if (!this.dataDesensitization || !this.mode) {
      return isShow;
    }
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    }
  }

}
