<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 40px">
    {{'Search' | translate}}
  </div>

  <!-- 状态 -->
  <app-property-select [displayMode]="'vertical'"
                       [title]="'Status' | translate"
                       [placeholder]="('PleaseSelect' | translate) + ('Status' | translate)"
                       [options]="statusOptions"
                       [(value)]="search.paymentStatus"></app-property-select>
  <!-- 保單號 -->
  <app-property-input [displayMode]="'vertical'"
                      [title]="'PolicyNo' | translate"
                      [placeholder]="('PleaseSelect' | translate) + ('PolicyNo' | translate)"
                      [(value)]="search.policyNo"></app-property-input>


  <!-- 销售渠道 -->
  <app-property-input *ngIf="mode == 'FOLLOWUP'" [displayMode]="'vertical'"
                      [title]="'Referee' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('Referee' | translate)"
                      [(value)]="search.accountName"></app-property-input>

  <div *ngIf="mode !== 'OWN' && mode !== 'FOLLOWUP'" class="referee-select">
    <div style="color: #999999;font-size: 14px;">{{'Referee' | translate}}</div>

    <nz-select nzShowSearch nzAllowClear style="width: 100%;"
               [nzBorderless]="true" [nzDropdownClassName]="'referee-select-dropdown'"
               [nzPlaceHolder]="('PleaseSelect' | translate) + ('Referee' | translate)"
               [(ngModel)]="search.accountName"
               [nzFilterOption]="filterOption">
      <nz-option nzCustomContent *ngFor="let option of accountNameOptions"
                 [nzLabel]="option.name" [nzValue]="option.accountName">
        <nz-avatar [nzText]="option.name?.trim().slice(0, 1)"
                   style="background-color: #0078d4" [nzSize]="25"
                   [nzSrc]="option.avatar"></nz-avatar>
        <span style="margin-left: 8px;">{{option.name?.trim()}}</span>
        <span style="color: #999999;font-size: 12px;">({{option.accountName}})</span>
      </nz-option>
    </nz-select>
  </div>

<!--  <app-property-select *ngIf="mode !== 'OWN' && mode !== 'FOLLOWUP'" [displayMode]="'vertical'"-->
<!--                       [title]="'Referee' | translate"-->
<!--                       [placeholder]="('PleaseSelect' | translate) + ('Referee' | translate)"-->
<!--                       [options]="accountNameOptions"-->
<!--                       [supportSearch]="true"-->
<!--                       [(value)]="search.accountName"></app-property-select>-->

  <!-- 产品 -->
  <app-property-pick [displayMode]="'vertical'"
                     [title]="'InsuranceProduct' | translate"
                     (click)="onProductSelection()"
                     [placeholder]="'ProductSelect' | translate"
                     [(value)]="search.product.productName"></app-property-pick>
  <!--保单周年日-->
  <app-property-datetime [displayMode]="'vertical'" [title]="'PremiumsDueDate' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('PremiumsDueDate' | translate)"
                         [formatter]="'yyyy-MM'"
                         [mode]="'month'"
                         [(value)]="search.time"></app-property-datetime>


  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           (click)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" (click)="onSearch()">
          <em nz-icon nzType="search" nzTheme="outline"></em>
          <span>{{'Search' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
