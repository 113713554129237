import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import {
  ApiResponse, DataDesensitization,
  DataMode,
  DialogReply,
  HealthInfoAnswer,
  HeathInfo,
  PagedResp,
  PendingDetailReply,
  PendingReply,
  PendingDetail,
  SignatureList
} from '../api/types';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { BaseService } from '../base/base.service';
import {
  PolicyInfo,
  PolicyListResp,
  PolicyPending,
  PolicyPendingDetail, PolicyPendingReply, PolicyPendingReplyReq,
  PolicyPendingSearch,
  PolicyPendingStatus,
  PolicySearch
} from './policy-types';
import { plainToClass, plainToInstance } from 'class-transformer';
import {
  UnderwritingPending,
  UnderwritingPendingReply, UnderwritingPendingReplyReq,
  UnderwritingPendingSearch,
  UnderwritingPendingStatus
} from '../underwriting/underwriting-types';
import { plainToArray } from '../shared/utils/class-transform';

@Injectable({
  providedIn: 'root'
})
export class PolicyService extends BaseService {

  constructor(private http: HttpService, private httpClient: HttpClient) {
    super();
  }

  list(search: PolicySearch, type: DataMode): Observable<PagedResp<PolicyListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status.toString());
    }
    if (search.policyNo != null) {
      httpParams = httpParams.set('policyNo', search.policyNo.toString());
    }
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    if (search.customCode != null) {
      httpParams = httpParams.set('customCode', search.customCode.toString());
    }
    if (search.newBusinessNumber != null) {
      httpParams = httpParams.set('newBusinessNumber', search.newBusinessNumber.toString());
    }
    if (search.month != null) {
      httpParams = httpParams.set('month', search.month.toString());
    }
    if (search.submissionDateStart != null) {
      httpParams = httpParams.set('submissionDateStart', search.submissionDateStart.toString());
    }
    if (search.submissionDateEnd != null) {
      httpParams = httpParams.set('submissionDateEnd', search.submissionDateEnd.toString());
    }
    if (search.approvalDateStart != null) {
      httpParams = httpParams.set('approvalDateStart', search.approvalDateStart.toString());
    }
    if (search.approvalDateEnd != null) {
      httpParams = httpParams.set('approvalDateEnd', search.approvalDateEnd.toString());
    }
    if (search.coolingOffDateStart != null) {
      httpParams = httpParams.set('coolingOffDateStart', search.coolingOffDateStart.toString());
    }
    if (search.coolingOffDateEnd != null) {
      httpParams = httpParams.set('coolingOffDateEnd', search.coolingOffDateEnd.toString());
    }
    if (search.accountName != null) {
      httpParams = httpParams.set('accountName', search.accountName.toString());
    }
    if (search.productCode != null) {
      httpParams = httpParams.set('productCode', search.productCode.toString());
    }
    if (search.autoTransfer != null) {
      httpParams = httpParams.set('autoTransfer', search.autoTransfer.toString());
    }
    if (search.companyCode != null) {
      httpParams = httpParams.set('companyCode', search.companyCode.toString());
    }
    if (search.categoryCode != null) {
      httpParams = httpParams.set('categoryCode', search.categoryCode.toString());
    }
    if (search.policyDateMonth != null) {
      httpParams = httpParams.set('policyDateMonth', search.policyDateMonth.toString());
    }
    let uri = '';
    switch (type) {
      case DataMode.OWN:
        uri = '/list';
        break;
      case DataMode.SUBORDINATE:
        uri = '/list-subordinate';
        break;
      case DataMode.FOLLOWUP:
        uri = '/list-follow-up';
        break;
      case DataMode.SALESGROUP:
        uri = '/list-sales-group';
        break;
    }
    return this.http.get<ApiResponse<PagedResp<PolicyListResp>>>(API.policyV3 + uri, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  dialogList(policyId: string): Observable<PendingReply[]> {
    return this.http.get<ApiResponse<PendingReply[]>>(API.policyV3 + '/dialog-list/' + policyId, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(PendingReply, data.body.data);
    }));
  }

  dialogReply(dialogRep: DialogReply): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.policyV3 + '/dialog-save', dialogRep, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 详情
   * @param policyId policyId
   */
  detail(policyId: string): Observable<PolicyInfo> {
    return this.http.get<ApiResponse<PolicyInfo>>(API.policyV3 + '/info/' + policyId, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(PolicyInfo, data.body.data);
    }));
  }

  /**
   * 健康问卷
   * @param policyId 保单号
   */
  question(policyId: string): Observable<HeathInfo> {
    let httpParams = new HttpParams();
    if (policyId != null) {
      httpParams = httpParams.set('policyId', policyId);
    }
    // @ts-ignore
    return this.http.get<ApiResponse<HeathInfo>>(API.policyV3 + '/question', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(HeathInfo, data.body.data);
    }));
  }

  /**
   * 保存健康问卷答案
   * @param params  params
   */
  underwritingAnswer(params: HealthInfoAnswer): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.policyV3 + '/answer', params, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  downloadPdf(policyNo: string, formId: string): Observable<Uint8Array> {
    let httpParams = new HttpParams();
    if (formId != null) {
      httpParams = httpParams.set('formId', formId);
    }
    return this.httpClient
      .get(API.policyV3 + '/download-form/' + policyNo, {
        params: httpParams,
        responseType: 'arraybuffer'
      }).pipe(map((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer)));
  }

  pendingDetailReply(params: PendingDetailReply): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.policyV3 + '/service/reply', params, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  serviceDetail(id: number): Observable<PendingDetail> {
    return this.http.get<ApiResponse<PendingDetail>>(API.policyV3 + '/service/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(PendingDetail, data.body.data);
    }));
  }

  serviceReply(id: number): Observable<PendingReply[]> {
    return this.http.get<ApiResponse<PendingReply[]>>(API.policyV3 + '/service/reply/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(PendingReply, data.body.data);
    }));
  }


  fromList(policyId: string, applicationType: string): Observable<SignatureList[]> {
    let httpParams = new HttpParams();
    if (policyId != null) {
      httpParams = httpParams.set('policyId', policyId);
    }
    if (applicationType != null) {
      httpParams = httpParams.set('applicationType', applicationType);
    }
    return this.http.get<ApiResponse<SignatureList[]>>(API.policyV3 + '/signature/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(SignatureList, data.body.data);
    }));
  }

  /**
   * 脱敏字典
   */
  desensitization(): Observable<DataDesensitization> {
    return this.http.get<ApiResponse<DataDesensitization>>(API.systemV3 + '/data-desensitization', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 查询Pending状态列表
   */
  pendingStatusList(): Observable<PolicyPendingStatus[]> {
    return this.http.get<ApiResponse<PolicyPendingStatus[]>>(API.policyV3 + '/service-status-list', {
      observe: 'response'
    }).pipe(map(data => {
      return plainToArray(PolicyPendingStatus, data.body.data);
    }));
  }

  /**
   * 查询保单Service列表
   * @param search 保单Service列表
   */
  pendingList(search: PolicyPendingSearch): Observable<PolicyPending[]> {
    let httpParams = new HttpParams();
    if (search.policyId != null) {
      httpParams = httpParams.set('policyId', search.policyId);
    }
    if (search.serviceNo != null) {
      httpParams = httpParams.set('serviceNo', search.serviceNo);
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status);
    }
    if (search.project != null) {
      httpParams = httpParams.set('project', search.project);
    }
    if (search.deliveryDate != null) {
      httpParams = httpParams.set('deliveryDate', search.deliveryDate);
    }
    if (search.deadlinesDate != null) {
      httpParams = httpParams.set('deadlinesDate', search.deadlinesDate);
    }
    return this.http.get<ApiResponse<PolicyPending[]>>(API.policyV3 + '/service-list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToArray(PolicyPending, data.body.data);
    }));
  }


  /**
   * 查询新单Pending详情
   * @param id 新单Pending的ID
   */
  pendingDetail(id: number): Observable<PolicyPendingDetail> {
    return this.http.get<ApiResponse<PolicyPendingDetail>>(API.policyV3 + '/service/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(PolicyPendingDetail, data.body.data);
    }));
  }


  /**
   * 获取Pending回复列表
   * @param id Pending的ID
   */
  pendingReplies(id: number): Observable<PolicyPendingReply[]> {
    return this.http.get<ApiResponse<PolicyPendingReply[]>>(API.policyV3 + '/service/reply/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(PolicyPendingReply, data.body.data);
    }));
  }

  /**
   * 新单回复
   * @param replyReq 回复请求数据
   */
  pendingDetailReplyV2(replyReq: PolicyPendingReplyReq): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.policyV3 + '/service/reply', replyReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * cash表单
   * @param bookingNo 查询条件
   * @param cashFormEnum 数据类型
   */

  cashQuestion(bookingNo: string, cashFormEnum: string): Observable<any> {
    let httpParams = new HttpParams();
    if (bookingNo !== null) {
      httpParams = httpParams.set('bookingNo', bookingNo.toString());
    }
    if (cashFormEnum !== null) {
      httpParams = httpParams.set('cashFormEnum', cashFormEnum.toString());
    }
    return this.httpClient.get<ApiResponse<any>>(API.policyV3 + '/cash/question', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 保单留言确认
   * @param param 参数
   */
  putDialogConfirm(param): Observable<boolean> {
    let httpParams = new HttpParams();
    if (param.id != null) {
      httpParams = httpParams.set('id', param.id.toString());
    }
    if (param.confirm != null) {
      httpParams = httpParams.set('confirm', param.confirm.toString());
    }
    return this.http.put<ApiResponse<boolean>>(API.policyV3 + '/dialog-confirm', null, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
