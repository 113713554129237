import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { DataService } from '../shared/service/data.service';
import { WorkbenchService } from '../workbench/workbench.service';
import { Workbench } from '../workbench/workbench-types';

@Component({
  selector: 'app-proposal-main',
  templateUrl: './proposal-main.component.html',
  styleUrls: ['./proposal-main.component.less']
})
export class ProposalMainComponent implements OnInit {

  workbench: Workbench;

  constructor(public dataService: DataService,
              private authService: AuthService,
              private workbenchService: WorkbenchService,
              private toastr: ToastrService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
  }

}
