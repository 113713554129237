import {ChangeDetectionStrategy, Component, OnInit, OnDestroy} from '@angular/core';
import {PagedResp} from '../../api/types';
import {InformationData, InformationList, InformationSearch} from '../share-type';
import {ShareService} from '../share.service';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {NzMessageService} from 'ng-zorro-antd/message';
import {HttpClient} from '@angular/common/http';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {MetadataService} from '../../shared/service/metadata.service';
import {ListRange} from '@angular/cdk/collections/collection-viewer';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {ShareDetailComponent} from '../share-detail/share-detail.component';

@Component({
  selector: 'app-share-list',
  templateUrl: './share-list.component.html',
  styleUrls: ['./share-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush

})

export class ShareListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  filterPopoverVisible = false;

  shareList: PagedResp<InformationList>;
  forwardList: InformationList[];
  loading = false;
  regionOptions: PropertySelectOption[] = [];
  total: number;
  dataSource = new MyDataSource(this.shareService);

  constructor(private shareService: ShareService,
              private nzMessage: NzMessageService,
              private http: HttpClient,
              private metadataService: MetadataService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
    const regions = this.metadataService.values('shareSources');
    for (const region of regions) {
      this.regionOptions.push(new PropertySelectOption(region.value, region.key));
    }
    this.dataSource
      .completed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
      });

    this.onForward();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onForward(): void {
    this.shareService.forwardList().subscribe(
      data => {
        this.forwardList = data;
      },
      error => {
      });
  }

  onSearch(pageNum: number): void {
    this.dataSource.search.pageNum = 1;
    this.dataSource.reset();
  }

  onReload(): void {
    this.dataSource.search = new InformationSearch();
    this.dataSource.reset();
  }

  onDetail(item: InformationList): void {
    this.shareService.detail(item.shareCode).subscribe(
      shareDetail => {
        const drawerRef = this.drawerService.create<ShareDetailComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: ShareDetailComponent,
          nzContentParams: {
            shareDetail
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
        });

        drawerRef.afterClose.subscribe(data => {
          const component = drawerRef.getContentComponent();
        });
      }
    );
  }
}

// 滾動列表
class MyDataSource extends DataSource<InformationList | undefined> {
  private total: number;
  private fetchedPages = new Set<number>();
  private cachedData: InformationList[] = [];
  private dataStream = new BehaviorSubject<InformationList[]>(this.cachedData);
  private complete$ = new Subject<void>();
  private disconnect$ = new Subject<void>();
  spinning = false;

  search: InformationSearch = new InformationSearch();

  collectionViewer: CollectionViewer;

  constructor(private shareService: ShareService) {
    super();
  }

  completed(): Observable<void> {
    return this.complete$.asObservable();
  }

  reset(): void {
    this.fetchedPages = new Set<number>();
    this.cachedData = [];
    this.fetchPage();
  }

  connect(collectionViewer: CollectionViewer): Observable<(InformationList | undefined)[]> {
    this.setup(collectionViewer);
    return this.dataStream;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.disconnect$.next();
    this.disconnect$.complete();
  }

  setup(collectionViewer: CollectionViewer): void {
    this.collectionViewer = collectionViewer;
    this.fetchPage();
    collectionViewer.viewChange.pipe(takeUntil(this.complete$), takeUntil(this.disconnect$)).subscribe(range => {
      if (this.cachedData.length >= this.total) {
        this.complete$.next();
        this.complete$.complete();
      } else {
        this.search.pageNum = this.getPageForIndex(range.end) + 1;
        this.fetchPage();
      }
    });
  }

  getPageForIndex(index: number): number {
    return Math.floor(index / this.search.pageSize);
  }

  getLength(): number {
    return this.total;
  }

  private fetchPage(): void {
    if (this.fetchedPages.has(this.search.pageNum)) {
      return;
    }
    this.spinning = true;
    this.fetchedPages.add(this.search.pageNum);
    this.shareService.list(this.search).subscribe(
      res => {
        this.total = res.total;
        this.cachedData.push(...res.list);
        this.dataStream.next(this.cachedData);
        this.spinning = false;
      }
    );
  }
}
