import { Component, OnInit } from '@angular/core';
import {DataService} from '../shared/service/data.service';

@Component({
  selector: 'app-document-main',
  templateUrl: './document-main.component.html',
  styleUrls: ['./document-main.component.less']
})
export class DocumentMainComponent implements OnInit {


  constructor(public dataService: DataService) { }

  ngOnInit(): void {
  }

}
