import { Injectable } from '@angular/core';
import {BaseService} from '../base/base.service';
import {HttpService} from '../core/http.service';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResponse, PagedReq, PagedResp} from '../api/types';
import {API} from '../api/api';
import {map} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {LeaveWordDetailsRes, LeaveWordListRes} from './leave-word-types';

@Injectable({
  providedIn: 'root'
})
export class LeaveWordService extends BaseService{

  constructor(private http: HttpService) {
    super();
  }

  /**
   * 获取列表
   * @param search 翻页
   */
  leaveWordList(search: PagedReq): Observable<PagedResp<LeaveWordListRes>> {
    let httpParams = new HttpParams();
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    return this.http.get<ApiResponse<PagedResp<LeaveWordListRes>>>(API.leaveWordV3 + '/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 获取详情
   * @param id 主键
   */
  detailsLeaveWord(id: number): Observable<LeaveWordDetailsRes> {
    return this.http.get<ApiResponse<LeaveWordDetailsRes>>(API.leaveWordV3 + '/info/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 删除留言
   * @param id 主键
   */
  deleteLeaveWord(id: number): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.leaveWordV3 + '/delete/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
