import {Component, EventEmitter, OnInit} from '@angular/core';
import {ContactPerson, PactDetails} from '../../sales-pact-types';
import {Material, UploadTokenReq, UploadType} from '../../../api/types';
import {DatePipe} from '@angular/common';
import {plainToInstance} from 'class-transformer';
import {SalesPactService} from '../../sales-pact.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-certificate-upload',
  templateUrl: './certificate-upload.component.html',
  styleUrls: ['./certificate-upload.component.less'],
  providers: [DatePipe]
})
export class CertificateUploadComponent implements OnInit {
  info: PactDetails = new PactDetails();
  id: number; // 合同id
  passportArr: Material[] = []; // 护照信息
  addressArr: Material[] = []; // 地址证明信息
  afterRecord: EventEmitter<any> = new EventEmitter<any>();
  loading = false;

  uploadTokenReq = new UploadTokenReq();

  constructor(private datePipe: DatePipe,
              private salesPactService: SalesPactService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    if (this.isBlank(this.info.passport)) {
      this.passportArr.push(this.info.passport);
    }
    if (this.isBlank(this.info.address)) {
      this.addressArr.push(this.info.address);
    }
    this.uploadTokenReq.uploadType = UploadType.ACCOUNT;
    this.uploadTokenReq.middleName = `${this.id}-${this.datePipe.transform(new Date(), 'yyyy-MM-dd')}`;

  }


  onSave(): void {
    const param = {
      id: this.id,
      identityCard: this.info.identityCard ? this.info.identityCard : [],
      passport: this.isNull(this.info.passport),
      address: this.isNull(this.info.address)
    };
    this.loading = true;
    this.salesPactService.updateInfo(param)
      .subscribe(
        data => {
          // if (data) {
          //   this.translate.get('UploadSuccess').subscribe(
          //     (res) => {
          //       this.toastr.success(res);
          //     }
          //   );
          // }
          this.loading = false;
          this.afterRecord.emit('');
        },
        error => {
          this.loading = false;
        }
      );
  }

  // 身份证信息
  identityCardsChange(attachments: Material[]): void {
    if (attachments.length > 2) {
      attachments.shift();
    }
    this.info.identityCard = attachments;
  }

  // 护照
  passportArrChange(attachments: Material[]): void {
    if (attachments.length > 1) {
      attachments.shift();
    }
    this.info.passport = attachments[0];
  }

  // 地址
  addressArrChange(attachments: Material[]): void {
    if (attachments.length > 1) {
      attachments.shift();
    }
    this.info.address = attachments[0];
  }

  isBlank(val): boolean {
    console.log(val);
    if (!val) {
      return false;
    }
    if (Object.keys(val).length > 0) {
      if (val.filePath) {
        return true;
      } else {
        return false;
      }
      return true;
    }
    return false;
  }

  isNull(val): {} {
    if (!val) {
      return val = {};
    }
    if (Object.keys(val).length > 0) {
      if (!val.filePath) {
        return val = {};
      }
    }
    return val;
  }
}
