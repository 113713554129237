<div class="drawer-container">

  <div class="drawer-title-small" style="margin-bottom: 30px">
    {{'CompanyDetail' | translate}}
  </div>

  <div class="centre-column">
    <div>
      <img width="80" [src]="company.logo" alt=""/>
    </div>
    <div style="color: #222222; font-size: 18px">{{company.name}} · {{company.code}}</div>
  </div>

  <nz-tabset [nzSize]="'large'" [nzCentered]="true">
    <nz-tab [nzTitle]="desc">
      <div style="color: #777777">
        {{company.desc}}
      </div>
    </nz-tab>
    <ng-template #desc>
      <div>{{'CompanyDesc' | translate}}</div>
    </ng-template>
    <nz-tab [nzTitle]="links">
      <div *ngIf="company.links">
        <div *ngFor="let link of company.links">
          <app-link [link]="link"></app-link>
        </div>
      </div>
    </nz-tab>
    <ng-template #links>
      {{'Links' | translate}}
    </ng-template>
    <nz-tab [nzTitle]="creditRating">
      <div>
        <div *ngFor="let creditRating of company.creditRatings; let i = index">
          <app-credit-rating [creditRating]="creditRating"></app-credit-rating>
        </div>
      </div>
    </nz-tab>
    <ng-template #creditRating>
      {{'CreditRatings' | translate}}
    </ng-template>
    <nz-tab [nzTitle]=officialDocs>
      <app-attachments [attachments]="company.files" [readonly]="true"></app-attachments>
    </nz-tab>
    <ng-template #officialDocs>
      {{'OfficialDocs' | translate}}
    </ng-template>
    <nz-tab [nzTitle]=internalDocs>
      <app-attachments [attachments]="company.customFiles" [readonly]="true"></app-attachments>
    </nz-tab>
    <ng-template #internalDocs>
      {{'InternalDocs' | translate}}
    </ng-template>
  </nz-tabset>
</div>
