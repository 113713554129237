import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { ProductService } from '../product.service';
import { Product, ProductAttributeGroupVO, ProductAttributeValues, ProductCompareReq, ProductCompareRes } from '../product-types';
import { plainToInstance } from 'class-transformer';

@Component({
  selector: 'app-product-comparison-result',
  templateUrl: './product-comparison-result.component.html',
  styleUrls: ['./product-comparison-result.component.less']
})
export class ProductComparisonResultComponent extends BaseComponent implements OnInit {

  primaryProduct: Product;

  secondaryProducts: Product[] = [];

  loading = false;

  comparisonResult: ProductCompareRes;

  sectionGroup: ProductAttributeGroupVO[] = [];

  tableScroll: { x: string; y: string} = {x: '1024px', y: '85vh'};
  @Input()
  isPremiumComparison: boolean;
  @Input()
  compareParams: ProductCompareReq;

  constructor(private productService: ProductService) {
    super();
  }

  ngOnInit(): void {
    let req = new ProductCompareReq();
    if (this.isPremiumComparison) {
      req = JSON.parse(JSON.stringify(this.compareParams));
    } else {
      req.leftProductCode = this.primaryProduct.productCode;
      req.rightProductCodes = this.secondaryProducts.map(value => value.productCode);
      console.log(this.primaryProduct);
      console.log(this.secondaryProducts);
    }
    this.loading = true;
    this.productService.compare(req)
      .subscribe(
        data => {
          this.loading = false;
          this.comparisonResult = data;
          this.dealResult(data);
        },
        () => {
          this.loading = false;
        }).disposedBy(this.disposeBag);

    this.tableScroll.x = `${Math.max(1024, this.secondaryProducts.length * 200)}px`;
    this.tableScroll.y = `85vh`;

    console.log(this.tableScroll);
  }

  dealResult(data: ProductCompareRes): void {
    this.sectionGroup = [];
    const detailGroups = data.left.details;
    const details = [data.left, ...data.rights];
    detailGroups.forEach((detailGroup, i) => {
      const group = plainToInstance(ProductAttributeGroupVO, detailGroup);
      group.attributes = [];
      group.products = [];
      group.companyNames = [];
      detailGroup.attributes.forEach((attribute, j) => {
        const attr = plainToInstance(ProductAttributeValues, attribute);
        attr.values = [];
        details.forEach((detail) => {
          const value = detail.details[i].attributes[j];
          attr.values.push(value);
        });
        group.attributes.push(attr);
      });

      details.forEach((detail) => {
        group.products.push(detail.productName);
        group.companyNames.push(detail.companyName);
      });
      this.sectionGroup.push(group);
    });
    console.log(this.sectionGroup);
  }

}
