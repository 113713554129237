<div [class]="disabled ? 'property-disable' : ''">
  <div class="property-title-row" *ngIf="title" style="margin: 15px 0 5px 0">
    <span>{{ title }}</span>
    <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
  </div>

  <!--  <div class="property-value-row">-->
  <div style="margin: 0 0 10px 0">
    <quill-editor [placeholder]="placeholder"
                  [theme]="'snow'"
                  [disabled]="disabled"
                  [(ngModel)]="value"
                  [modules]="modules"
                  [styles]="{height: '250px'}"></quill-editor>
    <!--    <p-editor [placeholder]="placeholder" [disabled]="disabled" [(ngModel)]="value"-->
<!--              [style]="{'height':'320px'}">-->
<!--      <ng-template pTemplate="header">-->
<!--        <span class="ql-formats">-->
<!--          <button type="button" class="ql-bold"></button>-->
<!--          <button type="button" class="ql-italic"></button>-->
<!--          <button type="button" class="ql-underline"></button>-->
<!--          <button type="button" class="ql-strike"></button>-->
<!--        </span>-->
<!--        <span class="ql-formats">-->
<!--          <button type="button" class="ql-list" value="ordered"></button>-->
<!--          <button type="button" class="ql-list" value="bullet"></button>-->
<!--          <select class="ql-align">-->
<!--            <option selected="selected"></option>-->
<!--            <option value="center"></option>-->
<!--            <option value="right"></option>-->
<!--            <option value="justify"></option>-->
<!--          </select>-->
<!--        </span>-->
<!--        <span class="ql-formats">-->
<!--          <button type="button" class="ql-link"></button>-->
<!--        </span>-->
<!--      </ng-template>-->
<!--    </p-editor>-->
  </div>
  <!--  </div>-->
</div>
<div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
<div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
