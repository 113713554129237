<div class="drawer-container" [style]="isEdit ? 'padding-bottom: 130px' : ''">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'ComparisonDetails' | translate}}
    </div>
  </nz-spin>
  <div class="title" style="color: #FFFFFF">
    <div [ngSwitch]="proposalDetail.status">
      <div *ngSwitchCase="'COMPLETED'">
        <img  alt="" width="16" src="assets/images/ic_finish.svg">
        <span>{{ 'ProposalCompareCompleted' | translate }}</span>
      </div>
      <div *ngSwitchCase="'FAIL'">
        <img alt="" width="16" src="assets/images/ic_error.svg">
       <span> {{ 'ProposalCompareFail' | translate }}</span>

      </div>
      <div *ngSwitchCase="'PENDING'">
        <img alt="" width="16" src="assets/images/ic_pending.svg">
        <span>{{ 'ProposalComparePending' | translate }}</span>
      </div>
    </div>
    <div [ngSwitch]="proposalDetail.status" style="cursor: pointer;">
      <span *ngSwitchCase="'COMPLETED'" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
        <span>{{'ViewShare' | translate}}</span> <i nz-icon style="vertical-align: middle;font-size: 19px" nzType="right" nzTheme="outline"></i>
      </span>
      <nz-dropdown-menu #menu="nzDropdownMenu" >
        <ul nz-menu nzSelectable>
          <li nz-menu-item appThrottleClick (throttleClick)="onDownload('CN')">{{ 'CN' | translate}}</li>
          <li nz-menu-item appThrottleClick (throttleClick)="onDownload('HK')">{{ 'HK' | translate}}</li>
          <li nz-menu-item appThrottleClick (throttleClick)="onDownload('EN')">{{ 'EN' | translate}}</li>
        </ul>
      </nz-dropdown-menu>
      <span *ngSwitchCase="'FAIL'" appThrottleClick (throttleClick)="onRetry()" )>
        {{'Retry' | translate}}
      </span>
    </div>
  </div>
  <div *ngFor="let group of copyGroups;let i = index">
    <div class="section-title" style="margin-bottom: 16px;margin-top: 22px;padding-left: 5px">
      <div *ngIf="isEdit">
        <label nz-checkbox [(ngModel)]="group.checked" [nzId]="group.key"> {{ group.name}}</label>
      </div>
      <div *ngIf="!isEdit">
        {{ group.name}}
      </div>
    </div>
    <table class="table sa-table">
      <thead>
      <tr>
        <th style="width: 150px">{{ 'Insurer' | translate }}</th>
        <th *ngFor="let companyName of group.companyNames">{{ companyName }}</th>
      </tr>
      <tr>
        <th style="width: 150px">{{ 'ProductName' | translate }}</th>
        <th *ngFor="let productName of group.products">{{ productName }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let attribute of group.attributes;let j = index">
        <td *ngIf="!isEdit" [class]="uiTheme.classes.propertyKey">{{ attribute.name }}</td>
        <td *ngIf="isEdit" [class]="uiTheme.classes.propertyKey">
          <label nz-checkbox [(ngModel)]="attribute.checked"
                 [nzId]="attribute.attribute"> {{ attribute.name }}</label>
        </td>
        <td *ngFor="let value of attribute.values;let index = index" [class]="uiTheme.classes.propertyValue" nz-button
            appThrottleClick
            (throttleClick)="onScoreDetail(index,group,value); $event.stopPropagation();">
          <div class="value">{{ value.value }}</div>
          <div *ngIf="value.score">
            <nz-rate style="font-size: 15px" [nzAllowHalf]="false" [ngModel]="Number(value.score)" nzDisabled></nz-rate>
          </div>

        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="footer" [style]="isEdit ? 'flex-direction: column;height: 120px' : ''">
    <div nz-row style="width: 100%; height: 100%;" *ngIf="!isEdit">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="Edit()">
          <span>{{'Edit' | translate}}</span>
        </a>
      </div>

    </div>
    <div *ngIf="isEdit" style="width: 100%;padding: 10px;">
      <!--公司及產品名稱加星號-->
      <app-property-switch  [title]="'MaskInsurerAsterisk' | translate"
                            [(value)]="proposalDetail.asterisk"></app-property-switch>
    </div>
    <div nz-row style="width: 100%; height: 100%;" *ngIf="isEdit">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onCancel()">
          <span>{{'Cancel' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
  <nz-modal [(nzVisible)]="scoreVisible"
            [nzTitle]="modalTitle"
            [nzContent]="modalContent"
            [nzFooter]="modalFooter"
            (nzOnCancel)="handleCancel()">
    <ng-template #modalTitle>
      <img width="22" [src]="attributeTitle.logo">
      {{attributeTitle.name}}
    </ng-template>

    <ng-template #modalContent>
      <div style="font-size: 14px;color:#CCCCCC">  {{'CompareScore' | translate}}</div>
      <div style="font-size: 14px">
        {{ attributeScore.name}}
      </div>
      <div>
        <nz-rate [nzAllowHalf]="false" [ngModel]="Number(attributeScore.score)"
                 (ngModelChange)="onScore($event)"></nz-rate>
      </div>
    </ng-template>

    <ng-template #modalFooter>
      <button nz-button nzType="default" (click)="handleCancel()">{{'Cancel' | translate}}</button>
      <button nz-button nzType="primary" (click)="handleOk()"
              [nzLoading]="isConfirmLoading">{{'Confirm' | translate}} </button>
    </ng-template>
  </nz-modal>

</div>
