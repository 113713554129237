import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmlComponent } from './aml.component';
import { AmlMainComponent } from './aml-main.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AmlSearchListComponent } from './aml-search/aml-search-list/aml-search-list.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { AmlSearchDetailComponent } from './aml-search/aml-search-detail/aml-search-detail.component';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { FormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AmlInspectComponent } from './aml-search/aml-search-inspect/aml-inspect.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzListModule } from 'ng-zorro-antd/list';
import { AmlMonitorComponent } from './aml-monitor/aml-monitor.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { AmlMonitorEntryComponent } from './aml-monitor/aml-monitor-entry/aml-monitor-entry.component';
import { AmlMonitorOpenAlertsComponent } from './aml-monitor/aml-monitor-open-alerts/aml-monitor-open-alerts.component';
import { AmlMonitorSummaryComponent } from './aml-monitor/aml-monitor-summary/aml-monitor-summary.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { AmlMonitorEntryDetailComponent } from './aml-monitor/aml-monitor-entry-detail/aml-monitor-entry-detail.component';
import { AmlMonitorEntryInspectComponent } from './aml-monitor/aml-monitor-entry-inspect/aml-monitor-entry-inspect.component';
import { AmlMonitorSummaryDetailComponent } from './aml-monitor/aml-monitor-summary-detail/aml-monitor-summary-detail.component';

@NgModule({
  declarations: [
    AmlComponent,
    AmlMainComponent,
    AmlSearchListComponent,
    AmlSearchDetailComponent,
    AmlInspectComponent,
    AmlMonitorComponent,
    AmlMonitorEntryComponent,
    AmlMonitorOpenAlertsComponent,
    AmlMonitorSummaryComponent,
    AmlMonitorEntryDetailComponent,
    AmlMonitorEntryInspectComponent,
    AmlMonitorSummaryDetailComponent

  ],
    imports: [
        CommonModule,
        NzLayoutModule,
        SharedModule,
        TranslateModule,
        RouterModule,
        NzSpinModule,
        NzIconModule,
        NzSkeletonModule,
        NzTableModule,
        NzGridModule,
        NzButtonModule,
        NzToolTipModule,
        NzProgressModule,
        FormsModule,
        PdfViewerModule,
        NzPopoverModule,
        NzListModule,
        NzTabsModule,
        NzDropDownModule
    ]
})
export class AmlModule {

}
