import {AfterViewInit, Component, EventEmitter, Input, OnInit, TemplateRef} from '@angular/core';
import {
  DataDesensitization,
  DataMode,
  Message,
  MessageListResp,
  MessageSearch,
  MessageType,
  PagedResp,
  TicketDetail,
  Underwriting
} from '../../api/types';
import {MetadataService} from '../../shared/service/metadata.service';
import {MessageService} from '../message.service';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {ProposalService} from '../../proposal/proposal.service';
import {ProposalDetailComponent} from '../../proposal/proposal-detail/proposal-detail.component';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import {BookingDetailComponent} from '../../booking/booking-detail/booking-detail.component';
import {BookingService} from '../../booking/booking.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {InformationDetailComponent} from '../../information/information-detail/information-detail.component';
import {InformationService} from '../../information/information.service';
import {Booking} from '../../booking/booking-types';
import {UnderwritingDetailComponent} from '../../underwriting/underwriting-detail/underwriting-detail.component';
import {UnderwritingService} from '../../underwriting/underwriting.service';
import {PolicyDetailComponent} from '../../policy/policy-detail/policy-detail.component';
import {Policy} from '../../policy/policy-types';
import {PolicyService} from '../../policy/policy.service';
import {TicketService} from '../../ticket/ticket.service';
import { TicketDetailComponent } from 'src/app/ticket/ticket-detail/ticket-detail.component';
import { KnowledgeDetailComponent } from '../../knowledge/knowledge-detail/knowledge-detail.component';
import { KnowledgeService } from '../../knowledge/knowledge.service';
import {PolicyReminderService} from '../../policy-reminder/policy-reminder.service';
import {CommissionService} from '../../commission/commission.service';
import {CommissionListComponent} from '../../commission/commission-list/commission-list.component';
import {ShareService} from '../../share/share.service';
import {ShareDetailComponent} from '../../share/share-detail/share-detail.component';
import {LeaveWordDetailComponent} from '../../leave-word/leave-word-detail/leave-word-detail.component';
import {LeaveWordService} from '../../leave-word/leave-word.service';
import {ContactInfoComponent} from '../../contact/contact-info/contact-info.component';
import {ContactsType} from '../../contact/contact-types';
import {ContactService} from '../../contact/contact.service';
import {CommissionDetailComponent} from '../../commission/commission-detail/commission-detail.component';
import {InfoList} from '../../commission/commission-types';
import { AccountService } from '../../account/account.service';
import { InviteInfoRes, InviteListRes } from '../../invitation/invitation-types';
import { formatDate } from '@angular/common';
import { plainToInstance } from 'class-transformer';
import { InvitationCreateComponent } from '../../invitation/invitation-create/invitation-create.component';
import { InvitationService } from '../../invitation/invitation.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.less']
})
export class MessagesComponent implements OnInit, AfterViewInit {

  @Input() messageOverview: MessageListResp;

  loading = false;
  spinning = false;
  deleting = false;

  afterRecord: EventEmitter<any> = new EventEmitter<any>();

  search: MessageSearch = new MessageSearch();
  messages: PagedResp<Message>;
  dataDesensitization: DataDesensitization = new DataDesensitization();
  constructor(private messageService: MessageService,
              private proposalService: ProposalService,
              private bookingService: BookingService,
              private informationService: InformationService,
              private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private modalService: NzModalService,
              private translate: TranslateService,
              private underwritingService: UnderwritingService,
              private ticketService: TicketService,
              private policyService: PolicyService,
              private knowledgeService: KnowledgeService,
              private router: Router,
              private toastr: ToastrService,
              private policyReminderService: PolicyReminderService,
              private commissionService: CommissionService,
              private shareService: ShareService,
              private leaveWordService: LeaveWordService,
              private contactService: ContactService,
              private accountService: AccountService,
              private invitationService: InvitationService,
              private messagesDrawerRef: NzDrawerRef<string>,
  ) {
  }

  ngOnInit(): void {
    this.search.type = this.messageOverview.type;
    this.onSearch(this.search.pageNum);
    this.dataDesensitization = this.accountService.dataDesensitization;
  }

  ngAfterViewInit(): void {
  }

  messageTypeTranslate(messageType: MessageType): string {
    return this.metadataService.translate('messageType', messageType);
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.messageService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.messages = data;
        },
        error => {
          this.loading = false;
        });
  }

  onAllReads(): void {
    const param = {
      type: this.messages.list[0].type
    };
    this.messageService.allMessageReads(param)
      .subscribe(data => {
        this.onReload();
        if (data) {
          this.messageService.messageUnreadEvent.emit('');
        }
      }, () => {});
  }

  onDelReads(tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>): void {
    const param = {
      type: this.messages.list[0].type
    };
    this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzClosable: false,
      nzOnOk: () => {
        this.loading = true;
        this.messageService.deleteAllMessage(param)
          .subscribe(
            data => {
              this.onReload();
            },
            () => {}
          );
      }
    });
  }

  onReload(): void {
    this.search.pageNum = 1;
    this.loading = true;
    this.messageService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.messages = data;
        },
        error => {
          this.loading = false;
        });
  }

  onCurrentReload(): void {
    this.loading = true;
    this.messageService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.messages = data;
        },
        error => {
          this.loading = false;
        });
  }

  onDelete(message: Message, tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: message,
      nzOnOk: () => {
      }
    });

  }

  onDeleteConfirm(ref: NzModalRef, message: Message): void {
    this.deleting = true;
    this.messageService.overtime(message.id)
      .subscribe(
        data => {
          this.deleting = false;
          this.onSearch(this.search.pageNum);
          ref.close();
        },
        error => {
          this.deleting = false;
          ref.close();
        });

  }

  onMessageDetail(message: Message): void {
    console.log(message);
    this.updateMessageReads(message.id);
    switch (message.type) {
      case MessageType.PROPOSAL:
        this.onProposalDetail(message.key);
        break;
      case MessageType.BOOKING:
        this.onBookingDetail(message.key);
        break;
      case MessageType.INFORMATION:
        this.onInformationDetail(Number(message.key));
        break;
      case MessageType.UNDERWRITING:
        this.onUnderwritingDetail(message.key);
        break;
      case MessageType.POLICY:
        this.onPolicyDetail(message.key);
        break;
      case MessageType.TICKET:
        this.onTicketDetail(Number(message.key));
        break;
      case MessageType.KNOWLEDGE:
        this.onKnowledgeDetail(Number(message.key));
        break;
      case MessageType.SCHEDULE:
        // console.log(message.key.substr(message.key.indexOf('-') + 1));
        this.onScheduleMessage(message.key);
        break;
      case MessageType.COMMISSION:
        this.onCommissionList(message.key);
        break;
      case MessageType.SHARE:
        this.onShareDetail(message.key);
        break;
      case MessageType.INVITATION:
        this.onInvitationDetail(message.key);
        break;
      case MessageType.LEAVE_WORD:
        this.onleaveWordDetail(Number(message.key));
        break;
      case MessageType.INTELLIGENT_CONTRACT:
        this.messagesDrawerRef?.close();
        this.router.navigate(['user/contract']).then();
        break;
      default:
        this.translate.get('ComingSoon').subscribe(
          (text: string) => {
            this.toastr.info(text);
          });
        break;
    }
  }

  // 消息已读
  updateMessageReads(id): void{
    const idArr = [id];
    const param = {
      ids: idArr
    };
    this.messageService.updateMessageReads(param)
      .subscribe(
        data => {
          // this.afterRecord.emit('');
          if (data) {
            this.onCurrentReload();
            this.messageService.messageUnreadEvent.emit('');
          }
        },
        error => {}
      );
  }

  // 日程消息提示分类
  onScheduleMessage(val): void {
    let str = '';
    str = val.substr(0, val.indexOf('-'));
    console.log(str);
    switch (str) {
      case 'POLICY':
        this.onPolicyReminderDetail(val.substr(val.indexOf('-') + 1));
        break;
      case 'BOOKING':
        this.onBookingDetail(val.substr(val.indexOf('-') + 1));
        break;
      case 'CONTACT':
        this.onContactDetail(val.substr(val.indexOf('-') + 1));
        break;
      default:
        this.translate.get('ComingSoon').subscribe(
          (text: string) => {
            this.toastr.info(text);
          });
        break;
    }
  }

  onProposalDetail(proposalId: string): void {
    this.loading = true;
    this.proposalService.info(proposalId)
      .subscribe(
        proposal => {
          this.loading = false;
          const drawerRef = this.drawerService.create<ProposalDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: ProposalDetailComponent,
            nzContentParams: {
              proposal
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        }
      );
  }

  onBookingDetail(bookingNo: string): void {
    this.loading = true;
    this.bookingService.detail(bookingNo)
      .subscribe(
        bookingDetail => {
          this.loading = false;
          const booking = bookingDetail.toBooking();

          const drawerRef = this.drawerService.create<BookingDetailComponent, { value: Booking }, string>({
            nzWidth: 800,
            nzContent: BookingDetailComponent,
            nzContentParams: {
              bookingDetail,
              booking
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onInformationDetail(id: number): void {
    this.loading = true;
    this.informationService.info(id)
      .subscribe(
        information => {
          this.loading = false;
          const drawerRef = this.drawerService.create<InformationDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: InformationDetailComponent,
            nzContentParams: {
              information
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onUnderwritingDetail(underWritingNo: string): void {
    this.spinning = true;
    this.underwritingService.detail(underWritingNo)
      .subscribe(
        underwritingDetail => {

          this.spinning = false;
          const underwriting = underwritingDetail.toUnderwriting();

          const drawerRef = this.drawerService.create<UnderwritingDetailComponent, { value: Underwriting }, string>({
            nzWidth: 800,
            nzContent: UnderwritingDetailComponent,
            nzContentParams: {
              underwritingDetail,
              underwriting,
              mode: DataMode.OWN,
              dataDesensitization: this.dataDesensitization
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.spinning = false;
        });
  }

  onPolicyDetail(policyId: string): void {
    this.spinning = true;
    this.policyService.detail(policyId)
      .subscribe(
        policyDetail => {

          this.spinning = false;
          const policy = policyDetail.toPolicy();

          const drawerRef = this.drawerService.create<PolicyDetailComponent, { value: Policy }, string>({
            nzWidth: 800,
            nzContent: PolicyDetailComponent,
            nzContentParams: {
              policyDetail,
              policy,
              mode: DataMode.OWN,
              dataDesensitization: this.dataDesensitization
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.spinning = false;
        });
  }

  onTicketDetail(id: number): void {
    this.loading = true;
    this.ticketService.detail(id)
      .subscribe(info => {
        this.loading = false;
        const drawerRef = this.drawerService.create<TicketDetailComponent, { value: TicketDetail }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: TicketDetailComponent,
          nzContentParams: {
            info
          },
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
        });
        drawerRef.afterClose.subscribe(data => {
          const component = drawerRef.getContentComponent();
          // if (component.stateModified) {
          //   this.onReload(false);
          // }
        });
      },
      error => {
        this.loading = false;
      });
  }

  onKnowledgeDetail(id: number): void {
    this.loading = true;
    this.knowledgeService.detail(id)
      .subscribe(
        knowledge => {
          this.loading = false;
          const drawerRef = this.drawerService.create<KnowledgeDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: KnowledgeDetailComponent,
            nzContentParams: {
              knowledge
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();
          });
        },
        error => {
          this.loading = false;
        }
      );
  }

  onPolicyReminderDetail(policyId: string): void {
    this.loading = true;
    this.policyReminderService.detail(policyId)
      .subscribe(
        policyDetail => {
          this.loading = false;
          const policy = policyDetail.toPolicy();
          const drawerRef = this.drawerService.create<PolicyDetailComponent, { value: Policy }, string>({
            nzWidth: 800,
            nzContent: PolicyDetailComponent,
            nzContentParams: {
              policy,
              policyDetail
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onCommissionList(date: string): void {
    const param = new InfoList();
    param.billDate = date;
    this.loading = true;
    this.commissionService.infoList(param, true)
      .subscribe(
        info => {
          this.loading = false;
          const drawerRef = this.drawerService.create<CommissionListComponent, { value: any }, string>({
            nzWidth: 600,
            nzContent: CommissionListComponent,
            nzContentParams: {
              info,
              mode: DataMode.OWN,
              commissionBillDate: date
            }
          });
          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();
            this.onSearch(this.search.pageNum);
          });
        },
        error => {
          this.loading = false;
        });
  }

  onInvitationDetail(key: string): void {
    this.invitationService.info(key)
      .subscribe(
        dataInfo => {
          this.loading = false;
          dataInfo.meetingTime = dataInfo.meetingTime ? formatDate(dataInfo.meetingTime, 'yyyy-MM-dd HH:mm:ss', 'en-US') : dataInfo.meetingTime;

          const formParams = plainToInstance(InviteInfoRes, dataInfo);
          // if (this.mode !== 'OWN') {
          //   formParams.status = 'PROCESSED';
          // }
          const drawerRef = this.drawerService.create<InvitationCreateComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: InvitationCreateComponent,
            nzContentParams: {
              type: 'detail',
              formParams
            },
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.invitationDrawerClose.subscribe(
              data => {
                drawerRef?.close();
              }
            );
          });
          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        });

  }

  onShareDetail(code: string): void {
    this.shareService.detail(code).subscribe(
      shareDetail => {
        const drawerRef = this.drawerService.create<ShareDetailComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: ShareDetailComponent,
          nzContentParams: {
            shareDetail
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
        });

        drawerRef.afterClose.subscribe(data => {
          const component = drawerRef.getContentComponent();
        });
      }
    );
  }

  onleaveWordDetail(num: number): void {
    this.leaveWordService.detailsLeaveWord(num)
      .subscribe(
        data => {
          const drawerRef = this.drawerService.create<LeaveWordDetailComponent, { value: any }, string>({
            nzWidth: 600,
            nzContent: LeaveWordDetailComponent,
            nzMask: true,
            nzContentParams: {
              detailData: data
            }
          });
          drawerRef.afterClose.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });
        },
        error => {
          console.log(error);
        }
      );
  }

  onContactDetail(val): void {
    this.contactService.detail(val)
      .subscribe(data => {
          const contactInfo = data;
          const drawerRef = this.drawerService.create<ContactInfoComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: ContactInfoComponent,
            nzContentParams: {
              infoID: val,
              contactInfo,
              // status: ContactsType.Edit
            }
          });
        },
        error => {
          console.log(error);
        });
  }

}
