import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Riders } from '../../proposal-types';
import { DynamicField, ProductListResp } from '../../../product/product-types';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { MetadataService } from '../../../shared/service/metadata.service';
import { FnaDraft } from '../../../fna/fna-types';
import { fa_IR } from 'ng-zorro-antd/i18n';

@Component({
  selector: 'app-proposal-additiona',
  templateUrl: './proposal-additiona.component.html',
  styleUrls: ['./proposal-additiona.component.less']
})
export class ProposalAdditionaComponent implements OnInit {
  applyReq: Riders = new Riders();
  ridersArrData: ProductListResp[] = [];
  paymentTermOptions: PropertySelectOption[] = [];

  currency: string;
  isDetail = false;

  productCodeOptions: PropertySelectOption[] = [];
  guaranteedAreaOptions: PropertySelectOption[] = [];
  medicalLevelOptions: PropertySelectOption[] = [];
  deductibleOptions: PropertySelectOption[] = [];
  supplementaryBenefitOptions: PropertySelectOption[] = [];

  selectProduct: ProductListResp = new ProductListResp();
  @Output()
  saveRiders: EventEmitter<Riders> = new EventEmitter<Riders>();

  @Output()
  editSaveRiders: EventEmitter<Riders> = new EventEmitter<Riders>();

  constructor(private metadataService: MetadataService) { }

  ngOnInit(): void {
    this.productCodeOptions = [];
    this.ridersArrData?.forEach(item => {
      this.productCodeOptions.push(new PropertySelectOption(item.productName, item.productCode));
    });
  }

  onProduct(e): void {
    if (e) {
      this.selectProduct = this.ridersArrData.find(item => item.productCode === e);
      this.applyReq.productName = this.selectProduct.productName;
      this.applyReq.categoryCode = this.selectProduct.categoryCode;
      if (!(this.applyReq.dynamicFields && this.applyReq.dynamicFields.length)) {
        this.applyReq.dynamicFields = JSON.parse(JSON.stringify(this.selectProduct.dynamicFields)) || [];
      }
      this.paymentTermOptions = [];
      if (this.selectProduct.categoryCode === 'MEDICAL') {
        this.mapOptions(this.selectProduct);
      } else {
        this.selectProduct.paymentTerm?.forEach(term => {
          this.paymentTermOptions.push(new PropertySelectOption(term, term));
        });
      }
    }
  }

  mapOptions(res: ProductListResp): void {
    // 保障地區
    if (res.medicalOptions?.regions) {
      this.guaranteedAreaOptions = [];
      for (const region of res.medicalOptions?.regions) {
        this.guaranteedAreaOptions.push(new PropertySelectOption(this.metadataService.translate('medicalRegion', region), region));
      }
    }
    // 医疗险等级
    if (res.medicalOptions?.levels) {
      this.medicalLevelOptions = [];
      for (const level of res.medicalOptions?.levels) {
        this.medicalLevelOptions.push(new PropertySelectOption(this.metadataService.translate('medicalLevel', level), level));
      }
    }
    // 自付額
    if (res.medicalOptions?.deductibles) {
      this.deductibleOptions = [];
      res.medicalOptions?.deductibles.forEach((deductible, index, array) => {
        console.log(deductible);
        // if (deductible.currency === this.currency) {
        // }
        for (const amount of deductible.amounts) {
          this.deductibleOptions.push(new PropertySelectOption(amount.toString() + ' ' + deductible.currency, amount + ' ' + deductible.currency));
        }
      });
    }
    // 附加保障
    if (res.medicalOptions?.supplementaryBenefits) {
      this.supplementaryBenefitOptions = [];
      for (const supplementaryBenefit of res.medicalOptions?.supplementaryBenefits) {
        this.supplementaryBenefitOptions.push(new PropertySelectOption(this.metadataService.translate('supplementaryBenefit', supplementaryBenefit), supplementaryBenefit));
      }
    }
  }

  onSave(): void {
    console.log('onSave');
    if (this.isDetail) {
      this.editSaveRiders.emit(this.applyReq);
    } else {
      this.saveRiders.emit(this.applyReq);
    }
  }

  onDynamicFieldChange(e, info: DynamicField): void {
    if (info.field === 'worryFreeSelection') {
      if (e === '是') {
        this.selectProduct.dynamicFields.map(item => {
          if (item.field === 'annualIncomeStart' ||  item.field === 'incomePaymentMethod' || item.field === 'incomePaymentOptions') {
            item.required = true;
            item.isDisabled = false;
          }
        });
      } else {
        this.selectProduct.dynamicFields.map(item => {
          if (item.field === 'annualIncomeStart' ||  item.field === 'incomePaymentMethod' || item.field === 'incomePaymentOptions') {
            item.required = false;
            item.isDisabled = true;
            item.value = null;
          }
        });
      }
    }
  }

}
