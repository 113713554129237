<nz-tabset [nzSize]="'large'">
  <nz-tab [nzTitle]=myDeals>
    <app-sale-report-table [mode]="'self'"></app-sale-report-table>
  </nz-tab>
  <ng-template #myDeals>
    {{'MyDeals' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=channelDeals>
    <app-sale-report-table [mode]="'share'"></app-sale-report-table>
  </nz-tab>
  <ng-template #channelDeals>
    {{'ShareTheCommission' | translate}}
  </ng-template>
</nz-tabset>


