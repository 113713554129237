import { Subscription } from 'rxjs';

export class DisposeBag {

  private subscriptions: Subscription[] = [];

  insert(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  dispose(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
}

export function disposedBy(this: Subscription, bag: DisposeBag): void {
  bag.insert(this);
}

Subscription.prototype.disposedBy = disposedBy;

declare module 'rxjs/internal/Subscription' {
  interface Subscription {
    disposedBy: typeof disposedBy;
  }
}
