import { Material } from '../../api/types';

export class Icons {

  public static iconOfMaterial(material: Material): string {
    const filePath = material.filePath ?? '';
    return this.iconOfFileName(filePath);
  }

  public static iconOfFileName(fileName: string): string {
    let path = '';
    const ext = fileName.substr(fileName.lastIndexOf('.') + 1)?.toLowerCase();
    switch (ext) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        path = 'assets/images/ic_file_img.svg';
        break;
      case 'doc':
      case 'docx':
        path = 'assets/images/ic_file_word.svg';
        break;
      case 'pdf':
        path = 'assets/images/ic_file_pdf.svg';
        break;
      case 'xls':
      case 'xlsx':
        path = 'assets/images/ic_file_excel.svg';
        break;
      case 'mp3':
        path = 'assets/images/ic_file_audio.svg';
        break;
      case 'mp4':
        path = 'assets/images/ic_file_video.svg';
        break;
      case 'zip':
        path = 'assets/images/ic_file_zip.svg';
        break;
      case 'ppt':
      case 'pptx':
        path = 'assets/images/ic_file_ppt.svg';
        break;
      default:
        path = 'assets/images/ic_file_default.svg';
        break;
    }
    return path;
  }

}
