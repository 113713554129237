import {Component, OnInit} from '@angular/core';
import {ScheduleService} from '../schedule.service';
import {ScheduleData, ScheduleItem} from '../schedule-type';
import {NzCalendarMode} from 'ng-zorro-antd/calendar';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {ScheduleListComponent} from '../schedule-list/schedule-list.component';
import {forEach} from 'lodash';
import {ScheduleCreatedComponent} from '../schedule-created/schedule-created.component';

@Component({
  selector: 'app-schedule-calendar',
  templateUrl: './schedule-calendar.component.html',
  styleUrls: ['./schedule-calendar.component.less']
})

export class ScheduleCalendarComponent implements OnInit {
  objectKeys = Object.keys;
  newData: number;
  detailData: number;
  spinning: false;
  scheduleList: ScheduleData = new ScheduleData();

  constructor(private schedule: ScheduleService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
    this.newData = new Date().getTime();
    this.detailData = new Date().getTime();
    this.onSearchList(this.newData);
  }

  onSearchList(time): void {
    this.schedule.list(time).subscribe(
      data => {
        this.scheduleList = data;
      }
    );
  }

  panelChange(e: { date: Date; mode: NzCalendarMode }): void {
    console.log(e);
  }

  selectChange(time: Date): void {
    this.detailData = time.getTime();
    console.log(this.detailData);
    this.onSearchList(time.getTime());
  }

  onDetail(key: any): void {
    console.log(key);
    if (this.scheduleList[key] && this.scheduleList[key].length > 0) {
      const drawerRef = this.drawerService.create<ScheduleListComponent, { value: any }, string>({
        nzWidth: 600,
        nzMaskClosable: true,
        nzContent: ScheduleListComponent,
        nzContentParams: {
          scheduleData: this.scheduleList[key],
          time: key,
        },
      });

      drawerRef.afterOpen.subscribe(() => {
        const component = drawerRef.getContentComponent();
        component.scheduleDelete.subscribe(data => {
          this.onSearchList(key);
        });
        component.scheduleUpdated.subscribe(res => {
          this.onSearchList(key);
        });

      });

      drawerRef.afterClose.subscribe(data => {
        const component = drawerRef.getContentComponent();
        this.onSearchList(key);
      });
    }

    // }
    // );


  }

  onAdd(): void {
    const drawerRef = this.drawerService.create<ScheduleCreatedComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: ScheduleCreatedComponent,
      nzContentParams: {},
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.scheduleCompareSave.subscribe(data => {
        this.onSearchList(this.detailData);
        drawerRef.close();
      });
    });

    drawerRef.afterClose.subscribe(data => {
      const component = drawerRef.getContentComponent();
    });
  }
}
