<div class="basic-info-container">
  <div class="basic-info-card">
    <div nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="5" style="display: flex;align-items: center;">
        <div class="display-flex" style="font-weight: 600;line-height: 16px;">
          <div style="color: #F66667;margin-right: 4px;">☆ </div>
          {{'ChooseFormLanguage' | translate}}
        </div>
      </div>

      <div nz-col [nzSpan]="12">
        <div class="value-box">
          <nz-radio-group [(ngModel)]="formLanguage" nzButtonStyle="solid"
                          (ngModelChange)="formLanguageChange($event)"
                          class="value-box-radio-group">
            <label nz-radio-button [nzValue]="'zh-Hant-HK'"
                   style="" class="value-box-radio-group-label">
              {{'TraditionalChinese' | translate}}
            </label>
            <label nz-radio-button [nzValue]="'zh-Hans-CN'"
                   style="" class="value-box-radio-group-label">
              {{'SimplifiedChinese' | translate}}
            </label>
            <label nz-radio-button [nzValue]="'en-US'"
                   style="" class="value-box-radio-group-label">
              {{'English' | translate}}
            </label>
          </nz-radio-group>
        </div>
      </div>
    </div>
  </div>

  <nz-spin [nzSize]="'large'" [nzSpinning]="loading">
    <div  *ngFor="let form of formConfigArr; let i = index;">
      <div class="basic-info-card">
        <div nz-row [nzGutter]="16" style="padding-right: 8px;">
          <div nz-col [nzSpan]="5">
            <div class="basic-info-card-title">
              {{form.name}}
            </div>
          </div>
          <div nz-col [nzSpan]="18">
            <div *ngFor="let vo of form.vos; let i = index;" class="" style="display: inline-block;margin-bottom: 8px;">
              <div *ngIf="!vo.formNo" class="form-no-box">
                <nz-checkbox-wrapper class="form-card-radio-box"
                                     style="width: 100%;padding-left: 0;">
                  <label nz-checkbox
                         (ngModelChange)="log($event, vo)"
                         [ngModel]="vo.tick">
                    <div class="check-text-label">
                      {{vo.name}}
                    </div>
                  </label>
                </nz-checkbox-wrapper>
              </div>
              <div *ngIf="vo.formNo" class="form-no-box">
                <div class="form-card-check-box" style="justify-content: space-between;margin-bottom: 0;">
                  <label nz-checkbox [(ngModel)]="vo.tick" style=""
                         (ngModelChange)="log1($event, vo)">
                    <div class="check-text-label">
                      {{vo.name}}
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div *ngFor="let vo of form.vos; let i = index;" class=""
                 [style]="!vo.formNo && vo.tick ? 'margin-top: 10px;' : ''">
              <div *ngIf="!vo.formNo && vo.tick" class="form-no-box" style="border: 1px dashed #E4E7ED;width: 30vw;">
                <div *ngFor="let voSub of vo.vos; let j = index;" class=""
                     [style]="j != vo.vos.length - 1 ? 'margin-bottom: 10px;' : ''">
                  <div class="form-card-check-box" style="justify-content: space-between;margin-bottom: 0;">
                    <label nz-checkbox [(ngModel)]="voSub.tick" style=""
                           (ngModelChange)="log1($event, vo)">
                      <div class="check-text-label">
                        {{voSub.name}}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isInputPolicyNo" class="basic-info-card">
      <div nz-row [nzGutter]="16" style="padding-right: 8px;">
        <div nz-col [nzSpan]="5" style="display: flex;align-items: center;">
          <div class="display-flex" style="font-weight: 600;line-height: 16px;">
            {{'PolicyNumber' | translate}}
          </div>
        </div>
        <div nz-col [nzSpan]="5" style="display: flex;align-items: center;">
          <div class="value-box">
            <input class="value-box-input" nz-input
                   [disabled]="!!fnaInfo.underwritingNo"
                   [placeholder]="'PleaseEnter' | translate"
                   [(ngModel)]="inputPolicyNo" />
          </div>
        </div>
      </div>
    </div>
  </nz-spin>

</div>
