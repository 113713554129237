import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Fna, FnaSignatureUpdateReq, FnaSignedStatus, FnaSigner } from '../fna-types';
import { StorageService } from '../../shared/service/storage.service';
import { Material } from '../../api/types';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FnaService } from '../fna.service';
import { SignaturePadComponent } from '../../shared/component/signature-pad/signature-pad.component';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

export class SignaturePadOptions {
  minWidth: number;
  canvasWidth: number;
  canvasHeight: number;
  backgroundColor: string;
}

@Component({
  selector: 'app-fna-signature-pad',
  templateUrl: './fna-signature-pad.component.html',
  styleUrls: ['./fna-signature-pad.component.less']
})
export class FnaSignaturePadComponent implements OnInit {

  drawerRef: NzDrawerRef<FnaSignaturePadComponent, string>;

  signer: FnaSigner = FnaSigner.SALES;

  fna: Fna;

  signedStatus: FnaSignedStatus;

  signatureUrl: string;

  signaturePadOptions: SignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 728,
    canvasHeight: 340,
    backgroundColor: 'rgba(0,0,0,0)'
  };

  @ViewChild(SignaturePadComponent)
  signaturePad: SignaturePadComponent;

  loading = false;

  agreed = false;

  @Output()
  signed: EventEmitter<any> = new EventEmitter<any>();

  constructor(private storageService: StorageService,
              private modalService: NzModalService,
              private toastr: ToastrService,
              private translate: TranslateService,
              private fnaService: FnaService) {
  }

  ngOnInit(): void {

    let material: Material;

    switch (this.signer) {
      case FnaSigner.SALES:
        material = this.fna.salesSignedUrl;
        this.signedStatus = this.fna.salesSignedStatus;
        break;
      case FnaSigner.OWNER:
        material = this.fna.ownerSignedUrl;
        this.signedStatus = this.fna.ownerSignedStatus;
        break;
    }

    if (material && material.filePath) {
      this.loading = true;
      this.storageService.accessToken(material.filePath)
        .subscribe(
          data => {
            this.loading = false;
            this.signatureUrl = data.accessToken;
          },
          error => {
            this.loading = false;
          });
    }
  }

  showDisclaimerModal(disclaimerText: string, tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>): void {
    const ref = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: {
        value: disclaimerText
      }
    });
    ref.afterOpen.subscribe(() => {
      }
    );
  }

  onDisclaimerConfirm(modelRef: NzModalRef): void {
    modelRef.destroy();
  }

  get canConfirm(): boolean {
    if (this.signedStatus === FnaSignedStatus.SIGNED) {
      return false;
    }
    if (this.loading) {
      return false;
    }
    return this.agreed;
  }

  get canRewrite(): boolean {
    if (this.fna.signedStatus === FnaSignedStatus.SIGNED) {
      return false;
    }
    return true;
  }

  onRewrite(tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    if (!this.canRewrite) {
      return;
    }
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzOnOk: () => {
      }
    });
  }

  onRewriteConfirm(modelRef: NzModalRef, params: any): void {
    modelRef.destroy();
    this.signedStatus = FnaSignedStatus.UNSIGNED;
    this.signaturePad?.clear();
  }

  onConfirm(): void {
    if (!this.canConfirm) {
      return;
    }
    this.loading = true;
    const updateReq = new FnaSignatureUpdateReq();
    updateReq.id = this.fna.id;
    updateReq.signedDate = new Date().valueOf();
    updateReq.imageBase64 = this.signaturePad.toDataURL();
    updateReq.signer = this.signer;
    if (!this.signaturePad.toData().length) {
      this.loading = false;
      this.translate.get('UnsignedTip').subscribe(
        (res) => {
          this.toastr.warning(res);
        }
      );
      return;
    }
    this.fnaService.updateSignature(updateReq)
      .subscribe(
        data => {
          this.loading = false;
          this.drawerRef?.close();
          this.signed.emit('');
        },
        error => {
          this.loading = false;
        }
      );
  }

}
