import * as JsEncryptModule from 'jsencrypt';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EncryptorService {

  publicKey = `
  -----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvhOVQDD4MxXlhktaSjV/sGA4G/+i7vFHeHcq1QRY2uRWYR/F1kqEgqu60m628iv2PeuwiQ0SWP3mluXOs65gCM5nBRtTc/DGDEVO0Ki4FLY3+7/tD7vk+EwELh9BzQDsFoABh7Wd4N78+5Z/5d73QnewPw9A+tIZuFw+XqcAVVgoaa2Cd7A+Ule1z5YS4N6dvE7bYojoNMXDo7l6sFcd0ijBZdXnFJ4cQ0ogq6Ppble6SMWVScZdql2fTS8ltJPI1AIFduJsYhJC84XBsfnjmLl4LW8AoZdQg0zW/Fj3oJ/wWdhmD9ODB2emR2o6ED2DOjYDeQzIsUO9wq8SqnG7JwIDAQAB
  -----END PUBLIC KEY-----
  `;

  encryptor: JsEncryptModule.JSEncrypt;

  constructor() {
    this.encryptor = new JsEncryptModule.JSEncrypt();
    this.encryptor.setPublicKey(this.publicKey);
  }


  RSAEncrypt(valueToEncrypt: string): string {
    // const rsa = Forge.pki.publicKeyFromPem(this.publicKey);
    // return window.btoa(rsa.encrypt(valueToEncrypt));
    return this.encryptor.encrypt(valueToEncrypt).toString();
  }

}
