<div class="workbench-container">
  <div style="display: flex;justify-content: space-between;align-items: center;">
    <div style="color: #4B84E5;cursor: pointer;" appThrottleClick (throttleClick)="goBack()">
      <i nz-icon nzType="left" nzTheme="outline"></i>
      {{'Return' | translate}}
    </div>
    <div>
      <span style="margin-right: 18px;color: #404040">{{'TimePicker' | translate}}</span>
      <nz-date-picker nzMode="year"
                      [(ngModel)]="dateYear"
                      [nzPlaceHolder]="'SelectYear' | translate"
                      (ngModelChange)="onChange($event)"></nz-date-picker>
    </div>
  </div>
  <div style="display: flex;flex-wrap: wrap;justify-content: space-between;align-items: flex-start;margin-top: 20px;">
    <div class="charts-card">
      <div echarts [options]="fulfillPolicyChartsOption" style="height: 300px;" [loading]="loading"></div>
    </div>

    <div class="charts-card">
      <div echarts [options]="premiumStatisticsChartsOption" style="height: 300px;" [loading]="loading"></div>
    </div>

    <div class="charts-card">
      <div echarts [options]="bookingChartsOption" style="height: 300px;" [loading]="loading"></div>
    </div>

    <div class="charts-card">
      <div echarts [options]="proposalChartsOption" style="height: 300px;" [loading]="loading"></div>
    </div>

    <div class="charts-card">
      <div echarts [options]="underwritingChartsOption" style="height: 300px;" [loading]="loading"></div>
    </div>
    <div style="height: 320px;width: 30%;"></div>
  </div>

</div>
