import { Expose, plainToClass, Type } from 'class-transformer';
import { Address, Customer, Material, PagedReq } from '../api/types';

export enum Classify {
  PERSONAL = 'PERSONAL', // 个人
  COMPANY = 'COMPANY', // 公司
}

export enum FileType {
  FILE = 'FILE', // 文件
  DIRECTORY = 'DIRECTORY', // 目录
}

export class DocumentSearch extends PagedReq {
  @Expose() id: number; // 编号
  @Expose() name: string; // 文件名
  @Expose() classify: string; // 文件类型
}

export class Document {
  @Expose() id: number; // 资料编号
  @Expose() type: string; // 类型
  @Expose() leader: number; // 上级
  @Expose() name: string; // 名称
  @Expose() url: string; // 文件地址
  @Expose() rank: number; // 排序值
  @Expose() accountName: string; // 创建者
  @Expose() fileSizeText: string; // 文件大小文本
  @Expose() fileSize: number; // 文件大小
  @Expose() updateTime: number; // 更新时间
  @Expose() isCollected: boolean; // 是否已收藏
  @Expose() download: boolean; // 能否下载
  @Expose() remark: string; // 备注
}

export class DocumentListResp {
  @Expose() id: number; // 资料编号
  @Expose() type: string; // 类型
  @Expose() leader: number; // 上级
  @Expose() name: string; // 名称
  @Expose() url: string; // 文件地址
  @Expose() rank: number; // 排序值
  @Expose() accountName: string; // 创建者
  @Expose() fileSizeText: string; // 文件大小文本
  @Expose() fileSize: number; // 文件大小
  @Expose() updateTime: number; // 更新时间
  @Expose() isCollected: boolean; // 是否已收藏
  @Expose() download: boolean; // 能否下载
  @Expose() remark: string; // 备注
}

export class CreateFolder {
  @Expose() id: number; // 主键
  @Expose() leader: number; // 上级
  @Expose() name: string; // 名称
}

export class FileUpload {
  @Expose() leader: number; // 上级
  @Expose() files: Material; // 文件
}

export class DocumentSpace {
  @Expose() currentUsedSpace: number; // 当前使用空间
  @Expose() totalSpace: number; // 总空间
  @Expose() unit: string; // 单位

  toString(): string {
    return this.currentUsedSpace + '' + this.unit + ' / ' + this.totalSpace + '' + this.unit;
  }
}
