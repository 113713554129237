import { ElementRef, Injectable } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BottomSheetComponent } from './bottom-sheet.component';
import { Subject } from 'rxjs';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { BottomSheetRef } from './bottom-sheet-ref';
import { BottomSheetServiceModule } from './bottom-sheet.service.module';
import { BottomSheetOptions, BottomSheetOptionsOfComponent } from './bottom-sheet-options';

export class BottomSheetBuilderForService<T, R> {

  private sheetRef: BottomSheetComponent<T, R> | null;
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay,
              private options: BottomSheetOptions) {
    this.overlayRef = this.overlay.create(this.getOverlayConfig(options.elementRef));
    this.sheetRef = this.overlayRef.attach(new ComponentPortal(BottomSheetComponent)).instance;

    const {...componentOption} = this.options;
    this.updateOptions(componentOption);

    this.sheetRef.atViewInit.subscribe(
      () => {
        this.sheetRef.open();
      })
      .disposedBy(this.sheetRef.disposeBag);

    this.sheetRef.atViewClose.subscribe(
      () => {
        this.sheetRef.close();
      })
      .disposedBy(this.sheetRef.disposeBag);

    this.sheetRef.afterClose.subscribe(
      () => {
        this.overlayRef?.dispose();
        this.overlayRef = null;
        this.sheetRef = null;
      })
      .disposedBy(this.sheetRef.disposeBag);

    // Prevent repeatedly open drawer when tap focus element.
  }

  getOverlayConfig(elementRef: ElementRef): OverlayConfig {

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(elementRef.nativeElement)
      .withPositions([{
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetX: 0,
        offsetY: 0
      }])
      .withLockedPosition(true);

    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    const element: HTMLElement = elementRef.nativeElement as HTMLElement;

    return new OverlayConfig({
      disposeOnNavigation: true,
      width: element.offsetWidth,
      height: element.offsetHeight,
      positionStrategy,
      scrollStrategy
    });
  }

  getInstance(): BottomSheetRef<T, R> {
    return this.sheetRef;
  }

  updateOptions(options: BottomSheetOptionsOfComponent): void {
    Object.assign(this.sheetRef, options);
  }

}

@Injectable({
  providedIn: BottomSheetServiceModule
})
export class BottomSheetService {

  constructor(private overlay: Overlay) {
  }

  create<T = NzSafeAny, D = undefined, R = NzSafeAny>(options: BottomSheetOptions<T, D extends undefined ? {} : D>): BottomSheetRef<T, R> {
    return new BottomSheetBuilderForService<T, R>(this.overlay, options).getInstance();
  }
}
