import { Component, OnInit } from '@angular/core';
import { PagedResp } from '../../api/types';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { ProductService } from '../product.service';
import { Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MetadataService } from '../../shared/service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CompanySearch, ProductCompany } from '../product-types';
import { CompanyDetailComponent } from '../company-detail/company-detail.component';
import { WorkbenchService } from '../../workbench/workbench.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.less']
})
export class CompaniesComponent implements OnInit {

  spinning = false;
  loading = false;
  search: CompanySearch = new CompanySearch();
  filterPopoverVisible = false;

  companies: PagedResp<ProductCompany>;

  regionOptions: PropertySelectOption[] = [];

  constructor(
    private productService: ProductService,
    private router: Router,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    private metadataService: MetadataService,
    private translate: TranslateService,
    private workbenchService: WorkbenchService,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {

    const regions = this.metadataService.values('productCompanyRegion');
    for (const region of regions) {
      this.regionOptions.push(new PropertySelectOption(region.value, region.key));
    }

    this.load();

    this.workbenchService.switchLocaleReload
      .subscribe(locale => {
        this.load();
      });

  }

  onReload(resetPageNum = true): void {
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.load();
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.filterPopoverVisible = false;
    this.load();
  }

  onResetSearch(): void {
    this.search = new CompanySearch();
    this.load();
  }

  load(): void {
    this.loading = true;
    this.productService.companyPage(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.companies = data;
        },
        error => {
          this.loading = false;
        });
  }

  onCompanyDetail(code: string): void {
    this.spinning = true;
    this.productService.companyDetail(code)
      .subscribe(
        company => {
          this.spinning = false;
          const drawerRef = this.drawerService.create<CompanyDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: CompanyDetailComponent,
            nzContentParams: {
              company
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.spinning = false;
        });
  }

}
