import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from "../../core/locale.service";
import { I18n, Locale } from "../../api/types";

@Pipe({
  name: 'i18n'
})
export class I18nPipe implements PipeTransform {

  constructor(private localeService: LocaleService) {

  }

  transform(value: I18n, ...args: any[]): any {
    let locale = this.localeService.locale;
    switch (locale) {
      case Locale.cn:
        return value.zhHansCN;
      case Locale.hk:
        return value.zhHantHK;
      case Locale.en:
        return value.enUS;
      case Locale.jp:
        return value.jaJP;
      case Locale.ko:
        return value.koKR;
    }
  }


}
