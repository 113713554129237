<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 30px; ">
    <span>{{'ClientInfo' | translate}}</span>
  </div>

  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="section-title" style="margin-top: 20px;display: flex;justify-content: space-between;align-items: center">
      <span style="margin-left: 8px">{{'IntendedInfo' | translate}}</span>
    </div>
    <div class="section-wrapper" style="padding-bottom: 10px;">
      <nz-row [nzGutter]="24">
        <nz-col [nzSpan]="12">
          <app-property-text [title]="'Referee' | translate"
                             [displayMode]="'vertical'"
                             [value]="formParams.name "></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-datetime-string [title]="'IntendedMeetingTime' | translate"
                                        [disabled]="formParams.status == 'PROCESSED'"
                                        [displayMode]="'vertical'"
                                        [formatter]="'yyyy-MM-dd HH:mm'"
                                        [showTime]="{nzFormat: 'HH:mm'}"
                                        [(value)]="formParams.meetingTime">
          </app-property-datetime-string>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-text [title]="'IntendedProduct' | translate"
                             [displayMode]="'vertical'"
                             [style]="formParams.status == 'PROCESSED' ? '' : 'cursor: pointer;'"
                             appThrottleClick (throttleClick)="onProductSelection()"
                             [value]="viewProduct()"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-text [title]="'IntendedCompany' | translate"
                             [displayMode]="'vertical'"
                             [value]="viewCompany()"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <div class="row-box">
            <div class="col-title">{{'BudgetYears' | translate}}</div>
            <div class="col-box">
              <div class="group-box">
                <nz-input-number class="group-box-input-number"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [disabled]="formParams.status == 'PROCESSED'"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="formParams.budget">
                </nz-input-number>
<!--                <span class="suffix-text">USD</span>-->
              </div>
            </div>
          </div>
        </nz-col>

      </nz-row>
    </div>

    <div class="section-title" style="margin-top: 20px;display: flex;justify-content: space-between;align-items: center">
      <span style="margin-left: 8px">{{'BasicInformationCMG' | translate}}</span>
    </div>

    <div class="section-wrapper" style="padding-bottom: 10px;">
      <nz-row [nzGutter]="24">
        <nz-col *ngIf="!workbench.hiddenLastName" [nzSpan]="12">
          <app-property-input *ngIf="formParams.status != 'PROCESSED'"
                              [title]="'LastNameEnglish' | translate"
                              [displayMode]="'vertical'"
                              [placeholder]="('PleaseChoose' | translate) + ('LastNameEnglish' | translate)"
                              [(value)]="formParams.basicInformation.englishLastName"></app-property-input>

          <app-property-text *ngIf="formParams.status == 'PROCESSED'"
                             [title]="'LastNameEnglish' | translate"
                             [displayMode]="'vertical'"
                             [isEncryption]="isDynamicEncryption('client_intention_invite_customer_name')"
                             [value]="formParams.basicInformation.englishLastName"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-input *ngIf="formParams.status != 'PROCESSED'"
                              [title]="'GivenNameEnglish' | translate"
                              [displayMode]="'vertical'"
                              [placeholder]="('PleaseChoose' | translate) + ('GivenNameEnglish' | translate)"
                              [(value)]="formParams.basicInformation.englishFirstName"></app-property-input>

          <app-property-text *ngIf="formParams.status == 'PROCESSED'"
                             [title]="'GivenNameEnglish' | translate"
                             [displayMode]="'vertical'"
                             [isEncryption]="isDynamicEncryption('client_intention_invite_customer_name')"
                             [value]="formParams.basicInformation.englishFirstName"></app-property-text>
        </nz-col>
        <nz-col *ngIf="!workbench.hiddenLastName" [nzSpan]="12">
          <app-property-input *ngIf="formParams.status != 'PROCESSED'"
                              [title]="'LastNameChinese' | translate"
                              [displayMode]="'vertical'"
                              [placeholder]="('PleaseChoose' | translate) + ('LastNameChinese' | translate)"
                              [(value)]="formParams.basicInformation.chineseLastName"></app-property-input>

          <app-property-text *ngIf="formParams.status == 'PROCESSED'"
                             [title]="'LastNameChinese' | translate"
                             [displayMode]="'vertical'"
                             [isEncryption]="isDynamicEncryption('client_intention_invite_customer_name')"
                             [value]="formParams.basicInformation.chineseLastName"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-input *ngIf="formParams.status != 'PROCESSED'"
                              [title]="'GivenNameChinese' | translate"
                              [displayMode]="'vertical'"
                              [placeholder]="('PleaseChoose' | translate) + ('GivenNameChinese' | translate)"
                              [(value)]="formParams.basicInformation.chineseFirstName"></app-property-input>

          <app-property-text *ngIf="formParams.status == 'PROCESSED'"
                             [title]="'GivenNameChinese' | translate"
                             [displayMode]="'vertical'"
                             [isEncryption]="isDynamicEncryption('client_intention_invite_customer_name')"
                             [value]="formParams.basicInformation.chineseFirstName"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-select [title]="'Gender' | translate"
                               [displayMode]="'vertical'"
                               [disabled]="formParams.status == 'PROCESSED'"
                               [placeholder]="('PleaseSelect' | translate) + ('Gender' | translate)"
                               [options]="genderOptions"
                               [(value)]="formParams.basicInformation.gender"></app-property-select>
        </nz-col>
        <nz-col [nzSpan]="12">
          <div class="row-box">
            <div class="col-title">{{'ContactPhone' | translate}}</div>
            <div class="value-box">
              <div style="display: flex;border-bottom: 1px solid #E2EBF6;padding-bottom: 6px;">
                <app-filter-popover [nationality]="formParams.basicInformation.areaCode"
                                    style="width: 90px;"
                                    [isDisabled]="formParams.status == 'PROCESSED'"
                                    [modeType]="'Number'"
                                    [isBorder]="false"
                                    (nationalitySelected)="mobileChange($event)">
                </app-filter-popover>
                <input nz-input class="value-box-input"
                       style="border: none;padding-left: 0;flex: 1"
                       [disabled]="formParams.status == 'PROCESSED'"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="formParams.basicInformation.telephone" />
              </div>
            </div>
          </div>
        </nz-col>
        <nz-col [nzSpan]="24">
          <app-property-input [title]="'ResidentialAddress' | translate"
                              [displayMode]="'vertical'"
                              [disabled]="formParams.status == 'PROCESSED'"
                              [placeholder]="('PleaseEnter' | translate) + ('ResidentialAddress' | translate)"
                              [(value)]="formParams.basicInformation.residentialAddress"></app-property-input>
        </nz-col>
        <nz-col [nzSpan]="24">
          <app-property-input-area [title]="'Remark' | translate"
                                   [disabled]="formParams.status == 'PROCESSED'"
                                   [placeholder]="('PleaseEnter' | translate) + ('Remark' | translate)"
                                   [(value)]="formParams.basicInformation.remark"></app-property-input-area>
        </nz-col>
      </nz-row>
    </div>

  </nz-spin>

  <div *ngIf="formParams.status !== 'PROCESSED'" class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSummit()">
          <span *ngIf="type === 'create'">{{ 'Create' | translate }}</span>
          <span *ngIf="type === 'detail'">{{ 'Save' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
