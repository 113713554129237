import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proposal-custom',
  templateUrl: './proposal-custom.component.html',
  styleUrls: ['./proposal-custom.component.less']
})
export class ProposalCustomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
