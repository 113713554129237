import { PagedReq } from '../api/types';
import { Expose, plainToInstance, Transform } from 'class-transformer';
import { BasicInformation } from '../new-fna/new-fna-types';

export class InviteSearch extends PagedReq {
  @Expose() accountName: string; // 销售渠道
  @Expose() customerName: string; // 客户姓名
  @Expose() productName: string; // 意向保险产品
  @Expose() productCode: string; // 意向保险产品
  @Expose() status: string;
  @Expose() startMeetingDate: string; // 开始会面日期
  @Expose() endMeetingDate: string; // 结束会面日期
  @Expose() sortField: string; // 排序字段   createTime、customerName、status、
  @Expose() sortOrder: string; // 排序类型 DESC/ASC
  @Expose() orderColumn: string; // 排序字段   createTime、customerName、status、
  @Expose() orderAspect: string; // 排序类型 DESC/ASC
}

export class InviteListRes {
  @Expose() id: number; // 主键
  @Expose() customerName: string; // 用户
  @Expose() name: string; // 用户名称
  @Expose() accountName: string; // 客户姓名
  @Expose() customerNameEn: string; // 客户姓名英文
  @Expose() meetingTime: string; // 意向会面时间
  @Expose() companyCodes: string[]; // 意向保险公司集合
  @Expose() companyNames: string[]; // 意向保险公司名称集合
  @Expose() productCodes: string[]; // 意向保险产品集合
  @Expose() productNames: string[]; // 意向保险产品名称集合
  @Expose() budget: number; // 预算/年(USD)
  @Expose() status: string; // 状态: PROCESSED-已处理;PENDING-PROCESSING-待处理
  @Expose() createTime: string; // 创建时间
  @Expose() updateTime: string; // 更新时间
  @Expose() productInfoList: ProductInfoList[] = []; // 产品信息
  @Expose() sortField: string; // 创建开始日期
  @Expose() sortOrder: string; // 创建结束日期
  @Expose() orderColumn: string; // 排序字段   createTime、customerName、status、
  @Expose() orderAspect: string; // 排序类型 DESC/ASC
}

export class ProductInfoList {
  @Expose() productName: string; // 产品名称
  @Expose() categoryName: string; // 产品分类名
  @Expose() companyName: string; // 公司名称
  @Expose() productCode: string; // 产品码
  @Expose() companyLog: string; // 公司Log
}

export class InviteInfo {
  @Expose() id: number; // 主键
  @Expose() meetingTime: string; // 意向会面时间
  @Expose() productCodes: string[]; // 意向保险产品集合
  @Expose() budget: number; // 预算/年(USD)
  @Transform((value) => plainToInstance(InviteBasicInfo, Object.assign(new InviteBasicInfo(), value.value)))
  @Expose() basicInformation: InviteBasicInfo = new InviteBasicInfo(); // 基础信息
}

export class InviteBasicInfo {
  @Expose() englishFirstName: string; // 英文名
  @Expose() englishLastName: string[]; // 英文姓
  @Expose() chineseFirstName: string[]; // 中文名
  @Expose() chineseLastName: string; // 中文姓
  @Expose() gender: string; // 性别
  @Expose() areaCode: string; // 区号
  @Expose() telephone: string; // 联系电话
  @Expose() residentialAddress: string; // 居住地址
  @Expose() remark: string; // 备注
}

export class InviteInfoRes {
  @Expose() id: number; // 主键
  @Expose() accountName: string; // 用户
  @Expose() name: string; // 用户名称
  @Expose() customerName: string; // 客户姓名
  @Expose() customerNameEn: string; // 客户姓名英文
  @Expose() meetingTime: string; // 意向会面时间
  @Expose() companyCodes: string[]; // 意向保险公司集合
  @Expose() companyNames: string[]; // 意向保险公司名称集合
  @Expose() productCodes: string[]; // 意向保险产品集合
  @Expose() productNames: string[]; // 意向保险产品名称集合
  @Expose() finalProductCode: string; // 意向保险产品名称集合
  @Expose() budget: number; // 预算/年(USD)
  @Transform((value) => plainToInstance(InviteBasicInfo, Object.assign(new InviteBasicInfo(), value.value)))
  @Expose() basicInformation: InviteBasicInfo = new InviteBasicInfo(); // 基础信息
  @Expose() status: string; // 状态: PROCESSED-已处理;PENDING-PROCESSING-待处理
  @Expose() underwritingNo: string; // 新单编号
  @Expose() createTime: string; // 创建时间
  @Expose() createBy: string; // 创建人
  @Expose() updateTime: string; // 更新时间
  @Expose() lastModifyBy: string; // 最后修改人
  @Expose() productInfoList: ProductInfoList[] = []; // 产品信息
}
