import {Component, OnInit} from '@angular/core';
import {AmlListResp, AmlSearch, PagedResp} from '../../../api/types';
import {AmlService} from '../../aml.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {AmlSearchDetailComponent} from '../aml-search-detail/aml-search-detail.component';
import {AmlInspectComponent} from '../aml-search-inspect/aml-inspect.component';
import {PropertySelectOption} from '../../../shared/component/property-select/property-select.component';
import {MetadataService} from '../../../shared/service/metadata.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-aml-search-list',
  templateUrl: './aml-search-list.component.html',
  styleUrls: ['./aml-search-list.component.less']
})
export class AmlSearchListComponent implements OnInit {

  loading = false;

  spinning = false;

  amls: PagedResp<AmlListResp>;

  search: AmlSearch = new AmlSearch();

  dates: number[];

  filterPopoverVisible = false;

  typeOptions: PropertySelectOption[] = [];
  statusOptions: PropertySelectOption[] = [];
  genderOptions: PropertySelectOption[] = [];

  constructor(private drawerService: NzDrawerService,
              private metadataService: MetadataService,
              private amlService: AmlService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.amlService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.amls = data;
        },
        error => {
          this.loading = false;
        });

    const amlRecordTypes = this.metadataService.values('amlRecordType');
    for (const amlRecordType of amlRecordTypes) {
      this.typeOptions.push(new PropertySelectOption(amlRecordType.value, amlRecordType.key));
    }
    const genders = this.metadataService.values('gender');
    for (const gender of genders) {
      this.genderOptions.push(new PropertySelectOption(gender.value, gender.key));
    }
    const amlRecordStatus = this.metadataService.values('amlRecordStatus');
    for (const status of amlRecordStatus) {
      this.statusOptions.push(new PropertySelectOption(status.value, status.key));
    }

  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.amlService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.amls = data;
        },
        error => {
          this.loading = false;
        });
  }

  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search = new AmlSearch();
    }
    this.amlService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.amls = data;
        },
        error => {
          this.loading = false;
        });

  }

  onResetSearch(): void {
    this.dates = null;
    this.search = new AmlSearch();
    this.onReload();
  }

  onAmlInspectDetail(id: number): void {

    this.loading = true;
    this.amlService.detail(id)
      .subscribe(info => {
          this.loading = false;
          const drawerRef = this.drawerService.create<AmlSearchDetailComponent, { value: any }, string>({
            nzWidth: 1024,
            nzMaskClosable: true,
            nzContent: AmlSearchDetailComponent,
            nzContentParams: {
              info
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();
            if (component.stateModified) {
              this.onReload(false);
            }
          });
        },
        error => {
          this.loading = false;
        });


  }

  onAmlNewInspection(): void {
    const drawerRef = this.drawerService.create<AmlInspectComponent, { value: any }, string>({
      nzWidth: 1024,
      nzMaskClosable: true,
      nzContent: AmlInspectComponent,
      nzContentParams: {},
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.amlInspectCreated
        .subscribe(
          data => {
            drawerRef.close();
            this.onReload();
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onSearchDateChange(dates: number[]): void {
    this.dates = dates;
    if (dates) {
      this.search.beginTime = dates[0];
      this.search.endTime = dates[1];
    } else {
      this.search.beginTime = undefined;
      this.search.endTime = undefined;
    }
  }

  // search record 添加到 monitor entry 列表
  addEntryList(id: number): void {
    this.loading = true;
    this.amlService.addMonitorEntryList(id)
      .subscribe(
        data => {
          this.loading = false;
          if (data) {
            this.amlService.monitorReload.next();
          }
          this.translate.get(['AddSuccess', 'AlreadyExists']).subscribe(
            (res) => {
              if (data) {
                this.toastr.success(res.AddSuccess);
              } else {
                this.toastr.info(res.AlreadyExists);
              }
            }
          );
        },
        error => {
          this.loading = false;
        });
  }

}
