import { Component, EventEmitter, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-update-available',
  templateUrl: './update-available.component.html',
  styleUrls: ['./update-available.component.less']
})
export class UpdateAvailableComponent implements OnInit {

  modalRef: NzModalRef<UpdateAvailableComponent, any>;

  laterEvent: EventEmitter<any> = new EventEmitter<any>();
  reloadEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onLater(): void {
    this.laterEvent.emit('');
    this.modalRef?.close();
  }

  onReload(): void {
    this.reloadEvent.emit('');
    this.modalRef?.close();
  }
}
