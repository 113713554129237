import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RecommendProduct } from '../fna-types';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { RecommendProductItem } from '../components/fna-product-item/fna-product-item.component';

@Component({
  selector: 'app-fna-product-list',
  templateUrl: './fna-product-list.component.html',
  styleUrls: ['./fna-product-list.component.less']
})
export class FnaProductListComponent implements OnInit {

  drawerRef: NzDrawerRef<FnaProductListComponent, string>;

  products: RecommendProduct[] = [];

  @Output()
  productsSaved: EventEmitter<RecommendProduct[]> = new EventEmitter<RecommendProduct[]>();

  constructor() { }

  ngOnInit(): void {
  }

  onTick(item: RecommendProductItem): void {
    item.product.tick = !item.product.tick;
    this.products[item.index] = item.product;
  }

  onSave(): void {
    this.productsSaved.emit(this.products);
    this.drawerRef?.close();
  }
}
