<div class="table-section">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!bvListData">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="bvListData" style="margin-top: 20px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="false"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="bvListData.list || []"
                [nzTitle]="tableHeader">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">
                <span style="font-size: 16px;">{{'BvModuleList' | translate}}</span>
                <!--                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate" style="margin-left: 5px"-->
                <!--                   appThrottleClick (throttleClick)="onReload()">-->
                <!--                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>-->
                <!--                </a>-->
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">

                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        class="icon-center"
                        nz-tooltip [nzTooltipTitle]="'Reload' | translate"
                        appThrottleClick (throttleClick)="onReload()">
                  <img alt="" width="24" src="assets/images/fna_reload.svg">
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <!--          <div class="table-title">-->
          <!--            {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ fnaData.total }}</span>-->
          <!--          </div>-->
        </ng-template>

        <thead>
          <tr>
            <th><span>{{'PolicyNo' | translate}}</span></th>
            <!--          <th nzWidth="150px"><span>{{'FnaId' | translate}}</span></th>-->
            <th nzWidth="160px">
              <span>{{'ClientName' | translate}}</span>
            </th>
            <th nzWidth="200px">
              <span>{{'ProductName' | translate}}</span>
            </th>
            <!--          <th><span>{{'Referee' | translate}}{{'FullName' | translate}}</span></th>-->
            <th><span>{{'Insurer' | translate}}</span></th>
            <th><span>{{'PolicyDate' | translate}}</span></th>
            <th>
              <span>{{'FirstYearBv' | translate}}</span>
            </th>
            <th>
              <span>{{'AIBV'}}</span>
            </th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let bv of bvListData.list; let i = index">
          <td nzEllipsis>#{{ bv.policyNo || 'N/A' }}</td>
          <td nzEllipsis><span>{{ bv.ownerName }}</span></td>
          <td nzEllipsis><span>{{ bv.productName }}</span></td>
          <td><span>{{ bv.companyName }}</span></td>
          <td><span>{{ bv.policyDate }}</span></td>
          <td>HKD {{ bv.totalBv | number }}</td>
          <td>HKD {{ bv.aibv | number }}</td>
        </tr>
        </tbody>
      </nz-table>

      <div style="margin: 15px 0;text-align: right;padding-right: 25px;">
        <nz-pagination [nzPageIndex]="search.pageNum"
                       [nzPageSize]="search.pageSize"
                       [nzTotal]="bvListData.total"
                       [nzShowTotal]="totalTemplate"
                       (nzPageIndexChange)="onSearch($event)">
        </nz-pagination>
        <ng-template #totalTemplate let-total>{{'HedgeProposalTotal' | translate}}: {{ total }} </ng-template>
      </div>
    </div>
  </nz-spin>
</div>


