<div class="container">
  <div class="common-title">
    {{'WithdrawalPlan' | translate}}
  </div>

  <div class="section-wrapper" style="">
    <div style="padding: 15px 0 15px 0; display: flex; flex-direction: row; align-items: center; justify-content: start;">
      <img alt="" width="20" src="assets/images/ic_details.svg">
      <div style="flex: 1; display: flex; flex-direction: column; align-items: flex-start;">
        <div *ngFor="let description of descriptions" style="margin: 0 0 0 10px">
          <div>{{description}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" style="">

    <app-property-switch *ngIf="maxWithdrawal" [title]="'MaximumWithdrawals' | translate"
                         [(value)]="withdrawal.max"></app-property-switch>

    <app-property-select [title]="'From' | translate"
                         [options]="fromOptions"
                         [(value)]="withdrawal.from"
                         (valueChange)="onFromChange($event)"></app-property-select>

    <app-property-select [title]="'To' | translate"
                         [options]="toOptions"
                         [(value)]="withdrawal.to"
                         (valueChange)="onToChange($event)"></app-property-select>

    <app-property-input-number *ngIf="!withdrawal.max" [title]="'WithdrawalAmount' | translate"
                               [bottomLineVisible]="false"
                               [(value)]="withdrawal.amount"></app-property-input-number>

  </div>

  <div style="margin: 10px 15px 10px 15px; color: #e63757; font-size: 12px" *ngIf="tip">
    {{tip | translate}}
  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onCancel()">
          <em nz-icon nzType="close" nzTheme="outline"></em>
          <span>{{'Cancel' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" [disabled]="!canConfirm"
           [style]="!canConfirm ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onConfirm()">
          <em nz-icon nzType="check" nzTheme="outline"></em>
          <span>{{'Confirm' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
