import { Expose, plainToClass, plainToInstance, Transform, Type } from 'class-transformer';
import { Address, Country, Customer, Gender, MarriageStatus, Material, PagedReq, Region, SmokeCondition, Title } from '../api/types';
import { Product } from '../product/product-types';

export enum FnaStatus {
  UNCOMPLETED = 'UNCOMPLETED',
  COMPLETED = 'COMPLETED',
}

export enum FnaSignedStatus {
  SIGNED = 'SIGNED',
  UNSIGNED = 'UNSIGNED',
}

export class FnaSearch extends PagedReq {
  @Expose() name: string; // 客户姓名
  @Expose() accountName: string;
  @Expose() appAccountName: string; // 查询fna账户名
  @Expose() bookingNo: string; // 预约编号
  @Expose() underwritingNo: string; // 新单编号
  @Expose() policyNo: string; // 保单编号
}

export class FnaListRes {
  @Expose() id: number; // 主键
  @Expose() productCode: string; // 保险产品唯一标识
  @Expose() productName: string; // 产品名称
  @Expose() name: string; // 姓名
  @Expose() status: FnaStatus; // 状态: UNCOMPLETED-待处理;COMPLETED-已完成;DELETED-已删除
  @Expose() endProductCodes: string; // 最终选择的产品
  @Expose() addTime: number; // 创建时间
  @Expose() bookingNo: string; // 预约编号
  @Expose() underwritingNo: string; // 新单编号
  @Expose() policyNo: string; // 保单编号
}

export class Fna {
  @Expose() accountName: string; // 销售渠道 给下线创建
  @Expose() nickName: string; // 销售渠道名
  @Expose() id: number; // 主键
  @Expose() proposalId: string; // 計劃書編號
  @Expose() bookingNo: string; // 预约编号
  @Expose() underwritingNo: string; // 新单编号
  @Expose() policyId: string; // 保单ID
  @Expose() policyNo: string; // 保单编号
  @Expose() productCode: string; // 保险产品唯一标识
  @Expose() productName: string; // 保险产品名称
  @Expose() status: FnaStatus; // 状态: UNCOMPLETED-待处理;COMPLETED-已完成;DELETED-已删除
  @Expose() fnaStatus: FnaStatus; // 状态: UNCOMPLETED-待处理;COMPLETED-已完成;DELETED-已删除
  @Expose() addTime: number; // 创建时间
  @Type(() => CustomerShot)
  @Expose() customerShot: CustomerShot = new CustomerShot(); // 客户信息快照
  @Type(() => PersonalData)
  @Transform((value) => plainToInstance(PersonalData, Object.assign(new PersonalData(), value.value)))
  @Expose() personalData: PersonalData = new PersonalData(); // 个人资料
  @Type(() => Eyewitness)
  @Transform((value) => plainToInstance(Eyewitness, Object.assign(new Eyewitness(), value.value)))
  @Expose() eyewitness: Eyewitness = new Eyewitness(); // 见证人信息快照
  @Type(() => ExistingSecurity)
  @Transform((value) => plainToInstance(ExistingSecurity, Object.assign(new ExistingSecurity(), value.value)))
  @Expose() existingSecurity: ExistingSecurity = new ExistingSecurity(); // 现有保障
  @Type(() => FamilyMember)
  @Transform((value) => plainToInstance(FamilyMember, Object.assign(new FamilyMember(), value.value)))
  @Expose() familyMembers: FamilyMember = new FamilyMember(); // 家庭状况
  @Type(() => FinancialData)
  @Transform((value) => plainToInstance(FinancialData, Object.assign(new FinancialData(), value.value)))
  @Expose() financialData: FinancialData = new FinancialData(); // 财务资料
  @Type(() => Assets)
  @Transform((value) => plainToInstance(Assets, Object.assign(new Assets(), value.value)))
  @Expose() assets: Assets = new Assets(); // 资产
  @Type(() => AssessAdvice)
  @Expose() assessAdvice: AssessAdvice = new AssessAdvice(); // 评估建议表
  @Type(() => Material)
  @Expose() url: Material; // 文件地址
  @Expose() signedStatus: FnaSignedStatus; // 签名状态：已签名-SIGNED 未签名-UNSIGNED
  @Expose() salesSignedDate: number; // 销售签名日期
  @Expose() salesSignedStatus: FnaSignedStatus; // 销售签名状态：已签名-SIGNED 未签名-UNSIGNED
  @Expose() ownerSignedDate: number; // 投保人签名日期
  @Expose() ownerSignedStatus: FnaSignedStatus; // 投保人签名状态：已签名-SIGNED 未签名-UNSIGNED
  @Expose() ownerSignedUrl: Material; // 投保人签名图片
  @Expose() salesSignedUrl: Material; // 销售签名图片

  @Expose() lastModifyBy: string; // 最后修改人
  @Expose() lastModifiedDate: number; // 最后修改时间

  @Type(() => RecommendProduct)
  @Expose() customize: RecommendProduct[]; // 自选推荐产品
  @Type(() => RecommendProduct)
  @Expose() recommend: RecommendProduct[]; // 系统推荐产品
  @Type(() => RecommendProduct)
  @Expose() history: RecommendProduct[]; // 历史推荐产品
  @Type(() => RecommendProduct)
  @Expose() endProduct: RecommendProduct[]; // 最终选择的产品

  @Expose() edit: boolean; // 服务端返回是否可编辑
  @Expose() accountNameEdit = true; // 服务端返回是否可编辑销售渠道
  @Expose() bookingStatusTip: string; // 服务端返回不可编辑销售渠道提示

  fnaProductName(): string {
    return this.endProduct && this.endProduct.length > 0 ? this.endProduct[0].productName : 'N/A';
  }

  get clientCanSave(): boolean {
    // 以服务端返回的结果优先
    // if (this.accountNameEdit === false) {
    //   return false;
    // }
    if (this.edit === false) {
      return false;
    }
    let can = false;
    do {
      if (!this.id) {
        can = true;
        break;
      }
      if (this.signedStatus === FnaSignedStatus.UNSIGNED) {
        can = true;
        break;
      }
      can = false;
    } while (false);
    return can;
  }

  get formCanSave(): boolean {
    // 以服务端返回的结果优先
    if (this.edit === false) {
      return false;
    }
    let can = true;
    do {
      if (this.signedStatus === FnaSignedStatus.SIGNED) {
        can = false;
        break;
      }
      can = true;
    } while (false);
    return can;
  }
  get productCanSave(): boolean {
    if (this.edit === false) {
      return false;
    }
    let can = true;
    do {
      if (this.signedStatus === FnaSignedStatus.SIGNED) {
        can = false;
        break;
      }
      can = true;
    } while (false);
    return can;
  }
}

export class PersonalData {
  @Expose() accountName: string; // 理财顾问名称
  @Expose() registrationNumber: string; // 理财顾问注册编号
  @Expose() number: string; // 理财顾问编码
  @Expose() customerName: string; // 客户姓名
  @Expose() taxCountry: string; // 税务国家
  @Expose() employment: string; // 税务国家
  @Expose() otherEmployment: string; // 其他就业情况
  @Expose() isRetired: boolean; // 是否已退休
  @Expose() retiredAge: number; // 退休年龄
}

// 个人客户 - 投保人/受保人
export class CustomerShot {
  @Expose() accountName: string; // 销售渠道 给下线创建
  @Expose() nickName: string; // 销售渠道名
  @Expose() customCode: string; // 自定义编号
  @Expose() lastName = '-'; // 姓
  @Expose() firstName: string; // 名
  @Expose() name: string; // 姓名
  @Expose() lastNamePinyin = '-'; // 姓-拼音
  @Expose() firstNamePinyin: string; // 名-拼音
  @Expose() pinyin: string; // 英文姓名
  @Expose() gender: Gender; // 性别
  @Expose() title: Title; // 称谓: Mr-先生;Mrs-夫人;Miss-小姐;Ms-女士;Dr-博士
  @Expose() birthday: number; // 出生日期
  @Expose() birthdate: string;
  @Expose() age: string; // 年龄
  @Expose() areaCode: string; // 区号
  @Expose() phone: string; // 手机号码
  @Expose() email: string; // 电子邮箱
  @Expose() smoke: SmokeCondition; // 吸烟: S-吸烟;NS-不吸烟
  @Expose() smokeQuantity: string; // 吸烟量
  @Expose() companyType: string; // 公司行业
  @Expose() source: string; // 客户来源
  @Expose() idCard: string; // 身份证
  @Expose() passport: string; // 护照
  @Expose() eepCode: string; // 港澳通行证
  @Expose() marriage: MarriageStatus; // 婚姻状况: SINGLE-单身;MARRIED-已婚;DIVORCED-离婚;WIDOWED-丧偶
  @Expose() birthplace: string; // 出生日期
  @Expose() education: string; // 教育程度: PRIMARY-小学及以下;SECONDARY-中学;TERTIARY_OR_ABOVE-大专或以上
  @Expose() residenceAreaCode: string; // 住宅电话区号
  @Expose() residenceTelephone: string; // 住宅电话
  @Expose() longtimeAbroad: boolean; // 是否长期在国外
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() residenceAddress: Address = new Address(); // 户籍地址
  @Expose() companyName: string; // 公司名称
  @Expose() companyAreaCode: string; // 公司电话区号
  @Expose() companyTelephone: string; // 公司电话
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() companyAddress: Address = new Address(); // 公司地址
  @Expose() workYear: number; // 公司电话
  @Expose() position: string; // 职位
  @Expose() salary: number; // 月薪
  @Expose() country: string; // 国家地区: CHINESE-中国;HONGKONG-中国香港;TAIWAN-中国台湾;MACAO-中国澳门;USA-美国;CANADA-加拿大;OTHER-其他
  @Expose() remark: string; // 备注
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() mailingAddress: Address = new Address(); // 通讯地址
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() residentialAddress: Address = new Address(); // 住宅地址

  phoneString(): string {
    return (this.areaCode ? '+' + this.areaCode + ' ' : '') + (this.phone ?? '');
  }

  officePhoneNumberString(): string {
    return (this.companyAreaCode ? '+' + this.companyAreaCode + ' ' : '') + (this.companyTelephone ?? '');
  }

  residentialPhoneNumberString(): string {
    return (this.residenceAreaCode ? '+' + this.residenceAreaCode + ' ' : '') + (this.residenceTelephone ?? '');
  }

  toCustomer(): Customer {
    return plainToClass(Customer, this, {excludeExtraneousValues: true});
  }

}

export class Eyewitness {
  @Expose() lastName: string; // 姓
  @Expose() firstName: string; // 名
  @Expose() name: string; // 姓名
  @Expose() lastNamePinyin: string; // 姓-拼音
  @Expose() firstNamePinyin: string; // 名-拼音
  @Expose() pinyin: string; // 拼音
  @Expose() relationship: string; // 与保单持有人关系
  @Expose() title: Title; // 称谓: Mr-先生;Mrs-夫人;Miss-小姐;Ms-女士;Dr-博士
  @Expose() gender: Gender; // 性别
  @Expose() birthday: number; // 出生日期
  @Expose() birthdate: string;
  @Expose() age: string; // 年龄
  @Expose() areaCode: string; // 区号
  @Expose() phone: string; // 区号
  @Expose() email: string; // 电子邮箱
  @Expose() idCard: string; // 身份证
  @Expose() passport: string; // 护照
  @Expose() eepCode: string; // 港澳通行证

  phoneString(): string {
    return (this.areaCode ? '+' + this.areaCode + ' ' : '') + (this.phone ?? '');
  }

}

export class ExistingSecurity {
  @Expose() employerPension: string; // 退休金(雇主供款)
  @Expose() employeePension: string; // 退休金(雇员供款)
  @Expose() hasMedicalSecurity: boolean; // 医疗保障
  @Expose() hasLifeInsurance: boolean; // 人寿保险
  @Expose() lifeInsurance: number; // 人寿保险
  @Expose() diseaseInsurance: number; // 危疾保险保额
  @Expose() accidentInsurance: number; // 意外保险保额
  @Expose() typesMedicalInsurance: string; // 医疗保险种类
}

export class FamilyMember {
  @Type(() => FamilyMemberProperties)
  @Transform((value) => plainToInstance(FamilyMemberProperties, Object.assign(new FamilyMemberProperties(), value.value)))
  @Expose() father: FamilyMemberProperties = new FamilyMemberProperties(); // 父亲
  @Type(() => FamilyMemberProperties)
  @Transform((value) => plainToInstance(FamilyMemberProperties, Object.assign(new FamilyMemberProperties(), value.value)))
  @Expose() mother: FamilyMemberProperties = new FamilyMemberProperties(); // 母亲
  @Type(() => FamilyMemberProperties)
  @Transform((value) => plainToInstance(FamilyMemberProperties, Object.assign(new FamilyMemberProperties(), value.value)))
  @Expose() spouse: FamilyMemberProperties = new FamilyMemberProperties(); // 配偶
  @Type(() => FamilyMemberProperties)
  @Expose() children: FamilyMemberProperties[] = []; // 儿女
  @Type(() => FamilyMemberProperties)
  @Expose() others: FamilyMemberProperties[] = []; // 儿女
}

export class FamilyMemberProperties {
  @Expose() needProvide: boolean; // 是否需要供养
  @Expose() age: number; // 是否需要供养
}


export class FinancialData {
  @Expose() salary: number; // 薪金
  @Expose() rentalIncome: number; // 租金收入
  @Expose() monthlyIncome: number; // 每月总平均收入
  @Expose() otherIncome: number; // 其他收入
  @Expose() otherIncomeExplain: string; // 其他收入说明
  @Expose() personalSpending: number; // 个人开支
  @Expose() householdSpending: number; // 家庭开支
  @Expose() mortgage: number; // 按揭
  @Expose() insurance: number; // 保险
  @Expose() otherSpending: number; // 其他支出
  @Expose() otherSpendingExplain: string; // 其他支出说明
  @Expose() monthlySpending: number; // 每月总平均支出
  @Expose() monthRemaining: number; // 估计每月剩余
  @Expose() ap: number; // AP：可买最大金额 =（收入（每月总平均收入）- 支出（每月总平均支出））* 12 / 2
}

export class Assets {
  @Expose() cash: number; // 现金及存款
  @Expose() investmentAssets: number; // 变现能力高的投资
  @Expose() otherCurrentAssetsExplain: string; // 其他流动资产说明
  @Expose() otherCurrentAssets: number; // 其他流动资产
  @Expose() totalCurrentAssets: number; // 流动资产总额
  @Expose() property: number; // 物业资产
  @Expose() otherNonCurrentAssetsExplain: string; // 其他非流动资产说明
  @Expose() otherNonCurrentAssets: number; // 其他非流动资产
  @Expose() totalNonCurrentAssets: number; // 非流动资产总额
  @Expose() mortgageLoans: number; // 按揭贷款
  @Expose() privateLoans: number; // 私人贷款
  @Expose() otherLoansExplain: string; // 其他债务说明
  @Expose() otherLoans: number; // 其他债务
  @Expose() totalLoans: number; // 总负债额
  @Expose() netAsset: number; // 净资产
  @Expose() remark: string; // 备注
  @Expose() sp: number; // 可买最大金额：SP = (流动资产/2)
}

export class AssessAdvice {
  @Expose() questions: FnaQuestionNoAndResult[]; // questions
  @Expose() remark: string; // 备注
  @Expose() knowRiderDetail: boolean; // 本人（我們）了解附加保障的保費會隨著計劃生效時間而發生明顯的增加 (如適用)
  @Expose() knowRisk: boolean; // 若整付保費計劃涉及有保費融資槓桿，本人（我們）確認明白及接受因利率急劇上升所引致的利率風險及不利影響。(如適用)
  @Expose() knowTaxDeductions: boolean; // 本人（我們）了解稅務扣減只適用於合資格延期年金保費，以及合資格延期年金保費的稅務扣減受制於《税務條例》及保單持有人（作為納稅人）的個人情況。(如適用)
  @Expose() knowTaxDeductionCeiling: boolean; // 本人（我們）了解稅務扣減上限（如適用）為每年$60,000，上限為可扣稅自願性供款和合資格延期年金保費的合計上限，但附加保障的保費（如適用）並不適用於稅務扣減。
  @Expose() agreeToApply: boolean;
}

export class RecommendRider {
  @Expose() productCode: string; // 產品碼
  @Expose() productName: string; // 產品名称
  @Expose() policyPeriod: string; // 保障年限
}

export class RecommendProduct {
  @Expose() productCode: string; // 唯一识别產品碼
  @Expose() productName: string; // 名称
  @Expose() companyName: string; // 保险公司
  @Expose() desc: string; // 简介
  @Expose() categoryCode: string; // 保险分类唯一识别
  @Expose() companyCode: string; // 所属公司唯一识别
  @Expose() logo: string; // LOGO
  @Expose() categoryName: string; // 产品分类名
  @Expose() policyPeriod: string; // 保障年限
  @Expose() riders: RecommendRider[]; // 自选的附加险
  @Expose() tick = false; // 是否选中

  static valueOf(product: Product): RecommendProduct {
    return plainToClass(RecommendProduct, product, {excludeExtraneousValues: true});
  }
}

export class RecommendProductCode {
  @Expose() productCode: string; // 唯一识别產品碼
  @Expose() riders: string[]; // 自选的附加险
}

export class FnaSourceData {
  @Expose() sourceName: string; // 真实姓名
  @Expose() sourceAccount: string; // 自定义账户名
  @Expose() id: number;
  @Expose() updateTime: number;
  @Expose() addTime: number;

  equals(source: FnaSourceData): boolean {
    return (this.sourceAccount === source?.sourceAccount && this.sourceName === source?.sourceName);
  }
}

export class FnaCustomerUpdateReq {
  @Expose() id: number; // 主键
  @Expose() accountName: string; // 下线
  @Type(() => CustomerShot)
  @Expose() customerShot: CustomerShot; // 投保人
  @Type(() => Eyewitness)
  @Expose() eyewitness: Eyewitness; // 见证人
}

export class FnaCreateReq {
  @Expose() customCode: string; // 自定义编号
  @Expose() lastName: string; // 姓
  @Expose() firstName: string; // 名
  @Expose() name: string; // 姓名
  @Expose() lastNamePinyin: string; // 姓-拼音
  @Expose() firstNamePinyin: string; // 名-拼音
  @Expose() pinyin: string; // 英文姓名
  @Expose() gender: Gender; // 性别
  @Expose() title: Title; // 称谓: Mr-先生;Mrs-夫人;Miss-小姐;Ms-女士;Dr-博士
  @Expose() birthday: number; // 出生日期
  @Expose() age: string; // 年龄
  @Expose() areaCode: string; // 区号
  @Expose() phone: string; // 手机号码
  @Expose() email: string; // 电子邮箱
  @Expose() smoke: SmokeCondition; // 吸烟: S-吸烟;NS-不吸烟
  @Expose() companyType: string; // 公司行业
  @Expose() source: string; // 客户来源
  @Expose() idCard: string; // 身份证
  @Expose() passport: string; // 护照
  @Expose() eepCode: string; // 港澳通行证
  @Expose() marriage: MarriageStatus; // 婚姻状况: SINGLE-单身;MARRIED-已婚;DIVORCED-离婚;WIDOWED-丧偶
  @Expose() birthplace: string; // 出生日期
  @Expose() education: string; // 教育程度: PRIMARY-小学及以下;SECONDARY-中学;TERTIARY_OR_ABOVE-大专或以上
  @Expose() residenceAreaCode: string; // 住宅电话区号
  @Expose() residenceTelephone: string; // 住宅电话
  @Expose() longtimeAbroad: boolean; // 是否长期在国外
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() residenceAddress: Address = new Address(); // 户籍地址
  @Expose() companyName: string; // 公司名称
  @Expose() companyAreaCode: string; // 公司电话区号
  @Expose() companyTelephone: string; // 公司电话
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() companyAddress: Address = new Address(); // 公司地址
  @Expose() workYear: number; // 公司电话
  @Expose() position: string; // 职位
  @Expose() salary: number; // 月薪
  @Expose() country: Region; // 国家地区: CHINESE-中国;HONGKONG-中国香港;TAIWAN-中国台湾;MACAO-中国澳门;USA-美国;CANADA-加拿大;OTHER-其他
  @Expose() remark: string; // 备注
  @Expose() eyewitness: Eyewitness; // 见证人
}

export class FnaDraft {
  @Expose() id: number; // 主键
  @Expose() status: FnaStatus; // 状态: UNCOMPLETED-待处理;COMPLETED-已完成;DELETED-已删除
}

/**
 * FNA问题答案结果
 */
export class FnaQuestionResult {
  @Expose() tick: boolean; // 是否勾选
  @Expose() inputBoxValue: { [key: string]: string } = {}; // 自定义字段 key-字段，value输入框值
}

export class FnaQuestionNoAndResult {
  @Expose() questionNo: string; // 问题编号
  @Expose() results: { [key: string]: FnaQuestionResult } = {}; // key-答案key值，value-输入框值

  static getQuestionNoAndResults(fnaQuestions: FnaQuestion[]): FnaQuestionNoAndResult[] {
    const questionNoAndResults: FnaQuestionNoAndResult[] = [];
    for (const fnaQuestion of fnaQuestions) {
      const questionNoAndResult = new FnaQuestionNoAndResult();
      const results: {[key: string]: FnaQuestionResult} = {};
      for (const answer of fnaQuestion.answers) {
        const questionResult = new FnaQuestionResult();
        const inputBoxValue: {[key: string]: string} = {};
        if (answer.inputBox) {
          for (const box of answer.inputBox) {
            inputBoxValue[box.field] = box.value;
          }
        }
        questionResult.inputBoxValue = inputBoxValue;
        questionResult.tick = !!answer.tick;
        results[answer.key] = questionResult;
      }
      questionNoAndResult.questionNo = fnaQuestion.questionNo;
      questionNoAndResult.results = results;
      questionNoAndResults.push(questionNoAndResult);
    }
    return questionNoAndResults;
  }
}

export class FnaFormUpdateReq {
  @Expose() id: number; // 主键
  @Expose() personalData: PersonalData; // 个人资料
  @Expose() existingSecurity: ExistingSecurity; // 现有保障
  @Expose() familyMembers: FamilyMember; // 家庭状况
  @Expose() financialData: FinancialData; // 财务资料
  @Expose() assets: Assets; // 资产
  @Expose() financialAnalysis: FnaQuestionNoAndResult[] = [];
}

/**
 * FNA问题类型
 */
export enum FnaQuestionType {
  FNA = 'FNA',    // 财务问题分析
  EVALUATION = 'EVALUATION' // 评估建议表
}

export class FnaQuestion {
  @Expose() questionNo: string; // 问题编号
  @Expose() questionTitle: string;
  @Expose() subKey: string;
  @Expose() title: string; // 题目描述
  @Expose() needModule: boolean; // 需组件支持
  @Expose() needFill: boolean; // 是否必填
  @Expose() condition: string; // 条件
  @Expose() subStyle: string;
  @Expose() tick: any;
  @Expose() sort: number;
  @Type(() => FnaInputBox)
  @Expose() inputBox: FnaInputBox[]; // 文本框
  @Expose() subQuestions: FnaQuestion[] = []; // 子问题列表
  @Type(() => FnaModuleAndAnswer)
  @Expose() answers: FnaModuleAndAnswer[] = []; // 问题和答案
  get type(): FnaModuleType | string {
    if (this.answers && this.answers.length > 0) {
      return this.answers[0].moduleType;
    }
    return FnaModuleType.UNKNOWN;
  }
}

export enum FnaModuleType {
  CHECKBOX = 'CHECKBOX',
  CHECKBOX_WITH_INPUT = 'CHECKBOX_WITH_INPUT',
  RADIO = 'RADIO',
  RADIO_WITH_INPUT = 'RADIO_WITH_INPUT',
  INPUT_BOX = 'INPUT_BOX',
  INPUT_BOX_WITH_HEADLINE = 'INPUT_BOX_WITH_HEADLINE',
  UNKNOWN = 'UNKNOWN'
}

export class FnaModuleAndAnswer {
  @Expose() moduleType: FnaModuleType | string; // 组件类型
  @Expose() key: string; // 组件key值
  @Expose() description: string; // 描述
  @Expose() tick: boolean; // 是否打勾
  @Expose() sort: any;
  @Expose() defaultTick: boolean; // 默认是否勾选
  @Type(() => FnaInputBox)
  @Expose() inputBox: FnaInputBox[]; // 文本框
  @Expose() headline: string; // 标题
  @Type(() => FnaQuestion)
  @Expose() subQuestion: FnaQuestion = new FnaQuestion(); // 子问题
  @Type(() => FnaQuestion)
  @Expose() subQuestions: FnaQuestion[] = []; // 子问题列表
}

export class FnaInputBox {
  @Expose() prefix: string; // 输入框的前缀
  @Expose() key: string;
  @Expose() title: string;
  @Expose() placeholder: string;
  @Expose() suffix: string; // 输入框的后缀
  @Expose() field: string; // 输入框的字段名
  @Expose() boxType: string; // 输入框样式：TEXT-字符串  NUMBER-数字
  @Expose() moreChoice: { [key: string]: string } = {}; // 必選其一 MAP key-輸入框對應的key，value-輸入框的field
  @Expose() error: string; // 錯誤提醒
  @Expose() value: any; // 输入框的值
}

export class FnaRecommendedUpdateReq {
  @Expose() id: number; // 主键
  @Type(() => RecommendProduct)
  @Expose() customize: RecommendProduct[]; // 自选推荐产品
  @Type(() => RecommendProduct)
  @Expose() recommend: RecommendProduct[]; // 系统推荐产品
  @Type(() => RecommendProduct)
  @Expose() history: RecommendProduct[]; // 历史推荐产品
}

export class FnaProductRecommended {
  @Type(() => RecommendProduct)
  @Expose() customize: RecommendProduct[] = []; // 自选推荐产品
  @Type(() => RecommendProduct)
  @Expose() recommend: RecommendProduct[] = []; // 系统推荐产品
  @Type(() => RecommendProduct)
  @Expose() history: RecommendProduct[] = []; // 历史推荐产品
}

export class FnaAssessAdviceUpdateReq {
  @Expose() id: number; // 主键
  @Expose() productCode: string; // 保险产品唯一标识
  @Type(() => AssessAdvice)
  @Expose() assessAdvice: AssessAdvice = new AssessAdvice(); // 保险产品唯一标识
  @Type(() => RecommendProductCode)
  @Expose() customize: RecommendProductCode[] = []; // 自选推荐产品
  @Type(() => RecommendProductCode)
  @Expose() recommend: RecommendProductCode[] = []; // 系统推荐产品
  @Type(() => RecommendProductCode)
  @Expose() history: RecommendProductCode[] = []; // 历史推荐产品
  @Expose() endProductCodes: string[]; // 最终选择的产品
}

export enum FnaSigner {
  SALES = 'SALES',
  OWNER = 'OWNER'
}

export class FnaSignatureUpdateReq {
  @Expose() id: number; // FNA Id
  @Expose() signedDate: number; // 签名日期
  @Expose() imageBase64: string; // imageBase64
  @Expose() signer: FnaSigner; // PDF签名的对象
  @Expose() signatureType: string; // 新FNA PDF签名的对象
}

