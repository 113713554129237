import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PremiumFinancing, PremiumFinancingProposalSearch } from '../premium-financing-types';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { PremiumFinancingService } from '../premium-financing.service';
import { PremiumFinancingProposalSelectComponent } from '../premium-financing-proposal-select/premium-financing-proposal-select.component';
import { PremiumFinancingBankSelectComponent } from '../premium-financing-bank-select/premium-financing-bank-select.component';
import { PremiumFinancingDiscountSelectComponent } from '../premium-financing-discount-select/premium-financing-discount-select.component';

@Component({
  selector: 'app-premium-financing-generate',
  templateUrl: './premium-financing-generate.component.html',
  styleUrls: ['./premium-financing-generate.component.less']
})
export class PremiumFinancingGenerateComponent implements OnInit {

  loading = false;

  premiumFinancing: PremiumFinancing = new PremiumFinancing();

  @Output()
  premiumFinancingGenerated: EventEmitter<any> = new EventEmitter<any>();

  proposalSearch: PremiumFinancingProposalSearch = new PremiumFinancingProposalSearch();

  constructor(private premiumFinancingService: PremiumFinancingService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
  }

  get canGenerate(): boolean {
    if (this.loading) {
      return false;
    }
    if (!this.premiumFinancing.name) {
      return false;
    }
    if (!this.premiumFinancing.proposalId) {
      return false;
    }
    if (!this.premiumFinancing.premium) {
      return false;
    }
    if (!this.premiumFinancing.premiumTotal) {
      return false;
    }
    if (!this.premiumFinancing.bankInfo) {
      return false;
    }
    return true;
  }


  onProposalSelect(): void {
    const drawerRef = this.drawerService.create<PremiumFinancingProposalSelectComponent, {}, string>({
      nzWidth: 800,
      nzContent: PremiumFinancingProposalSelectComponent,
      nzContentParams: {
        search: this.proposalSearch
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.proposalSelected.subscribe(proposal => {
        this.premiumFinancing.proposalId = proposal.proposalId;
        this.premiumFinancing.proposal = proposal;
        this.premiumFinancing.premium = proposal.premium;
        this.premiumFinancing.premiumTotal = proposal.premiumTotal;
        drawerRef.close();
      });
    });

    drawerRef.afterClose.subscribe(data => {
      const component = drawerRef.getContentComponent();
    });
  }

  get proposalLabel(): string {
    if (!this.premiumFinancing.proposal || !this.premiumFinancing.proposal.proposalId) {
      return '';
    }
    return (this.premiumFinancing.proposal.productName ?? '') + ' | ' + (this.premiumFinancing.proposal.proposalId ?? '');
  }

  onBankSelect(): void {
    const drawerRef = this.drawerService.create<PremiumFinancingBankSelectComponent, {}, string>({
      nzWidth: 800,
      nzContent: PremiumFinancingBankSelectComponent,
      nzContentParams: {}
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.bankInfoSelected.subscribe(bankInfo => {
        this.premiumFinancing.bankInfo = bankInfo;
        drawerRef.close();
      });
    });

    drawerRef.afterClose.subscribe(data => {
      const component = drawerRef.getContentComponent();
    });
  }

  onDiscountSelect(): void {
    const drawerRef = this.drawerService.create<PremiumFinancingDiscountSelectComponent, {}, string>({
      nzWidth: 800,
      nzContent: PremiumFinancingDiscountSelectComponent,
      nzContentParams: {}
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.discountInfoSelected.subscribe(discountInfo => {
        this.premiumFinancing.discountInfo = discountInfo;
        drawerRef.close();
      });
    });

    drawerRef.afterClose.subscribe(data => {
      const component = drawerRef.getContentComponent();
    });
  }

  onReset(): void {
    this.premiumFinancing = new PremiumFinancing();
  }

  onGenerate(): void {
    if (!this.canGenerate) {
      return;
    }
    this.premiumFinancingService.generate(this.premiumFinancing)
      .subscribe(generated => {
        if (generated) {
          this.premiumFinancingGenerated.emit('');
        }
      });
  }

}
