import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataDesensitization, DataMode, PagedResp } from '../../../api/types';
import { Booking, BookingListResp, BookingSearch } from '../../../booking/booking-types';
import { BookingService } from '../../../booking/booking.service';
import { BaseComponent } from '../../../base/base.component';
import { BookingSearchComponent } from '../../../booking/booking-search/booking-search.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { BookingDetailComponent } from '../../../booking/booking-detail/booking-detail.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { plainToInstance } from 'class-transformer';
import { ContactInfoRes } from '../../../contact/contact-types';
import { WorkbenchService } from '../../../workbench/workbench.service';
import { AccountService } from '../../../account/account.service';
import { Tool } from '../../utils/tool';
import { Workbench } from '../../../workbench/workbench-types';

@Component({
  selector: 'app-import-booking-info',
  templateUrl: './import-booking-info.component.html',
  styleUrls: ['./import-booking-info.component.less']
})
export class ImportBookingInfoComponent extends BaseComponent implements OnInit {
  @Input()
  drawerMode: string;
  @Output()
  ContactsInfo: EventEmitter<ContactInfoRes> = new EventEmitter<ContactInfoRes>();
  mode = DataMode.OWN;
  dataDesensitization: DataDesensitization = new DataDesensitization();
  workbench: Workbench;

  loading = false;
  spinning = false;
  importVisible = false;
  importInfoValue = 'holder';
  bookings: PagedResp<BookingListResp>;
  booking: Booking = new Booking();

  search: BookingSearch = new BookingSearch();

  constructor(private bookingService: BookingService,
              private drawerService: NzDrawerService,
              private workbenchService: WorkbenchService,
              private translate: TranslateService,
              private accountService: AccountService,
              private toastr: ToastrService) {
    super();
  }

  ngOnInit(): void {
    this.load();
    this.workbench = this.workbenchService.workbench;
    this.dataDesensitization = this.accountService.dataDesensitization;
  }

  load(): void {
    this.loading = true;
    this.bookingService.list(this.search, DataMode.OWN)
      .subscribe(
        data => {
          this.loading = false;
          this.bookings = data;
        },
        error => {
          this.loading = false;
        })
      .disposedBy(this.disposeBag);
  }
  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.load();
  }

  onReload(resetPageNum = true): void {
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.load();
  }

  onBookingSearch(): void {
    const drawerRef = this.drawerService.create<BookingSearchComponent, { value: BookingSearch }, string>({
      nzWidth: 800,
      nzContent: BookingSearchComponent,
      nzContentParams: {
        search: this.search
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.bookingSearch
        .subscribe(
          search => {
            this.search = search;
            this.onSearch(this.search.pageNum);
            drawerRef.close();
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onBookingDetail(bookingNo: string): void {
    console.log('onBookingDetail');
    this.spinning = true;
    this.bookingService.detail(bookingNo)
      .subscribe(
        bookingDetail => {

          this.spinning = false;
          this.booking = bookingDetail.toBooking();
          this.importVisible = true;
        },
        error => {
          this.spinning = false;
        }).disposedBy(this.disposeBag);
  }

  openImportDrawer(): void {
    if (this.importInfoValue === 'holder') {
      if (this.booking.ownerType === 'CORPORATE') {
        this.translate.get('CompanyOwnerImport').subscribe(
          (res) => {
            this.toastr.warning(res);
          }
        );
      } else {
        console.log(this.booking.owner);
        const info = plainToInstance(ContactInfoRes, this.booking.owner);
        this.importVisible = false;
        this.ContactsInfo.emit(info);
      }
    }
    if (this.importInfoValue === 'insured') {
      if (this.booking.insuredType === 'CORPORATE') {
        this.translate.get('CompanyInsuredImport').subscribe(
          (res) => {
            this.toastr.warning(res);
          }
        );
      } else {
        console.log(this.booking.insured);
        const info = plainToInstance(ContactInfoRes, this.booking.insured);
        console.log(info);
        this.importVisible = false;
        this.ContactsInfo.emit(info);
      }
    }
  }

  dynamicEncryption(keyName, val): string {
    let isShow = val;
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = Tool.dataMasking(val);
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = Tool.dataMasking(val);
        }
      });
    }
    return isShow;
  }


}
