import { Component, OnInit } from '@angular/core';
import { WorkbenchService } from '../workbench/workbench.service';
import { Workbench } from '../workbench/workbench-types';
import { DataService } from '../shared/service/data.service';

@Component({
  selector: 'app-pre-sales-main',
  templateUrl: './pre-sales-main.component.html',
  styleUrls: ['./pre-sales-main.component.less']
})
export class PreSalesMainComponent implements OnInit {

  spinning = false;
  workbench: Workbench;

  constructor(private workbenchService: WorkbenchService,
              public dataService: DataService) { }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
  }

}
