import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { HttpService } from '../../core/http.service';

// import { ElectronService } from '../../core/electron.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  // showSaveDialog(filename: string): void {
  //   const dialog = this.electronService.remote.dialog;
  //   const win = this.electronService.win;
  //   const app = this.electronService.app;
  //   const path = app.getPath('downloads') + '/' + filename;
  //   const paths = dialog.showSaveDialogSync(win, {
  //     title: '選擇保存位置',
  //     properties: ['showOverwriteConfirmation', 'createDirectory'],
  //     defaultPath: path,
  //     buttonLabel: '保存'
  //   });
  // }

  download(url: string, fileName: string): void {

    // if (this.electronService.isElectron) {
    //   this.electronService.win.webContents.downloadURL(url);
    //   return;
    // }

    this.http.download(url, {
      headers: null,
      params: null,
      responseType: 'blob',
      observe: 'response'
    }).subscribe(
      response => {

        console.log(fileName);
        const headers = response.headers;
        const contentType = headers.get('content-type');
        const link = document.createElement('a');
        const blob = new Blob([response.body], {type: contentType});
        const objectURL = window.URL.createObjectURL(blob);
        link.setAttribute('href', objectURL);
        link.setAttribute('download', this.decodeURIComponentSafely(fileName));
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(objectURL);
      },
      error => {
      }
    );
  }

  decodeURIComponentSafely(uri): string {
    try {
      return decodeURIComponent(uri);
    } catch (e) {
      console.log('URI Component not decodable: ' + uri);
      return uri;
    }
  }

}
