import { Component, OnInit } from '@angular/core';
import { AmlSummaryList, AmlSummary, PagedResp, AMLSummaryDetail } from '../../../api/types';
import { AmlService } from '../../aml.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { AmlMonitorEntryDetailComponent } from '../aml-monitor-entry-detail/aml-monitor-entry-detail.component';

@Component({
  selector: 'app-aml-monitor-summary-detail',
  templateUrl: './aml-monitor-summary-detail.component.html',
  styleUrls: ['./aml-monitor-summary-detail.component.less']
})
export class AmlMonitorSummaryDetailComponent implements OnInit {

  info: PagedResp<AMLSummaryDetail>;

  stateModified = false;

  loading = false;

  childrenVisible = false;

  search: AmlSummary = new AmlSummary();

  constructor(
    private amlService: AmlService,
    private drawerService: NzDrawerService
  ) {
  }

  ngOnInit(): void {

  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.amlService.summaryDetail(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.info = data;
        },
        error => {
          this.loading = false;
        });
  }

  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search = new AmlSummary();
    }
    this.amlService.summaryDetail(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.info = data;
        },
        error => {
          this.loading = false;
        });

  }

  omAMlSummaryListDetail(id: number): void {
    this.loading = true;
    this.amlService.entryDetail(id)
      .subscribe(info => {
          this.loading = false;

          const drawerRef = this.drawerService.create<AmlMonitorEntryDetailComponent, { value: any }, string>({
            nzWidth: 1024,
            nzMaskClosable: true,
            nzContent: AmlMonitorEntryDetailComponent,
            nzContentParams: {
              info
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            this.childrenVisible = true;
          });

          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();
            this.childrenVisible = false;
            if (component.stateModified) {
              this.onReload(false);
            }
          });
        },
        error => {
          this.loading = false;
        });

  }
}
