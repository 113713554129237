<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!listArr">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="listArr" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="listArr.list"
                [nzPageIndex]="listArr.pageNum"
                [nzPageSize]="listArr.pageSize"
                [nzTotal]="listArr.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{x: '1450px'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'SalesReportList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div class="col-radio-solid" style="width: 100%; display: flex; justify-content: flex-end;">
                <nz-radio-group [(ngModel)]="search.module" nzButtonStyle="solid"
                                [nzSize]="'small'"
                                (ngModelChange)="onReload(true)">
                  <label nz-radio-button nzValue="POLICY">{{'Policys' | translate}}</label>
                  <label nz-radio-button nzValue="UNDERWRITING">{{'Underwriting' | translate}}</label>
                </nz-radio-group>

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'Search' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onFilterate()">
                  <img alt="" width="22" src="assets/images/ic_search.svg">
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ listArr.total }}</span>
          </div>
        </ng-template>
        <thead>
        <tr>
          <th *ngIf="search.module === 'POLICY'" nzLeft nzWidth="165px" nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'PolicyNo' | translate">{{'PolicyNo' | translate}}</span>
          </th>
          <th *ngIf="search.module === 'UNDERWRITING'" nzLeft nzWidth="165px" nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'UnderwritingNumber' | translate">{{'UnderwritingNumber' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'ClientName' | translate">{{'ClientName' | translate}}</span>
          </th>
          <th nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'ProductName' | translate">{{'ProductName' | translate}}</span>
          </th>
          <th nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'Insurer' | translate">{{'Insurer' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'PolicyDate' | translate">{{'PolicyDate' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'TotalCommission' | translate">{{'TotalCommission' | translate}}</span>
          </th>
          <th nzRight nzWidth="160px" nzAlign="center">
            <span>{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let list of listArr.list; let i = index" appThrottleClick (throttleClick)="onDetail(list)">
          <td *ngIf="search.module === 'POLICY'" nzLeft nzAlign="center"> {{ list.policyNo }}</td>
          <td *ngIf="search.module === 'UNDERWRITING'" nzLeft nzAlign="center"> {{ list.underwritingNo }}</td>
          <td nzAlign="center"><span class="">{{ list.ownerName }}</span></td>
          <td nzAlign="center"><span class="">{{ list.productName }}</span></td>
          <td nzAlign="center"><span class="">{{ list.companyName }}</span></td>
          <td nzAlign="center"><span class="">{{ list.policyDate }}</span></td>
          <td nzAlign="center"><span class="">{{ list.totalCommission }}</span></td>
          <td nzRight nzAlign="center">
            <div class="functions" nz-tooltip [nzTooltipTitle]="'Detail' | translate">
              <a nz-button nzType="link" nzSize="small">
                <img alt="" width="20" src="../../../assets/images/ic_details.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>
