<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!underwritings">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="underwritings" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="underwritings.list"
                [nzPageIndex]="underwritings.pageNum"
                [nzPageSize]="underwritings.pageSize"
                [nzTotal]="underwritings.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{ x: '1515'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'UnderwritingFollowUp' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'UnderwritingSearch' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="openSearch()"
                >
                  <img alt="" width="22" src="assets/images/ic_search.svg">
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ underwritings.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="165px">
            <span nz-tooltip
                  [nzTooltipTitle]="'UnderwritingNumber' | translate">{{'UnderwritingNumber' | translate}}</span>
          </th>
          <th nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="'answer39' | translate">{{'answer39' | translate}}</span>
          </th>
          <th *ngIf="isShowBusiness" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'BusinessNumber' | translate">{{'BusinessNumber' | translate}}</span>
          </th>
          <th nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Referee' | translate">{{'Referee' | translate}}</span>
          </th>
          <th nzWidth="220px">
            <span nz-tooltip [nzTooltipTitle]="'ProductName' | translate">{{'ProductName' | translate}}</span>
          </th>

          <th nzWidth="220px">
            <span nz-tooltip [nzTooltipTitle]="'Holder' | translate">{{'Holder' | translate}}</span>
          </th>
          <th nzWidth="220px">
            <span>{{'Insured' | translate}}</span>
          </th>
          <th nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'PaymentTerm' | translate">{{'PaymentTerm' | translate}}</span>
          </th>
          <th nzWidth="130px"><span nz-tooltip [nzTooltipTitle]="'Status' | translate">{{'Status' | translate}}</span>
          </th>
          <th nzWidth="220px">
            <span nz-tooltip [nzTooltipTitle]="'SignDate' | translate">{{'SignDate' | translate}}</span>
          </th>
          <th nzRight nzWidth="160px">
            <span>{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let underwriting of underwritings.list; let i = index"
            appThrottleClick (throttleClick)="onUnderwritingDetail(underwriting.underwritingNo)"
        >
          <td nzLeft>
            <span style="font-weight: bold; font-size: 12px">
              #{{ underwriting.underwritingNo }}
            </span>
          </td>
          <td>
            <span style="font-weight: bold; font-size: 12px">
              {{ underwriting.policyNo }}
            </span>
          </td>
          <td *ngIf="isShowBusiness"><span>{{ underwriting.newBusinessNumber || 'N/A' }}</span></td>
          <td>
            <div>
              <span>{{ dynamicEncryption('salesName', underwriting.salesName) }}</span>
              <span>{{ dynamicEncryption('salesName', underwriting.accountName) }}</span>
            </div>
            <div *ngIf="workbench.secondAccountName">
              <span>{{ dynamicEncryption('salesName', underwriting.secondSalesName) }}</span>
              <span>{{ dynamicEncryption('salesName', underwriting.secondAccountName) }}</span>
            </div>
          </td>
          <td>
            <div style="display: flex; align-items: center;">
              <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="underwriting.companyLogo" alt=""/>
              <span>{{ underwriting.productName || 'N/A' }}<span
                *ngIf="underwriting.productYear">&nbsp; ({{ underwriting.custom ? (underwriting.productYear | yearConversion) : (underwriting.productYear | yearsOrMonthOrAge)}})</span></span>
            </div>
          </td>
          <td>
            <span>{{(dynamicEncryption('name', underwriting.name))}}</span>
            <br>
            <span>{{(dynamicEncryption('name', underwriting.pinyin))}}</span>
          </td>
          <td>{{ dynamicEncryption('insuredName', underwriting.insuredName) }}<br>{{dynamicEncryption('insuredName', underwriting.insuredPinyin)}}</td>
          <td>{{ underwriting.productYear }}</td>
          <td>
            <div [ngSwitch]="underwriting.status">
              <div>
                <span *ngSwitchCase="'CHECKING'" class="text-warning">●</span>
                <span *ngSwitchCase="'UNDERWRITING'" class="text-warning">●</span>
                <span *ngSwitchCase="'UNDERWRITE'" class="text-warning">●</span>
                <span *ngSwitchCase="'SENDING'" class="text-warning">●</span>
                <span *ngSwitchCase="'PENDING_PREMIUM'" class="text-warning">●</span>
                <span *ngSwitchCase="'FINISHED'" class="text-success">●</span>
                <span *ngSwitchCase="'FINISHED_PENDING_PREMIUM'" class="text-warning">●</span>
                <span *ngSwitchCase="'EFFECTIVE'" class="text-success">●</span>
                <span *ngSwitchCase="'DECLINED'" class="text-cancel">●</span>
                <span *ngSwitchCase="'DELETED'" class="text-warning">●</span>
                <span> {{ underwriting.status | metadataTranslate: 'underwritingStatus' }}</span>
              </div>
            </div>
          </td>
          <td>
            {{ (underwriting.signDate | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
          </td>
          <td nzRight>
            <div class="functions">
              <!--              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'LeaveMessage' | translate"-->
              <!--                 appThrottleClick (throttleClick)="onLeaveMessage(underwriting)">-->
              <!--                <img alt="" width="20" src="../../../assets/images/ic_leave_message.svg">-->
              <!--              </a>-->
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Preview' | translate"
                 style="padding-right: 0"
                 appThrottleClick
                 (throttleClick)="onUnderwritingDetail(underwriting.underwritingNo); $event.stopPropagation();">
                <img alt="" width="20" src="../../../assets/images/ic_details.svg">
                <div *ngIf="underwriting.reminder" class="red-dot"></div>
              </a>

              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'LeaveMessage'| translate"
                 style="padding-right: 0"
                 appThrottleClick
                 (throttleClick)="onUnderwritingDialog(underwriting); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_leave_message.svg">
                <div *ngIf="(underwriting.unReadCount > 0 || underwriting.unConfirmCount > 0)" class="red-dot"></div>
              </a>
              <!--              <a *ngIf="underwriting.status === 'COMPLETED'" nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Download' | translate"-->
              <!--                 appThrottleClick (throttleClick)="onDownload(underwriting)">-->
              <!--                <img alt="" width="20" src="../../../assets/images/ic_download.svg">-->
              <!--              </a>-->
            </div>
            <div *ngIf="underwriting.unFinishPending"
                 style="position: absolute;top: 0;right: 0;background-color: #f94949;color: #fff;padding: 0 5px;border-radius: 4px 0 0 4px;font-size: 12px;">
              {{instance.isFone() ? ('Pending' | translate) : 'Pending'}}({{underwriting.unFinishPending}})
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>
