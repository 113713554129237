<div class="sub-section-title">
  <span style="color: #555555">{{fnaQuestion.title}}</span>
  <img *ngIf="fnaQuestion.needFill" alt="" width="24" src="assets/images/ic_required.svg">
</div>

<div *ngFor="let answer of fnaQuestion.answers; let answerIndex = index;">
  <div>
    <div [ngSwitch]="answer.moduleType" style="padding: 8px 0 8px 0;">
      <div *ngSwitchCase="'CHECKBOX'">
        <label nz-checkbox [(ngModel)]="answer.tick" [disabled]="disabled" (nzCheckedChange)="onAnswerCheckbox(fnaQuestion, answerIndex, $event)">
          {{answer.description}}
        </label>
      </div>
      <div *ngSwitchCase="'CHECKBOX_WITH_INPUT'">
        <label nz-checkbox [(ngModel)]="answer.tick" [disabled]="disabled" (nzCheckedChange)="onAnswerCheckbox(fnaQuestion,answerIndex, $event)">
          {{answer.description}}
        </label>
        <div *ngIf="answer.inputBox">
          <app-fna-answer-input-boxes [(inputBox)]="answer.inputBox" [disabled]="disabled" (inputBoxChange)="onQuestionChange(answer,answerIndex)"></app-fna-answer-input-boxes>
        </div>
      </div>
      <div *ngSwitchCase="'RADIO'">
        <label nz-radio [(ngModel)]="answer.tick" appThrottleClick [disabled]="disabled" (throttleClick)="onAnswerRadio(fnaQuestion, answerIndex)">
          {{answer.description}}
        </label>
      </div>
      <div *ngSwitchCase="'RADIO_WITH_INPUT'">
        <label nz-radio [(ngModel)]="answer.tick" appThrottleClick (throttleClick)="onAnswerRadio(fnaQuestion, answerIndex)" [disabled]="disabled">
          {{answer.description}}
        </label>
        <div *ngIf="answer.inputBox">
          <app-fna-answer-input-boxes [(inputBox)]="answer.inputBox" (inputBoxChange)="onQuestionChange(answer,answerIndex)" [disabled]="disabled"></app-fna-answer-input-boxes>
        </div>
      </div>
      <div *ngSwitchCase="'INPUT_BOX'">
        <div>{{answer.description}}</div>
        <div *ngIf="answer.inputBox">
          <app-fna-answer-input-boxes [(inputBox)]="answer.inputBox" (inputBoxChange)="onQuestionChange(answer, answerIndex)" [disabled]="disabled"></app-fna-answer-input-boxes>
        </div>
      </div>
      <div *ngSwitchCase="'INPUT_BOX_WITH_HEADLINE'">
        <div>{{answer.headline}}</div>
        <div>{{answer.description}}</div>
        <div *ngIf="answer.inputBox">
          <app-fna-answer-input-boxes [(inputBox)]="answer.inputBox" (inputBoxChange)="onQuestionChange(answer,answerIndex)" [disabled]="disabled"></app-fna-answer-input-boxes>
        </div>
      </div>
    </div>
  </div>
</div>
