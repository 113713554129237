<div class="drawer-container">
  <div class="drawer-title" style="margin-bottom: 40px">
    <nz-breadcrumb nzSeparator=">" style="font-size: 20px;line-height: 22px">
      <nz-breadcrumb-item>{{'ContactsInfo' | translate}}</nz-breadcrumb-item>
      <nz-breadcrumb-item>{{'FamilyMember' | translate}}</nz-breadcrumb-item>
      <nz-breadcrumb-item>{{'AddFamilyMember' | translate}}</nz-breadcrumb-item>
    </nz-breadcrumb>
  </div>

  <div class="section-wrapper">
    <app-property-select [title]="'Relationship' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Relationship' | translate)"
                         [options]="relationshipOptions"
                         [required]="true"
                         [(value)]="relationship"></app-property-select>

    <app-property-pick [title]="'SelectContacts' | translate"
                       [required]="true"
                       appThrottleClick (throttleClick)="onContactSelection()"
                       [bottomLineVisible]="false"
                       [value]="contactSelect?.name"></app-property-pick>
  </div>
  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
