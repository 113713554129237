<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!salesListData">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="salesListData" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="salesListData.list"
                [nzPageIndex]="salesListData.pageNum"
                [nzPageSize]="salesListData.pageSize"
                [nzTotal]="salesListData.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                [nzScroll]="{ x: '1215'}"
                (nzPageIndexChange)="onSearch($event)">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'CommissionList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default" nzSize="large" nzShape="circle" style=" margin-left: 10px;"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight">
                  <img alt="" width="22" src="assets/images/ic_filter.svg">
                </button>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>

        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ salesListData.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzAlign="center" nzWidth="225px">
            <span nz-tooltip [nzTooltipTitle]="'BillDate' | translate">{{'BillDate' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="225px">
            <span nz-tooltip [nzTooltipTitle]="'BillQuantity' | translate">{{'BillQuantity' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="225px">
            <span nz-tooltip [nzTooltipTitle]="'BillAmount' | translate">{{'BillAmount' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="185px">
            <span nz-tooltip [nzTooltipTitle]="'PendingCount' | translate">{{'PendingCount' | translate}}</span>
          </th>
          <th nzRight nzWidth="120px" nzAlign="center">
            <span>{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let list of salesListData.list; let i = index">
          <td nzLeft nzAlign="center">
            <span style="font-weight: bold; font-size: 12px">
              {{ list.billDate }}
            </span>
          </td>
          <td nzAlign="center">
            <span>{{ list.billCount}}</span>
          </td>
          <td nzAlign="center">
            <span>{{ toDecimal(list.amount | number) }}</span>
          </td>
          <td nzAlign="center">
            <span style="font-weight: bold; font-size: 12px">
              {{ list.pendingCount }}
            </span>
          </td>
          <td nzRight nzAlign="center">
            <div class="functions" nz-tooltip [nzTooltipTitle]="'PolicyList' | translate">
              <a nz-button nzType="link" nzSize="small"
                 appThrottleClick (throttleClick)="onCommissionList(list.billDate); $event.stopPropagation();">
                <img alt="" width="20" src="../../../assets/images/ic_details.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
  <app-water-mark [xSpace]="60" [ySpace]="90"></app-water-mark>
</div>

<ng-template #filterTemplate>
  <div style="width: 300px; padding: 5px;">
<!--    <app-water-mark [xWidth]="60" [yHeight]="50"></app-water-mark>-->
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <!-- 账单日期 -->
    <app-property-datetime [title]="'BillDate' | translate"
                           [formatter]="'yyyy-MM-dd'"
                           [placeholder]="('PleaseSelect' | translate) + ('BillDate' | translate)"
                           [(value)]="salesSearch.billDate"></app-property-datetime>
    <!-- 保單號 -->
    <app-property-input [title]="'PolicyNo' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('PolicyNo' | translate)"
                        [(value)]="salesSearch.policyNo"></app-property-input>
    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onReload()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
