<div class="value-box">
  <div class="value-box-input-box"
       [style.cursor]="isDisabled ? 'not-allowed' : 'pointer'"
       [style.background-color]="isDisabled ? '#FAFBFD' : ''"
       [style.opacity]="isDisabled ? 0.8 : 1"
       [class]="isBorder ? 'is-border' : ''"
       nz-popover [nzPopoverContent]="filterTemplate"
       [(nzPopoverVisible)]="filterPopoverVisible"
       [nzPopoverTrigger]="isDisabled ? null : 'click'" (nzPopoverVisibleChange)="closeChange($event)">
    <div [ngSwitch]="modeType" style="margin-right: 2px;"
         [style]="modeType === 'String' ? 'width: calc(100% - 12px);' : 'width: calc(100% - 2px);'">
      <div *ngSwitchCase="'String'" style="width: calc(100% - 2px);">
        <div *ngIf="nationality" class="ellipsis-name" style="color: #000000d9;">{{nationalityString(nationality)}}</div>
        <div *ngIf="!nationality" class="ellipsis-name" style="color: rgba(0,0,0,0.25);">
          {{'PleaseChoose' | translate}}</div>
      </div>
      <div *ngSwitchCase="'Number'" style="width: calc(100% - 2px);">
        <div *ngIf="nationality" class="ellipsis-name" style="color: #000000d9;">{{nationality}}</div>
        <div *ngIf="!nationality" class="ellipsis-name" style="color: rgba(0,0,0,0.25);">
          {{'PleaseChoose' | translate}}</div>
      </div>
    </div>
    <i *ngIf="!nationality" nz-icon nzType="down" nzTheme="outline" style="color: rgba(0,0,0,0.25);"></i>
    <i *ngIf="isDisabled ? false : nationality" nz-icon nzType="close-circle" nzTheme="outline"
       style="color: rgba(0,0,0,0.25);"
       appThrottleClick (throttleClick)="onClearClick()"></i>
  </div>

  <ng-template #filterTemplate>
    <div style="width: 300px;height: 440px;">
      <div style="width: 100%;padding-right: 0;">
        <div class="filter-header">
          <nz-input-group class="filter-box-input" [nzPrefix]="prefixIconSearch">
            <input type="text" nz-input
                   [placeholder]="('PleaseEnter' | translate) + ('Country/Region' | translate)"
                   [(ngModel)]="filterSearchName"
                   (ngModelChange)="onSearch($event)"/>
          </nz-input-group>
          <ng-template #prefixIconSearch>
            <i nz-icon nzType="search" nzTheme="outline" style="color: #CCCCCC"></i>
          </ng-template>
        </div>
      </div>

      <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
      <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
        <div class="filter-content" #anchorContainer
             style="height: 410px;overflow: hidden;overflow-y: auto;box-sizing: border-box;margin-top: 6px;"
             [style]="!filterSearchName ? '' : 'padding-right: 16px'">
          <div *ngIf="!filterSearchName">
            <div id="☆" style="margin: 8px 0;color: #404040;font-weight: bolder;">
              <span>{{'PopularRegion' | translate}}</span>
            </div>
            <!-- 热门 -->
            <div *ngIf="countryGroups && countryGroups.hotCountries && countryGroups.hotCountries.length > 0">
              <div *ngFor="let region of countryGroups.hotCountries">
                <div class="country-item" appThrottleClick (throttleClick)="onRegionClick(region)">
                  <div style="display: flex;justify-content: space-between;">
                    <span>{{ region.name }}</span><span>+{{ region.areaCode }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- 分组 -->
            <div >
              <div *ngIf="countryGroups && countryGroups.groupCountries && countryGroups.groupCountries.length > 0">
                <div *ngFor="let groupCountry of groupCountries">
                  <div>
                    <div [id]="groupCountry.group"
                         style="font-weight: bold; font-size: 16px; margin-top: 5px">{{groupCountry.group}}</div>
                    <div *ngIf="groupCountry.list && groupCountry.list.length > 0">
                      <div *ngFor="let region of groupCountry.list" class="country-item"
                           appThrottleClick (throttleClick)="onRegionClick(region)">
                        <div style="display: flex;justify-content: space-between;">
                          <span>{{ region.name }}</span><span>+{{ region.areaCode }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="alphabet-bar">
              <nz-anchor [nzContainer]="anchorContainer">
                <nz-link *ngFor="let group of setOfGroup" [nzHref]="'#' + group" [nzTitle]="group"></nz-link>
              </nz-anchor>
            </div>
          </div>
          <div *ngIf="filterSearchName">
            <div *ngIf="countries && countries.length > 0">
              <div *ngFor="let region of countries" class="country-item" appThrottleClick
                   (throttleClick)="onRegionClick(region)">
                <div style="display: flex;justify-content: space-between;">
                  <span>{{ region.name }}</span><span>+{{ region.areaCode }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-spin>

    </div>
  </ng-template>
</div>

