import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Currency, MedicalPlan, Rider } from '../../../api/types';
import { ProductSelectionComponent, ProductSelectionType } from '../product-selection/product-selection.component';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { PropertySelectOption } from '../property-select/property-select.component';
import { MetadataService } from '../../service/metadata.service';
import { RiderMode } from '../product-riders-edit/product-riders-edit.component';
import { Product } from '../../../product/product-types';
import { RecommendProduct } from '../../../fna/fna-types';

export class RiderEntity {
  rider: Rider;
  index: number;
}

@Component({
  selector: 'app-product-rider-edit',
  templateUrl: './product-rider-edit.component.html',
  styleUrls: ['./product-rider-edit.component.less']
})
export class ProductRiderEditComponent implements OnInit {

  drawerRef: NzDrawerRef<ProductRiderEditComponent, string>;

  mode = RiderMode.DEFAULT;

  rider: Rider = new Rider();

  index: number;

  primaryProduct: Product = new Product();

  currency: Currency;

  product: Product = new Product();

  isNewFna = false;
  recommendProduct: RecommendProduct;

  guaranteedAreaOptions: PropertySelectOption[] = [];
  medicalLevelOptions: PropertySelectOption[] = [];
  deductibleOptions: PropertySelectOption[] = [];
  supplementaryBenefitOptions: PropertySelectOption[] = [];

  @Output()
  riderSaved: EventEmitter<RiderEntity> = new EventEmitter<RiderEntity>();

  constructor(private drawerService: NzDrawerService,
              private metadataService: MetadataService) {
  }

  ngOnInit(): void {
  }

  onProductSelection(): void {

    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.RIDER,
        copyProduct: this.product,
        isNewFna: this.isNewFna,
        recommendProduct: this.recommendProduct,
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      if (!this.isNewFna) {
        selectionComponent.search.companyCode = this.primaryProduct.companyCode;
      }
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.rider.medicalPlan = new MedicalPlan();
            this.productSelected(product);
            drawerRef.close();
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  productSelected(product: Product): void {
    this.product = product;
    this.rider.productCode = this.product.productCode;
    this.rider.productName = this.product.productName;
    this.rider.categoryCode = this.product.categoryCode;
    this.rider.policyPeriod = this.product.policyPeriod;

    if (this.product.categoryCode === 'MEDICAL') {
      this.guaranteedAreaOptions = [];
      if (this.product.medicalOptions?.regions) {
        for (const region of this.product.medicalOptions?.regions) {
          this.guaranteedAreaOptions.push(new PropertySelectOption(this.metadataService.translate('medicalRegion', region), region));
        }
      }

      if (this.product.medicalOptions?.levels) {
        this.medicalLevelOptions = [];
        for (const level of this.product.medicalOptions?.levels) {
          this.medicalLevelOptions.push(new PropertySelectOption(this.metadataService.translate('medicalLevel', level), level));
        }
      }

      if (this.product.medicalOptions?.deductibles) {
        this.deductibleOptions = [];
        this.product.medicalOptions?.deductibles.forEach((deductible, index, array) => {
          if (deductible.currency === this.currency) {
            for (const amount of deductible.amounts) {
              this.deductibleOptions.push(new PropertySelectOption(amount.toString(), amount));
            }
          }
        });
      }

      if (this.product.medicalOptions?.supplementaryBenefits) {
        this.supplementaryBenefitOptions = [];
        for (const supplementaryBenefit of this.product.medicalOptions?.supplementaryBenefits) {
          this.supplementaryBenefitOptions.push(new PropertySelectOption(this.metadataService.translate('supplementaryBenefit', supplementaryBenefit), supplementaryBenefit));
        }
      }
    }
  }

  get canSave(): boolean {
    if (!this.rider.productCode) {
      return false;
    }
    if (!this.rider.premium) {
      return false;
    }
    if (!this.rider.sumAssured) {
      return false;
    }
    return true;
  }

  onSave(): void {

    if (!this.canSave) {
      return;
    }

    const riderEntity = new RiderEntity();
    riderEntity.rider = this.rider;
    riderEntity.index = this.index;

    this.riderSaved.emit(riderEntity);
    this.drawerRef?.close();
  }
}
