<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!amls">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="amls">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="amls.list"
                [nzPageIndex]="amls.pageNum"
                [nzPageSize]="amls.pageSize"
                [nzTotal]="amls.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'AmlSummary' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">

                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight"
                        style=" margin-left: 10px;">
                  <img alt="" width="24" src="assets/images/ic_filter.svg">
                </button>


              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ amls.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th >
            <span>{{'RunTime' | translate}}</span>
          </th>
          <th >
            <span>{{'MonitorNumber' | translate }}</span>
          </th>
          <th >
            <span>{{'AlarmNumber' | translate}}</span>
          </th>
          <th nzRight nzWidth="160px">
            <span>{{'Action' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let aml of amls.list; let i = index" appThrottleClick (throttleClick)="onAmlInspectDetail(aml.id)">
          <td >
            <span style="font-weight: bold; font-size: 13px;">
              {{aml.date | date:'yyyy-MM-dd'}}
            </span>
          </td>
          <td>
            <span>
              {{aml.entryCounts}}
            </span>
          </td>
          <td>
            <span>
              {{aml.openAlertCounts}}
            </span>
          </td>

          <td nzRight>
            <div class="functions">
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate" appThrottleClick (throttleClick)="onAmlInspectDetail(aml.id); $event.stopPropagation();">
                         <img alt="" width="20" src="assets/images/ic_details.svg">
                </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>

<!-- 多语言气泡Template -->
<ng-template #filterTemplate>
  <div style="width: 255px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <!-- 創建時間 -->
    <app-property-datetime [title]="'CreateTime' | translate"
                           (valueChange)="onTimeChange($event)"
                           [(value)]="createTime"></app-property-datetime>

    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>  </div>
</ng-template>
<!-- 删除弹出框 -->
<ng-template #tplTitle>
  <span>
    {{ 'OperationReminder' | translate }}
  </span>
</ng-template>
<ng-template #tplContent>
  <span>
    {{ 'RemoveCustomer' | translate }}
  </span>
</ng-template>
<!-- <ng-template #tplFooter>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()">{{ Cancel | translate }}</button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="destroyTplModal(ref)" [nzLoading]="tplModalButtonLoading">
    {{ Confirm | translate}}
  </button>
</ng-template> -->
