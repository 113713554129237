<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'DiscountInfo' | translate}}
    </div>

    <nz-row [nzGutter]="12">
      <nz-col [nzSpan]="24">
        <app-property-input [displayMode]="'vertical'"
                            [title]="'Name' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('Name' | translate)"
                            [(value)]="search.name"
                            (valueChange)="onSearchParamChange()"></app-property-input>
      </nz-col>
    </nz-row>

    <div *ngFor="let info of discountInfos" appThrottleClick (throttleClick)="onDiscountInfo(info)">
      <div class="section-wrapper bank-info">
        <nz-row [nzGutter]="12">
          <nz-col [nzSpan]="24">
            <div style="font-size: 18px">{{ info.name }}</div>
          </nz-col>
        </nz-row>
        <nz-row [nzGutter]="12">
          <nz-col [nzSpan]="8">
            <app-property-text [displayMode]="'vertical'"
                               [bottomLineVisible]="false"
                               [title]="'FirstYearPremiumDiscount' | translate"
                               [valueColor]="'#003781'"
                               [value]="info.firstYearPremiumDiscount + '%'"></app-property-text>
          </nz-col>
          <nz-col [nzSpan]="8">
            <app-property-text [displayMode]="'vertical'"
                               [bottomLineVisible]="false"
                               [title]="'AnnualPremiumDiscount' | translate"
                               [valueColor]="'#003781'"
                               [value]="info.annualPremiumDiscount"></app-property-text>
          </nz-col>
          <nz-col [nzSpan]="8">
            <app-property-text [displayMode]="'vertical'"
                               [bottomLineVisible]="false"
                               [title]="'PremiumCashback' | translate"
                               [valueColor]="'#003781'"
                               [value]="info.premiumCashback + '%'"></app-property-text>
          </nz-col>
        </nz-row>
      </div>
    </div>

  </nz-spin>
</div>
