import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BankInfo, BankInfoSetting, BankInfoSearch } from '../../proposal/proposal-types';
import { ProposalService } from '../../proposal/proposal.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-premium-financing-bank-select',
  templateUrl: './premium-financing-bank-select.component.html',
  styleUrls: ['./premium-financing-bank-select.component.less']
})
export class PremiumFinancingBankSelectComponent implements OnInit {

  loading = false;

  search: BankInfoSearch = new BankInfoSearch();

  bankInfoSettings: BankInfoSetting[] = [];

  bankInfos: BankInfo[] = [];

  @Output()
  bankInfoSelected: EventEmitter<BankInfo> = new EventEmitter<BankInfo>();

  searchParamChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private proposalService: ProposalService) {
  }

  ngOnInit(): void {

    this.onLoad();

    this.searchParamChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          this.onLoad();
        }
      );
  }

  onLoad(): void {
    this.loading = true;
    this.proposalService.allBankInfo(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.bankInfos = [];
          for (const infoSetting of data) {
            const years = infoSetting.loanYears ?? [];
            for (const loanYears of years) {
              const info = new BankInfo();
              info.name = infoSetting.name;
              info.loanRatio = infoSetting.loanRatio;
              info.prepaymentInterestRate = infoSetting.prepaymentInterestRate;
              info.policyLoanHandlingFeeRate = infoSetting.policyLoanHandlingFeeRate;
              info.year = loanYears.year;
              info.annualInterestRate = loanYears.annualInterestRate;
              this.bankInfos.push(info);
            }
          }
          this.bankInfoSettings = data;
        },
        error => {
        }
      );

  }

  onSearchParamChange(): void {
    this.searchParamChange.emit('');
  }

  onBankInfo(info: BankInfo): void {
    this.bankInfoSelected.emit(info);
  }
}
