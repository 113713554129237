import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {SignaturePadComponent} from '../../../shared/component/signature-pad/signature-pad.component';
import {PactSign} from '../../sales-pact-types';
import {SalesPactService} from '../../sales-pact.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';

export class SignaturePadOptions {
  minWidth: number;
  canvasWidth: number;
  canvasHeight: number;
  backgroundColor: string;
}
@Component({
  selector: 'app-sales-pact-signature',
  templateUrl: './sales-pact-signature.component.html',
  styleUrls: ['./sales-pact-signature.component.less'],
  providers: [DatePipe]
})
export class SalesPactSignatureComponent implements OnInit {
  loading = false;
  id: number; // 合同id
  flag = true;
  imageBase64: string;

  @ViewChild(SignaturePadComponent)
  signaturePad: SignaturePadComponent;

  @Output()
  signed: EventEmitter<any> = new EventEmitter<any>();

  signaturePadOptions: SignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 728,
    canvasHeight: 340,
    backgroundColor: 'rgba(0,0,0,0)'
  };

  constructor(private modalService: NzModalService,
              private salesPactService: SalesPactService,
              private toastr: ToastrService,
              private translate: TranslateService,
              private datePipe: DatePipe) {
  }

  ngOnInit(): void {
  }

  onRewrite(tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzOnOk: () => {
      }
    });
  }

  get canConfirm(): boolean {
    console.log(this.signaturePad.toDataURL());
    if (this?.signaturePad?.toDataURL()) {
      return true;
    }
    return false;
  }

  // isCanvasBlank(canvas): boolean {
  //   const blank = document.createElement('canvas'); // 系统获取一个空canvas对象
  //   console.log(canvas);
  //   console.log(blank);
  //   console.log(blank.toDataURL());
  //   blank.width = canvas.options.canvasWidth;
  //   blank.height = canvas.options.canvasHeight;
  //   console.log(canvas.toDataURL() == blank.toDataURL());
  //   console.log(blank.toDataURL());
  //   console.log(canvas.toDataURL());
  //   return canvas.toDataURL() === blank.toDataURL(); // 比较值相等则为空
  // }

  onConfirm(): void {
    this.loading = true;
    const updateReq = new PactSign();
    updateReq.id = this.id;
    updateReq.signDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    updateReq.imageBase = this.signaturePad.toDataURL();
    if (!this.signaturePad.toData().length) {
      this.loading = false;
      this.translate.get('UnsignedTip').subscribe(
        (res) => {
          this.toastr.warning(res);
        }
      );
      return;
    }
    this.salesPactService.sign(updateReq)
      .subscribe(
        data => {
          this.loading = false;
          this.signed.emit('');
        },
        error => {
          this.loading = false;
        }
      );
  }

  onRewriteConfirm(modelRef: NzModalRef, params: any): void {
    modelRef.destroy();
    this.signaturePad?.clear();
  }
}
