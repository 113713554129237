import { Component, Input, OnInit } from '@angular/core';
import { Link } from '../../../api/types';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.less']
})
export class LinkComponent implements OnInit {

  @Input()
  link: Link;

  constructor() {
  }

  ngOnInit(): void {
  }

  openLink(link: Link): void {
    let url = link.link;
    if (!url.startsWith('http')) {
      url = 'https://' + url;
    }
    window.open(url);
  }

}
