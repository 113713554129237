import {Injectable} from '@angular/core';
import {HttpService} from '../core/http.service';
import {BaseService} from '../base/base.service';
import {
  PreSaleFollow,
  ConnectionsListRes,
  ContactInfoRes,
  ContactListRes,
  ContactSearch,
  ContactTable, DeleteFollow, FollowListRes, FollowReplyRes, InsertReply,
  MemberList,
  MemberListRes, SubordinateListRes, Tree, UpdateFollow, SubAccountListRes, BusinessSourceListRes
} from './contact-types';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResponse, PagedReq, PagedResp, UnderwritingDetailResp} from '../api/types';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API} from '../api/api';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends BaseService {

  constructor(private http: HttpService, private httpClient: HttpClient) {
    super();
  }

  /**
   * @param list 请求参数
   * 联系人列表
   */
  list(list: ContactSearch): Observable<PagedResp<ContactListRes>> {
    let httpParams = new HttpParams();
    if (list.pageNum != null) {
      httpParams = httpParams.set('pageNum', list.pageNum.toString());
    }
    if (list.pageSize != null) {
      httpParams = httpParams.set('pageSize', list.pageSize.toString());
    }
    if (list.name != null) {
      httpParams = httpParams.set('name', list.name.toString());
    }
    if (list.excludedFamilyId != null) {
      httpParams = httpParams.set('excludedFamilyId', list.excludedFamilyId.toString());
    }
    if (list.type != null) {
      httpParams = httpParams.set('type', list.type.toString());
    }
    return this.http.get<ApiResponse<PagedResp<ContactListRes>>>(API.contactV3 + '/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * @param list 预售客户下线列表
   * 联系人列表
   */
  preSubList(list: ContactSearch): Observable<PagedResp<ContactListRes>> {
    let httpParams = new HttpParams();
    if (list.pageNum != null) {
      httpParams = httpParams.set('pageNum', list.pageNum.toString());
    }
    if (list.pageSize != null) {
      httpParams = httpParams.set('pageSize', list.pageSize.toString());
    }
    if (list.name != null) {
      httpParams = httpParams.set('name', list.name.toString());
    }
    if (list.type != null) {
      httpParams = httpParams.set('type', list.type.toString());
    }
    return this.http.get<ApiResponse<PagedResp<ContactListRes>>>(API.contactV3 + '/pre-sales/subordinate-list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * @param list 请求参数
   * 人脉列表
   */
  connections(list: ContactTable): Observable<PagedResp<ConnectionsListRes>> {
    let httpParams = new HttpParams();
    if (list.pageNum != null) {
      httpParams = httpParams.set('pageNum', list.pageNum.toString());
    }
    if (list.pageSize != null) {
      httpParams = httpParams.set('pageSize', list.pageSize.toString());
    }
    if (list.type != null) {
      httpParams = httpParams.set('type', list.type.toString());
    }
    if (list.nickname != null) {
      httpParams = httpParams.set('nickname', list.nickname.toString());
    }
    return this.http.get<ApiResponse<PagedResp<ConnectionsListRes>>>(API.contactV3 + '/connections-list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * @param list 请求参数
   * 下线列表
   */
  subordinate(list: ContactTable): Observable<SubordinateListRes[]> {
    let httpParams = new HttpParams();
    if (list.pageNum != null) {
      httpParams = httpParams.set('pageNum', list.pageNum.toString());
    }
    if (list.pageSize != null) {
      httpParams = httpParams.set('pageSize', list.pageSize.toString());
    }
    if (list.subordinateName != null) {
      httpParams = httpParams.set('subordinateName', list.subordinateName.toString());
    }
    return this.http.get<ApiResponse<SubordinateListRes[]>>(API.contactV4 + '/subordinate-list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * @param id 聯繫人編號
   */
  detail(id: number): Observable<ContactInfoRes> {
    return this.http.get<ApiResponse<ContactInfoRes>>(API.contactV3 + '/info/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(ContactInfoRes, data.body.data);
    }));
  }

  /**
   * @param req 传参
   */
  create(req: ContactInfoRes): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.contactV3 + '/create', req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新信息
   * @param req 传参
   */
  updateInfo(req: ContactInfoRes): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.contactV3 + '/update', req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 家庭成员列表
   * @param req familyId
   */
  memberList(req: MemberList): Observable<MemberListRes[]> {
    let httpParams = new HttpParams();
    if (req.familyId != null) {
      httpParams = httpParams.set('familyId', req.familyId.toString());
    }
    return this.http.get<ApiResponse<MemberListRes[]>>(API.contactV3 + '/members', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 刪除家庭成員
   * @param id 戶主編號
   * @param memberID 成員編號
   */
  del(id, memberID: any): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.contactV3 + `/member/${id}`, {
      body: memberID,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 解散联系人家庭
   * @param id 戶主編號
   */
  dissolution(id: number): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.contactV3 + `/family/${id}`, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 添加聯繫人家庭成員
   * @param id 戶主編號
   * @param req 成員參數
   */
  addMember(id: number, req: any): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.contactV3 + `/member/${id}`, req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 组织架构
   */
  tree(): Observable<Tree> {
    return this.http.get<ApiResponse<Tree>>(API.contactV4 + '/tree', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 获取我下线账户列表(包括本人)
   * query {keywords: string}
   */
  sub(query = null): Observable<Tree[]> {
    let httpParams = new HttpParams();
    if (query && query?.keywords != null) {
      httpParams = httpParams.set('keywords', query.keywords.toString());
    }
    return this.http.get<ApiResponse<Tree[]>>(API.accountV3 + '/all-sub', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 二维码
   */
  qrCode(): Observable<any> {
    return this.http.get<ApiResponse<any>>(API.accountV3 + '/generate-qr-code', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 客户记录
   * @param id 联系人ID
   */
  meeting(id: number): Observable<FollowListRes> {
    return this.http.get<ApiResponse<FollowListRes>>(API.contactV3 + '/follow-list/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 记录详情
   * @param followId 客户记录ID
   */
  meetingDetails(followId: number): Observable<FollowReplyRes> {
    return this.http.get<ApiResponse<FollowReplyRes>>(API.contactV3 + '/follow-reply-list/' + followId, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 客户记录状态列表
   */
  followStatus(): Observable<any> {
    return this.http.get<ApiResponse<any>>(API.contactV3 + '/follow-status-list', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 添加客户记录
   * @param req 添加记录
   */
  createFollow(req: PreSaleFollow): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.contactV3 + '/insert-follow', req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 删除客户记录
   * @param req 客户记录表ID
   */
  deleteFollow(req: DeleteFollow): Observable<boolean> {
    let httpParams = new HttpParams();
    if (req.followId != null) {
      httpParams = httpParams.set('followId', req.followId.toString());
    }
    return this.http.delete<ApiResponse<boolean>>(API.contactV3 + `/delete-follow`, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 刪除聯繫人
   * @param id 客户记录表ID
   */
  deleteContact(id): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.contactV3 + `/${id}`, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 添加回复记录
   * @param req 添加记录
   */
  replyFollow(req: InsertReply): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.contactV3 + '/insert-follow-reply', req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 修改pre-sales-follow
   * @param req 传参
   */
  updateFollow(req: UpdateFollow): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.contactV3 + '/update-follow', req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 人脉导出excel
   */
  downloadExcel(): Observable<Uint8Array> {
    return this.httpClient
      .get(API.contactV3 + '/export-connections', {
        responseType: 'arraybuffer'
      }).pipe(map((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer)));
  }

  /**
   * 一键导入联系人
   */
  importContacts(): Observable<any> {
    return this.http.get<ApiResponse<any>>(API.contactV3 + '/import-contact', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 我的下线---銷售渠道
   */
  getSubList(): Observable<any> {
    return this.http.get<ApiResponse<SubAccountListRes[]>>(API.contactV4 + '/subordinate-list', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 我团队---銷售渠道
   */
  getTeamList(): Observable<any> {
    return this.http.get<ApiResponse<SubAccountListRes[]>>(API.accountV3 + '/team', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 业务来源列表
   */
  businessSourceList(): Observable<BusinessSourceListRes[]> {
    return this.http.get<ApiResponse<BusinessSourceListRes[]>>(API.contactV3 + '/business-source', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

}
