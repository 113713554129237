import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FnaService } from '../fna.service';
import { FnaSourceData } from '../fna-types';
import { Address } from '../../api/types';

@Component({
  selector: 'app-fna-sources',
  templateUrl: './fna-sources.component.html',
  styleUrls: ['./fna-sources.component.less']
})
export class FnaSourcesComponent implements OnInit {

  loading = false;

  sources: FnaSourceData[] = [];

  selectedSource: FnaSourceData;

  @Output()
  sourceSelected: EventEmitter<FnaSourceData> = new EventEmitter<FnaSourceData>();

  constructor(private fnaService: FnaService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.fnaService.sourceList()
      .subscribe(
        data => {
          this.sources = data;
          this.loading = false;
        }, error => {
          this.loading = false;
        });
  }

  onSource(source: FnaSourceData): void {
    this.sourceSelected.emit(source);
  }
}
