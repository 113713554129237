<nz-tabset [nzSize]="'large'">
  <nz-tab [nzTitle]=Contacts>
    <app-contacts></app-contacts>
  </nz-tab>
  <ng-template #Contacts>
    {{'Contacts' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=MyContacts>
    <app-contacts-connections></app-contacts-connections>
  </nz-tab>
  <ng-template #MyContacts>
    {{'MyContacts' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=MyReferrals *ngIf="workbench.pivot !== dataService.pivotOwn">
    <app-contacts-subordinate></app-contacts-subordinate>
  </nz-tab>
  <ng-template #MyReferrals>
    {{'MySubordinates1' | translate}}
  </ng-template>
</nz-tabset>
