<div [class]="product.tick ? 'outline-ticked' : 'outline'" appThrottleClick (throttleClick)="onTick()">
  <div class="flex-row" style="padding: 15px 10px 15px 0">
    <div class="flex-column" style="flex: 1">
      <div class="flex-row">
        <div>
          <img width="50" style="border-radius: 3px; margin-right: 5px" [src]="product.logo" alt=""/>
        </div>
        <div class="flex-column">
          <div style="color: #333333; font-size: 15px">{{product.productName}}</div>
          <div class="tag-outline-sm" style="margin: 3px 0 0 0;">{{product.categoryName}}</div>
        </div>
      </div>
      <div>
        <span class="desc">{{product.desc}}</span>
      </div>
    </div>
    <div style="flex-shrink: 1" class="flex-row">
      <a *ngIf="showDetailButton" style="color: #666666" nz-button nzType="link" nzSize="small" appThrottleClick (throttleClick)="onDetail(); $event.stopPropagation();">
        <span style="font-size: 13px; margin-right: 4px">{{'Detail' | translate}}</span>
        <span>
            <i nz-icon nzType="right" nzTheme="outline"></i>
          </span>
      </a>
      <a *ngIf="showEditButton" style="color: #666666" nz-button nzType="link" nzSize="small" appThrottleClick (throttleClick)="onEdit(); $event.stopPropagation();">
        <span style="font-size: 13px; margin-right: 4px">{{'Edit' | translate}}</span>
        <span>
            <i nz-icon nzType="right" nzTheme="outline"></i>
          </span>
      </a>
      <a *ngIf="showDeleteButton" style="color: #666666" nz-button nzType="link" nzSize="small" appThrottleClick (throttleClick)="onDelete(); $event.stopPropagation();">
        <img alt="" width="20" src="assets/images/ic_delete.svg">
      </a>
    </div>
  </div>
</div>
