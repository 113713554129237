import { Component, EventEmitter, OnInit } from '@angular/core';
import { MetadataService } from '../../shared/service/metadata.service';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { StorageService } from '../../shared/service/storage.service';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { DownloadService } from '../../shared/service/download.service';
import { ProductService } from '../../product/product.service';
import { ProposalApplyComponent } from '../proposal-apply/proposal-apply.component';
import { BookingCreateComponent } from '../../booking/booking-create/booking-create.component';
import { ProposalService } from '../proposal.service';
import { TranslateService } from '@ngx-translate/core';
import { EChartsOption } from 'echarts/types/dist/shared';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { BankInfo, DiscountInfo, Proposal, Withdrawal, WithdrawalRestriction } from '../proposal-types';
import { instanceToInstance, plainToClass, plainToInstance } from 'class-transformer';
import {
  Product,
  ProductListResp,
  ProductRestrictionReq,
  ProductRestrictionType,
  ProductSearch
} from '../../product/product-types';
import * as _ from 'lodash';
import {PremiumFinancingInfoComponent} from '../components/premium-financing-info/premium-financing-info.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Workbench } from '../../workbench/workbench-types';
import { WorkbenchService } from '../../workbench/workbench.service';

@Component({
  selector: 'app-proposal-detail',
  templateUrl: './proposal-detail.component.html',
  styleUrls: ['./proposal-detail.component.less']
})
export class ProposalDetailComponent implements OnInit {

  proposal: Proposal;
  product: ProductListResp;
  loading = false;
  loadingTable = false;
  dataVisible = false;
  workbench: Workbench;
  valueDemoChartsOption: EChartsOption;
  surrenderValueType: SurrenderValueType = SurrenderValueType.TOTAL;
  surrenderValueScenario: SurrenderValueScenario = SurrenderValueScenario.CURRENT_ASSUMED;
  valueTableType = 'extract';
  secondValueTableType: SurrenderValueScenario = SurrenderValueScenario.CURRENT_ASSUMED;

  surrenderValueTypeOptions: PropertySelectOption[] = [];
  valueTableTypeOptions: PropertySelectOption[] = [];
  surrenderValueScenarioOptions: PropertySelectOption[] = [];
  tableValueArray: any[] = [];

  rateDemoChartsOption: EChartsOption;
  surrenderRateType: SurrenderRateType = SurrenderRateType.RATE_OF_RETURN;
  surrenderRateScenario: SurrenderValueScenario = SurrenderValueScenario.CURRENT_ASSUMED;
  surrenderRateTypeOptions: PropertySelectOption[] = [];
  surrenderRateScenarioOptions: PropertySelectOption[] = [];

  drawerRef: NzDrawerRef<ProposalDetailComponent, string>;
  withdrawalRestriction: WithdrawalRestriction = new WithdrawalRestriction(); // 现金提取限制 - 當code、premiumTerm、type參數都傳時，才會有數據（如果有錄入數據）

  refreshEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private productService: ProductService,
              private proposalService: ProposalService,
              private downloadService: DownloadService,
              private translate: TranslateService,
              private storageService: StorageService,
              private workbenchService: WorkbenchService,
              private modalService: NzModalService) {


    // for (const surrenderValueTypeKey in SurrenderValueType) {
    //   if (SurrenderValueType.hasOwnProperty(surrenderValueTypeKey)) {
    //     this.surrenderValueTypeOptions.push(new PropertySelectOption(surrenderValueTypeKey, surrenderValueTypeKey));
    //   }
    // }
  }

  ngOnInit(): void {
    this.loading = true;
    // this.productService.detail(this.proposal.productCode)
    //   .subscribe(
    //     product => {
    //       this.product = product;
    //       this.loading = false;
    //     },
    //     error => {
    //       this.loading = false;
    //     });
    this.proposalService.getMultiplex(this.proposal.productCode)
      .subscribe(
        product => {
          this.product = product;
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
    this.workbench = this.workbenchService.workbench;
    this.dealDemonstration();
    this.setupWithdrawals();

    this.valueTableTypeOptions.push(new PropertySelectOption('extract', 'extract'));
    this.valueTableTypeOptions.push(new PropertySelectOption('not_extract', 'not_extract'));
  }

  onRetry(): void {
    this.loading = true;
    this.proposalService.retry(this.proposal.proposalId)
      .subscribe(
        data => {
          this.loading = false;
          this.loadDetail(this.proposal.proposalId);
        },
        error => {
          this.loading = false;
        });
  }


  onPreview(): void {
    if (this.proposal.url && this.proposal.url.filePath) {
      this.loading = true;
      this.storageService.accessToken(this.proposal.url.filePath)
        .subscribe(
          data => {
            this.loading = false;
            this.onPreviewPDF(data.accessToken);
          },
          error => {
            this.loading = false;
          });
    }
  }

  onPreviewPDF(pdfUrl: string): void {

    const drawerRef = this.drawerService.create<PdfViewerComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: PdfViewerComponent,
      nzContentParams: {
        src: pdfUrl,
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const pdfViewerComponent = drawerRef.getContentComponent();
      pdfViewerComponent.autoresize = true;
      pdfViewerComponent.showAll = true;
      pdfViewerComponent.originalSize = false;
      pdfViewerComponent.fitToPage = true;
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });

  }

  onDownload(): void {
    if (this.proposal.url && this.proposal.url.filePath) {
      this.loading = true;
      this.storageService.accessToken(this.proposal.url.filePath)
        .subscribe(
          data => {
            this.loading = false;
            this.downloadService.download(data.accessToken, this.proposal.url.fileName);
          },
          error => {
            this.loading = false;
          });
    }
  }

  onViewData(): void {
    this.dataVisible = true;
    this.changeDataTable();
  }

  dataDrawerClose(): void {
    this.dataVisible = false;
  }


  changeDataTable(): void {
    this.loadingTable = true;
    switch (this.valueTableType) {
      case 'extract':
        switch (this.secondValueTableType) {
          case SurrenderValueScenario.CURRENT_ASSUMED:
            this.tableValueArray = this.proposal.surrenderValueWithdrawal.currentAssumed || [];
            break;
          case SurrenderValueScenario.OPTIMISTIC_SCENARIO:
            this.tableValueArray = this.proposal.surrenderValueWithdrawal.optimisticScenario || [];
            break;
          case SurrenderValueScenario.PESSIMISTIC_SCENARIO:
            this.tableValueArray = this.proposal.surrenderValueWithdrawal.pessimisticScenario || [];
            break;
        }
        break;
      case 'not_extract':
        switch (this.secondValueTableType) {
          case SurrenderValueScenario.CURRENT_ASSUMED:
            this.tableValueArray = this.proposal.surrenderValue.currentAssumed || [];
            break;
          case SurrenderValueScenario.OPTIMISTIC_SCENARIO:
            this.tableValueArray = this.proposal.surrenderValue.optimisticScenario || [];
            break;
          case SurrenderValueScenario.PESSIMISTIC_SCENARIO:
            this.tableValueArray = this.proposal.surrenderValue.pessimisticScenario || [];
            break;
        }
        break;
    }
    this.loadingTable = false;
  }

  onDownloadImg(): void {
    if (this.proposal.contentImage && this.proposal.contentImage.filePath) {
      this.loading = true;
      this.storageService.accessToken(this.proposal.contentImage.filePath)
        .subscribe(
          data => {
            this.loading = false;
            this.downloadService.download(data.accessToken, this.proposal.contentImage.fileName);
          },
          error => {
            this.loading = false;
          });
    }
  }

  get isOwnerEmpty(): boolean {
    const owner = this.proposal.owner;
    if (!owner) {
      return true;
    }
    return !owner.age &&
      !owner.birthday &&
      !owner.firstName &&
      !owner.lastName &&
      !owner.gender &&
      !owner.smoke &&
      !owner.residenceRegion;
  }

  loadDetail(proposalId: string): void {
    this.loading = true;
    this.proposalService.info(proposalId)
      .subscribe(
        data => {
          this.loading = false;
          this.proposal = data;
          this.dealDemonstration();
          this.setupWithdrawals();
        },
        error => {
          this.loading = false;
        });
  }

  setupWithdrawals(): void {
    const req = new ProductRestrictionReq();
    req.code = this.proposal.productCode;
    req.premiumTerm = this.proposal.paymentTerm;
    req.frequency = this.proposal.frequency;
    req.type = ProductRestrictionType.WITHDRAWAL;
    this.productService.restriction(req)
      .subscribe(
        data => {
          this.withdrawalRestriction = data?.withdrawalRestriction;
          if (this.proposal.withdrawals) {
            for (const withdrawal of this.proposal.withdrawals) {
              this.setupWithdrawalString(withdrawal).then();
            }
          }
        },
        error => {
        });
  }

  async setupWithdrawalString(withdrawal: Withdrawal): Promise<void> {
    const res: any = await this.translate.get(['From', 'To', 'Year', 'YearsOld', 'Di', 'FixedWithdrawal', 'MaximumWithdrawals']).toPromise();
    const from = `${res.From}${res.Di}${withdrawal.from}${res.Year}(${this.add(this.proposal.age) + withdrawal.from}${res.YearsOld})`;
    const to = `${res.To}${res.Di}${withdrawal.to}${res.Year}(${this.add(this.proposal.age) + withdrawal.to}${res.YearsOld})`;
    const withdrawalWay = withdrawal.max ? res.MaximumWithdrawals : res.FixedWithdrawal;
    withdrawal.str = `${from}-${to}:${withdrawalWay}`;
  }

  dealDemonstration(): void {
    this.dealValueDemonstration();
    this.dealRateDemonstration();
  }

  dealValueDemonstration(): void {

    this.surrenderValueTypeOptions = [];
    this.surrenderValueTypeOptions.push(new PropertySelectOption('TotalCashValue', SurrenderValueType.TOTAL));
    this.surrenderValueTypeOptions.push(new PropertySelectOption('GuaranteedCashValue', SurrenderValueType.GUARANTEED));

    this.surrenderValueScenarioOptions = [];
    this.surrenderValueScenarioOptions.push(new PropertySelectOption('CurrentAssumed', SurrenderValueScenario.CURRENT_ASSUMED));
    this.surrenderValueScenarioOptions.push(new PropertySelectOption('OptimisticScenario', SurrenderValueScenario.OPTIMISTIC_SCENARIO));
    this.surrenderValueScenarioOptions.push(new PropertySelectOption('PessimisticScenario', SurrenderValueScenario.PESSIMISTIC_SCENARIO));

    this.translate.get(['NonWithdrawal', 'Withdrawal']).subscribe(
      (result: any) => {

        const xAxisData = [];
        const seriesNonWithdrawal = [];
        const seriesWithdrawal = [];
        let surrenderValuesNonWithdrawal = [];
        let surrenderValuesWithdrawal = [];

        switch (this.surrenderValueScenario) {
          case SurrenderValueScenario.CURRENT_ASSUMED:
            surrenderValuesNonWithdrawal = this.proposal.surrenderValue?.currentAssumed ?? [];
            surrenderValuesWithdrawal = this.proposal.surrenderValueWithdrawal?.currentAssumed ?? [];
            break;
          case SurrenderValueScenario.OPTIMISTIC_SCENARIO:
            surrenderValuesNonWithdrawal = this.proposal.surrenderValue?.optimisticScenario ?? [];
            surrenderValuesWithdrawal = this.proposal.surrenderValueWithdrawal?.optimisticScenario ?? [];
            break;
          case SurrenderValueScenario.PESSIMISTIC_SCENARIO:
            surrenderValuesNonWithdrawal = this.proposal.surrenderValue?.pessimisticScenario ?? [];
            surrenderValuesWithdrawal = this.proposal.surrenderValueWithdrawal?.pessimisticScenario ?? [];
            break;
        }

        for (const surrenderValue of surrenderValuesNonWithdrawal) {
          xAxisData.push(surrenderValue.year);
          switch (this.surrenderValueType) {
            case SurrenderValueType.TOTAL:
              seriesNonWithdrawal.push(surrenderValue.total.toFixed(2));
              break;
            case SurrenderValueType.GUARANTEED:
              seriesNonWithdrawal.push(surrenderValue.guaranteed.toFixed(2));
              break;
          }
        }

        for (const surrenderValue of surrenderValuesWithdrawal) {
          switch (this.surrenderValueType) {
            case SurrenderValueType.TOTAL:
              seriesWithdrawal.push(surrenderValue.total.toFixed(2));
              break;
            case SurrenderValueType.GUARANTEED:
              seriesWithdrawal.push(surrenderValue.guaranteed.toFixed(2));
              break;
          }
        }

        this.valueDemoChartsOption = this.getValueDemoChartsOption(result.NonWithdrawal, result.Withdrawal, xAxisData, seriesNonWithdrawal, seriesWithdrawal);

      });

  }

  getValueDemoChartsOption(nonWithdrawalLabel: string, withdrawalLabel: string, xAxisData: any[], seriesNonWithdrawal: any[], seriesWithdrawal: any[]): EChartsOption {
    return {
      legend: {
        data: [nonWithdrawalLabel, withdrawalLabel]
      },
      grid: {
        show: false,
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: xAxisData
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false
        },
        axisLabel: {
          inside: true
        }
      },
      series: [
        {
          name: nonWithdrawalLabel,
          showSymbol: false,
          type: 'line',
          data: seriesNonWithdrawal,
          smooth: true
        },
        {
          name: withdrawalLabel,
          showSymbol: false,
          type: 'line',
          data: seriesWithdrawal,
          smooth: true
        }
      ]
    };
  }

  dealRateDemonstration(): void {

    this.surrenderRateTypeOptions = [];
    this.surrenderRateTypeOptions.push(new PropertySelectOption('RateOfReturn', SurrenderRateType.RATE_OF_RETURN));
    this.surrenderRateTypeOptions.push(new PropertySelectOption('AnnualRateOfReturn', SurrenderRateType.ANNUAL_RATE_OF_RETURN));
    this.surrenderRateTypeOptions.push(new PropertySelectOption('InternalRateOfReturn', SurrenderRateType.INTERNAL_RATE_OF_RETURN));

    this.surrenderRateScenarioOptions = [];
    this.surrenderRateScenarioOptions.push(new PropertySelectOption('CurrentAssumed', SurrenderValueScenario.CURRENT_ASSUMED));
    this.surrenderRateScenarioOptions.push(new PropertySelectOption('OptimisticScenario', SurrenderValueScenario.OPTIMISTIC_SCENARIO));
    this.surrenderRateScenarioOptions.push(new PropertySelectOption('PessimisticScenario', SurrenderValueScenario.PESSIMISTIC_SCENARIO));

    this.translate.get(['NonWithdrawal', 'Withdrawal']).subscribe(
      (result: any) => {

        const xAxisData = [];
        const seriesNonWithdrawal = [];
        const seriesWithdrawal = [];
        let surrenderValuesNonWithdrawal = [];
        let surrenderValuesWithdrawal = [];

        switch (this.surrenderRateScenario) {
          case SurrenderValueScenario.CURRENT_ASSUMED:
            surrenderValuesNonWithdrawal = this.proposal.surrenderValue?.currentAssumed ?? [];
            surrenderValuesWithdrawal = this.proposal.surrenderValueWithdrawal?.currentAssumed ?? [];
            break;
          case SurrenderValueScenario.OPTIMISTIC_SCENARIO:
            surrenderValuesNonWithdrawal = this.proposal.surrenderValue?.optimisticScenario ?? [];
            surrenderValuesWithdrawal = this.proposal.surrenderValueWithdrawal?.optimisticScenario ?? [];
            break;
          case SurrenderValueScenario.PESSIMISTIC_SCENARIO:
            surrenderValuesNonWithdrawal = this.proposal.surrenderValue?.pessimisticScenario ?? [];
            surrenderValuesWithdrawal = this.proposal.surrenderValueWithdrawal?.pessimisticScenario ?? [];
            break;
        }

        surrenderValuesNonWithdrawal = surrenderValuesNonWithdrawal.filter(surrenderValue => {
          return surrenderValue.rateOfReturn >= 0;
        });
        surrenderValuesWithdrawal = surrenderValuesWithdrawal.filter(surrenderValue => {
          return surrenderValue.rateOfReturn >= 0;
        });

        for (const surrenderValue of surrenderValuesNonWithdrawal) {
          xAxisData.push(surrenderValue.year);
          switch (this.surrenderRateType) {
            case SurrenderRateType.RATE_OF_RETURN:
              seriesNonWithdrawal.push((surrenderValue.rateOfReturn * 100.0).toFixed(2));
              break;
            case SurrenderRateType.ANNUAL_RATE_OF_RETURN:
              seriesNonWithdrawal.push((surrenderValue.annualRateOfReturn * 100.0).toFixed(2));
              break;
            case SurrenderRateType.INTERNAL_RATE_OF_RETURN:
              seriesNonWithdrawal.push((surrenderValue.internalRateOfReturn * 100.0).toFixed(2));
              break;
          }
        }

        for (const surrenderValue of surrenderValuesWithdrawal) {
          switch (this.surrenderRateType) {
            case SurrenderRateType.RATE_OF_RETURN:
              seriesWithdrawal.push((surrenderValue.rateOfReturn * 100.0).toFixed(2));
              break;
            case SurrenderRateType.ANNUAL_RATE_OF_RETURN:
              seriesWithdrawal.push((surrenderValue.annualRateOfReturn * 100.0).toFixed(2));
              break;
            case SurrenderRateType.INTERNAL_RATE_OF_RETURN:
              seriesWithdrawal.push((surrenderValue.internalRateOfReturn * 100.0).toFixed(2));
              break;
          }
        }

        this.rateDemoChartsOption = this.getRateDemoChartsOption(result.NonWithdrawal, result.Withdrawal, xAxisData, seriesNonWithdrawal, seriesWithdrawal);

      });

  }

  getRateDemoChartsOption(nonWithdrawalLabel: string, withdrawalLabel: string, xAxisData: any[], seriesNonWithdrawal: any[], seriesWithdrawal: any[]): EChartsOption {
    return {
      legend: {
        data: [nonWithdrawalLabel, withdrawalLabel]
      },
      grid: {
        show: false,
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: xAxisData
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false
        },
        axisLabel: {
          inside: true
        }
      },
      series: [
        {
          name: nonWithdrawalLabel,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: seriesNonWithdrawal,
        },
        {
          name: withdrawalLabel,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: seriesWithdrawal,
        }
      ]
    };
  }
  onReuse(): void {
    const drawerRef = this.drawerService.create<ProposalApplyComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProposalApplyComponent,
      nzContentParams: {
        withdrawalRestriction: this.withdrawalRestriction
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();

      const proposal = instanceToInstance(this.proposal);

      component.drawerRef = drawerRef;
      console.log(this.product);
      component.reuse(this.product, proposal);
      component.proposalApplied
        .subscribe(data => {
          drawerRef.close();
          this.refreshEmitter.emit('');
          this.drawerRef?.close();
        });
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });

  }

  onCancel(): void {
    this.loading = true;
    this.proposalService.cancel(this.proposal.proposalId)
      .subscribe(
        data => {
          this.loading = false;
          this.loadDetail(this.proposal.proposalId);
        },
        error => {
          this.loading = false;
        });
  }

  onReserveNow(): void {
    const instance = this.metadataService.mockInstance;
    if (instance.isFone()) {
      this.translate.get(['FNAToBooking', 'Tips']).subscribe(
        res => {
          this.modalService.info({
            nzCentered: true,
            nzTitle: res.Tips,
            nzContent: res.FNAToBooking,
            nzClosable: true,
            nzOnOk: () => {},
          });
        }
      );
      return;
    }
    this.loading = true;
    this.productService.detail(this.proposal.productCode)
      .subscribe(
        product => {
          this.loading = false;

          const drawerRef = this.drawerService.create<BookingCreateComponent, { value: string }, string>({
            nzWidth: 800,
            nzContent: BookingCreateComponent,
            nzContentParams: {}
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.assignFromProposal(product, this.proposal);
            component.bookingDraftSaved.subscribe(
              data => {
                drawerRef.close();
              }
            );
            component.bookingApplied.subscribe(
              data => {
                drawerRef.close();
              }
            );
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        });

  }

  toDecimal(val): any {
    if (parseFloat(String(val)).toString() === 'NaN') {
      return val;
    } else if (val && val.toString().indexOf('.') === -1) {
      val = val + '.0%';
      return val;
    } else {
      return val + '%';
    }
  }

  onEditBack(): void {
    this.translate.get('BackInfo').subscribe(
      title => {
        const drawerRef = this.drawerService.create<PremiumFinancingInfoComponent, { value: any }, string>({
          nzWidth: 600,
          nzContent: PremiumFinancingInfoComponent,
          nzContentParams: {
            financingType: 'back',
            title,
            proposalId: this.proposal.proposalId,
            modifyState: true,
            bankInfo: plainToInstance(BankInfo, this.proposal.premiumFinancing.bankInfo),
            editFinancing: this.proposal.premiumFinancing
          }
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.editInfoSaved
            .subscribe(
              data => {
                drawerRef.close();
                this.onReload();
              }
            );
        });
      }
    );
  }

  onEditDiscount(): void {
    this.translate.get('DiscountInfo').subscribe(
      title => {
        const drawerRef = this.drawerService.create<PremiumFinancingInfoComponent, { value: any }, string>({
          nzWidth: 600,
          nzContent: PremiumFinancingInfoComponent,
          nzContentParams: {
            financingType: 'discount',
            title,
            proposalId: this.proposal.proposalId,
            modifyState: true,
            discountInfo: plainToInstance(DiscountInfo, this.proposal.premiumFinancing.discountInfo),
            editFinancing: this.proposal.premiumFinancing
          }
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.editInfoSaved
            .subscribe(
              data => {
                drawerRef.close();
                this.onReload();
              }
            );
        });
      }
    );
  }

  onReload(): void {
    this.loading = true;
    this.proposalService.info(this.proposal.proposalId)
      .subscribe(
        data => {
          this.loading = false;
          this.proposal = data;
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  add(num: number): number {
    if (!this.withdrawalRestriction?.calculation) {
      return num || 0;
    }
    switch (this.withdrawalRestriction.calculation) {
      case '+':
        num += this.withdrawalRestriction.calculationValue;
        break;
      case '-':
        num -= this.withdrawalRestriction.calculationValue;
        break;
    }
    return num;
  }

  changeDecimalToPercentage(data): string {
    if (!data) {
      return '0.00%';
    }
    return (data * 100).toFixed(2) + '%';
  }

}

enum SurrenderValueType {
  TOTAL = 'TOTAL',
  GUARANTEED = 'GUARANTEED',
}

enum SurrenderRateType {
  RATE_OF_RETURN = 'RATE_OF_RETURN',
  ANNUAL_RATE_OF_RETURN = 'ANNUAL_RATE_OF_RETURN',
  INTERNAL_RATE_OF_RETURN = 'INTERNAL_RATE_OF_RETURN',
}

enum SurrenderValueScenario {
  CURRENT_ASSUMED = 'CURRENT_ASSUMED',
  OPTIMISTIC_SCENARIO = 'OPTIMISTIC_SCENARIO',
  PESSIMISTIC_SCENARIO = 'PESSIMISTIC_SCENARIO',
}
