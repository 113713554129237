<div class="drawer-container">
<!--  <app-water-mark [xWidth]="60" [yHeight]="50"></app-water-mark>-->
  <div class="drawer-title" style="margin-bottom: 40px">
    <nz-breadcrumb nzSeparator=">" style="font-size: 20px;line-height: 22px">
      <nz-breadcrumb-item><a appThrottleClick (throttleClick)="onClose()">{{'PolicyList' | translate}}</a></nz-breadcrumb-item>
      <nz-breadcrumb-item>
        {{'CommissionDetail' | translate}}
      </nz-breadcrumb-item>
    </nz-breadcrumb>
  </div>

  <div *ngIf="detail.type == 'GENERAL'">
    <div class="commissionDetailTop">
      <div>
        <img width="80" style="border-radius: 3px; margin-right: 5px" [src]="detail.companyLogo" alt=""/>
      </div>
      <div>{{detail.productName}}</div>
      <div class="cardMoney">HKD {{toDecimal(detail.finalCommission | number)}}</div>
    </div>
    <nz-divider></nz-divider>
    <nz-row [nzGutter]="15">
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'ApprovalDate' | translate"
                           [bottomLineVisible]="false" [value]="detail.approvalDate"></app-property-text>
      </nz-col>
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'PolicyDate' | translate"
                           [bottomLineVisible]="false" [value]="detail.policyDate"></app-property-text>
      </nz-col>
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'Introducer' | translate"
                           [bottomLineVisible]="false" [value]="detail.nickName"></app-property-text>
      </nz-col>

      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'PolicyNumber' | translate "
                           [bottomLineVisible]="false" [value]="detail.policyNo"></app-property-text>
      </nz-col>

      <!--    投保人-->
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'PolicyHolder' | translate"
                           [bottomLineVisible]="false" [value]="detail.ownerName"></app-property-text>
      </nz-col>

      <!--    受保人-->
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'Insured' | translate"
                           [bottomLineVisible]="false" [value]="detail.insuredName"></app-property-text>
      </nz-col>

      <!--    供款年期-->
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'PaymentTerm' | translate"
                           [bottomLineVisible]="false" [value]="detail.contributionPeriod"></app-property-text>
      </nz-col>

      <!--    續費日期-->
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'RenewalDate' | translate"
                           [bottomLineVisible]="false" [value]="(detail.paymentDate | date: 'yyyy-MM-dd')"></app-property-text>
      </nz-col>

      <!--    货币-->
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'Currency' | translate"
                           [bottomLineVisible]="false" [value]="(detail.currency | metadataTranslate: 'currency')"></app-property-text>
      </nz-col>

      <!--    保費-->
      <nz-col [nzSpan]="12">
        <div style="position: relative">
          <app-property-text [displayMode]="'vertical'" [title]="'Premium' | translate"
                             [bottomLineVisible]="false" [value]="toDecimal(detail.premium | number)"></app-property-text>
          <a nz-icon nzType="info-circle"
             nzTheme="outline"
             nz-popover [nzPopoverContent]="uploadTemplate"
             [(nzPopoverVisible)]="uploadPopoverVisible"
             nzPopoverTrigger="click"
             nzPopoverPlacement="top"
             style="position: absolute; top: 19px; left: -18px;"></a>
        </div>
      </nz-col>
      <!--    介绍费类型-->
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'ReferralFeeType' | translate"
                           [bottomLineVisible]="false"
                           [value]="(detail.commissionType | metadataTranslate: 'commissionType')"></app-property-text>
      </nz-col>
      <nz-col [nzSpan]="12" *ngIf="workbench.showExchangeRate">
        <app-property-text [displayMode]="'vertical'" [title]="'SettlementRate' | translate"
                           [bottomLineVisible]="false"
                           [value]="detail.exchangeRate"></app-property-text>
      </nz-col>

      <nz-col [nzSpan]="12" *ngIf="instance.isWDF()">
        <app-property-text [displayMode]="'vertical'" [title]="'Product Factor'"
                           [bottomLineVisible]="false"
                           [value]="(detail.productFactor)"></app-property-text>
      </nz-col>
      <nz-col [nzSpan]="12" *ngIf="instance.isWDF()">
        <app-property-text [displayMode]="'vertical'" [title]="'AIBVIncrement' | translate"
                           [bottomLineVisible]="false"
                           [value]="(detail.aibvIncrement ? 'HKD' + ' ' + (detail.aibvIncrement | number) : '')">
        </app-property-text>
      </nz-col>
      <nz-col [nzSpan]="12" *ngIf="instance.isWDF()">
        <app-property-text [displayMode]="'vertical'" [title]="'BV Factor'"
                           [bottomLineVisible]="false"
                           [value]="(detail.bvFactor)">
        </app-property-text>
      </nz-col>
      <!--    备注-->
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'Remark' | translate"
                           [bottomLineVisible]="false" [value]="detail.remark"></app-property-text>
      </nz-col>
    </nz-row>

    <div *ngIf="detail.subordinateChannelBills && detail.subordinateChannelBills.length" style="margin-top: 10px">
      <nz-table #basicTable [nzData]="detail.subordinateChannelBills"
                [nzShowPagination]="false">
        <thead>
        <tr>
          <th></th>
          <th [nzAlign]="'center'">{{'CommissionMsgRate' | translate}}</th>
          <th [nzAlign]="'center'">{{'CommissionMsg' | translate}}(HKD)</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of detail.subordinateChannelBills">
          <td>{{ data.nickName }}</td>
          <td [nzAlign]="'center'">{{ data.rate | number: '.2' }}%</td>
          <td [nzAlign]="'center'">{{ data.finalCommission | number: '.2' }}</td>
        </tr>
        </tbody>
      </nz-table>
    </div>

    <!-- 附件-->
    <div>{{'Attachments' | translate}}</div>
<!--    <app-attachments *ngIf="detail.attachments?.length > 0"-->
<!--                     [attachments]="detail.attachments"-->
<!--                     [readonly]="true"></app-attachments>-->
    <app-attachments [attachments]="detail.attachments"
                     [uploadTokenReq]="uploadTokenReq"
                     [isGroup]="true"
                     [isPreview]="false"
                     (completeAll)="reloadDetail($event)"
                     (valueChange)="onAttachmentsChange($event)"></app-attachments>
  </div>

  <div *ngIf="detail.type == 'CUSTOM_ITEM'">
    <div class="commissionDetailTop">
      <div>
        <img width="80" style="border-radius: 3px; margin-right: 5px" [src]="detail.companyLogo" alt=""/>
      </div>
      <div>{{detail.productName}}</div>
      <div class="cardMoney">HKD {{toDecimal(detail.finalCommission | number)}}</div>
    </div>
    <nz-divider></nz-divider>
    <nz-row [nzGutter]="15">
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'PolicyNumber' | translate "
                           [bottomLineVisible]="false" [value]="detail.policyNo"></app-property-text>
      </nz-col>
      <!--    备注-->
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'Remark' | translate"
                           [bottomLineVisible]="false" [value]="detail.remark"></app-property-text>
      </nz-col>
    </nz-row>
    <!-- 附件-->
    <div>{{'Attachments' | translate}}</div>
<!--    <app-attachments *ngIf="detail.attachments?.length > 0"-->
<!--                     [attachments]="detail.attachments"-->
<!--                     [isGroup]="true"-->
<!--                     [readonly]="true"></app-attachments>-->
    <app-attachments [attachments]="detail.attachments"
                     [uploadTokenReq]="uploadTokenReq"
                     [isGroup]="true"
                     [isPreview]="false"
                     (completeAll)="reloadDetail($event)"
                     (billEdit)="fileEdit($event)"
                     (billDelete)="fileDelete($event)"
                     (valueChange)="onAttachmentsChange($event)"></app-attachments>
  </div>
  <div *ngIf="detail.status == 'PUBLISH' ? mode !== 'OWN' ? workbench.confirmSubordinateBill : true : false" class="commissionListButton">
    <div nz-col nzSpan="24" class="footer-button-primary">
      <a nz-button nzType="link"
         nzBlock nzSize="large"
         appThrottleClick (throttleClick)="onConfirm()">
        <span>{{'ConfirmReferralFee' | translate}}</span>
      </a>
    </div>
  </div>
</div>

<!-- 提示气泡Template -->
<ng-template #uploadTemplate>
  <div class="tips">
    <p class="tipsText">
      {{ 'CommissionDetailTips' | translate}}
    </p>
    <div>
      <button nz-button nzType="primary"
              appThrottleClick (throttleClick)="clickMe()">{{ 'Confirm' | translate}}</button>
    </div>
  </div>
</ng-template>
