<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{ 'CreateSchedule' | translate}}
  </div>
  <div>
    <app-property-input [title]="'ScheduleTitle' | translate"
                        [displayMode]="'vertical'"
                        [placeholder]="('PleaseSelect' | translate) + ('ScheduleTitle' | translate)"
                        [(value)]="scheduleParams.title"
                        [required]="true"
    ></app-property-input>
    <!-- 生效時間 -->
    <app-property-datetime [title]="'StartTime' | translate"
                           [displayMode]="'vertical'"
                           [required]="true"
                           [showTime]="{nzFormat: 'HH:mm'}"
                           [formatter]="'yyyy-MM-dd HH:mm'"
                           [(value)]="scheduleParams.startTime"></app-property-datetime>
    <!-- 失效時間 -->
    <app-property-datetime [title]="'EndTime' | translate"
                           [displayMode]="'vertical'"
                           [required]="true"
                           [showTime]="{nzFormat: 'HH:mm'}"
                           [formatter]="'yyyy-MM-dd HH:mm'"
                           [(value)]="scheduleParams.endTime"></app-property-datetime>
    <!-- 地址-->
    <app-property-pick [title]="'ScheduleAddress' | translate"
                       [displayMode]="'vertical'"
                       appThrottleClick (throttleClick)="onResidenceAddress()"
                       [value]="scheduleParams?.place.toAddressString()"></app-property-pick>
    <!--日程提醒-->
    <app-property-select [title]="'ScheduleRemind' | translate"
                         [displayMode]="'vertical'"
                         [required]="true"
                         [options]="typeOptions"
                         [(value)]="scheduleParams.remind">
    </app-property-select>
    <!--描述-->
    <app-property-input-area
                             [required]="true"
                             [title]="'ScheduleDesc' | translate"
                             [placeholder]="('PleaseEnter' | translate) + ('ScheduleDesc' | translate)"
                             [(value)]="scheduleParams.description"></app-property-input-area>
  </div>
  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [disabled]="!canReply"
           [style]="!canReply ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onSave()">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
