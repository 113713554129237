<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      <div>{{'Signature' | translate}}</div>
    </div>
    <div class="centre-container">
      <div class="signature-area">
        <app-signature-pad [options]="signaturePadOptions"></app-signature-pad>
      </div>
    </div>
    <div class="centre-container" style="margin: 20px 0 0 0">
      <div>
        <i nz-icon nzType="exclamation-circle" style="font-size: 16px;" [nzTwotoneColor]="'#f6c343'" nzTheme="twotone"></i>
        <span style="margin-left: 10px">{{'SigningTips' | translate}}</span>
      </div>
    </div>
    <div class="centre-container">
      <div nz-row style="width: fit-content; height: 50px; margin-top: 20px">
        <div nz-col nzSpan="12" class="footer-button signature-button">
          <a nz-button nzType="link" nzBlock nzSize="large"
             appThrottleClick (throttleClick)="onRewrite(rewriteTitle, rewriteContent, rewriteFooter);">
            <span style="color: #1d64c4">{{'Rewrite' | translate}}</span>
          </a>
        </div>
        <div nz-col nzSpan="12" class="footer-button-primary signature-button">
          <a nz-button nzType="link" nzBlock nzSize="large"
             appThrottleClick (throttleClick)="onConfirm()">
            <span style="color: white">{{'Confirm' | translate}}</span>
          </a>
        </div>
      </div>
    </div>
  </nz-spin>


</div>

<ng-template #rewriteTitle>
  <span>{{'Tips' | translate}}</span>
</ng-template>
<ng-template #rewriteContent let-params>
  <p>{{'SignatureRewriteTips' | translate}}</p>
</ng-template>
<ng-template #rewriteFooter let-ref="modalRef" let-params>
  <button nz-button class="disclaimer-button" appThrottleClick (throttleClick)="ref.destroy()">{{'No' | translate}}</button>
  <button nz-button nzType="primary" class="disclaimer-button" appThrottleClick (throttleClick)="onRewriteConfirm(ref, params)">
    {{'Yes' | translate}}
  </button>
</ng-template>
