import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { HttpService } from '../../core/http.service';
import { AccessToken, ApiResponse, UploadToken, UploadTokenReq } from '../../api/types';
import { Observable } from 'rxjs/internal/Observable';
import { API } from '../../api/api';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  accessToken(url: string): Observable<AccessToken> {
    return this.http.post<ApiResponse<AccessToken>>(API.storageV4 + '/access-token', {filePath: url}, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  uploadToken(req: UploadTokenReq): Observable<UploadToken> {
    return this.http.post<ApiResponse<UploadToken>>(API.storageV4 + '/upload-token', req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  centreUploadToken(req: UploadTokenReq): Observable<UploadToken> {
    return this.http.post<ApiResponse<UploadToken>>(API.storageCentreV3 + '/upload-token', req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
