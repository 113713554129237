import {Component, EventEmitter, OnInit} from '@angular/core';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {MetadataService} from '../../shared/service/metadata.service';
import { NzDrawerService} from 'ng-zorro-antd/drawer';
import {ContactsSelectionComponent} from '../../shared/component/contacts-selection/contacts-selection.component';
import {ContactService} from '../contact.service';

@Component({
  selector: 'app-contact-add-member',
  templateUrl: './contact-add-member.component.html',
  styleUrls: ['./contact-add-member.component.less']
})
export class ContactAddMemberComponent implements OnInit {

  relationship: any; // 与户主关系
  contactSelect: any; // 联系人信息

  id: number; // familyID
  afterSave: EventEmitter<any> = new EventEmitter<any>();
  relationshipOptions: PropertySelectOption[] = [];
  constructor(private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private contactService: ContactService) { }

  ngOnInit(): void {

    const relationships = this.metadataService.values('relationship');
    for (const relationship of relationships) {
      if (relationship.key !== 'OWN') {
        this.relationshipOptions.push(new PropertySelectOption(relationship.value, relationship.key));
      }
    }
  }

  onContactSelection(): void {
    const drawerRef = this.drawerService.create<ContactsSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ContactsSelectionComponent,
      nzContentParams: {
        id: this.id
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.contactsSelected
        .subscribe(
          data => {
            this.contactSelect = data;
            drawerRef.close();
          }
        );

    });
    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onSave(): void {
    const req = { memberId: this.contactSelect.id, relationship: this.relationship};
    this.contactService.addMember(this.id, req)
      .subscribe(
        data => {
          if (data) {
            this.afterSave.emit(data);
          }
        },
        error => {
          console.log(error);
        }
      );
  }
}
