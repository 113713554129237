import { Component, OnInit } from '@angular/core';
import { Knowledge } from '../knowledge-types';
import { PropertyTextEditType } from '../../shared/component/property-text/property-text.component';

@Component({
  selector: 'app-knowledge-detail',
  templateUrl: './knowledge-detail.component.html',
  styleUrls: ['./knowledge-detail.component.less']
})
export class KnowledgeDetailComponent implements OnInit {

  loading = false;

  knowledge: Knowledge;

  contentTextType: PropertyTextEditType = 'html';

  constructor() {
  }

  ngOnInit(): void {
  }

}
