<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 40px">
    {{title}}
  </div>

  <!-- 国家/地区 -->
  <app-property-input *ngIf="isAddressConfig()" [title]="'Country/Region' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('Country/Region' | translate)"
                      [(value)]="address.region"></app-property-input>

  <!-- 省市 -->
  <app-property-input *ngIf="isAddressConfig()" [title]="'Province&City' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('Province&City' | translate)"
                      [(value)]="address.city"></app-property-input>

  <!-- 街道 -->
  <app-property-input *ngIf="isAddressConfig()" [title]="'Street' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('Street' | translate)"
                      [(value)]="address.street"></app-property-input>

  <!-- 详细地址 -->
  <app-property-input [title]="'Location' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('Location' | translate)"
                      [(value)]="address.location"></app-property-input>

  <!-- 快捷地址列表 -->
  <div class="addresses-section">
    <div class="addresses-section-title">
      <span style="margin-left: 8px; margin-bottom: 10px">{{'QuickAddresses' | translate}}</span>
    </div>
    <!-- 快捷地址列表 -->
    <div *ngFor="let address of quickAddresses" class="addresses-item" appThrottleClick (throttleClick)="onQuickAddress(address)">
      <i nz-icon nzType="environment" nzTheme="outline"></i> {{address.toAddressString()}}
    </div>

  </div>


  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="check" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
