<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{browseInfo.appShareRecordVO.title}}
    </div>
    <div *ngFor="let tag of browseInfo.appShareRecordVO.tags; let i = index"
         style="margin-top: 12px;margin-bottom: 21px">
      <span class="tag-outline" style="font-size: 12px; padding: 2px;">{{ tag }}</span>
    </div>
    <div [innerHTML]="browseInfo.appShareRecordVO.content | html"></div>
    <div style="margin:40px 0">
      <app-attachments *ngIf="browseInfo.appShareRecordVO.attachments?.length > 0"
                       [attachments]="browseInfo.appShareRecordVO.attachments"
                       [readonly]="true"></app-attachments>
    </div>
    <div class="ticket-reply">
      <div *ngFor="let item of browseInfo.messageVOList" class="list-item">
        <div class="list-top">
          <nz-avatar class="list-top-avatar" style="margin-right: 20px;" [nzSize]="36"
                     [nzSrc]="item.headImgUrl"></nz-avatar>
          <div class="list-top-name" style="margin-right: 10px;">{{ item.nickname }}</div>
        </div>
        <div class="list-bottom">
          <div class="ticket-reply-content" [innerHTML]="item.content | html"></div>
        </div>
      </div>
    </div>
  </nz-spin>
</div>
