import {Material, PagedReq} from '../api/types';
import {Expose, plainToInstance, Transform, Type} from 'class-transformer';
import {PersonalData, RecommendProduct} from '../fna/fna-types';


export class PactSearch extends PagedReq {
  @Expose() status: string; // 签名状态
  @Expose() id: string; // 合同ID 查询合同详情
}

export class PactSearchRes {
  @Expose() id: number; // 合同ID
  @Expose() pactName: string; // 合同名称
  @Expose() effectiveDate: string; // 生效日期
  @Expose() expiryDate: string; // 失效日期
  @Expose() signedStatus: string; // 签名状态
  @Expose() signDate: string; // 签名时间
}

export class PDFListRes {
  @Expose() name: string; // PDF名称
  @Expose() sign: string; // PDF标识
}

export class PDFDownload {
  @Expose() id: string; // 合同ID
  @Expose() sign: string; // 合同PDF标识
}

export class PactDetails {
  @Expose() pactName: string; // 合同名称
  @Expose() effectiveDate: string; // 生效日期
  @Expose() expiryDate: string; // 失效日期
  @Expose() signedStatus: string; // 签名状态
  @Type(() => Material)
  @Transform((value) => plainToInstance(Material, Object.assign(new Material(), value.value)))
  @Expose() signature: Material = new Material(); // 签名信息
  @Expose() signDate: string; // 签名时间
  @Type(() => PersonalInfo)
  @Transform((value) => plainToInstance(PersonalInfo, Object.assign(new PersonalInfo(), value.value)))
  @Expose() personalInfo: PersonalInfo = new PersonalInfo(); // 个人信息
  @Type(() => BankInfo)
  @Transform((value) => plainToInstance(BankInfo, Object.assign(new BankInfo(), value.value)))
  @Expose() bankInfo: BankInfo = new BankInfo(); // 银行信息
  @Expose() identityCard: Material[]; // 身份证信息
  @Expose() passport: Material; // 护照信息
  @Expose() address: Material; // 地址证明信息
  @Type(() => ContactPerson)
  @Transform((value) => plainToInstance(ContactPerson, Object.assign(new ContactPerson(), value.value)))
  @Expose() contactPerson: ContactPerson[]; // 主要联系人
}

// 签名信息 // 附件信息
export class Signature {
  @Expose() fileName: string; // 文件名称
  @Expose() filePath: string; // 文件路径
  @Expose() uploadTime: number; // 上传时间
  @Expose() author: string; // 上传者
}

// 个人信息
export class PersonalInfo {
  @Expose() name: string; // 姓名
  @Expose() tel: string; // 联络电话
  @Expose() phone: string; // 行动电话
  @Expose() facsimile: string; // 传真
  @Expose() email: string; // 电邮
  @Expose() residential: string; // 居住地址
  @Expose() mailingAddress: string; // 邮寄地址
}

// 银行信息
export class BankInfo {
  @Expose() bankAccount: string; // 银行账号
  @Expose() accountName: string; // 银行账号姓名
  @Expose() bankName: string; // 银行名称
  @Expose() bankCode: string; // 银行代码
  @Expose() bankAddress: string; // 银行地址
}

// 主要联系人
export class ContactPerson {
  @Expose() name: string; // 姓名
  @Expose() position: string; // 职位
  @Expose() phone: string; // 电话
  @Expose() email: string; // 电邮
}

// 合同签名
export class PactSign {
  @Expose() id: number; // 合同ID
  @Expose() imageBase: string; // 签名信息
  @Expose() signDate: string; // 签名日期
}


