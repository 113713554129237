import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  DataDesensitization,
  DataMode,
  Forms,
  Material,
  PendingDetailReply,
  PendingReply,
  PendingDetail,
  SignatureList,
  Underwriting,
  UnderwritingAttachmentReq,
  UnderwritingDetailResp,
  UploadTokenReq,
  UploadType, MessageType, MessageSubType, Instance
} from '../../api/types';
import { UnderwritingService } from '../underwriting.service';
import { TimeLineComponent } from '../../shared/component/time-line/time-line.component';
import { WorkbenchService } from '../../workbench/workbench.service';
import { Workbench } from '../../workbench/workbench-types';
import { MessageService } from '../../message/message.service';
import { MessageRemind } from '../../message/message-types';
import { MetadataService } from '../../shared/service/metadata.service';


@Component({
    selector: 'app-underwriting-detail',
    templateUrl: './underwriting-detail.component.html',
    styleUrls: ['./underwriting-detail.component.less']
})
export class UnderwritingDetailComponent implements OnInit {
    @ViewChild('timeLint')
    timeLint: TimeLineComponent;
    @ViewChild('pendTimeLint')
    pendTimeLint: TimeLineComponent;
    formPdf: Uint8Array;
    loading = false;
    formVisible = false;
    underwritingDetail: UnderwritingDetailResp;
    underwriting: Underwriting;
    selectIndex = 0;
    messageRemind: MessageRemind = new MessageRemind();

    // 预约附件上传参数
    uploadTokenReq = new UploadTokenReq();
    pendingDetail: PendingDetail = new PendingDetail();
    pendingReply: PendingReply[] = [];
    formItem: SignatureList;
    formList: SignatureList[];
    time: Date = new Date();
    @Input()
    mode = DataMode.OWN;
    workbench: Workbench;
    attachmentAuth = {
      display: true,
      readonly: false
    };
    instance: Instance = new Instance();
    dataDesensitization: DataDesensitization = new DataDesensitization();
    constructor(private underwritingService: UnderwritingService,
                private workbenchService: WorkbenchService,
                private messageService: MessageService,
                private metadataService: MetadataService) {
    }

    ngOnInit(): void {
        this.uploadTokenReq.uploadType = UploadType.UNDERWRITING;
        this.uploadTokenReq.middleName = this.underwriting.underwritingNo;
        this.workbench = this.workbenchService.workbench;
        this.instance = this.workbenchService.mockInstance;
        this.viewAttachments();
        this.sendMesseage();
    }

    onAttachmentsChange(attachments: Material[]): void {
        const attachmentReq = new UnderwritingAttachmentReq();
        attachmentReq.underwritingNo = this.underwriting.underwritingNo;
        attachmentReq.attachments = attachments;
        this.loading = true;
        this.underwritingService.updateAttachments(attachmentReq)
            .subscribe(
                data => {
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                });

    }

  reloadDetail(flag): void {
    if (flag) {
      setTimeout(() => { this.loadUnderwritingDetail(this.underwriting.underwritingNo); }, 500);
    }
  }

    loadUnderwritingDetail(UnderwritingNo: string): void {
        this.loading = true;
        this.underwritingService.detail(UnderwritingNo)
            .subscribe(
                underwritingDetail => {
                    this.loading = false;
                    const underwriting = underwritingDetail.toUnderwriting();
                    this.underwritingDetail = underwritingDetail;
                    this.underwriting = underwriting;
                    this.metadataService.groupFiles.emit('');
                },
                error => {
                    this.loading = false;
                });
    }


    onPendingDetail($event: number): void {
        console.log($event);
        const id = $event;
        this.underwritingService.pendingDetail(id)
            .subscribe(
                pendDetail => {
                    this.pendingDetail = pendDetail;
                });
        this.underwritingService.pendingReply(id)
            .subscribe(
                pendReply => {
                    this.pendingReply = pendReply;
                });
    }

    LoadingFormList(item: Forms): void {
        this.underwritingService.fromList(this.underwriting.underwritingNo, this.underwriting.applicationType).subscribe(
            list => {
                console.log(list);
                list.forEach(e => {
                    if (e.bookingFormId === item.formId) {
                        this.formItem = e;
                    }
                });

            }
        );

    }

    onPenddingReplyDetail($event: PendingDetailReply): void {
        this.underwritingService.pendingDetailReply($event)
            .subscribe(
                data => {
                    this.timeLint.reast();

                }
            );
    }

    onPendReplyDetail($event: PendingDetailReply): void {
        this.underwritingService.pendingDetailReply($event)
            .subscribe(
                data => {
                    this.pendTimeLint.reast();
                }
            );
    }

    onFormDetail(i: Forms): void {
        this.LoadingFormList(i);
        this.underwritingService.downloadPdf(this.underwriting.underwritingNo, i.formId).subscribe(
            byteArray => {
                this.formPdf = byteArray;
            }
        );
        this.formVisible = true;
    }

    formClose(): void {
        this.formVisible = false;
    }
    onPreviewNormalResult(): void {
        const url = window.URL.createObjectURL(new Blob([this.formPdf], {type: 'application/pdf'}));
        window.open(url);
    }

    onDownloadNormalResult(): void {
        const a = document.createElement('a');
        a.download = this.formItem.bookingFormName + '.pdf';
        a.href = window.URL.createObjectURL(new Blob([this.formPdf], {type: 'application/pdf'}));
        a.click();
    }

  firstLevelAuthority(): void {
    switch (this.mode) {
      case DataMode.SUBORDINATE:
        if (this.workbench.childAttachmentUpload === 0) {
          this.attachmentAuth.display = false;
        }
        break;
      case DataMode.FOLLOWUP:
        if (this.workbench.followAttachmentUpload === 0) {
          this.attachmentAuth.display = false;
        }
        break;
      case DataMode.SALESGROUP:
        if (this.workbench.teamAttachmentUpload === 0) {
          this.attachmentAuth.display = false;
        }
        break;
    }
  }
  accessories(): void {
    if (this.workbench.underwritingAttachmentUploadNew === 0) {
      // 全部禁止
      this.attachmentAuth.display = false;
      return ;
    }
    if (this.workbench.underwritingAttachmentUploadNew === 2) {
      this.attachmentAuth.display = true;
      this.attachmentAuth.readonly = true;
      // 附件只读
      // 二级权限
      this.firstLevelAuthority();
      return ;
    }
    if (this.workbench.underwritingAttachmentUploadNew === 1) {
      switch (this.mode) {
        case DataMode.SUBORDINATE:
          if (this.workbench.childAttachmentUpload === 0) {
            this.attachmentAuth.display = false;
          }
          if (this.workbench.childAttachmentUpload === 2) {
            this.attachmentAuth.display = true;
            this.attachmentAuth.readonly = true;
          }
          if (this.workbench.childAttachmentUpload === 1) {
            this.attachmentAuth.display = true;
            this.attachmentAuth.readonly = false;
          }
          break;
        case DataMode.FOLLOWUP:
          if (this.workbench.followAttachmentUpload === 0) {
            this.attachmentAuth.display = false;
          }
          if (this.workbench.followAttachmentUpload === 2) {
            this.attachmentAuth.display = true;
            this.attachmentAuth.readonly = true;
          }
          if (this.workbench.followAttachmentUpload === 1) {
            this.attachmentAuth.display = true;
            this.attachmentAuth.readonly = false;
          }
          break;
        case DataMode.SALESGROUP:
          if (this.workbench.teamAttachmentUpload === 0) {
            this.attachmentAuth.display = false;
          }
          if (this.workbench.teamAttachmentUpload === 2) {
            this.attachmentAuth.display = true;
            this.attachmentAuth.readonly = true;
          }
          if (this.workbench.teamAttachmentUpload === 1) {
            this.attachmentAuth.display = true;
            this.attachmentAuth.readonly = false;
          }
          break;
      }
    }
  }

  viewAttachments(): void {
    switch (this.mode) {
      case DataMode.OWN:
        if (this.workbench.underwritingAttachmentUploadNew === 0) {
          this.attachmentAuth.display = false;
        }
        if (this.workbench.underwritingAttachmentUploadNew === 2) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = true;
        }
        if (this.workbench.underwritingAttachmentUploadNew === 1) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = false;
        }
        break;
      case DataMode.SUBORDINATE:
        if (this.workbench.childAttachmentUpload === 0) {
          this.attachmentAuth.display = false;
        }
        if (this.workbench.childAttachmentUpload === 2) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = true;
        }
        if (this.workbench.childAttachmentUpload === 1) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = false;
        }
        break;
      case DataMode.FOLLOWUP:
        if (this.workbench.followAttachmentUpload === 0) {
          this.attachmentAuth.display = false;
        }
        if (this.workbench.followAttachmentUpload === 2) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = true;
        }
        if (this.workbench.followAttachmentUpload === 1) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = false;
        }
        break;
      case DataMode.SALESGROUP:
        if (this.workbench.teamAttachmentUpload === 0) {
          this.attachmentAuth.display = false;
        }
        if (this.workbench.teamAttachmentUpload === 2) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = true;
        }
        if (this.workbench.teamAttachmentUpload === 1) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = false;
        }
        break;
    }
  }

  isDynamicEncryption(keyName): boolean {
    let isShow = false;
    if (!this.dataDesensitization || !this.mode) {
      return isShow;
    }
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    }
  }

  get isShowItem(): boolean {
    const instance = this.underwritingService.instance;
    return instance?.key === 'cash' || instance?.key === 'dev';
  }

  tabsIndex(e): void {
    this.selectIndex = e.index;
    this.sendMesseage();
  }

  sendMesseage(): void{
    const params = {
      key: this.underwriting.underwritingNo,
      type: MessageType.UNDERWRITING,
      subType: undefined,
    };
    switch (this.selectIndex) {
      case 0:
        params.subType = MessageSubType.STATUS;
        break;
      case 1:
        params.subType = MessageSubType.INFO;
        break;
      case 2:
        params.subType = MessageSubType.ATTACHMENT;
        break;
      case 3:
        params.subType = MessageSubType.PENDING;
        break;
    }
    this.messageService.putMessageReads(params).subscribe(
      () => {
        this.getMessageRemind();
      }
    );
  }

  getMessageRemind(): void {
    const param = {
      type: MessageType.UNDERWRITING,
      key: this.underwriting.underwritingNo
    };
    this.messageService.getMessageRemind(param)
      .subscribe(
        data => {
          this.messageRemind = data;
        },
        error => {}
      );
  }

}
