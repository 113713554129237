import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  DataDesensitization,
  DataMode, MessageSubType,
  MessageType,
  PagedResp,
  PendingReply,
  TemplateFileMD5List,
  UploadTokenReq,
  UploadType
} from '../../api/types';
import { Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { BookingService } from '../booking.service';
import { BookingSearchComponent } from '../booking-search/booking-search.component';
import { BookingCreateComponent } from '../booking-create/booking-create.component';
import { TranslateService } from '@ngx-translate/core';
import { BookingDetailComponent } from '../booking-detail/booking-detail.component';
import { EventService } from '../../shared/service/event.service';
import { ToastrService } from 'ngx-toastr';
import { Booking, BookingGroupListResp, BookingListResp, BookingSearch, DownloadSearch, ImportResTips } from '../booking-types';
import { MessageDialogComponent } from '../../shared/component/message-dialog/message-dialog.component';
import { BaseComponent } from '../../base/base.component';
import { formatDate } from '@angular/common';
import { FileItem, FileUploader, Headers, ParsedResponseHeaders } from 'ng2-file-upload';
import { API } from '../../api/api';
import { FileLikeObject } from 'ng2-file-upload/file-upload/file-like-object.class';
import { HttpService } from '../../core/http.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { WorkbenchService } from '../../workbench/workbench.service';
import { plainToInstance } from 'class-transformer';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BookingBatchComponent } from '../../shared/component/booking-batch/booking-batch.component';
import { MessageService } from '../../message/message.service';
import { AccountService } from '../../account/account.service';
import { Workbench } from '../../workbench/workbench-types';

export class AttachmentFileAlert {
  showAlert = false;
  maxFileSize = 100 * 1024 * 1024;
  fileSize = 0;

  get maxFileSizeStr(): string {
    return (this.maxFileSize / (1024 * 1024)).toFixed(2) + 'M';
  }

  get fileSizeStr(): string {
    return (this.fileSize / (1024 * 1024)).toFixed(2) + 'M';
  }
}

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.less']
})
export class BookingsComponent extends BaseComponent implements OnInit {

  @Input()
  mode = DataMode.OWN;

  loading = false;

  spinning = false;

  Dates: number[];

  groupMode = false;

  bookings: PagedResp<BookingListResp>;
  groupBookings: PagedResp<BookingGroupListResp>;

  createPopoverVisible = false;
  isVisible = false;
  iconType = 'download';
  uploader: FileUploader;
  @ViewChild('fileUpload')
  fileUpload: ElementRef;
  fileFormat = '';
  fileAlert = new AttachmentFileAlert();
  importMessageVisible = false;
  // updateTemplate = false; // new
  errorDetails: ImportResTips = new ImportResTips();
  search: BookingSearch = new BookingSearch();
  // templateMd5: TemplateFileMD5List[] = [];
  workbench: Workbench;
  isValueMd5: string;

  downloadSearch: DownloadSearch = new DownloadSearch();
  uploadTokenReq: UploadTokenReq = new UploadTokenReq();
  filterPopoverVisible = false;
  dataDesensitization: DataDesensitization = new DataDesensitization();
  deleting = false;

  constructor(private bookingService: BookingService,
              private eventService: EventService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private router: Router,
              private drawerService: NzDrawerService,
              private httpService: HttpService,
              private modalService: NzModalService,
              private notificationService: NzNotificationService,
              private workbenchService: WorkbenchService,
              private messageService: MessageService,
              private accountService: AccountService
  ) {
    super();
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;

    this.load();
    this.dataDesensitization = this.accountService.dataDesensitization;

    this.eventService.bookingsReload
      .subscribe(
        data => {
          this.onReload(false);
        })
      .disposedBy(this.disposeBag);

    this.uploader = new FileUploader({
      disableMultipart: false,
      autoUpload: false,
      removeAfterUpload: false,
    });
    this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);
    this.uploader.onSuccessItem = this.onUploadSuccess.bind(this);
    this.uploader.onErrorItem = this.onUploadError.bind(this);
    this.uploader.onWhenAddingFileFailed = this.onWhenAddingFileFailed.bind(this);
    // console.log(this.workbenchService.workbench);
    this.isValueMd5 = localStorage.getItem(`md5:booking`);

    this.bookingService.toReloadList
      .subscribe(mode => {
        if (mode === this.mode) {
          this.onReload();
        }
      }
    ).disposedBy(this.disposeBag);

  }

  load(): void {

    this.loading = true;

    if (this.groupMode) {
      this.bookingService.groupList(this.search, this.mode)
        .subscribe(
          data => {
            data.list.forEach(e => {
              e.expand = false;
            });
            this.loading = false;
            this.groupBookings = data;
          },
          error => {
            this.loading = false;
          })
        .disposedBy(this.disposeBag);
    } else {
      this.bookingService.list(this.search, this.mode)
        .subscribe(
          data => {
            this.loading = false;
            this.bookings = data;
          },
          error => {
            this.loading = false;
          })
        .disposedBy(this.disposeBag);
    }

  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.load();
  }

  onReload(resetPageNum = true): void {
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.load();
  }

  onDownload(): void {
    this.Dates = undefined;
    this.downloadSearch = new DownloadSearch();
  }

  onInspectDateChange(time: number[]): void {
    if (time) {
      this.Dates = time;
      this.downloadSearch.addStartDate = formatDate(time[0], 'yyyy-MM-dd', 'en-US');
      this.downloadSearch.addEndDate = formatDate(time[1], 'yyyy-MM-dd', 'en-US');
    }
  }

  onBookingCreate(): void {

    const instance = this.bookingService.mockInstance;
    if (instance.isFone()) {
      this.translate.get(['FNAToBooking', 'Tips']).subscribe(
        res => {
          this.modalService.info({
            nzCentered: true,
            nzTitle: res.Tips,
            nzContent: res.FNAToBooking,
            nzClosable: true,
            nzOnOk: () => {},
          });
        }
      );
      return;
    }

    const drawerRef = this.drawerService.create<BookingCreateComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: BookingCreateComponent,
      nzContentParams: {},
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.bookingDraftSaved.subscribe(
        data => {
          drawerRef.close();
          // this.onReload();
        }
      );
      component.bookingApplied.subscribe(
        data => {
          drawerRef.close();
          // this.onReload();
        }
      );
    });

    drawerRef.afterClose.subscribe(data => {
    });

  }

  onBookingSearch(): void {
    const drawerRef = this.drawerService.create<BookingSearchComponent, { value: BookingSearch }, string>({
      nzWidth: 800,
      nzContent: BookingSearchComponent,
      nzContentParams: {
        search: this.search,
        mode: this.mode,
        Dates: this.search.appointmentTimeConfirmStart ? [this.search.appointmentTimeConfirmStart, this.search.appointmentTimeConfirmEnd] : null
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.bookingSearch
        .subscribe(
          search => {
            this.search = search;
            this.onSearch(1);
            drawerRef.close();
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onBookingDetail(bookingNo: string): void {
    this.spinning = true;
    this.bookingService.detail(bookingNo)
      .subscribe(
        bookingDetail => {

          this.spinning = false;
          const booking = bookingDetail.toBooking();

          const drawerRef = this.drawerService.create<BookingDetailComponent, { value: Booking }, string>({
            nzWidth: 800,
            nzContent: BookingDetailComponent,
            nzContentParams: {
              bookingDetail,
              booking,
              mode: this.mode
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
            this.onReload(false);
            this.messageService.messageUnreadEvent.emit('');
          });
        },
        error => {
          this.spinning = false;
        }).disposedBy(this.disposeBag);
  }

  onLeaveMessage(booking: BookingListResp): void {
    this.sendMesseage(booking.bookingNo);
    const bookingNo = booking.bookingNo;
    this.uploadTokenReq.uploadType = UploadType.BOOKING;
    this.uploadTokenReq.middleName = bookingNo;
    const instance = this.bookingService.mockInstance;
    let canReply = true;
    if (instance.groupKey === 'wanson' || instance.groupKey === 'dev') {
      canReply = true;
    } else {
      canReply = booking.status !== 'COMPLETED';
    }
    this.translate.get('BookingDialog').subscribe(
      title => {
        this.bookingService.message(bookingNo)
          .subscribe(
            data => {
              const drawerRef = this.drawerService.create<MessageDialogComponent, { value: PendingReply }, string>({
                nzWidth: 800,
                nzContent: MessageDialogComponent,
                nzContentParams: {
                  Title: title,
                  list: data,
                  uploadTokenReq: this.uploadTokenReq,
                  moduleId: bookingNo,
                  replyContent: canReply,
                  moduleType: 'Booking'
                }
              });
              drawerRef.afterOpen.subscribe(() => {
                const component = drawerRef.getContentComponent();
                component.dialogReplied.subscribe(
                  dialogRep => {
                    this.bookingService.dialogReply(dialogRep).subscribe(
                      () => {
                        this.bookingService.message(bookingNo).subscribe(
                          list => {
                            component.loadList(list);
                          });
                      });
                  });
                component.confirmParam.subscribe(
                  confirmData => {
                    this.bookingService.putDialogConfirm(confirmData).subscribe(
                      resFlag => {
                        if (resFlag) {
                          this.bookingService.message(bookingNo).subscribe(
                            list => {
                              component.loadList(list);
                            }
                          );
                        }
                      });
                  });
              });

              drawerRef.afterClose.subscribe(() => {
                this.onReload(false);
                // this.workbenchService.reloadWorkbench.emit('');
                this.messageService.messageUnreadEvent.emit('');
              });
            });
      });
  }

  onGroupModeChange(groupMode: any): void {
    this.load();
  }

  sendMesseage(num): void{
    const params = {
      key: num,
      type: MessageType.BOOKING,
      subType: MessageSubType.DIALOG,
    };
    this.messageService.putMessageReads(params).subscribe();
  }

  onExportPDF(): void {
    console.log(this.Dates);
    if (this.Dates && this.Dates.length > 1) {
      // this.loading = true;
      let type = null;
      switch (this.mode) {
        case DataMode.OWN:
          type = 1;
          break;
        case DataMode.SUBORDINATE:
          type = 2;
          break;
        case DataMode.FOLLOWUP:
          type = 4;
          break;
        case DataMode.SALESGROUP:
          type = 3;
          break;
      }
      const param = {
        addEndDate: this.downloadSearch.addEndDate,
        addStartDate: this.downloadSearch.addStartDate,
        type
      };
      this.bookingService.downloadExcel(param)
        .subscribe(
          data => {
            console.log(data);
            if (data.headers && data.headers.headers) {

            }
            const link = document.createElement('a');
            const blob = new Blob([data.body], {type: 'application/vnd.ms-excel'});
            console.log(blob);
            let fileName = data.headers.get('Content-Disposition').split(';')[1].split('filename=')[1];
            // const fileNameUnicode = data.headers.get('Content-Disposition').split('filename*=')[1];
            // 当存在 filename* 时，取filename* 并进行解码（为了解决中文乱码问题）
            if (fileName) {
              fileName = decodeURIComponent(fileName.replace( /\+/g, '%20' ));
            }
            console.log(fileName);

            link.setAttribute('href', window.URL.createObjectURL(blob));
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // const url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
            // window.open(url);
            this.loading = false;
          }, error => {
            console.log(error);
            const reader = new FileReader();
            reader.readAsText(error.error);
            reader.onload = e => {
              const msg = JSON.parse(e.target['result'] as string);
              this.toastr.warning(msg.message ?? '');
            };
            this.loading = false;
          });
    }

  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleOk(): void {
    if (this.fileUpload) {
      this.fileUpload.nativeElement.click();
    }
    // this.isVisible = false;
    // this.loading = true;
  }

  // excel 模板下載
  exportExcel(): void {
    if (this.updateTemplate) {
      const list = this.workbenchService.workbench.templateFileMD5List || [];
      let templateMd5: TemplateFileMD5List;
      templateMd5 = list.find((o) => {
        return o.name === 'booking';
      });
      localStorage.setItem(`md5:booking`, templateMd5.md5);
      this.isValueMd5 = localStorage.getItem(`md5:booking`);
    }
    this.iconType = 'loading';
    this.bookingService.exportPDF()
      .subscribe(
        data => {
          const link = document.createElement('a');
          const blob = new Blob([data.body], {type: 'application/vnd.ms-excel'});
          let fileName = data.headers.get('Content-Disposition').split(';')[1].split('filename=')[1];
          // const fileNameUnicode = data.headers.get('Content-Disposition').split('filename*=')[1];
          // 当存在 filename* 时，取filename* 并进行解码（为了解决中文乱码问题）
          if (fileName) {
            fileName = decodeURIComponent(fileName.replace( /\+/g, '%20' ));
          }
          console.log(fileName);

          link.setAttribute('href', window.URL.createObjectURL(blob));
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.loading = false;
          this.iconType = 'download';
        }, error => {
          this.loading = false;
          this.iconType = 'download';
        });
  }

  onExportExcel(bookingNo): void {
    console.log('onExportExcel');
    this.spinning = true;
    this.bookingService.exportExcel(bookingNo)
      .subscribe(
        data => {
          const link = document.createElement('a');
          const blob = new Blob([data.body], {type: 'application/vnd.ms-excel'});
          let fileName = data.headers.get('Content-Disposition').split(';')[1].split('filename=')[1];
          // const fileNameUnicode = data.headers.get('Content-Disposition').split('filename*=')[1];
          // 当存在 filename* 时，取filename* 并进行解码（为了解决中文乱码问题）
          if (fileName) {
            fileName = decodeURIComponent(fileName.replace( /\+/g, '%20' ));
          }
          console.log(fileName);

          link.setAttribute('href', window.URL.createObjectURL(blob));
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.spinning = false;
        }, error => {
          this.spinning = false;
        });
  }


  ImportFileOnChanged(event): void {
  }

  onAfterAddingFile(fileItem: FileItem): any {
    fileItem.withCredentials = false;
    if (this.fileUpload) {
      // 重置文件选择，否则无法重复上传同一个文件
      this.fileUpload.nativeElement.value = '';
    }
    this.uploadFileItem(fileItem);
  }

  uploadFileItem(fileItem: FileItem): void {
    fileItem.method = 'POST';
    fileItem.url = API.bookingV3 + '/import-excel';
    fileItem.alias = 'file';
    this.spinning = true;
    const headers: Headers[] = [];
    const headersMap = this.httpService.getApiHttpHeaders(fileItem.url);
    headersMap.forEach((value, key, map) => {
      headers.push({name: key, value});
    });
    fileItem.headers = headers;
    fileItem.upload();
  }

  // 文件上传成功
  onUploadSuccess(item: FileItem, response: any, status: number, headers: ParsedResponseHeaders): any {
    this.spinning = false;
    response = JSON.parse(response);
    /// 1，非Excel或者Excel解析失败，失败，直接弹Toast，不弹提示窗  code != 1000
    if (response.code !== 1000) {
      if (response.message !== 'null') {
        this.toastr.error(response.message);
      }
    } else {
      /// 2，Excel版本正确，内容有问题，弹失败Toast，弹提示窗，code == 1000，date != null
      if (response.data) { //
        /// 弹提示窗
        this.errorDetails = plainToInstance(ImportResTips, response.data);
        this.importMessageVisible = true;
        if (response.message) {
          this.errorDetails.message = response.message;
        }
        /// 弹失败Toast
        this.translate.get('ExcelUploadFailed').subscribe(
          (res) => {
            this.toastr.error(res);
          }
        );
      } else {
        /// 3，成功（预约创建成功）：直接弹Toast，不弹提示窗，code == 1000，data == null
        /// 弹成功Toast
        this.translate.get('ExcelUploadSuccess').subscribe(
          (res) => {
            this.toastr.success(res);
          }
        );
      }
      this.onReload();
    }
    this.handleCancel();
    this.uploader.removeFromQueue(item);
  }

  onUploadError(item: FileItem, response: any, status: number, headers: ParsedResponseHeaders): any {
    this.spinning = false;
    response = JSON.parse(response);
    if (response.message !== 'null') {
      this.toastr.warning(response.message);
    }
    this.uploader.removeFromQueue(item);
    console.log(response + ' for ' + item.file.name + ' status ' + status, '===========');
  }

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any): any {
    this.spinning = false;
    console.log(item, filter, options, '----------------');
    if (filter.name === 'fileSize') {
      this.fileAlert.showAlert = true;
      this.fileAlert.fileSize = item.size;
      this.translate.get('ExceedsTheLimitsOfFileSize',
        {maxLength: this.fileAlert.maxFileSizeStr, length: this.fileAlert.fileSizeStr})
        .subscribe(
          text => {
            this.notificationService.create(
              'warning',
              '',
              text
            );
          }
        );
    }
  }

  get hasData(): boolean {
    return this.groupMode ? !!this.groupBookings : !!this.bookings;
  }

  get updateTemplate(): boolean {
    const list = this.workbenchService.workbench.templateFileMD5List || [];
    let templateMd5: TemplateFileMD5List;
    templateMd5 = list.find((o) => {
      return o.name === 'booking';
    });
    if (templateMd5) {
      if (this.isValueMd5 && this.isValueMd5 === templateMd5.md5) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  onDelete(booking: BookingListResp, tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: booking,
      nzOnOk: () => {
      }
    });
  }

  onDeleteConfirm(ref: NzModalRef, booking: BookingListResp): void {
    this.deleting = true;
    this.bookingService.delete(booking.bookingNo)
      .subscribe(
        data => {
          this.deleting = false;
          if (data) {
            this.translate.get('DeleteSuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          }
          this.onSearch(this.search.pageNum);
          ref.close();
        },
        error => {
          this.deleting = false;
          ref.close();
        });
  }

  batchOperation(groupNo: string, tit: string): void {
    this.uploadTokenReq.uploadType = UploadType.BOOKING;
    this.uploadTokenReq.middleName = groupNo;
    this.translate.get(tit).subscribe(
      title => {
        const drawerRef = this.drawerService.create<BookingBatchComponent, { value: PendingReply }, string>({
          nzWidth: 800,
          nzContent: BookingBatchComponent,
          nzContentParams: {
            Title: title,
            uploadTokenReq: this.uploadTokenReq,
            isAnnex: tit === 'BatchUploadAttachments'
          }
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.dialogRes.subscribe(
            dialogRep => {
              dialogRep.groupNo = groupNo;
              if (tit === 'BatchUploadAttachments') {
                const param = {
                  groupNo,
                  attachments: dialogRep.attachments
                };
                this.bookingService.groupAnnexReply(param).subscribe(
                  () => {
                    component.loadList(dialogRep);
                  });
              } else {
                this.bookingService.groupDialogReply(dialogRep).subscribe(
                  () => {
                    component.loadList(dialogRep);
                  });
              }
            });
        });

        drawerRef.afterClose.subscribe(() => {});
      });
  }
  timeChange(value): void {
    this.search.orderColumn = 'appointmentTimeAndTemporaryTime';
    this.search.orderAspect = value === 'ascend' ? 'ASC' : value === 'descend' ? 'DESC' : null;
    this.onReload();
  }

  statusChange(value): void {
    this.search.orderColumn = 'status';
    this.search.orderAspect = value === 'ascend' ? 'ASC' : value === 'descend' ? 'DESC' : null;
    this.onReload();
  }

  dataMasking(str): string {
    if (!str) {
      return ;
    }
    if (str.toString().length === 1) {
      return '*';
    }
    str = str.toString();
    let frontLen = 0;
    let endLen = 0;
    switch (str.length) {
      case 2:
      case 3:
        frontLen = 1;
        endLen = 0;
        break;
      case 4:
      case 5:
        frontLen = 1;
        endLen = 1;
        break;
      case 6:
      case 7:
      case 8:
        frontLen = 2;
        endLen = 1;
        break;
      default:
        frontLen = 3;
        endLen = 3;
        break;
    }
    const len = str.length - frontLen - endLen;
    let xing = '';
    for (let i = 0; i < len; i++) {
      xing += '*';
    }
    return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
  }

  dynamicEncryption(keyName, val): string {
    let isShow = val;
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = this.dataMasking(val);
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = this.dataMasking(val);
        }
      });
    }
    return isShow;
  }

}
