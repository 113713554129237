import { Address, Beneficiary, Customer, CustomerCorporate, Phone } from '../../api/types';
import * as _ from 'lodash';
import {ContactInfoRes} from '../../contact/contact-types';
import { plainToInstance } from 'class-transformer';
import { BasicInformation } from '../../new-fna/new-fna-types';

export class Phones {

  public static getIndividualQuickPhones(customers: Customer[]): Phone[] {
    const quickPhones: Phone[] = [];
    for (const customer of customers) {
      if (!customer) {
        continue;
      }
      if (customer.areaCode && customer.phone) {
        const phone = new Phone();
        phone.areaCode = customer.areaCode;
        phone.phone = customer.phone;
        quickPhones.push(plainToInstance(Phone, phone));
      }
      if (customer.residenceAreaCode && customer.residenceTelephone) {
        const phone = new Phone();
        phone.areaCode = customer.residenceAreaCode;
        phone.phone = customer.residenceTelephone;
        quickPhones.push(plainToInstance(Phone, phone));
      }
      if (customer.companyAreaCode && customer.companyTelephone) {
        const phone = new Phone();
        phone.areaCode = customer.companyAreaCode;
        phone.phone = customer.companyTelephone;
        quickPhones.push(plainToInstance(Phone, phone));
      }
      if (customer.phones && customer.phones.length > 0) {
        for (const phone of customer.phones) {
          if (phone && phone.toString()) {
            quickPhones.push(plainToInstance(Phone, phone));
          }
        }
      }
    }
    return quickPhones;
  }

  public static getCorporateQuickPhones(customers: CustomerCorporate[]): Phone[] {
    const quickPhones: Phone[] = [];
    for (const customer of customers) {
      if (!customer) {
        continue;
      }
      if (customer.areaCode && customer.telephone) {
        const phone = new Phone();
        phone.areaCode = customer.areaCode;
        phone.phone = customer.telephone;
        quickPhones.push(plainToInstance(Phone, phone));
      }
      if (customer.legalPersonAreaCode && customer.legalPersonPhone) {
        const phone = new Phone();
        phone.areaCode = customer.legalPersonAreaCode;
        phone.phone = customer.legalPersonPhone;
        quickPhones.push(plainToInstance(Phone, phone));
      }
      if (customer.phones && customer.phones.length > 0) {
        for (const phone of customer.phones) {
          if (phone && phone.toString()) {
            quickPhones.push(plainToInstance(Phone, phone));
          }
        }
      }
    }
    return quickPhones;
  }

  public static getContactQuickPhones(contacts: ContactInfoRes[]): Phone[] {
    const quickPhones: Phone[] = [];
    for (const contact of contacts) {
      if (!contact) {
        continue;
      }
      if (contact.areaCode && contact.phone) {
        const phone = new Phone();
        phone.areaCode = contact.areaCode;
        phone.phone = contact.phone;
        quickPhones.push(plainToInstance(Phone, phone));
      }
      if (contact.residenceAreaCode && contact.residenceTelephone) {
        const phone = new Phone();
        phone.areaCode = contact.residenceAreaCode;
        phone.phone = contact.residenceTelephone;
        quickPhones.push(plainToInstance(Phone, phone));
      }
      if (contact.companyAreaCode && contact.companyTelephone) {
        const phone = new Phone();
        phone.areaCode = contact.companyAreaCode;
        phone.phone = contact.companyTelephone;
        quickPhones.push(plainToInstance(Phone, phone));
      }
      if (contact.phones && contact.phones.length > 0) {
        for (const phone of contact.phones) {
          if (phone && phone.toString()) {
            quickPhones.push(plainToInstance(Phone, phone));
          }
        }
      }
    }
    return quickPhones;
  }

  public static getBeneficiaryQuickPhones(beneficiaries: Beneficiary[]): Phone[] {
    const quickPhones: Phone[] = [];
    if (!beneficiaries) {
      return quickPhones;
    }
    for (const beneficiary of beneficiaries) {
      if (!beneficiary) {
        continue;
      }
      if (beneficiary.areaCode && beneficiary.telephone) {
        const phone = new Phone();
        phone.areaCode = beneficiary.areaCode;
        phone.phone = beneficiary.telephone;
        quickPhones.push(plainToInstance(Phone, phone));
      }
      if (beneficiary.legalPersonAreaCode && beneficiary.legalPersonPhone) {
        const phone = new Phone();
        phone.areaCode = beneficiary.legalPersonAreaCode;
        phone.phone = beneficiary.legalPersonPhone;
        quickPhones.push(plainToInstance(Phone, phone));
      }
      // if (beneficiary.phones && beneficiary.phones.length > 0) {
      //   for (const phone of beneficiary.phones) {
      //     if (phone && phone.toString()) {
      //       quickPhones.push(plainToInstance(Phone, phone));
      //     }
      //   }
      // }
    }
    return quickPhones;
  }

  public static getFnaQuickPhones(basicInfo: BasicInformation[]): Phone[] {
    const quickPhones: Phone[] = [];
    if (!basicInfo) {
      return quickPhones;
    }
    for (const beneficiary of basicInfo) {
      if (!beneficiary) {
        continue;
      }
      if (beneficiary.mobile && beneficiary.mobile.includes(' ')) {
        const phone = new Phone();
        phone.areaCode = beneficiary.mobile.split(' ')[0];
        phone.phone = beneficiary.mobile.split(' ')[1];
        quickPhones.push(plainToInstance(Phone, phone));
      }
      if (beneficiary.homePhone && beneficiary.homePhone.includes(' ')) {
        const phone = new Phone();
        phone.areaCode = beneficiary.homePhone.split(' ')[0];
        phone.phone = beneficiary.homePhone.split(' ')[1];
        quickPhones.push(plainToInstance(Phone, phone));
      }
      if (beneficiary.officeTelephone && beneficiary.officeTelephone.includes(' ')) {
        const phone = new Phone();
        phone.areaCode = beneficiary.officeTelephone.split(' ')[0];
        phone.phone = beneficiary.officeTelephone.split(' ')[1];
        quickPhones.push(plainToInstance(Phone, phone));
      }
    }
    return quickPhones;
  }
}
