import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { Fna, FnaSignedStatus, FnaSigner } from '../fna-types';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { FnaService } from '../fna.service';
import { FnaSignaturePadComponent } from '../fna-signature-pad/fna-signature-pad.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { WorkbenchService } from '../../workbench/workbench.service';
import { Workbench } from '../../workbench/workbench-types';

@Component({
  selector: 'app-fna-signature',
  templateUrl: './fna-signature.component.html',
  styleUrls: ['./fna-signature.component.less']
})
export class FnaSignatureComponent implements OnInit {

  drawerRef: NzDrawerRef<FnaSignatureComponent, string>;

  fna: Fna;
  loading = false;
  workbench: Workbench;

  @Output()
  submitted: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  resigned: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fnaService: FnaService,
              private modalService: NzModalService,
              private drawerService: NzDrawerService,
              private workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
  }

  onViewFna(): void {
    this.loading = true;
    this.fnaService.viewFna(this.fna.id)
      .subscribe(
        data => {
          const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
          window.open(url);
          this.loading = false;
        }, error => {
          this.loading = false;
        });
  }

  onConsultantSignature(): void {
    const drawerRef = this.drawerService.create<FnaSignaturePadComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: FnaSignaturePadComponent,
      nzContentParams: {
        fna: this.fna,
        signer: FnaSigner.SALES
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.signed.subscribe(
        data => {
          this.loadDetail();
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onClientSignature(): void {
    const drawerRef = this.drawerService.create<FnaSignaturePadComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: FnaSignaturePadComponent,
      nzContentParams: {
        fna: this.fna,
        signer: FnaSigner.OWNER
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.signed.subscribe(
        data => {
          this.loadDetail();
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  loadDetail(): void {
    this.loading = true;
    this.fnaService.info(this.fna.id)
      .subscribe(
        fna => {
          this.loading = false;
          this.fna = fna;
        },
        error => {
          this.loading = false;
        });
  }

  get canSubmit(): boolean {
    let flag = true;
    switch (this.workbench.fnaSign) {
      case 0:
        flag = true;
        break;
      case 1:
        if (this.fna.salesSignedStatus === FnaSignedStatus.UNSIGNED || this.fna.signedStatus !== FnaSignedStatus.UNSIGNED) {
          flag = false;
        }
        break;
      case 2:
        if (this.fna.ownerSignedStatus === FnaSignedStatus.UNSIGNED || this.fna.signedStatus !== FnaSignedStatus.UNSIGNED) {
          flag = false;
        }
        break;
      default:
        if (this.loading) {
          flag = false;
        }
        if (this.fna.salesSignedStatus === FnaSignedStatus.UNSIGNED || this.fna.ownerSignedStatus === FnaSignedStatus.UNSIGNED) {
          flag = false;
        }
        if (this.fna.signedStatus !== FnaSignedStatus.UNSIGNED) {
          flag = false;
        }
    }
    return flag;
  }

  onSubmit(): void {
    if (!this.canSubmit) {
      return;
    }
    this.loading = true;
    this.fnaService.end(this.fna.id)
      .subscribe(
        fna => {
          this.loading = false;
          this.drawerRef.close();
          this.submitted.emit('');
        },
        error => {
          this.loading = false;
        });
  }

  onResign(tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: this.fna,
      nzOnOk: () => {
      }
    });
  }

  onReSignConfirm(ref: NzModalRef): void {
    this.loading = true;
    this.fnaService.reSign(this.fna.id)
      .subscribe(
        fna => {
          this.loading = false;
          this.resigned.emit('');
          this.loadDetail();
          ref.close();
        },
        error => {
          this.loading = false;
          ref.close();
        });
  }
}
