<div class="drawer-container">

  <div class="common-title">
    {{messageTypeTranslate(messageOverview.type)}}{{'Message' | translate}}
  </div>

  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!messages">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="messages" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="messages.list"
                [nzPageIndex]="messages.pageNum"
                [nzPageSize]="messages.pageSize"
                [nzTotal]="messages.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{ x: '1150px'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'MessageList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'ClearUnreadMessages' | translate"
                        class="centre-container" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onAllReads()">
                  <img alt="" width="22" src="../../../assets/images/ic_clear_unread.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'DeleteAll' | translate"
                        class="centre-container" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onDelReads(tplTitleAll, tplContentAll)">
                  <img alt="" width="24" src="../../../assets/images/ic_delete_all.svg">
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ messages.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="120px">
            <span nz-tooltip [nzTooltipTitle]="'MessageID' | translate">{{'MessageID' | translate}}</span>
          </th>
          <th nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'MessageTitle' | translate">{{'MessageTitle' | translate}}</span>
          </th>
          <th>
            <span nz-tooltip [nzTooltipTitle]="'MessageContent' | translate">{{'MessageContent' | translate}}</span>
          </th>
          <th>
            <span nz-tooltip [nzTooltipTitle]="'Time' | translate">{{'Time' | translate}}</span>
          </th>
          <th nzRight nzWidth="100px">
            <span>{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let message of messages.list; let i = index" appThrottleClick (throttleClick)="onMessageDetail(message)">
          <td nzLeft>
            <nz-badge [nzDot]="message.status === 'UNREAD'">
            <span style="font-weight: bold; font-size: 12px">
              {{ message.id }}
            </span>
            </nz-badge>
          </td>
          <td><span>{{ message.title }}</span></td>
          <td>
            <div style="display: flex; align-items: center; color: #666666">
              <span>{{ message.content }}</span>
            </div>
          </td>
          <td><span>{{ message.addTime | date:'yyyy-MM-dd HH:mm:ss' }}</span></td>
          <td nzRight>
            <div class="functions">
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
                 appThrottleClick (throttleClick)="onDelete(message, tplTitle, tplContent, tplFooter); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_delete.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>

</div>

<ng-template #tplTitle>
  <span>{{'MessageDelete' | translate}}</span>
</ng-template>
<ng-template #tplContent let-params>
  <p>{{'DoYouWantToDeleteMessage' | translate}} :「{{ params.id }} - {{ params.title }}」?</p>
</ng-template>
<ng-template #tplFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()" [disabled]="deleting">{{'Cancel' | translate}}</button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onDeleteConfirm(ref, params)" [nzLoading]="deleting">
    {{'Delete' | translate}}
  </button>
</ng-template>

<!-- 全部删除二次确认  -->
<ng-template #tplTitleAll>
  <span>
    {{ 'OperationReminder' | translate }}
  </span>
</ng-template>
<ng-template #tplContentAll>
  <span>
    {{ 'DeleteMessageAllTips' | translate }}
  </span>
</ng-template>

