import { Material, MedicalPlan, PagedReq } from '../api/types';
import { Expose, plainToInstance, Transform, Type } from 'class-transformer';
import { ProposalOwner } from '../proposal/proposal-types';
import { plainToArray } from '../shared/utils/class-transform';
import { QuotationFieldRequirement } from '../product/product-types';

export class TicketOrderListSearch extends PagedReq {
  @Expose() id: number;
  @Expose() status: string;
  @Expose() startReplyDate: string;
  @Expose() endReplyDate: string;
  @Expose() startCreateDate: string;
  @Expose() endCreateDate: string;
}

export class TicketOrderListResp {
  @Expose() id: number;
  @Expose() title: string;
  @Expose() status: string;
  @Expose() createTime: string;
  @Expose() lastReplyTime: string;
}

export class TicketOrderCreate {
  @Expose() content: string;
  @Transform((value) => plainToArray(Material, value.value))
  @Expose() files: Material[]; // 文件列表
  @Expose() title: string;
}

export class TicketOrderReply {
  @Expose() ticketId: number;
  @Expose() content: string;
  @Transform((value) => plainToArray(Material, value.value))
  @Expose() files: Material[]; // 文件列表
}

export class TicketOrderDetailResp {
  @Expose() title: string;
  @Expose() status: string;
  @Transform((value) => plainToInstance(InitiatorInfo, Object.assign(new InitiatorInfo(), value.value)))
  @Expose() initiatorInfo: InitiatorInfo = new InitiatorInfo();
  @Expose() lastReplyTime: string;
  @Transform((value) => plainToArray(RepliesArr, value.value))
  @Expose() replies: RepliesArr[] = [];
}

export class InitiatorInfo {
  @Expose() accountName: string;
  @Expose() accountType: string;
  @Expose() avatar: string;
  @Expose() instance: string;
}

export class RepliesArr {
  @Expose() role: string;
  @Expose() content: string;
  @Type(() => Material)
  @Expose() files: Material[]; // 文件列表
  @Expose() createTime: string;
}

