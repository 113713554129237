import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-schedule-main',
  templateUrl: './schedule-main.component.html',
  styleUrls: ['./schedule-main.component.less']
})
export class ScheduleMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
