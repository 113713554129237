<div class="progress-outer" [style.width]="width" [style.border-radius]="borderRadius">
  <div *ngIf="text" class="progress-text" style="color: black;">{{text}}</div>
  <div class="progress-inner" [style.width]="whichProgress(progress) + '%'" [style.border-radius]="borderRadius"
       [style.background-color]="degraded == null ? color : whichColor(progress)">
    <div *ngIf="showPercentage">
      <ng-container *ngIf="!disabledPercentage">
        {{whichProgress(progress)}}%
      </ng-container>
      <ng-container *ngIf="disabledPercentage">
        {{whichProgress(progress)}}
      </ng-container>
    </div>
  </div>
</div>
