import { Component, OnInit } from '@angular/core';
import {
  BookingAttachmentReq, DataDesensitization,
  DataMode,
  Forms, Instance,
  Material, MessageSubType, MessageType,
  SignatureList,
  UploadTokenReq,
  UploadType
} from '../../api/types';
import { BookingService } from '../booking.service';
import { Booking, BookingDetailResp, SignedParam } from '../booking-types';
import { WorkbenchService } from '../../workbench/workbench.service';
import { Workbench } from '../../workbench/workbench-types';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { FormSignatureComponent } from '../../shared/component/form-signature/form-signature.component';
import { Product } from '../../product/product-types';
import { ProductService } from '../../product/product.service';
import { BookingCreateComponent } from '../booking-create/booking-create.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../message/message.service';
import { MessageRemind } from '../../message/message-types';
import { MetadataService } from '../../shared/service/metadata.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AccountService } from '../../account/account.service';
import { Tool } from '../../shared/utils/tool';

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.less']
})
export class BookingDetailComponent implements OnInit {

  drawerRef: NzDrawerRef<BookingDetailComponent, string>;

  loading = false;
  formVisible: boolean;
  formItem: SignatureList;
  copyFormItem: Forms;
  formList: SignatureList[];
  formPdf: Uint8Array;
  time: Date = new Date();

  booking: Booking;

  product: Product;

  instance: Instance;

  // 预约附件上传参数
  uploadTokenReq = new UploadTokenReq();

  bookingDetail: BookingDetailResp;

  selectIndex = 0;
  messageRemind: MessageRemind = new MessageRemind();

  workbench: Workbench;
  mode = DataMode.OWN;
  dataDesensitization: DataDesensitization = new DataDesensitization();
  secondPower = {
    display: true,
    readonly: false
  };

  constructor(private router: Router,
              private bookingService: BookingService,
              private productService: ProductService,
              private workbenchService: WorkbenchService,
              private drawerService: NzDrawerService,
              private toastr: ToastrService,
              private translate: TranslateService,
              private messageService: MessageService,
              private metadataService: MetadataService,
              private accountService: AccountService,
              private modalService: NzModalService) {
  }

  ngOnInit(): void {

    this.instance = this.bookingService.mockInstance;
    this.dataDesensitization = this.accountService.dataDesensitization;

    // 加载产品信息
    if (this.booking.productCode) {
      this.productService.detail(this.booking.productCode)
        .subscribe(
          data => {
            this.product = data;
          },
          error => {
          });
    }

    this.uploadTokenReq.uploadType = UploadType.BOOKING;
    this.uploadTokenReq.middleName = this.booking.bookingNo;
    this.workbench = this.workbenchService.workbench;
    this.viewAttachments();

    if (this.bookingDetail.bookingForms && this.bookingDetail.bookingForms.length > 0) {
      this.bookingService.fromList(this.bookingDetail.bookingNo, this.bookingDetail.applicationType)
        .subscribe(
          list => {
            this.formList = list;
          }
        );
    }
    this.sendMesseage();
  }

  loadBookingDetail(bookingNo: string): void {
    this.loading = true;
    this.bookingService.detail(bookingNo)
      .subscribe(
        bookingDetail => {
          this.loading = false;
          const booking = bookingDetail.toBooking();
          this.bookingDetail = bookingDetail;
          this.booking = JSON.parse(JSON.stringify(booking));
          this.metadataService.groupFiles.emit('');
        },
        error => {
          this.loading = false;
        });
  }

  onAttachmentsChange(attachments: Material[]): void {
    const attachmentReq = new BookingAttachmentReq();
    attachmentReq.bookingNo = this.booking.bookingNo;
    attachmentReq.attachments = attachments;
    this.loading = true;
    this.bookingService.updateAttachments(attachmentReq)
      .subscribe(
        data => {
          this.loading = false;
        },
        error => {
          this.loading = false;
        });

  }

  reloadDetail(flag): void {
    if (flag) {
      setTimeout(() => { this.loadBookingDetail(this.booking.bookingNo); }, 500);
    }
  }

  firstLevelAuthority(): void {
    switch (this.mode) {
      case DataMode.SUBORDINATE:
        if (this.workbench.childAttachmentUpload === 0) {
          this.secondPower.display = false;
        }
        break;
      case DataMode.FOLLOWUP:
        if (this.workbench.followAttachmentUpload === 0) {
          this.secondPower.display = false;
        }
        break;
      case DataMode.SALESGROUP:
        if (this.workbench.teamAttachmentUpload === 0) {
          this.secondPower.display = false;
        }
        break;
    }
  }

  accessories(): void {
    if (this.workbench.bookingAttachmentUploadNew === 0) {
      // 全部禁止
      this.secondPower.display = false;
      return;
    }
    if (this.workbench.bookingAttachmentUploadNew === 2) {
      this.secondPower.display = true;
      this.secondPower.readonly = true;
      // 附件只读
      // 二级权限
      this.firstLevelAuthority();
      return;
    }
    if (this.workbench.bookingAttachmentUploadNew === 1) {
      switch (this.mode) {
        case DataMode.SUBORDINATE:
          if (this.workbench.childAttachmentUpload === 0) {
            this.secondPower.display = false;
          }
          if (this.workbench.childAttachmentUpload === 2) {
            this.secondPower.display = true;
            this.secondPower.readonly = true;
          }
          if (this.workbench.childAttachmentUpload === 1) {
            this.secondPower.display = true;
            this.secondPower.readonly = false;
          }
          break;
        case DataMode.FOLLOWUP:
          if (this.workbench.followAttachmentUpload === 0) {
            this.secondPower.display = false;
          }
          if (this.workbench.followAttachmentUpload === 2) {
            this.secondPower.display = true;
            this.secondPower.readonly = true;
          }
          if (this.workbench.followAttachmentUpload === 1) {
            this.secondPower.display = true;
            this.secondPower.readonly = false;
          }
          break;
        case DataMode.SALESGROUP:
          if (this.workbench.teamAttachmentUpload === 0) {
            this.secondPower.display = false;
          }
          if (this.workbench.teamAttachmentUpload === 2) {
            this.secondPower.display = true;
            this.secondPower.readonly = true;
          }
          if (this.workbench.teamAttachmentUpload === 1) {
            this.secondPower.display = true;
            this.secondPower.readonly = false;
          }
          break;
      }
    }
  }

  viewAttachments(): void {
    switch (this.mode) {
      case DataMode.OWN:
        if (this.workbench.bookingAttachmentUploadNew === 0) {
          this.secondPower.display = false;
        }
        if (this.workbench.bookingAttachmentUploadNew === 2) {
          this.secondPower.display = true;
          this.secondPower.readonly = true;
        }
        if (this.workbench.bookingAttachmentUploadNew === 1) {
          this.secondPower.display = true;
          this.secondPower.readonly = false;
        }
        break;
      case DataMode.SUBORDINATE:
        if (this.workbench.childAttachmentUpload === 0) {
          this.secondPower.display = false;
        }
        if (this.workbench.childAttachmentUpload === 2) {
          this.secondPower.display = true;
          this.secondPower.readonly = true;
        }
        if (this.workbench.childAttachmentUpload === 1) {
          this.secondPower.display = true;
          this.secondPower.readonly = false;
        }
        break;
      case DataMode.FOLLOWUP:
        if (this.workbench.followAttachmentUpload === 0) {
          this.secondPower.display = false;
        }
        if (this.workbench.followAttachmentUpload === 2) {
          this.secondPower.display = true;
          this.secondPower.readonly = true;
        }
        if (this.workbench.followAttachmentUpload === 1) {
          this.secondPower.display = true;
          this.secondPower.readonly = false;
        }
        break;
      case DataMode.SALESGROUP:
        if (this.workbench.teamAttachmentUpload === 0) {
          this.secondPower.display = false;
        }
        if (this.workbench.teamAttachmentUpload === 2) {
          this.secondPower.display = true;
          this.secondPower.readonly = true;
        }
        if (this.workbench.teamAttachmentUpload === 1) {
          this.secondPower.display = true;
          this.secondPower.readonly = false;
        }
        break;
    }
  }

  LoadingFormList(item: Forms): void {
    this.bookingService.fromList(this.bookingDetail.bookingNo, this.bookingDetail.applicationType).subscribe(
      list => {
        list.forEach(e => {
          if (e.bookingFormId === item.formId) {
            this.formItem = e;
          }
        });

      }
    );

  }

  onFormDetail(i: Forms): void {
    this.LoadingFormList(i);
    this.copyFormItem = JSON.parse(JSON.stringify(i));
    this.bookingService.downloadPdf(this.bookingDetail.bookingNo, i.formId).subscribe(
      byteArray => {
        this.formPdf = byteArray;
      }
    );
    this.formVisible = true;
  }

  formClose(): void {
    this.formVisible = false;
  }

  onPreviewNormalResult(): void {
    const url = window.URL.createObjectURL(new Blob([this.formPdf], {type: 'application/pdf'}));
    window.open(url);
  }

  onDownloadNormalResult(): void {
    const a = document.createElement('a');
    a.download = this.formItem.bookingFormName + '.pdf';
    a.href = window.URL.createObjectURL(new Blob([this.formPdf], {type: 'application/pdf'}));
    a.click();
  }

  onOpenSignature(val): void {
    console.log(val);
    const drawerRef = this.drawerService.create<FormSignatureComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: FormSignatureComponent,
      nzContentParams: {
        signData: val
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.signed.subscribe(
        data => {
          drawerRef.close();
          this.onSignature(val.signer, data);
        }
      );
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  resAttachmentsChange(attachments: Material[]): void {
    const params = new SignatureList();
    params.bookingNo = this.bookingDetail.bookingNo;
    params.bookingFormId = this.formItem.bookingFormId;
    params.credentials = attachments;
    this.bookingService.putUpload(params)
      .subscribe(
        data => {
          console.log(data);
        }
      );
  }

  onSignature(signer: string, base64: string): void {
    const req = new SignedParam();
    req.bookingNo = this.bookingDetail.bookingNo;
    req.bookingFormId = this.formItem.bookingFormId;
    req.signedImageBase = base64;
    req.signer = signer;
    this.bookingService.putSignature(req)
      .subscribe(
        data => {
          this.onReload();
        },
        error => {
        });
  }

  onReload(): void {
    this.onFormDetail(this.copyFormItem);
    // this.formClose();
  }

  onSignatureEnd(): void {
    if (!this.btnDisabled) {
      return;
    }
    console.log('最終提交簽名');
    const req = new SignedParam();
    req.bookingNo = this.bookingDetail.bookingNo;
    req.bookingFormId = this.formItem.bookingFormId;
    if (this.formItem.status === 'UNCOMPLETED') {
      req.signedDate = new Date().valueOf();
      this.bookingService.putSignatureEnd(req)
        .subscribe(
          data => {
            if (data) {
              this.outerRed();
              this.formClose();
            }
          },
          error => {
          }
        );
    } else {
      this.bookingService.putResetSignature(req)
        .subscribe(
          data => {
            if (data) {
              this.outerRed();
              this.formClose();
            }
          },
          error => {
          }
        );
    }
  }

  outerRed(): void {
    if (this.bookingDetail.bookingForms && this.bookingDetail.bookingForms.length > 0) {
      this.bookingService.fromList(this.bookingDetail.bookingNo, this.bookingDetail.applicationType)
        .subscribe(
          list => {
            this.formList = list;
          }
        );
    }
  }

  get isCash(): boolean {
    const instance = this.bookingService.mockInstance;
    return instance?.groupKey === 'cash' || instance?.groupKey === 'dev';
  }

  get btnDisabled(): boolean {
    return this.formItem?.signedData?.length && this.formItem?.signedData.every(item => item.signedStatus !== 'UNSIGNED');
  }

  /**
   * 提交預約
   */
  onSummit(): void {
    if (this.instance.isWanson()) {
      if (this.booking.openBankAccount && this.booking.ownerType === 'INDIVIDUAL') {
        if (!this.booking.owner.companyName) {
          this.translate.get(['PleaseEnter', 'Holder', 'CompanyName']).subscribe(
            (res: any) => {
              this.toastr.warning(res.PleaseEnter + res.Holder + res.CompanyName);
            });
          return;
        }
        if (!this.booking.owner.companyType) {
          this.translate.get(['PleaseEnter', 'Holder', 'Occupation']).subscribe(
            (res: any) => {
              this.toastr.warning(res.PleaseEnter + res.Holder + res.Occupation);
            });
          return;
        }
        if (!this.booking.owner.position) {
          this.translate.get(['PleaseEnter', 'Holder', 'Position']).subscribe(
            (res: any) => {
              this.toastr.warning(res.PleaseEnter + res.Holder + res.Position);
            });
          return;
        }
        if (!this.booking.owner.companyAddress.region && !this.booking.owner.companyAddress.city && !this.booking.owner.companyAddress.street && !this.booking.owner.companyAddress.location) {
          this.translate.get(['PleaseEnter', 'Holder', 'CompanyAddress']).subscribe(
            (res: any) => {
              this.toastr.warning(res.PleaseEnter + res.Holder + res.CompanyAddress);
            });
          return;
        }
      }
    }
    this.loading = true;
    this.bookingService.commit(this.booking)
      .subscribe(
        data => {
          this.loading = false;
          this.loadBookingDetail(this.booking.bookingNo);
        },
        error => {
          this.loading = false;
        });
  }

  showDeleteConfirm(): void {
    this.translate.get(['ReuseTips', 'Cancel', 'Confirm']).subscribe(
      (res: any) => {
        // this.toastr.warning(res.PleaseEnter + res.Cancel + res.Confirm  );
        this.modalService.confirm({
          nzCentered: true,
          nzTitle: res.ReuseTips,
          nzContent: null,
          nzOkText: res.Confirm,
          nzOkType: 'primary',
          nzOnOk: () => this.onReuse(),
          nzCancelText: res.Cancel,
          nzOnCancel: () => console.log('Cancel')
        });
      });
  }

  /**
   * 复用
   */
  onReuse(): void {
    const drawerRef = this.drawerService.create<BookingCreateComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: BookingCreateComponent,
      nzContentParams: {}
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.assignFromBooking(this.product, this.booking);
      component.bookingDraftSaved.subscribe(
        data => {
          this.drawerRef?.close();
          drawerRef.close();
        }
      );
      component.bookingApplied.subscribe(
        data => {
          this.drawerRef?.close();
          drawerRef.close();
        }
      );
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  tabsIndex(e): void {
    this.selectIndex = e.index;
    this.sendMesseage();
  }

  sendMesseage(): void {
    const params = {
      key: this.booking.bookingNo,
      type: MessageType.BOOKING,
      subType: undefined,
    };
    switch (this.selectIndex) {
      case 0:
        params.subType = MessageSubType.INFO;
        break;
      case 1:
        params.subType = MessageSubType.ATTACHMENT;
        break;
      // case 2:
      //   params.subType = MessageSubType.CREATE;
      //   break;
    }
    this.messageService.putMessageReads(params)
      .subscribe(
      () => {
        this.getMessageRemind();
      }
    );
  }

  getMessageRemind(): void {
    const param = {
      type: MessageType.BOOKING,
      key: this.booking.bookingNo
    };
    this.messageService.getMessageRemind(param)
      .subscribe(
        data => {
          this.messageRemind = data;
        },
        error => {}
      );
  }


  dynamicEncryption(keyName, val): string {
    let isShow = val;
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = Tool.dataMasking(val);
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName && item.mask) {
          isShow = Tool.dataMasking(val);
        }
      });
    }
    return isShow;
  }

  isDynamicEncryption(keyName): boolean {
    let isShow = false;
    if (!this.dataDesensitization || !this.mode) {
      return isShow;
    }
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    }
  }

}
