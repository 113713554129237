<nz-layout>
  <div>
    <app-page-header [title]="'ProposalTemplateModule' | translate"></app-page-header>
  </div>
  <nz-content>
    <div class="inner-content">
      <router-outlet></router-outlet>
    </div>
  </nz-content>
  <nz-footer></nz-footer>
</nz-layout>
