<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'FnaDetail' | translate}}
    </div>

    <div *ngIf="!fna.proposalId" class="section-wrapper" style="">
      <app-property-pick [title]="'ProposalApply' | translate"
                         nz-popover [nzPopoverContent]="productsForProposalTemplate"
                         [(nzPopoverVisible)]="productsForProposalVisible"
                         [nzPopoverTrigger]="null"
                         appThrottleClick (throttleClick)="onProposalApply()"
                         [bottomLineVisible]="false"></app-property-pick>
    </div>

    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'Client' | translate}}</span>
    </div>

    <div class="section-wrapper" style="">
      <app-property-pick [title]="'ClientName' | translate"
                         appThrottleClick (throttleClick)="onFnaClient()"
                         [value]="(instanceClass.isFoneOrDev() ? fna.customerShot.firstNamePinyin : fna.customerShot.pinyin)"
                         [bottomLineVisible]="false"></app-property-pick>
    </div>

    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'FNAForm' | translate}}</span>
    </div>

    <div class="section-wrapper" style="">
      <app-property-pick [title]="'Status' | translate"
                         appThrottleClick (throttleClick)="onFnaForm()"
                         [value]="fna.fnaStatus | metadataTranslate:'fnaStatus'"
                         [valueColor]="fna.fnaStatus === 'COMPLETED' ? '#1D64C4' : '#F6C343'"
                         [bottomLineVisible]="false"></app-property-pick>
    </div>

    <div *ngIf="fna.fnaStatus === 'COMPLETED'">
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'Product' | translate}}</span>
      </div>

      <div class="section-wrapper" style="">
        <app-property-pick [title]="'ProductName' | translate"
                           appThrottleClick (throttleClick)="onFnaProduct()"
                           [value]="fna.fnaProductName()"
                           [bottomLineVisible]="false"></app-property-pick>
      </div>

    </div>

    <div *ngIf="fna.endProduct != null && fna.endProduct.length > 0 && !isFone">
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'E-Signature' | translate}}</span>
      </div>

      <div class="section-wrapper" style="">
        <app-property-pick [title]="'Status' | translate"
                           appThrottleClick (throttleClick)="onFnaSignature()"
                           [value]="fna.signedStatus | metadataTranslate:'fnaSignedStatus'"
                           [valueColor]="fna.signedStatus === 'SIGNED' ? '#1D64C4' : '#e63757'"
                           [bottomLineVisible]="false"></app-property-pick>
      </div>
    </div>

    <div *ngIf="workbench.viewFnaPdf && fna?.endProduct && fna.endProduct.length > 0">
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'FNAFile' | translate}}</span>
      </div>

      <div class="section-wrapper" style="">
        <app-property-pick [title]="'ViewFna' | translate"
                           appThrottleClick (throttleClick)="onViewFna()"
                           [bottomLineVisible]="false"></app-property-pick>
      </div>
    </div>

    <div *ngIf="fna.proposalId">
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'AssociateProposal' | translate}}</span>
      </div>

      <div class="section-wrapper" style="">
        <app-property-pick [title]="'ProposalID' | translate"
                           [value]="fna.proposalId"
                           appThrottleClick (throttleClick)="onProposalDetail()"
                           [bottomLineVisible]="false"></app-property-pick>
      </div>
    </div>

    <div *ngIf="fna.bookingNo">
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'AssociateBooking' | translate}}</span>
      </div>

      <div class="section-wrapper" style="">
        <app-property-pick [title]="'BookingNo' | translate"
                           [value]="fna.bookingNo"
                           appThrottleClick (throttleClick)="onBookingDetail()"
                           [bottomLineVisible]="false"></app-property-pick>
      </div>
    </div>

    <div *ngIf="fna.underwritingNo">
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'AssociateUnderwriting' | translate}}</span>
      </div>

      <div class="section-wrapper" style="">
        <app-property-pick [title]="'UnderwritingNumber' | translate"
                           [value]="fna.underwritingNo"
                           appThrottleClick (throttleClick)="onUnderwritingDetail()"
                           [bottomLineVisible]="false"></app-property-pick>
      </div>
    </div>


    <div *ngIf="fna.policyId">
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'RelatedPolicy' | translate}}</span>
      </div>

      <div class="section-wrapper" style="">
        <app-property-pick [title]="'PolicyNo' | translate"
                           [value]="fna.policyNo"
                           appThrottleClick (throttleClick)="onPolicyDetail()"
                           [bottomLineVisible]="false"></app-property-pick>
      </div>
    </div>

    <div style="margin: 15px 0 0 0" *ngIf="!isFone">
      <span style="color: #999999; font-size: 11px; ">{{'FnaTip' | translate}}</span>
    </div>

  </nz-spin>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large" [disabled]="!reusable"
           appThrottleClick (throttleClick)="onReuse()">
          <span>{{'Reuse' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" [disabled]="!canBooking"
           appThrottleClick (throttleClick)="isBookingInfo = true"
           [style]="!canBooking ? 'opacity: 0.5' : ''">
          <span>{{'MakeReservationNow' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

  <nz-modal
    [(nzVisible)]="isBookingInfo"
    [nzTitle]="modalTitle1"
    [nzContent]="modalContent1"
    [nzFooter]="null"
    nzCentered
    [nzWidth]="'400px'"
    [nzClosable]="false"
    [nzMaskClosable]="false"
    (nzOnCancel)="handleClose()"
  >
    <ng-template #modalTitle1 style="text-align: center">{{'FNADataFilling' | translate}}</ng-template>
    <ng-template #modalContent1>
      <div style="display: flex; justify-content: center; padding-bottom: 20px;">
        <nz-radio-group [(ngModel)]="radioValue">
          <label nz-radio [nzValue]="'OWNER'"><span>{{'FillInToTheOwner' | translate}}</span></label>
          <label nz-radio [nzValue]="'INSURED'"><span>{{'FillInToTheInsured' | translate}}</span></label>
        </nz-radio-group>
      </div>
      <div nz-row style="width: 100%; height: 100%; justify-content: space-around;">
        <button nz-col nzSpan="10" nz-button nzType="default" appThrottleClick (throttleClick)="handleClose()">{{'Cancel' | translate}}</button>
        <button nz-col nzSpan="10"
                nz-popover [nzPopoverContent]="productsTemplate"
                [(nzPopoverVisible)]="productsForBookingVisible"
                [nzPopoverTrigger]="null"
                nz-button nzType="primary"
                appThrottleClick (throttleClick)="onBooking()"
        >{{'Confirm' | translate}}</button>
      </div>
    </ng-template>
  </nz-modal>

</div>

<!-- 立即预约选择产品Template -->
<ng-template #productsTemplate>
  <nz-list nzSize="small">
    <nz-list-item *ngFor="let product of fna.endProduct">
      <a nz-button nzType="link" nzBlock nzSize="default" appThrottleClick (throttleClick)="onProductBooking(product)">
        <!--          <em nz-icon nzType="save" nzTheme="outline"></em>-->
        <span>{{product.productName}}</span>
      </a>
    </nz-list-item>
  </nz-list>
</ng-template>

<ng-template #productsForProposalTemplate>
  <nz-list nzSize="small">
    <nz-list-item *ngFor="let product of fna.endProduct">
      <a nz-button nzType="link" nzBlock nzSize="default" appThrottleClick (throttleClick)="proposalApplyWithProduct(product)">
        <!--          <em nz-icon nzType="save" nzTheme="outline"></em>-->
        <span>{{product.productName}}</span>
      </a>
    </nz-list-item>
  </nz-list>
</ng-template>
