import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductService } from '../../../product/product.service';
import { PagedResp, ProductType, SellStatus } from '../../../api/types';
import { Observable } from 'rxjs/internal/Observable';
import { PropertySelectOption } from '../property-select/property-select.component';
import { debounceTime } from 'rxjs/operators';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Product, ProductCategory, ProductCompany, ProductListResp, ProductSearch } from '../../../product/product-types';
import { Booking } from '../../../booking/booking-types';
import { Expose } from 'class-transformer';
import { RecommendProduct } from '../../../fna/fna-types';

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.less']
})
export class ProductSelectionComponent implements OnInit {

  drawerRef: NzDrawerRef<ProductSelectionComponent, string>;

  @Input()
  type = ProductSelectionType.DEFAULT;

  @Input()
  copyProduct: Product = new Product();

  @Output()
  productSelected: EventEmitter<Product> = new EventEmitter<Product>();

  @Output()
  productInfo: EventEmitter<ProductListResp> = new EventEmitter<ProductListResp>();

  searchParamChange: EventEmitter<any> = new EventEmitter<any>();

  search = new ProductSearch();

  spinning = false;

  isFirst = false;

  loading = false;

  isBasic: boolean;

  isNewFna = false;
  recommendProduct: RecommendProduct;

  products: PagedResp<ProductListResp>;
  categories: ProductCategory[];
  companies: ProductCompany[];

  categoryOptions: PropertySelectOption[] = [];
  companyOptions: PropertySelectOption[] = [];

  constructor(private productService: ProductService) {
  }

  ngOnInit(): void {
    this.productService.categories()
      .subscribe(
        data => {
          this.categories = data;
          data.forEach(category => {
            this.categoryOptions?.push(new PropertySelectOption(category.name, category.code));
          });
        },
        error => {
        });

    this.productService.companies()
      .subscribe(
        data => {
          this.companies = data;
          data.forEach(company => {
            this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
          });

        },
        error => {
        });

    this.searchParamChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          this.onSearch(1);
        }
      );

  }

  onSearch(pageNum: number): void {
    if (this.isNewFna) {
      this.search.sellStatus = SellStatus.SELLING;
      this.search.mainProductCode = this.recommendProduct.productCode;
    } else {
      if (this.copyProduct && this.copyProduct.productName && !this.isFirst) {
        this.search.companyCode = this.copyProduct.companyCode;
        this.search.categoryCode = this.copyProduct.categoryCode;
        this.isFirst = true;
      }
      if (this.isBasic) {
        this.search.productType = ProductType.BASIC;
      }
    }
    this.search.pageNum = pageNum;
    let observable: Observable<PagedResp<ProductListResp>>;
    switch (this.type) {
      case ProductSelectionType.DEFAULT:
        observable = this.productService.page(this.search);
        break;
      case ProductSelectionType.RIDER:
        this.search.productType = ProductType.RIDER;
        observable = this.productService.page(this.search);
        break;
      case ProductSelectionType.PROPOSAL:
        this.search.productType = ProductType.BASIC;
        this.search.sellStatus = SellStatus.SELLING;
        console.log(this.search);
        observable = this.productService.pageForProposal(this.search);
        break;
      case ProductSelectionType.COMPARISON:
        this.search.comparable = true;
        observable = this.productService.page(this.search);
        break;
    }
    this.loading = true;
    observable?.subscribe(
      data => {
        this.loading = false;
        this.products = data;
      },
      error => {
        this.loading = false;
      });

  }

  onSearchParamChange(): void {
    this.searchParamChange.emit('');
  }

  onReload(): void {
    this.search.productName = undefined;
    this.onSearch(1);
  }

  onProductSelected(code: string, i: number, info: ProductListResp): void {
    this.productInfo?.emit(info);
    if (this.isNewFna) {
      this.productSelected?.emit(info);
    } else {
      this.loading = true;
      this.productService.detail(code)
        .subscribe(
          data => {
            this.loading = false;
            this.drawerRef?.close();
            console.log(data);
            this.productSelected?.emit(data);
          },
          error => {
            this.loading = false;
          }
        );
    }
  }
}

export enum ProductSelectionType {
  DEFAULT = 'DEFAULT',
  PROPOSAL = 'PROPOSAL',
  RIDER = 'RIDER',
  COMPARISON = 'COMPARISON'
}
