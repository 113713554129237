import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertySelectOption } from '../property-select/property-select.component';
import { MetadataService } from '../../service/metadata.service';
import { ContingentOwner, Customer, Instance } from '../../../api/types';
import { CustomerEntity } from '../customer-edit/customer-edit.component';
import { ContactsListComponent } from '../contacts-list/contacts-list.component';
import { plainToInstance } from 'class-transformer';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, formatDate } from '@angular/common';
import { Workbench } from '../../../workbench/workbench-types';
import { WorkbenchService } from '../../../workbench/workbench.service';

@Component({
  selector: 'app-second-holder-edit',
  templateUrl: './second-holder-edit.component.html',
  styleUrls: ['./second-holder-edit.component.less'],
  providers: [DatePipe]
})
export class SecondHolderEditComponent implements OnInit {
  title: string;
  objectType: string;
  contingentOwner: ContingentOwner = new ContingentOwner(); // 第二持有人快照
  birthdayDate: number;

  relationshipOptions: PropertySelectOption[] = [];
  genderOptions: PropertySelectOption[] = [];
  @Input()
  modeType: string;
  @Output()
  secondHolderSaved: EventEmitter<ContingentOwner> = new EventEmitter<ContingentOwner>();
  workbench: Workbench;
  instance: Instance;
  constructor(private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private translate: TranslateService,
              private workbenchService: WorkbenchService,
              private toastr: ToastrService,
              private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.instance = this.metadataService.mockInstance;
    if (this.modeType === 'booking') {
      const relationships = this.metadataService.values('bookingRelationship');
      for (const relationship of relationships) {
        this.relationshipOptions.push(new PropertySelectOption(relationship.value, relationship.key));
      }
    } else {
      const relationships = this.metadataService.values('relationship');
      for (const relationship of relationships) {
        this.relationshipOptions.push(new PropertySelectOption(relationship.value, relationship.key));
      }
    }

    const genders = this.metadataService.values('gender');
    for (const gender of genders) {
      this.genderOptions.push(new PropertySelectOption(gender.value, gender.key));
    }

    this.contingentOwner.birthdate ? this.contingentOwner.birthday = new Date(this.contingentOwner.birthdate).valueOf() : this.contingentOwner.birthday = null;
  }

  // 根据日期获取年龄
  getAge(date): any {
    if (!date) {
      return ;
    }
    if (date > new Date().getTime()) {
      this.translate.get('minAgeTip').subscribe(
        (res) => {
          this.toastr.warning(res);
        }
      );
      this.birthdayDate = 0;
      return ;
    }
    // 出生时间 毫秒
    const birthDayTime = new Date( parseInt(date, 0)).getTime();
    // 当前时间 毫秒
    const nowTime = new Date().getTime();
    // 一年毫秒数(365 * 86400000 = 31536000000)
    this.birthdayDate = Math.floor((nowTime - birthDayTime) / 31536000000);
    this.contingentOwner.birthdate = formatDate(date, 'yyyy-MM-dd', 'en-US');

    return ;
  }

  onSave(): void {
    console.log('onSave');
    this.secondHolderSaved.emit(this.contingentOwner);
  }

  onContacts(): void {
    const drawerRef = this.drawerService.create<ContactsListComponent, { value: any }, string>(
      {
        nzWidth: 600,
        nzMaskClosable: true,
        nzContent: ContactsListComponent,
        nzContentParams: {}
      },
    );
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.detailSelected.subscribe(data => {
        this.contingentOwner = plainToInstance(ContingentOwner, data, {excludeExtraneousValues: true});
        drawerRef.close();
        console.log(this.contingentOwner);
      });
    });
  }

  getIdCardChange(event): void {
    if (event && event.length === 18) {
      console.log(event);
      const idCardNumber = event; // 替换为实际的身份证号
      const birthYear = idCardNumber.substring(6, 10);
      const birthMonth = idCardNumber.substring(10, 12);
      const birthDay = idCardNumber.substring(12, 14);
      const birthDate = `${birthYear}-${birthMonth}-${birthDay}`;
      console.log(birthDate); // 打印出生日期
      // this.customer.birthdate = birthDate;
      this.contingentOwner.birthday = new Date(birthDate).valueOf();
    }
  }

}
