<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'NewTemplate' | translate}}
    </div>
    <app-property-input [title]="'ClientName' | translate"
                        [required]="true"
                        [(value)]="createParam.name"
                        [displayMode]="'vertical'"
                        [placeholder]="('PleaseEnter'| translate) + ('ClientName' | translate)">
    </app-property-input>
    <div style="width: 100%;padding: 10px 0;">
      <!--公司及產品名稱加星號-->
      <app-property-switch  [title]="'MaskInsurerAsterisk' | translate"
                            [(value)]="createParam.asterisk"></app-property-switch>
    </div>
    <div class="section-title" style="margin-top: 20px;display: flex">
      <span style="margin-left: 8px">{{'Proposal' | translate}}</span>
      <a style="margin-left: auto" nz-button [nzSize]="'small'" appThrottleClick (throttleClick)="onSelect();"
         nzType="link">{{'SelectProposal'| translate}}</a>
    </div>
    <div>
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzScroll]="{ x: '950px'}"
                [nzData]="listArr">
        <thead>
        <tr>
          <th nzLeft nzWidth="160px">{{'ProposalID' | translate}}</th>
          <th>{{'ProposalName' | translate}}</th>
          <th nzWidth="100px">{{'PaymentTerm' | translate}}</th>
          <th nzWidth="90px">{{'Gender' | translate}}</th>
          <th nzWidth="60px">{{'Age' | translate}}</th>
          <th nzWidth="100px">{{'Smoker' | translate}}</th>
          <th nzWidth="100px">{{'Withdrawal' | translate}}</th>
          <th nzWidth="140px">{{'Premium' | translate}}(USD)</th>
          <th nzRight>{{'Functions' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of listArr;let i = index">
          <td nzLeft>{{data.proposalId}}</td>
          <td><img width="20" style="border-radius: 3px; margin-right: 5px" [src]="data.companyLogo"
          >{{data.productName}}</td>
          <td>{{data.paymentTerm}}</td>
          <td>{{data.gender | metadataTranslate:'gender'}}</td>
          <td>{{data.age}}</td>
          <td>{{data.smoke | metadataTranslate:'smoke'}}</td>
          <td>
            {{data.isWithdrawal ? ('Yes' | translate) : ('No' | translate)}}</td>
          <td>{{data.premium | number }}</td>
          <td nzRight>
            <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
               appThrottleClick
               (throttleClick)="onDelete(data.proposalId,i); $event.stopPropagation();">
              <img alt="" width="20" src="assets/images/ic_delete.svg">
            </a>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
  <div class="footer" style="z-index:10">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [disabled]="!canCreate"
           [style]="!canCreate ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
