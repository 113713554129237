import {Expose, plainToInstance, Transform, Type} from 'class-transformer';
import { Material, MedicalPlan, PagedReq } from '../api/types';

export class SaleReportReq {
  @Expose() policyNo: string;
  @Expose() type: string; // self（默认） | 与我分佣的：share
  @Expose() ownerName: string; // 不知到有没有
}

export class NewSaleReportReq extends PagedReq {
  @Expose() policyNo: string; // 保单号
  @Expose() underwritingNo: string; // 新单号
  @Expose() companyCode: string; // 公司码
  @Expose() productCode: string; // 产品码
  @Expose() policyDate: string; // 保单日期
  @Expose() productName: string;
  @Expose() module: string; // UNDERWRITING:新单; POLICY:保单
  @Expose() type: string; // self（默认） | 与我分佣的：share
  @Expose() orderColumn: string; // 排序字段
  @Expose() orderAspect: string; // 排序类型 DESC/ASC
}

export class SaleListReq {
  @Expose() policyId: string;
  @Expose() underwritingNo: string;
  @Expose() productType: string;
}


export class SaleReportList {
  @Expose() companyCode: string;
  @Expose() companyName: string;
  @Expose() expand: boolean;
  @Type(() => SaleReportListItem)
  @Expose() list: SaleReportListItem[];
}

export class SaleReportListItem {
  @Expose() ownerName: string;
  @Expose() ownerNameEn: string;
  @Expose() policyDate: string;
  @Expose() policyId: string;
  @Expose() policyNo: string;
  @Expose() productName: string;
  @Expose() underwritingNo: string;
  @Expose() companyName: string;
  @Expose() productYear: string;
  @Expose() totalCommission: string;
}


export class SaleList {
  @Expose() policyId: string; // 保单ID
  @Expose() policyNo: string; // 保单ID
  @Expose() policyCommissionId: number; // 销售佣金ID
  @Expose() productName: string; // 产品名称
  @Expose() companyName: string; // 公司名称
  @Expose() yearPremium: number; // 年保费
  @Expose() commission: number; // 应收佣金
  @Expose() premiumDate: string; // 保费日期
  @Expose() commissionMode: string;
  @Expose() aibvIncrement: number;
  @Expose() productFactor: number;
  @Expose() bvFactor: number;
  @Transform((value) => plainToInstance(SaleReportDetail, Object.assign(new SaleReportDetail(), value.value)))
  @Expose() appPolicyCommissionDetailVO: SaleReportDetail = new SaleReportDetail();
}

export class SaleReportDetail {
  @Expose() accountName: string; // 销售渠道
  @Expose() nickName: string; // 销售渠道昵称
  @Expose() policyId: string; // 保单ID
  @Expose() policyNo: string; // 保单号
  @Expose() ownerName: string; // 投保人姓名
  @Expose() ownerNameEn: string; // 投保人姓名英文
  @Expose() insuredName: string; // 受保人姓名
  @Expose() insuredNameEn: string; // 受保人姓名英文
  @Expose() companyLogo: string; // 公司logo
  @Expose() icon: string;
  @Expose() productYear: string; // 供款年期
  @Expose() currency: string; // 货币
  @Expose() premium: number; // 保费
  @Expose() productName: string; // 产品名称
  @Expose() commission: number; // 应收佣金
  @Expose() premiumDate: string; // 保费日期
}

