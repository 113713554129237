import { Component, OnInit } from '@angular/core';
import {PDFDownload, PDFListRes} from '../sales-pact-types';
import {SalesPactService} from '../sales-pact.service';
import {logger} from 'codelyzer/util/logger';

@Component({
  selector: 'app-pdf-list',
  templateUrl: './pdf-list.component.html',
  styleUrls: ['./pdf-list.component.less']
})
export class PdfListComponent implements OnInit {
  pdfList: PDFListRes[] = [];
  id: number; // 合同id
  loading = false;
  pdfData: PDFDownload = new PDFDownload();
  constructor(private salesPactService: SalesPactService) { }

  ngOnInit(): void {
  }

  onViewFna(info): void {
    this.pdfData.id = this.id.toString();
    this.pdfData.sign = info.sign;
    this.loading = true;
    this.salesPactService.viewFna(this.pdfData)
      .subscribe(
        data => {
          const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
          window.open(url);
          this.loading = false;
        }, error => {
          this.loading = false;
        });
  }

  onDownloadPDF(info): void {
    this.pdfData.id = this.id.toString();
    this.pdfData.sign = info.sign;
    this.loading = true;
    this.salesPactService.downloadPDF(this.pdfData)
      .subscribe(
        data => {
          const link = document.createElement('a');
          const blob = new Blob([data.body], {type: 'application/pdf'});
          let fileName = data.headers.get('Content-Disposition')?.split(';')[1]?.split('filename=')[1];
          // const fileNameUnicode = data.headers.get('Content-Disposition').split('filename*=')[1];
          // 当存在 filename* 时，取filename* 并进行解码（为了解决中文乱码问题）
          if (fileName) {
            fileName = decodeURIComponent(fileName.replace( /\+/g, '%20' ));
          }

          link.setAttribute('href', window.URL.createObjectURL(blob));
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.loading = false;
        }, error => {
          this.loading = false;
        });
  }
}
