import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataMode, Instance, MessageSubType, MessageType, Underwriting } from '../../../api/types';
import { UnderwritingService } from '../../underwriting.service';
import { UnderwritingPending, UnderwritingPendingSearch, UnderwritingPendingStatus } from '../../underwriting-types';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { UnderwritingPendingDetailComponent } from '../underwriting-pending-detail/underwriting-pending-detail.component';
import { BaseComponent } from '../../../base/base.component';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { MessageService } from '../../../message/message.service';

@Component({
  selector: 'app-underwriting-pending',
  templateUrl: './underwriting-pending.component.html',
  styleUrls: ['./underwriting-pending.component.less']
})
export class UnderwritingPendingComponent extends BaseComponent implements OnInit {

  loading = false;

  @Input()
  mode = DataMode.OWN;
  @Input()
  underwriting: Underwriting;

  search = new UnderwritingPendingSearch();

  pendings: UnderwritingPending[];

  statusList: UnderwritingPendingStatus[];

  statusOptions: PropertySelectOption[] = [];

  filterPopoverVisible = false;
  @Output()
  putSendMessage: EventEmitter<any> = new EventEmitter<any>();
  instance: Instance = new Instance();

  constructor(private drawerService: NzDrawerService,
              private underwritingService: UnderwritingService,
              private messageService: MessageService
              ) {
    super();
  }

  ngOnInit(): void {
    this.search.underwritingNo = this.underwriting.underwritingNo;
    this.instance = this.underwritingService.mockInstance;
    this.loadPendingStatusList();
    this.loadPendingList();
  }

  loadPendingStatusList(): void {
    this.underwritingService.pendingStatusList()
      .subscribe(
        list => {
          this.statusList = list;
          for (const status of this.statusList) {
            this.statusOptions.push(new PropertySelectOption(status.status, status.status));
          }
        },
        error => {
        }
      )
      .disposedBy(this.disposeBag);
  }

  loadPendingList(): void {
    this.loading = true;
    this.underwritingService.pendingList(this.search)
      .subscribe(
        list => {
          this.loading = false;
          this.pendings = list;
        },
        error => {
          this.loading = false;
        }
      )
      .disposedBy(this.disposeBag);
  }

  onPendingDetail(pending: UnderwritingPending): void {
    this.sendMessage(pending.id);
    this.loading = true;
    this.underwritingService.pendingDetailV2(pending.id)
      .subscribe(pendingDetail => {
          this.loading = false;
          const drawerRef = this.drawerService.create<UnderwritingPendingDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: UnderwritingPendingDetailComponent,
            nzContentParams: {
              pendingDetail,
              mode: this.mode,
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.pendingChanged.subscribe(data => {
              this.loadPendingList();
            });
          });

          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();
            this.putSendMessage.emit();
          });
        },
        error => {
          this.loading = false;
        });
  }

  onResetSearch(): void {
    this.search = new UnderwritingPendingSearch();
    this.search.underwritingNo = this.underwriting.underwritingNo;
    this.filterPopoverVisible = false;
    this.loadPendingList();
  }

  onSearch(): void {
    this.filterPopoverVisible = false;
    this.loadPendingList();
  }

  sendMessage(num): void{
    const params = {
      key: this.underwriting.underwritingNo,
      subKey: num,
      type: MessageType.UNDERWRITING,
      subType: MessageSubType.PENDING_REPLY,
    };
    this.messageService.putMessageReads(params).subscribe();
  }

}
