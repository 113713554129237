import { Component, OnInit } from '@angular/core';
import { LocaleService } from './core/locale.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { InstancesService } from './shared/service/instances.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {

  titles = {
    dev: 'LifeBee Users Dev',
    lifebee: 'LifeBee Users Uat',
    baylight: '湾区之光',
  };

  constructor(private localService: LocaleService,
              private router: Router,
              private titleService: InstancesService,
              ) {
  }

  ngOnInit(): void {
    this.setUpAnalytics();
    this.setUpInstancesCustomization();
  }

  setUpAnalytics(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
      });
  }

  // 客户定制化标题图标等
  setUpInstancesCustomization(): void {
    this.titleService.setUp();
  }

}
