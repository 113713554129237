<div class="link">
  <div class="link-file-elem">
    <div class="link-file-name">
      <span>{{link.description}}</span>
    </div>
    <div class="link-file-path">
      <span style="cursor: pointer" appThrottleClick (throttleClick)="openLink(link)">{{link.link}}</span>
    </div>
  </div>
  <div
    appThrottleClick
    (throttleClick)="openLink(link)"
    class="link-file-icon" style="cursor: pointer"
  >
    <span>
      <i nz-icon nzTheme="outline" nzType="link"></i>
    </span>
  </div>
</div>
