<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'EditProduct' | translate}}
    </div>

    <div class="section-wrapper">
      <app-property-text [title]="'Insurer' | translate"
                         [value]="product.companyName"></app-property-text>

      <app-property-text [title]="'InsuranceProduct' | translate"
                         [value]="product.productName"></app-property-text>

      <app-property-text [title]="'GuaranteePeriod' | translate"
                         [bottomLineVisible]="false"
                         [value]="product.policyPeriod"></app-property-text>
    </div>

    <div class="section-wrapper" *ngIf="primaryProduct">
      <app-product-riders-edit [mode]="riderMode"
                               [product]="product"
                               [currency]="currency"
                               [isNewFna]="isNewFna"
                               [primaryProduct]="primaryProduct"
                               [(riders)]="riders"></app-product-riders-edit>
    </div>

  </nz-spin>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="check" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
