import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FnaModuleAndAnswer, FnaQuestion } from '../../fna-types';

@Component({
  selector: 'app-fna-question',
  templateUrl: './fna-question.component.html',
  styleUrls: ['./fna-question.component.less']
})
export class FnaQuestionComponent implements OnInit {

  @Input()
  fnaQuestion: FnaQuestion;
  @Input() disabled = false;

  @Output()
  subQuestionEvent: EventEmitter<FnaModuleAndAnswer> = new EventEmitter<FnaModuleAndAnswer>();

  @Output()
  fnaQuestionChange: EventEmitter<FnaQuestion> = new EventEmitter<FnaQuestion>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onAnswerRadio(fnaQuestion: FnaQuestion, answerIndex: number): void {
    for (let i = 0; i < fnaQuestion.answers.length; i++) {
      fnaQuestion.answers[i].tick = answerIndex === i;
      const answer = fnaQuestion.answers[i];
      if (answer.subQuestions && answer.subQuestions.length > 0) {
        this.subQuestionEvent.emit(answer);
      }
    }
    this.fnaQuestionChange.emit(this.fnaQuestion);
  }

  onAnswerCheckbox(fnaQuestion: FnaQuestion, answerIndex: number, checked: boolean): void {
    fnaQuestion.answers[answerIndex].tick = checked;
    const answer = fnaQuestion.answers[answerIndex];
    if (answer.subQuestions && answer.subQuestions.length > 0) {
      this.subQuestionEvent.emit(answer);
    }
    this.fnaQuestionChange.emit(this.fnaQuestion);
  }

  onQuestionChange(answer: FnaModuleAndAnswer, answerIndex: number): void {
    answer.tick = true;
    this.fnaQuestionChange.emit(this.fnaQuestion);
  }
}
