import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.less']
})
export class EmptyComponent implements OnInit {

  @Input()
  width = '250px';

  @Input()
  text = '';

  constructor() { }

  ngOnInit(): void {
  }

}
