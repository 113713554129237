import {Component, EventEmitter, OnInit, Output, TemplateRef} from '@angular/core';
import {ScheduleData, ScheduleItem} from '../schedule-type';
import {TranslateService} from '@ngx-translate/core';
import {FnaListRes} from '../../fna/fna-types';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ScheduleService} from '../schedule.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {ScheduleCreatedComponent} from '../schedule-created/schedule-created.component';
import {ScheduleEditComponent} from '../schedule-edit/schedule-edit.component';
import {plainToInstance} from 'class-transformer';

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.less']
})
export class ScheduleListComponent implements OnInit {
  @Output()
  scheduleDelete: EventEmitter<any> = new EventEmitter<any>();
  scheduleUpdated: EventEmitter<any> = new EventEmitter<any>();
  scheduleData: ScheduleItem[];
  time: any;
  ids: string;
  title: string;
  newData: number;

  constructor(private translate: TranslateService,
              private schedule: ScheduleService,
              private drawerService: NzDrawerService,
              private modalService: NzModalService) {
  }

  ngOnInit(): void {
    this.newData = new Date().getTime();
    console.log(this.scheduleData);
    this.translate.get(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', `Sunday`]).subscribe(
      res => {
        const year = new Date(Number(this.time)).getFullYear();
        const month = new Date(Number(this.time)).getMonth() + 1;
        const i = year + '-' + month + '-' + new Date(Number(this.time)).getDate();
        switch (new Date(Number(this.time)).getDay()) {
          case 1:
            this.title = i + ' ' + res.Monday;
            break;
          case 2:
            this.title = i + ' ' + res.Tuesday;
            break;
          case 3:
            this.title = i + ' ' + res.Wednesday;
            break;
          case 4:
            this.title = i + ' ' + res.Thursday;
            break;
          case 5:
            this.title = i + ' ' + res.Friday;
            break;
          case 6:
            this.title = i + ' ' + res.Saturday;
            break;
          case 0:
            this.title = i + ' ' + res.Sunday;
            break;
        }
      }
    );
  }

  onDetail(data: ScheduleItem): void {
    this.translate.get('IsConfirmSchedule').subscribe(title => {
      this.modalService.confirm({
        nzCentered: true,
        nzTitle: title,

        nzComponentParams: data,
        nzOnOk: () => {
          data.status = 'COMPLETED';
          this.schedule.update(data).subscribe(e => {
            console.log(this.scheduleData);
            this.scheduleUpdated.emit();
          }, error => {

          });
        },
        nzOnCancel: () => console.log('Cancel')
      });
    });
  }


  onDelete(data: ScheduleItem, i: number): void {
    this.translate.get('IsDeleteSchedule').subscribe(title => {
      this.modalService.confirm({
        nzCentered: true,
        nzTitle: title,
        nzComponentParams: data,
        nzOnOk: () => {
          this.schedule.delete(data.id).subscribe(e => {
            this.scheduleData.splice(i, 1);
            console.log(this.scheduleData);
            this.scheduleDelete.emit();
          }, error => {

          });
        },
        nzOnCancel: () => console.log('Cancel')

      });
    });

  }

  onChangeDetail(scheduleParams: ScheduleItem, i: number): void {
    scheduleParams =  plainToInstance(ScheduleItem, scheduleParams, {exposeDefaultValues: true});
    console.log(scheduleParams);
    const drawerRef = this.drawerService.create<ScheduleEditComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: ScheduleEditComponent,
      nzContentParams: {
        scheduleParams
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.scheduleCompareSave.subscribe(data => {
        this.schedule.info(String(scheduleParams.id)).subscribe(
          res => {
            console.log(res);
            this.scheduleData[i] = res[0];
          }
        );
        drawerRef.close();
      });
    });

    drawerRef.afterClose.subscribe(data => {
      const component = drawerRef.getContentComponent();
    });
  }
}
