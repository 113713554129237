import {Expose, plainToInstance, Transform, Type} from 'class-transformer';
import { I18n, MedicalPlan, PagedReq } from '../api/types';


export class ProposalCompareSearch extends PagedReq {
  @Expose() accountName: string;
  @Expose() categoryCode: string;
  @Expose() status: string;
  @Expose() name: string;
}

export class ProposalList {
  @Expose() gender: string; // 性别
  @Expose() companyLogo: string; // LOGO
  @Expose() smoke: string; // 是否吸烟
  @Expose() categoryCode: string; // 产品分类
  @Expose() proposalId: string; // 计划书编号
  @Expose() productName: string; // 产品名称
  @Expose() sumAssured: string; // 保额
  @Expose() productCode: string; // 产品码
  @Expose() premium: number; // 保费
  @Expose() applicationTime: number; // 申请时间
  @Expose() isWithdrawal: boolean; // 是否有提取
  @Expose() extracted: boolean; // 是否异常 false异常
  @Expose() deductible: number; // 医疗险特有条件:自付额
  @Expose() currency: string; // 货币: HKD-港币;USD-美元;EUR-欧元;JPY-日元;CAD-加拿大币; GBP-英镑; AUD-澳大利亚币; CNY-人民币
  @Expose() id: number; // 主键
  @Expose() paymentTerm: string; // 年期
  @Expose() age: number; // 年龄
}

export class ProposalCompare {
  @Expose() proposalList: ProposalList[];
  @Expose() addTime: number;
  @Expose() urlHk: string;
  @Expose() name: string;
  @Expose() proposalIds: string[];
  @Expose() id: number;
  @Expose() categoryCode: string;
  @Expose() urlUs: string;
  @Expose() urlCn: string;
  @Expose() status: string;
  @Expose() expand: boolean;
}

export class CreateProposalCompare {
  @Expose() name: string; // 客户姓名
  @Expose() groups: string[]; // 隐藏的对比分组
  @Expose() proposalIds: string[]; // 计划书编号列表
  @Expose() attributes: string[]; // 隐藏的对比项
  @Expose() id: number; // 编号
  @Expose() cover: string; // 封面地址
  @Expose() categoryCode: string; // 保险种类
  @Expose() asterisk: boolean; // 加星
  @Expose() valueDemonstrationYear: ValueDemonstration = new ValueDemonstration();
}

export class ValueDemonstration {
  @Expose() from: number;
  @Expose() to: number;
}

export class SelectProposalList extends PagedReq {
  @Expose() categoryCode: string; // 保險種類
  @Expose() productCodes: string; // 产品码列表
  @Expose() accountName: string; // 账户名
  @Expose() ProductName: string; // 产品名
}

export class FileUrl {
  @Expose() fileName: string; // 文件名
  @Expose() author: string; // 文件名
  @Expose() filePath: string; // 文件名
  @Expose() uploadTime: string; // 文件名
}

export class Attribute {
  @Expose() score: string; // 分数
  @Expose() name: string; // 对比项名称
  @Expose() checked: boolean; // 是否选中
  @Expose() attribute: string; // 对比项key
  @Expose() value: string; // 对比项值
}

export class Groups {
  @Expose() name: string; // 对比分组名称
  @Expose() checked: boolean; // 是否选中
  @Expose() attributes: Attribute[]; // 对比项
  @Expose() key: string; // 对比分组key
}

export class Details {
  @Expose() companyName: string; // 保险公司名
  @Expose() logo: string; // logo
  @Expose() groups: Groups[]; // 对比分组
  @Expose() proposalId: string; // 对比分组
  @Expose() productName: string; // 对比分组

}

export class ProposalDetail {
  @Expose() urlHk: FileUrl; // 繁体PDF
  @Expose() addTime: number; // 创建时间
  @Expose() name: string; // 客户姓名
  @Expose() details: Details[]; // 对比详情
  @Expose() urlUs: FileUrl; // 英文PDF
  @Expose() urlCn: FileUrl; // 简体PDF
  @Expose() status: string; // 状态
  @Expose() id: number; // 编号
  @Expose() categoryCode: string; // 保险种类
  @Expose() asterisk: boolean; // 加星
}

export class AttributeSearch {
  @Expose() score: string;
  @Expose() id: number;
  @Expose() attribute: string;
  @Expose() proposalId: string;
}

export class CoverClass {
  @Expose() name: string; // 名称
  @Expose() id: number; // 编号
  @Expose() nameI18n: I18n; // 名称国际化
  @Expose() categoryCode: string; // 分类
  @Expose() url: string; // 封面链接
}

export class CoverQuery extends PagedReq{
  @Expose() categoryCode: string; // 保险种类
  @Expose() status: string; // 状态
}
