import { Component, Input, OnInit } from '@angular/core';
import { SaleReportDetail } from '../sale-report-type';

@Component({
  selector: 'app-sale-report-detail',
  templateUrl: './sale-report-detail.component.html',
  styleUrls: ['./sale-report-detail.component.less']
})
export class SaleReportDetailComponent implements OnInit {
  @Input()
  detailInfo: SaleReportDetail;
  constructor() { }

  ngOnInit(): void {
  }

  toDecimal(val): any {
    if (val && val.indexOf('.') === -1) {
      val = val + '.00';
      return val;
    } else {
      return val;
    }
  }

}
