import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Country, CountryGroups } from '../../../api/types';
import { BaseComponent } from '../../../base/base.component';
import * as _ from 'lodash';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

export class GroupCountries {
  group: string;
  list: Country[];
}

@Component({
  selector: 'app-region-selection',
  templateUrl: './region-selection.component.html',
  styleUrls: ['./region-selection.component.less']
})
export class RegionSelectionComponent extends BaseComponent implements OnInit {

  drawerRef: NzDrawerRef<RegionSelectionComponent, string>;

  loading = false;

  name: string;

  countryGroups: CountryGroups;

  setOfGroup = new Set<string>();

  groupCountries: GroupCountries[];
  countries: Country[];

  @Output()
  regionSelected: EventEmitter<Country> = new EventEmitter<Country>();

  constructor(private commonService: CommonService) {
    super();
  }

  ngOnInit(): void {
    this.loadCountries();
  }

  loadCountries(): void {
    this.loading = true;
    this.commonService.countryGroups()
      .subscribe(
        data => {
          this.loading = false;
          this.countryGroups = data;
          if (this.countryGroups && this.countryGroups.groupCountries) {
            this.setOfGroup.clear();
            for (const groupCountry of this.countryGroups.groupCountries) {
              this.setOfGroup.add(groupCountry.group);
            }
            this.groupCountries = _.chain(this.countryGroups.groupCountries)
              // Group the elements of Array based on `color` property
              .groupBy('group')
              // `key` is group's name, `value` is the array of objects
              .map((value, key) => ({ group: key, list: value }))
              .value();
          }
        },
        error => {
          this.loading = false;
        })
      .disposedBy(this.disposeBag);
  }

  onRegionClick(region: Country): void {
    this.regionSelected.emit(region);
    this.drawerRef?.close();
  }

  onSearch(name: string): void {
    this.loading = true;
    this.commonService.countryAll(name)
      .subscribe(
        data => {
          this.loading = false;
          this.countries = data;
        },
        error => {
          this.loading = false;
        })
      .disposedBy(this.disposeBag);
  }

}
