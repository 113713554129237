import { Component, OnInit, TemplateRef } from '@angular/core';
import { ProposalCustomService } from '../proposal-custom.service';
import { ListQuery, ListRes } from '../proposal-custom-types';
import { Material, PagedResp } from '../../api/types';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { StorageService } from '../../shared/service/storage.service';
import { DownloadService } from '../../shared/service/download.service';
import { ProposalSearch } from '../../proposal/proposal-types';
import { ProposalCustomCreateComponent } from '../proposal-custom-create/proposal-custom-create.component';


interface ParentItemData {
  key: number;
  name: string;
  platform: string;
  version: string;
  upgradeNum: number | string;
  creator: string;
  createdAt: string;
  expand: boolean;
}

interface ChildrenItemData {
  key: number;
  name: string;
  date: string;
  upgradeNum: string;
}

@Component({
  selector: 'app-proposal-customs',
  templateUrl: './proposal-customs.component.html',
  styleUrls: ['./proposal-customs.component.less']
})
export class ProposalCustomsComponent implements OnInit {

  search: ListQuery = new ListQuery();
  listData: PagedResp<ListRes> = new PagedResp<ListRes>();
  spinning = false;
  loading = false;


  listOfParentData: ParentItemData[] = [];
  listOfChildrenData: ChildrenItemData[] = [];

  constructor(private proposalCustomService: ProposalCustomService,
              private modalService: NzModalService,
              private toastr: ToastrService,
              private translate: TranslateService,
              private storageService: StorageService,
              private downloadService: DownloadService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
    this.getList();
  }

  getList(): void {
    this.loading = true;
    this.proposalCustomService.list(this.search)
      .subscribe(data => {
        data.list.forEach((item, i) => {
          item.key = i;
          item.expand = false;
        });
        this.loading = false;
        this.listData = data;
      }, ( ) => {
        this.loading = false;
      });
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.proposalCustomService.list(this.search)
      .subscribe(
        data => {
          data.list.forEach((item, i) => {
            item.key = i;
            item.expand = false;
          });
          this.loading = false;
          this.listData = data;
        },
        error => {
          this.loading = false;
        });
  }

  onReload(): void {
    this.search = new ListQuery();
    this.getList();
  }

  onShowExpand(val: ListRes): boolean {
    return val.expand = !val.expand;
  }

  onDelete(id, tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>): void {
    this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzClosable: false,
      nzOnOk: () => {
        this.loading = true;
        this.proposalCustomService.deleteCustom(id)
          .subscribe(
            data => {
              if (data) {
                this.translate.get('DeleteSuccess').subscribe(
                  (res) => {
                    this.toastr.success(res);
                  }
                );
              }
              this.onSearch(this.search.pageNum);
              this.loading = false;
            },
            error => {
              this.loading = false;
            });
      }
    });
  }

  onRetry(id): void {
    this.loading = true;
    this.proposalCustomService.retryCustom(id)
      .subscribe(
        data => {
          // if (data) {
          //   this.translate.get('DeleteSuccess').subscribe(
          //     (res) => {
          //       this.toastr.success(res);
          //     }
          //   );
          // }
          this.onSearch(this.search.pageNum);
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  onPreview(attachment: Material): void {
    this.loading = true;
    this.storageService.accessToken(attachment.filePath)
      .subscribe(
        data => {
          this.onPDFPreview(data.accessToken);
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  onPDFPreview(pdfUrl: string): void {
    const drawerRef = this.drawerService.create<PdfViewerComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: PdfViewerComponent,
      nzContentParams: {
        src: pdfUrl,
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const pdfViewerComponent = drawerRef.getContentComponent();
      pdfViewerComponent.renderText = true;
      pdfViewerComponent.autoresize = true;
      pdfViewerComponent.showAll = true;
      pdfViewerComponent.originalSize = false;
      pdfViewerComponent.fitToPage = true;
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });

  }

  onDownload(attachment: Material): void {
    this.storageService.accessToken(attachment.filePath)
      .subscribe(
        data => {
          this.downloadService.download(data.accessToken, attachment.fileName);
        },
        err => {

        });

  }

  onCreate(): void {
    const drawerRef = this.drawerService.create<ProposalCustomCreateComponent, { value: string }, string>({
      nzWidth: 1000,
      nzContent: ProposalCustomCreateComponent,
      nzContentParams: {}
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.proposalCustomSave.subscribe(data => {
        this.onReload();
        drawerRef.close();
      });
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

}
