<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 40px">
    {{'BookingDetail' | translate}}
  </div>

  <div style="display: flex; flex-direction: row; align-items: end">
    <div style="flex: 1; font-weight: bold; font-size: 18px; color: #404040">#{{bookingDetail.bookingNo}}</div>
    <div>
      <div [ngSwitch]="bookingDetail.status">
        <div>
          <span *ngSwitchCase="'DRAFT'" class="text-warning">●</span>
          <span *ngSwitchCase="'APPLIED'" class="text-processing">●</span>
          <span *ngSwitchCase="'CONFIRMED'" class="text-processing">●</span>
          <span *ngSwitchCase="'COMPLETED'" class="text-success">●</span>
          <span *ngSwitchCase="'CANCELLED'" class="text-cancel">●</span>
          <span> {{ bookingDetail.status | metadataTranslate: 'bookingStatus' }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="booking-detail-header">
    <div>
      <img width="60" style="border-radius: 3px; margin-right: 5px" [src]="bookingDetail.companyLogo" alt=""/>
    </div>
    <div style="flex: 1">
      <!-- 客戶名稱 -->
      <app-property-text [title]="'ClientName' | translate"
                         [isEncryption]="isDynamicEncryption('name')"
                         [value]="bookingDetail.name + ' ' + bookingDetail.pinyin"></app-property-text>
      <!-- 产品名称 -->
      <app-property-text [title]="'ProductName' | translate"
                         [isEncryption]="isDynamicEncryption('productName')"
                         [value]="bookingDetail.productName"></app-property-text>
      <!-- 供款年期 -->
      <app-property-text [title]="'PaymentTerm' | translate"
                         *ngIf="!bookingDetail.custom"
                         [value]="bookingDetail.productPlan?.productYear"></app-property-text>
      <!-- 资管产品供款年期 -->
      <app-property-text *ngIf="bookingDetail.custom" [title]="'CustomPaymentTerm' | translate"
                         [value]="bookingDetail.productPlan?.customPaymentTerm | yearConversion"></app-property-text>
      <!-- 銷售渠道 -->
      <app-property-text [title]="'Referee' | translate"
                         [isEncryption]="isDynamicEncryption('accountName')"
                         [value]="bookingDetail.accountName"></app-property-text>
      <!-- 保费 -->
      <app-property-text [title]="'Premium' | translate"
                         [isEncryption]="isDynamicEncryption('premium')"
                         [value]="(bookingDetail?.productPlan?.premium | number: '.2') + (bookingDetail.productPlan.currency | metadataTranslate: 'currency')"></app-property-text>
    </div>
  </div>

  <nz-tabset [nzSize]="'large'" [nzCentered]="false"
             (nzSelectChange)="tabsIndex($event)">
    <nz-tab [nzTitle]=bookingOverview>
      <app-booking-overview [(bookingDetail)]="bookingDetail" [booking]="booking" [mode]="mode"></app-booking-overview>
    </nz-tab>
    <ng-template #bookingOverview>
      <nz-badge nzDot [nzShowDot]="messageRemind.info > 0">
        <span>
          {{'BookingData' | translate}}
        </span>
      </nz-badge>
    </ng-template>
    <nz-tab *ngIf="secondPower.display" [nzTitle]=bookingAttachments>
      <app-attachments [attachments]="booking.attachments"
                       [uploadTokenReq]="uploadTokenReq"
                       [readonly]="secondPower.readonly"
                       [isGroup]="true"
                       [isPreview]="true"
                       (completeAll)="reloadDetail($event)"
                       (valueChange)="onAttachmentsChange($event)"></app-attachments>
    </nz-tab>
    <ng-template #bookingAttachments>
      <nz-badge nzDot [nzShowDot]="messageRemind.attachment > 0">
        <span>
          {{'BookingAttachments' | translate}}
        </span>
      </nz-badge>
    </ng-template>

    <nz-tab [nzTitle]=Forms *ngIf="isCash && bookingDetail.bookingForms && bookingDetail.bookingForms.length > 0">
      <div class="booking-section-wrapper"
           *ngFor="let i of bookingDetail.bookingForms; let index = index"
           appThrottleClick (throttleClick)="onFormDetail(i)">
        <div class="property-row">
          <div class="property-title-row">{{ i.name }}</div>
          <div class="property-value-row">
            <div class="property-pick-value">
            <span style="flex: 1;">
              <span *ngIf="formList && formList.length" style="color: #1d64c4; margin-right: 5px">
                <span *ngIf="formList[index].status === 'UNCOMPLETED'">{{ 'Uncompleted' | translate }}</span>
                <span *ngIf="formList[index].status === 'COMPLETED'">{{ 'Completed' | translate }}</span>
              </span>
            </span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>
    </nz-tab>
    <ng-template #Forms>
      {{'Forms'| translate}}
    </ng-template>
  </nz-tabset>

  <div class="footer" *ngIf="booking.status === 'DRAFT'">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="onSummit()">
          <!--          <em nz-icon nzType="check" nzTheme="outline"></em>-->
          <span>{{'Summit' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

  <div class="footer" *ngIf="instance.isFoneOrDev() ? false : booking.status !== 'DRAFT'">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="showDeleteConfirm()">
          <!--          <em nz-icon nzType="check" nzTheme="outline"></em>-->
          <span>{{'Reuse' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

  <!--  form签名抽屉-->
  <nz-drawer [nzWidth]="800" [nzMaskClosable]="true" [nzVisible]="formVisible"
             (nzOnClose)="formClose()">
    <ng-container *nzDrawerContent>
      <div class="drawer-container" *ngIf="formItem">
        <div class="common-title" style="margin-bottom: 40px">
          {{formItem.bookingFormName}}
        </div>
        <div class="flex">
          <div>{{formItem.signedDate ? (formItem.signedDate| date:'yyyy-MM-dd') : (time | date:'yyyy-MM-dd')}}</div>
          <div *ngIf="formItem.status === 'UNCOMPLETED'">{{'Unsigned' | translate}}</div>
          <div *ngIf="formItem.status === 'COMPLETED'">{{'Signed' | translate}}</div>
        </div>
        <div class="section-wrapper">
          <div
            style="padding: 15px 0 15px 0;display: flex; flex-direction: row; align-items: center; justify-content: start;">
            <div style="flex: 1; display: flex; flex-direction: row; align-items: center;">
              <span style="margin-left: 5px; font-weight: bold; font-size: 16px"> {{formItem.bookingFormName}}</span>
            </div>
            <a *ngIf="formPdf" nz-button nzType="link" nzSize="small" nz-tooltip
               [nzTooltipTitle]="'Preview' | translate"
               appThrottleClick (throttleClick)="onPreviewNormalResult(); $event.stopPropagation();">
              <img alt="" width="20" src="../../../../assets/images/ic_view.svg">
            </a>
            <a *ngIf="formPdf" nz-button nzType="link" nzSize="small" nz-tooltip
               [nzTooltipTitle]="'Download' | translate"
               appThrottleClick (throttleClick)="onDownloadNormalResult(); $event.stopPropagation();">
              <img alt="" width="20" src="../../../../assets/images/ic_download.svg">
            </a>
          </div>
        </div>

        <div *ngIf="formItem.signedData && formItem.signedData.length">
          <div *ngFor="let signer of formItem.signedData;" class="section-wrapper" style="">
            <app-property-pick [title]="signer.title"
                               appThrottleClick (throttleClick)="onOpenSignature(signer)"
                               [value]="signer.signedStatus | metadataTranslate:'signedStatus'"
                               [valueColor]="signer.signedStatus === 'SIGNED' ? '#1D64C4' : '#e63757'"
                               [bottomLineVisible]="false"></app-property-pick>
          </div>
        </div>

        <div>
          <div class="annex-text">{{ 'UploadVoucher' | translate }}</div>
          <app-attachments [attachments]="formItem.credentials"
                           [uploadTokenReq]="uploadTokenReq"
                           [isPreview]="true"
                           (valueChange)="resAttachmentsChange($event)"
                           [showEmpty]="false"></app-attachments>
        </div>
      </div>
      <div class="footer">
        <div nz-row style="width: 100%; height: 100%;">
          <div nz-col nzSpan="24" class="footer-button-primary">
            <a nz-button nzType="link" nzBlock nzSize="large"
               [style]="!btnDisabled ? 'opacity: 0.5' : ''"
               [disabled]="!btnDisabled"
               *ngIf="formItem?.status === 'UNCOMPLETED'"
               appThrottleClick (throttleClick)="onSignatureEnd()">
              <!--          <em nz-icon nzType="check" nzTheme="outline"></em>-->
              <span>{{'SubmitSignature' | translate}}</span>
            </a>
            <a nz-button nzType="link" nzBlock nzSize="large"
               *ngIf="formItem?.status === 'COMPLETED'"
               appThrottleClick (throttleClick)="onSignatureEnd()">
              <span>{{'ResetAllSignatures' | translate}}</span>
            </a>
          </div>
        </div>
      </div>
    </ng-container>
  </nz-drawer>
</div>
