<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{'QRCodeTitle' | translate}}
  </div>
  <div>
    <p style="text-align: center; font-size: 16px">{{'QRCode' | translate}}</p>
    <nz-divider></nz-divider>
    <div style="display: flex; justify-content: center; align-items: center;margin-top: 10px">
      <qr-code [value]="codeImgUrl" [level]="'L'" [size]="280"></qr-code>
    </div>
  </div>
</div>
