<div class="drawer-container">

  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 40px">
      {{'InspectionDetail' | translate}}
    </div>

  <div class="section-title" style="margin-top: 20px">
    <span style="margin-left: 8px">{{'InspectionBrief' | translate}}</span>
  </div>

  <!-- 类型 -->
  <app-property-text [displayMode]="'vertical'"
                     [title]="'Type' | translate"
                     [value]="info.searchCondition.type | metadataTranslate: 'amlRecordType'"></app-property-text>

  <div [ngSwitch]="info.searchCondition.type">
    <div *ngSwitchCase="'PERSON'">
      <nz-row [nzGutter]="24">
        <nz-col [nzSpan]="4" >
          <!-- 姓 -->
          <app-property-text [displayMode]="'vertical'"
                             [title]="'LastName' | translate"
                             [value]="info.searchCondition.surname"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="4">
          <!-- 名 -->
          <app-property-text [displayMode]="'vertical'"
                             [title]="'FirstName' | translate"
                             [value]="info.searchCondition.forename"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="4" >
          <!-- 姓 -->
          <app-property-text [displayMode]="'vertical'"
                             [title]="'lastNameEN' | translate"
                             [value]="info.searchCondition.englishSurname"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="4">
          <!-- 名 -->
          <app-property-text [displayMode]="'vertical'"
                             [title]="'firstNameEN' | translate"
                             [value]="info.searchCondition.englishForename"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="4">
          <!-- 出生日期 -->
          <app-property-text [displayMode]="'vertical'"
                             [title]="'DateOfBirth' | translate"
                             [value]="info.searchCondition.dateOfBirth"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="4">
          <app-property-text [displayMode]="'vertical'"
                             [title]="'Gender' | translate"
                             [value]="info.searchCondition.gender | metadataTranslate: 'gender'"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="4">
          <!-- 国家/地区 -->
          <app-property-text [displayMode]="'vertical'"
                             [title]="'Country/Region' | translate"
                             [value]="info.searchCondition.country | metadataTranslate: 'countries'"></app-property-text>
        </nz-col>
      </nz-row>
    </div>
    <div *ngSwitchCase="'BUSINESS'">
      <nz-row [nzGutter]="12">
        <nz-col [nzSpan]="12">
          <!-- 姓 -->
          <app-property-text [displayMode]="'vertical'"
                             [title]="'CompanyName' | translate"
                             [value]="info.searchCondition.businessName"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <app-property-text [displayMode]="'vertical'"
                             [title]="'CompanyNameEn' | translate"
                             [value]="info.searchCondition.englishBusinessName"></app-property-text>
        </nz-col>
        <nz-col [nzSpan]="12">
          <!-- 国家/地区 -->
          <app-property-text [displayMode]="'vertical'"
                             [title]="'Country/Region' | translate"
                             [value]="info.searchCondition.country | metadataTranslate: 'countries'"></app-property-text>
        </nz-col>
      </nz-row>
    </div>
  </div>

  <div class="section-title" style="margin-top: 50px">
    <span style="margin-left: 8px">{{'InspectionResult' | translate}}</span>
  </div>

  <nz-table *ngIf="!!info.searchResult && info.searchResult.length > 0"
    style="margin: 30px 0 0 0"
            [nzBordered]="false"
            [nzOuterBordered]="true"
            [nzShowPagination]="false"
            [nzFrontPagination]="false"
            [nzData]="info.searchResult"
            [nzTotal]="info.searchResult?.length"
            [nzTitle]="tableHeader"
            [nzFooter]="tableFooter"
            [nzScroll]="{ x: '1150px'}">
    <ng-template #tableHeader>
      <div *ngIf="statusKeyValues"
           style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center">
        <div *ngFor="let statusKeyValue of statusKeyValues"
             style="display: flex; flex-direction: row; justify-content: center; align-items: center; margin-left: 20px">
          <div [ngSwitch]="statusKeyValue.key">
<!--            <div *ngSwitchCase="'PENDING'">-->
<!--              <div class="artificial-status-no-pointer artificial-status-pending"></div>-->
<!--            </div>-->
            <div *ngSwitchCase="'MATCHED'">
              <div class="artificial-status-no-pointer artificial-status-matched"></div>
            </div>
            <div *ngSwitchCase="'NOT_MATCHED'">
              <div class="artificial-status-no-pointer artificial-status-no-matched"></div>
            </div>
<!--            <div *ngSwitchCase="'UNSURE'">-->
<!--              <div class="artificial-status-no-pointer artificial-status-unsure"></div>-->
<!--            </div>-->
          </div>
          {{statusKeyValue.text}}
          <!--          <div class="artificial-status artificial-status-pending">-->
          <!--            {{statusKeyValue.key}} - {{statusKeyValue.value}}-->
          <!--          </div>-->
        </div>
      </div>
    </ng-template>
    <ng-template #tableFooter>
      <div class="table-title">
        {{'TotalRows' | translate}} : <span
        style="color: #002140; font-weight: bold;">{{ info.searchResult?.length }}</span>
      </div>
    </ng-template>

    <thead>
    <tr>
      <th nzLeft nzWidth="165px"><span>{{'Name' | translate}}</span></th>
      <th><span>{{'ID' | translate}}</span></th>
      <th><span>{{'DateOfBirth' | translate}}</span></th>
      <th><span>{{'Gender' | translate}}</span></th>
      <th><span>{{'Similarity' | translate}}</span></th>
      <th nzWidth="200px"><span>{{'StateOfArtificial' | translate}}</span></th>
      <th><span>{{'Remark' | translate}}</span></th>
      <th nzRight nzWidth="100px"><span>{{'Action' | translate}}</span></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let result of info.searchResult; let i = index">
      <td nzLeft>
        {{ result.name }}
      </td>
      <td>
        <span>{{ result.id }}</span>
      </td>
      <td>
        <span>{{ result.birthday ?? 'N/A' }}</span>
      </td>
      <td>
        <span>{{ result.gender | translate }}</span>
      </td>
      <td>
<!--        <nz-progress [nzPercent]="result.score" nzWidth="5" nzStrokeWidth="5"-->
<!--                     [nzStrokeColor]="{ '0%': '#e63757', '100%': '#e51035' }">-->
<!--        </nz-progress>-->
        <app-progress-bar [progress]="result.score" [degraded]="{'89': '#e63757'}"></app-progress-bar>

      </td>
      <td>
        <div style="display: flex; align-items: center; flex-direction: row">
<!--          <div class="artificial-status artificial-status-pending"-->
<!--               nz-tooltip [nzTooltipTitle]="'PENDING' | metadataTranslate:'amlArtificialStatus'"-->
<!--               appThrottleClick (throttleClick)="onArtificialStatusUpdate(result, 'PENDING', tplEditorTitle, tplEditorContent, tplEditorFooter)">-->
<!--              <i *ngIf="result.artificialStatus === 'PENDING'" nz-icon nzType="check" nzTheme="outline" style="color: white"></i>-->
<!--          </div>-->
          <div class="artificial-status artificial-status-matched"
               nz-tooltip [nzTooltipTitle]="'true-positive'"
               appThrottleClick (throttleClick)="onArtificialStatusUpdate(result,'MATCHED',tplEditorTitle,tplEditorContent,tplEditorFooter)">
              <i *ngIf="result.artificialStatus === 'MATCHED'" nz-icon nzType="check" nzTheme="outline" style="color: white"></i>
          </div>
          <div class="artificial-status artificial-status-no-matched"
               nz-tooltip [nzTooltipTitle]="'false-positive'"
               appThrottleClick (throttleClick)="onArtificialStatusUpdate(result,'NOT_MATCHED',tplEditorTitle,tplEditorContent,tplEditorFooter)">
              <i *ngIf="result.artificialStatus === 'NOT_MATCHED'" nz-icon nzType="check" nzTheme="outline" style="color: white"></i>
          </div>
<!--          <div class="artificial-status artificial-status-unsure"-->
<!--               nz-tooltip [nzTooltipTitle]="'UNSURE' | metadataTranslate:'amlArtificialStatus'"-->
<!--               appThrottleClick (throttleClick)="onArtificialStatusUpdate(result,'UNSURE',tplEditorTitle,tplEditorContent,tplEditorFooter)">-->
<!--              <i *ngIf="result.artificialStatus === 'UNSURE'" nz-icon nzType="check" nzTheme="outline" style="color: white"></i>-->
<!--          </div>-->
        </div>
      </td>
      <td>
        <span>{{ result.artificialRemark }}</span>
      </td>
      <td nzRight>
        <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'ViewDetail' | translate"
           appThrottleClick (throttleClick)="onPreview(result); $event.stopPropagation();">
          <img alt="" width="20" src="assets/images/ic_view.svg">
        </a>
      </td>
    </tr>
    </tbody>
  </nz-table>

  <div *ngIf="!info.searchResult || info.searchResult.length === 0">

    <div *ngIf="normalPdf" class="section-wrapper" style="">
      <div
        style="padding: 15px 0 15px 0;display: flex; flex-direction: row; align-items: center; justify-content: start;">
        <div style="flex: 1; display: flex; flex-direction: row; align-items: center;">
          <img alt="" width="20" src="assets/images/ic_details.svg">
          <span style="margin-left: 5px; font-weight: bold; color: #00d97e; font-size: 16px">{{'Normal' | translate}}</span>
        </div>
        <a *ngIf="normalPdf" nz-button nzType="link" nzSize="small" nz-tooltip
           [nzTooltipTitle]="'Preview' | translate"
           appThrottleClick (throttleClick)="onPreviewNormalResult(); $event.stopPropagation();">
          <img alt="" width="20" src="assets/images/ic_view.svg">
        </a>
        <a *ngIf="normalPdf" nz-button nzType="link" nzSize="small" nz-tooltip
           [nzTooltipTitle]="'Download' | translate"
           appThrottleClick (throttleClick)="onDownloadNormalResult(); $event.stopPropagation();">
          <img alt="" width="20" src="assets/images/ic_download.svg">
        </a>
      </div>
    </div>

<!--    <pdf-viewer *ngIf="normalPdf" [src]="normalPdf" style="margin-top: 20px; height: 100%"></pdf-viewer>-->

  </div>

  </nz-spin>

</div>

<ng-template #tplEditorTitle>
  <span>{{'ModifyStateOfArtificial' | translate}}</span>
</ng-template>
<ng-template #tplEditorContent let-params>
  <div>{{ 'DoYouWantToModifyTheStatusOfThisRecord' | translate }}?</div>
  <div>
    <app-property-input-area [title]="'Remark' | translate"
                             placeholder=""
                             [(value)]="params.artificialRemark"></app-property-input-area>
  </div>
</ng-template>
<ng-template #tplEditorFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()">
    <!--    <i nz-icon nzType="close" nzTheme="outline" style="font-size: 13px;"></i>-->
    <span>{{'Close' | translate}}</span>
  </button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onArtificialStatusUpdateConfirm(ref, params)" [nzLoading]="stateOfArtificialLoading">
    <!--    <i nz-icon nzType="save" nzTheme="outline" style="font-size: 13px;"></i>-->
    <span>{{'Confirm' | translate}}</span>
  </button>
</ng-template>
