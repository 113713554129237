import { Component, OnInit } from '@angular/core';
import { MessageService } from './message.service';
import { MessageListResp, MessageType } from '../api/types';
import { MetadataService } from '../shared/service/metadata.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { MessagesComponent } from './messages/messages.component';
import { MessageConfig, MessageSetting } from './message-types';

@Component({
  selector: 'app-message-main',
  templateUrl: './message-main.component.html',
  styleUrls: ['./message-main.component.less']
})
export class MessageMainComponent implements OnInit {

  spinning: boolean;
  messageOverviews: MessageListResp[];

  isSettingsVisible: boolean;
  configLoading = false;
  config: MessageConfig;

  constructor(private messageService: MessageService,
              private metadataService: MetadataService,
              private drawerService: NzDrawerService,
  ) {
  }

  ngOnInit(): void {
    this.load();
    this.getMessageConfig();
  }

  load(): void {
    this.spinning = true;
    this.messageService.center()
      .subscribe(
        data => {
          this.messageOverviews = data;
          this.spinning = false;
        },
        error => {
          this.spinning = false;
        });
  }

  onReload(): void {
    this.load();
  }

  messageTypeTranslate(messageType: MessageType): string {
    return this.metadataService.translate('messageType', messageType);
  }

  onMessage(message: MessageListResp): void {
    const drawerRef = this.drawerService.create<MessagesComponent, { value: MessageListResp }, string>({
      nzWidth: 1024,
      nzContent: MessagesComponent,
      nzContentParams: {
        messageOverview: message
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      // component.afterRecord
      //   .subscribe(
      //     data => {
      //       this.load();
      //     }
      //   );
    });

    drawerRef.afterClose.subscribe(data => {
      this.load();
    });
  }

  getMessageConfig(): void {
    this.configLoading = true;
    this.messageService.getMessageConfig()
      .subscribe(
        data => {
          this.configLoading = false;
          this.config = JSON.parse(JSON.stringify(data));
        },
        error => {
          this.configLoading = false;
        }
      );
  }

  onSettings(): void {
    this.isSettingsVisible = true;
    this.getMessageConfig();
  }

  onMessageSettingsCancel(): void {
    this.isSettingsVisible = false;
  }

  onBadgeReminderNumberChange(value: boolean): void {
    this.config.digitalReminder = value;
    if (this.config.digitalReminder && this.config.systematicNotification) {
      this.config.messageDisturb = false;
    }
  }

  onSystemNotificationChange(value: boolean): void {
    this.config.systematicNotification = value;
    if (this.config.digitalReminder && this.config.systematicNotification) {
      this.config.messageDisturb = false;
    }
  }

  onNotDisturbChange(value: boolean): void {
    this.config.messageDisturb = value;
    if (value) {
      this.config.systematicNotification = false;
      this.config.digitalReminder = false;
    }
  }

  onMessageConfigSave(): void {
    this.configLoading = true;
    this.messageService.updateMessageConfig(this.config)
      .subscribe(
        data => {
          this.configLoading = false;
          this.isSettingsVisible = false;
          this.messageService.messageConfigUpdated.emit(this.config);
        },
        error => {
          this.configLoading = false;
        }
      );
  }

  get showDot(): boolean {
    if (!this.config) {
      return false;
    }
    return this.config.messageDisturb || !this.config.digitalReminder;
  }

}
