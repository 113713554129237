import {Address, PagedReq} from '../api/types';
import {Expose, plainToInstance, Transform, Type} from 'class-transformer';


export class ScheduleItem {
  @Expose() id: number;
  @Expose() linkId: string;
  @Expose() type: string;
  @Expose() status: string;
  @Expose() title: string;
  @Expose() startTime: number;
  @Expose() endTime: number;
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() place: Address  = new Address();
  @Expose() description: string;
  @Expose() remind: string;
  @Expose() owner: string;
  @Expose() reminderTime: number;
  @Expose() createTime: number;

  toAddressString(): string {
    return (this.place.region ?? '') + (this.place.city ?? '') + (this.place.street ?? '') + (this.place.location ?? '');
  }
}

export class ScheduleData {
  [key: number]: ScheduleItem[]
}

export class ScheduleParams {
  @Expose() title: string; // 标题
  @Expose() startTime: number; // 开始时间
  @Expose() endTime: number; // 结束时间
  @Type(() => Address)
  @Transform((value) => plainToInstance(Address, Object.assign(new Address(), value.value)))
  @Expose() place: Address = new Address(); // 地点
  @Expose() description: string; // 描述
  @Expose() remind: string; // 提醒

  toAddressString(): string {
    return (this.place.region ?? '') + (this.place.city ?? '') + (this.place.street ?? '') + (this.place.location ?? '');
  }
}
