import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { GuideContent } from '../../api/types';
import SwiperCore, { Pagination } from 'swiper/core';
import { Router } from '@angular/router';

// install Swiper modules
SwiperCore.use([Pagination]);

@Component({
  selector: 'app-guide-gallery',
  templateUrl: './guide-gallery.component.html',
  styleUrls: ['./guide-gallery.component.less']
})
export class GuideGalleryComponent implements OnInit {

  contents: GuideContent[];

  constructor(private authService: AuthService,
              private router: Router) {
    this.authService.getGuideContent()
      .subscribe(
        data => {
          this.contents = data;
        },
        error => {
        }
      );
  }

  ngOnInit(): void {
  }

  onLoginNow(): void {
    this.router.navigate(['/auth/login-by-account']).then();
  }
}
