import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PropertyDisplayMode} from '../../shared';

@Component({
  selector: 'app-property-switch',
  templateUrl: './property-switch.component.html',
  styleUrls: ['./property-switch.component.less']
})
export class PropertySwitchComponent implements OnInit {

  @Input() title: string;
  @Input() tip: string;
  @Input() tipVisible = false;
  @Input() bottomLineVisible = true;
  @Input() disabled = false;

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  innerValue: boolean;

  @Input()
  get value(): boolean {
    return this.innerValue;
  }

  set value(val: boolean) {
    this.innerValue = val;
    this.valueChange.emit(this.innerValue);
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
