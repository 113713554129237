<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'ProductRecommendationSelection' | translate}}
    </div>

    <div class="section-title flex-row" style="margin-top: 20px;">
      <div style="margin-left: 8px; flex: 1">{{'RecommendedProduct' | translate}}</div>
      <div *ngIf="canSave">
        <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;" appThrottleClick (throttleClick)="onRecommendedProductSelect()">
          {{'SelectProduct' | translate}} <span style="color: #999999; margin-left: 3px">></span>
        </a>
      </div>
    </div>

    <div *ngFor="let product of recommendedProducts; let i = index;">
      <app-fna-product-item [product]="product" [index]="i" [showEditButton]="canSave" (editEvent)="onRecommendedProductEdit(product, i)"></app-fna-product-item>
    </div>

    <div *ngIf="recommendedProducts && recommendedProducts.length === 1">
      <app-property-input [displayMode]="'vertical'"
                          [title]="'只能提供一间保险公司建议的原因，如有' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate)"
                          [(value)]="fnaAssessAdvice.EVALUATION_Reason"></app-property-input>
    </div>

    <div class="section-title flex-row" style="margin-top: 20px;">
      <div style="margin-left: 8px; flex: 1">{{'FinalProductChoice' | translate}}</div>
      <div *ngIf="canSave">
        <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;" appThrottleClick (throttleClick)="onFinalProductSelect()">
          {{'SelectProduct' | translate}} <span style="color: #999999; margin-left: 3px">></span>
        </a>
      </div>
    </div>

    <div *ngFor="let product of fna.products; let i = index;">
      <app-fna-product-item [product]="product" [index]="i"></app-fna-product-item>
    </div>

    <div class="section-title flex-row" style="margin-top: 20px;">
      <div style="margin-left: 8px; flex: 1">{{'SuggestionsReasons' | translate}}</div>
    </div>

    <div>
      <div class="section-wrapper" style="padding-top: 20px;padding-bottom: 20px;">
        <div>
          <div class="text-style" style="margin-bottom: 8px;">1.有没有出现合适性偏差?</div>
          <nz-radio-group [(ngModel)]="fnaAssessAdvice.EVALUATION_01">
            <label nz-radio [nzValue]="false" style="margin-right: 20px">没有</label>
            <label nz-radio [nzValue]="true">有</label>
          </nz-radio-group>
          <div *ngIf="fnaAssessAdvice.EVALUATION_01 === false">
            <div style="margin: 5px 0">
              <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_No_A">
                <span>此建議是考慮到申請人的理財目標、全面保障的需要、各需要的優先重要性及申請人的財務預算而作出</span>
              </label>
            </div>
            <div style="margin: 5px 0">
              <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_No_B">
                <span>此建議是考慮到申請人的理財目標、全面保障的需要、各需要的優先重要性及申請人的財務預算而 作出，在與申請人商討後，申請人期望在以上各方面及以下原因取得平衡</span>
              </label>
              <div style="margin-left: 24px">
                <input nz-input [placeholder]="'PleaseEnter' | translate" [(ngModel)]="fnaAssessAdvice.EVALUATION_01_A_No_B_addition" />
              </div>
            </div>
          </div>
          <div *ngIf="fnaAssessAdvice.EVALUATION_01 === true">
            <div style="margin: 5px 0">
              <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_Yes_A">
                <span>购买保险产品的目标</span>
              </label>
            </div>
            <div style="margin: 5px 0">
              <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_Yes_B">
                <span>目标利益/保障期的预期时间</span>
              </label>
            </div>
            <div style="margin: 5px 0">
              <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_Yes_C">
                <span>能够及愿意缴付的保费占所有收入来源</span>
              </label>
            </div>
            <div style="margin: 5px 0">
              <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_Yes_D">
                <span>能够及愿意为保单支付保费的年期</span>
              </label>
            </div>
            <div style="margin: 5px 0">
              <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_Yes_E">
                <span>保障所需要的金额</span>
              </label>
            </div>
            <div style="margin: 5px 0">
              <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_Yes_F">
                <span>其他</span>
              </label>
              <div style="margin-left: 24px">
                <input nz-input [placeholder]="'PleaseEnter' | translate" [(ngModel)]="fnaAssessAdvice.EVALUATION_01_Yes_F_addition" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="text-style" style="margin-top: 10px;margin-bottom: 8px;">2.虽然有合适性偏差出现，但仍然介绍此产品的原因是</div>
          <div>
            <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_02_A">
              <span>因现在供款预算原因，这保险计划的金额未能达到保险或者储蓄的全面要求，但是现在建议的保险，已经符合阁下的年期及供款金额等要求，能够为阁下迈向全面的保障及储蓄目标。</span>
            </label>
          </div>
          <div style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_02_B"></label>
            <div style="flex: 1;margin-left: 10px">
              <input nz-input [placeholder]="'PleaseEnter' | translate" [(ngModel)]="fnaAssessAdvice.EVALUATION_02_B_addition" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="fna.showMedicalAssessment" class="section-wrapper" style="padding-top: 20px;padding-bottom: 20px;">
      <div class="text-style">(准)受保人的医疗保险目标及需要</div>
      <div>
        <div class="text-style" style="margin-bottom: 8px;">阁下购买医疗保险产品的目标何？（勾选一项或多项）</div>
        <div>
          <label nz-checkbox [(ngModel)]="fnaMedicalAssessment.MEDICAL_01_A">
            <span>(a) 為應付未来医疗保健需要</span>
          </label>
        </div>
        <div>
          <label nz-checkbox [(ngModel)]="fnaMedicalAssessment.MEDICAL_01_B">
            <span>(b) 為應付上涨的醫療保健费用</span>
          </label>
        </div>
        <div>
          <label nz-checkbox [(ngModel)]="fnaMedicalAssessment.MEDICAL_01_C">
            <span>(c) 為应付因伤残/住院是的收入损失</span>
          </label>
        </div>
      </div>
      <div>
        <div class="text-style" style="margin-top: 8px;margin-bottom: 8px;">阁下需要的医疗保险产品种类为何？（勾选一项或多项）</div>
        <div>
          <label nz-checkbox [(ngModel)]="fnaMedicalAssessment.MEDICAL_02_A">
            <span>(a) 弥偿</span>
          </label>
        </div>
        <div>
          <label nz-checkbox [(ngModel)]="fnaMedicalAssessment.MEDICAL_02_B">
            <span>(b) 非弥偿</span>
          </label>
        </div>
        <div>
          <label nz-checkbox [(ngModel)]="fnaMedicalAssessment.MEDICAL_02_C">
            <span>(c) 混合型</span>
          </label>
        </div>
      </div>
    </div>
    <!--    <div *ngFor="let fnaQuestion of fnaQuestions; let i = index">-->
    <!--      <app-fna-question [disabled]="!canSave" [fnaQuestion]="fnaQuestion" (fnaQuestionChange)="onFnaQuestionChange($event, i)" (subQuestionEvent)="onSubQuestionEvent($event)">-->
    <!--      </app-fna-question>-->
    <!--    </div>-->

  </nz-spin>

  <div *ngIf="canSave" class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onTemporarySave()">
          <span>{{'Cancel' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" [disabled]="loading"
           appThrottleClick (throttleClick)="onSave()"
           [style]="loading ? 'opacity: 0.5' : ''">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
