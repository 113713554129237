import { Injectable } from '@angular/core';
import {BaseService} from '../base/base.service';
import {HttpService} from '../core/http.service';
import { Proposal, ProposalListResp, ProposalSearch } from '../proposal/proposal-types';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResponse, PagedResp} from '../api/types';
import {HttpParams} from '@angular/common/http';
import {API} from '../api/api';
import {map} from 'rxjs/operators';
import {plainToInstance} from 'class-transformer';
import {CreateParam, ListQuery, ListRes, ProposalList, SelectListQuery} from './proposal-custom-types';
import {CreateProposalCompare} from '../proposal-compare/proposal-compare';

@Injectable({
  providedIn: 'root'
})
export class ProposalCustomService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  /**
   * 获取自定义模板列表
   * @param search 查询条件
   */
  list(search: ListQuery): Observable<PagedResp<ListRes>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.search != null) {
      httpParams = httpParams.set('search', search.search.toString());
    }
    if (search.accountName != null) {
      httpParams = httpParams.set('accountName', search.accountName.toString());
    }
    return this.http.get<ApiResponse<PagedResp<ListRes>>>(API.proposalCustomV3 + '/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
      // 转换为具体的类（包括函数）
      // const resp = plainToInstance(PagedResp, data.body.data) as PagedResp<ListRes>;
      // for (let i = 0; i < resp.list.length; i++) {
      //   resp.list[i] = plainToInstance(ListRes, resp.list[i]);
      // }
      // return resp;
    }));
  }

  /**
   * .删除自定义模板
   * @param id 自定义模板ID
   */
  deleteCustom(id): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.proposalCustomV3 + `/delete/${id}`, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 自定义模板重试
   * @param id 自定义模板ID
   */
  retryCustom(id): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.proposalCustomV3 + `/retry/${id}`, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 自定义模板重试
   * @param search 查询条件
   */
  selectList(search: SelectListQuery): Observable<PagedResp<Proposal>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.search != null) {
      httpParams = httpParams.set('search', search.search.toString());
    }
    if (search.accountName != null) {
      httpParams = httpParams.set('accountName', search.accountName.toString());
    }
    if (search.accountNames != null) {
      httpParams = httpParams.set('accountNames', search.accountNames.toString());
    }
    if (search.age != null) {
      httpParams = httpParams.set('age', search.age.toString());
    }
    if (search.productCodes != null) {
      httpParams = httpParams.set('productCodes', search.productCodes.toString());
    }
    return this.http.get<ApiResponse<PagedResp<Proposal>>>(API.proposalCustomV3 + `/list-proposal`, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * .新增自定义模板
   * @param params CreateParam
   */
  add(params: CreateParam): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.proposalCustomV3 + '/add', params, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}

