<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'SelectProposal' | translate}}
    </div>
  </nz-spin>
  <div *ngIf="!listArr">
    <nz-empty nzNotFoundImage="simple"></nz-empty>
  </div>
  <div *ngIf="listArr">
    <nz-table [nzBordered]="false"
              [nzOuterBordered]="true"
              [nzShowPagination]="true"
              [nzLoading]="loading"
              [nzLoadingIndicator]="indicatorTemplate"
              [nzTitle]="tableHeader"
              [nzPageIndex]="listArr.pageNum"
              [nzPageSize]="listArr.pageSize"
              [nzTotal]="listArr.total"
              [nzFooter]="tableFooter"
              [nzFrontPagination]="false"
              (nzPageIndexChange)="onSearch($event)"
              (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
              [nzData]="listArr.list"
              [nzScroll]="{ x: '1140px'}">
      <ng-template #tableHeader>
        <nz-row>
          <nz-col [nzSpan]="12">
            <div class="list-header-title">{{'ProposalCustomizeList' | translate}}
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'ProposalList' | translate"
                 style="margin-left: 5px"
                 appThrottleClick (throttleClick)="onReload()">
                <em nz-icon style="color: black;font-size:22px" nzType="reload" nzTheme="outline"></em>
              </a>
            </div>
          </nz-col>
        </nz-row>
      </ng-template>
      <ng-template #tableFooter>
        <div class="table-title">
          {{'TotalRows' | translate}} : <span
          style="color: #002140; font-weight: bold;">{{ listArr.total }}</span>
        </div>
      </ng-template>
      <thead>
      <tr>
        <th nzLeft nzWidth="50px"></th>
        <th nzLeft nzWidth="160px">{{'ProposalID' | translate}}</th>
        <th>{{'ProductName' | translate}}</th>
        <th nzWidth="100px">{{'PaymentTerm' | translate}}</th>
        <th nzWidth="90px">{{'Gender' | translate}}</th>
        <th nzWidth="60px">{{'Age' | translate}}</th>
        <th nzWidth="100px">{{'Smoker' | translate}}</th>
        <th nzWidth="100px">{{'Withdrawal' | translate}}</th>
        <th nzWidth="140px">{{'Premium' | translate}}(USD)</th>
        <th nzWidth="140px">{{'SumAssured' | translate}}(USD)</th>
        <!--          <th>{{'CreateTime' | translate}}</th>-->
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of listArr.list">
        <td
          nzLeft
          [nzChecked]="setOfCheckedId.has(data.proposalId)"
          (nzCheckedChange)="onItemChecked(data, $event)"
        ></td>
        <td nzLeft>
          {{data.proposalId}}
          <div *ngIf="!data.extracted" class="warning-text">{{'DataException' | translate}}</div>
        </td>
        <td><img width="20" style="border-radius: 3px; margin-right: 5px" [src]="data.companyLogo"
        >{{data.productName}}</td>
        <td>{{data.paymentTerm}}</td>
        <td>{{data.gender | metadataTranslate:'gender'}}</td>
        <td>{{data.age}}</td>
        <td>{{data.smoke | metadataTranslate:'smoke'}}</td>
        <td>
          {{data.isWithdrawal ? ('Yes' | translate) : ('No' | translate)}}</td>
        <td>{{data.premium ? (data.premium | number) : 'N/A'}}</td>
        <td>{{data.sumAssured ? (data.sumAssured | number) : 'N/A'}}</td>
        <!--          <td>{{data.applicationTime | date:'yyyy-MM-dd'}}</td>-->
      </tr>
      </tbody>
    </nz-table>
  </div>
  <div class="footer" style="z-index:10">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [disabled]="!canInspect"
           [style]="!canInspect ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
