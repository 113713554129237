import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogReply, Material, PendingReply, UploadTokenReq, UploadType } from '../../../api/types';
import { AccountService } from '../../../account/account.service';
import { DataService } from '../../service/data.service';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.less']
})
export class MessageDialogComponent implements OnInit {
  loading = false;

  @Output()
  dialogReplied: EventEmitter<DialogReply> = new EventEmitter<DialogReply>();
  @Input()
  list: PendingReply[] = [];
  @Input()
  dialogReply: DialogReply = new DialogReply();
  @Input()
  uploadTokenReq: UploadTokenReq;
  @Input()
  moduleId: string;
  @Input()
  Title: string;
  @Input()
  replyContent = true;
  accountName: string;

  @Input()
  moduleType: string;
  @Output()
  confirmParam: EventEmitter<any> = new EventEmitter<any>();

  constructor(private accountService: AccountService,
              private dataService: DataService) {
  }

  ngOnInit(): void {
    this.accountName = this.accountService.passport.accountName;
    this.loadDraft();
    this.list.map(item => item.content = item.content ? item.content : '');
  }

  loadDraft(): void {
    switch (this.uploadTokenReq.uploadType) {
      case UploadType.PROPOSAL:
        this.dialogReply = this.dataService.getProposalMessageDraft(this.moduleId) ?? new DialogReply();
        break;
      case UploadType.BOOKING:
        this.dialogReply = this.dataService.getBookingMessageDraft(this.moduleId) ?? new DialogReply();
        break;
      case UploadType.UNDERWRITING:
        this.dialogReply = this.dataService.getUnderwritingMessageDraft(this.moduleId) ?? new DialogReply();
        break;
      case UploadType.POLICY:
        this.dialogReply = this.dataService.getPolicyMessageDraft(this.moduleId) ?? new DialogReply();
    }
  }

  saveDraft(): void {
    if (!this.dialogReply) {
      return;
    }
    if (!this.dialogReply.content && (!this.dialogReply.attachments || this.dialogReply.attachments.length === 0)) {
      return;
    }
    switch (this.uploadTokenReq.uploadType) {
      case UploadType.PROPOSAL:
        this.dataService.setProposalMessageDraft(this.moduleId, this.dialogReply);
        break;
      case UploadType.BOOKING:
        this.dataService.setBookingMessageDraft(this.moduleId, this.dialogReply);
        break;
      case UploadType.UNDERWRITING:
        this.dataService.setUnderwritingMessageDraft(this.moduleId, this.dialogReply);
        break;
      case UploadType.POLICY:
        this.dataService.setPolicyMessageDraft(this.moduleId, this.dialogReply);
    }
  }

  reply(): void {
    if (!this.canReply) {
      return;
    }
    switch (this.uploadTokenReq.uploadType) {
      case UploadType.PROPOSAL:
        this.dialogReply.proposalId = this.moduleId;
        this.dataService.removeProposalMessageDraft(this.moduleId);
        break;
      case UploadType.BOOKING:
        this.dialogReply.bookingNo = this.moduleId;
        this.dataService.removeBookingMessageDraft(this.moduleId);
        break;
      case UploadType.UNDERWRITING:
        this.dialogReply.underwritingNo = this.moduleId;
        this.dataService.removeUnderwritingMessageDraft(this.moduleId);
        break;
      case UploadType.POLICY:
        this.dialogReply.policyId = this.moduleId;
        this.dataService.removePolicyMessageDraft(this.moduleId);
    }
    this.dialogReplied.emit(this.dialogReply);
  }

  needConfirmBtn(id, flag: boolean): void {
    const param = {
      id,
      confirm: flag
    };
    this.confirmParam.emit(param);
  }

  loadList(list: PendingReply[]): void {
    list.map(item => item.content = item.content ? item.content : '');
    this.dialogReply = new DialogReply();
    this.list = list;
  }

  replyAttachmentsChange(attachments: Material[]): void {
    this.dialogReply.attachments = attachments;
    this.saveDraft();
  }

  get canReply(): boolean {
    return !!this.dialogReply.content || !!this.dialogReply.attachments;
  }
}
