import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AccountSwitchComponent } from '../account-switch/account-switch.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Instance, InstanceAccount } from '../../api/types';
import { InstancesService } from '../../shared/service/instances.service';
import { Constant } from '../../base/constant';

@Component({
  selector: 'app-instance-code',
  templateUrl: './instance-code.component.html',
  styleUrls: ['./instance-code.component.less']
})
export class InstanceCodeComponent implements OnInit {

  code = '';
  checking = false;

  accounts: InstanceAccount[];
  instance: Instance = new Instance();

  constructor(private authService: AuthService,
              private translate: TranslateService,
              private router: Router,
              private toastr: ToastrService,
              private drawerService: NzDrawerService,
              private instancesService: InstancesService,
  ) {
  }

  ngOnInit(): void {
    this.accounts = this.authService.accounts;
    // 登录修改跳过组织码sales.hkbaylight.com
    if (window.location.href.includes('sales.hkbaylight.com')) {
      this.code = 'BIB';
      localStorage.setItem(Constant.LOCAL_STORAGE_KEY_INSTANCE, JSON.stringify({
        code: 'BIB',
        groupKey: 'baylight',
        key: 'baylight',
        logo: ''
      }));
      this.checkInstance();
    }
  }

  checkInstance(): void {
    this.checking = true;
    this.authService.checkInstance(this.code)
      .subscribe(
        data => {
          this.checking = false;
          this.authService.instance = data;
          this.instance = data;
          this.instancesService.setUp();
          this.router.navigate(['/auth/guide-gallery']).then();
        },
        error => {
          // 组织代码错误，提示错误
          this.checking = false;
        });
  }

  onAccountSwitch(): void {
    const drawerRef = this.drawerService.create<AccountSwitchComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: AccountSwitchComponent,
      nzContentParams: {
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }
}
