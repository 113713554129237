import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BasicInformation, BasicInfoSave, NewFnaDetail } from '../../new-fna-types';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { MetadataService } from '../../../shared/service/metadata.service';
import { Phone, SmokeCondition } from '../../../api/types';
import { RegionSelectionComponent } from '../../../shared/component/region-selection/region-selection.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Phones } from '../../../shared/utils/phones';
import { PhoneEditComponent } from '../../../shared/component/phone-edit/phone-edit.component';
import { TranslateService } from '@ngx-translate/core';
import { plainToInstance } from 'class-transformer';
import { Tool } from '../../../shared/utils/tool';
import { NewFnaService } from '../../new-fna.service';
import { FnaQuestion } from '../../../fna/fna-types';
import { Workbench } from '../../../workbench/workbench-types';
import { WorkbenchService } from '../../../workbench/workbench.service';

@Component({
  selector: 'app-fna-basic-info',
  templateUrl: './fna-basic-info.component.html',
  styleUrls: ['./fna-basic-info.component.less']
})
export class FnaBasicInfoComponent implements OnInit {
  loading = false;
  fnaInfo: NewFnaDetail = new NewFnaDetail();

  @Output()
  basicSaveChange: EventEmitter<any> = new EventEmitter<any>();

  basicInfo: BasicInformation = new BasicInformation();
  appellationOptions: PropertySelectOption[] = [];
  maritalOptions: PropertySelectOption[] = [];
  smokeOptions: PropertySelectOption[] = [];
  educationOptions: PropertySelectOption[] = [];
  employmentOptions: PropertySelectOption[] = [];
  intendedOptions: PropertySelectOption[] = [];
  needDependentOptions: PropertySelectOption[] = [];
  workbench: Workbench;

  constructor(private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private translate: TranslateService,
              private workbenchService: WorkbenchService,
              private newFnaService: NewFnaService,
  ) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;

    const titles = this.metadataService.values('fnaAppellation');
    for (const title of titles) {
      this.appellationOptions.push(new PropertySelectOption(title.value, title.key));
    }

    const marital = this.metadataService.values('fnaMarriage');
    for (const gender of marital) {
      this.maritalOptions.push(new PropertySelectOption(gender.value, gender.key));
    }
    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'S'), true));
    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'NS'), false));

    const educations = this.metadataService.values('fnaEducation');
    for (const education of educations) {
      this.educationOptions.push(new PropertySelectOption(education.value, education.key));
    }
    const employments = this.metadataService.values('fnaEmployment');
    for (const education of employments) {
      this.employmentOptions.push(new PropertySelectOption(education.value, education.key));
    }
    const intended = this.metadataService.values('fnaRetirement');
    for (const education of intended) {
      this.intendedOptions.push(new PropertySelectOption(education.value, education.key));
    }

    this.translate.get(['Yes', 'No']).subscribe(
      (res: any) => {
        this.needDependentOptions.push(new PropertySelectOption(res.Yes, true));
        this.needDependentOptions.push(new PropertySelectOption(res.No, false));
      });
  }

  onSave(): void {
    console.log('onSave');
    if (!this.canSave) {
      return;
    }
    const param = new BasicInfoSave();
    param.id = this.fnaInfo.id;
    param.save = true;
    param.basicInformation = JSON.parse(JSON.stringify(this.basicInfo));
    console.log(param, 'basicInformation');
    this.newFnaService.basicInfo(param)
      .subscribe(data => {
        console.log(data, 'basicInfo');
        this.basicSaveChange.emit(data);
      });
  }

  onCancel(): void {
    console.log('onCancel');
    const param = new BasicInfoSave();
    param.id = this.fnaInfo.id;
    param.save = false;
    param.basicInformation = JSON.parse(JSON.stringify(this.basicInfo));
    console.log(param, 'basicInformation');
    this.newFnaService.basicInfo(param)
      .subscribe(data => {
        console.log(data, 'basicInfo');
        this.basicSaveChange.emit(data);
      });
  }

  onCountry(): void {
    const drawerRef = this.drawerService.create<RegionSelectionComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: RegionSelectionComponent,
      nzContentParams: {},
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.regionSelected.subscribe(
        data => {
          this.basicInfo.nationality = data.countryCode;
          drawerRef.close();
        }
      );
    });
  }

  get nationalityString(): string {
    const nationality = this.metadataService.translate('countries', this.basicInfo.nationality);
    return nationality;
  }

  onMobilePhoneNumber(): void {
    this.translate.get('Mobile').subscribe(
      (title: any) => {
        const quickPhones = Phones.getFnaQuickPhones([this.basicInfo]);

        const phone = new Phone();
        if (this.basicInfo.mobile && this.basicInfo.mobile.includes(' ')) {
          phone.areaCode = this.basicInfo.mobile.split(' ')[0];
          phone.phone = this.basicInfo.mobile.split(' ')[1];
        }

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.basicInfo.mobile = data?.areaCode + ' ' + data?.phone;
            }
          );
        });
      });
  }

  onHomePhoneNumber(): void {
    this.translate.get('Home').subscribe(
      (title: any) => {
        const quickPhones = Phones.getFnaQuickPhones([this.basicInfo]);

        const phone = new Phone();
        if (this.basicInfo.homePhone && this.basicInfo.homePhone.includes(' ')) {
          phone.areaCode = this.basicInfo.homePhone.split(' ')[0];
          phone.phone = this.basicInfo.homePhone.split(' ')[1];
        }

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.basicInfo.homePhone = data?.areaCode + ' ' + data?.phone;
            }
          );
        });
      });
  }

  onOfficeTelephone(): void {
    this.translate.get('Office').subscribe(
      (title: any) => {
        const quickPhones = Phones.getFnaQuickPhones([this.basicInfo]);

        const phone = new Phone();
        if (this.basicInfo.officeTelephone && this.basicInfo.officeTelephone.includes(' ')) {
          phone.areaCode = this.basicInfo.officeTelephone.split(' ')[0];
          phone.phone = this.basicInfo.officeTelephone.split(' ')[1];
        }

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.basicInfo.officeTelephone = data?.areaCode + ' ' + data?.phone;
            }
          );
        });
      });
  }

  totalAverageMonthlyIncomes(): void { // 每月總平均收入A
    const salaryCommissionBonusAmount = this.basicInfo.salaryCommissionBonusAmount || 0;
    const rentalIncomeAmount = this.basicInfo.rentalIncomeAmount || 0;
    const otherIncomesAmount = this.basicInfo.otherIncomesAmount || 0;
    this.basicInfo.totalAverageMonthlyIncomes = Number(Tool.mathAdd(salaryCommissionBonusAmount, Tool.mathAdd(rentalIncomeAmount, otherIncomesAmount)));
    this.estimatedMonthlySurplusDeficit();
  }
  totalAverageMonthlyExpenses(): void { // 每月总平均支出B
    const personalExpensesAmount = this.basicInfo.personalExpensesAmount || 0;
    const householdExpensesAmount = this.basicInfo.householdExpensesAmount || 0;
    const mortgagesLoansRepaymentAmount = this.basicInfo.mortgagesLoansRepaymentAmount || 0;
    const insuranceAmount = this.basicInfo.insuranceAmount || 0;
    const otherExpensesAmount = this.basicInfo.otherExpensesAmount || 0;
    this.basicInfo.totalAverageMonthlyExpenses = Number(Tool.mathAdd(otherExpensesAmount, Tool.mathAdd(Tool.mathAdd(personalExpensesAmount, householdExpensesAmount), Tool.mathAdd(mortgagesLoansRepaymentAmount, insuranceAmount))));
    this.estimatedMonthlySurplusDeficit();
  }
  estimatedMonthlySurplusDeficit(): void { // 估計每月剩餘/超支【（A）-（B）】
    this.basicInfo.estimatedMonthlySurplusDeficit = Number(Tool.matchSub(this.basicInfo.totalAverageMonthlyIncomes || 0, this.basicInfo.totalAverageMonthlyExpenses || 0));
  }

  totalLiquidAssets(): void { // 流動資產總額 C
    const cashDepositAmount = this.basicInfo.cashDepositAmount || 0;
    const currentInvestmentAmount = this.basicInfo.currentInvestmentAmount || 0;
    const otherLiquidAssetsAmount = this.basicInfo.otherLiquidAssetsAmount || 0;
    this.basicInfo.totalLiquidAssets = Number(Tool.mathAdd(cashDepositAmount, Tool.mathAdd(currentInvestmentAmount, otherLiquidAssetsAmount)));
    this.estimatedNetAssets();
  }

  totalNonLiquidAssets(): void { // 非流動資產總額 D
    const propertiesAmount = this.basicInfo.propertiesAmount || 0;
    const otherNonLiquidAssetsAmount = this.basicInfo.otherNonLiquidAssetsAmount || 0;
    this.basicInfo.totalNonLiquidAssets = Number(Tool.mathAdd(propertiesAmount, otherNonLiquidAssetsAmount));
    this.estimatedNetAssets();
  }

  totalLiabilities(): void { // 負債總額 E
    const mortgageLoansAmount = this.basicInfo.mortgageLoansAmount || 0;
    const personalCreditCardLoansAmount = this.basicInfo.personalCreditCardLoansAmount || 0;
    const otherLiabilitiesAmount = this.basicInfo.otherLiabilitiesAmount || 0;
    this.basicInfo.totalLiabilities = Number(Tool.mathAdd(mortgageLoansAmount, Tool.mathAdd(personalCreditCardLoansAmount, otherLiabilitiesAmount)));
    this.estimatedNetAssets();
  }

  estimatedNetAssets(): void { // 估計淨資產（C+D-E）
    const totalLiquidAssets = this.basicInfo.totalLiquidAssets || 0;
    const totalNonLiquidAssets = this.basicInfo.totalNonLiquidAssets || 0;
    const totalLiabilities = this.basicInfo.totalLiabilities || 0;
    this.basicInfo.estimatedNetAssets = Number(Tool.matchSub(Tool.mathAdd(totalLiquidAssets, totalNonLiquidAssets), totalLiabilities));
    this.maximumAffordablePremium();
  }

  maximumAffordablePremium(): void { // 可負擔最高保費（估計淨資產除以 2）
    this.basicInfo.maximumAffordablePremium = Tool.accDiv(this.basicInfo.estimatedNetAssets || 0, 2);
  }

  get canSave(): boolean {
    if (Tool.isEmpty(this.basicInfo.englishLastName)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.englishFirstName)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.chineseFirstName)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.chineseLastName)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.appellation)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.dateOfBirth)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.nationality)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.smoke)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.countryOfBirth)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.taxResidence)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.mobile)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.homePhone)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.officeTelephone)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.maritalStatus)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.emailAddress)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.education)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.employment)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.position)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.industry)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.intendedRetirement)) {
      return false;
    }
    if (this.basicInfo?.intendedRetirement === 'OTHERS' && Tool.isEmpty(this.basicInfo.retirementAdditional)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.familySituationFatherAge)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.familySituationFatherDependents)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.familySituationMotherAge)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.familySituationMotherDependents)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.familySituationSpouseAge)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.familySituationSpouseDependents)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.familySituationChidrenTotal)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.familySituationChidrenAgeStart)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.familySituationChidrenAgeEnd)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.familySituationChidrenDependents)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.lifeInsuranceSumInsured)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.criticalIllnessInsuranceSumInsured)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.accidentalInsuranceSumInsured)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.medicalInsuranceType)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.otherExistingCoverage)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.salaryCommissionBonusAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.rentalIncomeAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.otherIncomesAdditional)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.otherIncomesAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.totalAverageMonthlyIncomes)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.personalExpensesAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.householdExpensesAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.mortgagesLoansRepaymentAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.insuranceAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.otherExpensesAdditional)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.otherExpensesAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.totalAverageMonthlyExpenses)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.estimatedMonthlySurplusDeficit)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.cashDepositAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.currentInvestmentAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.otherLiquidAssetsAdditional)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.otherLiquidAssetsAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.totalLiquidAssets)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.propertiesAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.otherNonLiquidAssetsAdditional)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.otherNonLiquidAssetsAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.totalNonLiquidAssets)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.mortgageLoansAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.personalCreditCardLoansAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.otherLiabilitiesAdditional)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.otherLiabilitiesAmount)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.totalLiabilities)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.estimatedNetAssets)) {
      return false;
    }
    if (Tool.isEmpty(this.basicInfo.maximumAffordablePremium)) {
      return false;
    }
    return true;
  }

}
