import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  bookingsReload = new Subject<void>();

  underwritingsReload = new Subject<void>();

  verifyIntercept = new Subject<void>();

  constructor() {

  }
}
