import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageMainComponent } from './message-main.component';
import { MessageComponent } from './message.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { MessagesComponent } from './messages/messages.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    MessageMainComponent,
    MessageComponent,
    MessagesComponent
  ],
  imports: [
    CommonModule,
    NzLayoutModule,
    RouterModule,
    SharedModule,
    TranslateModule,
    NzTableModule,
    NzSkeletonModule,
    NzSpinModule,
    NzGridModule,
    NzToolTipModule,
    NzButtonModule,
    NzIconModule,
    NzBadgeModule,
    NzModalModule,
    NzSwitchModule,
    FormsModule
  ]
})
export class MessageModule { }
