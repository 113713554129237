import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvitationComponent } from './invitation.component';
import { InvitationMainComponent } from './invitation-main/invitation-main.component';
import { InvitationListComponent } from './invitation-list/invitation-list.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { InvitationCreateComponent } from './invitation-create/invitation-create.component';
import { NewFnaTemplateModule } from '../new-fna-template/new-fna-template.module';

@NgModule({
  declarations: [
    InvitationComponent,
    InvitationMainComponent,
    InvitationListComponent,
    InvitationCreateComponent
  ],
  imports: [
    CommonModule,
    NzLayoutModule,
    RouterModule,
    SharedModule,
    TranslateModule,
    NzTabsModule,
    NzButtonModule,
    NzGridModule,
    NzIconModule,
    NzPaginationModule,
    NzPopoverModule,
    NzSkeletonModule,
    NzSpinModule,
    NzTableModule,
    NzToolTipModule,
    NzInputNumberModule,
    FormsModule,
    NzInputModule,
    NewFnaTemplateModule,
  ]
})
export class InvitationModule { }
