import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProposalCompareService } from '../proposal-compare.service';
import { CoverClass, CoverQuery } from '../proposal-compare';

@Component({
  selector: 'app-proposal-cover',
  templateUrl: './proposal-cover.component.html',
  styleUrls: ['./proposal-cover.component.less']
})
export class ProposalCoverComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  categoryCode: string;
  dataSource = new MyDataSource(this.proposalCompareService);
  @Output()
  confirmItem: EventEmitter<any> = new EventEmitter<any>();

  constructor(private proposalCompareService: ProposalCompareService) {
  }

  ngOnInit(): void {

    this.dataSource
      .completed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
      });
    this.dataSource.search.categoryCode = this.categoryCode;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onConfirm(item): void {
    this.confirmItem.emit(item);
  }

}

// 滾動列表
class MyDataSource extends DataSource<CoverClass | undefined> {
  private total: number;
  private fetchedPages = new Set<number>();
  private cachedData: CoverClass[] = [];
  private dataStream = new BehaviorSubject<CoverClass[]>(this.cachedData);
  private complete$ = new Subject<void>();
  private disconnect$ = new Subject<void>();
  spinning = false;

  search: CoverQuery = new CoverQuery();

  collectionViewer: CollectionViewer;

  constructor(private proposalCompareService: ProposalCompareService) {
    super();
  }

  completed(): Observable<void> {
    return this.complete$.asObservable();
  }

  reset(): void {
    this.fetchedPages = new Set<number>();
    this.cachedData = [];
    this.fetchPage();
  }

  connect(collectionViewer: CollectionViewer): Observable<(CoverClass | undefined)[]> {
    this.setup(collectionViewer);
    return this.dataStream;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.disconnect$.next();
    this.disconnect$.complete();
  }

  setup(collectionViewer: CollectionViewer): void {
    this.collectionViewer = collectionViewer;
    this.fetchPage();
    collectionViewer.viewChange.pipe(takeUntil(this.complete$), takeUntil(this.disconnect$)).subscribe(range => {
      if (this.cachedData.length >= this.total) {
        this.complete$.next();
        this.complete$.complete();
      } else {
        this.search.pageNum = this.getPageForIndex(range.end) + 1;
        this.fetchPage();
      }
    });
  }

  getPageForIndex(index: number): number {
    return Math.floor(index / this.search.pageSize);
  }

  getLength(): number {
    return this.total;
  }

  private fetchPage(): void {
    if (this.fetchedPages.has(this.search.pageNum)) {
      return;
    }
    this.spinning = true;
    this.fetchedPages.add(this.search.pageNum);
    // this.search.categoryCode = this.categoryCode;
    this.proposalCompareService.coverList(this.search).subscribe(
      res => {
        this.total = res.total;
        this.cachedData.push(...res.list);
        this.dataStream.next(this.cachedData);
        this.spinning = false;
      }
    );
  }
}

