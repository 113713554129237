import { Component, OnInit } from '@angular/core';
import { Material, Passport, TicketDetail, TicketReply, UploadTokenReq, UploadType } from '../../api/types';
import { TicketOrderDetailResp, TicketOrderReply } from '../ticket-order-types';
import { TicketOrderService } from '../ticket-order.service';
import { StorageService } from '../../shared/service/storage.service';

@Component({
  selector: 'app-ticket-order-detail',
  templateUrl: './ticket-order-detail.component.html',
  styleUrls: ['./ticket-order-detail.component.less']
})
export class TicketOrderDetailComponent implements OnInit {
  id: number;
  loading = false;
  accountInfo: Passport = new Passport();
  ticketReply: TicketOrderReply = new TicketOrderReply();
  info: TicketOrderDetailResp;
  uploadTokenReq = new UploadTokenReq();
  constructor(private ticketOrderService: TicketOrderService,
              private storageService: StorageService) { }

  ngOnInit(): void {
    this.accountInfo = this.storageService.passport;
    this.uploadTokenReq.uploadType = UploadType.TICKET;
    this.uploadTokenReq.middleName = this.id.toString();
  }

  reply(): void {
    if (!this.canReply) {
      return;
    }
    this.loading = true;
    this.ticketReply.ticketId = this.id;
    if (!this.ticketReply.files) {
      this.ticketReply.files = [];
    }
    this.ticketOrderService.reply(this.ticketReply)
      .subscribe(data => {
          console.log(data);
          this.loading = false;
          this.loadDetail(this.id);
          this.ticketReply = new TicketOrderReply();
        },
        error => {
          this.loading = false;
        }
      );

    console.log(this.ticketReply);
  }

  replyAttachmentsChange(attachments: Material[]): void {
    this.ticketReply.files = attachments;
    console.log(attachments);
  }

  get canReply(): boolean {
    if (this.loading) {
      return false;
    }
    return !!this.ticketReply.content;
  }

  loadDetail(id: number): void {
    this.loading = true;
    this.ticketOrderService.detail(id)
      .subscribe(info => {
          this.info = info;
          this.loading = false;
          console.log(this.info);
        },
        error => {
          this.loading = false;
        });
  }
}
