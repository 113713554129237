import { Pipe, PipeTransform } from '@angular/core';
import { Locale, TicketStatus } from '../../api/types';

@Pipe({
  name: 'ticketStatus'
})
export class TicketStatusPipe implements PipeTransform {

  transform(value: TicketStatus, ...args: any[]): string {
    let result = '';
    switch (value) {
      case TicketStatus.PROCESSED:
        result = 'TicketListStatusProcessed';
        break;
      case TicketStatus.PROCESSING:
        result = 'TicketListStatusProcessing';
        break;
    }
    return result;
  }

}
