import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesPactComponent } from './sales-pact.component';
import { SalesPactMainComponent } from './sales-pact-main.component';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzButtonModule} from 'ng-zorro-antd/button';
import { SalesPactTableComponent } from './sales-pact-table/sales-pact-table.component';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import { SalesPactDetailsComponent } from './sales-pact-details/sales-pact-details.component';
import { PdfListComponent } from './pdf-list/pdf-list.component';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import { SalesPrimaryContactComponent } from './sales-primary-contact/sales-primary-contact.component';
import { PrimaryContactInfoComponent } from './components/primary-contact-info/primary-contact-info.component';
import {NzModalModule} from 'ng-zorro-antd/modal';
import { CertificateUploadComponent } from './components/certificate-upload/certificate-upload.component';
import { SalesPactSignatureComponent } from './components/sales-pact-signature/sales-pact-signature.component';



@NgModule({
  declarations: [
    SalesPactComponent,
    SalesPactMainComponent,
    SalesPactTableComponent,
    SalesPactDetailsComponent,
    PdfListComponent,
    SalesPrimaryContactComponent,
    PrimaryContactInfoComponent,
    CertificateUploadComponent,
    SalesPactSignatureComponent
  ],
    imports: [
        CommonModule,
        NzLayoutModule,
        SharedModule,
        RouterModule,
        TranslateModule,
        NzIconModule,
        NzButtonModule,
        NzSpinModule,
        NzSkeletonModule,
        NzTableModule,
        NzGridModule,
        NzPopoverModule,
        NzToolTipModule,
        NzEmptyModule,
        NzModalModule
    ]
})
export class SalesPactModule { }
