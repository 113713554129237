<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 30px; ">
    <span>{{information.title}}</span>
  </div>

  <div>
    <div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start">
      <div style="font-size: 13px; color: #A6A6A6; margin: 5px 0 0 0; display: flex; flex-direction: row; align-items: center">
        <span>{{'PublishedOn' | translate}}</span>
        <span>{{information.time | date:'yyyy-MM-dd HH:mm'}}</span>
        <span class="type" style="margin-left: 10px">
          {{information.type | metadataTranslate:'informationType'}}
        </span>
        <span *ngIf="!information.effective" class="expiry" style="margin-left: 10px">
            {{'Expired' | translate}}
        </span>
      </div>

      <div style="font-size: 13px; color: #A6A6A6; margin: 5px 0 0 0; display: flex; flex-direction: row; align-items: center">
        <span>{{'InformationEffectiveTime' | translate}}：</span>
        <span>{{(information.startEffectiveDate | date:'yyyy-MM-dd') ?? '-'}}</span>
        <span>~</span>
        <span>{{(information.endEffectiveDate | date:'yyyy-MM-dd') ?? '-'}}</span>
      </div>

      <div *ngIf="information.tags" style="margin: 5px 0 0 0;">
        <div *ngIf="information.tags.length < 9;else elseTagTemplate" class="tags">
          <div class="tag-outline" *ngFor="let tag of information.tags">{{tag}}</div>
        </div>
        <ng-template #elseTagTemplate>
          <div nz-tooltip nzTooltipTitle="{{(information.tags && information.tags.join())}}" class="tags">
            <div *ngFor="let tag of information.tags let j = index">
              <div class="tag-outline" *ngIf="j < 8">{{tag}}</div>
            </div>
            <div style="padding-top: 12px;">...</div>
          </div>
        </ng-template>
      </div>
      <div *ngIf="information.productNames">
        <div *ngIf="information.productNames.length < 10;else elseTemplate" style="margin: 5px 0 0 0"
             class="tags">
          <div *ngIf="information.companyName" class="tag-info">
            {{information.companyName}}
          </div>
          <div class="tag-info" *ngFor="let productName of information.productNames">{{productName}}</div>
        </div>
        <ng-template #elseTemplate>
          <div *ngIf="!isEllipsis" style="margin: 5px 0 0 0"
               class="tags">
            <div *ngIf="information.companyName" class="tag-info">
              {{information.companyName}}
            </div>
            <div *ngFor="let productName of information.productNames; let i = index">
              <div class="tag-info" *ngIf="i < 9">{{productName}}</div>
            </div>
            <div style="margin: 2px;padding: 4px 6px;color: #1D64C4;cursor: pointer"
                 appThrottleClick (throttleClick)="isEllipsis = true">
              {{'ExpandAll' | translate }}
              <i nz-icon nzType="down" nzTheme="outline"></i>
            </div>
          </div>
          <div *ngIf="isEllipsis" style="margin: 5px 0 0 0"
               class="tags">
            <div *ngIf="information.companyName" class="tag-info">
              {{information.companyName}}
            </div>
            <div class="tag-info" *ngFor="let productName of information.productNames">{{productName}}</div>
            <div style="margin: 2px;padding: 4px 6px;color: #1D64C4;cursor: pointer" appThrottleClick (throttleClick)="isEllipsis = false">
              {{'Collapse' | translate}}
              <i nz-icon nzType="up" nzTheme="outline"></i>
            </div>
          </div>
        </ng-template>
      </div>

    </div>
  </div>

  <nz-tabset [nzSize]="'large'" [nzCentered]="true">
    <nz-tab [nzTitle]=content>
      <div [innerHTML]="information.content | html"></div>
    </nz-tab>
    <ng-template #content>
      {{'Content' | translate}}
    </ng-template>
    <nz-tab [nzTitle]=attachments>
      <app-attachments [attachments]="information.attachments" [readonly]="true"></app-attachments>
    </nz-tab>
    <ng-template #attachments>
      {{'Attachments' | translate}}
    </ng-template>
  </nz-tabset>

</div>
