import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BankInfo, DiscountInfo, DiscountInfoSearch, DiscountInfoSetting } from '../../proposal/proposal-types';
import { ProposalService } from '../../proposal/proposal.service';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-premium-financing-discount-select',
  templateUrl: './premium-financing-discount-select.component.html',
  styleUrls: ['./premium-financing-discount-select.component.less']
})
export class PremiumFinancingDiscountSelectComponent implements OnInit {

  loading = false;

  search: DiscountInfoSearch = new DiscountInfoSearch();

  discountInfoSettings: DiscountInfoSetting[] = [];

  discountInfos: DiscountInfo[] = [];

  @Output()
  discountInfoSelected: EventEmitter<DiscountInfo> = new EventEmitter<DiscountInfo>();

  searchParamChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private proposalService: ProposalService) {
  }

  ngOnInit(): void {
    this.onLoad();
    this.searchParamChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          this.onLoad();
        }
      );
  }

  onLoad(): void {
    this.loading = true;
    this.proposalService.allDiscountInfo(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.discountInfos = [];
          this.discountInfoSettings = data;
          const discountInfoSettings = data ?? [];
          for (const infoSetting of discountInfoSettings) {
            const discountInfo = instanceToInstance<DiscountInfo>(infoSetting);
            this.discountInfos.push(discountInfo);
          }
        },
        error => {
        }
      );
  }

  onSearchParamChange(): void {
    this.searchParamChange.emit('');
  }

  onDiscountInfo(info: DiscountInfo): void {
    this.discountInfoSelected.emit(info);
  }
}
