import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { ProductSelectionComponent, ProductSelectionType } from '../../shared/component/product-selection/product-selection.component';
import { SellStatus } from '../../api/types';
import { Fna, FnaProductRecommended, FnaRecommendedUpdateReq, RecommendProduct } from '../fna-types';
import { deduplication } from '../../shared/utils/collections';
import { RecommendProductItem } from '../components/fna-product-item/fna-product-item.component';
import { FnaService } from '../fna.service';
import { ProductDetailComponent } from '../../product/product-detail/product-detail.component';
import { ProductService } from '../../product/product.service';
import { Product } from '../../product/product-types';

@Component({
  selector: 'app-fna-product-selection',
  templateUrl: './fna-product-selection.component.html',
  styleUrls: ['./fna-product-selection.component.less']
})
export class FnaProductSelectionComponent implements OnInit {

  drawerRef: NzDrawerRef<FnaProductSelectionComponent, string>;

  loading = false;

  fna: Fna;

  recommended: FnaProductRecommended = new FnaProductRecommended();

  @Output()
  recommendedSaved: EventEmitter<FnaProductRecommended> = new EventEmitter<FnaProductRecommended>();

  constructor(private drawerService: NzDrawerService,
              private fnaService: FnaService,
              private productService: ProductService) {
  }

  ngOnInit(): void {

    this.fnaService.productRecommended(this.fna.id)
      .subscribe(
        data => {
          this.recommended = data;
        },
        error => {
        }
      );
  }

  onOptionalRecommendedProductSelect(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.DEFAULT
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.drawerRef = drawerRef;
      selectionComponent.search.sellStatus = SellStatus.SELLING;
      selectionComponent.productSelected
        .subscribe(
          product => {
            const recommendProduct = RecommendProduct.valueOf(product);
            this.recommended.customize.push(recommendProduct);
            this.recommended.customize = deduplication(this.recommended.customize, (v => {
              return v.productCode;
            }));
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onOptionalRecommendedProductDelete(productItem: RecommendProductItem): void {
    if (productItem.index >= 0 && this.recommended.customize.length > productItem.index) {
      this.recommended.customize.splice(productItem.index, 1);
    }
  }

  onSystemRecommendedProductDetail(productItem: RecommendProductItem): void {
    this.onProductDetail(productItem.product.productCode);
  }

  onProductDetail(productCode: string): void {
    this.loading = true;
    this.productService.detail(productCode)
      .subscribe(
        product => {

          this.loading = false;

          const drawerRef = this.drawerService.create<ProductDetailComponent, { value: Product }, string>({
            nzWidth: 800,
            nzContent: ProductDetailComponent,
            nzContentParams: {
              product
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        });
  }

  onSystemRecommendedProductTick(productItem: RecommendProductItem): void {
    if (productItem.index >= 0 && this.recommended.recommend.length > productItem.index) {
      this.recommended.recommend[productItem.index].tick = !this.recommended.recommend[productItem.index].tick;
    }
  }

  onHistoryRecommendedProductDetail(productItem: RecommendProductItem): void {
    this.onProductDetail(productItem.product.productCode);
  }

  onHistoryRecommendedProductTick(productItem: RecommendProductItem): void {
    if (productItem.index >= 0 && this.recommended.history.length > productItem.index) {
      this.recommended.history[productItem.index].tick = !this.recommended.history[productItem.index].tick;
    }
  }

  onSave(): void {
    this.loading = true;
    const updateReq = new FnaRecommendedUpdateReq();
    updateReq.id = this.fna.id;
    updateReq.customize = this.recommended.customize; // 自选产品不需要过滤tick
    updateReq.recommend = this.recommended.recommend.filter(p => {
      return p.tick === true;
    });
    updateReq.history = this.recommended.history.filter(p => {
      return p.tick === true;
    });
    this.fnaService.updateRecommended(updateReq)
      .subscribe(
        data => {
          this.loading = false;
          this.recommendedSaved.emit(this.recommended);
          this.drawerRef?.close();
        }, error => {
          this.loading = false;
        });
  }

}
