import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyDisplayMode } from '../../shared';

@Component({
  selector: 'app-property-pick',
  templateUrl: './property-pick.component.html',
  styleUrls: ['./property-pick.component.less']
})
export class PropertyPickComponent implements OnInit {

  @Input() displayMode: PropertyDisplayMode = 'horizontal';
  @Input() title: string;
  @Input() required = false;
  @Input() placeholder: string;
  @Input() bottomLineVisible = true;
  @Input() valueColor = '#333333';
  @Input() valueCursor = 'auto';
  @Input() titleColor: string;
  @Input() tip: string;
  @Input() tipColor = 'red'; // 提示文字颜色
  @Input() tipVisible = false;

  @Output()
  valueChange: EventEmitter<any | any[]> = new EventEmitter<any | any[]>();

  innerValue: any | any[];

  @Input()
  get value(): any {
    return this.innerValue;
  }

  set value(val: any | any[]) {
    this.innerValue = val;
    this.valueChange.emit(this.innerValue);
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
