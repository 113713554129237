import { Component, OnInit } from '@angular/core';
import { Workbench } from '../../workbench/workbench-types';
import { Instance } from '../../api/types';
import { DataService } from '../../shared/service/data.service';
import { WorkbenchService } from '../../workbench/workbench.service';

@Component({
  selector: 'app-bv-module-main',
  templateUrl: './bv-module-main.component.html',
  styleUrls: ['./bv-module-main.component.less']
})
export class BvModuleMainComponent implements OnInit {
  workbench: Workbench;
  tableIndex = 0;
  instance: Instance = new Instance();
  constructor(public dataService: DataService,
              private workbenchService: WorkbenchService) { }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.instance = this.workbenchService.mockInstance;
    // this.tableIndex = localStorage.getItem('NneFnaTabs') === 'SUBORDINATE' ? 1 : 0;
  }

}
