import {Component, Input, OnInit} from '@angular/core';
import {
  DataMode,
  PagedResp,
  TicketDetail,
  TicketSearch,
  TicketListResp,
  Underwriting,
  DataDesensitization, Instance
} from '../../api/types';
import {BookingService} from '../../booking/booking.service';
import {MetadataService} from '../../shared/service/metadata.service';
import {EventService} from '../../shared/service/event.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {NzModalService} from 'ng-zorro-antd/modal';
import {TicketService} from '../ticket.service';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {TicketDetailComponent} from '../ticket-detail/ticket-detail.component';
import {TicketSearchComponent} from '../ticket-search/ticket-search.component';
import {TicketCreateComponent} from '../ticket-create/ticket-create.component';
import { PolicyService } from '../../policy/policy.service';
import { PolicyDetailComponent } from '../../policy/policy-detail/policy-detail.component';
import { Policy } from '../../policy/policy-types';
import { MessageService } from '../../message/message.service';
import { UnderwritingDetailComponent } from '../../underwriting/underwriting-detail/underwriting-detail.component';
import { UnderwritingService } from '../../underwriting/underwriting.service';
import { BookingDetailComponent } from '../../booking/booking-detail/booking-detail.component';
import { Booking } from '../../booking/booking-types';
import { AccountService } from '../../account/account.service';

@Component({
  selector: 'app-ticket-table',
  templateUrl: './ticket-table.component.html',
  styleUrls: ['./ticket-table.component.less']
})
export class TicketTableComponent implements OnInit {
  statusOptions: PropertySelectOption[] = [];

  @Input()
  mode = DataMode.OWN;

  loading = false;

  spinning = false;
  instance: Instance;
  ticketTable: PagedResp<TicketListResp>;
  dataDesensitization: DataDesensitization = new DataDesensitization();

  search: TicketSearch = new TicketSearch();

  constructor(private bookingService: BookingService,
              private metadataService: MetadataService,
              private eventService: EventService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private modalService: NzModalService,
              private router: Router,
              private drawerService: NzDrawerService,
              private ticketService: TicketService,
              private accountService: AccountService,
              private policyService: PolicyService,
              private messageService: MessageService,
              private underwritingService: UnderwritingService,
  ) {
  }

  ngOnInit(): void {
    this.instance = this.metadataService.mockInstance;
    this.dataDesensitization = this.accountService.dataDesensitization;

    this.ticketService.list(this.search, this.mode === DataMode.SUBORDINATE)
      .subscribe(
        data => {
          this.loading = false;
          this.ticketTable = data;
        },
        error => {
        }
      );

    this.eventService.bookingsReload
      .subscribe(
        data => {
          this.onReload(false);
        });

  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.ticketService.list(this.search, this.mode === DataMode.SUBORDINATE)
      .subscribe(
        data => {
          this.loading = false;
          this.ticketTable = data;
        },
        error => {
          this.loading = false;
        });
  }

  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.ticketService.list(this.search, this.mode === DataMode.SUBORDINATE)
      .subscribe(
        data => {
          this.loading = false;
          this.ticketTable = data;
        },
        error => {
          this.loading = false;
        });
  }

  onTicketCreate(): void {
    const drawerRef = this.drawerService.create<TicketCreateComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: TicketCreateComponent,
      nzContentParams: {},
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.ticketCreateRes.subscribe(
        data => {
          drawerRef.close();
          this.onReload();
        }
      );
    });

    // drawerRef.afterClose.subscribe(data => {
    //   if (typeof data === 'string') {
    //   }
    // });

  }

  onTicketSearch(): void {
    const drawerRef = this.drawerService.create<TicketSearchComponent, { value: any }, string>({
      nzWidth: 800,
      nzContent: TicketSearchComponent,
      nzContentParams: {
        search: this.search
      },
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.ticketSearch
        .subscribe(
          search => {
            this.search = search;
            this.onSearch(this.search.pageNum);
            drawerRef.close();
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
      const component = drawerRef.getContentComponent();
    });
  }

  onTicketDetail(id: number): void {
    this.loading = true;
    this.ticketService.detail(id)
      .subscribe(info => {
          this.loading = false;
          const drawerRef = this.drawerService.create<TicketDetailComponent, { value: TicketDetail }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: TicketDetailComponent,
            nzContentParams: {
              info
            },
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });
          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();
            this.onReload(true);
            this.messageService.messageUnreadEvent.emit('');
          });
        },
        error => {
          this.loading = false;
        });
  }

  onPolicyDetail(policyID: string): void {
    console.log('policyID', policyID);
    this.spinning = true;
    this.policyService.detail(policyID)
      .subscribe(
        policyDetail => {

          this.spinning = false;
          const policy = policyDetail.toPolicy();

          const drawerRef = this.drawerService.create<PolicyDetailComponent, { value: Policy }, string>({
            nzWidth: 800,
            nzContent: PolicyDetailComponent,
            nzContentParams: {
              policyDetail,
              policy
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.spinning = false;
        });
  }

  onUnderwritingDetail(underWritingNo: string): void {
    this.spinning = true;
    this.underwritingService.detail(underWritingNo)
      .subscribe(
        underwritingDetail => {

          this.spinning = false;
          const underwriting = underwritingDetail.toUnderwriting();

          const drawerRef = this.drawerService.create<UnderwritingDetailComponent, { value: Underwriting }, string>({
            nzWidth: 800,
            nzContent: UnderwritingDetailComponent,
            nzContentParams: {
              underwritingDetail,
              underwriting,
              mode: this.mode,
              dataDesensitization: this.dataDesensitization
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
            this.onReload(false);
            this.messageService.messageUnreadEvent.emit('');
          });
        },
        error => {
          this.spinning = false;
        });
  }

  onBookingDetail(bookingNo: string): void {
    this.spinning = true;
    this.bookingService.detail(bookingNo)
      .subscribe(
        bookingDetail => {

          this.spinning = false;
          const booking = bookingDetail.toBooking();

          const drawerRef = this.drawerService.create<BookingDetailComponent, { value: Booking }, string>({
            nzWidth: 800,
            nzContent: BookingDetailComponent,
            nzContentParams: {
              bookingDetail,
              booking,
              mode: this.mode
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
            this.onReload(false);
            this.messageService.messageUnreadEvent.emit('');
          });
        },
        error => {
          this.spinning = false;
        });
  }


  isFone(): boolean {
    return this.ticketService.instance.isFone();
  }
}
