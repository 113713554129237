import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Policy, PolicyInfo } from '../policy-types';
import {
  AnswerInfo, ContingentOwner,
  Customer,
  CustomerCorporate,
  CustomerType, DataDesensitization, DataMode,
  HealthInfoAnswer,
  HealthType,
  HeathInfo, Instance,
  QuestionInfo
} from '../../api/types';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../shared/service/event.service';
import { CustomerReadComponent } from '../../shared/component/customer-read/customer-read.component';
import { BeneficiariesReadComponent } from '../../shared/component/beneficiaries-read/beneficiaries-read.component';
import { plainToInstance } from 'class-transformer';
import { BankDetailComponent } from '../../shared/component/bank-detail/bank-detail.component';
import { HealthInfoComponent } from '../../shared/component/health-info/health-info.component';
import { PolicyProductPlanComponent } from '../components/policy-product-plan/policy-product-plan.component';
import { PolicyService } from '../policy.service';
import { PolicyInfomationComponent } from '../components/policy-infomation/policy-infomation.component';
import { Product } from '../../product/product-types';
import {DomainMetadataReadComponent} from '../../shared/component/domain-metadata-read/domain-metadata-read.component';
import { SecondHolderReadComponent } from '../../shared/component/second-holder-read/second-holder-read.component';
import { BusinessFormReadComponent } from '../../shared/component/business-form-read/business-form-read.component';

@Component({
  selector: 'app-policy-overview',
  templateUrl: './policy-overview.component.html',
  styleUrls: ['./policy-overview.component.less']
})
export class PolicyOverviewComponent implements OnInit {
  @Input()
  policy: Policy;
  @Input()
  policyDetail: PolicyInfo;

  @Output()
  policyDetailChange: EventEmitter<PolicyInfo> = new EventEmitter<PolicyInfo>();

  product: Product;
  policyVisible = false;
  loading = false;
  remarkVisible = false;
  instance: Instance;

  heathInformation: HeathInfo;
  @Input()
  dataDesensitization: DataDesensitization = new DataDesensitization();
  @Input()
  mode = DataMode.OWN;
  constructor(private drawerService: NzDrawerService,
              private translate: TranslateService,
              private eventService: EventService,
              private policyService: PolicyService) {
  }

  ngOnInit(): void {
    this.instance = this.policyService.mockInstance;
    this.loadingHeathInformation(this.policy.policyId);
  }

  // 产品计划
  onProductPlan(): void {

    const drawerRef = this.drawerService.create<PolicyProductPlanComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: PolicyProductPlanComponent,
      nzContentParams: {
        policy: this.policy,
        product: this.product,
        mode: this.mode,
      },
    });

    drawerRef.afterOpen.subscribe(() => {

      // const component = drawerRef.getContentComponent();
      // component.productPlanSaved
      //   .subscribe(
      //     productPlanEntity => {
      //       this.underwriting = productPlanEntity.underwriting;
      //       this.product = productPlanEntity.product;
      //       this.saveProductPlan(this.underwriting, drawerRef);
      //       // drawerRef.close();
      //     });
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });

  }

  // 投保人
  onUnderwritingOwner(): void {
    this.translate.get('Holder').subscribe(
      (holder: any) => {

        const drawerRef = this.drawerService.create<CustomerReadComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: CustomerReadComponent,
          nzContentParams: {
            title: holder,
            mode: 'holder',
            sourceType: 'policy',
            customerType: this.policy.ownerType,
            customer: this.policy.owner ?? new Customer(),
            customerCorporate: this.policy.companyOwner ?? new CustomerCorporate(),
            otherCustomer: this.policy.insured,
            otherCustomerCorporate: this.policy.companyInsured,
            contingentOwner: this.policy.contingentOwner ? this.policy.contingentOwner : new ContingentOwner(),
            dataDesensitization: this.dataDesensitization,
            modeType: this.mode
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.customerSaved
            .subscribe(
              customerEntity => {
                this.policy.ownerType = customerEntity.customerType;
                switch (customerEntity.customerType) {
                  case CustomerType.INDIVIDUAL:
                    this.policy.owner = customerEntity.customer;
                    break;
                  case CustomerType.CORPORATE:
                    this.policy.companyOwner = customerEntity.corporateCustomer;
                    break;
                }
                // this.saveOwner(this.underwriting, drawerRef);
              });
        });

        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  onBookingInsured(): void {
    this.translate.get('Insured').subscribe(
      (insured: any) => {

        const drawerRef = this.drawerService.create<CustomerReadComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: CustomerReadComponent,
          nzContentParams: {
            title: insured,
            mode: 'insured',
            sourceType: 'policy',
            customerType: this.policy.insuredType,
            relationship: this.policy.relationship,
            customer: this.policy.insured ?? new Customer(),
            customerCorporate: this.policy.companyInsured ?? new CustomerCorporate(),
            otherCustomer: this.policy.owner,
            otherCustomerCorporate: this.policy.companyOwner,
            dataDesensitization: this.dataDesensitization,
            modeType: this.mode
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.customerSaved
            .subscribe(
              customerEntity => {
                this.policy.insuredType = customerEntity.customerType;
                this.policy.relationship = customerEntity.relationship;
                switch (customerEntity.customerType) {
                  case CustomerType.INDIVIDUAL:
                    this.policy.insured = customerEntity.customer;
                    break;
                  case CustomerType.CORPORATE:
                    this.policy.companyInsured = customerEntity.corporateCustomer;
                    break;
                }
              });
        });

        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });

      });
  }

  onUnderwritingBeneficiaries(): void {
    this.translate.get('Beneficiaries').subscribe(
      (title: any) => {
        const drawerRef = this.drawerService.create<BeneficiariesReadComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: BeneficiariesReadComponent,
          nzContentParams: {
            title,
            beneficiaries: this.policy.beneficiaries,
            insured: this.policy.insured,
            dataDesensitization: this.dataDesensitization,
            modeType: this.mode
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.beneficiariesSaved
            .subscribe(
              data => {
                this.policy.beneficiaries = data;
              });
        });

        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  loadBookingDetail(policyId: string): void {
    this.loading = true;
    this.policyService.detail(policyId)
      .subscribe(
        policyDetail => {
          this.loading = false;
          const policy = policyDetail.toPolicy();
          this.policyDetail = policyDetail;
          this.policyDetailChange.emit(policyDetail);
          this.policy = policy;
          // 通知列表頁面刷新
          this.eventService.bookingsReload.next();
        },
        error => {
          this.loading = false;
        });
  }

  loadingHeathInformation(policyId: string): void {
    this.loading = true;
    this.policyService.question(policyId)
      .subscribe(
        health => {
          this.loading = false;
          const that = this;
          const healthDetail = plainToInstance(HeathInfo, health, {excludeExtraneousValues: true});
          // 初始化
          that.heathInformation = new HeathInfo();
          // 基础信息
          that.heathInformation = Object.assign(that.heathInformation, {
            id: healthDetail.id,
            answerOwnerId: healthDetail.answerOwnerId,
            answerInsuredId: healthDetail.answerInsuredId,
            ownerTotal: 0,
            insuredTotal: 0
          });
          if (healthDetail.questionOwner && healthDetail.questionOwner.length > 0) {
            healthDetail.questionOwner.forEach(question => {
              that.heathInformation.ownerTotal += question.questionnaire.length;
            });
            that.heathInformation.questionOwner = that. getHealthInformationQuestion(healthDetail.questionOwner, healthDetail.answerOwner);
          }
          if (healthDetail.questionInsured && healthDetail.questionInsured.length > 0) {
            healthDetail.questionInsured.forEach((item) => {
              that.heathInformation.insuredTotal = that.heathInformation.insuredTotal + item.questionnaire.length;
            });
            // 处理受保人问题答案数据
            that.heathInformation.questionInsured = that.getHealthInformationQuestion(healthDetail.questionInsured, healthDetail.answerInsured);
          }
          console.log(this.heathInformation);
        },
        error => {
          this.loading = false;
        }
      );

  }

  onPolicyInformation(): void {
    const drawerRef = this.drawerService.create<PolicyInfomationComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: PolicyInfomationComponent,
      nzContentParams: {
        policy: this.policy,
      },
    });

    drawerRef.afterOpen.subscribe(() => {


    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });

  }

  onBankDetail(): void {
    console.log(this.policy);
    const drawerRef = this.drawerService.create<BankDetailComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: BankDetailComponent,
      nzContentParams: {
        bankInfo: this.policy.bank,
        edit: false
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.bankSaved
        .subscribe(data => {
          this.policy.bank = data;
        });

    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  close(): void {
    this.policyVisible = false;
  }

  onPolicyDelivery(): void {

    this.policyVisible = true;
  }

  onRemark(): void {
    this.remarkVisible = true;
  }

  remarkClose(): void {
    this.remarkVisible = false;
  }

  onOther(): void {
    const drawerRef = this.drawerService.create<DomainMetadataReadComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: DomainMetadataReadComponent,
      nzContentParams: {
        domainMetadata: this.policy.domainMetadataVOS,
      },
    });
  }

  onHealthInfo(): void {
    this.translate.get('HealthInformation').subscribe(
      (title: any) => {
        const healthType: HealthType = {
          ownerType: this.policy.ownerType,
          relationship: this.policy.relationship,
          edit: false
        };
        const drawerRef = this.drawerService.create<HealthInfoComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: HealthInfoComponent,
          nzContentParams: {
            heathInformation: this.heathInformation,
            healthType
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.healthSaved.subscribe(
            folist => {
              let params: HealthInfoAnswer = new HealthInfoAnswer();
              params = {
                bookingNo: undefined,
                policyNo: undefined,
                policyId: this.policy.policyId,
                underwritingNo: undefined,
                questionId: this.heathInformation.id,
                foList: folist.length > 0 ? folist : [{
                  id: this.heathInformation.answerOwnerId,
                  role: 'OWNER',
                  answer: JSON.stringify(this.heathInformation.questionOwner)
                },
                  {
                    id: this.heathInformation.answerInsuredId,
                    role: 'INSURED',
                    answer: JSON.stringify(this.heathInformation.questionInsured)
                  }
                ]
              };
              this.policyService.underwritingAnswer(params).subscribe(
                data => {
                  drawerRef.close();
                  this.loading = false;
                  this.loadBookingDetail(this.policy.policyId);
                  this.loadingHeathInformation(this.policy.policyId);
                }, error => {
                  this.loading = false;
                }
              );

            }
          );
        });


        drawerRef.afterClose.subscribe(() => {

        });
      },
      error => {
      }
    );


  }

  getHealthInformationQuestion(qusetionEntity: QuestionInfo[], answerEntity: AnswerInfo[]): QuestionInfo[] {
    const that = this;
    if (!answerEntity) {
      answerEntity = [];
    }
    let questionTotal = 0;
    // 循环问题种类
    qusetionEntity.forEach((item) => {
      // 循环具体问题
      item.questionnaire.forEach((questionnaireItem) => {
        // 记录当前问题条数
        questionnaireItem.index = ++questionTotal;
        // 查找问题数组和答案数组，根据id关联
        // const answerItem = _.find(answerEntity, function(o) {
        //   return o.questionId == questionnaireItem.questionId;
        // });

        const answerItem = answerEntity.find(o => {
          return o.questionId === questionnaireItem.questionId;
        });
        // 判空
        questionnaireItem.value = answerItem ? answerItem.value : null;
        questionnaireItem.answer.forEach((answer) => {
          if (questionnaireItem.value === answer.answerId && answer.attachment != null) {
            if (answer.attachment.type === 'form') {
              const headers = [];
              answer.attachment.headers.forEach((headerItem) => {
                // const inItem = _.find(answer.attachment.headers, function(o) {
                //   return o.attachmentId == headerItem.attachmentId;
                // });
                const inItem = answerItem.attachment.headers.find(o => {
                  return o.attachmentId === headerItem.attachmentId;
                });
                if (!!inItem) {
                  const params = that.getParams(headerItem, inItem);
                  headers.push(params);
                }
              });
              answer.attachment.headers = headers;
            } else {
              const inValue = [];
              answerItem.attachment.values.forEach((valueItem) => {
                const inValueSub = [];
                valueItem.forEach((vItem) => {
                  const headerItem = answer.attachment.headers.find(o => {
                    return o.attachmentId === vItem.attachmentId;
                  });
                  if (headerItem) {
                    const params = that.getParams(headerItem, vItem);
                    inValueSub.push(params);
                  }
                });
                inValue.push(inValueSub);
              });
              if (inValue.length < 1) {
                inValue.push(answer.attachment.headers);
              }
              answer.attachment.values = inValue;
            }
          } else {
            if (answer.attachment != null && answer.attachment.type === 'table') {
              answer.attachment.values = [];
              answer.attachment.values.push(answer.attachment.headers);
            }
          }
        });
      });
    });
    return qusetionEntity;
  }


  getParams(header, item): { inputValue: any; startTime: any; attachmentId: any; label: any; endTime: any; type: any; value: any } {
    const params = {
      attachmentId: header.attachmentId || null,
      label: header.label || null,
      value: header.value || null,
      type: header.type || null,
      required: header.required || false,
      inputValue: item.inputValue || null,
      startTime: item.startTime || null,
      endTime: item.endTime || null
    };

    // if (header.type === 'date' && header.value === 'range' && !!item.startTime && !!item.endTime) {
    //   params.inputValue = [item.startTime, item.endTime];
    // }
    return params;
  }

  get isShowBeneficiary(): boolean {
    const instance = this.policyService.mockInstance;
    return !(instance && instance.groupKey === 'fone');
  }

  onSecondHolder(): void {
    this.translate.get('SecondHolder').subscribe(
      (title: any) => {
        const drawerRef = this.drawerService.create<SecondHolderReadComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: SecondHolderReadComponent,
          nzContentParams: {
            title,
            dataDesensitization: this.dataDesensitization,
            modeType: this.mode,
            contingentOwner: this.policy.contingentOwner ? this.policy.contingentOwner : new ContingentOwner()
          },
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
        });
        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  onSecondInsured(): void {
    this.translate.get('SecondInsured').subscribe(
      (title: any) => {
        const drawerRef = this.drawerService.create<SecondHolderReadComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: SecondHolderReadComponent,
          nzContentParams: {
            title,
            dataDesensitization: this.dataDesensitization,
            modeType: this.mode,
            contingentOwner: this.policy.contingentInsured ? this.policy.contingentInsured : new ContingentOwner()
          },
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
        });
        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  openBusinessForm(type: string): void {
    const drawerRef = this.drawerService.create<BusinessFormReadComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: BusinessFormReadComponent,
      nzContentParams: {
        policyNo: this.policy.policyId,
        formType: type,
        moduleType: 'policy'
      },
    });
  }

  get isShowItem(): boolean {
    const instance = this.policyService.mockInstance;
    return (instance?.groupKey === 'cash' || instance?.groupKey === 'dev') && (this.policyDetail.ownerType !== 'CORPORATE' && this.policyDetail.insuredType !== 'CORPORATE');
  }
}
