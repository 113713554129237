import { formatDate } from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NzDateMode, SupportTimeOptions} from 'ng-zorro-antd/date-picker/standard-types';
import {PropertyDisplayMode} from '../../shared';

@Component({
  selector: 'app-property-datetime',
  templateUrl: './property-datetime.component.html',
  styleUrls: ['./property-datetime.component.less']
})
export class PropertyDatetimeComponent implements OnInit {

  @Input() displayMode: PropertyDisplayMode = 'horizontal';
  @Input() title: string;
  @Input() tip: string;
  @Input() tipVisible = false;
  @Input() borderless = true;
  @Input() disabled = false;
  @Input() required = false;
  @Input() bottomLineVisible = true;
  @Input() formatter = 'yyyy-MM-dd';
  @Input() showTime: SupportTimeOptions;
  @Input() placeholder: string;
  @Input() mode: NzDateMode = 'date';
  date: Date;

  @Output()
  valueChange: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  valueDateChange: EventEmitter<string> = new EventEmitter<string>();
  innerValue: number;

  @Input()
  get value(): number {
    return this.innerValue;
  }

  set value(val: number) {
    console.log(val);
    this.date = val ? new Date(val) : null;
    this.innerValue = val;
    this.valueChange.emit(this.innerValue);
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  onDateChange(date: Date): void {
    this.date = date;
    if (!date) {
      return;
    }
    const dateString = formatDate(date, this.formatter, 'en-US');
    const newDate = new Date(dateString.replace(/\-/g, '/'));
    const dateNew = newDate.valueOf();
    const dateUTC = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0);
    this.value = dateNew;
    // this.value = dateUTC;
  }

}
