<div class="drawer-container">
  <div class="drawer-title" style="margin-bottom: 40px; font-size: 20px;">
    {{'LeaveWordDetails' | translate}}
  </div>
  <div>
    <div style="display: flex; align-items: center; color: #404040;">
      <nz-avatar class="list-top-avatar" style="margin-right: 20px;" [nzSize]="60"
                 nzSrc="assets/images/default_avatar.png"></nz-avatar>
      <div>
        <div style="font-size: 16px">{{detailData.name || 'N/A'}}</div>
        <div><span class="textColor">{{'ContactNumber' | translate}}:</span> {{detailData.areaCode}} {{detailData.phone}} </div>
        <div><span class="textColor">{{'Email' | translate}}:</span> {{detailData.email || 'N/A'}}  </div>
      </div>
    </div>
    <nz-divider></nz-divider>
    <div style="margin-bottom: 5px">{{(detailData.createTime | date: 'yyyy-MM-dd HH:mm:ss') || 'N/A'}}</div>
    <div style="background: #d3e1fd;border-radius: 10px; padding: 10px">{{detailData.content}}</div>
  </div>
</div>
