import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.less']
})
export class InformationComponent implements OnInit {

  promotion: boolean;

  constructor(private router: ActivatedRoute,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.router
      .queryParams
      .subscribe(params => {
        this.promotion = JSON.parse(params['promotion']);
      });
  }

}
