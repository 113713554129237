<div class="drawer-container">
  <div class="drawer-title" style="margin-bottom: 30px; font-size: 20px;">
    {{'ContractList' | translate}}
  </div>
<!--  <app-water-mark [xWidth]="60" [yHeight]="50"></app-water-mark>-->

  <nz-empty *ngIf="!info || !info.length" nzNotFoundImage="simple"></nz-empty>
  <div *ngFor="let list of info; let i=index"
       style="height: 196px;">
    <nz-carousel [nzEffect]="'scrollx'" [nzDots]="infoCopy[i].length > 1" [nzEnableSwipe]="infoCopy[i].length > 1">
      <div nz-carousel-content *ngFor="let item of infoCopy[i]">
        <div class="section-title" style="margin: 20px 0px 24px">
          <span style="margin-left: 8px">{{item.contractName}}</span>
        </div>

        <div class="card" [class]="selectIndex === i ? 'select-border' : ''"
             appThrottleClick (throttleClick)="onContractSelected(list.listVO, i);$event.stopPropagation();">
          <div class="card-middle">
            <div class="cardMiddle" style="flex: 1">
              <div>
                <span style="color: #A6A6A6">{{'CalculationBasis' | translate}}：</span>
                {{item.baseOn | metadataTranslate: 'contractBaseOn'}}
              </div>
              <div>
                <span style="color: #A6A6A6">{{'EffectiveDate' | translate}}：</span>
                {{item.effectiveDate}}
                <span>~</span>
                {{item.invalidDate}}
              </div>
              <div>
                <span style="color: #A6A6A6">{{'CreateTime' | translate}}：</span>
                {{timeZone(item.createTime)}}
              </div>
<!--              <div>-->
<!--                <span style="color: #A6A6A6">{{'EndDate2' | translate}}：</span>-->
<!--              </div>-->
            </div>
            <div>
              <div class="tag-button"
                   style="margin-bottom: 5px"
                   appThrottleClick (throttleClick)="switchType(list, i); $event.stopPropagation();">
                {{item.contractType | metadataTranslate: 'contractType'}}
              </div>
<!--              <div class="tag-button pointer"-->
<!--                   *ngIf="item.promotionContractType"-->
<!--                   [style]="flags[i] ? 'background-color: #1D64C4FF;color: #FFFFFF;' : ''"-->
<!--                   appThrottleClick (throttleClick)="switchType(list, i); $event.stopPropagation();">-->
<!--                {{item.promotionContractType | metadataTranslate: 'contractType'}}-->
<!--              </div>-->
            </div>
          </div>
<!--          <div class="card-bottom">-->
<!--            <span style="color: #A6A6A6">{{'CreateTime' | translate}}：</span>-->
<!--            {{timeZone(item.createTime)}}-->
<!--          </div>-->
        </div>
      </div>
    </nz-carousel>
  </div>
</div>
