import {Expose} from 'class-transformer';

export class LeaveWordListRes {
  @Expose() id: number; // 主键id
  @Expose() name: string; // 姓名/姓名拼音
  @Expose() areaCode: string; // 区号
  @Expose() phone: string; // 电话
  @Expose() email: string; // 邮箱
  @Expose() Status: string; // 状态 READ, UNREAD
  @Expose() avatar: string; // 头像
  @Expose() createTime: string; // 创建时间
}

export class LeaveWordDetailsRes {
  @Expose() id: number; // 主键id
  @Expose() name: string; // 姓名/姓名拼音
  @Expose() areaCode: string; // 区号
  @Expose() phone: string; // 电话
  @Expose() email: string; // 邮箱
  @Expose() content: string; // 内容
  @Expose() Status: string; // 状态 READ, UNREAD
  @Expose() avatar: string; // 头像
  @Expose() createTime: string; // 创建时间
}
