<div class="ratings">
  <div class="ratings-rating">
    <span>{{creditRating.rating}}</span>
  </div>
  <div class="ratings-desc">
    <div class="ratings-agency">
      <span>{{creditRating.ratingAgency}}</span>
    </div>
    <div class="ratings-project">
      <span>{{creditRating.project}}</span>
    </div>
    <div class="ratings-time">
      <span>{{creditRating.time | date:'yyyy-MM-dd' }}</span>
    </div>
  </div>
  <div class="ratings-file-actions">
  </div>
</div>
