import { Component, OnInit } from '@angular/core';
import {ContactService} from '../../../contact/contact.service';

@Component({
  selector: 'app-qrcode-drawer',
  templateUrl: './qrcode-drawer.component.html',
  styleUrls: ['./qrcode-drawer.component.less']
})
export class QrcodeDrawerComponent implements OnInit {
  codeImgUrl: string;

  constructor(private contactService: ContactService) { }

  ngOnInit(): void {
    this.onCode();
  }

  onCode(): void {
    this.contactService.qrCode()
      .subscribe(
        data => {
          this.codeImgUrl = data;
        },
        error => {
          console.log(error);
        }
      );
  }
}
