<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div class="quotations-box" style="margin-top: 50px">
      <div class="box-header">
        <div class="header-text">
          <div>
            <div style="font-size: 20px;color: #1D64C4;font-weight: 600;">{{'Premiums' | translate}}</div>
            <div style="color: #999999; margin-top: 5px">
              {{'PremiumsTips' | translate}}
              <span style="color: #1d64c4">({{ 'DataVersion' | translate }}：{{edition || 'N/A'}})</span>
            </div>
          </div>
          <div style="font-size: 32px;color: #1D64C4;font-weight: 600;"><span style="font-size: 28px">$</span>{{calculationRes || '- -'}}</div>
        </div>

        <button nz-button nzType="default"
                nz-tooltip [nzTooltipTitle]="'Reset' | translate"
                appThrottleClick (throttleClick)="onReload()"
                nzSize="large" nzShape="circle" style="align-self: center; margin-left: 60px;">
          <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
        </button>
      </div>

      <div class="box-center">
        <nz-row [nzGutter]="24">
          <nz-col [nzSpan]="8">
            <!-- 产品 -->
            <app-property-pick [title]="'InsuranceProduct' | translate"
                               [displayMode]="'vertical'"
                               [required]="true"
                               appThrottleClick (throttleClick)="onProductSelection()"
                               [(value)]="product.productName"></app-property-pick>
          </nz-col>
        </nz-row>
        <nz-row [nzGutter]="24">
          <nz-col [nzSpan]="8">
            <!-- 年龄 -->
            <app-property-input-number [displayMode]="'vertical'"
                                       [title]="'Age' | translate"
                                       [tip]="'PleaseSelectProductFirst' | translate"
                                       [tipVisible]="product.companyCode === undefined"
                                       [placeholder]="('PleaseEnter' | translate) + ('Age' | translate)"
                                       [disabled]="product.companyCode === undefined"
                                       [required]="true"
                                       [(value)]="premium.age"></app-property-input-number>
            <div *ngIf="product.companyCode !== undefined" class="restrictionsTip">
              <span>{{'MinimumAge' | translate}}</span> {{ageMin}},<span>{{'MaximumAge' | translate}}</span> {{ageMax}}
            </div>
          </nz-col>
          <nz-col [nzSpan]="8">
            <!-- 性别 -->
            <app-property-select [title]="'Gender' | translate"
                                 [displayMode]="'vertical'"
                                 [tip]="'PleaseSelectProductFirst' | translate"
                                 [tipVisible]="product.companyCode === undefined"
                                 [disabled]="product.companyCode === undefined"
                                 [placeholder]="('PleaseSelect' | translate) + ('Gender' | translate)"
                                 [options]="genderOptions"
                                 [required]="getRequirement('Gender')"
                                 [(value)]="premium.gender"></app-property-select>
          </nz-col>
          <nz-col [nzSpan]="8">
            <!-- 是否吸烟 -->
            <app-property-select [title]="'SmokeCondition' | translate"
                                 [displayMode]="'vertical'"
                                 [tip]="'PleaseSelectProductFirst' | translate"
                                 [tipVisible]="product.companyCode === undefined"
                                 [disabled]="product.companyCode === undefined"
                                 [placeholder]="('PleaseSelect' | translate) + ('SmokeCondition' | translate)"
                                 [options]="smokeOptions"
                                 [required]="getRequirement('SmokeCondition')"
                                 [(value)]="premium.smokeCondition"></app-property-select>
          </nz-col>
        </nz-row>
        <nz-row [nzGutter]="24">
          <nz-col [nzSpan]="8">
            <!-- 货币 -->
            <app-property-select [title]="'Currency' | translate"
                                 [displayMode]="'vertical'"
                                 [tip]="'PleaseSelectProductFirst' | translate"
                                 [tipVisible]="product.companyCode === undefined"
                                 [disabled]="product.companyCode === undefined"
                                 [placeholder]="('PleaseSelect' | translate) + ('Currency' | translate)"
                                 [required]="getRequirement('Currency')"
                                 [options]="currencyOptions"
                                 [(value)]="premium.currency" (valueChange)="onCurrencyChange($event)"></app-property-select>
          </nz-col>
          <nz-col [nzSpan]="8">
            <!-- 供款年期 -->
            <app-property-select [title]="'PaymentTerm' | translate"
                                 [displayMode]="'vertical'"
                                 [tip]="'PleaseSelectProductFirst' | translate"
                                 [tipVisible]="product.companyCode === undefined"
                                 [disabled]="product.companyCode === undefined"
                                 [placeholder]="('PleaseSelect' | translate) + ('PaymentTerm' | translate)"
                                 [required]="getRequirement('PremiumTerm')"
                                 [options]="paymentTermOptions"
                                 [(value)]="premium.premiumTerm"></app-property-select>
          </nz-col>
          <nz-col [nzSpan]="8" *ngIf="product.categoryCode === 'MEDICAL'">
            <!-- 医疗险等级 -->
            <app-property-select [title]="'MedicalLevel' | translate"
                                 [displayMode]="'vertical'"
                                 [placeholder]="('PleaseSelect' | translate) + ('MedicalLevel' | translate)"
                                 [options]="medicalLevelOptions"
                                 [required]="getRequirement('MedicalLevel')"
                                 [(value)]="premium.level"></app-property-select>
          </nz-col>
        </nz-row>
        <nz-row [nzGutter]="24">
          <nz-col [nzSpan]="8" *ngIf="product.categoryCode === 'MEDICAL'">
            <!-- 自付額 -->
            <app-property-select [title]="'Deductible' | translate"
                                 [displayMode]="'vertical'"
                                 [required]="getRequirement('MedicalDeductible')"
                                 [placeholder]="('PleaseSelect' | translate) + ('Deductible' | translate)"
                                 [options]="deductibleOptions"
                                 [(value)]="premium.deductible"></app-property-select>
          </nz-col>
          <nz-col [nzSpan]="8" *ngIf="product.categoryCode === 'MEDICAL'">
            <!-- 保障地區 -->
            <app-property-select [title]="'GuaranteedArea' | translate"
                                 [displayMode]="'vertical'"
                                 [required]="getRequirement('MedicalRegion')"
                                 [placeholder]="('PleaseSelect' | translate) + ('GuaranteedArea' | translate)"
                                 [options]="guaranteedAreaOptions"
                                 [(value)]="premium.region"></app-property-select>
          </nz-col>
          <nz-col [nzSpan]="8" *ngIf="product.categoryCode === 'MEDICAL'">
            <!-- 附加保障 -->
            <app-property-select [title]="'SupplementaryBenefits' | translate"
                                 [displayMode]="'vertical'"
                                 [mode]="'multiple'"
                                 [placeholder]="('PleaseSelect' | translate) + ('SupplementaryBenefits' | translate)"
                                 [options]="supplementaryBenefitOptions"
                                 [(value)]="premium.supplementaryBenefits"></app-property-select>
          </nz-col>
        </nz-row>
        <!--          <nz-col [nzSpan]="8" *ngIf="false">-->
<!--            <div style="display: flex;height: 100%;align-items: center">-->
<!--              <button [disabled]="!canQuote" style="height: 50%; display: flex; align-items: center;" nz-button nzType="default"-->
<!--                      appThrottleClick (throttleClick)="onCalculation()">{{'Quotation' | translate}}-->
<!--              </button>-->
<!--            </div>-->
<!--          </nz-col>-->
      </div>
      <div class="box-foot">
        <div style="display: grid;grid-template-columns: 320px auto;">
          <div *ngIf="product.categoryCode !== 'MEDICAL'" style="margin-right: 20px">
            <!--保額-->
            <app-property-input-number [title]="'SumAssured' | translate"
                                       [displayMode]="'horizontal'"
                                       [placeholder]="'SumAssured' | translate"
                                       [(value)]="premium.amount"
                                       (valueChange)="amountChange()"></app-property-input-number>
            <div *ngIf="product.companyCode !== undefined" class="restrictionsTip">
              <span>{{'MinimumSumAssured' | translate}}</span> {{sumInsuredMin}},
              <span>{{'MaximumSumAssured' | translate}}</span> {{sumInsuredMax}}
            </div>
          </div>
          <div *ngIf="product.categoryCode !== 'MEDICAL'">
            <div style="margin-top: 40px">
              <nz-slider [(ngModel)]="premium.amount" [nzMax]="sumInsuredMax" [nzMin]="sumInsuredMin" (nzOnAfterChange)="onAmountChange($event)"></nz-slider>
            </div>
          </div>
          <div *ngIf="product.categoryCode === 'MEDICAL'" style="margin-right: 20px">
            <!--结束年龄-->
            <app-property-text [title]="'AgeTo' | translate"
                               [displayMode]="'horizontal'"
                               [value]="premium.ageTo"></app-property-text>
            <div *ngIf="product.companyCode !== undefined" class="restrictionsTip">
              <span>{{'MinimumEndAge' | translate}}</span> {{premium.age ?? 0}},
              <span>{{'MaximumEndAge' | translate}}</span> {{ageMax}}
            </div>
          </div>
          <div *ngIf="product.categoryCode === 'MEDICAL'">
            <div style="margin-top: 40px">
              <nz-slider [(ngModel)]="premium.ageTo" [nzMax]="ageMax" [nzMin]="premium.age"
                         (nzOnAfterChange)="onAgeToChange($event)"></nz-slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-spin>

</div>
