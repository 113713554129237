import { Component, EventEmitter, OnInit, TemplateRef } from '@angular/core';
import { Material, PagedResp } from '../../api/types';
import { PremiumFinancing, PremiumFinancingSearch } from '../premium-financing-types';
import { PremiumFinancingService } from '../premium-financing.service';
import { StorageService } from '../../shared/service/storage.service';
import { DownloadService } from '../../shared/service/download.service';
import { ProposalCustomCreateComponent } from '../../proposal-custom/proposal-custom-create/proposal-custom-create.component';
import { ProposalSearch } from '../../proposal/proposal-types';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { PremiumFinancingGenerateComponent } from '../premium-financing-generate/premium-financing-generate.component';

@Component({
  selector: 'app-premium-financing-list',
  templateUrl: './premium-financing-list.component.html',
  styleUrls: ['./premium-financing-list.component.less']
})
export class PremiumFinancingListComponent implements OnInit {

  spinning = false;
  listArr: PagedResp<PremiumFinancing>;
  search: PremiumFinancingSearch = new PremiumFinancingSearch();
  searchPopoverVisible = false;

  constructor(private premiumFinancingService: PremiumFinancingService,
              private storageService: StorageService,
              private downloadService: DownloadService,
              private drawerService: NzDrawerService
  ) {
  }

  ngOnInit(): void {
    this.onSearch(0);
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.spinning = true;
    this.premiumFinancingService.list(this.search).subscribe(
      data => {
        data.list.forEach(e => {
        });
        this.listArr = data;
        this.spinning = false;
        console.log(this.listArr);
      }, error => {
        this.spinning = false;
      }
    );
  }

  onReload(): void {
    this.search = new PremiumFinancingSearch();
    this.spinning = true;
    this.premiumFinancingService.list(this.search).subscribe(
      data => {
        this.listArr = data;
        this.spinning = false;
      }, e => {
        this.spinning = false;
      }
    );
  }

  onCreate(): void {
    const drawerRef = this.drawerService.create<PremiumFinancingGenerateComponent, { value: string }, string>({
      nzWidth: 1000,
      nzContent: PremiumFinancingGenerateComponent,
      nzContentParams: {}
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.premiumFinancingGenerated.subscribe(data => {
        this.onReload();
        drawerRef.close();
      });
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onDetail(premiumFinancing: PremiumFinancing): void {

  }

  onDownload(url: Material): void {
    this.storageService.accessToken(url.filePath)
      .subscribe(
        data => {
          this.downloadService.download(data.accessToken, url.fileName);
        },
        err => {
        });
  }

  onDelete(item: PremiumFinancing, tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
  }
}
