import { Injectable } from '@angular/core';
import {BaseService} from '../base/base.service';
import {HttpService} from '../core/http.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResponse} from '../api/types';
import {API} from '../api/api';
import {map} from 'rxjs/operators';
import {Calculate, CalculateRes} from '../commission/commission-types';

@Injectable({
  providedIn: 'root'
})
export class CommissionForecastService extends BaseService {

  constructor(private http: HttpService,
              private httpClient: HttpClient) {
    super();
  }

  /**
   * 计算我的介绍费
   * @param param 参数
   */
  calculate(param: Calculate): Observable<CalculateRes[]> {
    let httpParams = new HttpParams();
    if (param.productCode != null) {
      httpParams = httpParams.set('productCode', param.productCode.toString());
    }
    if (param.paymentTerm != null) {
      httpParams = httpParams.set('paymentTerm', param.paymentTerm.toString());
    }
    if (param.premium != null) {
      httpParams = httpParams.set('premium', param.premium.toString());
    }
    if (param.age != null) {
      httpParams = httpParams.set('age', param.age.toString());
    }
    if (param.currency != null) {
      httpParams = httpParams.set('currency', param.currency.toString());
    }
    return this.http.get<ApiResponse<CalculateRes[]>>(API.commissionV3 + '/forecast/calculate', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
