<nz-modal [nzVisible]="versionContent.flag" nzClosable="false" [nzCentered]="true" [nzFooter]="null">

  <div *nzModalContent>

    <div class="title">
      <span>{{'版本' + versionContent.version + '更新內容'}}</span>
    </div>

    <div *ngFor="let item of versionContent.text; let i = index">
      <div style="color: #666666; margin: 5px 0 0 0">{{item}}</div>
    </div>
    <div *ngIf="!versionContent.text.length">
      <nz-empty nzNotFoundImage="simple"></nz-empty>
    </div>

    <div nz-row style="width: 100%; height: 100%; margin: 40px 0 0 0">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" (click)="handleOk()">
          <span>{{'Confirm' | translate}}</span>
        </a>
      </div>
    </div>

  </div>
</nz-modal>
