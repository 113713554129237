import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TicketComponent} from './ticket.component';
import {TicketMainComponent} from './ticket-main.component';
import {TicketTableComponent} from './ticket-table/ticket-table.component';
import {TicketDetailComponent} from './ticket-detail/ticket-detail.component';
import { RouterModule } from '@angular/router';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzTableModule} from 'ng-zorro-antd/table';
import {SharedModule} from '../shared/shared.module';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {TranslateModule} from '@ngx-translate/core';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzListModule} from 'ng-zorro-antd/list';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import { TicketSearchComponent } from './ticket-search/ticket-search.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { TicketCreateComponent } from './ticket-create/ticket-create.component';
import { NzTagModule } from 'ng-zorro-antd/tag';



@NgModule({
  declarations: [
    TicketComponent,
    TicketMainComponent,
    TicketTableComponent,
    TicketDetailComponent,
    TicketSearchComponent,
    TicketCreateComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        NzLayoutModule,
        TranslateModule,
        SharedModule,
        NzTabsModule,
        NzTableModule,
        NzSpinModule,
        NzSkeletonModule,
        NzGridModule,
        NzToolTipModule,
        NzBadgeModule,
        NzButtonModule,
        NzIconModule,
        NzListModule,
        FormsModule,
        NzAvatarModule,
        NzUploadModule,
        NzFormModule,
        ReactiveFormsModule,
        NzAlertModule,
        NzInputModule,
        NzTagModule
    ]
})
export class TicketModule { }
