import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecommendProduct } from '../../fna-types';

export class RecommendProductItem {
  index: number;
  product: RecommendProduct;
}

@Component({
  selector: 'app-fna-product-item',
  templateUrl: './fna-product-item.component.html',
  styleUrls: ['./fna-product-item.component.less']
})
export class FnaProductItemComponent implements OnInit {

  @Input()
  product: RecommendProduct;

  @Input()
  index: number;

  @Input()
  showDetailButton = false;

  @Input()
  showEditButton = false;

  @Input()
  showDeleteButton = false;

  @Output()
  tickEvent: EventEmitter<RecommendProductItem> = new EventEmitter<RecommendProductItem>();

  @Output()
  detailEvent: EventEmitter<RecommendProductItem> = new EventEmitter<RecommendProductItem>();

  @Output()
  deleteEvent: EventEmitter<RecommendProductItem> = new EventEmitter<RecommendProductItem>();

  @Output()
  editEvent: EventEmitter<RecommendProductItem> = new EventEmitter<RecommendProductItem>();

  constructor() { }

  ngOnInit(): void {
  }

  onTick(): void {
    const item = new RecommendProductItem();
    item.product = this.product;
    item.index = this.index;
    this.tickEvent.emit(item);
  }

  onDetail(): void {
    const item = new RecommendProductItem();
    item.product = this.product;
    item.index = this.index;
    this.detailEvent.emit(item);
  }

  onDelete(): void {
    const item = new RecommendProductItem();
    item.product = this.product;
    item.index = this.index;
    this.deleteEvent.emit(item);
  }

  onEdit(): void {
    const item = new RecommendProductItem();
    item.product = this.product;
    item.index = this.index;
    this.editEvent.emit(item);
  }

}
