import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractComponent } from './contract.component';
import { ContractMainComponent } from './contract-main/contract-main.component';
import { ContractListComponent } from './contract-list/contract-list.component';
import { ContractDetailComponent } from './contract-detail/contract-detail.component';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzPopoverModule} from 'ng-zorro-antd/popover';



@NgModule({
  declarations: [
    ContractComponent,
    ContractMainComponent,
    ContractListComponent,
    ContractDetailComponent
  ],
  imports: [
    CommonModule,
    NzLayoutModule,
    SharedModule,
    RouterModule,
    TranslateModule,
    NzSpinModule,
    NzSkeletonModule,
    NzTableModule,
    NzGridModule,
    NzToolTipModule,
    NzIconModule,
    NzButtonModule,
    NzPopoverModule
  ]
})
export class ContractModule { }
