import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PropertyDisplayMode} from '../../shared';
import {SupportTimeOptions} from 'ng-zorro-antd/date-picker/standard-types';

@Component({
  selector: 'app-property-datetime-range',
  templateUrl: './property-datetime-range.component.html',
  styleUrls: ['./property-datetime-range.component.less']
})
export class PropertyDatetimeRangeComponent implements OnInit {


  @Input() displayMode: PropertyDisplayMode = 'horizontal';
  @Input() title: string;
  @Input() tip: string;
  @Input() tipVisible = false;
  @Input() borderless = true;
  @Input() disabled = false;
  @Input() required = false;
  @Input() bottomLineVisible = true;
  @Input() formatter = 'yyyy-MM-dd';
  @Input() showTime: SupportTimeOptions | boolean;

  date: Date[];

  @Output()
  valueChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  innerValue: number[];

  @Input()
  get value(): number[] {
    return this.innerValue;
  }

  set value(val: number[]) {
    const date1 = val && val[0] ? new Date(val[0]) : null;
    const date2 = val && val[1] ? new Date(val[1]) : null;
    this.date = val ? [date1, date2] : null;
    this.innerValue = val;
    this.valueChange.emit(this.innerValue);
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  onDateChange(date: Date[]): void {
    this.date = date;
    // const dateString = formatDate(date, this.formatter, 'en-US');
    // const newDate = new Date(dateString);
    // this.value = newDate.valueOf();
    this.value = date && date.length > 0 ? [date[0]?.valueOf(), date[1]?.valueOf()] : null;
  }

}
