import { Expose } from 'class-transformer';
import { PagedReq } from '../api/types';

export class KnowledgeType {
  @Expose() id: number; // 类型id
  @Expose() name: string; // 排序值
  @Expose() rank: number; // 类型名
}

export class KnowledgeListResp {
  @Expose() id: number; // 知识库id
  @Expose() createTime: number; // 创建时间
  @Expose() updateTime: number; // 更新时间
  @Expose() typeId: number; // 知识类型id
  @Expose() title: string; // 标题
  @Expose() content: string; // 内容
  @Expose() mediaType: string; // 媒體類型 - HTML | VIDEO | AUDIO
  @Expose() cover: string; // 封面
  @Expose() tags: string[]; // 标签
  @Expose() companyCode: string; // 保险公司码
  @Expose() productCode: string; // 产品码
  @Expose() productName: string; // 产品名
  @Expose() visits: number; // 访问次数
  @Expose() issueDate: number; // 发布时间
  @Expose() author: number; // 作者
}

export class KnowledgeSearch extends PagedReq {
  @Expose() typeId: number; // 知识类型id
  @Expose() companyCode: string; // 保险公司码
  @Expose() keyword: string; // 关键字
  @Expose() hottest: boolean; // 是否返回最热知识列表
}

export class Knowledge {
  @Expose() id: number; // 知识库id
  @Expose() createTime: number; // 创建时间
  @Expose() updateTime: number; // 更新时间
  @Expose() typeId: number; // 知识类型id
  @Expose() title: string; // 标题
  @Expose() content: string; // 内容
  @Expose() mediaType: string; // 媒體類型 - HTML | VIDEO | AUDIO
  @Expose() cover: string; // 封面
  @Expose() tags: string[]; // 标签
  @Expose() companyCode: string; // 保险公司码
  @Expose() productCode: string; // 产品码
  @Expose() productName: string; // 产品名
  @Expose() visits: number; // 访问次数
  @Expose() issueDate: number; // 发布时间
  @Expose() author: number; // 作者
}
