<nz-tabset [nzSize]="'large'" [nzSelectedIndex]="tabIndex">
  <nz-tab *ngIf="isProductEnabled()" [nzTitle]=insuranceProduct>
    <app-products></app-products>
  </nz-tab>
  <ng-template #insuranceProduct>
    {{'InsuranceProduct' | translate}}
  </ng-template>
  <nz-tab *ngIf="isCompanyEnabled()" [nzTitle]=insurer>
    <app-companies></app-companies>
  </nz-tab>
  <ng-template #insurer>
    {{'Insurer' | translate}}
  </ng-template>
</nz-tabset>
