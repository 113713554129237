import { Component, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ProductSelectionComponent, ProductSelectionType } from '../../shared/component/product-selection/product-selection.component';
import { ProductMultiSelectionComponent } from '../../shared/component/product-multi-selection/product-multi-selection.component';
import { deduplication } from '../../shared/utils/collections';
import { ProductComparisonResultComponent } from '../product-comparison-result/product-comparison-result.component';
import { BaseComponent } from '../../base/base.component';
import { CompanyHistoryRes, CompanySearch, Product, ProductCompany } from '../product-types';
import { Router } from '@angular/router';
import { PagedReq, PagedResp } from '../../api/types';
import { ProductService } from '../product.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../shared/service/storage.service';
import { DownloadService } from '../../shared/service/download.service';
import { ProposalCompare } from '../../proposal-compare/proposal-compare';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ToastrService } from 'ngx-toastr';
import {
  ProposalCompareDetailComponent
} from '../../proposal-compare/proposal-compare-detail/proposal-compare-detail.component';
import { ProductComparisonDetailComponent } from '../product-comparison-detail/product-comparison-detail.component';

@Component({
  selector: 'app-product-comparison',
  templateUrl: './product-comparison.component.html',
  styleUrls: ['./product-comparison.component.less']
})
export class ProductComparisonComponent extends BaseComponent implements OnInit {

  primaryProduct: Product;

  secondaryProducts: Product[] = [];

  search: PagedReq = new PagedReq();

  companyHistoryData: PagedResp<CompanyHistoryRes>;

  spinning = false;

  deleting = false;

  products: Product[] = [];

  companies: ProductCompany[] = [];

  constructor(private router: Router,
              private drawerService: NzDrawerService,
              private translate: TranslateService,
              private storageService: StorageService,
              private downloadService: DownloadService,
              private toastr: ToastrService,
              private modalService: NzModalService,
              private productService: ProductService) {
    super();
    const state = this.router.getCurrentNavigation()?.extras?.state;
    if (!!state && state.product) {
      this.primaryProduct = state.product;
    }
  }

  ngOnInit(): void {
    this.onReload();
    this.getCompareData();
  }

  onSelectPrimaryProduct(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzMask: true,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.COMPARISON,
        copyProduct: this.primaryProduct
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.primaryProduct = product;
            drawerRef.close();
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onClearPrimaryProduct(): void {
    this.primaryProduct = null;
    this.resetSecondaryProducts();
  }

  onClearSecondaryProducts(): void {
    this.resetSecondaryProducts();
  }

  resetSecondaryProducts(): void {
    this.secondaryProducts = [];
  }

  onAddSecondaryProducts(): void {
    const drawerRef = this.drawerService.create<ProductMultiSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductMultiSelectionComponent,
      nzMask: true,
      nzContentParams: {
        type: ProductSelectionType.COMPARISON,
        hideCategory: true,
        selectedProducts: this.secondaryProducts
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.drawerRef = drawerRef;
      selectionComponent.search.categoryCode = this.primaryProduct.categoryCode;
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.secondaryProducts.push(product);
            this.secondaryProducts = deduplication(this.secondaryProducts, v => {
              return v.productCode;
            });
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onSecondaryProductDelete(product: Product, i: number): void {
    this.secondaryProducts.splice(i, 1);
  }

  get canCompare(): boolean {
    if (!this.primaryProduct) {
      return false;
    }
    if (!this.secondaryProducts) {
      return false;
    }
    if (this.secondaryProducts.length <= 0) {
      return false;
    }
    return true;
  }

  onCompare(): void {
    const drawerRef = this.drawerService.create<ProductComparisonResultComponent, { value: string }, string>({
      nzWidth: '100vw',
      nzContent: ProductComparisonResultComponent,
      nzContentParams: {
        primaryProduct: this.primaryProduct,
        secondaryProducts: this.secondaryProducts
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
    });

    drawerRef.afterClose.subscribe(data => {
      this.onReload(true);
    });
  }

  onReload(flag: boolean = false): void {
    if (flag) {
      this.search.pageNum = 1;
    }
    this.spinning = true;
    this.productService.companyHistory(this.search)
      .subscribe(
        data => {
          data.list.forEach(e => {
            e.expand = false;
          });
          console.log(data.list);
          this.spinning = false;
          this.companyHistoryData = data;
        },
        error => {
          this.spinning = false;
        }
      );
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.onReload();
  }

  onDownload(type: any): void {
    this.storageService.accessToken(type.filePath)
      .subscribe(
        data => {
          // console.log(data);
          this.downloadService.download(data.accessToken, type.fileName);
        },
        err => {
        });
  }

  onDelete(contacts: any, tplTitle: any, tplContent: any, tplFooter: any): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: contacts,
      nzOnOk: () => {

      }
    });

  }

  onDeleteConfirm(ref: any, contacts: any): void {
    this.productService.delete(contacts.id).subscribe(
      data => {
        if (data) {
          this.translate.get('DeleteSuccess').subscribe(
            (res) => {
              this.toastr.success(res);
            }
          );
        }
        this.onReload();
        ref.close();
      },
      e => {
        ref.close();
      }
    );
  }

  getCompareData(): void {
    this.productService.companyView()
      .subscribe(
        data => {
          this.products = JSON.parse(JSON.stringify(data.products));
          this.companies = JSON.parse(JSON.stringify(data.companies));
        }
      );
  }

  onDetail(id: number): void {

    this.productService.companyHistoryDetail(id).subscribe(companyDetail => {
      console.log(companyDetail);
      const drawerRef = this.drawerService.create<ProductComparisonDetailComponent, {}, string>({
        nzWidth: 800,
        nzContent: ProductComparisonDetailComponent,
        nzContentParams: {
          companyDetail
        }
      });
      drawerRef.afterOpen.subscribe(() => {
        const component = drawerRef.getContentComponent();
      });

      drawerRef.afterClose.subscribe(data => {
        if (typeof data === 'string') {
        }
      });
    });

  }

  productIntro(str): Product {
    let res: any;
    if (this.products.length) {
      res = this.products.find(item => item.productCode === str);
    }
    return res;
  }

  companieInfo(code): string {
    let res: any;
    let companyCode: any;
    if (this.products.length) {
      companyCode = this.products.find(item => item.productCode === code)?.companyCode;
    }
    if (this.companies.length && companyCode) {
      res = this.companies.find(item => item.code === companyCode)?.name;
    }
    return res;
  }
}
