import { Component, Input, OnInit } from '@angular/core';
import { DataMode, PagedResp } from '../../api/types';
import { NewSaleReportReq, SaleListReq, SaleReportListItem } from '../sale-report-type';
import { SaleReportService } from '../sale-report.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { SaleReportListComponent } from '../sale-report-list/sale-report-list.component';
import { TicketSearchComponent } from '../../ticket/ticket-search/ticket-search.component';
import { SaleReportSearchComponent } from '../sale-report-search/sale-report-search.component';

@Component({
  selector: 'app-sale-report-table',
  templateUrl: './sale-report-table.component.html',
  styleUrls: ['./sale-report-table.component.less']
})
export class SaleReportTableComponent implements OnInit {
  @Input()
  mode = 'self';
  loading = false;
  spinning = false;
  search: NewSaleReportReq = new NewSaleReportReq();
  listArr: PagedResp<SaleReportListItem>;

  constructor(private saleReportService: SaleReportService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
    this.search.module = 'POLICY';
    this.newGetList();
  }


  newGetList(): void {
    this.loading = true;
    this.search.type = this.mode;
    this.saleReportService.newList(this.search)
      .subscribe(
        data => {
          this.listArr = JSON.parse(JSON.stringify(data));
          console.log(this.listArr, 'this.listShareArr');
          this.loading = false;
        }, () => {
          this.loading = false;
        }
      );
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.search.type = this.mode;
    this.newGetList();
  }

  onFilterate(): void {
    const drawerRef = this.drawerService.create<SaleReportSearchComponent, { value: any }, string>({
      nzWidth: 800,
      nzContent: SaleReportSearchComponent,
      nzContentParams: {
        search: this.search
      },
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.saleReportSearch
        .subscribe(
          search => {
            this.search = search;
            this.onSearch(this.search.pageNum);
            drawerRef.close();
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
      const component = drawerRef.getContentComponent();
    });
  }


  onReload(resetPageNum = true): void {
    this.loading = true;
    this.search.type = this.mode;
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.newGetList();
  }


  onDetail(info: SaleReportListItem): void {
    const params = new SaleListReq();
    params.policyId = info.policyId;
    params.underwritingNo = info.underwritingNo;
    params.productType = 'BASIC';
    const drawerRef = this.drawerService.create<SaleReportListComponent, { value: any }, string>({
      nzWidth: 600,
      nzContent: SaleReportListComponent,
      nzContentParams: {
        filterData: params,
        modeType: this.search.module
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
    });
    drawerRef.afterClose.subscribe(() => {
      // const component = drawerRef.getContentComponent();
    });
  }

}
