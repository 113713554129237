<nz-tabset [nzSize]="'large'" >
  <nz-tab [nzTitle]=myDeals>
    <app-policy-reminders [mode]="dataService.dataModeOwn"></app-policy-reminders>
  </nz-tab>
  <ng-template #myDeals>
    {{'MyDeals' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=channelDeals *ngIf="workbench.pivot !== dataService.pivotOwn">
    <app-policy-reminders [mode]="dataService.dataModeSubordinate"></app-policy-reminders>
  </nz-tab>
  <ng-template #channelDeals>
    {{'MySubordinates' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=myToDo *ngIf="workbench.tr">
    <app-policy-reminders [mode]="dataService.dataModeFollowUp"></app-policy-reminders>
  </nz-tab>
  <ng-template #myToDo>
    {{'MyToDo' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=myTeam>
    <app-policy-reminders [mode]="dataService.dataModeSalesGroup"></app-policy-reminders>
  </nz-tab>
  <ng-template #myTeam>
    {{'MySalesGroup' | translate}}
  </ng-template>



</nz-tabset>
