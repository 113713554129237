import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { ProductSelection } from '../quotation/quotation-types';
import { ApiResponse } from '../api/types';
import { API } from '../api/api';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SalesService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  startUsing(): Observable<boolean> {
    return this.http.get<ApiResponse<boolean>>(API.behaviorV3 + '/start-using', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

}
