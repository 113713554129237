<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'SystemInformation' | translate}}
    </div>

    <div class="section-wrapper">
      <!--      销售渠道-->
      <app-property-pick [title]="'Referee' | translate"
                         appThrottleClick (throttleClick)="onSub()"
                         [valueCursor]="!accountNameSave ? 'no-drop' : 'auto'"
                         [valueColor]="mode === 'SALESGROUP' ? '#cccccc' : ''"
                         [value]="fnaInfo.nickName"></app-property-pick>

      <app-property-select [displayMode]="'horizontal'"
                           [title]="'CustomerSource' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('CustomerSource' | translate)"
                           [options]="customerSourceOptions"
                           [(value)]="fnaInfo.customerSource"></app-property-select>

      <app-property-select [title]="'TypeOfCustomer' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('TypeOfCustomer' | translate)"
                           [options]="customerTypeOptions"
                           [(value)]="fnaInfo.customerType"></app-property-select>

      <app-property-text [title]="'TechnicalRepresentative' | translate"
                         [value]="fnaInfo.trAccountName"></app-property-text>

      <app-property-text [title]="'CreateTime' | translate"
                         [bottomLineVisible]="false"
                         [value]="fnaInfo.createTime | date:'yyyy-MM-dd HH:mm'"></app-property-text>
    </div>
  </nz-spin>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onCancel()">
          <span>{{'TemporarySave' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
