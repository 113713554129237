import {Expose} from 'class-transformer';

export class UnderwritingToDoRes {
  @Expose() unReadMessage: number; // 未读留言数
  @Expose() messageCount: number; // 留言总数
  @Expose() project: string; // Pending主题
  @Expose() remark: string; // 备注
  @Expose() underwritingNo: string; // 新单编号
  @Expose() pendingNo: string; // Pending编号
  @Expose() deadlines: number; // 截止时间
  @Expose() organizer: string; // 发起人
  @Expose() finish: boolean; // 是否完成Pending标志:false-未完成;true-完成
  @Expose() id: number; // 主键
  @Expose() applyTime: number; // 创建时间
  @Expose() status: string; // Pending状态
}

export class PolicyToDoRes {
  @Expose() unReadMessage: number; // 未读留言数
  @Expose() messageCount: number; // 留言总数
  @Expose() policyId: string; // 保单编号
  @Expose() policyNo: string; // 保單号
  @Expose() project: string; // Service主题
  @Expose() serviceNo: string; // Service编号
  @Expose() finish: boolean; // 是否完成Pending标志:false-未完成;true-完成
  @Expose() id: number; // 主键
  @Expose() applyTime: number; // 创建时间
  @Expose() status: string; // Service状态
}


export class DialogToDoRes {
  @Expose() bookingNo: string; // 预约编号
  @Expose() underwritingNo: string; // 新单编号
  @Expose() policyId: string; // 保单ID
  @Expose() proposalId: string; // 计划书编号
  @Expose() content: string; // 内容
  @Expose() id: number; // 主键
  @Expose() time: string; // 回复时间
}
