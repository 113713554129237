<div>
  <div *ngIf="fnaQuestion.questionNo === 'FNA_01'" class="from-info-card">
    <div class="form-card-title question-title">
      {{'question1' | translate}}
    </div>
    <div class="form-card-check-box" style="justify-content: space-between;">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[0].tick"
             [disabled]="isDisabled"
             style="margin-left: 16px;">
        <div class="check-text-label">
          {{'CMGQuestion2' | translate}}
        </div>
      </label>
      <div class="display-flex">
        <div class="check-text-label" style="margin-right: 4px;">{{'CMGQuestion61' | translate}} :</div>
        <div class="col-box" style="width: 220px;">
          <div class="group-box"
               [style.background-color]="isDisabled ? '#FAFBFD' : ''">
            <nz-input-number class="group-box-input-number"
                             [nzParser]="parserValue"
                             [nzFormatter]="formatterValue"
                             [disabled]="isDisabled"
                             [nzPlaceHolder]="'PleaseEnter' | translate"
                             [(ngModel)]="fnaQuestion.answers[0].inputBox[0].value">
            </nz-input-number>
            <span class="suffix-text">HK$</span>
          </div>
        </div>
      </div>
    </div>

    <div class="form-card-check-box" style="justify-content: space-between;">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[1].tick" [disabled]="isDisabled" style="margin-left: 16px;">
        <div class="check-text-label">
          {{'CMGQuestion3' | translate}}
        </div>
      </label>
      <div class="display-flex">
        <div class="check-text-label" style="margin-right: 4px;">{{'CMGQuestion62' | translate}} :</div>
        <div class="col-box" style="width: 220px">
          <div class="group-box"
               [style.background-color]="isDisabled ? '#FAFBFD' : ''">
            <nz-input-number class="group-box-input-number"
                             [nzParser]="parserValue"
                             [nzFormatter]="formatterValue"
                             [disabled]="isDisabled"
                             [nzPlaceHolder]="'PleaseEnter' | translate"
                             [(ngModel)]="fnaQuestion.answers[1].inputBox[0].value">
            </nz-input-number>
            <span class="suffix-text">HK$</span>
          </div>
        </div>
      </div>
    </div>

    <div class="form-card-check-box">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].tick" [disabled]="isDisabled" style="margin-left: 54px;">
        <div class="check-text-label">
          {{'CMGQuestion3_1' | translate}}
        </div>
      </label>
    </div>

    <div class="form-card-check-box">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[1].subQuestions[1].tick" [disabled]="isDisabled" style="margin-left: 54px;">
        <div class="check-text-label">
          {{'CMGQuestion3_2' | translate}}
        </div>
      </label>
    </div>

    <div class="form-card-check-box">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[1].subQuestions[2].tick" [disabled]="isDisabled" style="margin-left: 54px;">
        <div class="check-text-label">
          {{'CMGQuestion3_4' | translate}}
        </div>
      </label>
    </div>

    <div class="form-card-check-box" style="padding-left: 54px;font-size: clamp(12px, 1.2vw, 14px)">
      <div>{{'ProductTypes' | translate}}:</div>
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[1].subQuestions[3].tick" [disabled]="isDisabled" style="margin-left: 80px;">
        <div class="check-text-label">
          {{'Indemnity' | translate}}
        </div>
      </label>
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[1].subQuestions[4].tick" [disabled]="isDisabled" style="margin-left: 80px;">
        <div class="check-text-label">
          {{'NonIndemnity' | translate}}
        </div>
      </label>
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[1].subQuestions[5].tick" [disabled]="isDisabled" style="margin-left: 80px;">
        <div class="check-text-label">
          {{'ComboProduct' | translate}}
        </div>
      </label>
    </div>

    <div class="form-card-check-box">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[2].tick" [disabled]="isDisabled" style="margin-left: 16px;">
        <div class="check-text-label">
          {{'CMGQuestion4' | translate}}
        </div>
      </label>
    </div>

    <div class="form-card-check-box">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[3].tick" [disabled]="isDisabled" style="margin-left: 16px;">
        <div class="check-text-label">
          {{'CMGQuestion5' | translate}}
        </div>
      </label>
    </div>

    <div class="form-card-check-box">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[4].tick" [disabled]="isDisabled" style="margin-left: 16px;">
        <div class="check-text-label">
          {{'CMGQuestion6' | translate}}
        </div>
      </label>
    </div>

    <div class="form-card-check-box" style="margin-bottom: 0;">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[5].tick" [disabled]="isDisabled" style="margin-left: 16px;">
        <div class="check-text-label">
          {{'CMGQuestion7' | translate}}
        </div>
      </label>
      <div class="col-box">
        <textarea nz-input class="value-box-input" [placeholder]="'CMGQuestion8' | translate"
                  style="max-width: 620px;" [disabled]="isDisabled"
                  [(ngModel)]="fnaQuestion.answers[5].inputBox[0].value"
                  [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
<!--        <input nz-input class="value-box-input"-->
<!--               style="width: 320px;"-->
<!--               [placeholder]="'CMGQuestion8' | translate"-->
<!--               [(ngModel)]="fnaQuestion.answers[5].inputBox[0].value" />-->
      </div>
    </div>
  </div>

  <div *ngIf="fnaQuestion.questionNo === 'FNA_02'" class="from-info-card">
    <div class="form-card-title question-title">
      {{'CMGQuestion9' | translate}}
    </div>
    <nz-checkbox-wrapper class="form-card-radio-box"
                         style="width: 100%;">
      <div nz-row>
        <div nz-col nzSpan="24">
          <label nz-checkbox [nzValue]="fnaQuestion.answers[0].key"
                 [disabled]="isDisabled"
                 [ngModel]="fnaQuestion.answers[0].tick"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[0].key)">
            <div class="check-text-label" style="margin-bottom: 10px;">
              {{'CMGQuestion10' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="24">
          <label nz-checkbox [nzValue]="fnaQuestion.answers[1].key"
                 [disabled]="isDisabled"
                 [ngModel]="fnaQuestion.answers[1].tick"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[1].key)"
                 style="margin-bottom: 10px;">
            <div class="check-text-label">
              {{'CMGQuestion11' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="24">
          <label nz-checkbox [nzValue]="fnaQuestion.answers[2].key"
                 [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[2].key)"
                 [ngModel]="fnaQuestion.answers[2].tick">
            <div class="check-text-label">
              {{'CMGQuestion12' | translate}}
            </div>
          </label>
        </div>
      </div>
    </nz-checkbox-wrapper>
  </div>

  <div *ngIf="fnaQuestion.questionNo === 'FNA_03'" class="from-info-card">
    <div class="form-card-title question-title">
      {{'CMGQuestion13' | translate}}
    </div>

    <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
      <div nz-row>
        <div nz-col nzSpan="4">
          <label nz-checkbox
                 [nzValue]="fnaQuestion.answers[0].key"
                 [ngModel]="fnaQuestion.answers[0].tick"
                 [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[0].key)">
            <div class="check-text-label" style="margin-bottom: 10px;">
              {{'CMGQuestion14' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="4">
          <label nz-checkbox
                 [nzValue]="fnaQuestion.answers[1].key"
                 [ngModel]="fnaQuestion.answers[1].tick"
                 [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[1].key)"
                 style="margin-bottom: 10px;">
            <div class="check-text-label">
              {{'CMGQuestion15' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="4">
          <label nz-checkbox
                 [nzValue]="fnaQuestion.answers[2].key"
                 [ngModel]="fnaQuestion.answers[2].tick"
                 [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[2].key)">
            <div class="check-text-label">
              {{'CMGQuestion16' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="4">
          <label nz-checkbox
                 [nzValue]="fnaQuestion.answers[3].key"
                 [ngModel]="fnaQuestion.answers[3].tick"
                 [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[3].key)">
            <div class="check-text-label">
              {{'CMGQuestion17' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="4">
          <label nz-checkbox
                 [nzValue]="fnaQuestion.answers[4].key"
                 [ngModel]="fnaQuestion.answers[4].tick"
                 [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[4].key)">
            <div class="check-text-label">
              {{'CMGQuestion18' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="4">
          <label nz-checkbox
                 [nzValue]="fnaQuestion.answers[5].key"
                 [ngModel]="fnaQuestion.answers[5].tick"
                 [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[5].key)">
            <div class="check-text-label">
              {{'CMGQuestion19' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="4">
          <label nz-checkbox
                 [nzValue]="fnaQuestion.answers[6].key"
                 [ngModel]="fnaQuestion.answers[6].tick"
                 [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[6].key)">
            <div class="check-text-label">
              {{'CMGQuestion20' | translate}}
            </div>
          </label>
        </div>
      </div>
    </nz-checkbox-wrapper>
  </div>

  <div *ngIf="fnaQuestion.questionNo === 'FNA_04'" class="from-info-card">
    <div class="form-card-title question-title">
      {{'CMGQuestion21' | translate}}</div>

    <div class="form-card-check-box" style="">
      <label nz-checkbox
             [nzValue]="fnaQuestion.answers[0].key"
             [ngModel]="fnaQuestion.answers[0].tick"
             [disabled]="isDisabled"
             (ngModelChange)="FNA04log($event, fnaQuestion, fnaQuestion.answers[0].key)"
             style="margin-left: 16px;width: 120px;">
        <div class="check-text-label">
          {{'CMGQuestion22' | translate}}:
        </div>
      </label>
      <div class="display-flex" style="margin-left: 78px;">
        <div class="check-text-label" style="margin-right: 6px;">{{'answer18' | translate}}</div>
        <div class="col-box" style="">
          <input nz-input class="value-box-input"
                 style="width: 130px;" [disabled]="isDisabled"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="fnaQuestion.answers[0].inputBox[0].value" />
        </div>
        <div class="check-text-label" style="margin-left: 8px;">{{'CMGQuestion24' | translate}}</div>
      </div>

      <div class="display-flex" style="margin-left: 8px;">
        <div class="check-text-label" style="margin-right: 6px;">{{'CMGQuestion25' | translate}}</div>
        <div class="col-box" style="">
          <input nz-input class="value-box-input"
                 style="width: 130px;" [disabled]="isDisabled"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="fnaQuestion.answers[0].inputBox[1].value" />
        </div>
        <div class="check-text-label" style="margin-left: 8px;">{{'CMGQuestion26' | translate}}</div>
      </div>

      <div class="display-flex" style="margin-left: 8px;">
        <div class="col-box" style="width: 250px;">
          <div class="group-box"
               [style.background-color]="isDisabled ? '#FAFBFD' : ''">
            <nz-input-number class="group-box-input-number"
                             [nzParser]="parserValue"
                             [nzPlaceHolder]="'PleaseEnter' | translate"
                             [disabled]="isDisabled"
                             [nzFormatter]="formatterValue"
                             [(ngModel)]="fnaQuestion.answers[0].inputBox[2].value">
            </nz-input-number>
            <span class="suffix-text">HK$</span>
          </div>
        </div>
      </div>
    </div>

    <div class="form-card-check-box" style="">
      <label nz-checkbox
             [nzValue]="fnaQuestion.answers[1].key"
             [ngModel]="fnaQuestion.answers[1].tick"
             [disabled]="isDisabled"
             (ngModelChange)="FNA04log($event, fnaQuestion, fnaQuestion.answers[1].key)"
             style="margin-left: 16px;width: 120px;">
        <div class="check-text-label">
          {{'CMGQuestion23' | translate}}:
        </div>
      </label>
      <div class="display-flex" style="margin-left: 78px;">
        <div class="check-text-label" style="margin-right: 6px;">{{'answer18' | translate}}</div>
        <div class="col-box" style="">
          <input nz-input class="value-box-input"
                 style="width: 130px;" [disabled]="isDisabled"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="fnaQuestion.answers[1].inputBox[0].value" />
        </div>
        <div class="check-text-label" style="margin-left: 8px;">{{'CMGQuestion24' | translate}}</div>
      </div>

      <div class="display-flex" style="margin-left: 8px;">
        <div class="check-text-label" style="margin-right: 6px;">{{'CMGQuestion25' | translate}}</div>
        <div class="col-box" style="">
          <input nz-input class="value-box-input"
                 style="width: 130px;" [disabled]="isDisabled"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="fnaQuestion.answers[1].inputBox[1].value" />
        </div>
        <div class="check-text-label" style="margin-left: 8px;">{{'CMGQuestion26' | translate}}</div>
      </div>

      <div class="display-flex" style="margin-left: 8px;">
        <div class="col-box" style="width: 250px;">
          <div class="group-box"
               [style.background-color]="isDisabled ? '#FAFBFD' : ''">
            <nz-input-number class="group-box-input-number"
                             [nzParser]="parserValue"
                             [disabled]="isDisabled"
                             [nzPlaceHolder]="'PleaseEnter' | translate"
                             [nzFormatter]="formatterValue"
                             [(ngModel)]="fnaQuestion.answers[1].inputBox[2].value">
            </nz-input-number>
            <span class="suffix-text">HK$</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fnaQuestion.questionNo === 'FNA_05'" class="from-info-card">
    <div class="form-card-title question-title">{{'CMGQuestion27' | translate}}</div>
    <div style="padding-left: 16px">
      <div class="question-title-span question-title" style="font-weight: bold;">
        {{'CMGQuestion28' | translate}}
      </div>
      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
        <div nz-row style="margin-bottom: 10px;">
          <div nz-col nzSpan="24" class="display-flex" style="margin-bottom: 10px;">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[0].answers[0].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[0].subKey, fnaQuestion.answers[0].subQuestions[0].answers[0].key)"
                   >
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion29' | translate}}
              </div>
            </label>
            <div class="col-box" style="margin-left: 4px;display: flex;align-items: center;justify-content: center;">
              <div class="group-box" style="width: 220px;flex: none;"
                   [style.background-color]="isDisabled ? '#FAFBFD' : ''">
                <nz-input-number class="group-box-input-number"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [disabled]="isDisabled"
                                 [(ngModel)]="fnaQuestion.answers[0].subQuestions[0].answers[0].inputBox[0].value">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
              <div class="check-text-label check-text-label-span" style="flex: 1;margin-left: 10px;">
                {{'CMGQuestion63' | translate}}
              </div>
            </div>
          </div>
          <div nz-col nzSpan="5">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[0].answers[1].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[0].subKey, fnaQuestion.answers[0].subQuestions[0].answers[1].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion31' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="5">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[0].answers[2].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[0].subKey, fnaQuestion.answers[0].subQuestions[0].answers[2].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion32' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="5">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[0].answers[3].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[0].subKey, fnaQuestion.answers[0].subQuestions[0].answers[3].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion33' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="5">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[0].answers[4].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[0].subKey, fnaQuestion.answers[0].subQuestions[0].answers[4].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion34' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[0].answers[5].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[0].subKey, fnaQuestion.answers[0].subQuestions[0].answers[5].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion35' | translate}}
              </div>
            </label>
          </div>
        </div>
      </nz-checkbox-wrapper>

      <div class="question-title-span question-title" style="font-weight: bold;">{{'CMGQuestion36' | translate}}</div>
      <div class="display-flex" style="margin-left: 16px;margin-bottom: 10px;">
        <div class="check-text-label" style="margin-right: 6px;">{{'CMGQuestion37' | translate}}：</div>
        <div class="col-box">
          <div class="group-box" style="width: 220px;"
               [style.background-color]="isDisabled ? '#FAFBFD' : ''">
            <nz-input-number class="group-box-input-number"
                             [nzParser]="parserValue"
                             [nzFormatter]="formatterValue"
                             [disabled]="isDisabled"
                             [(ngModel)]="fnaQuestion.answers[0].subQuestions[1].answers[0].inputBox[0].value">
            </nz-input-number>
            <span class="suffix-text">HK$</span>
          </div>
        </div>
      </div>


      <div class="question-title-span question-title" style="font-weight: bold;">
        {{'CMGQuestion38' | translate}}
      </div>

      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
        <div nz-row style="margin-bottom: 10px;">
          <div nz-col nzSpan="4" style="margin-bottom: 10px;">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[2].answers[0].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[2].subKey, fnaQuestion.answers[0].subQuestions[2].answers[0].key)">
              <div class="check-text-label">
                {{'CMGQuestion39' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[2].answers[1].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[2].subKey, fnaQuestion.answers[0].subQuestions[2].answers[1].key)">
              <div class="check-text-label">
                {{'CMGQuestion40' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[2].answers[2].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[2].subKey, fnaQuestion.answers[0].subQuestions[2].answers[2].key)">
              <div class="check-text-label">
                {{'CMGQuestion41' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[2].answers[3].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[2].subKey, fnaQuestion.answers[0].subQuestions[2].answers[3].key)">
              <div class="check-text-label">
                {{'CMGQuestion42' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[2].answers[4].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[2].subKey, fnaQuestion.answers[0].subQuestions[2].answers[4].key)">
              <div class="check-text-label">
                {{'CMGQuestion43' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[2].answers[5].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[2].subKey, fnaQuestion.answers[0].subQuestions[2].answers[5].key)">
              <div class="check-text-label">
                {{'CMGQuestion44' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="24" class="display-flex">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[2].answers[6].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[2].subKey, fnaQuestion.answers[0].subQuestions[2].answers[6].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion45' | translate}}
              </div>
            </label>
            <div class="col-box" style="margin-left: 10px;">
              <textarea nz-input class="value-box-input" [placeholder]="'PleaseEnter' | translate"
                        style="max-width: 620px;" [disabled]="isDisabled"
                        [(ngModel)]="fnaQuestion.answers[0].subQuestions[2].answers[6].inputBox[0].value"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
<!--              <input nz-input class="value-box-input"-->
<!--                     style="width: 320px;"-->
<!--                     [placeholder]="'PleaseEnter' | translate"-->
<!--                     [(ngModel)]="fnaQuestion.answers[0].subQuestions[2].answers[6].inputBox[0].value" />-->
            </div>
          </div>

        </div>
      </nz-checkbox-wrapper>

      <div class="question-title-span question-title" style="font-weight: bold;">
        {{'CMGQuestion46' | translate}}
      </div>

      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
        <div nz-row style="margin-bottom: 10px;">
          <div nz-col nzSpan="4" >
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[3].answers[0].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[3].subKey, fnaQuestion.answers[0].subQuestions[3].answers[0].key)">
              <div class="check-text-label">
                {{'CMGQuestion39' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[3].answers[1].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[3].subKey, fnaQuestion.answers[0].subQuestions[3].answers[1].key)">
              <div class="check-text-label">
                {{'CMGQuestion40' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[3].answers[2].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[3].subKey, fnaQuestion.answers[0].subQuestions[3].answers[2].key)">
              <div class="check-text-label">
                {{'CMGQuestion41' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[3].answers[3].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[3].subKey, fnaQuestion.answers[0].subQuestions[3].answers[3].key)">
              <div class="check-text-label">
                {{'CMGQuestion42' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[3].answers[4].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[3].subKey, fnaQuestion.answers[0].subQuestions[3].answers[4].key)">
              <div class="check-text-label">
                {{'CMGQuestion43' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[3].answers[5].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[3].subKey, fnaQuestion.answers[0].subQuestions[3].answers[5].key)">
              <div class="check-text-label">
                {{'CMGQuestion44' | translate}}
              </div>
            </label>
          </div>
        </div>
      </nz-checkbox-wrapper>

      <div class="question-title-span question-title" style="font-weight: bold;">{{'CMGQuestion47' | translate}}</div>

      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;" (nzOnChange)="log($event)">
        <div nz-row style="margin-bottom: 10px;">
          <div nz-col nzSpan="4" style="margin-bottom: 10px;">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[4].answers[0].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[4].subKey, fnaQuestion.answers[0].subQuestions[4].answers[0].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion48' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[4].answers[1].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[4].subKey, fnaQuestion.answers[0].subQuestions[4].answers[1].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion49' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[4].answers[2].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[4].subKey, fnaQuestion.answers[0].subQuestions[4].answers[2].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion50' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[4].answers[3].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[4].subKey, fnaQuestion.answers[0].subQuestions[4].answers[3].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion51' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[4].answers[4].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[4].subKey, fnaQuestion.answers[0].subQuestions[4].answers[4].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion52' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[4].answers[5].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[4].subKey, fnaQuestion.answers[0].subQuestions[4].answers[5].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion53' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="24" class="display-flex">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[4].answers[6].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[4].subKey, fnaQuestion.answers[0].subQuestions[4].answers[6].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion54' | translate}}
              </div>
            </label>
            <div class="col-box" style="margin-left: 10px;">
              <div class="group-box" style="width: 220px;"
                   [style.background-color]="isDisabled ? '#FAFBFD' : ''">
                <nz-input-number class="group-box-input-number"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [disabled]="isDisabled"
                                 [(ngModel)]="fnaQuestion.answers[0].subQuestions[4].answers[6].inputBox[0].value">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>
        </div>
      </nz-checkbox-wrapper>

      <div class="question-title-span question-title" style="font-weight: bold;">
        {{'CMGQuestion55' | translate}}
      </div>
      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
        <div nz-row style="">
          <div nz-col nzSpan="12" style="display: flex;align-items: center;">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[5].answers[0].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[5].subKey, fnaQuestion.answers[0].subQuestions[5].answers[0].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion56' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="12" class="display-flex">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[5].answers[1].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[5].subKey, fnaQuestion.answers[0].subQuestions[5].answers[1].key)">
              <div class="check-text-label">
                {{'answer36' | translate}}
              </div>
            </label>
            <div class="col-box">
              <textarea nz-input class="value-box-input" [placeholder]="'CMGQuestion8' | translate"
                        style="max-width: 620px;" [disabled]="isDisabled"
                        [(ngModel)]="fnaQuestion.answers[0].subQuestions[5].answers[1].inputBox[0].value"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
<!--              <input nz-input class="value-box-input"-->
<!--                     style="width: 320px;"-->
<!--                     [placeholder]="'CMGQuestion8' | translate"-->
<!--                     [(ngModel)]="fnaQuestion.answers[0].subQuestions[5].answers[1].inputBox[0].value" />-->
            </div>
          </div>
        </div>
      </nz-checkbox-wrapper>
    </div>
  </div>

  <div *ngIf="fnaQuestion.questionNo === 'FNA_06'" class="from-info-card">
    <div class="form-card-title question-title">
      {{'CMGQuestion57' | translate}}
      <div class="icon-minus" (click)="otherExisting = !otherExisting">
        <img *ngIf="otherExisting" alt="" width="20"
             src="assets/images/icon_minus.svg">
        <img *ngIf="!otherExisting" alt="" width="20"
             src="assets/images/icon_plus.svg">
      </div>
    </div>
    <div *ngIf="otherExisting" style="padding-left: 16px;">
      <div class="question-title-span question-title" style="font-weight: bold;">{{'CMGQuestion58' | translate}}</div>
      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
        <div nz-row style="margin-bottom: 10px;">
          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[0].tick"
                   (ngModelChange)="FNA06log($event, fnaQuestion, fnaQuestion.answers[0].key, fnaQuestion.answers[0].subQuestions[0].subKey)">
              <div class="check-text-label">
                {{'answer37' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[1].tick"
                   (ngModelChange)="FNA06log($event, fnaQuestion, fnaQuestion.answers[0].key, fnaQuestion.answers[0].subQuestions[1].subKey)">
              <div class="check-text-label">
                {{'answer38' | translate}}
              </div>
            </label>
          </div>
        </div>
      </nz-checkbox-wrapper>
      <div *ngIf="fnaQuestion.answers[0].subQuestions[0].tick" class="form-card-radio-box policy-box">
        <div nz-row [nzGutter]="16" style="padding-right: 8px;">
          <div nz-col [nzSpan]="6">
            <div class="label-text">{{'answer39' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input [disabled]="isDisabled"
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[0].subQuestions[0].subQuestions[0].answers[0].inputBox[0].value" />
            </div>
          </div>
          <div nz-col [nzSpan]="6">
            <div class="label-text">{{'answer40' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input [disabled]="isDisabled"
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[0].subQuestions[0].subQuestions[0].answers[1].inputBox[0].value" />
            </div>
          </div>
          <div nz-col [nzSpan]="6">
            <div class="label-text">{{'answer41' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input [disabled]="isDisabled"
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[0].subQuestions[0].subQuestions[0].answers[2].inputBox[0].value" />
            </div>
          </div>
          <div nz-col [nzSpan]="6">
            <div class="label-text">{{'answer42' | translate}}</div>
            <div class="value-box">
                <nz-input-number class="value-box-input"
                                 style="padding: 0;"
                                 [disabled]="isDisabled"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="fnaQuestion.answers[0].subQuestions[0].subQuestions[0].answers[3].inputBox[0].value">
                </nz-input-number>
            </div>
          </div>
          <div nz-col [nzSpan]="6" style="margin-top: 8px;">
            <div class="label-text">{{'answer43' | translate}}</div>
            <div class="value-box">
              <nz-input-number class="value-box-input"
                               style="padding: 0;"
                               [disabled]="isDisabled"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="fnaQuestion.answers[0].subQuestions[0].subQuestions[0].answers[4].inputBox[0].value">
              </nz-input-number>
<!--              <input class="value-box-input" nz-input-->
<!--                     [placeholder]="'PleaseEnter' | translate"-->
<!--                     [(ngModel)]="fnaQuestion.answers[0].subQuestions[0].subQuestions[0].answers[4].inputBox[0].value" />-->
            </div>
          </div>
          <div nz-col [nzSpan]="6" style="margin-top: 8px;">
            <div class="label-text">{{'answer44' | translate}}</div>
            <div class="value-box">
              <nz-input-number class="value-box-input"
                               style="padding: 0;"
                               [disabled]="isDisabled"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="fnaQuestion.answers[0].subQuestions[0].subQuestions[0].answers[5].inputBox[0].value">
              </nz-input-number>
<!--              <input class="value-box-input" nz-input-->
<!--                     [placeholder]="'PleaseEnter' | translate"-->
<!--                     [(ngModel)]="fnaQuestion.answers[0].subQuestions[0].subQuestions[0].answers[5].inputBox[0].value" />-->
            </div>
          </div>
          <div nz-col [nzSpan]="6" style="margin-top: 8px;">
            <div class="label-text">{{'answer45' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input [disabled]="isDisabled"
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[0].subQuestions[0].subQuestions[0].answers[6].inputBox[0].value" />
<!--              <nz-input-number class="value-box-input"-->
<!--                               style="padding: 0;"-->
<!--                               [nzPlaceHolder]="'PleaseEnter' | translate"-->
<!--                               [nzParser]="parserValue"-->
<!--                               [nzFormatter]="formatterValue"-->
<!--                               [(ngModel)]="fnaQuestion.answers[0].subQuestions[0].subQuestions[0].answers[6].inputBox[0].value">-->
<!--              </nz-input-number>-->
            </div>
          </div>
          <div nz-col [nzSpan]="6" style="margin-top: 8px;">
            <div class="label-text">{{'answer46' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input [disabled]="isDisabled"
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[0].subQuestions[0].subQuestions[0].answers[7].inputBox[0].value" />
<!--              <nz-input-number class="value-box-input"-->
<!--                               style="padding: 0;"-->
<!--                               [nzPlaceHolder]="'PleaseEnter' | translate"-->
<!--                               [nzParser]="parserValue"-->
<!--                               [nzFormatter]="formatterValue"-->
<!--                               [(ngModel)]="fnaQuestion.answers[0].subQuestions[0].subQuestions[0].answers[7].inputBox[0].value">-->
<!--              </nz-input-number>-->
            </div>
          </div>
        </div>

      </div>

      <div class="question-title-span question-title" style="font-weight: bold;">
        {{'CMGQuestion59' | translate}}
      </div>

      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;" (nzOnChange)="log($event)">
        <div nz-row style="margin-bottom: 10px;">
          <div nz-col nzSpan="4" style="">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[1].subQuestions[0].tick"
                   (ngModelChange)="FNA06log($event, fnaQuestion, fnaQuestion.answers[1].key, fnaQuestion.answers[1].subQuestions[0].subKey)">
              <div class="check-text-label">
                {{'answer37' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[1].subQuestions[1].tick"
                   (ngModelChange)="FNA06log($event, fnaQuestion, fnaQuestion.answers[1].key, fnaQuestion.answers[1].subQuestions[1].subKey)">
              <div class="check-text-label">
                {{'answer38' | translate}}
              </div>
            </label>
          </div>
        </div>
      </nz-checkbox-wrapper>
      <div *ngIf="fnaQuestion.answers[1].subQuestions[0].tick" class="form-card-radio-box policy-box">
        <div class="question-title-span question-title" style="margin-bottom: 8px;">POLICY  1</div>
        <div nz-row [nzGutter]="16" style="padding-right: 8px;">
          <div nz-col [nzSpan]="6">
            <div class="label-text">{{'PolicyNumber39' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input [disabled]="isDisabled"
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[0].answers[0].inputBox[0].value" />
            </div>
          </div>
          <div nz-col [nzSpan]="6">
            <div class="label-text">{{'answer40' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input [disabled]="isDisabled"
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[0].answers[1].inputBox[0].value" />
            </div>
          </div>
          <div nz-col [nzSpan]="6">
            <div class="label-text">{{'answer41' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input [disabled]="isDisabled"
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[0].answers[2].inputBox[0].value" />
            </div>
          </div>
          <div nz-col [nzSpan]="6">
            <div class="label-text">{{'answer42' | translate}}</div>
            <div class="value-box">
              <nz-input-number class="value-box-input"
                               style="padding: 0;" [disabled]="isDisabled"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[0].answers[3].inputBox[0].value">
              </nz-input-number>
            </div>
          </div>
          <div nz-col [nzSpan]="6" style="margin-top: 8px;">
            <div class="label-text">{{'answer43' | translate}}</div>
            <div class="value-box">
              <nz-input-number class="value-box-input"
                               style="padding: 0;" [disabled]="isDisabled"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[0].answers[4].inputBox[0].value">
              </nz-input-number>
<!--              <input class="value-box-input" nz-input-->
<!--                     [placeholder]="'PleaseEnter' | translate"-->
<!--                     [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[0].answers[4].inputBox[0].value" />-->
            </div>
          </div>
          <div nz-col [nzSpan]="6" style="margin-top: 8px;">
            <div class="label-text">{{'CMGQuestion64' | translate}}</div>
            <div class="value-box">
              <nz-input-number class="value-box-input"
                               style="padding: 0;" [disabled]="isDisabled"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[0].answers[5].inputBox[0].value">
              </nz-input-number>
<!--              <input class="value-box-input" nz-input-->
<!--                     [placeholder]="'PleaseEnter' | translate"-->
<!--                     [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[0].answers[5].inputBox[0].value" />-->
            </div>
          </div>
          <div nz-col [nzSpan]="6" style="margin-top: 8px;">
            <div class="label-text">{{'answer45' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input [disabled]="isDisabled"
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[0].answers[6].inputBox[0].value" />
<!--              <nz-input-number class="value-box-input"-->
<!--                               style="padding: 0;"-->
<!--                               [nzPlaceHolder]="'PleaseEnter' | translate"-->
<!--                               [nzParser]="parserValue"-->
<!--                               [nzFormatter]="formatterValue"-->
<!--                               [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[0].answers[6].inputBox[0].value">-->
<!--              </nz-input-number>-->
            </div>
          </div>
          <div nz-col [nzSpan]="6" style="margin-top: 8px;">
            <div class="label-text">{{'answer46' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input [disabled]="isDisabled"
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[0].answers[7].inputBox[0].value" />
<!--              <nz-input-number class="value-box-input"-->
<!--                               style="padding: 0;"-->
<!--                               [nzPlaceHolder]="'PleaseEnter' | translate"-->
<!--                               [nzParser]="parserValue"-->
<!--                               [nzFormatter]="formatterValue"-->
<!--                               [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[0].answers[7].inputBox[0].value">-->
<!--              </nz-input-number>-->
            </div>
          </div>
        </div>


      </div>
      <div *ngIf="fnaQuestion.answers[1].subQuestions[0].tick" class="form-card-radio-box policy-box" style="margin-bottom: 0;">
        <div class="question-title-span question-title" [style]="policy2 ? '' : 'margin-bottom: 0;'">
          POLICY  2
          <div class="icon-minus" style="margin-left: 26px;" (click)="policy2 = !policy2">
            <img *ngIf="policy2" alt="" width="20"
                 src="assets/images/icon_minus.svg">
            <img *ngIf="!policy2" alt="" width="20"
                 src="assets/images/icon_plus.svg">
          </div>
        </div>
        <div *ngIf="policy2" nz-row [nzGutter]="16" style="padding-right: 8px;">
          <div nz-col [nzSpan]="6">
            <div class="label-text">{{'PolicyNumber39' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input [disabled]="isDisabled"
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[1].answers[0].inputBox[0].value" />
            </div>
          </div>
          <div nz-col [nzSpan]="6">
            <div class="label-text">{{'answer40' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input [disabled]="isDisabled"
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[1].answers[1].inputBox[0].value" />
            </div>
          </div>
          <div nz-col [nzSpan]="6">
            <div class="label-text">{{'answer41' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[1].answers[2].inputBox[0].value" />
            </div>
          </div>
          <div nz-col [nzSpan]="6">
            <div class="label-text">{{'answer42' | translate}}</div>
            <div class="value-box">
              <nz-input-number class="value-box-input"
                               style="padding: 0;"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[1].answers[3].inputBox[0].value">
              </nz-input-number>
            </div>
          </div>
          <div nz-col [nzSpan]="6" style="margin-top: 8px;">
            <div class="label-text">{{'answer43' | translate}}</div>
            <div class="value-box">
              <nz-input-number class="value-box-input"
                               style="padding: 0;"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[1].answers[4].inputBox[0].value">
              </nz-input-number>
<!--              <input class="value-box-input" nz-input-->
<!--                     [placeholder]="'PleaseEnter' | translate"-->
<!--                     [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[1].answers[4].inputBox[0].value" />-->
            </div>
          </div>
          <div nz-col [nzSpan]="6" style="margin-top: 8px;">
            <div class="label-text">{{'CMGQuestion64' | translate}}</div>
            <div class="value-box">
              <nz-input-number class="value-box-input"
                               style="padding: 0;"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[1].answers[5].inputBox[0].value">
              </nz-input-number>
<!--              <input class="value-box-input" nz-input-->
<!--                     [placeholder]="'PleaseEnter' | translate"-->
<!--                     [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[1].answers[5].inputBox[0].value" />-->
            </div>
          </div>
          <div nz-col [nzSpan]="6" style="margin-top: 8px;">
            <div class="label-text">{{'answer45' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[1].answers[6].inputBox[0].value" />
<!--              <nz-input-number class="value-box-input"-->
<!--                               style="padding: 0;"-->
<!--                               [nzPlaceHolder]="'PleaseEnter' | translate"-->
<!--                               [nzParser]="parserValue"-->
<!--                               [nzFormatter]="formatterValue"-->
<!--                               [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[1].answers[6].inputBox[0].value">-->
<!--              </nz-input-number>-->
            </div>
          </div>
          <div nz-col [nzSpan]="6" style="margin-top: 8px;">
            <div class="label-text">{{'answer46' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[1].answers[7].inputBox[0].value" />
<!--              <nz-input-number class="value-box-input"-->
<!--                               style="padding: 0;"-->
<!--                               [nzPlaceHolder]="'PleaseEnter' | translate"-->
<!--                               [nzParser]="parserValue"-->
<!--                               [nzFormatter]="formatterValue"-->
<!--                               [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].subQuestions[1].answers[7].inputBox[0].value">-->
<!--              </nz-input-number>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fnaQuestion.questionNo === 'FNA_06' && fnaQuestion.answers[0].subQuestions[0].tick" class="from-info-card">
    <div *ngIf="fnaQuestion.answers[0].subQuestions[0].tick" class="form-card-radio-box">
      {{'CMGQuestion60' | translate}}
    </div>
  </div>
</div>

