import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NzDrawerRef, NzDrawerService} from 'ng-zorro-antd/drawer';
import {FnaProductSelectionComponent} from '../fna-product-selection/fna-product-selection.component';
import {FnaService} from '../fna.service';
import {
  Fna,
  FnaAssessAdviceUpdateReq,
  FnaModuleAndAnswer,
  FnaQuestion,
  FnaQuestionNoAndResult,
  FnaQuestionType,
  RecommendProduct,
  RecommendProductCode
} from '../fna-types';
import {deduplication} from '../../shared/utils/collections';
import {FnaProductListComponent} from '../fna-product-list/fna-product-list.component';
import {plainToArray} from '../../shared/utils/class-transform';
import {FnaProductEditComponent} from '../fna-product-edit/fna-product-edit.component';

@Component({
  selector: 'app-fna-product',
  templateUrl: './fna-product.component.html',
  styleUrls: ['./fna-product.component.less']
})
export class FnaProductComponent implements OnInit {

  drawerRef: NzDrawerRef<FnaProductComponent, string>;

  loading: boolean;

  fna: Fna;

  @Input()
  fnaQuestions: FnaQuestion[] = [];

  fnaQuestionMap = new Map<string, FnaQuestion>();

  recommendedProducts: RecommendProduct[];

  @Output()
  saved: EventEmitter<any> = new EventEmitter<any>();

  constructor(private drawerService: NzDrawerService,
              private fnaService: FnaService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.fnaService.questions(this.fna.id, FnaQuestionType.EVALUATION)
      .subscribe(
        data => {
          this.loading = false;
          const newQuestions: FnaQuestion[] = [];
          for (const fnaQuestion of data) {
            newQuestions.push(fnaQuestion);
            if (fnaQuestion.answers) {
              for (const answer of fnaQuestion.answers) {
                if (answer.tick && answer.subQuestions) {
                  newQuestions.push(...answer.subQuestions);
                }
              }
            }
          }
          this.fnaQuestions = newQuestions;
          for (const fnaQuestion of this.fnaQuestions) {
            this.fnaQuestionMap.set(fnaQuestion.questionNo, fnaQuestion);
          }
        }, error => {
          this.loading = false;
        });

    this.loadDetail();

  }

  loadDetail(): void {
    this.loading = true;
    this.fnaService.info(this.fna.id)
      .subscribe(
        fna => {
          this.loading = false;
          this.fna = fna;
          this.setupRecommendedProducts();
        },
        error => {
          this.loading = false;
        });
  }

  onRecommendedProductSelect(): void {
    const drawerRef = this.drawerService.create<FnaProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: FnaProductSelectionComponent,
      nzContentParams: {
        fna: this.fna
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.recommendedSaved
        .subscribe(data => {
          this.loadDetail();
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  setupRecommendedProducts(): void {
    this.recommendedProducts = [];
    this.recommendedProducts = this.recommendedProducts
      .concat(this.fna.customize)
      .concat(this.fna.recommend)
      .concat(this.fna.history);
    this.recommendedProducts = deduplication(this.recommendedProducts, v => {
      return v.productCode;
    });
  }

  onFnaQuestionChange(fnaQuestion: FnaQuestion, i: number): void {
    this.fnaQuestions[i] = fnaQuestion;
  }

  onSubQuestionEvent(answer: FnaModuleAndAnswer): void {
    for (const subQuestion of answer.subQuestions) {
      if (answer.tick) {
        this.fnaQuestionMap.set(subQuestion.questionNo, subQuestion);
      } else {
        this.fnaQuestionMap.delete(subQuestion.questionNo);
      }
    }
    this.fnaQuestions = [...this.fnaQuestionMap.values()];
    this.fnaQuestions = this.fnaQuestions.sort((v1: FnaQuestion, v2: FnaQuestion) => {
      return Number(v1.questionNo) > Number(v2.questionNo) ? 1 : -1;
    });
  }

  onFinalProductSelect(): void {

    const products = plainToArray(RecommendProduct, this.recommendedProducts);
    for (const product of products) {
      for (const recommendProduct of this.fna.endProduct) {
        if (product.productCode === recommendProduct.productCode) {
          product.tick = true;
        }
      }
    }

    const drawerRef = this.drawerService.create<FnaProductListComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: FnaProductListComponent,
      nzContentParams: {
        products
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.productsSaved
        .subscribe(data => {
          const endProducts = data.filter(value => {
            return value.tick;
          });
          for (const product of endProducts) {
            product.tick = false;
          }
          this.fna.endProduct = endProducts;
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  recommendProductMapFunc = (value: RecommendProduct): RecommendProductCode => {
    const recommendProductCode = new RecommendProductCode();
    recommendProductCode.productCode = value.productCode;
    recommendProductCode.riders = value.riders?.map(r => r.productCode);
    return recommendProductCode;
  }

  onTemporarySave(): void {
    this.loading = true;
    const updateReq = new FnaAssessAdviceUpdateReq();
    updateReq.id = this.fna.id;
    updateReq.customize = this.fna.customize?.map(this.recommendProductMapFunc);
    updateReq.recommend = this.fna.recommend?.map(this.recommendProductMapFunc);
    updateReq.history = this.fna.history?.map(this.recommendProductMapFunc);
    updateReq.assessAdvice.questions = FnaQuestionNoAndResult.getQuestionNoAndResults(this.fnaQuestions);
    updateReq.endProductCodes = this.fna.endProduct.map(value => {
      return value.productCode;
    });
    this.fnaService.updateAdviceTemporary(updateReq)
      .subscribe(
        data => {
          this.loading = false;
          this.saved.emit('');
          this.drawerRef?.close();
        }, error => {
          this.loading = false;
        });
  }

  onSave(): void {
    this.loading = true;
    const updateReq = new FnaAssessAdviceUpdateReq();
    updateReq.id = this.fna.id;
    updateReq.customize = this.fna.customize?.map(this.recommendProductMapFunc);
    updateReq.recommend = this.fna.recommend?.map(this.recommendProductMapFunc);
    updateReq.history = this.fna.history?.map(this.recommendProductMapFunc);
    updateReq.assessAdvice.questions = FnaQuestionNoAndResult.getQuestionNoAndResults(this.fnaQuestions);
    updateReq.endProductCodes = this.fna.endProduct?.map(value => {
      return value.productCode;
    });
    this.fnaService.updateAdvice(updateReq)
      .subscribe(
        data => {
          this.loading = false;
          this.saved.emit('');
          this.drawerRef?.close();
        }, error => {
          this.loading = false;
        });
  }

  onRecommendedProductEdit(product: RecommendProduct, index: number): void {

    const drawerRef = this.drawerService.create<FnaProductEditComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: FnaProductEditComponent,
      nzContentParams: {
        product
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.productSaved
        .subscribe(data => {
          this.recommendedProducts[index] = data;
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  get canSave(): boolean {
    return this.fna.productCanSave;
  }

}
