import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { PagedResp, ProductType } from '../../../api/types';
import { Product, ProductCategory, ProductCompany, ProductListResp, ProductSearch } from '../../../product/product-types';
import { PropertySelectOption } from '../property-select/property-select.component';
import { ProductService } from '../../../product/product.service';
import { debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { ProductSelectionType } from '../product-selection/product-selection.component';
import { arrayToMap, deduplication } from '../../utils/collections';

@Component({
  selector: 'app-product-multi-selection',
  templateUrl: './product-multi-selection.component.html',
  styleUrls: ['./product-multi-selection.component.less']
})
export class ProductMultiSelectionComponent implements OnInit {

  drawerRef: NzDrawerRef<ProductMultiSelectionComponent, string>;

  @Input()
  type = ProductSelectionType.DEFAULT;

  @Output()
  productSelected: EventEmitter<Product> = new EventEmitter<Product>();

  @Output()
  productsSelected: EventEmitter<Product[]> = new EventEmitter<Product[]>();

  searchParamChange: EventEmitter<any> = new EventEmitter<any>();

  search = new ProductSearch();

  spinning = false;

  loading = false;

  products: PagedResp<ProductListResp>;
  categories: ProductCategory[];
  companies: ProductCompany[];

  categoryOptions: PropertySelectOption[] = [];
  companyOptions: PropertySelectOption[] = [];

  selectedProducts: Product[] = [];

  productsPopoverVisible = false;

  hideCategory = false;

  constructor(private productService: ProductService) {
  }

  ngOnInit(): void {
    this.productService.categories()
      .subscribe(
        data => {
          this.categories = data;
          data.forEach(category => {
            this.categoryOptions?.push(new PropertySelectOption(category.name, category.code));
          });
        },
        error => {
        });

    this.productService.companies()
      .subscribe(
        data => {
          this.companies = data;
          data.forEach(company => {
            this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
          });

        },
        error => {
        });

    this.searchParamChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          this.onSearch(1);
        }
      );


  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    let observable: Observable<PagedResp<ProductListResp>>;
    switch (this.type) {
      case ProductSelectionType.DEFAULT:
        observable = this.productService.page(this.search);
        break;
      case ProductSelectionType.RIDER:
        this.search.productType = ProductType.RIDER;
        observable = this.productService.page(this.search);
        break;
      case ProductSelectionType.PROPOSAL:
        observable = this.productService.pageForProposal(this.search);
        break;
      case ProductSelectionType.COMPARISON:
        this.search.comparable = true;
        observable = this.productService.page(this.search);
        break;
    }
    this.loading = true;
    observable?.subscribe(
      data => {
        this.loading = false;
        this.products = data;
      },
      error => {
        this.loading = false;
      });

  }

  onSearchParamChange(): void {
    this.searchParamChange.emit('');
  }

  onReload(): void {
    this.onSearch(this.search.pageNum);
  }

  onProductSelected(code: string, i: number): void {

    if (this.containsProduct(code)) {
      return;
    }

    this.loading = true;
    this.productService.detail(code)
      .subscribe(
        product => {
          this.loading = false;
          this.productSelected?.emit(product);
          this.selectedProducts.push(product);
          this.selectedProducts = deduplication(this.selectedProducts, (v => {
            return v.productCode;
          }));
          this.productsSelected?.emit(this.selectedProducts);
        },
        error => {
          this.loading = false;
        }
      );
  }

  containsProduct(productCode: string): boolean {
    const map = arrayToMap(this.selectedProducts, (v => {
      return v.productCode;
    }));
    return map.has(productCode);
  }

  onConfirm(): void {
    this.drawerRef?.close();
  }

  onProductDelete(product: Product, i: number): void {
    this.selectedProducts.splice(i, 1);
    this.selectedProducts = deduplication(this.selectedProducts, (v => {
      return v.productCode;
    }));
    this.productsSelected?.emit(this.selectedProducts);
  }

}
