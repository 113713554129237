import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-property-yes-or-not',
  templateUrl: './property-yes-or-not.component.html',
  styleUrls: ['./property-yes-or-not.component.less']
})
export class PropertyYesOrNotComponent implements OnInit {

  @Input() title: string;
  @Input() tip: string;
  @Input() tipVisible = false;
  @Input() bottomLineVisible = true;
  @Input() disabled = false;
  @Input() required = false;

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  innerValue: boolean;

  @Input()
  get value(): boolean {
    return this.innerValue;
  }

  set value(val: boolean) {
    this.innerValue = val;
    this.valueChange.emit(this.innerValue);
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
