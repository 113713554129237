import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  NavigationExtras,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { BaseService } from '../base/base.service';
import { Constant } from '../base/constant';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends BaseService implements CanActivate, CanActivateChild {

  constructor(private router: Router) {
    super();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    return this.checkAuth(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checkAuth(url: string): boolean {
    if (this.passport != null) {
      return true;
    }

    // Store the attempted URL for redirecting
    this.redirectUrl = url;

    // Create a dummy session id
    // let sessionId = 123456789;

    // Set our navigation extras object
    // that contains our global query params and fragment
    const navigationExtras: NavigationExtras = {
      // queryParams: { 'session_id': sessionId, 'phone': '' },
      // fragment: 'anchor'
    };

    // Navigate to the login page with extras
    if (this.instance === null) {
      if (window.location.href.includes('sales.hkbaylight.com')) {
        this.router.navigate(['/auth/guide-gallery'], navigationExtras).then();
      } else {
        this.router.navigate(['/auth/instance-code'], navigationExtras).then();
      }
    } else {
      this.router.navigate(['/auth/guide-gallery'], navigationExtras).then();
    }
    return false;
  }
}
