import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { API } from '../api/api';
import { AuthMode, HttpService, MODE_TOKEN } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class HttpCommonInterceptor implements HttpInterceptor {

  constructor(private httpService: HttpService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!req.url.startsWith(API.baseUrl)) {
      return next.handle(req);
    }
    let httpHeaders = req.headers;

    const context = req.context;
    const mode = context.get(MODE_TOKEN);
    // console.log(context);
    // console.log(mode);

    switch (mode) {
      case AuthMode.STORAGE: {
        const headersMap = this.httpService.getApiHttpHeaders(req.url);
        headersMap.forEach((value, key, map) => {
          httpHeaders = httpHeaders.set(key, value);
        });
        break;
      }
      case AuthMode.MEMORY: {
        const headersMap = this.httpService.getApiHttpHeadersByContext(req.url, req.context);
        headersMap.forEach((value, key, map) => {
          httpHeaders = httpHeaders.set(key, value);
        });
        break;
      }
    }

    const authReq = req.clone({
      headers: httpHeaders
    });
    return next.handle(authReq);
  }
}
