import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { ApiResponse, PagedResp } from '../api/types';
import { HttpParams } from '@angular/common/http';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { Knowledge, KnowledgeListResp, KnowledgeSearch, KnowledgeType } from './knowledge-types';
import { plainToClass, plainToInstance } from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class KnowledgeService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  /**
   * 知识库分页列表
   * @param search 筛选条件
   */
  list(search: KnowledgeSearch): Observable<PagedResp<KnowledgeListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.typeId != null) {
      httpParams = httpParams.set('typeId', search.typeId.toString());
    }
    if (search.companyCode != null) {
      httpParams = httpParams.set('companyCode', search.companyCode.toString());
    }
    if (search.keyword != null) {
      httpParams = httpParams.set('keyword', search.keyword.toString());
    }
    if (search.hottest != null) {
      httpParams = httpParams.set('hottest', search.hottest.toString());
    }
    return this.http.get<ApiResponse<PagedResp<KnowledgeListResp>>>(API.knowledgeV3, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 详情
   * @param id 知识库文章编号
   */
  detail(id: number): Observable<Knowledge> {
    return this.http.get<ApiResponse<Knowledge>>(API.knowledgeV3 + '/info/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(Knowledge, data.body.data);
    }));
  }

  /**
   * 知识库所有类型
   */
  types(): Observable<KnowledgeType[]> {
    return this.http.get<ApiResponse<KnowledgeType[]>>(API.knowledgeV3 + '/all-type', {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(KnowledgeType, data.body.data);
    }));
  }

}
