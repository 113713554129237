import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationMainComponent } from './information-main.component';
import { InformationComponent } from './information.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { InformationListComponent } from './information-list/information-list.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { InformationDetailComponent } from './information-detail/information-detail.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

@NgModule({
  declarations: [
    InformationComponent,
    InformationMainComponent,
    InformationListComponent,
    InformationDetailComponent,
  ],
    imports: [
        CommonModule,
        NzLayoutModule,
        SharedModule,
        RouterModule,
        TranslateModule,
        NzSpinModule,
        NzIconModule,
        NzSkeletonModule,
        NzTableModule,
        NzGridModule,
        NzButtonModule,
        NzToolTipModule,
        NzPopoverModule,
        NzTagModule,
        NzTabsModule,
        NzEmptyModule
    ]
})
export class InformationModule { }
