<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{'NewFolder' | translate}}
  </div>

  <!-- 文件名称 -->
  <app-property-input [displayMode]="'vertical'"
                      [required]="true"
                      [title]="'FolderName' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('FolderName' | translate)"
                      [(value)]="createFolder.name"></app-property-input>

  <div class="document-footer">
    <a nz-button nzType="primary"
       nzBlock nzSize="large"
       appThrottleClick (throttleClick)="create()"
       [disabled]="!canReply"
       [style]="!canReply ? 'opacity: 0.7;background: #1D64C4;color: white;' : ''">
      <span>{{ 'Confirm' | translate }}</span>
    </a>
  </div>
</div>
