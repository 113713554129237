import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleCalendarComponent } from './schedule-calendar/schedule-calendar.component';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzCalendarModule} from 'ng-zorro-antd/calendar';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {SharedModule} from '../shared/shared.module';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import {TranslateModule} from '@ngx-translate/core';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzButtonModule} from 'ng-zorro-antd/button';
import { ScheduleCreatedComponent } from './schedule-created/schedule-created.component';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzCardModule} from 'ng-zorro-antd/card';
import { ScheduleEditComponent } from './schedule-edit/schedule-edit.component';



@NgModule({
    declarations: [
        ScheduleCalendarComponent,
        ScheduleListComponent,
        ScheduleCreatedComponent,
        ScheduleEditComponent
    ],
    exports: [
        ScheduleCalendarComponent
    ],
  imports: [
    CommonModule,
    NzSpinModule,
    NzCalendarModule,
    NzBadgeModule,
    SharedModule,
    TranslateModule,
    NzToolTipModule,
    NzButtonModule,
    NzGridModule,
    NzCardModule
  ]
})
export class ScheduleModule { }
