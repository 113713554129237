<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      <div *ngIf="signer === 'SALES'">{{'ConsultantSignature' | translate}}</div>
      <div *ngIf="signer === 'OWNER'">{{'ClientSignature' | translate}}</div>
    </div>
    <div class="centre-container">
      <div class="signature-area">
        <app-signature-pad *ngIf="signedStatus === 'UNSIGNED'" [options]="signaturePadOptions"></app-signature-pad>
        <img *ngIf="signedStatus !== 'UNSIGNED'"
             [width]="signaturePadOptions.canvasWidth"
             [height]="signaturePadOptions.canvasHeight"
             [src]="signatureUrl" alt=""/>
      </div>
    </div>
    <div *ngIf="canRewrite" class="centre-container" style="margin: 20px 0 0 0">
      <div>
        <label nz-checkbox [(ngModel)]="agreed"></label>
        <span style="margin-left: 10px">{{'PleaseReadFnaDeclaration' | translate}}
          <a appThrottleClick (throttleClick)="showDisclaimerModal('', disclaimerTitle, disclaimerContent, disclaimerFooter)">『{{'Declaration' | translate}}』</a>
          ，{{'BeforeYouSign' | translate}}</span>
      </div>
    </div>
    <div class="centre-container">
      <div nz-row style="width: fit-content; height: 50px; margin-top: 20px">
        <div nz-col nzSpan="12" class="footer-button signature-button">
          <a nz-button nzType="link" nzBlock nzSize="large" [disabled]="!canRewrite"
             appThrottleClick (throttleClick)="onRewrite(rewriteTitle, rewriteContent, rewriteFooter);"
             [style]="!canRewrite ? 'opacity: 0.5' : ''">
            <span style="color: #1d64c4">{{'Rewrite' | translate}}</span>
          </a>
        </div>
        <div nz-col nzSpan="12" class="footer-button-primary signature-button">
          <a nz-button nzType="link" nzBlock nzSize="large" [disabled]="!canConfirm"
             appThrottleClick (throttleClick)="onConfirm()"
             [style]="!canConfirm ? 'opacity: 0.5' : ''">
            <span style="color: white">{{'Confirm' | translate}}</span>
          </a>
        </div>
      </div>
    </div>
  </nz-spin>
</div>

<ng-template #disclaimerTitle>
  <span style="font-size: 24px">{{'Declaration' | translate}}</span>
</ng-template>
<ng-template #disclaimerContent let-params>
  <div style="overflow-y: scroll; height: 580px">
    <p>{{ 'FnaDeclarationText1' | translate }}</p>
    <p>{{ 'FnaDeclarationText2' | translate }}</p>
    <p>{{ 'FnaDeclarationText3' | translate }}</p>
    <p>{{ 'FnaDeclarationText4' | translate }}</p>
    <p>{{ 'FnaDeclarationText5' | translate }}</p>
    <p>{{ 'FnaDeclarationText6' | translate }}</p>
  </div>
</ng-template>
<ng-template #disclaimerFooter let-ref="modalRef">
  <button #agreeButton id="agreeButton" nz-button class="disclaimer-button disclaimer-agree-button" nzType="default"  nzShape="round" appThrottleClick (throttleClick)="onDisclaimerConfirm(ref)" autofocus="autofocus">
    {{'Confirm' | translate}}
  </button>
</ng-template>

<ng-template #rewriteTitle>
  <span>{{'Tips' | translate}}</span>
</ng-template>
<ng-template #rewriteContent let-params>
  <p>{{'SignatureRewriteTips' | translate}}</p>
</ng-template>
<ng-template #rewriteFooter let-ref="modalRef" let-params>
  <button nz-button class="disclaimer-button" appThrottleClick (throttleClick)="ref.destroy()">{{'No' | translate}}</button>
  <button nz-button nzType="primary" class="disclaimer-button" appThrottleClick (throttleClick)="onRewriteConfirm(ref, params)">
    {{'Yes' | translate}}
  </button>
</ng-template>
