<div *ngIf="fnaQuestion.title" style="margin-bottom: 10px">
  <span class="question-title">{{fnaQuestion.title | translate}}</span>
  <img *ngIf="fnaQuestion.needFill" alt="" width="24" src="assets/images/ic_required.svg">
</div>

<div *ngIf="fnaQuestion.questionTitle" class="sub-section-title" style="margin-top: 0">
  <span [class]="'question-title'">
    {{fnaQuestion.questionTitle | translate}}
  </span>
  <img *ngIf="fnaQuestion.needFill" alt="" width="24" src="assets/images/ic_required.svg">
</div>

<div class="wrap-row" *ngFor="let answer of fnaQuestion.answers; let answerIndex = index;">
  <div>
    <div [ngSwitch]="answer.moduleType" style="padding: 8px 0 8px 0;">
      <div *ngSwitchCase="'CHECKBOX'" class="box-row">
        <label nz-checkbox [nzId]="answer.key" [(ngModel)]="answer.tick" [disabled]="disabled" (nzCheckedChange)="onAnswerCheckbox(fnaQuestion, answerIndex, $event)">
          <span class="description">{{answer.description | translate}}</span>
        </label>
      </div>
      <div *ngSwitchCase="'CHECKBOX_WITH_INPUT'" class="box-row">
        <label nz-checkbox [nzId]="answer.key" [(ngModel)]="answer.tick" [disabled]="disabled" (nzCheckedChange)="onAnswerCheckbox(fnaQuestion,answerIndex, $event)">
          <span class="description">{{answer.description | translate}}</span>
        </label>
        <div *ngIf="answer.inputBox">
          <app-answer-input-boxes style="display: flex;"
                                  [(inputBox)]="answer.inputBox" [disabled]="disabled"
                                  (inputBoxChange)="onQuestionChange(answer,answerIndex)"></app-answer-input-boxes>
        </div>
      </div>
      <div *ngSwitchCase="'RADIO'" class="radio-wrap-box">
        <label nz-radio [nzValue]="answer.key" [(ngModel)]="answer.tick" [disabled]="disabled" appThrottleClick (throttleClick)="onAnswerRadio(fnaQuestion, answerIndex)">
          <span class="description">{{answer.description | translate}}</span>
        </label>
      </div>
      <div *ngSwitchCase="'RADIO_WITH_INPUT'" class="box-row radio-wrap-box">
        <label nz-radio [nzValue]="answer.key" [(ngModel)]="answer.tick" appThrottleClick (throttleClick)="onAnswerRadio(fnaQuestion, answerIndex)" [disabled]="disabled">
          <span class="description">{{answer.description | translate}}</span>
        </label>
        <div *ngIf="answer.inputBox">
          <app-answer-input-boxes [(inputBox)]="answer.inputBox" (inputBoxChange)="onQuestionChange(answer,answerIndex)" [disabled]="disabled"></app-answer-input-boxes>
        </div>
      </div>
      <div *ngSwitchCase="'INPUT_BOX'" class="box-row">
        <div class="description">{{answer.description | translate}}</div>
        <div *ngIf="answer.inputBox">
          <app-answer-input-boxes [(inputBox)]="answer.inputBox" (inputBoxChange)="onQuestionChange(answer, answerIndex)" [disabled]="disabled"></app-answer-input-boxes>
        </div>
      </div>
      <div *ngSwitchCase="'INPUT_BOX_WITH_HEADLINE'" class="box-row">
        <div class="headline">{{answer.headline}}</div>
        <div class="description">{{answer.description | translate}}</div>
        <div *ngIf="answer.inputBox">
          <app-answer-input-boxes [(inputBox)]="answer.inputBox" (inputBoxChange)="onQuestionChange(answer,answerIndex)" [disabled]="disabled"></app-answer-input-boxes>
        </div>
      </div>
    </div>
  </div>
</div>
