<div class="drawer-container">
  <nz-spin [nzSpinning]="spinning">
    <div class="drawer-title" style="margin-bottom: 40px">
      <nz-breadcrumb nzSeparator=">" style="font-size: 20px;line-height: 22px">
        <nz-breadcrumb-item>{{'ContactsInfo' | translate}}</nz-breadcrumb-item>
        <nz-breadcrumb-item>{{'CustomerRecords' | translate}}</nz-breadcrumb-item>
        <nz-breadcrumb-item>{{'RecordDetails' | translate}}</nz-breadcrumb-item>
      </nz-breadcrumb>
    </div>
    <div class="detail">
      <div class="detail-header">
        <span>{{dataInfo.status}}</span>
        <span class="textColor">{{dataInfo.followDate}}</span>
      </div>
      <div class="detail-content">
        <div>
          <span class="textColor">{{'ClientName' | translate}}:</span> &nbsp;<span>{{dataInfo.name}}</span>
        </div>
        <div>
          <span class="textColor">{{'MeetingLocation' | translate}}:</span> &nbsp;<span>{{dataInfo.place}}</span>
        </div>
        <div>
          <span class="textColor">{{'Referee' | translate}}:</span> &nbsp;<span>{{dataInfo.accountName}}</span>
        </div>
        <div>
          <span class="textColor">{{'Matter' | translate}}:</span> &nbsp;<span>{{dataInfo.matter}}</span>
        </div>
        <div>
          <span class="textColor">{{'Product' | translate}}:</span>
          <span *ngIf="dataInfo.productNames && dataInfo.productNames.length > 0">
            <span> {{ dataInfo.productNames.join('，') }}</span>
          </span>
        </div>
        <div>
          <span class="textColor">{{'Remark' | translate}}:</span> &nbsp;<span>{{dataInfo.remark}}</span>
        </div>
      </div>
      <nz-tabset [nzSize]="'small'" [nzCentered]="false">
        <nz-tab [nzTitle]=attachments>
          <app-attachments [attachments]="dataInfo.attachments"
                           [showEmpty]="true"
                           [readonly]="true"></app-attachments>
        </nz-tab>
        <ng-template #attachments>
          {{'Attachments' | translate}}
        </ng-template>
        <nz-tab [nzTitle]=leaveMessage>

          <div>
            <!--  内容回填-->
            <div class="ticket-reply" *ngIf="dataInfo.replyListVOS.length >0">
              <div *ngFor="let item of dataInfo.replyListVOS" class="list-item">
                <div class="list-top">
<!--                  <nz-avatar class="list-top-avatar" style="margin-right: 20px;" [nzSize]="36" [nzSrc]="''"></nz-avatar>-->
                  <nz-avatar class="list-top-avatar" style="margin-right: 20px;" [nzSize]="36" nzSrc="assets/images/default_avatar.png"></nz-avatar>
                  <div class="list-top-name" style="margin-right: 10px;">{{ item.accountName }}</div>
                  <span class="list-top-myReply"
                        *ngIf="item.mySelf"
                        style="color: #4B84E5; font-size: 12px; border: 1px solid #4B84E5; border-radius: 6px">{{ 'Oneself' | translate }}</span>
                </div>
                <div class="list-bottom">
                  <div class="ticket-reply-content" [innerHTML]="item.content | html"></div>
                  <app-attachments *ngIf="item.attachments?.length > 0"
                                   [attachments]="item.attachments"
                                   [readonly]="true"></app-attachments>
                </div>
              </div>
            </div>
            <!--          添加回复记录-->
            <div class="">
              <div nz-row style="width: 100%;">
                <div nz-col nzSpan="24">
                  <div class="section-wrapper" style="margin: 15px 0px 10px 0px">
                    <app-property-input-area [bottomLineVisible]="false"
                                             [title]="'Content' | translate"
                                             [required]="true"
                                             [placeholder]="('PleaseEnter' | translate) + ('ReplyContent' | translate)"
                                             [(value)]="addReplyFollow.content"></app-property-input-area>
                  </div>
                </div>
                <div nz-col nzSpan="24" style="padding: 0px 0px">
                  <app-attachments [attachments]="addReplyFollow.attachments"
                                   [uploadTokenReq]="uploadTokenReq"
                                   [isPreview]="true"
                                   (valueChange)="replyAttachmentsChange($event)"
                                   [showEmpty]="false"></app-attachments>
                </div>
                <div nz-col nzSpan="24" class="footer-button-primary"
                     style="height: 50px; border-radius: 10px; margin-top: 15px">
                  <a nz-button nzType="link" nzBlock nzSize="large" nzShape="round"
                     appThrottleClick (throttleClick)="reply()"
                     [disabled]="!canReply"
                     [style]="!canReply ? 'opacity: 0.5' : ''">
                    <span>{{ 'Reply' | translate }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nz-tab>
        <ng-template #leaveMessage>
          {{'LeaveMessage' | translate}}
        </ng-template>
      </nz-tabset>
    </div>
  </nz-spin>
  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onEdit()">
          <span>{{'Edit' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
