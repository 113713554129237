import { Component, OnInit } from '@angular/core';
import {PagedReq, PagedResp} from '../../api/types';
import {LeaveWordListRes} from '../leave-word-types';
import {LeaveWordService} from '../leave-word.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {LeaveWordDetailComponent} from '../leave-word-detail/leave-word-detail.component';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-leave-word-table',
  templateUrl: './leave-word-table.component.html',
  styleUrls: ['./leave-word-table.component.less']
})
export class LeaveWordTableComponent implements OnInit {
  loading = false;
  spinning = false;
  isVisible = false;
  deleteId: number;
  listData: PagedResp<LeaveWordListRes>;
  search: PagedReq = new PagedReq();
  constructor(private leaveWordService: LeaveWordService,
              private drawerService: NzDrawerService,
              private toastr: ToastrService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.getList();
  }

  onSearch(pageNum: number): void{
    this.search.pageNum = pageNum;
    this.loading = true;
    this.leaveWordService.leaveWordList(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.listData = data;
        },
        error => {
          this.loading = false;
        });
  }

  onDetail(num: number): void {
    this.leaveWordService.detailsLeaveWord(num)
      .subscribe(
        data => {
          const drawerRef = this.drawerService.create<LeaveWordDetailComponent, { value: any }, string>({
            nzWidth: 600,
            nzContent: LeaveWordDetailComponent,
            nzMask: true,
            nzContentParams: {
              detailData: data
            }
          });
          drawerRef.afterClose.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });
        },
        error => {
          console.log(error);
        }
      );
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  onDelete(num: number): void {
    this.deleteId = num;
    this.isVisible = true;
  }

  onReload(): void {
    console.log('-');
    this.search = new PagedReq();
    this.getList();
  }

  handleOk(): void {
    this.isVisible = false;
    this.loading = true;
    this.leaveWordService.deleteLeaveWord(this.deleteId)
      .subscribe(
        data => {
          this.loading = false;
          if (data) {
            this.translate.get('DeleteSuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          }
          this.onReload();
        },
        error => {
          this.loading = false;
          console.log(error);
        }
      );
  }

  getList(): void {
    this.loading = true;
    this.leaveWordService.leaveWordList(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.listData = data;
          console.log(this.listData);
        },
        error => {
          this.loading = false;
        }
      );
  }

}
