import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuotationComponent } from './quotation.component';
import { RouterModule } from '@angular/router';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SharedModule } from '../shared/shared.module';
import { QuotationsComponent } from './quotations/quotations.component';
import { QuotationMainComponent } from './quotation-main.component';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzSliderModule} from 'ng-zorro-antd/slider';
import {FormsModule} from '@angular/forms';
import { QuotationProductSelectionComponent } from './quotation-product-selection/quotation-product-selection.component';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzTagModule} from 'ng-zorro-antd/tag';



@NgModule({
  declarations: [
    QuotationComponent,
    QuotationMainComponent,
    QuotationsComponent,
    QuotationProductSelectionComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        NzLayoutModule,
        TranslateModule,
        NzButtonModule,
        NzIconModule,
        SharedModule,
        NzSpinModule,
        NzToolTipModule,
        NzGridModule,
        NzSliderModule,
        FormsModule,
        NzSkeletonModule,
        NzTableModule,
        NzTagModule,
    ]
})
export class QuotationModule { }
