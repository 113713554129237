<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'FnaForm' | translate}}
    </div>

    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'PersonalData' | translate}}</span>
    </div>

    <!-- 顧問姓名 -->
    <app-property-input [title]="'ConsultantName' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('ConsultantName' | translate)"
                        [required]="true"
                        [(value)]="fna.personalData.accountName"></app-property-input>

    <!-- 理財顧問註冊編號 -->
    <app-property-input [title]="'ConsultantRegistrationNo' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('ConsultantRegistrationNo' | translate)"
                        [required]="true"
                        [(value)]="fna.personalData.registrationNumber"></app-property-input>

    <!-- 理財顧問内部編码 -->
    <app-property-input [title]="'ConsultantNo' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('ConsultantNo' | translate)"
                        [(value)]="fna.personalData.number"></app-property-input>

    <!-- 客户名称 -->
    <app-property-input [title]="'ClientName' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('ClientName' | translate)"
                        [required]="true"
                        [(value)]="fna.personalData.customerName"></app-property-input>

    <!-- 稅務國家 -->
    <app-property-input [title]="'CountryOfTaxResidence' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('CountryOfTaxResidence' | translate)"
                        [required]="true"
                        [(value)]="fna.personalData.taxCountry"></app-property-input>

    <!-- 就業情況 -->
    <app-property-select [title]="'EmploymentStatus' | translate"
                         [disabled]="!canSave"
                         [placeholder]="('PleaseSelect' | translate) + ('EmploymentStatus' | translate)"
                         [options]="employmentStatusOptions"
                         [required]="true"
                         [(value)]="fna.personalData.employment"></app-property-select>

    <!-- 就業情況（其他）-->
    <app-property-input *ngIf="fna.personalData.employment === 'OTHER'"
                        [disabled]="!canSave"
                        [title]="('EmploymentStatus' | translate) + ' - ' + ('Other' | translate)"
                        [placeholder]="('PleaseEnter' | translate) + ('EmploymentStatus' | translate)"
                        [(value)]="fna.personalData.otherEmployment"></app-property-input>

    <!-- 是否已退休 -->
    <app-property-switch *ngIf="!instance.isFoneOrDev()"
                         [title]="'IsRetired' | translate"
                         [disabled]="!canSave"
                         [(value)]="fna.personalData.isRetired"></app-property-switch>

    <!-- 預計退休年齡 -->
    <app-property-input *ngIf="instance.isFoneOrDev() ? false : (!fna.personalData.isRetired)" [title]="('ExpectedRetirementAge' | translate)"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('ExpectedRetirementAge' | translate)"
                        [(value)]="fna.personalData.retiredAge"></app-property-input>

    <div *ngIf="!instance.isFone()">
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'ExistingCoverage' | translate}}</span>
      </div>

      <!-- 退休金(雇主供款) -->
      <app-property-input [title]="'PensionEmployer' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('PensionEmployer' | translate)"
                          [(value)]="fna.existingSecurity.employerPension"></app-property-input>

      <!-- 退休金(雇员供款) -->
      <app-property-input [title]="'PensionEmployee' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('PensionEmployee' | translate)"
                          [(value)]="fna.existingSecurity.employeePension"></app-property-input>

      <!-- 雇主提供之保险-医疗保障 -->
      <app-property-yes-or-not [title]="'EmployerProvidedMedicalCoverage' | translate"
                               [disabled]="!canSave"
                               [(value)]="fna.existingSecurity.hasMedicalSecurity"></app-property-yes-or-not>

      <!-- 雇主提供之保险-人寿保险 -->
      <app-property-yes-or-not [title]="'EmployerProvidedLifeInsurance' | translate"
                               [disabled]="!canSave"
                               [(value)]="fna.existingSecurity.hasLifeInsurance"></app-property-yes-or-not>

      <!-- 人寿保险 - 总保额（港币） -->
      <app-property-input-number [title]="'LifeInsuranceTotalSumInsuredInHKD' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('LifeInsuranceTotalSumInsuredInHKD' | translate)"
                                 [(value)]="fna.existingSecurity.lifeInsurance"></app-property-input-number>

      <!-- 危疾保险 - 总保额（港币） -->
      <app-property-input-number [title]="'CriticalIllnessInsuranceTotalSumInsuredInHKD' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('CriticalIllnessInsuranceTotalSumInsuredInHKD' | translate)"
                                 [(value)]="fna.existingSecurity.diseaseInsurance"></app-property-input-number>

      <!-- 危疾保险 - 总保额（港币） -->
      <app-property-input-number [title]="'AccidentInsuranceTotalSumInsuredInHKD' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('AccidentInsuranceTotalSumInsuredInHKD' | translate)"
                                 [(value)]="fna.existingSecurity.accidentInsurance"></app-property-input-number>

      <!-- 医疗保险 -->
      <app-property-input [title]="'MedicalInsurance' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('MedicalInsurance' | translate)"
                          [(value)]="fna.existingSecurity.typesMedicalInsurance"></app-property-input>

    </div>

    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'FamilyStatus' | translate}}</span>
    </div>

    <div class="sub-section-title">
      <span>{{'Father' | translate}}</span>
    </div>

    <!-- 年齡-父親 -->
    <app-property-input [title]="'Age' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('Age' | translate)"
                        [(value)]="fna.familyMembers.father.age"></app-property-input>

    <app-property-yes-or-not [title]="'NeedDependent' | translate"
                             [disabled]="!canSave"
                             [(value)]="fna.familyMembers.father.needProvide"></app-property-yes-or-not>

    <div class="sub-section-title">
      <span>{{'Mother' | translate}}</span>
    </div>

    <!-- 年齡-母亲 -->
    <app-property-input [title]="'Age' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('Age' | translate)"
                        [(value)]="fna.familyMembers.mother.age"></app-property-input>

    <app-property-yes-or-not [title]="'NeedDependent' | translate"
                             [disabled]="!canSave"
                             [(value)]="fna.familyMembers.mother.needProvide"></app-property-yes-or-not>

    <div class="sub-section-title">
      <span>{{'Spouse' | translate}}</span>
    </div>

    <!-- 年齡-配偶 -->
    <app-property-input [title]="'Age' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('Age' | translate)"
                        [disabled]="!canSave"
                        [(value)]="fna.familyMembers.spouse.age"></app-property-input>

    <app-property-yes-or-not [title]="'NeedDependent' | translate"
                             [disabled]="!canSave"
                             [(value)]="fna.familyMembers.spouse.needProvide"></app-property-yes-or-not>

    <div *ngFor="let child of fna.familyMembers.children; let i = index">
      <div class="sub-section-title" style="display: flex; flex-direction: row; align-items: center" *ngIf="canSave">
        <span style="flex: 1; text-align: left">{{'Children' | translate}} - {{i + 1}}</span>
        <a appThrottleClick  (throttleClick)="onRemoveFamilyMemberChild(i); $event.stopPropagation();">
          <img alt="" width="20" src="assets/images/ic_delete.svg">
        </a>
      </div>

      <app-property-input [title]="'Age' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('Age' | translate)"
                          [(value)]="fna.familyMembers.children[i].age"></app-property-input>

      <app-property-yes-or-not [title]="'NeedDependent' | translate"
                               [disabled]="!canSave"
                               [(value)]="fna.familyMembers.children[i].needProvide"></app-property-yes-or-not>

    </div>

    <div class="add-children-button"
         *ngIf="canSave"
         appThrottleClick (throttleClick)="onAddFamilyMemberChild(); $event.stopPropagation();">
      <div style="margin-right: 10px;">{{'AddChildren' | translate}}</div>
      <i nz-icon nzType="app_add_circle" nzTheme="outline"></i>
    </div>

    <div *ngIf="!instance.isFone()">
      <div class="section-title required-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'FinancialInformation' | translate}}</span>
        <img alt="" width="24" src="assets/images/ic_required.svg">
      </div>

      <div class="sub-section-title">
        <span>{{'AverageMonthlyIncomeInThePastTwoYears' | translate}}</span>
      </div>

      <!-- 薪金 / 佣金 / 花红 -->
      <app-property-input-number [title]="'Salary/Commission/Bonus' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('Salary/Commission/Bonus' | translate)"
                                 (valueChange)="onIncomesChange()"
                                 [(value)]="fna.financialData.salary"></app-property-input-number>

      <!-- 租金收入 -->
      <app-property-input-number [title]="'RentalIncome' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('RentalIncome' | translate)"
                                 (valueChange)="onIncomesChange()"
                                 [(value)]="fna.financialData.rentalIncome"></app-property-input-number>

      <!-- 其他收入項目 -->
      <app-property-input [title]="'OtherIncomeItem' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('OtherIncomeItem' | translate)"
                          [(value)]="fna.financialData.otherIncomeExplain"></app-property-input>

      <!-- 其他收入 -->
      <app-property-input-number [title]="'OtherIncome' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('OtherIncome' | translate)"
                                 (valueChange)="onIncomesChange()"
                                 [(value)]="fna.financialData.otherIncome"></app-property-input-number>


      <!-- 每月總平均收入 -->
      <app-property-text [title]="'TotalMonthlyIncome' | translate"
                         [value]="fna.financialData.monthlyIncome"></app-property-text>

      <div class="sub-section-title">
        <span>{{'AverageMonthlyExpenditureInThePastTwoYears' | translate}}</span>
      </div>

      <!-- 個人開支 -->
      <app-property-input-number [title]="'PersonalExpenditures' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('PersonalExpenditures' | translate)"
                                 (valueChange)="onExpendituresChange()"
                                 [(value)]="fna.financialData.personalSpending"></app-property-input-number>

      <!-- 家庭開支 -->
      <app-property-input-number [title]="'HouseholdExpenditures' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('HouseholdExpenditures' | translate)"
                                 (valueChange)="onExpendituresChange()"
                                 [(value)]="fna.financialData.householdSpending"></app-property-input-number>

      <!-- 按揭/借貸還款 -->
      <app-property-input-number [title]="'Mortgage/LoanRepayment' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('Mortgage/LoanRepayment' | translate)"
                                 (valueChange)="onExpendituresChange()"
                                 [(value)]="fna.financialData.mortgage"></app-property-input-number>

      <!-- 保險 -->
      <app-property-input-number [title]="'Insurance' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('Insurance' | translate)"
                                 (valueChange)="onExpendituresChange()"
                                 [(value)]="fna.financialData.insurance"></app-property-input-number>

      <!-- 其他支出项目 -->
      <app-property-input [title]="'OtherExpenditureItem' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('OtherExpenditureItem' | translate)"
                          [(value)]="fna.financialData.otherSpendingExplain"></app-property-input>

      <!-- 其他支出 -->
      <app-property-input-number [title]="'OtherExpenditures' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('OtherExpenditures' | translate)"
                                 (valueChange)="onExpendituresChange()"
                                 [(value)]="fna.financialData.otherSpending"></app-property-input-number>

      <!-- 每月總平均支出 -->
      <app-property-text [title]="'TotalMonthlyExpenditure' | translate"
                         [value]="fna.financialData.monthlySpending"></app-property-text>

      <!-- 估計每月剩餘/超支 -->
      <app-property-text [title]="'EstimatedMonthlySurplus/Deficit' | translate"
                         [value]="fna.financialData.monthRemaining"></app-property-text>

      <div class="section-title required-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'Assets' | translate}}</span>
        <img alt="" width="24" src="assets/images/ic_required.svg">
      </div>

      <div class="sub-section-title">
        <span>{{'CurrentAssets' | translate}} (HKD)</span>
      </div>

      <!-- 現金及存款 -->
      <app-property-input-number [title]="'CashAndDeposits' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('CashAndDeposits' | translate)"
                                 (valueChange)="onCurrentAssetsChange()"
                                 [(value)]="fna.assets.cash"></app-property-input-number>

      <!-- 變現能力高的投資 (例如: 股票或債券) -->
      <app-property-input-number [title]="'InvestmentsWithHighLiquidity' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('InvestmentsWithHighLiquidity' | translate)"
                                 (valueChange)="onCurrentAssetsChange()"
                                 [(value)]="fna.assets.investmentAssets"></app-property-input-number>

      <!-- 其他流動資產項目说明 -->
      <app-property-input [title]="'OtherCurrentAssetsItem' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('OtherCurrentAssetsItem' | translate)"
                          [(value)]="fna.assets.otherCurrentAssetsExplain"></app-property-input>

      <!-- 其他流動資產 -->
      <app-property-input-number [title]="'OtherCurrentAssets' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('OtherCurrentAssets' | translate)"
                                 (valueChange)="onCurrentAssetsChange()"
                                 [(value)]="fna.assets.otherCurrentAssets"></app-property-input-number>

      <!-- 流動資產总额 -->
      <app-property-text [title]="'CurrentAssetsTotal' | translate"
                         [value]="fna.assets.totalCurrentAssets"></app-property-text>

      <div class="sub-section-title">
        <span>{{'Non-CurrentAssets' | translate}} (HKD)</span>
      </div>

      <!-- 物業 -->
      <app-property-input-number [title]="'Property' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('Property' | translate)"
                                 (valueChange)="onNonCurrentAssetsChange()"
                                 [(value)]="fna.assets.property"></app-property-input-number>

      <!-- 其他非流动资产项目说明 -->
      <app-property-input [title]="'OtherNon-CurrentAssetsItem' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('OtherNon-CurrentAssetsItem' | translate)"
                          [(value)]="fna.assets.otherNonCurrentAssetsExplain"></app-property-input>

      <!-- 其他非流动资产 -->
      <app-property-input-number [title]="'OtherNon-CurrentAssets' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('OtherNon-CurrentAssets' | translate)"
                                 (valueChange)="onNonCurrentAssetsChange()"
                                 [(value)]="fna.assets.otherNonCurrentAssets"></app-property-input-number>

      <!-- 非流動資產总额 -->
      <app-property-text [title]="'Non-CurrentAssetsTotal' | translate"
                         [value]="fna.assets.totalNonCurrentAssets"></app-property-text>

      <div class="sub-section-title">
        <span>{{'Liabilities' | translate}} (HKD)</span>
      </div>

      <!-- 按揭貸款 -->
      <app-property-input-number [title]="'MortgageLoan' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('MortgageLoan' | translate)"
                                 (valueChange)="onLiabilitiesChange()"
                                 [(value)]="fna.assets.mortgageLoans"></app-property-input-number>

      <!-- 私人/信用卡 貸款 -->
      <app-property-input-number [title]="'CreditCardLoan' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('CreditCardLoan' | translate)"
                                 (valueChange)="onLiabilitiesChange()"
                                 [(value)]="fna.assets.privateLoans"></app-property-input-number>

      <!-- 其他負債項目 -->
      <app-property-input [title]="'OtherLiabilitiesItem' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('OtherLiabilitiesItem' | translate)"
                          [(value)]="fna.assets.otherLoansExplain"></app-property-input>

      <!-- 其他非流动资产 -->
      <app-property-input-number [title]="'OtherLiabilities' | translate"
                                 [disabled]="!canSave"
                                 [placeholder]="('PleaseEnter' | translate) + ('OtherLiabilities' | translate)"
                                 (valueChange)="onLiabilitiesChange()"
                                 [(value)]="fna.assets.otherLoans"></app-property-input-number>

      <!-- 非流動資產总额 -->
      <app-property-text [title]="'TotalDebt' | translate"
                         [value]="fna.assets.totalLoans"></app-property-text>

      <div class="sub-section-title">
        <span>{{'EstimatedNetAssets' | translate}} (HKD)</span>
      </div>

      <!-- 估計淨資產 -->
      <app-property-text [title]="'Amount' | translate"
                         [value]="fna.assets.netAsset"></app-property-text>

    </div>

    <div *ngIf="!instance.isFoneOrDev()" class="sub-section-title">
      <span>{{'Remark' | translate}}</span>
    </div>

    <app-property-input-area *ngIf="!instance.isFoneOrDev()"
                             [title]="('PleaseEnter' | translate) + ('Remark' | translate)"
                             [disabled]="!canSave"
                             placeholder=""
                             [(value)]="fna.assets.remark"></app-property-input-area>

    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{ ('FinancialNeedAnalysis' | translate)}}</span>
    </div>

    <div *ngFor="let fnaQuestion of fnaQuestions; let i = index">
      <app-fna-question [fnaQuestion]="fnaQuestion" (fnaQuestionChange)="onFnaQuestionChange($event, i)"
                        [disabled]="!canSave"
                        (subQuestionEvent)="onSubQuestionEvent($event)">
      </app-fna-question>
    </div>

    <div *ngIf="!instance.isFoneOrDev()" style="margin: 10px 0 0 0; font-weight: bold">
      <div style="font-size: 16px">{{'MaxPolicyAmount' | translate}}</div>
      <div style="margin: 5px 0 0 0;">AP： <span style="color: #1d64c4">{{AP | number: '1.2'}}</span></div>
      <div style="margin: 5px 0 0 0;">SP： <span style="color: #1d64c4">{{SP | number: '1.2'}}</span></div>
    </div>
  </nz-spin>

  <div *ngIf="canSave" class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onTemporarySave()">
          <span>{{'TemporarySave' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" [disabled]="loading"
           appThrottleClick (throttleClick)="onSave()"
           [style]="loading ? 'opacity: 0.5' : ''">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
