import {Component, Input, OnInit} from '@angular/core';
import {Attribute, AttributeSearch, Details} from '../../proposal-compare/proposal-compare';
import {StorageService} from '../../shared/service/storage.service';
import {DownloadService} from '../../shared/service/download.service';
import {
  AttributeScore,
  CompanyHistoryDetailRes, CreateProductCompare,
  Groups, Product, ProductCompareReq, ProductSearch,

} from '../product-types';
import {ProductService} from '../product.service';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { Currency, SmokeCondition } from '../../api/types';
import { MetadataService } from '../../shared/service/metadata.service';
import { ProductComparisonResultComponent } from '../product-comparison-result/product-comparison-result.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { plainToInstance } from 'class-transformer';

@Component({
  selector: 'app-product-comparison-detail',
  templateUrl: './product-comparison-detail.component.html',
  styleUrls: ['./product-comparison-detail.component.less']
})
export class ProductComparisonDetailComponent implements OnInit {
  @Input()
  companyDetail: CompanyHistoryDetailRes = new CompanyHistoryDetailRes();
  sectionGroup: Groups[] = [];
  loading = false;
  isEdit = false;
  scoreVisible = false;
  drawerVisible = false;
  attributeScore: Attribute = new Attribute();
  attributeTitle: { name: string };
  isConfirmLoading = false;
  productCodes: string;
  search = new ProductSearch();
  productInfo = new Product();
  currencyOptions: PropertySelectOption[] = [];
  paymentTermOptions: PropertySelectOption[] = [];
  genderOptions: PropertySelectOption[] = [];
  smokeOptions: PropertySelectOption[] = [];
  medicalLevelOptions: PropertySelectOption[] = [];
  deductibleOptions: PropertySelectOption[] = [];
  supplementaryBenefitOptions: PropertySelectOption[] = [];
  guaranteedAreaOptions: PropertySelectOption[] = [];
  compareData = new ProductCompareReq();
  requiredObj: any;

  constructor(private storageService: StorageService,
              private downloadService: DownloadService,
              private productService: ProductService,
              private metadataService: MetadataService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
  }

  onDownload(type: string): void {
    // this.btnLoading = true;
    this.loading = true;
    // console.log('-', type);
    let url = null;
    if (type === 'CN') {
      url = this.companyDetail.urlCn.filePath;
    }
    if (type === 'HK') {
      url = this.companyDetail.urlHk.filePath;
    }
    if (type === 'EN') {
      url = this.companyDetail.urlUs.filePath;
    }
    this.storageService.accessToken(url)
      .subscribe(
        data => {
          // console.log(data);
          this.downloadService.download(data.accessToken, this.companyDetail.urlCn.fileName);
          this.loading = false;
        },
        err => {
          this.loading = false;
        });
  }

  get uiTheme(): any {
    switch (this.companyDetail.categoryCode) {
      case 'SAVINGS':
        return {
          category: 'SAVINGS',
          categoryColor: 'rgba(231, 191, 136)',
          classes: {
            systemName: 'sa-system-name',
            table: 'sa-table',
            groupLifebee: 'sa-lifebee',
            groupSquare: 'sa-square',
            propertyKey: 'bg-sa-property-key',
            propertyValue: 'bg-sa-property-value'
          }
        };
      case 'CRITICAL_ILLNESS':
        return {
          category: 'CRITICAL ILLNESS',
          categoryColor: 'rgba(216, 128, 153)',
          classes: {
            systemName: 'ci-system-name',
            table: 'ci-table',
            groupLifebee: 'ci-lifebee',
            groupSquare: 'ci-square',
            propertyKey: 'bg-ci-property-key',
            propertyValue: 'bg-ci-property-value'
          }
        };
      default:
        return {
          category: '',
          categoryName: '',
          header: '',
          groupIcon: '',
          classes: {
            systemName: '',
            table: '',
            propertyKey: '',
            propertyValue: ''
          }
        };
    }
  }

  Number(score: any): number {
    return score ? Number(score) : score;
  }

  Edit(): void {
    this.isEdit = true;
  }

  onCancel(): void {
    this.isEdit = false;
  }

  handleCancel(): void {
    this.scoreVisible = false;
  }

  onSave(): void {
    const groups = [];
    const attributes = [];
    this.companyDetail.groups.forEach(e => {
      if (!e.checked) {
        groups.push(e.key);
      }
      if (e.attributes) {
        e.attributes.forEach(item => {
          if (!item.checked) {
            attributes.push(item.attribute);
          }
        });
      }
    });
    const params: CreateProductCompare = new CreateProductCompare();
    params.groups = Array.from(new Set(groups));
    params.attributes = Array.from(new Set(attributes));
    params.id = this.companyDetail.id;
    console.log(this.companyDetail.quotation);
    if (this.companyDetail.quotation) {
      Object.keys(this.companyDetail.quotation).forEach(key => {
        if (!!this.companyDetail.quotation[key]) {
          params[key] = this.companyDetail.quotation[key];
        }
      });
    }
    this.loading = true;
    this.productService.editCompany(params).subscribe(data => {
      this.productService.companyHistoryDetail(this.companyDetail.id).subscribe(
        companyDetail => {
          this.companyDetail = companyDetail;
          this.loading = false;
          this.isEdit = false;
        },
        error1 => {
          this.loading = false;
        }
      );

    }, err => {
      this.isEdit = false;

    });
  }

  onScoreDetail(index: any, group: any, value: any): void {
    if (this.isEdit === false) {
      return;
    }
    this.attributeTitle = {
      name: group.productNames[index],
    };
    this.productCodes = this.companyDetail.productCodes[index];

    this.attributeScore = JSON.parse(JSON.stringify(value));
    this.scoreVisible = true;
  }

  onRetry(): void {
    this.productService.retry(this.companyDetail.id).subscribe(data => {
      console.log(data);
    });
  }

  onScore($event: number): void {
    console.log($event);
    this.attributeScore.score = String($event);
  }

  handleOk(): void {
    this.isConfirmLoading = true;
    const params: AttributeScore = new AttributeScore();
    params.score = this.attributeScore.score || '0';

    if (this.companyDetail.id) {
      params.id = this.companyDetail.id;
    }
    if (this.attributeScore.attribute) {
      params.attribute = this.attributeScore.attribute;
    }
    if (this.productCodes) {
      params.productCode = this.productCodes;
    }
    console.log(params);
    this.productService.compareAttributeScore(params).subscribe(
      data => {
        this.productService.companyHistoryDetail(this.companyDetail.id).subscribe(
          companyDetail => {
            this.companyDetail = companyDetail;
            this.scoreVisible = false;
            this.isConfirmLoading = false;
          },
          error1 => {
            this.isConfirmLoading = false;
          }
        );
      },
      e => {
        this.isConfirmLoading = false;
      }
    );
  }

  onComparison(): void {
    this.getMainProductInfo();
    this.genderOptions = [];
    this.smokeOptions = [];
    const genders = this.metadataService.values('gender');
    for (const gender of genders) {
      this.genderOptions.push(new PropertySelectOption(gender.value, gender.key));
    }
    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'S'), SmokeCondition.S));
    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'NS'), SmokeCondition.NS));
    this.drawerVisible = true;
    if (this.companyDetail.quotation) {
      let params: ProductCompareReq = new ProductCompareReq();
      params = plainToInstance(ProductCompareReq, this.companyDetail.quotation);
      this.compareData = params;
    }
  }
  drawerClose(): void {
    this.drawerVisible = false;
    this.onDetail(this.companyDetail.id);
  }

  getMainProductInfo(): void {
    this.productService.detail(this.companyDetail.leftProductCode).subscribe(
      data => {
        console.log(data);
        this.productInfo = data;
        this.optionsMap(data);
      }, error => {
        console.log(error);
      }
    );
  }

  optionsMap(data: Product): void {
    if (data.quotationFieldRequirements && data.quotationFieldRequirements.length) {
      const isRequired = JSON.parse(JSON.stringify(data.quotationFieldRequirements));
      this.requiredObj = isRequired.reduce((total, c) => ({ ...total, [c.field]: c.required }), {});
    }
    this.compareData.currency = data.currencies && data.currencies.length ? data.currencies[0] : undefined;
    this.paymentTermOptions = [];
    data.paymentTerm?.forEach(term => {
      this.paymentTermOptions.push(new PropertySelectOption(term, term));
    });

    this.currencyOptions = [];
    data.currencies?.forEach(currency => {
      this.currencyOptions.push(new PropertySelectOption(currency, currency));
    });

    // 保障地區
    if (data.medicalOptions?.regions) {
      this.guaranteedAreaOptions = [];
      for (const region of data.medicalOptions?.regions) {
        this.guaranteedAreaOptions.push(new PropertySelectOption(this.metadataService.translate('medicalRegion', region), region));
      }
    }
    // 医疗险等级
    if (data.medicalOptions?.levels) {
      this.medicalLevelOptions = [];
      for (const level of data.medicalOptions?.levels) {
        this.medicalLevelOptions.push(new PropertySelectOption(this.metadataService.translate('medicalLevel', level), level));
      }
    }
    // 自付額
    if (data.medicalOptions?.deductibles && data.medicalOptions?.deductibles.length) {
      this.deductibleOptions = [];
      data.medicalOptions?.deductibles.forEach((deductible, index, array) => {
        if (deductible.currency === this.compareData.currency) {
          for (const amount of deductible.amounts) {
            this.deductibleOptions.push(new PropertySelectOption(amount.toString(), amount));
          }
        }
      });
    } else {
      setTimeout(() => {
        this.deductibleOptions.push(new PropertySelectOption('0', 0));
        this.compareData.deductible = 0;
      }, 500);
    }
    // 附加保障
    if (data.medicalOptions?.supplementaryBenefits) {
      this.supplementaryBenefitOptions = [];
      for (const supplementaryBenefit of data.medicalOptions?.supplementaryBenefits) {
        this.supplementaryBenefitOptions.push(new PropertySelectOption(this.metadataService.translate('supplementaryBenefit', supplementaryBenefit), supplementaryBenefit));
      }
    }
  }

  onCurrencyChange(currency: Currency): void {
    this.deductibleOptions = [];
    this.productInfo?.medicalOptions?.deductibles.forEach((deductible, index, array) => {
      if (deductible.currency === currency) {
        for (const amount of deductible.amounts) {
          this.compareData.deductible = null;
          this.deductibleOptions.push(new PropertySelectOption(amount.toString(), amount));
        }
      }
    });
  }

  goCompare(): void {
    this.compareData.historyId = this.companyDetail.id;
    this.compareData.leftProductCode = this.companyDetail.leftProductCode;
    this.compareData.rightProductCodes = this.companyDetail.rightProductCodes;
    this.compareData.withQuotation = true;
    const drawerRef = this.drawerService.create<ProductComparisonResultComponent, { value: string }, string>({
      nzWidth: '100vw',
      nzContent: ProductComparisonResultComponent,
      nzContentParams: {
        isPremiumComparison: true,
        compareParams: JSON.parse(JSON.stringify(this.compareData))
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onAgeChange(age: any): void {
    this.compareData.age = age;
  }

  onRedoCompareData(type: string): void {
    if (type === 'product') {
      this.compareData.currency = null;
      this.compareData.premiumTerm = null;
      this.compareData.amount = null;
      if (this.companyDetail.categoryCode === 'MEDICAL') {
        this.compareData.region = null;
        this.compareData.level = null;
        this.compareData.deductible = null;
        this.compareData.supplementaryBenefits = null;

      }
    } else {
      this.compareData.age = null;
      this.compareData.gender = null;
      this.compareData.smokeCondition = null;
    }
  }

  onDetail(id: number): void {
    this.productService.companyHistoryDetail(id)
      .subscribe(companyDetail => {
        console.log(companyDetail);
        this.companyDetail = companyDetail;
    });

  }
}
