import {Component, EventEmitter, OnInit} from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";

@Component({
  selector: 'app-booking-to-complete',
  templateUrl: './booking-to-complete.component.html',
  styleUrls: ['./booking-to-complete.component.less']
})
export class BookingToCompleteComponent implements OnInit {

  modalRef: NzModalRef<BookingToCompleteComponent, any>;

  bookingNo: string;

  onGoComplete: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onNotNowClick(): void {
    this.modalRef?.close();
  }

  onGoCompleteClick(): void {
    this.onGoComplete.emit('');
    this.modalRef?.close();
  }
}
