<div class="drawer-container">
  <div class="common-title">
    {{'PolicySelection' | translate}}
  </div>
  <nz-row [nzGutter]="12">
    <nz-col [nzSpan]="24">
      <app-property-input [displayMode]="'vertical'"
                          [title]="'PolicyNo' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('PolicyNo' | translate)"
                          [(value)]="search.policyNo"
                          (valueChange)="onSearchParamChange()"></app-property-input>
    </nz-col>
  </nz-row>
  <nz-row [nzGutter]="12">
    <nz-col [nzSpan]="24">
      <!-- 产品 -->
      <app-property-pick [displayMode]="'vertical'"
                         [title]="'ProductName' | translate"
                         appThrottleClick (throttleClick)="onProductSelection()"
                         [placeholder]="('PleaseSelect' | translate) + ('ProductName' | translate)"
                         [(value)]="search.product.productName"
                         (valueChange)="onSearchParamChange()"></app-property-pick>
    </nz-col>
  </nz-row>
  <div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
      <div *ngIf="!policys">
        <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
      </div>
      <div *ngIf="policys" style="margin-top: 30px; ">
        <nz-table [nzBordered]="false"
                  [nzOuterBordered]="true"
                  [nzShowPagination]="true"
                  [nzLoading]="loading"
                  [nzLoadingIndicator]="indicatorTemplate"
                  [nzFrontPagination]="false"
                  [nzData]="policys.list"
                  [nzPageIndex]="policys.pageNum"
                  [nzPageSize]="policys.pageSize"
                  [nzTotal]="policys.total"
                  [nzTitle]="tableHeader"
                  [nzFooter]="tableFooter"
                  (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
                  (nzPageIndexChange)="onSearch($event)">
          <ng-template #tableHeader>
            <nz-row>
              <nz-col [nzSpan]="12">
                <div class="list-header-title">{{'PolicyList' | translate}}
                  <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Reset' | translate"
                     style="margin-left: 5px"
                     appThrottleClick (throttleClick)="onReload()">
                    <em nz-icon nzType="reload" nzTheme="outline"></em>
                  </a>
                </div>
              </nz-col>
              <nz-col [nzSpan]="12">
                <div style="width: 100%; display: flex; justify-content: flex-end;">
                </div>
              </nz-col>
            </nz-row>
          </ng-template>
          <ng-template #tableFooter>
            <div class="table-title">
              {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ policys.total }}</span>
            </div>
          </ng-template>

          <thead>
          <tr>
            <th nzLeft nzWidth="50px"></th>
            <th><span nz-tooltip
                      [nzTooltipTitle]="'ProductCategory' | translate">{{'PolicyNo' | translate}}</span></th>
            <th><span nz-tooltip [nzTooltipTitle]="'ProductName' | translate">{{'ProductName' | translate}}</span></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let policy of policys.list; let i = index">
            <td
              [nzChecked]="setOfCheckedId.has(policy.policyId)"
              (nzCheckedChange)="onItemChecked(policy, $event)"
            ></td>
            <td>
              <span>{{ policy.policyNo }}</span>
            </td>
            <td>
              <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="policy.companyLogo" alt=""/>
              {{ policy.productName }}
            </td>
          </tr>
          </tbody>
        </nz-table>
      </div>
    </nz-spin>

  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onConfirm()">
          <em nz-icon nzType="check" nzTheme="outline"></em>
          <span>{{'Confirm' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
