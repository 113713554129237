<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!listData.list">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="listData.list" style="margin-top: 30px; ">
      <nz-table #nestedTable
                [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="listData.list"
                [nzPageIndex]="listData.pageNum"
                [nzPageSize]="listData.pageSize"
                [nzTotal]="listData.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'ProposalTemplate' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'NewTemplate' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onCreate()">
                  <img alt="" width="22" src="assets/images/ic_proposal_new.svg">
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ listData.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th [nzWidth]="'40px'"></th>
          <th nzWidth="150px"><span>{{'TemplateNo' | translate}}</span></th>
          <th nzWidth="200px"><span>{{'ClientName' | translate}}</span></th>
          <th><span>{{'State' | translate}}</span></th>
          <th><span>{{'Type' | translate}}</span></th>
          <th><span>{{'CreateTime' | translate}}</span></th>
          <th nzAlign="center" nzWidth="200px"><span>{{'Functions' | translate}}</span></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let item of listData.list; let i = index">
          <tr appThrottleClick (throttleClick)="onShowExpand(item)" style="cursor: pointer;">
            <td>
              <i nz-icon [nzType]="item.expand ? 'caret-down' : 'caret-right'" nzTheme="outline"></i>
            </td>
            <td>
              <div>{{ item.id }}</div>
            </td>
            <td>
              <span>
                {{ item.name }}
              </span>
            </td>
            <td>
              <div [ngSwitch]="item.status">
                <div>
                  <span *ngSwitchCase="'PENDING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'COMPLETED'" class="text-success">●</span>
                  <span *ngSwitchCase="'FAIL'" class="text-danger">●</span>
                  <span *ngSwitchCase="'DELETED'" class="text-cancel">●</span>
                  <span> {{ item.status | metadataTranslate: 'proposalCustomStatus' }}</span>
                </div>
              </div>
            </td>
            <td>{{ item.category}}</td>
            <td>{{ item.createTime | date:'yyyy-MM-dd HH:mm' }}</td>
            <td>
              <div class="functions" style="display: flex;justify-content: space-evenly;">
                <a *ngIf="item.status != 'PENDING'"
                   nz-button nzType="link" nzSize="small"
                   nz-tooltip [nzTooltipTitle]="'Retry' | translate"
                   appThrottleClick (throttleClick)="onRetry(item.id); $event.stopPropagation();">
                  <img alt="" width="20" src="assets/images/ic_refresh.svg">
                </a>
                <a *ngIf="item.status != 'PENDING' && item.status != 'FAIL'"
                   nz-button nzType="link" nzSize="small" nz-tooltip
                   [nzTooltipTitle]="'Preview' | translate"
                   appThrottleClick (throttleClick)="onPreview(item.url); $event.stopPropagation();">
                  <img alt="" width="20" src="assets/images/ic_view.svg">
                </a>
                <a *ngIf="item.status != 'PENDING' && item.status != 'FAIL'"
                   nz-button nzType="link" nzSize="small" nz-tooltip
                   [nzTooltipTitle]="'Download' | translate"
                   appThrottleClick (throttleClick)="onDownload(item.url); $event.stopPropagation();">
                  <img alt="" width="20" src="assets/images/ic_download.svg">
                </a>
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
                   appThrottleClick (throttleClick)="onDelete(item.id, tplTitle,tplContent); $event.stopPropagation();">
                  <img alt="" width="20" src="assets/images/ic_delete.svg">
                </a>
              </div>
            </td>
          </tr>
          <tr [nzExpand]="item.expand">
            <div class="inner-table" style="background-color: #FAFBFD;color: #8EA3C6;border-bottom: 1px solid #d4dded">
              <span>{{'ProposalName' | translate}}</span>
              <span>{{'ProposalID' | translate}}</span>
              <span>{{'Period' | translate}}</span>
              <span>{{'Gender' | translate}}</span>
              <span>{{'Age' | translate}}</span>
              <span>{{'Smoking' | translate}}</span>
              <span>{{'Premium' | translate}}</span>
            </div>
            <div class="inner-table" *ngFor="let info of item.proposalList; let j = index"
                 style="background-color: #FFFFFF;" [style]="j != item.proposalList.length - 1 ? 'border-bottom: 1px solid #d4dded' : '' ">
              <span>{{info.productName}}</span>
              <span>{{info.proposalId}}</span>
              <span>{{info.paymentTerm}}</span>
              <span>{{info.gender | metadataTranslate: 'gender'}}</span>
              <span>{{info.age}}</span>
              <span>{{info.smoke | metadataTranslate: 'smoke'}}</span>
              <span>{{info.premium}}{{info.currency}}</span>
            </div>
          </tr>
        </ng-container>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>

<!-- 删除弹出框 -->
<ng-template #tplTitle>
  <span>
    {{ 'OperationReminder' | translate }}
  </span>
</ng-template>
<ng-template #tplContent>
  <span>
    {{ 'RemoveTemplateTip' | translate }}
  </span>
</ng-template>
