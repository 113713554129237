<div>
  <div class="title">
    <span>{{'UpdateAvailable' | translate}}</span>
  </div>

  <div class="desc">
    <span>{{'ReloadWebsiteOrNot' | translate}}</span>
  </div>

  <div>
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           (click)="onLater()">
          <span>{{'Later' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" (click)="onReload()">
          <span>{{'Reload' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
