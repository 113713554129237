// @ts-ignore
import packageInfo from '../../package.json';
import { Locale } from '../app/api/types';

export const environment = {
  production: true,
  hmr: false,
  target: 'WEB',
  supportedLocale: [Locale.hk, Locale.cn, Locale.en, Locale.jp, Locale.ko],
  VERSION: packageInfo.version
};
