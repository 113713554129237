<div *ngFor="let box of inputBox">
  <div
    style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start; align-items: center; padding: 0 0 0 0">
    <span style="flex-shrink: 1">{{box.prefix}}</span>
    <div [ngSwitch]="box.boxType">
      <div *ngSwitchCase="'TEXT'">
        <input nz-input [nzBorderless]="true" [disabled]="disabled" [(ngModel)]="box.value" style="width: 300px" (ngModelChange)="inputBoxChange.emit(inputBox)"/>
      </div>
      <div *ngSwitchCase="'NUMBER'">
        <nz-input-number [(ngModel)]="box.value" [disabled]="disabled" style="width: 200px" (ngModelChange)="inputBoxChange.emit(inputBox)"></nz-input-number>
      </div>
      <div *ngSwitchCase="'MONEY'">
        <nz-input-number [(ngModel)]="box.value" [disabled]="disabled" style="width: 200px" (ngModelChange)="inputBoxChange.emit(inputBox)"></nz-input-number>
      </div>
    </div>
    <span style="flex-shrink: 1">{{box.suffix}}</span>
  </div>
</div>
