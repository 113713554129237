import {Component, OnInit, TemplateRef} from '@angular/core';
import {AmlListResp, AmlMonitorSearch, PagedResp} from '../../../api/types';
import {PropertySelectOption} from '../../../shared/component/property-select/property-select.component';
import {AmlService} from '../../aml.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {NzModalService} from 'ng-zorro-antd/modal';
import {AmlMonitorEntryInspectComponent} from '../aml-monitor-entry-inspect/aml-monitor-entry-inspect.component';
import {AmlMonitorEntryDetailComponent} from '../aml-monitor-entry-detail/aml-monitor-entry-detail.component';
import {MetadataService} from '../../../shared/service/metadata.service';
import {EventService} from '../../../shared/service/event.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import { Workbench } from '../../../workbench/workbench-types';
import { WorkbenchService } from '../../../workbench/workbench.service';

@Component({
  selector: 'app-aml-monitor-entry',
  templateUrl: './aml-monitor-entry.component.html',
  styleUrls: ['./aml-monitor-entry.component.less']
})
export class AmlMonitorEntryComponent implements OnInit {
  loading = false;

  spinning = false;

  dates: number[];
  workbench: Workbench;

  constructor(private drawerService: NzDrawerService,
              private amlService: AmlService,
              private metadataService: MetadataService,
              private modalService: NzModalService,
              private eventService: EventService,
              private toastr: ToastrService,
              private workbenchService: WorkbenchService,
              private translate: TranslateService) {
  }

  amls: PagedResp<AmlListResp>;

  search: AmlMonitorSearch = new AmlMonitorSearch();

  typeOptions: PropertySelectOption[] = [];
  statusOptions: PropertySelectOption[] = [];
  filterPopoverVisible = false;

  ngOnInit(): void {
    this.loading = true;
    this.workbench = this.workbenchService.workbench;
    this.amlService.entryList(this.search).subscribe(
      data => {
        this.loading = false;
        this.amls = data;
      },
      error => {
        this.loading = false;
      }
    );
    const amlRecordTypes = this.metadataService.values('amlRecordType');
    this.typeOptions = [];
    for (const amlRecordType of amlRecordTypes) {
      this.typeOptions.push(new PropertySelectOption(amlRecordType.value, amlRecordType.key));
    }
    const amlMonitorStatus = this.metadataService.values('amlMonitorStatus');
    this.statusOptions = [];
    for (const status of amlMonitorStatus) {
      this.statusOptions.push(new PropertySelectOption(status.value, status.key));
    }
    this.amlService.monitorReload.subscribe(
      data => {
        this.onReload();
      }
    );
  }

  appMetadataChanged(): void {
    this.ngOnInit();
  }

  onResetSearch(): void {
    this.dates = null;
    this.search = new AmlMonitorSearch();
    this.onReload();
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.amlService.entryList(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.amls = data;
        },
        error => {
          this.loading = false;
        });
  }

  onAmlInspectDetail(id: number): void {
    this.loading = true;
    this.amlService.entryDetail(id)
      .subscribe(info => {
          this.loading = false;
          const drawerRef = this.drawerService.create<AmlMonitorEntryDetailComponent, { value: any }, string>({
            nzWidth: 1024,
            nzMaskClosable: true,
            nzContent: AmlMonitorEntryDetailComponent,
            nzContentParams: {
              info
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();
            if (component.stateModified) {
              this.onReload(false);
            }
          });
        },
        error => {
          this.loading = false;
        });


  }

  onAmlNewInspection(): void {
    const drawerRef = this.drawerService.create<AmlMonitorEntryInspectComponent, { value: any }, string>({
      nzWidth: 1024,
      nzMaskClosable: true,
      nzContent: AmlMonitorEntryInspectComponent,
      nzContentParams: {},
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.amlInspectCreated
        .subscribe(
          data => {
            drawerRef.close();
            this.onReload();
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search = new AmlMonitorSearch();
    }
    this.amlService.entryList(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.amls = data;
        },
        error => {
          this.loading = false;
        });

  }

  onAmlDelete(row: any, tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>): void {
    this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzClosable: false,
      nzOnOk: () => {
        this.loading = true;
        this.amlService.deleteMonitorEntry(row.id, row.type).subscribe(
          result => {
            if (result) {
              this.translate.get('DeleteSuccess').subscribe(
                (res) => {
                  this.toastr.success(res);
                }
              );
            }
            if (this.amls.total > this.amls.pageSize && this.amls.total - 1 <= this.amls.pageNum * this.amls.pageSize) {
              this.search.pageNum -= 1;
            }
            this.amlService.entryList(this.search).subscribe(
              data => {
                this.loading = false;
                this.amls = data;
              },
              error => {
                this.loading = false;
              }
            );
          },
          error => {
            this.loading = false;
          }
        );
      }
    });
  }

  onSearchDateChange(dates: number[]): void {
    this.dates = dates;
    if (dates) {
      this.search.beginTime = dates[0];
      this.search.endTime = dates[1];
    } else {
      this.search.beginTime = undefined;
      this.search.endTime = undefined;
    }
  }
}
