import { Injectable } from '@angular/core';
import {HttpService} from '../core/http.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BaseService} from '../base/base.service';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResponse, PagedResp} from '../api/types';
import {API} from '../api/api';
import {map} from 'rxjs/operators';
import {PactDetails, PactSearch, PactSearchRes, PactSign, PDFDownload, PDFListRes} from './sales-pact-types';

@Injectable({
  providedIn: 'root'
})
export class SalesPactService extends BaseService {

  constructor(private http: HttpService, private httpClient: HttpClient) {
    super();
  }

  /**
   * 获取合同列表
   * @param search Query
   */
  pactList(search: PactSearch): Observable<PagedResp<PactSearchRes>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status.toString());
    }
    return this.http.get<ApiResponse<PagedResp<PactSearchRes>>>(API.salesPact + '/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 合同詳情
   * @param search 合同詳情
   */
  info(search: PactSearch): Observable<PactDetails> {
    let httpParams = new HttpParams();
    if (search.id != null) {
      httpParams = httpParams.set('id', search.id.toString());
    }
    return this.http.get<ApiResponse<PactDetails>>(API.salesPact + '/info', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 合同PDF列表
   *
   */
  viewPdf(): Observable<PDFListRes[]> {
    return this.http.get<ApiResponse<PDFListRes[]>>(API.salesPact + '/pdf/list', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * pdf预览
   * @param search Query
   */
  viewFna(search: PDFDownload): Observable<Uint8Array> {
    let httpParams = new HttpParams();
    if (search.id != null) {
      httpParams = httpParams.set('id', search.id.toString());
    }
    if (search.sign != null) {
      httpParams = httpParams.set('sign', search.sign.toString());
    }
    return this.httpClient
      .get(API.salesPact + '/download', {
        params: httpParams,
        responseType: 'arraybuffer'
      }).pipe(map((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer)));
  }

  /**
   * pdf下载
   * @param search Query
   */

  downloadPDF(search: PDFDownload): Observable<any> {
    let httpParams = new HttpParams();
    if (search.sign != null) {
      httpParams = httpParams.set('sign', search.sign.toString());
    }
    if (search.id != null) {
      httpParams = httpParams.set('id', search.id.toString());
    }
    return this.httpClient.get(API.salesPact + '/download', {
      params: httpParams,
      responseType: 'blob',
      observe: 'response'
    }).pipe(map((date: any) => {
      return date;
    }));
  }

  /**
   * 修改合同信息
   * @param info 传参
   */
  updateInfo(info: any): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.salesPact + '/update', info, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 合同签名
   * @param info 传参
   */
  sign(info: PactSign): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.salesPact + '/sign', info, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
