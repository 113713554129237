<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'Client' | translate}}
    </div>

    <div class="section-wrapper">
      <!--      销售渠道-->
      <app-property-pick [title]="'Referee' | translate"
                         appThrottleClick (throttleClick)="onSub()"
                         [valueCursor]="!accountNameSave ? 'no-drop' : 'auto'"
                         [valueColor]="mode === 'SALESGROUP' ? '#cccccc' : ''"
                         [bottomLineVisible]="false"
                         [value]="customerShot.nickName"></app-property-pick>
    </div>
    <div class="section-title" style="margin-top: 10px">
      <span style="margin-left: 8px">{{'Client' | translate}}</span>
    </div>

    <!-- 姓氏 -->
    <app-property-input *ngIf="!workbench.hiddenLastName"
                        [displayMode]="'horizontal'"
                        [title]="'LastName' | translate"
                        [disabled]="!canSave || isInputName"
                        [placeholder]="('PleaseEnter' | translate) + ('LastName' | translate)"
                        [(value)]="customerShot.lastName"></app-property-input>
    <!-- 名字 -->
    <app-property-input [displayMode]="'horizontal'"
                        [title]="'FirstName' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('FirstName' | translate)"
                        [(value)]="customerShot.firstName"></app-property-input>

    <!-- 姓氏-英文 -->
    <app-property-input *ngIf="!workbench.hiddenLastName"
                        [displayMode]="'horizontal'"
                        [required]="true"
                        [isPinYin]="true"
                        [disabled]="!canSave || isInputName"
                        [title]="'LastNameInEnglish' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('LastNameInEnglish' | translate)"
                        [(value)]="customerShot.lastNamePinyin"></app-property-input>
    <!-- 名字-英文 -->
    <app-property-input [displayMode]="'horizontal'"
                        [required]="true"
                        [disabled]="!canSave"
                        [isPinYin]="true"
                        [title]="'FirstNameInEnglish' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('FirstNameInEnglish' | translate)"
                        [(value)]="customerShot.firstNamePinyin"></app-property-input>

    <!-- 稱謂 -->
    <app-property-select [displayMode]="'horizontal'"
                         [required]="true"
                         [disabled]="!canSave"
                         [title]="'Title' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Title' | translate)"
                         [options]="titleOptions"
                         [(value)]="customerShot.title"></app-property-select>

    <!-- 性别 -->
    <app-property-select [displayMode]="'horizontal'"
                         [disabled]="!canSave"
                         [title]="'Gender' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Gender' | translate)"
                         [options]="genderOptions"
                         [required]="false"
                         [(value)]="customerShot.gender"></app-property-select>

    <!-- 生日 -->
<!--    <app-property-datetime [displayMode]="'horizontal'"-->
<!--                           [disabled]="!canSave"-->
<!--                           [title]="'Birthday' | translate"-->
<!--                           (valueChange)="onBirthdayChange($event)"-->
<!--                           [required]="!instance.isFoneOrDev()"-->
<!--                           [value]="customerShot.birthday"></app-property-datetime>-->
    <app-property-datetime-string [title]="'Birthday' | translate"
                                  [displayMode]="'horizontal'"
                                  [disabled]="!canSave"
                                  [formatter]="'yyyy-MM-dd'"
                                  [showTime]="null"
                                  [required]="!instance.isFoneOrDev()"
                                  [(value)]="customerShot.birthdate">
    </app-property-datetime-string>

    <!-- 年龄 -->
    <app-property-input-number [displayMode]="'horizontal'"
                               [disabled]="!canSave"
                               [title]="'Age' | translate"
                               [placeholder]="('PleaseEnter' | translate) + ('Age' | translate)"
                               [max]="138" [min]="0"
                               [required]="true"
                               [(value)]="customerShot.age"></app-property-input-number>

    <!-- 移动号码 -->
    <app-property-pick [displayMode]="'horizontal'"
                       [title]="'MobileNumber' | translate"
                       [required]="true"
                       appThrottleClick (throttleClick)="onMobileNumber()"
                       [value]="customerShot.phoneString()"></app-property-pick>

    <!-- 郵箱 -->
    <app-property-input [title]="'Email' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('Email' | translate)"
                        [required]="true"
                        [(value)]="customerShot.email"></app-property-input>

    <!-- 是否吸烟 -->
    <app-property-select [title]="'SmokeCondition' | translate"
                         [disabled]="!canSave"
                         [placeholder]="('PleaseSelect' | translate) + ('SmokeCondition' | translate)"
                         [options]="smokeOptions"
                         [(value)]="customerShot.smoke"></app-property-select>
    <!-- 吸烟量 -->
    <app-property-input *ngIf="customerShot.smoke === 'S'" [title]="'SmokingVolume' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('SmokingVolume' | translate)"
                        [(value)]="customerShot.smokeQuantity"></app-property-input>

    <!-- 行业 -->
    <app-property-input [title]="'Occupation' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('Occupation' | translate)"
                        [required]="true"
                        [(value)]="customerShot.companyType"></app-property-input>
    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'IdentificationInformation' | translate}}</span>
    </div>

    <div>
      <span
        style="color: #AAAAAA; font-size: 12px">{{'PleaseFillInAtLeastOneTypeOfPersonalIdentification' | translate}}</span>
    </div>

    <!-- 身份證號 -->
    <app-property-input [title]="'IdentityCardNumber' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('IdentityCardNumber' | translate)"
                        [(value)]="customerShot.idCard"></app-property-input>

    <!-- 護照號碼 -->
    <app-property-input [title]="'PassportNo' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('PassportNo' | translate)"
                        [(value)]="customerShot.passport"></app-property-input>

    <!-- 通行證號碼 -->
    <app-property-input [title]="'VisaNo' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('VisaNo' | translate)"
                        [(value)]="customerShot.eepCode"></app-property-input>

    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'BasicInformation' | translate}}</span>
    </div>

    <!-- 婚姻状态 -->
    <app-property-select [title]="'Marriage' | translate"
                         [disabled]="!canSave"
                         [required]="true"
                         [placeholder]="('PleaseSelect' | translate) + ('Marriage' | translate)"
                         [options]="marriageOptions"
                         [(value)]="customerShot.marriage"></app-property-select>

    <!-- 国籍（国家地区） -->
    <app-property-pick [displayMode]="'horizontal'"
                       [title]="'Country' | translate"
                       [required]="true"
                       appThrottleClick (throttleClick)="onCountry()"
                       [value]="countryString"></app-property-pick>

    <!-- 出生地（省市） -->
    <app-property-input [title]="'Birthplace' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('Birthplace' | translate)"
                        [(value)]="customerShot.birthplace"></app-property-input>

    <!-- 教育程度 -->
    <app-property-select [title]="'Education' | translate"
                         [disabled]="!canSave"
                         [required]="true"
                         [placeholder]="('PleaseSelect' | translate) + ('Education' | translate)"
                         [options]="educationOptions"
                         [(value)]="customerShot.education"></app-property-select>

    <!-- 固定電話 -->
    <app-property-pick [title]="'ResidentialPhoneNumber' | translate"
                       appThrottleClick (throttleClick)="onResidentialPhoneNumber()"
                       [value]="customerShot.residentialPhoneNumberString()"></app-property-pick>

    <!-- 過往一年是否所屬國家以外地區居住超過182日 -->
    <app-property-select [title]="'LongtimeAbroad' | translate"
                         [disabled]="!canSave"
                         [placeholder]="('PleaseSelect' | translate) + ('LongtimeAbroad' | translate)"
                         [options]="longtimeAbroadOptions"
                         [(value)]="customerShot.longtimeAbroad"></app-property-select>

    <!-- 戶籍地址 -->
    <app-property-pick *ngIf="!instance.isFoneOrDev()" [title]="'LegalAddress' | translate"
                       appThrottleClick (throttleClick)="onResidenceAddress()"
                       [value]="customerShot.residenceAddress?.toAddressString()"></app-property-pick>

    <!-- 通讯地址 -->
    <app-property-pick [title]="'PostalAddress' | translate"
                       appThrottleClick (throttleClick)="mailingAddress()"
                       [value]="customerShot.mailingAddress?.toAddressString()"></app-property-pick>

    <!-- 住宅地址 -->
    <app-property-pick [title]="'UserAddress' | translate"
                       appThrottleClick (throttleClick)="residentialAddress()"
                       [value]="customerShot.residentialAddress?.toAddressString()"></app-property-pick>


    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'PositionInformation' | translate}}</span>
    </div>

    <!-- 公司名称 -->
    <app-property-input [title]="'CompanyName' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('CompanyName' | translate)"
                        [(value)]="customerShot.companyName"></app-property-input>

    <!-- 公司電話 -->
    <app-property-pick [title]="'OfficePhoneNumber' | translate"
                       appThrottleClick (throttleClick)="onOfficePhoneNumber()"
                       [value]="customerShot.officePhoneNumberString()"></app-property-pick>

    <!-- 职位 -->
    <app-property-input [title]="'Position' | translate"
                        [disabled]="!canSave"
                        [placeholder]="('PleaseEnter' | translate) + ('Position' | translate)"
                        [(value)]="customerShot.position"></app-property-input>

    <!-- 总工作年期 -->
    <app-property-input-number [title]="'WorkYear' | translate"
                               [disabled]="!canSave"
                               [placeholder]="('PleaseEnter' | translate) + ('WorkYear' | translate)"
                               [(value)]="customerShot.workYear"></app-property-input-number>

    <!-- 月薪 -->
    <app-property-input-number [title]="'Salary' | translate"
                               [disabled]="!canSave"
                               [placeholder]="('PleaseEnter' | translate) + ('Salary' | translate)"
                               [(value)]="customerShot.salary"></app-property-input-number>

    <!-- 公司地址 -->
    <app-property-pick [title]="'CompanyAddress' | translate"
                       appThrottleClick (throttleClick)="onCompanyAddress()"
                       [value]="customerShot.companyAddress?.toAddressString()"></app-property-pick>

    <div *ngIf="!isFone">
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'WitnessInformation' | translate}}</span>
      </div>

      <!-- 姓氏 -->
      <app-property-input *ngIf="!workbench.hiddenLastName"
                          [displayMode]="'horizontal'"
                          [title]="'LastName' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('LastName' | translate)"
                          [(value)]="eyewitness.lastName"></app-property-input>
      <!-- 名字 -->
      <app-property-input [displayMode]="'horizontal'"
                          [title]="'FirstName' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('FirstName' | translate)"
                          [(value)]="eyewitness.firstName"></app-property-input>

      <!-- 姓氏-英文 -->
      <app-property-input *ngIf="!workbench.hiddenLastName"
                          [displayMode]="'horizontal'"
                          [title]="'LastNameInEnglish' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('LastNameInEnglish' | translate)"
                          [(value)]="eyewitness.lastNamePinyin"></app-property-input>

      <!-- 名字-英文 -->
      <app-property-input [displayMode]="'horizontal'"
                          [title]="'FirstNameInEnglish' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('FirstNameInEnglish' | translate)"
                          [(value)]="eyewitness.firstNamePinyin"></app-property-input>

      <!-- 關係 -->
      <app-property-input [displayMode]="'horizontal'"
                          [title]="'Relationship' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('Relationship' | translate)"
                          [(value)]="eyewitness.relationship"></app-property-input>

      <!-- 稱謂 -->
      <app-property-select [displayMode]="'horizontal'"
                           [disabled]="!canSave"
                           [title]="'Title' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Title' | translate)"
                           [options]="titleOptions"
                           [(value)]="eyewitness.title"></app-property-select>

      <!-- 性别 -->
      <app-property-select [displayMode]="'horizontal'"
                           [disabled]="!canSave"
                           [title]="'Gender' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Gender' | translate)"
                           [options]="genderOptions"
                           [(value)]="eyewitness.gender"></app-property-select>

      <!-- 生日 -->
<!--      <app-property-datetime [displayMode]="'horizontal'"-->
<!--                             [disabled]="!canSave"-->
<!--                             [title]="'Birthday' | translate"-->
<!--                             (valueChange)="onWitnessBirthdayChange($event)"-->
<!--                             [value]="eyewitness.birthday"></app-property-datetime>-->
      <app-property-datetime-string [title]="'Birthday' | translate"
                                    [displayMode]="'horizontal'"
                                    [disabled]="!canSave"
                                    [formatter]="'yyyy-MM-dd'"
                                    [showTime]="null"
                                    [(value)]="eyewitness.birthdate">
      </app-property-datetime-string>

      <!-- 年龄 -->
      <app-property-input-number [displayMode]="'horizontal'"
                                 [disabled]="!canSave"
                                 [title]="'Age' | translate"
                                 [placeholder]="('PleaseEnter' | translate) + ('Age' | translate)"
                                 [max]="138" [min]="0"
                                 [(value)]="eyewitness.age"></app-property-input-number>

      <!-- 移动号码 -->
      <app-property-pick [displayMode]="'horizontal'"
                         [title]="'MobileNumber' | translate"
                         appThrottleClick (throttleClick)="onMobileNumber()"
                         [value]="eyewitness.phoneString()"></app-property-pick>

      <!-- 郵箱 -->
      <app-property-input [title]="'Email' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('Email' | translate)"
                          [(value)]="eyewitness.email"></app-property-input>

      <!-- 身份證號 -->
      <app-property-input [title]="'IdentityCardNumber' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('IdentityCardNumber' | translate)"
                          [(value)]="eyewitness.idCard"></app-property-input>

      <!-- 護照號碼 -->
      <app-property-input [title]="'PassportNo' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('PassportNo' | translate)"
                          [(value)]="eyewitness.passport"></app-property-input>

      <!-- 護照號碼 -->
      <app-property-input [title]="'VisaNo' | translate"
                          [disabled]="!canSave"
                          [placeholder]="('PleaseEnter' | translate) + ('VisaNo' | translate)"
                          [(value)]="eyewitness.eepCode"></app-property-input>

    </div>


    <div *ngIf="!instance.isFoneOrDev()" class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'Source' | translate}}</span>
    </div>

    <!-- 來源 -->
    <div *ngIf="!instance.isFoneOrDev()" class="section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{'Source' | translate}}</div>
        <div class="property-value-row" style="cursor: pointer" appThrottleClick (throttleClick)="onSource()">
          <div class="property-pick-value">
            <div style="margin-right: 4px; display: flex; flex-direction: column">
              <span style="font-size: 15px">{{sourceData.sourceAccount}}</span>
              <span style="font-size: 12px; color: #AAAAAA">{{sourceData.sourceName}}</span>
            </div>
          </div>
        </div>
        <div style="color: #333333;">
          <i nz-icon nzType="right" nzTheme="outline"></i>
        </div>
      </div>
    </div>

    <!-- 備註 -->
    <div *ngIf="!instance.isFoneOrDev()" class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'Remark' | translate}}</span>
    </div>

    <div *ngIf="!instance.isFoneOrDev()" class="section-wrapper">
      <textarea style="padding: 0!important; margin: 15px 0 15px 0"
                [nzBorderless]="true"
                [disabled]="!canSave"
                nz-input
                [placeholder]="('PleaseEnter' | translate) + ('Remark' | translate)"
                [(ngModel)]="customerShot.remark"
                [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
    </div>

  </nz-spin>

  <div *ngIf="canSave" class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" [disabled]="loading"
           appThrottleClick (throttleClick)="onSave()"
           [style]="loading ? 'opacity: 0.5' : ''">
          <em nz-icon nzType="check" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
