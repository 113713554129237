<div class="card-container">
  <nz-tabset [nzSize]="'large'" nzType="card" [nzSelectedIndex]="tableIndex">
    <nz-tab [nzTitle]=myDeals>
      <app-bv-module-list [mode]="dataService.dataModeOwn"></app-bv-module-list>
    </nz-tab>
    <ng-template #myDeals>
      {{'MyDeals' | translate}}
    </ng-template>
    <nz-tab [nzTitle]=channelDeals *ngIf="workbench.pivot !== dataService.pivotOwn">
      <app-bv-module-list [mode]="dataService.dataModeSubordinate"></app-bv-module-list>
    </nz-tab>
    <ng-template #channelDeals>
      {{'MySubordinates' | translate}}
    </ng-template>
<!--    <nz-tab [nzTitle]=myToDo *ngIf="workbench.tr">-->
<!--      <app-new-fna-list [mode]="dataService.dataModeFollowUp"></app-new-fna-list>-->
<!--    </nz-tab>-->
<!--    <ng-template #myToDo>-->
<!--      {{'MyToDo' | translate}}-->
<!--    </ng-template>-->
    <!--    <nz-tab [nzTitle]=myTeam>-->
    <!--      <app-new-fna-list [mode]="dataService.dataModeSalesGroup"></app-new-fna-list>-->
    <!--    </nz-tab>-->
    <!--    <ng-template #myTeam>-->
    <!--      {{'MySalesGroup' | translate}}-->
    <!--    </ng-template>-->
  </nz-tabset>
</div>

