<div class="drawer-container">

  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>

  <div class="common-title" style="margin-bottom: 40px">
    {{'SelectToCombineBooking' | translate}}
  </div>

  <div style="margin: 10px 0 10px 0;">
    {{ 'OtherAppointmentsGoingOoAtTheSameTime' | translate }}
  </div>

  <div class="booking-section-wrapper">
    <div class="property-row" appThrottleClick (throttleClick)="onBookingGroupSelected(null)">
      <div class="property-title-row">{{ 'No' | translate }}</div>
      <div class="property-value-row">
        <div class="property-pick-value">
          <i *ngIf="!selectedGroupNo" nz-icon nzType="check-circle" nzTheme="outline" style="color: #003781"></i>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="bookings" style="margin-top: 30px; ">

    <nz-table [nzBordered]="false"
              [nzOuterBordered]="true"
              [nzShowPagination]="true"
              [nzLoading]="loading"
              [nzLoadingIndicator]="indicatorTemplate"
              [nzFrontPagination]="false"
              [nzData]="bookings.list"
              [nzPageIndex]="bookings.pageNum"
              [nzPageSize]="bookings.pageSize"
              [nzTotal]="bookings.total"
              [nzTitle]="tableHeader"
              [nzFooter]="tableFooter"
              (nzPageIndexChange)="onSearch($event)">
      <ng-template #tableHeader>
        <nz-row>
          <nz-col [nzSpan]="12">
            <div class="list-header-title">
              <div>
                <span>{{'BookingGroupList' | translate}}</span>
              </div>
            </div>
          </nz-col>
        </nz-row>
      </ng-template>
      <ng-template #tableFooter>
        <div class="table-title">
          {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ bookings.total }}</span>
        </div>
      </ng-template>

      <thead>
      <tr>
        <th nzWidth="165px">
          <span>{{'GroupId' | translate}}</span>
        </th>
        <th>
          <span>{{'Holder' | translate}}</span>
        </th>
        <th>
          <span>{{'ProductName' | translate}}</span>
        </th>
        <th></th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let booking of bookings.list; let i = index" appThrottleClick (throttleClick)="onBookingGroupSelected(booking.groupNo)">
        <td>
          {{ booking.groupNo }}
        </td>
        <td>
          {{ booking.appBookingListVOS[0].name }} | {{ booking.appBookingListVOS[0].namePinYin ?? '' }}
        </td>
        <td>
          <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="booking.appBookingListVOS[0].companyLogo" alt=""/>
          <span>{{ booking.appBookingListVOS[0].productName }}</span>
        </td>
        <td>
          <i *ngIf="setOfCheckedId.has(booking.groupNo)" nz-icon nzType="check-circle" nzTheme="outline" style="color: #003781"></i>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button" nz-tooltip [nzTooltipTitle]="'Cancel' | translate">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onCancel()">
          <!--          <em nz-icon nzType="save" nzTheme="outline"></em>-->
          <span>{{'Cancel' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="onConfirm()">
          <!--          <em nz-icon nzType="check" nzTheme="outline"></em>-->
          <span>{{'Confirm' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
