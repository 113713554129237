import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { FinancialSave, FnaAssessAdvice, FnaDeclaration, FnaSystemSave, NewFnaDetail } from '../../new-fna-types';
import { DataMode } from '../../../api/types';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { MetadataService } from '../../../shared/service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { FnaService } from '../../../fna/fna.service';
import { NewFnaService } from '../../new-fna.service';
import { AccountService } from '../../../account/account.service';
import { ToastrService } from 'ngx-toastr';
import { SubSalesChannelComponent } from '../../../shared/component/sub-sales-channel/sub-sales-channel.component';
import { plainToInstance } from 'class-transformer';

@Component({
  selector: 'app-new-fna-important-clause',
  templateUrl: './new-fna-important-clause.component.html',
  styleUrls: ['./new-fna-important-clause.component.less']
})
export class NewFnaImportantClauseComponent implements OnInit {
  drawerRef: NzDrawerRef<NewFnaImportantClauseComponent, string>;

  loading = false;
  fnaInfo: NewFnaDetail = new NewFnaDetail();
  declarationFrom: FnaDeclaration = new FnaDeclaration();
  @Output()
  declarationSaveChange: EventEmitter<any> = new EventEmitter<any>();


  constructor(private metadataService: MetadataService,
              private translate: TranslateService,
              private drawerService: NzDrawerService,
              private fnaService: FnaService,
              private newFnaService: NewFnaService,
              private accountService: AccountService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.fnaInfo.declaration?.forEach(item => {
      this.declarationFrom[item.key] = item.value;
    });

  }

  onCancel(): void {
    this.drawerRef?.close();
  }

  onSave(): void {
    const param = new FinancialSave();
    param.id = this.fnaInfo.id;
    const obj1 = plainToInstance(FnaDeclaration, new FnaDeclaration());
    const arr1 = Object.keys(obj1);
    const map1 = arr1.map(item => {
      return { key: item, value: this.declarationFrom[item] || null };
    });
    param.dataList = JSON.parse(JSON.stringify(map1));
    console.log(param);
    console.log(!param.dataList.every(item => !!item.value));
    if (!param.dataList.every(item => !!item.value)) {
      return;
    }
    this.newFnaService.updateFnaDeclaration(param)
      .subscribe(data => {
        this.declarationSaveChange.emit(data);
      });
  }

}
