import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzSelectModeType } from 'ng-zorro-antd/select';
import { PropertyDisplayMode } from '../../shared';

@Component({
  selector: 'app-property-select',
  templateUrl: './property-select.component.html',
  styleUrls: ['./property-select.component.less']
})
export class PropertySelectComponent implements OnInit {

  @Input() displayMode: PropertyDisplayMode = 'horizontal';
  @Input() mode: NzSelectModeType = 'default';
  @Input() title: string;
  @Input() placeholder = '';
  @Input() tip: string;
  @Input() tipVisible = false;
  @Input() loading = false;
  @Input() required = false;
  @Input() disabled = false;
  @Input() bottomLineVisible = true;
  @Input() options: PropertySelectOption[];
  @Input() allowClear = true;
  @Input() supportSearch = false;
  @Input() titleColor: string;
  @Output()
  valueChange: EventEmitter<any | any[]> = new EventEmitter<any | any[]>();

  @Output()
  scrollToBottom: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  searchChange: EventEmitter<string> = new EventEmitter<string>();

  innerValue: any | any[];

  @Input()
  get value(): any {
    return this.innerValue;
  }

  set value(val: any | any[]) {
    this.innerValue = val;
    this.valueChange.emit(this.innerValue);
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  onScrollToButton(): void {
    this.scrollToBottom.emit('');
  }

  onSearch(value: string): void {
    this.searchChange.emit(value);
  }
}

export class PropertySelectOption {
  label: string;
  value: any;
  disabled = false;

  constructor(label: string, value: any, disabled = false) {
    this.label = label;
    this.value = value;
    this.disabled = disabled;
  }
}
