<div class="drawer-container">
  <div class="common-title">
    {{'ProductSelect' | translate}}
  </div>
  <nz-row [nzGutter]="12">
    <nz-col [nzSpan]="24">
      <app-property-input [displayMode]="'vertical'"
                          [title]="'ProductName' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('ProductName' | translate)"
                          [(value)]="search.productName"
                          (valueChange)="onSearchParamChange()"></app-property-input>
    </nz-col>
  </nz-row>
  <nz-row [nzGutter]="12">
    <nz-col [nzSpan]="12" *ngIf="!hideCategory">
      <app-property-select [displayMode]="'vertical'"
                           [title]="'ProductCategory' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('ProductCategory' | translate)"
                           [(value)]="search.categoryCode"
                           [options]="categoryOptions"
                           (valueChange)="onSearchParamChange()"></app-property-select>
    </nz-col>
    <nz-col [nzSpan]="hideCategory ? 24 : 12">
      <app-property-select [displayMode]="'vertical'"
                           [title]="'Insurer' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Insurer' | translate)"
                           [(value)]="search.companyCode"
                           [options]="companyOptions"
                           (valueChange)="onSearchParamChange()"></app-property-select>
    </nz-col>
  </nz-row>
  <div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
      <div *ngIf="!products">
        <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
      </div>
      <div *ngIf="products" style="margin-top: 30px; ">
        <nz-table [nzBordered]="false"
                  [nzOuterBordered]="true"
                  [nzShowPagination]="true"
                  [nzLoading]="loading"
                  [nzLoadingIndicator]="indicatorTemplate"
                  [nzFrontPagination]="false"
                  [nzData]="products.list"
                  [nzPageIndex]="products.pageNum"
                  [nzPageSize]="products.pageSize"
                  [nzTotal]="products.total"
                  [nzTitle]="tableHeader"
                  [nzFooter]="tableFooter"
                  (nzPageIndexChange)="onSearch($event)">
          <ng-template #tableHeader>
            <nz-row>
              <nz-col [nzSpan]="12">
                <div class="list-header-title">{{'ProductList' | translate}}
                  <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                     style="margin-left: 5px"
                     appThrottleClick (throttleClick)="onReload()">
                    <em nz-icon nzType="reload" nzTheme="outline"></em>
                  </a>
                </div>
              </nz-col>
              <nz-col [nzSpan]="12">
                <div style="width: 100%; display: flex; justify-content: flex-end;">
                  <nz-badge [nzCount]="selectedProducts.length ?? 0">
                    <button nz-button nzType="default" nzSize="default" nzShape="circle"
                            nz-tooltip [nzTooltipTitle]="'SelectedProduct' | translate"
                            nz-popover [nzPopoverContent]="productsTemplate"
                            [(nzPopoverVisible)]="productsPopoverVisible"
                            nzPopoverTrigger="click"
                            nzPopoverPlacement="bottomRight"
                            style=" margin-left: 10px;">
                        <span nz-icon nzType="appstore" nzTheme="outline"></span>
                    </button>
                  </nz-badge>
                </div>
              </nz-col>
            </nz-row>
          </ng-template>
          <ng-template #tableFooter>
            <div class="table-title">
              {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ products.total }}</span>
            </div>
          </ng-template>

          <thead>
          <tr>
            <th><span nz-tooltip
                      [nzTooltipTitle]="'ProductCategory' | translate">{{'ProductCategory' | translate}}</span></th>
            <th><span nz-tooltip [nzTooltipTitle]="'ProductName' | translate">{{'ProductName' | translate}}</span></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let product of products.list; let i = index" appThrottleClick (throttleClick)="onProductSelected(product.productCode, i)">
            <td>
              <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="product.logo" alt=""/>
              {{ product.categoryName }}
            </td>
            <td>
              <div style="display: flex; align-items: center;">
                <img *ngIf="product.proposalRPASupported && type === 'PROPOSAL'" alt="" style="margin: 0 10px 0 0px" width="16"
                     src="../../../../assets/images/ic_robot.svg">
                <span>{{ product.productName }}</span>
              </div>
            </td>
            <td>
              <i *ngIf="containsProduct(product.productCode)" nz-icon nzType="check-circle" nzTheme="outline" style="color: #003781"></i>
            </td>
          </tr>
          </tbody>
        </nz-table>
      </div>
    </nz-spin>

  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onConfirm()">
          <em nz-icon nzType="check" nzTheme="outline"></em>
          <span>{{'Confirm' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>


<ng-template #productsTemplate>
  <div style="width: 600px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'SelectedProduct' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <div *ngIf="selectedProducts && selectedProducts.length > 0; else secondaryEmpty">
      <div *ngFor="let product of selectedProducts; let i = index">
        <app-product-list-item [product]="product" [showDeleteButton]="true"
                               (deleteEvent)="onProductDelete(product, i)"></app-product-list-item>
      </div>
    </div>
    <ng-template #secondaryEmpty>
      <div class="centre-container" style="height: 100%; margin-top: 20px">
        <app-empty [text]="('PleaseSelect' | translate) + ('Product' | translate)"></app-empty>
      </div>
    </ng-template>
  </div>
</ng-template>
