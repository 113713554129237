<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 20px">
    {{'BackInfo' | translate}}
  </div>
  <nz-row [nzGutter]="12">
    <nz-col [nzSpan]="24">
      <app-property-input [displayMode]="'vertical'"
                          [title]="'Name' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('Name' | translate)"
                          [(value)]="search.name"
                          (valueChange)="onSearchParamChange()"></app-property-input>
    </nz-col>
  </nz-row>
  <nz-spin [nzSpinning]="loading">
    <div class="section-wrapper member-card"
         *ngFor="let item of dataList; let i = index"
         appThrottleClick (throttleClick)="onInfoSelected(item)">
      <div>{{item.name}}</div>
    </div>

    <nz-empty *ngIf="dataList.length < 1" nzNotFoundImage="simple"></nz-empty>
  </nz-spin>
</div>
