import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewFnaListComponent } from './new-fna-list/new-fna-list.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { TranslateModule } from '@ngx-translate/core';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { SharedModule } from '../shared/shared.module';
import { NewFnaCreateComponent } from './new-fna-create/new-fna-create.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { FnaBasicInfoComponent } from './components/fna-basic-info/fna-basic-info.component';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NewFnaFormComponent } from './components/new-fna-form/new-fna-form.component';
import { FnaModule } from '../fna/fna.module';
import { AnswerInputBoxesComponent } from './components/answer-input-boxes/answer-input-boxes.component';
import { NewFnaQuestionComponent } from './components/new-fna-question/new-fna-question.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NewFnaRecommendedProductComponent } from './components/new-fna-recommended-product/new-fna-recommended-product.component';
import { NewFnaProductSelectionComponent } from './components/new-fna-product-selection/new-fna-product-selection.component';
import { NewFnaSignatureComponent } from './components/new-fna-signature/new-fna-signature.component';
import { NewFnaSignaturePadComponent } from './components/new-fna-signature-pad/new-fna-signature-pad.component';
import { NewFnaSystemInfoComponent } from './components/new-fna-system-info/new-fna-system-info.component';
import { NewFnaImportantClauseComponent } from './components/new-fna-important-clause/new-fna-important-clause.component';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';



@NgModule({
  declarations: [
    NewFnaListComponent,
    NewFnaCreateComponent,
    FnaBasicInfoComponent,
    NewFnaFormComponent,
    AnswerInputBoxesComponent,
    NewFnaQuestionComponent,
    NewFnaRecommendedProductComponent,
    NewFnaProductSelectionComponent,
    NewFnaSignatureComponent,
    NewFnaSignaturePadComponent,
    NewFnaSystemInfoComponent,
    NewFnaImportantClauseComponent
  ],
  exports: [
    NewFnaListComponent,
    NewFnaQuestionComponent
  ],
    imports: [
        CommonModule,
        NzSpinModule,
        NzTableModule,
        NzSkeletonModule,
        TranslateModule,
        NzPopoverModule,
        NzGridModule,
        NzToolTipModule,
        NzIconModule,
        NzButtonModule,
        SharedModule,
        NzTabsModule,
        NzInputNumberModule,
        FormsModule,
        NzInputModule,
        FnaModule,
        NzCheckboxModule,
        NzRadioModule,
        NzPaginationModule,
    ]
})
export class NewFnaModule { }
