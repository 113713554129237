import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProposalList } from '../../proposal-compare/proposal-compare';
import { SelectListQuery } from '../proposal-custom-types';
import { PagedResp } from '../../api/types';
import { ProposalCustomService } from '../proposal-custom.service';
import { debounceTime } from 'rxjs/operators';
import { Proposal } from '../../proposal/proposal-types';

@Component({
  selector: 'app-proposal-custom-selection',
  templateUrl: './proposal-custom-selection.component.html',
  styleUrls: ['./proposal-custom-selection.component.less']
})
export class ProposalCustomSelectionComponent implements OnInit {
  @Output()
  proposalSelected: EventEmitter<Proposal> = new EventEmitter<Proposal>();

  searchParamChange: EventEmitter<any> = new EventEmitter<any>();
  spinning = false;
  loading = false;
  search: SelectListQuery = new SelectListQuery();

  proposals: PagedResp<Proposal>;

  listOfCurrentPageData: Proposal[] = [];

  proposal: Proposal;

  constructor(private proposalCustomService: ProposalCustomService) { }

  ngOnInit(): void {
    this.searchParamChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          this.onSearch(1);
        }
      );
  }

  onSearchParamChange(): void {
    this.searchParamChange.emit('');
  }

  onCurrentPageDataChange(listOfCurrentPageData: Proposal[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
  }

  // onReload(): void {
  //   this.search.pageNum = 1;
  //   this.loading = true;
  //   this.proposalCustomService.selectList(this.search).subscribe(
  //     data => {
  //       this.listArr = data;
  //       console.log(this.listArr);
  //       this.loading = false;
  //     }, error => {
  //       this.loading = false;
  //     });
  // }

  onSearch($event: number): void {
    this.search.pageNum = $event;
    this.loading = true;
    this.proposalCustomService.selectList(this.search).subscribe(
      data => {
        this.proposals = data;
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }

  get canConfirm(): boolean {
    if (this.loading) {
      return false;
    }
    if (!this.proposal) {
      return false;
    }
    return true;
  }


  onProposal(proposal: Proposal): void {
    this.proposal = proposal;
    this.proposalSelected.emit(this.proposal);
  }
}
