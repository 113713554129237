import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductSelectionComponent, ProductSelectionType } from '../product-selection/product-selection.component';
import {
  Product,
  ProductCategory,
  ProductCompany,
  ProductListResp,
  ProductSearch
} from '../../../product/product-types';
import { DataMode, PagedResp, ProductType } from '../../../api/types';
import { PropertySelectOption } from '../property-select/property-select.component';
import { ProductService } from '../../../product/product.service';
import { debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { arrayToMap, deduplication } from '../../utils/collections';
import { PolicyListResp, PolicySearch } from '../../../policy/policy-types';
import { PolicyService } from '../../../policy/policy.service';
import { ProposalList } from '../../../proposal-compare/proposal-compare';

@Component({
  selector: 'app-policy-selection',
  templateUrl: './policy-selection.component.html',
  styleUrls: ['./policy-selection.component.less']
})
export class PolicySelectionComponent implements OnInit {
  drawerRef: NzDrawerRef<PolicySelectionComponent, string>;
  @Output()
  policySelected: EventEmitter<any> = new EventEmitter<any>();

  searchParamChange: EventEmitter<any> = new EventEmitter<any>();

  search: PolicySearch = new PolicySearch();

  spinning = false;

  loading = false;
  policys: PagedResp<PolicyListResp>;
  checked = false;
  setOfCheckedId = new Set<string>();
  listOfCurrentPageData: PolicyListResp[] = [];
  indeterminate = false;

  hideCategory = false;
  constructor(private policyService: PolicyService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
    this.searchParamChange.pipe(debounceTime(800))
      .subscribe(
        data => {
          this.onSearch(1);
        }
      );
  }

  onSearch(pageNum: number): void {
    this.loading = true;
    this.search.pageNum = pageNum;
    this.policyService.list(this.search, DataMode.OWN)
      .subscribe(
        data => {
          this.loading = false;
          this.policys = data;
        },
        error => {
          this.loading = false;
        });

  }

  onSearchParamChange(): void {
    this.searchParamChange.emit('');
  }

  onReload(): void {
    this.search = new PolicySearch();
    this.onSearch(this.search.pageNum);
  }

  onConfirm(): void {
    this.policySelected.emit([...this.setOfCheckedId]);
    this.drawerRef?.close();
  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.DEFAULT,
        copyProduct: this.search.product
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.search.product = product;
            this.search.productCode = this.search.product.productCode;
            drawerRef.close();
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onItemChecked(data: PolicyListResp, checked: boolean): void {
    this.updateCheckedSet(data, checked);
    this.refreshCheckedStatus();
  }

  updateCheckedSet(data: PolicyListResp, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(data.policyNo);
    } else {
      this.setOfCheckedId.delete(data.policyNo);
    }
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData;
    this.checked = listOfEnabledData.every(({policyNo}) => this.setOfCheckedId.has(policyNo));
    this.indeterminate = listOfEnabledData.some(({policyNo}) => this.setOfCheckedId.has(policyNo)) && !this.checked;
  }

  onCurrentPageDataChange(listOfCurrentPageData: PolicyListResp[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }


}
