<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!documentTable">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="documentTable" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="documentTable"
                [nzTitle]="tableHeader">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'FileList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="300px">
            <span>{{'DocumentName' | translate}}</span>
          </th>
          <th nzWidth="200px" *ngIf="!workbench.hideUploader">
            <span>{{'Uploader' | translate}}</span>
          </th>
          <th>
            <span>{{'FileSize' | translate}}</span>
          </th>
          <th nzWidth="220px">
            <span>{{'RecentlyUsed' | translate}}</span>
          </th>
          <th nzWidth="120px" nzAlign="left"><span style="margin-left: 10px">{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let document of documentTable; let i = index">
          <td nzLeft style="display: flex; align-items: center">
            <img *ngIf="document.type === 'FILE'" alt="" width="30" [src]="iconOfFileName(document.name)">
            <img *ngIf="document.type === 'DIRECTORY'" alt="" width="30"
                 src="../../../assets/images/ic_collection_folder.svg">
            <span style="width: 390px; font-weight: bold; font-size: 12px; margin-left: 5px">
              {{ document.name }}
            </span>
          </td>
          <td *ngIf="!workbench.hideUploader">{{ document.accountName }}</td>
          <td>{{ document.fileSizeText }}</td>
          <td>
            <div style="display: flex; align-items: center;">
              <span>{{ document.updateTime | date:'yyyy-MM-dd HH:mm:ss' }}</span>
            </div>
          </td>
          <td nzRight nzAlign="left">
            <div class="functions" style="display: flex;">

              <!--              預覽-->
              <a *ngIf="document.type === 'FILE'"
                 appThrottleClick (throttleClick)="onPreview(document);"
                 nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Preview' | translate">
                <img alt="" width="20" src="../../../assets/images/ic_view.svg">
              </a>
              <!--              收藏-->
              <div *ngIf="document.isCollected"
                 nz-button nzType="link"
                 nzSize="small"
                 nz-tooltip [nzTooltipTitle]="'Collection' | translate">
                <img alt="" width="20" src="../../../assets/images/ic_collection_sel.svg">
              </div>
              <!--              &lt;!&ndash;              詳情&ndash;&gt;-->
              <!--              <a *ngIf="document.type === 'DIRECTORY'"-->
              <!--                 nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate">-->
              <!--                <img alt="" width="20" src="../../../assets/images/ic_details.svg">-->
              <!--              </a>-->
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>
