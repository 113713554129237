  <div class="drawer-container">

  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">

    <div class="common-title" style="margin-bottom: 40px">
      {{'BookingCreate' | translate}}
    </div>

    <app-property-select [displayMode]="'vertical'"
                         [title]="'ApplicationType' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('ApplicationType' | translate)"
                         [required]="true"
                         [options]="applicationTypeOptions"
                         [(value)]="draftReq.applicationType"></app-property-select>

    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'SelectToCombineBooking' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onBookingGroupSelect()">
          <div class="property-pick-value">
            <span style="flex: 1; font-weight: bold">{{ draftReq.groupNo }}</span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 预约信息 -->
    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'PreferredTime' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onBookingInfo()">
          <div class="property-pick-value">
            <span style="flex: 1; font-weight: bold">{{draftReq.temporaryTime | date:'yyyy-MM-dd HH:mm'}}</span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 产品计划 -->
    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'ProductPlan' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onBookingProductPlan()">
          <div style="display: flex; align-items: center; flex-direction: row;">
            <div style="flex: 1; margin-right: 10px">
              <div *ngIf="product"
                   style="font-weight: bold; font-size: 15px">{{product?.productName}}</div>
              <div>
              <span *ngIf="draftReq.productPlan?.currency" style="font-size: 13px; color: #999999">
                {{'Currency' | translate}}:{{draftReq.productPlan?.currency}}
              </span>
                <span *ngIf="draftReq.productPlan?.premium" style="font-size: 13px; color: #999999">
                {{'Premium' | translate}}:{{draftReq.productPlan?.premium | number}}
              </span>
                <span *ngIf="draftReq.productPlan?.sumAssured" style="font-size: 13px; color: #999999">
                {{'SumAssured' | translate}}:{{draftReq.productPlan?.sumAssured | number}}
              </span>
              </div>
            </div>
            <div><i nz-icon nzType="right" nzTheme="outline"></i></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 投保人 -->
    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'Holder' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onBookingOwner()">
          <div class="property-pick-value">
            <span style="flex: 1; font-weight: bold">{{draftReq.ownerNameString()}}</span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 受保人 -->
    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'Insured' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onBookingInsured()">
          <div class="property-pick-value">
            <span style="flex: 1; font-weight: bold">{{draftReq.insuredNameString()}}</span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 受益人 -->
    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'Beneficiary' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onBookingBeneficiaries()">
          <div class="property-pick-value">
            <span style="flex: 1; font-weight: bold">{{draftReq.firstBeneficiaryNameString()}}</span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 第二持有人 -->
    <div *ngIf="instance.isBaylightOrDev() || instance.key === 'wanson'" class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'SecondHolder' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onSecondHolder()">
          <div class="property-pick-value">
            <span style="flex: 1;"></span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>


    <!-- 第二受保人 -->
    <div *ngIf="instance.key !== 'fone'" class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'SecondInsured' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onSecondInsured()">
          <div class="property-pick-value">
            <span style="flex: 1;"></span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!--  預約單备注  -->
    <div class="booking-section-wrapper" *ngIf="(instance?.key === 'wanson' || instance?.key === 'dev')">
      <div class="property-row" appThrottleClick (throttleClick)="onBookingRemark()">
        <div class="property-title-row">{{ 'BookingRemark' | translate }}</div>
        <div class="property-value-row">
          <div class="property-pick-value">
            <div style="flex: 1;">{{ draftReq.special }}</div>
            <div><i nz-icon nzType="right" nzTheme="outline"></i></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 关联计划书 -->
    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'AssociateProposal' | translate }}</div>
        <div class="property-value-row"
             appThrottleClick (throttleClick)="onAssociateProposal(tplAssociateProposalTitle, tplAssociateProposalContent, tplAssociateProposalFooter)">
          <div class="property-pick-value">
            <span style="flex: 1;">
            <span *ngIf="draftReq.proposalId">
            <a nz-dropdown [nzDropdownMenu]="menu">
              #{{draftReq.proposalId}}
            </a>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item appThrottleClick (throttleClick)="onProposalDetail()"
                    style="color: #4B84E5; font-size: 14px;">{{'ViewDetail' | translate}}</li>
                <li nz-menu-item appThrottleClick (throttleClick)="onProposalReAssociate()"
                    style="color: #4B84E5; font-size: 14px;">{{'ReAssociate' | translate}}</li>
                <li nz-menu-item appThrottleClick (throttleClick)="onProposalDisassociate()"
                    style="color: #e63757; font-size: 14px;">{{'Disassociate' | translate}}</li>
              </ul>
            </nz-dropdown-menu>
            </span>
          </span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!--  转保声明  -->
<!--    <div class="booking-section-wrapper">-->
<!--      <div class="property-row" appThrottleClick (throttleClick)="onStatement()">-->
<!--        <div class="property-title-row">{{ 'ReinsuranceStatement' | translate }}</div>-->
<!--        <div class="property-value-row">-->
<!--          <div class="property-pick-value">-->
<!--            <span style="flex: 1;"></span>-->
<!--            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div *ngIf="!instance.isFone()" style="margin: 20px 0;">
      <label nz-checkbox [(ngModel)]="draftReq.isPaperContracts">{{'RequiredPaperContractsNot' | translate}}</label>
    </div>
    <div *ngIf="instance.isEmpire()" style="margin: 20px 0;">
      <label nz-checkbox [(ngModel)]="draftReq.authorizeCollect">{{'AuthorizeCollect' | translate}}</label>
    </div>
    <div *ngIf="workbench.bookingTipsRemark" style="margin: 20px 10px 10px 0; color: #666666">
      <span style="color: #1d64c4"><i nz-icon nzType="info-circle" nzTheme="outline"></i></span>
      <span style="margin-left: 5px" [innerHTML]="workbench.bookingTipsRemark | html"></span>
    </div>

  </nz-spin>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button" nz-tooltip [nzTooltipTitle]="'SaveToDraft' | translate">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSaveDraft()">
          <!--          <em nz-icon nzType="save" nzTheme="outline"></em>-->
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="onSummit()">
          <!--          <em nz-icon nzType="check" nzTheme="outline"></em>-->
          <span>{{'Summit' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>


<ng-template #tplAssociateProposalTitle>
  <span>{{'AssociateProposal' | translate}}</span>
</ng-template>
<ng-template #tplAssociateProposalContent let-ref="modalRef" let-params>
  <nz-list nzSize="small">
    <nz-list-item>
      <div style="text-align: center; width: 100%">
        <a nz-button nzType="link" nzSize="small" style="color: #4B84E5; font-size: 14px;"
           appThrottleClick (throttleClick)="onProposalDetail(); ref.destroy()">
          {{'ViewDetail' | translate}}
        </a>
      </div>
    </nz-list-item>
    <nz-list-item>
      <div style="text-align: center; width: 100%">
        <a nz-button nzType="link" nzSize="small" style="color: #4B84E5; font-size: 14px;"
           appThrottleClick (throttleClick)="onProposalReAssociate(); ref.destroy()">
          {{'ReAssociate' | translate}}
        </a>
      </div>
    </nz-list-item>
    <nz-list-item>
      <div style="text-align: center; width: 100%">
        <a nz-button nzType="link" nzSize="small" style="color: #e63757; font-size: 14px;"
           appThrottleClick (throttleClick)="onProposalDisassociate(); ref.destroy()">
          {{'Disassociate' | translate}}
        </a>
      </div>
    </nz-list-item>
  </nz-list>
</ng-template>
<ng-template #tplAssociateProposalFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()">{{'Cancel' | translate}}</button>
</ng-template>
