import {Component, OnInit} from '@angular/core';
import {Material, ProposalAmountType, TicketDetail, TicketReply, UploadTokenReq, UploadType} from '../../api/types';
import {MetadataService} from '../../shared/service/metadata.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {TicketService} from '../ticket.service';

@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.less']
})
export class TicketDetailComponent implements OnInit {

  loading = false;

  info: TicketDetail;

  ticketReply: TicketReply = new TicketReply();

  uploadTokenReq = new UploadTokenReq();

  stateModified = false;

  constructor(private metadataService: MetadataService,
              private modalService: NzModalService,
              private ticketService: TicketService) {
  }

  ngOnInit(): void {
    this.uploadTokenReq.uploadType = UploadType.TICKET;
    this.uploadTokenReq.middleName = this.info.id.toString();
  }

  loadDetail(id: number): void {
    this.loading = true;
    this.ticketService.detail(id)
      .subscribe(info => {
          this.info = info;
          this.loading = false;
          console.log(this.info);
        },
        error => {
          this.loading = false;
        });
  }

  reply(): void {

    if (!this.canReply) {
      return;
    }

    this.loading = true;

    this.ticketReply.ticketId = this.info.id;
    this.ticketService.reply(this.ticketReply)
      .subscribe(data => {
          console.log(data);
          this.loading = false;
          this.loadDetail(this.info.id);
          this.ticketReply = new TicketReply();
        },
        error => {
          this.loading = false;
        }
      );

    console.log(this.ticketReply);
  }

  replyAttachmentsChange(attachments: Material[]): void {
    this.ticketReply.attachments = attachments;
    console.log(attachments);
  }

  get canReply(): boolean {
    if (this.loading) {
      return false;
    }
    return !!this.ticketReply.content;
  }

  restartProblem(): void {
    this.ticketService.restart(this.info.id)
      .subscribe(data => {
        // console.log(data);
        if (data) {
          this.loadDetail(this.info.id);
        }
      }, () => {
      });
  }

}
