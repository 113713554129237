<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{ 'ReinsuranceStatement' | translate }}
  </div>
  <div *ngIf="!bookingDetail.showReplacementDeclaration" class="section-wrapper">
    <div>
      <!-- 过去12个月取代 -->
      <app-property-select [title]="'TransferPast12' | translate"
                           [displayMode]="'vertical'"
                           [placeholder]="('PleaseSelect' | translate)"
                           [options]="selectOptions"
                           [(value)]="other.transferPast12"></app-property-select>
      <!-- 未来12个月取代 -->
      <app-property-select [title]="'TransferFuture12' | translate"
                           [displayMode]="'vertical'"
                           [placeholder]="('PleaseSelect' | translate)"
                           [options]="selectOptions"
                           [bottomLineVisible]="false"
                           [(value)]="other.transferFuture12"></app-property-select>
    </div>
  </div>
  <div *ngIf="bookingDetail.showReplacementDeclaration" class="section-wrapper">
    <div style="margin-top: 5px">
      <p *ngFor="let item of titleArr">{{item | translate}}</p>
    </div>
    <div>
      <nz-radio-group [(ngModel)]="replacementDeclaration">
        <label *ngFor="let adio of declarationOptions" nz-radio [nzValue]="adio.key">{{adio.value}}</label>
      </nz-radio-group>
    </div>
    <div style="margin: 5px 0">{{'ReinsuranceTips' | translate}}</div>
  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
