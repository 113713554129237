import { Component, OnInit } from '@angular/core';
import { PagedResp } from '../../api/types';
import { InformationListResp, InformationSearch } from '../../information/information-types';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from '../../shared/service/metadata.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { InformationService } from '../../information/information.service';
import { InformationDetailComponent } from '../../information/information-detail/information-detail.component';
import {
  ProductSelectionComponent,
  ProductSelectionType
} from '../../shared/component/product-selection/product-selection.component';
import { ProductPromotionDetailComponent } from '../product-promotion-detail/product-promotion-detail.component';
import { ProductService } from '../../product/product.service';
import { ProductCompany } from '../../product/product-types';

@Component({
  selector: 'app-product-promotion-list',
  templateUrl: './product-promotion-list.component.html',
  styleUrls: ['./product-promotion-list.component.less']
})
export class ProductPromotionListComponent implements OnInit {

  loading = false;

  spinning = false;

  informations: PagedResp<InformationListResp>;

  search: InformationSearch = new InformationSearch();
  companies: ProductCompany[];

  filterPopoverVisible = false;
  regionOptions: PropertySelectOption[] = [];
  companyOptions: PropertySelectOption[] = [];
  typeOptions: PropertySelectOption[] = [];
  constructor(private router: ActivatedRoute,
              private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private productService: ProductService,
              private informationService: InformationService) { }

  ngOnInit(): void {
    this.loading = true;

    this.informationService.newList(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.informations = data;
        },
        error => {
          this.loading = false;
        });

    const informationTypes = this.metadataService.values('informationType');
    for (const informationType of informationTypes) {
      this.typeOptions.push(new PropertySelectOption(informationType.value, informationType.key));
    }
    this.getCompanies();
    this.getRegion();
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.informationService.newList(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.informations = data;
        },
        error => {
          this.loading = false;
        });
  }


  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search = new InformationSearch();
    }
    this.informationService.newList(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.informations = data;
        },
        error => {
          this.loading = false;
        });
  }

  onResetSearch(): void {
    this.search = new InformationSearch();
    this.companies.forEach(company => {
      this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
    });
    this.onReload();
  }

  onInformationDetail(id: number): void {
    this.loading = true;
    this.informationService.info(id)
      .subscribe(
        information => {
          this.loading = false;
          const drawerRef = this.drawerService.create<ProductPromotionDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: ProductPromotionDetailComponent,
            nzContentParams: {
              information
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.DEFAULT
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.search.product = product;
            this.search.productCode = this.search.product.productCode;
            drawerRef.close();
            this.filterPopoverVisible = true;
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  getCompanies(): void {
    this.productService.companies()
      .subscribe(
        data => {
          this.companies = data;
          data.forEach(company => {
            this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
          });

        },
        error => {
        });
  }

  getRegion(): void {
    this.productService.companiesRegion()
      .subscribe(regions => {
        for (const region of regions) {
          this.regionOptions.push(new PropertySelectOption(region.name, region.key));
        }
      }, () => {});
  }

  onRegion(even): void {
    console.log(even);
    let copy: ProductCompany[] = [];
    let strArr: PropertySelectOption[] = [];
    const options: PropertySelectOption[] = [];
    if (even && even.length === 1 && even.includes('')) {
      return ;
    } else {
      if (even && even.length) {
        even.map(item => {
          if (this.regionOptions.find(region => region.value === item)){
            strArr.push(...this.regionOptions.filter(region => region.value === item))
          }
        });
      } else {
        this.companyOptions = [];
        this.companies.forEach(company => {
          this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
        });
      }
      console.log(strArr, 'strArrstrArrstrArr');
      if (strArr && strArr.length) {
        strArr.map(item => {
          if (this.companies.find(companie => companie.region === item.label)) {
            copy.push(...this.companies.filter(companie => companie.region === item.label))
          }
        });
        copy?.forEach(company => {
          options?.push(new PropertySelectOption(company.name, company.code));
        });
        this.companyOptions = JSON.parse(JSON.stringify(options));
      }
    }
  }

}
