import {EventEmitter, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { en_US, NzI18nService, zh_CN, zh_HK, ja_JP, ko_KR } from 'ng-zorro-antd/i18n';
import {Locale} from '../api/types';
import {Constant} from '../base/constant';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  localeChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translate: TranslateService,
              private i18n: NzI18nService) {
  }

  get locale(): Locale {
    return (localStorage.getItem(Constant.LOCAL_STORAGE_KEY_LOCALE) as Locale) ?? Locale.hk;
  }

  setLocale(val: Locale): Observable<any> {
    localStorage.setItem(Constant.LOCAL_STORAGE_KEY_LOCALE, val);
    this.localeChanged.emit(val);

    switch (val) {
      case Locale.cn:
        this.i18n.setLocale(zh_CN);
        break;
      case Locale.hk:
        this.i18n.setLocale(zh_HK);
        break;
      case Locale.en:
        this.i18n.setLocale(en_US);
        break;
      case Locale.jp:
        this.i18n.setLocale(ja_JP);
        break;
      case Locale.ko:
        this.i18n.setLocale(ko_KR);
        break;
    }

    return this.translate.use(val);
  }

  get locales(): Locale[] {
    return environment.supportedLocale;
  }
}
