import { Component, Input, OnInit } from '@angular/core';
import { PropertyDisplayMode } from '../../shared';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export declare type PropertyTextEditType = 'default' | 'html';

/**
 * 用於屬性展示
 */
@Component({
  selector: 'app-property-text',
  templateUrl: './property-text.component.html',
  styleUrls: ['./property-text.component.less']
})
export class PropertyTextComponent implements OnInit {

  @Input() displayMode: PropertyDisplayMode = 'horizontal';
  @Input() title: string;
  @Input() tip: string;
  @Input() tipVisible = false;
  @Input() bottomLineVisible = true;
  @Input() valueColor = '#333333';
  @Input() textType: PropertyTextEditType = 'default';
  @Input() isEncryption = false;  // 是否需要加*号

  innerValue: any;

  @Input()
  get value(): any {
    if (this.textType === 'html') {
      return this.innerValue;
    } else if (this.isEncryption) {
      return this.encryption(this.innerValue);
    } else {
      return this.innerValue;
    }
  }

  set value(val: any) {
    this.innerValue = val;
  }

  get html(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.value);
  }

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
  }

  // 加**
  dataMasking(str, frontLen = 0, endLen = 0): string {
    if (!str) {
      return ;
    }
    if (str.toString().length === 1) {
      return '*';
    }
    str = str.toString();
    const len = str.length - frontLen - endLen;
    let xing = '';
    for (let i = 0; i < len; i++) {
      xing += '*';
    }
    return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
  }

  encryption(str): any {
    if (!str) {
      return ;
    }
    let getResult = null;
    switch (str.toString().length) {
      case 1:
      case 2:
        getResult = this.dataMasking(str, 1, 0);
        break;
      case 3:
      case 4:
      case 5:
        getResult = this.dataMasking(str, 1, 1);
        break;
      case 6:
      case 7:
      case 8:
        getResult = this.dataMasking(str, 2, 1);
        break;
      default:
        getResult = this.dataMasking(str, 3, 3);
        break;
    }
    return getResult;
  }
}
