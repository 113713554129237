<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate"  [nzSpinning]="spinning">
    <div *ngIf="!contactList">
      <nz-empty nzNotFoundImage="simple"></nz-empty>
    </div>
    <div *ngIf="contactList" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzShowPagination]="true"
                [nzOuterBordered]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="contactList.list"
                [nzPageIndex]="contactList.pageNum"
                [nzPageSize]="contactList.pageSize"
                [nzTotal]="contactList.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{ x: '1415'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'ContactsList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        nz-tooltip [nzTooltipTitle]="'ContactSearch' | translate"
                        nz-popover [nzPopoverContent]="searchTemplate"
                        [(nzPopoverVisible)]="searchPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight"
                        style=" margin-right: 10px;">
                  <img alt="" width="22" src="assets/images/ic_search.svg">
                </button>

                <button *ngIf="mode === 'OWN'" nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'CreateContacts' | translate"  style=" margin-left: 20px;"
                        appThrottleClick (throttleClick)="onContactCreate()">
                  <img alt="" width="22" src="assets/images/ic_proposal_new.svg">
                </button>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ contactList.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzAlign="left" nzWidth="165px">
            <span nz-tooltip
                  [nzTooltipTitle]="'FullName' | translate">{{'FullName' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="165px">
            <span nz-tooltip
                  [nzTooltipTitle]="'NameInEnglish' | translate">{{'NameInEnglish' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Gender' | translate">{{'Gender' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Age' | translate">{{'Age' | translate}}</span>
          </th>
          <th nzRight nzAlign="center" nzWidth="160px">
            <span>{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let contacts of contactList.list; let i = index"
            appThrottleClick (throttleClick)="onDetail(contacts.id)"
        >
          <td nzLeft nzAlign="left">
            <div style="display: flex; align-items: center">
              <nz-avatar class="list-top-avatar" style="flex-shrink: 0; margin-right: 20px;" [nzSize]="36" [nzSrc]="contacts.avatar"></nz-avatar>
              <span style="flex-shrink: 1;font-weight: bold; font-size: 12px">
              {{ contacts.name }}
            </span>
            </div>
          </td>
          <td nzAlign="center" ><span>{{ contacts.pinyin }}</span></td>
          <td nzAlign="center" ><span>{{ contacts.gender | metadataTranslate: 'gender' }}</span></td>
          <td nzAlign="center" ><span>{{ contacts.age }} {{'YearsOld' | translate}}</span></td>
          <td nzRight nzAlign="center" >
            <div class="functions">
              <a nz-button nzType="link" nzSize="small"
                 nz-tooltip [nzTooltipTitle]="'Detail' | translate">
                <img alt="" width="20" src="../../../assets/images/ic_details.svg">
              </a>
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
                 appThrottleClick (throttleClick)="onDelete(contacts.id); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_delete.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
  <nz-modal
    [(nzVisible)]="isVisible"
    [nzTitle]="modalTitle"
    [nzContent]="modalContent"
    [nzFooter]="null"
    [nzWidth]="'400px'"
    nzCentered
    [nzClosable]="false"
    [nzMaskClosable]="false"
    (nzOnCancel)="handleCancel()"
  >
    <ng-template #modalTitle style="text-align: center">{{'Tips' | translate}}</ng-template>

    <ng-template #modalContent>
      <p style="text-align: center; margin-bottom: 30px">{{'DoYouWantToDeleteContacts' | translate}}</p>
      <div nz-row style="width: 100%; height: 100%; justify-content: space-around;">
        <button nz-col nzSpan="10" nz-button nzType="default" appThrottleClick (throttleClick)="handleCancel()">{{'Cancel' | translate}}</button>
        <button nz-col nzSpan="10" nz-button nzType="primary" appThrottleClick (throttleClick)="handleOk()">{{'Confirm' | translate}}</button>
      </div>
    </ng-template>
  </nz-modal>
</div>

<!-- 搜索气泡Template -->
<ng-template #searchTemplate>
  <div style="width: 300px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'ContactSearch' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <!-- 名字 -->
    <app-property-input [title]="'ContactName' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('ContactName' | translate)"
                        [(value)]="search.name"></app-property-input>
    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
