import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address, Instance } from '../../../api/types';
import * as _ from 'lodash';
import { plainToInstance } from 'class-transformer';
import { WorkbenchService } from '../../../workbench/workbench.service';
import { Workbench } from '../../../workbench/workbench-types';

@Component({
  selector: 'app-address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.less']
})
export class AddressEditComponent implements OnInit {

  title: string;
  modeType: string;

  address: Address;
  workbench: Workbench;

  // 快捷地址
  quickAddresses: Address[] = [];
  instance: Instance;

  @Output()
  addressSaved: EventEmitter<Address> = new EventEmitter<Address>();

  constructor(private workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    this.instance = this.workbenchService.mockInstance;
    this.workbench = this.workbenchService.workbench;
  }

  onQuickAddress(address: Address): void {
    this.address = plainToInstance(Address, address);
  }

  onSave(): void {
    this.addressSaved.emit(this.address);
  }

  onReset(): void {
    this.address = new Address();
  }

  isAddressConfig(): boolean {
    if (this.instance.isBetterOrDev()) {
      return false;
    }
    if (this.modeType === 'booking') {
      return !this.workbench.addressConfig;
    }
    return true;
  }

}
