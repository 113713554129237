import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShareComponent} from './share.component';
import {ShareMainComponent} from './share-main/share-main.component';
import {SharedModule} from '../shared/shared.module';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzTableModule} from 'ng-zorro-antd/table';
import {ShareListComponent} from './share-list/share-list.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzIconModule} from 'ng-zorro-antd/icon';
import { ShareDetailComponent } from './share-detail/share-detail.component';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import { ShareConnectionsComponent } from './share-connections/share-connections.component';
import {NgxEchartsModule} from 'ngx-echarts';
import {NzTimelineModule} from 'ng-zorro-antd/timeline';
import { ShareBrowseInfoComponent } from './share-browse-info/share-browse-info.component';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';


@NgModule({
  declarations: [
    ShareComponent,
    ShareMainComponent,
    ShareListComponent,
    ShareDetailComponent,
    ShareConnectionsComponent,
    ShareBrowseInfoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NzLayoutModule,
    RouterModule,
    TranslateModule,
    NzSpinModule,
    NzSkeletonModule,
    NzTableModule,
    ScrollingModule,
    NzListModule,
    NzGridModule,
    NzToolTipModule,
    NzPopoverModule,
    NzButtonModule,
    NzTagModule,
    NzIconModule,
    NzBreadCrumbModule,
    NzDividerModule,
    NgxEchartsModule,
    NzTimelineModule,
    NzAvatarModule
  ],
  providers: [{provide: NzMessageService}]
})
export class ShareModule {
}
