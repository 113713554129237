<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{ 'Other' | translate }}
  </div>
  <div class="section-wrapper">
    <div *ngFor="let item of dataCopy; let i = index">
      <app-property-input *ngIf="item.fieldType == 'TextField'" [title]="item.fieldName"
                          [placeholder]="''"
                          [(value)]="item.value"></app-property-input>

      <app-property-input *ngIf="item.fieldType == 'TextareaField'" [title]="item.fieldName"
                          [placeholder]="''"
                          [(value)]="item.value"></app-property-input>

      <app-property-input-number *ngIf="item.fieldType == 'MoneyField'" [title]="item.fieldName"
                                 [(value)]="item.value"></app-property-input-number>

      <app-property-input-number *ngIf="item.fieldType == 'NumberField'" [title]="item.fieldName"
                                 [(value)]="item.value"></app-property-input-number>

      <app-property-datetime *ngIf="item.fieldType == 'DateField'" [title]="item.fieldName"
                             [formatter]="'yyyy-MM-dd'"
                             [(value)]="item.value"></app-property-datetime>

      <app-property-datetime *ngIf="item.fieldType == 'DateTimeField'" [title]="item.fieldName"
                             [formatter]="'yyyy-MM-dd HH:mm'"
                             [showTime]="{nzFormat: 'HH:mm'}"
                             [(value)]="item.value"></app-property-datetime>

      <app-property-select *ngIf="item.fieldType == 'SingleBox'" [title]="item.fieldName"
                           [options]="item.values"
                           [(value)]="item.value"></app-property-select>

      <app-property-select *ngIf="item.fieldType == 'MultiCheckBoxes'" [title]="item.fieldName"
                           [mode]="'multiple'"
                           [options]="item.values"
                           [(value)]="item.value"></app-property-select>

    </div>
  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
