import {Component, Input, OnInit} from '@angular/core';
import {FnaFormConfig, NewFnaDetail} from '../../new-fna/new-fna-types';
import {NewFnaService} from '../../new-fna/new-fna.service';

@Component({
  selector: 'app-zuu-form-selected',
  templateUrl: './zuu-form-selected.component.html',
  styleUrls: ['./zuu-form-selected.component.less']
})
export class ZuuFormSelectedComponent implements OnInit {

  @Input()
  fnaInfo: NewFnaDetail = new NewFnaDetail();

  loading = false;

  formLanguage = 'zh-Hant-HK';

  formConfigArr: FnaFormConfig[] = [];
  formConfigArrCopy: FnaFormConfig[] = [];

  subjects: FnaFormConfig[] = [];

  constructor(private newFnaService: NewFnaService) { }

  ngOnInit(): void {
    this.getFormConfig();
  }


  getFormConfig(): void {
    this.formLanguage = this.fnaInfo.formLanguage || 'zh-Hant-HK';
    this.setValue();
  }

  setValue(): void {
    this.loading = true;
    this.newFnaService.fnaFormConfig(this.formLanguage)
      .subscribe(
        data => {
          console.log(data);
          console.log(this.fnaInfo.fnaBusinessType);
          if (this.fnaInfo.id) {
            data?.map(item => {
              if (item.category === this.fnaInfo.fnaBusinessType) {
                item.vos.map(vo => {
                  vo.tick = true;
                });
              }
            });
          }
          this.formConfigArr = JSON.parse(JSON.stringify(data));
          this.formConfigArr?.map(item => {
            this.setVo(item.vos);
          });
          console.log(this.formConfigArr);
          this.formConfigArrCopy = JSON.parse(JSON.stringify(data));
          // setTimeout(() => {
          //   this.signatureInfoCom.setSignatureValue();
          // }, 200);
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  setVo(arr: FnaFormConfig[] = []): void {
    if (this.fnaInfo.categoryNos && this.fnaInfo.categoryNos.length) {
      arr.map(item => {
        // if (!item.formNo) {
        //   item.tick = item.vos.some(vo => this.fnaInfo.formNos.includes(vo.formNo));
        // } else {
        //   item.tick = this.fnaInfo.formNos.includes(item.formNo);
        // }
        item.tick = this.fnaInfo.categoryNos.includes(item.category);
        if (item.vos && item.vos.length) {
          this.setVo(item.vos);
        }
      });
    }
  }

  log(value: string[], type: FnaFormConfig): void {
    this.formConfigArr.map(item => {
      if (item.category === type.leader){
        item?.vos.map(vo => {
          if (vo.category === type.category) {
            vo.tick = !vo.tick;
          } else {
            vo.tick = false;
          }
          vo?.vos.map(vo2 => {
            vo2.tick = false;
          });
        });
      }
    });
    this.subjects = type.vos;

    // if (type === 'EVALUATION_01_No') {
    //   this.fnaAssessAdvice.EVALUATION_01_No = !this.fnaAssessAdvice.EVALUATION_01_No;
    //   this.fnaAssessAdvice.EVALUATION_01_Yes = this.fnaAssessAdvice.EVALUATION_01_No ? false : false;
    // } else {
    //   this.fnaAssessAdvice.EVALUATION_01_Yes = !this.fnaAssessAdvice.EVALUATION_01_Yes;
    //   this.fnaAssessAdvice.EVALUATION_01_No = this.fnaAssessAdvice.EVALUATION_01_Yes ? false : false;
    // }
  }

  formLanguageChange(flag): void {
    this.setValue();
    // const result = null;
    // if (flag === this.formLanguage) {
    //   this.formLanguage = result;
    // } else {
    //   this.formLanguage = flag;
    // }
  }

  // setData(arr: FnaFormConfig[] = []): void {
  //   console.log(arr);
  // }


  /**
   * 计算年龄（出生日期格式为yyyy-mm-dd）
   * @param dateString - 出生日期
   * @returns 年龄
   */
  getAge(dateString): number {
    const today = new Date();
    const birthDate = new Date(dateString);

    // 确保出生日期合法
    if (isNaN(birthDate.getTime())) {
      // return 'Invalid date';
      return 0;
    }

    const diffTime = today.getTime() - birthDate.getTime();

    // 年龄的计算
    let age = diffTime / (365 * 24 * 60 * 60 * 1000);
    age = age > 0 ? Math.floor(age) : Math.ceil(age);

    return age;
  }

}
