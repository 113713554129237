import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ContactComponent } from './contact.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContactMainComponent } from './contact-main.component';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import { ContactsComponent } from './contacts/contacts.component';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import { ContactFamilyComponent } from './contact-family/contact-family.component';
import {FileUploadModule} from 'ng2-file-upload';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzModalModule} from 'ng-zorro-antd/modal';
import { ContactAddMemberComponent } from './contact-add-member/contact-add-member.component';
import { ContactsConnectionsComponent } from './contacts-connections/contacts-connections.component';
import { ContactsSubordinateComponent } from './contacts-subordinate/contacts-subordinate.component';
import { ContactsTreeComponent } from './contacts-tree/contacts-tree.component';
import { NzTreeViewModule} from 'ng-zorro-antd/tree-view';
import { NzImageModule} from 'ng-zorro-antd/image';
import {QRCodeModule} from 'angular2-qrcode';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import { ContactsMeetingRecordComponent } from './components/contacts-meeting-record/contacts-meeting-record.component';
import {FormsModule} from '@angular/forms';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import { ContactsCreateRecordComponent } from './components/contacts-create-record/contacts-create-record.component';
import { ContactsRecordDetailsComponent } from './components/contacts-record-details/contacts-record-details.component';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzTreeModule} from 'ng-zorro-antd/tree';
import { NzTagModule } from 'ng-zorro-antd/tag';



@NgModule({
  declarations: [
    ContactComponent,
    ContactMainComponent,
    ContactsComponent,
    ContactInfoComponent,
    ContactFamilyComponent,
    ContactAddMemberComponent,
    ContactsConnectionsComponent,
    ContactsSubordinateComponent,
    ContactsTreeComponent,
    ContactsMeetingRecordComponent,
    ContactsCreateRecordComponent,
    ContactsRecordDetailsComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        NzLayoutModule,
        SharedModule,
        TranslateModule,
        NzTabsModule,
        NzSpinModule,
        NzSkeletonModule,
        NzEmptyModule,
        NzToolTipModule,
        NzTableModule,
        NzGridModule,
        NzButtonModule,
        NzIconModule,
        NzPopoverModule,
        NzAvatarModule,
        NzUploadModule,
        FileUploadModule,
        NzBreadCrumbModule,
        NzModalModule,
        NzTreeViewModule,
        NzImageModule,
        QRCodeModule,
        NzDividerModule,
        FormsModule,
        NzSwitchModule,
        NzRadioModule,
        NzTreeModule,
        NzTagModule
    ]
})
export class ContactModule { }
