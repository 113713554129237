import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Beneficiary, Customer, Gender, Instance, Phone, Relationship } from '../../../api/types';
import {PropertySelectOption} from '../property-select/property-select.component';
import {MetadataService} from '../../service/metadata.service';
import {plainToClass, plainToInstance} from 'class-transformer';
import {Phones} from '../../utils/phones';
import {PhoneEditComponent} from '../phone-edit/phone-edit.component';
import {TranslateService} from '@ngx-translate/core';
import {Addresses} from '../../utils/addresses';
import {AddressEditComponent} from '../address-edit/address-edit.component';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import { formatDate } from '@angular/common';
import {Workbench} from '../../../workbench/workbench-types';
import {WorkbenchService} from '../../../workbench/workbench.service';
import {ContactsListComponent} from '../contacts-list/contacts-list.component';
import {Booking} from '../../../booking/booking-types';

@Component({
  selector: 'app-beneficiary-edit',
  templateUrl: './beneficiary-edit.component.html',
  styleUrls: ['./beneficiary-edit.component.less']
})
export class BeneficiaryEditComponent implements OnInit {

  beneficiary: Beneficiary = new Beneficiary();
  beneficiaries: Beneficiary[] = [];
  relationshipOptions: PropertySelectOption[] = [];
  genderOptions: PropertySelectOption[] = [];
  registrationCountryOptions: PropertySelectOption[] = [];
  workbench: Workbench;

  insured: Customer;

  instance: Instance;
  isInputName = false;
  @Input()
  booking: Booking;
  @Input()
  modeType: string;
  @Input()
  type: string;
  @Output()
  beneficiarySaved: EventEmitter<Beneficiary> = new EventEmitter<Beneficiary>();
  relationTypeOptions: PropertySelectOption[] = [];

  constructor(private metadataService: MetadataService,
              private workbenchService: WorkbenchService,
              private translate: TranslateService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.instance = this.metadataService.mockInstance;
    this.isDefaultFill();

    const relationsType = this.metadataService.values('ownerType');
    for (const relationship of relationsType) {
      this.relationTypeOptions.push(new PropertySelectOption(relationship.value, relationship.key));
    }

    this.beneficiary.beneficiaryType = this.beneficiary.beneficiaryType || 'INDIVIDUAL';
    const genders = this.metadataService.values('gender');
    for (const gender of genders) {
      this.genderOptions.push(new PropertySelectOption(gender.value, gender.key));
    }
    const registrationCountrySelect = this.metadataService.values('nationality');
    for (const registrationCountrys of registrationCountrySelect) {
      this.registrationCountryOptions.push(new PropertySelectOption(registrationCountrys.value, registrationCountrys.key));
    }

    if (this.modeType === 'booking') {
      const relationships = this.metadataService.values('bookingRelationship');
      for (const relationship of relationships) {
        this.relationshipOptions.push(new PropertySelectOption(relationship.value, relationship.key));
      }
    } else {
      const relationships = this.metadataService.values('relationship');
      for (const relationship of relationships) {
        this.relationshipOptions.push(new PropertySelectOption(relationship.value, relationship.key));
      }
    }

    this.beneficiary.birthdate ? this.beneficiary.birthday = new Date(this.beneficiary.birthdate).valueOf() : this.beneficiary.birthday = null;
  }

  onReset(): void {
    this.beneficiary = new Beneficiary();
    this.isDefaultFill();
  }

  get canSave(): boolean {
    if (!this.beneficiary.relation) {
      return false;
    }
    if (!this.beneficiary.beneficiaryType) {
      return false;
    }
    if (this.beneficiary.relation !== Relationship.OWN) {
      if (this.beneficiary.beneficiaryType === 'INDIVIDUAL') {

        if (!this.beneficiary.firstName) {
          return false;
        }
        if (!this.workbench.hiddenLastName && !this.beneficiary.lastName) {
          return false;
        }
        if (!this.beneficiary.firstNamePinyin) {
          return false;
        }
        if (!this.workbench.hiddenLastName && !this.beneficiary.lastNamePinyin) {
          return false;
        }
        if (!this.beneficiary.gender) {
          return false;
        }
        if (!this.beneficiary.idCard) {
          return false;
        }
      }
      if (this.beneficiary.beneficiaryType === 'CORPORATE') {
        if (!this.beneficiary.companyName) {
          return false;
        }
        if (!this.beneficiary.companyNameEn) {
          return false;
        }
        if (!this.beneficiary.businessRegistrationNumber) {
          return false;
        }
        if (!this.beneficiary.registrationDate) {
          return false;
        }
        if (!this.beneficiary.officePhoneNumberString()) {
          return false;
        }
      }
    }
    return true;
  }

  onSave(): void {
    if (!this.canSave) {
      return;
    }
    if (this.beneficiary.relation === Relationship.OWN) {
      const tempBeneficiary = plainToClass(Beneficiary, this.beneficiary, {excludeExtraneousValues: true});
      this.beneficiary = plainToClass(Beneficiary, this.insured, {excludeExtraneousValues: true});
      this.beneficiary.relation = tempBeneficiary.relation;
      this.beneficiary.remark = tempBeneficiary.remark;
      this.beneficiary.percentage = tempBeneficiary.percentage;
    }
    this.beneficiarySaved.emit(this.beneficiary);
  }

  onCorporateOfficePhoneNumber(): void {
    this.translate.get('OfficePhoneNumber').subscribe(
      (title: any) => {
        const phone = new Phone();
        const quickPhones = Phones.getBeneficiaryQuickPhones(this.beneficiaries);
        phone.areaCode = this.beneficiary.areaCode;
        phone.phone = this.beneficiary.telephone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.beneficiary.areaCode = data?.areaCode;
              this.beneficiary.telephone = data?.phone;
            }
          );
        });
      });
  }

  onCorporateResidentialAddress(): void {
    this.translate.get('RegisteredAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getBeneficiaryQuickAddresses(this.beneficiaries);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.beneficiary.registeredAddress,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.beneficiary.registeredAddress = data;
            }
          );
        });
      });
  }

  onCorporateContactAddress(): void {
    this.translate.get('PostalAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getBeneficiaryQuickAddresses(this.beneficiaries);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.beneficiary.contactAddress,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.beneficiary.contactAddress = data;
            }
          );
        });
      });
  }

  onCorporateLegalPersonPhoneNumber(): void {
    this.translate.get('LegalPersonPhone').subscribe(
      (title: any) => {
        const quickPhones = Phones.getBeneficiaryQuickPhones(this.beneficiaries);

        const phone = new Phone();
        phone.areaCode = this.beneficiary.legalPersonAreaCode;
        phone.phone = this.beneficiary.legalPersonPhone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.beneficiary.legalPersonAreaCode = data?.areaCode;
              this.beneficiary.legalPersonPhone = data?.phone;
            }
          );
        });
      });
  }

  isDefaultFill(): void {
    if (this.instance.isFoneOrDev() || this.instance.isWDF()) {
      this.isInputName = true;
      if (!this.beneficiary.lastName) {
        this.beneficiary.lastName = '-';
      }
      if (!this.beneficiary.lastNamePinyin) {
        this.beneficiary.lastNamePinyin = '-';
      }
    }
  }

  getAge(date): void {
    if (!date) {
      return;
    }
    this.beneficiary.birthdate = formatDate(date, 'yyyy-MM-dd', 'en-US');
  }

  relationFlag(): boolean {
    return this.workbench.relation;
  }

  onContacts(): void {
    const drawerRef = this.drawerService.create<ContactsListComponent, { value: any }, string>(
      {
        nzWidth: 600,
        nzMaskClosable: true,
        nzContent: ContactsListComponent,
        nzContentParams: {}
      },
    );
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.detailSelected.subscribe(data => {
        console.log(this.beneficiary);

        const arr = Object.keys(plainToInstance(Beneficiary, {}));
        arr.forEach(item => {
          if (data[item]) {
            this.beneficiary[item] = data[item];
          }
        });
        // this.beneficiary = plainToInstance(Beneficiary, data);
        drawerRef.close();
        // console.log(this.customer);
      });
    });
  }

  onOwner(): void {
    console.log('onOwner');
    const arr = Object.keys(plainToInstance(Beneficiary, {}));
    if (this.beneficiary.beneficiaryType === 'INDIVIDUAL') {
      arr.forEach(item => {
        if (this.booking.owner[item]) {
          this.beneficiary[item] = this.booking.owner[item];
        }
      });
    } else {
      arr.forEach(item => {
        if (this.booking.companyOwner[item]) {
          this.beneficiary[item] = this.booking.companyOwner[item];
        }
      });
    }
  }

  onInsured(): void {
    console.log('onInsured');
    const arr = Object.keys(plainToInstance(Beneficiary, {}));
    if (this.beneficiary.beneficiaryType === 'INDIVIDUAL') {
      if (this.booking.relationship === 'OWN') {
        arr.forEach(item => {
          if (this.booking.owner[item]) {
            this.beneficiary[item] = this.booking.owner[item];
          }
        });
      } else {
        arr.forEach(item => {
          if (this.booking.insured[item]) {
            this.beneficiary[item] = this.booking.insured[item];
          }
        });
      }
    } else {
      if (this.booking.relationship === 'OWN') {
        arr.forEach(item => {
          if (this.booking.companyOwner[item]) {
            this.beneficiary[item] = this.booking.companyOwner[item];
          }
        });
      } else {
        arr.forEach(item => {
          if (this.booking.companyInsured[item]) {
            this.beneficiary[item] = this.booking.companyInsured[item];
          }
        });
      }
    }
  }

  getIdCardChange(event): void {
    if (event && event.length === 18) {
      console.log(event);
      const idCardNumber = event; // 替换为实际的身份证号
      const birthYear = idCardNumber.substring(6, 10);
      const birthMonth = idCardNumber.substring(10, 12);
      const birthDay = idCardNumber.substring(12, 14);
      const birthDate = `${birthYear}-${birthMonth}-${birthDay}`;
      console.log(birthDate); // 打印出生日期
      // this.customer.birthdate = birthDate;
      this.beneficiary.birthday = new Date(birthDate).valueOf();
    }
  }


}



