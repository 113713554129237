<div class="guide-container">
  <!--  <nz-carousel nzEffect='scrollx' style="background-color: #13263f">-->
  <!--    <div nz-carousel-content *ngFor="let content of contents">-->
  <!--      <div>-->
  <!--        <img width="400" [src]="content.cover" alt="" style="background-color: #95AAC9">-->
  <!--      </div>-->
  <!--      <div>-->
  <!--        <h3>{{ content.title }}</h3>-->
  <!--      </div>-->
  <!--      <div>-->
  <!--        <h3>{{ content.content }}</h3>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </nz-carousel>-->

  <swiper [spaceBetween]="10" [pagination]="{clickable: true}" [autoplay]="false">
    <ng-template swiperSlide *ngFor="let content of contents">
      <div class="swiper-slide">
        <img [src]="content.cover" alt="">
        <div class="swiper-title">{{ content.title }}</div>
        <div class="swiper-description">{{ content.content }}</div>
      </div>
    </ng-template>
  </swiper>

  <div style="margin-top: 30px; margin-bottom: 30px">
    <button style="width: 120px; height: 50px;" type="button"
            nz-button nzType="default" nzShape="round" appThrottleClick (throttleClick)="onLoginNow()">
<!--      <i nz-icon nzType="arrow-right" nzTheme="outline"></i>-->
      {{'LoginNow' | translate}}
    </button>
  </div>
</div>
