<div *ngIf="product.information">
  <nz-spin [nzSpinning]="loading">
    <div *ngFor="let information of product.information" style="padding: 10px 0;cursor: pointer" appThrottleClick
         (throttleClick)="onInformationDetail(information.id)">
      <div style="display: flex; flex-direction: column">
        <div style="color: #404040; font-size: 16px; font-weight: bold">
          {{information.title}}
        </div>
        <div style="margin-top: 10px" class="text-ellipsis">
          <span>{{information.companyName}} | </span>
          <span>{{information?.productNames.join(' | ')}}</span>
          <span *ngIf="information.productNames?.length && information.tags?.length"> | </span>
          <span>{{information?.tags.join(' | ')}}</span>
<!--          <nz-tag *ngFor="let productName of information.productNames"-->
<!--                  style="margin-bottom: 3px">{{productName}}</nz-tag>-->
<!--          <nz-tag *ngFor="let tag of information.tags" style="margin-bottom: 3px">{{tag}}</nz-tag>-->
        </div>
      </div>
      <div style="margin: 12px 0 0 0; color: #A6A6A6">{{information.time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
      <div class="property-bottom-line" style="margin: 12px 0 0 0"></div>
    </div>
  </nz-spin>
</div>
<div *ngIf="!product.information">
  <nz-empty nzNotFoundImage="simple"></nz-empty>
</div>
