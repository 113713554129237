<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{ questionDetail.introduction }}
  </div>
  <div>
    <div *ngIf="questionDetail.question && questionDetail.question.type == 'score'">
      <div *ngIf="editDataSource && editDataSource.question">
        <div *ngFor="let item of editDataSource.question?.question" >
          <div *ngIf="item.type == 'title'" class="question-title mt-8">
            {{ item.label }}
          </div>
          <div *ngIf="item.type == 'question' && !item.parent" class="question_box">
            <div class="question_box_right">
              <div class="question-title">
                <span *ngIf="item.number">{{ item.num }}.</span>{{ item.label }}
              </div>
              <div *ngIf="item.value && item.value.type == 'multi-select'" class="mt-8">
                <nz-checkbox-group [(ngModel)]="item.value.data"></nz-checkbox-group>
<!--                <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="scoreCheckbox($event)">-->
<!--                  <div nz-row>-->
<!--                    <div nz-col nzSpan="24" *ngFor="let item of item.value.data">-->
<!--                      <label nz-checkbox [nzValue]="item.answerId" [ngModel]="item.checked">-->
<!--                        {{item.label}}-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </nz-checkbox-wrapper>-->
                <div *ngFor="let item of item.value.data">
                  <app-property-input *ngIf="item.answerId === '1-6' && item?.checked"
                                      [displayMode]="'vertical'"
                                      [title]=""
                                      [placeholder]="''"
                                      style="position: relative;z-index: 1000"
                                      [(value)]="item.attachment.model"></app-property-input>
                </div>
              </div>
              <div></div>
              <div *ngIf="item.value && item.value.type == 'radio' && !item.value.line" class="question-radio-group">
                <nz-radio-group [(ngModel)]="item.model" (ngModelChange)="onRadioChange($event)">
                  <label
                    *ngFor="let value of item.value.data"
                    nz-radio
                    [nzValue]="value.answerId"
                  >
                    {{ value.label }}
                  </label>
                </nz-radio-group>
              </div>
              <div *ngIf="item.value && item.value.type == 'radio' && item.value.line" class="mt-8">
                <nz-radio-group [(ngModel)]="item.model" (ngModelChange)="onRadioChange($event)">
                  <label
                    *ngFor="let value of item.value.data"
                    class="question_radio"
                    nz-radio
                    [nzValue]="value.answerId"
                  >
                    {{ value.label }}
                  </label>
                </nz-radio-group>
              </div>
              <div *ngIf="item.value && item.value.type == 'input'">
                <app-property-input [displayMode]="'vertical'"
                                    [title]=""
                                    [disabled]="true"
                                    [placeholder]="''"
                                    [(value)]="item.model"></app-property-input>
              </div>
              <div *ngIf="item.value && item.value.type == 'totalScores'" class="mt-8">
                <app-property-input [displayMode]="'vertical'"
                                    [title]=""
                                    [disabled]="true"
                                    [placeholder]="''"
                                    [(value)]="item.model"></app-property-input>
              </div>
              <div *ngIf="item.value && item.value.type == 'select-read'">
                <div *ngFor="let score of item.value.data">
                  <div *ngIf="item.model == score.answerId">
                    <div class="question-title mt-8">
                      {{ score.data }}
                    </div>
                    <div class="mt-8">
                      {{ score.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="questionDetail.question && questionDetail.question?.type == 'conditions'">
      <div *ngIf="editDataSource && editDataSource?.question">
        <div *ngFor="let item of editDataSource.question?.question; let ind = index">
          <div *ngIf="item.type == 'title'" class="question-title">
            {{ item.label }}
          </div>
          <div *ngIf="item.type == 'question' && !item.parent" class="question_box">
            <div class="question_box_right">
              <div class="">
                <span *ngIf="item.number">{{ item.num }}.</span>{{ item.label }}
              </div>
              <div *ngIf="item.value && item.value.type == 'multi-select'" class="mt-8">
                <nz-radio-group [(ngModel)]="item.model">
                  <div *ngFor="let value of item.value.data; let index = index" class="quesiton-multi">
                    <label nz-radio [nzValue]="value.answerId">
                      {{ item.label }}
                    </label>
                    <div *ngIf="item.model == value.answerId && value.attachment && value.attachment.type == 'input'">
                      <app-property-input [displayMode]="'vertical'"
                                          [title]=""
                                          [placeholder]="''"
                                          [(value)]="value.attachment.model"></app-property-input>
                    </div>
                  </div>
                </nz-radio-group>
              </div>
              <div></div>
              <div *ngIf="item.value && item.value.type == 'radio' && !item.value.line" class="question-radio-group">
                <nz-radio-group [(ngModel)]="item.model" (ngModelChange)="onRadioChange($event)">
                  <label
                    *ngFor="let value of item.value.data"
                    nz-radio
                    [nzValue]="value.answerId"
                  >
                    {{ value.label }}
                  </label>
                </nz-radio-group>
                <div *ngFor="let answer of item.value.data">
                  <div *ngIf="item.model == answer.answerId && answer.attachment && answer.attachment.type == 'input'" class="mt-3">
                    <app-property-input [displayMode]="'vertical'"
                                        [title]=""
                                        [placeholder]="''"
                                        [(value)]="answer.attachment.model"></app-property-input>
                  </div>
                </div>
              </div>
              <div *ngIf="item.value && item.value.type == 'radio' && item.value.line" class="mt-8">
                <nz-radio-group [(ngModel)]="item.model" (ngModelChange)="onRadioChange($event)">
                  <label
                    *ngFor="let value of item.value.data"
                    nz-radio
                    [nzValue]="value.answerId"
                  >
                    {{ value.label }}
                  </label>
                </nz-radio-group>
                <div *ngFor="let answer of item.value.data">
                  <div *ngIf="item.model == answer.answerId && answer.attachment && answer.attachment.type == 'input'" class="mt-3">
                    <app-property-input [displayMode]="'vertical'"
                                        [title]=""
                                        [placeholder]="''"
                                        [(value)]="answer.attachment.model"></app-property-input>
                  </div>
                </div>
              </div>
              <div *ngIf="item.value && item.value.type == 'input'" class="mt-3">
                <app-property-input [displayMode]="'vertical'"
                                    [title]=""
                                    [placeholder]="''"
                                    [(value)]="item.model"></app-property-input>
              </div>
              <div *ngIf="item.value && item.value.type == 'totalScores'" class="mt-3">
                <app-property-input [displayMode]="'vertical'"
                                    [title]=""
                                    [placeholder]="''"
                                    [(value)]="item.model"></app-property-input>
              </div>
              <div *ngIf="item.value && item.value.type == 'select-read'">
                <div *ngFor="let score of item.value.data">
                  <div *ngIf="item.model == score.answerId">
                    <div class="question-title mt-8">
                      {{ score.data }}
                    </div>
                    <div class="mt-8">
                      {{ score.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.type == 'question' && item.parent && item.isCheck">
            <div class="question_box_right">
              <div class="">
                <span *ngIf="item.number">{{ item.num }}.</span>{{ item.label }}
              </div>
              <div *ngIf="item.value && item.value.type == 'multi-select'" class="mt-8">
                <nz-radio-group [(ngModel)]="item.model" (ngModelChange)="onRadioChange($event)">
                  <div *ngFor=" let value of item.value.data" class="quesiton-multi">
                    <label
                      *ngFor="let value of item.value.data"
                      nz-radio
                      [nzValue]="value.answerId"
                    >
                      {{ value.label }}
                    </label>
                    <div *ngIf="value.attachment && value.attachment.type == 'input'" class="mt-3">
                      <app-property-input [displayMode]="'vertical'"
                                          [title]=""
                                          [placeholder]="''"
                                          [(value)]="value.attachment.model"></app-property-input>
                    </div>
                  </div>
                </nz-radio-group>
              </div>
              <div></div>
              <div *ngIf="item.value && item.value.type == 'radio' && !item.value.line" class="question-radio-group">
                <nz-radio-group [(ngModel)]="item.model" (ngModelChange)="onRadioChange($event)">
                  <label
                    *ngFor="let value of item.value.data"
                    nz-radio
                    [nzValue]="value.answerId"
                  >
                    {{ value.label }}
                  </label>
                </nz-radio-group>
              </div>
              <div *ngIf="item.value && item.value.type == 'radio' && item.value.line" class="mt-8">
                <nz-radio-group [(ngModel)]="item.model" (ngModelChange)="onRadioChange($event)">
                  <label
                    *ngFor="let value of item.value.data"
                    nz-radio
                    [nzValue]="value.answerId"
                  >
                    {{ value.label }}
                  </label>
                </nz-radio-group>
              </div>
              <div *ngIf="item.value && item.value.type == 'input'" class="mt-3">
                <app-property-input [displayMode]="'vertical'"
                                    [title]=""
                                    [placeholder]="''"
                                    [(value)]="item.model"></app-property-input>
              </div>
              <div *ngIf="item.value && item.value.type == 'totalScores'" class="mt-3">
                <app-property-input [displayMode]="'vertical'"
                                    [title]=""
                                    [placeholder]="''"
                                    [(value)]="item.model"></app-property-input>
              </div>
              <div *ngIf="item.value && item.value.type == 'select-read'">
                <div *ngFor="let score of item.value.data">
                  <div *ngIf="item.model == score.answerId">
                    <div class="question-title mt-8">
                      {{ score.data }}
                    </div>
                    <div class="mt-8">
                      {{ score.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="questionDetail.question && questionDetail.question?.type == 'standard'">
      <div *ngIf="editDataSource && editDataSource?.question">
        <div *ngFor="let item of editDataSource.question.question">
          <div *ngIf="item?.type == 'title'">
            <div class="question-title mt-8">
              {{ item.label }}
            </div>
            <div *ngIf="item.child && item.child.length > 0">
              <div *ngFor="let child of item.child" class="question_box">
                <div class="question_box_right">
                  <div class="question-title">
                    <span *ngIf="child.number">{{ child.num }}.</span> {{ child.label }}
                  </div>
                  <div
                    *ngIf="child.value && child.value.type == 'radio' && !child.value.line"
                    class="question-radio-group mt-8"
                  >
                    <nz-radio-group [(ngModel)]="child.model" (ngModelChange)="onRadioChange($event)" class="mt-3">
                      <label
                        *ngFor="let answer of child.value.data"
                        nz-radio
                        [nzValue]="answer.answerId"
                      >
                        {{ answer.label }}
                      </label>
                    </nz-radio-group>
                    <div *ngFor="let answer of child.value.data">
                      <div *ngIf="child.model == answer.answerId && answer.attachment">
                        <div *ngIf="answer.attachment.repeat && answer.attachment.repeat == 2; else elseTemplate">
                          <div *ngIf="answer.attachment.label">{{ answer.attachment.label }}</div>
                          <app-property-input [displayMode]="'vertical'"
                                              [title]=""
                                              [placeholder]="''"
                                              [(value)]="answer.attachment.model[0]"></app-property-input>
                          <div *ngIf="answer.attachment.label">{{ answer.attachment.label }}</div>
                          <app-property-input [displayMode]="'vertical'"
                                              [title]=""
                                              [placeholder]="''"
                                              [(value)]="answer.attachment.model[1]"></app-property-input>
                        </div>
                        <ng-template #elseTemplate>
                          <div>
                            <div *ngIf="answer.attachment.label">{{ answer.attachment.label }}</div>
                            <app-property-input [displayMode]="'vertical'"
                                                [title]=""
                                                [placeholder]="''"
                                                [(value)]="answer.attachment.model"></app-property-input>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="child.value && child.value.type == 'radio' && child.value.line" class="mt-8">
                    <nz-radio-group [(ngModel)]="child.model" (ngModelChange)="onRadioChange($event)" class="mt-3">
                      <label
                        *ngFor="let answer of child.value.data"
                        nz-radio
                        [nzValue]="answer.answerId"
                      >
                        {{ answer.label }}
                      </label>
                    </nz-radio-group>
                    <div *ngFor="let answer of child.value.data">
                      <div *ngIf="child.model == answer.answerId && answer.attachment">
                        <div *ngIf="answer.attachment.repeat && answer.attachment.repeat == 2; else elseTemplate">
                          <div *ngIf="answer.attachment.label">{{ answer.attachment.label }}</div>
                          <app-property-input [displayMode]="'vertical'"
                                              [title]=""
                                              [placeholder]="''"
                                              [(value)]="answer.attachment.model[0]"></app-property-input>
                          <div *ngIf="answer.attachment.label">{{ answer.attachment.label }}</div>
                          <app-property-input [displayMode]="'vertical'"
                                              [title]=""
                                              [placeholder]="''"
                                              [(value)]="answer.attachment.model[1]"></app-property-input>
                        </div>
                        <ng-template #elseTemplate>
                          <div>
                            <div *ngIf="answer.attachment.label">{{ answer.attachment.label }}</div>
                            <app-property-input [displayMode]="'vertical'"
                                                [title]=""
                                                [placeholder]="''"
                                                [(value)]="answer.attachment.model"></app-property-input>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="child.value && child.value.type == 'multi-select' && child.value.line" class="mt-8">
                    <nz-checkbox-group [(ngModel)]="child.value.data"></nz-checkbox-group>
<!--                    <label *ngFor="let value of child.value.data" nz-checkbox [(ngModel)]="child.model" (ngModelChange)="onRadioChange(child)">{{ value?.label }}</label>-->
<!--                    <div *ngIf="item.value && item.value.type == 'multi-select'" class="mt-8">-->
<!--                      <nz-checkbox-group [(ngModel)]="item.value.data"></nz-checkbox-group>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.type == 'describe'" class="mt-8">
            <div class="question-title mt-8">
              {{ item.label }}
            </div>
            <div *ngFor="let questionItem of item.child">
              <div *ngIf="questionItem.type == 'question'" class="question_box">
                <div class="question_box_right">
                  <div class="question-title">
                    <span *ngIf="questionItem.number">{{ questionItem.num }}.</span>{{ questionItem.label }}
                  </div>
                  <div
                    *ngIf="questionItem.value && questionItem.value.type == 'radio' && !questionItem.value.line"
                    class="question-radio-group"
                  >
                    <nz-radio-group [(ngModel)]="questionItem.model" (ngModelChange)="onRadioChange($event)">
                      <label
                        *ngFor="let answer of questionItem.value.data"
                        nz-radio
                        [nzValue]="answer.answerId"
                      >
                        {{ answer.label }}
                      </label>
                    </nz-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.type == 'question'" class="question-box mt-8">
            <!-- Checklist-->
            <div class="question-title mt-8">
              {{ item.label }}
            </div>
            <div *ngIf="item.value && item.value.type == 'multi-select'" class="mt-8">
              <nz-checkbox-group [(ngModel)]="item.value.data"></nz-checkbox-group>
            </div>
          </div>
      </div>
      </div>
    </div>

    <div *ngIf="questionDetail.question && questionDetail.question?.type == 'placeholder'">
      <div *ngIf="editDataSource && editDataSource?.question">
        <div *ngFor="let item of editDataSource.question.question">
          <div *ngIf="item?.type == 'title'" class="mt-8">
            <div class="question-title">{{ item.label }}</div>
            <div *ngFor="let child of item.child">
              <div *ngIf="child.html" class="question_html">
                  <div *ngFor="let html of child.html" class="block-input">
                    <span *ngIf="isHtml(child, html); else elseTemplate">
                      <span *ngFor="let field of child.field">
                        <input *ngIf="field.answerId == html && field.data !== 'number'" nz-input [(ngModel)]="field.model" />
                        <nz-input-number
                          *ngIf="field.answerId == html && field.data === 'number'"
                          [(ngModel)]="field.model" [nzMin]="0" [nzStep]="1">
                        </nz-input-number>
<!--                        <ng-template #elseTemplate>-->
<!--                          <nz-input-number [(ngModel)]="field.model" [nzMin]="0" [nzStep]="1"></nz-input-number>-->
<!--                        </ng-template>-->
                      </span>
                    </span>
                    <ng-template #elseTemplate>
                      <span>
                        {{ html }}
                      </span>
                    </ng-template>
                  </div>
              </div>
            </div>
          </div>
          <div *ngIf="item?.type == 'question'" class="mt-8">
            <div class="question-title">{{ item.label }}</div>
            <div
              *ngIf="item.value && item.value.type == 'radio' && item.value.line"
              class="question-radio-group mt-8"
            >
              <nz-radio-group [(ngModel)]="item.model" (ngModelChange)="onRadioChange($event)" class="mt-3">
                <label
                  *ngFor="let answer of item.value.data"
                  nz-radio
                  [nzValue]="answer.answerId"
                >
                  {{ answer.label }}
                </label>
              </nz-radio-group>
              <div *ngFor="let answer of item.value.data">
                <div *ngIf="item.model == answer.answerId && answer.attachment">
                  <div *ngIf="answer?.attachment?.repeat && answer?.attachment?.repeat == 2; else repeatTemplate">
                    <div *ngIf="answer.attachment.label">{{ answer.attachment.label }}</div>
                    <app-property-input [displayMode]="'vertical'"
                                        [title]=""
                                        [placeholder]="''"
                                        [(value)]="answer.attachment.model[0]"></app-property-input>
                    <div *ngIf="answer.attachment.label">{{ answer.attachment.label }}</div>
                    <app-property-input [displayMode]="'vertical'"
                                        [title]=""
                                        [placeholder]="''"
                                        [(value)]="answer.attachment.model[1]"></app-property-input>
                  </div>
                  <ng-template #repeatTemplate>
                    <div>
                      <div *ngIf="answer.attachment.label">{{ answer.attachment.label }}</div>
                      <app-property-input [displayMode]="'vertical'"
                                          [title]=""
                                          [placeholder]="''"
                                          [(value)]="answer.attachment.model"></app-property-input>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="isRead" class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="onSave()">
          <!--          <em nz-icon nzType="check" nzTheme="outline"></em>-->
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
