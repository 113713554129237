<div class="file-upload">
  <div *ngIf="!readonly" class="file-upload-box">
    <input #fileUpload hidden="hidden" type="file" ng2FileSelect [uploader]="uploader"
           [accept]="fileFormat"
           (change)="selectedImportFileOnChanged($event)" multiple/>

    <a class="file-upload-button" nz-button nzType="link" appThrottleClick (throttleClick)="onAddAttachment()"> + </a>
    <span>{{ 'SelectFile' | translate }}</span>
  </div>
</div>
<div *ngIf="!attachments || attachments.length == 0">
  <nz-empty *ngIf="showEmpty" nzNotFoundImage="simple"></nz-empty>
</div>
<div>
  <nz-tag *ngFor="let item of tagArr; let k = index"
          nzMode="checkable" [nzChecked]="tagFlag[k]"
          [class]="tagFlag[k] ? '' : 'tag-border'"
          (nzCheckedChange)="checkTagChange($event, k)">
    {{item}}
  </nz-tag>
</div>
<div *ngIf="!!attachments && attachments.length > 0" style="margin-top: 10px">
  <div *ngIf="!isGroup">
    <div *ngFor="let attachment of attachments; let i = index" class="attachment">
      <div>
        <img alt="" width="30" [src]="iconOfFileName(attachment.filePath)">
      </div>
      <div class="attachment-file">
        <div style="color: #333333; font-size: 15px;max-width: 380px;word-break: break-word;">{{attachment.fileName}}</div>
        <div style="color: #999999; font-size: 12px">{{attachment.uploadTime | date:'yyyy-MM-dd HH:mm:ss'}}</div>
        <div>
          <nz-tag *ngFor="let tag of attachment.tags; let j = index">
            {{tag}}
          </nz-tag>
        </div>
      </div>
      <div *ngIf="attachment.fileType">
        <nz-tag>
          {{fileTypeArrOption(attachment.fileType)}}
        </nz-tag>
      </div>
      <div *ngIf="previewFilter(attachment.filePath)">
        <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Preview' | translate" appThrottleClick
           (throttleClick)="onPreview(attachment)">
          <img alt="" width="22" src="assets/images/ic_view.svg">
        </a>
      </div>
      <div>
        <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Download' | translate" appThrottleClick
           (throttleClick)="onDownload(attachment)">
          <img alt="" width="22" src="assets/images/ic_download.svg">
        </a>
      </div>
      <div *ngIf="!readonly && isPreview">
        <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Rename' | translate" appThrottleClick
           (throttleClick)="onEditor('', i, tplEditorTitle, tplEditorContent, tplEditorFooter)">
          <img alt="" width="22" src="assets/images/ic_edit.svg">
        </a>
      </div>
      <div *ngIf="!readonly && isPreview">
        <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate" appThrottleClick
           (throttleClick)="onDelete('', i, tplDeleteTitle, tplDeleteContent, tplDeleteFooter)">
          <img alt="" width="22" src="assets/images/ic_delete.svg">
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="isGroup">
    <nz-spin [nzSpinning]="spinning">
      <nz-collapse [nzBordered]="false">
        <nz-collapse-panel
          *ngFor="let fileType of setFileTypeCopy"
          [nzHeader]="(fileTypeArrOption(fileType.type) + ' (' + fileType.files.length + ')')"
          [nzActive]="fileType.active &&  fileType.files.length !== 0"
          [nzDisabled]="fileType.count === 0"
          [ngStyle]="{'background': '#ffffff'}"
        >
          <div *ngFor="let attachment of fileType.files; let i = index" class="attachment">
            <div>
              <img alt="" width="30" [src]="iconOfFileName(attachment.filePath)">
            </div>
            <div class="attachment-file">
              <div style="color: #333333; font-size: 15px;max-width: 380px;word-break: break-word;">{{attachment.fileName}}</div>
              <div style="color: #999999; font-size: 12px">{{attachment.uploadTime | date:'yyyy-MM-dd HH:mm:ss'}}</div>
              <div>
                <nz-tag *ngFor="let tag of attachment.tags; let j = index">
                  {{tag}}
                </nz-tag>
              </div>
            </div>
            <div *ngIf="attachment.fileType">
              <nz-tag>
                {{fileTypeArrOption(attachment.fileType)}}
              </nz-tag>
            </div>
            <div *ngIf="previewFilter(attachment.filePath)">
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Preview' | translate" appThrottleClick
                 (throttleClick)="onPreview(attachment)">
                <img alt="" width="22" src="assets/images/ic_view.svg">
              </a>
            </div>
            <div>
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Download' | translate" appThrottleClick
                 (throttleClick)="onDownload(attachment)">
                <img alt="" width="22" src="assets/images/ic_download.svg">
              </a>
            </div>
            <div *ngIf="!readonly && (isPreview || fileType.type == 'SALES')">
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Rename' | translate" appThrottleClick
                 (throttleClick)="onEditor(fileType.type, i, tplEditorTitle, tplEditorContent, tplEditorFooter)">
                <img alt="" width="22" src="assets/images/ic_edit.svg">
              </a>
            </div>
            <div *ngIf="!readonly && (isPreview || fileType.type == 'SALES')">
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate" appThrottleClick
                 (throttleClick)="onDelete(fileType.type, i, tplDeleteTitle, tplDeleteContent, tplDeleteFooter)">
                <img alt="" width="22" src="assets/images/ic_delete.svg">
              </a>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </nz-spin>
  </div>
</div>
<div *ngIf="!!uploader.queue && uploader.queue.length > 0" style="margin-top: 10px">
  <div *ngFor="let item of uploader.queue; let i = index">
    <div class="attachment">
      <div>
        <img alt="" width="30" [src]="iconOfFileName(item.file.name)">
      </div>
      <div class="attachment-file">
        <div style="color: #333333; font-size: 15px">{{item.file.name}} <i nz-icon nzType="loading" style="font-size: 9px"></i></div>
      </div>
      <nz-progress *ngIf="item.progress > 0" [nzPercent]="item.progress" nzType="circle" [nzWidth]="32"
                   [nzStrokeColor]="{ '0%': '#108ee9', '100%': '#87d068' }">
      </nz-progress>
    </div>
  </div>
</div>

<!--<nz-alert *ngIf="fileAlert.showAlert" nzType="error"-->
<!--          [nzMessage]="'ExceedsTheLimitsOfFileSize' | translate:{ maxLength: fileAlert.maxFileSizeStr, length: fileAlert.fileSizeStr }"-->
<!--          nzShowIcon [nzCloseText]="'Close' | translate"></nz-alert>-->

<ng-template #tplDeleteTitle>
  <span>{{'DeleteAttachment' | translate}}</span>
</ng-template>
<ng-template #tplDeleteContent let-params>
  <p>{{'DoYouWantToDeleteAttachment' | translate}} :「{{ params.attachment.fileName }}」?</p>
</ng-template>
<ng-template #tplDeleteFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()"
          [disabled]="deleting">{{'Cancel' | translate}}</button>
  <button nz-button nzDanger nzType="primary" appThrottleClick (throttleClick)="onDeleteConfirm(ref, params)"
          [nzLoading]="deleting">
    {{'Delete' | translate}}
  </button>
</ng-template>

<ng-template #tplEditorTitle>
  <i nz-icon nzType="edit" nzTheme="outline"
     style="color: #07367c; font-size: 17px; margin-right: 5px"></i><span>{{'Rename' | translate}}</span>
</ng-template>
<ng-template #tplEditorContent let-params>
  <input type="text" class="editor-input" style="width: 100%;" [(ngModel)]="params.attachment.fileName" placeholder=""/>
</ng-template>
<ng-template #tplEditorFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()">
    <!--    <i nz-icon nzType="close" nzTheme="outline" style="font-size: 13px;"></i>-->
    <span>{{'Close' | translate}}</span>
  </button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onEditorConfirm(ref, params)">
    <!--    <i nz-icon nzType="save" nzTheme="outline" style="font-size: 13px;"></i>-->
    <span>{{'Confirm' | translate}}</span>
  </button>
</ng-template>
