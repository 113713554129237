<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'ComparisonDetails' | translate}}
    </div>
  </nz-spin>
  <div class="title" style="color: #FFFFFF">
    <div [ngSwitch]="companyDetail.status">
      <div *ngSwitchCase="'COMPLETED'">
        <img  alt="" width="16" src="assets/images/ic_finish.svg">
        <span>{{ 'ProductCompareCompleted' | translate }}</span>
      </div>
      <div *ngSwitchCase="'FAIL'">
        <img alt="" width="16" src="assets/images/ic_error.svg">
        <span> {{ 'ProductCompareFail' | translate }}</span>

      </div>
      <div *ngSwitchCase="'PENDING'">
        <img alt="" width="16" src="assets/images/ic_pending.svg">
        <span>{{ 'ProductComparePending' | translate }}</span>
      </div>
    </div>
    <div [ngSwitch]="companyDetail.status" style="cursor: pointer;">
      <span *ngSwitchCase="'COMPLETED'" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
        <span>{{'ViewShare' | translate}}</span> <i nz-icon style="vertical-align: middle;font-size: 19px" nzType="right" nzTheme="outline"></i>
      </span>
      <nz-dropdown-menu #menu="nzDropdownMenu" >
        <ul nz-menu nzSelectable>
          <li nz-menu-item appThrottleClick (throttleClick)="onDownload('CN')">{{ 'CN' | translate}}</li>
          <li nz-menu-item appThrottleClick (throttleClick)="onDownload('HK')">{{ 'HK' | translate}}</li>
          <li nz-menu-item appThrottleClick (throttleClick)="onDownload('EN')">{{ 'EN' | translate}}</li>
        </ul>
      </nz-dropdown-menu>
      <span *ngSwitchCase="'FAIL'" appThrottleClick (throttleClick)="onRetry()" )>
        {{'Retry' | translate}}
      </span>
    </div>
  </div>
  <div *ngFor="let group of companyDetail.groups;let i = index">
    <div class="section-title" style="margin-bottom: 16px;margin-top: 22px;padding-left: 5px">
      <div *ngIf="isEdit">
        <label nz-checkbox [(ngModel)]="group.checked" [nzId]="group.key"> {{ group.name}}</label>
      </div>
      <div *ngIf="!isEdit">
        {{ group.name}}
      </div>
    </div>
    <table class="table sa-table">
      <thead>
      <tr>
        <th style="width: 150px">{{ 'Insurer' | translate }}</th>
        <th *ngFor="let companyName of group.companyNames">{{ companyName }}</th>
      </tr>
      <tr>
        <th style="width: 150px">{{ 'ProductName' | translate }}</th>
        <th *ngFor="let productName of group.productNames">{{ productName }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let attribute of group.attributes;let j = index">
        <td *ngIf="!isEdit" [class]="uiTheme.classes.propertyKey">{{ attribute.name }}</td>
        <td *ngIf="isEdit" [class]="uiTheme.classes.propertyKey">
          <label nz-checkbox [(ngModel)]="attribute.checked"
                 [nzId]="attribute.attribute"> {{ attribute.name }}</label>
        </td>
        <td *ngFor="let value of attribute.values;let index = index" [class]="uiTheme.classes.propertyValue" nz-button
            appThrottleClick
            (throttleClick)="onScoreDetail(index,group,value); $event.stopPropagation();">
          <div class="value" style="white-space: pre-line;">{{ value.value }}</div>
          <div *ngIf="value.score">
            <nz-rate style="font-size: 15px" [nzAllowHalf]="false" [ngModel]="Number(value.score)" nzDisabled></nz-rate>
          </div>

        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;" *ngIf="!isEdit">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onComparison()">
          <span>{{'PremiumComparison' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="Edit()">
          <span>{{'Edit' | translate}}</span>
        </a>
      </div>

    </div>
    <div nz-row style="width: 100%; height: 100%;" *ngIf="isEdit">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onCancel()">
          <span>{{'Cancel' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
  <nz-modal [(nzVisible)]="scoreVisible"
            [nzTitle]="modalTitle"
            [nzContent]="modalContent"
            [nzFooter]="modalFooter"
            (nzOnCancel)="handleCancel()">
    <ng-template #modalTitle>
      {{attributeTitle.name}}
    </ng-template>

    <ng-template #modalContent>
      <div style="font-size: 14px;color:#CCCCCC">  {{'CompareScore' | translate}}</div>
      <div style="font-size: 14px">
        {{ attributeScore.name}}
      </div>
      <div>
        <nz-rate [nzAllowHalf]="false" [ngModel]="Number(attributeScore.score)"
                 (ngModelChange)="onScore($event)"></nz-rate>
      </div>
    </ng-template>

    <ng-template #modalFooter>
      <button nz-button nzType="default" (click)="handleCancel()">{{'Cancel' | translate}}</button>
      <button nz-button nzType="primary" (click)="handleOk()"
              [nzLoading]="isConfirmLoading">{{'Confirm' | translate}} </button>
    </ng-template>
  </nz-modal>

  <nz-drawer
    [nzClosable]="false"
    [nzVisible]="drawerVisible"
    [nzWidth]="600"
    nzPlacement="right"
    [nzTitle]="'PremiumComparison' | translate"
    (nzOnClose)="drawerClose()"
  >
    <ng-container *nzDrawerContent>
      <div class="drawer-container">
        <div>
          <div class="proposal-apply-section-title">
            <span style="margin-left: 8px">{{'ProductPlan' | translate}}</span>
            <span style="margin-left: 4px;cursor: pointer;"
                  appThrottleClick (throttleClick)="onRedoCompareData('product')">
              <i nz-icon nzType="redo" nzTheme="outline"></i>
            </span>
          </div>

          <div>
            <!-- 货币 -->
            <app-property-select [displayMode]="'vertical'"
                                 [title]="'Currency' | translate"
                                 [placeholder]="('PleaseSelect' | translate) + ('Currency' | translate)"
                                 [required]="requiredObj && requiredObj['Currency']"
                                 [options]="currencyOptions"
                                 [(value)]="compareData.currency" (valueChange)="onCurrencyChange($event)"></app-property-select>

            <!-- 供款年期 -->
            <app-property-select [displayMode]="'vertical'"
                                 [title]="'PaymentTerm' | translate"
                                 [placeholder]="('PleaseSelect' | translate) + ('PaymentTerm' | translate)"
                                 [required]="requiredObj && requiredObj['PremiumTerm']"
                                 [options]="paymentTermOptions"
                                 [(value)]="compareData.premiumTerm"></app-property-select>


            <app-property-input-number [displayMode]="'vertical'"
                                       [title]="'SumAssured' | translate"
                                       [required]="true"
                                       [placeholder]="'SumAssured' | translate"
                                       [(value)]="compareData.amount"></app-property-input-number>
          </div>
          <div *ngIf="companyDetail.categoryCode === 'MEDICAL'">
            <!-- 保障地區 -->
            <app-property-select [title]="'GuaranteedArea' | translate"
                                 [displayMode]="'vertical'"
                                 [required]="requiredObj && requiredObj['MedicalRegion']"
                                 [placeholder]="('PleaseSelect' | translate) + ('GuaranteedArea' | translate)"
                                 [options]="guaranteedAreaOptions"
                                 [(value)]="compareData.region"></app-property-select>

            <!-- 医疗险等级 -->
            <app-property-select [title]="'MedicalLevel' | translate"
                                 [displayMode]="'vertical'"
                                 [placeholder]="('PleaseSelect' | translate) + ('MedicalLevel' | translate)"
                                 [options]="medicalLevelOptions"
                                 [required]="requiredObj && requiredObj['MedicalLevel']"
                                 [(value)]="compareData.level"></app-property-select>

            <!-- 自付額 -->
            <app-property-select [title]="'Deductible' | translate"
                                 [displayMode]="'vertical'"
                                 [required]="requiredObj && requiredObj['MedicalDeductible']"
                                 [placeholder]="('PleaseSelect' | translate) + ('Deductible' | translate)"
                                 [options]="deductibleOptions"
                                 [(value)]="compareData.deductible"></app-property-select>

            <!-- 附加保障 -->
            <app-property-select [title]="'SupplementaryBenefits' | translate"
                                 [displayMode]="'vertical'"
                                 [mode]="'multiple'"
                                 [required]="false"
                                 [placeholder]="('PleaseSelect' | translate) + ('SupplementaryBenefits' | translate)"
                                 [options]="supplementaryBenefitOptions"
                                 [(value)]="compareData.supplementaryBenefits"></app-property-select>
          </div>

          <div class="proposal-apply-section-title" style="margin-top: 20px">
            <span style="margin-left: 8px">{{'Insured' | translate}}</span>
            <span style="margin-left: 4px;cursor: pointer;"
                  appThrottleClick (throttleClick)="onRedoCompareData('insured')">
              <i nz-icon nzType="redo" nzTheme="outline"></i>
            </span>
          </div>
          <div style="margin-bottom: 20px">
            <!-- 年龄 -->
            <app-property-input-number [displayMode]="'vertical'"
                                       [title]="'Age' | translate"
                                       [tip]="'PleaseSelectProductFirst' | translate"
                                       [placeholder]="'Age' | translate"
                                       [required]="requiredObj && requiredObj['Age']"
                                       [value]="compareData.age" (valueChange)="onAgeChange($event)"></app-property-input-number>
            <!-- 性别 -->
            <app-property-select [displayMode]="'vertical'"
                                 [title]="'Gender' | translate"
                                 [placeholder]="('PleaseSelect' | translate) + ('Gender' | translate)"
                                 [options]="genderOptions"
                                 [required]="requiredObj && requiredObj['Gender']"
                                 [(value)]="compareData.gender"></app-property-select>

            <!-- 是否吸烟 -->
            <app-property-select [displayMode]="'vertical'"
                                 [title]="'SmokeCondition' | translate"
                                 [placeholder]="('PleaseSelect' | translate) + ('Smoking' | translate)"
                                 [options]="smokeOptions"
                                 [required]="requiredObj && requiredObj['SmokeCondition']"
                                 [(value)]="compareData.smokeCondition"></app-property-select>
          </div>
        </div>
        <div class="footer">
          <div nz-row style="width: 100%; height: 100%;">
            <div nz-col nzSpan="24" class="footer-button-primary">
              <a nz-button nzType="link" nzBlock nzSize="large"
                 appThrottleClick (throttleClick)="goCompare()">
                <span>{{'GoCompare' | translate}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </nz-drawer>

</div>
