import { Component, OnInit } from '@angular/core';
import { Information } from '../information-types';
import { CompanyDetailComponent } from '../../product/company-detail/company-detail.component';
import { ProductService } from '../../product/product.service';
import { Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ProductDetailComponent } from '../../product/product-detail/product-detail.component';
import { Product } from '../../product/product-types';

@Component({
  selector: 'app-information-detail',
  templateUrl: './information-detail.component.html',
  styleUrls: ['./information-detail.component.less']
})
export class InformationDetailComponent implements OnInit {

  information: Information;
  isEllipsis = false;
  spinning = false;
  tableIndex = 0;
  constructor(private productService: ProductService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
    if (!this.information.content) {
      this.tableIndex = 1;
    }
  }

  onCompanyDetail(code: string): void {
    this.spinning = true;
    this.productService.companyDetail(code)
      .subscribe(
        company => {
          this.spinning = false;
          const drawerRef = this.drawerService.create<CompanyDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: CompanyDetailComponent,
            nzContentParams: {
              company
            }
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
          });
          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.spinning = false;
        });
  }

  onProductDetail(productCode: string): void {
    this.spinning = true;
    this.productService.detail(productCode)
      .subscribe(
        product => {

          this.spinning = false;

          const drawerRef = this.drawerService.create<ProductDetailComponent, { value: Product }, string>({
            nzWidth: 800,
            nzContent: ProductDetailComponent,
            nzContentParams: {
              product
            }
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
          });
          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.spinning = false;
        });
  }

}
