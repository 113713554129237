<div [ngSwitch]="displayMode">
  <div *ngSwitchCase="'horizontal'">
    <div [class]="disabled ? 'property-row property-disable' : 'property-row'">
      <span class="property-title-row" [style]="titleColor ? ('color:' + titleColor) : ''" *ngIf="title">{{ title }}</span>
      <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
      <span class="property-value-row">
        <input [nzBorderless]="borderless" nz-input [placeholder]="placeholder" [(ngModel)]="value"
               (change)="inputChange(value)"
               (keyup)="inputChange(value)"
               [disabled]="disabled"

               style="text-align: right;" />
      </span>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible"
         style="font-size: 10px;" [style.color]="tipColor">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>

  <div *ngSwitchCase="'vertical'">
    <div [class]="disabled ? 'property-column property-disable' : 'property-column'">
      <div class="property-title-column">
        <span *ngIf="title" [style]="titleColor ? ('color:' + titleColor) : ''">{{ title }}</span>
        <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
      </div>
      <div class="property-value-column">
          <input [nzBorderless]="borderless" nz-input [placeholder]="placeholder" [(ngModel)]="value"
                 [disabled]="disabled"
                 (change)="inputChange(value)"
                 (keyup)="inputChange(value)"
                 style="text-align: left;" />
      </div>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible"
         style="font-size: 10px;" [style.color]="tipColor">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>

</div>
