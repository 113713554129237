<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 40px">
    {{'Rider' | translate}}
  </div>

  <!-- 产品 -->
  <app-property-pick [title]="'Product' | translate"
                     [required]="true"
                     appThrottleClick (throttleClick)="onProductSelection()"
                     [value]="product?.productName"></app-property-pick>

  <div [ngSwitch]="mode">
    <div *ngSwitchCase="'DEFAULT'">
      <!-- 保費 -->
      <app-property-input-number [title]="'Premium' | translate"
                                 [required]="true"
                                 [placeholder]="'Premium' | translate"
                                 [(value)]="rider.premium"></app-property-input-number>

      <!-- 保額 -->
      <app-property-input-number [title]="'SumAssured' | translate"
                                 [required]="true"
                                 [placeholder]="'SumAssured' | translate"
                                 [(value)]="rider.sumAssured"></app-property-input-number>

      <div *ngIf="product.categoryCode === 'MEDICAL'">
        <!-- 保障地區 -->
        <app-property-select [title]="'GuaranteedArea' | translate"
                             [placeholder]="('PleaseSelect' | translate) + ('GuaranteedArea' | translate)"
                             [options]="guaranteedAreaOptions"
                             [(value)]="rider.medicalPlan.region"></app-property-select>

        <!-- 医疗险等级 -->
        <app-property-select [title]="'MedicalLevel' | translate"
                             [placeholder]="('PleaseSelect' | translate) + ('MedicalLevel' | translate)"
                             [options]="medicalLevelOptions"
                             [(value)]="rider.medicalPlan.medicalLevel"></app-property-select>

        <!-- 自付額 -->
        <app-property-select [title]="'Deductible' | translate"
                             [placeholder]="('PleaseSelect' | translate) + ('Deductible' | translate)"
                             [options]="deductibleOptions"
                             [(value)]="rider.medicalPlan.deductible"></app-property-select>

        <!-- 附加保障 -->
        <app-property-select [title]="'SupplementaryBenefits' | translate"
                             [mode]="'multiple'"
                             [placeholder]="('PleaseSelect' | translate) + ('SupplementaryBenefits' | translate)"
                             [options]="supplementaryBenefitOptions"
                             [(value)]="rider.medicalPlan.supplementaryBenefits"></app-property-select>
      </div>
      <div *ngSwitchCase="'FNA'">

      </div>
    </div>
  </div>


  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [disabled]="!canSave"
           [style]="!canSave ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="check" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
