import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CalculateRes, ListVO} from '../../commission/commission-types';
import {DatePipe} from '@angular/common';
import { Expose } from 'class-transformer';

@Component({
  selector: 'app-contract-select',
  templateUrl: './contract-select.component.html',
  styleUrls: ['./contract-select.component.less'],
  providers: [DatePipe]
})
export class ContractSelectComponent implements OnInit {
  info: CalculateRes[] = [];
  flags =  [];
  infoCopy = [];
  selectIndex = 0;
  @Output()
  contractSelected: EventEmitter<{info: ListVO, index: any}> = new EventEmitter<{info: ListVO, index: any}>();
  constructor(private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.info.forEach((item) => {
      const arr = [];
      if (item.contractType) {
        const obj = {
          contractName: item.contractName,
          contractType: item.contractType,
          baseOn: item.baseOn,
          effectiveDate: item.effectiveDate,
          invalidDate: item.invalidDate,
          createTime: item.createTime,
        };
        arr.push(obj);
      }
      if (item.promotionContractType) {
        const obj1 = {
          contractName: item.promotionContractName,
          contractType: item.promotionContractType,
          baseOn: item.baseOn,
          effectiveDate: item.promotionEffectiveDate,
          invalidDate: item.promotionInvalidDate,
          createTime: item.promotionCreateTime,
        };
        arr.push(obj1);
      }
      this.infoCopy.push(arr);
      // this.flags.push(false);
    });
    console.log(this.infoCopy);
  }

  timeZone(date): string {
    if (date) {
      const timezone = this.datePipe.transform(new Date(), 'z');  // 当前时区
      return this.datePipe.transform(date, 'yyyy-MM-dd', timezone);
    } else {
      return 'N/A';
    }
  }

  onContractSelected(item: ListVO, i): void {
    this.contractSelected.emit({info: item, index: i});
  }

  switchType(info: CalculateRes, index): void {
    if (!info.promotionContractType) {
      return ;
    }
    console.log(this.flags);
    this.flags[index] = !this.flags[index];
  }

}
