import {Component, Input, OnInit} from '@angular/core';
import {DataMode, PagedResp} from '../../api/types';
import {ConnectionsListRes, ContactTable} from '../contact-types';
import {ContactService} from '../contact.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {MetadataService} from '../../shared/service/metadata.service';
import {ShareConnectionsComponent} from '../../share/share-connections/share-connections.component';
import {ShareService} from '../../share/share.service';

@Component({
  selector: 'app-contacts-connections',
  templateUrl: './contacts-connections.component.html',
  styleUrls: ['./contacts-connections.component.less']
})
export class ContactsConnectionsComponent implements OnInit {

  spinning = false;
  loading = false;
  filterPopoverVisible = false;
  contactsTable: PagedResp<ConnectionsListRes>;
  search: ContactTable = new ContactTable();

  levelOptions: PropertySelectOption[] = [];
  constructor(private contactService: ContactService,
              private shareService: ShareService,
              private metadataService: MetadataService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
    this.loading = true;
    this.contactService.connections(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.contactsTable = data;
        },
        error => {
          this.loading = false;
        }
      );

    const levels = this.metadataService.values('connectionsType');
    for (const level of levels) {
      this.levelOptions.push(new PropertySelectOption(level.value, level.key));
    }
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.contactService.connections(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.contactsTable = data;
        },
        error => {
          this.loading = false;
        }
      );
  }

  onReload(): void {
    this.onSearch(this.search.pageNum);
  }

  onResetSearch(): void {
    this.search = new ContactTable();
    this.onReload();
  }

  onExportExcel(): void {
    this.loading = true;
    this.contactService.downloadExcel()
      .subscribe(
        data => {
          // console.log(data);
          const url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
          // console.log(url);
          window.open(url);
          this.loading = false;
        }, error => {
          this.loading = false;
        });
  }

  onConnectionsDetail(id: number): void {
    this.shareService.connectionsDetail(id).subscribe(
      connectionsInfo => {
        const drawerRef = this.drawerService.create<ShareConnectionsComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: ShareConnectionsComponent,
          nzContentParams: {
            connectionsInfo
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
        });

        drawerRef.afterClose.subscribe(data => {
          const component = drawerRef.getContentComponent();
        });
      }
    );
  }
}
