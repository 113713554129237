import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ContingentOwner, Customer, CustomerCorporate, DomainMetadataVOS} from '../../../api/types';
import {PropertySelectOption} from '../property-select/property-select.component';
import {CustomerEntity} from '../customer-edit/customer-edit.component';
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-domain-metadata-edit',
  templateUrl: './domain-metadata-edit.component.html',
  styleUrls: ['./domain-metadata-edit.component.less'],
  providers: [DatePipe]
})
export class DomainMetadataEditComponent implements OnInit {
  drawerRef: NzDrawerRef<DomainMetadataEditComponent, string>;
  domainMetadata: DomainMetadataVOS[] = [];
  dataCopy: any[] | DomainMetadataVOS[] = [];
  @Output()
  domainSaved: EventEmitter<any> = new EventEmitter<any>();

  constructor(private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.dataCopy = JSON.parse(JSON.stringify(this.domainMetadata));
    this.mapData();
  }

  onOptions(arr): PropertySelectOption[] {
    const optionsArr: PropertySelectOption[] = [];
    for (const item of arr) {
      optionsArr.push(new PropertySelectOption(item.name, item.id));
    }
    return optionsArr;
  }

  onMultiCheckBoxes(val: any): any {
    if (!val) {
      return null;
    }
    const paramArr = JSON.parse(val);
    if (paramArr.length) {
      // for (const item of paramArr) {
      //   newArr.push(item);
      // }
      return paramArr;
    } else {
      return [];
    }
  }

  mapData(): void {
    for (const item of this.dataCopy) {
      if (item.fieldType === 'MultiCheckBoxes') {
        if (item.value) {
          item.value = item.value.length ? JSON.parse(item.value) : [];
        }
        item.values = this.onOptions(item.values);
      }
      if (item.fieldType === 'SingleBox') {
        item.values = this.onOptions(item.values);
      }
    }
  }

  onReset(): void {
    this.dataCopy = JSON.parse(JSON.stringify(this.domainMetadata));
    this.mapData();
  }

  onSave(): void {
    const newArr = [];
    this.dataCopy.forEach( item => {
      const param = {
        fieldId: null,
        value: null
      };
      param.fieldId = item.fieldId;
      switch (item.fieldType) {
        case 'DateField':
          param.value = this.datePipe.transform(item.value, 'yyyy-MM-dd');
          break;
        case 'DateTimeField':
          param.value = this.datePipe.transform(item.value, 'yyyy-MM-dd HH:mm:ss');
          break;
        case 'MultiCheckBoxes':
          if (item.value) {
            param.value = item.value.length ? JSON.stringify(item.value) : null;
          }
          break;
        default:
          param.value = item.value;
          break;
      }
      newArr.push(param);
    });
    this.domainSaved.emit(newArr);
  }

}
