import { Component, Input, OnInit } from '@angular/core';
import { PropertyDisplayMode } from '../../shared';

@Component({
  selector: 'app-property-tags',
  templateUrl: './property-tags.component.html',
  styleUrls: ['./property-tags.component.less']
})
export class PropertyTagsComponent implements OnInit {

  @Input() displayMode: PropertyDisplayMode = 'horizontal';
  @Input() title: string;
  @Input() tip: string;
  @Input() tipVisible = false;
  @Input() bottomLineVisible = true;

  innerValue: string[];

  @Input()
  get value(): string[] {
    return this.innerValue;
  }

  set value(val: string[]) {
    if (Array.isArray(val)) {
      this.innerValue = val;
    }
  }

  constructor() {
  }

  ngOnInit(): void {
  }
}
