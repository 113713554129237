import { Component, OnInit } from '@angular/core';
import { ChangePassword, ChangePasswordRes, ChangePwdMode, Country } from '../../api/types';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';

@Component({
  selector: 'app-phone-number-login',
  templateUrl: './phone-number-login.component.html',
  styleUrls: ['./phone-number-login.component.less']
})
export class PhoneNumberLoginComponent implements OnInit {
  t: any; // 定时器
  timeVal: any;
  isOk = false;
  changePwd: ChangePassword = new ChangePassword();
  changeInfo: ChangePasswordRes = new ChangePasswordRes();
  logging = false;
  areaCode = '86';
  phone: string;
  code: string;
  codeTips: string;
  drawerTitle: string;
  visible = false;
  accountNameArr: string[] = [];
  areaCodeOptions: PropertySelectOption[] = [];
  contactsAreaCodeArr: Country[] = [];

  constructor(private router: Router,
              private authService: AuthService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    const areaCodes = [
      {
        key: '86',
        value: '+86'
      },
      {
        key: '852',
        value: '+852'
      },
      {
        key: '886',
        value: '+886'
      },
      {
        key: '81',
        value: '+81'
      },
      {
        key: '66',
        value: '+66'
      },
      {
        key: '82',
        value: '+82'
      },
      {
        key: '61',
        value: '+61'
      },
      {
        key: '64',
        value: '+64'
      },
      {
        key: '853',
        value: '+853'
      }
    ];
    for (const areaCode of areaCodes) {
      this.areaCodeOptions.push(new PropertySelectOption(areaCode.value, areaCode.key));
    }
    this.areaCodeData();
  }


  verifyAccount(): void {
    console.log('下一步', this.changePwd);
    if (!(this.areaCode && this.phone && this.code)) {
      return;
    }
    const str = this.areaCode ? this.areaCode.split('+')[0] : null;
    this.authService.getPhoneLoginList({areaCode: str, phone: this.phone, code: this.code})
      .subscribe(
        data => {
          console.log(data);
          if (data.length === 1) {
            this.onPhoneChange(data[0]);
          } else {
            this.accountNameArr = data;
            this.drawerTitle = this.encryptPhoneNumber(this.phone);
            this.visible = true;
          }
          // console.log(data);
          // this.changeInfo = data;
        },
        error => {
          console.log(error);
        });
  }

  onPhoneChange(accountName): void {
    const str = this.areaCode ? this.areaCode.split('+')[0] : null;
    this.authService.getPhoneLogin({areaCode: str, phone: this.phone, code: this.code, accountName})
      .subscribe(
        data => {
          this.logging = false;
          this.authService.passport = data;
          if (this.isOk) {
            clearInterval(this.t);
          }
          this.router.navigate(['/user/workbench']).then();
        },
        () => {
          this.logging = false;
        });
  }

  onBack(): void {
    if (this.isOk) {
      clearInterval(this.t);
    }
    this.router.navigate(['auth/login-by-account']).then();
  }

  isValue(): boolean {
    return !(this.changePwd.newPassword && this.changePwd.code);
  }

  onSend(): void {
    if (!(this.areaCode && this.phone)) {
      return;
    }
    if (!this.isOk) {
      this.codeTips = '';
      this.countdown();
      const str = this.areaCode ? this.areaCode.split('+')[0] : null;
      this.authService.getPhoneCode({areaCode: str, phone: this.phone})
        .subscribe(
          data => {
            console.log(data, '验证码发送成功');
            this.codeTips = data;
            // if (data) {
            //   this.translate.get('SendSuccessfullyTips').subscribe(
            //     (res) => {
            //       this.toastr.success(res);
            //     }
            //   );
            // }
          },
          error => {
            console.log(error);
          });
    }
  }

  toggleEmail(): void {
    // console.log('换邮箱验证');
    this.changePwd.newPassword = undefined;
    this.changePwd.code = undefined;
  }

  countdown(): void {
    clearInterval(this.t);
    let times = 60;
    this.t = setInterval(() => {
      if (--times > 0) {
        this.timeVal = times + 'S';
        this.isOk = true;
      } else {
        this.isOk = false;
        clearInterval(this.t);
      }
    }, 1000);
  }

  close(): void {
    this.visible = false;
  }

  encryptPhoneNumber(phoneNumber): string {
    if (typeof phoneNumber !== 'string') {
      return '';
    }
    const len = phoneNumber.length;
    if (len <= 4) {
      return phoneNumber;
    }
    const lastFourDigits = phoneNumber.substring(len - 4);
    const encryptedDigits = '*'.repeat(len - 4);
    return '****' + lastFourDigits;
  }

  areaCodeData(): void {
    this.authService.getCountryAll()
      .subscribe(
        data => {
          console.log(data);
          this.contactsAreaCodeArr = data;
        },
        error => {
          console.log(error);
        });
  }

  selectFilterOption(input, option): boolean {
    if (input) {
      return option.key.includes(input);
    }
    return false;
  }
}
