import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FnaQuestion } from '../../fna/fna-types';
import { KeyValue } from '../../api/types';
import { FinancialSave, NewFnaDetail, PiBaFinancialArr, QuestionsArrPiBA } from '../../new-fna/new-fna-types';
import { ActivatedRoute } from '@angular/router';
import { NewFnaService } from '../../new-fna/new-fna.service';
import { plainToArray } from '../../shared/utils/class-transform';
import { plainToInstance } from 'class-transformer';

@Component({
  selector: 'app-piba-form-template',
  templateUrl: './piba-form-template.component.html',
  styleUrls: ['./piba-form-template.component.less']
})
export class PibaFormTemplateComponent implements OnInit {
  otherExisting = true;
  policy2 = false;
  rentalIncomeAmount: string;
  fnaForm: KeyValue[] = [];
  @Input()
  fnaInfo: NewFnaDetail = new NewFnaDetail();

  financialObj: PiBaFinancialArr = new PiBaFinancialArr();
  financialObjCopy: PiBaFinancialArr = new PiBaFinancialArr();

  constructor(private router: ActivatedRoute,
              private newFnaService: NewFnaService) {
  }

  ngOnInit(): void {
    // this.fnaQuestions = plainToArray(FnaQuestion, QuestionsArrPiBA);
  }

  setForms(fnaInfo: NewFnaDetail): void {
    let formObj = {};
    if (fnaInfo.financialAnalysis && fnaInfo.financialAnalysis.length) {
      formObj = fnaInfo.financialAnalysis.reduce((prev, c) => ({...prev, [c.key]: c.value}), {});
    }
    this.financialObj = plainToInstance(PiBaFinancialArr, formObj);
    console.log(this.financialObj);
    this.financialObjCopy = JSON.parse(JSON.stringify(this.financialObj));
    // this.fnaQuestions.forEach(question => {
    //   this.setQuestions(question, formObj);
    // });
    const arr = ['FNA_06_b_Insurer_Name_02', 'FNA_06_b_Interest_Rate_02',
      'FNA_06_b_Lender_Name_02', 'FNA_06_b_Loan_Amount_02', 'FNA_06_b_Policy_Number_02',
      'FNA_06_b_Repayment_Amount_01_02',
      'FNA_06_b_Repayment_Amount_02_02', 'FNA_06_b_Repayment_Term_02'];
    this.policy2 = arr.some(item => !!formObj[item]);
    // this.fnaQuestionsCopy = JSON.parse(JSON.stringify(this.fnaQuestions));
  }

  onDetail(id: number): void {
    this.newFnaService.info(id)
      .subscribe(
        fna => {
          this.setForms(fna);
        },
        error => {
        });
  }

  parserValue(value: string): string {
    return value.replace(/\$\s?|(,*)/g, '');
  }

  formatterValue(value: number): string {
    if (parseFloat(String(value)).toString() === 'NaN') {
      return '';
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  FNA02log(valueKey: string): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || !!this.fnaInfo.underwritingNo)) {
      const arr = ['FNA_02_A', 'FNA_02_B', 'FNA_02_C'];
      const isTask = this.financialObj[valueKey];
      arr.map(item => {
        this.financialObj[item] = false;
      });
      this.financialObj[valueKey] = !isTask;
    }
  }

  FNA03log(valueKey: string): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || !!this.fnaInfo.underwritingNo)) {
      const arr = ['FNA_03_A', 'FNA_03_B', 'FNA_03_C', 'FNA_03_D', 'FNA_03_E', 'FNA_03_F', 'FNA_03_G'];
      const isTask = this.financialObj[valueKey];
      arr.map(item => {
        this.financialObj[item] = false;
      });
      this.financialObj[valueKey] = !isTask;
    }
  }

  FNA05alog(valueKey: string): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || !!this.fnaInfo.underwritingNo)) {
      const arr = ['FNA_05_a_A', 'FNA_05_a_B', 'FNA_05_a_C', 'FNA_05_a_D', 'FNA_05_a_E', 'FNA_05_a_F'];
      const isTask = this.financialObj[valueKey];
      arr.map(item => {
        this.financialObj[item] = false;
      });
      this.financialObj.FNA_05_a_A_addition = null;
      this.financialObj[valueKey] = !isTask;
    }
  }

  FNA05clog(valueKey: string): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || !!this.fnaInfo.underwritingNo)) {
      const arr = ['FNA_05_c_A', 'FNA_05_c_B', 'FNA_05_c_C', 'FNA_05_c_D', 'FNA_05_c_E', 'FNA_05_c_F'];
      const isTask = this.financialObj[valueKey];
      arr.map(item => {
        this.financialObj[item] = false;
      });
      this.financialObj.FNA_05_c_F_percent = null;
      this.financialObj.FNA_05_c_H_addition = null;
      this.financialObj[valueKey] = !isTask;
    }
  }

  FNA05dlog(valueKey: string): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || !!this.fnaInfo.underwritingNo)) {
      const arr = ['FNA_05_d_A', 'FNA_05_d_B', 'FNA_05_d_C', 'FNA_05_d_D', 'FNA_05_d_E', 'FNA_05_d_F'];
      const isTask = this.financialObj[valueKey];
      arr.map(item => {
        this.financialObj[item] = false;
      });
      this.financialObj.FNA_05_d_F_percent = null;
      this.financialObj.FNA_05_d_H_addition = null;
      this.financialObj[valueKey] = !isTask;
    }
  }

  FNA05elog(valueKey: string): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || !!this.fnaInfo.underwritingNo)) {
      const arr = ['FNA_05_e_basic_A', 'FNA_05_e_basic_B', 'FNA_05_e_basic_C', 'FNA_05_e_basic_D', 'FNA_05_e_basic_E', 'FNA_05_e_basic_F', 'FNA_05_e_basic_G', 'FNA_05_e_basic_H'];
      const isTask = this.financialObj[valueKey];
      arr.map(item => {
        this.financialObj[item] = false;
      });
      this.financialObj.FNA_05_e_basic_G_year = null;
      this.financialObj.FNA_05_e_basic_H_money = null;
      this.financialObj[valueKey] = !isTask;
    }
  }

  FNA05erlog(valueKey: string): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || !!this.fnaInfo.underwritingNo)) {
      const arr = ['FNA_05_e_rider_A', 'FNA_05_e_rider_B', 'FNA_05_e_rider_C', 'FNA_05_e_rider_D', 'FNA_05_e_rider_E', 'FNA_05_e_rider_F', 'FNA_05_e_rider_G', 'FNA_05_e_rider_H'];
      const isTask = this.financialObj[valueKey];
      arr.map(item => {
        this.financialObj[item] = false;
      });
      this.financialObj.FNA_05_e_rider_G_year = null;
      this.financialObj.FNA_05_e_rider_H_money = null;
      this.financialObj[valueKey] = !isTask;
    }
  }

  FNA05log(valueKey): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || !!this.fnaInfo.underwritingNo)) {
      const arr = ['FNA_05_f_A', 'FNA_05_f_B'];
      const isTask = this.financialObj[valueKey];
      arr.map(item => {
        this.financialObj[item] = false;
      });
      this.financialObj.FNA_05_f_B_addition = null;
      this.financialObj[valueKey] = !isTask;
    }
  }

  FNA06log(valueKey): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || !!this.fnaInfo.underwritingNo)) {
      const arr = ['FNA_06_a_A', 'FNA_06_a_B'];
      const isTask = this.financialObj[valueKey];
      arr.map(item => {
        this.financialObj[item] = false;
      });
      this.financialObj.FNA_06_a_Policy_Number = null;
      this.financialObj.FNA_06_a_Insurer_Name = null;
      this.financialObj.FNA_06_a_Lender_Name = null;
      this.financialObj.FNA_06_a_Loan_Amount = null;
      this.financialObj.FNA_06_a_Interest_Rate = null;
      this.financialObj.FNA_06_a_Repayment_Term = null;
      this.financialObj.FNA_06_a_Repayment_Amount = null;
      this.financialObj.FNA_06_a_Repayment_Amount_02 = null;
      this.financialObj[valueKey] = !isTask;
    }
  }

  FNA062log(valueKey): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || !!this.fnaInfo.underwritingNo)) {
      const arr = ['FNA_06_b_A', 'FNA_06_b_B'];
      const isTask = this.financialObj[valueKey];
      arr.map(item => {
        this.financialObj[item] = false;
      });
      this.financialObj.FNA_06_b_Policy_Number_01 = null;
      this.financialObj.FNA_06_b_Insurer_Name_01 = null;
      this.financialObj.FNA_06_b_Lender_Name_01 = null;
      this.financialObj.FNA_06_b_Loan_Amount_01 = null;
      this.financialObj.FNA_06_b_Interest_Rate_01 = null;
      this.financialObj.FNA_06_b_Repayment_Term_01 = null;
      this.financialObj.FNA_06_b_Repayment_Amount_01 = null;
      this.financialObj.FNA_06_b_Repayment_Amount_01_02 = null;
      this.financialObj.FNA_06_b_Policy_Number_02 = null;
      this.financialObj.FNA_06_b_Insurer_Name_02 = null;
      this.financialObj.FNA_06_b_Lender_Name_02 = null;
      this.financialObj.FNA_06_b_Loan_Amount_02 = null;
      this.financialObj.FNA_06_b_Interest_Rate_02 = null;
      this.financialObj.FNA_06_b_Repayment_Term_02 = null;
      this.financialObj.FNA_06_b_Repayment_Amount_02 = null;
      this.financialObj.FNA_06_b_Repayment_Amount_02_02 = null;
      this.financialObj[valueKey] = !isTask;
    }
  }
}
