<div>

  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">

    <!-- 预约信息 -->
    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'BookingInfo' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onBookingInfo()">
          <div class="property-pick-value">
            <span style="flex: 1;"></span>
            <span style="margin: 0 10px 0 10px; color: #1D64C4">{{bookingDetail.bookingProgress?.booking}}%</span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 产品计划 -->
    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'ProductPlan' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onBookingProductPlan()">
          <div style="display: flex; align-items: center; flex-direction: row;">
            <!--            <div style="flex: 1; margin-right: 10px">-->
            <!--              <span>-->
            <!--                  <span *ngIf="booking.productPlan?.currency" style="font-size: 13px; color: #999999">-->
            <!--                    {{'Currency' | translate}}:{{booking.productPlan?.currency}}-->
            <!--                  </span>-->
            <!--                <span *ngIf="booking.productPlan?.premium" style="font-size: 13px; color: #999999">-->
            <!--                    {{'Premium' | translate}}:{{booking.productPlan?.premium | number}}-->
            <!--                  </span>-->
            <!--                <span *ngIf="booking.productPlan?.sumAssured" style="font-size: 13px; color: #999999">-->
            <!--                    {{'SumAssured' | translate}}:{{booking.productPlan?.sumAssured | number}}-->
            <!--                  </span>-->
            <!--              </span>-->
            <!--            </div>-->
            <span style="flex: 1;"></span>
            <div><span style="margin: 0 10px 0 10px; color: #1D64C4">{{bookingDetail.bookingProgress?.productPlan}}%</span></div>
            <div><i nz-icon nzType="right" nzTheme="outline"></i></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 投保人 -->
    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'Holder' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onBookingOwner()">
          <div class="property-pick-value">
            <!--            <span style="flex: 1;">{{booking.ownerNameString()}}</span>-->
            <span style="flex: 1;"></span>
            <span style="margin: 0 10px 0 10px; color: #1D64C4">{{bookingDetail.bookingProgress?.owner}}%</span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 受保人 -->
    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'Insured' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onBookingInsured()">
          <div class="property-pick-value">
            <!--            <span style="flex: 1;">{{booking.insuredNameString()}}</span>-->
            <span style="flex: 1;"></span>
            <span style="margin: 0 10px 0 10px; color: #1D64C4">{{bookingDetail.bookingProgress?.insured}}%</span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 受益人 -->
    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'Beneficiary' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onBookingBeneficiaries()">
          <div class="property-pick-value">
            <span style="flex: 1;"></span>
            <span style="margin: 0 10px 0 10px; color: #1D64C4">{{bookingDetail.bookingProgress?.beneficiaries}}%</span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 第二持有人 -->
    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'SecondHolder' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onSecondHolder()">
          <div class="property-pick-value">
            <span style="flex: 1;"></span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 第二受保人 -->
    <div *ngIf="instance.key !== 'fone'" class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'SecondInsured' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onSecondInsured()">
          <div class="property-pick-value">
            <span style="flex: 1;"></span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 健康信息 -->
    <div class="booking-section-wrapper">
      <div class="property-row" appThrottleClick (throttleClick)="onHealthInfo()">
        <div class="property-title-row">{{ 'HealthInformation' | translate }}</div>
        <div class="property-value-row">
          <div class="property-pick-value">
            <span style="flex: 1;"></span>
            <span style="margin: 0 10px 0 10px; color: #1D64C4">{{bookingDetail.bookingProgress?.healthQuestions}}%</span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 关联FNA -->
    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'AssociateFNA' | translate }}</div>
        <div class="property-value-row"
             appThrottleClick (throttleClick)="onAssociateFNA()">
          <div class="property-pick-value">
          <span style="flex: 1;">
            <span *ngIf="booking.fnaId">
              <a nz-dropdown [nzDropdownMenu]="associateFNAMenu">
                #{{booking.fnaId}}
              </a>
              <nz-dropdown-menu #associateFNAMenu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item appThrottleClick (throttleClick)="onFnaDetail()"
                      style="color: #4B84E5; font-size: 14px;">{{'ViewDetail' | translate}}</li>
                  <li nz-menu-item appThrottleClick (throttleClick)="onFnaReAssociate()"
                      style="color: #4B84E5; font-size: 14px;">{{'ReAssociate' | translate}}</li>
                  <li nz-menu-item appThrottleClick (throttleClick)="onFnaDisassociate()"
                      style="color: #e63757; font-size: 14px;">{{'Disassociate' | translate}}</li>
                </ul>
              </nz-dropdown-menu>
            </span>
          </span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 关联计划书 -->
    <div class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'AssociateProposal' | translate }}</div>
        <div class="property-value-row"
             appThrottleClick (throttleClick)="onAssociateProposal(tplAssociateProposalTitle, tplAssociateProposalContent, tplAssociateProposalFooter)">
          <div class="property-pick-value">
          <span style="flex: 1;">
            <span *ngIf="booking.proposalId">
            <a nz-dropdown [nzDropdownMenu]="menu">
              #{{booking.proposalId}}
            </a>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item appThrottleClick (throttleClick)="onProposalDetail()"
                    style="color: #4B84E5; font-size: 14px;">{{'ViewDetail' | translate}}</li>
                <li nz-menu-item appThrottleClick (throttleClick)="onProposalReAssociate()"
                    style="color: #4B84E5; font-size: 14px;">{{'ReAssociate' | translate}}</li>
                <li nz-menu-item appThrottleClick (throttleClick)="onProposalDisassociate()"
                    style="color: #e63757; font-size: 14px;">{{'Disassociate' | translate}}</li>
              </ul>
            </nz-dropdown-menu>
            </span>
          </span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!--  转保声明  -->
    <div class="booking-section-wrapper">
      <div class="property-row" appThrottleClick (throttleClick)="onStatement()">
        <div class="property-title-row">{{ 'ReinsuranceStatement' | translate }}</div>
        <div class="property-value-row">
          <div class="property-pick-value">
            <span style="flex: 1;"></span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <!--  預約單备注  -->
<!--    <div class="booking-section-wrapper">-->
<!--      <div class="property-row" appThrottleClick (throttleClick)="onBookingRemark()">-->
<!--        <div class="property-title-row">{{ 'BookingRemark' | translate }}</div>-->
<!--        <div class="property-value-row">-->
<!--          <div class="property-pick-value">-->
<!--            <div style="flex: 1;">{{ booking.special }}</div>-->
<!--            <div><i nz-icon nzType="right" nzTheme="outline"></i></div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <!--  其他  -->
    <div class="booking-section-wrapper">
      <div class="property-row" appThrottleClick (throttleClick)="onOther()">
        <div class="property-title-row">{{ 'Other' | translate }}</div>
        <div class="property-value-row">
          <div class="property-pick-value">
            <span style="flex: 1;"></span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isShowItem">
      <!--  New BUSINESS Form  -->
      <div *ngIf="bookingDetail.applicationType !== 'FUND_SWITCHING'" class="booking-section-wrapper">
        <div class="property-row" appThrottleClick (throttleClick)="openBusinessForm('NEW_BUSINESS_FORM')">
          <div class="property-title-row">New Business Form</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>

      <!-- Account Opening Form -->
      <div
        *ngIf="bookingDetail.applicationType !== 'FUND_SWITCHING' && bookingDetail.applicationType !== 'MPF_APPLICATION' && bookingDetail.applicationType !== 'GENERAL_INSURANCE_APPLICATION'"
        class="booking-section-wrapper">
        <div class="property-row" appThrottleClick (throttleClick)="openBusinessForm('ACCOUNT_OPENING_FORM')">
          <div class="property-title-row">Account Opening Form</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>

      <!-- Confirmation Letter(non-ILAS)-->
      <div
        *ngIf="bookingDetail.applicationType !== 'FUND_SWITCHING' && bookingDetail.applicationType !== 'MPF_APPLICATION' && bookingDetail.applicationType !== 'GENERAL_INSURANCE_APPLICATION'"
        class="booking-section-wrapper">
        <div class="property-row" appThrottleClick (throttleClick)="openBusinessForm('CONFIRMATION_LETTER_NON_ILAS')">
          <div class="property-title-row">Confirmation Letter(non-ILAS)</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>

      <!-- RISK_PROFILE_QUESTIONNAIRE-->
      <div *ngIf="bookingDetail.applicationType === 'FUND_SWITCHING'" class="booking-section-wrapper">
        <div class="property-row" appThrottleClick (throttleClick)="openBusinessForm('RISK_PROFILE_QUESTIONNAIRE')">
          <div class="property-title-row">Risk Profile Questionnaire</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>

      <!-- Checklist-->
      <div
        *ngIf="bookingDetail.applicationType !== 'FUND_SWITCHING' && bookingDetail.applicationType !== 'MPF_APPLICATION' && bookingDetail.applicationType !== 'GENERAL_INSURANCE_APPLICATION'"
        class="booking-section-wrapper">
        <div class="property-row" appThrottleClick (throttleClick)="openBusinessForm('CHECKLIST')">
          <div class="property-title-row">Checklist</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="workbench.isBookingOwnerSignature" class="booking-section-wrapper">
      <div class="property-row">
        <div class="property-title-row">{{ 'PolicyOwnerSignatureCopy' | translate }}</div>
        <div class="property-value-row" appThrottleClick (throttleClick)="onOpenSignature()">
          <div class="property-pick-value">
            <span style="flex: 1;"></span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!instance.isFone()" style="margin: 20px 0;">
      <label nz-checkbox [(ngModel)]="bookingDetail.isPaperContracts" (ngModelChange)="onChange($event)">
        {{'RequiredPaperContractsNot' | translate}}
      </label>
    </div>

    <div *ngIf="instance.isEmpire()" style="margin: 20px 0;">
      <label nz-checkbox [(ngModel)]="bookingDetail.authorizeCollect" (ngModelChange)="onEmpireChange($event)">
        {{'AuthorizeCollect' | translate}}
      </label>
    </div>

    <div *ngIf="workbench.bookingSignatureDataRemark" style="margin: 20px 10px 10px 0; color: #666666">
      <div [innerHTML]="workbench.bookingSignatureDataRemark | html"></div>
    </div>

    <div *ngIf="workbench.bookingCompanyWorkDateRemark" style="margin: 20px 10px 10px 0; color: #666666">
      <div [innerHTML]="workbench.bookingCompanyWorkDateRemark | html"></div>
    </div>

  </nz-spin>

</div>


<ng-template #tplAssociateProposalTitle>
  <span>{{'AssociateProposal' | translate}}</span>
</ng-template>
<ng-template #tplAssociateProposalContent let-ref="modalRef" let-params>
  <nz-list nzSize="small">
    <nz-list-item>
      <div style="text-align: center; width: 100%">
        <a nz-button nzType="link" nzSize="small" style="color: #4B84E5; font-size: 14px;"
           appThrottleClick (throttleClick)="onProposalDetail(); ref.destroy()">
          {{'ViewDetail' | translate}}
        </a>
      </div>
    </nz-list-item>
    <nz-list-item>
      <div style="text-align: center; width: 100%">
        <a nz-button nzType="link" nzSize="small" style="color: #4B84E5; font-size: 14px;"
           appThrottleClick (throttleClick)="onProposalReAssociate(); ref.destroy()">
          {{'ReAssociate' | translate}}
        </a>
      </div>
    </nz-list-item>
    <nz-list-item>
      <div style="text-align: center; width: 100%">
        <a nz-button nzType="link" nzSize="small" style="color: #e63757; font-size: 14px;"
           appThrottleClick (throttleClick)="onProposalDisassociate(); ref.destroy()">
          {{'Disassociate' | translate}}
        </a>
      </div>
    </nz-list-item>
  </nz-list>
</ng-template>
<ng-template #tplAssociateProposalFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()">{{'Cancel' | translate}}</button>
</ng-template>


<nz-modal [(nzVisible)]="isVisible"
          nzCentered [nzTitle]="modalTitle"
          [nzFooter]="null"
          [nzWidth]="'600px'"
          [nzMaskClosable]="false"
          (nzOnCancel)="handleCancel()">
  <ng-template #modalTitle>
    <div>{{'PolicyOwnerSignatureCopy' | translate}}</div>
  </ng-template>
  <ng-container *nzModalContent>
    <div class="display-flex" style="justify-content: center;margin-bottom: 20px;">
      <div class="signature-area">
        <app-signature-pad *ngIf="!signatureUrl"
                           [options]="signaturePadOptions"></app-signature-pad>
        <img *ngIf="signatureUrl"
             [width]="signaturePadOptions.canvasWidth"
             [height]="signaturePadOptions.canvasHeight"
             [src]="signatureUrl" alt=""/>
      </div>
    </div>
    <div class="modal-footer display-flex" style="justify-content: center;">
      <div class="button-default">
        <button nz-button nzType="default"
                [style]="!canRewrite ? 'opacity: 0.5' : ''"
                appThrottleClick (throttleClick)="onRewriteConfirm()">{{'Clean' | translate}}</button>
      </div>
      <div class="button-primary" style="margin-left: 22px;">
        <button nz-button nzType="primary"
                [style]="!canConfirm ? 'opacity: 0.5' : ''"
                appThrottleClick (throttleClick)="onConfirm()">{{'Confirm' | translate}}</button>
      </div>
    </div>
  </ng-container>
</nz-modal>
