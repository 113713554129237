<div class="basic-info-container">
  <div class="basic-info-card">
    <div nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text" style="margin-top: 0">{{'SalesChannel' | translate}}</div>
        <div class="value-box">
          <nz-select [(ngModel)]="fnaInfo.accountName" nzAllowClear
                     [nzPlaceHolder]="'PleaseChoose' | translate"
                     class="value-box-select"
                     [nzShowSearch]="true"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [nzFilterOption]="accountNameSearch">
            <nz-option *ngFor="let option of accountNameOptions" [nzLabel]="option.name" [nzValue]="option.accountName">
            </nz-option>
          </nz-select>
        </div>
      </div>
      <!--      <div nz-col [nzSpan]="6">-->
      <!--        <div class="label-text" style="margin-top: 0">-->
      <!--          {{'ClientType' | translate}}-->
      <!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
      <!--        </div>-->
      <!--        <div class="value-box">-->
      <!--          <nz-radio-group [(ngModel)]="fnaInfo.customerType" nzButtonStyle="solid"-->
      <!--                          class="value-box-radio-group">-->
      <!--            <label nz-radio-button *ngFor="let type of customerTypeOptions"-->
      <!--                   style="" class="value-box-radio-group-label"-->
      <!--                   [nzValue]="type.value">-->
      <!--              {{type.label}}-->
      <!--            </label>-->
      <!--          </nz-radio-group>-->
      <!--        </div>-->
      <!--      </div>-->

      <div nz-col [nzSpan]="6">
        <div class="label-text" style="margin-top: 0">
          {{'ClientType' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of customerTypeOptions"
                 class="radio-group-box-label"
                 (click)="customerTypeChange(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? fnaInfo.customerType === type.value ? 'radio-group-box-label-active-disabled' : '' : fnaInfo.customerType === type.value ? 'radio-group-box-label-active' : ''">
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text required-after" style="margin-top: 0">{{'BusinessType' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <nz-select [(ngModel)]="fnaInfo.fnaBusinessType" nzAllowClear
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [nzPlaceHolder]="'PleaseChoose' | translate"
                     class="value-box-select"
                     [nzShowSearch]="false">
            <nz-option *ngFor="let option of fnaBusinessTypeOptions"
                       [nzLabel]="option.label" [nzValue]="option.value">
            </nz-option>
          </nz-select>
        </div>
      </div>
    </div>
  </div>

  <div class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">{{'ConsultantWitnessInformation' | translate}}</div>
    <div nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'ConsultantNameEnglish' | translate}}</div>
        <div class="value-box">
          <input class="value-box-input" nz-input
                 [disabled]="true"
                 [placeholder]="'DefaultTRName' | translate"
                 [(ngModel)]="fnaInfo.consultantName" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'ConsultantLicenseNo' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.englishConsultantLicenseNo" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'ContactNoConsultant' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.consultantContactNo" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'WitnessNameEnglish' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.englishWitnessName" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'WitnessIDNoIANo' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.witnessIdNo" />
        </div>
      </div>
    </div>
  </div>

  <div class="basic-info-card">
    {{'BasicInfoTips' | translate}}
  </div>

  <div class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">
      {{'ClientInfoPolicyOwner' | translate}}
      <div class="icon-minus" (click)="basicOwner = !basicOwner">
        <img *ngIf="basicOwner" alt="" width="20"
             src="assets/images/icon_minus.svg">
        <img *ngIf="!basicOwner" alt="" width="20"
             src="assets/images/icon_plus.svg">
      </div>
    </div>
    <div *ngIf="basicOwner" nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div *ngIf="!workbench.hiddenLastName" nz-col [nzSpan]="6">
        <div class="label-text required-after">
          {{'LastNameEnglish' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <input class="value-box-input" nz-input
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.englishLastName" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">
          {{'GivenNameEnglish' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.englishFirstName" />
        </div>
      </div>
      <div *ngIf="!workbench.hiddenLastName" nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'LastNameChinese' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.chineseLastName" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'GivenNameChinese' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.chineseFirstName" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">
          {{'Gender' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.gender" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of genderOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of genderOptions"
                 class="radio-group-box-label"
                 (click)="genderChange(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.gender === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.gender === type.value ? 'radio-group-box-label-active' : ''">
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">
          {{'Title' | translate}}
        </div>
        <div class="value-box">
          <nz-select [(ngModel)]="basicInfo.appellation" nzAllowClear
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [nzPlaceHolder]="'PleaseChoose' | translate"
                     class="value-box-select"
                     [nzShowSearch]="false">
            <nz-option *ngFor="let option of titleOptions"
                       [nzLabel]="option.label" [nzValue]="option.value">
            </nz-option>
          </nz-select>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">
          {{'SmokingStatus' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.smoke"-->
          <!--                          nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of smokeOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of smokeOptions"
                 class="radio-group-box-label"
                 (click)="smokingChange(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.smoke === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.smoke === type.value ? 'radio-group-box-label-active' : ''">
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">
          {{'DateOfBirth' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <div class="value-box-mat-box" style="position: relative;">
            <input nz-input class="value-box-matInput"
                   [placeholder]="'DD/MM/YYYY'"
                   [(ngModel)]="basicInfo.dateOfBirth"
                   (input)="logClick()"
                   (focus)="onAddClick()"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   (keyup.enter)="handleEndOpenChange()"
                   (blur)="handleEndOpenChange()"/>
            <!--            <i nz-icon nzType="calendar" nzTheme="outline"-->
            <!--               appThrottleClick (throttleClick)="handleEndOpenChange()"></i>-->
            <nz-date-picker #datePicker class="date-picker-row"
                            style="position: absolute;top: 0;z-index: -2;"
                            nzBorderless [ngModel]="birthdate"
                            (ngModelChange)="onDateChange($event)"
                            [nzFormat]="'dd/MM/yyyy'"
                            [nzShowTime]="false"
                            [nzShowToday]="false"
                            [nzPlaceHolder]="''"
                            (nzOnOpenChange)="handleOpenChange($event)"></nz-date-picker>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'BirthplaceCMG' | translate}}</div>
        <div class="value-box">
          <app-filter-popover [nationality]="basicInfo.countryOfBirth"
                              [isDisabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                              [modeType]="'String'"
                              (nationalitySelected)="countryChange($event)">
          </app-filter-popover>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'Nationality' | translate}}</div>
        <div class="value-box">
          <app-filter-popover [nationality]="basicInfo.nationality"
                              [isDisabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                              [modeType]="'String'"
                              (nationalitySelected)="nationalityChange($event)">
          </app-filter-popover>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'IDNo/BirthCertNo' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.idCard" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'PassportNo' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.passport" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'Marriage' | translate}}</div>
        <div class="value-box">
          <nz-select [(ngModel)]="basicInfo.maritalStatus" nzAllowClear
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [nzPlaceHolder]="'PleaseChoose' | translate"
                     class="value-box-select"
                     [nzShowSearch]="false">
            <nz-option *ngFor="let option of maritalStatusOptions"
                       [nzLabel]="option.label" [nzValue]="option.value">
            </nz-option>
          </nz-select>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'Education' | translate}}</div>
        <div class="value-box">
          <nz-select [(ngModel)]="basicInfo.education" nzAllowClear
                     [nzPlaceHolder]="'PleaseChoose' | translate"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     class="value-box-select"
                     [nzShowSearch]="false">
            <nz-option *ngFor="let option of educationOptions"
                       [nzLabel]="option.label" [nzValue]="option.value">
            </nz-option>
          </nz-select>
        </div>
      </div>

      <div *ngIf="basicInfo.education === 'OTHERS'" nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'OthersPleaseSpecify' | translate"
                 [(ngModel)]="basicInfo.othersEducationAddition" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'MobilePhoneNo' | translate}}</div>
        <div class="value-box">
          <div style="display: flex;border: 1px solid #D9D9D9;border-radius: 4px;">
            <app-filter-popover [nationality]="basicInfo.mobileArea"
                                [isDisabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                style="width: 80px;"
                                [modeType]="'Number'"
                                [isBorder]="false"
                                (nationalitySelected)="mobileChange($event)">
            </app-filter-popover>
            <input nz-input class="value-box-input"
                   style="border: none;padding-left: 0;flex: 1"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [placeholder]="'PleaseEnter' | translate"
                   [(ngModel)]="basicInfo.mobile" />
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'HomeNo' | translate}}</div>
        <div class="value-box">
          <div style="display: flex;border: 1px solid #D9D9D9;border-radius: 4px;">
            <app-filter-popover [nationality]="basicInfo.homePhoneArea"
                                style="width: 80px;"
                                [isDisabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                [modeType]="'Number'"
                                [isBorder]="false"
                                (nationalitySelected)="homePhoneChange($event)">
            </app-filter-popover>
            <input nz-input class="value-box-input"
                   style="border: none;padding-left: 0;flex: 1"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [placeholder]="'PleaseEnter' | translate"
                   [(ngModel)]="basicInfo.homePhone" />
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'OfficeNo' | translate}}</div>
        <div class="value-box">
          <div style="display: flex;border: 1px solid #D9D9D9;border-radius: 4px;">
            <app-filter-popover [nationality]="basicInfo.officeTelephoneArea"
                                [isDisabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                style="width: 80px;"
                                [modeType]="'Number'"
                                [isBorder]="false"
                                (nationalitySelected)="officeChange($event)">
            </app-filter-popover>
            <input nz-input class="value-box-input"
                   style="border: none;padding-left: 0;flex: 1"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [placeholder]="'PleaseEnter' | translate"
                   [(ngModel)]="basicInfo.officeTelephone" />
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'EmailAddress' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.emailAddress" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'CountryResidence' | translate}}</div>
        <div class="value-box">
          <app-filter-popover [nationality]="basicInfo.taxResidence"
                              [isDisabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                              [modeType]="'String'"
                              (nationalitySelected)="taxResidenceChange($event)">
          </app-filter-popover>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'RelationshipToInsured' | translate}}</div>
        <div class="value-box">
          <nz-select [(ngModel)]="basicInfo.relationship" nzAllowClear
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [nzPlaceHolder]="'PleaseChoose' | translate"
                     class="value-box-select"
                     [nzShowSearch]="false">
            <nz-option *ngFor="let option of fnaInsuredRelationshipOptions"
                       [nzLabel]="option.label" [nzValue]="option.value">
            </nz-option>
          </nz-select>
        </div>
      </div>


      <div *ngIf="basicInfo.relationship === 'OTHER'" nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'OthersPleaseSpecify' | translate"
                 [(ngModel)]="basicInfo.otherRelationship" />
        </div>
      </div>
    </div>
    <div class="basic-info-card-title" style="margin-top: 12px;">
      <span class="required-after">{{'ResidentialAddress' | translate}}</span>
      <div class="icon-minus" (click)="residential = !residential">
        <img *ngIf="residential" alt="" width="20"
             src="assets/images/icon_minus.svg">
        <img *ngIf="!residential" alt="" width="20"
             src="assets/images/icon_plus.svg">
      </div>

      <div *ngIf="residential" class="icon-minus" (click)="clearResidentialAddress()">
        <img alt="" width="20" src="assets/images/icon_address_clear.svg">
      </div>
    </div>
    <div *ngIf="residential" nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Room/Flat' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.residentialAddress.room" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Floor' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.residentialAddress.floor" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Block/Building' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.residentialAddress.block" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NameEstate/Court' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.residentialAddress.court" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NoNameStreet/Road' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.residentialAddress.road" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'District' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.residentialAddress.district" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.residentialAddress.hkDistrict" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of fnaHkDistrictOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of fnaHkDistrictOptions"
                 class="radio-group-box-label"
                 (click)="residentialAddresshk(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.residentialAddress.hkDistrict === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.residentialAddress.hkDistrict === type.value ? 'radio-group-box-label-active' : ''">
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6"></div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'Province' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.residentialAddress.province" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'CountryFNA' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.residentialAddress.country" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'PostalCode' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.residentialAddress.postalCode" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box lh-32">
          {{'ForAddressOutsideHongKongOnly' | translate}}
        </div>
      </div>

    </div>

    <div class="basic-info-card-title" style="margin-top: 12px;">
      {{'PostalAddress' | translate}}
      <div class="icon-minus" (click)="postal = !postal">
        <img *ngIf="postal" alt="" width="20"
             src="assets/images/icon_minus.svg">
        <img *ngIf="!postal" alt="" width="20"
             src="assets/images/icon_plus.svg">
      </div>

      <div *ngIf="postal" class="icon-minus" (click)="clearCorrespondenceAddress()">
        <img alt="" width="20" src="assets/images/icon_address_clear.svg">
      </div>
      <span class="tips-span">{{'IfDifferentResidentialAddressTips' | translate}}</span>
    </div>
    <div *ngIf="postal" nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Room/Flat' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.correspondenceAddress.room" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Floor' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.correspondenceAddress.floor" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Block/Building' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.correspondenceAddress.block" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NameEstate/Court' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.correspondenceAddress.court" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NoNameStreet/Road' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.correspondenceAddress.road" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'District' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.correspondenceAddress.district" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.correspondenceAddress.hkDistrict" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of fnaHkDistrictOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of fnaHkDistrictOptions"
                 class="radio-group-box-label"
                 (click)="correspondenceAddresshk(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.correspondenceAddress.hkDistrict === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.correspondenceAddress.hkDistrict === type.value ? 'radio-group-box-label-active' : ''">
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6"></div>


      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'Province' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.correspondenceAddress.province" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'CountryFNA' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.correspondenceAddress.country" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'PostalCode' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.correspondenceAddress.postalCode" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box lh-32">
          {{'ForAddressOutsideHongKongOnly' | translate}}
        </div>
      </div>

    </div>

    <div class="basic-info-card-title" style="margin-top: 12px;">
      {{'PermanentAddress' | translate}}
      <div class="icon-minus" (click)="permanent = !permanent">
        <img *ngIf="permanent" alt="" width="20"
             src="assets/images/icon_minus.svg">
        <img *ngIf="!permanent" alt="" width="20"
             src="assets/images/icon_plus.svg">
      </div>

      <div *ngIf="permanent" class="icon-minus" (click)="clearPermanentAddress()">
        <img alt="" width="20" src="assets/images/icon_address_clear.svg">
      </div>
      <span class="tips-span">{{'IfDifferentResidentialAddressTips' | translate}}</span>
    </div>
    <div *ngIf="permanent" nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Room/Flat' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.permanentAddress.room" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Floor' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.permanentAddress.floor" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Block/Building' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.permanentAddress.block" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NameEstate/Court' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.permanentAddress.court" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NoNameStreet/Road' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.permanentAddress.road" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'District' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.permanentAddress.district" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.permanentAddress.hkDistrict" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of fnaHkDistrictOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of fnaHkDistrictOptions"
                 class="radio-group-box-label"
                 (click)="permanentAddresshk(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.permanentAddress.hkDistrict === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.permanentAddress.hkDistrict === type.value ? 'radio-group-box-label-active' : ''">
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6"></div>


      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'Province' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.permanentAddress.province" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'CountryFNA' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.permanentAddress.country" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'PostalCode' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.permanentAddress.postalCode" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box lh-32">
          {{'ForAddressOutsideHongKongOnly' | translate}}
        </div>
      </div>

    </div>

    <div nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="12">
        <div class="label-text">{{'Remarks' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.remark" />
        </div>
      </div>
    </div>
  </div>

  <div class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">{{'OccupationInfo' | translate}}</div>
    <div nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'OccupationTitle/Position' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.position" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'BusinessNature/Industry' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.industry" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'NameEmployer' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.employerName" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'EmploymentStatus' | translate}}</div>
        <div class="value-box">
          <nz-select [(ngModel)]="basicInfo.employment" nzAllowClear
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [nzPlaceHolder]="'PleaseChoose' | translate"
                     class="value-box-select"
                     [nzShowSearch]="false">
            <nz-option *ngFor="let option of employmentOptions"
                       [nzLabel]="option.label" [nzValue]="option.value">
            </nz-option>
          </nz-select>
        </div>
      </div>
    </div>

    <div class="basic-info-card-title" style="margin-top: 12px;">
      {{'AddressEmployer' | translate}}
      <div class="icon-minus" (click)="companyAddress = !companyAddress">
        <img *ngIf="companyAddress" alt="" width="20"
             src="assets/images/icon_minus.svg">
        <img *ngIf="!companyAddress" alt="" width="20"
             src="assets/images/icon_plus.svg">
      </div>
      <div *ngIf="companyAddress" class="icon-minus" (click)="clearEmployerAddress()">
        <img alt="" width="20" src="assets/images/icon_address_clear.svg">
      </div>
    </div>
    <div *ngIf="companyAddress" nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Room/Flat' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.employerAddress.room" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Floor' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.employerAddress.floor" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Block/Building' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.employerAddress.block" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <!--        <div class="label-text">{{'NameEstate/Court' | translate}}</div>-->
        <!--        <div class="value-box">-->
        <!--          <input nz-input class="value-box-input"-->
        <!--                 [placeholder]="'PleaseEnter' | translate"-->
        <!--                 [(ngModel)]="basicInfo.employerAddress.court" />-->
        <!--        </div>-->
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NoNameStreet/Road' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.employerAddress.road" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'District' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.employerAddress.district" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.employerAddress.hkDistrict" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of fnaHkDistrictOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of fnaHkDistrictOptions"
                 class="radio-group-box-label"
                 (click)="employerAddresshk(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.employerAddress.hkDistrict === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.employerAddress.hkDistrict === type.value ? 'radio-group-box-label-active' : ''">
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6"></div>


      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'Province' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.employerAddress.province" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'CountryFNA' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.employerAddress.country" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'PostalCode' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.employerAddress.postalCode" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box lh-32">
          {{'ForAddressOutsideHongKongOnly' | translate}}
        </div>
      </div>

    </div>

    <div nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'AverageMonthlySalary' | translate}}</div>
        <div class="value-box">
          <nz-input-number class="value-box-input-number"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [nzParser]="parserValue"
                           [nzFormatter]="formatterValue"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [(ngModel)]="basicInfo.averageMonthlySalary">
          </nz-input-number>
        </div>
      </div>

      <div nz-col [nzSpan]="8">
        <div class="label-text"
             [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'required-after' : ''">
          {{'ExpectedRetirementAge' | translate}}
        </div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.intendedRetirement" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of fnaRetirementOptions"-->
          <!--                   [style]=" type.value === 'OTHERS' ? 'flex: 2;' : ''" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of fnaRetirementOptions"
                 class="radio-group-box-label"
                 (click)="intendedRetirementChange(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.intendedRetirement === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.intendedRetirement === type.value ? 'radio-group-box-label-active' : ''"
                 [style]="type.value === 'OTHERS' ? 'flex: 2;' : ''">
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="basicInfo.intendedRetirement === 'OTHERS'" nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.retirementAdditional" />
        </div>
      </div>
    </div>
  </div>

  <div class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">{{'FamilySituation' | translate}}</div>
    <div nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="3">
        <div class="label-text">{{'FatherAge' | translate}}</div>
        <div class="value-box">
          <nz-input-number class="value-box-input-number"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [(ngModel)]="basicInfo.familySituationFatherAge"></nz-input-number>
        </div>
      </div>

      <div nz-col [nzSpan]="3">
        <div class="label-text">{{'Dependent' | translate}}</div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.familySituationFatherDependents" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of yesOrNoOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of yesOrNoOptions"
                 class="radio-group-box-label"
                 (click)="fatherDependentsChange(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.familySituationFatherDependents === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.familySituationFatherDependents === type.value ? 'radio-group-box-label-active' : ''"
            >
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="3">
        <div class="label-text">{{'MotherAge' | translate}}</div>
        <div class="value-box">
          <nz-input-number class="value-box-input-number"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [(ngModel)]="basicInfo.familySituationMotherAge"></nz-input-number>
        </div>
      </div>

      <div nz-col [nzSpan]="3">
        <div class="label-text">{{'Dependent' | translate}}</div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.familySituationMotherDependents" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of yesOrNoOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of yesOrNoOptions"
                 class="radio-group-box-label"
                 (click)="motherDependentsChange(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.familySituationMotherDependents === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.familySituationMotherDependents === type.value ? 'radio-group-box-label-active' : ''"
            >
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="3">
        <div class="label-text">{{'SpouseAge' | translate}}</div>
        <div class="value-box">
          <nz-input-number class="value-box-input-number"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [(ngModel)]="basicInfo.familySituationSpouseAge"></nz-input-number>
        </div>
      </div>

      <div nz-col [nzSpan]="3">
        <div class="label-text">{{'Dependent' | translate}}</div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.familySituationSpouseDependents" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of yesOrNoOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of yesOrNoOptions"
                 class="radio-group-box-label"
                 (click)="spouseDependentsChange(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.familySituationSpouseDependents === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.familySituationSpouseDependents === type.value ? 'radio-group-box-label-active' : ''"
            >
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6"></div>

      <div nz-col [nzSpan]="3">
        <div class="label-text">{{'ChildrenTotal' | translate}}</div>
        <div class="value-box">
          <nz-input-number class="value-box-input-number"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [(ngModel)]="basicInfo.familySituationChidrenTotal">
          </nz-input-number>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'ChildrenAge' | translate}}</div>
        <div style="display: flex;align-items: center;">
          <div class="value-box">
            <nz-input-number class="value-box-input-number"
                             [nzPlaceHolder]="'PleaseEnter' | translate"
                             [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                             [(ngModel)]="basicInfo.familySituationChidrenAgeStart">
            </nz-input-number>
          </div>
          <div style="margin: 0 4px;">{{'To' | translate}}</div>
          <div class="value-box">
            <nz-input-number class="value-box-input-number"
                             [nzPlaceHolder]="'PleaseEnter' | translate"
                             [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                             [(ngModel)]="basicInfo.familySituationChidrenAgeEnd">
            </nz-input-number>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="3">
        <div class="label-text">{{'Dependent' | translate}}</div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.familySituationChidrenDependents" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of yesOrNoOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of yesOrNoOptions"
                 class="radio-group-box-label"
                 (click)="chidrenDependentsChange(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.familySituationChidrenDependents === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.familySituationChidrenDependents === type.value ? 'radio-group-box-label-active' : ''"
            >
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">{{'ExistingCoverageCMG' | translate}}</div>
    <div nz-row [nzGutter]="16" style="padding-right: 8px;align-items: end;">
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'LifeInsuranceSumInsured' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.lifeInsuranceSumInsured" />
          <!--          <nz-input-number class="value-box-input-number"-->
          <!--                           [nzParser]="parserValue"-->
          <!--                           [nzFormatter]="formatterValue"-->
          <!--                           [nzPlaceHolder]="'PleaseEnter' | translate"-->
          <!--                           [(ngModel)]="basicInfo.lifeInsuranceSumInsured">-->
          <!--          </nz-input-number>-->
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'CriticalIllnessSumInsured' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.criticalIllnessInsuranceSumInsured" />
          <!--          <nz-input-number class="value-box-input-number"-->
          <!--                           [nzPlaceHolder]="'PleaseEnter' | translate"-->
          <!--                           [nzParser]="parserValue"-->
          <!--                           [nzFormatter]="formatterValue"-->
          <!--                           [(ngModel)]="basicInfo.criticalIllnessInsuranceSumInsured">-->
          <!--          </nz-input-number>-->
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'AccidentalInsuranceSumInsured' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.accidentalInsuranceSumInsured" />
          <!--          <nz-input-number class="value-box-input-number"-->
          <!--                           [nzPlaceHolder]="'PleaseEnter' | translate"-->
          <!--                           [nzParser]="parserValue"-->
          <!--                           [nzFormatter]="formatterValue"-->
          <!--                           [(ngModel)]="basicInfo.accidentalInsuranceSumInsured">-->
          <!--          </nz-input-number>-->
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'MedicalInsuranceSumInsured/Type' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.medicalInsuranceType" />
        </div>
      </div>
    </div>

    <div class="basic-info-card-title" style="margin-top: 12px;">
      {{'OtherExistingCoverageCMG' | translate}}
      <div class="icon-minus" (click)="otherExisting = !otherExisting">
        <img *ngIf="otherExisting" alt="" width="20"
             src="assets/images/icon_minus.svg">
        <img *ngIf="!otherExisting" alt="" width="20"
             src="assets/images/icon_plus.svg">
      </div>
    </div>

    <div *ngIf="otherExisting" nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="24">
        <div class="label-text"></div>
        <div class="value-box">
            <textarea
              style="padding-top: 4px;resize: none;"
              nz-input class="value-box-input"
              [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
              [(ngModel)]="basicInfo.otherExistingCoverage"
              [placeholder]="'PleaseEnter' | translate"
              [nzAutosize]="{minRows: 4, maxRows: 4}"
            ></textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="basic-info-card">
    <div class="basic-info-card-title" style="margin-bottom: 10px;color: #333333;">{{'FinancialInformation' | translate}}</div>
    <div style="display: flex;justify-content: center;">
      <div class="financial-info-box" style="padding-right: 18px;border-right: 1px dashed #D9D9D9;">
        <div class="financial-info-title"
             [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'required-after' : ''">
          {{'Past2YearsAverageMonthlyIncomes' | translate}}
        </div>
        <div class="row-box">
          <div class="col-box">{{'Salary/Commission/Bonus' | translate}}</div>
          <div class="col-box">
            <!--            <nz-input-group [nzSuffix]="suffixIconSearch" class="value-box-input">-->
            <!--              <input type="number" nz-input [placeholder]="'PleaseEnter' | translate"-->
            <!--                     [(ngModel)]="basicInfo.salaryCommissionBonusAmount"-->
            <!--                     (change)="totalAverageMonthlyIncomes()"/>-->
            <!--            </nz-input-group>-->
            <!--            <ng-template #suffixIconSearch>-->
            <!--              <span class="suffix-text">HK$</span>-->
            <!--            </ng-template>-->
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="basicInfo.salaryCommissionBonusAmount"
                               (ngModelChange)="totalAverageMonthlyIncomes()">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>

        <div class="row-box">
          <div class="col-box">{{'RentalIncome' | translate}}</div>
          <div class="col-box">
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                               [(ngModel)]="basicInfo.rentalIncomeAmount"
                               (ngModelChange)="totalAverageMonthlyIncomes()">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>

        <div class="row-box">
          <div class="col-box">{{'OtherIncomes' | translate}}</div>
          <div class="col-box">
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                               [(ngModel)]="basicInfo.otherIncomesAmount"
                               (ngModelChange)="totalAverageMonthlyIncomes()">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>

        <div class="row-box">
          <div class="col-box"></div>
          <div class="col-box">
            <input nz-input class="value-box-input"
                   [placeholder]="'CMGQuestion8'  |translate"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [(ngModel)]="basicInfo.otherIncomesAdditional" />
          </div>
        </div>

        <div class="row-box">
          <div class="col-box text-bold">{{'TotalAverageMonthlyIncomes' | translate}}</div>
          <div class="col-box">
            <div class="group-box" style="background: #F6F6F6;">
              <nz-input-number class="group-box-input-number group-box-input-number-disabled"
                               [nzPlaceHolder]="'AutomaticCalculation' | translate"
                               [disabled]="true"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="basicInfo.totalAverageMonthlyIncomes"
                               (ngModelChange)="totalAverageMonthlyIncomes()">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>

      </div>

      <div class="financial-info-box" style="padding-left: 18px;">
        <div class="financial-info-title"
             [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'required-after' : ''">
          {{'Past2YearsAverageMonthlyExpenses' | translate}}
        </div>
        <div class="row-box">
          <div class="col-box">{{'PersonalExpenses' | translate}}</div>
          <div class="col-box">
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="basicInfo.personalExpensesAmount"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                               (ngModelChange)="totalAverageMonthlyExpenses()">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>

        <div class="row-box">
          <div class="col-box">{{'HouseholdExpenses' | translate}}</div>
          <div class="col-box">
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                               [(ngModel)]="basicInfo.householdExpensesAmount"
                               (ngModelChange)="totalAverageMonthlyExpenses()">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>

        <div class="row-box">
          <div class="col-box">{{'Mortgages/LoansRepayment' | translate}}</div>
          <div class="col-box">
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="basicInfo.mortgagesLoansRepaymentAmount"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                               (ngModelChange)="totalAverageMonthlyExpenses()">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>

        <div class="row-box">
          <div class="col-box">{{'Insurance' | translate}}</div>
          <div class="col-box">
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="basicInfo.insuranceAmount"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                               (ngModelChange)="totalAverageMonthlyExpenses()">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>

        <div class="row-box">
          <div class="col-box">{{'OtherExpenses' | translate}}</div>
          <div class="col-box">
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="basicInfo.otherExpensesAmount"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                               (ngModelChange)="totalAverageMonthlyExpenses()">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>

        <div class="row-box">
          <div class="col-box"></div>
          <div class="col-box">
            <input nz-input class="value-box-input"
                   [placeholder]="'CMGQuestion8'  |translate"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [(ngModel)]="basicInfo.otherExpensesAdditional" />
          </div>
        </div>

        <div class="row-box" style="margin: 0;">
          <div class="col-box text-bold">{{'TotalAverageMonthlyExpenses' | translate}}</div>
          <div class="col-box">
            <div class="group-box" style="background-color: #F6F6F6;">
              <nz-input-number class="group-box-input-number group-box-input-number-disabled"
                               [nzPlaceHolder]="'AutomaticCalculation' | translate"
                               [disabled]="true"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="basicInfo.totalAverageMonthlyExpenses">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <div class="financial-info-box" style="padding-right: 18px;border: none;"></div>

      <div class="financial-info-box" style="padding: 0 0 0 18px;border: none;">
        <div class="row-box" style="margin: 10px 0 0;">
          <div class="col-box text-bold">{{'EstimatedMonthlySurplus/DeficitCMG' | translate}}</div>
          <div class="col-box">
            <div class="group-box" style="background-color: #F6F6F6;">
              <nz-input-number class="group-box-input-number group-box-input-number-disabled"
                               [nzPlaceHolder]="'AutomaticCalculation' | translate"
                               [disabled]="true"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                               [(ngModel)]="basicInfo.estimatedMonthlySurplusDeficit">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="basic-info-card">
    <div class="basic-info-card-title" style="margin-bottom: 10px;color: #333333;">{{'NewAssets' | translate}}</div>
    <div style="display: flex;justify-content: center;">
      <div style="width: 100%;flex: 1;padding-right: 18px;border-right: 1px solid #D9D9D9;">
        <div class="financial-info-box">
          <div class="financial-info-title">{{'LiquidAssetsCMG' | translate}}</div>
          <div class="row-box">
            <div class="col-box">{{'CashDeposit' | translate}}</div>
            <div class="col-box">
              <div class="group-box"
                   [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
                <nz-input-number class="group-box-input-number"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="basicInfo.cashDepositAmount"
                                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                                 (ngModelChange)="totalLiquidAssets()">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>

          <div class="row-box">
            <div class="col-box">{{'CurrentInvestmentEquities/Bonds' | translate}}</div>
            <div class="col-box">
              <div class="group-box"
                   [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
                <nz-input-number class="group-box-input-number"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="basicInfo.currentInvestmentAmount"
                                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                                 (ngModelChange)="totalLiquidAssets()">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>

          <div class="row-box">
            <div class="col-box">{{'OtherLiquidAssets' | translate}}</div>
            <div class="col-box">
              <div class="group-box"
                   [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
                <nz-input-number class="group-box-input-number"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="basicInfo.otherLiquidAssetsAmount"
                                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                                 (ngModelChange)="totalLiquidAssets()">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>

          <div class="row-box">
            <div class="col-box"></div>
            <div class="col-box">
              <input nz-input class="value-box-input"
                     [placeholder]="'CMGQuestion8'  |translate"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [(ngModel)]="basicInfo.otherLiquidAssetsAdditional" />
            </div>
          </div>

          <div class="row-box">
            <div class="col-box text-bold">{{'TotalLiquidAssets' | translate}}</div>
            <div class="col-box">
              <div class="group-box" style="background-color: #F6F6F6;">
                <nz-input-number class="group-box-input-number group-box-input-number-disabled"
                                 [nzPlaceHolder]="'AutomaticCalculation' | translate"
                                 [disabled]="true"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="basicInfo.totalLiquidAssets"
                                 (ngModelChange)="totalLiquidAssets()">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div style="width: 100%;flex: 1;padding-left: 18px;">
        <div class="financial-info-box" style="height: 100%;">
          <div class="financial-info-title">{{'Non‐LiquidAssetsCMG' | translate}}</div>
          <div class="row-box">
            <div class="col-box">{{'Properties' | translate}}</div>
            <div class="col-box">
              <div class="group-box"
                   [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
                <nz-input-number class="group-box-input-number"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="basicInfo.propertiesAmount"
                                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                                 (ngModelChange)="totalNonLiquidAssets()">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>

          <div class="row-box">
            <div class="col-box">{{'OtherNon‐LiquidAssetsCompanyShares' | translate}}</div>
            <div class="col-box">
              <div class="group-box"
                   [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
                <nz-input-number class="group-box-input-number"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="basicInfo.otherNonLiquidAssetsAmount"
                                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                                 (ngModelChange)="totalNonLiquidAssets()">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>

          <div class="row-box">
            <div class="col-box"></div>
            <div class="col-box">
              <input nz-input class="value-box-input"
                     [placeholder]="'CMGQuestion8'  |translate"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [(ngModel)]="basicInfo.otherNonLiquidAssetsAdditional" />
            </div>
          </div>

          <div class="row-box">
            <div class="col-box text-bold">{{'TotalNon‐LiquidAssets' | translate}}</div>
            <div class="col-box">
              <div class="group-box" style="background-color: #F6F6F6;">
                <nz-input-number class="group-box-input-number group-box-input-number-disabled"
                                 [nzPlaceHolder]="'AutomaticCalculation' | translate"
                                 [disabled]="true"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="basicInfo.totalNonLiquidAssets">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <div style="width: 100%;flex: 1;padding-right: 18px;border-right: 1px solid #D9D9D9;padding-top: 15px;">
        <div class="financial-info-box" style="border: none;padding: 0;">
          <div class="financial-info-title">{{'LiabilitiesCMG' | translate}}</div>
          <div class="row-box">
            <div class="col-box">{{'MortgageLoans' | translate}}</div>
            <div class="col-box">
              <div class="group-box"
                   [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
                <nz-input-number class="group-box-input-number"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="basicInfo.mortgageLoansAmount"
                                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                                 (ngModelChange)="totalLiabilities()">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>

          <div class="row-box">
            <div class="col-box">{{'Personal/CreditCardLoans' | translate}}</div>
            <div class="col-box">
              <div class="group-box"
                   [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
                <nz-input-number class="group-box-input-number"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                                 [(ngModel)]="basicInfo.personalCreditCardLoansAmount"
                                 (ngModelChange)="totalLiabilities()">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>

          <div class="row-box">
            <div class="col-box">{{'OtherLiabilities' | translate}}</div>
            <div class="col-box">
              <div class="group-box"
                   [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? '#FAFBFD' : ''">
                <nz-input-number class="group-box-input-number"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                                 [(ngModel)]="basicInfo.otherLiabilitiesAmount"
                                 (ngModelChange)="totalLiabilities()">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>

          <div class="row-box">
            <div class="col-box"></div>
            <div class="col-box">
              <input nz-input class="value-box-input"
                     [placeholder]="'CMGQuestion8'  |translate"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [(ngModel)]="basicInfo.otherLiabilitiesAdditional" />
            </div>
          </div>

          <div class="row-box" style="margin: 0;">
            <div class="col-box text-bold">{{'TotalLiabilities' | translate}}</div>
            <div class="col-box">
              <div class="group-box" style="background-color: #F6F6F6;">
                <nz-input-number class="group-box-input-number group-box-input-number-disabled"
                                 [nzPlaceHolder]="'AutomaticCalculation' | translate"
                                 [disabled]="true"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="basicInfo.totalLiabilities">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div style="width: 100%;flex: 1;padding-left: 18px;padding-top: 15px;">
        <div class="financial-info-box" style="border: none;padding: 0;">

          <div class="row-box">
            <div class="col-box text-bold">
              {{'EstimatedNetAssetsCMG' | translate}}
            </div>
            <div class="col-box">
              <div class="group-box" style="background-color: #F6F6F6;">
                <nz-input-number class="group-box-input-number group-box-input-number-disabled"
                                 [nzPlaceHolder]="'AutomaticCalculation' | translate"
                                 [disabled]="true"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="basicInfo.estimatedNetAssets">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>

          <div class="row-box">
            <div class="col-box text-bold">
              <span>
                {{'MaximumAffordablePremiumLiquidAssetBasis' | translate}}
              </span>
              <div nz-popover [nzPopoverContent]="'MaximumAffordablePremiumTips' | translate"
                   nzPopoverTrigger="click">
                <img style="margin: 0 4px;cursor: pointer;vertical-align: sub;" alt=""
                     width="16" src="assets/images/formula-tips.svg">
              </div>
            </div>
            <div class="col-box">
              <div class="group-box" style="background-color: #F6F6F6;">
                <nz-input-number class="group-box-input-number group-box-input-number-disabled"
                                 [nzPlaceHolder]="'AutomaticCalculation' | translate"
                                 [disabled]="true"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="basicInfo.maximumAffordablePremium">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>
          <div class="row-box">
            <div class="col-box text-bold">
              <span>
                {{'MaximumAffordablePremiumIncomeBasis' | translate}}
              </span>
              <div nz-popover [nzPopoverContent]="'MaximumAffordablePremiumIncomeBasisTips' | translate"
                   nzPopoverTrigger="click">
                <img style="margin: 0 4px;cursor: pointer;vertical-align: sub;" alt=""
                     width="16" src="assets/images/formula-tips.svg">
              </div>
            </div>
            <div class="col-box">
              <div class="group-box" style="background-color: #F6F6F6;">
                <nz-input-number class="group-box-input-number group-box-input-number-disabled"
                                 [nzPlaceHolder]="'AutomaticCalculation' | translate"
                                 [disabled]="true"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="basicInfo.maximumAffordablePremiumIncome">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">
      {{'AdditionalInformation' | translate}}
      <div class="icon-minus" (click)="otherExistingInsured = !otherExistingInsured">
        <img *ngIf="otherExistingInsured" alt="" width="20"
             src="assets/images/icon_minus.svg">
        <img *ngIf="!otherExistingInsured" alt="" width="20"
             src="assets/images/icon_plus.svg">
      </div>
    </div>

    <div *ngIf="otherExistingInsured" nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="24">
        <div class="label-text"></div>
        <div class="value-box">
            <textarea
              style="padding-top: 4px;resize: none;"
              nz-input class="value-box-input"
              [(ngModel)]="basicInfo.financialAdditionalInformation"
              [placeholder]="'PleaseEnter' | translate"
              [nzAutosize]="{minRows: 4, maxRows: 4}"
            ></textarea>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="basicInfo.relationship !== 'OWN'" class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">
      {{'ClientInformationInsured' | translate}}
      <div class="icon-minus" (click)="basicInsured = !basicInsured">
        <img *ngIf="basicInsured" alt="" width="20"
             src="assets/images/icon_minus.svg">
        <img *ngIf="!basicInsured" alt="" width="20"
             src="assets/images/icon_plus.svg">
      </div>
    </div>
    <div *ngIf="basicInsured" nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div *ngIf="!workbench.hiddenLastName" nz-col [nzSpan]="6">
        <div class="label-text required-after">
          {{'LastNameEnglish' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <input class="value-box-input" nz-input
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.englishLastName" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">
          {{'GivenNameEnglish' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.englishGivenName" />
        </div>
      </div>
      <div *ngIf="!workbench.hiddenLastName" nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'LastNameChinese' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.chineseLastName" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text required-after">{{'GivenNameChinese' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.chineseGivenName" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">
          {{'Gender' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.insured.gender" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of genderOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of genderOptions"
                 class="radio-group-box-label"
                 (click)="insuredGenderChange(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.insured.gender === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.insured.gender === type.value ? 'radio-group-box-label-active' : ''"
            >
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">
          {{'Smoking' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.insured.smoke"-->
          <!--                          nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of smokeOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of smokeOptions"
                 class="radio-group-box-label"
                 (click)="insuredSmokingChange(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.insured.smoke === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.insured.smoke === type.value ? 'radio-group-box-label-active' : ''"
            >
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">
          {{'DateOfBirth' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <div class="value-box-mat-box" style="position: relative;">
            <input nz-input class="value-box-matInput"
                   [placeholder]="'DD/MM/YYYY'"
                   [(ngModel)]="basicInfo.insured.dateOfBirth"
                   (input)="logInsuredClick()"
                   (focus)="onAddInsuredClick()"
                   (keyup.enter)="handleInsuredEndOpenChange()"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   (blur)="handleInsuredEndOpenChange()"/>
            <nz-date-picker #datePickerInsured class="date-picker-row"
                            style="position: absolute;top: 0;z-index: -2;"
                            nzBorderless [ngModel]="insuredBirthdate"
                            (ngModelChange)="onDateInsuredChange($event)"
                            [nzFormat]="'dd/MM/yyyy'"
                            [nzShowTime]="false"
                            [nzShowToday]="false"
                            [nzPlaceHolder]="''"
                            (nzOnOpenChange)="handleInsuredOpenChange($event)"></nz-date-picker>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'BirthplaceCMG' | translate}}</div>
        <div class="value-box">
          <app-filter-popover [nationality]="basicInfo.insured.countryOfBirth"
                              [isDisabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                              [modeType]="'String'"
                              (nationalitySelected)="countryInsuredChange($event)">
          </app-filter-popover>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Nationality' | translate}}</div>
        <div class="value-box">
          <app-filter-popover [nationality]="basicInfo.insured.nationality"
                              [isDisabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                              [modeType]="'String'"
                              (nationalitySelected)="nationalityInsuredChange($event)">
          </app-filter-popover>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'IDNo/BirthCertNo' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.insured.idCard" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'PassportNo' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.insured.passport" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Marriage' | translate}}</div>
        <div class="value-box">
          <nz-select [(ngModel)]="basicInfo.insured.maritalStatus" nzAllowClear
                     [nzPlaceHolder]="'PleaseChoose' | translate"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     class="value-box-select"
                     [nzShowSearch]="false">
            <nz-option *ngFor="let option of maritalStatusOptions"
                       [nzLabel]="option.label" [nzValue]="option.value">
            </nz-option>
          </nz-select>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Education' | translate}}</div>
        <div class="value-box">
          <nz-select [(ngModel)]="basicInfo.insured.education" nzAllowClear
                     [nzPlaceHolder]="'PleaseChoose' | translate"
                     class="value-box-select"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [nzShowSearch]="false">
            <nz-option *ngFor="let option of educationOptions"
                       [nzLabel]="option.label" [nzValue]="option.value">
            </nz-option>
          </nz-select>
        </div>
      </div>

      <div *ngIf="basicInfo.insured.education === 'OTHERS'" nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'OthersPleaseSpecify' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.othersEducationAddition" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'MobilePhoneNo' | translate}}</div>
        <div class="value-box">
          <div style="display: flex;border: 1px solid #D9D9D9;border-radius: 4px;">
            <app-filter-popover [nationality]="basicInfo.insured.mobileArea"
                                style="width: 80px;"
                                [modeType]="'Number'"
                                [isBorder]="false"
                                [isDisabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                (nationalitySelected)="mobileInsuredChange($event)">
            </app-filter-popover>
            <input nz-input class="value-box-input"
                   style="border: none;padding-left: 0;flex: 1"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [placeholder]="'PleaseEnter' | translate"
                   [(ngModel)]="basicInfo.insured.mobile" />
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'HomeNo' | translate}}</div>
        <div class="value-box">
          <div style="display: flex;border: 1px solid #D9D9D9;border-radius: 4px;">
            <app-filter-popover [nationality]="basicInfo.insured.homePhoneArea"
                                style="width: 80px;"
                                [isDisabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                [modeType]="'Number'"
                                [isBorder]="false"
                                (nationalitySelected)="homePhoneInsuredChange($event)">
            </app-filter-popover>
            <input nz-input class="value-box-input"
                   style="border: none;padding-left: 0;flex: 1"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [placeholder]="'PleaseEnter' | translate"
                   [(ngModel)]="basicInfo.insured.homePhone" />
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
      </div>
    </div>
    <div *ngIf="basicInsured" class="basic-info-card-title" style="margin-top: 12px;">
      {{'ResidentialAddress' | translate}}
      <div class="icon-minus" (click)="residentialInsured = !residentialInsured">
        <img *ngIf="residentialInsured" alt="" width="20"
             src="assets/images/icon_minus.svg">
        <img *ngIf="!residentialInsured" alt="" width="20"
             src="assets/images/icon_plus.svg">
      </div>
      <span style="color: rgba(0,120,212,0.85);margin-left: 9px;cursor: pointer;font-weight: normal;" appThrottleClick (throttleClick)="copyAddress()">{{'QuicklyAddressTips' | translate}}</span>
      <div *ngIf="residentialInsured" class="icon-minus" (click)="clearInsuredResidentialAddress()">
        <img alt="" width="20" src="assets/images/icon_address_clear.svg">
      </div>
    </div>
    <div *ngIf="basicInsured && residentialInsured" nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Room/Flat' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.residentialAddress.room" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Floor' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.residentialAddress.floor" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Block/Building' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.residentialAddress.block" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NameEstate/Court' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.residentialAddress.court" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NoNameStreet/Road' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.residentialAddress.road" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'District' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.residentialAddress.district" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.insured.residentialAddress.hkDistrict" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of fnaHkDistrictOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of fnaHkDistrictOptions"
                 class="radio-group-box-label"
                 (click)="insuredResidentialAddresshk(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.insured.residentialAddress.hkDistrict === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.insured.residentialAddress.hkDistrict === type.value ? 'radio-group-box-label-active' : ''"
            >
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6"></div>


      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'Province' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.residentialAddress.province" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'CountryFNA' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.residentialAddress.country" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'PostalCode' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.residentialAddress.postalCode" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box lh-32">
          {{'ForAddressOutsideHongKongOnly' | translate}}
        </div>
      </div>

    </div>

    <div *ngIf="basicInsured" class="basic-info-card-title" style="margin-top: 12px;">
      {{'PostalAddress' | translate}}
      <div class="icon-minus" (click)="postalInsured = !postalInsured">
        <img *ngIf="postalInsured" alt="" width="20"
             src="assets/images/icon_minus.svg">
        <img *ngIf="!postalInsured" alt="" width="20"
             src="assets/images/icon_plus.svg">
      </div>
      <span style="color: rgba(0,120,212,0.85);margin-left: 9px;cursor: pointer;font-weight: normal;" appThrottleClick (throttleClick)="copyPcAddress()">{{'QuicklyAddressCopy' | translate}}</span>
      <div *ngIf="postalInsured" class="icon-minus" (click)="clearInsuredCorrespondenceAddress()">
        <img alt="" width="20" src="assets/images/icon_address_clear.svg">
      </div>
      <span class="tips-span">{{'IfDifferentResidentialAddressTips' | translate}}</span>
    </div>
    <div *ngIf="basicInsured && postalInsured" nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Room/Flat' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.correspondenceAddress.room" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Floor' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.correspondenceAddress.floor" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Block/Building' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.correspondenceAddress.block" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NameEstate/Court' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.correspondenceAddress.court" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NoNameStreet/Road' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.correspondenceAddress.road" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'District' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.correspondenceAddress.district" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.insured.correspondenceAddress.hkDistrict" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of fnaHkDistrictOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of fnaHkDistrictOptions"
                 class="radio-group-box-label"
                 (click)="insuredCorrespondenceAddresshk(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.insured.correspondenceAddress.hkDistrict === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.insured.correspondenceAddress.hkDistrict === type.value ? 'radio-group-box-label-active' : ''"
            >
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6"></div>


      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'Province' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.correspondenceAddress.province" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'CountryFNA' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.correspondenceAddress.country" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'PostalCode' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.correspondenceAddress.postalCode" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box lh-32">
          {{'ForAddressOutsideHongKongOnly' | translate}}
        </div>
      </div>

    </div>

    <div *ngIf="basicInsured" class="basic-info-card-title" style="margin-top: 12px;">
      {{'PermanentAddress' | translate}}
      <div class="icon-minus" (click)="permanentInsured = !permanentInsured">
        <img *ngIf="permanentInsured" alt="" width="20"
             src="assets/images/icon_minus.svg">
        <img *ngIf="!permanentInsured" alt="" width="20"
             src="assets/images/icon_plus.svg">
      </div>
      <div *ngIf="permanentInsured" class="icon-minus" (click)="clearInsuredPermanentAddress()">
        <img alt="" width="20" src="assets/images/icon_address_clear.svg">
      </div>
      <span class="tips-span">{{'IfDifferentResidentialAddressTips' | translate}}</span>
    </div>
    <div *ngIf="basicInsured && permanentInsured" nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Room/Flat' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.permanentAddress.room" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Floor' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.permanentAddress.floor" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Block/Building' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.permanentAddress.block" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NameEstate/Court' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.permanentAddress.court" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NoNameStreet/Road' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.permanentAddress.road" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'District' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.permanentAddress.district" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.insured.permanentAddress.hkDistrict" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of fnaHkDistrictOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of fnaHkDistrictOptions"
                 class="radio-group-box-label"
                 (click)="insuredPermanentAddresshk(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.insured.permanentAddress.hkDistrict === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.insured.permanentAddress.hkDistrict === type.value ? 'radio-group-box-label-active' : ''"
            >
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6"></div>


      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'Province' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.permanentAddress.province" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'CountryFNA' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.permanentAddress.country" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'PostalCode' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.permanentAddress.postalCode" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box lh-32">
          {{'ForAddressOutsideHongKongOnly' | translate}}
        </div>
      </div>

    </div>

    <div *ngIf="basicInsured" nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="12">
        <div class="label-text">{{'Remarks' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.insured.remark" />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="basicInfo.relationship !== 'OWN' && basicInsured" class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">{{'OccupationInformationInsured' | translate}}</div>
    <div nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'OccupationTitle/Position' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.insured.position" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'BusinessNature/Industry' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.insured.industry" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NameEmployer/School' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="basicInfo.insured.employerName" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'AverageMonthlySalary' | translate}}</div>
        <div class="value-box">
          <nz-input-number class="value-box-input-number"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [nzParser]="parserValue"
                           [nzFormatter]="formatterValue"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [(ngModel)]="basicInfo.insured.averageMonthlySalary">
          </nz-input-number>
        </div>
      </div>
    </div>

    <div class="basic-info-card-title" style="margin-top: 12px;">
      {{'AddressEmployer' | translate}}
      <div class="icon-minus" (click)="companyAddressInsured = !companyAddressInsured">
        <img *ngIf="companyAddressInsured" alt="" width="20"
             src="assets/images/icon_minus.svg">
        <img *ngIf="!companyAddressInsured" alt="" width="20"
             src="assets/images/icon_plus.svg">
      </div>

      <div *ngIf="companyAddressInsured" class="icon-minus" (click)="clearInsuredEmployerAddress()">
        <img alt="" width="20" src="assets/images/icon_address_clear.svg">
      </div>
    </div>
    <div *ngIf="companyAddressInsured" nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Room/Flat' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.employerAddress.room" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Floor' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.employerAddress.floor" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'Block/Building' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.employerAddress.block" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <!--        <div class="label-text">{{'NameEstate/Court' | translate}}</div>-->
        <!--        <div class="value-box">-->
        <!--          <input nz-input class="value-box-input"-->
        <!--                 [placeholder]="'PleaseEnter' | translate"-->
        <!--                 [(ngModel)]="basicInfo.insured.employerAddress.court" />-->
        <!--        </div>-->
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'NoNameStreet/Road' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.employerAddress.road" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'District' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.employerAddress.district" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box">
          <!--          <nz-radio-group [(ngModel)]="basicInfo.insured.employerAddress.hkDistrict" nzButtonStyle="solid"-->
          <!--                          class="value-box-radio-group">-->
          <!--            <label nz-radio-button *ngFor="let type of fnaHkDistrictOptions"-->
          <!--                   style="" class="value-box-radio-group-label"-->
          <!--                   [nzValue]="type.value">-->
          <!--              {{type.label}}-->
          <!--            </label>-->
          <!--          </nz-radio-group>-->
          <div class="radio-group-box"
               [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? 'radio-group-box-label-disabled' : ''">
            <div *ngFor="let type of fnaHkDistrictOptions"
                 class="radio-group-box-label"
                 (click)="insuredEmployerAddresshk(type.value)"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo ? basicInfo.insured.employerAddress.hkDistrict === type.value ? 'radio-group-box-label-active-disabled' : '' : basicInfo.insured.employerAddress.hkDistrict === type.value ? 'radio-group-box-label-active' : ''"
            >
              <div>{{type.label}}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="6"></div>


      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'Province' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.employerAddress.province" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'CountryFNA' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.employerAddress.country" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text">#{{'PostalCode' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="basicInfo.insured.employerAddress.postalCode" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text h-20"></div>
        <div class="value-box lh-32">
          {{'ForAddressOutsideHongKongOnly' | translate}}
        </div>
      </div>

    </div>
  </div>

</div>
