<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 40px">
      {{'InspectionDetail' | translate}}
    </div>

  <div class="section-title" style="margin-top: 20px">
    <span style="margin-left: 8px">{{'InspectionBrief' | translate}}</span>
  </div>

  <nz-table
    style="margin: 30px 0 0 0"
            [nzBordered]="false"
            [nzOuterBordered]="true"
            [nzShowPagination]="true"
            [nzFrontPagination]="false"
            [nzData]="info.list"
            [nzPageIndex]="info.pageNum"
            [nzPageSize]="info.pageSize"
            [nzTotal]="info.total"
            [nzTotal]="info.list?.length"
            [nzFooter]="tableFooter"
            [nzScroll]="{ x: '1150px'}"
            (nzPageIndexChange)="onSearch($event)">
            <ng-template #tableFooter>
              <div class="table-title">
                {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ info.total }}</span>
              </div>
            </ng-template>
    <thead>
      <tr>
        <th>
          {{ 'CustomCode' | translate }}
        </th>
        <th>
          {{ 'ClientName' | translate }}
        </th>
        <th>
          {{ 'InspectionTime' | translate }}
        </th>
        <th>
          {{ 'Type' | translate }}
        </th>
        <th>
          {{'InspectionResult' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor='let item of info.list' appThrottleClick (throttleClick)="omAMlSummaryListDetail(item.id)">
        <td>
          {{item.customCode || '-'}}
        </td>
        <td>
          <span>
          {{item.name}}
        </span>
        </td>
        <td>
          {{item.newestMonitorDate | date:"yyyy-MM-dd" }}
        </td>
        <td>
          {{item.type | metadataTranslate: 'amlRecordType'}}
        </td>
        <td>
          {{item.monitorStatus | metadataTranslate: 'amlMonitorStatus'}}
        </td>
      </tr>
    </tbody>
  </nz-table>
  </nz-spin>
</div>
