import { Pipe, PipeTransform } from '@angular/core';
import { MetadataService } from '../service/metadata.service';

@Pipe({
  name: 'metadataTranslate',
  pure: false // required to update the value when the promise is resolved
})
export class MetadataTranslatePipe implements PipeTransform {

  constructor(private metadataService: MetadataService) {

  }

  transform(value: any, ...args: any[]): any {
    if (Array.isArray(value)) {
      const result: any[] = [];
      for (const v of value) {
        result.push(this.metadataService.translate(args[0] ?? '', v));
      }
      return result;
    } else {
      return this.metadataService.translate(args[0] ?? '', value);
    }
  }

}
