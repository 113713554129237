import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FnaModuleAndAnswer, FnaQuestion} from '../../fna/fna-types';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {MetadataService} from '../../shared/service/metadata.service';
import { NewFnaDetail } from '../../new-fna/new-fna-types';

@Component({
  selector: 'app-zuu-template-question',
  templateUrl: './zuu-template-question.component.html',
  styleUrls: ['./zuu-template-question.component.less']
})
export class ZuuTemplateQuestionComponent implements OnInit {
  @Input()
  fnaQuestion: FnaQuestion;
  checked = true;
  otherExisting = true;
  policy2 = false;
  rentalIncomeAmount: string;
  currencyOptions: PropertySelectOption[] = [];
  @Input()
  isDisabled: boolean;
  @Input()
  isCMGQuestion6: boolean;
  @Input()
  fnaInfo: NewFnaDetail = new NewFnaDetail();

  @Output()
  subEvent: EventEmitter<FnaModuleAndAnswer> = new EventEmitter<FnaModuleAndAnswer>();

  @Output()
  questionEChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  fnaChange: EventEmitter<FnaQuestion> = new EventEmitter<FnaQuestion>();
  constructor(private metadataService: MetadataService) { }

  ngOnInit(): void {
    console.log(this.fnaQuestion);
    const currencyTypes = this.metadataService.values('currency');
    for (const fnaGenderType of currencyTypes) {
      this.currencyOptions.push(new PropertySelectOption(fnaGenderType.value, fnaGenderType.key));
    }
  }

  FNA02log(value: boolean, info: FnaQuestion, infoNo: string, ids: string): void {
    if (!value) {
      info.answers.forEach(answer => {
        answer.tick = false;
      });
    } else {
      info.answers.forEach(answer => {
        if (ids === answer.key) {
          answer.tick = true;
        } else {
          answer.tick = false;
        }
      });
    }
    console.log(info);

    setTimeout(() => {
      this.fnaChange.emit(info);
    }, 200);
  }

  FNA04log(value: boolean, info: FnaQuestion, infoNo: string): void {
    info.answers.forEach(answer => {
      if (infoNo === answer.key) {
        answer.tick = value;
      }
    });

    setTimeout(() => {
      this.fnaChange.emit(info);
    }, 200);
  }

  FNA05log(value: boolean, info: FnaQuestion, infoNo: string, ids: string): void {
    console.log(info);
    console.log(infoNo);
    console.log(ids);
    if (!value) {
      info.answers.forEach(answer => {
        answer.subQuestions.map(item => {
          if (item.subKey === infoNo) {
            item.answers.map(subInfo => {
              subInfo.tick = false;
              if (subInfo.inputBox && subInfo.inputBox.length) {
                subInfo.inputBox.forEach(subItem => {
                  subItem.value = '';
                });
              }
            });
          }
        });
      });
    } else {
      info.answers.forEach(answer => {
        answer.subQuestions.map(item => {
          if (item.subKey === infoNo) {
            console.log(item);
            item.answers.map(subInfo => {
              console.log(subInfo.key);
              if (ids === subInfo.key) {
                subInfo.tick = true;
              } else {
                subInfo.tick = false;
                if (subInfo.inputBox && subInfo.inputBox.length) {
                  subInfo.inputBox.forEach(subItem => {
                    subItem.value = '';
                  });
                }
              }
            });
          }
        });
      });
    }
    console.log(info);

    setTimeout(() => {
      this.fnaChange.emit(info);
    }, 200);
  }

  parserValue(value: string): string {
    return value.replace(/\$\s?|(,*)/g, '');
  }

  formatterValue(value: number): string {
    if (parseFloat(String(value)).toString() === 'NaN') {
      return '';
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  fnaQuestion1e(e): void {
    if (!e.tick) {
      this.questionEChange.emit('');
    }
  }


}
