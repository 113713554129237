import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BookingInsuredReq,
  BookingOwnerReq,
  ContingentOwner,
  DataDesensitization,
  DataMode,
  Instance
} from '../../../api/types';
import { PropertySelectOption } from '../property-select/property-select.component';
import { MetadataService } from '../../service/metadata.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SecondHolderEditComponent } from '../second-holder-edit/second-holder-edit.component';
import { plainToClass } from 'class-transformer';
import { Booking } from '../../../booking/booking-types';
import { BookingService } from '../../../booking/booking.service';
import { AccountService } from '../../../account/account.service';
import { Workbench } from '../../../workbench/workbench-types';
import { WorkbenchService } from '../../../workbench/workbench.service';

@Component({
  selector: 'app-second-holder-read',
  templateUrl: './second-holder-read.component.html',
  styleUrls: ['./second-holder-read.component.less']
})
export class SecondHolderReadComponent implements OnInit {

  title: string;
  contingentOwner: ContingentOwner = new ContingentOwner(); // 第二持有人快照
  birthdayDate: string;
  dataDesensitization: DataDesensitization = new DataDesensitization();
  modeType = DataMode.OWN;
  relationshipOptions: PropertySelectOption[] = [];
  genderOptions: PropertySelectOption[] = [];
  instance: Instance;

  objectType = 'SecondInsured';
  moduleSource: string;
  loading = false;
  booking: Booking = new Booking();
  @Output()
  secondHolderSaved: EventEmitter<ContingentOwner> = new EventEmitter<ContingentOwner>();
  workbench: Workbench;
  constructor(private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private translate: TranslateService,
              private bookingService: BookingService,
              private workbenchService: WorkbenchService,
              private accountService: AccountService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.instance = this.metadataService.mockInstance;
    this.dataDesensitization = this.accountService.dataDesensitization;
    const relationships = this.metadataService.values('relationship');
    for (const relationship of relationships) {
      this.relationshipOptions.push(new PropertySelectOption(relationship.value, relationship.key));
    }

    const genders = this.metadataService.values('gender');
    for (const gender of genders) {
      this.genderOptions.push(new PropertySelectOption(gender.value, gender.key));
    }
    this.getAge(this.contingentOwner.birthdate);
  }

  dynamicEncryption(keyName): boolean {
    let isShow = false;
    if (!this.dataDesensitization || !this.modeType) {
      return isShow;
    }
    if (this.modeType === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    }
  }

  // 根据日期获取年龄
  getAge(date): any {
    if (!date) {
      return ;
    }
    const dob = new Date(date.replace(/-/g, '/')).getTime();
    if (dob > new Date().getTime()) {
      this.birthdayDate = '0';
      return ;
    }
    // 出生时间 毫秒
    const birthDayTime = new Date( parseInt(date, 0)).getTime();
    // 当前时间 毫秒
    const nowTime = new Date().getTime();
    // 一年毫秒数(365 * 86400000 = 31536000000)
    this.birthdayDate = Math.floor((nowTime - dob) / 31536000000).toString();
    return ;
  }


  onSave(): void {
    console.log('onSave');
    this.translate.get(this.objectType).subscribe(
      (title: any) => {
        const drawerRef = this.drawerService.create<SecondHolderEditComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: SecondHolderEditComponent,
          nzContentParams: {
            title,
            modeType: 'booking',
            objectType: this.objectType,
            contingentOwner: this.booking.contingentOwner ? this.booking.contingentOwner : new ContingentOwner()
          },
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.secondHolderSaved
            .subscribe(
              secondHolderInfo => {
                if (this.objectType === 'SecondHolder') {
                  this.booking.contingentOwner = JSON.parse(JSON.stringify(secondHolderInfo));
                  const bookingOwnerReq = plainToClass(BookingOwnerReq, this.booking, {excludeExtraneousValues: true});
                  this.loading = true;
                  this.bookingService.owner(bookingOwnerReq)
                    .subscribe(
                      progress => {
                        drawerRef.close();
                        this.loading = false;
                        this.loadBookingDetail(this.booking.bookingNo);
                      },
                      error => {
                        this.loading = false;
                      });
                } else {
                  this.booking.contingentInsured = JSON.parse(JSON.stringify(secondHolderInfo));
                  const bookingInsuredReq = plainToClass(BookingInsuredReq, this.booking, {excludeExtraneousValues: true});
                  this.loading = true;
                  this.bookingService.insured(bookingInsuredReq)
                    .subscribe(
                      progress => {
                        drawerRef.close();
                        this.loading = false;
                        this.loadBookingDetail(this.booking.bookingNo);
                      },
                      error => {
                        this.loading = false;
                      });
                }
              }
            );
        });
        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  loadBookingDetail(bookingNo: string): void {
    this.loading = true;
    this.bookingService.detail(bookingNo)
      .subscribe(
        bookingDetail => {
          this.loading = false;
          this.booking = bookingDetail.toBooking();
          this.contingentOwner = this.objectType === 'SecondHolder' ? this.booking.contingentOwner : this.booking.contingentInsured;
          this.secondHolderSaved.emit(this.contingentOwner);
        },
        error => {
          this.loading = false;
        });
  }

}
