import { Component, Input, OnInit } from '@angular/core';
import { CreditRating } from '../../product-types';

@Component({
  selector: 'app-credit-rating',
  templateUrl: './credit-rating.component.html',
  styleUrls: ['./credit-rating.component.less']
})
export class CreditRatingComponent implements OnInit {

  @Input()
  creditRating: CreditRating;

  constructor() { }

  ngOnInit(): void {
  }

}
