<div class="workbench-container">
  <div class="workbench-row">
    <div class="workbench-column" style="width: 40%">
      <div *ngIf="dashboardTotal" class="workbench-block">
        <div style="margin-top: 80px; display: flex; flex-direction: column; align-items: center;">
          <nz-avatar [nzSize]="80" nzIcon="user" [nzSrc]="workbenchService.workbench.avatar"></nz-avatar>
          <div style="margin: 25px 0 0 0; font-size: 20px; font-weight: bold;display: flex;align-items: center;">
            {{ instance.isFoneOrDev() ? passport.accountName + '&nbsp;' + workbenchService.workbench.userName
              : workbenchService.workbench.userName }}
            <nz-tag *ngIf="instance.isBaylightOrDev()" style="margin-left: 5px" nzColor="processing">{{ passport.salesRank ? passport.salesRank : ('NewRich' | translate) }}</nz-tag>
          </div>

          <div *ngIf="instance.isWDF()"
               style="line-height: 20px;display: flex;align-items: center;cursor: pointer;"
               nz-tooltip [nzTooltipTitle]="titleTemplate"
               nzTooltipPlacement="bottom"
               [nzTooltipTrigger]="'click'" [nzTooltipColor]="'#fff'">
            <img alt="" width="20" src="assets/images/home-rank-icon.svg">
            <span style="font-size: 14px; color: #404040; margin-left: 4px;">
              {{ passport.currentRank | metadataTranslate: 'salesRank' }}
            </span>
          </div>

          <ng-template #titleTemplate>
            <div style="color: #404040;">
              <div class="rank-row" style="padding-top: 8px">
                <span class="rank-title">{{ 'CurrentJobLevel' | translate }}</span>
                <span>{{passport.currentRank | metadataTranslate: 'salesRank'}}</span>
              </div>
              <div class="rank-row">
                <span class="rank-title">{{ 'TargetJobLevel' | translate }}</span>
                <span>{{passport.nextRank | metadataTranslate: 'salesRank'}}</span>
              </div>
              <div class="rank-row">
                <span class="rank-title">{{ 'TargetCondition' |translate }}</span>
                <span>{{passport.promotionConditions}}</span>
              </div>
              <div class="rank-row" style="border: none;margin: 0">
                <span class="rank-title">{{ 'RemainingCondition' | translate }}</span>
                <span>{{passport.remainingCondition}}</span>
              </div>
            </div>
          </ng-template>

          <span style="margin: 5px; font-size: 14px; white-space: pre-wrap;">{{workbenchService.workbench.profile}}</span>
          <div style="width: 100%; height: 1px;margin: 50px 0 0 0; border-bottom: 1px solid #F3F3F3"></div>
        </div>
        <div style="margin: 50px 0 0 0; display: flex; flex-direction: column;" *ngIf="workbenchService.workbench.salesAchievementReport">
          <div class="workbench-block-header1">
            <span style="flex: 1; color: #404040; font-size: 18px; font-weight: 450">
              {{'PerformanceStatistics' | translate}}
            </span>
            <a nz-button nzType="link" nzSize="small" style="color: #4B84E5; font-size: 14px;" appThrottleClick (throttleClick)="onStatisticsMore()">
              {{'More' | translate}} >
            </a>
          </div>
          <div class="workbench-statistics-panel">
            <div style="display: flex; flex-direction: row">
              <div class="workbench-statistics-item">
                <span class="workbench-statistics-title">{{'Premium' | translate}} (HKD)</span>
                <span class="workbench-statistics-value">{{dashboardTotal.premium | number}}</span>
              </div>
            </div>
            <div style="display: flex; flex-direction: row">
              <div class="workbench-statistics-item">
                <span class="workbench-statistics-title">{{'NumberOfPolicies' | translate}}</span>
                <span class="workbench-statistics-value">{{dashboardTotal.policyCounts}}</span>
              </div>
              <div class="workbench-statistics-item">
                <span class="workbench-statistics-title">{{'NumberOfReservations' | translate}}</span>
                <span class="workbench-statistics-value">{{dashboardTotal.bookingDetail}}</span>
              </div>
              <div class="workbench-statistics-item">
                <span class="workbench-statistics-title">{{'NumberOfProposal' | translate}}</span>
                <span class="workbench-statistics-value">{{dashboardTotal.proposalDetail}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!dashboardTotal">
        <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 8 }">
        </nz-skeleton>
        <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 8 }">
        </nz-skeleton>
      </div>
    </div>

    <div class="workbench-column" style="width: 60%;">
      <div class="workbench-block" *ngIf="workbenchService.workbench && dashboardTotal">
        <div class="workbench-block-header2">
          <span style="flex: 1; color: #333333; font-size: 18px; font-weight: 450">
            {{'NewestMessages' | translate}}
          </span>
          <a nz-button nzType="link" nzSize="small" style="color: #4B84E5; font-size: 14px;" appThrottleClick (throttleClick)="onMessageMore()">
            {{'More' | translate}} >
          </a>
        </div>
        <div *ngIf="workbenchService.workbench?.messageList?.length > 0">
          <nz-table [nzBordered]="false"
                    [nzOuterBordered]="false"
                    [nzShowPagination]="true"
                    [nzFrontPagination]="false"
                    [nzData]="workbenchService.workbench?.messageList">
            <tbody>

            <tr *ngFor="let messageOverview of workbenchService.workbench?.messageList; let i = index">
              <td>
                <img alt="" width="30" [src]="messageOverview.logo">
              </td>
              <td>
                <span style="color: #404040; font-size: 16px; font-weight: bold">
                  {{messageTypeTranslate(messageOverview.type)}}
                </span>
                <span style="color: #A6A6A6">{{messageOverview.addTime | date:'yyyy-MM-dd HH:mm' }}</span>
                <br>
                <span style="color: #A6A6A6">{{messageOverview.content}}</span>
              </td>
              <td>
                <a nz-button nzType="link" nzSize="small" style="color: #4B84E5; font-size: 14px;" appThrottleClick (throttleClick)="onMessageDetail(messageOverview)">
                  {{'View' | translate}} >
                </a>
              </td>
            </tr>
            </tbody>
          </nz-table>
        </div>
        <nz-empty *ngIf="workbenchService.workbench?.messageList?.length === 0" nzNotFoundImage="simple"></nz-empty>
      </div>
      <div style="height: 20px"></div>
      <div class="workbench-block" *ngIf="workbenchService.workbench?.informationList?.length > 0">
        <div class="workbench-block-header2">
          <span style="flex: 1; color: #333333; font-size: 18px; font-weight: 450">
            {{'NewestInformation' | translate}}
          </span>
          <a nz-button nzType="link" nzSize="small" style="color: #4B84E5; font-size: 14px;" appThrottleClick (throttleClick)="onInformationMore()">
            {{'More' | translate}} >
          </a>
        </div>
        <div *ngIf="workbenchService.workbench?.informationList?.length > 0" style="margin-top: 15px">
          <div *ngFor="let information of workbenchService.workbench?.informationList; let i = index">
            <div class="flex-row workbench-info">
              <div style="width: 80px; margin-right: 5px">
                <span class="workbench-info-type">{{information.type | metadataTranslate:'informationType'}}</span>
              </div>
              <span style="flex: 1; color: #222222">{{information.title}}</span>
              <span style="width: 80px; font-size: 12px">{{information.time | date:'yyyy-MM-dd' }}</span>
              <a nz-button nzType="link" nzSize="small" style="color: #4B84E5; font-size: 14px;" appThrottleClick (throttleClick)="onInformationDetail(information.id)">
                {{'View' | translate}} >
              </a>
            </div>
          </div>
        </div>

        <nz-empty *ngIf="workbenchService.workbench?.informationList?.length === 0" nzNotFoundImage="simple"></nz-empty>
      </div>

      <div *ngIf="!workbenchService.workbench || !dashboardTotal">
        <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 8 }">
        </nz-skeleton>
        <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 8 }">
        </nz-skeleton>
      </div>

    </div>

  </div>
</div>
