import { Component, OnInit } from '@angular/core';
import { formatNumber } from '@angular/common';
import { WorkbenchService } from '../workbench.service';
import { EChartsOption } from 'echarts/types/dist/shared';
import { Instance, OverviewRes } from '../../api/types';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {

  dateYear = undefined;
  loading = false;
  fulfillPolicyChartsOption: EChartsOption;
  premiumStatisticsChartsOption: EChartsOption;
  bookingChartsOption: EChartsOption;
  proposalChartsOption: EChartsOption;
  underwritingChartsOption: EChartsOption;
  chartsData: OverviewRes = new OverviewRes();
  instance: Instance;

  constructor(public workbenchService: WorkbenchService,
              private translate: TranslateService,
              private router: Router) { }

  ngOnInit(): void {
    this.instance = this.workbenchService.mockInstance;
    this.getData();
  }

  getData(date: number = 0): void {
    this.loading = true;
    const param = { year: null };
    if (date) {
      param.year = date;
    }
    this.workbenchService.getDashboardOverview(param)
      .subscribe(
        data => {
          console.log(data);
          this.chartsData = data;
          this.getRateDemoChartsOption();
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  goBack(): void {
    this.router.navigate(['/user/workbench']).then();
  }

  getRateDemoChartsOption(): void {
    const policyXData = this.chartsData.policyCounts.map(item => item.key + '月');
    this.translate.get(['Month', 'RecommendPolicyStatistics', 'NumberOfPolicies',
      'AchieveUnderwritingStatistics', 'Completed', 'Uncompleted', 'Proposal',
      'Quantity', 'Statistics', 'NumberProposals', 'BookingQuantityStatistics', 'BookingQuantity',
      'People', 'PremiumStatistics', 'Premium', 'AchievePolicyStatistics']).subscribe(
      (result: any) => {
        const policyData = this.chartsData.policyCounts.map(item => item.value);
        const premiumXData = this.chartsData.premium.map(item => item.key + result.Month);
        const premiumData = this.chartsData.premium.map(item => item.value);
        const bookingXData = this.chartsData.bookingCounts.map(item => item.key + result.Month);
        const bookingData = this.chartsData.bookingCounts.map(item => item.value);
        const proposalXData = this.chartsData.proposalCounts.map(item => item.key + result.Month);
        const proposalData = this.chartsData.proposalCounts.map(item => item.value);
        const underwritingXData = this.chartsData.underwritingList.map(item => item.key + result.Month);
        const underwritingUnFinishedData = this.chartsData.underwritingList.map(item => item?.value?.unFinished);
        const underwritingFinishedData = this.chartsData.underwritingList.map(item => item?.value?.finished);
        this.fulfillPolicyChartsOption = {
          title: {
            text: this.instance.isWDF() ? result.RecommendPolicyStatistics : result.AchievePolicyStatistics
          },
          legend: {
            data: [ result.NumberOfPolicies ],
            right: '5px'
          },
          grid: {
            show: false,
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: policyXData
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLabel: {
              inside: false
            }
          },
          series: [
            {
              name: result.NumberOfPolicies,
              type: 'bar',
              color: '#0078D4',
              emphasis: {
                focus: 'series'
              },
              data: policyData,
            }
          ]
        };
        this.premiumStatisticsChartsOption = {
          title: {
            text: result.PremiumStatistics
          },
          legend: {
            data: [ result.Premium + '(HKD)' ],
            right: '5px'
          },
          grid: {
            show: false,
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: premiumXData
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLabel: {
              inside: false
            }
          },
          series: [
            {
              name: result.Premium + '(HKD)',
              type: 'bar',
              color: '#0078D4',
              emphasis: {
                focus: 'series'
              },
              data: premiumData,
            }
          ]
        };
        this.bookingChartsOption = {
          title: {
            text: result.BookingQuantityStatistics
          },
          legend: {
            data: [ result.BookingQuantity + `(${result.People})` ],
            right: '5px'
          },
          grid: {
            show: false,
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: bookingXData
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLabel: {
              inside: false
            }
          },
          series: [
            {
              name: result.BookingQuantity + `(${result.People})`,
              type: 'bar',
              color: '#0078D4',
              emphasis: {
                focus: 'series'
              },
              data: bookingData,
            }
          ]
        };
        this.proposalChartsOption = {
          title: {
            text: result.Proposal + result.Quantity + result.Statistics
          },
          legend: {
            data: [ result.NumberProposals ],
            right: '5px'
          },
          grid: {
            show: false,
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: proposalXData
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLabel: {
              inside: false
            }
          },
          series: [
            {
              name: result.NumberProposals,
              type: 'bar',
              color: '#0078D4',
              emphasis: {
                focus: 'series'
              },
              data: proposalData,
            }
          ]
        };
        this.underwritingChartsOption = {
          title: {
            text: result.AchieveUnderwritingStatistics
          },
          legend: {
            data: [ result.Completed, result.Uncompleted ],
            right: '5px'
          },
          grid: {
            show: false,
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            boundaryGap: true,
            data: underwritingXData
          },
          yAxis: {
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLabel: {
              inside: false
            }
          },
          series: [
            {
              name: result.Completed,
              type: 'bar',
              color: '#0078D4',
              stack: 'all',
              emphasis: {
                focus: 'series'
              },
              data: underwritingFinishedData,
            },
            {
              name: result.Uncompleted,
              type: 'bar',
              color: '#CADFFB',
              stack: 'all',
              emphasis: {
                focus: 'series'
              },
              data: underwritingUnFinishedData,
            }
          ]
        };
      });
  }

  onChange(result: Date): void {
    console.log('onChange: ', result);
    console.log(new Date(result).getFullYear().toString());
    let str = new Date(result).getFullYear().toString();
    console.log(new Date(str).getTime());
    this.getData(new Date(str).getTime());
  }
}
