import {Component, Input, OnInit} from '@angular/core';
import {Policy} from '../../policy-types';
import { PolicyService } from '../../policy.service';
import { Instance } from '../../../api/types';

@Component({
  selector: 'app-policy-infomation',
  templateUrl: './policy-infomation.component.html',
  styleUrls: ['./policy-infomation.component.less']
})
export class PolicyInfomationComponent implements OnInit {
  @Input()
  policy: Policy;
  instance: Instance;

  constructor(private policyService: PolicyService) {
  }

  ngOnInit(): void {
    this.instance = this.policyService.mockInstance;
  }

  isShowDDA(info): boolean {
    let res = false;
    if (info.categoryCode) {
      res =  info.categoryCode !== 'GI';
    }
    if (res && info.companyCode) {
      res = info.companyCode !== 'AMP';
    }
    if (res && this.instance.isFone()) {
      res = info.autoTransfer;
    }
    if (this.instance.isQuanBao() || this.instance.isBaylightOrDev()) {
      res = false;
    }
    return res;
  }

  get isShowItem(): boolean {
    const instance = this.policyService.mockInstance;
    return !(instance && instance.groupKey === 'fone');
  }
}
