<div #anchorContainer class="drawer-container" style="overflow-y: scroll; height: 100%">

  <div class="common-title">
    {{'Country/Region' | translate}}
  </div>

  <div style="margin-right: 20px;">

    <div class="flex-row search">
      <input type="text" nz-input
             [placeholder]="('PleaseEnter' | translate) + ('Country/Region' | translate)"
             [nzBorderless]="true"
             [(ngModel)]="name" (ngModelChange)="onSearch($event)"/>
      <img alt="" width="22" src="assets/images/ic_search.svg">
    </div>

    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">

      <div *ngIf="!name">
        <div class="section-title" style="margin-top: 20px">
          <span style="margin-left: 8px">{{('Hot' | translate) + ('Country/Region' | translate)}}</span>
        </div>
        <!-- 热门 -->
        <div nz-row *ngIf="countryGroups && countryGroups.hotCountries && countryGroups.hotCountries.length > 0"
             [nzGutter]="10">
          <div *ngFor="let region of countryGroups.hotCountries" nz-col [nzSpan]="8">
            <div class="hot-item" appThrottleClick (throttleClick)="onRegionClick(region)">
              <div style="display: flex;justify-content: space-between;">
                <span>{{ region.name }}</span><span>+{{ region.areaCode }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 分组 -->
        <div *ngIf="countryGroups && countryGroups.groupCountries && countryGroups.groupCountries.length > 0">
          <div *ngFor="let groupCountry of groupCountries">
            <div>
              <div [id]="groupCountry.group"
                   style="font-weight: bold; font-size: 16px; margin-top: 5px">{{groupCountry.group}}</div>
              <div *ngIf="groupCountry.list && groupCountry.list.length > 0">
                <div *ngFor="let region of groupCountry.list" class="country-item" appThrottleClick
                     (throttleClick)="onRegionClick(region)">
                  <div style="display: flex;justify-content: space-between;">
                    <span>{{ region.name }}</span><span>+{{ region.areaCode }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="name">
        <div *ngIf="countries && countries.length > 0">
          <div *ngFor="let region of countries" class="country-item" appThrottleClick
               (throttleClick)="onRegionClick(region)">
            <div style="display: flex;justify-content: space-between;">
              <span>{{ region.name }}</span><span>+{{ region.areaCode }}</span>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>

  </div>
</div>

<div class="alphabet-bar">
  <nz-anchor [nzContainer]="anchorContainer">
    <nz-link *ngFor="let group of setOfGroup" [nzHref]="'#' + group" [nzTitle]="group"></nz-link>
  </nz-anchor>
</div>
