import {Component, Input, OnInit} from '@angular/core';
import {BasicInformation, FnaFormInfo, NewFnaDetail} from '../../new-fna/new-fna-types';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {MetadataService} from '../../shared/service/metadata.service';
import {TranslateService} from '@ngx-translate/core';
import {Tree} from '../../contact/contact-types';
import {ContactService} from '../../contact/contact.service';

@Component({
  selector: 'app-zuu-additional-info',
  templateUrl: './zuu-additional-info.component.html',
  styleUrls: ['./zuu-additional-info.component.less']
})
export class ZuuAdditionalInfoComponent implements OnInit {
  basicInfo: BasicInformation = new BasicInformation();

  @Input()
  fnaInfo: NewFnaDetail = new NewFnaDetail();

  formInfo: FnaFormInfo = new FnaFormInfo();
  formInfoCopy: FnaFormInfo = new FnaFormInfo();
  currencyOptions: PropertySelectOption[] = [];
  frequencyOption: PropertySelectOption[] = [];
  yesOrNoOptions: PropertySelectOption[] = [];

  accountNameOptions: Tree[] = [];

  constructor(private metadataService: MetadataService,
              private translate: TranslateService,
              private contactService: ContactService) { }

  ngOnInit(): void {
    const currencyTypes = this.metadataService.values('currency');
    for (const fnaGenderType of currencyTypes) {
      this.currencyOptions.push(new PropertySelectOption(fnaGenderType.value, fnaGenderType.key));
    }

    const frequencyTypes = this.metadataService.values('frequency');
    for (const fnaGenderType of frequencyTypes) {
      this.frequencyOption.push(new PropertySelectOption(fnaGenderType.value, fnaGenderType.key));
    }

    this.translate.get(['Yes', 'No']).subscribe(
      (res: any) => {
        this.yesOrNoOptions.push(new PropertySelectOption(res.Yes, '1'));
        this.yesOrNoOptions.push(new PropertySelectOption(res.No, '0'));
      });

    // this.contactService.sub()
    //   .subscribe(
    //     data => {
    //       this.accountNameOptions = data || [];
    //     }
    //   );
    // if (this.fnaInfo.accountName) {
    //   this.formInfo.FNA_ADA_10_account_name = this.fnaInfo.accountName;
    // }
  }

  setArr(): void {
    this.fnaInfo.additionalDataAnalysis?.forEach(item => {
      this.formInfo[item.key] = item.value;
      if (item.key === 'FNA_ADA_01_is_transfer_in' || item.key === 'FNA_ADA_02_is_new_customer') {
        this.formInfo[item.key] = item.value === null ? null : item.value ? '1' : '0';
      }
    });
    this.formInfoCopy = JSON.parse(JSON.stringify(this.formInfo));

  }

  accountNameSearch(input, option): boolean {
    if (input) {
      return option.key.includes(input) || option.nzLabel.includes(input);
    }
    return false;
  }

  parserValue(value: string): string {
    return value.replace(/\$\s?|(,*)/g, '');
  }

  formatterValue(value: number): string {
    if (parseFloat(String(value)).toString() === 'NaN') {
      return '';
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  radioLog16(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_16_A', 'FNA_ADA_16_B', 'FNA_ADA_16_C', 'FNA_ADA_16_D', 'FNA_ADA_16_E', 'FNA_ADA_16_F'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog21(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_21_A', 'FNA_ADA_21_B'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }
  radioLog22(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_22_A', 'FNA_ADA_22_B'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }
  radioLog23(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_23_A', 'FNA_ADA_23_B', 'FNA_ADA_23_C'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }
  radioLog24(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_24_A', 'FNA_ADA_24_B', 'FNA_ADA_24_C'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }
  radioLog25(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_25_A', 'FNA_ADA_25_B'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog29(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_29_A', 'FNA_ADA_29_B', 'FNA_ADA_29_C'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog30(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_30_A', 'FNA_ADA_30_B'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog31(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_31_A', 'FNA_ADA_31_B'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog32(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_32_A', 'FNA_ADA_32_B'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog33(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_33_A', 'FNA_ADA_33_B'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog34(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_34_A', 'FNA_ADA_34_B', 'FNA_ADA_34_C'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog35(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_35_A', 'FNA_ADA_35_B', 'FNA_ADA_35_C', 'FNA_ADA_35_D'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog36(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_36_A', 'FNA_ADA_36_B', 'FNA_ADA_36_C', 'FNA_ADA_36_D'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog37(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_37_A', 'FNA_ADA_37_B'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog38(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_38_A', 'FNA_ADA_38_B', 'FNA_ADA_38_C', 'FNA_ADA_38_D'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog39(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_39_A', 'FNA_ADA_39_B', 'FNA_ADA_39_C', 'FNA_ADA_39_D'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog40(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_40_A', 'FNA_ADA_40_B', 'FNA_ADA_40_C', 'FNA_ADA_40_D'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog41(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_41_A', 'FNA_ADA_41_B', 'FNA_ADA_41_C', 'FNA_ADA_41_D'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog45(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_45_A', 'FNA_ADA_45_B'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog46(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_46_A', 'FNA_ADA_46_B', 'FNA_ADA_46_C', 'FNA_ADA_46_D', 'FNA_ADA_46_E'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog47(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_47_A', 'FNA_ADA_47_B', 'FNA_ADA_47_C', 'FNA_ADA_47_D', 'FNA_ADA_47_E', 'FNA_ADA_47_F'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog50(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_50_A', 'FNA_ADA_50_B', 'FNA_ADA_50_C', 'FNA_ADA_50_D'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
  }

  radioLog54(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_54_A', 'FNA_ADA_54_B'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });

    const arrs = ['FNA_ADA_54_B1_a', 'FNA_ADA_54_B1_b', 'FNA_ADA_54_B1_c', 'FNA_ADA_54_B1_d', 'FNA_ADA_54_B1_e', 'FNA_ADA_54_B1_f', 'FNA_ADA_54_B1_g', 'FNA_ADA_54_B2_a', 'FNA_ADA_54_B2_b', 'FNA_ADA_54_B2_c', 'FNA_ADA_54_B2_d', 'FNA_ADA_54_B2_e'];
    if (valueKey === 'FNA_ADA_54_A' && this.formInfo[valueKey]) {
      arrs.map(item => {
        this.formInfo[item] = false;
      });
    }
  }

  radioLog54b1(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_54_B1_a', 'FNA_ADA_54_B1_b', 'FNA_ADA_54_B1_c', 'FNA_ADA_54_B1_d', 'FNA_ADA_54_B1_e', 'FNA_ADA_54_B1_f', 'FNA_ADA_54_B1_g'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
    this.formInfo.FNA_ADA_54_A = false;
    this.formInfo.FNA_ADA_54_B = true;
  }

  radioLog54b2(event: any, valueKey: string): void {
    const arr = ['FNA_ADA_54_B2_a', 'FNA_ADA_54_B2_b', 'FNA_ADA_54_B2_c', 'FNA_ADA_54_B2_d', 'FNA_ADA_54_B2_e'];
    arr.map(item => {
      if (valueKey === item) {
        this.formInfo[item] = event;
      } else {
        this.formInfo[item] = null;
      }
    });
    this.formInfo.FNA_ADA_54_A = false;
    this.formInfo.FNA_ADA_54_B = true;
  }
}
