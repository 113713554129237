import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import * as CryptoJS from 'crypto-js';
import { Country } from '../../../api/types';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-verify-pin',
  templateUrl: './verify-pin.component.html',
  styleUrls: ['./verify-pin.component.less']
})
export class VerifyPinComponent implements OnInit {
  @ViewChild('codeInput', { static: false }) codeInput: ElementRef;
  @ViewChild('codeInput1', { static: false }) codeInput1: ElementRef;
  @ViewChild('codeInput2', { static: false }) codeInput2: ElementRef;
  @ViewChild('codeInput3', { static: false }) codeInput3: ElementRef;
  @ViewChild('codeInput4', { static: false }) codeInput4: ElementRef;
  isVisible = true;
  isEdit = false;
  @Input()
  isPhone = false;
  captcha: any[] = [];
  input1: '';
  input2: '';
  input3: '';
  input4: '';
  oncePinCode: string;
  verifyCode: string;
  @Input()
  mode = 'verify'; // verify 验证; setUp 设置; 修改 edit;
  again = false;

  isOk = false;
  areaCode = '852';
  phone: string;
  code: string;
  t: any; // 定时器
  timeVal: any;
  contactsAreaCodeArr: Country[] = [];

  @Output()
  verifyNotification: EventEmitter<any> = new EventEmitter<any>();


  constructor(private el: ElementRef,
              private authService: AuthService,
              private router: Router,
              private toastr: ToastrService,
              private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.codeInput1.nativeElement.focus();
    }, 500);
    this.areaCodeData();
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    // this.isVisible = false;
    this.captchaAdd();
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.router.navigate(['/user/workbench']).then();
    this.isVisible = false;
    this.verifyNotification.emit(false);
  }
  editPin(): void {
    this.isPhone = true;
  }

  onChange(e): void {
    if (parseFloat(String(e.data)).toString() === 'NaN') {
      return;
    }
    this.codeInput2.nativeElement.focus();
  }

  onChange1(e): void {
    if (parseFloat(String(e.data)).toString() === 'NaN') {
      return;
    }
    this.codeInput3.nativeElement.focus();
  }

  onChange2(e): void {
    if (parseFloat(String(e.data)).toString() === 'NaN') {
      return;
    }
    this.codeInput4.nativeElement.focus();
  }

  onChange3(e): void {
    if (parseFloat(String(e.data)).toString() === 'NaN') {
      return;
    }
    // this.codeInput4.nativeElement.blur();
    switch (this.mode) {
      case 'verify':
        this.captchaAdd();
        break;
      case 'edit':
        console.log(this.mode);
        this.editPinCode();
        break;
      case 'setUp':
        console.log(this.mode);
        this.setUpPinCode();
        break;
    }
  }

  delDown(e, i): void {
    // console.log(e.keyCode);
    if (e.keyCode === 8) {
      switch (i) {
        case 0:
          break;
        case 1:
          this.codeInput1.nativeElement.focus();
          break;
        case 2:
          this.codeInput2.nativeElement.focus();
          break;
        case 3:
          this.codeInput3.nativeElement.focus();
          break;
      }
    }
  }

  editPinCode(): void {
    if (this.again) {
      if (!this.isValid()) {
        return;
      }
      const code = '' + this.input1 + this.input2 + this.input3 + this.input4;
      console.log(code);
      if (!(this.oncePinCode === code)) {
        this.translate.get('PinError').subscribe(
          (res) => {
            this.toastr.warning(res);
          }
        );
        setTimeout(() => {
          this.emptyInput();
        }, 500);
        return;
      }
      this.authService.pinUpdateCode({code: this.code, pinCode: this.oncePinCode})
        .subscribe(
          data => {
            if (data) {
              this.mode = 'verify';
              this.again = false;
              this.translate.get('ModifySuccess').subscribe(
                (res) => {
                  this.toastr.success(res);
                }
              );
              setTimeout(() => {
                this.emptyInput();
              }, 500);
            }
          },
          error => {
            console.log(error);
            this.emptyInput();
          });

    } else {
      if (!this.isValid()) {
        return;
      }
      const code = '' + this.input1 + this.input2 + this.input3 + this.input4;
      console.log(code);
      this.oncePinCode = code;
      setTimeout(() => {
        this.again = true;
        this.emptyInput();
      }, 500);
    }
  }

  setUpPinCode(): void {
    if (this.again) {
      if (!this.isValid()) {
        return;
      }
      const code = '' + this.input1 + this.input2 + this.input3 + this.input4;
      console.log(code);
      if (!(this.oncePinCode === code)) {
        this.translate.get('PinError').subscribe(
          (res) => {
            this.toastr.warning(res);
          }
        );
        setTimeout(() => {
          this.emptyInput();
        }, 500);
        return;
      }
      this.authService.pinSettingCode(this.oncePinCode)
        .subscribe(
          data => {
            if (data) {
              this.mode = 'verify';
              this.again = false;
              setTimeout(() => {
                this.emptyInput();
              }, 400);
            }
          },
          error => {
            console.log(error);
            this.emptyInput();
          });

    } else {
      if (!this.isValid()) {
        return;
      }
      const code = '' + this.input1 + this.input2 + this.input3 + this.input4;
      console.log(code);
      this.oncePinCode = code;
      setTimeout(() => {
        this.again = true;
        this.emptyInput();
      }, 500);
    }
  }

  backSetUp(): void {
    this.again = false;
    setTimeout(() => {
      this.emptyInput();
    }, 500);
  }

  backEditPinFirst(): void {
    this.again = false;
    setTimeout(() => {
      this.emptyInput();
    }, 500);
  }

  backVerify(): void {
    this.again = false;
    this.mode = 'verify';
    setTimeout(() => {
      this.emptyInput();
    }, 500);
  }

  captchaAdd(): void {
    if (!this.isValid()) {
      return;
    }
    const code = '' + this.input1 + this.input2 + this.input3 + this.input4;
    console.log(code);
    if (!code) {
      return;
    }
    const codeMd5 = CryptoJS.MD5(code);
    this.authService.verifyPin(codeMd5)
      .subscribe(data => {
        console.log(data);
        if (data) {
          this.verifyNotification.emit(true);
          localStorage.setItem('codePin', codeMd5);
          const date = new Date();
          const timestamp = date.getTime();
          console.log(timestamp);
          localStorage.setItem('codePinDate', timestamp.toString());
          clearInterval(this.t);
        }
      }, error => {
        console.log(error);
        this.emptyInput();
      });
  }

  emptyInput(): void {
    this.input1 = '';
    this.input2 = '';
    this.input3 = '';
    this.input4 = '';
    this.codeInput1.nativeElement.focus();
  }

  isValid(): boolean {
    if (parseFloat(String(this.input1)).toString() === 'NaN') {
      return false;
    }
    if (parseFloat(String(this.input2)).toString() === 'NaN') {
      return false;
    }
    if (parseFloat(String(this.input3)).toString() === 'NaN') {
      return false;
    }
    if (parseFloat(String(this.input4)).toString() === 'NaN') {
      return false;
    }
    return true;
  }

  selectFilterOption(input, option): boolean {
    if (input) {
      return option.key.includes(input);
    }
    return false;
  }

  onSend(): void {
    if (!(this.areaCode && this.phone)) {
      return;
    }
    if (!this.isOk) {
      this.countdown();
      const str = this.areaCode ? this.areaCode.split('+')[0] : null;
      this.authService.pinPhoneCode({areaCode: str, phone: this.phone})
        .subscribe(
          data => {
            console.log(data, '发送成功，请注意查收。');
            this.translate.get('SendSuccessfullyTips').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          },
          error => {
            console.log(error);
          });
    }
  }

  areaCodeData(): void {
    this.authService.getCountryAll()
      .subscribe(
        data => {
          console.log(data);
          this.contactsAreaCodeArr = data;
          console.log(data.find(item => item.areaCode === '852'));
          const copy = data.find(item => item.areaCode === '852');
          this.areaCode = copy ? copy.areaCode + '+' + copy.name : '852';

        },
        error => {
          console.log(error);
        });
  }

  countdown(): void {
    clearInterval(this.t);
    let times = 60;
    this.t = setInterval(() => {
      if (--times > 0) {
        this.timeVal = times + 'S';
        this.isOk = true;
      } else {
        this.isOk = false;
        clearInterval(this.t);
      }
    }, 1000);
  }

  phoneBack(): void {
    this.isOk = false;
    this.phone = '';
    this.code = '';
    this.isPhone = false;
    this.mode = 'verify';
  }

  nextVerifyCode(): void {
    setTimeout(() => {
      this.emptyInput();
    }, 510);
    if (!this.code) {
      return;
    }
    this.authService.pinVerifyCode(this.code)
      .subscribe(
        data => {
          console.log(data, '校验验证码');
          this.isPhone = false;
          this.again = false;
          this.mode = 'edit';
        },
        error => {
          console.log(error);
        });
  }

  backPhone(): void {
    this.isPhone = true;
  }

  onceAgainPin(): void {
    this.isPhone = true;
  }

}
