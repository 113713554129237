import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyDisplayMode } from '../../shared';
import { NzDateMode, SupportTimeOptions } from 'ng-zorro-antd/date-picker/standard-types';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-property-datetime-string',
  templateUrl: './property-datetime-string.component.html',
  styleUrls: ['./property-datetime-string.component.less']
})
export class PropertyDatetimeStringComponent implements OnInit {

  @Input() displayMode: PropertyDisplayMode = 'horizontal';
  @Input() title: string;
  @Input() tip: string;
  @Input() tipVisible = false;
  @Input() borderless = true;
  @Input() disabled = false;
  @Input() required = false;
  @Input() bottomLineVisible = true;
  @Input() formatter = 'yyyy-MM-dd';
  @Input() showTime: SupportTimeOptions;
  @Input() placeholder: string;
  @Input() mode: NzDateMode = 'date';
  @Input() titleColor: string;

  date: Date;

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  innerValue: string;

  @Input()
  get value(): string {
    return this.innerValue;
  }

  set value(val: string) {
    this.date = val ? new Date(val) : undefined;
    console.log(this.date);
    this.innerValue = val ;
    this.valueChange.emit(this.innerValue);
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  onDateChange(date: Date): void {
    this.date = date;
    if (!date) {
      return;
    }
    const dateString = formatDate(date, this.formatter, 'zh-Hans-CN');
    // const newDate = new Date(dateString);
    // const dateNew = newDate.valueOf();
    // const dateUTC = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0);
    // console.log(dateNew);
    // console.log(dateUTC);
    console.log(dateString);
    this.value = dateString;
    // this.value = dateUTC;
  }

}
