import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.less']
})
export class ProposalComponent implements OnInit {

  constructor(router: Router) {
  }

  ngOnInit(): void {
  }

}
