<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 40px">
    {{title}}
  </div>

  <!-- 区号 -->
<!--  <app-property-input-number [title]="'AreaCode' | translate"-->
<!--                             [placeholder]="('PleaseEnter' | translate) + ('AreaCode' | translate)"-->
<!--                             [(value)]="phone.areaCode"></app-property-input-number>-->

  <app-property-pick [displayMode]="'horizontal'"
                     [title]="'AreaCode' | translate"
                     [placeholder]="('PleaseEnter' | translate) + ('AreaCode' | translate)"
                     appThrottleClick (throttleClick)="onCountry()"
                     [value]="phone.areaCode"></app-property-pick>

<!--  <div style="padding: 10px 0 0px 0; display: flex; flex-direction: row; justify-content: flex-end">-->
<!--    <nz-tag style="cursor: pointer" *ngFor="let areaCode of areaCodes" appThrottleClick (throttleClick)="onAreaCode(areaCode)">+{{areaCode}}</nz-tag>-->
<!--  </div>-->

  <!-- 电话号码 -->
  <app-property-input-number [title]="'PhoneNumber' | translate"
                             [placeholder]="('PleaseEnter' | translate) + ('PhoneNumber' | translate)"
                             [(value)]="phone.phone"></app-property-input-number>

  <!-- 电话類型 -->
  <app-property-input *ngIf="showType" [title]="'Remark' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('Remark' | translate)"
                      [(value)]="phone.type"></app-property-input>

  <!-- 快捷地址列表 -->
  <div class="phones-section" *ngIf="quickPhones && quickPhones.length > 0">
    <div class="phones-section-title">
      <span style="margin-left: 8px; margin-bottom: 10px">{{'QuickPhones' | translate}}</span>
    </div>
    <!-- 快捷地址列表 -->
    <div *ngFor="let phone of quickPhones" class="phones-item" appThrottleClick (throttleClick)="onQuickPhone(phone)">
      <i nz-icon nzType="mobile" nzTheme="outline"></i> {{phone.toPhoneString()}}
    </div>
  </div>


  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [class]="canNotSave() ? 'disabled' : ''"
           [disabled]="canNotSave()"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="check" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
