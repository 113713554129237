import { Component, OnInit } from '@angular/core';
import {LeaveWordDetailsRes} from '../leave-word-types';

@Component({
  selector: 'app-leave-word-detail',
  templateUrl: './leave-word-detail.component.html',
  styleUrls: ['./leave-word-detail.component.less']
})
export class LeaveWordDetailComponent implements OnInit {
  detailData: LeaveWordDetailsRes = new LeaveWordDetailsRes();
  constructor() { }

  ngOnInit(): void {
  }

}
