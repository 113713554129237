import { Injectable } from '@angular/core';
import { ApiResponse } from '../../api/types';
import { BaseService } from '../../base/base.service';
import * as StackTrace from 'stacktrace-js';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpService } from '../../core/http.service';
import { API } from '../../api/api';
import packageJson from '../../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class ErrorService extends BaseService {

  version = '';

  constructor(private http: HttpService) {
    super();
    this.version = packageJson.version;
  }

  report(error: Error, location: LocationStrategy): void {

    // if (!environment.production) {
    //   return;
    // }

    StackTrace.fromError(error, {offline: true}).then(stackFrames => {
      const stackString = stackFrames
        .map((sf) => {
          return sf.toString();
        }).join('\n');
      // log on the server

      const body = {
        version: '',
        url: '',
        name: '',
        message: '',
        stack: ''
      };

      body.version = this.version;
      body.url = location instanceof PathLocationStrategy ? location.path() : '';
      body.name = error.name;
      body.message = error.message;
      body.stack = stackString;

      const url = API.errorV3 + '/report';
      this.http.post<ApiResponse<string>>(url, body, {
        observe: 'response'
      }).subscribe(
        data => {
        },
        () => {
        }
      );

    });
  }

}
