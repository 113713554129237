import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import {
  BookingInsuredReq,
  BookingOwnerReq,
  ContingentOwner,
  Customer,
  CustomerCorporate,
  CustomerType,
  DataMode,
  Instance,
  Relationship
} from '../../api/types';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { MetadataService } from '../../shared/service/metadata.service';
import { BookingProductPlanComponent } from '../components/booking-product-plan/booking-product-plan.component';
import { BookingService } from '../booking.service';
import { CustomerEditComponent } from '../../shared/component/customer-edit/customer-edit.component';
import { TranslateService } from '@ngx-translate/core';
import { BeneficiariesEditComponent } from '../../shared/component/beneficiaries-edit/beneficiaries-edit.component';
import { BookingInfoEditComponent } from '../components/booking-info-edit/booking-info-edit.component';
import * as _ from 'lodash';
import { ProposalSelectionComponent } from '../../proposal/proposal-selection/proposal-selection.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProposalDetailComponent } from '../../proposal/proposal-detail/proposal-detail.component';
import { ProposalService } from '../../proposal/proposal.service';
import { Booking, BookingCheckMerge, BookingDraftResp, Other } from '../booking-types';
import { Fna } from '../../fna/fna-types';
import { Proposal, ProposalSearch, ProposalStatus } from '../../proposal/proposal-types';
import { Product } from '../../product/product-types';
import { WorkbenchService } from '../../workbench/workbench.service';
import { Workbench } from '../../workbench/workbench-types';
import { BookingGroupSelectionComponent } from '../components/booking-group-selection/booking-group-selection.component';
import { CommonInputEditComponent } from '../../shared/component/common-input-edit/common-input-edit.component';
import { BookingMergeComponent } from '../components/booking-merge/booking-merge.component';
import { ToastrService } from 'ngx-toastr';
import { ReinsuranceComponent } from '../../shared/component/reinsurance/reinsurance.component';
import { SecondHolderEditComponent } from '../../shared/component/second-holder-edit/second-holder-edit.component';
import { plainToInstance } from 'class-transformer';

@Component({
  selector: 'app-booking-create',
  templateUrl: './booking-create.component.html',
  styleUrls: ['./booking-create.component.less']
})
export class BookingCreateComponent implements OnInit {

  drawerRef: NzDrawerRef<BookingCreateComponent, string>;

  @Output()
  draftReq: Booking = new Booking();
  product: Product = new Product();
  applicationTypeOptions: PropertySelectOption[] = [];
  loading = false;

  workbench: Workbench;
  instance: Instance;

  @Output()
  bookingDraftSaved: EventEmitter<BookingDraftResp> = new EventEmitter<BookingDraftResp>();

  @Output()
  bookingApplied: EventEmitter<string> = new EventEmitter<string>();

  constructor(private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private modalService: NzModalService,
              private translate: TranslateService,
              private bookingService: BookingService,
              private proposalService: ProposalService,
              private workbenchService: WorkbenchService,
              private viewContainerRef: ViewContainerRef,
              private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {

    this.draftReq.isPaperContracts = false;

    this.instance = this.bookingService.mockInstance;

    const bookingApplicationTypes = this.metadataService.values('bookingApplicationType');
    if (bookingApplicationTypes && bookingApplicationTypes.length > 0) {
      for (const bookingApplicationType of bookingApplicationTypes) {
        this.applicationTypeOptions.push(new PropertySelectOption(bookingApplicationType.value, bookingApplicationType.key));
      }
      this.draftReq.applicationType = bookingApplicationTypes[0].key;
    }

    // this.draftReq.accompanyName = this.bookingService.passport.name;
    // this.draftReq.accompanyEmail = this.bookingService.passport.email;
    // this.draftReq.accompanyAreaCode = this.bookingService.passport.areaCode;
    // this.draftReq.accompanyPhone = this.bookingService.passport.phone;

    this.workbench = this.workbenchService.workbench;
  }

  onBookingProductPlan(): void {

    const booking = plainToInstance(Booking, this.draftReq);

    const drawerRef = this.drawerService.create<BookingProductPlanComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: BookingProductPlanComponent,
      nzContentParams: {
        booking,
        product: this.product
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.productPlanSaved
        .subscribe(
          productPlanEntity => {
            this.draftReq = productPlanEntity.booking;
            this.draftReq.productCode = productPlanEntity.product.productCode;
            this.product = productPlanEntity.product;
            drawerRef.close();
          });
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });

  }

  onBookingOwner(): void {
    const instance = this.metadataService.mockInstance;
    const isOpenBankAccountRequired = this.draftReq.ownerType === CustomerType.INDIVIDUAL && instance.isWanson();
    this.translate.get('Holder').subscribe(
      (holder: any) => {
        const drawerRef = this.drawerService.create<CustomerEditComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: CustomerEditComponent,
          nzContentParams: {
            title: holder,
            mode: 'holder',
            modeType: 'booking',
            isCreate: true,
            customerType: this.draftReq.ownerType,
            customer: this.draftReq.owner,
            customerCorporate: this.draftReq.companyOwner,
            otherCustomer: this.draftReq.insured,
            otherCustomerCorporate: this.draftReq.companyInsured,
            isOpenBankAccountRequired
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.drawerRef = drawerRef;
          component.customerSaved
            .subscribe(
              customerEntity => {
                this.draftReq.ownerType = customerEntity.customerType;
                switch (customerEntity.customerType) {
                  case CustomerType.INDIVIDUAL:
                    this.draftReq.owner = customerEntity.customer;
                    break;
                  case CustomerType.CORPORATE:
                    this.draftReq.companyOwner = customerEntity.corporateCustomer;
                    break;
                }
              });
        });

        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  onBookingInsured(): void {
    this.translate.get('Insured').subscribe(
      (insured: any) => {

        const drawerRef = this.drawerService.create<CustomerEditComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: CustomerEditComponent,
          nzContentParams: {
            title: insured,
            mode: 'insured',
            modeType: 'booking',
            isCreate: true,
            customerType: this.draftReq.insuredType,
            relationship: this.draftReq.relationship,
            customer: this.draftReq.insured,
            customerCorporate: this.draftReq.companyInsured,
            otherCustomer: this.draftReq.owner,
            otherCustomerCorporate: this.draftReq.companyOwner
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.customerSaved
            .subscribe(
              customerEntity => {
                this.draftReq.insuredType = customerEntity.customerType;
                this.draftReq.relationship = customerEntity.relationship;
                switch (customerEntity.customerType) {
                  case CustomerType.INDIVIDUAL:
                    this.draftReq.insured = customerEntity.customer;
                    break;
                  case CustomerType.CORPORATE:
                    this.draftReq.companyInsured = customerEntity.corporateCustomer;
                    break;
                }
                drawerRef.close();
              });
        });

        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  onBookingBeneficiaries(): void {
    this.translate.get('Beneficiaries').subscribe(
      (title: any) => {
        const insured = this.draftReq.relationship === Relationship.OWN ? this.draftReq.owner : this.draftReq.insured;
        const drawerRef = this.drawerService.create<BeneficiariesEditComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: BeneficiariesEditComponent,
          nzContentParams: {
            title,
            beneficiaries: this.draftReq.beneficiaries,
            insured,
            bookingDetail: this.draftReq
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.beneficiariesSaved
            .subscribe(
              data => {
                this.draftReq.beneficiaries = data;
                drawerRef.close();
              });
        });

        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  onBookingInfo(): void {
    this.translate.get('BookingInfo').subscribe(
      (title: any) => {

        const booking = plainToInstance(Booking, this.draftReq);

        const drawerRef = this.drawerService.create<BookingInfoEditComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: BookingInfoEditComponent,
          nzContentParams: {
            title,
            booking,
            preUnderwritingEdit: true,
            isCreate: true
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.bookingSaved
            .subscribe(
              value => {
                this.draftReq = value;
                console.log(this.draftReq);
                drawerRef.close();
              });
        });

        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  /**
   * 关联计划书
   */
  onAssociateProposal(tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {

    if (this.draftReq.proposalId) {
      // 換成Dropdown Menu
      // this.showAssociateProposalModal(tplTitle, tplContent, tplFooter);
      return;
    }

    this.showProposalSelection();

  }

  showProposalSelection(): void {
    // 只能关联已完成的计划书
    const search = new ProposalSearch();
    search.status = ProposalStatus.COMPLETED;
    // search.productCode = this.draftReq.productCode;
    search.categoryCode = this.product.categoryCode;
    search.companyCode = this.product.companyCode;

    const drawerRef = this.drawerService.create<ProposalSelectionComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: ProposalSelectionComponent,
      nzContentParams: {
        search,
        enableOptions: true
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.proposalSelected
        .subscribe(
          value => {
            const proposal: Proposal = value as Proposal;
            this.draftReq.proposalId = proposal.proposalId;
            drawerRef.close();
          });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  showAssociateProposalModal(tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzOnOk: () => {
      }
    });
  }

  onProposalDetail(): void {
    if (!this.draftReq.proposalId) {
      return;
    }
    this.loading = true;
    this.proposalService.info(this.draftReq.proposalId)
      .subscribe(
        proposal => {
          this.loading = false;
          const drawerRef = this.drawerService.create<ProposalDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: ProposalDetailComponent,
            nzContentParams: {
              proposal
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onProposalReAssociate(): void {
    this.showProposalSelection();
  }

  onProposalDisassociate(): void {
    this.draftReq.proposalId = null;
  }

  assignFromProduct(product: Product): void {
    this.product = product;
    this.draftReq.productCode = product.productCode;
  }

  assignFromProposal(product: Product, proposal: Proposal): void {
    this.product = product;
    this.draftReq.productCode = product.productCode;
    this.draftReq.proposalId = proposal.proposalId;
    this.draftReq.productPlan.currency = proposal.currency;
    this.draftReq.productPlan.productYear = proposal.paymentTerm;
    this.draftReq.productPlan.premium = proposal.premium;
    this.draftReq.productPlan.sumAssured = proposal.sumAssured;
  }

  assignFromFna(product: Product, fna: Fna): void {
    this.product = product;
    this.draftReq.productCode = product.productCode;
    this.draftReq.fnaId = fna.id;
  }

  assignFromBooking(product: Product, booking: Booking): void {
    booking.fnaId = null;
    booking.owner = booking.owner ?? new Customer();
    booking.companyOwner = booking.companyOwner ?? new CustomerCorporate();
    booking.insured = booking.insured ?? new Customer();
    booking.companyInsured = booking.companyInsured ?? new CustomerCorporate();
    this.draftReq = booking;
    this.product = product;
    this.draftReq.productCode = product.productCode;
  }

  /**
   * 保存為草稿
   */
  onSaveDraft(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.bookingService.draft(this.draftReq)
      .subscribe(
        data => {
          this.loading = false;
          this.bookingDraftSaved.emit(data);
          // this.bookingService.toBeCompleted.emit(data.bookingNo);
          this.bookingService.toReloadList.emit(DataMode.OWN);
          this.drawerRef?.close();
        },
        error => {
          this.loading = false;
        });
  }

  /**
   * 提交預約
   */
  onSummit(): void {
    if (this.loading) {
      return;
    }
    if (this.instance.isWanson()) {
      if (this.draftReq.openBankAccount && this.draftReq.ownerType === 'INDIVIDUAL') {
        if (!this.draftReq.owner.companyName) {
          this.translate.get(['PleaseEnter', 'Holder', 'CompanyName']).subscribe(
            (res: any) => {
              this.toastr.warning(res.PleaseEnter + res.Holder + res.CompanyName);
            });
          return;
        }
        if (!this.draftReq.owner.companyType) {
          this.translate.get(['PleaseEnter', 'Holder', 'Occupation']).subscribe(
            (res: any) => {
              this.toastr.warning(res.PleaseEnter + res.Holder + res.Occupation);
            });
          return;
        }
        if (!this.draftReq.owner.position) {
          this.translate.get(['PleaseEnter', 'Holder', 'Position']).subscribe(
            (res: any) => {
              this.toastr.warning(res.PleaseEnter + res.Holder + res.Position);
            });
          return;
        }
        if (!this.instance.isFoneOrDev()) {
          if (!this.draftReq.owner.companyAddress.region && !this.draftReq.owner.companyAddress.city && !this.draftReq.owner.companyAddress.street && !this.draftReq.owner.companyAddress.location) {
            this.translate.get(['PleaseEnter', 'Holder', 'CompanyAddress']).subscribe(
              (res: any) => {
                this.toastr.warning(res.PleaseEnter + res.Holder + res.CompanyAddress);
              });
            return;
          }
        }
      }
    }
    this.loading = true;
    console.log(this.draftReq);
    this.bookingService.apply(this.draftReq)
      .subscribe(
        bookingNo => {
          this.loading = false;
          this.handleSubmitSuccess(bookingNo);
        },
        error => {
          this.loading = false;
        });
  }

  onBookingGroupSelect(): void {
    const drawerRef = this.drawerService.create<BookingGroupSelectionComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: BookingGroupSelectionComponent,
      nzContentParams: {
        groupNo: this.draftReq.groupNo
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.cancel.subscribe(data => {
        drawerRef.close();
      });
      component.confirm.subscribe(groupNo => {
        this.draftReq.groupNo = groupNo;
        drawerRef.close();
      });
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  async onBookingRemark(): Promise<void> {
    const title = await this.translate.get('BookingRemark').toPromise();
    const drawerRef = this.drawerService.create<CommonInputEditComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: CommonInputEditComponent,
      nzContentParams: {
        type: 'textarea',
        title,
        value: this.draftReq.special
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.saved.subscribe(
        text => {
          this.draftReq.special = text;
        }
      );
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onStatement(): void {
    const drawerRef = this.drawerService.create<ReinsuranceComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: ReinsuranceComponent,
      nzContentParams: {
        other: this.draftReq.other ? this.draftReq.other : new Other(),
      },
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.reinsuranceSaved
        .subscribe(
          data => {
            this.draftReq.other = JSON.parse(JSON.stringify(data));
            drawerRef.close();
          });
    });
    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  handleSubmitSuccess(bookingNo: string): void {

    /// 检查是否能够合并
    this.bookingService.checkMerge(bookingNo)
      .subscribe(
      checkMerge => {
        // 检查成功，处理
        this.handleCheckMerge(bookingNo, checkMerge);
      },
      error => {
        // 检查失败，直接关闭
        this.closeBySubmit(bookingNo);
      }
    );
  }

  closeBySubmit(bookingNo: string): void {
    this.bookingApplied.emit(bookingNo);
    this.bookingService.toBeCompleted.emit(bookingNo);
    this.bookingService.toReloadList.emit(DataMode.OWN);
    this.drawerRef?.close();
  }

  handleCheckMerge(bookingNo: string, checkMerge: BookingCheckMerge): void {
    if (checkMerge.merge) {
      this.showMergeModal(bookingNo, checkMerge);
    } else {
      this.closeBySubmit(bookingNo);
    }
  }

  showMergeModal(bookingNo: string, checkMerge: BookingCheckMerge): void {
    const modalRef = this.modalService.create({
      nzCentered: true,
      nzContent: BookingMergeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        bookingNo,
        checkMerge,
      },
      nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false
    });
    const component = modalRef.getContentComponent();
    modalRef.afterOpen.subscribe(() => {
      component.modalRef = modalRef;
    });
    // Return a result when closed
    modalRef.afterClose.subscribe(result => {
    });
    component.doNotMergeEvent.subscribe(() => {
      this.closeBySubmit(bookingNo);
    });
    component.mergedEvent.subscribe(() => {
      this.closeBySubmit(bookingNo);
    });
  }

  onSecondHolder(): void {
    this.translate.get('SecondHolder').subscribe(
      (title: any) => {
        const drawerRef = this.drawerService.create<SecondHolderEditComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: SecondHolderEditComponent,
          nzContentParams: {
            title,
            contingentOwner: this.draftReq.contingentOwner ? this.draftReq.contingentOwner : new ContingentOwner()
          },
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.secondHolderSaved
            .subscribe(
              secondHolderInfo => {
                this.draftReq.contingentOwner = JSON.parse(JSON.stringify(secondHolderInfo));
                drawerRef.close();
              }
            );
        });
        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }

  onSecondInsured(): void {
    this.translate.get('SecondInsured').subscribe(
      (title: any) => {
        const drawerRef = this.drawerService.create<SecondHolderEditComponent, { value: any }, string>({
          nzWidth: 800,
          nzMaskClosable: true,
          nzContent: SecondHolderEditComponent,
          nzContentParams: {
            title,
            modeType: 'booking',
            objectType: 'SecondInsured',
            contingentOwner: this.draftReq.contingentInsured ? this.draftReq.contingentInsured : new ContingentOwner()
          },
        });
        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.secondHolderSaved
            .subscribe(
              secondInsuredInfo => {
                this.draftReq.contingentInsured = JSON.parse(JSON.stringify(secondInsuredInfo));
                drawerRef.close();
              }
            );
        });
        drawerRef.afterClose.subscribe(data => {
          if (typeof data === 'string') {
          }
        });
      });
  }


}
