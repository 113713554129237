import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpContext, HttpContextToken, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { throttleTime } from 'rxjs/operators';
import { ApiResponse, Instance, Locale, Passport, RSASign } from '../api/types';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from '../base/constant';
import { environment } from '../../environments/environment';
import { API } from '../api/api';
import { LocaleService } from './locale.service';
import { EncryptorService } from '../shared/service/encryptor.service';
import { AppInjector } from './app-injector';
import { BaseService } from '../base/base.service';
import { EventService } from '../shared/service/event.service';

export enum AuthMode {
  STORAGE = 'STORAGE',
  MEMORY = 'MEMORY'
}

export const MODE_TOKEN = new HttpContextToken<AuthMode>(() => AuthMode.STORAGE);
export const INSTANCE_TOKEN = new HttpContextToken<Instance>(() => null);
export const PASSPORT_TOKEN = new HttpContextToken<Passport>(() => null);

@Injectable({
  providedIn: 'root'
})
export class HttpService extends BaseService {

  http: HttpClient;
  router: Router;
  translate: TranslateService;
  localeService: LocaleService;
  encryptorService: EncryptorService;
  toastr: ToastrService;
  eventService: EventService;

  apiErrorEmitter: EventEmitter<ApiResponse<any>> = new EventEmitter<ApiResponse<any>>();
  httpErrorEmitter: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

  memoryContext(instance: Instance, passport: Passport): HttpContext {
    const httpContext = new HttpContext();
    httpContext.set(MODE_TOKEN, AuthMode.MEMORY);
    httpContext.set(INSTANCE_TOKEN, instance);
    httpContext.set(PASSPORT_TOKEN, passport);
    return httpContext;
  }

  context(): HttpContext {
    const httpContext = new HttpContext();
    httpContext.set(MODE_TOKEN, AuthMode.STORAGE);
    httpContext.set(INSTANCE_TOKEN, this.instance);
    httpContext.set(PASSPORT_TOKEN, this.passport);
    return httpContext;
  }

  constructor() {
    super();
    const injector = AppInjector.injector;
    this.http = injector.get(HttpClient);
    this.router = injector.get(Router);
    this.translate = injector.get(TranslateService);
    this.localeService = injector.get(LocaleService);
    this.encryptorService = injector.get(EncryptorService);
    this.toastr = injector.get(ToastrService);
    this.eventService = injector.get(EventService);

    this.apiErrorEmitter
      .pipe(throttleTime(1000))
      .subscribe(
        data => {
          this.handleApiError(data);
        });
    this.httpErrorEmitter
      .pipe(throttleTime(1000))
      .subscribe(
        data => {
          this.handleHttpError(data);
        });
  }

  aspect<T>(observable: Observable<HttpResponse<T>>,
            onNext?: (data: HttpResponse<T>) => void,
            onError?: (data: any) => void,
            onComplete?: () => void): Observable<HttpResponse<T>> {
    return new Observable(subscriber => {
      return observable.subscribe(
        response => {
          if (response.headers.has(Constant.HTTP_HEADER_TOKEN)) {
            const passport = this.passport;
            passport.token = response.headers.get(Constant.HTTP_HEADER_TOKEN);
            this.passport = passport;
          }
          const apiResponse = response.body as unknown as ApiResponse<any>;
          if (apiResponse?.code === 1000) {
            if (onNext != null) {
              onNext(response);
            }
            subscriber.next(response);
          } else {
            if (onError != null) {
              onError(response);
            }
            subscriber.error(response);
          }
        },
        error => {
          if (onError != null) {
            onError(error);
          }
          subscriber.error(error);
        },
        () => {
          if (onComplete != null) {
            onComplete();
          }
          subscriber.complete();
        });
    });
  }

  get<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    context?: HttpContext;
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<T>> {
    options.context = options.context ? options.context : this.context();
    const response: Observable<HttpResponse<T>> = this.http.get<T>(url, options);
    return this.aspect(response,
      next => {
      },
      error => {
        this.handleError(error);
      },
      () => {
      }
    );
  }

  post<T>(url: string, body: any, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    context?: HttpContext;
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<T>> {
    options.context = options.context ? options.context : this.context();
    const response: Observable<HttpResponse<T>> = this.http.post<T>(url, body, options);
    return this.aspect(response,
      next => {
      },
      error => {
        this.handleError(error);
      },
      () => {
      }
    );
  }

  put<T>(url: string, body: any, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    context?: HttpContext;
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<T>> {
    options.context = options.context ? options.context : this.context();
    const response: Observable<HttpResponse<T>> = this.http.put<T>(url, body, options);
    return this.aspect(response,
      next => {
      },
      error => {
        this.handleError(error);
      },
      () => {
      }
    );
  }

  delete<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    context?: HttpContext;
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any | null;
  }): Observable<HttpResponse<T>> {
    options.context = options.context ? options.context : this.context();
    const response: Observable<HttpResponse<T>> = this.http.delete<T>(url, options);
    return this.aspect(response,
      next => {
      },
      error => {
        this.handleError(error);
      },
      () => {
      }
    );
  }

  patch<T>(url: string, body: any, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    context?: HttpContext;
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<T>> {
    options.context = options.context ? options.context : this.context();
    const response: Observable<HttpResponse<T>> = this.http.patch<T>(url, body, options);
    return this.aspect(response,
      next => {
      },
      error => {
        this.handleError(error);
      },
      () => {
      }
    );
  }

  head<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    context?: HttpContext;
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<T>> {
    options.context = options.context ? options.context : this.context();
    const response: Observable<HttpResponse<T>> = this.http.head<T>(url, options);
    return this.aspect(response,
      next => {
      },
      error => {
        this.handleError(error);
      },
      () => {
      }
    );
  }

  options<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    context?: HttpContext;
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<T>> {
    options.context = options.context ? options.context : this.context();
    const response: Observable<HttpResponse<T>> = this.http.options<T>(url, options);
    return this.aspect(response,
      next => {
      },
      error => {
        this.handleError(error);
      },
      () => {
      }
    );
  }

  download(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    context?: HttpContext;
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Blob>> {
    // const response: Observable<HttpResponse<Blob>> = this.http.get(url, options)
    // return this.aspect(response,
    //     next => {
    //     },
    //     error => {
    //         this.handleError(error);
    //     },
    //     () => {
    //     }
    // );
    options.context = options.context ? options.context : this.context();
    return this.http.get(url, options);
  }

  getArrayBuffer(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<ArrayBuffer> {
    options.context = options.context ? options.context : this.context();
    return this.http.get(url, options);
  }

  handleError(error: HttpErrorResponse | HttpResponse<any>): void {
    if (error instanceof HttpResponse) {
      const httpResponse = error as HttpResponse<any>;
      const apiResponse = httpResponse.body as unknown as ApiResponse<any>;
      if (apiResponse) {
        this.apiErrorEmitter.emit(apiResponse);
      }
    }
    if (error instanceof HttpErrorResponse) {
      const errorResponse = error as HttpErrorResponse;
      this.httpErrorEmitter.emit(errorResponse);
      // this.toastr.error(errorResponse.message);
    }
  }

  handleApiError(error: ApiResponse<any>): void {
    if (error) {
      console.error('ERROR CODE: ' + error.code);
      switch (error.code) {
        // Unauthorized
        case 401: {
          this.passport = null;
          this.translate.get('AuthenticationInValid').subscribe(
            (val: string) => {
              this.toastr.warning(val);
            });
          this.router.navigate(['/auth/login-by-account']).then();
          break;
        }
        case 400: {
          this.toastr.warning(error.message ?? '');
          break;
        }
        // 组织码错误
        case 100: {
          this.translate.get('InvalidOrgCode').subscribe((message: string) => {
            this.toastr.warning(message);
          });
          break;
        }
        case 1054:
          this.eventService.verifyIntercept.next();
          break;
        case 2322: // 系統關閉
        case 2906: // 登录超时
        case 2902: // 账号被禁用或已被刪除
        case 2315: // 账号禁用
        case 5555: {
          this.toastr.warning(error.message ?? '');
          this.router.navigate(['/auth/login-by-account']).then();
          break;
        }
        default: {
          this.toastr.warning(error.message ?? '');
        }
      }
    }
  }

  handleHttpError(error: HttpErrorResponse): void {
    if (error) {
      switch (error.status) {
        // Unauthorized
        case 401: {
          this.passport = null;
          this.translate.get('AuthenticationInValid').subscribe(
            (val: string) => {
              this.toastr.warning(val);
            });
          this.router.navigate(['/auth/login-by-account']).then();
          break;
        }
        case 400: {
          const apiResponse = error.error as unknown as ApiResponse<any>;
          if (apiResponse) {
            this.apiErrorEmitter.emit(apiResponse);
          }
          break;
        }
        case 403: {
          const apiResponse = error.error as unknown as ApiResponse<any>;
          if (apiResponse) {
            this.apiErrorEmitter.emit(apiResponse);
          }
          break;
        }
        default: {
          this.translate.get('NetworkErrorTryAgainLater').subscribe(
            (val: string) => {
              this.toastr.warning(val);
            });
        }
      }
    }
  }

  getApiHttpHeaders(url: string): Map<string, string> {
    const headers = new Map<string, string>();
    headers.set(Constant.HTTP_HEADER_VERSION, environment.VERSION);
    headers.set(Constant.HTTP_HEADER_DEVICE_ID, this.uuid);
    headers.set(Constant.HTTP_HEADER_OS, this.os);
    headers.set(Constant.HTTP_HEADER_SYSTEM, this.deviceInfo.device);

    if (environment.target === 'WEB') {
      headers.set(Constant.HTTP_HEADER_CLIENT_TYPE, 'SALES_WEB');
    } else {
      headers.set(Constant.HTTP_HEADER_CLIENT_TYPE, 'SALES_DESKTOP');
    }

    headers.set(Constant.HTTP_HEADER_MODEL, 'Electron');
    headers.set(Constant.HTTP_HEADER_LANGUAGE, this.language());

    if (this.instance != null) {
      headers.set(Constant.HTTP_HEADER_INSTANCE, this.instance.key);
    }

    if (this.mockInstanceKey != null) {
      headers.set(Constant.HTTP_HEADER_INSTANCE_MOCK, this. mockInstanceKey);
    }

    if (this.pinCode != null) {
      headers.set(Constant.HTTP_HEADER_PIN_CODE, this.pinCode);
    }

    if (this.passport != null) {
      const token = this.passport.token;
      headers.set(Constant.HTTP_HEADER_TOKEN, token);

      url = url.replace(API.baseUrl, '');
      const tokens = token.split('.');
      const signToken = tokens[tokens.length - 1];
      const timestamp = (new Date()).valueOf().toString();
      const rsaSign = new RSASign(this.uuid, signToken, url, timestamp);
      const sign = this.encryptorService.RSAEncrypt(JSON.stringify(rsaSign));
      headers.set(Constant.HTTP_HEADER_TIMESTAMP, timestamp);
      headers.set(Constant.HTTP_HEADER_SIGN_VERSION, 'Venus');
      headers.set(Constant.HTTP_HEADER_SIGN, sign);
    }
    return headers;
  }

  getApiHttpHeadersByContext(url: string, context: HttpContext): Map<string, string> {

    const instance = context.get(INSTANCE_TOKEN);
    const passport = context.get(PASSPORT_TOKEN);

    const headers = new Map<string, string>();
    headers.set(Constant.HTTP_HEADER_VERSION, environment.VERSION);
    headers.set(Constant.HTTP_HEADER_DEVICE_ID, this.uuid);
    headers.set(Constant.HTTP_HEADER_OS, this.os);
    headers.set(Constant.HTTP_HEADER_SYSTEM, this.deviceInfo.device);

    if (environment.target === 'WEB') {
      headers.set(Constant.HTTP_HEADER_CLIENT_TYPE, 'SALES_WEB');
    } else {
      headers.set(Constant.HTTP_HEADER_CLIENT_TYPE, 'SALES_DESKTOP');
    }

    headers.set(Constant.HTTP_HEADER_MODEL, 'Electron');
    headers.set(Constant.HTTP_HEADER_LANGUAGE, this.language());

    if (instance != null) {
      headers.set(Constant.HTTP_HEADER_INSTANCE, instance.key);
    }

    if (this.mockInstanceKey != null) {
      headers.set(Constant.HTTP_HEADER_INSTANCE_MOCK, this.mockInstanceKey);
    }

    if (this.pinCode != null) {
      headers.set(Constant.HTTP_HEADER_PIN_CODE, this.pinCode);
    }

    if (passport != null) {
      const token = passport.token;
      headers.set(Constant.HTTP_HEADER_TOKEN, token);

      url = url.replace(API.baseUrl, '');
      const tokens = token.split('.');
      const signToken = tokens[tokens.length - 1];
      const timestamp = (new Date()).valueOf().toString();
      const rsaSign = new RSASign(this.uuid, signToken, url, timestamp);
      const sign = this.encryptorService.RSAEncrypt(JSON.stringify(rsaSign));
      headers.set(Constant.HTTP_HEADER_TIMESTAMP, timestamp);
      headers.set(Constant.HTTP_HEADER_SIGN_VERSION, 'Venus');
      headers.set(Constant.HTTP_HEADER_SIGN, sign);
    }
    return headers;
  }

  get os(): string {
    switch (this.deviceInfo.os) {
      case 'Mac':
        return 'macOS';
      case 'Windows':
        return 'Windows';
      default:
        return this.deviceInfo.os;
    }
  }

  language(): string {
    let str = '';
    switch (this.localeService.locale) {
      case Locale.cn:
        str = Locale.cn;
        break;
      case Locale.hk:
        str = Locale.hk;
        break;
      case Locale.en:
        str = Locale.en;
        break;
      case Locale.jp:
        str = 'ja-JP';
        break;
      case Locale.ko:
        str = 'ko-KR';
        break;
    }
    return str;
  }
}
