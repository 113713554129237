import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstanceCodeComponent } from './instance-code/instance-code.component';
import { LoginByAccountComponent } from './login-by-account/login-by-account.component';
import { LoginByCodeComponent } from './login-by-code/login-by-code.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { GuideGalleryComponent } from './guide-gallery/guide-gallery.component';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { SwiperModule } from 'swiper/angular';
import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { OpenAuthComponent } from './open-auth/open-auth.component';
import { AccountSwitchComponent } from './account-switch/account-switch.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { AccountAddComponent } from './account-add/account-add.component';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { DeitPasswordComponent } from './deit-password/deit-password.component';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import { PhoneNumberLoginComponent } from './phone-number-login/phone-number-login.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';



@NgModule({
  declarations: [
    InstanceCodeComponent,
    LoginByAccountComponent,
    LoginByCodeComponent,
    GuideGalleryComponent,
    ForgotPasswordComponent,
    OpenAuthComponent,
    AccountSwitchComponent,
    AccountAddComponent,
    DeitPasswordComponent,
    PhoneNumberLoginComponent,
  ],
  imports: [
    CommonModule,
    NzInputModule,
    FormsModule,
    NzButtonModule,
    NzGridModule,
    NzToolTipModule,
    NzIconModule,
    TranslateModule,
    NzDividerModule,
    NzCarouselModule,
    SwiperModule,
    SharedModule,
    NzAvatarModule,
    NzStepsModule,
    NzCheckboxModule,
    NzSelectModule,
    NzDrawerModule
  ]
})
export class AuthModule { }
