<div class="flex-row section-wrapper" style="margin: 10px 0 10px 0; padding: 5px">
  <div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: center; flex: 1">
    <div style="font-weight: bold; font-size: 17px; color: #404040; margin: 10px 0 5px 10px ">{{product.productName}}</div>
    <div style="margin: 0 0 5px 10px">
                  <span class="tag-sm">
                    {{product.categoryName}}
                  </span>
    </div>
    <div style="margin: 0 0 0 10px; font-size: 12px">
      {{product.desc | ellipsis: 40}}
    </div>
  </div>
  <div>
    <img width="60" [src]="product.logo"/>
  </div>
  <div *ngIf="showDeleteButton">
    <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate" appThrottleClick (throttleClick)="deleteEvent.emit(product)">
      <img alt="" width="22" src="assets/images/ic_delete.svg">
    </a>
  </div>
</div>
