import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../../../product/product-types';

@Component({
  selector: 'app-product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.less']
})
export class ProductListItemComponent implements OnInit {

  @Input()
  product: Product;

  @Input()
  showDeleteButton = false;

  @Output()
  deleteEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

}
