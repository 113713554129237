<div *ngIf="product.highlights">
  <div *ngFor="let highlight of product.highlights" style="padding: 10px 0 10px 0">
    <div style="display: flex; flex-direction: row">
      <div>
        <img alt="" width="20" src="assets/images/ic_highlight.svg">
      </div>
      <div style="margin: 0 0 0 10px">
        {{highlight}}
      </div>
    </div>
    <div class="property-bottom-line" style="margin: 12px 0 0 0"></div>
  </div>
</div>
<div *ngIf="!product.highlights">
  <nz-empty nzNotFoundImage="simple"></nz-empty>
</div>
