import { Instance, InstanceAccount, Passport } from '../api/types';
import { Constant } from './constant';
import { UUID } from 'angular2-uuid';
import { AppInjector } from '../core/app-injector';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { plainToInstance } from 'class-transformer';
import { plainToArray } from '../shared/utils/class-transform';
import { FingerprintService } from '../shared/service/fingerprint.service';
import { EventEmitter, Output } from '@angular/core';

export class BaseService {

  deviceInfo: DeviceInfo = null;

  deviceService: DeviceDetectorService;
  fingerprintService: FingerprintService;

  constructor() {
    const injector = AppInjector.injector;
    this.deviceService = injector.get(DeviceDetectorService);
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.fingerprintService = injector.get(FingerprintService);
  }

  get redirectUrl(): string {
    return localStorage.getItem(Constant.LOCAL_STORAGE_KEY_REDIRECT_URL);
  }

  set redirectUrl(val: string) {
    localStorage.setItem(Constant.LOCAL_STORAGE_KEY_REDIRECT_URL, val);
  }

  get uuid(): string {
    let value: string = localStorage.getItem(Constant.LOCAL_STORAGE_KEY_UUID);
    if (value === null) {
      // value = UUID.UUID();
      value = this.fingerprintService.fingerprint;
      this.uuid = value;
    }
    return value;
  }

  set uuid(val: string) {
    localStorage.setItem(Constant.LOCAL_STORAGE_KEY_UUID, val);
  }

  get instance(): Instance {
    const value: string = localStorage.getItem(Constant.LOCAL_STORAGE_KEY_INSTANCE);
    return plainToInstance(Instance, JSON.parse(value));
  }

  set instance(val: Instance) {
    localStorage.setItem(Constant.LOCAL_STORAGE_KEY_INSTANCE, JSON.stringify(val));
  }

  get passport(): Passport {
    const value: string = localStorage.getItem(Constant.LOCAL_STORAGE_KEY_PASSPORT);
    return plainToInstance(Passport, JSON.parse(value));
  }

  set passport(val: Passport) {
    localStorage.setItem(Constant.LOCAL_STORAGE_KEY_PASSPORT, JSON.stringify(val));
  }

  get accounts(): InstanceAccount[] {
    const value: string = localStorage.getItem(Constant.LOCAL_STORAGE_KEY_ACCOUNTS);
    let list: InstanceAccount[] = [];
    if (value) {
      const array = JSON.parse(value);
      list = plainToArray(InstanceAccount, array);
    }
    return list;
  }

  set accounts(val: InstanceAccount[]) {
    localStorage.setItem(Constant.LOCAL_STORAGE_KEY_ACCOUNTS, JSON.stringify(val));
  }

  get pinCode(): string {
    const TOKEN_LIVE_TIME = 30 * 60000;
    const LoginTime = parseInt(localStorage.getItem('codePinDate'), 0);
    if (LoginTime + TOKEN_LIVE_TIME < Date.now()) {
      localStorage.removeItem('codePin');
    }
    return localStorage.getItem('codePin');
  }

  get mockInstanceKey(): string {
    return localStorage.getItem(Constant.LOCAL_STORAGE_KEY_MOCK_INSTANCE_KEY);
  }

  get mockInstance(): Instance {
    const value: string = localStorage.getItem(Constant.LOCAL_STORAGE_KEY_INSTANCE);
    const mock =  localStorage.getItem(Constant.LOCAL_STORAGE_KEY_MOCK_INSTANCE_KEY);
    const values = JSON.parse(value);
    if (values.groupKey === 'dev') {
      if (mock) {
        values.groupKey = mock;
      }
    }
    return plainToInstance(Instance, values);
  }

}
