import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BasicInfoSave, FinancialSave, FnaQuestionNoAndResult, NewFnaDetail, QuestionsArr } from '../../new-fna-types';
import { FnaQuestion } from '../../../fna/fna-types';
import { plainToArray } from '../../../shared/utils/class-transform';
import { NewFnaService } from '../../new-fna.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Tool } from '../../../shared/utils/tool';
import { KeyValue } from '../../../api/types';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-new-fna-form',
  templateUrl: './new-fna-form.component.html',
  styleUrls: ['./new-fna-form.component.less']
})

export class NewFnaFormComponent implements OnInit {
  fnaForm: KeyValue[] = [];
  fnaInfo: NewFnaDetail = new NewFnaDetail();
  fnaQuestions: FnaQuestion[] = [];
  isRequired = true;
  flag = false;
  errorText = '';
  obj: FnaQuestionNoAndResult[] = [];
  @Output()
  formSaveChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private newFnaService: NewFnaService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    console.log(QuestionsArr);
    this.fnaQuestions = plainToArray(FnaQuestion, QuestionsArr);
    console.log(this.fnaQuestions, 'fnaQuestionsfnaQuestionsfnaQuestionsfnaQuestions');
    this.setForms(this.fnaInfo);
  }


  setForms(fnaInfo: NewFnaDetail): void {
    // let arr = Object.keys(this.basicInformation);
    // arr.forEach((e) => {
    //   this.basicInformation[e].value = fnaInfo.basicInformation[e] || undefined;
    // });
    console.log(this.obj);
    fnaInfo.financialAnalysis.map(item => {
      const copy = new FnaQuestionNoAndResult();
      console.log(copy);
      this.obj.push(Object.assign(copy, item));
    });

    console.log(this.obj, 'type');

    if (fnaInfo.financialAnalysis && fnaInfo.financialAnalysis.length) {
      const formObj = fnaInfo.financialAnalysis.reduce((prev, c) => ({...prev, [c.key]: c.value}), {});
      console.log(formObj);

      this.fnaQuestions.forEach(question => {
        if (question.answers) {
          question.answers.forEach(answer => {
            switch (answer.moduleType) {
              case 'CHECKBOX_WITH_INPUT':
                answer.tick = formObj[answer.key];
                answer.inputBox.forEach(item => {
                  item.value = formObj[item.key];
                });
                break;
              case 'RADIO_WITH_INPUT':
                if (answer.key == 'FNA_06_a_A') {
                  answer.tick = formObj[answer.key];
                  // answer.subQuestions.forEach(item => {
                  //   item.answers.forEach(answerItem => {
                  //     answerItem.inputBox.forEach(inputBoxItem => {
                  //       inputBoxItem.value = formObj[inputBoxItem.key];
                  //       console.log(11111111, inputBoxItem);
                  //     });
                  //   });
                  // });
                } else {
                  answer.tick = formObj[answer.key];
                  answer.inputBox.forEach(item => {
                    item.value = formObj[item.key];
                  });
                }
                break;
              case 'RADIO':
                if (answer.key == 'FNA_06_b_A') {
                  answer.tick = formObj[answer.key];
                  // answer.subQuestions.forEach(item => {
                  //   item.answers.forEach(answerItem => {
                  //     answerItem.inputBox.forEach(inputBoxItem => {
                  //       inputBoxItem.value = formObj[inputBoxItem.key];
                  //     });
                  //   });
                  // });
                } else {
                  answer.tick = formObj[answer.key];
                }
                break;
              case 'CHECKBOX':
                answer.tick = formObj[answer.key];
                break;
              case 'INPUT_BOX':
                answer.inputBox.forEach(item => {
                  if (answer.key == 'FNA_05_b') {
                    item.value = formObj[answer.key];
                  }
                  if (question.subKey === 'FNA_06_a_A_Sub') {
                    answer.inputBox.forEach(inputBoxItem => {
                      inputBoxItem.value = formObj[inputBoxItem.key];
                    });
                    // item.value = formObj[answer.key];
                  }
                  if (question.subKey === 'FNA_06_b_A_Sub_a') {
                    // item.value = formObj[answer.key];
                    answer.inputBox.forEach(inputBoxItem => {
                      inputBoxItem.value = formObj[inputBoxItem.key];
                    });
                  }
                  if (question.subKey === 'FNA_06_b_A_Sub_b') {
                    // item.value = formObj[answer.key];
                    answer.inputBox.forEach(inputBoxItem => {
                      inputBoxItem.value = formObj[inputBoxItem.key];
                    });
                  }
                });
                break;
            }
          });
        }
      });
    }

    // const newQuestions = [];
    // const copyQuestionsArr = JSON.parse(JSON.stringify(this.fnaQuestions));
    // copyQuestionsArr.forEach((fnaQuestion) => {
    //   newQuestions.push(fnaQuestion);
    //   if (fnaQuestion.answers) {
    //     fnaQuestion.answers.forEach((answer) => {
    //       if (answer.subQuestions) {
    //         newQuestions.push(...answer.subQuestions);
    //       }
    //     });
    //   }
    // });
    //
    // this.fnaQuestions = JSON.parse(JSON.stringify(newQuestions));
  }


  onSave(): void {
    console.log(this.fnaQuestions);
    this.canSave();
    if (!this.isRequired) {
      this.translate.get('ValidateEmpty').subscribe(
        (res) => {
          this.toastr.warning(this.errorText + ': ' + res);
        }
      );
      return;
    }

    let arr2 = [];
    console.log(this.fnaQuestions, 'sdjfiosdjfjsdifjsdiofjiods');
    this.fnaQuestions.forEach(question => {
      if (question.answers) {
        question.answers.forEach(answer => {
          // console.log(answer);
          switch (answer.moduleType) {
            case 'CHECKBOX_WITH_INPUT':
              arr2.push({key: answer.key, value: answer.tick});
              answer.inputBox.forEach(item => {
                arr2.push({key: item.key, value: item.value});
              });
              break;
            case 'RADIO_WITH_INPUT':
              if (answer.key == 'FNA_06_a_A') {
                arr2.push({key: answer.key, value: answer.tick});
                // answer.subQuestions.forEach(item => {
                //   item.answers.forEach(answerItem => {
                //     answerItem.inputBox.forEach(inputBoxItem => {
                //       arr2.push({key: inputBoxItem.key, value: inputBoxItem.value});
                //     });
                //   });
                // });
              } else {
                arr2.push({key: answer.key, value: answer.tick});
                answer.inputBox.forEach(item => {
                  arr2.push({key: item.key, value: item.value});
                });
              }
              break;
            case 'RADIO':
              if (answer.key == 'FNA_06_b_A') {
                arr2.push({key: answer.key, value: answer.tick});
                console.log(answer, '000000');
                // answer.subQuestions.forEach(item => {
                //   item.answers.forEach(answerItem => {
                //     answerItem.inputBox.forEach(inputBoxItem => {
                //       arr2.push({key: inputBoxItem.key, value: inputBoxItem.value});
                //     });
                //   });
                // });
              } else {
                arr2.push({key: answer.key, value: answer.tick});
              }
              break;
            case 'CHECKBOX':
              arr2.push({key: answer.key, value: answer.tick});
              break;
            case 'INPUT_BOX':
              answer.inputBox.forEach(item => {
                if (answer.key == 'FNA_05_b') {
                  arr2.push({key: answer.key, value: item.value});
                }
                if (question.subKey === 'FNA_06_a_A_Sub') {
                  answer.inputBox.forEach(inputBoxItem => {
                    arr2.push({key: inputBoxItem.key, value: inputBoxItem.value});
                  });
                }
                if (question.subKey === 'FNA_06_b_A_Sub_a') {
                  answer.inputBox.forEach(inputBoxItem => {
                    arr2.push({key: inputBoxItem.key, value: inputBoxItem.value});
                  });
                }
                if (question.subKey === 'FNA_06_b_A_Sub_b') {
                  answer.inputBox.forEach(inputBoxItem => {
                    arr2.push({key: inputBoxItem.key, value: inputBoxItem.value});
                  });
                }
              });
              break;
            default:
              console.log(answer);
              break;
          }
        });
      }
    });
    console.log(arr2, 'arr2arr2arr2arr2arr2');
    const param = new FinancialSave();
    param.id = this.fnaInfo.id;
    param.dataList = JSON.parse(JSON.stringify(arr2));
    console.log(param, 'basicInformation');
    this.newFnaService.updateFinancial(param)
      .subscribe(data => {
        console.log(data, 'basicInfo');
        this.formSaveChange.emit(data);
      });
  }

  onCancel(): void {
    console.log('onCancel3333');
    this.formSaveChange.emit(this.fnaInfo.id);
  }

  canSave(): void {
    this.isRequired = true;
    this.errorText = '';
    try {
      this.fnaQuestions.forEach(question => {
        if (question.answers) {
          this.flag = false;
          question.answers.forEach(answer => {
            switch (answer.moduleType) {
              case 'CHECKBOX_WITH_INPUT':
                if (answer.tick) {
                  this.flag = true;
                  answer?.inputBox?.forEach(item => {
                    if (Tool.isEmpty(item.value)) {
                      this.isRequired = false;
                      this.errorText = `${question.questionTitle ? question.questionTitle : question.title} — ${answer.description}`;
                      throw Error();
                    }
                  });
                }
                break;
              case 'RADIO_WITH_INPUT':
                if (answer.tick) {
                  this.flag = true;
                  answer?.inputBox?.forEach(item => {
                    if (Tool.isEmpty(item.value)) {
                      this.isRequired = false;
                      this.errorText = `${question.questionTitle ? question.questionTitle : question.title} — ${answer.description}`;
                      throw Error();
                    }
                  });
                }
                break;
              case 'RADIO':
                if (answer.tick) {
                  this.flag = true;
                }
                break;
              case 'CHECKBOX':
                if (answer.tick) {
                  this.flag = true;
                }
                break;
              case 'INPUT_BOX':
                answer.inputBox.forEach(item => {
                  if (answer.key == 'FNA_05_b') {
                    if (Tool.isEmpty(item.value)) {
                      this.isRequired = false;
                      this.errorText = `${question.questionTitle ? question.questionTitle : question.title} — ${answer.description}`;
                      throw Error();
                    } else {
                      this.flag = true;
                    }
                  } else {
                    this.flag = true;
                  }
                });
                break;
              default:
                console.log(answer);
                break;
            }
          });
          if (!this.flag) {
            this.isRequired = false;
            this.errorText = `${question.questionTitle ? question.questionTitle : question.title}`;
            throw Error();
          }
        }
      });
    }catch (error) {
      console.log(error);
    }
  }
}

