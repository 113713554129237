<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'E-Signature' | translate}}
    </div>

<!--    <div class="section-wrapper" style="">-->
<!--      <app-property-pick [title]="'ViewFna' | translate"-->
<!--                         appThrottleClick (throttleClick)="onViewFna()"-->
<!--                         [bottomLineVisible]="false"></app-property-pick>-->
<!--    </div>-->

    <div *ngIf="workbench.fnaSign !== 0">
      <div *ngIf="workbench.fnaSign !== 2" class="section-wrapper" style="">
        <app-property-pick [title]="'ConsultantSignature' | translate"
                           appThrottleClick (throttleClick)="onConsultantSignature()"
                           [value]="fna.salesSignedStatus | metadataTranslate:'fnaSignedStatus'"
                           [valueColor]="fna.salesSignedStatus === 'SIGNED' ? '#1D64C4' : '#e63757'"
                           [bottomLineVisible]="false"></app-property-pick>
      </div>
      <div *ngIf="workbench.fnaSign !== 1" class="section-wrapper" style="">
        <app-property-pick [title]="'ClientSignature' | translate"
                           appThrottleClick (throttleClick)="onClientSignature()"
                           [value]="fna.ownerSignedStatus | metadataTranslate:'fnaSignedStatus'"
                           [valueColor]="fna.ownerSignedStatus === 'SIGNED' ? '#1D64C4' : '#e63757'"
                           [bottomLineVisible]="false"></app-property-pick>
      </div>
    </div>
    <div class="section-wrapper" style="">
      <app-property-text [title]="'Date' | translate"
                         [value]="fna.salesSignedDate | date:'yyyy-MM-dd'"
                         [bottomLineVisible]="false"></app-property-text>
    </div>
    <div class="section-wrapper" style="">
      <app-property-text *ngIf="workbench.fnaSign !== 0"
                         [title]="'Status' | translate"
                         [value]="fna.signedStatus | metadataTranslate:'fnaSignedStatus'"
                         [valueColor]="fna.signedStatus === 'SIGNED' ? '#1D64C4' : '#e63757'"
                         [bottomLineVisible]="false"></app-property-text>

      <app-property-text *ngIf="workbench.fnaSign === 0"
                         [title]="'Status' | translate"
                         [value]="'不需要签名'"
                         [valueColor]="fna.signedStatus === 'SIGNED' ? '#1D64C4' : '#e63757'"
                         [bottomLineVisible]="false"></app-property-text>
    </div>
  </nz-spin>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div *ngIf="fna.signedStatus === 'UNSIGNED'" nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" [disabled]="!canSubmit"
           appThrottleClick (throttleClick)="onSubmit()"
           [style]="!canSubmit ? 'opacity: 0.5' : ''">
          <span>{{'SubmitSignature' | translate}}</span>
        </a>
      </div>
      <div *ngIf="fna.signedStatus !== 'UNSIGNED'" nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" [disabled]="fna.signedStatus === 'UNSIGNED'"
           appThrottleClick (throttleClick)="onResign(tplTitle, tplContent, tplFooter)"
           [style]="fna.signedStatus === 'UNSIGNED' ? 'opacity: 0.5' : ''">
          <span>{{'Re-Sign' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>

<ng-template #tplTitle>
  <span>{{'Re-Sign' | translate}}</span>
</ng-template>
<ng-template #tplContent let-params>
  <p>{{'DoYouWantToReSignFna' | translate}}?</p>
</ng-template>
<ng-template #tplFooter let-ref="modalRef">
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()" [disabled]="loading">{{'Cancel' | translate}}</button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onReSignConfirm(ref)" [nzLoading]="loading">
    {{'Re-Sign' | translate}}
  </button>
</ng-template>
