import {Component, OnInit} from '@angular/core';
import {DomainMetadataVOS, Values} from '../../../api/types';

@Component({
  selector: 'app-domain-metadata-read',
  templateUrl: './domain-metadata-read.component.html',
  styleUrls: ['./domain-metadata-read.component.less']
})
export class DomainMetadataReadComponent implements OnInit {
  title: string;
  domainMetadata: DomainMetadataVOS[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }


  onSingleBox(val, arr: Values[]): any {
    let res: any;
    if (val) {
      res = arr.find(item => item.id === val);
      return res.name;
    } else {
      return '';
    }
  }

  onMultiCheckBoxes(info: DomainMetadataVOS): any {
    if (!info.value) {
      return '';
    }
    const newArr = [];
    const paramArr = JSON.parse(info.value);
    if (paramArr.length) {
      for (const item of paramArr) {
        newArr.push(info.values.find( value => value.id === item).name);
      }
      return newArr.toString();
    } else {
      return '';
    }
  }
}
