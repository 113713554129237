<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{'CertificateUpload' | translate}}
  </div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'IDCardInfo' | translate}}({{'FrontAndBack' | translate}})</span>
    </div>
    <div *ngIf="info.signedStatus == 'SIGNED'">
      <app-attachments *ngIf="info.identityCard?.length > 0"
                       [attachments]="info.identityCard"
                       [readonly]="true"></app-attachments>
      <nz-empty *ngIf="!info.identityCard?.length > 0" nzNotFoundImage="simple"></nz-empty>
    </div>
    <div *ngIf="info.signedStatus == 'UNSIGNED'">
      <app-attachments [attachments]="info.identityCard"
                       [uploadTokenReq]="uploadTokenReq"
                       [fileFormat]="'.png, .jpeg, .jpg'"
                       [isPreview]="true"
                       (valueChange)="identityCardsChange($event)"
                       [showEmpty]="false"></app-attachments>
    </div>
    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'PassportNumber' | translate}}</span>
    </div>
    <div *ngIf="info.signedStatus == 'SIGNED'">
      <app-attachments *ngIf="passportArr.length > 0"
                       [attachments]="passportArr"
                       [readonly]="true"></app-attachments>
      <nz-empty *ngIf="!passportArr.length > 0" nzNotFoundImage="simple"></nz-empty>
    </div>
    <div *ngIf="info.signedStatus == 'UNSIGNED'">
      <app-attachments [attachments]="passportArr"
                       [uploadTokenReq]="uploadTokenReq"
                       [fileFormat]="'.png, .jpeg, .jpg'"
                       [isPreview]="true"
                       (valueChange)="passportArrChange($event)"
                       [showEmpty]="false"></app-attachments>
    </div>
    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'ProofOfAddress' | translate}}</span>
    </div>
    <div *ngIf="info.signedStatus == 'SIGNED'">
      <app-attachments *ngIf="addressArr.length > 0"
                       [attachments]="addressArr"
                       [readonly]="true"></app-attachments>
      <nz-empty *ngIf="!addressArr.length > 0" nzNotFoundImage="simple"></nz-empty>
    </div>
    <div *ngIf="info.signedStatus == 'UNSIGNED'">
      <app-attachments [attachments]="addressArr"
                       [uploadTokenReq]="uploadTokenReq"
                       [fileFormat]="'.png, .jpeg, .jpg'"
                       [isPreview]="true"
                       (valueChange)="addressArrChange($event)"
                       [showEmpty]="false"></app-attachments>
    </div>
  </nz-spin>
  <div *ngIf="info.signedStatus == 'UNSIGNED'" class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
