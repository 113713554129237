import {Component, EventEmitter, OnInit} from '@angular/core';
import {PactDetails, PactSearch} from '../sales-pact-types';
import {SalesPactService} from '../sales-pact.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {PdfListComponent} from '../pdf-list/pdf-list.component';
import {SalesPrimaryContactComponent} from '../sales-primary-contact/sales-primary-contact.component';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {CertificateUploadComponent} from '../components/certificate-upload/certificate-upload.component';
import {SalesPactSignatureComponent} from '../components/sales-pact-signature/sales-pact-signature.component';

@Component({
  selector: 'app-sales-pact-details',
  templateUrl: './sales-pact-details.component.html',
  styleUrls: ['./sales-pact-details.component.less']
})
export class SalesPactDetailsComponent implements OnInit {
  loading = false;
  info: PactDetails = new PactDetails();
  afterRecord: EventEmitter<any> = new EventEmitter<any>();
  id: number; // 合同id

  constructor(private salesPactService: SalesPactService,
              private drawerService: NzDrawerService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  onRefresh(): void {
    const param = new PactSearch();
    param.id = this.id.toString();
    this.loading = true;
    this.salesPactService.info(param)
      .subscribe(
        data => {
          this.info = data;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      );
  }

  onViewPDF(): void {
    this.loading = true;
    this.salesPactService.viewPdf()
      .subscribe(
        data => {
          this.loading = false;
          const drawerRef = this.drawerService.create<PdfListComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: PdfListComponent,
            nzMask: false,
            nzContentParams: {
              pdfList: data,
              id: this.id
            }
          });
        }, error => {
          this.loading = false;
        });
  }

  onSave(): void {
    const param = {
      id: this.id,
      personalInfo: this.info.personalInfo, // 个人信息
      bankInfo: this.info.bankInfo, // 银行信息
    };
    this.salesPactService.updateInfo(param)
      .subscribe(
        data => {
          if (data) {
            this.translate.get('ModifySuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          }
          this.afterRecord.emit('');
        },
        error => {
        }
      );
  }

  onPrimary(): void {
    const drawerRef = this.drawerService.create<SalesPrimaryContactComponent, { value: any }, string>({
      nzWidth: 600,
      nzContent: SalesPrimaryContactComponent,
      nzMask: false,
      nzContentParams: {
        listData: this.info,
        id: this.id
      }
    });
    drawerRef.afterClose.subscribe(data => {
      this.onRefresh();
    });
  }

  openCertificateUpload(): void {
    const drawerRef = this.drawerService.create<CertificateUploadComponent, { value: any }, string>({
      nzWidth: 800,
      nzContent: CertificateUploadComponent,
      nzMask: false,
      nzContentParams: {
        info: this.info,
        id: this.id
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.afterRecord
        .subscribe(
          data => {
            drawerRef.close();
            this.onRefresh();
          }
        );
    });
  }

  onConsultantSignature(): void {
    if (this.info.signedStatus === 'SIGNED') {
      this.translate.get('PactDeleteTips').subscribe(
        (res) => {
          this.toastr.info(res);
        }
      );
      return;
    }
    const drawerRef = this.drawerService.create<SalesPactSignatureComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: SalesPactSignatureComponent,
      nzContentParams: {
        id: this.id
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.signed.subscribe(
        data => {
          drawerRef.close();
          this.onRefresh();
        }
      );
    });
    //
    // drawerRef.afterClose.subscribe(data => {
    // });
  }

  isBlank(val): boolean {
    if (!val) {
      return false;
    }
    if (Object.keys(val).length > 0) {
      if (val.filePath) {
        return true;
      } else {
        return false;
      }
      return true;
    }
    return false;
  }
}
