import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeaveWordComponent } from './leave-word.component';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SharedModule } from '../shared/shared.module';
import { LeaveWordTableComponent } from './leave-word-table/leave-word-table.component';
import { LeaveWordMainComponent } from './leave-word-main.component';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzModalModule} from 'ng-zorro-antd/modal';
import { LeaveWordDetailComponent } from './leave-word-detail/leave-word-detail.component';
import {NzDividerModule} from 'ng-zorro-antd/divider';



@NgModule({
  declarations: [
    LeaveWordComponent,
    LeaveWordMainComponent,
    LeaveWordTableComponent,
    LeaveWordDetailComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    NzLayoutModule,
    NzSpinModule,
    NzButtonModule,
    NzIconModule,
    TranslateModule,
    NzAvatarModule,
    NzTableModule,
    NzEmptyModule,
    NzGridModule,
    NzToolTipModule,
    NzModalModule,
    NzDividerModule
  ]
})
export class LeaveWordModule { }
