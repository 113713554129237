import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewFnaPibaComponent } from './new-fna-piba.component';
import { NewFnaTemplateModule } from '../new-fna-template/new-fna-template.module';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FormsModule } from '@angular/forms';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { PaginatorModule } from 'primeng/paginator';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { PibaBasicInfoComponent } from './piba-basic-info/piba-basic-info.component';
import { PibaFormSelectedComponent } from './piba-form-selected/piba-form-selected.component';
import { PibaFormTemplateComponent } from './piba-form-template/piba-form-template.component';
import { PibaTemplateIntroductionComponent } from './piba-template-introduction/piba-template-introduction.component';
import { PibaTemplateOverviewComponent } from './piba-template-overview/piba-template-overview.component';
import { PibaTemplateQuestionComponent } from './piba-template-question/piba-template-question.component';
import { PibaTemplateSignatureComponent } from './piba-template-signature/piba-template-signature.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';


@NgModule({
  declarations: [
    NewFnaPibaComponent,
    PibaBasicInfoComponent,
    PibaFormSelectedComponent,
    PibaFormTemplateComponent,
    PibaTemplateIntroductionComponent,
    PibaTemplateOverviewComponent,
    PibaTemplateQuestionComponent,
    PibaTemplateSignatureComponent
  ],
  exports: [
    NewFnaPibaComponent,
    PibaBasicInfoComponent,
    PibaFormSelectedComponent,
    PibaFormTemplateComponent,
    PibaTemplateIntroductionComponent,
    PibaTemplateQuestionComponent,
    PibaTemplateSignatureComponent
  ],
  imports: [
    CommonModule,
    NewFnaTemplateModule,
    NzAlertModule,
    NzDropDownModule,
    NzIconModule,
    FormsModule,
    NzLayoutModule,
    NzMenuModule,
    NzSpinModule,
    SharedModule,
    TranslateModule,
    NzAnchorModule,
    NzInputModule,
    NzPopoverModule,
    PaginatorModule,
    NzCheckboxModule,
    NzGridModule,
    NzRadioModule,
    NzButtonModule,
    NzTabsModule,
    NzModalModule,
    NzDatePickerModule,
    NzInputNumberModule,
    NzSelectModule,
  ]
})
export class NewFnaPibaModule { }
