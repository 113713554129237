import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataDesensitization, DataMode, Instance, Material, UploadTokenReq, UploadType } from '../../../api/types';
import { AccountService } from '../../../account/account.service';
import { BaseComponent } from '../../../base/base.component';
import { PolicyPendingDetail, PolicyPendingReply, PolicyPendingReplyReq } from '../../policy-types';
import { PolicyService } from '../../policy.service';

@Component({
  selector: 'app-policy-pending-detail',
  templateUrl: './policy-pending-detail.component.html',
  styleUrls: ['./policy-pending-detail.component.less']
})
export class PolicyPendingDetailComponent extends BaseComponent implements OnInit {
  @Input()
  mode = DataMode.OWN;
  loading = false;

  pendingDetail: PolicyPendingDetail;

  accountName: string;

  replies: PolicyPendingReply[] = [];

  uploadTokenReq = new UploadTokenReq();

  replyReq: PolicyPendingReplyReq = new PolicyPendingReplyReq();

  detailVisible = false;
  instance: Instance;
  dataDesensitization: DataDesensitization = new DataDesensitization();

  @Output()
  pendingChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(private accountService: AccountService,
              private policyService: PolicyService) {
    super();
  }

  ngOnInit(): void {
    this.instance = this.accountService.mockInstance;
    this.accountName = this.accountService.passport.accountName;
    this.dataDesensitization = this.accountService.dataDesensitization;

    this.uploadTokenReq.uploadType = UploadType.POLICY;
    this.uploadTokenReq.middleName = this.pendingDetail.policyId;

    this.replyReq.serviceId = this.pendingDetail.id;

    this.loadReplies();
  }


  loadReplies(): void {
    this.loading = true;
    this.policyService.pendingReplies(this.pendingDetail.id)
      .subscribe(
        list => {
          this.loading = false;
          this.replies = list;
          if (this.replies && this.replies.length) {
            this.replies.map(item => item.content = item.content ? item.content : '');
          }
        },
        error => {
          this.loading = false;
        }
      );
  }

  attachmentsChange($event: Material[]): void {
    this.replyReq.attachments = $event;
  }

  get canReply(): boolean {
    return !!this.replyReq.content || !!this.replyReq.attachments;
  }

  /**
   * 提交回复
   */
  reply(): void {

    if (!this.canReply) {
      return;
    }
    this.loading = true;
    this.policyService.pendingDetailReplyV2(this.replyReq)
      .subscribe(
        result => {
          this.loadReplies();
          this.resetReply();
          this.loading = false;
          this.pendingChanged.emit('');
        },
        error => {
          this.loading = false;
        }
      );
  }

  /**
   * 重置回复数据
   */
  resetReply(): void {
    this.replyReq = new PolicyPendingReplyReq();
    this.replyReq.serviceId = this.pendingDetail.id;
  }

  openDetail(): void {
    this.detailVisible = true;
  }

  closeDetail(): void {
    this.detailVisible = false;
  }

  isDynamicEncryption(keyName): boolean {
    let isShow = false;
    if (!this.dataDesensitization || !this.mode) {
      return isShow;
    }
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    }
  }
}
