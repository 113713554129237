<div class="basic-info-container">
  <div class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">
      Life and MPF Business Record Form
    </div>
    <div nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label required-after">
          {{'IsTransferIn' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <nz-select [(ngModel)]="formInfo.FNA_ADA_01_is_transfer_in"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     nzAllowClear
                     [nzPlaceHolder]="'PleaseChoose' | translate"
                     class="value-box-select"
                     [nzShowSearch]="false">
            <nz-option *ngFor="let option of yesOrNoOptions"
                       [nzLabel]="option.label" [nzValue]="option.value">
            </nz-option>
          </nz-select>
<!--          <input class="value-box-input" nz-input-->
<!--                 [placeholder]="'PleaseChoose' | translate"-->
<!--                 [(ngModel)]="formInfo.FNA_ADA_01_is_transfer_in" />-->
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label required-after">
          {{'NewClient' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <nz-select [(ngModel)]="formInfo.FNA_ADA_02_is_new_customer"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     nzAllowClear
                     [nzPlaceHolder]="'PleaseChoose' | translate"
                     class="value-box-select"
                     [nzShowSearch]="false">
            <nz-option *ngFor="let option of yesOrNoOptions"
                       [nzLabel]="option.label" [nzValue]="option.value">
            </nz-option>
          </nz-select>
<!--          <input class="value-box-input" nz-input-->
<!--                 [placeholder]="'PleaseChoose' | translate"-->
<!--                 [(ngModel)]="formInfo.FNA_ADA_02_is_new_customer" />-->
        </div>
      </div>
<!--      <div nz-col [nzSpan]="6">-->
<!--        <div class="label-text additional-info-label">-->
<!--          {{'Insured' | translate}}-->
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
<!--        </div>-->
<!--        <div class="value-box">-->
<!--          <input class="value-box-input" nz-input-->
<!--                 [placeholder]="'PleaseEnter' | translate"-->
<!--                 [(ngModel)]="formInfo.FNA_ADA_03_insured" />-->
<!--        </div>-->
<!--      </div>-->
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label required-after">
          {{'ProductProvider' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <input class="value-box-input" nz-input
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="formInfo.FNA_ADA_04_policy_company" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label">{{'PolicyNumber' | translate}}</div>
        <div class="value-box">
          <input class="value-box-input" nz-input
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="formInfo.FNA_ADA_05_policy_no" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label required-after">
          {{'ZUUPaymentTerm' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <input class="value-box-input" nz-input
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="formInfo.FNA_ADA_06_term_of_payment" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label required-after">
          {{'PaymentMode' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <nz-select [(ngModel)]="formInfo.FNA_ADA_07_frequency"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     nzAllowClear class="value-box-select">
            <nz-option *ngFor="let option of frequencyOption"
                       [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label">
          {{'ZUUSumInsured' | translate}}
        </div>
        <div class="value-box additional-input-select">
          <nz-input-number class="group-box-input-number additional-orInput"
                           style="border: none;height: 30px;"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [nzParser]="parserValue"
                           [nzFormatter]="formatterValue"
                           [(ngModel)]="formInfo.FNA_ADA_08_sum_assured">
          </nz-input-number>
<!--          <input class="value-box-input additional-orInput" nz-input-->
<!--                 [placeholder]="'PleaseEnter' | translate"-->
<!--                 style="border: none;height: 30px;"-->
<!--                 [(ngModel)]="formInfo.FNA_ADA_08_sum_assured" />-->
          <nz-select [(ngModel)]="formInfo.FNA_ADA_08_sum_assured_currency"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     nzAllowClear class="additional-orSelect">
            <nz-option *ngFor="let option of currencyOptions"
                       [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label">
          {{'Premium' | translate}}
        </div>
        <div class="value-box additional-input-select">
          <nz-input-number class="group-box-input-number additional-orInput"
                           style="border: none;height: 30px;"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [nzParser]="parserValue"
                           [nzFormatter]="formatterValue"
                           [(ngModel)]="formInfo.FNA_ADA_09_premium">
          </nz-input-number>
<!--          <input class="value-box-input additional-orInput" nz-input-->
<!--                 style="border: none;height: 30px;"-->
<!--                 [placeholder]="'PleaseEnter' | translate"-->
<!--                 [(ngModel)]="formInfo.FNA_ADA_09_premium" />-->
          <nz-select [(ngModel)]="formInfo.FNA_ADA_09_premium_currency"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     nzAllowClear class="additional-orSelect">
            <nz-option *ngFor="let option of currencyOptions"
                       [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label required-after">
          {{'PrincipalWriting' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
<!--          <nz-select [(ngModel)]="formInfo.FNA_ADA_10_account_name" nzAllowClear-->
<!--                     [nzPlaceHolder]="'PleaseChoose' | translate"-->
<!--                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"-->
<!--                     class="value-box-select"-->
<!--                     [nzShowSearch]="true"-->
<!--                     [disabled]="true"-->
<!--                     [nzFilterOption]="accountNameSearch">-->
<!--            <nz-option *ngFor="let option of accountNameOptions" [nzLabel]="option.name" [nzValue]="option.accountName">-->
<!--            </nz-option>-->
<!--          </nz-select>-->
          <input class="value-box-input" nz-input
                 [placeholder]="'PleaseEnter' | translate"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [(ngModel)]="formInfo.FNA_ADA_10_account_name" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label required-after">
          {{'PrincipalAFYC%' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <nz-input-number class="group-box-input-number"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [(ngModel)]="formInfo.FNA_ADA_11_first_year_commission_percentage">
          </nz-input-number>
<!--          <input class="value-box-input" nz-input-->
<!--                 [placeholder]="'PleaseEnter' | translate"-->
<!--                 [(ngModel)]="formInfo.FNA_ADA_11_first_year_commission_percentage" />-->
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label required-after">
          {{'PrincipalAFYCHKD' | translate}}
<!--          <div style="color: #F66667;margin-left: 4px;">☆</div>-->
        </div>
        <div class="value-box">
          <nz-input-number class="group-box-input-number"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [nzParser]="parserValue"
                           [nzFormatter]="formatterValue"
                           [(ngModel)]="formInfo.FNA_ADA_12_first_year_commission_amount">
          </nz-input-number>
<!--          <input class="value-box-input" nz-input-->
<!--                 [placeholder]="'PleaseEnter' | translate"-->
<!--                 [(ngModel)]="formInfo.FNA_ADA_12_first_year_commission_amount" />-->
        </div>
      </div>
      <div nz-col [nzSpan]="6">
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label">{{'2nd Layer' | translate}}</div>
        <div class="value-box">
          <input class="value-box-input" nz-input
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="formInfo.FNA_ADA_13_2and_layer_account_name" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label">{{'PrincipalAFYC%' | translate}}</div>
        <div class="value-box">
          <nz-input-number class="group-box-input-number"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [(ngModel)]="formInfo.FNA_ADA_13_2and_layer_first_year_commission_percentage">
          </nz-input-number>
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label">{{'PrincipalAFYCHKD' | translate}}</div>
        <div class="value-box">
          <nz-input-number class="group-box-input-number"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [nzParser]="parserValue"
                           [nzFormatter]="formatterValue"
                           [(ngModel)]="formInfo.FNA_ADA_13_2and_layer_first_year_commission_amount">
          </nz-input-number>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label">{{'3rd Layer' | translate}}</div>
        <div class="value-box">
          <input class="value-box-input" nz-input
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="formInfo.FNA_ADA_13_3rd_layer_account_name" />
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label">{{'PrincipalAFYC%' | translate}}</div>
        <div class="value-box">
          <nz-input-number class="group-box-input-number"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [(ngModel)]="formInfo.FNA_ADA_13_3rd_layer_first_year_commission_percentage">
          </nz-input-number>
        </div>
      </div>
      <div nz-col [nzSpan]="6">
        <div class="label-text additional-info-label">{{'PrincipalAFYCHKD' | translate}}</div>
        <div class="value-box">
          <nz-input-number class="group-box-input-number"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [nzParser]="parserValue"
                           [nzFormatter]="formatterValue"
                           [(ngModel)]="formInfo.FNA_ADA_13_3rd_layer_first_year_commission_amount">
          </nz-input-number>
        </div>
      </div>
      <div nz-col [nzSpan]="6">

      </div>
    </div>
  </div>
  <div *ngIf="fnaInfo.fnaBusinessType === 'LIFE'" class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">
      Financial Needs Analysis Form
    </div>
    <div style="margin: 10px 0 0 16px;">
      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
          {{'ZUUFna14' | translate}}
        </span>
      </div>
      <div class="col-box" style="width: 402px;margin-left: 24px;">
        <div class="group-box">
          <nz-input-number class="group-box-input-number"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzParser]="parserValue"
                           [nzFormatter]="formatterValue"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [(ngModel)]="formInfo.FNA_ADA_14">
          </nz-input-number>
          <span class="suffix-text">HK$</span>
        </div>
      </div>
    </div>

    <div style="margin: 6px 0 0 16px;">
      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
          {{'ZUUFna15' | translate}}
        </span>
      </div>
      <div class="col-box" style="width: 402px;margin-left: 24px;">
        <div class="group-box">
          <nz-input-number class="group-box-input-number"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzParser]="parserValue"
                           [nzFormatter]="formatterValue"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [(ngModel)]="formInfo.FNA_ADA_15">
          </nz-input-number>
          <span class="suffix-text">HK$</span>
        </div>
      </div>
    </div>

    <div style="margin: 6px 0 0 16px;">
      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 0;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
          {{'ZUUFna16' | translate}}
        </span>
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_16_A'"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [ngModel]="formInfo.FNA_ADA_16_A"
                     (ngModelChange)="radioLog16($event, 'FNA_ADA_16_A')">
                <div class="check-text-label">
                  {{'<= 10%' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_16_B'"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [ngModel]="formInfo.FNA_ADA_16_B"
                     (ngModelChange)="radioLog16($event, 'FNA_ADA_16_B')">
                <div class="check-text-label">
                  {{'11% - 20%' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_16_C'"
                     [ngModel]="formInfo.FNA_ADA_16_C"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog16($event, 'FNA_ADA_16_C')">
                <div class="check-text-label">
                  {{'21% - 40%' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_16_D'"
                     [ngModel]="formInfo.FNA_ADA_16_D"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog16($event, 'FNA_ADA_16_D')">
                <div class="check-text-label">
                  {{'41% - 60%' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_16_E'"
                     [ngModel]="formInfo.FNA_ADA_16_E"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog16($event, 'FNA_ADA_16_E')">
                <div class="check-text-label">
                  {{'61% - 80%' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_16_F'"
                     [ngModel]="formInfo.FNA_ADA_16_F"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog16($event, 'FNA_ADA_16_F')">
                <div class="check-text-label">
                  {{'81% - 100%' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>

    <div style="margin: 6px 0 0 16px;">
      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;">
        {{'ZUUFna17' | translate}}
<!--        <span *ngIf="fnaInfo.customerType === 'CORPORATE'" style="color: #F66667;margin-left: 4px;">☆</span>-->
      </div>
      <div class="col-box" style="width: 402px;margin-left: 24px;">
        <div class="group-box">
          <nz-input-number class="group-box-input-number"
                           [nzParser]="parserValue"
                           [nzFormatter]="formatterValue"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [(ngModel)]="formInfo.FNA_ADA_17">
          </nz-input-number>
          <span class="suffix-text">HK$</span>
        </div>
      </div>
    </div>

    <div style="margin: 6px 0 0 16px;">
      <div class="form-card-title additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;">
        {{'ZUUFna18' | translate}}
      </div>
      <div class="col-box" style="width: 402px;margin-left: 24px;">
        <div class="group-box">
          <nz-input-number class="group-box-input-number"
                           [nzParser]="parserValue"
                           [nzFormatter]="formatterValue"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [(ngModel)]="formInfo.FNA_ADA_18">
          </nz-input-number>
          <span class="suffix-text">HK$</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fnaInfo.fnaBusinessType === 'LIFE'" class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">
      Premium Financing
    </div>
    <div style="margin: 10px 0 0 16px;">
      <div class="form-card-title additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 4px;">
        {{'ZUUFna19' | translate}}
      </div>
      <div style="margin-left: 20px;">
        <div nz-row style="padding-left: 6px;">
          <div nz-col nzSpan="3" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_19_A"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="check-text-label">
                {{'ZUUFna20' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="3" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_19_B">
              <div class="check-text-label">
                {{'ZUUFna21' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="3" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_19_C"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="check-text-label">
                {{'ZUUFna22' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="15" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_19_D"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="check-text-label">
                {{'answer36' | translate}}
              </div>
            </label>
            <div class="col-box">
              <textarea nz-input class="value-box-input" [placeholder]="'PleaseSpecify' | translate"
                        style="max-width: 620px;"
                        [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                        [(ngModel)]="formInfo.FNA_ADA_19_D_other_content"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
<!--              <input nz-input class="value-box-input"-->
<!--                     style="width: 320px;"-->
<!--                     [placeholder]="'PleaseSpecify' | translate"-->
<!--                     [(ngModel)]="formInfo.FNA_ADA_19_D_other_content" />-->
            </div>
          </div>
        </div>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;">
        {{'ZUUFna23' | translate}}
      </div>

      <div style="margin-left: 20px;">
        <div style="display: flex;align-items: center;margin-left: 5px;">
          <div class="additional-info-label">
            {{'ZUUFna24' | translate}}
          </div>
          <div class="col-box" style="width: 160px;margin-left: 8px;flex: none;">
            <div class="group-box">
              <nz-input-number class="group-box-input-number"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [nzPlaceHolder]="''"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                               [(ngModel)]="formInfo.FNA_ADA_20_i">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>
        <div style="display: flex;align-items: center;margin-left: 5px;">
          <div class="additional-info-label">
            {{'ZUUFna25' | translate}}
          </div>
          <div class="col-box" style="width: 160px;margin-left: 8px;flex: none;">
            <div class="group-box">
              <nz-input-number class="group-box-input-number"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                               [nzPlaceHolder]="''"
                               [(ngModel)]="formInfo.FNA_ADA_20_ii">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-card-title additional-question-title" style="font-weight: normal;color: #404040;">
        {{'ZUUFna26' | translate}}
      </div>
      <div class="col-box" style="margin-left: 20px;height: auto">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 4px;">
          <div nz-row>
            <div nz-col nzSpan="24" style=" ">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_21_A'"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [ngModel]="formInfo.FNA_ADA_21_A"
                     (ngModelChange)="radioLog21($event, 'FNA_ADA_21_A')">
                <div class="additional-info-label">
                  {{'ZUUFna27' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="24" style="margin-bottom: 9px;">
              <div nz-row [nzGutter]="16" style="padding-right: 8px;">
                <div nz-col [nzSpan]="6">
                  <div class="label-text additional-info-label">
                    {{'ZUUFna28' | translate}}
                  </div>
                  <div class="value-box">
                    <input class="value-box-input" nz-input
                           [placeholder]="'PleaseEnter' | translate"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [(ngModel)]="formInfo.FNA_ADA_21_A_name_of_lender" />
                  </div>
                </div>
                <div nz-col [nzSpan]="6">
                  <div class="label-text additional-info-label">
                    {{'ZUUFna29' | translate}}
                  </div>
                  <div class="value-box">
                    <nz-input-number class="group-box-input-number"
                                     [nzPlaceHolder]="'PleaseEnter' | translate"
                                     [nzParser]="parserValue"
                                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                                     [nzFormatter]="formatterValue"
                                     [(ngModel)]="formInfo.FNA_ADA_21_A_loan_amount">
                    </nz-input-number>
<!--                    <input class="value-box-input" nz-input-->
<!--                           [placeholder]="'PleaseEnter' | translate"-->
<!--                           [(ngModel)]="formInfo.FNA_ADA_21_A_loan_amount" />-->
                  </div>
                </div>
                <div nz-col [nzSpan]="6">
                  <div class="label-text additional-info-label">
                    {{'ZUUFna30' | translate}}
                  </div>
                  <div class="value-box">
                    <nz-input-number class="group-box-input-number"
                                     [nzPlaceHolder]="'PleaseEnter' | translate"
                                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                                     [(ngModel)]="formInfo.FNA_ADA_21_A_lending_rate">
                    </nz-input-number>
<!--                    <input class="value-box-input" nz-input-->
<!--                           [placeholder]="'PleaseEnter' | translate"-->
<!--                           [(ngModel)]="formInfo.FNA_ADA_21_A_lending_rate" />-->
                  </div>
                </div>
                <div nz-col [nzSpan]="6">
                  <div class="label-text additional-info-label">
                    {{'ZUUFna31' | translate}}
                  </div>
                  <div class="value-box">
                    <input class="value-box-input" nz-input
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [placeholder]="'PleaseEnter' | translate"
                           [(ngModel)]="formInfo.FNA_ADA_21_A_loan_term" />
                  </div>
                </div>
                <div nz-col [nzSpan]="6">
                  <div class="label-text additional-info-label">
                    {{'ZUUFna32' | translate}}
                  </div>
                  <div class="value-box">
                    <nz-input-number class="group-box-input-number"
                                     [nzPlaceHolder]="'PleaseEnter' | translate"
                                     [nzParser]="parserValue"
                                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                                     [nzFormatter]="formatterValue"
                                     [(ngModel)]="formInfo.FNA_ADA_21_A_monthly_repayment_amount">
                    </nz-input-number>
<!--                    <input class="value-box-input" nz-input-->
<!--                           [placeholder]="'PleaseEnter' | translate"-->
<!--                           [(ngModel)]="formInfo.FNA_ADA_21_A_monthly_repayment_amount" />-->
                  </div>
                </div>
              </div>
            </div>
            <div nz-col nzSpan="24">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_21_B'"
                     [ngModel]="formInfo.FNA_ADA_21_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog21($event, 'FNA_ADA_21_B')">
                <div class="additional-info-label">
                  {{'ZUUFna33' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
  </div>

  <div *ngIf="fnaInfo.fnaBusinessType === 'LIFE'" class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">
      ANTI-MONEY LAUNDERING RISK ASSESSMENT QUESTIONNAIRE
    </div>

    <div style="margin: 10px 0 0 16px;">
      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 0;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
          {{'ZUUFna34' | translate}}
        </span>
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_22_A'"
                     [ngModel]="formInfo.FNA_ADA_22_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog22($event, 'FNA_ADA_22_A')">
                <div class="check-text-label">
                  {{'answer37' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_22_B'"
                     [ngModel]="formInfo.FNA_ADA_22_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog22($event, 'FNA_ADA_22_B')">
                <div class="check-text-label">
                  {{'answer38' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 0;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
          {{'ZUUFna35' | translate}}
        </span>
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_23_A'"
                     [ngModel]="formInfo.FNA_ADA_23_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog23($event, 'FNA_ADA_23_A')">
                <div class="check-text-label">
                  {{'ZUUFna36' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_23_B'"
                     [ngModel]="formInfo.FNA_ADA_23_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog23($event, 'FNA_ADA_23_B')">
                <div class="check-text-label">
                  {{'ZUUFna37' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_23_C'"
                     [ngModel]="formInfo.FNA_ADA_23_C"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog23($event, 'FNA_ADA_23_C')">
                <div class="check-text-label">
                  {{'ZUUFna38' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 0;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
          {{'ZUUFna39' | translate}}
        </span>
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_24_A'"
                     [ngModel]="formInfo.FNA_ADA_24_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog24($event, 'FNA_ADA_24_A')">
                <div class="check-text-label">
                  {{'ZUUFna36' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_24_B'"
                     [ngModel]="formInfo.FNA_ADA_24_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog24($event, 'FNA_ADA_24_B')">
                <div class="check-text-label">
                  {{'ZUUFna37' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_24_C'"
                     [ngModel]="formInfo.FNA_ADA_24_C"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog24($event, 'FNA_ADA_24_C')">
                <div class="check-text-label">
                  {{'ZUUFna38' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 0;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
          {{'ZUUFna40' | translate}}
        </span>
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_25_A'"
                     [ngModel]="formInfo.FNA_ADA_25_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog25($event, 'FNA_ADA_25_A')">
                <div class="check-text-label">
                  {{'answer37' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_25_B'"
                     [ngModel]="formInfo.FNA_ADA_25_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog25($event, 'FNA_ADA_25_B')">
                <div class="check-text-label">
                  {{'answer38' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
          {{'ZUUFna41' | translate}}
        </span>
      </div>

      <div style="margin-left: 20px;">
        <div nz-row style="padding-left: 6px;">
          <div nz-col nzSpan="3" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_26_A"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   style="">
              <div class="additional-info-label">
                {{'ZUUFna42' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="3" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_26_B"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="check-text-label">
                {{'ZUUFna43' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="3" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_26_C"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna44' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="3" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_26_D"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna45' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="3" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_26_E"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna46' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="9" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_26_F"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'answer36' | translate}}
              </div>
            </label>
            <div class="col-box">
              <textarea nz-input class="value-box-input" [placeholder]="'PleaseSpecify' | translate"
                        style="max-width: 620px;"
                        [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                        [(ngModel)]="formInfo.FNA_ADA_26_F_other_content"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
<!--              <input nz-input class="value-box-input"-->
<!--                     style="max-width: 320px;"-->
<!--                     [placeholder]="'PleaseSpecify' | translate"-->
<!--                     [(ngModel)]="formInfo.FNA_ADA_26_F_other_content" />-->
            </div>
          </div>
        </div>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
          {{'ZUUFna47' | translate}}
        </span>
      </div>
      <div style="margin-left: 20px;">
        <div nz-row style="padding-left: 6px;">
          <div nz-col nzSpan="8" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_27_A"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna48' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="8" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_27_B"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="check-text-label">
                {{'ZUUFna49' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="8" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_27_C"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna50' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="8" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_27_D"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna51' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="8" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_27_E"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna52' | translate}}
              </div>
            </label>
          </div>
        </div>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;margin-top: 6px;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
          {{'ZUUFna53' | translate}}
        </span>
      </div>
      <div style="margin-left: 20px;">
        <div nz-row style="padding-left: 6px;display: flex;flex-wrap: wrap;">
          <div nz-col nzSpan="8" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_28_A" style=""
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna48' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="8" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_28_B"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="check-text-label">
                {{'ZUUFna49' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="8" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_28_C"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna50' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="8" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_28_D"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna51' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="8" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_28_E"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna52' | translate}}
              </div>
            </label>
          </div>
        </div>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 0;margin-top: 6px;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
          {{'ZUUFna54' | translate}}
        </span>
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_29_A'"
                     [ngModel]="formInfo.FNA_ADA_29_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog29($event, 'FNA_ADA_29_A')">
                <div class="check-text-label">
                  {{'ZUUFna55' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_29_B'"
                     [ngModel]="formInfo.FNA_ADA_29_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog29($event, 'FNA_ADA_29_B')">
                <div class="check-text-label">
                  {{'ZUUFna56' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_29_C'"
                     [ngModel]="formInfo.FNA_ADA_29_C"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog29($event, 'FNA_ADA_29_C')">
                <div class="check-text-label">
                  {{'ZUUFna57' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 0;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
          {{'ZUUFna58' | translate}}
        </span>
      </div>
      <div class="check-text-label" style="margin-left: 24px;line-height: 20px;">
        {{'ZUUFna59' | translate}}
      </div>
      <div class="check-text-label" style="margin-left: 24px;line-height: 20px;">
        {{'ZUUFna60' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;height: auto;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_30_A'"
                     [ngModel]="formInfo.FNA_ADA_30_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog30($event, 'FNA_ADA_30_A')">
                <div class="check-text-label">
                  {{'answer37' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_30_B'"
                     [ngModel]="formInfo.FNA_ADA_30_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog30($event, 'FNA_ADA_30_B')">
                <div class="check-text-label">
                  {{'answer38' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 0;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
          {{'ZUUFna61' | translate}}
        </span>
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_31_A'"
                     [ngModel]="formInfo.FNA_ADA_31_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog31($event, 'FNA_ADA_31_A')">
                <div class="check-text-label">
                  {{'answer37' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_31_B'"
                     [ngModel]="formInfo.FNA_ADA_31_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog31($event, 'FNA_ADA_31_B')">
                <div class="check-text-label">
                  {{'answer38' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 0;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
          {{'ZUUFna62' | translate}}
        </span>
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_32_A'"
                     [ngModel]="formInfo.FNA_ADA_32_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog32($event, 'FNA_ADA_32_A')">
                <div class="check-text-label">
                  {{'answer37' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_32_B'"
                     [ngModel]="formInfo.FNA_ADA_32_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog32($event, 'FNA_ADA_32_B')">
                <div class="check-text-label">
                  {{'answer38' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>


      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 0;">
        {{'ZUUFna63' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_33_A'"
                     [ngModel]="formInfo.FNA_ADA_33_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog33($event, 'FNA_ADA_33_A')">
                <div class="check-text-label">
                  {{'answer37' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_33_B'"
                     [ngModel]="formInfo.FNA_ADA_33_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog33($event, 'FNA_ADA_33_B')">
                <div class="check-text-label">
                  {{'answer38' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>


      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 0;">
        {{'ZUUFna64' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_34_A'"
                     [ngModel]="formInfo.FNA_ADA_34_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog34($event, 'FNA_ADA_34_A')">
                <div class="check-text-label">
                  {{'ZUUFna36' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_34_B'"
                     [ngModel]="formInfo.FNA_ADA_34_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog34($event, 'FNA_ADA_34_B')">
                <div class="check-text-label">
                  {{'ZUUFna37' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_34_C'"
                     [ngModel]="formInfo.FNA_ADA_34_C"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog34($event, 'FNA_ADA_34_C')">
                <div class="check-text-label">
                  {{'ZUUFna38' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
  </div>


  <div *ngIf="fnaInfo.fnaBusinessType === 'LIFE'" class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">
      Risk Profile Questionnaire
    </div>

    <div style="margin: 10px 0 0 16px;">
      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 0;">
        {{'ZUUFna65' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="6">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_35_A'"
                     [ngModel]="formInfo.FNA_ADA_35_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog35($event, 'FNA_ADA_35_A')">
                <div class="check-text-label">
                  {{'ZUUFna66' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="6">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_35_B'"
                     [ngModel]="formInfo.FNA_ADA_35_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog35($event, 'FNA_ADA_35_B')">
                <div class="check-text-label">
                  {{'ZUUFna67' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="6">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_35_C'"
                     [ngModel]="formInfo.FNA_ADA_35_C"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog35($event, 'FNA_ADA_35_C')">
                <div class="check-text-label">
                  {{'ZUUFna68' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="6">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_35_D'"
                     [ngModel]="formInfo.FNA_ADA_35_D"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog35($event, 'FNA_ADA_35_D')">
                <div class="check-text-label">
                  {{'ZUUFna69' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;">
        {{'ZUUFna70' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_36_A'"
                     [ngModel]="formInfo.FNA_ADA_36_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog36($event, 'FNA_ADA_36_A')">
                <div class="check-text-label">
                  {{'ZUUFna71' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_36_B'"
                     [ngModel]="formInfo.FNA_ADA_36_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog36($event, 'FNA_ADA_36_B')">
                <div class="check-text-label">
                  {{'ZUUFna67' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_36_C'"
                     [ngModel]="formInfo.FNA_ADA_36_C"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog36($event, 'FNA_ADA_36_C')">
                <div class="check-text-label">
                  {{'ZUUFna72' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_36_D'"
                     [ngModel]="formInfo.FNA_ADA_36_D"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog36($event, 'FNA_ADA_36_D')">
                <div class="check-text-label">
                  {{'ZUUFna73' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;margin-top: 10px;">
        {{'ZUUFna75' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_37_A'"
                     [ngModel]="formInfo.FNA_ADA_37_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog37($event, 'FNA_ADA_37_A')">
                <div class="check-text-label">
                  {{'ZUUYes' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_37_B'"
                     [ngModel]="formInfo.FNA_ADA_37_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog37($event, 'FNA_ADA_37_B')">
                <div class="check-text-label">
                  {{'ZUUNo' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>


      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;">
        {{'ZUUFna76' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;height: auto;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_38_A'"
                     [ngModel]="formInfo.FNA_ADA_38_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog38($event, 'FNA_ADA_38_A')">
                <div class="check-text-label">
                  {{'ZUUFna77' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_38_B'"
                     [ngModel]="formInfo.FNA_ADA_38_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog38($event, 'FNA_ADA_38_B')">
                <div class="check-text-label">
                  {{'ZUUFna78' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_38_C'"
                     [ngModel]="formInfo.FNA_ADA_38_C"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog38($event, 'FNA_ADA_38_C')">
                <div class="check-text-label">
                  {{'ZUUFna79' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_38_D'"
                     [ngModel]="formInfo.FNA_ADA_38_D"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog38($event, 'FNA_ADA_38_D')">
                <div class="check-text-label">
                  {{'ZUUFna80' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;margin-top: 6px;">
        {{'ZUUFna81' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;height: auto;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_39_A'"
                     [ngModel]="formInfo.FNA_ADA_39_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog39($event, 'FNA_ADA_39_A')">
                <div class="check-text-label">
                  {{'ZUUFna82' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_39_B'"
                     [ngModel]="formInfo.FNA_ADA_39_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog39($event, 'FNA_ADA_39_B')">
                <div class="check-text-label">
                  {{'ZUUFna83' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_39_C'"
                     [ngModel]="formInfo.FNA_ADA_39_C"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog39($event, 'FNA_ADA_39_C')">
                <div class="check-text-label">
                  {{'ZUUFna84' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_39_D'"
                     [ngModel]="formInfo.FNA_ADA_39_D"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog39($event, 'FNA_ADA_39_D')">
                <div class="check-text-label">
                  {{'ZUUFna85' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>


      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;margin-top: 6px;">
        {{'ZUUFna86' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;height: auto;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_40_A'"
                     [ngModel]="formInfo.FNA_ADA_40_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog40($event, 'FNA_ADA_40_A')">
                <div class="check-text-label">
                  {{'ZUUFna87' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_40_B'"
                     [ngModel]="formInfo.FNA_ADA_40_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog40($event, 'FNA_ADA_40_B')">
                <div class="check-text-label">
                  {{'ZUUFna88' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_40_C'"
                     [ngModel]="formInfo.FNA_ADA_40_C"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog40($event, 'FNA_ADA_40_C')">
                <div class="check-text-label">
                  {{'ZUUFna89' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="12">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_40_D'"
                     [ngModel]="formInfo.FNA_ADA_40_D"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog40($event, 'FNA_ADA_40_D')">
                <div class="check-text-label">
                  {{'ZUUFna90' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>


      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;margin-top: 6px;">
        {{'ZUUFna91' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;height: auto;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_41_A'"
                     [ngModel]="formInfo.FNA_ADA_41_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog41($event, 'FNA_ADA_41_A')">
                <div class="check-text-label">
                  {{'ZUUFna92' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_41_B'"
                     [ngModel]="formInfo.FNA_ADA_41_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog41($event, 'FNA_ADA_41_B')">
                <div class="check-text-label">
                  {{'ZUUFna93' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_41_C'"
                     [ngModel]="formInfo.FNA_ADA_41_C"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog41($event, 'FNA_ADA_41_C')">
                <div class="check-text-label">
                  {{'ZUUFna94' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_41_D'"
                     [ngModel]="formInfo.FNA_ADA_41_D"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog41($event, 'FNA_ADA_41_D')">
                <div class="check-text-label">
                  {{'ZUUFna95' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

    </div>
  </div>

  <div *ngIf="fnaInfo.fnaBusinessType === 'MPF'" class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">
      MPF - MPF Client Confirmation form
    </div>

    <div style="margin: 10px 0 0 16px;">
      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;">
        {{'ZUUFna96' | translate}}
      </div>
      <div style="margin-left: 20px;">
        <div nz-row style="padding-left: 6px;">
          <div nz-col nzSpan="12" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_42_individual_customers_A"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna97' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="12" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_42_individual_customers_B"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="check-text-label">
                {{'ZUUFna98' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="12" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_42_individual_customers_C"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna99' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="12" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_42_individual_customers_D"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna100' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="12" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_42_individual_customers_E"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna101' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="12" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_42_individual_customers_F"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'answer36' | translate}}
              </div>
            </label>
            <div class="col-box">
              <textarea nz-input class="value-box-input" [placeholder]="'PleaseSpecify' | translate"
                        style="max-width: 620px;"
                        [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                        [(ngModel)]="formInfo.FNA_ADA_42_individual_customers_F_other_content"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
<!--              <input nz-input class="value-box-input"-->
<!--                     style="max-width: 352px;"-->
<!--                     [placeholder]="'PleaseSpecify' | translate"-->
<!--                     [(ngModel)]="formInfo.FNA_ADA_42_individual_customers_F_other_content" />-->
            </div>
          </div>
        </div>
        <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;margin-left: 6px;">
          {{'ZUUFna102' | translate}}
        </div>
        <div nz-row style="padding-left: 6px;">
          <div nz-col nzSpan="12" style="display: flex;align-items: center;padding-left: 2em;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_42_employer_client_A"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna103' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="12" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_42_employer_client_B"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna104' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="12" style="display: flex;align-items: center;margin-bottom: 4px;padding-left: 2em;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_42_employer_client_C"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'answer36' | translate}}
              </div>
            </label>
            <div class="col-box">
              <textarea nz-input class="value-box-input" [placeholder]="'PleaseSpecify' | translate"
                        style="max-width: 620px;"
                        [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                        [(ngModel)]="formInfo.FNA_ADA_42_employer_client_C_other_content"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
<!--              <input nz-input class="value-box-input"-->
<!--                     style="max-width: 352px;"-->
<!--                     [placeholder]="'PleaseSpecify' | translate"-->
<!--                     [(ngModel)]="formInfo.FNA_ADA_42_employer_client_C_other_content" />-->
            </div>
          </div>
        </div>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;">
        {{'ZUUFna105' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <input nz-input class="value-box-input"
               style="max-width: 402px;"
               [placeholder]="'PleaseEnter' | translate"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
               [(ngModel)]="formInfo.FNA_ADA_43_business_registration_certificate_number" />
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;margin-top: 4px;">
        {{'ZUUFna106' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <input nz-input class="value-box-input"
               style="max-width: 402px;"
               [placeholder]="'PleaseEnter' | translate"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
               [(ngModel)]="formInfo.FNA_ADA_44_name_of_employer_representative" />
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;margin-top: 4px;">
        {{'ZUUFna107' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [nzValue]="'FNA_ADA_45_A'"
                     [ngModel]="formInfo.FNA_ADA_45_A"
                     (ngModelChange)="radioLog45($event, 'FNA_ADA_45_A')">
                <div class="check-text-label">
                  {{'answer37' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     [nzValue]="'FNA_ADA_45_B'"
                     [ngModel]="formInfo.FNA_ADA_45_B"
                     (ngModelChange)="radioLog45($event, 'FNA_ADA_45_B')">
                <div class="check-text-label">
                  {{'answer38' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>


      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;margin-top: 4px;">
        {{'ZUUFna108' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;height: auto;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="24" style="margin-bottom: 4px;">
                <div class="check-text-label">
                  {{'ZUUFna109' | translate}}
                </div>
            </div>
            <div nz-col nzSpan="24" style="margin-bottom: 4px;">
                <div class="check-text-label">
                  {{'ZUUFna110' | translate}}
                </div>
            </div>
            <div nz-col nzSpan="24" style="margin-bottom: 4px;">
                <div class="check-text-label">
                  {{'ZUUFna111' | translate}}
                </div>
            </div>

            <div nz-col nzSpan="24" style="margin-bottom: 4px;">
                <div class="check-text-label">
                  {{'ZUUFna112' | translate}}
                </div>
            </div>

            <div nz-col nzSpan="24">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_46_E'"
                     [ngModel]="formInfo.FNA_ADA_46_E"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog46($event, 'FNA_ADA_46_E')">
                <div class="check-text-label">
                  {{'ZUUFna113' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;margin-top: 4px;">
        {{'ZUUFna114' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;height: auto;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="24" style="margin-bottom: 4px;">
                <div class="check-text-label">
                  {{'ZUUFna115' | translate}}
                </div>
            </div>
            <div nz-col nzSpan="24" style="margin-bottom: 4px;">
                <div class="check-text-label">
                  {{'ZUUFna116' | translate}}
                </div>
            </div>
            <div nz-col nzSpan="24" style="margin-bottom: 4px;">
                <div class="check-text-label">
                  {{'ZUUFna117' | translate}}
                </div>
            </div>
            <div nz-col nzSpan="24" style="margin-bottom: 4px;">
                <div class="check-text-label">
                  {{'ZUUFna118' | translate}}
                </div>
            </div>
            <div nz-col nzSpan="24" style="margin-bottom: 4px;">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_47_E'"
                     [ngModel]="formInfo.FNA_ADA_47_E"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog47($event, 'FNA_ADA_47_E')">
                <div class="additional-info-label">
                  {{'ZUUFna119' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="24">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_47_F'"
                     [ngModel]="formInfo.FNA_ADA_47_F"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog47($event, 'FNA_ADA_47_F')">
                <div class="additional-info-label">
                  {{'ZUUFna120' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 6px;margin-top: 4px;">
        {{'ZUUFna121' | translate}}
      </div>
      <div style="margin-left: 2em;">
        <div class="additional-info-label" style="margin-bottom: 4px;">
          {{'ZUUFna122' | translate}}
        </div>
        <div nz-row style="padding-left: 22px;">
          <div nz-col nzSpan="6" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_48_a_a"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna123' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="8" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_48_a_b"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="check-text-label">
                {{'ZUUFna124' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="6" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_48_a_c"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna125' | translate}}
              </div>
            </label>
          </div>
        </div>

        <div class="additional-info-label" style="margin-bottom: 4px;">
          {{'ZUUFna126' | translate}}
        </div>
        <div nz-row style="padding-left: 22px;">
          <div nz-col nzSpan="12" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_48_b_a"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna127' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="12" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_48_b_b"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="check-text-label">
                {{'ZUUFna128' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="12" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_48_b_c"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna129' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="12" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_48_b_d"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna130' | translate}}
              </div>
            </label>
            <div class="col-box">
              <textarea nz-input class="value-box-input" [placeholder]="'ZUUFna131' | translate"
                        style="max-width: 620px;"
                        [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                        [(ngModel)]="formInfo.FNA_ADA_48_b_d_other_content"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
<!--              <input nz-input class="value-box-input"-->
<!--                     style="max-width: 320px;"-->
<!--                     [placeholder]="'ZUUFna131' | translate"-->
<!--                     [(ngModel)]="formInfo.FNA_ADA_48_b_d_other_content" />-->
            </div>
          </div>
        </div>

        <div class="additional-info-label" style="margin-bottom: 4px;">
          {{'ZUUFna132' | translate}}
        </div>
        <div nz-row style="padding-left: 22px;">
          <div nz-col nzSpan="12" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_48_c_a"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna133' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="12" style="display: flex;align-items: center;margin-bottom: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_48_c_b"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="check-text-label">
                {{'ZUUFna134' | translate}}
              </div>
            </label>
          </div>
        </div>

        <div class="additional-info-label" style="margin-bottom: 2px;">
          {{'ZUUFna135' | translate}}
        </div>
        <div nz-row style="padding-left: 22px;">
          <div nz-col nzSpan="12" style="display: flex;align-items: center;margin-bottom: 4px;">
            <div class="col-box">
              <textarea nz-input class="value-box-input" [placeholder]="'ZUUFna131' | translate"
                        style="max-width: 620px;"
                        [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                        [(ngModel)]="formInfo.FNA_ADA_48_d_content"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
<!--              <input nz-input class="value-box-input"-->
<!--                     style="max-width: 320px;"-->
<!--                     [placeholder]="'ZUUFna131' | translate"-->
<!--                     [(ngModel)]="formInfo.FNA_ADA_48_d_content" />-->
            </div>
          </div>
        </div>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 4px;margin-top: 4px;">
        {{'ZUUFna136' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <input nz-input class="value-box-input"
               style="max-width: 536px;"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
               [placeholder]="'PleaseEnter' | translate"
               [(ngModel)]="formInfo.FNA_ADA_49_intermediary_no" />
      </div>
    </div>
  </div>

  <div *ngIf="fnaInfo.fnaBusinessType === 'MPF'" class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">
      MPF – MPF Suitability Assessment form
    </div>

    <div style="margin: 10px 0 0 16px;">
      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 4px;margin-top: 4px;">
        {{'ZUUFna137' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;height: auto;">
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
          <div nz-row>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_50_A'"
                     [ngModel]="formInfo.FNA_ADA_50_A"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog50($event, 'FNA_ADA_50_A')">
                <div class="check-text-label">
                  {{'ZUUFna138' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_50_B'"
                     [ngModel]="formInfo.FNA_ADA_50_B"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog50($event, 'FNA_ADA_50_B')">
                <div class="check-text-label">
                  {{'ZUUFna139' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_50_C'"
                     [ngModel]="formInfo.FNA_ADA_50_C"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog50($event, 'FNA_ADA_50_C')">
                <div class="check-text-label">
                  {{'ZUUFna140' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox
                     [nzValue]="'FNA_ADA_50_D'"
                     [ngModel]="formInfo.FNA_ADA_50_D"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                     (ngModelChange)="radioLog50($event, 'FNA_ADA_50_D')">
                <div class="check-text-label">
                  {{'ZUUFna141' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 4px;margin-top: 4px;">
        {{'ZUUFna142' | translate}}
      </div>
      <div style="margin-left: 20px;">
        <div nz-row style="padding-left: 6px;display: flex;flex-wrap: wrap;">
          <div nz-col nzSpan="4" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_51_A" style=""
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna143' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_51_B"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="check-text-label">
                {{'ZUUFna144' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4" style="display: flex;align-items: center;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_51_C"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'ZUUFna145' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="24" style="display: flex;align-items: center;margin-top: 4px;">
            <label nz-checkbox [(ngModel)]="formInfo.FNA_ADA_51_D"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo">
              <div class="additional-info-label">
                {{'answer36' | translate}}
              </div>
            </label>
            <div class="col-box">
              <textarea nz-input class="value-box-input" [placeholder]="'PleaseSpecify' | translate"
                        style="max-width: 620px;"
                        [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                        [(ngModel)]="formInfo.FNA_ADA_51_D_other_content"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
<!--              <input nz-input class="value-box-input"-->
<!--                     style="max-width: 364px;"-->
<!--                     [placeholder]="'PleaseSpecify' | translate"-->
<!--                     [(ngModel)]="formInfo.FNA_ADA_51_D_other_content" />-->
            </div>
          </div>
        </div>
      </div>

      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 4px;margin-top: 4px;">
        {{'ZUUFna146' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <input nz-input class="value-box-input"
               style="max-width: 402px;"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
               [placeholder]="'PleaseEnter' | translate"
               [(ngModel)]="formInfo.FNA_ADA_52" />
      </div>
    </div>
  </div>

  <div *ngIf="fnaInfo.fnaBusinessType === 'LIFE'" class="basic-info-card">
    <div class="basic-info-card-title" style="color: #333333;">
      Client Agreement
    </div>

    <div style="margin: 10px 0 0 16px;">
      <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 4px;">
        {{'ZUUFna147' | translate}}
      </div>
      <div class="col-box" style="margin-left: 24px;">
        <input nz-input class="value-box-input"
               style="max-width: 402px;"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
               [placeholder]="'PleaseEnter' | translate"
               [(ngModel)]="formInfo.FNA_ADA_53_referee" />
      </div>
    </div>
  </div>


  <div *ngIf="fnaInfo.fnaBusinessType === 'LIFE'" class="basic-info-card">
    <div class="additional-question-title" style="font-weight: normal;color: #404040;margin-bottom: 4px;">
      <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'additional-required-after' : ''">
        {{'ZUUFna148' | translate}}
      </span>
    </div>
    <div class="col-box" style="margin-left: 24px;height: auto;">
      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0">
        <div nz-row>
          <div nz-col nzSpan="24" style="margin-bottom: 4px;">
            <label nz-checkbox
                   [nzValue]="'FNA_ADA_54_A'"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [ngModel]="formInfo.FNA_ADA_54_A"
                   (ngModelChange)="radioLog54($event, 'FNA_ADA_54_A')">
              <div class="check-text-label">
                {{'ZUUFna149' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="24" style="margin-bottom: 4px;">
            <label nz-checkbox
                   [nzValue]="'FNA_ADA_54_B'"
                   [ngModel]="formInfo.FNA_ADA_54_B"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   (ngModelChange)="radioLog54($event, 'FNA_ADA_54_B')">
              <div class="check-text-label">
                {{'ZUUFna150' | translate}}
              </div>
            </label>
          </div>
        </div>
        <div nz-row style="margin-left: 62px;">
          <div nz-col nzSpan="24" style="margin-bottom: 4px;">
            <label nz-checkbox
                   [nzValue]="'FNA_ADA_54_B1_a'"
                   [ngModel]="formInfo.FNA_ADA_54_B1_a"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   (ngModelChange)="radioLog54b1($event, 'FNA_ADA_54_B1_a')">
              <div class="check-text-label">
                {{'ZUUFna151' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="24" style="margin-bottom: 4px;">
            <label nz-checkbox
                   [nzValue]="'FNA_ADA_54_B1_b'"
                   [ngModel]="formInfo.FNA_ADA_54_B1_b"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   (ngModelChange)="radioLog54b1($event, 'FNA_ADA_54_B1_b')">
              <div class="check-text-label">
                {{'ZUUFna152' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="24" style="margin-bottom: 4px;">
            <label nz-checkbox
                   [nzValue]="'FNA_ADA_54_B1_c'"
                   [ngModel]="formInfo.FNA_ADA_54_B1_c"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   (ngModelChange)="radioLog54b1($event, 'FNA_ADA_54_B1_c')">
              <div class="check-text-label">
                {{'ZUUFna153' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="24" style="margin-bottom: 4px;">
            <label nz-checkbox
                   [nzValue]="'FNA_ADA_54_B1_d'"
                   [ngModel]="formInfo.FNA_ADA_54_B1_d"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   (ngModelChange)="radioLog54b1($event, 'FNA_ADA_54_B1_d')">
              <div class="check-text-label">
                {{'ZUUFna154' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="24" style="margin-bottom: 4px;">
            <label nz-checkbox
                   [nzValue]="'FNA_ADA_54_B1_e'"
                   [ngModel]="formInfo.FNA_ADA_54_B1_e"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   (ngModelChange)="radioLog54b1($event, 'FNA_ADA_54_B1_e')">
              <div class="check-text-label">
                {{'ZUUFna155' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="24" style="margin-bottom: 4px;">
            <label nz-checkbox
                   [nzValue]="'FNA_ADA_54_B1_f'"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [ngModel]="formInfo.FNA_ADA_54_B1_f"
                   (ngModelChange)="radioLog54b1($event, 'FNA_ADA_54_B1_f')">
              <div class="check-text-label">
                {{'ZUUFna156' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="24" style="margin-bottom: 4px;display: flex;align-items: center;">
            <label nz-checkbox
                   [nzValue]="'FNA_ADA_54_B1_g'"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [ngModel]="formInfo.FNA_ADA_54_B1_g"
                   (ngModelChange)="radioLog54b1($event, 'FNA_ADA_54_B1_g')">
              <div class="additional-info-label">
                {{'ZUUFna157' | translate}}
              </div>
            </label>
            <div class="col-box">
              <textarea nz-input class="value-box-input" [placeholder]="'PleaseSpecify' | translate"
                        style="max-width: 620px;"
                        [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                        [(ngModel)]="formInfo.FNA_ADA_54_B1_g_other_content"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
<!--              <input nz-input class="value-box-input"-->
<!--                     style="max-width: 364px;"-->
<!--                     [placeholder]="'PleaseSpecify' | translate"-->
<!--                     [(ngModel)]="formInfo.FNA_ADA_54_B1_g_other_content" />-->
            </div>
          </div>
        </div>
        <div style="margin-left: 38px;">
          <div class="additional-info-label">
            {{'ZUUFna158' | translate}}
          </div>
        </div>
        <div nz-row style="margin-left: 62px;">
          <div nz-col nzSpan="24" style="margin-bottom: 4px;">
            <label nz-checkbox
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [nzValue]="'FNA_ADA_54_B2_a'"
                   [ngModel]="formInfo.FNA_ADA_54_B2_a"
                   (ngModelChange)="radioLog54b2($event, 'FNA_ADA_54_B2_a')">
              <div class="check-text-label">
                {{'ZUUFna159' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="24" style="margin-bottom: 4px;">
            <label nz-checkbox
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [nzValue]="'FNA_ADA_54_B2_b'"
                   [ngModel]="formInfo.FNA_ADA_54_B2_b"
                   (ngModelChange)="radioLog54b2($event, 'FNA_ADA_54_B2_b')">
              <div class="check-text-label">
                {{'ZUUFna160' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="24" style="margin-bottom: 4px;">
            <label nz-checkbox
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [nzValue]="'FNA_ADA_54_B2_c'"
                   [ngModel]="formInfo.FNA_ADA_54_B2_c"
                   (ngModelChange)="radioLog54b2($event, 'FNA_ADA_54_B2_c')">
              <div class="check-text-label">
                {{'ZUUFna161' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="24" style="margin-bottom: 4px;">
            <label nz-checkbox
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [nzValue]="'FNA_ADA_54_B2_d'"
                   [ngModel]="formInfo.FNA_ADA_54_B2_d"
                   (ngModelChange)="radioLog54b2($event, 'FNA_ADA_54_B2_d')">
              <div class="check-text-label">
                {{'ZUUFna162' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="24" style="margin-bottom: 4px;display: flex;align-items: center;">
            <label nz-checkbox
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                   [nzValue]="'FNA_ADA_54_B2_e'"
                   [ngModel]="formInfo.FNA_ADA_54_B2_e"
                   (ngModelChange)="radioLog54b2($event, 'FNA_ADA_54_B2_e')">
              <div class="additional-info-label">
                {{'ZUUFna163' | translate}}
              </div>
            </label>
            <div class="col-box">
              <textarea nz-input class="value-box-input" [placeholder]="'PleaseSpecify' | translate"
                        style="max-width: 620px;"
                        [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                        [(ngModel)]="formInfo.FNA_ADA_54_B2_e_other_content"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
<!--              <input nz-input class="value-box-input"-->
<!--                     style="max-width: 364px;"-->
<!--                     [placeholder]="'PleaseSpecify' | translate"-->
<!--                     [(ngModel)]="formInfo.FNA_ADA_54_B2_e_other_content" />-->
            </div>
          </div>
        </div>
      </nz-checkbox-wrapper>
    </div>
  </div>

</div>
