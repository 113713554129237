<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{'TicketSearch' | translate}}
  </div>

<!--  状态-->
  <app-property-select [options]="statusOptions"
                       [title]="'TicketStatus' | translate"
                       [placeholder]="('PleaseSelect' | translate) + ('TicketStatus' | translate)"
                       [displayMode]="'vertical'"
                       [(value)]="search.status"></app-property-select>
<!--  标题-->
  <app-property-input [displayMode]="'vertical'"
                      [title]="'TicketTitle' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('TicketTitle' | translate)"
                      [(value)]="search.title"></app-property-input>
  <!--  内容-->
  <app-property-input [displayMode]="'vertical'"
                      [title]="'MessageContent' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('MessageContent' | translate)"
                      [(value)]="search.content"></app-property-input>

  <app-property-input [displayMode]="'vertical'"
                      [title]="'QuestionNumber' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('QuestionNumber' | translate)"
                      [(value)]="search.id"></app-property-input>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="onSearch()">
          <em nz-icon nzType="search" nzTheme="outline"></em>
          <span>{{'Search' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
