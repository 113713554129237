import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Customer, Phone } from '../../../api/types';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PhoneEditComponent } from '../phone-edit/phone-edit.component';

@Component({
  selector: 'app-phones-edit',
  templateUrl: './phones-edit.component.html',
  styleUrls: ['./phones-edit.component.less']
})
export class PhonesEditComponent implements OnInit {

  drawerRef: NzDrawerRef<PhonesEditComponent, string>;

  title: string;
  phones: Phone[] = [];

  insured: Customer;

  @Output()
  phonesSaved: EventEmitter<Phone[]> = new EventEmitter<Phone[]>();

  constructor(private drawerService: NzDrawerService,
              private modalService: NzModalService,
  ) {
  }

  ngOnInit(): void {
  }

  onAddPhone(): void {
    const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: PhoneEditComponent,
      nzContentParams: {
        phone: new Phone(),
        showType: true
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.phoneSaved.subscribe(
        data => {
          drawerRef.close();
          if (!this.phones) {
            this.phones = [];
          }
          this.phones.push(data);
        }
      );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });

  }


  onEdit(phone: Phone, index: number): void {
    const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: PhoneEditComponent,
      nzContentParams: {
        phone,
        showType: true
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.phoneSaved.subscribe(
        data => {
          drawerRef.close();
          if (!this.phones) {
            this.phones = [];
          }
          if (this.phones.length > index) {
            this.phones[index] = data;
          }
        }
      );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onDelete(phone: Phone, index: number): void {
    if (this.phones.length > index) {
      this.phones.splice(index, 1);
    }
  }

  onSave(): void {
    console.log(this.phones);
    this.phonesSaved.emit(this.phones);
    this.drawerRef?.close();
  }

}
