import { Component, OnInit, TemplateRef } from '@angular/core';
import { AccountService } from '../../account/account.service';
import { Instance, InstanceAccount, Passport } from '../../api/types';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { AccountAddComponent } from '../account-add/account-add.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-switch',
  templateUrl: './account-switch.component.html',
  styleUrls: ['./account-switch.component.less']
})
export class AccountSwitchComponent implements OnInit {

  instance: Instance;
  passport: Passport;
  accounts: InstanceAccount[];

  accountToDelete: InstanceAccount = new InstanceAccount();

  constructor(private router: Router,
              private accountService: AccountService,
              private drawerService: NzDrawerService,
              private modalService: NzModalService) {
    this.instance = this.accountService.instance;
    this.passport = this.accountService.passport;
  }

  ngOnInit(): void {
    this.accounts = this.accountService.accounts;
  }

  isCurrent(account: InstanceAccount): boolean {
    if (!this.instance || !this.passport) {
      return false;
    }
    return account.instance.groupKey === this.instance.groupKey && account.passport.accountName === this.passport.accountName;
  }

  onSwitchAccount(account: InstanceAccount): void {
    if (this.isCurrent(account)) {
      return;
    }
    this.accountService.instance = account.instance;
    this.accountService.passport = account.passport;
    this.router.navigate(['/user/workbench']).then(data => {
      window.location.reload();
      }
    );
  }

  onAddAccount(): void {
    const drawerRef = this.drawerService.create<AccountAddComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: AccountAddComponent,
      nzContentParams: {}
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onDeleteAccount(account: InstanceAccount, index: number, tplDeleteTitle: TemplateRef<any>, tplDeleteContent: TemplateRef<any>): void {
    this.accountToDelete = account;
    this.modalService.create({
      nzTitle: tplDeleteTitle,
      nzContent: tplDeleteContent,
      nzClosable: false,
      nzOnOk: () => {
        this.accountService.removeAccount(account);
        this.accounts = this.accountService.accounts;
      }
    });
  }
}
