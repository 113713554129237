import { NzOptionComponent } from 'ng-zorro-antd/select';

export class Tool {
  // 数字相加
  public static mathAdd(arg1, arg2): string {
    let r1;
    let r2;
    let m;
    let n;
    try {
      r1 = arg1.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = r1 >= r2 ? r1 : r2;
    return ((arg1 * m + arg2 * m) / m).toFixed(n);
  }

  // 数字相减
  public static matchSub(arg1, arg2): string {
    let r1;
    let r2;
    let m;
    let n;
    try {
      r1 = arg1.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }

    m = Math.pow(10, Math.max(r1, r2));
    // last modify by deeka
    // 动态控制精度长度
    n = r1 >= r2 ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
  }

  // 乘
  public static accMul(arg1, arg2): number {
    let m = 0;
    let s1 = arg1.toString();
    let s2 = arg2.toString();
    try {
      m += s1.split('.')[1].length;
    } catch (e) {}
    try {
      m += s2.split('.')[1].length;
    } catch (e) {}
    return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
  }

  // 除
  public static accDiv(a, b): number {
    let c;
    let d;
    let e = 0;
    let f = 0;
    try {
      e = a.toString().split('.')[1].length;
    } catch (g) {}
    try {
      f = b.toString().split('.')[1].length;
    } catch (g) {}
    console.log(
      (c = Number(a.toString().replace('.', ''))),
      (d = Number(b.toString().replace('.', ''))),
      Tool.accMul(c / d, Math.pow(10, f - e))
    );
    return (
      (c = Number(a.toString().replace('.', ''))), (d = Number(b.toString().replace('.', ''))), Tool.accMul(c / d, Math.pow(10, f - e))
    );
  }

  public static dataMasking(str: string): string {
    if (!str) {
      return '';
    }
    if (str.toString().length === 1) {
      return '*';
    }
    str = str.toString();
    let frontLen = 0;
    let endLen = 0;
    switch (str.length) {
      case 2:
      case 3:
        frontLen = 1;
        endLen = 0;
        break;
      case 4:
      case 5:
        frontLen = 1;
        endLen = 1;
        break;
      case 6:
      case 7:
      case 8:
        frontLen = 2;
        endLen = 1;
        break;
      default:
        frontLen = 2;
        endLen = 2;
        break;
    }
    const len = str.length - frontLen - endLen;
    let star = '';
    for (let i = 0; i < len; i++) {
      star += '*';
    }
    return str.substring(0, frontLen) + star + str.substring(str.length - endLen);
  }

  // 判空
  public static isEmpty(obj): boolean {
    return typeof obj === 'undefined' || obj == null || obj === '';
  }


  public static filterOption(input?: string, option?: NzOptionComponent): boolean {
    if (input) {
      return typeof option.nzLabel !== 'number' && option.nzLabel.toLowerCase()?.includes(input.toLowerCase()) || option.nzValue.toLowerCase().includes(input.toLowerCase());
    }
    return false;
  }
}
