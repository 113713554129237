<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'FnaDetail' | translate}}
    </div>

    <div style="display: flex;justify-content: space-between">
      <div style="display: flex;">
        <div>
          <img [src]="(detailInfo.products && detailInfo.products.length ? detailInfo.products[0].logo : '')" alt=""
               style="border-radius: 3px; margin-right: 5px" width="60"/>
        </div>
        <div
          style="font-size: 20px;font-weight: 600;color: #404040;display: flex;flex-direction: column;justify-content: space-between;">
          <div style="width:320px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
            {{detailInfo.basicInformation?.chineseName || 'N/A'}}
            {{detailInfo.basicInformation?.englishName || 'N/A'}}
          </div>
          <div style="font-size: 14px;color: #333333;font-weight: 400;">
            {{detailInfo.products && detailInfo.products.length ? detailInfo?.products[0].productName : 'N/A'}}
          </div>
        </div>
      </div>
      <div style="display: flex;flex-direction: column;align-items: end;justify-content: space-between;">
        <div [class]="detailInfo.status"
             style="font-size: 16px;font-weight: 600;display: flex;align-items: center;"
        >
          <div class="radius-box" style="width: 10px;height: 10px;border-radius: 50%;margin-right: 5px;"></div>
          {{detailInfo.status | metadataTranslate: 'fnaStatus'}}
        </div>
        <div style="font-size: 14px;color: #1D64C4;;">#{{detailInfo?.id || 'N/A'}}</div>
      </div>
    </div>

    <nz-tabset nzCentered>
      <nz-tab [nzTitle]="'FnaInfo' | translate">
        <div class="booking-section-wrapper">
          <div class="property-row">
            <div class="property-title-row"
                 style="color: #333333;font-size: 16px;font-weight: 600;">{{ 'basicInfoFna' | translate }}</div>
            <div (throttleClick)="onBasicInfo()" appThrottleClick class="property-value-row">
              <div class="property-pick-value">
                <span [class]="detailInfo.basicStatus"
                      style="flex: 1;"
                >{{detailInfo.basicStatus | metadataTranslate: 'fnaStatus'}}</span>
                <span><i nz-icon nzTheme="outline" nzType="right"></i></span>
              </div>
            </div>
          </div>
        </div>

        <div class="booking-section-wrapper">
          <div class="property-row">
            <div class="property-title-row"
                 style="color: #333333;font-size: 16px;font-weight: 600;">{{('FinancialNeedAnalysis' | translate) }}</div>
            <div appThrottleClick (throttleClick)="onFnaForm()" class="property-value-row">
              <div class="property-pick-value">
                <span [class]="detailInfo.fnaStatus"
                      style="flex: 1;">
                  {{detailInfo.fnaStatus | metadataTranslate: 'fnaStatus'}}</span>
                <span><i nz-icon nzTheme="outline" nzType="right"></i></span>
              </div>
            </div>
          </div>
        </div>

        <div class="booking-section-wrapper">
          <div class="property-row">
            <div class="property-title-row"
                 style="color: #333333;font-size: 16px;font-weight: 600;">{{ 'ProductRecommendationSelection' | translate }}</div>
            <div appThrottleClick (throttleClick)="onFnaProductCodes()" class="property-value-row">
              <div class="property-pick-value">
                <span style="flex: 1;" [class]="detailInfo?.productCodes?.length ? 'COMPLETED' : 'UNCOMPLETED'">
                  {{detailInfo?.productCodes?.length ? ('Selected' | translate) : ('Unselected' | translate)}}
                </span>
                <span><i nz-icon nzTheme="outline" nzType="right"></i></span>
              </div>
            </div>
          </div>
        </div>

        <div class="booking-section-wrapper">
          <div class="property-row">
            <div class="property-title-row"
                 style="color: #333333;font-size: 16px;font-weight: 600;">{{ 'SystemInformation' | translate }}</div>
            <div appThrottleClick (throttleClick)="onFnaSystem()" class="property-value-row">
              <div class="property-pick-value">
                <span [class]="detailInfo.systemStatus"
                      style="flex: 1;">
                  {{detailInfo.systemStatus | metadataTranslate: 'fnaStatus'}}</span>
                <span><i nz-icon nzTheme="outline" nzType="right"></i></span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="detailInfo.showDeclaration" class="booking-section-wrapper">
          <div class="property-row">
            <div class="property-title-row"
                 style="color: #333333;font-size: 16px;font-weight: 600;">{{ 'SpecialCustomerDeclaration' | translate }}</div>
            <div appThrottleClick (throttleClick)="onFnaDeclaration()" class="property-value-row">
              <div class="property-pick-value">
                <span [class]="detailInfo.declarationStatus"
                      style="flex: 1;">
                  {{detailInfo.declarationStatus | metadataTranslate: 'fnaStatus'}}</span>
                <span><i nz-icon nzTheme="outline" nzType="right"></i></span>
              </div>
            </div>
          </div>
        </div>

        <div class="booking-section-wrapper">
          <div class="property-row">
            <div class="property-title-row"
                 style="color: #333333;font-size: 16px;font-weight: 600;">{{ 'SigningStatus' | translate }}</div>
            <div class="property-value-row" appThrottleClick (throttleClick)="onFnaSignature()">
              <div class="property-pick-value">
                <span style="flex: 1;"></span>
                <span [class]="detailInfo.signatureStatus"
                      style="flex: 1;">
                  {{detailInfo.signatureStatus | metadataTranslate: 'fnaSignedStatus'}}</span>
                <span><i nz-icon nzTheme="outline" nzType="right"></i></span>
              </div>
            </div>
          </div>
        </div>

      </nz-tab>
      <nz-tab [nzTitle]="'RelatesInfo' | translate">

        <div class="booking-section-wrapper">
          <div class="property-row">
            <div class="property-title-row"
                 style="color: #333333;font-size: 16px;font-weight: 600;">
              {{ detailInfo.proposalId ? ('ProposalID' | translate) : ('AssociateProposal' | translate)}}
            </div>
            <div class="property-value-row" appThrottleClick (throttleClick)="showProposalSelection()">
              <div class="property-pick-value">
                <span style="flex: 1;">{{ detailInfo.proposalId || 'N/A'}}</span>
                <span><i nz-icon nzTheme="outline" nzType="right"></i></span>
              </div>
            </div>
          </div>
        </div>

        <div class="booking-section-wrapper">
          <div class="property-row">
            <div class="property-title-row"
                 style="color: #333333;font-size: 16px;font-weight: 600;">{{ 'BookingNo' | translate }}</div>
            <div appThrottleClick (throttleClick)="onBookingDetail()" class="property-value-row">
              <div class="property-pick-value">
                <span style="flex: 1;">{{ detailInfo.bookingNo || 'N/A'}}</span>
                <span><i nz-icon nzTheme="outline" nzType="right"></i></span>
              </div>
            </div>
          </div>
        </div>

        <div class="booking-section-wrapper">
          <div class="property-row">
            <div class="property-title-row"
                 style="color: #333333;font-size: 16px;font-weight: 600;">{{ 'UnderwritingNumber' | translate }}</div>
            <div appThrottleClick (throttleClick)="onUnderwritingDetail()" class="property-value-row">
              <div class="property-pick-value">
                <span style="flex: 1;">{{ detailInfo.underwritingNo || 'N/A'}}</span>
                <span><i nz-icon nzTheme="outline" nzType="right"></i></span>
              </div>
            </div>
          </div>
        </div>

        <div class="booking-section-wrapper">
          <div class="property-row">
            <div class="property-title-row"
                 style="color: #333333;font-size: 16px;font-weight: 600;">{{ 'PolicyNo' | translate }}</div>
            <div appThrottleClick (throttleClick)="onPolicyDetail()" class="property-value-row">
              <div class="property-pick-value">
                <span style="flex: 1;">{{ detailInfo.policyNo || 'N/A'}}</span>
                <span><i nz-icon nzTheme="outline" nzType="right"></i></span>
              </div>
            </div>
          </div>
        </div>
      </nz-tab>
    </nz-tabset>
  </nz-spin>

  <div *ngIf="detailInfo.id" class="footer">
    <div style="width: 100%; height: 100%;display: flex">
<!--      <div class="footer-button" nz-col nzSpan="12">-->
<!--        <a (throttleClick)="onReuse()" appThrottleClick nz-button nzBlock-->
<!--           nzSize="large" nzType="link">-->
<!--          <span>{{'TemporarySave' | translate}}</span>-->
<!--        </a>-->
<!--      </div>-->
<!--      <div class="footer-button-primary" nz-col nzSpan="12">-->
<!--        <a (throttleClick)="onFna()" appThrottleClick nz-button nzBlock-->
<!--           nzSize="large" nzType="link">-->
<!--          <span>{{'立即提交' | translate}}</span>-->
<!--        </a>-->
<!--      </div>-->
      <div *ngIf="detailInfo.id" class="footer-button" style="flex: 1">
        <a (throttleClick)="onReuse()" appThrottleClick nz-button nzBlock
           nzSize="large" nzType="link">
          <span>{{'Reuse' | translate}}</span>
        </a>
      </div>
      <div *ngIf="detailInfo.signatureStatus === 'SIGNED'" class="footer-button-primary" style="flex: 1">
        <a (throttleClick)="onViewFna()" appThrottleClick nz-button nzBlock
           nzSize="large" nzType="link">
          <span>{{'ViewFna' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
