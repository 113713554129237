import { Component, OnInit } from '@angular/core';
import { Workbench } from '../workbench/workbench-types';
import { DataService } from '../shared/service/data.service';
import { WorkbenchService } from '../workbench/workbench.service';
import { Instance } from '../api/types';

@Component({
  selector: 'app-new-fna-main',
  templateUrl: './new-fna-main.component.html',
  styleUrls: ['./new-fna-main.component.less']
})
export class NewFnaMainComponent implements OnInit {

  workbench: Workbench;
  tableIndex = 0;
  instance: Instance = new Instance();

  constructor(public dataService: DataService,
              private workbenchService: WorkbenchService) { }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.instance = this.workbenchService.mockInstance;
    this.tableIndex = localStorage.getItem('NneFnaTabs') === 'SUBORDINATE' ? 1 : 0;
  }

}
