import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasicInformation, NewFnaDetail } from '../new-fna-types';
import { NewFnaService } from '../new-fna.service';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MetadataService } from '../../shared/service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FnaBasicInfoComponent } from '../components/fna-basic-info/fna-basic-info.component';
import { NewFnaFormComponent } from '../components/new-fna-form/new-fna-form.component';
import {
  NewFnaRecommendedProductComponent
} from '../components/new-fna-recommended-product/new-fna-recommended-product.component';
import { NewFnaSignatureComponent } from '../components/new-fna-signature/new-fna-signature.component';
import { BookingDetailComponent } from '../../booking/booking-detail/booking-detail.component';
import { Booking } from '../../booking/booking-types';
import { UnderwritingDetailComponent } from '../../underwriting/underwriting-detail/underwriting-detail.component';
import { DataMode, Instance, Underwriting } from '../../api/types';
import { PolicyDetailComponent } from '../../policy/policy-detail/policy-detail.component';
import { Policy } from '../../policy/policy-types';
import { BookingService } from '../../booking/booking.service';
import { UnderwritingService } from '../../underwriting/underwriting.service';
import { PolicyService } from '../../policy/policy.service';
import { NewFnaSystemInfoComponent } from '../components/new-fna-system-info/new-fna-system-info.component';
import {
  NewFnaImportantClauseComponent
} from '../components/new-fna-important-clause/new-fna-important-clause.component';
import { Proposal, ProposalSearch, ProposalStatus } from '../../proposal/proposal-types';
import { ProposalSelectionComponent } from '../../proposal/proposal-selection/proposal-selection.component';
import { ProposalDetailComponent } from '../../proposal/proposal-detail/proposal-detail.component';
import { ProposalService } from '../../proposal/proposal.service';
import { FnaDraft } from '../../fna/fna-types';

@Component({
  selector: 'app-new-fna-create',
  templateUrl: './new-fna-create.component.html',
  styleUrls: ['./new-fna-create.component.less']
})
export class NewFnaCreateComponent implements OnInit {
  drawerRef: NzDrawerRef<NewFnaCreateComponent, string>;

  loading = false;
  id: number;
  @Output()
  detailInfo: NewFnaDetail = new NewFnaDetail();
  @Input()
  mode = DataMode.OWN;

  instance: Instance = new Instance();

  @Output()
  newFnaReused: EventEmitter<FnaDraft> = new EventEmitter<FnaDraft>();

  constructor(private newFnaService: NewFnaService,
              private drawerService: NzDrawerService,
              private modalService: NzModalService,
              private metadataService: MetadataService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private bookingService: BookingService,
              private underwritingService: UnderwritingService,
              private policyService: PolicyService,
              private proposalService: ProposalService,
  ) {
  }

  ngOnInit(): void {
    console.log(this.detailInfo);
    console.log(this.detailInfo.products);
    this.instance = this.newFnaService.mockInstance;
  }

  onReuse(): void {
    this.loading = true;
    this.newFnaService.reuseNewFna(this.detailInfo.id)
      .subscribe(data => {
        this.loading = false;
        this.newFnaReused.emit(data);
        this.drawerRef.close();
      }, error => {
        this.loading = false;
      });
  }

  onFna(): void {
    console.log('onReuse');
  }

  onBasicInfo(): void {
    const drawerRef = this.drawerService.create<FnaBasicInfoComponent, { value: any }, string>({
      nzWidth: 800,
      nzMask: false,
      nzMaskClosable: false,
      nzContent: FnaBasicInfoComponent,
      nzContentParams: {
        basicInfo: this.detailInfo.basicInformation ? JSON.parse(JSON.stringify(this.detailInfo.basicInformation)) : new BasicInformation(),
        fnaInfo: JSON.parse(JSON.stringify(this.detailInfo))
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.basicSaveChange.subscribe(id => {
        console.log(id);
        this.getDetail(id);
        drawerRef.close();
      });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }


  onFnaForm(): void {
    if (this.detailInfo.basicStatus !== 'COMPLETED') {
      this.translate.get([ 'PleaseCompleteFirst', 'basicInfoFna']).subscribe(
        (res: any) => {
          this.toastr.warning(res.PleaseCompleteFirst + res.basicInfoFna);
        });
      return;
    }
    const drawerRef = this.drawerService.create<NewFnaFormComponent, { value: any }, string>({
      nzWidth: 800,
      nzMask: false,
      nzMaskClosable: false,
      nzContent: NewFnaFormComponent,
      nzContentParams: {
        fnaForm: this.detailInfo.financialAnalysis ? JSON.parse(JSON.stringify(this.detailInfo.financialAnalysis)) : [],
        fnaInfo: JSON.parse(JSON.stringify(this.detailInfo))
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.formSaveChange.subscribe(data => {
        console.log(this.id);
        this.getDetail(this.id);
        drawerRef.close();
      });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onFnaProductCodes(): void {
    if (this.detailInfo.fnaStatus !== 'COMPLETED') {
      this.translate.get(['PleaseCompleteFirst', 'FinancialNeedAnalysis', 'ZuuFinancialNeedAnalysis']).subscribe(
        (res: any) => {
          if (this.instance.isZUU()) {
            this.toastr.warning(res.PleaseCompleteFirst + res.ZuuFinancialNeedAnalysis);
          } else {
            this.toastr.warning(res.PleaseCompleteFirst + res.FinancialNeedAnalysis);
          }
        });
      return;
    }
    const drawerRef = this.drawerService.create<NewFnaRecommendedProductComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: NewFnaRecommendedProductComponent,
      nzContentParams: {
        fna: this.detailInfo,
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.saved.subscribe(
        data => {
          this.getDetail(this.id);
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onFnaSignature(): void {
    console.log(this.detailInfo.showDeclaration, this.detailInfo.declarationStatus !== 'COMPLETED');
    if (this.detailInfo.showDeclaration && this.detailInfo.declarationStatus !== 'COMPLETED') {
      this.translate.get(['PleaseCompleteFirst', 'SpecialCustomerDeclaration']).subscribe(
        (res: any) => {
          this.toastr.warning(res.PleaseCompleteFirst + res.SpecialCustomerDeclaration);
        });
      return;
    }
    if (!this.detailInfo.showDeclaration && this.detailInfo.systemStatus !== 'COMPLETED') {
      this.translate.get(['PleaseCompleteFirst', 'SystemInformation']).subscribe(
        (res: any) => {
          this.toastr.warning(res.PleaseCompleteFirst + res.SystemInformation);
        });
      return;
    }

    const drawerRef = this.drawerService.create<NewFnaSignatureComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: NewFnaSignatureComponent,
      nzContentParams: {
        fna: this.detailInfo
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.submitted.subscribe(
        data => {
          this.getDetail(this.id);
        });
      component.resigned.subscribe(
        data => {
          this.getDetail(this.id);
        });
    });

    drawerRef.afterClose.subscribe(data => {
      this.getDetail(this.id);
    });
  }

  getDetail(id): void {
    this.newFnaService.info(id)
      .subscribe(data => {
          console.log(data);
          this.id = data.id;
          this.detailInfo = JSON.parse(JSON.stringify(data));
        },
        error => {
          console.log(error);
        }
      );

  }

  onBookingDetail(): void {
    if (!this.detailInfo.bookingNo) {
      return;
    }
    this.loading = true;
    this.bookingService.detail(this.detailInfo.bookingNo)
      .subscribe(
        bookingDetail => {
          this.loading = false;
          const booking = bookingDetail.toBooking();

          const drawerRef = this.drawerService.create<BookingDetailComponent, { value: Booking }, string>({
            nzWidth: 800,
            nzContent: BookingDetailComponent,
            nzContentParams: {
              bookingDetail,
              booking
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.drawerRef = drawerRef;
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        });
  }

  onUnderwritingDetail(): void {
    if (!this.detailInfo.underwritingNo) {
      return;
    }
    this.loading = true;
    this.underwritingService.detail(this.detailInfo.underwritingNo)
      .subscribe(
        underwritingDetail => {

          this.loading = false;
          const underwriting = underwritingDetail.toUnderwriting();

          const drawerRef = this.drawerService.create<UnderwritingDetailComponent, { value: Underwriting }, string>({
            nzWidth: 800,
            nzContent: UnderwritingDetailComponent,
            nzContentParams: {
              underwritingDetail,
              underwriting,
              mode: this.mode,
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onPolicyDetail(): void {
    if (!this.detailInfo.policyId) {
      return;
    }
    this.loading = true;
    this.policyService.detail(this.detailInfo.policyId)
      .subscribe(
        policyDetail => {

          this.loading = false;
          const policy = policyDetail.toPolicy();

          const drawerRef = this.drawerService.create<PolicyDetailComponent, { value: Policy }, string>({
            nzWidth: 800,
            nzContent: PolicyDetailComponent,
            nzContentParams: {
              policyDetail,
              policy,
              mode: this.mode,
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onViewFna(): void {
    this.loading = true;
    this.newFnaService.viewFna(this.detailInfo.id)
      .subscribe(
        data => {
          const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
          window.open(url);
          this.loading = false;
        }, error => {
          this.loading = false;
        });
  }

  onFnaSystem(): void {
    if (!this.detailInfo.productCodes || !this.detailInfo.productCodes.length) {
      this.translate.get(['PleaseCompleteFirst', 'ProductRecommendationSelection']).subscribe(
        (res: any) => {
          this.toastr.warning(res.PleaseCompleteFirst + res.ProductRecommendationSelection);
        });
      return;
    }
    const drawerRef = this.drawerService.create<NewFnaSystemInfoComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: NewFnaSystemInfoComponent,
      nzContentParams: {
        fnaInfo: this.detailInfo,
        mode: this.mode
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.systemSaveChange.subscribe(
        data => {
          drawerRef.close();
          this.getDetail(this.id);
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onFnaDeclaration(): void {
    if (!this.detailInfo.systemStatus || this.detailInfo.systemStatus === 'UNCOMPLETED') {
      this.translate.get(['PleaseCompleteFirst', 'SystemInformation']).subscribe(
        (res: any) => {
          this.toastr.warning(res.PleaseCompleteFirst + res.SystemInformation);
        });
      return;
    }
    const drawerRef = this.drawerService.create<NewFnaImportantClauseComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: NewFnaImportantClauseComponent,
      nzContentParams: {
        fnaInfo: this.detailInfo,
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.declarationSaveChange.subscribe(
        data => {
          drawerRef.close();
          this.getDetail(this.id);
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  showProposalSelection(): void {
    if (!this.detailInfo.id) {
      this.translate.get(['PleaseCompleteFirst', 'FnaInfo']).subscribe(
        (res: any) => {
          this.toastr.warning(res.PleaseCompleteFirst + res.FnaInfo);
        });
      return;
    }
    if (this.detailInfo.proposalId) {
      this.onProposalDetail();
    } else {
      // 只能关联已完成的计划书
      const search = new ProposalSearch();
      search.status = ProposalStatus.COMPLETED;
      // // 2022-12-05 17：00 修改需求 客户要求放开限制
      // // search.productCode = this.booking.productCode;
      // search.categoryCode = this.product?.categoryCode;
      // search.companyCode = this.product?.companyCode;
      const drawerRef = this.drawerService.create<ProposalSelectionComponent, { value: any }, string>({
        nzWidth: 800,
        nzMaskClosable: true,
        nzContent: ProposalSelectionComponent,
        nzContentParams: {
          search,
          enableOptions: true,
          isNewFna: true,
          newFna: JSON.parse(JSON.stringify(this.detailInfo))
        },
      });
      drawerRef.afterOpen.subscribe(() => {
        const component = drawerRef.getContentComponent();
        component.proposalSelected
          .subscribe(
            value => {
              const proposal: Proposal = value as Proposal;
              this.associateProposal(proposal.proposalId, drawerRef);
            });
        component.proposalApplyFna
          .subscribe(
            value => {
              drawerRef.close();
              this.getDetail(this.id);
            });
      });
      drawerRef.afterClose.subscribe(data => {
        if (typeof data === 'string') {
        }
      });
    }
  }


  associateProposal(proposalId: string, drawerRef: NzDrawerRef<ProposalSelectionComponent, string>): void {
    this.loading = true;
    this.newFnaService.relatedProposalNewFna({id: this.id, proposalId})
      .subscribe(
        progress => {
          drawerRef.close();
          this.loading = false;
          this.getDetail(this.id);
        },
        error => {
          this.loading = false;
        });
  }

  onProposalDetail(): void {
    if (!this.detailInfo.proposalId) {
      return;
    }
    this.loading = true;
    this.proposalService.info(this.detailInfo.proposalId)
      .subscribe(
        proposal => {
          this.loading = false;
          const drawerRef = this.drawerService.create<ProposalDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: ProposalDetailComponent,
            nzContentParams: {
              proposal
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        }
      );
  }
}
