import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProposalSearch } from '../../proposal/proposal-types';
import { Product } from '../../product/product-types';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import {
  ProductSelectionComponent,
  ProductSelectionType
} from '../../shared/component/product-selection/product-selection.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NewSaleReportReq } from '../sale-report-type';
import { ProductService } from '../../product/product.service';

@Component({
  selector: 'app-sale-report-search',
  templateUrl: './sale-report-search.component.html',
  styleUrls: ['./sale-report-search.component.less']
})
export class SaleReportSearchComponent implements OnInit {
  @Input()
  search: NewSaleReportReq;
  companyOptions: PropertySelectOption[] = [];
  @Output()
  saleReportSearch: EventEmitter<NewSaleReportReq> = new EventEmitter<NewSaleReportReq>();
  constructor(private drawerService: NzDrawerService,
              private productService: ProductService) { }

  ngOnInit(): void {
    this.productService.companies()
      .subscribe(
        data => {
          data.forEach(company => {
            this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
          });
        },
        error => {
        });
  }

  onProductCompanyChange(companyCode: string): void {
    if (this.search.companyCode !== companyCode) {
      this.search.productCode = null;
      // this.search.product = new Product();
    }
  }

  onReset(): void {
    this.search.productCode = '';
    this.search.policyDate = '';
    this.search.companyCode = '';
    this.search.underwritingNo = '';
    this.search.policyNo = '';
    this.search.productName = '';
    this.saleReportSearch.emit(this.search);
  }

  onSearch(): void {
    this.saleReportSearch.emit(this.search);
  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.DEFAULT
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.search.productName = product.productName;
            this.search.productCode = product.productCode;
            drawerRef.close();
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

}
