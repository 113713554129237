import {Component, OnInit} from '@angular/core';
import {ContactsType, FollowListRes} from '../../contact-types';
import {ContactService} from '../../contact.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {ContactAddMemberComponent} from '../../contact-add-member/contact-add-member.component';
import {ContactsCreateRecordComponent} from '../contacts-create-record/contacts-create-record.component';
import {ContactsRecordDetailsComponent} from '../contacts-record-details/contacts-record-details.component';

@Component({
  selector: 'app-contacts-meeting-record',
  templateUrl: './contacts-meeting-record.component.html',
  styleUrls: ['./contacts-meeting-record.component.less']
})
export class ContactsMeetingRecordComponent implements OnInit {
  id: number; // 联系人ID
  meetingRecord: FollowListRes;
  spinning = false;

  constructor(private contactService: ContactService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
    // this.contactService.followStatus()
    //   .subscribe(
    //     data => {
    //       console.log(data);
    //     },
    //     error => {
    //       console.log(error);
    //     }
    //   );
  }

  addRecord(): void {
    const drawerRef = this.drawerService.create<ContactsCreateRecordComponent, { value: any }, string>({
      nzWidth: 800,
      nzContent: ContactsCreateRecordComponent,
      nzMask: false,
      nzContentParams: {
        id: this.id,
        type: ContactsType.Create
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.recordCreated
        .subscribe(
          data => {
            drawerRef.close();
            this.onReset();
          }
        );
    });
  }

  onReset(): void {
    this.spinning = true;
    this.contactService.meeting(this.id)
      .subscribe(
        data => {
          this.meetingRecord = data;
          this.spinning = false;
        },
        error => {
          this.spinning = false;
          console.log(error);
        }
      );
  }

  onDetails(id): void {
    this.contactService.meetingDetails(id)
      .subscribe(
        data => {
          const drawerRef = this.drawerService.create<ContactsRecordDetailsComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: ContactsRecordDetailsComponent,
            nzMask: false,
            nzContentParams: {
              followId: id,
              id: this.id,
              dataInfo: data
            }
          });
          drawerRef.afterClose.subscribe(() => {
            const component = drawerRef.getContentComponent();
            this.onReset();
          });
        },
        error => {
          console.log(error);
        }
      );

  }

  onDel(val): void {
    const param = {followId: val};
    this.contactService.deleteFollow(param)
      .subscribe(
        data => {
          this.onReset();
        },
        error => {
          console.log(error);
        }
      );
  }

}
