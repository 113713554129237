<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{title}}
  </div>
  <div *ngFor="let data of scheduleData;let i = index">
    <div style="margin-top:20px;display: flex">
      <div class="card" appThrottleClick (throttleClick)="onChangeDetail(data,i)">
        <div *ngIf="data.endTime < newData || data.status == 'COMPLETED';else elseTemplate" class="through">
          <ng-container>
            <nz-badge
              class="through"
              nzStatus="success"
              [nzText]="data.title"></nz-badge>
          </ng-container>
          <p>
            <span>{{data.startTime | date:'yyyy-MM-dd HH:mm'}}-{{data.endTime | date:'yyyy-MM-dd HH:mm'}}</span>
          </p>
        </div>
        <ng-template #elseTemplate>
          <div *ngIf="data.status == 'WAIT'">
            <ng-container>
              <nz-badge
                nzStatus="processing"
                [nzText]="data.title"></nz-badge>
            </ng-container>
            <p *ngIf="newData > data.startTime  && data.endTime > newData; else elseBlock">
              <span>{{data.startTime | date:'yyyy-MM-dd HH:mm'}}-{{data.endTime | date:'yyyy-MM-dd HH:mm'}}</span>
            </p>
            <ng-template #elseBlock>
              <p>
                <span>{{data.startTime | date:'yyyy-MM-dd HH:mm'}}-{{data.endTime | date:'yyyy-MM-dd HH:mm'}}</span>
              </p>
            </ng-template>
          </div>
        </ng-template>

      </div>
      <div class="card_right" *ngIf="data.type=== 'PERSONAL'">
        <a nz-button
           [nzType]="'link'"
           nzSize="small"
           appThrottleClick
           style="margin-bottom: 12px"
           (throttleClick)="onDetail(data)">
          <img width="24" src="assets/images/ic_complete.svg" alt="">
        </a>
        <a nz-button
           [nzType]="'link'"
           nzSize="small"
           appThrottleClick
           (throttleClick)="onDelete(data,i)">
          <img width="24" src="assets/images/ic_schedule_delete.svg" alt="">
        </a>
      </div>
    </div>
  </div>
</div>
