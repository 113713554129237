import {
  AfterViewInit,
  Component, ElementRef, EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { BottomSheetRef } from './bottom-sheet-ref';
import { Overlay, OverlayConfig, OverlayKeyboardDispatcher, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ESCAPE } from '@angular/cdk/keycodes';

export const BOTTOM_SHEET_ANIMATE_DURATION = 300;

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.less']
})
export class BottomSheetComponent<T = NzSafeAny, R = NzSafeAny, D = NzSafeAny> extends BottomSheetRef<T, R> implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('sheetTemplate', {static: true})
  sheetTemplate!: TemplateRef<void>;

  @Input()
  content: TemplateRef<{ $implicit: D; sheetRef: BottomSheetRef<R> }> | Type<T>;

  contentOutlet: Type<T>;

  @ViewChild(CdkPortalOutlet, {static: false})
  contentPortalOutlet?: CdkPortalOutlet;

  @ViewChild('content', {read: ElementRef})
  contentElementRef: ElementRef;

  @Output() readonly atViewInit = new EventEmitter<void>();
  @Output() readonly atViewClose = new EventEmitter<MouseEvent>();
  @Output() readonly atVisibleChange = new EventEmitter<boolean>();

  private destroy$ = new Subject<void>();

  private componentInstance: T | null = null;

  attribute?: D;

  overlayRef?: OverlayRef | null;
  portal?: TemplatePortal;

  isOpen = false;

  templateContext: { $implicit: D | undefined; drawerRef: BottomSheetRef<R> } = {
    $implicit: undefined,
    drawerRef: this as BottomSheetRef<R>
  };

  afterOpen = new Subject<void>();
  afterClose = new Subject<R>();

  constructor(public overlay: Overlay,
              private injector: Injector,
              private viewContainerRef: ViewContainerRef,
  ) {
    super();
  }

  ngOnInit(): void {
    // this.attachOverlay();
    this.attachContent();
  }

  ngAfterViewInit(): void {

    // if (!this.overlayRef) {
    //   this.overlayRef = this.overlay.create(this.getOverlayConfig(this.contentElementRef));
    // }


    if (this.atViewInit.observers.length) {
      setTimeout(() => {
        this.atViewInit.emit();
      });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
    this.disposeOverlay();
  }

  isTemplateRef(value: {}): boolean {
    return value instanceof TemplateRef;
  }

  private attachContent(): void {
    if (this.content instanceof Type) {
      this.contentOutlet = this.content;
      // const childInjector = Injector.create({
      //   parent: this.injector,
      //   providers: [{provide: BottomSheetRef, useValue: this}]
      // });
      //
      // const componentPortal = new ComponentPortal<T>(this.content, null, childInjector);
      // const componentRef = this.overlayRef.attach(componentPortal);
      // this.componentInstance = componentRef.instance;
      // Object.assign(componentRef.instance, this.attribute);
      // componentRef.changeDetectorRef.detectChanges();
    }
  }

  private attachOverlay(): void {

    if (!this.overlayRef) {
      // this.portal = new TemplatePortal(this.sheetTemplate, this.viewContainerRef);
      this.overlayRef = this.overlay.create(this.getOverlayConfig(this.contentElementRef));
    }

    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      this.overlayRef.attach(this.portal);
      this.overlayRef?.keydownEvents()
        .pipe(takeUntil(this.destroy$))
        .subscribe((event: KeyboardEvent) => {
          console.log(event);
          // if (event.code === ESCAPE && this.isOpen && this.nzKeyboard) {
          //   this.nzOnClose.emit();
          // }
        });
      this.overlayRef
        .detachments()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.disposeOverlay();
        });
    }
  }

  private getOverlayConfig(elementRef: ElementRef): OverlayConfig {
    console.log(elementRef);
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(elementRef.nativeElement)
      .withPositions([{
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetX: 0,
        offsetY: 0
      }])
      .withLockedPosition(true);

    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    const element: HTMLElement = elementRef.nativeElement as HTMLElement;

    return new OverlayConfig({
      disposeOnNavigation: true,
      // width: element.offsetWidth,
      // height: element.offsetHeight,
      positionStrategy,
      scrollStrategy
    });
  }

  private disposeOverlay(): void {
    this.overlayRef?.dispose();
    this.overlayRef = null;
  }

  open(): void {
    this.isOpen = true;
    this.atVisibleChange.emit(true);

    setTimeout(() => {
      this.afterOpen.next();
    }, this.getAnimationDuration());
  }

  close(result?: R): void {
    this.isOpen = false;
    this.atVisibleChange.emit(false);

    setTimeout(() => {
      this.afterClose.next(result);
      this.afterClose.complete();
      this.componentInstance = null;
    }, this.getAnimationDuration());
  }

  onMask(): void {
    this.atViewClose.emit();
  }

  private getAnimationDuration(): number {
    return BOTTOM_SHEET_ANIMATE_DURATION;
  }

}
