import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ContactListRes, ContactSearch} from '../../../contact/contact-types';
import {debounceTime} from 'rxjs/operators';
import {ContactService} from '../../../contact/contact.service';
import {PagedResp} from '../../../api/types';
import {NzDrawerRef} from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-contacts-selection',
  templateUrl: './contacts-selection.component.html',
  styleUrls: ['./contacts-selection.component.less']
})
export class ContactsSelectionComponent implements OnInit {

  drawerRef: NzDrawerRef<ContactsSelectionComponent, string>;
  search = new ContactSearch();
  contacts: PagedResp<ContactListRes>;
  searchParamChange: EventEmitter<any> = new EventEmitter<any>();

  id: number; // familyID
  @Output()
  contactsSelected: EventEmitter<ContactListRes> = new EventEmitter<ContactListRes>();
  spinning = false;

  loading = false;
  constructor(private contactService: ContactService) { }

  ngOnInit(): void {
    this.onSearch(1);
    this.searchParamChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          this.onSearch(1);
        }
      );
  }

  onSearchParamChange(): void {
    this.searchParamChange.emit('');
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.search.excludedFamilyId = this.id;
    this.contactService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.contacts = data;
        },
        error => {
          this.loading = false;
        });
  }

  onReload(): void {
    this.onSearch(this.search.pageNum);
  }

  onContactsSelected(info: any, i: number): void {
    this.drawerRef?.close();
    this.contactsSelected.emit(info);
  }
}
