import { Component, OnInit } from '@angular/core';
import { Instance, PagedResp } from '../../api/types';
import { ContactTable, SubordinateListRes } from '../contact-types';
import { ContactService } from '../contact.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ContactsTreeComponent } from '../contacts-tree/contacts-tree.component';
import { WorkbenchService } from '../../workbench/workbench.service';
import { Workbench } from '../../workbench/workbench-types';

@Component({
  selector: 'app-contacts-subordinate',
  templateUrl: './contacts-subordinate.component.html',
  styleUrls: ['./contacts-subordinate.component.less']
})
export class ContactsSubordinateComponent implements OnInit {
  spinning = false;
  loading = false;
  qrCodePopoverVisible = false;
  codeImgUrl: string;
  isLimit = false;
  featuresArr = [];
  workbench: Workbench;
  contactsTable: SubordinateListRes[] = [];
  search: ContactTable = new ContactTable();
  instance: Instance;
  filterPopoverVisible = false;

  constructor(private contactService: ContactService,
              private workbenchService: WorkbenchService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
    this.instance = this.workbenchService.mockInstance;
    this.loading = true;
    this.contactService.subordinate(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.contactsTable = data;
        },
        error => {
          this.loading = false;
        }
      );
    this.workbench = this.workbenchService.workbench;
    this.onLimit();
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.contactService.subordinate(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.contactsTable = data;
        },
        error => {
          this.loading = false;
        }
      );
  }

  onReload(): void {
    this.search = new ContactTable();
    this.onSearch(this.search.pageNum);
    if (this.isLimit) {
      this.onCode();
    }
  }

  onStructure(): void {
    this.contactService.tree()
      .subscribe(
        data => {
          const drawerRef = this.drawerService.create<ContactsTreeComponent, { value: any }, string>({
            nzWidth: 600,
            nzContent: ContactsTreeComponent,
            nzContentParams: {
              treeData: data
            }
          });
        },
        error => {
        }
      );
  }

  onLimit(): void {
    this.isLimit = false;
    this.workbench.features.forEach(item => {
      this.featuresArr.push(item.squares);
    });
    this.featuresArr.map(item => {
      item.filter(i => {
        if (i.name === 'EXPANDING' && i.enable && i.visible) {
          this.isLimit = true;
        }
      });
    });
  }

  onCode(): void {
    this.contactService.qrCode()
      .subscribe(
        data => {
          this.codeImgUrl = data;
        },
        error => {
          console.log(error);
        }
      );
  }
}
