import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Inject, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { registerLocaleData } from '@angular/common';
import locale_zh from '@angular/common/locales/zh';
import locale_en from '@angular/common/locales/en';
import locale_ja from '@angular/common/locales/ja';
import locale_ko from '@angular/common/locales/ko';
import { LocaleService } from './core/locale.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SharedModule } from './shared/shared.module';
import { SalesService } from './sales/sales.service';
import { SalesModule } from './sales/sales.module';
import { AuthModule } from './auth/auth.module';
import { SwiperModule } from 'swiper/angular';
import { AuthService } from './auth/auth.service';
import { HttpService } from './core/http.service';
import { WorkbenchService } from './workbench/workbench.service';
import { WorkbenchModule } from './workbench/workbench.module';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EncryptorService } from './shared/service/encryptor.service';
import { AppInjector } from './core/app-injector';
import SwiperCore, { Pagination } from 'swiper/core';
import { Locale } from './api/types';
import { HttpCommonInterceptor } from './core/http-common.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AccountModule } from './account/account.module';
import { ProductModule } from './product/product.module';
import { ProductService } from './product/product.service';
import { ProposalModule } from './proposal/proposal.module';
import { ProposalService } from './proposal/proposal.service';
import { BookingModule } from './booking/booking.module';
import { BookingService } from './booking/booking.service';
import { MessageService } from './message/message.service';
import { MessageModule } from './message/message.module';
import { AmlModule } from './aml/aml.module';
import { InformationModule } from './information/information.module';
import { NzImageService } from 'ng-zorro-antd/image';
import { NgxEchartsModule } from 'ngx-echarts';
import { FnaModule } from './fna/fna.module';
import { FnaService } from './fna/fna.service';
import { TicketModule } from './ticket/ticket.module';
import { FormsModule } from '@angular/forms';
import { PolicyModule } from './policy/policy.module';
import { UnderwritingModule } from './underwriting/underwriting.module';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { OverlayModule } from '@angular/cdk/overlay';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { DocumentModule } from './document/document.module';
import { PolicyReminderComponent } from './policy-reminder/policy-reminder.component';
import { PolicyReminderMainComponent } from './policy-reminder/policy-reminder-main.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { PolicyReminderModule } from './policy-reminder/policy-reminder.module';
import { KnowledgeModule } from './knowledge/knowledge.module';
import { CommissionModule } from './commission/commission.module';
import { ProposalWithdrawalEditComponent } from './proposal/components/proposal-withdrawal-edit/proposal-withdrawal-edit.component';
import { ContactModule } from './contact/contact.module';
import { ShareModule } from './share/share.module';
import { QRCodeModule } from 'angular2-qrcode';
import { QuotationModule } from './quotation/quotation.module';
import { ContractModule } from './contract/contract.module';
import { WebSocketService } from './core/web-socket.service';
import { PreSalesModule } from './pre-sales/pre-sales.module';
import { ScheduleComponent } from './schedule/schedule.component';
import { ScheduleMainComponent } from './schedule/schedule-main.component';
import { ScheduleModule } from './schedule/schedule.module';
import AppErrorHandler from './app-error-handler';
import { LeaveWordModule } from './leave-word/leave-word.module';
import { ProposalCompareComponent } from './proposal-compare/proposal-compare.component';
import { ProposalCompareModule } from './proposal-compare/proposal-compare.module';
import { SalesPactModule } from './sales-pact/sales-pact.module';
import { ToDoModule } from './to-do/to-do.module';
import { ProposalCustomModule } from './proposal-custom/proposal-custom.module';
import { CommissionForecastModule } from './commission-forecast/commission-forecast.module';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { QuillModule } from 'ngx-quill';
import { ProductPromotionModule } from './product-promotion/product-promotion.module';
import { SaleReportModule} from './sale-report/sale-report.module';
import { NewFnaComponent } from './new-fna/new-fna.component';
import { NewFnaMainComponent } from './new-fna/new-fna-main.component';
import { NewFnaModule } from './new-fna/new-fna.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NewFnaTemplateComponent } from './new-fna-template/new-fna-template.component';
import { NewFnaTemplateModule } from './new-fna-template/new-fna-template.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { PremiumFinancingModule } from './premium-financing/premium-financing.module';
import { PremiumFinancingService } from './premium-financing/premium-financing.service';
import { ZuuFnaTemplateModule } from './zuu-fna-template/zuu-fna-template.module';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { BvModuleModule } from './bv-module/bv-module.module';
import { NewFnaPibaModule } from './new-fna-piba/new-fna-piba.module';
import { TicketOrderModule } from './ticket-order/ticket-order.module';
import { InvitationModule } from './invitation/invitation.module';

registerLocaleData(locale_zh, 'zh');
registerLocaleData(locale_en, 'en');
registerLocaleData(locale_ja, 'ja');
registerLocaleData(locale_ko, 'ko');

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// install Swiper modules
SwiperCore.use([Pagination]);

@NgModule({
  declarations: [
    AppComponent,
    PolicyReminderComponent,
    PolicyReminderMainComponent,
    ScheduleComponent,
    ScheduleMainComponent,
    ProposalCompareComponent,
    NewFnaComponent,
    NewFnaMainComponent,
    NewFnaTemplateComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        OverlayModule,
        SharedModule,
        AuthModule,
        SalesModule,
        WorkbenchModule,
        AccountModule,
        ProductModule,
        ProposalModule,
        BookingModule,
        MessageModule,
        AmlModule,
        InformationModule,
        ProductPromotionModule,
        FnaModule,
        AppRoutingModule,
        UnderwritingModule,
        TicketModule,
        PolicyModule,
        FormsModule,
        DocumentModule,
        KnowledgeModule,
        CommissionModule,
        ContactModule,
        QRCodeModule,
        ShareModule,
        ContractModule,
        QuotationModule,
        PreSalesModule,
        LeaveWordModule,
        ProposalCustomModule,
        CommissionForecastModule,
        SaleReportModule,
        TicketOrderModule,
        InvitationModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
            closeButton: true,
            // progressBar: true,
            timeOut: 3000
        }), // ToastrModule added
        NgxEchartsModule.forRoot({
            /**
             * This will import all modules from echarts.
             * If you only need custom modules,
             * please refer to [Custom Build] section.
             */
            echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
        }),
        QuillModule.forRoot(),
        SwiperModule,
        NzSpinModule,
        NzSkeletonModule,
        NzTableModule,
        NzGridModule,
        NzToolTipModule,
        NzBadgeModule,
        NzLayoutModule,
        NzTabsModule,
        PolicyReminderModule,
        ScheduleModule,
        ProposalCompareModule,
        SalesPactModule,
        ToDoModule,
        NzAffixModule,
        NzAnchorModule,
        NewFnaModule,
        PdfViewerModule,
        NewFnaTemplateModule,
        NewFnaPibaModule,
        NzDropDownModule,
        NzIconModule,
        PremiumFinancingModule,
        ZuuFnaTemplateModule,
        BvModuleModule,
        NzAlertModule
    ],
  providers: [
    {
      provide: ErrorHandler, useClass: AppErrorHandler
    },
    LocaleService,
    {
      provide: LOCALE_ID,
      useFactory: (localeService: LocaleService) => {
        return localeService.locale;
      },
      deps: [LocaleService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCommonInterceptor,
      multi: true
    },
    AuthService,
    SalesService,
    WorkbenchService,
    ProductService,
    ProposalService,
    BookingService,
    FnaService,
    MessageService,
    EncryptorService,
    WebSocketService,
    DeviceDetectorService,
    NzNotificationService,
    NzModalService,
    NzImageService,
    PremiumFinancingService,
  ],
  exports: [],
  bootstrap: [AppComponent],
  entryComponents: [
    ProposalWithdrawalEditComponent,
  ]
})
export class AppModule {

  constructor(
    private injector: Injector,
    private localeService: LocaleService,
    @Inject(LOCALE_ID) private systemLocale: Locale) {

    // 设置全局injector
    AppInjector.injector = injector;

    // 先从本地存储获取语言，如果为空，则设置为系统语言
    let locale = this.localeService.locale ?? systemLocale;
    // 如果语言不在支持列表，默认设计为香港繁体
    if (environment.supportedLocale.indexOf(locale) === -1) {
      locale = Locale.hk;
    }
    // 将语言保存到本地存储，并应用语言
    this.localeService.setLocale(locale);

    (window as any).pdfWorkerSrc = '/assets/js/pdf.worker.min.js';

  }

}
