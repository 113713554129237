<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!proposals">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="proposals" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="proposals.list"
                [nzPageIndex]="proposals.pageNum"
                [nzPageSize]="proposals.pageSize"
                [nzTotal]="proposals.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{ x: '1690px'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'ProposalList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate" style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'ProposalApply' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onProposalApply()">
                  <img alt="" width="22" src="assets/images/ic_proposal_new.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'ProposalSearch' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onProposalSearch()">
                  <img alt="" width="22" src="assets/images/ic_search.svg">
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ proposals.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="150px"><span>{{'ProposalID' | translate}}</span></th>
<!--          <th><span nz-tooltip [nzTooltipTitle]="'RefereeAccount' | translate">{{'RefereeAccount' | translate}}</span></th>-->
          <th><span>{{'Referee' | translate}}</span></th>
          <th nzWidth="250px"><span>{{'ProductName' | translate}}</span></th>
          <th nzWidth="120px"><span>{{'SumAssured' | translate}}</span></th>
          <th nzWidth="120px"><span>{{'Premium' | translate}}</span></th>
          <th><span>{{'PaymentTerm' | translate}}</span></th>
          <th><span>{{'Gender' | translate}}</span></th>
          <th><span>{{'Age' | translate}}</span></th>
          <th><span>{{'Currency' | translate}}</span></th>
          <th><span>{{'SmokeCondition' | translate}}</span></th>
          <th nzWidth="140px"><span>{{'Status' | translate}}</span></th>
          <th><span>{{'StatusNote' | translate}}</span></th>
          <th><span>{{'ApplicationTime' | translate}}</span></th>
          <th nzRight nzWidth="200px"><span>{{'Functions' | translate}}</span></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let proposal of proposals.list; let i = index" appThrottleClick (throttleClick)="onDetail(proposal.proposalId)">
          <td nzLeft>
            <nz-badge [nzDot]="proposal.reminder">
              <span style="font-weight: bold; font-size: 13px">
                {{ proposal.proposalId }}
              </span>
            </nz-badge>
          </td>
<!--          <td><span>{{ proposal.accountName }}</span></td>-->
          <td>
            <div>{{ proposal.salesName }}</div>
            <div>{{ proposal.accountName }}</div>
          </td>
          <td>
            <div style="display: flex; align-items: center;">
              <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="proposal.companyLogo" alt=""/>
              <span>{{ proposal.productName }}</span>
            </div>
          </td>
          <td>{{ proposal.sumAssuredFormat }}</td>
          <td>{{ proposal.premiumFormat }}</td>
          <td>{{ proposal.paymentTerm }}</td>
          <td>{{ proposal.gender }}</td>
          <td>{{ proposal.age }}</td>
          <td>{{ proposal.currency }}</td>
          <td>{{ proposal.smoke }}</td>
          <td>
            <div [ngSwitch]="proposal.status">
              <div class="flex-row">
                <span *ngSwitchCase="'PENDING'" class="text-warning">●</span>
                <span *ngSwitchCase="'COMPLETED'" class="text-success">●</span>
                <span *ngSwitchCase="'REPLIED'" class="text-success">●</span>
                <span *ngSwitchCase="'CANCELLED'" class="text-cancel">●</span>
                <span> {{ proposal.status | metadataTranslate : 'proposalStatus'}}</span>
                <a *ngIf="proposal.statusNoteHistory && proposal.statusNoteHistory.length > 0"
                   nz-icon nzType="info-circle" nzTheme="outline"  style="margin-left: 5px"
                   nz-tooltip [nzTooltipTitle]="'ProposalStatusHistory' | translate"
                   (click)="onStatusHistory(proposal, statusHistoryTitle, statusHistoryContent, statusHistoryFooter); $event.stopPropagation()">
                </a>
              </div>
            </div>
          </td>
          <td><div nz-tooltip [nzTooltipTitle]="proposal.salesStatusNote">
            {{proposal.salesStatusNote | ellipsis: 30 }}
          </div>
          </td>
          <td><span>{{ proposal.applicationTime | date:'yyyy-MM-dd HH:mm:ss' }}</span></td>
          <td nzRight>
            <div class="functions">
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'LeaveMessage' | translate"
                 style="padding-right: 0"
                 appThrottleClick (throttleClick)="onLeaveMessage(proposal); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_leave_message.svg">
                <div *ngIf="proposal.unReadCount > 0" class="red-dot"></div>
              </a>
              <a *ngIf="proposal.status === 'PENDING' && proposal.canRetry()" nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Retry' | translate"
                 appThrottleClick (throttleClick)="onRetry(proposal.proposalId); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_refresh.svg">
              </a>
              <a *ngIf="workbench.downloadProposal && proposal.status === 'COMPLETED'" nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Preview' | translate"
                 appThrottleClick (throttleClick)="onPreview(proposal); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_view.svg">
              </a>
              <a *ngIf="workbench.downloadProposal && proposal.status === 'COMPLETED'" nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Download' | translate"
                 appThrottleClick (throttleClick)="onDownload(proposal); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_download.svg">
              </a>
              <a *ngIf="workbench.salesDeleteProposal ? mode === dataService.dataModeOwn : false" nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
                 appThrottleClick (throttleClick)="onDelete(proposal, i, tplTitle, tplContent, tplFooter);">
                <img alt="" width="20" src="assets/images/ic_delete.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>

<ng-template #tplTitle>
  <span>{{'DeleteProposal' | translate}}</span>
</ng-template>
<ng-template #tplContent let-params>
  <p>{{'DoYouWantToDeleteProposal' | translate}} :「{{ params.proposalId }}」?</p>
</ng-template>
<ng-template #tplFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()">{{'Cancel' | translate}}</button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onDeleteConfirm(ref, params)">
    {{'Delete' | translate}}
  </button>
</ng-template>


<ng-template #statusHistoryTitle>
  <span style="font-size: 24px">{{'ProposalStatusHistory' | translate}}</span>
</ng-template>
<ng-template #statusHistoryContent let-proposal>
  <div style="overflow-y: scroll; height: 580px">
    <p>{{ toJson(proposal.value) }}</p>

    <nz-table [nzBordered]="false"
              [nzOuterBordered]="true"
              [nzShowPagination]="true"
              [nzLoading]="loading"
              [nzLoadingIndicator]="indicatorTemplate"
              [nzFrontPagination]="false"
              [nzData]="proposal.statusNoteHistory"
              [nzPageSize]="10"
              [nzTotal]="proposal.statusNoteHistory.length">
      <thead>
      <tr>
        <th><span>{{'StatusNote' | translate}}</span></th>
        <th><span>{{'Time' | translate}}</span></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let statusNoteHistory of proposal.statusNoteHistory; let i = index">
        <td>
            <span style="font-weight: bold; font-size: 12px">
              {{ statusNoteHistory.salesNote }}
            </span>
        </td>
        <td>
          <div style="display: flex; align-items: center;">
            <span>{{ statusNoteHistory.createTime }}</span>
          </div>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</ng-template>
<ng-template #statusHistoryFooter let-ref="modalRef">
</ng-template>
