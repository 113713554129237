import { Component, OnInit } from '@angular/core';
import {DataService} from '../shared/service/data.service';
import {WorkbenchService} from '../workbench/workbench.service';
import { Workbench } from '../workbench/workbench-types';

@Component({
  selector: 'app-policy-main',
  templateUrl: './policy-main.component.html',
  styleUrls: ['./policy-main.component.less']
})
export class PolicyMainComponent implements OnInit {
  workbench: Workbench;
  constructor(public dataService: DataService,
              private workbenchService: WorkbenchService) { }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
  }

}
