import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Fna, FnaListRes, FnaSearch } from '../fna-types';
import { debounceTime } from 'rxjs/operators';
import { FnaService } from '../fna.service';
import { DataMode, Instance, PagedResp } from '../../api/types';

@Component({
  selector: 'app-fna-selection',
  templateUrl: './fna-selection.component.html',
  styleUrls: ['./fna-selection.component.less']
})
export class FnaSelectionComponent implements OnInit {

  @Output()
  fnaSelected: EventEmitter<Fna | Fna[]> = new EventEmitter<Fna | Fna[]>();

  searchParamChange: EventEmitter<any> = new EventEmitter<any>();

  fnas: PagedResp<FnaListRes>;
  search = new FnaSearch();
  loading = false;
  spinning = false;
  mode = DataMode.OWN;
  accountName: string;
  instance: Instance = new Instance();

  constructor(private fnaService: FnaService) { }

  ngOnInit(): void {
    this.instance = this.fnaService.mockInstance;
    this.search.appAccountName = this.accountName;
    this.onSearch(1);
    this.searchParamChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          this.onSearch(1);
        }
      );
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.fnaService.listPage(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.fnas = data;
        },
        error => {
          this.loading = false;
        });

  }

  onSearchParamChange(): void {
    this.searchParamChange.emit('');
  }

  onReload(): void {
    this.onSearch(1);
  }

  onFnaSelected(fnaId: number, i: number): void {
    this.loading = true;
    this.fnaService.info(fnaId)
      .subscribe(
        data => {
          this.loading = false;
          this.fnaSelected?.emit(data);
        },
        error => {
          this.loading = false;
        });
  }
}
