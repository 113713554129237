import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProposalComponent } from './proposal.component';
import { ProposalMainComponent } from './proposal-main.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { ProposalsComponent } from './proposals/proposals.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { FileUploadModule } from 'ng2-file-upload';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { FormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { ProposalApplyComponent } from './proposal-apply/proposal-apply.component';
import { ProposalSearchComponent } from './proposal-search/proposal-search.component';
import { ProposalDetailComponent } from './proposal-detail/proposal-detail.component';
import { ProposalSelectionComponent } from './proposal-selection/proposal-selection.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ProposalWithdrawalEditComponent } from './components/proposal-withdrawal-edit/proposal-withdrawal-edit.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { SidebarModule } from 'primeng/sidebar';
import { PremiumFinancingInfoComponent } from './components/premium-financing-info/premium-financing-info.component';
import { BackInfoComponent } from './components/back-info/back-info.component';
import { DiscountInfoComponent } from './components/discount-info/discount-info.component';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import { ProposalStatusHistoryComponent } from './components/proposal-status-history/proposal-status-history.component';
import { ProposalAdditionaComponent } from './components/proposal-additiona/proposal-additiona.component';
import { ProposalHedgePlanComponent } from './components/proposal-hedge-plan/proposal-hedge-plan.component';
import { HedgePreviewComponent } from './components/hedge-preview/hedge-preview.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTagModule } from 'ng-zorro-antd/tag';


@NgModule({
    declarations: [
        ProposalComponent,
        ProposalMainComponent,
        ProposalsComponent,
        ProposalApplyComponent,
        ProposalSearchComponent,
        ProposalDetailComponent,
        ProposalSelectionComponent,
        ProposalWithdrawalEditComponent,
        PremiumFinancingInfoComponent,
        BackInfoComponent,
        DiscountInfoComponent,
        ProposalStatusHistoryComponent,
        ProposalAdditionaComponent,
        ProposalHedgePlanComponent,
        HedgePreviewComponent
    ],
    exports: [
        BackInfoComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        SharedModule,
        TranslateModule,
        NzLayoutModule,
        NzTabsModule,
        NzSpinModule,
        NzIconModule,
        NzGridModule,
        NzSkeletonModule,
        NzTableModule,
        NzButtonModule,
        NzToolTipModule,
        FileUploadModule,
        PdfViewerModule,
        NzDrawerModule,
        NzBadgeModule,
        NgxEchartsModule,
        NzSelectModule,
        NzSwitchModule,
        SidebarModule,
        NzEmptyModule,
        NzPopoverModule,
        NzListModule,
        NzModalModule,
        NzTagModule,
    ]
})
export class ProposalModule { }
