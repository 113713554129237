<nz-tabset [nzSize]="'small'">
  <nz-tab [nzTitle]="'AmlEntry' | translate">
    <app-aml-monitor-entry></app-aml-monitor-entry>
  </nz-tab>
  <nz-tab [nzTitle]="'AmlOpenAlerts' | translate">
    <app-aml-monitor-open-alerts></app-aml-monitor-open-alerts>
  </nz-tab>
  <nz-tab [nzTitle]="'AmlSummary' | translate">
    <app-aml-monitor-summary></app-aml-monitor-summary>
  </nz-tab>
</nz-tabset>
