<nz-tabset [nzSize]="'large'" >
  <nz-tab [nzTitle]=GeneralKnowledge>
    <app-knowledge-list [mode]="'GeneralKnowledge'"></app-knowledge-list>
  </nz-tab>
  <ng-template #GeneralKnowledge>
    {{'GeneralKnowledge' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=PopularProducts>
    <app-knowledge-list [mode]="'PopularProducts'"></app-knowledge-list>
  </nz-tab>
  <ng-template #PopularProducts>
    {{'PopularProduct' | translate}}
  </ng-template>
</nz-tabset>
