import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnderwritingComponent } from './underwriting.component';
import { UnderwritingMainComponent } from './underwriting-main.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { UnderwritingsComponent } from './underwritings/underwritings.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { UnderwritingSearchComponent } from './underwriting-search/underwriting-search.component';
import { UnderwritingDetailComponent } from './underwriting-detail/underwriting-detail.component';
import { UnderwritingOverviewComponent } from './underwriting-overview/underwriting-overview.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzListModule } from 'ng-zorro-antd/list';
import { UnderwritingProductPlanComponent } from './components/underwriting-product-plan/underwriting-product-plan.component';
import { UnderwritingInformationComponent } from './components/underwriting-information/underwriting-information.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { UnderwritingPendingComponent } from './components/underwriting-pending/underwriting-pending.component';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { UnderwritingPendingDetailComponent } from './components/underwriting-pending-detail/underwriting-pending-detail.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { FormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';


@NgModule({
  declarations: [
    UnderwritingComponent,
    UnderwritingMainComponent,
    UnderwritingsComponent,
    UnderwritingSearchComponent,
    UnderwritingDetailComponent,
    UnderwritingOverviewComponent,
    UnderwritingProductPlanComponent,
    UnderwritingInformationComponent,
    UnderwritingPendingComponent,
    UnderwritingPendingDetailComponent
  ],
    imports: [
        CommonModule,
        NzLayoutModule,
        SharedModule,
        TranslateModule,
        RouterModule,
        NzTabsModule,
        NzSpinModule,
        NzSkeletonModule,
        NzTableModule,
        NzFormModule,
        NzToolTipModule,
        NzBadgeModule,
        NzButtonModule,
        NzIconModule,
        NzDropDownModule,
        NzListModule,
        NzDrawerModule,
        NzAffixModule,
        NzAvatarModule,
        NzPopoverModule,
        FormsModule,
        NzCheckboxModule,
        NzSelectModule
    ]
})
export class UnderwritingModule {
}
