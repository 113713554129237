<div class="table-section">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!invitationData">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="invitationData" style="margin-top: 20px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="false"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="invitationData.list || []"
                [nzTitle]="tableHeader"
                [nzScroll]="{ x: mode !== 'OWN' ? language() ?  '1280px' : '1375px' : language() ? '1080px' : '1175px', y: getChartHeight()}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="20">
              <div class="list-header-title">
                <span style="font-weight: 500;font-size: 14px;color: #F66667;">{{'IntendedTips' | translate}}</span>
              </div>
            </nz-col>
            <nz-col [nzSpan]="4">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        class="icon-center"
                        nz-tooltip [nzTooltipTitle]="'Reload' | translate"
                        appThrottleClick (throttleClick)="onReload()">
                  <img alt="" width="24" src="assets/images/fna_reload.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        nz-tooltip [nzTooltipTitle]="'Search' | translate"
                        class="icon-center"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight">
                  <img alt="" width="24" src="assets/images/ic_filter.svg">
                </button>

<!--                <button *ngIf="mode === 'OWN'"-->
<!--                        class="icon-center"-->
<!--                        appThrottleClick (throttleClick)="onForms()"-->
<!--                        nz-tooltip [nzTooltipTitle]="'BlankForm' | translate"-->
<!--                        nz-button nzType="default" nzSize="large" nzShape="circle">-->
<!--                  <img alt="" width="22" src="assets/images/fna_forms.svg">-->
<!--                </button>-->

                <button *ngIf="mode === 'OWN'"
                        class="icon-center"
                        nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'AddIntendedInvitation' | translate"
                        style="background: #1D64C4;"
                        appThrottleClick (throttleClick)="onCreate()">
                  <img alt="" width="22" src="assets/images/fna_plus.svg">
                </button>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>

        <thead>
          <tr>
            <th nzWidth="80px"><span>{{'ID'}}</span></th>
            <th *ngIf="mode !== 'OWN'" nzWidth="200px"><span>{{'SalesChannel' | translate}}</span></th>
            <th [nzWidth]="language() ? '130px' : '150px'">
              <span>{{'ClientName' | translate}} (EN)</span>
            </th>
            <th [nzWidth]="language() ? '90px' : '120px'">
              <span>{{'ClientName' | translate}}</span>
            </th>
            <th [nzWidth]="language() ? '120px' : '180px'"><span>{{'IntendedCompany' | translate}}</span></th>
            <th [nzWidth]="language() ? '150px' : '270px'"><span>{{'IntendedProduct' | translate}}</span>
            <th [nzWidth]="language() ? '140px' : '165px'"><span>{{'BudgetYears' | translate}}</span></th>
            <th nzWidth="160px"
                [nzShowSort]="true"
                [nzSortFn]="true"
                (nzSortOrderChange)="createTimeChange($event)">
              <span>{{'PreferredTime' | translate}}</span>
            </th>
            <th [nzWidth]="language() ? '90px' : '110px'">
              <span>{{'Status' | translate}}</span>
            </th>
            <th nzAlign="left" nzRight nzWidth="120px"><span>{{'Functions' | translate}}</span></th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let invitation of invitationData.list; let i = index" appThrottleClick (throttleClick)="onDetail(invitation)">
          <td nzEllipsis>{{ invitation.id }}</td>
          <td *ngIf="mode !== 'OWN'" nzEllipsis>{{dynamicEncryption('name', invitation.name)}} {{dynamicEncryption('accountName', invitation.accountName)}}</td>
          <td><div class="ellipsis-name" style="max-width: 120px"
                   nz-tooltip [nzTooltipTitle]="customerEnTemplate">{{ dynamicEncryption('client_intention_invite_customer_name', invitation.customerNameEn) }}</div></td>
          <ng-template #customerEnTemplate>
            <span>{{dynamicEncryption('client_intention_invite_customer_name', invitation.customerNameEn)}}</span>
          </ng-template>
          <td>
            <div class="ellipsis-name" style="max-width: 85px"
                 nz-tooltip [nzTooltipTitle]="customerTemplate">{{ dynamicEncryption('client_intention_invite_customer_name', invitation.customerName) }}</div>
            <ng-template #customerTemplate>
              <span>{{dynamicEncryption('client_intention_invite_customer_name', invitation.customerName)}}</span>
            </ng-template>
          </td>
          <td>
            <div *ngIf="invitation?.productCodes?.length" style="display: flex;align-items: center;" nz-tooltip [nzTooltipTitle]="titleTemplate">
              <div class="ellipsis-name" [style]="{'max-width': language() ? '120px' : '180px'}">
                {{ invitation?.companyNames[0] }}
              </div>
              <div *ngIf="invitation?.companyNames?.length"
                   class="card-tag-radius">
                {{invitation?.companyNames?.length}}
              </div>
              <ng-template #titleTemplate>
                <span>{{invitation?.companyNames?.join('、')}}</span>
              </ng-template>
            </div>
            <span *ngIf="!invitation?.productCodes || !invitation?.productCodes?.length">N/A</span>
          </td>
          <td>
            <div *ngIf="invitation?.productCodes?.length" style="display: flex;align-items: center;" nz-tooltip [nzTooltipTitle]="productNameTemplate">
              <div class="ellipsis-name" [style]="{'max-width': language() ? '150px' : '270px'}">
                {{ invitation?.productNames[0] }}
              </div>
              <div *ngIf="invitation?.productNames?.length"
                   class="card-tag-radius">
                {{invitation?.productNames?.length}}
              </div>
              <ng-template #productNameTemplate>
                <span>{{invitation?.productNames?.join('、')}}</span>
              </ng-template>
            </div>
            <span *ngIf="!invitation?.productCodes || !invitation?.productCodes?.length">N/A</span>
          </td>
          <td><span>{{ invitation.budget ? (invitation.budget | number) : 'N/A'}}</span></td>
          <td><span>{{ invitation.meetingTime | date:'yyyy-MM-dd HH:mm' }}</span></td>
          <td>
            <div [ngSwitch]="invitation.status">
              <div *ngSwitchCase="'PENDING-PROCESSING'" class="fna-text-warning">
                <span> {{ 'ProposalStatusPending' | translate }}</span>
              </div>
              <div *ngSwitchCase="'PROCESSED'" class="fna-text-success">
                <span> {{ 'Processed' | translate  }}</span>
              </div>
            </div>
          </td>
          <td nzRight nzAlign="left">
            <div class="functions">
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate"
                 style="padding: 0 7px 0 0;"
                 appThrottleClick (throttleClick)="onDetail(invitation); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_details.svg">
              </a>
              <a *ngIf="mode === 'OWN'" nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Reuse' | translate"
                 style="padding: 0 7px 0 0;"
                 appThrottleClick (throttleClick)="reuseInfo(invitation); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_reuse_invitation.svg">
              </a>
              <a *ngIf="mode === 'OWN' && invitation.status !== 'PROCESSED'" nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
                 style="padding: 0 7px 0 0;"
                 appThrottleClick (throttleClick)="onDelete(invitation, tplTitle, tplContent, tplFooter); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_delete_red.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>

      <div style="margin: 15px 0;text-align: right;padding-right: 25px;">
        <nz-pagination [nzPageIndex]="search.pageNum"
                       [nzPageSize]="search.pageSize"
                       [nzTotal]="invitationData.total"
                       [nzShowTotal]="totalTemplate"
                       (nzPageIndexChange)="onSearch($event)">
        </nz-pagination>
        <ng-template #totalTemplate let-total>{{'HedgeProposalTotal' | translate}}: {{ total }} </ng-template>
      </div>
    </div>
  </nz-spin>
</div>


<!-- 气泡Template -->
<ng-template #filterTemplate>
  <div style="width: 400px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <app-property-select [title]="'Status' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Status' | translate)"
                         [options]="statusOptions"
                         [(value)]="search.status"></app-property-select>
    <!-- 名字 -->
    <app-property-input [title]="'ClientName' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('ClientName' | translate)"
                        [(value)]="search.customerName"></app-property-input>

    <!-- 产品 -->
    <app-property-pick [title]="'IntendedProduct' | translate"
                       appThrottleClick (throttleClick)="onProductSelection()"
                       [placeholder]="'ProductSelect' | translate"
                       [(value)]="search.productName"></app-property-pick>

    <app-property-datetime-range [title]="'IntendedMeetingTime' | translate"
                                 [value]="dates"
                                 (valueChange)="onDateChange($event)"></app-property-datetime-range>

    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>

<ng-template #tplTitle>
  <span>{{'Tips' | translate}}</span>
</ng-template>
<ng-template #tplContent let-params>
  <p>{{'DeleteIntendedInvitation' | translate}} :「{{ params.id }}」?</p>
</ng-template>
<ng-template #tplFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()" [disabled]="deleting">{{'Cancel' | translate}}</button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onDeleteConfirm(ref, params)" [nzLoading]="deleting">
    {{'Delete' | translate}}
  </button>
</ng-template>

