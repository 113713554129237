<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'basicInfoFna' | translate}}
    </div>

    <div class="section-wrapper" style="padding-bottom: 15px;padding-top: 20px;">
      <div style="font-size: 16px;font-weight: 600;color: #333333;">{{'PersonalData' | translate}}</div>
      <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LastNameInEnglish' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('LastNameInEnglish' | translate)"
                          [required]="true"
                          [(value)]="basicInfo.englishLastName"></app-property-input>

      <app-property-input [title]="'FirstNameInEnglish' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('FirstNameInEnglish' | translate)"
                          [required]="true"
                          [(value)]="basicInfo.englishFirstName"></app-property-input>

      <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'lastNameCN' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('lastNameCN' | translate)"
                          [required]="true"
                          [(value)]="basicInfo.chineseLastName"></app-property-input>

      <app-property-input [title]="'firstNameCN' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('firstNameCN' | translate)"
                          [required]="true"
                          [(value)]="basicInfo.chineseFirstName"></app-property-input>

      <app-property-select [title]="'Title' | translate"
                           [titleColor]="'#404040'"
                           [placeholder]="('PleaseSelect' | translate) + ('Title' | translate)"
                           [options]="appellationOptions"
                           [required]="true"
                           [(value)]="basicInfo.appellation"></app-property-select>

      <app-property-select [title]="'Marriage' | translate"
                           [titleColor]="'#404040'"
                           [placeholder]="('PleaseSelect' | translate) + ('Marriage' | translate)"
                           [options]="maritalOptions"
                           [required]="true"
                           [(value)]="basicInfo.maritalStatus"></app-property-select>
      <!-- 是否吸烟 -->
      <app-property-select [title]="'SmokeCondition' | translate"
                           [required]="true"
                           [titleColor]="'#404040'"
                           [placeholder]="('PleaseSelect' | translate) + ('SmokeCondition' | translate)"
                           [options]="smokeOptions"
                           [(value)]="basicInfo.smoke"></app-property-select>

      <app-property-datetime-string [title]="'DateOfBirth' | translate"
                                    [titleColor]="'#404040'"
                                    [placeholder]="('PleaseSelect' | translate) + ('DateOfBirth' | translate)"
                                    [required]="true"
                                    [(value)]="basicInfo.dateOfBirth"></app-property-datetime-string>
      <!-- 国籍（国家地区） -->
      <app-property-pick [displayMode]="'horizontal'"
                         [titleColor]="'#404040'"
                         [title]="'Nationality' | translate"
                         [required]="true"
                         appThrottleClick (throttleClick)="onCountry()"
                         [value]="nationalityString"></app-property-pick>

      <app-property-input [title]="'CountryBirth' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('CountryBirth' | translate)"
                          [required]="true"
                          [(value)]="basicInfo.countryOfBirth"></app-property-input>

      <app-property-input [title]="'CountryOfTaxResidence' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('CountryOfTaxResidence' | translate)"
                          [required]="true"
                          [(value)]="basicInfo.taxResidence"></app-property-input>

      <!-- 手提电话 -->
      <app-property-pick [title]="'Mobile' | translate"
                         [required]="true"
                         [titleColor]="'#404040'"
                         appThrottleClick (throttleClick)="onMobilePhoneNumber()"
                         [value]="(basicInfo.mobile ? '+' + basicInfo.mobile : basicInfo.mobile)">
      </app-property-pick>

      <!-- 住宅电话 -->
      <app-property-pick [title]="'Home' | translate"
                         [required]="true"
                         [titleColor]="'#404040'"
                         appThrottleClick (throttleClick)="onHomePhoneNumber()"
                         [value]="(basicInfo.homePhone ? '+' + basicInfo.homePhone : basicInfo.homePhone)">
      </app-property-pick>

      <!-- 办公室电话 -->
      <app-property-pick [title]="'Office' | translate"
                         [required]="true"
                         [titleColor]="'#404040'"
                         appThrottleClick (throttleClick)="onOfficeTelephone()"
                         [value]="(basicInfo.officeTelephone ? '+' + basicInfo.officeTelephone : basicInfo.officeTelephone)">
      </app-property-pick>


      <app-property-input [title]="'Email'"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + 'Email'"
                          [required]="true"
                          [(value)]="basicInfo.emailAddress"></app-property-input>

      <app-property-select [title]="'Education' | translate"
                           [titleColor]="'#404040'"
                           [placeholder]="('PleaseSelect' | translate) + ('Education' | translate)"
                           [options]="educationOptions"
                           [required]="true"
                           [(value)]="basicInfo.education"></app-property-select>

      <app-property-select [title]="'EmploymentStatus' | translate"
                           [titleColor]="'#404040'"
                           [placeholder]="('PleaseSelect' | translate) + ('EmploymentStatus' | translate)"
                           [options]="employmentOptions"
                           [required]="true"
                           [(value)]="basicInfo.employment"></app-property-select>

      <app-property-input [title]="'Position' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('Position' | translate)"
                          [required]="true"
                          [(value)]="basicInfo.position"></app-property-input>

      <app-property-input [title]="'Occupation' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('Occupation' | translate)"
                          [required]="true"
                          [(value)]="basicInfo.industry"></app-property-input>

      <app-property-select [title]="'ExpectedRetirementAge' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('ExpectedRetirementAge' | translate)"
                           [options]="intendedOptions"
                           [titleColor]="'#404040'"
                           [required]="true"
                           [(value)]="basicInfo.intendedRetirement"></app-property-select>

      <app-property-input *ngIf="basicInfo.intendedRetirement == 'OTHERS'"
                          [title]="'OtherInputs' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate)"
                          [required]="true"
                          [(value)]="basicInfo.retirementAdditional"></app-property-input>

    </div>
    <div class="section-wrapper" style="padding-bottom: 15px;padding-top: 20px;">
      <div style="font-size: 16px;font-weight: 600;color: #333333;">{{'FamilySituation' | translate}}</div>

      <app-property-input-number [title]="'Father' | translate"
                                 [required]="true"
                                 [titleColor]="'#404040'"
                                 [placeholder]="('PleaseEnter' | translate) + ('Age' | translate)"
                                 [(value)]="basicInfo.familySituationFatherAge"></app-property-input-number>

      <app-property-select [title]="'NeedDependent' | translate"
                           [placeholder]="('PleaseSelect' | translate)"
                           [options]="needDependentOptions"
                           [required]="true"
                           [titleColor]="'#404040'"
                           [(value)]="basicInfo.familySituationFatherDependents"></app-property-select>

      <app-property-input-number [title]="'Mother' | translate"
                                 [required]="true"
                                 [titleColor]="'#404040'"
                                 [placeholder]="('PleaseEnter' | translate) + ('Age' | translate)"
                                 [(value)]="basicInfo.familySituationMotherAge"></app-property-input-number>

      <app-property-select [title]="'NeedDependent' | translate"
                           [placeholder]="('PleaseSelect' | translate)"
                           [options]="needDependentOptions"
                           [required]="true"
                           [titleColor]="'#404040'"
                           [(value)]="basicInfo.familySituationMotherDependents"></app-property-select>

      <app-property-input-number [title]="'Spouse' | translate"
                                 [required]="true"
                                 [titleColor]="'#404040'"
                                 [placeholder]="('PleaseEnter' | translate) + ('Age' | translate)"
                                 [(value)]="basicInfo.familySituationSpouseAge"></app-property-input-number>

      <app-property-select [title]="'NeedDependent' | translate"
                           [placeholder]="('PleaseSelect' | translate)"
                           [options]="needDependentOptions"
                           [required]="true"
                           [titleColor]="'#404040'"
                           [(value)]="basicInfo.familySituationSpouseDependents"></app-property-select>

      <app-property-input-number [title]="('Children' | translate) + ('totals' | translate)"
                                 [required]="true"
                                 [titleColor]="'#404040'"
                                 [placeholder]="('PleaseEnter' | translate)"
                                 [(value)]="basicInfo.familySituationChidrenTotal"></app-property-input-number>

      <div class="children-age">
        <div style="display: flex;color: #404040">
          {{'Children' | translate}}{{'Age' | translate}}
          <img alt="" width="24" src="assets/images/ic_required.svg">
        </div>
        <div class="children-age-right">
          <nz-input-number style="width: 120px;"
                           [(ngModel)]="basicInfo.familySituationChidrenAgeStart"
                           [nzPlaceHolder]="('PleaseEnter' | translate)"></nz-input-number>
          <div>{{'To' | translate}}</div>
          <nz-input-number style="width: 120px;"
                           class="children-age-right-r"
                           [(ngModel)]="basicInfo.familySituationChidrenAgeEnd"
                           [nzPlaceHolder]="('PleaseEnter' | translate)"></nz-input-number>
        </div>
      </div>

      <app-property-select [title]="'NeedDependent' | translate"
                           [placeholder]="('PleaseSelect' | translate)"
                           [options]="needDependentOptions"
                           [required]="true"
                           [titleColor]="'#404040'"
                           [(value)]="basicInfo.familySituationChidrenDependents"></app-property-select>
    </div>

    <div class="section-wrapper" style="padding-bottom: 15px;padding-top: 20px;">
      <div style="font-size: 16px;font-weight: 600;color: #333333;">{{'NewExistingCoverage' | translate}}</div>

      <app-property-input-number [title]="('LifeInsurance' | translate) + '-' + ('SumInsured' | translate)"
                                 [required]="true"
                                 [titleColor]="'#404040'"
                                 [placeholder]="('PleaseEnter' | translate)"
                                 [(value)]="basicInfo.lifeInsuranceSumInsured"></app-property-input-number>

      <app-property-input-number [title]="('CriticalIllnessInsurance' | translate) + '-' + ('SumInsured' | translate)"
                                 [required]="true"
                                 [titleColor]="'#404040'"
                                 [placeholder]="('PleaseEnter' | translate)"
                                 [(value)]="basicInfo.criticalIllnessInsuranceSumInsured"></app-property-input-number>

      <app-property-input-number [title]="('AccidentalInsurance' | translate) + '-' + ('SumInsured' | translate)"
                                 [required]="true"
                                 [titleColor]="'#404040'"
                                 [placeholder]="('PleaseEnter' | translate)"
                                 [(value)]="basicInfo.accidentalInsuranceSumInsured"></app-property-input-number>

      <app-property-input [title]="('NewMedicalInsurance' | translate) + '(' + ('NewType' | translate) + ')'"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate)"
                          [required]="true"
                          [(value)]="basicInfo.medicalInsuranceType"></app-property-input>

      <app-property-input [title]="('OtherExistingCoverage' | translate) + '(' + ('PleaseSpecifyDetails' | translate) + ')'"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate)"
                          [required]="true"
                          [(value)]="basicInfo.otherExistingCoverage"></app-property-input>
    </div>

    <div class="section-wrapper" style="padding-bottom: 15px;padding-top: 20px;">
      <div style="font-size: 16px;font-weight: 600;color: #333333;">{{'FinancialInformation' | translate}}</div>
      <div style="display: flex;color: #404040;padding-top: 5px;">
        <div style="flex: 1;margin-right: 16px;">{{'AverageMonthlyIncomesPast2Years' | translate}} (HKD)</div>
        <div style="flex: 1;padding-left: 16px;">{{'AverageMonthlyExpensesPast2Years' | translate}} (HKD)</div>
      </div>
      <div style="display: flex">
        <div style="flex: 1;margin-right: 16px;padding-bottom: 15px;">
          <app-property-input-number [title]="'Salary/Commission/Bonus' | translate"
                                     [required]="true"
                                     [titleColor]="'#404040'"
                                     (valueChange)="totalAverageMonthlyIncomes()"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     [(value)]="basicInfo.salaryCommissionBonusAmount"></app-property-input-number>

          <app-property-input-number [title]="'RentalIncome' | translate"
                                     [titleColor]="'#404040'"
                                     [required]="true"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalAverageMonthlyIncomes()"
                                     [(value)]="basicInfo.rentalIncomeAmount"></app-property-input-number>

          <app-property-input [title]="'OtherIncomeItem' | translate"
                              [titleColor]="'#404040'"
                              [placeholder]="('PleaseEnter' | translate)"
                              [required]="true"
                              [(value)]="basicInfo.otherIncomesAdditional"></app-property-input>

          <app-property-input-number [title]="('OtherIncomes' | translate)"
                                     [titleColor]="'#404040'"
                                     [required]="true"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalAverageMonthlyIncomes()"
                                     [(value)]="basicInfo.otherIncomesAmount"></app-property-input-number>

          <app-property-input-number [title]="('TotalMonthlyIncome' | translate) + ' A'"
                                     [titleColor]="'#404040'"
                                     [required]="true"
                                     [disabled]="true"
                                     [placeholder]="('AutomaticCalculation' | translate)"
                                     (valueChange)="totalAverageMonthlyIncomes()"
                                     nz-tooltip [nzTooltipTitle]="totalMonthlyIncomeTemplate"
                                     [(value)]="basicInfo.totalAverageMonthlyIncomes"></app-property-input-number>
          <ng-template #totalMonthlyIncomeTemplate>
            <div>
              {{('CalculationFormula1' | translate)}}
            </div>
          </ng-template>
        </div>
        <div style="flex: 1;border-left: 1px dashed #d9d9d9;padding-left: 16px;padding-bottom: 15px;">
          <app-property-input-number [title]="'PersonalExpenses' | translate"
                                     [required]="true"
                                     [titleColor]="'#404040'"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalAverageMonthlyExpenses()"
                                     [(value)]="basicInfo.personalExpensesAmount"></app-property-input-number>

          <app-property-input-number [title]="'HouseholdExpenses' | translate"
                                     [titleColor]="'#404040'"
                                     [required]="true"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalAverageMonthlyExpenses()"
                                     [(value)]="basicInfo.householdExpensesAmount"></app-property-input-number>

          <app-property-input-number [title]="'MortgagesLoansRepayment' | translate"
                                     [titleColor]="'#404040'"
                                     [required]="true"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalAverageMonthlyExpenses()"
                                     [(value)]="basicInfo.mortgagesLoansRepaymentAmount"></app-property-input-number>

          <app-property-input-number [title]="'Insurance' | translate"
                                     [required]="true"
                                     [titleColor]="'#404040'"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalAverageMonthlyExpenses()"
                                     [(value)]="basicInfo.insuranceAmount"></app-property-input-number>

          <app-property-input [title]="'OtherExpenditureItem' | translate"
                              [titleColor]="'#404040'"
                              [placeholder]="('PleaseEnter' | translate)"
                              [required]="true"
                              [(value)]="basicInfo.otherExpensesAdditional"></app-property-input>

          <app-property-input-number [title]="('OtherExpenses' | translate)"
                                     [required]="true"
                                     [titleColor]="'#404040'"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalAverageMonthlyExpenses()"
                                     [(value)]="basicInfo.otherExpensesAmount"></app-property-input-number>

          <app-property-input-number [title]="('TotalAverageMonthlyExpenses' | translate) + ' B'"
                                     [required]="true"
                                     [titleColor]="'#404040'"
                                     [disabled]="true"
                                     [placeholder]="('AutomaticCalculation' | translate)"
                                     nz-tooltip [nzTooltipTitle]="totalAverageMonthlyExpensesTemplate"
                                     [(value)]="basicInfo.totalAverageMonthlyExpenses"></app-property-input-number>
          <ng-template #totalAverageMonthlyExpensesTemplate>
            <div>
              {{('CalculationFormula2' | translate)}}
            </div>
          </ng-template>

        </div>
      </div>
      <div style="display: flex;border-top: 1px dashed #d9d9d9;">
        <div style="flex: 1;"></div>
        <div style="flex: 1;padding-left: 16px;">
          <app-property-input-number [title]="('EstimatedMonthlySurplusDeficit' | translate) + '【（A）-（B）】'"
                                     [required]="true"
                                     [disabled]="true"
                                     [titleColor]="'#404040'"
                                     [placeholder]="('AutomaticCalculation' | translate)"
                                     nz-tooltip [nzTooltipTitle]="estimatedMonthlySurplusDeficitTemplate"
                                     [(value)]="basicInfo.estimatedMonthlySurplusDeficit"></app-property-input-number>
          <ng-template #estimatedMonthlySurplusDeficitTemplate>
            <div>{{'CalculationFormula3' | translate}}</div>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="section-wrapper" style="padding-bottom: 15px;padding-top: 20px;">
      <div style="font-size: 16px;font-weight: 600;color: #333333;">{{'Assets' | translate}}</div>
      <div style="display: flex;color: #404040;padding-top: 5px;">
        <div style="flex: 1;margin-right: 16px;">{{'LiquidAssets' | translate}}(HKD)</div>
        <div style="flex: 1;padding-left: 16px;">{{'NewLiabilities' | translate}}(HKD)</div>
      </div>
      <div style="display: flex">
        <div style="flex: 1;margin-right: 16px;padding-bottom: 15px;">
          <app-property-input-number [title]="'CashAndDeposits' | translate"
                                     [required]="true"
                                     [titleColor]="'#404040'"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalLiquidAssets()"
                                     [(value)]="basicInfo.cashDepositAmount"></app-property-input-number>

          <app-property-input-number [title]="'CurrentInvestment' | translate"
                                     [required]="true"
                                     [titleColor]="'#404040'"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalLiquidAssets()"
                                     [(value)]="basicInfo.currentInvestmentAmount"></app-property-input-number>

          <app-property-input [title]="'OtherLiquidAssetsItem' | translate"
                              [titleColor]="'#404040'"
                              [placeholder]="('PleaseEnter' | translate)"
                              [required]="true"
                              [(value)]="basicInfo.otherLiquidAssetsAdditional"></app-property-input>

          <app-property-input-number [title]="'OtherLiquidAssetsAmount' | translate"
                                     [titleColor]="'#404040'"
                                     [required]="true"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalLiquidAssets()"
                                     [(value)]="basicInfo.otherLiquidAssetsAmount"></app-property-input-number>

          <app-property-input-number [title]="('NewTotalLiquidAssets' | translate) + ' C'"
                                     [titleColor]="'#404040'"
                                     [required]="true"
                                     [disabled]="true"
                                     [placeholder]="('AutomaticCalculation' | translate)"
                                     nz-tooltip [nzTooltipTitle]="newTotalLiquidAssetsTemplate"
                                     [(value)]="basicInfo.totalLiquidAssets"></app-property-input-number>
          <ng-template #newTotalLiquidAssetsTemplate>
            <div>{{'CalculationFormula4' | translate}}</div>
          </ng-template>


          <div style="color: #404040;padding-top: 5px;">{{'NonLiquidAssets' | translate}}</div>

          <app-property-input-number [title]="'Properties' | translate"
                                     [titleColor]="'#404040'"
                                     [required]="true"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalNonLiquidAssets()"
                                     [(value)]="basicInfo.propertiesAmount"></app-property-input-number>

          <app-property-input [title]="'OtherNonLiquidAssets' | translate"
                              [titleColor]="'#404040'"
                              [placeholder]="('PleaseEnter' | translate)"
                              [required]="true"
                              [(value)]="basicInfo.otherNonLiquidAssetsAdditional"></app-property-input>

          <app-property-input-number [title]="'OtherNonLiquidAssetsAmount' | translate"
                                     [titleColor]="'#404040'"
                                     [required]="true"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalNonLiquidAssets()"
                                     [(value)]="basicInfo.otherNonLiquidAssetsAmount"></app-property-input-number>

          <app-property-input-number [title]="('TotalNonLiquidAssets' | translate) + ' D'"
                                     [titleColor]="'#404040'"
                                     [required]="true"
                                     [disabled]="true"
                                     [placeholder]="('AutomaticCalculation' | translate)"
                                     nz-tooltip [nzTooltipTitle]="totalNonLiquidAssetsTemplate"
                                     [(value)]="basicInfo.totalNonLiquidAssets"></app-property-input-number>
          <ng-template #totalNonLiquidAssetsTemplate>
            <div>{{'CalculationFormula5' | translate}}</div>
          </ng-template>

        </div>
        <div style="flex: 1;border-left: 1px dashed #d9d9d9;padding-left: 16px;padding-bottom: 15px;">
          <app-property-input-number [title]="'MortgageLoan' | translate"
                                     [required]="true"
                                     [titleColor]="'#404040'"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalLiabilities()"
                                     [(value)]="basicInfo.mortgageLoansAmount"></app-property-input-number>

          <app-property-input-number [title]="'PersonalCreditCardLoans' | translate"
                                     [required]="true"
                                     [titleColor]="'#404040'"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalLiabilities()"
                                     [(value)]="basicInfo.personalCreditCardLoansAmount"></app-property-input-number>

          <app-property-input [title]="'OtherLiabilitiesItem' | translate"
                              [titleColor]="'#404040'"
                              [placeholder]="('PleaseEnter' | translate)"
                              [required]="true"
                              [(value)]="basicInfo.otherLiabilitiesAdditional"></app-property-input>

          <app-property-input-number [title]="('OtherLiabilitiesItem' | translate) + ('Amount' | translate)"
                                     [titleColor]="'#404040'"
                                     [required]="true"
                                     [placeholder]="('PleaseEnter' | translate)"
                                     (valueChange)="totalLiabilities()"
                                     [(value)]="basicInfo.otherLiabilitiesAmount"></app-property-input-number>

          <app-property-input-number [title]="('NewTotalLiabilities' | translate) + ' E'"
                                     [titleColor]="'#404040'"
                                     [required]="true"
                                     [disabled]="true"
                                     [placeholder]="('AutomaticCalculation' | translate)"
                                     (valueChange)="totalLiabilities()"
                                     nz-tooltip [nzTooltipTitle]="newTotalLiabilitiesTemplate"
                                     [(value)]="basicInfo.totalLiabilities"></app-property-input-number>
          <ng-template #newTotalLiabilitiesTemplate>
            <div>{{'CalculationFormula6' | translate}}</div>
          </ng-template>

          <app-property-input-number [title]="('NewEstimatedNetAssets' | translate) + '（C+D-E）'"
                                     [required]="true"
                                     [disabled]="true"
                                     [titleColor]="'#404040'"
                                     [placeholder]="('AutomaticCalculation' | translate)"
                                     nz-tooltip [nzTooltipTitle]="newEstimatedNetAssetsTemplate"
                                     [(value)]="basicInfo.estimatedNetAssets"></app-property-input-number>
          <ng-template #newEstimatedNetAssetsTemplate>
            <div>{{'CalculationFormula7' | translate}}</div>
          </ng-template>

        </div>
      </div>
      <div style="display: flex;border-top: 1px dashed #d9d9d9;">
        <div style="flex: 1;"></div>
        <div style="flex: 1;padding-left: 16px;">
          <app-property-input-number [title]="'MaximumAffordablePremiumEstimatedNet' | translate"
                                     [required]="true"
                                     [disabled]="true"
                                     [titleColor]="'#404040'"
                                     [placeholder]="('AutomaticCalculation' | translate)"
                                     nz-tooltip [nzTooltipTitle]="maximumAffordablePremiumEstimatedNetTemplate"
                                     [(value)]="basicInfo.maximumAffordablePremium"></app-property-input-number>
          <ng-template #maximumAffordablePremiumEstimatedNetTemplate>
            <div>{{'CalculationFormula8' | translate}}</div>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="section-wrapper" style="padding-bottom: 15px;padding-top: 13px;">
      <div style="font-size: 16px;font-weight: 600;color: #333333;padding-bottom: 10px;">{{'AdditionalInformation' | translate}}</div>
      <textarea nz-input
                [placeholder]="('PleaseEnter' | translate)"
                [nzAutosize]="{ minRows: 3, maxRows: 3 }"
                [(ngModel)]="basicInfo.financialAdditionalInformation"></textarea>
<!--      <app-property-input-area [placeholder]="('PleaseEnter' | translate)"-->
<!--                               [(value)]="basicInfo.financialAdditionalInformation"></app-property-input-area>-->
    </div>


  </nz-spin>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onCancel()">
          <span>{{'TemporarySave' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [disabled]="!canSave"
           [style.opacity]="canSave ? '1' : '0.5'"
           appThrottleClick (throttleClick)="onSave()">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
