<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!ticketTable">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="ticketTable" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="ticketTable.list"
                [nzPageIndex]="ticketTable.pageNum"
                [nzPageSize]="ticketTable.pageSize"
                [nzTotal]="ticketTable.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{x: '1450px'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'TicketList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button *ngIf="mode === 'OWN'"
                        nz-button nzType="default"
                        nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'TicketCreate' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onTicketCreate()">
                  <img alt="" width="22" src="assets/images/ic_proposal_new.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'TicketSearch' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onTicketSearch()">
                  <img alt="" width="22" src="assets/images/ic_search.svg">
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>

        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ ticketTable.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="165px" nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'QuestionNumber' | translate">{{'QuestionNumber' | translate}}</span>
          </th>
          <th nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'TicketTitle' | translate">{{'TicketTitle' | translate}}</span>
          </th>
          <th *ngIf="!instance.isFoneOrDev()" nzAlign="center" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'BookingNo' | translate">{{'BookingNo' | translate}}</span>
          </th>
          <th *ngIf="!instance.isFoneOrDev()" nzAlign="center" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'UnderwritingNumber' | translate">{{'UnderwritingNumber' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'PolicyNo' | translate">{{'PolicyNo' | translate}}</span>
          </th>
          <th nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'CreateTime' | translate">{{'CreateTime' | translate}}</span>
          </th>
          <th nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'UpdateTime' | translate">{{'UpdateTime' | translate}}</span>
          </th>
          <th nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'Referee' | translate">{{'Referee' | translate}}</span>
          </th>
          <th nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'Status' | translate">{{'Status' | translate}}</span>
          </th>
          <th nzRight nzWidth="160px" nzAlign="center">
            <span>{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let ticket of ticketTable.list; let i = index" appThrottleClick (throttleClick)="onTicketDetail(ticket.id)">
          <td nzLeft nzAlign="center">
            <nz-badge [nzDot]="ticket.reminder">
            <span style="font-weight: bold; font-size: 12px">
              {{ ticket.id }}
            </span>
            </nz-badge>
          </td>
          <td nzEllipsis nzAlign="center"><span class="">{{ ticket.title }}</span></td>
          <td *ngIf="!instance.isFoneOrDev()" nzAlign="center">
            <div *ngIf="ticket.bookingNos?.length">
              <nz-tag *ngFor="let item of ticket.bookingNos; let j = index"
                      appThrottleClick
                      (throttleClick)="onBookingDetail(item); $event.stopPropagation();"
                      style="cursor: pointer; margin-bottom: 2px">{{ item }}</nz-tag>
            </div>
          </td>
          <td *ngIf="!instance.isFoneOrDev()" nzAlign="center">
            <div *ngIf="ticket.underwritingNos?.length">
              <nz-tag *ngFor="let item of ticket.underwritingNos; let j = index"
                      appThrottleClick
                      (throttleClick)="onUnderwritingDetail(item); $event.stopPropagation();"
                      style="cursor: pointer; margin-bottom: 2px">{{ item }}</nz-tag>
            </div>
          </td>
          <td nzAlign="center">
            <div *ngIf="ticket.policyNos?.length">
              <nz-tag *ngFor="let item of ticket.policyNos; let j = index"
                      appThrottleClick
                      (throttleClick)="onPolicyDetail(ticket.policyNoToPolicyIdMap[item]); $event.stopPropagation();"
                      style="cursor: pointer; margin-bottom: 2px">{{ item }}</nz-tag>
            </div>
          </td>
          <td nzAlign="center">
            <span>{{ ticket.addTime | date:'yyy-MM-dd HH:mm:ss' }}</span>
          </td>
          <td nzAlign="center">{{ ticket.updateTime | date: 'yyy-MM-dd HH:mm:ss' }}</td>
          <td nzAlign="center">
            <div>{{ ticket.salesName }}</div>
            <div>{{ ticket.accountName }}</div>
          </td>
          <td nzAlign="center">
            <div [ngSwitch]="ticket.status">
                <div *ngSwitchCase="'PROCESSED'" >
                  <div *ngIf="!isFone()">
                    <span class="text-success">●</span>
                    <span> {{ ticket.status | ticketStatus | translate}}</span>
                  </div>
                </div>
                <div *ngSwitchCase="'PROCESSING'">
                  <span class="text-warning">●</span>
                  <span> {{ ticket.status | ticketStatus | translate}}</span>
                </div>
            </div>
          </td>
          <td nzRight nzAlign="center">
            <div class="functions" nz-tooltip [nzTooltipTitle]="'Detail' | translate">
              <a nz-button nzType="link" nzSize="small">
                <img alt="" width="20" src="../../../assets/images/ic_details.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>
