import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommissionComponent } from './commission.component';
import { CommissionMainComponent } from './commission-main.component';
import { TranslateModule} from '@ngx-translate/core';
import { NzLayoutModule} from 'ng-zorro-antd/layout';
import { RouterModule} from '@angular/router';
import { SharedModule} from '../shared/shared.module';
import { CommissionsComponent } from './commissions/commissions.component';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import { CommissionListComponent } from './commission-list/commission-list.component';
import { CommissionDetailComponent } from './commission-detail/commission-detail.component';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {NzEmptyModule} from 'ng-zorro-antd/empty';



@NgModule({
  declarations: [
    CommissionComponent,
    CommissionMainComponent,
    CommissionsComponent,
    CommissionListComponent,
    CommissionDetailComponent
  ],
    imports: [
        CommonModule,
        NzLayoutModule,
        TranslateModule,
        RouterModule,
        SharedModule,
        NzTabsModule,
        NzSpinModule,
        NzSkeletonModule,
        NzTableModule,
        NzGridModule,
        NzToolTipModule,
        NzButtonModule,
        NzIconModule,
        NzDrawerModule,
        NzBreadCrumbModule,
        NzDividerModule,
        NzModalModule,
        NzPopoverModule,
        NzEmptyModule
    ]
})
export class CommissionModule { }
