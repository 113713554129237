import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PolicyMainComponent} from './policy-main.component';
import {PolicyComponent} from './policy.component';
import {SharedModule} from '../shared/shared.module';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {PolicySearchComponent} from './policy-search/policy-search.component';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {FormsModule} from '@angular/forms';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {PolicysComponent} from './policys/policys.component';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import { PolicyDetailComponent } from './policy-detail/policy-detail.component';
import { PolicyOverviewComponent } from './policy-overview/policy-overview.component';
import { PolicyInfomationComponent } from './components/policy-infomation/policy-infomation.component';
import { PolicyProductPlanComponent } from './components/policy-product-plan/policy-product-plan.component';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import { PolicyPendingComponent } from './components/policy-pending/policy-pending.component';
import { PolicyPendingDetailComponent } from './components/policy-pending-detail/policy-pending-detail.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';


@NgModule({
  declarations: [
    PolicyMainComponent,
    PolicyComponent,
    PolicySearchComponent,
    PolicysComponent,
    PolicyDetailComponent,
    PolicyOverviewComponent,
    PolicyInfomationComponent,
    PolicyProductPlanComponent,
    PolicyPendingComponent,
    PolicyPendingDetailComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        NzLayoutModule,
        RouterModule,
        TranslateModule,
        NzDatePickerModule,
        FormsModule,
        NzTabsModule,
        NzSpinModule,
        NzSkeletonModule,
        NzTableModule,
        NzGridModule,
        NzToolTipModule,
        NzBadgeModule,
        NzButtonModule,
        NzIconModule,
        NzDrawerModule,
        NzPopoverModule,
        NzAvatarModule,
        NzCheckboxModule,
        NzSelectModule
    ]
})
export class PolicyModule {
}
