/// 数组转Map
export function arrayToMap<K, V>(array: V[], keyFn: (v: V) => K): Map<K, V> {
  const map = new Map<K, V>();
  for (const item of array) {
    map.set(keyFn(item), item);
  }
  return map;
}

/// Map转数组
export function mapToArray<K, V>(map: Map<K, V>): V[] {
  return [...map.values()];
}

/// 数组去重
export function deduplication<K, V>(array: V[], keyFn: (v: V) => K): V[] {
  const map = new Map<K, V>();
  for (const item of array) {
    map.set(keyFn(item), item);
  }
  return [...map.values()];
}

