import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-information-main',
  templateUrl: './information-main.component.html',
  styleUrls: ['./information-main.component.less']
})
export class InformationMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
