import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpService } from '../core/http.service';
import { Observable} from 'rxjs/internal/Observable';
import {
  ApiResponse,
  PagedResp,
  TicketCreateResp,
  TicketDetail,
  TicketDetailReplyResp,
  TicketSearch,
  TicketListResp
} from '../api/types';
import { HttpParams} from '@angular/common/http';
import { API} from '../api/api';
import { map} from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class TicketService extends BaseService {
  constructor(private http: HttpService) {
    super();
  }

  /**
   * Ticket列表
   * @param ticketSearch 获取
   * @param isSubordinate 是否查询我的下线数据
   */
  list(ticketSearch: TicketSearch, isSubordinate: boolean): Observable<PagedResp<TicketListResp>> {
    let httpParams = new HttpParams();
    if (ticketSearch.pageNum != null) {
      httpParams = httpParams.set('pageNum', ticketSearch.pageNum.toString());
    }
    if (ticketSearch.pageSize != null) {
      httpParams = httpParams.set('pageSize', ticketSearch.pageSize.toString());
    }
    if (ticketSearch.status != null) {
      httpParams = httpParams.set('status', ticketSearch.status.toString());
    }
    if (ticketSearch.title != null) {
      httpParams = httpParams.set('title', ticketSearch.title.toString());
    }
    if (ticketSearch.content != null) {
      httpParams = httpParams.set('content', ticketSearch.content.toString());
    }
    if (ticketSearch.id != null) {
      httpParams = httpParams.set('id', ticketSearch.id.toString());
    }
    const url = isSubordinate ? '/list-subordinate' : '';
    return this.http.get<ApiResponse<PagedResp<TicketListResp>>>(API.ticketV3 + url, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * Ticket我下线的列表
   * @param ticketSearch 获取
   */
  listSubordinate(ticketSearch: TicketSearch): Observable<PagedResp<TicketListResp>> {
    let httpParams = new HttpParams();
    if (ticketSearch.pageNum != null) {
      httpParams = httpParams.set('pageNum', ticketSearch.pageNum.toString());
    }
    if (ticketSearch.pageSize != null) {
      httpParams = httpParams.set('pageSize', ticketSearch.pageSize.toString());
    }
    return this.http.get<ApiResponse<PagedResp<TicketListResp>>>(API.ticketV3 + '/list-subordinate', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * Ticket详情
   * @param id 編號
   */
  detail(id: number): Observable<TicketDetail> {
    return this.http.get<ApiResponse<TicketDetail>>(API.ticketV3 + '/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 详情回复
   * @param req 回复内容
   */
  reply(req: TicketDetailReplyResp): Observable<any> {
    return this.http.post<ApiResponse<TicketDetailReplyResp>>(API.ticketV3 + '/reply' , req, {
      observe: 'response'
    }).pipe(map(data => {
      console.log(data.body.data);
      return data.body.data;
    }));
  }

  /**
   * 新增咨询
   * @param req 新增条件
   */
  creat(req: TicketCreateResp): Observable<any> {
    return this.http.post<ApiResponse<TicketCreateResp>>(API.ticketV3, req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 重启问题
   * @param id 新增条件
   */
  restart(id: number): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.ticketV3 + `/open/${id}`, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
