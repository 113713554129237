<div class="drawer-container">
  <div class="drawer-title" style="margin-bottom: 40px; font-size: 20px;">
    {{'CommissionEstimateDetails' | translate}}
  </div>
<!--  <app-water-mark [xWidth]="60" [yHeight]="50"></app-water-mark>-->

  <div class="booking-detail-header">
    <div>
      <img width="60" style="border-radius: 3px; margin-right: 5px" [src]="contractDetail.logo" alt=""/>
    </div>
    <div style="flex: 1">
      <!-- 产品名称 -->
      <app-property-text [title]="'ProductName' | translate"
                         [value]="contractDetail.productName"></app-property-text>
      <!-- 供款年期 -->
      <app-property-text [title]="'PaymentTerm' | translate"
                         [value]="contractDetail.paymentTerm ? contractDetail.paymentTerm + ('Year' | translate) : null"></app-property-text>
      <!-- 保费 -->
      <app-property-text [title]="'Premium' | translate"
                         [value]="((contractDetail.premium | number: '.2') + (contractDetail.currency | metadataTranslate: 'currency'))"></app-property-text>
      <!-- 年龄 -->
      <app-property-text [title]="'Age' | translate"
                         [value]="contractDetail.age"></app-property-text>
      <!-- 货币 -->
<!--      <app-property-text [title]="'Currency' | translate"-->
<!--                         [value]="contractDetail.currency ? (contractDetail.currency | metadataTranslate: 'currency') : null"></app-property-text>-->
    </div>
  </div>
  <nz-empty *ngIf="!contractDetail.list?.length" nzNotFoundImage="simple"></nz-empty>
  <div *ngIf="contractDetail.list?.length">
    <nz-table [nzData]="contractDetail.list"
              [nzShowPagination]="false"
              [nzFooter]="tableFooter">
      <ng-template #tableFooter>
        <div class="table-title">
          {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ contractDetail.list?.length }}</span>
        </div>
      </ng-template>
      <thead>
      <tr>
        <th>{{'Annual' | translate}}</th>
        <th>{{'Proportion' | translate}}</th>
        <th>{{'Amount' | translate}}({{contractDetail.currency | metadataTranslate: 'currency'}})</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of contractDetail.list">
        <td>{{ data.policyYear }}</td>
        <td>{{ onRate(data.rate) }}</td>
        <td>{{ data.amount | number: '.2' }}</td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</div>
