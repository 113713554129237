import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MetadataService } from '../../shared/service/metadata.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { ProductService } from '../../product/product.service';
import { ProductSelectionComponent, ProductSelectionType } from '../../shared/component/product-selection/product-selection.component';
import { ProposalSearch } from '../proposal-types';
import { Product } from '../../product/product-types';
import { ContactService } from '../../contact/contact.service';
import { DataMode } from '../../api/types';

@Component({
  selector: 'app-proposal-search',
  templateUrl: './proposal-search.component.html',
  styleUrls: ['./proposal-search.component.less']
})
export class ProposalSearchComponent implements OnInit {

  @Input()
  search: ProposalSearch;
  @Input()
  mode = DataMode.OWN;

  statusOptions: PropertySelectOption[] = [];
  categoryOptions: PropertySelectOption[] = [];
  companyOptions: PropertySelectOption[] = [];
  subOptions: PropertySelectOption[] = [];

  @Output()
  proposalSearch: EventEmitter<ProposalSearch> = new EventEmitter<ProposalSearch>();

  constructor(private metadataService: MetadataService,
              private productService: ProductService,
              private drawerService: NzDrawerService,
              private contactService: ContactService) {
  }

  ngOnInit(): void {
    const statusList = this.metadataService.values('proposalStatus');
    for (const status of statusList) {
      this.statusOptions.push(new PropertySelectOption(status.value, status.key));
    }

    this.contactService.getSubList()
      .subscribe(
        data => {
          console.log(data);
          if (data && data.length) {
            data.map( item => {
              this.subOptions?.push(new PropertySelectOption(item.name + ' —— ' + item.accountName, item.accountName));
            });
          }
        },
        error => {
        });
    this.productService.categories()
      .subscribe(
        data => {
          data.forEach(category => {
            this.categoryOptions?.push(new PropertySelectOption(category.name, category.code));
          });
        },
        error => {
        });
    this.productService.companies()
      .subscribe(
        data => {
          data.forEach(company => {
            this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
          });
        },
        error => {
        });
  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.PROPOSAL,
        copyProduct: this.search.product
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.search.product = product;
            this.search.productCode = this.search.product.productCode;
            this.search.categoryCode = this.search.product.categoryCode;
            this.search.companyCode = this.search.product.companyCode;
            drawerRef.close();
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onProductCategoryChange(categoryCode: string): void {
    if (this.search.product.categoryCode !== categoryCode) {
      this.search.productCode = null;
      this.search.product = new Product();
    }
  }

  onProductCompanyChange(companyCode: string): void {
    if (this.search.product.companyCode !== companyCode) {
      this.search.productCode = null;
      this.search.product = new Product();
    }
  }

  onReset(): void {
    this.search = new ProposalSearch();
    this.search.applicationTime = null;
  }

  onSearch(): void {
    this.proposalSearch.emit(this.search);
  }

}
