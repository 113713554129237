import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PremiumFinancingComponent } from './premium-financing.component';
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { PremiumFinancingMainComponent } from './premium-financing-main.component';
import { PremiumFinancingListComponent } from './premium-financing-list/premium-financing-list.component';
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { PremiumFinancingGenerateComponent } from './premium-financing-generate/premium-financing-generate.component';
import { PremiumFinancingProposalSelectComponent } from './premium-financing-proposal-select/premium-financing-proposal-select.component';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { PremiumFinancingBankSelectComponent } from './premium-financing-bank-select/premium-financing-bank-select.component';
import { PremiumFinancingDiscountSelectComponent } from './premium-financing-discount-select/premium-financing-discount-select.component';



@NgModule({
  declarations: [
    PremiumFinancingComponent,
    PremiumFinancingMainComponent,
    PremiumFinancingListComponent,
    PremiumFinancingGenerateComponent,
    PremiumFinancingProposalSelectComponent,
    PremiumFinancingBankSelectComponent,
    PremiumFinancingDiscountSelectComponent
  ],
    imports: [
        CommonModule,
        NzLayoutModule,
        RouterModule,
        SharedModule,
        TranslateModule,
        NzSpinModule,
        NzSkeletonModule,
        NzTableModule,
        NzGridModule,
        NzToolTipModule,
        NzPopoverModule,
        NzDropDownModule,
        NzButtonModule,
        NzIconModule,
        NzEmptyModule
    ]
})
export class PremiumFinancingModule { }
