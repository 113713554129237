<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!informations">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="informations" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="informations.list"
                [nzPageIndex]="informations.pageNum"
                [nzPageSize]="informations.pageSize"
                [nzTotal]="informations.total"
                nzTableLayout="fixed"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                [nzScroll]="{ x: '1850px'}"
                (nzPageIndexChange)="onSearch($event)">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{ (promotion ? 'ProductPromotionList' : 'InformationList') | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">

                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight"
                        style=" margin-left: 10px;">
                  <img alt="" width="24" src="assets/images/ic_filter.svg">
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ informations.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="160px" *ngIf="!promotion">
            <span>{{'Type' | translate}}</span>
          </th>
          <th nzWidth="550px" nzEllipsis>
            <span>{{'InformationTitle' | translate}}</span>
          </th>
<!--          <th>-->
<!--            <span>{{'Tags' | translate}}</span>-->
<!--          </th>-->
          <th>
            <span>{{'Product' | translate}}</span>
          </th>
          <th nzWidth="150px" [nzAlign]="'center'">
            <span>{{'ShareCount' | translate}}</span>
          </th>
          <th nzWidth="150px" [nzAlign]="'center'">
            <span>{{'ViewCountTable' | translate}}</span>
          </th>
          <th nzWidth="170px">
            <span>{{'EffectiveDate' | translate}} / {{'ExpiryDate' | translate}}</span>
          </th>
          <th nzWidth="160px">
            <span>{{'Status' | translate}}</span>
          </th>
          <th nzWidth="180px">
            <span>{{'Time' | translate}}</span>
          </th>
          <!--          <th nzRight nzWidth="160px">-->
          <!--            <span>{{'Action' | translate}}</span>-->
          <!--          </th>-->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let information of informations.list; let i = index" appThrottleClick (throttleClick)="onInformationDetail(information.id)">
          <td nzLeft *ngIf="!promotion">
            <img alt="" width="20" src="assets/images/ic-announcement.svg">
            <span [class]="information.type === 'PRODUCT_PROMOTION' ? 'type-title-promotion' : 'type-title'">
              {{ information.type | metadataTranslate: 'informationType' }}
            </span>
          </td>
          <td>
            <div style="font-weight: bold; font-size: 12px">
              <div>
                <span *ngIf="information.top" class="tag-outline-sm" style="margin-right: 5px">{{ 'Pinned' | translate }}</span>
                <span class="title-length"
                      [nzTooltipPlacement]="['topLeft', 'leftTop']"
                      nz-tooltip nzTooltipTitle="{{information.title}}"
                >{{ information.title }}</span>
              </div>
              <div *ngIf="information.tags && information.tags.length < 4;else elseTemplate" class="title-length">
                <span *ngFor="let tag of information.tags; let j = index">
                  <span [class]="(['tag-a', 'tag-b', 'tag-c', 'tag-d', 'tag-e'][j])">
                    {{tag}}
                  </span>
                </span>
              </div>
              <ng-template #elseTemplate>
              <span class="title-length"
                    nz-tooltip nzTooltipTitle="{{(information.tags && information.tags.join())}}"
              >
                <span *ngFor="let tag of information.tags; let j = index">
                  <span *ngIf="j < 4;" [class]="(['tag-a', 'tag-b', 'tag-c', 'tag-d', 'tag-e'][j])">
                    {{tag}}
                  </span>
                </span>
                <span *ngIf="information.tags && information.tags.length > 4"
                      class="tag-e">...</span>
              </span>
              </ng-template>
            </div>
          </td>
<!--          <td>-->
<!--            <nz-tag *ngFor="let tag of information.tags">{{tag}}</nz-tag>-->
<!--          </td>-->
          <td>
            <div *ngIf="information.productNames">
              <span *ngIf="information.productNames.length < 3; else productNamesTemplate">
                <nz-tag *ngFor="let productName of information.productNames"
                        style="font-size: 15px;font-weight: bolder;margin-bottom: 5px">{{productName}}</nz-tag>
              </span>
              <ng-template #productNamesTemplate>
                <div nz-tooltip nzTooltipTitle="{{(information.productNames && information.productNames.join(' | '))}}">
                  <span *ngFor="let productName of information.productNames; let f = index">
                    <nz-tag *ngIf="f < 2" style="font-size: 15px;font-weight: bolder;margin-bottom: 5px">{{productName}}</nz-tag>
                  </span>
                  <nz-tag style="font-size: 15px;font-weight: bolder;margin-bottom: 5px">...</nz-tag>
                </div>
              </ng-template>
            </div>
          </td>
          <td [nzAlign]="'center'">{{information.shareCount | number}}</td>
          <td [nzAlign]="'center'">{{information.browseCount | number}}</td>
          <td>
            <div style="display: flex; align-items: center;font-size: 15px;font-weight: bolder">
              <span>{{ (information.startEffectiveDate | date:'yyyy-MM-dd') ?? '-' }}</span>
            </div>
            <div style="display: flex; align-items: center;font-size: 15px;font-weight: bolder">
              <span>{{ (information.endEffectiveDate | date:'yyyy-MM-dd') ?? '-' }}</span>
            </div>
          </td>
          <td>
            <span *ngIf="information.effective" class="text-success" style="font-size: 15px;font-weight: bolder">●{{'InEffect' | translate}}</span>
            <span *ngIf="!information.effective" class="text-cancel" style="font-size: 15px;font-weight: bolder">●{{'Expired' | translate}}</span>
          </td>
          <td>
            <div style="display: flex; align-items: center;font-size: 15px;font-weight: bolder">
              <span>{{ (information.time | date:'yyyy-MM-dd HH:mm') ?? '-' }}</span>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>

<!-- 多语言气泡Template -->
<ng-template #filterTemplate>
  <div style="width: 255px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <!-- 類型 -->
    <app-property-select *ngIf="!promotion" [title]="'Type' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Type' | translate)"
                         [options]="typeOptions"
                         [(value)]="search.type"></app-property-select>
    <!-- 標題 -->
    <app-property-input [title]="'InformationTitle' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('InformationTitle' | translate)"
                        [(value)]="search.title"></app-property-input>
    <!-- 標籤 -->
    <app-property-input [title]="'Tag' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('Tag' | translate)"
                        [(value)]="search.tag"></app-property-input>
    <!-- 生效時間 -->
    <app-property-datetime [title]="'StartTime' | translate"
                           [(value)]="search.startTime"></app-property-datetime>
    <!-- 失效時間 -->
    <app-property-datetime [title]="'EndTime' | translate"
                           [(value)]="search.endTime"></app-property-datetime>

    <!-- 公司 -->
    <app-property-select [title]="'Insurer' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Insurer' | translate)"
                         [(value)]="search.companyCode"
                         [options]="companyOptions"></app-property-select>

    <app-property-select [title]="('Insurer' | translate) + ('Region' | translate)"
                         [placeholder]="('PleaseSelect' | translate) + ('Insurer' | translate) + ('Region' | translate)"
                         [mode]="'multiple'"
                         [options]="regionOptions"
                         [(value)]="search.regions"></app-property-select>

    <!-- 产品 -->
    <app-property-pick [displayMode]="'vertical'"
                       [title]="'InsuranceProduct' | translate"
                       appThrottleClick (throttleClick)="onProductSelection()"
                       [placeholder]="'ProductSelect' | translate"
                       [(value)]="search.product.productName"></app-property-pick>

    <!-- 是否生效中 -->
    <app-property-yes-or-not [title]="'InEffect' | translate"
                             [(value)]="search.effective"></app-property-yes-or-not>

    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
