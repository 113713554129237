import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from '../base/base.service';
import { ApiResponse, DataMode, PagedResp } from '../api/types';
import { Observable } from 'rxjs/internal/Observable';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { BvList, BvSearch } from './bv-types';

@Injectable({
  providedIn: 'root'
})
export class BvService extends BaseService{

  constructor(private http: HttpService, private httpClient: HttpClient) {
    super();
  }

  /**
   * FNA列表
   * @param search 查询条件
   */
  list(search: BvSearch): Observable<PagedResp<BvList>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.orderColumn != null) {
      httpParams = httpParams.set('orderColumn', search.orderColumn.toString());
    }
    if (search.orderAspect != null) {
      httpParams = httpParams.set('orderAspect', search.orderAspect.toString());
    }
    if (search.policyNo != null) {
      httpParams = httpParams.set('policyNo', search.policyNo.toString());
    }
    if (search.underwritingNo != null) {
      httpParams = httpParams.set('underwritingNo', search.underwritingNo.toString());
    }
    if (search.companyCode != null) {
      httpParams = httpParams.set('companyCode', search.companyCode.toString());
    }
    if (search.policyDateStart != null) {
      httpParams = httpParams.set('policyDateStart', search.policyDateStart.toString());
    }
    if (search.policyDateEnd != null) {
      httpParams = httpParams.set('policyDateEnd', search.policyDateEnd.toString());
    }
    if (search.mySelf != null) {
      httpParams = httpParams.set('mySelf', search.mySelf.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.accountName != null) {
      httpParams = httpParams.set('name', search.accountName.toString());
    }
    if (search.productCode != null) {
      httpParams = httpParams.set('productCode', search.productCode.toString());
    }
    return this.http.get<ApiResponse<PagedResp<BvList>>>(API.bvV3 + '/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
