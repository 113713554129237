import {Address, Beneficiary, Customer, CustomerCorporate} from '../../api/types';
import * as _ from 'lodash';
import {ContactInfoRes} from '../../contact/contact-types';
import {ScheduleParams} from '../../schedule/schedule-type';

export class Addresses {

  public static getIndividualQuickAddresses(customers: Customer[]): Address[] {
    const quickAddresses: Address[] = [];
    for (const customer of customers) {
      if (!customer) {
        continue;
      }
      if (customer.address && customer.address.toAddressString()) {
        quickAddresses.push(_.cloneDeepWith(customer.address));
      }
      if (customer.residenceAddress && customer.residenceAddress.toAddressString()) {
        quickAddresses.push(_.cloneDeepWith(customer.residenceAddress));
      }
      if (customer.postalAddress && customer.postalAddress.toAddressString()) {
        quickAddresses.push(_.cloneDeepWith(customer.postalAddress));
      }
      if (customer.companyAddress && customer.companyAddress.toAddressString()) {
        quickAddresses.push(_.cloneDeepWith(customer.companyAddress));
      }
    }
    return quickAddresses;
  }

  public static getCorporateQuickAddresses(customers: CustomerCorporate[]): Address[] {
    const quickAddresses: Address[] = [];
    for (const customer of customers) {
      if (!customer) {
        continue;
      }
      if (customer.registeredAddress && customer.registeredAddress.toAddressString()) {
        quickAddresses.push(_.cloneDeepWith(customer.registeredAddress));
      }
      if (customer.contactAddress && customer.contactAddress.toAddressString()) {
        quickAddresses.push(_.cloneDeepWith(customer.contactAddress));
      }
    }
    return quickAddresses;
  }

  public static getContactQuickAddresses(contacts: ContactInfoRes[]): Address[] {
    const quickAddresses: Address[] = [];
    for (const contact of contacts) {
      if (!contact) {
        continue;
      }
      if (contact.address && contact.address.toAddressString()) {
        quickAddresses.push(_.cloneDeepWith(contact.address));
      }
      if (contact.residenceAddress && contact.residenceAddress.toAddressString()) {
        quickAddresses.push(_.cloneDeepWith(contact.residenceAddress));
      }
      if (contact.postalAddress && contact.postalAddress.toAddressString()) {
        quickAddresses.push(_.cloneDeepWith(contact.postalAddress));
      }
      if (contact.companyAddress && contact.companyAddress.toAddressString()) {
        quickAddresses.push(_.cloneDeepWith(contact.companyAddress));
      }
    }
    return quickAddresses;
  }

  public static getBeneficiaryQuickAddresses(beneficiaries: Beneficiary[]): Address[] {
    const quickAddresses: Address[] = [];
    if (!beneficiaries) {
      return quickAddresses;
    }
    for (const beneficiary of beneficiaries) {
      if (!beneficiary) {
        continue;
      }
      if (beneficiary.registeredAddress && beneficiary.registeredAddress.toAddressString()) {
        quickAddresses.push(_.cloneDeepWith(beneficiary.registeredAddress));
      }
      if (beneficiary.contactAddress && beneficiary.contactAddress.toAddressString()) {
        quickAddresses.push(_.cloneDeepWith(beneficiary.contactAddress));
      }
    }
    return quickAddresses;
  }

  public static getScheduleQuickAddresses(scheduleParams: ScheduleParams[]): Address[] {
    const quickAddresses: Address[] = [];
    console.log(scheduleParams);
    for (const scheduleParam of scheduleParams) {
      if (!scheduleParam) {
        continue;
      }
      if (scheduleParam.place && scheduleParam.place.toAddressString()) {
        quickAddresses.push(_.cloneDeepWith(scheduleParam.place));
      }
    }
    return quickAddresses;
  }

}

