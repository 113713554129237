<div class="login-container">

  <div nz-row nzJustify="center" style="width: 100%; height: 100%;">
    <div nz-col [nzSpan]="12" class="login-column">
      <div style="width: 100%" class="login-column">
        <img width="150" src="assets/images/logo.png" alt="">
        <div class="changePwd-title">{{'PhoneNumberLogin' | translate}}</div>
        <!--        验证账号-->
        <div style="width: 50%; margin-top: 20px;">
          <div class="input-group" style="display: flex;align-items: center;">
<!--            <app-property-select [options]="areaCodeOptions"-->
<!--                                 [bottomLineVisible]="false"-->
<!--                                 [(value)]="areaCode"></app-property-select>-->
            <div class="phone-select">
              <nz-select nzShowSearch nzAllowClear
                         [nzPlaceHolder]="'AreaCode' | translate"
                         [(ngModel)]="areaCode"
                         style="min-width: 80px;"
                         [nzDropdownMatchSelectWidth]="false"
                         [nzFilterOption]="selectFilterOption">
                <nz-option nzCustomContent="nzCustomContent" *ngFor="let areaCodeItem of contactsAreaCodeArr"
                           [nzLabel]="'+' + areaCodeItem.areaCode" [nzValue]="areaCodeItem.areaCode + '+' + areaCodeItem.name">
                  {{areaCodeItem.name}} <span style="float: right;margin-left: 20px">+{{areaCodeItem.areaCode}}</span>
                </nz-option>
<!--                <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>-->
<!--                <nz-option nzLabel="Tom" nzValue="tom"></nz-option>-->
              </nz-select>
            </div>
            <input [(ngModel)]="phone" id="'phoneNumber'"
                   name="phoneNumber"
                   [placeholder]="('PleaseEnter' | translate) + ('PhoneNumber' | translate)"
                   class="login-input" style="text-align: left;margin-bottom: 10px;" />

          </div>
        </div>

        <div style="width: 50%; margin-top: 10px;">
          <nz-input-group class="input-group-code">
            <input required [(ngModel)]="code" id="code" name="code"
                   style="width: 60%;"
                   [placeholder]="('PleaseEnter' | translate) + ('VerificationCode' | translate)" class="login-input-change"/>
            <div [class]="isOk ? 'code-button-disabled' : 'code-button'" appThrottleClick (throttleClick)="onSend()">
              {{isOk ? timeVal : ('GetVerificationCode' | translate)}}
            </div>
          </nz-input-group>
        </div>

        <div *ngIf="codeTips" style="font-size: 12px;margin-top: 10px">{{ codeTips }}</div>

        <div  style="margin-top: 20px">
          <a nz-button nzType="link" appThrottleClick (throttleClick)="onBack()">
            {{'AccountPasswordLogin' | translate}}<i nz-icon nzType="right" nzTheme="outline"></i>
          </a>
        </div>

        <div style="margin-top: 30px;display: flex;align-items: center;">
          <!--  上一步-->
          <div>
            <button style="width: 60px; height: 60px;margin-right: 60px" type="button"
                    nz-tooltip [nzTooltipTitle]="'Back' | translate" [disabled]="logging"
                    nz-button nzType="default" nzShape="circle" appThrottleClick (throttleClick)="onBack()">
              <i nz-icon nzType="arrow-left"></i>
            </button>
          </div>

          <div>
            <button style="width: 60px; height: 60px; color: #13263f" [nzLoading]="logging"
                    [disabled]="!(areaCode && phone && code)"
                    appThrottleClick (throttleClick)="verifyAccount()"
                    nz-tooltip [nzTooltipTitle]="'Next' | translate"
                    nz-button nzType="default" nzShape="circle">
              <i nz-icon nzType="login"></i>
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>

  <nz-drawer
    [nzClosable]="false"
    [nzVisible]="visible"
    [nzWidth]="400"
    nzPlacement="right"
    (nzOnClose)="close()"
  >
    <ng-container *nzDrawerContent>
      <div style="padding: 20px">
        <div style="font-size: 18px;color: #000000;font-weight: 600;line-height: 60px;">
          {{'UsernameAssociatedPhoneNumber' | translate:  {phoneNumber: drawerTitle} }}
        </div>
        <div *ngFor="let account of accountNameArr; let i = index">
          <div class="account-item"
               appThrottleClick
               (throttleClick)="onPhoneChange(account)">{{ account }}</div>
        </div>
      </div>
    </ng-container>
  </nz-drawer>
</div>
