<div [ngSwitch]="displayMode">
  <div *ngSwitchCase="'horizontal'">
    <div [class]="disabled ? 'property-row property-disable' : 'property-row'">
      <div class="property-title-row" *ngIf="title">{{ title }}</div>
      <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
      <div class="property-value-row">
        <input [nzBorderless]="borderless" nz-input [placeholder]="placeholder" [ngModel]="value" (ngModelChange)="onModelChange($event)"
               [disabled]="disabled" [min]="min" [max]="max"
               style="text-align: right;" />
      </div>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>
  <div *ngSwitchCase="'vertical'">
    <div [class]="disabled ? 'property-column property-disable' : 'property-column'">
      <div class="property-title-column">
        <span *ngIf="title">{{ title }}</span>
        <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
      </div>
      <div class="property-value-column">
        <input [nzBorderless]="borderless" nz-input [placeholder]="placeholder" [(ngModel)]="value"
               [disabled]="disabled" [min]="min" [max]="max"
               style="text-align: left;" />
      </div>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>
</div>
