import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Material, UploadTokenReq, UploadType } from '../../api/types';
import { TicketOrderCreate } from '../ticket-order-types';
import { TicketOrderService } from '../ticket-order.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ticket-order-create',
  templateUrl: './ticket-order-create.component.html',
  styleUrls: ['./ticket-order-create.component.less'],
  providers: [DatePipe]
})
export class TicketOrderCreateComponent implements OnInit {
  loading = false;

  ticketCreateResp: TicketOrderCreate = new TicketOrderCreate();

  uploadTokenReq = new UploadTokenReq();
  @Output()
  ticketCreateRes: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private ticketOrderService: TicketOrderService,
              private datePipe: DatePipe,
              private toastr: ToastrService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.uploadTokenReq.uploadType = UploadType.TICKET;
    this.uploadTokenReq.middleName = 'temp' + this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }


  create(): void {
    if (!this.canReply) {
      return;
    }
    this.loading = true;
    this.ticketOrderService.create(this.ticketCreateResp)
      .subscribe(data => {
          this.loading = false;
          if (data) {
            this.translate.get('CreateSuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          }
          this.ticketCreateRes.emit(data);
        },
        error => {
          this.loading = false;
        }
      );
  }

  get canReply(): boolean {
    return !!this.ticketCreateResp.title && !!this.ticketCreateResp.content;
  }

  replyAttachmentsChange(attachments: Material[]): void {
    this.ticketCreateResp.files = attachments;
  }

}
