<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!messageOverviews">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="messageOverviews" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="messageOverviews"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                [nzScroll]="{ x: '1150px'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'MessageCenter' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon nzType="reload" nzTheme="outline"></em>
                </a>

                <button nz-button nzType="default" style="margin-left: 10px"
                        appThrottleClick (throttleClick)="onSettings()">
                  <i nz-icon nzType="setting" style="font-size: 16px"></i>
                  {{ 'MessageReceiveSettings' | translate }}
                </button>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ messageOverviews.length }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="220px">
            <span nz-tooltip [nzTooltipTitle]="'Module' | translate">{{'Module' | translate}}</span>
          </th>
          <th>
            <span nz-tooltip [nzTooltipTitle]="'MessageContent' | translate">{{'MessageContent' | translate}}</span>
          </th>
          <th nzWidth="240px">
            <span nz-tooltip [nzTooltipTitle]="'Time' | translate">{{'Time' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let messageOverview of messageOverviews; let i = index" appThrottleClick (throttleClick)="onMessage(messageOverview)">
          <td nzLeft>
            <nz-badge [nzCount]="showDot ? null : messageOverview.unreadTotal" style="transform: scale(0.7, 0.7)" [nzDot]="showDot">
              <img alt="" width="40" [src]="messageOverview.logo">
            </nz-badge>
            <span style="margin-left: 20px; font-size: 16px;">{{ messageTypeTranslate(messageOverview.type) }}</span>
          </td>
          <td>
            <div>
              <span style="color: #666666">{{ messageOverview.content }}</span>
            </div>
          </td>
          <td><span>{{ messageOverview.addTime | date:'yyyy-MM-dd HH:mm:ss' }}</span></td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>

  <nz-modal
    [(nzVisible)]="isSettingsVisible"
    [nzTitle]="modalTitle"
    [nzContent]="modalContent"
    [nzFooter]="modalFooter"
    (nzOnCancel)="onMessageSettingsCancel()"
  >
    <ng-template #modalTitle>{{ 'MessageReceiveSettings' | translate }}</ng-template>

    <ng-template #modalContent>
      <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="configLoading">
        <div *ngIf="config">

          <div style="padding: 0 0 5px 0">
            <div>
              <span>{{ 'MessageReceiveWaySettings' | translate }}</span>
              <i nz-icon nzType="question-circle" nzTheme="outline" style="margin-left: 5px"
                 nz-tooltip [nzTooltipTitle]="'MessageReceiveWaySettingsTip' | translate"></i>
            </div>
            <div style="margin-top: 5px" class="property-bottom-line"></div>
          </div>

          <!-- 角標數字提醒 -->
          <app-property-switch [title]="'MessagesBadgeNumberSettings' | translate"
                               [value]="config.digitalReminder"
                               [bottomLineVisible]="false"
                               (valueChange)="onBadgeReminderNumberChange($event)"></app-property-switch>

          <!-- 系统悬浮栏通知 -->
          <app-property-switch [title]="'MessagesSystemNotification' | translate"
                               [value]="config.systematicNotification"
                               [bottomLineVisible]="false"
                               (valueChange)="onSystemNotificationChange($event)"></app-property-switch>

          <!-- 消息免打擾 -->
          <app-property-switch [title]="'MessagesNotDisturb' | translate"
                               [value]="config.messageDisturb"
                               [tipVisible]="true"
                               [tip]="'MessagesNotDisturbTip' | translate"
                               [bottomLineVisible]="false"
                               (valueChange)="onNotDisturbChange($event)"></app-property-switch>

          <div style="padding: 20px 0 5px 0">
            <div style="display: flex">
              <div style="flex: 1">
                <span>{{ 'MessageReceiveSettings' | translate }}</span>
                <i nz-icon nzType="question-circle" nzTheme="outline" style="margin-left: 5px"
                   nz-tooltip [nzTooltipTitle]="'MessageReceiveSettingsTip' | translate"></i>
              </div>
              <div>
                <nz-switch [(ngModel)]="config.messageReceiving"></nz-switch>
              </div>
            </div>
            <div style="margin-top: 5px" class="property-bottom-line"></div>
          </div>

          <!-- 設置列表 -->
          <div *ngIf="config.messageReceiving">
            <div *ngIf="config.messageSettings && config.messageSettings.length > 0" style="max-height: 300px; overflow-y: scroll">
              <app-property-switch *ngFor="let setting of config.messageSettings; let i = index"
                                   [title]="setting.messageType | metadataTranslate: 'messageType'"
                                   [(value)]="setting.enable"
                                   [bottomLineVisible]="false"></app-property-switch>
            </div>
          </div>

        </div>
      </nz-spin>
    </ng-template>

    <ng-template #modalFooter>
      <button nz-button appThrottleClick (throttleClick)="onMessageSettingsCancel()">
        <!--    <i nz-icon nzType="close" nzTheme="outline" style="font-size: 13px;"></i>-->
        <span>{{'Cancel' | translate}}</span>
      </button>
      <button nz-button nzType="primary" appThrottleClick (throttleClick)="onMessageConfigSave()" [nzLoading]="configLoading">
        <!--    <i nz-icon nzType="save" nzTheme="outline" style="font-size: 13px;"></i>-->
        <span>{{'Save' | translate}}</span>
      </button>
    </ng-template>

  </nz-modal>
</div>
