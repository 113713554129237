<div class="drawer-container">
  <div class="drawer-title" style="margin-bottom: 40px">
    <nz-breadcrumb nzSeparator=">" style="font-size: 20px;line-height: 22px">
      <nz-breadcrumb-item>{{'ContactsInfo' | translate}}</nz-breadcrumb-item>
      <nz-breadcrumb-item>{{'CustomerRecords' | translate}}</nz-breadcrumb-item>
    </nz-breadcrumb>
  </div>
  <nz-spin [nzSpinning]="spinning">
    <div>
      <div style="display: flex; align-items: center">
        <nz-avatar class="list-top-avatar" style="margin-right: 20px;" [nzSize]="60"
                   [nzSrc]="meetingRecord.avatar"></nz-avatar>
        <div>
          <div style="font-size: 16px">{{meetingRecord.name}}</div>
          <div><span class="textColor">{{'RecordingTimes' | translate}}:</span> {{meetingRecord.followCount}}</div>
          <div><span class="textColor">{{'CurrentState' | translate}}:</span> {{meetingRecord.followStatus}}
          </div>
        </div>
      </div>
      <div *ngFor="let listVos of meetingRecord.followListVOS; let i = index" style="display: flex; align-items: center;">
        <div class="section-wrapper meeting-card">
          <div class="meeting-card-th margin-bottom">
            <span style="font-size: 16px; font-weight: 600">{{listVos.followDate}}</span>
            <span>
            <a nz-button nzType="link" nzSize="small" style="color: #4B84E5; font-size: 14px;"
               appThrottleClick (throttleClick)="onDetails(listVos.id)">
              {{'View' | translate}} >
            </a>
          </span>
          </div>
          <div class="margin-bottom">
            <span class="textColor">{{'Place' | translate}}：</span><span>{{listVos.place}}</span>
          </div>
          <div class="margin-bottom">
            <span class="textColor">{{'Matter' | translate}}：</span><span>{{listVos.matter}}</span>
          </div>
        </div>

        <a nz-button nzType="link" nzSize="small"
           appThrottleClick (throttleClick)="onDel(listVos.id)"
           nz-tooltip [nzTooltipTitle]="'Delete' | translate">
          <img alt="" width="28" src="assets/images/ic_delete.svg">
        </a>
      </div>
    </div>
  </nz-spin>
  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="addRecord()">
          <span>{{'AddRecord' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
