<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{'E-Contract' | translate}}
  </div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div *ngIf="pdfList.length">
      <div *ngFor="let pdf of pdfList" class="colStyle section-wrapper">
        <div>
          <img alt="" width="36" src="assets/images/ic_file_pdf.svg">
          {{pdf.name}}
        </div>
        <div class="icon-right">
          <a nz-button nzType="link" nzSize="small"
             nz-tooltip [nzTooltipTitle]="'Preview' | translate"
             appThrottleClick (throttleClick)="onViewFna(pdf)">
            <img alt="" width="22" src="assets/images/ic_view.svg">
          </a>
          <a nz-button nzType="link" nzSize="small"
             nz-tooltip [nzTooltipTitle]="'Download' | translate"
             appThrottleClick (throttleClick)="onDownloadPDF(pdf)">
            <img alt="" width="22" src="assets/images/ic_download.svg">
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="!pdfList.length">
      <nz-empty nzNotFoundImage="simple"></nz-empty>
    </div>
  </nz-spin>
</div>
