import { Component, EventEmitter, OnInit } from '@angular/core';
import { PreSaleFollow, ContactsType } from '../../contact-types';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { ContactService } from '../../contact.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Material, UploadTokenReq, UploadType } from '../../../api/types';
import { DatePipe } from '@angular/common';
import { ProductMultiSelectionComponent } from '../../../shared/component/product-multi-selection/product-multi-selection.component';
import { ProductSelectionType } from '../../../shared/component/product-selection/product-selection.component';
import { deduplication } from '../../../shared/utils/collections';
import { Product } from '../../../product/product-types';
import { ProductService } from '../../../product/product.service';

@Component({
  selector: 'app-contacts-create-record',
  templateUrl: './contacts-create-record.component.html',
  styleUrls: ['./contacts-create-record.component.less'],
  providers: [DatePipe]
})
export class ContactsCreateRecordComponent implements OnInit {

  id: number; // 联系人id
  type: ContactsType;
  follow: PreSaleFollow = new PreSaleFollow();
  uploadTokenReq = new UploadTokenReq();
  statusOptions: PropertySelectOption[] = [];
  recordCreated: EventEmitter<any> = new EventEmitter<any>();
  recordUpdated: EventEmitter<any> = new EventEmitter<any>();

  selectedProducts: Product[] = [];

  constructor(private contactService: ContactService,
              private productService: ProductService,
              private drawerService: NzDrawerService,
              private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    console.log(this.type);
    this.uploadTokenReq.uploadType = UploadType.CONTACT;
    this.uploadTokenReq.middleName = this.id.toString();
    this.contactService.followStatus()
      .subscribe(
        data => {
          const status = data;
          for (const title of status) {
            this.statusOptions.push(new PropertySelectOption(title, title));
          }
        },
        error => {
          console.log(error);
        }
      );
    this.getProducts();
  }

  getProducts(): void {
    if (this.follow.productCodes && this.follow.productCodes.length > 0) {
      this.productService.productsByCodes(this.follow.productCodes)
        .subscribe(
          data => {
            this.selectedProducts = data;
          },
          error => {
            console.log(error);
          }
        );
    }
  }

  addAttachmentsChange(attachments: Material[]): void {
    this.follow.attachments = attachments;
  }

  onAddProducts(): void {
    const drawerRef = this.drawerService.create<ProductMultiSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductMultiSelectionComponent,
      nzMask: true,
      nzContentParams: {
        type: ProductSelectionType.COMPARISON,
        hideCategory: true,
        selectedProducts: this.selectedProducts
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.drawerRef = drawerRef;
      selectionComponent.productsSelected
        .subscribe(
          products => {
            this.selectedProducts = products;
            this.selectedProducts = deduplication(this.selectedProducts, v => {
              return v.productCode;
            });
            this.follow.productCodes = this.selectedProducts.map(item => item.productCode);
            this.follow.productNames = this.selectedProducts.map(item => item.productName);
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onSave(): void {
    if (!this.canReply) {
      return;
    }
    switch (this.type) {
      case ContactsType.Create:
        this.onCreate();
        break;
      case ContactsType.Edit:
        this.onUpdate();
        break;
    }
  }

  onCreate(): void {
    this.follow.preSalesId = this.id;
    this.contactService.createFollow(this.follow)
      .subscribe(
        data => {
          this.recordCreated.emit(data);
        },
        error => {
        }
      );
  }

  onUpdate(): void {
    this.contactService.updateFollow(this.follow)
      .subscribe(
        data => {
          this.recordUpdated.emit(data);
        },
        error => {
          console.log(error);
        }
      );
  }

  get productName(): string {
    if (this.follow && this.follow.productNames && this.follow.productNames.length > 0) {
      return this.follow.productNames[0];
    }
    return '';
  }

  get canReply(): boolean {
    return !!this.follow.followDate && !!this.follow.matter && !!this.follow.status;
  }

}
