import {Component, Input, OnInit} from '@angular/core';
import {DataMode, PagedResp} from '../../api/types';
import {
  BillPageResp,
  CommissionList, InfoList,
  NewCommissionList
} from '../commission-types';
import {CommissionService} from '../commission.service';
import {CommissionListComponent} from '../commission-list/commission-list.component';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-commissions',
  templateUrl: './commissions.component.html',
  styleUrls: ['./commissions.component.less'],
  providers: [DatePipe]
})
export class CommissionsComponent implements OnInit {

  @Input()
  mode = DataMode.OWN;
  spinning = false;
  loading = false;
  filterPopoverVisible = false;

  salesSearch: NewCommissionList = new NewCommissionList();
  salesListData: PagedResp<BillPageResp>;

  constructor(private commissionService: CommissionService,
              private datePipe: DatePipe,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
    this.list();
  }

  onSearch(pageNum: number): void {
    this.salesSearch.billDate = this.datePipe.transform(this.salesSearch.billDate, 'yyyy-MM-dd');
    this.salesSearch.pageNum = pageNum;
    this.loading = true;
    this.commissionService.newList(this.salesSearch, this.mode === DataMode.OWN)
      .subscribe(
        data => {
          this.loading = false;
          this.salesListData = data;
        },
        error => {
          this.loading = false;
        });
  }

  list(): void {
    this.commissionService.newList(this.salesSearch, this.mode === DataMode.OWN)
      .subscribe(
        data => {
          this.salesListData = data;
        },
        error => {
        }
      );
  }

  onReload(): void {
    this.loading = true;
    this.salesSearch = new NewCommissionList();
    this.commissionService.newList(this.salesSearch, this.mode === DataMode.OWN)
      .subscribe(
        data => {
          this.loading = false;
          this.salesListData = data;
        },
        error => {
          this.loading = false;
        }
      );
  }

  onCommissionList(date: string): void {
    const infoDate = new InfoList();
    infoDate.billDate = date;
    // this.spinning = true;
    // this.spinning = false;
    const drawerRef = this.drawerService.create<CommissionListComponent, { value: any }, string>({
      nzWidth: 600,
      nzContent: CommissionListComponent,
      nzContentParams: {
        mode: this.mode,
        commissionBillDate: date
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.referralFee
        .subscribe(
          () => {
            // this.commissionConfirm(id);
            drawerRef.close();
          }
        );
    });
    drawerRef.afterClose.subscribe(() => {
      // const component = drawerRef.getContentComponent();
      this.onSearch(this.salesSearch.pageNum);
    });
    // this.commissionService.infoList(infoDate, this.mode === DataMode.OWN)
    //   .subscribe(
    //     info => {
    //
    //     }
    //   );
  }

  toDecimal(val): any {
    if (val.indexOf('.') === -1) {
      return val = val + '.00';
    } else {
      return val;
    }
  }

}
