<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'PactDetails' | translate}}
    </div>

    <!--    电子合同-->
    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'E-Contract' | translate}}</span>
    </div>

    <div class="section-wrapper" style="">
      <app-property-pick [title]="'ViewContract' | translate"
                         appThrottleClick (throttleClick)="onViewPDF()"
                         [bottomLineVisible]="false"></app-property-pick>
    </div>

    <!--    合同时间-->
    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'ContractTime' | translate}}</span>
    </div>

    <div class="section-wrapper" style="">
      <app-property-text [title]="'EffectiveDate' | translate"
                         [value]="info.effectiveDate"></app-property-text>

      <app-property-text [title]="'EndDate' | translate"
                         [value]="info.expiryDate"
                         [bottomLineVisible]="false"></app-property-text>
    </div>

    <!--    电子签名-->
    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'E-Signature' | translate}}</span>
    </div>

    <div class="section-wrapper" style="">
      <app-property-pick [title]="'ConsultantSignature' | translate"
                         appThrottleClick (throttleClick)="onConsultantSignature()"
                         [value]="info.signedStatus | metadataTranslate:'signedStatus'"
                         [valueColor]="info.signedStatus === 'SIGNED' ? '#1D64C4' : '#e63757'"
                         [bottomLineVisible]="false"></app-property-pick>
    </div>

    <div class="section-wrapper" style="">
      <app-property-text [title]="'SignatureTime' | translate"
                         [value]="info.signDate"
                         [bottomLineVisible]="false"></app-property-text>
    </div>

    <div *ngIf="info.signedStatus == 'UNSIGNED'">
      <!--    个人信息-->
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'PersonalDetails' | translate}}</span>
      </div>

      <div class="section-wrapper" style="">
        <!-- 姓名 -->
        <app-property-input [title]="'FullName' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('FullName' | translate)"
                            [(value)]="info.personalInfo.name"></app-property-input>
        <!-- 聯絡電話 -->
        <app-property-input [title]="'ContactNumber' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('ContactNumber' | translate)"
                            [(value)]="info.personalInfo.tel"></app-property-input>
        <!-- 行動電話 -->
        <app-property-input [title]="'MobileNumber' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('MobileNumber' | translate)"
                            [(value)]="info.personalInfo.phone"></app-property-input>
        <!-- 傳真 -->
        <app-property-input [title]="'Facsimile' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('Facsimile' | translate)"
                            [(value)]="info.personalInfo.facsimile"></app-property-input>
        <!-- 電郵 -->
        <app-property-input [title]="'Email' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('Email' | translate)"
                            [(value)]="info.personalInfo.email"></app-property-input>
        <!-- 居住地址 -->
        <app-property-input [title]="'ResidentialAddress' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('ResidentialAddress' | translate)"
                            [(value)]="info.personalInfo.residential"></app-property-input>
        <!-- 郵寄地址 -->
        <app-property-input [title]="'MailingAddress' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('MailingAddress' | translate)"
                            [bottomLineVisible]="false"
                            [(value)]="info.personalInfo.mailingAddress"></app-property-input>
      </div>
<!--      主要聯係人-->
      <div class="section-wrapper" style="">
        <app-property-pick [title]="'PrimaryContact' | translate"
                           appThrottleClick (throttleClick)="onPrimary()"
                           [value]="info.contactPerson?.length"
                           [bottomLineVisible]="false"></app-property-pick>
      </div>
      <!--    證件信息-->
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'CertificateInfo' | translate}}</span>
      </div>
      <div class="section-wrapper">
        <!--      身份證-->
        <app-property-pick [title]="'IDCard' | translate"
                           appThrottleClick (throttleClick)="openCertificateUpload()"
                           [value]="info.identityCard?.length ? ('Uploaded' | translate) : ('NotUploaded' | translate)"
                           [valueColor]="info.identityCard?.length ? '#1D64C4' : '#e63757'"></app-property-pick>
        <!--      護照-->
        <app-property-pick [title]="'PassportNumber' | translate"
                           appThrottleClick (throttleClick)="openCertificateUpload()"
                           [value]="isBlank(info.passport) ? ('Uploaded' | translate) : ('NotUploaded' | translate)"
                           [valueColor]="isBlank(info.passport) ? '#1D64C4' : '#e63757'"></app-property-pick>
        <!--      地址證明-->
        <app-property-pick [title]="'ProofOfAddress' | translate"
                           appThrottleClick (throttleClick)="openCertificateUpload()"
                           [value]="isBlank(info.address) ? ('Uploaded' | translate) : ('NotUploaded' | translate)"
                           [valueColor]="isBlank(info.address) ? '#1D64C4' : '#e63757'"
                           [bottomLineVisible]="false"></app-property-pick>

      </div>
      <!--    銀行信息-->
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'BackInfo' | translate}}</span>
      </div>
      <div class="section-wrapper">
        <!-- 銀行賬號 -->
        <app-property-input [title]="'BankAccount' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('BankAccount' | translate)"
                            [(value)]="info.bankInfo.bankAccount"></app-property-input>
        <!-- 銀行賬號姓名 -->
        <app-property-input [title]="'BankAccountName' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('BankAccountName' | translate)"
                            [(value)]="info.bankInfo.accountName"></app-property-input>
        <!-- 銀行名稱 -->
        <app-property-input [title]="'BankName' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('BankName' | translate)"
                            [(value)]="info.bankInfo.bankName"></app-property-input>

        <app-property-input [title]="'BankInfoCode' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('BankInfoCode' | translate)"
                            [(value)]="info.bankInfo.bankCode"></app-property-input>

        <app-property-input [title]="'BankAddress' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('BankAddress' | translate)"
                            [bottomLineVisible]="false"
                            [(value)]="info.bankInfo.bankAddress"></app-property-input>
      </div>

    </div>

    <div *ngIf="info.signedStatus == 'SIGNED'">
      <!--    个人信息-->
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'PersonalDetails' | translate}}</span>
      </div>

      <div class="section-wrapper">
        <!-- 姓氏 -->
        <app-property-text [title]="'FullName' | translate"
                           [value]="info.personalInfo.name"></app-property-text>
        <!-- 聯絡電話 -->
        <app-property-text [title]="'ContactNumber' | translate"
                           [value]="info.personalInfo.tel"></app-property-text>
        <!-- 行動電話 -->
        <app-property-text [title]="'MobileNumber' | translate"
                           [value]="info.personalInfo.phone"></app-property-text>
        <!-- 傳真 -->
        <app-property-text [title]="'Facsimile' | translate"
                           [value]="info.personalInfo.facsimile"></app-property-text>
        <!-- 電郵 -->
        <app-property-text [title]="'Email' | translate"
                           [value]="info.personalInfo.email"></app-property-text>
        <!-- 居住地址 -->
        <app-property-text [title]="'ResidentialAddress' | translate"
                           [value]="info.personalInfo.residential"></app-property-text>
        <!-- 郵寄地址 -->
        <app-property-text [title]="'MailingAddress' | translate"
                           [bottomLineVisible]="false"
                           [value]="info.personalInfo.mailingAddress"></app-property-text>
      </div>
<!--      主要聯係人-->
      <div class="section-wrapper" style="">
        <app-property-pick [title]="'PrimaryContact' | translate"
                           appThrottleClick (throttleClick)="onPrimary()"
                           [value]="info.contactPerson?.length"
                           [bottomLineVisible]="false"></app-property-pick>
      </div>
      <!--    證件信息-->
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'CertificateInfo' | translate}}</span>
      </div>
      <div class="section-wrapper">
        <!--      身份證-->
        <app-property-pick [title]="'IDCard' | translate"
                           appThrottleClick (throttleClick)="openCertificateUpload()"
                           [value]="info.identityCard?.length ? ('Uploaded' | translate) : ('NotUploaded' | translate)"
                           [valueColor]="info.identityCard?.length ? '#1D64C4' : '#e63757'"></app-property-pick>
        <!--      護照-->
        <app-property-pick [title]="'PassportNumber' | translate"
                           appThrottleClick (throttleClick)="openCertificateUpload()"
                           [value]="isBlank(info.passport) ? ('Uploaded' | translate) : ('NotUploaded' | translate)"
                           [valueColor]="isBlank(info.passport) ? '#1D64C4' : '#e63757'"></app-property-pick>
        <!--      地址證明-->
        <app-property-pick [title]="'ProofOfAddress' | translate"
                           appThrottleClick (throttleClick)="openCertificateUpload()"
                           [value]="isBlank(info.address) ? ('Uploaded' | translate) : ('NotUploaded' | translate)"
                           [valueColor]="isBlank(info.address) ? '#1D64C4' : '#e63757'"
                           [bottomLineVisible]="false"></app-property-pick>

      </div>
      <!--    銀行信息-->
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'BackInfo' | translate}}</span>
      </div>
      <div class="section-wrapper">
        <!-- 銀行賬號 -->
        <app-property-text [title]="'BankAccount' | translate"
                           [value]="info.bankInfo.bankAccount"></app-property-text>
        <!-- 銀行賬號姓名 -->
        <app-property-text [title]="'BankAccountName' | translate"
                           [value]="info.bankInfo.accountName"></app-property-text>
        <!-- 銀行名稱 -->
        <app-property-text [title]="'BankName' | translate"
                           [value]="info.bankInfo.bankName"></app-property-text>

        <app-property-text [title]="'BankInfoCode' | translate"
                           [value]="info.bankInfo.bankCode"></app-property-text>

        <app-property-text [title]="'BankAddress' | translate"
                           [bottomLineVisible]="false"
                           [value]="info.bankInfo.bankAddress"></app-property-text>
      </div>

    </div>

  </nz-spin>
  <div *ngIf="info.signedStatus == 'UNSIGNED'" class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
