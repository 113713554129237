<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{title}}
  </div>

  <div *ngIf="financingType == 'bank'">
    <!-- 导入 -->
    <app-property-pick [title]="'Import' | translate"
                       appThrottleClick (throttleClick)="onBackInfoSelection()"></app-property-pick>

    <!-- 银行名称 -->
    <app-property-input [title]="'BankName' | translate"
                        [required]="true"
                        [placeholder]="('PleaseEnter' | translate) + ('BankName' | translate)"
                        [(value)]="bankInfo.name"></app-property-input>
    <!-- 貸款比例 -->
    <app-property-input-number [title]="'LoanRatio' | translate"
                               [placeholder]="('PleaseEnter' | translate) + ('LoanRatio' | translate)"
                               nz-tooltip [nzTooltipTitle]="unit"
                               nzTooltipPlacement="topRight"
                               [min]="0"
                               [(value)]="bankInfo.loanRatio"></app-property-input-number>
    <!-- 贷款年限 -->
    <app-property-input-number *ngIf="yearOptions.length < 1" [title]="'LoanYear' | translate"
                               [min]="0"
                               [placeholder]="('PleaseEnter' | translate) + ('LoanYear' | translate)"
                               nz-tooltip [nzTooltipTitle]="unitYear"
                               nzTooltipPlacement="topRight"
                               [(value)]="bankInfo.year"></app-property-input-number>
    <ng-template #unitYear><div>年</div></ng-template>

    <!-- 贷款年限下拉 -->
    <app-property-select *ngIf="yearOptions.length > 0" [title]="'LoanYear' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('LoanYear' | translate)"
                         [options]="yearOptions"
                         nz-tooltip [nzTooltipTitle]="unitYear"
                         nzTooltipPlacement="topRight"
                         [(value)]="bankInfo.year"
                         (valueChange)="onYearOptionsChange($event)"></app-property-select>

    <!-- 年利率 -->
    <app-property-input-number [title]="'AnnualInterestRate' | translate"
                               [min]="0"
                               [placeholder]="('PleaseEnter' | translate) + ('AnnualInterestRate' | translate)"
                               nz-tooltip [nzTooltipTitle]="unit"
                               nzTooltipPlacement="topRight"
                               [(value)]="bankInfo.annualInterestRate"></app-property-input-number>
    <!-- 預付保費利息 -->
    <app-property-input-number [title]="'PrepaymentInterestRate' | translate"
                               [min]="0"
                               [placeholder]="('PleaseEnter' | translate) + ('PrepaymentInterestRate' | translate)"
                               nz-tooltip [nzTooltipTitle]="unit"
                               nzTooltipPlacement="topRight"
                               [(value)]="bankInfo.prepaymentInterestRate"></app-property-input-number>
    <!-- 貸款手續費率 -->
  <!--  <app-property-input [title]="'貸款手續費率' | translate"-->
  <!--                      [placeholder]="('PleaseEnter' | translate) + ('貸款手續費率' | translate)"-->
  <!--                      [(value)]="bankInfo.policyLoanHandlingFeeRate"></app-property-input>-->
    <app-property-input-number [title]="'PolicyLoanHandlingFeeRate' | translate"
                               [min]="0"
                               [placeholder]="('PleaseEnter' | translate) + ('PolicyLoanHandlingFeeRate' | translate)"
                               nz-tooltip [nzTooltipTitle]="unit"
                               nzTooltipPlacement="topRight"
                               [(value)]="bankInfo.policyLoanHandlingFeeRate"></app-property-input-number>
    <ng-template #unit><div>%</div></ng-template>
  </div>

  <div *ngIf="financingType == 'discount'">
    <!-- 导入 -->
    <app-property-pick [title]="'Import' | translate"
                       appThrottleClick (throttleClick)="onDiscountInfoSelection()"></app-property-pick>

<!--    首年折扣率-->
    <app-property-input-number [title]="'FirstYearPremiumDiscount' | translate"
                               [min]="0"
                               [placeholder]="('PleaseEnter' | translate) + ('FirstYearPremiumDiscount' | translate)"
                               nz-tooltip [nzTooltipTitle]="unit"
                               nzTooltipPlacement="topRight"
                               [(value)]="discountInfo.firstYearPremiumDiscount"></app-property-input-number>

<!--    年折扣率-->
    <app-property-input-number [title]="'AnnualPremiumDiscount' | translate"
                               [min]="0"
                               [placeholder]="('PleaseEnter' | translate) + ('AnnualPremiumDiscount' | translate)"
                               nz-tooltip [nzTooltipTitle]="unit"
                               nzTooltipPlacement="topRight"
                               [(value)]="discountInfo.annualPremiumDiscount"></app-property-input-number>

<!--    第二年保费回赠-->
    <app-property-input-number [title]="'PremiumCashback' | translate"
                               [min]="0"
                               [placeholder]="('PleaseEnter' | translate) + ('PremiumCashback' | translate)"
                               nz-tooltip [nzTooltipTitle]="unit"
                               nzTooltipPlacement="topRight"
                               [(value)]="discountInfo.premiumCashback"></app-property-input-number>
    <ng-template #unit><div>%</div></ng-template>
  </div>


  <div class="footer">
    <div *ngIf="!modifyState" nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="check" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
    <div *ngIf="modifyState" nz-row style="width: 100%; height: 100%;">
      <div class="footer-button-primary" style="width: 100%; height: 100%;">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onEdit()">
          <em nz-icon nzType="check" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
