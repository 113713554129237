<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!hasData">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="!groupMode && bookings" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="bookings.list"
                [nzPageIndex]="bookings.pageNum"
                [nzPageSize]="bookings.pageSize"
                [nzTotal]="bookings.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{ x: '1515'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">
                <div>
                  <span>{{'BookingList' | translate}}</span>
                </div>
                <div style="margin: 0 0 0 10px" nz-tooltip [nzTooltipTitle]="'ShowGroup' | translate">
                  <nz-switch [(ngModel)]="groupMode" (ngModelChange)="onGroupModeChange($event)"></nz-switch>
                </div>
                <div>
                  <a nz-button nzType="link" nzSize="small"
                     nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                     style="margin-left: 5px"
                     appThrottleClick (throttleClick)="onReload()">
                    <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                  </a>
                </div>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'BookingCreate' | translate" style=" margin-left: 10px;"
                        nz-popover [nzPopoverContent]="createTemplate"
                        [(nzPopoverVisible)]="createPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight">
                  <img alt="" width="22" src="assets/images/ic_proposal_new.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'BookingSearch' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onBookingSearch()">
                  <img alt="" width="22" src="assets/images/ic_search.svg">
                </button>
                <button nz-button nzType="default" nzSize="large" nzShape="circle" style=" margin-left: 10px;"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight">
                  <img style="margin-bottom: 4px;" alt="" width="22" src="assets/images/ic_export_excel.svg">
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ bookings.total }}</span>
          </div>
        </ng-template>

        <ng-template #commonTrs>
          <tr>
            <th nzLeft nzWidth="165px">
              <span>{{'BookingNo' | translate}}</span>
            </th>
            <th nzWidth="220px">
              <span>{{'Referee' | translate}}</span>
            </th>
            <th>
              <span>{{'ProductName' | translate}}</span>
            </th>
            <th>
              <span>{{'Insurer' | translate}}</span>
            </th>
            <th>
              <span>{{'Holder' | translate}}</span>
            </th>
            <th>
              <span>{{'Insured' | translate}}</span>
            </th>
            <th>
              <span>{{'PaymentTerm' | translate}}</span>
            </th>
            <th nzWidth="100px"
                [nzShowSort]="true"
                [nzSortFn]="true"
                (nzSortOrderChange)="statusChange($event)"><span>{{'Status' | translate}}</span>
            </th>
            <th nzWidth="280px"
                [nzShowSort]="true"
                [nzSortFn]="true"
                (nzSortOrderChange)="timeChange($event)">
              <span>{{'Time' | translate}}</span>
            </th>
            <th nzRight nzWidth="220px">
              <span>{{'Functions' | translate}}</span>
            </th>
          </tr>
        </ng-template>

        <thead>
        <ng-container *ngTemplateOutlet="commonTrs"></ng-container>
        </thead>

        <tbody>
        <tr *ngFor="let booking of bookings.list; let i = index" appThrottleClick
            (throttleClick)="onBookingDetail(booking.bookingNo)">
          <td nzLeft>
            <nz-badge [nzDot]="booking.reminder">
              <span style="font-weight: bold; font-size: 12px">
                #{{ booking.bookingNo }}
              </span>
            </nz-badge>
          </td>
          <td>
            <div>
              <span>{{ dynamicEncryption('salesName', booking.salesName) }}</span>
              <span>{{ dynamicEncryption('accountName', booking.accountName) }}</span>
            </div>
            <div *ngIf="workbench.secondAccountName">
              <span>{{ dynamicEncryption('salesName', booking.secondSalesName) }}</span>
              <span>{{ dynamicEncryption('accountName', booking.secondAccountName) }}</span>
            </div>
          </td>
          <td>
            <div style="display: flex; align-items: center;">
              <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="booking.companyLogo" alt=""/>
              <span>{{ booking.productName || 'N/A' }} <span
                *ngIf="booking.productYear">&nbsp;({{ booking.custom ? (booking.productYear | yearConversion) : (booking.productYear | yearsOrMonthOrAge)}}
                )</span> </span>
            </div>
          </td>
          <td>{{ booking.companyName }}<nz-tag *ngIf="booking.region" nzColor="processing">{{booking.region | metadataTranslate: 'productCompanyRegion'}}</nz-tag></td>
          <td>{{ dynamicEncryption('name', booking.name) }}<br>{{dynamicEncryption('pinyin', booking.namePinYin)}}</td>
          <td>{{ dynamicEncryption('insuredName', booking.insuredName) }}<br>{{dynamicEncryption('insuredName', booking.insuredPinyin)}}</td>
          <td>{{ booking.custom ? (booking.productYear | yearConversion) : (booking.productYear | yearsOrMonthOrAge) }}</td>
          <td>
            <div [ngSwitch]="booking.status">
              <div>
                <span *ngSwitchCase="'DRAFT'" class="text-warning">●</span>
                <span *ngSwitchCase="'PENDING_REVIEW'" class="text-warning">●</span>
                <span *ngSwitchCase="'APPLIED'" class="text-processing">●</span>
                <span *ngSwitchCase="'PRE_UNDERWRITING'" class="text-processing">●</span>
                <span *ngSwitchCase="'UNDERWRITING_APPROVAL'" class="text-processing">●</span>
                <span *ngSwitchCase="'CONFIRMED'" class="text-processing">●</span>
                <span *ngSwitchCase="'COMPLETED'" class="text-success">●</span>
                <span *ngSwitchCase="'CANCELLED'" class="text-cancel">●</span>
                <span> {{ booking.status | metadataTranslate: 'bookingStatus' }}</span>
              </div>
            </div>
          </td>
          <td>
            <div [ngSwitch]="booking.status">
              <div>
                <div *ngSwitchCase="'PENDING_REVIEW'">
                  <span style="color: #999999; font-size: 12px">{{'ConfirmTime' | translate}} : </span>
                  {{ (booking.appointmentTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                </div>
                <div *ngSwitchCase="'DRAFT'">
                  <span style="color: #999999; font-size: 12px">{{'PreferredTime' | translate}} : </span>
                  {{ (booking.temporaryTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                </div>
                <div *ngSwitchCase="'APPLIED'">
                  <span style="color: #999999; font-size: 12px">{{'PreferredTime' | translate}} : </span>
                  {{ (booking.temporaryTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                </div>
                <div *ngSwitchCase="'CONFIRMED'">
                  <span style="color: #999999; font-size: 12px">{{'ConfirmTime' | translate}} : </span>
                  {{ (booking.appointmentTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                  <br>
                  <span *ngIf="!booking.hideTr">
                    <span style="color: #999999; font-size: 12px">{{'TechnicalRepresentative' | translate}} : </span>
                    {{booking.trName}}
                  </span>
                  <br *ngIf="!booking.hideTr">
                  <span style="color: #999999; font-size: 12px">{{'LocationOfReservation' | translate}} : </span>
                  <span nz-tooltip="true" [nzTooltipTitle]="booking.appointmentAddress">
                    {{booking.appointmentAddress | ellipsis: 8}}
                  </span>
                </div>
                <div *ngSwitchCase="'COMPLETED'">
                  <span style="color: #999999; font-size: 12px">{{'ConfirmTime' | translate}} : </span>
                  {{ (booking.appointmentTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                  <br>
                  <span *ngIf="!booking.hideTr">
                    <span style="color: #999999; font-size: 12px">{{'TechnicalRepresentative' | translate}} : </span>
                    {{booking.trName}}
                  </span>
                  <br *ngIf="!booking.hideTr">
                  <span style="color: #999999; font-size: 12px">{{'LocationOfReservation' | translate}} : </span>
                  <span nz-tooltip="true" [nzTooltipTitle]="booking.appointmentAddress">
                    {{booking.appointmentAddress | ellipsis: 8}}
                  </span>
                </div>
                <div *ngSwitchCase="'CANCELLED'">
                  <span style="color: #999999; font-size: 12px">{{'PreferredTime' | translate}} : </span>
                  {{ (booking.temporaryTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                </div>
              </div>
            </div>
          </td>
          <td nzRight>
            <div class="functions" style="width: 100px">
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Preview' | translate"
                 style="padding-right: 0">
                <img alt="" width="20" src="../../../assets/images/ic_details.svg">
              </a>
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'LeaveMessage' | translate"
                 style="padding-right: 0"
                 appThrottleClick (throttleClick)="onLeaveMessage(booking);">
                <img alt="" width="20" src="assets/images/ic_leave_message.svg">
                <div *ngIf="(booking.unReadCount > 0 || booking.unConfirmCount > 0)" class="red-dot"></div>
              </a>
              <a nz-button nzType="link" nzSize="small" nz-tooltip
                 [nzTooltipTitle]="'ExportExcel' | translate"
                 style="vertical-align: sub;margin-left: 4px;margin-top: 3px;"
                 appThrottleClick (throttleClick)="onExportExcel(booking.bookingNo)">
                <i nz-icon nzType="export" nzTheme="outline" style="font-size: 16px;"></i>
              </a>
              <a *ngIf="booking.status === 'DRAFT'" nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
                 appThrottleClick (throttleClick)="onDelete(booking, tplTitle, tplContent, tplFooter); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_delete.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>

    <div *ngIf="groupMode && groupBookings" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="groupBookings.list"
                [nzPageIndex]="groupBookings.pageNum"
                [nzPageSize]="groupBookings.pageSize"
                [nzTotal]="groupBookings.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">
                <div>
                  <span>{{'BookingList' | translate}}</span>
                </div>
                <div style="margin: 0 0 0 10px" nz-tooltip [nzTooltipTitle]="'ShowGroup' | translate">
                  <nz-switch [(ngModel)]="groupMode" (ngModelChange)="onGroupModeChange($event)"></nz-switch>
                </div>
                <div>
                  <a nz-button nzType="link" nzSize="small"
                     nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                     style="margin-left: 5px"
                     appThrottleClick (throttleClick)="onReload()">
                    <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                  </a>
                </div>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'BookingCreate' | translate" style=" margin-left: 10px;"
                        nz-popover [nzPopoverContent]="createTemplate"
                        [(nzPopoverVisible)]="createPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight">
                  <img alt="" width="22" src="assets/images/ic_proposal_new.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'BookingSearch' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onBookingSearch()">
                  <img alt="" width="22" src="assets/images/ic_search.svg">
                </button>
                <button nz-button nzType="default" nzSize="large" nzShape="circle" style=" margin-left: 10px;"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight">
                  <img style="margin-bottom: 4px;" alt="" width="22" src="assets/images/ic_export_excel.svg">

                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ groupBookings.total }}</span>
          </div>
        </ng-template>

        <tbody>
        <ng-container *ngFor="let bookingGroup of groupBookings.list">
          <tr>
            <td [(nzExpand)]="bookingGroup.expand"><span style="font-weight: bold">
              {{ "GroupId" | translate }}:</span> {{ bookingGroup.groupNo }}
              <span style="margin-left: 20px">
                <button nz-button [nzSize]="'small'" nzType="default"
                        appThrottleClick (throttleClick)="batchOperation(bookingGroup.groupNo, 'BatchReply');"
                        >{{ 'BatchReply' | translate }}</button>
                <button style="margin-left: 10px" nz-button
                        [nzSize]="'small'" nzType="default"
                        appThrottleClick (throttleClick)="batchOperation(bookingGroup.groupNo, 'BatchUploadAttachments');"
                        >{{ 'BatchUploadAttachments' | translate }}</button>
              </span>
            </td>
          </tr>
          <tr [nzExpand]="bookingGroup.expand" appThrottleClick (throttleClick)="$event.stopPropagation();">
            <div>
              <nz-table nzSize="middle"
                        [nzShowPagination]="false"
                        [nzData]="bookingGroup.appBookingListVOS"
                        [nzScroll]="{ x: '1350px'}">
                <thead>
                <tr>
                  <th nzLeft nzWidth="165px">
                    <span>{{'BookingNo' | translate}}</span>
                  </th>
                  <th nzWidth="160px">
                    <span>{{'Referee' | translate}}</span>
                  </th>
                  <th>
                    <span>{{'ProductName' | translate}}</span>
                  </th>
                  <th>
                    <span>{{'Insurer' | translate}}</span>
                  </th>
                  <th>
                    <span>{{'Holder' | translate}}</span>
                  </th>
                  <th>
                    <span>{{'Insured' | translate}}</span>
                  </th>
                  <th>
                    <span>{{'PaymentTerm' | translate}}</span>
                  </th>
                  <th nzWidth="100px"
                      [nzShowSort]="true"
                      [nzSortFn]="true"
                      (nzSortOrderChange)="statusChange($event)"><span>{{'Status' | translate}}</span>
                  </th>
                  <th nzWidth="280px"
                      [nzShowSort]="true"
                      [nzSortFn]="true"
                      (nzSortOrderChange)="timeChange($event)">
                    <span>{{'Time' | translate}}</span>
                  </th>
                  <th nzRight nzWidth="160px">
                    <span>{{'Functions' | translate}}</span>
                  </th>
                </tr>
                </thead>

                <tbody>
                <tr *ngFor="let booking of bookingGroup.appBookingListVOS; let i = index" appThrottleClick (throttleClick)="onBookingDetail(booking.bookingNo)">
                  <td nzLeft>
                    <nz-badge [nzDot]="booking.reminder">
                      <span style="font-weight: bold; font-size: 12px">
                        #{{ booking.bookingNo }}
                      </span>
                    </nz-badge>
                  </td>
                  <td>
                    <div>
                      <span>{{ dynamicEncryption('salesName', booking.salesName) }}</span>
                      <span>{{ dynamicEncryption('salesName', booking.accountName) }}</span>
                    </div>
                    <div *ngIf="workbench.secondAccountName">
                      <span>{{ dynamicEncryption('salesName', booking.secondSalesName) }}</span>
                      <span>{{ dynamicEncryption('accountName', booking.secondAccountName) }}</span>
                    </div>
                  </td>
                  <td>
                    <div style="display: flex; align-items: center;">
                      <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="booking.companyLogo" alt=""/>
                      <span>{{ booking.productName || 'N/A' }} <span
                        *ngIf="booking.productYear">&nbsp;({{ booking.custom ? (booking.productYear | yearConversion) : (booking.productYear | yearsOrMonthOrAge)}}
                        )</span> </span>
                    </div>
                  </td>
                  <td>{{ booking.companyName }}<nz-tag *ngIf="booking.region" nzColor="processing">{{booking.region | metadataTranslate: 'productCompanyRegion'}}</nz-tag></td>
                  <td>{{ dynamicEncryption('name', booking.name) }}<br>{{dynamicEncryption('pinyin', booking.namePinYin)}}</td>
                  <td>{{ dynamicEncryption('insuredName', booking.insuredName) }}<br>{{dynamicEncryption('insuredName', booking.insuredPinyin)}}</td>
                  <td>{{ booking.productYear }}</td>
                  <td>
                    <div [ngSwitch]="booking.status">
                      <div>
                        <span *ngSwitchCase="'DRAFT'" class="text-warning">●</span>
                        <span *ngSwitchCase="'PENDING_REVIEW'" class="text-warning">●</span>
                        <span *ngSwitchCase="'APPLIED'" class="text-processing">●</span>
                        <span *ngSwitchCase="'PRE_UNDERWRITING'" class="text-processing">●</span>
                        <span *ngSwitchCase="'UNDERWRITING_APPROVAL'" class="text-processing">●</span>
                        <span *ngSwitchCase="'CONFIRMED'" class="text-processing">●</span>
                        <span *ngSwitchCase="'COMPLETED'" class="text-success">●</span>
                        <span *ngSwitchCase="'CANCELLED'" class="text-cancel">●</span>
                        <span> {{ booking.status | metadataTranslate: 'bookingStatus' }}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div [ngSwitch]="booking.status">
                      <div>
                        <div *ngSwitchCase="'PENDING_REVIEW'">
                          <span style="color: #999999; font-size: 12px">{{'ConfirmTime' | translate}} : </span>
                          {{ (booking.appointmentTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                        </div>
                        <div *ngSwitchCase="'DRAFT'">
                          <span style="color: #999999; font-size: 12px">{{'PreferredTime' | translate}} : </span>
                          {{ (booking.temporaryTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                        </div>
                        <div *ngSwitchCase="'APPLIED'">
                          <span style="color: #999999; font-size: 12px">{{'PreferredTime' | translate}} : </span>
                          {{ (booking.temporaryTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                        </div>
                        <div *ngSwitchCase="'CONFIRMED'">
                          <span style="color: #999999; font-size: 12px">{{'ConfirmTime' | translate}} : </span>
                          {{ (booking.appointmentTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                          <br>
                          <span *ngIf="!booking.hideTr">
                            <span style="color: #999999; font-size: 12px">{{'TechnicalRepresentative' | translate}} : </span>
                            {{booking.trName}}
                          </span>
                          <br *ngIf="!booking.hideTr">
                          <span style="color: #999999; font-size: 12px">{{'LocationOfReservation' | translate}} : </span>
                          {{booking.appointmentAddress}}
                        </div>
                        <div *ngSwitchCase="'COMPLETED'">
                          <span style="color: #999999; font-size: 12px">{{'ConfirmTime' | translate}} : </span>
                          {{ (booking.appointmentTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                          <br>
                          <span *ngIf="!booking.hideTr">
                            <span style="color: #999999; font-size: 12px">{{'TechnicalRepresentative' | translate}} : </span>
                            {{booking.trName}}
                          </span>
                          <br *ngIf="!booking.hideTr">
                          <span style="color: #999999; font-size: 12px">{{'LocationOfReservation' | translate}} : </span>
                          {{booking.appointmentAddress}}
                        </div>
                        <div *ngSwitchCase="'CANCELLED'">
                          <span style="color: #999999; font-size: 12px">{{'PreferredTime' | translate}} : </span>
                          {{ (booking.temporaryTime | date:'yyyy-MM-dd HH:mm') || 'N/A' }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td nzRight>
                    <div class="functions">
                      <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Preview' | translate">
                        <img alt="" width="20" src="../../../assets/images/ic_details.svg">
                      </a>
                      <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'LeaveMessage' | translate"
                         style="padding-right: 0"
                         appThrottleClick (throttleClick)="onLeaveMessage(booking);">
                        <img alt="" width="20" src="assets/images/ic_leave_message.svg">
                        <div *ngIf="booking.unReadCount > 0" class="red-dot"></div>
                      </a>
                      <a nz-button nzType="link" nzSize="small" nz-tooltip
                         [nzTooltipTitle]="'ExportExcel' | translate"
                         style="vertical-align: sub;margin-left: 4px;"
                         appThrottleClick (throttleClick)="onExportExcel(booking.bookingNo)">
                        <i nz-icon nzType="export" nzTheme="outline" style="font-size: 16px;"></i>
                      </a>
                      <a *ngIf="booking.status === 'DRAFT'" nz-button nzType="link" nzSize="small"
                         nz-tooltip [nzTooltipTitle]="'Delete' | translate"
                         appThrottleClick (throttleClick)="onDelete(booking, tplTitle, tplContent, tplFooter); $event.stopPropagation();">
                        <img alt="" width="20" src="assets/images/ic_delete.svg">
                      </a>
                    </div>
                  </td>
                </tr>
                </tbody>
              </nz-table>
            </div>
          </tr>
        </ng-container>
        </tbody>
      </nz-table>
    </div>
</nz-spin>

    <ng-template #filterTemplate>
      <div style="width: 400px; padding: 5px;">
        <div style="padding: 0 0 15px 0">{{'ExportPDF' | translate}}</div>
        <div class="property-bottom-line-blue"></div>
        <app-property-datetime-range [title]="'CreateTime' | translate"
                                     [value]="Dates"
                                     [required]="true"
                                     (valueChange)="onInspectDateChange($event)"></app-property-datetime-range>
        <nz-row [nzGutter]="5">
          <nz-col nzSpan="12">
            <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                    (throttleClick)="onDownload()">
              <span>{{'Reset' | translate}}</span>
            </button>
          </nz-col>
          <nz-col nzSpan="12">
            <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                    (throttleClick)="onExportPDF()">
              <span>{{'Confirm' | translate}}</span>
            </button>
          </nz-col>
        </nz-row>
      </div>
    </ng-template>

  <!--  Excel导入弹窗-->
  <nz-modal
    [(nzVisible)]="isVisible"
    [nzTitle]="modalTitle"
    [nzContent]="modalContent"
    [nzFooter]="null"
    nzCentered
    [nzClosable]="true"
    [nzMaskClosable]="false"
    (nzOnCancel)="handleCancel()"
  >
    <ng-template #modalTitle>
      <div style="font-weight: bold; font-size: 16px;">
        {{'ExcelImport' | translate}}
      </div>
    </ng-template>

    <ng-template #modalContent>
      <p style="font-weight: 600;font-size: 16px;">{{'Prepare' | translate}}</p>
      <p>
        {{'PrepareInfo' | translate}}
        <span style="color: #0078d4; cursor: pointer;"
              appThrottleClick (throttleClick)="exportExcel()">
          <i nz-icon [nzType]="iconType" nzTheme="outline"></i>
          {{'ClickDownload' | translate}}
        </span>
        <nz-tag *ngIf="updateTemplate" [nzColor]="'#f50'">New!</nz-tag>
      </p>
      <p>{{'PrepareInfo2' | translate}}</p>
      <p style="margin-bottom: 30px">{{'PrepareInfo3' | translate}}</p>

      <div nz-row style="width: 100%; height: 100%; justify-content: end;">
        <button nz-col nzSpan="6" nz-button nzType="primary"
                appThrottleClick (throttleClick)="handleOk()">{{'UploadFile' | translate}}</button>
      </div>
      <input #fileUpload hidden=true type="file" ng2FileSelect [uploader]="uploader"
             [accept]="fileFormat"
             (change)="ImportFileOnChanged($event)"/>
    </ng-template>
  </nz-modal>

  <!--  导入提示弹窗弹窗-->
  <nz-modal
    [(nzVisible)]="importMessageVisible"
    [nzTitle]="modalTitle1"
    [nzContent]="modalContent1"
    [nzFooter]="null"
    nzCentered
    [nzClosable]="true"
    [nzMaskClosable]="false"
    (nzOnCancel)="importMessageVisible = false"
  >
    <ng-template #modalTitle1>
      <div style="font-weight: bold; font-size: 16px;">
        {{'ImportPolicyInfo' | translate}}
      </div>
    </ng-template>

    <ng-template #modalContent1>

      <div *ngIf="errorDetails && errorDetails.message" style="margin-bottom: 10px;">
        <span style="font-size: 13px; color: #e63757;">{{ errorDetails?.message }}</span>
      </div>

      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="errorDetails.errorDetails">
        <thead>
        <tr>
          <th>
            <span>{{'Row' | translate}}</span>
          </th>
          <th>
            <span>{{'Column' | translate}}</span>
          </th>
          <th>
            <span>{{'IdentifyData' | translate}}</span>
          </th>
          <th>
            <span>{{'ErrorTip' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of errorDetails.errorDetails; let i = index">
          <td><span>{{ item.row }}</span></td>
          <td><span>{{ item.column }}</span></td>
          <td><span>{{ item.data }}</span></td>
          <td><span>{{ item.message }}</span></td>
        </tr>
        </tbody>
      </nz-table>
      <div style="margin-top: 10px">
        <p>{{'IdentifyRows' | translate}}{{ errorDetails.totalRows }}</p>
        <p>{{'ErrorRows' | translate}}{{ errorDetails.errorRecords }}</p>
        <p>{{'EffectiveRows' | translate}}{{ errorDetails.validRows }}</p>
        <p>{{'SaveRows' | translate}}{{ errorDetails.storageRecords || 0}}</p>
      </div>
    </ng-template>
  </nz-modal>
</div>

<!-- 删除预约 -->
<ng-template #tplTitle>
  <span>{{'DeleteBooking' | translate}}</span>
</ng-template>
<ng-template #tplContent let-params>
  <p>{{'DoYouWantToDeleteBooking' | translate}} :「{{ params.bookingNo }}」?</p>
</ng-template>
<ng-template #tplFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()" [disabled]="deleting">{{'Cancel' | translate}}</button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onDeleteConfirm(ref, params)" [nzLoading]="deleting">
    {{'Delete' | translate}}
  </button>
</ng-template>

<!-- 气泡Template -->
<ng-template #createTemplate>
  <div style="width: 170px; padding: 5px;">
    <button nz-button style="width: 100%; margin-bottom: 10px"
            nzType="default"
            appThrottleClick (throttleClick)="onBookingCreate()">
      {{'CreateBooking' | translate}}
    </button>
    <button nz-button style="width: 100%;"
            nzType="default"
            appThrottleClick (throttleClick)="isVisible = true">
      {{'UploadExcel' | translate}}
    </button>
  </div>
</ng-template>
