<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!amls">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="amls" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="amls.list"
                [nzPageIndex]="amls.pageNum"
                [nzPageSize]="amls.pageSize"
                [nzTotal]="amls.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'AmlList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">

                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight"
                        style=" margin-left: 10px;">
                  <img alt="" width="24" src="assets/images/ic_filter.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'NewInspection' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onAmlNewInspection()">
                  <img alt="" width="22" src="../../../../assets/images/ic_add.svg">
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ amls.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="200px">
            <span>{{'Name' | translate}}</span>
          </th>
          <th nzLeft nzWidth="200px">
            <span>{{'NameInEnglish' | translate}}</span>
          </th>
          <th nzWidth="200px">
            <span>{{'InspectionTime' | translate}}</span>
          </th>
          <th nzWidth="220px">
            <span>{{'Type' | translate}}</span>
          </th>
          <th nzWidth="100px"><span>{{'InspectionResult' | translate}}</span>
          </th>
          <th nzRight nzWidth="160px" nzAlign="center">
            <span>{{'Action' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let aml of amls.list; let i = index" appThrottleClick (throttleClick)="onAmlInspectDetail(aml.id)">
          <td nzLeft>
            <span style="font-weight: bold; font-size: 13px;">
              {{ aml.name }}
            </span>
          </td>
          <td>
            <span style="font-weight: bold; font-size: 13px;">
              {{aml.englishName || 'N/A'}}
            </span>
          </td>
          <td>
            <div style="display: flex; align-items: center;">
              <span>{{ aml.createTime | date:'yyyy-MM-dd HH:mm:ss' }}</span>
            </div>
          </td>
          <td>{{ aml.type | metadataTranslate: 'amlRecordType' }}</td>
          <td>
            <div [ngSwitch]="aml.status">
              <div>
                <span *ngSwitchCase="'NORMAL'"
                      class="text-success">●<span> {{ aml.status | metadataTranslate: 'amlRecordStatus' }}</span></span>
                <span *ngSwitchCase="'SUSPICIOUS'"
                      class="text-warning">●<span> {{ aml.status | metadataTranslate: 'amlRecordStatus' }}</span></span>
                <span *ngSwitchCase="'DANGEROUS'"
                      class="text-danger">●<span> {{ aml.status | metadataTranslate: 'amlRecordStatus' }}</span></span>
                <span *ngSwitchCase="'WARNING'"
                      class="text-warning">●<span> {{ aml.status | metadataTranslate: 'amlRecordStatus' }}</span></span>
                <span *ngSwitchCase="'FAILED'"
                      class="text-cancel">●<span> {{ aml.status | metadataTranslate: 'amlRecordStatus' }}</span></span>
              </div>
            </div>
          </td>
          <td nzRight nzAlign="center">
            <div class="functions">
              <a nz-button nzType="link"
                 nz-tooltip [nzTooltipTitle]="'Add to Monitor'"
                 appThrottleClick (throttleClick)="addEntryList(aml.id); $event.stopPropagation();">
                <i style="font-size: 20px" nz-icon nzType="plus-circle" nzTheme="outline"></i>
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>

<!-- 气泡Template -->
<ng-template #filterTemplate>
  <div style="width: 400px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <!-- 類型 -->
    <app-property-select [title]="'Type' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Type' | translate)"
                         [options]="typeOptions"
                         [allowClear]="false"
                         [(value)]="search.type"></app-property-select>
    <!-- 名字 -->
    <app-property-input [title]="'Name' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('Name' | translate)"
                        [(value)]="search.name"></app-property-input>

<!--    <app-property-select [title]="'Gender' | translate"-->
<!--                         [placeholder]="('PleaseSelect' | translate) + ('Gender' | translate)"-->
<!--                         [options]="genderOptions"-->
<!--                         [(value)]="search.gender"></app-property-select>-->
    <!-- 創建時間 -->
    <app-property-datetime-range [title]="'InspectTime' | translate"
                           [value]="dates" (valueChange)="onSearchDateChange($event)"></app-property-datetime-range>
    <!-- 檢查結果 -->
    <app-property-select [title]="'InspectionResult' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('InspectionResult' | translate)"
                         [options]="statusOptions"
                         [(value)]="search.status"></app-property-select>

    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
