import { Component, Input, OnInit } from '@angular/core';
import { DataMode, Passport } from '../../../api/types';

@Component({
  selector: 'app-water-mark',
  templateUrl: './water-mark.component.html',
  styleUrls: ['./water-mark.component.less']
})
export class WaterMarkComponent implements OnInit {
  @Input()
  xSpace = 150;
  @Input()
  ySpace = 100;
  @Input()
  xWidth = 200;
  @Input()
  yHeight = 100;
  watermarkList = [];
  constructor() {
  }

  ngOnInit(): void {
    let passport: Passport;
    passport = JSON.parse(localStorage.getItem('LIFEBEE-SALES-PASSPORT'));
    setTimeout(() => {
      let text1 = {watermark_txt: passport.name, watermark_account: passport.accountName, watermark_fontsize: '16px'};
      this.watermarkList = this.watermark(text1);
      for (let item of this.watermarkList) {
        let style = JSON.parse(item.style);
        // json字符串转换为json对象，因为ngStyle只支持对象
        item.objStyle = style;
      }
    }, 500);
  }

  watermark(settings): any {
    // 默认设置
    let defaultSettings = {
      watermark_txt: 'text',
      watermark_account: 'account',
      watermark_x: 20, // 水印起始位置x轴坐标
      watermark_y: 20, // 水印起始位置Y轴坐标
      watermark_rows: 20, // 水印行数
      watermark_cols: 20, // 水印列数
      watermark_x_space: this.xSpace, // 水印x轴间隔
      watermark_y_space: this.ySpace, // 水印y轴间隔
      watermark_color: '#000000', // 水印字体颜色
      watermark_alpha: 0.15, // 水印透明度
      watermark_fontsize: '18px', // 水印字体大小
      watermark_font: '微软雅黑', // 水印字体
      watermark_width: this.xWidth, // 水印宽度
      watermark_height: this.yHeight, // 水印长度
      watermark_angle: 25, // 水印倾斜度数
    };
    // 采用配置项替换默认值，作用类似jquery.extend
    if (arguments.length === 1 && typeof arguments[0] === 'object') {
      let src = arguments[0] || {};
      for (let key in src) {
        if (src[key] && defaultSettings[key] && src[key] === defaultSettings[key])
          continue;
        else if (src[key])
          defaultSettings[key] = src[key];
      }
    }

    let resList = [];
    let page_width = document.body.offsetWidth;
    let page_height = document.body.offsetHeight;

    // 如果将水印列数设置为0，或水印列数设置过大，超过页面最大宽度，则重新计算水印列数和水印x轴间隔
    if (defaultSettings.watermark_cols === 0 || (defaultSettings.watermark_x + defaultSettings.watermark_width * defaultSettings.watermark_cols + defaultSettings.watermark_x_space * (defaultSettings.watermark_cols - 1) > page_width)) {
      defaultSettings.watermark_cols = (page_width - defaultSettings.watermark_x + defaultSettings.watermark_x_space) / (defaultSettings.watermark_width + defaultSettings.watermark_x_space);
      defaultSettings.watermark_x_space = (page_width - defaultSettings.watermark_x - defaultSettings.watermark_width * defaultSettings.watermark_cols) / (defaultSettings.watermark_cols - 1);
    }
    // 如果将水印行数设置为0，或水印行数设置过大，超过页面最大长度，则重新计算水印行数和水印y轴间隔
    if (defaultSettings.watermark_rows == 0 ||
      (defaultSettings.watermark_y + defaultSettings.watermark_height * defaultSettings.watermark_rows + defaultSettings.watermark_y_space * (defaultSettings.watermark_rows - 1)) > page_height) {
      defaultSettings.watermark_rows = (defaultSettings.watermark_y_space + page_height - defaultSettings.watermark_y) / (defaultSettings.watermark_height + defaultSettings.watermark_y_space);
      defaultSettings.watermark_y_space = (page_height - defaultSettings.watermark_y - defaultSettings.watermark_height * defaultSettings.watermark_rows) / (defaultSettings.watermark_rows - 1);
    }
    let x;
    let y;
    for (let i = 0; i < defaultSettings.watermark_rows; i++) {
      y = defaultSettings.watermark_y + (defaultSettings.watermark_y_space + defaultSettings.watermark_height) * i;
      for (let j = 0; j < defaultSettings.watermark_cols; j++) {
        x = defaultSettings.watermark_x + (defaultSettings.watermark_width + defaultSettings.watermark_x_space) * j;
        let mask_div = {
          style: '',
          txt: '',
          account: '',
        };

        mask_div.txt = defaultSettings.watermark_txt;
        mask_div.account = defaultSettings.watermark_account;
        // 设置水印div倾斜显示
        mask_div.style = JSON.stringify({
          'pointer-events': 'none',
          '-webkit-transform': 'rotate(-' + defaultSettings.watermark_angle + 'deg)',
          '-moz-transform': 'rotate(-' + defaultSettings.watermark_angle + 'deg)',
          '-ms-transform': 'rotate(-' + defaultSettings.watermark_angle + 'deg)',
          '-o-transform': 'rotate(-' + defaultSettings.watermark_angle + 'deg)',
          'transform': 'rotate(-' + defaultSettings.watermark_angle + 'deg)',
          'position': 'absolute',
          'left': x + 'px',
          'top': y + 'px',
          'overflow': 'hidden',
          'z-index': '999999',
          'opacity': defaultSettings.watermark_alpha,
          'font-size': defaultSettings.watermark_fontsize,
          'font-family': defaultSettings.watermark_font,
          'color': defaultSettings.watermark_color,
          'text-align': 'center',
          'width': defaultSettings.watermark_width + 'px',
          'height': defaultSettings.watermark_height + 'px',
          'display': 'block',
        });

        resList = resList.concat(mask_div);
      }
    }
    return resList;
  }

}
