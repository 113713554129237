<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{'ProductList' | translate}}
  </div>

  <div *ngFor="let product of products; let i = index;">
    <app-fna-product-item [product]="product" [index]="i" (tickEvent)="onTick($event)"></app-fna-product-item>
  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
