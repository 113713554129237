import { Injectable } from '@angular/core';
import { ApiResponse, DataMode, PagedResp } from '../api/types';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { HttpService } from '../core/http.service';
import {
  Fna,
  FnaAssessAdviceUpdateReq,
  FnaCreateReq,
  FnaCustomerUpdateReq,
  FnaDraft,
  FnaFormUpdateReq,
  FnaListRes,
  FnaProductRecommended,
  FnaQuestion,
  FnaQuestionType,
  FnaRecommendedUpdateReq,
  FnaSearch, FnaSignatureUpdateReq,
  FnaSourceData
} from './fna-types';
import { plainToClass, plainToInstance } from 'class-transformer';
import { plainToArray } from '../shared/utils/class-transform';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class FnaService extends BaseService {

  constructor(private http: HttpService, private httpClient: HttpClient) {
    super();
  }

  /**
   * FNA列表
   * @param search 查询条件
   * @param type 类型
   */
  list(search: FnaSearch, type: DataMode): Observable<PagedResp<FnaListRes>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    if (search.accountName != null) {
      httpParams = httpParams.set('accountName', search.accountName.toString());
    }
    if (search.bookingNo != null) {
      httpParams = httpParams.set('bookingNo', search.bookingNo.toString());
    }
    if (search.underwritingNo != null) {
      httpParams = httpParams.set('underwritingNo', search.underwritingNo.toString());
    }
    if (search.policyNo != null) {
      httpParams = httpParams.set('policyNo', search.policyNo.toString());
    }
    let uri = '';
    switch (type) {
      case DataMode.OWN:
        uri = '/list';
        break;
      case DataMode.SUBORDINATE:
        uri = '/list-subordinate';
        break;
      case DataMode.FOLLOWUP:
        uri = '/list-follow-up';
        break;
      case DataMode.SALESGROUP:
        uri = '/list-sales-group';
        break;
    }
    return this.http.get<ApiResponse<PagedResp<FnaListRes>>>(API.fnaV3 + uri, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      const resp = plainToClass(PagedResp, data.body.data) as PagedResp<FnaListRes>;
      for (let i = 0; i < resp.list.length; i++) {
        resp.list[i] = plainToClass(FnaListRes, resp.list[i]);
      }
      return resp;
    }));
  }

  /**
   * FNA关联列表
   * @param search 查询条件
   */
  listPage(search: FnaSearch): Observable<PagedResp<FnaListRes>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    if (search.appAccountName != null) {
      httpParams = httpParams.set('appAccountName', search.appAccountName.toString());
    }
    if (search.bookingNo != null) {
      httpParams = httpParams.set('bookingNo', search.bookingNo.toString());
    }
    if (search.underwritingNo != null) {
      httpParams = httpParams.set('underwritingNo', search.underwritingNo.toString());
    }
    if (search.policyNo != null) {
      httpParams = httpParams.set('policyNo', search.policyNo.toString());
    }
    return this.http.get<ApiResponse<PagedResp<FnaListRes>>>(API.fnaV3 + '/page', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      const resp = plainToClass(PagedResp, data.body.data) as PagedResp<FnaListRes>;
      for (let i = 0; i < resp.list.length; i++) {
        resp.list[i] = plainToClass(FnaListRes, resp.list[i]);
      }
      return resp;
    }));
  }

  /**
   * FNA詳情
   * @param id FNA詳情
   */
  info(id: number): Observable<Fna> {
    return this.http.get<ApiResponse<Fna>>(API.fnaV3 + '/info/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(Fna, data.body.data, {exposeDefaultValues: true});
    }));
  }

  /**
   * 更新FNA客戶
   * @param createReq 客戶數據
   */
  create(createReq: FnaCreateReq): Observable<FnaDraft> {
    return this.http.post<ApiResponse<FnaDraft>>(API.fnaV3 + '/create', createReq, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(FnaDraft, data.body.data);
    }));
  }

  /**
   * 更新FNA客戶
   * @param updateReq 客戶數據
   */
  updateCustomer(updateReq: FnaCustomerUpdateReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.fnaV3 + '/customer', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * FNA客户来源列表
   */
  sourceList(): Observable<FnaSourceData[]> {
    return this.http.get<ApiResponse<FnaSourceData[]>>(API.fnaV3 + '/source-list', {
      observe: 'response'
    }).pipe(map(data => {
      const sources = data.body.data;
      return plainToArray(FnaSourceData, sources);
    }));
  }

  /**
   * 更新FNA表單
   * @param updateReq 客戶數據
   */
  updateForm(updateReq: FnaFormUpdateReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.fnaV3 + '/form', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 暫存FNA表單
   * @param updateReq 客戶數據
   */
  updateFormTemporary(updateReq: FnaFormUpdateReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.fnaV3 + '/draft-fna', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * FNA客户来源列表
   */
  questions(id: number, questionType: FnaQuestionType): Observable<FnaQuestion[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('id', id.toString());
    httpParams = httpParams.set('questionType', questionType.toString());
    return this.http.get<ApiResponse<FnaQuestion[]>>(API.fnaV3 + '/questions', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      const sources = data.body.data;
      return plainToArray(FnaQuestion, sources);
    }));
  }

  productRecommended(id: number): Observable<FnaProductRecommended> {
    return this.http.get<ApiResponse<FnaProductRecommended>>(API.fnaV4 + '/recommend/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      const sources = data.body.data;
      return plainToClass(FnaProductRecommended, sources, {exposeDefaultValues: true});
    }));
  }

  /**
   * 推荐产品保存
   * @param updateReq 推荐表单
   */
  updateRecommended(updateReq: FnaRecommendedUpdateReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.fnaV3 + '/recommend', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 暫存FNA评估建议
   * @param updateReq 评估建议數據
   */
  updateAdviceTemporary(updateReq: FnaAssessAdviceUpdateReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.fnaV3 + '/draft-advice', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新FNA评估建议
   * @param updateReq 评估建议數據
   */
  updateAdvice(updateReq: FnaAssessAdviceUpdateReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.fnaV3 + '/advice', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * FNA pdf下载
   * @param id id
   */
  viewFna(id: number): Observable<Uint8Array> {
    return this.httpClient
      .get(API.fnaV3 + '/download/' + id, {
        responseType: 'arraybuffer'
      }).pipe(map((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer)));
  }

  /**
   * FNA pdf下载
   * @param id id
   */
  viewFnaPdf(id: number): Observable<string> {
    return this.http.get<ApiResponse<string>>(API.fnaV3 + '/pdf/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新FNA签名
   * @param updateReq 签名數據
   */
  updateSignature(updateReq: FnaSignatureUpdateReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.fnaV4 + '/signature', updateReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * FNA重新簽名
   * @param id FNA Id
   */
  reSign(id: number): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.fnaV3 + '/re-sign/' + id, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * FNA提交最終簽名
   * @param id FNA Id
   */
  end(id: number): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.fnaV3 + '/end/' + id, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 删除FNA
   * @param id FNA Id
   */
  delete(id: number): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.fnaV3 + '/delete/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 复用FNA
   * @param id FNA ID
   */
  reuse(id: number): Observable<FnaDraft> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('id', id.toString());
    return this.http.get<ApiResponse<FnaDraft>>(API.fnaV3 + '/reuse', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToClass(FnaDraft, data.body.data, {excludeExtraneousValues: true, exposeDefaultValues: true});
    }));
  }

}
