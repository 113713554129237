<div>

  <div class="merge-title">
    <span>{{'BookingGroupPrompting' | translate}}</span>
  </div>

  <div class="merge-desc">
    <span>{{'BookingGroupPromptingDesc' | translate}}</span>
  </div>

  <div>
    <span>{{'LastBookingInfo' | translate}}：</span>
  </div>
  <div class="property-bottom-line" style="margin: 5px 0 5px 0"></div>
  <div class="merge-info">
    <span>{{'ReservationTime' | translate}}：{{ checkMerge.temporaryTime | date:'yyyy-MM-dd HH:mm' }}</span>
  </div>
  <div class="merge-info">
    <span>{{'Holder' | translate}}：{{ checkMerge.ownerName }} | {{ checkMerge.ownerNameEn }}</span>
  </div>
  <div class="merge-info">
    <span>{{'ProductName' | translate}}：{{ checkMerge.productName }}</span>
  </div>

  <div>
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           (click)="onDoNotMerge()">
          <span>{{'DoNotMerge' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" (click)="onMerge()">
          <span>{{'Merge' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
