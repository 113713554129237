<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{title}}
  </div>

  <div class="section-wrapper">
    <!-- 与受保人关系 -->
    <app-property-text [title]="objectType === 'SecondInsured' ? ('RelationshipToHolder' | translate) : ('RelationshipToInsured' | translate)"
                       [bottomLineVisible]="false"
                       [value]="contingentOwner.relation |metadataTranslate:'relationship'"></app-property-text>
  </div>

  <div *ngIf="contingentOwner.relation != 'OWN'" class="section-wrapper">
    <!-- 姓氏 -->
    <app-property-text *ngIf="!workbench.hiddenLastName" [title]="'LastName' | translate"
                       [isEncryption]="dynamicEncryption('lastName')"
                       [value]="contingentOwner.lastName"></app-property-text>
    <!-- 名字 -->
    <app-property-text [title]="'FirstName' | translate"
                       [isEncryption]="dynamicEncryption('firstName')"
                       [value]="contingentOwner.firstName"></app-property-text>
    <!-- 姓氏-英文 -->
    <app-property-text *ngIf="!workbench.hiddenLastName" [title]="'LastNameInEnglish' | translate"
                       [isEncryption]="dynamicEncryption('lastNamePinyin')"
                       [value]="contingentOwner.lastNamePinyin"></app-property-text>
    <!-- 名字-英文 -->
    <app-property-text [title]="'FirstNameInEnglish' | translate"
                       [isEncryption]="dynamicEncryption('firstNamePinyin')"
                       [value]="contingentOwner.firstNamePinyin"></app-property-text>
    <div *ngIf="!instance.isWDF() ? true : moduleSource !== 'booking'">
      <!-- 性别 -->
      <app-property-text [title]="'Gender' | translate"
                         [isEncryption]="dynamicEncryption('gender')"
                         [value]="contingentOwner.gender | metadataTranslate:'gender'"></app-property-text>
      <!-- 生日 -->
      <app-property-text [title]="'DateOfBirth' | translate"
                         [isEncryption]="dynamicEncryption('birthday')"
                         [value]="contingentOwner.birthdate"></app-property-text>
      <!-- 年龄 -->
      <app-property-text [title]="'Age' | translate"
                         [isEncryption]="dynamicEncryption('birthday')"
                         [value]="birthdayDate"></app-property-text>
      <!-- 身份證號 -->
      <app-property-text [title]="'IdentityCardNumber' | translate"
                         [isEncryption]="dynamicEncryption('idCard')"
                         [value]="contingentOwner.idCard"></app-property-text>
      <!-- 护照号码 -->
      <app-property-text [title]="'PassportNo' | translate"
                         [isEncryption]="dynamicEncryption('passport')"
                         [bottomLineVisible]="false"
                         [value]="contingentOwner.passport"></app-property-text>
    </div>
  </div>

  <div *ngIf="moduleSource === 'booking'" class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Edit' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
