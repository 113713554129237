import { Component, OnInit } from '@angular/core';
import { PagedResp } from '../../api/types';
import { MetadataService } from '../../shared/service/metadata.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { InformationService } from '../information.service';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { InformationDetailComponent } from '../information-detail/information-detail.component';
import { ActivatedRoute } from '@angular/router';
import { InformationListResp, InformationSearch, InformationType } from '../information-types';
import { ProductSelectionComponent, ProductSelectionType } from '../../shared/component/product-selection/product-selection.component';
import { ProductService } from '../../product/product.service';
import { ProductCompany } from '../../product/product-types';

@Component({
  selector: 'app-information-list',
  templateUrl: './information-list.component.html',
  styleUrls: ['./information-list.component.less']
})
export class InformationListComponent implements OnInit {

  promotion: boolean; // 是否是产品优惠列表

  loading = false;

  spinning = false;

  informations: PagedResp<InformationListResp>;

  search: InformationSearch = new InformationSearch();

  filterPopoverVisible = false;

  companies: ProductCompany[];

  companyOptions: PropertySelectOption[] = [];

  typeOptions: PropertySelectOption[] = [];

  regionOptions: PropertySelectOption[] = [];


  constructor(private router: ActivatedRoute,
              private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private productService: ProductService,
              private informationService: InformationService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.informationService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.informations = data;
        },
        error => {
          this.loading = false;
        });

    const informationTypes = this.metadataService.values('informationType');
    for (const informationType of informationTypes) {
      this.typeOptions.push(new PropertySelectOption(informationType.value, informationType.key));
    }

    this.productService.companies()
      .subscribe(
        data => {
          this.companies = data;
          data.forEach(company => {
            this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
          });

        },
        error => {
        });


    this.router
      .queryParams
      .subscribe(params => {
        this.promotion = JSON.parse(params['promotion']);
        if (this.promotion) {
          this.search.type = InformationType.PRODUCT_PROMOTION;
        } else {
          this.search.type = null;
        }
      });

    this.getRegion();

  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.informationService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.informations = data;
          this.filterPopoverVisible = false;
        },
        error => {
          this.loading = false;
        });
  }


  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search = new InformationSearch();
    }
    this.informationService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.informations = data;
        },
        error => {
          this.loading = false;
        });
  }

  getRegion(): void {
    this.productService.companiesRegion()
      .subscribe(regions => {
        for (const region of regions) {
          this.regionOptions.push(new PropertySelectOption(region.name, region.key));
        }
      }, () => {});
  }

  onResetSearch(): void {
    this.search = new InformationSearch();
    this.onReload();
  }

  onInformationDetail(id: number): void {
    console.log(id);
    this.loading = true;
    this.informationService.info(id)
      .subscribe(
        information => {
          this.loading = false;
          const drawerRef = this.drawerService.create<InformationDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: InformationDetailComponent,
            nzContentParams: {
              information
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.DEFAULT
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.search.product = product;
            this.search.productCode = this.search.product.productCode;
            drawerRef.close();
            this.filterPopoverVisible = true;
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
    });
  }
}
