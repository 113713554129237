import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreSalesComponent } from './pre-sales.component';
import { PreSalesMainComponent } from './pre-sales-main.component';
import { PreSalesListComponent } from './pre-sales-list/pre-sales-list.component';
import {SharedModule} from '../shared/shared.module';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {RouterModule} from '@angular/router';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {TranslateModule} from '@ngx-translate/core';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzModalModule} from 'ng-zorro-antd/modal';
import { NzTabsModule } from 'ng-zorro-antd/tabs';



@NgModule({
  declarations: [
    PreSalesComponent,
    PreSalesMainComponent,
    PreSalesListComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        NzLayoutModule,
        NzSpinModule,
        NzButtonModule,
        NzIconModule,
        TranslateModule,
        NzEmptyModule,
        NzTableModule,
        NzGridModule,
        NzToolTipModule,
        NzPopoverModule,
        NzAvatarModule,
        NzModalModule,
        NzTabsModule
    ]
})
export class PreSalesModule { }
