<!-- 接送需求 -->
<app-property-select [title]="'TransferDemand' | translate"
                     [options]="transportDemandOptions"
                     [(value)]="vip.transportDemand"></app-property-select>

<!-- 行程1 -->
<div>
  <div class="section-title" style="margin-top: 15px">
    <span
      style="color: #555555; font-weight: bold; font-size: 14px; margin: 5px 5px 5px 5px">{{ 'Trip1' | translate}}</span>
  </div>

  <!-- 上車時間 -->
  <app-property-datetime [title]="'BoardingTime' | translate"
                         [formatter]="'yyyy-MM-dd HH:mm'"
                         [showTime]="{nzFormat: 'HH:mm'}"
                         [required]="true"
                         [(value)]="vip.pickUpTime"></app-property-datetime>
  <!-- 航班編號/高鐵車次 -->
  <app-property-input [title]="'FlightOrTrainNumber' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('FlightOrTrainNumber' | translate)"
                      [(value)]="vip.arriveFlightNumber"></app-property-input>
  <!-- 上車地點 -->
  <app-property-input [title]="'BoardingLocation' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('BoardingLocation' | translate)"
                      [required]="true"
                      [(value)]="vip.arriveBoardingLocation"></app-property-input>

  <!-- 下車地點 -->
  <app-property-input [title]="'DropOffLocation' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('DropOffLocation' | translate)"
                      [required]="true"
                      [(value)]="vip.arriveGetOffLocation"></app-property-input>

  <!-- 行程联系人 -->
  <app-property-input [title]="'TripContact' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('TripContact' | translate)"
                      [required]="true"
                      [(value)]="vip.arriveContacts"></app-property-input>

  <!-- 行程联系人电话 -->
  <app-property-input [title]="'TripContactPhone' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('TripContactPhone' | translate)"
                      [required]="true"
                      [(value)]="vip.arriveContactDetails"></app-property-input>

</div>

<!-- 行程2 -->
<div *ngIf="vip.transportDemand === 'ROUND_TRIP'">
  <div class="section-title" style="margin-top: 15px">
    <span
      style="color: #555555; font-weight: bold; font-size: 14px; margin: 5px 5px 5px 5px">{{ 'Trip2' | translate}}</span>
  </div>

  <!-- 上車時間 -->
  <app-property-datetime [title]="'BoardingTime' | translate"
                         [formatter]="'yyyy-MM-dd HH:mm'"
                         [showTime]="{nzFormat: 'HH:mm'}"
                         [required]="true"
                         [(value)]="vip.seeOFFTime"></app-property-datetime>

  <!-- 航班編號/高鐵車次 -->
  <app-property-input [title]="'FlightOrTrainNumber' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('FlightOrTrainNumber' | translate)"
                      [(value)]="vip.leaveFlightNumber"></app-property-input>

  <!-- 上車地點 -->
  <app-property-input [title]="'BoardingLocation' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('BoardingLocation' | translate)"
                      [required]="true"
                      [(value)]="vip.leaveBoardingLocation"></app-property-input>
  <!-- 下車地點 -->
  <app-property-input [title]="'DropOffLocation' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('DropOffLocation' | translate)"
                      [required]="true"
                      [(value)]="vip.leaveGetOffLocation"></app-property-input>

  <!-- 行程联系人 -->
  <app-property-input [title]="'TripContact' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('TripContact' | translate)"
                      [required]="true"
                      [(value)]="vip.leaveContacts"></app-property-input>

  <!-- 行程联系人电话 -->
  <app-property-input [title]="'TripContactPhone' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('TripContactPhone' | translate)"
                      [required]="true"
                      [(value)]="vip.leaveContactDetails"></app-property-input>

</div>
<!-- 接机备注 -->
<app-property-input [title]="'Remark' | translate"
                    [placeholder]="('PleaseEnter' | translate) + ('Remark' | translate)"
                    [(value)]="vip.receivingRemark"></app-property-input>
<div style="height: 15px"></div>

<!-- 是否安排专人服务 -->
<app-property-yes-or-not *ngIf="isShow"
                         [title]="'Accompany' | translate"
                         [disabled]="disabled"
                         [bottomLineVisible]="vip.oneToOne"
                         [(value)]="vip.oneToOne"></app-property-yes-or-not>

<div *ngIf="vip.oneToOne">

  <div class="section-title" style="margin-top: 15px">
    <span
      style="color: #555555; font-weight: bold; font-size: 14px; margin: 5px 5px 5px 5px">{{ 'Trip1' | translate}}</span>
  </div>

  <!-- 行程1专人服务时间 -->
  <app-property-datetime [title]="'ServiceHoursOfTrip1' | translate"
                         [formatter]="'yyyy-MM-dd HH:mm'"
                         [disabled]="disabled"
                         [showTime]="{nzFormat: 'HH:mm'}"
                         [(value)]="vip.accompanyTime"></app-property-datetime>
  <!-- 司机姓名 -->
  <app-property-input [title]="'DriverName' | translate"
                      [disabled]="disabled"
                      [placeholder]="('PleaseEnter' | translate) + ('DriverName' | translate)"
                      [(value)]="vip.receivingDriverName"></app-property-input>
  <!-- 联系方式 -->
  <app-property-input [title]="'ContactInformation1' | translate"
                      [disabled]="disabled"
                      [placeholder]="('PleaseEnter' | translate) + ('ContactInformation1' | translate)"
                      [(value)]="vip.contactDetails"></app-property-input>
  <!-- 联系方式2 -->
  <app-property-input [title]="'ContactInformation2' | translate"
                      [disabled]="disabled"
                      [placeholder]="('PleaseEnter' | translate) + ('ContactInformation2' | translate)"
                      [(value)]="vip.receivingContactDetails2"></app-property-input>
  <!-- 车牌号 -->
  <app-property-input [title]="'LicensePlateNumber' | translate"
                      [disabled]="disabled"
                      [placeholder]="('PleaseEnter' | translate) + ('LicensePlateNumber' | translate)"
                      [(value)]="vip.receivingLicensePlateNumber"></app-property-input>
  <!-- 车身颜色 -->
  <app-property-input [title]="'CarBodyColor' | translate"
                      [disabled]="disabled"
                      [placeholder]="('PleaseEnter' | translate) + ('CarBodyColor' | translate)"
                      [(value)]="vip.receivingBodyColor"></app-property-input>


  <div *ngIf="vip.transportDemand === 'ROUND_TRIP'">
    <div class="section-title" style="margin-top: 15px">
      <span
        style="color: #555555; font-weight: bold; font-size: 14px; margin: 5px 5px 5px 5px">{{ 'Trip2' | translate}}</span>
    </div>

    <!-- 行程2专人服务时间 -->
    <app-property-datetime [title]="'ServiceHoursOfTrip2' | translate"
                           [formatter]="'yyyy-MM-dd HH:mm'"
                           [showTime]="{nzFormat: 'HH:mm'}"
                           [disabled]="disabled"
                           [(value)]="vip.sendAccompanyTime"></app-property-datetime>
    <!-- 司机姓名 -->
    <app-property-input [title]="'DriverName' | translate"
                        [disabled]="disabled"
                        [placeholder]="('PleaseEnter' | translate) + ('DriverName' | translate)"
                        [(value)]="vip.sendDriverName"></app-property-input>
    <!-- 联系方式 -->
    <app-property-input [title]="'ContactInformation1' | translate"
                        [disabled]="disabled"
                        [placeholder]="('PleaseEnter' | translate) + ('ContactInformation1' | translate)"
                        [(value)]="vip.sendContactDetails"></app-property-input>
    <!-- 联系方式2 -->
    <app-property-input [title]="'ContactInformation2' | translate"
                        [disabled]="disabled"
                        [placeholder]="('PleaseEnter' | translate) + ('ContactInformation2' | translate)"
                        [(value)]="vip.sendContactDetails2"></app-property-input>
    <!-- 车牌号 -->
    <app-property-input [title]="'LicensePlateNumber' | translate"
                        [disabled]="disabled"
                        [placeholder]="('PleaseEnter' | translate) + ('LicensePlateNumber' | translate)"
                        [(value)]="vip.sendLicensePlateNumber"></app-property-input>
    <!-- 车身颜色 -->
    <app-property-input [title]="'CarBodyColor' | translate"
                        [disabled]="disabled"
                        [placeholder]="('PleaseEnter' | translate) + ('CarBodyColor' | translate)"
                        [bottomLineVisible]="false"
                        [(value)]="vip.sendBodyColor"></app-property-input>

  </div>
<!--   送机备注-->
      <app-property-input [title]="'Remark' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('Remark' | translate)"
                          [disabled]="disabled"
                          [bottomLineVisible]="false"
                          [(value)]="vip.sendRemark"></app-property-input>
</div>
