import { Component, OnInit } from '@angular/core';
import {Tree} from '../contact-types';
import {NzFormatEmitEvent, NzTreeNode} from 'ng-zorro-antd/tree';
import {NzContextMenuService, NzDropdownMenuComponent} from 'ng-zorro-antd/dropdown';
import { Instance } from '../../api/types';
import { MetadataService } from '../../shared/service/metadata.service';

@Component({
  selector: 'app-contacts-tree',
  templateUrl: './contacts-tree.component.html',
  styleUrls: ['./contacts-tree.component.less']
})
export class ContactsTreeComponent implements OnInit {

  treeData: Tree;
  activatedNode?: NzTreeNode;
  nodes = [];
  isShow = false;
  instance: Instance;
  constructor(private nzContextMenuService: NzContextMenuService,
              private metadataService: MetadataService) {
  }

  ngOnInit(): void {
    this.instance = this.metadataService.mockInstance;
    this.nodes.push(this.treeData);
  }

  activeNode(data: NzFormatEmitEvent): void {
    this.activatedNode = data?.node;
  }

  contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent): void {
    this.nzContextMenuService.create($event, menu);
  }

  selectDropdown(): void {
    // do something
  }
  onEdit(): void {
    this.isShow = !this.isShow;
  }

}
