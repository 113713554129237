import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Phone } from '../../../api/types';
import * as _ from 'lodash';
import { MetadataService } from '../../service/metadata.service';
import { plainToInstance } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { RegionSelectionComponent } from '../region-selection/region-selection.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-phone-edit',
  templateUrl: './phone-edit.component.html',
  styleUrls: ['./phone-edit.component.less']
})
export class PhoneEditComponent implements OnInit {

  title: string;
  phone: Phone;
  showType = false;
  requiredPhone = false;

  // 快捷电话
  quickPhones: Phone[] = [];

  areaCodes: string[] = ['86', '852', '853', '886'];


  @Output()
  phoneSaved: EventEmitter<Phone> = new EventEmitter<Phone>();

  constructor(private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    const areaCodes = this.metadataService.values('areaCode');
    this.areaCodes = areaCodes.map(code => code.key);
  }

  onQuickPhone(phone: Phone): void {
    this.phone = plainToInstance(Phone, phone);
  }

  canNotSave(): boolean {
    return !this.phone || !this.phone.areaCode || !this.phone.phone;
  }

  onReset(): void {
    this.phone = new Phone();
  }

  onSave(): void {
    if (this.canNotSave()) {
      return;
    }
    if (this.requiredPhone && this.canNotPhone()) {
      return;
    }
    this.phoneSaved.emit(this.phone);
  }

  onAreaCode(areaCode: string): void {
    this.phone.areaCode = areaCode;
  }

  canNotPhone(): boolean {
    const hkPhone = /^\d{8}$/;
    const chPhone = /^\d{11}$/;
    let falg = false;
    if (this.phone.areaCode === '852' && !hkPhone.test(this.phone.phone)) {
      falg = true;
      this.translate.get('ErrorPhoneTis')
        .subscribe(
          res => {
            this.toastr.warning(res);
          });
    } else if (this.phone.areaCode === '86' && !chPhone.test(this.phone.phone)) {
      falg = true;
      this.translate.get('ErrorPhoneTis')
        .subscribe(
          res => {
            this.toastr.warning(res);
          });
    }
    return falg;
  }

  onCountry(): void {
    const drawerRef = this.drawerService.create<RegionSelectionComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: RegionSelectionComponent,
      nzContentParams: {
      },
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.regionSelected.subscribe(
        data => {
          this.phone.areaCode = data.areaCode;
          drawerRef.close();
        }
      );
    });
  }
}
