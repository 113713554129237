import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CreateFolder} from '../document-types';
import {DocumentService} from '../document.service';
import {subscribeOn} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-document-create',
  templateUrl: './document-create.component.html',
  styleUrls: ['./document-create.component.less']
})
export class DocumentCreateComponent implements OnInit {

  createFolder: CreateFolder = new CreateFolder();
  loading = false;
  @Input()
  id: number;

  @Output()
  createFolderRes: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private documentService: DocumentService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  create(): void {
    if (!this.canReply) {
      return;
    }
    if (!this.id) {
      this.id = 0;
    }
    this.createFolder.leader = this.id;
    this.loading = true;
    this.documentService.create(this.createFolder)
      .subscribe(data => {
        this.loading = false;
        if (data) {
          this.translate.get('CreateSuccess').subscribe(
            (res) => {
              this.toastr.success(res);
            }
          );
        }
        this.createFolderRes.emit(data);
      }, error => {
        this.loading = false;
      });
  }

  get canReply(): boolean {
    return !!this.createFolder.name;
  }

}
