import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TicketSearch} from '../../api/types';
import { MetadataService } from '../../shared/service/metadata.service';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';

@Component({
  selector: 'app-ticket-search',
  templateUrl: './ticket-search.component.html',
  styleUrls: ['./ticket-search.component.less']
})
export class TicketSearchComponent implements OnInit {

  @Input()
  search: TicketSearch = new TicketSearch();

  statusOptions: PropertySelectOption[] = [];

  @Output()
  ticketSearch: EventEmitter<TicketSearch> = new EventEmitter<TicketSearch>();

  constructor(private metadataService: MetadataService) { }

  ngOnInit(): void {
    const statusList = this.metadataService.values('ticketStatus');
    for (const status of statusList) {
      this.statusOptions.push(new PropertySelectOption(status.value, status.key));
    }
  }

  onReset(): void {
    this.search = new TicketSearch();
    this.ticketSearch.emit(this.search);
  }

  onSearch(): void {
    this.ticketSearch.emit(this.search);
  }

}
