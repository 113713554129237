import { Component, Input, OnInit } from '@angular/core';
import {
  FamilyMemberProperties, FinancialData,
  Fna,
  FnaFormUpdateReq,
  FnaModuleAndAnswer,
  FnaQuestion,
  FnaQuestionNoAndResult,
  FnaQuestionResult,
  FnaQuestionType
} from '../fna-types';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { FnaService } from '../fna.service';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { WorkbenchService } from '../../workbench/workbench.service';
import { MetadataService } from '../../shared/service/metadata.service';
import { Instance } from '../../api/types';

@Component({
  selector: 'app-fna-form',
  templateUrl: './fna-form.component.html',
  styleUrls: ['./fna-form.component.less']
})
export class FnaFormComponent implements OnInit {

  drawerRef: NzDrawerRef<FnaFormComponent, string>;

  loading = false;

  fna: Fna = new Fna();

  @Input()
  fnaQuestions: FnaQuestion[] = [];

  fnaQuestionMap = new Map<string, FnaQuestion>();

  employmentStatusOptions: PropertySelectOption[] = [];
  instance: Instance;

  constructor(private workbenchService: WorkbenchService,
              private metadataService: MetadataService,
              private fnaService: FnaService) {
  }


  ngOnInit(): void {
    this.instance = this.metadataService.mockInstance;
    const employmentStatus = this.metadataService.values('employmentStatus');
    this.employmentStatusOptions = [];
    for (const employment of employmentStatus) {
      this.employmentStatusOptions.push(new PropertySelectOption(employment.value, employment.key));
    }

    this.loading = true;
    this.fnaService.questions(this.fna.id, FnaQuestionType.FNA)
      .subscribe(
        data => {
          this.loading = false;
          const newQuestions: FnaQuestion[] = [];
          for (const fnaQuestion of data) {
            newQuestions.push(fnaQuestion);
            if (fnaQuestion.answers) {
              for (const answer of fnaQuestion.answers) {
                if (answer.tick && answer.subQuestions) {
                  newQuestions.push(...answer.subQuestions);
                }
              }
            }
          }
          this.fnaQuestions = newQuestions;
          for (const fnaQuestion of this.fnaQuestions) {
            this.fnaQuestionMap.set(fnaQuestion.questionNo, fnaQuestion);
          }
        },
        error => {
          this.loading = false;
        }
      );

    if (!this.fna.personalData.accountName) {
      this.fna.personalData.accountName = this.fnaService.passport.name;
    }
    if (!this.fna.personalData.registrationNumber) {
      this.fna.personalData.registrationNumber = this.fnaService.passport.registerNumber;
    }
    if (!this.fna.personalData.number) {
      this.fna.personalData.number = this.fnaService.passport.consultantNumber;
    }
    if (!this.fna.personalData.customerName) {
      this.fna.personalData.customerName = this.fna.customerShot?.pinyin;
    }

  }

  onTemporarySave(): void {
    this.loading = true;
    const req = new FnaFormUpdateReq();
    req.id = this.fna.id;
    req.personalData = this.fna.personalData;
    req.existingSecurity = this.fna.existingSecurity;
    req.familyMembers = this.fna.familyMembers;
    req.financialData = this.fna.financialData;
    req.assets = this.fna.assets;
    req.financialAnalysis = FnaQuestionNoAndResult.getQuestionNoAndResults(this.fnaQuestions);
    this.fnaService.updateFormTemporary(req)
      .subscribe(
        data => {
          this.loading = false;
          this.drawerRef?.close();
        },
        error => {
          this.loading = false;
        }
      );
  }

  // prop<T extends object, K extends keyof T>(obj: T, key: K): any {
  //   return obj[key];
  // }

  onSave(): void {
    this.loading = true;
    const req = new FnaFormUpdateReq();
    // let key: keyof FinancialData;
    // for ( key in this.fna.financialData) {
    //   if (key) {
    //     console.log(this.prop(this.fna.financialData, key));
    //   }
    // }
    Object.keys(this.fna.financialData).forEach((x: any) => {
      if (this.fna.financialData[x] === null || this.fna.financialData[x] === undefined) {
        this.fna.financialData[x] = 0;
      }
    });
    Object.keys(this.fna.assets).forEach((x) => {
      if (this.fna.assets[x] === null || this.fna.assets[x] === undefined) {
        this.fna.assets[x] = 0;
      }
    });

    req.id = this.fna.id;
    req.personalData = this.fna.personalData;
    req.existingSecurity = this.fna.existingSecurity;
    req.familyMembers = this.fna.familyMembers;
    req.financialData = this.fna.financialData;
    req.assets = this.fna.assets;
    req.financialAnalysis = FnaQuestionNoAndResult.getQuestionNoAndResults(this.fnaQuestions);

    this.fnaService.updateForm(req)
      .subscribe(
        data => {
          this.loading = false;
          this.drawerRef?.close();
        },
        error => {
          this.loading = false;
        }
      );
  }

  onAddFamilyMemberChild(): void {
    this.fna.familyMembers.children.push(new FamilyMemberProperties());
  }

  onRemoveFamilyMemberChild(i: number): void {
    this.fna.familyMembers.children.splice(i, 1);
  }

  onIncomesChange(): void {
    this.fna.financialData.monthlyIncome =
      (this.fna.financialData.salary ?? 0) +
      (this.fna.financialData.rentalIncome ?? 0) +
      (this.fna.financialData.otherIncome ?? 0);

    this.fna.financialData.monthRemaining =
      (this.fna.financialData.monthlyIncome ?? 0) -
      (this.fna.financialData.monthlySpending ?? 0);
  }

  onExpendituresChange(): void {
    this.fna.financialData.monthlySpending =
      (this.fna.financialData.personalSpending ?? 0) +
      (this.fna.financialData.householdSpending ?? 0) +
      (this.fna.financialData.mortgage ?? 0) +
      (this.fna.financialData.insurance ?? 0) +
      (this.fna.financialData.otherSpending ?? 0);

    this.fna.financialData.monthRemaining =
      (this.fna.financialData.monthlyIncome ?? 0) -
      (this.fna.financialData.monthlySpending ?? 0);
  }

  onCurrentAssetsChange(): void {
    this.fna.assets.totalCurrentAssets =
      (this.fna.assets.cash ?? 0) +
      (this.fna.assets.investmentAssets ?? 0) +
      (this.fna.assets.otherCurrentAssets ?? 0);

    this.updateNetAssets();
  }

  onNonCurrentAssetsChange(): void {
    this.fna.assets.totalNonCurrentAssets =
      (this.fna.assets.property ?? 0) +
      (this.fna.assets.otherNonCurrentAssets ?? 0);

    this.updateNetAssets();
  }

  onLiabilitiesChange(): void {
    this.fna.assets.totalLoans =
      (this.fna.assets.mortgageLoans ?? 0) +
      (this.fna.assets.privateLoans ?? 0) +
      (this.fna.assets.otherLoans ?? 0);

    this.updateNetAssets();
  }

  updateNetAssets(): void {
    this.fna.assets.netAsset =
      (this.fna.assets.totalCurrentAssets ?? 0) +
      (this.fna.assets.totalNonCurrentAssets ?? 0) -
      (this.fna.assets.totalLoans ?? 0);
  }

  onAnswerRadio(fnaQuestion: FnaQuestion, answerIndex: number): void {
    for (let i = 0; i < fnaQuestion.answers.length; i++) {
      fnaQuestion.answers[i].tick = answerIndex === i;
    }
  }

  get questionNoAndResult(): FnaQuestionNoAndResult[] {
    const questionNoAndResults: FnaQuestionNoAndResult[] = [];
    for (const fnaQuestion of this.fnaQuestions) {
      const questionNoAndResult = new FnaQuestionNoAndResult();
      const results: { [key: string]: FnaQuestionResult } = {};
      for (const answer of fnaQuestion.answers) {
        const questionResult = new FnaQuestionResult();
        const inputBoxValue: { [key: string]: string } = {};
        if (answer.inputBox) {
          for (const box of answer.inputBox) {
            inputBoxValue[box.field] = box.value;
          }
        }
        questionResult.inputBoxValue = inputBoxValue;
        questionResult.tick = !!answer.tick;
        results[answer.key] = questionResult;
      }
      questionNoAndResult.questionNo = fnaQuestion.questionNo;
      questionNoAndResult.results = results;
      questionNoAndResults.push(questionNoAndResult);
    }
    return questionNoAndResults;
  }

  onSubQuestionEvent(answer: FnaModuleAndAnswer): void {
    for (const subQuestion of answer.subQuestions) {
      if (answer.tick) {
        this.fnaQuestionMap.set(subQuestion.questionNo, subQuestion);
      } else {
        this.fnaQuestionMap.delete(subQuestion.questionNo);
      }
    }
    this.fnaQuestions = [...this.fnaQuestionMap.values()];
    this.fnaQuestions = this.fnaQuestions.sort((v1: FnaQuestion, v2: FnaQuestion) => {
      return Number(v1.questionNo) > Number(v2.questionNo) ? 1 : -1;
    });
  }

  onFnaQuestionChange(fnaQuestion: FnaQuestion, i: number): void {
    this.fnaQuestions[i] = fnaQuestion;
  }

  // AP =（收入（每月总平均收入）-支出（每月总平均支出））* 12 / 2
  get AP(): number {
    return ((this.fna.financialData.monthRemaining ?? 0) * 6);
  }

  // SP = 流动资产总额 / 2
  get SP(): number {
    return ((this.fna.assets.totalCurrentAssets ?? 0) / 2);
  }

  get canSave(): boolean {
    return this.fna.formCanSave;
  }

}
