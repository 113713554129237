<div style="padding: 15px">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin:20px 0 40px 20px;">
      {{ instance.isFone() ? ('MatterRecord' | translate) : ('PendingRecord' | translate)}}
    </div>
    <div class="flex-row-space" style="margin: 0 15px 0">
      <div [ngSwitch]="pendingDetail.underwritingStatus">
        <div>
          <div *ngSwitchCase="'UNDERWRITING'" class="text-danger">
            ● <span style="font-size: 16px;font-weight:bold">  {{ pendingDetail.status }}  </span>
          </div>
          <div *ngSwitchCase="'CHECKING'" class="text-processing">
            ● <span style="font-size: 16px;font-weight:bold">  {{ pendingDetail.status }}  </span>
          </div>
        </div>
      </div>
      <div
        style="color: #999999;font-size: 12px">{{'Deadline' | translate}} {{pendingDetail.deadlines | date:'yyyy-MM-dd' }}</div>
    </div>
    <div class="status-detail" appThrottleClick (throttleClick)="openDetail()">
      <div style="padding: 10px 0" class="flex-row">
        <div style="flex: 1">
          <div style="font-weight: bold; font-size: 16px">{{ instance.isFone() ? ('ChangeProject' | translate) : 'Pending' }}{{ ':' + pendingDetail.project}}</div>
          <div style="color: #999999;font-size: 12px">{{instance.isFone() ? ('DeliveryDate' | translate) : ('PendingDate' | translate)}} {{pendingDetail.deliveryDate | date:'yyyy-MM-dd'}}</div>
          <div *ngIf="pendingDetail.remark">
            <span>{{instance.isFone() ? ('TodoContent' | translate) : ('PendingContent' | translate)}} : </span>
            <span [innerHTML]="pendingDetail.remark | html"></span>
          </div>
        </div>
        <div>
          <i nz-icon nzType="right" nzTheme="outline"></i>
        </div>
      </div>
    </div>
    <div class="status-detail" *ngIf="replies && replies.length > 0">
      <div class="status-detail-context">
        <div *ngFor="let reply of replies" class="list-item">
          <div class="list-top">
            <nz-avatar class="list-top-avatar" style="margin-right: 20px;" [nzSize]="36"
                       [nzSrc]="reply.avatar"></nz-avatar>
            <div  class="list-top-right">
              <div style="margin-right: 10px;">
                <span class="list-top-name" style="margin-right: 10px;">{{ reply.accountName }}</span>
                <span class="list-top-myReply"
                      *ngIf="reply.accountName === accountName"
                      style="color: #4B84E5; font-size: 12px; border: 1px solid #4B84E5; border-radius: 6px">{{ 'Oneself' | translate }}</span>
              </div>
              <div class="list-top-name" style="margin-right: 10px;">{{ reply.time | date:'yyyy-MM-dd HH:mm:ss' }}</div>
            </div>
          </div>
          <div class="list-bottom">
            <div class="ticket-reply-content" [innerHTML]="reply.content | html"></div>
            <app-attachments *ngIf="reply.attachments?.length > 0"
                             [attachments]="reply.attachments"
                             [readonly]="true"></app-attachments>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="instance.isFoneOrDev() ? !pendingDetail.ultimate: true">
      <div style="width: 100%;">
        <div class="section-wrapper" style="margin: 15px 15px 10px 15px">
<!--          <app-property-input-area [bottomLineVisible]="false"-->
<!--                                   [required]="true"-->
<!--                                   [title]="'TodoContent' | translate"-->
<!--                                   [placeholder]="('PleaseEnter' | translate) + ('TodoContent' | translate)"-->
<!--                                   [(value)]="replyReq.content"></app-property-input-area>-->
          <app-property-editor [bottomLineVisible]="false"
                               [required]="true"
                               [title]="(instance.isFone() ? ('TodoContent' | translate) : ('PendingContent' | translate))"
                               [placeholder]="('PleaseEnter' | translate) + (instance.isFone() ? ('TodoContent' | translate) : ('PendingContent' | translate))"
                               [(value)]="replyReq.content"></app-property-editor>
        </div>
        <div style="margin: 15px 15px 10px 15px">
          <app-attachments [attachments]="replyReq.attachments"
                           [uploadTokenReq]="uploadTokenReq"
                           [isPreview]="true"
                           (valueChange)="attachmentsChange($event)"
                           [showEmpty]="false"></app-attachments>
        </div>
        <div style="margin: 15px 15px 10px 15px">
          <div nz-col nzSpan="24" class="footer-button-primary">
            <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="reply()"
               [disabled]="!canReply"
               [style]="!canReply ? 'opacity: 0.5' : ''">
              <span>{{ 'Reply' | translate }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nz-spin>

  <!-- 事项详情 -->
  <nz-drawer [(nzVisible)]="detailVisible" [nzWidth]="600" (nzOnClose)="closeDetail()">
    <ng-container *nzDrawerContent>
      <div class="common-title" style="margin:20px 0 40px 20px;">
        {{ instance.isFone() ? ('MatterDetail' | translate) : ('PendingDetail' | translate)}}
      </div>
      <div class="section-title" style="margin:10px 15px 10px 15px">
        <span style="margin-left: 8px"> {{ 'IDNumber' | translate }}: {{pendingDetail.pendingNo}} </span>
      </div>
      <div class="status-detail">
        <div class="status-detail-context">
          <nz-row [nzGutter]="12">
            <nz-col [nzSpan]="24">
              <app-property-text [title]="'Insurer' | translate"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.companyName"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="'Insured' | translate"
                                 [displayMode]="'vertical'"
                                 [isEncryption]="isDynamicEncryption('insuredName')"
                                 [value]="pendingDetail.insuredName"></app-property-text>
            </nz-col>

            <nz-col [nzSpan]="12">
              <app-property-text [title]="'Holder' | translate"
                                 [displayMode]="'vertical'"
                                 [isEncryption]="isDynamicEncryption('ownerName')"
                                 [value]="pendingDetail.ownerName"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="'Status' | translate"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.status"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="instance.isFone() ? ('DeliveryDate' | translate) : ('PendingDate' | translate)"
                                 [displayMode]="'vertical'"
                                 [value]="(pendingDetail.deliveryDate | date: 'yyyy-MM-dd') || 'N/A'"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="'ArchiveDate' | translate"
                                 [displayMode]="'vertical'"
                                 [value]="(pendingDetail.archiveDate | date: 'yyyy-MM-dd') || 'N/A'"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="'ExpirationDate' | translate"
                                 [displayMode]="'vertical'"
                                 [value]="(pendingDetail.deadlines | date: 'yyyy-MM-dd') || 'N/A'"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="'CaseCloseDate' | translate"
                                 [displayMode]="'vertical'"
                                 [value]="(pendingDetail.finishDate | date: 'yyyy-MM-dd') || 'N/A' "></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="instance.isFone() ? ('ChangeProject' | translate) : 'Pending'"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.project"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="'CreateUserName' | translate"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.organizer"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="'Administrator' | translate"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.receiver"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="24">
              <app-property-text [title]="(instance.isFone() ? ('TodoContent' | translate) : ('PendingContent' | translate))"
                                 [textType]="'html'"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.remark"></app-property-text>
            </nz-col>
          </nz-row>
        </div>
      </div>
      <div class="section-title" style="margin:10px 15px 10px 15px"
           *ngIf="pendingDetail.attachments && pendingDetail.attachments.length">
        <span style="margin-left: 8px"> {{ 'File' | translate }}</span>
      </div>

      <div style="padding:0 15px 15px;">
        <app-attachments [attachments]="pendingDetail.attachments" [readonly]="true"
                         *ngIf="pendingDetail.attachments && pendingDetail.attachments.length">
        </app-attachments>
      </div>

    </ng-container>
  </nz-drawer>
</div>
