import {Injectable} from '@angular/core';
import {BaseService} from '../base/base.service';
import {HttpService} from '../core/http.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ApiResponse, SumRangeResp} from '../api/types';
import {API} from '../api/api';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {Premium, PremiumResp, ProductSelection, SumRange} from './quotation-types';
import {urlToHttpOptions} from 'http';

@Injectable({
  providedIn: 'root'
})
export class QuotationService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  /**
   * 获取产品
   */
  productSelection(): Observable<ProductSelection> {
    return this.http.get<ApiResponse<ProductSelection>>(API.quotationV3 + '/view', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 获取年龄范围 试算保额范围
   * @param code // 产品码
   */
  quotationsGetValue(code: SumRange, type: string): Observable<SumRangeResp> {
    let httpParams = new HttpParams();
    if (code.productCode != null) {
      httpParams = httpParams.set('productCode', code.productCode.toString());
    }
    if (code.currency != null) {
      httpParams = httpParams.set('currency', code.currency.toString());
    }
    let uri = '';
    switch (type) {
      case 'Age':
        uri = '/age-range';
        break;
      case 'Currency':
        uri = '/sum-assured-range';
        break;
    }
    return this.http.get<ApiResponse<SumRangeResp>>(API.quotationV3 + uri, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 保费试算
   * @param parameter 参数
   */
  trial(parameter: Premium): Observable<PremiumResp> {
    return this.http.post<ApiResponse<PremiumResp>>(API.quotationV3, parameter, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
