<div>
  <div *ngIf="fnaQuestion.questionNo === 'FNA_01'" class="from-info-card">
    <div class="form-card-title question-title"
         [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'question-required-after' : ''">
      {{'question1' | translate}}
    </div>
    <div class="form-card-check-box" style="justify-content: space-between;">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[0].tick"
             [disabled]="isDisabled" style="margin-left: 16px;">
        <div class="check-text-label">
          {{'CMGQuestion2' | translate}}
        </div>
      </label>
      <div class="display-flex">
        <div class="check-text-label" style="margin-right: 4px;">{{'CMGQuestion61' | translate}} :</div>
        <div class="col-box" style="width: 220px;">
<!--          <div class="group-box">-->
<!--            <nz-input-number class="group-box-input-number"-->
<!--                             [nzParser]="parserValue"-->
<!--                             [nzFormatter]="formatterValue"-->
<!--                             [nzPlaceHolder]="'PleaseEnter' | translate"-->
<!--                             [(ngModel)]="fnaQuestion.answers[0].inputBox[0].value">-->
<!--            </nz-input-number>-->
<!--            <span class="suffix-text">HK$</span>-->
<!--          </div>-->

          <div class="value-box additional-input-select">
            <nz-input-number class="group-box-input-number additional-orInput"
                             [nzSize]="'small'" [disabled]="isDisabled"
                             [nzPlaceHolder]="'PleaseEnter' | translate"
                             [nzParser]="parserValue"
                             [nzFormatter]="formatterValue"
                             [(ngModel)]="fnaQuestion.answers[0].inputBox[0].value">
            </nz-input-number>
            <nz-select [(ngModel)]="fnaQuestion.answers[0].inputBox[1].value"
                       [nzSize]="'small'" [disabled]="isDisabled"
                       nzAllowClear class="group-box-input-select additional-orSelect">
              <nz-option *ngFor="let option of currencyOptions"
                         [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>

    <div class="form-card-check-box" style="justify-content: space-between;">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[1].tick" [disabled]="isDisabled"
             style="margin-left: 16px;">
        <div class="check-text-label">
          {{'CMGQuestion3' | translate}}
        </div>
      </label>
      <div class="display-flex">
        <div class="check-text-label" style="margin-right: 4px;">{{'CMGQuestion62' | translate}} :</div>
        <div class="col-box" style="width: 220px">
<!--          <div class="group-box">-->
<!--            <nz-input-number class="group-box-input-number"-->
<!--                             [nzParser]="parserValue"-->
<!--                             [nzFormatter]="formatterValue"-->
<!--                             [nzPlaceHolder]="'PleaseEnter' | translate"-->
<!--                             [(ngModel)]="fnaQuestion.answers[1].inputBox[0].value">-->
<!--            </nz-input-number>-->
<!--            <span class="suffix-text">HK$</span>-->
<!--          </div>-->
          <div class="value-box additional-input-select">
            <nz-input-number class="group-box-input-number additional-orInput"
                             [nzSize]="'small'" [disabled]="isDisabled"
                             [nzPlaceHolder]="'PleaseEnter' | translate"
                             [nzParser]="parserValue"
                             [nzFormatter]="formatterValue"
                             [(ngModel)]="fnaQuestion.answers[1].inputBox[0].value">
            </nz-input-number>
            <nz-select [(ngModel)]="fnaQuestion.answers[1].inputBox[1].value"
                       [nzSize]="'small'" [disabled]="isDisabled"
                       nzAllowClear class="group-box-input-select additional-orSelect">
              <nz-option *ngFor="let option of currencyOptions"
                         [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>

    <div class="form-card-check-box">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[1].subQuestions[0].tick" [disabled]="isDisabled"
             style="margin-left: 54px;">
        <div class="check-text-label">
          {{'CMGQuestion3_1' | translate}}
        </div>
      </label>
    </div>

    <div class="form-card-check-box">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[1].subQuestions[1].tick" [disabled]="isDisabled"
             style="margin-left: 54px;">
        <div class="check-text-label">
          {{'CMGQuestion3_2' | translate}}
        </div>
      </label>
    </div>

    <div class="form-card-check-box">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[1].subQuestions[2].tick" [disabled]="isDisabled"
             style="margin-left: 54px;">
        <div class="check-text-label">
          {{'CMGQuestion3_4' | translate}}
        </div>
      </label>
    </div>

    <div class="form-card-check-box">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[2].tick" [disabled]="isDisabled"
             style="margin-left: 16px;">
        <div class="check-text-label">
          {{'CMGQuestion4' | translate}}
        </div>
      </label>
    </div>

    <div class="form-card-check-box">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[3].tick" [disabled]="isDisabled"
             style="margin-left: 16px;">
        <div class="check-text-label">
          {{'CMGQuestion5' | translate}}
        </div>
      </label>
    </div>

    <div class="form-card-check-box">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[4].tick" [disabled]="isDisabled"
             style="margin-left: 16px;" (ngModelChange)="fnaQuestion1e(fnaQuestion.answers[4])">
        <div class="check-text-label">
          {{'CMGQuestion6' | translate}}
        </div>
      </label>
    </div>

    <div class="form-card-check-box" style="margin-bottom: 0;">
      <label nz-checkbox [(ngModel)]="fnaQuestion.answers[5].tick" [disabled]="isDisabled"
             style="margin-left: 16px;">
        <div class="check-text-label">
          {{'CMGQuestion7' | translate}}
        </div>
      </label>
    </div>

    <div class="" style="padding-left: 54px;">
      <div nz-row [nzGutter]="[0, 10]">
        <div nz-col nzSpan="8">
          <div class="form-card-check-box" style="margin-bottom: 0;">
            <label nz-checkbox [disabled]="isDisabled"
                   [(ngModel)]="fnaQuestion.answers[5].subQuestions[0].tick">
              <div class="check-text-label">
                {{'ZUU01Fa' | translate}}
              </div>
            </label>
          </div>
        </div>

        <div nz-col nzSpan="8">
          <div class="form-card-check-box" style="margin-bottom: 0;">
            <label nz-checkbox [disabled]="isDisabled"
                   [(ngModel)]="fnaQuestion.answers[5].subQuestions[1].tick">
              <div class="check-text-label">
                {{'ZUU01Fb' | translate}}
              </div>
            </label>
          </div>
        </div>

        <div nz-col nzSpan="8">
          <div class="form-card-check-box" style="margin-bottom: 0;">
            <label nz-checkbox [disabled]="isDisabled"
                   [(ngModel)]="fnaQuestion.answers[5].subQuestions[2].tick">
              <div class="check-text-label">
                {{'ZUU01Fc' | translate}}
              </div>
            </label>
          </div>
        </div>

        <div nz-col nzSpan="8" class="display-flex">
          <div class="form-card-check-box" style="margin-bottom: 0;">
            <label nz-checkbox [disabled]="isDisabled"
                   [(ngModel)]="fnaQuestion.answers[5].subQuestions[3].tick">
              <div class="check-text-label">
                {{'ZUU01Fd' | translate}}
              </div>
            </label>
          </div>
        </div>

        <div nz-col nzSpan="16">
          <div class="form-card-check-box" style="margin-bottom: 0;">
            <label nz-checkbox [disabled]="isDisabled"
                   [(ngModel)]="fnaQuestion.answers[5].subQuestions[4].tick">
              <div class="check-text-label">
                {{'ZUU01Fe' | translate}}
              </div>
            </label>
            <div class="col-box">
            <textarea nz-input class="value-box-input" [placeholder]="'PleaseSpecify' | translate"
                    style="max-width: 620px;" [disabled]="isDisabled"
                    [(ngModel)]="fnaQuestion.answers[5].inputBox[0].value"
                    [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
              <!--        <input nz-input class="value-box-input"-->
              <!--               style="width: 320px;"-->
              <!--               [placeholder]="'CMGQuestion8' | translate"-->
              <!--               [(ngModel)]="fnaQuestion.answers[5].inputBox[0].value" />-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fnaQuestion.questionNo === 'FNA_02'" class="from-info-card">
    <div class="form-card-title question-title">
      <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'question-required-after' : ''">
        {{'CMGQuestion9' | translate}}
      </span>
    </div>
    <nz-checkbox-wrapper class="form-card-radio-box"
                         style="width: 100%;">
      <div nz-row>
        <div nz-col nzSpan="24">
          <label nz-checkbox [nzValue]="fnaQuestion.answers[0].key"
                 [ngModel]="fnaQuestion.answers[0].tick"
                 [disabled]="isDisabled ? isDisabled : isCMGQuestion6"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[0].key)">
            <div class="check-text-label" style="margin-bottom: 10px;">
              {{'CMGQuestion10' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="24">
          <label nz-checkbox [nzValue]="fnaQuestion.answers[1].key"
                 [ngModel]="fnaQuestion.answers[1].tick"
                 [disabled]="isDisabled ? isDisabled : isCMGQuestion6"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[1].key)"
                 style="margin-bottom: 10px;">
            <div class="check-text-label">
              {{'CMGQuestion11' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="24">
          <label nz-checkbox [nzValue]="fnaQuestion.answers[2].key"
                 [disabled]="isDisabled ? isDisabled : isCMGQuestion6"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[2].key)"
                 [ngModel]="fnaQuestion.answers[2].tick">
            <div class="check-text-label">
              {{'CMGQuestion12' | translate}}
            </div>
          </label>
        </div>
      </div>
    </nz-checkbox-wrapper>
  </div>

  <div *ngIf="fnaQuestion.questionNo === 'FNA_03'" class="from-info-card">
    <div class="form-card-title question-title"
         [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'question-required-after' : ''">
      {{'CMGQuestion13' | translate}}
    </div>

    <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
      <div nz-row>
        <div nz-col nzSpan="4">
          <label nz-checkbox
                 [nzValue]="fnaQuestion.answers[0].key"
                 [ngModel]="fnaQuestion.answers[0].tick" [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[0].key)">
            <div class="check-text-label" style="margin-bottom: 10px;">
              {{'CMGQuestion14' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="4">
          <label nz-checkbox
                 [nzValue]="fnaQuestion.answers[1].key"
                 [ngModel]="fnaQuestion.answers[1].tick" [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[1].key)"
                 style="margin-bottom: 10px;">
            <div class="check-text-label">
              {{'CMGQuestion15' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="4">
          <label nz-checkbox
                 [nzValue]="fnaQuestion.answers[2].key"
                 [ngModel]="fnaQuestion.answers[2].tick" [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[2].key)">
            <div class="check-text-label">
              {{'CMGQuestion16' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="4">
          <label nz-checkbox
                 [nzValue]="fnaQuestion.answers[3].key"
                 [ngModel]="fnaQuestion.answers[3].tick" [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[3].key)">
            <div class="check-text-label">
              {{'CMGQuestion17' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="4">
          <label nz-checkbox
                 [nzValue]="fnaQuestion.answers[4].key"
                 [ngModel]="fnaQuestion.answers[4].tick" [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[4].key)">
            <div class="check-text-label">
              {{'CMGQuestion18' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="4">
          <label nz-checkbox
                 [nzValue]="fnaQuestion.answers[5].key"
                 [ngModel]="fnaQuestion.answers[5].tick" [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[5].key)">
            <div class="check-text-label">
              {{'CMGQuestion19' | translate}}
            </div>
          </label>
        </div>
        <div nz-col nzSpan="4">
          <label nz-checkbox
                 [nzValue]="fnaQuestion.answers[6].key"
                 [ngModel]="fnaQuestion.answers[6].tick" [disabled]="isDisabled"
                 (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[6].key)">
            <div class="check-text-label">
              {{'CMGQuestion20' | translate}}
            </div>
          </label>
        </div>
      </div>
    </nz-checkbox-wrapper>
  </div>

  <div *ngIf="fnaQuestion.questionNo === 'FNA_04'" class="from-info-card">
    <div class="form-card-title question-title">
      <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'question-required-after' : ''">
        {{'ZUUQuestion21' | translate}}
      </span>
    </div>
    <div class="form-card-check-box" style="">
      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
        <div nz-row [nzGutter]="[10, 10]">
          <div nz-col nzSpan="8" class="display-flex">
            <label nz-checkbox
                   [nzValue]="fnaQuestion.answers[0].key"
                   [ngModel]="fnaQuestion.answers[0].tick" [disabled]="isDisabled"
                   (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[0].key)"
                   style="margin-left: 0;">
              <div class="check-text-label">
                {{'CMGQuestion22' | translate}}:
              </div>
            </label>
            <div class="col-box" style="width: 250px;">
              <div class="value-box additional-input-select">
                <nz-input-number class="group-box-input-number additional-orInput"
                                 [nzSize]="'small'" [disabled]="isDisabled"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="fnaQuestion.answers[0].inputBox[2].value">
                </nz-input-number>
                <nz-select [(ngModel)]="fnaQuestion.answers[0].inputBox[3].value"
                           [nzSize]="'small'" [disabled]="isDisabled"
                           nzAllowClear class="group-box-input-select additional-orSelect">
                  <nz-option *ngFor="let option of currencyOptions"
                             [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                </nz-select>
              </div>
            </div>
          </div>
          <div nz-col nzSpan="8" class="display-flex">
            <label nz-checkbox
                   [nzValue]="fnaQuestion.answers[1].key"
                   [ngModel]="fnaQuestion.answers[1].tick" [disabled]="isDisabled"
                   (ngModelChange)="FNA02log($event, fnaQuestion, fnaQuestion.questionNo, fnaQuestion.answers[1].key)"
                   style="margin-left: 0;">
              <div class="check-text-label">
                {{'CMGQuestion23' | translate}}:
              </div>
            </label>
            <div class="col-box" style="width: 250px;">
              <div class="value-box additional-input-select">
                <nz-input-number class="group-box-input-number additional-orInput"
                                 [nzSize]="'small'" [disabled]="isDisabled"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="fnaQuestion.answers[1].inputBox[2].value">
                </nz-input-number>
                <nz-select [(ngModel)]="fnaQuestion.answers[1].inputBox[3].value"
                           [nzSize]="'small'" [disabled]="isDisabled"
                           nzAllowClear class="group-box-input-select additional-orSelect">
                  <nz-option *ngFor="let option of currencyOptions"
                             [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                </nz-select>
              </div>
            </div>
          </div>
          <div nz-col nzSpan="24">
            <div>{{ 'ZUUQuestion4' |  translate}}</div>
            <div class="display-flex" style="margin-top: 6px;">
              <div class="col-box" style="flex: none;">
                <input nz-input class="value-box-input"
                       style="width: 130px;"
                       [nzSize]="'small'" [disabled]="isDisabled"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="fnaQuestion.answers[2].inputBox[0].value" />
              </div>
              <div class="check-text-label" style="margin-left: 8px;">{{'Year' | translate}}</div>
            </div>
          </div>
        </div>
      </nz-checkbox-wrapper>
    </div>

  </div>

  <div *ngIf="fnaQuestion.questionNo === 'FNA_05'" class="from-info-card">
    <div class="form-card-title question-title">{{'CMGQuestion27' | translate}}</div>
    <div style="padding-left: 16px">
      <div class="question-title-span question-title" style="font-weight: bold;">
        {{'CMGQuestion28' | translate}}
      </div>
      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
        <div nz-row style="margin-bottom: 10px;">
          <div nz-col nzSpan="24" class="display-flex" style="margin-bottom: 10px;">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[0].answers[0].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[0].subKey, fnaQuestion.answers[0].subQuestions[0].answers[0].key)"
            >
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion29' | translate}}
              </div>
            </label>
            <div class="col-box" style="margin-left: 4px;display: flex;align-items: center;justify-content: center;">
              <div class="group-box" style="width: 220px;flex: none;"
                   [style.background-color]="isDisabled ? '#FAFBFD' : ''">
                <nz-input-number class="group-box-input-number"
                                 [nzParser]="parserValue" [disabled]="isDisabled"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="fnaQuestion.answers[0].subQuestions[0].answers[0].inputBox[0].value">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
              <div class="check-text-label check-text-label-span" style="flex: 1;margin-left: 10px;">
                {{'CMGQuestion63' | translate}}
              </div>
            </div>
          </div>
          <div nz-col nzSpan="5">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[0].answers[1].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[0].subKey, fnaQuestion.answers[0].subQuestions[0].answers[1].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion31' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="5">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[0].answers[2].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[0].subKey, fnaQuestion.answers[0].subQuestions[0].answers[2].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion32' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="5">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[0].answers[3].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[0].subKey, fnaQuestion.answers[0].subQuestions[0].answers[3].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion33' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="5">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[0].answers[4].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[0].subKey, fnaQuestion.answers[0].subQuestions[0].answers[4].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion34' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[0].answers[5].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[0].subKey, fnaQuestion.answers[0].subQuestions[0].answers[5].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion35' | translate}}
              </div>
            </label>
          </div>
        </div>
      </nz-checkbox-wrapper>

      <div class="question-title-span question-title" style="font-weight: bold;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'question-required-after' : ''">
          {{'CMGQuestion36' | translate}}
        </span>
      </div>
      <div class="display-flex" style="margin-left: 16px;margin-bottom: 10px;">
        <div class="check-text-label" style="margin-right: 6px;">{{'CMGQuestion37' | translate}}：</div>
        <div class="col-box">
          <div class="group-box" style="width: 220px;"
               [style.background-color]="isDisabled ? '#FAFBFD' : ''">
            <nz-input-number class="group-box-input-number"
                             [disabled]="isDisabled"
                             [nzParser]="parserValue"
                             [nzFormatter]="formatterValue"
                             [(ngModel)]="fnaQuestion.answers[0].subQuestions[1].answers[0].inputBox[0].value">
            </nz-input-number>
            <span class="suffix-text">HK$</span>
          </div>
        </div>
      </div>

      <div class="question-title-span question-title" style="font-weight: bold;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'question-required-after' : ''">
          {{'ZUUQuestion47' | translate}}
        </span>
      </div>

      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
        <div nz-row style="margin-bottom: 10px;">
          <div nz-col nzSpan="4" style="margin-bottom: 10px;">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[2].answers[0].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[2].subKey, fnaQuestion.answers[0].subQuestions[2].answers[0].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion48' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[2].answers[1].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[2].subKey, fnaQuestion.answers[0].subQuestions[2].answers[1].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion49' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[2].answers[2].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[2].subKey, fnaQuestion.answers[0].subQuestions[2].answers[2].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion50' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[2].answers[3].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[2].subKey, fnaQuestion.answers[0].subQuestions[2].answers[3].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion51' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[2].answers[4].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[2].subKey, fnaQuestion.answers[0].subQuestions[2].answers[4].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion52' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[2].answers[5].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[2].subKey, fnaQuestion.answers[0].subQuestions[2].answers[5].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion53' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="24" class="display-flex">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[2].answers[6].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[2].subKey, fnaQuestion.answers[0].subQuestions[2].answers[6].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion54' | translate}}
              </div>
            </label>
            <div class="col-box" style="margin-left: 10px;">
              <div class="group-box" style="width: 220px;"
                   [style.background-color]="isDisabled ? '#FAFBFD' : ''">
                <nz-input-number class="group-box-input-number"
                                 [disabled]="isDisabled"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="fnaQuestion.answers[0].subQuestions[2].answers[6].inputBox[0].value">
                </nz-input-number>
                <span class="suffix-text">HK$</span>
              </div>
            </div>
          </div>
        </div>
      </nz-checkbox-wrapper>

      <div class="question-title-span question-title" style="font-weight: bold;">
        {{'ZUUQuestion55' | translate}}
      </div>
      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
        <div nz-row style="display: flex;align-items: center;">
          <div nz-col nzSpan="12">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[3].answers[0].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[3].subKey, fnaQuestion.answers[0].subQuestions[3].answers[0].key)">
              <div class="check-text-label check-text-label-span">
                {{'CMGQuestion56' | translate}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="12" class="display-flex">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[3].answers[1].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[3].subKey, fnaQuestion.answers[0].subQuestions[3].answers[1].key)">
              <div class="check-text-label">
                {{'answer36' | translate}}
              </div>
            </label>
            <div class="col-box">
              <textarea nz-input class="value-box-input" [placeholder]="'CMGQuestion8' | translate"
                        style="max-width: 620px;" [disabled]="isDisabled"
                        [(ngModel)]="fnaQuestion.answers[0].subQuestions[3].answers[1].inputBox[0].value"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
<!--              <input nz-input class="value-box-input"-->
<!--                     style="width: 320px;"-->
<!--                     [placeholder]="'CMGQuestion8' | translate"-->
<!--                     [(ngModel)]="fnaQuestion.answers[0].subQuestions[5].answers[1].inputBox[0].value" />-->
            </div>
          </div>
        </div>
      </nz-checkbox-wrapper>

      <div class="question-title-span question-title"
           style="font-weight: bold;margin-top: 10px;">
        <span [class]="fnaInfo.fnaBusinessType === 'LIFE' ? 'question-required-after' : ''">
          {{'ZUUFna164' | translate}}
        </span>
      </div>
      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
        <div nz-row style="margin-bottom: 10px;">
          <div nz-col nzSpan="4" style="margin-bottom: 10px;">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[4].answers[0].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[4].subKey, fnaQuestion.answers[0].subQuestions[4].answers[0].key)">
              <div class="check-text-label check-text-label-span">
                {{'≤ 10%'}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[4].answers[1].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[4].subKey, fnaQuestion.answers[0].subQuestions[4].answers[1].key)">
              <div class="check-text-label check-text-label-span">
                {{'11% - 20%'}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[4].answers[2].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[4].subKey, fnaQuestion.answers[0].subQuestions[4].answers[2].key)">
              <div class="check-text-label check-text-label-span">
                {{'21% - 40%'}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[4].answers[3].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[4].subKey, fnaQuestion.answers[0].subQuestions[4].answers[3].key)">
              <div class="check-text-label check-text-label-span">
                {{'41% - 60%'}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[4].answers[4].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[4].subKey, fnaQuestion.answers[0].subQuestions[4].answers[4].key)">
              <div class="check-text-label check-text-label-span">
                {{'61% - 80%'}}
              </div>
            </label>
          </div>

          <div nz-col nzSpan="4">
            <label nz-checkbox [disabled]="isDisabled"
                   [ngModel]="fnaQuestion.answers[0].subQuestions[4].answers[5].tick"
                   (ngModelChange)="FNA05log($event, fnaQuestion, fnaQuestion.answers[0].subQuestions[4].subKey, fnaQuestion.answers[0].subQuestions[4].answers[5].key)">
              <div class="check-text-label check-text-label-span">
                {{'81% - 100%'}}
              </div>
            </label>
          </div>
        </div>
      </nz-checkbox-wrapper>
    </div>
  </div>

</div>

