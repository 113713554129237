import { EventEmitter, Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpService } from '../core/http.service';
import { Observable } from 'rxjs/internal/Observable';
import { ApiResponse, DialogReply, Message, PagedResp, PendingReply } from '../api/types';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { plainToInstance } from 'class-transformer';
import {
  AccountList,
  BankInfoSetting, BankInfoSearch,
  DiscountInfoSetting, HedgeParams, HedgeRes,
  Proposal,
  ProposalBalance, ProposalBalanceLimit,
  ProposalListResp,
  ProposalSearch, DiscountInfoSearch
} from './proposal-types';
import { lowerFirst } from 'lodash';
import { ProductListResp } from '../product/product-types';

@Injectable({
  providedIn: 'root'
})
export class ProposalService extends BaseService {

  messageReceived: EventEmitter<Message> = new EventEmitter<Message>();
  infoTransmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpService) {
    super();
  }

  /**
   * 计划书列表
   * @param search 查询条件
   * @param isSubordinate 是否查询我的下线数据
   */
  list(search: ProposalSearch, isSubordinate: boolean): Observable<PagedResp<ProposalListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status.toString());
    }
    if (search.proposalId != null) {
      httpParams = httpParams.set('proposalId', search.proposalId.toString());
    }
    if (search.productCode != null) {
      httpParams = httpParams.set('productCode', search.productCode.toString());
    }
    if (search.companyCode != null) {
      httpParams = httpParams.set('companyCode', search.companyCode.toString());
    }
    if (search.categoryCode != null) {
      httpParams = httpParams.set('categoryCode', search.categoryCode.toString());
    }
    if (search.accountName != null) {
      httpParams = httpParams.set('accountName', search.accountName.toString());
    }
    if (search.age != null) {
      httpParams = httpParams.set('age', search.age.toString());
    }
    if (search.gender != null) {
      httpParams = httpParams.set('gender', search.gender.toString());
    }
    if (search.smoke != null) {
      httpParams = httpParams.set('smoke', search.smoke.toString());
    }
    if (search.applicationTime != null) {
      httpParams = httpParams.set('applicationTime', search.applicationTime.toString());
    }
    if (search.startApplicationTime != null) {
      httpParams = httpParams.set('startApplicationTime', search.startApplicationTime.toString());
    }
    if (search.endApplicationTime != null) {
      httpParams = httpParams.set('endApplicationTime', search.endApplicationTime.toString());
    }
    const uri = isSubordinate ? '/list-subordinate' : '/list-mine';
    return this.http.get<ApiResponse<PagedResp<ProposalListResp>>>(API.proposalV3 + uri, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      // 转换为具体的类（包括函数）
      const resp = plainToInstance(PagedResp, data.body.data) as PagedResp<ProposalListResp>;
      for (let i = 0; i < resp.list.length; i++) {
        resp.list[i] = plainToInstance(ProposalListResp, resp.list[i]);
      }
      return resp;
    }));
  }

  /**
   * 计划书申请
   * @param applyReq 申请参数
   */
  apply(applyReq: Proposal): Observable<string> {
    return this.http.post<ApiResponse<string>>(API.proposalV4 + '/apply', applyReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 查询今日计划书额度使用情况
   */
  balance(): Observable<ProposalBalance> {
    return this.http.get<ApiResponse<ProposalBalance>>(API.proposalV4 + '/balance', {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(ProposalBalance, data.body.data);
    }));
  }

  /**
   * 查询计划书额度限制
   */
  balanceLimit(): Observable<ProposalBalanceLimit[]> {
    return this.http.get<ApiResponse<ProposalBalanceLimit[]>>(API.proposalV4 + '/limit', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 計劃書詳情
   * @param proposalId 計劃書編號
   */
  info(proposalId: string): Observable<Proposal> {
    return this.http.get<ApiResponse<Proposal>>(API.proposalV3 + '/info/' + proposalId, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(Proposal, data.body.data);
    }));
  }

  /**
   * 取消申請
   * @param proposalId 計劃書編號
   */
  cancel(proposalId: string): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.proposalV3 + '/cancel/' + proposalId, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 重试
   * @param proposalId 計劃書編號
   */
  retry(proposalId: string): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.proposalV3 + '/retry/' + proposalId, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 计划书留言列表
   * @param proposalId 计划书编号
   */
  message(proposalId: string): Observable<PendingReply[]> {
    return this.http.get<ApiResponse<PendingReply[]>>(API.proposalV3 + '/dialog-list/' + proposalId, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(PendingReply, data.body.data);
    }));
  }

  /**
   * 计划书留言回复
   * @param param 回复内容
   */
  reply(param: DialogReply): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.proposalV3 + '/dialog-save', param, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 删除计划书
   * @param proposalId 计划书ID
   */
  delete(proposalId: string): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.proposalV3 + '/delete/' + proposalId, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 获取保费融资银行信息
   * @param search 搜索
   */
  allBankInfo(search: BankInfoSearch): Observable<BankInfoSetting[]> {
    let httpParams = new HttpParams();
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    return this.http.get<ApiResponse<BankInfoSetting[]>>(API.proposalV3 + '/bank/all', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 获取保费融资银行信息
   * @param search 搜索
   */
  allDiscountInfo(search: DiscountInfoSearch): Observable<DiscountInfoSetting[]> {
    let httpParams = new HttpParams();
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    return this.http.get<ApiResponse<DiscountInfoSetting[]>>(API.proposalV3 + '/discount/all', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新保费融资信息
   * @param putReq 参数 计划书编号&&保费融资
   */
  putFinancing(putReq): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.proposalV3 + '/update/financing', putReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 复用计划书产品详情
   * @param code 产品码
   */
  getMultiplex(code): Observable<any> {
    return this.http.get<ApiResponse<any>>(API.proposalV3 + `/multiplex/${code}`, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 获取計劃書账号列表
   */
  proposalAccount(): Observable<AccountList> {
    return this.http.get<ApiResponse<AccountList>>(API.proposalV3 + '/account/list', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 添加計劃書账号
   * @param putReq 参数
   */
  createProposalAccount(putReq): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.proposalV3 + '/account', putReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 删除计划书账号
   * @param accountId 账号ID
   */
  deleteProposalAccount(accountId: string): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.proposalV3 + '/account/' + accountId, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新计划书账号
   * @param putReq 計劃書編號
   */
  putProposalAccount(putReq): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.proposalV3 + '/account', putReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 禁用账号
   * @param accountId 計劃書編號
   * @param flag 判断禁用 || 启用
   */
  isDisableProposalAccount(accountId: string, flag: boolean): Observable<boolean> {
    const ulr = flag ? `/account/enable/${accountId}` : `/account/disable/${accountId}`;
    console.log(ulr);
    return this.http.put<ApiResponse<boolean>>(API.proposalV3 + ulr, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 查询附加险列表
   * @param riders 附加险 || 多个
   */
  getRidersList(riders: any): Observable<ProductListResp[]> {
    let httpParams = new HttpParams();
    if (riders.riderProductCodes != null) {
      httpParams = httpParams.set('riderProductCodes', riders.riderProductCodes.toString());
    }
    return this.http.get<ApiResponse<ProductListResp[]>>(API.proposalV3 + '/riders', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 查询对冲产品列表
   * @param hedge 产品码s
   */
  getHedgeList(hedge: any): Observable<ProductListResp[]> {
    let httpParams = new HttpParams();
    if (hedge.productCodes != null) {
      httpParams = httpParams.set('productCodes', hedge.productCodes.toString());
    }
    return this.http.get<ApiResponse<ProductListResp[]>>(API.proposalV3 + '/hedge-products', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 对冲方案预览
   * @param hedgeReq 参数
   */
  viewHedge(hedgeReq: HedgeParams): Observable<HedgeRes> {
    return this.http.post<ApiResponse<HedgeRes>>(API.proposalV3 + '/hedge', hedgeReq, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 发送计划书验证码
   * @param params 申请参数 id code
   */
  sendCode(params): Observable<string> {
    return this.http.post<ApiResponse<string>>(API.proposalV3 + '/account/send-code', params, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
