<!--<div class="pin-box">-->

<!--</div>-->
<nz-modal [(nzVisible)]="isVisible"
          [nzTitle]="tableHeader"
          [nzFooter]="tableFooter"
          [nzWidth]="400"
          [nzCentered]="true"
          [nzMaskClosable]="false"
          (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <ng-template #tableHeader>
    <div *ngIf="!isPhone" class="modal-header">
      <div>
        <span *ngIf="mode === 'verify'">{{'PinCodeVerification' | translate}}</span>
        <span *ngIf="mode === 'edit'">{{'PinCodeUpdate' | translate}}</span>
        <span *ngIf="mode === 'setUp'">{{'PinCodeSetting' | translate}}</span>
      </div>
    </div>
    <div *ngIf="isPhone" class="modal-header">
      <span>{{'PinCodeUpdate' | translate}}</span>
    </div>
  </ng-template>
  <ng-template #tableFooter>
    <div *ngIf="!isPhone" class="modal-footer">
      <div *ngIf="mode === 'verify'">
        <button nz-button nzType="default" nz-button
                appThrottleClick (throttleClick)="handleCancel()"
        >{{'Cancel' | translate}}</button>
        <button style="margin-left: 8px" nz-button
                appThrottleClick (throttleClick)="handleOk()"
                nz-button nzType="primary">{{'Confirm' | translate}}</button>
      </div>
      <div *ngIf="mode === 'edit' && !again">
        <button style="margin-left: 8px" nz-button
                appThrottleClick (throttleClick)="backPhone()"
                nz-button nzType="default">{{'Back' | translate}}</button>

        <button style="margin-left: 8px" nz-button
                appThrottleClick (throttleClick)="editPinCode()"
                nz-button nzType="primary">{{'Next' | translate}}</button>
      </div>
      <div *ngIf="mode === 'edit' && again">
        <button style="margin-left: 8px" nz-button
                appThrottleClick (throttleClick)="backEditPinFirst()"
                nz-button nzType="default">{{'Back' | translate}}</button>

        <button style="margin-left: 8px" nz-button
                appThrottleClick (throttleClick)="editPinCode()"
                nz-button nzType="primary">{{'Confirm' | translate}}</button>
      </div>
      <div *ngIf="mode === 'setUp' && !again">
        <!--        <button style="margin-left: 8px" nz-button-->
        <!--                appThrottleClick (throttleClick)="backVerify()"-->
        <!--                nz-button nzType="default">{{'Back' | translate}}</button>-->

        <button style="margin-left: 8px" nz-button
                appThrottleClick (throttleClick)="setUpPinCode()"
                nz-button nzType="primary">{{'Next' | translate}}</button>
      </div>
      <div *ngIf="mode === 'setUp' && again">
        <button style="margin-left: 8px" nz-button
                appThrottleClick (throttleClick)="backSetUp()"
                nz-button nzType="default">{{'Back' | translate}}</button>

        <button style="margin-left: 8px" nz-button
                appThrottleClick (throttleClick)="setUpPinCode()"
                nz-button nzType="primary">{{'Confirm' | translate}}</button>
      </div>
    </div>
    <div *ngIf="isPhone" class="modal-footer">
      <button nz-button nzType="default" nz-button
              appThrottleClick (throttleClick)="phoneBack()"
      >{{'Back' | translate}}</button>
      <button style="margin-left: 8px" nz-button
              [disabled]="!(areaCode && phone && code)"
              appThrottleClick (throttleClick)="nextVerifyCode()"
              nz-button nzType="primary">{{'Next' | translate}}</button>
    </div>
  </ng-template>
  <ng-container *nzModalContent>
    <div *ngIf="!isPhone" class="modal-box">
      <div class="modal-body">
        <div style="font-weight: 600;color: #404040;font-size: 16px;">
          <span *ngIf="mode === 'verify'">{{'PINCreateTips' | translate}}</span>
          <span *ngIf="mode === 'edit'">{{'SettingPinCode' | translate}}</span>
          <span *ngIf="mode === 'setUp'">{{'SettingPinCode' | translate}}</span>
        </div>
        <div style="color: #6A6A6A;font-size: 13px;">
          <span *ngIf="mode === 'verify'">{{'EnterPIN' | translate}}</span>
          <span *ngIf="mode === 'edit' && !again">{{'EnterPIN' | translate}}</span>
          <span *ngIf="mode === 'edit' && again">{{'ConfirmPin' | translate}}</span>
          <span *ngIf="mode === 'setUp' && !again">{{'SetPinCode' | translate}}</span>
          <span *ngIf="mode === 'setUp' && again">{{'ConfirmPin' | translate}}</span>
        </div>
        <div class="code-box">
          <input nz-input [(ngModel)]="input1" #codeInput1 type="password"
                 autocomplete="new-password" maxlength="1" class="code-item"
                 onkeyup="this.value=this.value.replace(/\D/g,'')"
                 (input)="onChange($event)" (keyup)="delDown($event, 0)"/>
          <input nz-input [(ngModel)]="input2" #codeInput2 type="password"
                 autocomplete="new-password" maxlength="1" class="code-item"
                 onkeyup="this.value=this.value.replace(/\D/g,'')"
                 (input)="onChange1($event)" (keyup)="delDown($event, 1)"/>
          <input nz-input [(ngModel)]="input3" #codeInput3 type="password"
                 autocomplete="new-password" maxlength="1" class="code-item"
                 onkeyup="this.value=this.value.replace(/\D/g,'')"
                 (input)="onChange2($event)" (keyup)="delDown($event, 2)"/>
          <input nz-input [(ngModel)]="input4" #codeInput4 type="password"
                 autocomplete="new-password" maxlength="1" class="code-item"
                 onkeyup="this.value=this.value.replace(/\D/g,'')"
                 (input)="onChange3($event )" (keyup)="delDown($event, 3)"/>
        </div>
        <div *ngIf="mode === 'verify'" class="tips-text">
        <span style="cursor: pointer"
              appThrottleClick (throttleClick)="editPin()">{{'ForgotPIN' | translate}}</span>
        </div>
      </div>
    </div>

    <div *ngIf="isPhone" class="modal-box">
      <div class="modal-body">
        <div style="color: #6A6A6A;font-size: 13px;">
          <span>{{'PleaseEnter' | translate}}</span>
          <span>{{'BindPhoneNumber' | translate}}</span>
        </div>
        <div class="phone-code-box">
          <div>
            <div class="input-group" style="display: flex;align-items: center;">
              <div class="phone-select">
                <nz-select nzShowSearch nzAllowClear
                           [nzPlaceHolder]="'AreaCode' | translate"
                           [(ngModel)]="areaCode"
                           [nzDropdownStyle]="{ zIndex: '9999999' }"
                           style="min-width: 80px;"
                           [nzDropdownMatchSelectWidth]="false"
                           [nzFilterOption]="selectFilterOption">
                  <nz-option *ngFor="let areaCodeItem of contactsAreaCodeArr"
                             [nzCustomContent]="true"
                             [nzLabel]="'+' + areaCodeItem.areaCode" [nzValue]="areaCodeItem.areaCode + '+' + areaCodeItem.name">
                    {{areaCodeItem.name}} <span style="float: right;margin-left: 20px">+{{areaCodeItem.areaCode}}</span>
                  </nz-option>
                </nz-select>
              </div>
              <input [(ngModel)]="phone" id="'phoneNumber'"
                     name="phoneNumber"
                     [placeholder]="('PleaseEnter' | translate) + ('PhoneNumber' | translate)"
                     class="login-input" style="text-align: left;margin-bottom: 10px;" />

            </div>
            <div style="margin-top: 10px;">
              <nz-input-group class="input-group-code">
                <input required [(ngModel)]="code" id="code" name="code"
                       style="width: 60%;"
                       [placeholder]="('PleaseEnter' | translate) + ('VerificationCode' | translate)" class="login-input-change"/>
                <div [class]="isOk ? 'code-button-disabled' : 'code-button'" appThrottleClick (throttleClick)="onSend()">
                  {{isOk ? timeVal : ('GetVerificationCode' | translate)}}
                </div>
              </nz-input-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
