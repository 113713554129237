import { Injectable } from '@angular/core';
import { HttpService } from '../../core/http.service';
import { BaseService } from '../../base/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { ApiResponse, Country, CountryGroups, MaterialType } from '../../api/types';
import { API } from '../../api/api';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  countryGroups(): Observable<CountryGroups> {
    return this.http.get<ApiResponse<CountryGroups>>(API.commonV3 + '/country/group', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  countryAll(name: string): Observable<Country[]> {
    let httpParams = new HttpParams();
    if (name != null) {
      httpParams = httpParams.set('name', name);
    }
    return this.http.get<ApiResponse<Country[]>>(API.commonV3 + '/country/all', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  getMaterialType(): Observable<MaterialType[]> {
    return this.http.get<ApiResponse<MaterialType[]>>(API.commonV3 + '/material/type', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

}
