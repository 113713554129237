import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Country, CountryGroups } from '../../api/types';
import { GroupCountries } from '../../shared/component/region-selection/region-selection.component';
import { MetadataService } from '../../shared/service/metadata.service';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';
import { CommonService } from '../../shared/service/common.service';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-filter-popover',
  templateUrl: './filter-popover.component.html',
  styleUrls: ['./filter-popover.component.less']
})
export class FilterPopoverComponent extends BaseComponent implements OnInit {
  filterPopoverVisible = false;
  countryGroups: CountryGroups;
  setOfGroup = new Set<string>();
  groupCountries: GroupCountries[];
  countries: Country[];
  loading = false;
  filterSearchName: string;
  @Input()
  nationality: string;
  @Input()
  isBorder = true;
  @Input()
  isDisabled: boolean;
  @Input()
  modeType = 'String';
  searchParamChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  nationalitySelected: EventEmitter<Country | null> = new EventEmitter<Country | null>();

  constructor(private metadataService: MetadataService,
              private commonService: CommonService) {
    super();
  }

  ngOnInit(): void {
    this.loadCountries();

    this.searchParamChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          this.onFilterSearch();
        }
      );
  }

  onSearch(name: string): void {
    this.searchParamChange.emit('');
  }

  loadCountries(): void {
    this.loading = true;
    this.commonService.countryGroups()
      .subscribe(
        data => {
          this.loading = false;
          this.countryGroups = data;
          if (this.countryGroups && this.countryGroups.groupCountries) {
            this.setOfGroup.clear();
            this.setOfGroup.add('☆');
            for (const groupCountry of this.countryGroups.groupCountries) {
              this.setOfGroup.add(groupCountry.group);
            }
            this.groupCountries = _.chain(this.countryGroups.groupCountries)
              // Group the elements of Array based on `color` property
              .groupBy('group')
              // `key` is group's name, `value` is the array of objects
              .map((value, key) => ({ group: key, list: value }))
              .value();
          }
        },
        error => {
          this.loading = false;
        })
      .disposedBy(this.disposeBag);
  }

  nationalityString(value: string): string {
    if (!value) {
      return '';
    }
    const nationality = this.metadataService.translate('countries', value);
    return nationality;
  }

  onFilterSearch(): void {
    this.loading = true;
    this.commonService.countryAll(this.filterSearchName)
      .subscribe(
        data => {
          this.loading = false;
          this.countries = data;
        },
        error => {
          this.loading = false;
        })
      .disposedBy(this.disposeBag);
  }


  onRegionClick(region: Country): void {
    console.log(region);
    this.nationalitySelected.emit(region);

    this.filterPopoverVisible = false;
  }

  onClearClick(): void {
    this.nationalitySelected.emit(null);
    this.filterPopoverVisible = false;
  }

  closeChange(e): void {
    if (!e) {
      this.filterSearchName = undefined;
    }
  }
}
