import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'yearConversion'
})
export class YearsConversionPipe implements PipeTransform {
  constructor(private translate: TranslateService) {
  }

  transform(text: string, ...args: unknown[]): unknown {
    let value = null;
    if (!text) {
      return value;
    }
    if (text.includes('~')) {
      this.translate.get('ProductAge', {condition: text.replace('~', '')}).subscribe(
        (res: any) => {
          value = res;
        });
    }
    if (text.includes('Y')) {
      this.translate.get('ProductYears', {condition: text.replace('Y', '')}).subscribe(
        (res: any) => {
          value = res;
        });
    }
    if (text.includes('M')) {
      this.translate.get('ProductMonth', {condition: text.replace('M', '')}).subscribe(
        (res: any) => {
          value = res;
        });
    }
    return value;
  }

}
