import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './product.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ProductMainComponent } from './product-main.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { ProductsComponent } from './products/products.component';
import { CompaniesComponent } from './companies/companies.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductAttributesComponent } from './product-attributes/product-attributes.component';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { ProductHighlightsComponent } from './product-highlights/product-highlights.component';
import { ProductNewsComponent } from './product-news/product-news.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { CreditRatingComponent } from './components/credit-rating/credit-rating.component';
import { ProductComparisonComponent } from './product-comparison/product-comparison.component';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { ProductComparisonResultComponent } from './product-comparison-result/product-comparison-result.component';
import {NzAffixModule} from "ng-zorro-antd/affix";
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { ProductComparisonDetailComponent } from './product-comparison-detail/product-comparison-detail.component';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';



@NgModule({
    declarations: [
        ProductComponent,
        ProductMainComponent,
        ProductsComponent,
        CompaniesComponent,
        ProductDetailComponent,
        ProductAttributesComponent,
        ProductHighlightsComponent,
        ProductNewsComponent,
        CompanyDetailComponent,
        CreditRatingComponent,
        ProductComparisonComponent,
        ProductComparisonResultComponent,
        ProductComparisonDetailComponent
    ],
    exports: [
    ],
    imports: [
        CommonModule,
        NzLayoutModule,
        RouterModule,
        TranslateModule,
        NzTabsModule,
        NzSpinModule,
        NzIconModule,
        NzSkeletonModule,
        NzTableModule,
        NzGridModule,
        NzToolTipModule,
        NzButtonModule,
        NzBadgeModule,
        NzPopoverModule,
        NzEmptyModule,
        NzTagModule,
        NzStepsModule,
        SharedModule,
        NzAffixModule,
        NzDropDownModule,
        NzRateModule,
        NzModalModule,
        FormsModule,
        NzCheckboxModule,
        NzDrawerModule
    ]
})
export class ProductModule { }
