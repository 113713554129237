<nz-tabset [nzSize]="'large'" >
  <nz-tab [nzTitle]=myDeals>
    <app-ticket-table [mode]="dataService.dataModeOwn"></app-ticket-table>
  </nz-tab>
  <ng-template #myDeals>
    {{'MyDeals' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=channelDeals *ngIf="workbench.pivot !== dataService.pivotOwn">
    <app-ticket-table [mode]="dataService.dataModeSubordinate"></app-ticket-table>
  </nz-tab>
  <ng-template #channelDeals>
    {{'ChannelDeals' | translate}}
  </ng-template>
</nz-tabset>
