import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationStrategy } from '@angular/common';
import { ErrorService } from './shared/service/error.service';

/**
 * 统一的错误处理
 */
@Injectable()
export default class AppErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) {
        // The true paramter tells Angular to rethrow exceptions, so operations like 'bootstrap' will result in an error
        // when an error happens. If we do not rethrow, bootstrap will always succeed.

    }

    handleError(error: Error | HttpErrorResponse): void {

        console.error(error);
        // send the error to the server
        // 将错误反馈到后端
        const errorService = this.injector.get(ErrorService);
        const location = this.injector.get(LocationStrategy);
        errorService.report(error, location);

        // throw error;
    }

}
