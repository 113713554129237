import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.less']
})
export class ProgressBarComponent implements OnInit {

  @Input() progress: number;
  @Input() color: string;
  @Input() degraded: any;
  @Input() showPercentage = true;
  @Input() disabledPercentage: boolean;
  @Input() text: string;
  @Input() width = '120px';
  @Input() borderRadius = '20px';

  constructor() {
    // Default color
    this.color = '#1D64C4';
    this.disabledPercentage = false;
  }

  ngOnInit(): void {
  }

  /**
   * Returns a color for a certain percent
   * @param percent The current progress
   */
  whichColor(percent: number): void {
    // Get all entries index as an array
    let k: Array<any> = Object.keys(this.degraded);
    // Convert string to number
    k.forEach((e, i) => k[i] = +e);
    // Sort them by value
    k = k.sort((a, b) => a - b);
    // Percent as number
    const p = +percent;
    // Set last by default as the first occurrence
    let last = k[0];
    // Foreach keys
    for (const val of k) {
      // if current val is < than percent
      if (val < p) {
        last = val;
      }
      // if val >= percent then the val that we could show has been reached
      else if (val >= p - 1) {
        return this.degraded[last];
      }
    }
    // if its the last one return the last
    return this.degraded[last];
  }

  whichProgress(progress: number): number {
    try {
      return Math.round(+progress * 100) / 100;
    } catch {
      return progress;
    }
  }

}
