import { Component, ElementRef, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.less']
})
export class ReadMoreComponent implements OnInit, OnChanges {

  @Input() text: string;
  @Input() maxLength = 100;
  currentText: string;
  hideToggle = true;

  public isCollapsed = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleView(): void {
    this.isCollapsed = !this.isCollapsed;
    this.determineView();
  }

  determineView(): void {
    if (!this.text || this.text.length <= this.maxLength) {
      this.currentText = this.text;
      this.isCollapsed = false;
      this.hideToggle = true;
      return;
    }
    this.hideToggle = false;
    if (this.isCollapsed === true) {
      this.currentText = this.text.substring(0, this.maxLength) + '...';
    } else if (this.isCollapsed === false) {
      this.currentText = this.text;
    }

  }

  ngOnChanges(): void {
    this.determineView();
  }

}
