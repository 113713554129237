<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{knowledge.title}}
    </div>

    <div *ngFor="let tag of knowledge.tags; let i = index">
      <span class="tag-outline" style="font-size: 12px; padding: 2px;">{{ tag }}</span>
    </div>

    <div class="flex-row" style="padding: 5px 0 5px 0; margin-top: 5px">
      <img alt="" width="16" src="assets/images/ic_knowledge_author.svg">
      <span style="margin-left: 5px">{{knowledge.author}}</span>
    </div>

    <div class="flex-row" style="padding: 5px 0 5px 0">
      <img alt="" width="16" src="assets/images/ic_knowledge_time.svg">
      <span style="margin-left: 5px">{{knowledge.issueDate | date:'yyyy-MM-dd'}}</span>
      <img  style="margin-left: 5px" alt="" width="16" src="assets/images/ic_knowledge_visits.svg">
      <span style="margin-left: 5px">{{knowledge.visits}}</span>
    </div>

    <div [innerHTML]="knowledge.content | html"></div>

  </nz-spin>
</div>
