import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Beneficiary, PagedResp} from '../../api/types';
import {CreateProposalCompare, ProposalCompare, ProposalList, SelectProposalList} from '../proposal-compare';
import {ProposalCompareService} from '../proposal-compare.service';

export interface Data {
  id: number;
  name: string;
  age: number;
  address: string;
  disabled: boolean;
}

@Component({
  selector: 'app-proposal-compare-selected',
  templateUrl: './proposal-compare-selected.component.html',
  styleUrls: ['./proposal-compare-selected.component.less']
})

export class ProposalCompareSelectedComponent implements OnInit {
  @Output()
  proposalCompareSaved: EventEmitter<ProposalList[]> = new EventEmitter<ProposalList[]>();
  @Output()
  proposals: EventEmitter<any> = new EventEmitter<any>();
  loading = false;
  listArr: PagedResp<ProposalList>;
  listOfCurrentPageData: ProposalList[] = [];
  proposalList: ProposalList[] = [];
  createProposalCompare: CreateProposalCompare;
  search: SelectProposalList = new SelectProposalList();
  setOfCheckedId = new Set<string>();
  checked = false;
  indeterminate = false;

  constructor(private proposalCompareService: ProposalCompareService) {
  }

  ngOnInit(): void {


  }

  get canInspect(): boolean {
    if (this.loading) {
      return false;
    }

    if (!this.proposalList) {
      return false;
    }
    if (!this.proposalList.length) {
      return false;
    }
    return true;
  }

  updateCheckedSet(data: ProposalList, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(data.proposalId);
      this.proposalList.push(data);
    } else {
      this.proposalList.forEach((item, index) => {
        if (item.proposalId === data.proposalId) {
          this.proposalList.splice(index, 1);
        }
      });
      this.setOfCheckedId.delete(data.proposalId);
    }
  }

  onCurrentPageDataChange(listOfCurrentPageData: ProposalList[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData;
    this.checked = listOfEnabledData.every(({proposalId}) => this.setOfCheckedId.has(proposalId));
    this.indeterminate = listOfEnabledData.some(({proposalId}) => this.setOfCheckedId.has(proposalId)) && !this.checked;
  }

  onItemChecked(data: ProposalList, checked: boolean): void {
    this.updateCheckedSet(data, checked);
    this.refreshCheckedStatus();
    console.log(this.proposalList);
    console.log(this.setOfCheckedId);
  }

  sendRequest(): void {
    this.loading = true;
    const requestData = this.listArr.list.filter(data => this.setOfCheckedId.has(data.proposalId));
    console.log(requestData);
    setTimeout(() => {
      this.setOfCheckedId.clear();
      this.refreshCheckedStatus();
      this.loading = false;
    }, 1000);
  }

  onReload(): void {
    this.search.pageNum = 1;
    this.loading = true;
    this.proposalCompareService.selectList(this.search).subscribe(
      data => {
        this.listArr = data;
        console.log(this.listArr);
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }

  onClose(): void {
    this.setOfCheckedId = new Set<string>();
    this.proposalList = [];
  }

  onSave(): void {
    this.proposalCompareSaved.emit(this.proposalList);
    this.proposals.emit(this.setOfCheckedId);
  }

  onSearch($event: number): void {
    this.search.pageNum = $event;
    this.loading = true;
    this.proposalCompareService.selectList(this.search).subscribe(
      data => {
        this.listArr = data;
        console.log(this.listArr);
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }
}
