import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {InformationList, InformationSearch} from '../share-type';
import {PagedResp} from '../../api/types';
import {ShareService} from '../share.service';

@Component({
  selector: 'app-share-main',
  templateUrl: './share-main.component.html',
  styleUrls: ['./share-main.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareMainComponent implements OnInit {


  constructor() {
  }

  ngOnInit(): void {

  }

}

