import { Expose, plainToInstance, Transform, Type } from 'class-transformer';
import { Material, PagedReq } from '../api/types';
import { BankInfo, DiscountInfo, Proposal } from '../proposal/proposal-types';

export class PremiumFinancing {
  @Expose() id: number;
  @Expose() name: string;
  @Expose() interestRatio: number;
  @Expose() year: string;
  @Expose() productCode: string;
  @Expose() categoryCode: string;
  @Expose() proposalId: string;
  @Expose() status: string;
  @Expose() initialSurrenderValue: number;
  @Expose() premium: number;
  @Expose() premiumTotal: number;
  @Type(() => BankInfo)
  @Transform((value) => plainToInstance(BankInfo, Object.assign(new BankInfo(), value.value)))
  @Expose() bankInfo: BankInfo = new BankInfo();
  @Type(() => DiscountInfo)
  @Transform((value) => plainToInstance(DiscountInfo, Object.assign(new DiscountInfo(), value.value)))
  @Expose() discountInfo: DiscountInfo = new DiscountInfo();

  @Expose() urlHk: Material;
  @Expose() urlCn: Material;
  @Expose() urlUs: Material;

  @Expose() proposal: Proposal = new Proposal();
}

export class PremiumFinancingSearch extends PagedReq {
  @Expose() id: number;
}

export class PremiumFinancingProposalSearch extends PagedReq {
  @Expose() id: number;
}
