import { Injectable } from '@angular/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

@Injectable({
  providedIn: 'root'
})
export class FingerprintService {

  fpPromise = FingerprintJS.load();

  innerFingerprint: string;

  constructor() {
    this.fpPromise
      .then(fp => fp.get())
      .then(result => {
      this.innerFingerprint = result.visitorId;
      console.log('fingerprint');
      console.log(this.innerFingerprint);
    });
  }

  get fingerprint(): string {
    return this.innerFingerprint;
  }
}
