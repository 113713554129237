<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'Source' | translate}}
    </div>

    <div *ngFor="let source of sources" class="section-wrapper">
      <div style="display: flex; flex-direction: row; align-items: center; margin: 10px 10px 10px 0;">
        <div appThrottleClick (throttleClick)="onSource(source)" style="flex: 1; display: flex; flex-direction: column; justify-content: flex-start">
          <div style="font-size: 15px">{{source.sourceAccount}}</div>
          <div style="font-size: 12px; color: #AAAAAA">{{source.sourceName}}</div>
        </div>
        <div *ngIf="source.equals(selectedSource)">
          <i nz-icon nzType="check" nzTheme="outline" style="color: #1d64c4; font-size: 16px"></i>
        </div>
      </div>
    </div>

  </nz-spin>
</div>
