import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RecommendProduct, RecommendRider } from '../fna-types';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Currency, Rider } from '../../api/types';
import { ProductService } from '../../product/product.service';
import { plainToInstance } from 'class-transformer';
import { RiderMode } from '../../shared/component/product-riders-edit/product-riders-edit.component';
import { Product } from '../../product/product-types';

@Component({
  selector: 'app-fna-product-edit',
  templateUrl: './fna-product-edit.component.html',
  styleUrls: ['./fna-product-edit.component.less']
})
export class FnaProductEditComponent implements OnInit {

  product: RecommendProduct;

  currency: Currency = Currency.HKD;

  drawerRef: NzDrawerRef<FnaProductEditComponent, string>;

  primaryProduct: Product;

  riderMode = RiderMode.FNA;

  isNewFna = false;

  @Output()
  productSaved: EventEmitter<RecommendProduct> = new EventEmitter<RecommendProduct>();

  loading = false;

  riders: Rider[] = [];

  constructor(private productService: ProductService) {
  }

  ngOnInit(): void {

    this.riders = plainToInstance(Rider, this.product.riders) ?? [];

    this.loading = true;
    this.productService.detail(this.product.productCode)
      .subscribe(
        data => {
          this.loading = false;
          this.primaryProduct = data;
        },
        () => {
          this.loading = false;
        }
      );
  }

  onSave(): void {
    this.product.riders = plainToInstance(RecommendRider, this.riders);
    this.productSaved.emit(this.product);
    this.drawerRef.close();
  }
}
