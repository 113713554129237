<div class="drawer-container">

  <div class="drawer-title" style="margin-bottom: 30px">
    {{'PasswordUpdate' | translate}}
  </div>

  <div>
    <div>
      <div><span class="required-icon">*</span>{{'OldPassword' | translate}}</div>
      <nz-input-group class="input-group">
        <input required [(ngModel)]="editPwd.oldPassword" id="oldPwd" name="oldPwd" [type]="'password'"
               [placeholder]="('PleaseEnter' | translate) + ('OldPassword' | translate)" class="login-input-change"/>
      </nz-input-group>
    </div>
    <div>
      <div style="margin-top: 10px"><span class="required-icon">*</span>{{'NewEditPassword' | translate}}</div>
      <nz-input-group class="input-group">
        <input required [(ngModel)]="editPwd.newPassword" id="phonePwd" name="phonePwd" [type]="'password'"
               [placeholder]="('PleaseEnter' | translate) + ('NewEditPassword' | translate)" class="login-input-change"/>
      </nz-input-group>
    </div>
    <div>
      <div style="margin-top: 10px"><span class="required-icon">*</span>{{'ConfirmEditPassword' | translate}}</div>
      <nz-input-group class="input-group">
        <input required [(ngModel)]="confirmPass" id="confirm" name="confirm" [type]="'password'"
               [placeholder]="'ConfirmPasswordAgain' | translate" class="login-input-change"/>
      </nz-input-group>
    </div>
  </div>
  <div style="text-align: center" class="footer">
    <button nz-button nzType="default" appThrottleClick (throttleClick)="cancelPass()">{{'Cancel' | translate}}</button>
    <button nz-button nzType="primary" appThrottleClick
            style="margin-left: 20px"
            [disabled]="!canConfirm"
            (throttleClick)="confirmPas()">{{'Confirm' | translate}}</button>
  </div>
</div>
