import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyDisplayMode } from '../../shared';

@Component({
  selector: 'app-property-input-currency',
  templateUrl: './property-input-currency.component.html',
  styleUrls: ['./property-input-currency.component.less']
})
export class PropertyInputCurrencyComponent implements OnInit {


  @Input() displayMode: PropertyDisplayMode = 'horizontal';
  @Input() title: string;
  @Input() placeholder: string;
  @Input() tip: string;
  @Input() tipVisible = false;
  @Input() borderless = true;
  @Input() disabled = false;
  @Input() required = false;
  @Input() bottomLineVisible = true;
  @Input() max: number;
  @Input() min: number;

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  innerValue: any;

  @Input()
  get value(): any {
    return this.innerValue;
  }

  set value(val: any) {
    this.innerValue = val;
    this.valueChange.emit(this.innerValue);
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  onModelChange(val: any): void {

    const strDecimal = val.replace(/[^0-9.\-]/g, '') as string;
    console.log('strDecimal:' + strDecimal);

    /// 防止输入两个点'.'
    // const dotChar = '.';
    //
    // const charArray = decimal.split('');
    // const count = this.searchCount(charArray, dotChar);
    // if (count > 1) {
    //   const dotIndex = decimal.indexOf(dotChar);
    //   if (dotIndex >= 0) {
    //     charArray.splice(dotIndex, 1);
    //   }
    // }
    //
    // decimal = charArray.join('');
    const decimal = Number(strDecimal);
    console.log('decimal:' + decimal);
    this.value = decimal;
    console.log('value:' + this.value);

  }

  searchCount(charArray: string[], searchChar: string): number {
    let count = 0;
    for (const char of charArray) {
      if (char === searchChar) {
        count++;
      }
    }
    return count;
  }
}
