import { Component, Input, OnInit } from '@angular/core';
import { DataMode, PagedResp } from '../../api/types';
import { BvList, BvSearch } from '../bv-types';
import { NewFnaService } from '../../new-fna/new-fna.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MetadataService } from '../../shared/service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WorkbenchService } from '../../workbench/workbench.service';
import { BvService } from '../bv.service';

@Component({
  selector: 'app-bv-module-list',
  templateUrl: './bv-module-list.component.html',
  styleUrls: ['./bv-module-list.component.less']
})
export class BvModuleListComponent implements OnInit {
  @Input()
  mode = DataMode.OWN;
  loading = false;
  spinning = false;
  search: BvSearch = new BvSearch();
  bvListData: PagedResp<BvList>;
  filterPopoverVisible = false;
  constructor(private bvService: BvService,
              private drawerService: NzDrawerService,
              private modalService: NzModalService,
              private metadataService: MetadataService,
              private translate: TranslateService,
              private router: Router,
              private toastr: ToastrService,
              private workbenchService: WorkbenchService) { }

  ngOnInit(): void {
    this.onSearch(null);
  }

  onReload(): void {
    this.search = new BvSearch();
    this.onSearch(null);
  }

  onSearch(pageNum: number): void {
    if (pageNum) {
      this.search.pageNum = pageNum;
    }
    this.loading = true;
    this.search.mySelf = this.mode === DataMode.OWN ? '1' : null;
    this.bvService.list(this.search)
      .subscribe(list => {
          console.log(list);
          this.bvListData = JSON.parse(JSON.stringify(list));
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }
}
