<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{'HedgeProposal' | translate}}
  </div>
  <div class="proposal-apply-section">
    <app-property-select [title]="'InsuranceProduct' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('InsuranceProduct' | translate)"
                         [options]="productCodeOptions"
                         [required]="true"
                         [(value)]="applyReq.productCode"
                         (valueChange)="onProduct($event)"></app-property-select>
    <!--    <app-property-input-number [title]="'InsuranceProduct' | translate"-->
    <!--                               [placeholder]="('PleaseSelect' | translate) + ('InsuranceProduct' | translate)"-->
    <!--                               [(value)]="applyReq.productCode"></app-property-input-number>-->

    <!-- 貨幣 -->
    <app-property-text [title]="'Currency' | translate"
                       [valueColor]="'#807b7b'"
                       [value]="proposal.currency | metadataTranslate:'currency'"></app-property-text>
    <!-- 受保人年龄-->
    <app-property-text [title]="('Insured' | translate) + ('Age' | translate)"
                       [valueColor]="'#807b7b'"
                       [value]="applyReq.beginAge"></app-property-text>
    <!-- 结束年龄-->
    <app-property-input-number [title]="'AgeTo' | translate"
                               [required]="true"
                               [placeholder]="('PleaseSelect' | translate) + ('AgeTo' | translate)"
                               [(value)]="applyReq.endAge"></app-property-input-number>
    <div>
      <!-- 保障地區 -->
      <app-property-select [title]="'GuaranteedArea' | translate"
                           [displayMode]="'horizontal'"
                           [required]="requiredObj && requiredObj['MedicalRegion']"
                           [placeholder]="('PleaseSelect' | translate) + ('GuaranteedArea' | translate)"
                           [options]="guaranteedAreaOptions"
                           [(value)]="applyReq.coverageRegion"></app-property-select>

      <!-- 医疗险等级 -->
      <app-property-select [title]="'MedicalLevel' | translate"
                           [displayMode]="'horizontal'"
                           [placeholder]="('PleaseSelect' | translate) + ('MedicalLevel' | translate)"
                           [options]="medicalLevelOptions"
                           [required]="requiredObj && requiredObj['MedicalLevel']"
                           [(value)]="applyReq.medicalLevel"></app-property-select>

      <!-- 自付額 -->
      <app-property-select [title]="'Deductible' | translate"
                           [displayMode]="'horizontal'"
                           [required]="requiredObj && requiredObj['MedicalDeductible']"
                           [placeholder]="('PleaseSelect' | translate) + ('Deductible' | translate)"
                           [options]="deductibleOptions"
                           [(value)]="applyReq.deductible"></app-property-select>

      <!-- 附加保障 -->
      <app-property-select [title]="'SupplementaryBenefits' | translate"
                           [displayMode]="'horizontal'"
                           [mode]="'multiple'"
                           [placeholder]="('PleaseSelect' | translate) + ('SupplementaryBenefits' | translate)"
                           [options]="supplementaryBenefitOptions"
                           [(value)]="applyReq.supplementaryBenefits"></app-property-select>
    </div>
  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="previewHedge()">
          <span>{{'HedgeProposal' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
