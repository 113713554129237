export class Constant {

  static PAGE_SIZE = 10;

  static LOCAL_STORAGE_KEY_REDIRECT_URL = 'LIFEBEE-SALES-REDIRECT-URL';
  static LOCAL_STORAGE_KEY_LOCALE = 'LIFEBEE-SALES-REDIRECT-LOCALE';
  static LOCAL_STORAGE_KEY_UUID = 'LIFEBEE-SALES-REDIRECT-UUID';
  static LOCAL_STORAGE_KEY_INSTANCE = 'LIFEBEE-SALES-INSTANCE';
  static LOCAL_STORAGE_KEY_PASSPORT = 'LIFEBEE-SALES-PASSPORT';
  static LOCAL_STORAGE_KEY_METADATA = 'LIFEBEE-SALES-METADATA';
  static LOCAL_STORAGE_KEY_ACCOUNTS = 'LIFEBEE-SALES-ACCOUNTS';
  static LOCAL_STORAGE_KEY_MOCK_INSTANCE_KEY = 'LIFEBEE-SALES-MOCK-INSTANCE-KEY';

  static HTTP_HEADER_CONTENT_TYPE = 'Content-Type';
  static HTTP_HEADER_INSTANCE = 'X-BeeFintech-Instance';
  static HTTP_HEADER_INSTANCE_MOCK = 'X-BeeFintech-Mock-Instance';
  static HTTP_HEADER_VERSION = 'X-BeeFintech-Version';
  static HTTP_HEADER_LANGUAGE = 'X-BeeFintech-Language';
  static HTTP_HEADER_DEVICE_ID = 'X-BeeFintech-DeviceId';
  static HTTP_HEADER_OS = 'X-BeeFintech-OS';
  static HTTP_HEADER_MODEL = 'X-BeeFintech-Model';
  static HTTP_HEADER_CLIENT_TYPE = 'X-BeeFintech-ClientType';
  static HTTP_HEADER_SYSTEM = 'X-BeeFintech-System';
  static HTTP_HEADER_SIGN = 'X-BeeFintech-Sign';
  static HTTP_HEADER_SIGN_VERSION = 'X-BeeFintech-SignVersion';
  static HTTP_HEADER_TIMESTAMP = 'X-BeeFintech-Timestamp';
  static HTTP_HEADER_TOKEN = 'X-BeeFintech-Token';
  static HTTP_HEADER_PIN_CODE = 'X-BeeFintech-PINCode';
}
