import {Expose} from 'class-transformer';
import {CompanyListRes, Product, ProductCategory, ProductCompany} from '../product/product-types';
import {ProposalAmountType, SupplementaryBenefit} from '../api/types';

export class ProductSelection {
  @Expose() companies: ProductCompany[]; // 保险公司s
  @Expose() categories: ProductCategory[]; // 产品分类s
  @Expose() products: Product[]; // 产品s
}
export class Companies {
  @Expose() name: string; // 名称(ZH-HK)
  @Expose() code: string; // 唯一识别
  @Expose() logo: string;
  @Expose() desc: string; // 简介
  @Expose() rank: number; // 排序值
}

export class PremiumResp {
  @Expose() resultType: string; // 結果類型：PREMIUM - 保費；SUM_INSURED - 保額；
  @Expose() discountRate: number; // 折扣率
  @Expose() premiumRate: number; // 費率
  @Expose() finalRate: number; // 最終計算費率
  @Expose() total: number; // 總金额
  @Expose() basic: number; // 基本金额
  @Expose() levy: number; // 徵費（暫時不用）
  @Expose() outpatient: number; // 門診（醫療險適用）
  @Expose() maternity: number; // 產科（醫療險適用）
  @Expose() dental: number; // 牙科（醫療險適用）
  @Expose() policyFee: number; // 额外保单费用
  @Expose() quotationVersion: string; // 版本號
}

export class Premium {
  @Expose() productCode: string; // 产品码
  @Expose() premiumTerm: string; // 产品年期
  @Expose() gender: string;
  @Expose() smokeCondition: string; // 吸烟情况
  @Expose() age: number;
  @Expose() ageTo: number; // 结束年龄-医疗险选填
  @Expose() currency: string; // 货币
  @Expose() amount: string; // 金额
  @Expose() region: string; // 医疗险-保障地区
  @Expose() deductible: string; // 医疗险-自费额
  @Expose() level: string; // 医疗险-病房等级
  @Expose() supplementaryBenefits: SupplementaryBenefit[]; // 医疗险-附加保障
}

export class SumRange {
  @Expose() productCode: string; // 产品码
  @Expose() currency: string; // 币种
}
