import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogReply, Material, PendingReply, UploadTokenReq } from '../../../api/types';
import { AccountService } from '../../../account/account.service';
import { DataService } from '../../service/data.service';
import { GroupDialog } from '../../../booking/booking-types';

@Component({
  selector: 'app-booking-batch',
  templateUrl: './booking-batch.component.html',
  styleUrls: ['./booking-batch.component.less']
})
export class BookingBatchComponent implements OnInit {
  loading = false;

  @Output()
  dialogRes: EventEmitter<GroupDialog> = new EventEmitter<GroupDialog>();

  list: GroupDialog[] = [];
  @Input()
  dialogReply: GroupDialog = new GroupDialog();
  @Input()
  uploadTokenReq: UploadTokenReq;
  @Input()
  groupNo: string;
  @Input()
  Title: string;
  @Input()
  isAnnex: boolean;


  @Input()
  moduleType: string;
  @Output()
  confirmParam: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }


  replyAttachmentsChange(attachments: Material[]): void {
    this.dialogReply.attachments = attachments;
  }

  get canReply(): boolean {
    if (!this.isAnnex) {
      return !!this.dialogReply.content || !!this.dialogReply.attachments;
    } else {
      return !!this.dialogReply.attachments;
    }
  }

  reply(): void {
    if (!this.canReply) {
      return;
    }
    this.dialogRes.emit(this.dialogReply);
  }

  loadList(list: GroupDialog): void {
    const copy = JSON.parse(JSON.stringify(list));
    copy.content = copy.content ? copy.content : '';
    this.dialogReply = new GroupDialog();
    this.list.push(copy);
  }

}
