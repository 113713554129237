import { Component, EventEmitter, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { CommonInputType } from '../../shared';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../base/base.component';


@Component({
  selector: 'app-common-input-edit',
  templateUrl: './common-input-edit.component.html',
  styleUrls: ['./common-input-edit.component.less']
})
export class CommonInputEditComponent extends BaseComponent implements OnInit {

  type: CommonInputType = 'text';
  title: string;
  placeholder = '';
  value: string;

  drawerRef: NzDrawerRef<CommonInputEditComponent, string>;

  saved: EventEmitter<string> = new EventEmitter<string>();
  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.translate.get('PleaseEnter')
      .subscribe(
        text => {
          this.placeholder = text + this.title;
        },
        error => {}
      ).disposedBy(this.disposeBag);
  }

  get canSave(): boolean {
    return true;
  }

  onSave(): void {
    this.saved.emit(this.value);
    this.drawerRef?.close();
  }
}
