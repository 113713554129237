import { Component, OnInit } from '@angular/core';
import {ListVO} from '../../commission/commission-types';
import {Tool} from '../../shared/utils/tool';

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.less']
})
export class ContractDetailComponent implements OnInit {

  contractDetail: ListVO = new ListVO();
  constructor() { }

  ngOnInit(): void {
  }

  isShow(): boolean {
    return !!Object.keys(this.contractDetail).length;
  }

  onRate(rate): string {
    if (rate) {
      // return Tool.accMul(rate, 100) + '%';
      return rate + '%';
    } else {
      return rate;
    }
  }

}
