import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProposalCompareListComponent } from './proposal-compare-list/proposal-compare-list.component';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {SharedModule} from '../shared/shared.module';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {TranslateModule} from '@ngx-translate/core';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzButtonModule} from 'ng-zorro-antd/button';
import { ProposalCompareSelectedComponent } from './proposal-compare-selected/proposal-compare-selected.component';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {ProposalCompareDetailComponent} from './proposal-compare-detail/proposal-compare-detail.component';
import {ProposalCompareSelectDetailComponent} from './proposal-compare-select-detail/proposal-compare-select-detail.component';
import {NzRateModule} from 'ng-zorro-antd/rate';
import {FormsModule} from '@angular/forms';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import { ProposalCoverComponent } from './proposal-cover/proposal-cover.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzSliderModule } from 'ng-zorro-antd/slider';



@NgModule({
  declarations: [
    ProposalCompareListComponent,
    ProposalCompareSelectedComponent,
    ProposalCompareDetailComponent,
    ProposalCompareSelectDetailComponent,
    ProposalCoverComponent
  ],
    imports: [
        CommonModule,
        NzSpinModule,
        NzEmptyModule,
        NzTableModule,
        NzGridModule,
        NzToolTipModule,
        SharedModule,
        NzPopoverModule,
        NzModalModule,
        TranslateModule,
        NzAvatarModule,
        NzIconModule,
        NzButtonModule,
        NzSkeletonModule,
        NzRateModule,
        FormsModule,
        NzCheckboxModule,
        NzDropDownModule,
        ScrollingModule,
        NzListModule,
        NzImageModule,
        NzSliderModule
    ]
})
export class ProposalCompareModule { }
