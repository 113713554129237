import {Component, EventEmitter, OnInit} from '@angular/core';
import {ContactPerson, PactDetails} from '../../sales-pact-types';
import {SalesPactService} from '../../sales-pact.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-primary-contact-info',
  templateUrl: './primary-contact-info.component.html',
  styleUrls: ['./primary-contact-info.component.less']
})
export class PrimaryContactInfoComponent implements OnInit {
  data: ContactPerson = new ContactPerson();
  createInfo: ContactPerson = new ContactPerson();
  signatureStatus: string;
  modifyState: string;
  infoIndex: number; // 下标 主要联系人
  id: number; // 合同id
  afterRecord: EventEmitter<any> = new EventEmitter<any>();
  infoData: PactDetails = new PactDetails(); // 合同信息
  constructor(private salesPactService: SalesPactService,
              private toastr: ToastrService,
              private translate: TranslateService) { }

  ngOnInit(): void {
  }

  onSave(): void {
    if (!this.canReply) {
      return;
    }
    if (this.signatureStatus === 'UNSIGNED') {
      this.onEdit();
    }
    if (this.modifyState === 'Create') {
      this.onCreate();
    }
  }

  get canReply(): boolean {
    if (this.signatureStatus === 'UNSIGNED') {
      return !!this.data.name;
    }
    if (this.modifyState === 'Create') {
      return !!this.createInfo.name;
    }
  }

  onEdit(): void {
    this.infoData.contactPerson[this.infoIndex].name = this.data.name;
    this.infoData.contactPerson[this.infoIndex].phone = this.data.phone;
    this.infoData.contactPerson[this.infoIndex].position = this.data.position;
    this.infoData.contactPerson[this.infoIndex].email = this.data.email;
    const param = {
      id: this.id,
      contactPerson: this.infoData.contactPerson
    };
    this.salesPactService.updateInfo(param)
      .subscribe(
        data => {
          if (data) {
            this.translate.get('ModifySuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          }
          this.afterRecord.emit('');
        },
        error => {
        }
      );
  }

  onCreate(): void {
    const item = {
      name: this.createInfo.name,
      phone: this.createInfo.phone,
      position: this.createInfo.position,
      email: this.createInfo.email
    };
    if (!this.infoData.contactPerson) {
      this.infoData.contactPerson = [];
    }
    this.infoData.contactPerson.push(item);
    const param = {
      id: this.id,
      contactPerson: this.infoData.contactPerson
    };
    this.salesPactService.updateInfo(param)
      .subscribe(
        data => {
          if (data) {
            this.translate.get('AddSuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          }
          this.afterRecord.emit('');
        },
        error => {
        }
      );
  }

}
