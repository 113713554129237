import { Component, EventEmitter, OnInit, Output, SimpleChanges } from '@angular/core';
import { CashQuestionData } from '../../../api/types';
import { UnderwritingService } from '../../../underwriting/underwriting.service';
import { BookingService } from '../../../booking/booking.service';
import { PolicyService } from '../../../policy/policy.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-business-form-read',
  templateUrl: './business-form-read.component.html',
  styleUrls: ['./business-form-read.component.less']
})
export class BusinessFormReadComponent implements OnInit {

  dataSource: CashQuestionData = new CashQuestionData();
  editDataSource: CashQuestionData = new CashQuestionData();
  questionDetail: CashQuestionData = new CashQuestionData();
  policyNo: string;
  formType: string;
  moduleType: string;
  isRead: boolean;

  @Output()
  successTitle: EventEmitter<any> = new EventEmitter<any>();

  constructor(private underwritingService: UnderwritingService,
              private bookingService: BookingService,
              private policyService: PolicyService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.gteData();
  }


  gteData(): void {
    switch (this.moduleType) {
      case 'booking':
        this.bookingService.cashQuestion(this.policyNo, this.formType)
          .subscribe(
            data => {
              this.questionDetail = JSON.parse(JSON.stringify(data));
              this.getRiskQuestion();
            }, error => {
              console.log(error);
            });
        break;
      case 'underwriting':
        this.underwritingService.cashQuestion(this.policyNo, this.formType)
          .subscribe(
            data => {
              this.questionDetail = JSON.parse(JSON.stringify(data));
              this.getRiskQuestion();
            }, error => {
              console.log(error);
            });
        break;
      case 'policy':
        this.policyService.cashQuestion(this.policyNo, this.formType)
          .subscribe(
            data => {
              this.questionDetail = JSON.parse(JSON.stringify(data));
              this.getRiskQuestion();
            }, error => {
              console.log(error);
            });
        break;
    }


  }

  getRiskQuestion(): void {
    if (this.questionDetail.question?.type === 'score') {
      let num = 0;
      this.questionDetail.question.question.forEach((e) => {
        if (e.type === 'question') {
          if (e.value.type === 'radio') {
            e['model'] = null;
          }
          if (e.value.type === 'multi-select') {
            e['model'] = [];
          }
          let anwerObj;
          if (this.questionDetail.answer && this.questionDetail.answer.length > 0) {
            anwerObj = this.questionDetail.answer.find((anwer) => {
              return anwer.questionId === e.qid;
            });
          }
          if (e.value.data) {
            e.value.data.forEach((data) => {
              if (data.attachment) {
                data.attachment['model'] = null;
              }
            });
          }
          if (anwerObj) {
            this.getAttachment(e, anwerObj);
          }
          if (e.number) {
            num += 1;
            e.num = num;
          }
        }
      });
    }
    if (this.questionDetail.question?.type === 'standard') {
      this.questionDetail.question.question.forEach((e) => {
        if (e.qid) {
          e['model'] = null;
        }
        if (e.child) {
          let num = 0;
          e.child.forEach((childValue) => {
            if (childValue.qid) {
              childValue['model'] = null;
              if (childValue.value.data && childValue.value.type !== 'input') {
                childValue.value.data.forEach((element) => {
                  if (element.attachment) {
                    if (element.attachment.repeat) {
                      const arr = [];
                      for (let i = 0; i < element.attachment.repeat; i++) {
                        arr.push('');
                      }
                      element.attachment['model'] = arr;
                    } else {
                      element.attachment['model'] = null;
                    }
                  }
                });
              }
              let anwerObj;
              if (childValue.type === 'question') {
                if (this.questionDetail.answer && this.questionDetail.answer.length > 0) {
                  anwerObj = this.questionDetail.answer.find((anwer) => {
                    return anwer.questionId === childValue.qid;
                  });
                }
                if (anwerObj) {
                  this.getAttachment(childValue, anwerObj);
                }
              }
              if (childValue.number) {
                num += 1;
                childValue.num = num;
              }
            }
          });
        } else {
          if (e.type === 'question' && e.value) {
            let anwerObj;
            if (this.questionDetail.answer && this.questionDetail.answer.length > 0) {
              anwerObj = this.questionDetail.answer.find((anwer) => {
                return anwer.questionId === e.qid;
              });
            }
            if (e.value.data) {
              e.value.data.forEach((data) => {
                if (data.attachment) {
                  data.attachment['model'] = null;
                }
              });
            }
            if (anwerObj) {
              this.getAttachment(e, anwerObj);
            }
          }
        }
      });
    }
    if (this.questionDetail.question?.type === 'conditions') {
      this.questionDetail.question.question.forEach((e) => {
        let anwerObj;
        if (e.type === 'question') {
          if (this.questionDetail.answer && this.questionDetail.answer.length > 0) {
            anwerObj = this.questionDetail.answer.find((anwer) => {
              return anwer.questionId === e.qid;
            });
          }
          e['model'] = null;
          if (e.value.data && e.value.type !== 'input') {
            e.value.data.forEach((data) => {
              if (data.attachment) {
                data.attachment['model'] = null;
              }
            });
          }
          if (anwerObj) {
            this.getAttachment(e, anwerObj);
          }
        }
      });
    }
    if (this.questionDetail.question?.type === 'placeholder') {
      this.questionDetail.question.question.forEach((e) => {
        if (e.type === 'title') {
          if (e.child) {
            e.child.forEach((child) => {
              child['html'] = this.labelHtml(child);
              let anwerObj;
              if (this.questionDetail.answer && this.questionDetail.answer.length > 0) {
                anwerObj = this.questionDetail.answer.find((anwer) => {
                  return anwer.questionId == child.qid;
                });
              }
              if (child.field && child.field.length > 0) {
                child.field.forEach((field) => {
                  field['model'] = null;
                  if (anwerObj) {
                    anwerObj.field.forEach((anwerField) => {
                      if (anwerField.answerId == field.answerId) {
                        field.model = anwerField.value;
                      }
                    });
                  }
                });
              }
            });
          }
        }
        if (e.type === 'question' && e.value) {
          let anwerObj;
          if (this.questionDetail.answer && this.questionDetail.answer.length > 0) {
            anwerObj = this.questionDetail.answer.find((anwer) => {
              return anwer.questionId == e.qid;
            });
          }
          if (e.value.data) {
            e.value.data.forEach((data) => {
              console.log(data);
              if (data.attachment) {
                data.attachment['model'] = null;
              }
              if (data.attachment.repeat) {
                const arr = [];
                for (let i = 0; i < data.attachment.repeat; i++) {
                  arr.push('');
                }
                data.attachment['model'] = arr;
              } else {
                data.attachment['model'] = null;
              }
            });
          }
          if (anwerObj) {
            this.getAttachment(e, anwerObj);
          }
        }
      });
    }
    console.log(this.questionDetail);
    this.dataSource = JSON.parse(JSON.stringify(this.questionDetail));
    this.editDataSource = JSON.parse(JSON.stringify(this.questionDetail));
    this.onRadioChange(2);
    // this.$forceUpdate();
    // console.log(this.editDataSource);
  }

  getAttachment(e, anwerObj): void {
    e.model = anwerObj.value;
    if (e.value.type === 'multi-select' && e.model) {
      e.model = e.model.split(',');
      e.model = [...new Set(e.model)];
      e.value.data.map(item => {
        if (e.model.includes(item.answerId)) {
          item['checked'] = true;
        } else {
          item['checked'] = false;
        }
      });
    }
    if (e.value.data && e.value.data.length > 0) {
      e.value.data.forEach((data) => {
        if (data.attachment && anwerObj.attachment && data.attachment.attachmentId === anwerObj.attachment.attachmentId) {
          if (data.attachment.repeat) {
            // console.log(anwerObj.attachment.value);
            data.attachment.model = anwerObj.attachment.value.split(',');
            // data.attachment.model = anwerObj.attachment.value
          } else {
            data.attachment.model = anwerObj.attachment.value;
          }
        }
      });
    }
  }

  labelHtml(label): any[] {
    let list = [];
    if (label.field && label.field.length > 0) {
      label.field.forEach((e) => {
        if (list.length > 0) {
          let data = [];
          list.forEach((el) => {
            const data2 = el.split(`#${e.answerId}#`);
            if (data2.length === 2) {
              data2.splice(1, 0, e.answerId);
            }
            data = data.concat(data2);
          });
          list = data;
        } else {
          list = label.label.split(`#${e.answerId}#`);
          if (list.length === 2) {
            list.splice(1, 0, e.answerId);
          }
        }
      });
    } else {
      list.push(label.label);
    }
    return list;
  }

  isHtml(row, item): boolean {
    return row.field && row.field.length > 0
      ? !!row.field.find((e) => {
        return e.answerId === item;
      })
      : false;
  }

  onRadioChange(event): void {
    console.log(this.editDataSource);
    let totalScores = 0;
    this.editDataSource.question.question.forEach((e) => {
      if (e.number) {
        e.value.data.forEach((element) => {
          if (element.answerId === e.model) {
            totalScores += element.score;
          }
        });
      }
      if (e.type === 'question' && e.value.type === 'totalScores') {
        e.model = totalScores ? totalScores.toString() : null;
      }
      if (e.type === 'question' && e.value.type === 'select-read') {
        e.value.data.forEach((element) => {
          const score = element.score.split('-');
          if (totalScores >= score[0] && totalScores <= score[1]) {
            e.model = element.answerId;
          }
        });
      }
      if (e.type === 'question' && e.parent) {
        e['isCheck'] = !!this.editDataSource.question.question.find((val) => {
          return val.model === e.parent;
        });
      }
    });
  }

  scoreCheckbox(e): void {
    this.editDataSource.question.question.map(item => {
      if (item.type === 'question') {
        if (item.value.type === 'multi-select') {
          if (item.value.data) {
            item.value.data.map(iet => {
              iet.checked = !!e.includes(iet.answerId);
            });
          }
        }
      }
    });
  }

  onSave(): any {
    const result = JSON.parse(JSON.stringify(this.editDataSource));
    const list = [];
    let formErr = false;
    try {
      switch (this.formType) {
        case 'NEW_BUSINESS_FORM':
          result.question.question.forEach((e) => {
            if (e.qid) {
              const obj = {
                questionId: e.qid,
                value: e.model
              };
              if (e.parent) {
                obj['parent'] = e.parent;
                result.question.question.forEach(el => {
                  // obj.value = '';
                  if (el.model === e.parent) {
                    obj.value = e.model;
                    if (!e.model) {
                      console.log(e);
                      formErr = true;
                      this.errText(e.label);
                      throw Error();
                    }
                  }
                });
              }
              if (!e.model && !e.parent) {
                console.log(e);
                formErr = true;
                this.errText(e.label);
                throw Error();
              }
              if (e.value.data) {
                e.value.data.forEach(data => {
                  if (data.attachment && data.answerId === e.model) {
                    const attachment = {
                      attachmentId: data.attachment.attachmentId,
                      value: data.attachment.model
                    };
                    if (data.attachment.repeat && data.attachment.repeat === 2) {
                      attachment.value.join();
                    }
                    obj['attachment'] = attachment;
                    if (!data.attachment.model) {
                      console.log(e);
                      formErr = true;
                      this.errText(e.label);
                      throw Error();
                    }
                  }
                });
                if (e.value.type === 'multi-select') {
                  e.model.forEach(el => {
                    const answerObj = e.value.data.find(answer => {
                      return answer.answerId === el && answer.attachment;
                    });
                    if (answerObj) {
                      console.log(answerObj);
                      obj['attachment'] = {
                        attachmentId: answerObj.attachment.attachmentId,
                        value: answerObj.attachment.model
                      };
                      if (!answerObj.attachment.model) {
                        console.log(e);
                        formErr = true;
                        this.errText(e.label);
                        throw Error();
                      }
                    }
                  });
                  obj.value = obj.value.join();
                }
              }
              list.push(obj);
            }
          });
          break;
        case 'ACCOUNT_OPENING_FORM':
          result.question.question.forEach(question => {
            if (question.child) {
              question.child.forEach(child => {
                if (child.qid) {
                  if (child.value.type === 'multi-select') {
                    child.model = [];
                    child.value.data.map(item => {
                      if (item.checked) {
                        child.model.push(item.answerId);
                      }
                    });
                  }
                  const obj = {
                    questionId: child.qid,
                    value: child.model
                  };
                  if (child.parent) {
                    obj['parent'] = child.parent;
                    result.question.question.forEach.forEach(el => {
                      if (el.model === child.parent) {
                        if (!child.model) {
                          console.log(child.label);
                          formErr = true;
                          this.errText(child.label);
                          throw Error();
                        }
                      }
                    });
                  }
                  if (!child.model) {
                    console.log(child.label);
                    formErr = true;
                    this.errText(child.label);
                    throw Error();
                  }
                  if (child.value.data) {
                    child.value.data.forEach(data => {
                      if (data.attachment && data.answerId === child.model) {
                        const attachment = {
                          attachmentId: data.attachment.attachmentId,
                          value: data.attachment.model
                        };
                        if (data.attachment.repeat && data.attachment.repeat === 2) {
                          attachment.value.forEach(value => {
                            console.log(value);
                            if (!value) {
                              console.log(child.label);
                              formErr = true;
                              this.errText(child.label);
                              throw Error();
                            }
                          });
                          attachment.value = attachment.value.join();
                        }
                        obj['attachment'] = attachment;
                        if (!attachment.value) {
                          console.log(child.label);
                          formErr = true;
                          this.errText(child.label);
                          throw Error();
                        }
                      }
                    });
                    if (child.value.type === 'multi-select') {
                      console.log(child, '-------------');
                      child.model.forEach(el => {
                        const answerObj = child.value.data.find(answer => {
                          return answer.answerId === el && answer.attachment;
                        });
                        if (answerObj) {
                          console.log(answerObj);
                          obj['attachment'] = {
                            attachmentId: answerObj.attachment.attachmentId,
                            value: answerObj.attachment.model
                          };
                          if (!answerObj.attachment.model) {
                            console.log(child);
                            formErr = true;
                            this.errText(child.label);
                            throw Error();
                          }
                        }
                      });
                      obj.value = obj.value.join(',');
                    }
                  }
                  list.push(obj);
                }
              });
              console.log(list);
            }
          });
          // if (formErr) return;
          break;
        case 'CONFIRMATION_LETTER_NON_ILAS':
          result.question.question.forEach(e => {
            if (e.child) {
              e.child.forEach(child => {
                if (child.qid) {
                  const obj = {
                    questionId: child.qid,
                    field: []
                  };
                  if (child.field && child.field.length > 0) {
                    child.field.forEach(field => {
                      obj.field.push({
                        answerId: field.answerId,
                        data: field.data,
                        value: field.model,
                        type: field.type
                      });
                      if (!field.model) {
                          console.log(child.label);
                          formErr = true;
                          this.errText(child.label);
                          throw Error();
                      }
                    });
                  }
                  list.push(obj);
                }
              });
            } else if (e.value) {
              const obj = {
                questionId: e.qid,
                value: e.model
              };
              console.log(e);
              if (!e.model) {
                  formErr = true;
                  this.errText(e.label);
                  throw Error();
              }
              e.value.data.forEach(data => {
                if (data.attachment && data.answerId === e.model) {
                  const attachment = {
                    attachmentId: data.attachment.attachmentId,
                    value: data.attachment.model
                  };
                  if (data.attachment.repeat && data.attachment.repeat === 2) {
                    attachment.value.forEach(value => {
                      console.log(value);
                      if (!value) {
                          console.log(e.label);
                          formErr = true;
                          this.errText(e.label);
                          throw Error();
                      }
                    });
                    attachment.value = attachment.value.join();
                  }
                  obj['attachment'] = attachment;
                  if (!attachment.value) {
                  console.log(e.label);
                  formErr = true;
                  this.errText(e.label);
                  throw Error();
                  }
                }
              });
              list.push(obj);
            }
          });
          // if (formErr) return;
          break;
        case 'CHECKLIST':
          const that = this;
          result.question.question.forEach(e => {
            console.log(e);
            if (e.qid) {
              const narry: any = [];
              e.value.data.map(item => {
                if (item.checked) {
                  narry.push(item.answerId);
                }
              });
              if (!narry.length) {
                formErr = true;
                this.errText('Checklist');
                throw Error();
              }
              const obj = {
                questionId: e.qid,
                value: narry
              };
              if (e.value.data) {
                if (e.value.type === 'multi-select') {
                  // e.model.forEach(el => {
                  //   const answerObj = e.value.data.find(answer => {
                  //     return answer.answerId === el && answer.attachment;
                  //   });
                  //   if (answerObj) {
                  //     console.log(answerObj);
                  //     obj['attachment'] = {
                  //       attachmentId: answerObj.attachment.attachmentId,
                  //       value: answerObj.attachment.model
                  //     };
                  //     if (!answerObj.attachment.model) {
                  //       console.log(e);
                  //       // console.log(child.label);
                  //       formErr = true;
                  //       // that.errText(child.label);
                  //       throw Error();
                  //     }
                  //   }
                  // });
                  obj.value = obj.value.join();
                }
              }
              list.push(obj);
              console.log(list);
            }
          });
          // if (formErr) return;
          break;
        case 'RISK_PROFILE_QUESTIONNAIRE':
          result.question.question.forEach((e) => {
            if (e.qid) {
              const obj = {
                questionId: e.qid,
                value: e.model
              };
              if (e.parent) {
                obj['parent'] = e.parent;
                result.question.question.forEach(el => {
                  obj.value = '';
                  if (el.model === e.parent) {
                    obj.value = e.model;
                    if (!e.model) {
                      console.log(e);
                      formErr = true;
                      this.errText(e.label);
                      throw Error();
                    }
                  }
                });
              }
              if (!e.model && !e.parent) {
                console.log(e);
                formErr = true;
                this.errText(e.label);
                throw Error();
              }
              if (e.value.data) {
                e.value.data.forEach(data => {
                  if (data.attachment && data.answerId === e.model) {
                    const attachment = {
                      attachmentId: data.attachment.attachmentId,
                      value: data.attachment.model
                    };
                    obj['attachment'] = attachment;
                    if (!data.attachment.model) {
                      console.log(e);
                      formErr = true;
                      this.errText(e.label);
                      throw Error();
                    }
                  }
                });
                if (e.value && e.value.type === 'multi-select') {
                  e.model = [];
                  e.value.data.map(item => {
                    if (item.checked) {
                      e.model.push(item.answerId);
                    }
                  });
                  if (!e.model.length) {
                    formErr = true;
                    this.errText(e.label);
                    throw Error();
                  }
                }
                if (e.value.type === 'multi-select') {
                  e.model.forEach(el => {
                    const answerObj = e.value.data.find(answer => {
                      return answer.answerId === el && answer.attachment;
                    });
                    if (answerObj) {
                      console.log(answerObj);
                      obj['attachment'] = {
                        attachmentId: answerObj.attachment.attachmentId,
                        value: answerObj.attachment.model
                      };
                      if (!answerObj.attachment.model) {
                        console.log(e);
                        formErr = true;
                        this.errText(e.label);
                        throw Error();
                      }
                    }
                  });
                  obj.value = e.model.join();
                }
              }
              list.push(obj);
            }
          });
          // if (formErr) return
          break;
      }
      switch (this.moduleType) {
        case 'booking':
          const params = {
            questionId: result.id,
            id: result.answerId || undefined,
            bookingNo: this.policyNo,
            answer: JSON.stringify(list)
          };
          this.bookingService.cashAnswer(params)
            .subscribe(
              data => {
                if (data) {
                  this.translate.get('SavedSuccessfully').subscribe(
                    (res) => {
                      this.toastr.success(res);
                      this.successTitle.emit('');
                    }
                  );
                }
              }, error => {
                console.log(error);
              });
          break;
        case 'underwriting':
          const param = {
            questionId: result.id,
            id: result.answerId || undefined,
            underwritingNo: this.policyNo,
            answer: JSON.stringify(list)
          };
          this.underwritingService.cashAnswer(param)
            .subscribe(
              data => {
                if (data) {
                  this.translate.get('SavedSuccessfully').subscribe(
                    (res) => {
                      this.toastr.success(res);
                      this.successTitle.emit('');
                    }
                  );
                }
              }, error => {
                console.log(error);
              });
          break;
      }
      return {name: 'newBusiness', status: 'success'};
    } catch (error) {
      console.log(error);
      if (formErr) {
        return {name: 'empty', status: 'fail'};
      }
      return {name: 'newBusiness', status: 'fail'};
    } finally {
      // this.isEdit = false;
    }
  }

  errText(text): void {
    console.log(text);
    this.translate.get('ValidateEmpty').subscribe(
      (res) => {
        this.toastr.warning(text + res);
      }
    );
  }
}
