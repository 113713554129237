import { Injector } from '@angular/core';

export class AppInjector {

  private static innerInjector: Injector;

  public static get injector(): Injector {
    return AppInjector.innerInjector;
  }

  public static set injector(injector: Injector) {
    AppInjector.innerInjector = injector;
  }

}
