<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate"  [nzSpinning]="spinning">
    <div *ngIf="!listData">
      <nz-empty nzNotFoundImage="simple"></nz-empty>
    </div>
    <div *ngIf="listData" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="listData.list"
                [nzPageIndex]="listData.pageNum"
                [nzPageSize]="listData.pageSize"
                [nzTotal]="listData.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{ x: '1415'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'CustomerLeaveWordList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ listData.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzAlign="center" nzWidth="165px">
            <span nz-tooltip
                  [nzTooltipTitle]="'Name' | translate">{{'Name' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'ContactNumber' | translate">{{'ContactNumber' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Email' | translate">{{'Email' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Time' | translate">{{'Time' | translate}}</span>
          </th>
          <th nzRight nzAlign="center" nzWidth="160px">
            <span>{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let leaveWords of listData.list; let i = index"
            appThrottleClick (throttleClick)="onDetail(leaveWords.id)"
        >
          <td nzLeft nzAlign="left">
            <div style="display: flex; align-items: center">
              <nz-avatar
                class="list-top-avatar"
                style="flex-shrink: 0; margin-right: 20px;"
                [nzSize]="36" nzSrc="assets/images/default_avatar.png"></nz-avatar>
              <span style="flex-shrink: 1;font-weight: bold; font-size: 12px">
              {{ leaveWords.name }}
            </span>
            </div>
          </td>
          <td nzAlign="center" ><span>{{ leaveWords.areaCode }} {{leaveWords.phone}}</span></td>
          <td nzAlign="center" ><span>{{ leaveWords.email || 'N/A' }}</span></td>
          <td nzAlign="center" ><span>{{ (leaveWords.createTime | date: 'yyyy-MM-dd HH:mm:ss') || 'N/A' }}</span></td>
          <td nzRight nzAlign="center" >
            <div class="functions">
              <a nz-button nzType="link" nzSize="small"
                 nz-tooltip [nzTooltipTitle]="'Detail' | translate">
                <img alt="" width="20" src="../../../assets/images/ic_details.svg">
              </a>
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
                 appThrottleClick (throttleClick)="onDelete(leaveWords.id); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_delete.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>

  <nz-modal
    [(nzVisible)]="isVisible"
    [nzTitle]="modalTitle"
    [nzContent]="modalContent"
    [nzFooter]="null"
    [nzWidth]="'400px'"
    nzCentered
    [nzClosable]="false"
    [nzMaskClosable]="false"
    (nzOnCancel)="handleCancel()"
  >
    <ng-template #modalTitle style="text-align: center">{{'Tips' | translate}}</ng-template>

    <ng-template #modalContent>
      <p style="text-align: center; margin-bottom: 30px">{{'LeaveWordDelete' | translate}}</p>
      <div nz-row style="width: 100%; height: 100%; justify-content: space-around;">
        <button nz-col nzSpan="10" nz-button nzType="default" appThrottleClick (throttleClick)="handleCancel()">{{'Cancel' | translate}}</button>
        <button nz-col nzSpan="10" nz-button nzType="primary" appThrottleClick (throttleClick)="handleOk()">{{'Confirm' | translate}}</button>
      </div>
    </ng-template>
  </nz-modal>
</div>

