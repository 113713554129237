import { Expose } from 'class-transformer';

export class MessageConfig {
  @Expose() digitalReminder: boolean; // 角标数字提醒
  @Expose() systematicNotification: boolean; // 系统栏和悬浮通知
  @Expose() messageDisturb: boolean; // 系统栏和悬浮通知
  @Expose() messageReceiving: boolean; // 消息接收设置
  @Expose() messageSettings: MessageSetting[]; // 消息设置
}

export class MessageSetting {
  @Expose() messageType: string; // 消息类型
  @Expose() enable: boolean; // 是否开启
}

export class MessageRemind {
  @Expose() dialog: number; // 留言数
  @Expose() pending: number; // Pending数
  @Expose() pendingReply: number; // Pending回复数
  @Expose() service: number; // service数
  @Expose() serviceReply: number; // service回复数
  @Expose() attachment: number; // 附件数
  @Expose() status: number; // 状态变更
  @Expose() info: number; // 详情变更
  @Expose() create: number; // 新建
  @Expose() remind: number; // 不知道
}
