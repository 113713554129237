<div [ngSwitch]="displayMode">
  <div *ngSwitchCase="'horizontal'">
    <div [class]="'property-row'">
      <span class="property-title-row" *ngIf="title">{{ title }}</span>
      <span class="property-value-row" [ngSwitch]="textType">
        <div *ngSwitchCase="'default'">
          <span [style]="'color:' + valueColor + ';'">{{value ?? ''}}</span>
        </div>
        <div *ngSwitchCase="'html'">
          <div [innerHTML]="html"></div>
        </div>
      </span>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>
  <div *ngSwitchCase="'vertical'">
    <div [class]="'property-column'">
      <div class="property-title-column">
        <span *ngIf="title">{{ title }}</span>
      </div>
      <div class="property-value-column" style="line-height: 32px;" [ngSwitch]="textType">
        <div *ngSwitchCase="'default'">
          <span [style]="'color:' + valueColor + ';'">{{value ?? ''}}</span>
        </div>
        <div *ngSwitchCase="'html'">
          <div [innerHTML]="value | html"></div>
        </div>
      </div>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>

</div>
