import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ticketOrder'
})
export class TicketOrderStatusPipe implements PipeTransform {

  transform(value: any, ...args: any[]): string {
    let ticketStatus = '';
    switch (value) {
      case 'PENDING':
        ticketStatus = 'TicketListStatusProcessing';
        break;
      case 'REPLIED':
        ticketStatus = 'Replied';
        break;
      case 'CLOSED':
        ticketStatus = 'Close';
        break;
    }
    return ticketStatus;
  }

}
