<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 40px">
    {{'SalesReportSearch' | translate}}
  </div>

  <!-- 编号 -->
  <app-property-input *ngIf="search.module === 'POLICY'"
                      [displayMode]="'vertical'"
                      [title]="'PolicyNo' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('PolicyNo' | translate)"
                      [(value)]="search.policyNo"></app-property-input>

  <!-- 编号 -->
  <app-property-input *ngIf="search.module === 'UNDERWRITING'"
                      [displayMode]="'vertical'"
                      [title]="'UnderwritingNumber' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('UnderwritingNumber' | translate)"
                      [(value)]="search.underwritingNo"></app-property-input>

  <!-- 保险公司 -->
  <app-property-select [displayMode]="'vertical'"
                       [title]="'Insurer' | translate"
                       [placeholder]="('PleaseSelect' | translate) + ('Insurer' | translate)"
                       [(value)]="search.companyCode"
                       [options]="companyOptions"
                       (valueChange)="onProductCompanyChange($event)"></app-property-select>
  <!-- 产品 -->
  <app-property-pick [displayMode]="'vertical'"
                     [title]="'InsuranceProduct' | translate"
                     appThrottleClick (throttleClick)="onProductSelection()"
                     [placeholder]="'ProductSelect' | translate"
                     [(value)]="search.productName"></app-property-pick>
  <!-- 保单日期 -->
  <app-property-datetime-string [title]="'PolicyDate' | translate"
                                [displayMode]="'vertical'"
                                [formatter]="'yyyy-MM-dd'"
                                [(value)]="search.policyDate">
  </app-property-datetime-string>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="onSearch()">
          <em nz-icon nzType="search" nzTheme="outline"></em>
          <span>{{'Search' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
