<div>
    <div class="file-upload-box">
        <input #fileUpload hidden=true type="file" ng2FileSelect [uploader]="uploader"
                (change)="selectedImportFileOnChanged($event)"/>

        <button nz-button style="width: 100%; display: flex; justify-content: center; align-items: center"
                nzType="default"
                [nzLoading]="spinning"
                appThrottleClick (throttleClick)="onAddAttachment()">
            {{( !spinning ? 'UploadFile' : 'Uploading') | translate}}
<!--          <span *ngIf="!!uploader.queue && uploader.queue.length > 0">-->
<!--            <span *ngFor="let item of uploader.queue; let i = index">-->
<!--              <nz-progress *ngIf="item.progress > 0" [nzPercent]="item.progress" nzType="circle" [nzWidth]="24"-->
<!--                           style="margin-left: 10px"-->
<!--                           [nzStrokeColor]="{ '0%': '#108ee9', '100%': '#87d068' }">-->
<!--              </nz-progress>-->
<!--            </span>-->
<!--          </span>-->
        </button>
    </div>
</div>
