import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { ApiResponse, PagedResp } from '../api/types';
import { Observable } from 'rxjs/internal/Observable';
import { HttpParams } from '@angular/common/http';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { HttpService } from '../core/http.service';
import { Information, InformationListResp, InformationSearch } from './information-types';

@Injectable({
  providedIn: 'root'
})
export class InformationService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  /**
   * 资讯列表
   * @param search 查询条件
   */
  list(search: InformationSearch): Observable<PagedResp<InformationListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.title != null) {
      httpParams = httpParams.set('title', search.title.toString());
    }
    if (search.type != null) {
      httpParams = httpParams.set('type', search.type.toString());
    }
    if (search.startTime != null) {
      httpParams = httpParams.set('startTime', search.startTime.toString());
    }
    if (search.endTime != null) {
      httpParams = httpParams.set('endTime', search.endTime.toString());
    }
    if (search.tag != null) {
      httpParams = httpParams.set('tag', search.tag.toString());
    }
    if (search.effective != null) {
      httpParams = httpParams.set('effective', search.effective.toString());
    }
    if (search.companyCode != null) {
      httpParams = httpParams.set('companyCode', search.companyCode.toString());
    }
    if (search.regions != null) {
      httpParams = httpParams.set('regions', search.regions.toString());
    }
    if (search.productCode != null) {
      httpParams = httpParams.set('productCode', search.productCode.toString());
    }
    return this.http.get<ApiResponse<PagedResp<InformationListResp>>>(API.informationV3 + '/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 资讯列表 (过滤)
   * @param search 查询条件
   */
  newList(search: InformationSearch): Observable<PagedResp<InformationListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.title != null) {
      httpParams = httpParams.set('title', search.title.toString());
    }
    if (search.type != null) {
      httpParams = httpParams.set('type', search.type.toString());
    }
    if (search.startTime != null) {
      httpParams = httpParams.set('startTime', search.startTime.toString());
    }
    if (search.endTime != null) {
      httpParams = httpParams.set('endTime', search.endTime.toString());
    }
    if (search.tag != null) {
      httpParams = httpParams.set('tag', search.tag.toString());
    }
    if (search.regions != null) {
      httpParams = httpParams.set('regions', search.regions.toString());
    }
    if (search.companyCode != null) {
      httpParams = httpParams.set('companyCode', search.companyCode.toString());
    }
    if (search.effective != null) {
      httpParams = httpParams.set('effective', search.effective.toString());
    }
    if (search.productCode != null) {
      httpParams = httpParams.set('productCode', search.productCode.toString());
    }
    return this.http.get<ApiResponse<PagedResp<InformationListResp>>>(API.informationV3 + '/product-promotion-list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 资讯详情
   * @param id 资讯id
   */
  info(id: number): Observable<Information> {
    return this.http.get<ApiResponse<Information>>(API.informationV3 + '/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
