<div class="drawer-container">

  <div class="drawer-title" style="margin-bottom: 30px">
    {{'SwitchAccount' | translate}}
  </div>

  <div class="account-switch-desc">
    {{ 'TapItemToSwitchAccount' |translate }}
  </div>

  <div style="margin-top: 20px">
    <div *ngFor="let account of accounts; let i = index"
         class="account-item flex-row"
         [style.cursor]="isCurrent(account) ? 'normal' : 'pointer'"
         appThrottleClick (throttleClick)="onSwitchAccount(account)">
      <nz-avatar [nzSize]="60" nzIcon="user" [nzSrc]="account.passport.avatar"></nz-avatar>
      <div *ngIf="isCurrent(account)" style="position: absolute; top: 20px; right: 20px;">
        <div class="flex-row">
          <div class="status-circle"></div>
          <div>{{ 'CurrentLoginAccount' | translate }}</div>
        </div>
      </div>
      <div class="flex-column" style="margin-left: 10px; flex: 1">
        <div style="color: #000c17; font-weight: bold; font-size: 18px">{{ account.passport.name }}</div>
        <div>{{ 'OrganizationCode' | translate }} {{ account.instance.code }}</div>
      </div>
      <a *ngIf="!isCurrent(account)" nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate" appThrottleClick (throttleClick)="onDeleteAccount(account, i, tplDeleteTitle,tplDeleteContent);
      $event.stopPropagation();">
        <span nz-icon nzType="delete" nzTheme="outline" style="color: #e63757"></span> <span style='color:#e63757; margin-left: 5px'> {{'Delete' | translate}}</span>
      </a>
    </div>

    <div class="account-item flex-row" style="cursor: pointer" appThrottleClick (throttleClick)="onAddAccount()">
      <div class="add-icon">
        <i nz-icon nzType="plus" style="font-size: 18px" nzTheme="outline"></i>
      </div>
      <div style="margin-left: 10px; font-size: 18px">
        <div>{{ 'AddAccount' | translate }}</div>
      </div>
    </div>
  </div>
</div>

<ng-template #tplDeleteTitle>
  <span>
    {{ 'FriendlyReminder' | translate }}
  </span>
</ng-template>
<ng-template #tplDeleteContent>
  <span>
    {{ 'DeleteAccountTip' | translate: { name: accountToDelete?.passport?.name ?? "" } }}
  </span>
</ng-template>
