import { EventEmitter, Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { ApiResponse, Message, MessageListResp, MessageSearch, PagedResp } from '../api/types';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { MessageConfig, MessageRemind } from './message-types';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends BaseService {

  messageReceived: EventEmitter<Message> = new EventEmitter<Message>();

  messageDetail: EventEmitter<Message> = new EventEmitter<Message>();

  messageUnreadEvent: EventEmitter<any> = new EventEmitter<any>();

  messageConfigUpdated: EventEmitter<MessageConfig> = new EventEmitter<MessageConfig>();

  constructor(private http: HttpService) {
    super();
  }

  center(): Observable<MessageListResp[]> {
    return this.http.get<ApiResponse<MessageListResp[]>>(API.messageV3 + '/center', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  bells(): Observable<number> {
    return this.http.get<ApiResponse<number>>(API.messageV3 + '/bells', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 消息列表
   * @param search 查询条件
   */
  list(search: MessageSearch): Observable<PagedResp<Message>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.type != null) {
      httpParams = httpParams.set('type', search.type.toString());
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status.toString());
    }
    return this.http.get<ApiResponse<PagedResp<Message>>>(API.messageV3, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  overtime(id: number): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.messageV3 + '/overtime/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 批量已读消息
   * @param ids 消息列表 number[]
   */
  updateMessageReads(ids): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.messageV3 + '/reads', ids, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 清除未读消息
   * @param param 传参
   */
  allMessageReads(param): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.messageV3 + '/reads-all', param, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 一键删除
   * @param param 传参
   */
  deleteAllMessage(param): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.messageV3 + '/delete-all', param, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 获取消息设置
   */
  getMessageConfig(): Observable<MessageConfig> {
    return this.http.get<ApiResponse<MessageConfig>>(API.messageV3 + '/message-config', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 修改消息设置
   * @param config 消息设置参數
   */
  updateMessageConfig(config: MessageConfig): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.messageV3 + '/message-config', config, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 读消息，并返回未读消息数
   * @param param 传参
   */
  putMessageReads(param): Observable<any> {
    return this.http.put<ApiResponse<any>>(API.messageV3 + '/natural-read', param, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 获取账户某条记录的提醒消息
   * @param param 传参
   */
  getMessageRemind(param): Observable<MessageRemind> {
    let httpParams = new HttpParams();
    if (param.type != null) {
      httpParams = httpParams.set('type', param.type.toString());
    }
    if (param.key != null) {
      httpParams = httpParams.set('key', param.key.toString());
    }
    return this.http.get<ApiResponse<MessageRemind>>(API.messageV3 + '/remind', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

}
