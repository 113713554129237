import { Component, OnInit } from '@angular/core';
import { WorkbenchService } from '../workbench/workbench.service';
import { ModuleName } from '../api/types';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-main',
  templateUrl: './product-main.component.html',
  styleUrls: ['./product-main.component.less']
})
export class ProductMainComponent implements OnInit {

  tabIndex = 0;

  constructor(private router: ActivatedRoute,
              public workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    this.router
      .queryParams
      .subscribe(params => {
        this.tabIndex = JSON.parse(params['tabIndex']);
      });

  }

  isProductEnabled(): boolean {
    return this.workbenchService.moduleEnabled(ModuleName.PRODUCT);
  }

  isCompanyEnabled(): boolean {
    return this.workbenchService.moduleEnabled(ModuleName.COMPANY);
  }

}
