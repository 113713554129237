import { Component, EventEmitter, OnInit } from '@angular/core';
import { Product, QuotationFieldRequirement } from '../../product/product-types';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ProductSelectionComponent } from '../../shared/component/product-selection/product-selection.component';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { MetadataService } from '../../shared/service/metadata.service';
import { SmokeCondition, SumRangeResp } from '../../api/types';
import { Premium, SumRange } from '../quotation-types';
import { QuotationService } from '../quotation.service';
import { arrayToMap } from '../../shared/utils/collections';
import { Router } from '@angular/router';
import { NzSliderValue } from "ng-zorro-antd/slider";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-quotations',
  templateUrl: './quotations.component.html',
  styleUrls: ['./quotations.component.less']
})
export class QuotationsComponent implements OnInit {
  spinning = false;
  product: Product = new Product();
  premium: Premium = new Premium(); // 试算参数
  sumRange: SumRange = new SumRange();
  ageValue: SumRangeResp = new SumRangeResp();
  currencyValue: SumRangeResp = new SumRangeResp();
  calculationRes: any;
  edition: string;

  amountParamChange: EventEmitter<any> = new EventEmitter<any>();


  genderOptions: PropertySelectOption[] = [];
  smokeOptions: PropertySelectOption[] = [];
  currencyOptions: PropertySelectOption[] = [];
  paymentTermOptions: PropertySelectOption[] = [];
  deductibleOptions: PropertySelectOption[] = [];
  medicalLevelOptions: PropertySelectOption[] = [];
  guaranteedAreaOptions: PropertySelectOption[] = [];
  supplementaryBenefitOptions: PropertySelectOption[] = [];

  requirements: Map<string, QuotationFieldRequirement> = new Map<string, QuotationFieldRequirement>();

  constructor(private router: Router,
              private drawerService: NzDrawerService,
              private metadataService: MetadataService,
              private quotationService: QuotationService) {
    const state = this.router.getCurrentNavigation()?.extras?.state;
    if (!!state && state.product) {
      this.productSelected(state.product);
    }
  }

  ngOnInit(): void {

    const genders = this.metadataService.values('gender');
    for (const gender of genders) {
      this.genderOptions.push(new PropertySelectOption(gender.value, gender.key));
    }

    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'S'), SmokeCondition.S));
    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'NS'), SmokeCondition.NS));

    this.amountParamChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          this.onCalculation();
        }
      );
  }

  onReload(): void {
    // console.log('-');
    this.product = new Product();
    this.premium = new Premium(); // 试算参数
    this.sumRange = new SumRange();
    this.calculationRes = undefined;
    this.edition = null;
  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: any }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        copyProduct: this.product
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.search.quotable = true;
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.productSelected(product);
            drawerRef.close();
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  productSelected(product: Product): void {
    this.premium = new Premium();
    this.product = product;
    // console.log(this.product);
    this.premium.productCode = this.product.productCode;
    this.sumRange.productCode = this.product.productCode;
    this.getAgeValue();

    this.currencyOptions = [];
    this.product.currencies?.forEach(currency => {
      this.currencyOptions.push(new PropertySelectOption(currency, currency));
    });
    this.premium.currency = this.product.currencies ? this.product.currencies[0] : null;

    this.paymentTermOptions = [];
    this.product.paymentTerm?.forEach(term => {
      this.paymentTermOptions.push(new PropertySelectOption(term, term));
    });
    this.premium.premiumTerm = null;

    if (this.product.categoryCode === 'MEDICAL') {

      this.setupGuaranteedAreaOptions();
      this.setupMedicalLevelOptions();
      this.setupDeductibleOptions();
      this.setupSupplementaryBenefitOptions();

    } else { // not medical
      this.premium.level = null;
      this.premium.deductible = null;
      this.premium.region = null;
      this.premium.supplementaryBenefits = [];
    }

    const quotationFieldRequirements = this.product.quotationFieldRequirements;

    this.requirements = arrayToMap(quotationFieldRequirements, (v => {
      return v.field.toString();
    }));

  }

  get ageMin(): number {
    return this.ageValue?.min ?? 0;
  }

  get ageMax(): number {
    // return this.ageValue?.max;
    return 100;
  }

  get sumInsuredMin(): number {
    return this.currencyValue?.min;
  }

  get sumInsuredMax(): number {
    return this.currencyValue?.max;
  }

  setupGuaranteedAreaOptions(): void {
    this.guaranteedAreaOptions = [];
    if (this.product.medicalOptions?.regions) {
      for (const region of this.product.medicalOptions?.regions) {
        this.guaranteedAreaOptions.push(new PropertySelectOption(this.metadataService.translate('medicalRegion', region), region));
      }
    }
  }

  setupMedicalLevelOptions(): void {
    if (this.product.medicalOptions?.levels) {
      this.medicalLevelOptions = [];
      for (const level of this.product.medicalOptions?.levels) {
        this.medicalLevelOptions.push(new PropertySelectOption(this.metadataService.translate('medicalLevel', level), level));
      }
    }
  }

  setupDeductibleOptions(): void {
    if (this.product.medicalOptions?.deductibles) {
      this.deductibleOptions = [];
      this.product.medicalOptions?.deductibles.forEach((deductible, index, array) => {
        if (deductible.currency === this.premium.currency) {
          for (const amount of deductible.amounts) {
            this.deductibleOptions.push(new PropertySelectOption(amount.toString(), amount));
          }
        }
      });
    }
  }

  setupSupplementaryBenefitOptions(): void {
    if (this.product.medicalOptions?.supplementaryBenefits) {
      this.supplementaryBenefitOptions = [];
      for (const supplementaryBenefit of this.product.medicalOptions?.supplementaryBenefits) {
        this.supplementaryBenefitOptions.push(new PropertySelectOption(this.metadataService.translate('supplementaryBenefit', supplementaryBenefit), supplementaryBenefit));
      }
    }
  }

  getAgeValue(): void {
    this.quotationService.quotationsGetValue(this.sumRange, 'Age')
      .subscribe(
        data => {
          // console.log(data);
          this.ageValue = data;
        },
        error => {
        }
      );
  }

  getCurrencyValue(): void {
    this.quotationService.quotationsGetValue(this.sumRange, 'Currency')
      .subscribe(
        data => {
          // console.log(data);
          this.currencyValue = data;
        },
        error => {
        }
      );
  }

  onCurrencyChange(currency): void {
    // console.log(currency);
    this.sumRange.currency = currency;
    if (currency != null) {
      this.setupDeductibleOptions();
      this.getCurrencyValue();
    }
  }

  onAmountChange(val: NzSliderValue): void {
    this.onCalculation();
  }

  onAgeToChange(val: NzSliderValue): void {
    this.onCalculation();
  }

  // onAmountChange(val): void {
  //   console.log(val);
  //   if (val > this.sumInsuredMax){
  //     console.log('-');
  //     this.premium.amount = this.sumInsuredMax.toString();
  //   }
  //   if (val !== undefined) {
  //     return this.premium.amount = parseInt(val, 0).toString();
  //   }
  // }

  onCalculation(): void {
    if (!this.canQuote) {
      return;
    }
    if (this.premium.amount !== undefined && this.premium.amount !== null && this.premium.amount !== '') {
      this.premium.amount = parseInt(this.premium.amount, 0).toString();
      if (Number(this.premium.amount) > this.sumInsuredMax) {
        this.premium.amount = this.sumInsuredMax.toString();
      }
    }
    this.quotationService.trial(this.premium)
      .subscribe(
        data => {
          this.edition = data.quotationVersion;
          this.calculationRes = this.formatNum(data.total);
        },
        error => {
          console.log(error);
        }
      );
  }

  formatNum(num): number {
    if (!isNaN(num)) {
      return ((num + '').indexOf('.') !== -1) ? num : num.toFixed(2);
    }
  }

  getRequirement(field: string): boolean {
    const requirement = this.requirements.get(field);
    if (!requirement) {
      return false;
    }

    return requirement.required;
  }

  get canQuote(): boolean {
    if (this.spinning) {
      return false;
    }
    if (this.premium.productCode === undefined) {
      return false;
    }

    if (this.premium.currency === undefined && this.getRequirement('Currency')) {
      return false;
    }
    if (this.premium.gender === undefined && this.getRequirement('Gender')) {
      return false;
    }
    if (this.premium.smokeCondition === undefined && this.getRequirement('SmokeCondition')) {
      return false;
    }
    if (this.premium.currency === undefined && this.getRequirement('Currency')) {
      return false;
    }
    if (this.premium.premiumTerm === undefined && this.getRequirement('PremiumTerm')) {
      return false;
    }

    if (this.product.categoryCode === 'MEDICAL') {
      if (this.premium.level === undefined && this.getRequirement('MedicalLevel')) {
        return false;
      }
      if (this.premium.deductible === undefined && this.getRequirement('MedicalDeductible')) {
        return false;
      }
      if (this.premium.region === undefined && this.getRequirement('MedicalRegion')) {
        return false;
      }
    }
    return true;
  }

  amountChange(): void{
    this.amountParamChange.emit('');
  }

}

