import {Injectable} from '@angular/core';
import {BaseService} from '../base/base.service';
import {HttpService} from '../core/http.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResponse} from '../api/types';
import {API} from '../api/api';
import {map} from 'rxjs/operators';
import {ScheduleData, ScheduleItem, ScheduleParams} from './schedule-type';
import {plainToInstance} from 'class-transformer';
import {Fna} from '../fna/fna-types';


@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  /**
   * 当月日程
   * @param search 条件
   */
  list(time: number): Observable<ScheduleData> {
    let httpParams = new HttpParams();
    if (time != null) {
      httpParams = httpParams.set('time', time.toString());
    }
    return this.http.get<ApiResponse<ScheduleData>>(API.scheduleV3 + '/moth-list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(ScheduleData, data.body.data, {exposeDefaultValues: true});
    }));
  }

  /**
   * 获取日程详细信息
   */
  info(ids: string): Observable<ScheduleItem[]> {
    return this.http.get<ApiResponse<ScheduleItem[]>>(API.scheduleV3 + '/info/' + ids, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 创建日程
   * @param params ScheduleParams
   */
  create(params: ScheduleParams): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.scheduleV3 + '/create', params, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 删除日程
   * @param id number
   */
  delete(id: number): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.scheduleV3 + '/delete/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新日程
   * @param schedule ScheduleParams
   */
  update(schedule: ScheduleParams): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.scheduleV3 + '/update', schedule, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

}
