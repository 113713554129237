<div class="fna-overview">
  <div class="fna-overview-header">
    <button nz-button nzType="default"
            appThrottleClick (throttleClick)="close()">
      <!--            <img alt="" width="20" src="assets/images/ic_left.svg">-->
      {{'Close' | translate}}
    </button>
  </div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin class="fna-overview-main" [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="content">
      <div class="name">
        <img class="logo" *ngIf="returnProduct().logo"
             [src]="returnProduct().logo" alt=""/>
        <div *ngIf="!returnProduct().logo"
             style="border-radius: 50%; background-color: #d9d9d9" class="logo"></div>
        <div class="info">
          <div class="nickName">
            <span>{{fnaInfo.basicInformation?.chineseName || 'N/A'}}</span>
            <span style="margin-left: 10px;">{{fnaInfo.basicInformation?.englishName || 'N/A'}}</span>
          </div>
          <div class="companyName">
            {{returnProduct().productName || 'N/A'}}
          </div>
        </div>
      </div>
      <div class="info" style="align-items: end;">
        <div class="status">
          <div style="font-size: 16px; font-weight: 500"
               [class]="fnaInfo.status === 'COMPLETED' ? 'green' : 'orange'">
            <span style="margin-right: 4px;">●</span>
            <span>
              {{fnaInfo.status | metadataTranslate: 'fnaStatus'}}
            </span>
          </div>
        </div>
        <div class="fnaNo">#{{fnaInfo.id || 'N/A'}}</div>
      </div>
    </div>
    <div class="tab">
      <nz-tabset [nzCentered]="true" [(nzSelectedIndex)]="selectedIndex">
        <nz-tab [nzTitle]=myDeals>
          <div class="steps">
            <div class="step_item" appThrottleClick (throttleClick)="toDetail(1)">
              <img alt="" class="text-icon" src="assets/images/ic_step_1.svg">
              <span class="step_name">{{'BasicInformationCMG' | translate}}</span>
              <span class="step_status" [class]="fnaInfo.basicStatus === 'COMPLETED' ? 'green' : 'orange'">
                            {{fnaInfo.basicStatus | metadataTranslate: 'fnaStatus'}}
                          </span>
              <i nz-icon nzType="right" style="color: #4B84E5;" nzTheme="outline"></i>
            </div>
            <div class="step_item" [style]="fnaId ? '' : 'cursor: no-drop;'" appThrottleClick
                 (throttleClick)="toDetail(2)">
              <img alt="" class="text-icon" width="20" src="assets/images/ic_step_2.svg">
              <span class="step_name">{{('FNAQuestions' | translate)}}</span>
              <span class="step_status" [class]="fnaInfo.fnaStatus === 'COMPLETED' ? 'green' : 'orange'">
                            {{fnaInfo.fnaStatus | metadataTranslate: 'fnaStatus'}}
                          </span>
              <i nz-icon nzType="right" style="color: #4B84E5;" nzTheme="outline"></i>
            </div>
            <div class="step_item" [style]="fnaId ? '' : 'cursor: no-drop;'" appThrottleClick
                 (throttleClick)="toDetail(3)">
              <img alt="" class="text-icon" width="20" src="assets/images/ic_step_3.svg">
              <span class="step_name">{{'ProductsIntroduction' | translate}}</span>
              <span class="step_status" [class]="fnaInfo.productStatus === 'COMPLETED' ? 'green' : 'orange'">
                            {{fnaInfo.productStatus | metadataTranslate: 'fnaStatus'}}
                          </span>
              <i nz-icon nzType="right" style="color: #4B84E5;" nzTheme="outline"></i>
            </div>
            <div class="step_item" [style]="fnaId ? '' : 'cursor: no-drop;'" appThrottleClick
                 (throttleClick)="toDetail(4)">
              <img alt="" class="text-icon" width="20" src="assets/images/ic_step_4.svg">
              <span class="step_name">{{'ESignatureCMG' | translate}}</span>
              <span class="step_status" [class]="fnaInfo.signatureStatus === 'SIGNED' ? 'green' : 'orange'">
                            {{fnaInfo.signatureStatus | metadataTranslate: 'fnaSignedStatus'}}
                          </span>
              <i nz-icon nzType="right" style="color: #4B84E5;" nzTheme="outline"></i>
            </div>
            <div class="step_item" [style]="fnaId ? '' : 'cursor: no-drop;'" appThrottleClick
                 (throttleClick)="toDetail(5)">
              <img alt="" class="text-icon" width="20" src="assets/images/ic_step_5.svg">
              <span class="step_name">{{'FormsSelection' | translate}}</span>
              <span class="step_status"
                    [class]="fnaInfo.categoryNos && fnaInfo.categoryNos.length ? 'green' : 'orange'">
                            {{fnaInfo.categoryNos && fnaInfo.categoryNos.length ? ('Completed' | translate) : ('Select' | translate) }}
                          </span>
              <i nz-icon nzType="right" style="color: #4B84E5;" nzTheme="outline"></i>
            </div>
          </div>
        </nz-tab>
        <ng-template #myDeals>
          {{'FNAOverview' | translate}}
        </ng-template>

        <nz-tab [nzTitle]=channelDeals>
          <div class="steps step_wrap">
            <div class="step_item" style="width: 100%;"
                 appThrottleClick (throttleClick)="showProposalSelection()">
              <span class="step_name">{{'RelatedProposal' | translate}}</span>
              <span class="step_status"
                    [class]="fnaInfo.proposalId ? '' : 'orange'">
                            {{ fnaInfo.proposalId ? fnaInfo.proposalId : ('Choose' | translate)}}</span>
              <i nz-icon nzType="right" style="color: #4B84E5;" nzTheme="outline"></i>
            </div>
            <div class="step_item"
                 appThrottleClick (throttleClick)="onUnderwritingDetail()"
                 style="width: 100%;"
                 [style]="fnaInfo.underwritingNo ? '' : 'cursor: auto;'">
              <span class="step_name">{{'RelatedUnderwriting' | translate}}</span>
              <span class="step_status">{{ fnaInfo.underwritingNo || 'N/A'}}</span>
              <i *ngIf="fnaInfo.underwritingNo" nz-icon nzType="right" style="color: #4B84E5;"
                 nzTheme="outline"></i>
            </div>
            <div class="step_item" style="width: 100%;"
                 appThrottleClick (throttleClick)="onPolicyDetail()"
                 [style]="fnaInfo.policyNo ? '' : 'cursor: auto;'">
              <span class="step_name">{{'RelatedPolicyCMG' | translate}}</span>
              <span class="step_status">{{ fnaInfo.policyNo || 'N/A'}}</span>
              <i *ngIf="fnaInfo.policyNo" nz-icon nzType="right" style="color: #4B84E5;" nzTheme="outline"></i>
            </div>
          </div>
        </nz-tab>
        <ng-template #channelDeals>
          {{'RelatedInformation' | translate}}
        </ng-template>
      </nz-tabset>
    </div>
  </nz-spin>
  <div class="create-footer">
    <div class="footer-tips" *ngIf="selectedIndex == 0">
      {{'FNAOverviewTips' | translate}}
    </div>
    <div style="border-top: 1px solid #e6e6e6; margin: 8px 0 8px 0;"></div>
    <button *ngIf="fnaId" style="" nz-button nzType="primary"
            appThrottleClick (throttleClick)="onReuse()">{{'Reuse' | translate}}</button>
  </div>
</div>
