import {Component, Input, OnInit} from '@angular/core';
import {ChooseProduct, ChooseProductInfo, FnaAssessAdvice, NewFnaDetail} from '../../new-fna/new-fna-types';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {
  ProductSelectionComponent,
  ProductSelectionType
} from '../../shared/component/product-selection/product-selection.component';
import {SellStatus} from '../../api/types';

@Component({
  selector: 'app-zuu-template-introduction',
  templateUrl: './zuu-template-introduction.component.html',
  styleUrls: ['./zuu-template-introduction.component.less']
})
export class ZuuTemplateIntroductionComponent implements OnInit {
  @Input()
  fnaInfo: NewFnaDetail = new NewFnaDetail();
  chooseProduct: ChooseProduct[] = [];
  chooseProductCopy: ChooseProduct[] = [];
  fnaAssessAdvice: FnaAssessAdvice = new FnaAssessAdvice();
  fnaAssessAdviceCopy: FnaAssessAdvice = new FnaAssessAdvice();
  constructor(private drawerService: NzDrawerService) { }

  ngOnInit(): void {
  }

  setArr(): void {
    this.chooseProduct = [];
    this.fnaInfo.assessAdvice?.forEach(item => {
      this.fnaAssessAdvice[item.key] = item.value;
    });
    // 默认选中否 且选中答案A
    // if (!this.fnaInfo.assessAdvice && !this.fnaInfo.assessAdvice?.length) {
    //   this.fnaAssessAdvice.EVALUATION_01_No = true;
    //   this.fnaAssessAdvice.EVALUATION_01_No_A = true;
    // }
    if (!this.fnaInfo.chooseProduct || !this.fnaInfo.chooseProduct.length) {
      const productInfo = {
        productName: null,
        productCode: null,
        premiumPaymentPeriod: null,
        protectionPeriod: null,
        logo: null,
        companyCode: null,
        companyName: null,
      };
      const obj = {
        choose: false,
        fnaProduct: productInfo,
        riderProduct: [],
        logo: ''
      };
      const copyInfo = JSON.parse(JSON.stringify(productInfo));
      obj.riderProduct.push(copyInfo);
      const copyObj = JSON.parse(JSON.stringify(obj));
      this.chooseProduct.push(obj);
      this.chooseProduct.push(copyObj);
    } else if (this.fnaInfo.chooseProduct.length < 2) {
      const productInfo = {
        productName: null,
        productCode: null,
        premiumPaymentPeriod: null,
        protectionPeriod: null,
        logo: null,
        companyCode: null,
        companyName: null,
      };
      const obj = {
        choose: false,
        fnaProduct: productInfo,
        riderProduct: [],
        logo: ''
      };
      const copyInfo = JSON.parse(JSON.stringify(productInfo));
      obj.riderProduct.push(copyInfo);
      this.fnaInfo.chooseProduct.map(item => {
        const obj1 = {
          choose: item.choose,
          fnaProduct: item.fnaProduct || new ChooseProductInfo(),
          riderProduct: item.riderProduct && item.riderProduct.length ? item.riderProduct : [JSON.parse(JSON.stringify(productInfo))],
          logo: ''
        };
        this.chooseProduct.push(JSON.parse(JSON.stringify(obj1)));
      });
      this.chooseProduct.push(obj);
    } else {
      const productInfo = {
        productName: null,
        productCode: null,
        premiumPaymentPeriod: null,
        logo: null,
        protectionPeriod: null,
        companyCode: null,
        companyName: null,
      };
      const obj = {
        choose: false,
        fnaProduct: productInfo,
        riderProduct: [],
        logo: ''
      };
      const copyInfo = JSON.parse(JSON.stringify(productInfo));
      obj.riderProduct.push(copyInfo);
      this.fnaInfo.chooseProduct.map(item => {
        const obj1 = {
          choose: item.choose,
          fnaProduct: item.fnaProduct || new ChooseProductInfo(),
          riderProduct: item.riderProduct && item.riderProduct.length ? item.riderProduct : [JSON.parse(JSON.stringify(productInfo))],
          logo: ''
        };
        this.chooseProduct.push(JSON.parse(JSON.stringify(obj1)));
      });
    }
    this.chooseProductCopy = JSON.parse(JSON.stringify(this.chooseProduct));
    this.fnaAssessAdviceCopy = JSON.parse(JSON.stringify(this.fnaAssessAdvice));
  }

  onAddRider(i): void {
    const productInfo = {
      productName: null,
      productCode: null,
      premiumPaymentPeriod: null,
      logo: null,
      protectionPeriod: null,
      companyCode: null,
      companyName: null,
    };
    const arr = JSON.parse(JSON.stringify(this.chooseProduct));
    arr[i].riderProduct.push(productInfo);
    this.chooseProduct = JSON.parse(JSON.stringify(arr));
  }

  onSelectedChoose(i): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const arr = JSON.parse(JSON.stringify(this.chooseProduct));
      let flag = false;
      arr.map((item, index) => {
        if (index === i && item.fnaProduct.productCode) {
          flag = true;
          item.choose = !item.choose;
        } else {
          item.choose = false;
        }
      });
      // arr[i].choose = !arr[i].choose;
      if (flag) {
        this.chooseProduct = JSON.parse(JSON.stringify(arr));
      }
    }
  }

  onPlayerClick(i): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const arr = JSON.parse(JSON.stringify(this.chooseProduct));
      // arr[i].choose = !arr[i].choose;
      const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
        nzWidth: 800,
        nzContent: ProductSelectionComponent,
        nzContentParams: {
          type: ProductSelectionType.DEFAULT,
          isBasic: true
        }
      });
      drawerRef.afterOpen.subscribe(() => {
        const selectionComponent = drawerRef.getContentComponent();
        selectionComponent.drawerRef = drawerRef;
        selectionComponent.search.sellStatus = SellStatus.SELLING;
        selectionComponent.productSelected
          .subscribe(
            product => {
              console.log(product);
              arr[i].fnaProduct.productName = product.productName;
              arr[i].fnaProduct.productCode = product.productCode;
              arr[i].fnaProduct.companyCode = product.companyCode;
              arr[i].fnaProduct.companyName = product.companyName;
              this.chooseProduct = JSON.parse(JSON.stringify(arr));
            }
          );
      });
      drawerRef.afterClose.subscribe(data => {
      });
    }
  }

  onRiderClick(i, j): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const arr = JSON.parse(JSON.stringify(this.chooseProduct));
      // arr[i].choose = !arr[i].choose;
      if (!arr[i].fnaProduct.productCode) {
        return;
      }
      const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
        nzWidth: 800,
        nzContent: ProductSelectionComponent,
        nzContentParams: {
          type: ProductSelectionType.RIDER,
          copyProduct: arr[i].fnaProduct,
          recommendProduct: arr[i].fnaProduct,
        }
      });

      drawerRef.afterOpen.subscribe(() => {
        const selectionComponent = drawerRef.getContentComponent();
        // if (!this.isNewFna) {
        //   selectionComponent.search.companyCode = this.primaryProduct.companyCode;
        // }
        selectionComponent.productSelected
          .subscribe(
            product => {
              arr[i].riderProduct[j].productName = product.productName;
              arr[i].riderProduct[j].productCode = product.productCode;
              this.chooseProduct = JSON.parse(JSON.stringify(arr));
              drawerRef.close();
            }
          );

      });

      drawerRef.afterClose.subscribe(data => {
      });
    }

  }

  onDeleChoose(i): void {
    console.log(i);
    const arr = JSON.parse(JSON.stringify(this.chooseProduct));
    arr.splice(i, 1);
    this.chooseProduct = JSON.parse(JSON.stringify(arr));
  }

  onDeleteRider(i, j): void {
    const arr = JSON.parse(JSON.stringify(this.chooseProduct));
    arr[i].riderProduct.splice(j, 1);
    this.chooseProduct = JSON.parse(JSON.stringify(arr));
  }

  // 清空整组值
  onClearProductClick(i): void {
    console.log(i);
    const arr = JSON.parse(JSON.stringify(this.chooseProduct));
    const productInfo = {
      productName: null,
      productCode: null,
      premiumPaymentPeriod: null,
      logo: null,
      protectionPeriod: null,
      companyCode: null,
      companyName: null,
    };
    const obj = {
      choose: false,
      fnaProduct: productInfo,
      riderProduct: [],
      logo: ''
    };
    const copyInfo = JSON.parse(JSON.stringify(productInfo));
    obj.riderProduct.push(copyInfo);
    arr[i] = obj;
    this.chooseProduct = JSON.parse(JSON.stringify(arr));
  }

  onClearRiderClick(i, j): void {
    const arr = JSON.parse(JSON.stringify(this.chooseProduct));
    const productInfo = {
      productName: null,
      productCode: null,
      premiumPaymentPeriod: null,
      logo: null,
      protectionPeriod: null,
      companyCode: null,
      companyName: null,
    };
    arr[i].riderProduct[j] = productInfo;
    this.chooseProduct = JSON.parse(JSON.stringify(arr));
  }

  onAddChoose(): void {
    const productInfo = {
      productName: null,
      productCode: null,
      premiumPaymentPeriod: null,
      protectionPeriod: null,
      logo: null,
      companyCode: null,
      companyName: null,
    };
    const obj = {
      choose: false,
      fnaProduct: productInfo,
      riderProduct: [productInfo],
    };
    const arr = JSON.parse(JSON.stringify(this.chooseProduct));
    arr.push(obj);
    this.chooseProduct = JSON.parse(JSON.stringify(arr));
  }

  log(value: string[], type: string): void {
    console.log(value);
    if (type === 'EVALUATION_01_No') {
      this.fnaAssessAdvice.EVALUATION_01_No = !this.fnaAssessAdvice.EVALUATION_01_No;
      this.fnaAssessAdvice.EVALUATION_01_Yes = this.fnaAssessAdvice.EVALUATION_01_No ? false : false;
    } else {
      this.fnaAssessAdvice.EVALUATION_01_Yes = !this.fnaAssessAdvice.EVALUATION_01_Yes;
      this.fnaAssessAdvice.EVALUATION_01_No = this.fnaAssessAdvice.EVALUATION_01_Yes ? false : false;
    }
  }

  getReason(): boolean {
    return this.chooseProduct.some(item => item.choose && !!item.fnaProduct.productCode);
  }
}
