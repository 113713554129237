import { Component, OnInit } from '@angular/core';
import { LoginByCodeReq } from '../../api/types';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-login-by-code',
  templateUrl: './login-by-code.component.html',
  styleUrls: ['./login-by-code.component.less']
})
export class LoginByCodeComponent implements OnInit {

  loginReq: LoginByCodeReq = {accountName: '', code: ''};

  logging = false;

  thresholdSeconds = 10;
  leftSeconds = 0;

  countDownTimer: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.countDownTimer = timer(100, 1000)
      .pipe(map(i => this.thresholdSeconds - i))
      // .pipe(take(this.thresholdSeconds + 1))
      .subscribe(i => {
        this.leftSeconds = i;
        if (i === 0) {
          this.countDownTimer.unsubscribe();
        }
      });
  }


  loginByCode(): void {
    this.logging = true;
    this.authService
      .loginByCode(this.loginReq)
      .subscribe(
        data => {
          this.logging = false;
        },
        error => {
          this.logging = false;
        });
  }

  onAccounts(): void {

  }

  onLoginByAccount(): void {
    this.router.navigate(['/auth/login-by-account']).then();
  }
}
