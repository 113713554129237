<div class="instance-code-container">
  <div nz-row nzJustify="center" style="width: 100%; height: 100%;">
    <div nz-col [nzSpan]="12" class="instance-code-column">
      <form (ngSubmit)="checkInstance()" style="width: 100%" class="instance-code-column">
        <img width="150" [src]="instance.logo || 'assets/images/logo.png'" style="margin-bottom: 40px">
        <div class="instance-code-sub-title" style="margin-bottom: 10px">{{ 'OrgCode' | translate
          }}
        </div>
        <div style="width: 50%;">
          <nz-input-group [nzSuffix]="suffixIconAccount" class="input-group">
            <input required [(ngModel)]="code" id="code" name="code" autofocus="autofocus"
                   class="instance-code-input"/>
          </nz-input-group>
          <ng-template #suffixIconAccount>
            <!--                        <i style="font-size: 20px" nz-icon nzType="user-switch"></i>-->
          </ng-template>
        </div>
        <div style="margin-top: 60px;">
          <button style="width: 60px; height: 60px; margin-left: 10px;" type="submit" [nzLoading]="checking"
                  [disabled]="code.length === 0"
                  nz-button nzType="default" nzShape="circle">
            <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div style="position: absolute; right: 60px; top: 50px">
    <a *ngIf="accounts && accounts.length > 0" nz-button nzType="link" nzSize="small"
       style="color: #666666; font-size: 30px;"
       nz-tooltip [nzTooltipTitle]="'AccountSwitch' | translate"
       appThrottleClick (throttleClick)="onAccountSwitch(); $event.stopPropagation();">
      <i nz-icon nzType="user-switch" style="color: #666666; font-size: 30px;" nzTheme="outline"></i>
    </a>
  </div>
</div>
