import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ProposalList, SelectProposalList} from '../../proposal-compare/proposal-compare';
import {CreateParam, SelectListQuery} from '../proposal-custom-types';
import {ProposalCustomService} from '../proposal-custom.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {ProposalCustomMultiSelectionComponent} from '../proposal-custom-selected/proposal-custom-multi-selection.component';
import { ProposalCustomSelectionComponent } from '../proposal-custom-selection/proposal-custom-selection.component';
import { Proposal } from '../../proposal/proposal-types';

@Component({
  selector: 'app-proposal-custom-create',
  templateUrl: './proposal-custom-create.component.html',
  styleUrls: ['./proposal-custom-create.component.less']
})
export class ProposalCustomCreateComponent implements OnInit {
  loading = false;
  listArr: Proposal[] = [];
  createParam: CreateParam = new CreateParam();
  @Output()
  proposalCustomSave: EventEmitter<any> = new EventEmitter<any>();

  constructor(private proposalCustomService: ProposalCustomService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
  }

  onSelect(): void {
    const search: SelectListQuery = new SelectListQuery();
    this.proposalCustomService.selectList(search)
      .subscribe(proposals => {
        const drawerRef = this.drawerService.create<ProposalCustomSelectionComponent, {}, string>({
          nzWidth: 800,
          nzContent: ProposalCustomSelectionComponent,
          nzContentParams: {
            search,
            proposals,
          }
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.proposalSelected.subscribe(proposal => {
            // 只支持一个计划书，不再支持多个计划书
            this.listArr = [];
            this.listArr.push(proposal);
            this.createParam.proposalIds = [proposal.proposalId];
            drawerRef.close();
          });
        });

        drawerRef.afterClose.subscribe(data => {
          const component = drawerRef.getContentComponent();
        });
      }, error => {});
  }

  onSave(): void {
    if (!this.canCreate) {
      return;
    }
    this.proposalCustomService.add(this.createParam)
      .subscribe(data => {
        this.proposalCustomSave.emit();
      }, error => {});
  }

  onDelete(proposalId: string, i: number): void {
    this.listArr.splice(i, 1);
  }

  get canCreate(): boolean {
    if (this.loading) {
      return false;
    }
    if (!this.createParam.name) {
      return false;
    }
    return true;
  }

}
