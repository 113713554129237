import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HedgeParams, HedgePlan, Proposal, Riders } from '../../proposal-types';
import { ProductListResp, QuotationField, QuotationFieldRequirement } from '../../../product/product-types';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { MetadataService } from '../../../shared/service/metadata.service';
import { ProposalService } from '../../proposal.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { HedgePreviewComponent } from '../hedge-preview/hedge-preview.component';

@Component({
  selector: 'app-proposal-hedge-plan',
  templateUrl: './proposal-hedge-plan.component.html',
  styleUrls: ['./proposal-hedge-plan.component.less']
})
export class ProposalHedgePlanComponent implements OnInit {
  applyReq: HedgePlan = new HedgePlan();
  hedgeArrData: ProductListResp[] = [];

  proposal: Proposal = new Proposal();

  isRequired: QuotationFieldRequirement[] = [];
  requiredObj: any;

  productCodeOptions: PropertySelectOption[] = [];
  guaranteedAreaOptions: PropertySelectOption[] = [];
  medicalLevelOptions: PropertySelectOption[] = [];
  deductibleOptions: PropertySelectOption[] = [];
  supplementaryBenefitOptions: PropertySelectOption[] = [];

  selectProduct: ProductListResp = new ProductListResp();
  @Output()
  saveHedge: EventEmitter<HedgePlan> = new EventEmitter<HedgePlan>();

  @Output()
  editSaveHedge: EventEmitter<HedgePlan> = new EventEmitter<HedgePlan>();

  constructor(private metadataService: MetadataService,
              private proposalService: ProposalService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
    this.productCodeOptions = [];
    this.hedgeArrData?.forEach(item => {
      this.productCodeOptions.push(new PropertySelectOption(item.productName, item.productCode));
    });
    this.applyReq.beginAge = this.proposal.age;
  }

  onProduct(e): void {
    if (e) {
      this.selectProduct = this.hedgeArrData.find(item => item.productCode === e);
      this.applyReq.productName = this.selectProduct.productName;
      if (this.selectProduct.quotationFieldRequirements && this.selectProduct.quotationFieldRequirements.length) {
        this.isRequired = JSON.parse(JSON.stringify(this.selectProduct.quotationFieldRequirements));
        // this.requiredObj(this.isRequired);
        this.requiredObj = this.isRequired.reduce((total, c) => ({ ...total, [c.field]: c.required }), {});
      }
      if (this.selectProduct.categoryCode === 'MEDICAL') {
        this.mapOptions(this.selectProduct);
      }
    }
  }

  mapOptions(res: ProductListResp): void {
    // 保障地區
    if (res.medicalOptions?.regions) {
      this.guaranteedAreaOptions = [];
      for (const region of res.medicalOptions?.regions) {
        this.guaranteedAreaOptions.push(new PropertySelectOption(this.metadataService.translate('medicalRegion', region), region));
      }
    }
    // 医疗险等级
    if (res.medicalOptions?.levels) {
      this.medicalLevelOptions = [];
      for (const level of res.medicalOptions?.levels) {
        this.medicalLevelOptions.push(new PropertySelectOption(this.metadataService.translate('medicalLevel', level), level));
      }
    }
    // 自付額
    if (res.medicalOptions?.deductibles) {
      this.deductibleOptions = [];
      res.medicalOptions?.deductibles.forEach((deductible, index, array) => {
        if (deductible.currency === this.proposal.currency) {
          for (const amount of deductible.amounts) {
            this.deductibleOptions.push(new PropertySelectOption(amount.toString(), amount));
          }
        }
      });
    }
    // 附加保障
    if (res.medicalOptions?.supplementaryBenefits) {
      this.supplementaryBenefitOptions = [];
      for (const supplementaryBenefit of res.medicalOptions?.supplementaryBenefits) {
        this.supplementaryBenefitOptions.push(new PropertySelectOption(this.metadataService.translate('supplementaryBenefit', supplementaryBenefit), supplementaryBenefit));
      }
    }
  }

  onSave(): void {
    this.saveHedge.emit(this.applyReq);
  }

  previewHedge(): void {
    const params = new HedgeParams();
    params.mainProductCode = this.proposal.productCode;
    params.gender = this.proposal.gender;
    params.currency = this.proposal.currency;
    params.paymentTerm = this.proposal.paymentTerm;
    params.age = this.proposal.age;
    params.medicalLevel = this.applyReq.medicalLevel;
    params.supplementaryBenefits = this.applyReq.supplementaryBenefits;
    params.endAge = this.applyReq.endAge;
    params.productCode = this.applyReq.productCode;
    params.coverageRegion = this.applyReq.coverageRegion;
    params.deductible = this.applyReq.deductible;

    console.log('preview');
    this.proposalService.viewHedge(params)
      .subscribe(res => {
        console.log(res);
        const drawerRef = this.drawerService.create<HedgePreviewComponent, { value: string }, string>({
          nzWidth: 500,
          nzContent: HedgePreviewComponent,
          nzContentParams: {
            hedgeData: JSON.parse(JSON.stringify(res))
          }
        });
      });
  }

}
