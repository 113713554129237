<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{'ContactInformation' | translate}}
  </div>
  <div *ngIf="signatureStatus == 'SIGNED'" class="section-wrapper">
    <app-property-text [title]="'FullName' | translate"
                       [value]="data.name"></app-property-text>

    <app-property-text [title]="'Position' | translate"
                       [value]="data.position"></app-property-text>

    <app-property-text [title]="'PhoneNumber' | translate"
                       [value]="data.phone"></app-property-text>

    <app-property-text [title]="'Email' | translate"
                       [bottomLineVisible]="false"
                       [value]="data.email"></app-property-text>
  </div>

  <div *ngIf="signatureStatus == 'UNSIGNED'" class="section-wrapper">
    <!-- 姓名 -->
    <app-property-input [title]="'FullName' | translate"
                        [required]="true"
                        [placeholder]="('PleaseEnter' | translate) + ('FullName' | translate)"
                        [(value)]="data.name"></app-property-input>

    <!-- 职位 -->
    <app-property-input [title]="'Position' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('Position' | translate)"
                        [(value)]="data.position"></app-property-input>

    <!-- 手机号码 -->
    <app-property-input [title]="'PhoneNumber' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('PhoneNumber' | translate)"
                        [(value)]="data.phone"></app-property-input>

    <!-- 邮箱 -->
    <app-property-input [title]="'Email' | translate"
                        [bottomLineVisible]="false"
                        [placeholder]="('PleaseEnter' | translate) + ('Email' | translate)"
                        [(value)]="data.email"></app-property-input>

  </div>

  <div *ngIf="modifyState == 'Create'" class="section-wrapper">
    <!-- 姓名 -->
    <app-property-input [title]="'FullName' | translate"
                        [required]="true"
                        [placeholder]="('PleaseEnter' | translate) + ('FullName' | translate)"
                        [(value)]="createInfo.name"></app-property-input>

    <!-- 职位 -->
    <app-property-input [title]="'Position' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('Position' | translate)"
                        [(value)]="createInfo.position"></app-property-input>

    <!-- 手机号码 -->
    <app-property-input [title]="'PhoneNumber' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('PhoneNumber' | translate)"
                        [(value)]="createInfo.phone"></app-property-input>

    <!-- 邮箱 -->
    <app-property-input [title]="'Email' | translate"
                        [bottomLineVisible]="false"
                        [placeholder]="('PleaseEnter' | translate) + ('Email' | translate)"
                        [(value)]="createInfo.email"></app-property-input>

  </div>

  <div *ngIf="signatureStatus == 'UNSIGNED' || modifyState == 'Create'" class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [disabled]="!canReply"
           [style]="!canReply ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onSave()">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
