<div class="card-container">
  <nz-tabset [nzSize]="'large'" nzType="card">
    <nz-tab [nzTitle]=myDeals>
      <app-invitation-list [mode]="dataService.dataModeOwn"></app-invitation-list>
    </nz-tab>
    <ng-template #myDeals>
      {{'MyDeals' | translate}}
    </ng-template>
    <nz-tab [nzTitle]=channelDeals *ngIf="workbench.pivot !== dataService.pivotOwn">
      <app-invitation-list [mode]="dataService.dataModeSubordinate"></app-invitation-list>
    </nz-tab>
    <ng-template #channelDeals>
      {{'MySubordinates' | translate}}
    </ng-template>
  </nz-tabset>
</div>

