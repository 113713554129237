<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px;font-size: 20px;color: #333333;font-weight: 600;">
    {{'FinancialNeedAnalysis' | translate}}
  </div>

  <div *ngFor="let fnaQuestion of fnaQuestions; let i = index" class="section-wrapper" style="padding-bottom: 15px" [style]="fnaQuestion.subStyle">
    <app-new-fna-question [fnaQuestion]="fnaQuestion"></app-new-fna-question>
  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onCancel()">
          <span>{{'Cancel' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
