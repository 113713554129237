import { Component, OnInit, TemplateRef } from '@angular/core';
import { AmlListResp, AmlMonitorSearch, PagedResp } from '../../../api/types';
import { MetadataService } from '../../../shared/service/metadata.service';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { AmlService } from '../../aml.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AmlMonitorEntryDetailComponent } from '../aml-monitor-entry-detail/aml-monitor-entry-detail.component';
import { Workbench } from '../../../workbench/workbench-types';
import { WorkbenchService } from '../../../workbench/workbench.service';

@Component({
  selector: 'app-aml-monitor-open-alerts',
  templateUrl: './aml-monitor-open-alerts.component.html',
  styleUrls: ['./aml-monitor-open-alerts.component.less']
})
export class AmlMonitorOpenAlertsComponent implements OnInit {
  loading = false;

  spinning = false;

  filterPopoverVisible = false;
  workbench: Workbench;

  constructor(
    private metadataService: MetadataService,
    private drawerService: NzDrawerService,
    private amlService: AmlService,
    private workbenchService: WorkbenchService,
    private modalService: NzModalService
  ) {
  }

  amls: PagedResp<AmlListResp>;

  search: AmlMonitorSearch = new AmlMonitorSearch();

  typeOptions: PropertySelectOption[] = [];
  statusOptions: PropertySelectOption[] = [];

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.loading = true;
    this.amlService.openAlertList(this.search).subscribe(
      data => {
        this.loading = false;
        this.amls = data;
      },
      error => {
        this.loading = false;
      }
    );
    const amlRecordTypes = this.metadataService.values('amlRecordType');
    for (const amlRecordType of amlRecordTypes) {
      this.typeOptions.push(new PropertySelectOption(amlRecordType.value, amlRecordType.key));
    }
    const amlMonitorStatus = this.metadataService.values('amlMonitorStatus');

    for (const status of amlMonitorStatus) {
      this.statusOptions.push(new PropertySelectOption(status.value, status.key));
    }
  }

  onResetSearch(): void {
    this.search = new AmlMonitorSearch();
    this.onReload();
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.amlService.openAlertList(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.amls = data;
        },
        error => {
          this.loading = false;
        });
  }

  onAmlInspectDetail(id: number): void {
    this.loading = true;
    this.amlService.openAlertDetail(id)
      .subscribe(info => {
          this.loading = false;
          const drawerRef = this.drawerService.create<AmlMonitorEntryDetailComponent, { value: any }, string>({
            nzWidth: 1024,
            nzMaskClosable: true,
            nzContent: AmlMonitorEntryDetailComponent,
            nzContentParams: {
              info
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();
            if (component.stateModified) {
              this.onReload(false);
            }
          });
        },
        error => {
          this.loading = false;
        });


  }


  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search = new AmlMonitorSearch();
    }
    this.amlService.openAlertList(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.amls = data;
        },
        error => {
          this.loading = false;
        });

  }

  onAmlDelete(row: any, tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>): void {
    this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzClosable: false,
      nzOnOk: () => {
        this.loading = true;
        this.amlService.deleteMonitorEntry(row.entryId, row.type).subscribe(
          data => {
            if (this.amls.total > this.amls.pageSize && this.amls.total - 1 <= this.amls.pageNum * this.amls.pageSize){
              this.search.pageNum -= 1;
            }
            this.amlService.openAlertList(this.search).subscribe(
              // tslint:disable-next-line:no-shadowed-variable
              data => {
                this.loading = false;
                this.amls = data;
              },
              error => {
                this.loading = false;
              }
            );
          },
          error => {
            this.loading = false;
          }
        );
      }
    });
  }
}
