import {Component, Input, OnInit} from '@angular/core';
import {Versions} from '../../../api/types';

@Component({
  selector: 'app-version-description',
  templateUrl: './version-description.component.html',
  styleUrls: ['./version-description.component.less']
})
export class VersionDescriptionComponent implements OnInit {

  isVisible = true;
  @Input()
  versionContent: Versions = new Versions();
  localVersion = require('../../../../../package.json').version;
  constructor() { }

  ngOnInit(): void {
  }

  handleOk(): void {
    this.versionContent.flag = false;
    localStorage.setItem('LIFEBEE-SALES-VERSION', this.localVersion);
  }

}
