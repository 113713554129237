import { Component, OnInit } from '@angular/core';
import { ToDoService } from '../to-do.service';
import { WorkbenchService } from '../../workbench/workbench.service';
import { Workbench } from '../../workbench/workbench-types';
import { MessageService } from '../../message/message.service';
import { Instance } from '../../api/types';

@Component({
  selector: 'app-to-do-main',
  templateUrl: './to-do-main.component.html',
  styleUrls: ['./to-do-main.component.less']
})
export class ToDoMainComponent implements OnInit {
  workbench: Workbench;
  instance: Instance = new Instance();
  badgeStyle: { position: 'relative', bottom: '10px', right: '10px' };

  constructor(private workbenchService: WorkbenchService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.instance = this.workbenchService.mockInstance;
    this.messageService.messageUnreadEvent
      .subscribe(
        res => {
          this.workbenchService.getWorkbench().subscribe(
            () => {
              this.workbench = JSON.parse(JSON.stringify(this.workbenchService.workbench));
            }
          );
        },
        error => {
        });
  }

}
