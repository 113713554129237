import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Material, TicketCreateResp, UploadTokenReq, UploadType} from '../../api/types';
import {DatePipe} from '@angular/common';
import {TicketService} from '../ticket.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {
  ProductMultiSelectionComponent
} from '../../shared/component/product-multi-selection/product-multi-selection.component';
import { ProductSelectionType } from '../../shared/component/product-selection/product-selection.component';
import { deduplication } from '../../shared/utils/collections';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { PolicySelectionComponent } from '../../shared/component/policy-selection/policy-selection.component';

@Component({
  selector: 'app-ticket-create',
  templateUrl: './ticket-create.component.html',
  styleUrls: ['./ticket-create.component.less'],
  providers: [DatePipe]
})
export class TicketCreateComponent implements OnInit {

  loading = false;

  ticketCreateResp: TicketCreateResp = new TicketCreateResp();

  uploadTokenReq = new UploadTokenReq();

  @Output()
  ticketCreateRes: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private datePipe: DatePipe,
              private ticketService: TicketService,
              private toastr: ToastrService,
              private drawerService: NzDrawerService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.uploadTokenReq.uploadType = UploadType.TICKET;
    this.uploadTokenReq.middleName = 'temp' + this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  create(): void {
    if (!this.canReply) {
      return;
    }
    this.loading = true;
    this.ticketService.creat(this.ticketCreateResp)
      .subscribe(data => {
          this.loading = false;
          if (data) {
            this.translate.get('CreateSuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          }
          this.ticketCreateRes.emit(data);
        },
        error => {
          this.loading = false;
        }
      );
  }

  replyAttachmentsChange(attachments: Material[]): void {
    this.ticketCreateResp.attachments = attachments;
  }

  get canReply(): boolean {
    return !!this.ticketCreateResp.title && !!this.ticketCreateResp.content;
  }

  onPolicySelection(): void {
    const drawerRef = this.drawerService.create<PolicySelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: PolicySelectionComponent,
      nzMask: true,
      nzContentParams: {
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.drawerRef = drawerRef;
      selectionComponent.policySelected
        .subscribe(
          policy => {
            this.ticketCreateResp.policyNos = policy;
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onCloseTag(i): void {
    this.ticketCreateResp.policyNos.splice(i, 1);
  }
}
