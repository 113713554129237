import { Component, Input, OnInit } from '@angular/core';
import { SaleList, SaleListReq } from '../sale-report-type';
import { CommissionDetailComponent } from '../../commission/commission-detail/commission-detail.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { SaleReportService } from '../sale-report.service';
import { SaleReportDetailComponent } from '../sale-report-detail/sale-report-detail.component';

@Component({
  selector: 'app-sale-report-list',
  templateUrl: './sale-report-list.component.html',
  styleUrls: ['./sale-report-list.component.less']
})
export class SaleReportListComponent implements OnInit {
  filterPopoverVisible: boolean;
  info: SaleList[] = [];
  @Input()
  filterData: SaleListReq;
  @Input()
  modeType = 'POLICY'; // UNDERWRITING:新单; POLICY:保单
  spinning: boolean;

  constructor(private drawerService: NzDrawerService,
              private saleReportService: SaleReportService) {
  }

  ngOnInit(): void {
    this.getInfo();
  }

  tabsChange(e): void {
    switch (e) {
      case 0:
        this.filterData.productType = 'BASIC';
        this.getInfo();
        break;
      case 1:
        this.filterData.productType = 'RIDER';
        this.getInfo();
        break;
    }
  }

  getInfo(): void {
    this.spinning = true;
    if (this.modeType === 'POLICY') {
      this.saleReportService.salesList(this.filterData)
        .subscribe(data => {
          this.info = data;
          this.spinning = false;
        }, error => {
          this.spinning = false;
        });
    } else {
      this.saleReportService.salesListUnderwriting(this.filterData)
        .subscribe(data => {
          this.info = data;
          this.spinning = false;
        }, error => {
          this.spinning = false;
        });
    }
  }

  onCommissionDetail(id: number): void {
    this.saleReportService.detailInfo(id)
      .subscribe(
        data => {
          const drawerRef = this.drawerService.create<SaleReportDetailComponent, { value: any }, string>({
            nzWidth: 600,
            nzContent: SaleReportDetailComponent,
            nzContentParams: {
              detailInfo: data
            }
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });
          drawerRef.afterClose.subscribe(() => {
          });
        }
      );
  }

}
