import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import { PropertySelectOption } from 'src/app/shared/component/property-select/property-select.component';
import {
  BasicInfoInsuredCMG,
  BasicInformation,
  CMGBasicInfo, CMGBasicInfoOwner,
  NewFnaDetail,
  ResidentialAddressCMG
} from '../../new-fna/new-fna-types';
import { MetadataService } from '../../shared/service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { ContactService } from '../../contact/contact.service';
import { Tree } from '../../contact/contact-types';
import { DatePipe, formatDate } from '@angular/common';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { Country } from '../../api/types';
import { Tool } from '../../shared/utils/tool';
import { plainToInstance } from 'class-transformer';
import { AccountService } from '../../account/account.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Workbench } from '../../workbench/workbench-types';
import { WorkbenchService } from '../../workbench/workbench.service';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.less'],
  providers: [DatePipe],
})
export class BasicInfoComponent implements OnInit {
  basicInfo: BasicInformation = new BasicInformation();
  basicInfoCopy: BasicInformation = new BasicInformation();
  @Input()
  fnaInfo: NewFnaDetail = new NewFnaDetail();

  basicOwner = true;
  residential = true;
  basicInsured = false;
  residentialInsured = true;
  postal = false;
  postalInsured = false;
  permanent = false;
  permanentInsured = false;
  companyAddress = false;
  companyAddressInsured = false;
  otherExisting = false;
  otherExistingInsured = false;
  birthdate: Date;
  insuredBirthdate: Date;

  @ViewChild('datePicker')
  datePicker!: NzDatePickerComponent;
  @ViewChild('datePickerInsured')
  datePickerInsured!: NzDatePickerComponent;

  customerTypeOptions: PropertySelectOption[] = [];
  genderOptions: PropertySelectOption[] = [];
  fnaHkDistrictOptions: PropertySelectOption[] = [];
  fnaRetirementOptions: PropertySelectOption[] = [];
  smokeOptions: PropertySelectOption[] = [];
  maritalStatusOptions: PropertySelectOption[] = [];
  fnaInsuredRelationshipOptions: PropertySelectOption[] = [];
  educationOptions: PropertySelectOption[] = [];
  employmentOptions: PropertySelectOption[] = [];
  yesOrNoOptions: PropertySelectOption[] = [];

  accountNameOptions: Tree[] = [];
  workbench: Workbench;

  constructor(private metadataService: MetadataService,
              private translate: TranslateService,
              private contactService: ContactService,
              private accountService: AccountService,
              private routerService: ActivatedRoute,
              private datePipe: DatePipe,
              private workbenchService: WorkbenchService,
              private toastr: ToastrService,
              private modalService: NzModalService) { }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    // CMG默认值
    const obj = new CMGBasicInfo();
    const objOwn = new CMGBasicInfoOwner();
    this.basicInfo = plainToInstance(BasicInformation, objOwn);
    this.basicInfo.insured = plainToInstance(BasicInfoInsuredCMG, obj);
    this.routerService
      .queryParams
      .subscribe(params => {
        console.log(params);
        if (!params.id) {
          console.log('dd');
          this.basicInfo.englishConsultantName = this.accountService.passport.accountName;
          this.fnaInfo.consultantName = this.accountService.passport.name;
          this.basicInfo.englishWitnessName = this.accountService.passport.name;
          this.basicInfo.englishConsultantLicenseNo = this.accountService.passport.registerNumber;
          this.basicInfo.witnessIdNo = this.accountService.passport.registerNumber;
        }
      });

    const fnaCustomerTypes = this.metadataService.values('fnaCustomerType');
    for (const fnaCustomerType of fnaCustomerTypes) {
      this.customerTypeOptions.push(new PropertySelectOption(fnaCustomerType.value, fnaCustomerType.key));
    }
    const fnaGenderTypes = this.metadataService.values('fnaGender');
    for (const fnaGenderType of fnaGenderTypes) {
      this.genderOptions.push(new PropertySelectOption(fnaGenderType.value, fnaGenderType.key));
    }
    const fnaHkDistrictArr = this.metadataService.values('fnaHkDistrict');
    for (const fnaHkDistrict of fnaHkDistrictArr) {
      this.fnaHkDistrictOptions.push(new PropertySelectOption(fnaHkDistrict.value, fnaHkDistrict.key));
    }

    const fnaRetirements = this.metadataService.values('fnaRetirement');
    for (const fnaRetirement of fnaRetirements) {
      this.fnaRetirementOptions.push(new PropertySelectOption(fnaRetirement.value, fnaRetirement.key));
    }

    const marital = this.metadataService.values('fnaMarriage');
    for (const gender of marital) {
      this.maritalStatusOptions.push(new PropertySelectOption(gender.value, gender.key));
    }

    const fnaInsuredRelationships = this.metadataService.values('fnaInsuredRelationship');
    for (const fnaInsuredRelationship of fnaInsuredRelationships) {
      this.fnaInsuredRelationshipOptions.push(new PropertySelectOption(fnaInsuredRelationship.value, fnaInsuredRelationship.key));
    }

    const educations = this.metadataService.values('fnaEducation');
    for (const education of educations) {
      this.educationOptions.push(new PropertySelectOption(education.value, education.key));
    }

    this.translate.get(['Yes', 'No']).subscribe(
      (res: any) => {
        this.yesOrNoOptions.push(new PropertySelectOption(res.Yes, true));
        this.yesOrNoOptions.push(new PropertySelectOption(res.No, false));
      });

    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'S'), true));
    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'NS'), false));

    const employments = this.metadataService.values('fnaEmployment');
    for (const education of employments) {
      this.employmentOptions.push(new PropertySelectOption(education.value, education.key));
    }
    this.contactService.sub()
      .subscribe(
        data => {
          this.accountNameOptions = data || [];
        }
      );
  }

  setBasicInfo(): void {
    console.log(111);
    console.log(this.fnaInfo.basicInformation);
    this.basicInfo = this.fnaInfo.basicInformation;
    this.basicInfoCopy = JSON.parse(JSON.stringify(this.fnaInfo.basicInformation));

    // this.basicInfo = JSON.parse(JSON.stringify(this.fnaInfo.basicInformation));
    if (this.fnaInfo.basicInformation.dateOfBirth) {
      const date = this.fnaInfo.basicInformation.dateOfBirth;
      this.basicInfo.dateOfBirth = date.split('-').reverse().join('/');
    }
    if (this.fnaInfo.basicInformation.insured.dateOfBirth) {
      const dateInsured = this.fnaInfo.basicInformation.insured.dateOfBirth;
      this.basicInfo.insured.dateOfBirth = dateInsured.split('-').reverse().join('/');
    }
    this.postal = this.fnaInfo.basicInformation.correspondenceAddress ? this.hasAnyValue(this.fnaInfo.basicInformation.correspondenceAddress) : false;
    this.postalInsured = this.fnaInfo.basicInformation.insured.correspondenceAddress ? this.hasAnyValue(this.fnaInfo.basicInformation.insured.correspondenceAddress) : false;
    this.permanent = this.fnaInfo.basicInformation.permanentAddress ? this.hasAnyValue(this.fnaInfo.basicInformation.permanentAddress) : false;
    this.permanentInsured = this.fnaInfo.basicInformation.insured.permanentAddress ? this.hasAnyValue(this.fnaInfo.basicInformation.insured.permanentAddress) : false;
    this.companyAddress = this.fnaInfo.basicInformation.employerAddress ? this.hasAnyValue(this.fnaInfo.basicInformation.employerAddress) : false;
    this.companyAddressInsured = this.fnaInfo.basicInformation.insured.employerAddress ? this.hasAnyValue(this.fnaInfo.basicInformation.insured.employerAddress) : false;
    this.basicInsured = this.postalInsured && this.permanentInsured && this.companyAddressInsured;
    this.otherExisting = this.fnaInfo.basicInformation.otherExistingCoverage ? !!this.fnaInfo.basicInformation.otherExistingCoverage : false;
    this.otherExistingInsured = this.fnaInfo.basicInformation.financialAdditionalInformation ? !!this.fnaInfo.basicInformation.financialAdditionalInformation : false;
    this.isDeepEmptyObject(this.fnaInfo.basicInformation.insured);
  }

  isDeepEmptyObject(obj): void {
    for (const key of Object.keys(obj)) {
      if (obj[key] !== null && typeof obj[key] !== 'object' && obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
        console.log(key + ': ' + obj[key]);
        if (key === 'mobileArea' || key === 'homePhoneArea') {
          if (obj[key] !== '852') {
            this.basicInsured = true;
          }
        } else if (key === 'homePhoneCountry' || key === 'mobileCountry' || key === 'nationality' || key === 'countryOfBirth') {
          if (obj[key] !== 'HONGKONG') {
            this.basicInsured = true;
          }
        } else {
          this.basicInsured = true;
        }
      }
      if (typeof obj[key] === 'object' && obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
        this.isDeepEmptyObject(obj[key]);
      }
    }
  }

  accountNameSearch(input, option): boolean {
    if (input) {
      return option.key.includes(input) || option.nzLabel.includes(input);
    }
    return false;
  }

  onDateChange(date: Date): void {
    console.log('onChange: ', date);
    if (!date) {
      return;
    }
    const dateString = formatDate(date, 'dd/MM/yyyy', 'zh-Hans-CN');
    console.log(dateString);
    this.basicInfo.dateOfBirth = dateString;
  }

  logClick(): void {
    // this.basicInfo.dateOfBirth = '03/03/1990';
    if (!this.basicInfo.dateOfBirth) {
      return;
    }
    if (this.datePicker) {
      this.datePicker.open();
    }
    if (this.basicInfo.dateOfBirth.length === 2 || this.basicInfo.dateOfBirth.length === 5) {
      this.basicInfo.dateOfBirth += '/';
    }
    if (this.basicInfo.dateOfBirth.length === 10 && !this.isValidDate(this.basicInfo.dateOfBirth)) {
      console.log('时间格式错误，请按照正确时间格式输入：DD/MM/YYYY，例：01/01/2024');
      this.translate.get('DateErrorTips').subscribe(
        (res) => {
          this.toastr.warning(res);
        }
      );
      this.basicInfo.dateOfBirth = '';
    }
    const result = this.basicInfo.dateOfBirth.match(/[^\/]*\/[^\/]*\/[^\/]*/);
    console.log(result);
    if (result !== null) {
      const yearStr = this.basicInfo.dateOfBirth.split('/');
      if (yearStr[yearStr.length - 1].length === 4) {
        const dateOfBirthCopy = this.convertToDate(this.basicInfo.dateOfBirth);
        const dateStr = new Date(dateOfBirthCopy);
        console.log(dateStr);
        this.birthdate = dateStr;
      }
    }
  }

  isValidDate(dateString): boolean {
    // 按照DD/MM/YYYY格式分割字符串
    const parts = dateString.split('/');
    // 如果不是三个部分，则不是正确格式
    if (parts.length !== 3) {
      return false;
    }
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    // 校验月份是否在1-12之间
    if (month < 1 || month > 12) {
      return false;
    }
    // 校验日期是否在1-31之间（根据月份调整）
    const maxDayOfMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > maxDayOfMonth) {
      return false;
    }
    // 对于二月判断闰年的情况，这里简化处理，仅检查是否小于等于29
    if (month === 2 && day > 29) {
      return false;
    }
    // 年份可以不做严格限制，但通常会要求是四位数且在某个范围内
    // 下面简单示例只判断是否为非负整数
    if (isNaN(year) || String(year).length !== 4) {
      return false;
    }

    // 如果所有条件都满足，那么这个日期格式就是有效的
    return true;
  }


  containsOnlyOnce(str, char): boolean {
    // 检查字符串是否包含指定字符
    if (str.includes(char)) {
      // 使用 match 方法和正则表达式查找字符出现次数
      const count = (str.match(new RegExp(char, 'g')) || []).length;
      // 如果出现次数为1，则返回true
      return count === 1;
    } else {
      // 字符不存在于字符串中
      return false;
    }
  }

  convertToDate(dateString): string {
    const parts = dateString.split('/');
    const arr = dateString.split('/');
    const firstItem = parts[0];
    const secondItem = parts[1];
    arr.splice(0, 2, secondItem, firstItem);
    return arr.join('/');
  }

  onAddClick(): void {
    if (this.datePicker) {
      console.log(this.datePicker);
      this.datePicker.open();
    }
  }

  handleEndOpenChange(): void {
    console.log(this.datePicker);
    this.datePicker.close();
  }

  handleOpenChange(open: boolean): void {
    console.log('handleEndOpenChange', open);
  }

  onDateInsuredChange(date: Date): void {
    console.log('onChange: ', date);
    if (!date) {
      return;
    }
    const dateString = formatDate(date, 'dd/MM/yyyy', 'zh-Hans-CN');
    console.log(dateString);
    this.basicInfo.insured.dateOfBirth = dateString;
  }

  logInsuredClick(): void {
    // this.basicInfo.dateOfBirth = '03/03/1990';
    if (!this.basicInfo.insured.dateOfBirth) {
      return;
    }
    if (this.datePickerInsured) {
      this.datePickerInsured.open();
    }
    if (this.basicInfo.insured.dateOfBirth.length === 2 || this.basicInfo.insured.dateOfBirth.length === 5) {
      this.basicInfo.insured.dateOfBirth += '/';
    }
    if (this.basicInfo.insured.dateOfBirth.length === 10 && !this.isValidDate(this.basicInfo.insured.dateOfBirth)) {
      this.translate.get('DateErrorTips').subscribe(
        (res) => {
          this.toastr.warning(res);
        }
      );
      this.basicInfo.insured.dateOfBirth = '';
    }
    console.log('onChange: ', this.basicInfo.insured.dateOfBirth);
    const result = this.basicInfo.insured.dateOfBirth.match(/[^\/]*\/[^\/]*\/[^\/]*/);
    console.log(result);
    if (result !== null) {
      const yearStr = this.basicInfo.insured.dateOfBirth.split('/');
      if (yearStr[yearStr.length - 1].length === 4) {
        const dateOfBirthCopy = this.convertToDate(this.basicInfo.insured.dateOfBirth);
        const dateStr = new Date(dateOfBirthCopy);
        console.log(dateStr);
        this.insuredBirthdate = dateStr;
      }
    }
  }

  onAddInsuredClick(): void {
    if (this.datePickerInsured) {
      console.log(this.datePickerInsured);
      this.datePickerInsured.open();
    }
  }

  handleInsuredEndOpenChange(): void {
    console.log(this.datePickerInsured);
    this.datePickerInsured.close();
  }

  handleInsuredOpenChange(open: boolean): void {
    console.log('handleEndOpenChange', open);
  }

  nationalityChange(result: Country | null): void {
    this.basicInfo.nationality = result?.countryCode || null;
  }

  countryChange(result: Country | null): void {
    this.basicInfo.countryOfBirth = result?.countryCode || null;
  }

  nationalityInsuredChange(result: Country | null): void {
    this.basicInfo.insured.nationality = result?.countryCode || null;
  }

  countryInsuredChange(result: Country | null): void {
    this.basicInfo.insured.countryOfBirth = result?.countryCode || null;
  }

  taxResidenceChange(result: Country | null): void {
    this.basicInfo.taxResidence = result?.countryCode || null;
  }

  mobileChange(result: Country | null): void {
    this.basicInfo.mobileArea = result?.areaCode || null;
    this.basicInfo.mobileCountry = result?.countryCode || null;
  }

  mobileInsuredChange(result: Country | null): void {
    this.basicInfo.insured.mobileArea = result?.areaCode || null;
    this.basicInfo.insured.mobileCountry = result?.countryCode || null;
  }

  homePhoneInsuredChange(result: Country | null): void {
    this.basicInfo.insured.homePhoneArea = result?.areaCode || null;
    this.basicInfo.insured.homePhoneCountry = result?.countryCode || null;
  }

  homePhoneChange(result: Country | null): void {
    this.basicInfo.homePhoneArea = result?.areaCode || null;
    this.basicInfo.homePhoneCountry = result?.countryCode || null;
  }

  officeChange(result: Country | null): void {
    this.basicInfo.officeTelephoneArea = result?.areaCode || null;
    this.basicInfo.officeTelephoneCountry = result?.countryCode || null;
  }

  totalNumber(inputValue): string | number {
    // 使用正则表达式验证输入是否为数字（包括小数和负数）
    const regex = /^-?\d+(\.\d+)?$/;
    // let inputText = inputValue;

    // 如果输入值不符合要求，则清空输入框
    if (!regex.test(inputValue)) {
      inputValue = 0;
    }
    return inputValue;
  }

  totalAverageMonthlyIncomes(): void { // 每月總平均收入A
    const salaryCommissionBonusAmount = this.totalNumber(this.basicInfo.salaryCommissionBonusAmount);
    const rentalIncomeAmount = this.totalNumber(this.basicInfo.rentalIncomeAmount);
    const otherIncomesAmount = this.totalNumber(this.basicInfo.otherIncomesAmount) || 0;
    this.basicInfo.totalAverageMonthlyIncomes = Number(Tool.mathAdd(salaryCommissionBonusAmount, Tool.mathAdd(rentalIncomeAmount, otherIncomesAmount)));
    this.estimatedMonthlySurplusDeficit();
  }

  totalAverageMonthlyExpenses(): void { // 每月总平均支出B
    const personalExpensesAmount = this.totalNumber(this.basicInfo.personalExpensesAmount);
    const householdExpensesAmount = this.totalNumber(this.basicInfo.householdExpensesAmount);
    const mortgagesLoansRepaymentAmount = this.totalNumber(this.basicInfo.mortgagesLoansRepaymentAmount);
    const insuranceAmount = this.totalNumber(this.basicInfo.insuranceAmount);
    const otherExpensesAmount = this.totalNumber(this.basicInfo.otherExpensesAmount);
    this.basicInfo.totalAverageMonthlyExpenses = Number(Tool.mathAdd(otherExpensesAmount, Tool.mathAdd(Tool.mathAdd(personalExpensesAmount, householdExpensesAmount), Tool.mathAdd(mortgagesLoansRepaymentAmount, insuranceAmount))));
    this.estimatedMonthlySurplusDeficit();
  }
  estimatedMonthlySurplusDeficit(): void { // 估計每月剩餘/超支【（A）-（B）】
    this.basicInfo.estimatedMonthlySurplusDeficit = Number(Tool.matchSub(this.basicInfo.totalAverageMonthlyIncomes || 0, this.basicInfo.totalAverageMonthlyExpenses || 0));
    this.maximumAffordablePremiumIncome();
  }

  totalLiquidAssets(): void { // 流動資產總額 C
    console.log('流動資產總額 C');
    const cashDepositAmount = this.totalNumber(this.basicInfo.cashDepositAmount);
    const currentInvestmentAmount = this.totalNumber(this.basicInfo.currentInvestmentAmount);
    const otherLiquidAssetsAmount = this.totalNumber(this.basicInfo.otherLiquidAssetsAmount);
    this.basicInfo.totalLiquidAssets = Number(Tool.mathAdd(cashDepositAmount, Tool.mathAdd(currentInvestmentAmount, otherLiquidAssetsAmount)));
    this.estimatedNetAssets();
    this.maximumAffordablePremium();
  }

  totalNonLiquidAssets(): void { // 非流動資產總額 D
    const propertiesAmount = this.totalNumber(this.basicInfo.propertiesAmount);
    const otherNonLiquidAssetsAmount = this.totalNumber(this.basicInfo.otherNonLiquidAssetsAmount);
    this.basicInfo.totalNonLiquidAssets = Number(Tool.mathAdd(propertiesAmount, otherNonLiquidAssetsAmount));
    this.estimatedNetAssets();
  }

  totalLiabilities(): void { // 負債總額 E
    const mortgageLoansAmount = this.totalNumber(this.basicInfo.mortgageLoansAmount);
    const personalCreditCardLoansAmount = this.totalNumber(this.basicInfo.personalCreditCardLoansAmount);
    const otherLiabilitiesAmount = this.totalNumber(this.basicInfo.otherLiabilitiesAmount);
    this.basicInfo.totalLiabilities = Number(Tool.mathAdd(mortgageLoansAmount, Tool.mathAdd(personalCreditCardLoansAmount, otherLiabilitiesAmount)));
    this.estimatedNetAssets();
    this.maximumAffordablePremium();
  }

  estimatedNetAssets(): void { // 估計淨資產（C+D-E）
    const totalLiquidAssets = this.totalNumber(this.basicInfo.totalLiquidAssets);
    const totalNonLiquidAssets = this.totalNumber(this.basicInfo.totalNonLiquidAssets);
    const totalLiabilities = this.totalNumber(this.basicInfo.totalLiabilities);
    this.basicInfo.estimatedNetAssets = Number(Tool.matchSub(Tool.mathAdd(totalLiquidAssets, totalNonLiquidAssets), totalLiabilities));
  }

  maximumAffordablePremium(): void { // 可負擔最高保費 [净流动资产= （C–E ）/ 2]
    const totalLiquidAssets = this.totalNumber(this.basicInfo.totalLiquidAssets);
    const totalLiabilities = this.totalNumber(this.basicInfo.totalLiabilities);
    this.basicInfo.maximumAffordablePremium = Number(Tool.accDiv(Tool.matchSub(totalLiquidAssets, totalLiabilities) || 0, 2));
  }

  maximumAffordablePremiumIncome(): void { // 每月收入扣除每月支出 x 12月 x 50%[ (A-B) X 6]
    this.basicInfo.maximumAffordablePremiumIncome = Tool.accMul(this.basicInfo.estimatedMonthlySurplusDeficit || 0, 6);
  }

  copyAddress(): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      this.basicInfo.insured.residentialAddress = plainToInstance(ResidentialAddressCMG, this.basicInfo.residentialAddress);
    }
  }

  customerTypeChange(flag): void {
    if (this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo) {
    } else {
      console.log(flag);
      console.log(this.fnaInfo.customerType);
      const result = null;
      if (flag === this.fnaInfo.customerType) {
        this.fnaInfo.customerType = result;
      } else {
        this.fnaInfo.customerType = flag;
      }
    }
  }

  genderChange(flag): void {
    if (this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo) {
    } else {
      const result = null;
      if (flag === this.basicInfo.gender) {
        this.basicInfo.gender = result;
      } else {
        this.basicInfo.gender = flag;
      }
    }
  }

  smokingChange(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.smoke) {
        this.basicInfo.smoke = result;
      } else {
        this.basicInfo.smoke = flag;
      }
    }
  }

  residentialAddresshk(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.residentialAddress.hkDistrict) {
        this.basicInfo.residentialAddress.hkDistrict = result;
      } else {
        this.basicInfo.residentialAddress.hkDistrict = flag;
      }
    }
  }

  correspondenceAddresshk(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.correspondenceAddress.hkDistrict) {
        this.basicInfo.correspondenceAddress.hkDistrict = result;
      } else {
        this.basicInfo.correspondenceAddress.hkDistrict = flag;
      }
    }
  }

  permanentAddresshk(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.permanentAddress.hkDistrict) {
        this.basicInfo.permanentAddress.hkDistrict = result;
      } else {
        this.basicInfo.permanentAddress.hkDistrict = flag;
      }
    }
  }

  employerAddresshk(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.employerAddress.hkDistrict) {
        this.basicInfo.employerAddress.hkDistrict = result;
      } else {
        this.basicInfo.employerAddress.hkDistrict = flag;
      }
    }
  }

  intendedRetirementChange(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.intendedRetirement) {
        this.basicInfo.intendedRetirement = result;
      } else {
        this.basicInfo.intendedRetirement = flag;
      }
    }
  }

  fatherDependentsChange(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.familySituationFatherDependents) {
        this.basicInfo.familySituationFatherDependents = result;
      } else {
        this.basicInfo.familySituationFatherDependents = flag;
      }
    }
  }

  motherDependentsChange(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.familySituationMotherDependents) {
        this.basicInfo.familySituationMotherDependents = result;
      } else {
        this.basicInfo.familySituationMotherDependents = flag;
      }
    }
  }

  spouseDependentsChange(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.familySituationSpouseDependents) {
        this.basicInfo.familySituationSpouseDependents = result;
      } else {
        this.basicInfo.familySituationSpouseDependents = flag;
      }
    }
  }

  chidrenDependentsChange(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.familySituationChidrenDependents) {
        this.basicInfo.familySituationChidrenDependents = result;
      } else {
        this.basicInfo.familySituationChidrenDependents = flag;
      }
    }
  }

  insuredGenderChange(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.insured.gender) {
        this.basicInfo.insured.gender = result;
      } else {
        this.basicInfo.insured.gender = flag;
      }
    }
  }

  insuredSmokingChange(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.insured.smoke) {
        this.basicInfo.insured.smoke = result;
      } else {
        this.basicInfo.insured.smoke = flag;
      }
    }
  }

  insuredResidentialAddresshk(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.insured.residentialAddress.hkDistrict) {
        this.basicInfo.insured.residentialAddress.hkDistrict = result;
      } else {
        this.basicInfo.insured.residentialAddress.hkDistrict = flag;
      }
    }
  }

  insuredCorrespondenceAddresshk(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.insured.correspondenceAddress.hkDistrict) {
        this.basicInfo.insured.correspondenceAddress.hkDistrict = result;
      } else {
        this.basicInfo.insured.correspondenceAddress.hkDistrict = flag;
      }
    }
  }

  insuredPermanentAddresshk(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.insured.permanentAddress.hkDistrict) {
        this.basicInfo.insured.permanentAddress.hkDistrict = result;
      } else {
        this.basicInfo.insured.permanentAddress.hkDistrict = flag;
      }
    }
  }

  insuredEmployerAddresshk(flag): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      const result = null;
      if (flag === this.basicInfo.insured.employerAddress.hkDistrict) {
        this.basicInfo.insured.employerAddress.hkDistrict = result;
      } else {
        this.basicInfo.insured.employerAddress.hkDistrict = flag;
      }
    }
  }

  parserValue(value: string): string {
    return value.replace(/\$\s?|(,*)/g, '');
  }

  formatterValue(value: number): string {
    if (parseFloat(String(value)).toString() === 'NaN') {
      return '';
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  clearInsuredResidentialAddress(): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      this.basicInfo.insured.residentialAddress = plainToInstance(ResidentialAddressCMG, new ResidentialAddressCMG());
    }
  }

  clearResidentialAddress(): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      this.basicInfo.residentialAddress = plainToInstance(ResidentialAddressCMG, new ResidentialAddressCMG());
    }
  }

  clearInsuredCorrespondenceAddress(): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      this.basicInfo.insured.correspondenceAddress = plainToInstance(ResidentialAddressCMG, new ResidentialAddressCMG());
    }
  }

  clearCorrespondenceAddress(): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      this.basicInfo.correspondenceAddress = plainToInstance(ResidentialAddressCMG, new ResidentialAddressCMG());
    }
  }

  clearInsuredPermanentAddress(): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      this.basicInfo.insured.permanentAddress = plainToInstance(ResidentialAddressCMG, new ResidentialAddressCMG());
    }
  }

  clearPermanentAddress(): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      this.basicInfo.permanentAddress = plainToInstance(ResidentialAddressCMG, new ResidentialAddressCMG());
    }
  }

  clearInsuredEmployerAddress(): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      this.basicInfo.insured.employerAddress = plainToInstance(ResidentialAddressCMG, new ResidentialAddressCMG());
    }
  }

  clearEmployerAddress(): void {
    if (!(this.fnaInfo.signatureStatus === 'SIGNED' || this.fnaInfo.underwritingNo)) {
      this.basicInfo.employerAddress = plainToInstance(ResidentialAddressCMG, new ResidentialAddressCMG());
    }
  }

  hasAnyValue(obj: any): boolean {
    // 使用 Object.values() 获取对象的所有属性值
    const values = Object.values(obj);
    // 使用 Array.prototype.some() 检查数组中是否存在至少一个元素满足条件（即是否有值）
    return values.some(value => {
      // 判断值是否为非空字符串、数字、布尔值或对象/数组（递归检查其子项）
      return (
        value !== '' &&
        value !== null &&
        value !== undefined &&
        (typeof value !== 'object' ||
          (Array.isArray(value) && this.hasAnyValue(value)) ||
          (value.constructor === Object && this.hasAnyValue(value)))
      );
    });
  }

}
