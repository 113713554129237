import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingComponent } from './booking.component';
import { BookingMainComponent } from './booking-main.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { BookingsComponent } from './bookings/bookings.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { BookingSearchComponent } from './booking-search/booking-search.component';
import { BookingCreateComponent } from './booking-create/booking-create.component';
import { BookingProductPlanComponent } from './components/booking-product-plan/booking-product-plan.component';
import { BookingInfoEditComponent } from './components/booking-info-edit/booking-info-edit.component';
import { BookingDetailComponent } from './booking-detail/booking-detail.component';
import { BookingOverviewComponent } from './booking-overview/booking-overview.component';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { FileUploadModule } from 'ng2-file-upload';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzTagModule} from 'ng-zorro-antd/tag';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormsModule } from '@angular/forms';
import { BookingGroupSelectionComponent } from './components/booking-group-selection/booking-group-selection.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { BookingToCompleteComponent } from './components/booking-to-complete/booking-to-complete.component';
import { BookingVipEditComponent } from './components/booking-vip-edit/booking-vip-edit.component';
import { BookingVipEditV2Component } from './components/booking-vip-edit-v2/booking-vip-edit-v2.component';
import { BookingMergeComponent } from './components/booking-merge/booking-merge.component';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';



@NgModule({
  declarations: [
    BookingComponent,
    BookingMainComponent,
    BookingsComponent,
    BookingSearchComponent,
    BookingCreateComponent,
    BookingProductPlanComponent,
    BookingInfoEditComponent,
    BookingDetailComponent,
    BookingOverviewComponent,
    BookingGroupSelectionComponent,
    BookingToCompleteComponent,
    BookingVipEditComponent,
    BookingVipEditV2Component,
    BookingMergeComponent,
  ],
  imports: [
    CommonModule,
    NzLayoutModule,
    RouterModule,
    SharedModule,
    TranslateModule,
    NzTabsModule,
    NzSpinModule,
    NzSkeletonModule,
    NzTableModule,
    NzGridModule,
    NzButtonModule,
    NzIconModule,
    NzToolTipModule,
    NzBadgeModule,
    NzListModule,
    NzDropDownModule,
    NzEmptyModule,
    FileUploadModule,
    NzPopoverModule,
    NzFormModule,
    NzModalModule,
    NzTagModule,
    NzSwitchModule,
    FormsModule,
    NzDrawerModule,
    NzCascaderModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzSelectModule,
    NzAvatarModule
  ]
})
export class BookingModule { }
