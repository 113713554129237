import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolicyRemindersComponent } from './policy-reminders/policy-reminders.component';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import { PolicyReminderSearchComponent } from './policy-reminder-search/policy-reminder-search.component';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {RouterModule} from '@angular/router';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {FormsModule} from '@angular/forms';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSelectModule } from 'ng-zorro-antd/select';



@NgModule({
  declarations: [
    PolicyRemindersComponent,
    PolicyReminderSearchComponent,
  ],
  exports: [
    PolicyRemindersComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        NzLayoutModule,
        RouterModule,
        TranslateModule,
        NzDatePickerModule,
        FormsModule,
        NzTabsModule,
        NzSpinModule,
        NzSkeletonModule,
        NzTableModule,
        NzGridModule,
        NzToolTipModule,
        NzBadgeModule,
        NzButtonModule,
        NzIconModule,
        NzDrawerModule,
        NzPopoverModule,
        NzAvatarModule,
        NzSelectModule
    ]
})
export class PolicyReminderModule { }
