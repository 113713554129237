<div class="from-info-container">
  <div class="from-info-card">
    <div style="color: rgba(64,64,64,0.85);text-align: justify">
      {{'ProductsIntroductionTitle' | translate}}
    </div>
  </div>

  <div class="from-info-card clearfix">
    <div class="form-card-title question-title">{{'NameofInsuranceCo' | translate}}</div>
    <div style="padding-left: 1em;position: relative;padding-bottom: 20px;" class="clearfix">
      <div style="margin-left: 98px;margin-right: 20px;position: absolute;"
           class="choose-product-y clearfix">
        <div *ngFor="let choose of chooseProduct; let i = index" class="display-flex">
          <div class="choose-box">
            <div *ngIf="!i" class="choose-box-label">{{'BasicPlanIntroduce' | translate}}</div>
            <div class="value-box">
              <div class="value-box-input-box is-border"
                   [class]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? 'product-name-disable' : ''"
                   appThrottleClick (throttleClick)="onPlayerClick(i)">
                <div style="width: calc(100% - 16px);font-size: 14px;">
                  <div *ngIf="choose.fnaProduct.productName" class="product-name">
                    {{choose.fnaProduct.productName}}
                  </div>
                  <div *ngIf="!choose.fnaProduct.productName" style="color: #45454540;">
                    {{'PleaseChoose' | translate}}</div>
                </div>
                <i *ngIf="!choose.fnaProduct.productName" nz-icon nzType="down" nzTheme="outline"
                   style="color: rgba(0,0,0,0.25);"></i>
                <i *ngIf="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? false : choose.fnaProduct.productName"
                   nz-icon nzType="close-circle" nzTheme="outline"
                   appThrottleClick (throttleClick)="onClearProductClick(i)"
                   style="color: rgba(0,0,0,0.25);"></i>
              </div>
            </div>
          </div>
          <div class="choose-box">
            <div *ngIf="!i" class="choose-box-label">{{'PremiumPaymentPeri' | translate}}</div>
            <div class="value-box">
              <!--              <input class="value-box-input" nz-input-->
              <!--                     style="width: 180px"-->
              <!--                     [placeholder]="'PleaseEnter' | translate"-->
              <!--                     [(ngModel)]="choose.fnaProduct.premiumPaymentPeriod" />-->
              <nz-input-group style="width: 180px" [nzSuffix]="suffixIconSearch" class="value-box-input">
                <input nz-input [placeholder]="'PleaseEnter' | translate"
                       [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [(ngModel)]="choose.fnaProduct.premiumPaymentPeriod"/>
              </nz-input-group>
              <ng-template #suffixIconSearch>
                <span class="suffix-text">{{'Year' | translate}}</span>
              </ng-template>
            </div>
          </div>
          <div class="choose-box">
            <div *ngIf="!i" class="choose-box-label">{{'ProtectionPeriod' | translate}}</div>
            <div class="value-box">
              <input class="value-box-input" nz-input
                     style="width: 180px"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [placeholder]="'PleaseEnter' | translate"
                     [(ngModel)]="choose.fnaProduct.protectionPeriod" />
            </div>
          </div>
          <div *ngFor="let rider of choose.riderProduct; let j = index"
               class="display-flex">
            <div class="choose-box">
              <div *ngIf="!i" class="choose-box-label" [style]="j > 0 ? 'padding-left: 8px;' : ''">
                {{'RiderIntroduced' | translate}}
              </div>
              <div class="value-box border-dashed-10" >
                <div class="value-box-input-box is-border"
                     [class]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? 'product-name-disable' : choose.fnaProduct.productCode ? 'pointer' : 'no-drop'"
                     appThrottleClick (throttleClick)="onRiderClick(i, j)">
                  <div style="width: calc(100% - 16px);font-size: 14px;">
                    <div *ngIf="rider.productName" style="color: #000000d9;" class="product-name">{{rider.productName}}</div>
                    <div *ngIf="!rider.productName" style="color: #45454540;">
                      {{'PleaseChoose' | translate}}</div>
                  </div>
                  <i *ngIf="!rider.productName" nz-icon nzType="down" nzTheme="outline" style="color: rgba(0,0,0,0.25);"></i>
                  <i *ngIf="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? false : rider.productName"
                     nz-icon nzType="close-circle" nzTheme="outline"
                     appThrottleClick (throttleClick)="onClearRiderClick(i, j)"
                     style="color: rgba(0,0,0,0.25);"></i>
                </div>
              </div>
            </div>
            <div class="choose-box">
              <div *ngIf="!i" class="choose-box-label">{{'PremiumPaymentPeri' | translate}}</div>
              <div class="value-box">
                <!--                <input class="value-box-input" nz-input-->
                <!--                       style="width: 180px"-->
                <!--                       [placeholder]="'PleaseEnter' | translate"-->
                <!--                       [(ngModel)]="rider.premiumPaymentPeriod" />-->
                <nz-input-group style="width: 180px" [nzSuffix]="suffixIconSearch" class="value-box-input">
                  <input nz-input [placeholder]="'PleaseEnter' | translate"
                         [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                         [(ngModel)]="rider.premiumPaymentPeriod"/>
                </nz-input-group>
                <ng-template #suffixIconSearch>
                  <span class="suffix-text">{{'Year' | translate}}</span>
                </ng-template>
              </div>
            </div>
            <div class="choose-box">
              <div *ngIf="!i" class="choose-box-label">{{'ProtectionPeriod' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input
                       style="width: 180px"
                       [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="rider.protectionPeriod" />
              </div>
            </div>
            <div *ngIf="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? false : 0 < j"
                 class="choose-box" style="width: 30px;margin-right: 0;">
              <div *ngIf="!i" class="choose-box-label"></div>
              <div class="value-box pointer"
                   appThrottleClick (throttleClick)="onDeleteRider(i, j)">
                <img alt="" width="20" src="assets/images/ic_fna_delete_red.svg">
              </div>
            </div>
          </div>
          <div *ngIf="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? false : choose.riderProduct.length < 3"
               class="choose-box" style="width: 40px;">
            <div *ngIf="!i" class="choose-box-label"></div>
            <div class="value-box pointer border-dashed"
                 appThrottleClick (throttleClick)="onAddRider(i)">
              <img alt="" width="20" src="assets/images/ic_choose_push.svg">
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="choose-box" style="width: 120px;">
          <div class="choose-box-label">{{'ProductsSelected' | translate}}</div>
          <div *ngFor="let choose of chooseProduct; let i = index" class="">
            <div class="display-flex parent" style="position: relative">
              <div class="value-box pointer child" style="width: 20px;position: absolute;left: 0;top: 5px;"
                   *ngIf="(fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo) ? false : i > 1"
                   appThrottleClick (throttleClick)="onDeleChoose(i)">
                <img alt="" width="20" src="assets/images/ic_fna_delete_red.svg">
              </div>

              <div class="value-box display-flex" style="padding-left: 30px;height: 32px;margin-bottom: 8px;"
                   [class]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? '' : 'pointer'"
                   appThrottleClick (throttleClick)="onSelectedChoose(i)">
                <div class="selected-box"
                     [class]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? 'hi-lite-disable' : choose.choose ? 'hi-lite' : ''">
                  <div [class]="choose.choose ? 'back-color' : ''"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="clear: both;"></div>
      <!--      <div style="position: absolute;right: 0;top: 0;">-->
      <!--        <div class="choose-box" style="width: 60px;">-->
      <!--          <div class="choose-box-label"></div>-->
      <!--          <div *ngFor="let choose of chooseProduct; let i = index">-->
      <!--            <div class="choose-box">-->
      <!--              <div style="height: 32px;" class="display-flex">-->
      <!--                <div class="value-box pointer border-dashed"-->
      <!--                     appThrottleClick (throttleClick)="onAddRider(i)">-->
      <!--                  <img alt="" width="20" src="assets/images/icon_plus.svg">-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div *ngIf="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? false : chooseProduct.length < 3" style="padding-left: 1em;">
      <div class="value-box pointer"
           style="padding-left: 28px;"
           appThrottleClick (throttleClick)="onAddChoose()">
        <img alt="" width="20" src="assets/images/ic_choose_push.svg">
      </div>
    </div>
  </div>

  <div class="basic-info-card">
    <div nz-row [nzGutter]="16" style="padding-right: 8px;">
      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'PolicyNumber' | translate}}</div>
        <div class="value-box">
          <input nz-input class="value-box-input"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                 [placeholder]="'PleaseEnter' | translate"
                 [(ngModel)]="fnaAssessAdvice.policyNo" />
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'ZUUSumInsured' | translate}}</div>
        <div class="value-box">
          <nz-input-number class="value-box-input-number"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzParser]="parserValue"
                           [nzFormatter]="formatterValue"
                           [(ngModel)]="fnaAssessAdvice.sumAssured">
          </nz-input-number>
        </div>
      </div>

      <div nz-col [nzSpan]="6">
        <div class="label-text">{{'ModalPremium' | translate}}</div>
        <div class="value-box">
          <nz-input-number class="value-box-input-number"
                           [nzPlaceHolder]="'PleaseEnter' | translate"
                           [disabled]="fnaInfo.signatureStatus === 'SIGNED' || fnaInfo.underwritingNo"
                           [nzParser]="parserValue"
                           [nzFormatter]="formatterValue"
                           [(ngModel)]="fnaAssessAdvice.premium">
          </nz-input-number>
        </div>
      </div>
    </div>
  </div>

  <div class="from-info-card">
    <div class="form-card-title question-title" style="margin: 0;">{{'Reason' | translate}}</div>
    <div style="padding-left: 1em;">
      <div class="property-value-column">
        <input [(ngModel)]="fnaAssessAdvice.EVALUATION_Reason"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
               [placeholder]="('PleaseEnter' | translate)"
               class="value-box-input"
               nz-input/>
      </div>
    </div>
  </div>

  <div class="from-info-card">
    <div class="form-card-title question-title">{{'RARFR' | translate}}</div>
    <div style="padding-left: 1em;">
      <div class="form-card-title question-title">{{'Suitability' | translate}}</div>
      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;padding-left: 0;">
        <div nz-row style="margin-bottom: 10px;">
          <div nz-col nzSpan="3">
            <label nz-checkbox
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   [ngModel]="fnaAssessAdvice.EVALUATION_01_No"
                   (ngModelChange)="log($event, 'EVALUATION_01_No')">
              <div class="check-text-label">
                {{'answer38' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="3">
            <label nz-checkbox
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   [ngModel]="fnaAssessAdvice.EVALUATION_01_Yes"
                   (ngModelChange)="log($event, 'EVALUATION_01_Yes')">
              <div class="check-text-label">
                {{'answer37' | translate}}
              </div>
            </label>
          </div>
        </div>
      </nz-checkbox-wrapper>
      <div *ngIf="fnaAssessAdvice.EVALUATION_01_No">
        <div class="form-card-check-box">
          <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_No_A"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                 style="margin-left: 23px;">
            <div class="check-text-label" style="text-indent: 0;padding-left: 0;">
              {{'TheRecommendation' | translate}}
            </div>
          </label>
        </div>
        <div class="form-card-check-box">
          <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_No_B"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                 style="margin-left: 23px;">
            <div class="check-text-label" style="text-indent: 0;padding-left: 0;">
              {{'TheRecommendation2' | translate}}
            </div>
          </label>
        </div>
        <div class="form-card-check-box" style="margin-bottom: 0;">
          <div class="property-value-column" style="margin-left: 45px;">
            <input [(ngModel)]="fnaAssessAdvice.EVALUATION_01_No_B_addition"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   [placeholder]="('PleaseSpecify' | translate)"
                   class="value-box-input"
                   nz-input/>
          </div>
        </div>
      </div>

      <div *ngIf="fnaAssessAdvice.EVALUATION_01_Yes">
        <div class="form-card-check-box">
          <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_Yes_A"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                 style="margin-left: 23px;">
            <div class="check-text-label" style="text-indent: 0;padding-left: 0;">
              {{'Objectives' | translate}}
            </div>
          </label>
        </div>
        <div class="form-card-check-box">
          <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_Yes_B"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                 style="margin-left: 23px;">
            <div class="check-text-label" style="text-indent: 0;padding-left: 0;">
              {{'TargetBenefit' | translate}}
            </div>
          </label>
        </div>
        <div class="form-card-check-box">
          <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_Yes_C"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                 style="margin-left: 23px;">
            <div class="check-text-label" style="text-indent: 0;padding-left: 0;">
              {{'Percentage' | translate}}
            </div>
          </label>
        </div>
        <div class="form-card-check-box">
          <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_Yes_D"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                 style="margin-left: 23px;">
            <div class="check-text-label" style="text-indent: 0;padding-left: 0;">
              {{'Yearsofability' | translate}}
            </div>
          </label>
        </div>
        <div class="form-card-check-box">
          <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_Yes_E"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                 style="margin-left: 23px;">
            <div class="check-text-label" style="text-indent: 0;padding-left: 0;">
              {{'Levelofprotection' | translate}}
            </div>
          </label>
        </div>

        <div class="form-card-check-box" style="">
          <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_01_Yes_F"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                 style="margin-left: 23px;">
            <div class="check-text-label">
              {{'answer36' | translate}}
            </div>
          </label>
          <div class="form-card-check-box" style="margin-bottom: 0;flex: 1">
            <div class="property-value-column" style="margin-left: 16px;">
              <input [(ngModel)]="fnaAssessAdvice.EVALUATION_01_Yes_F_addition"
                     [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [placeholder]="('PleaseSpecify' | translate)"
                     class="value-box-input"
                     nz-input/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="fnaAssessAdvice.EVALUATION_01_Yes" style="padding-left: 1em;">
      <div class="form-card-title question-title" style="font-weight: normal;">{{'Reasonsforrecommendation' | translate}}</div>
      <div class="form-card-check-box">
        <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_02_A"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
               style="margin-left: 23px;">
          <div class="check-text-label" style="text-indent: 0;padding-left: 0;">
            {{'OwingTobudgetlimit' | translate}}
          </div>
        </label>
      </div>

      <div class="form-card-check-box" style="margin-bottom: 0;">
        <label nz-checkbox [(ngModel)]="fnaAssessAdvice.EVALUATION_02_B"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
               style="margin-left: 23px;">
          <div class="check-text-label">
            {{'answer36' | translate}}
          </div>
        </label>
        <div class="form-card-check-box" style="margin-bottom: 0;flex: 1">
          <div class="property-value-column" style="margin-left: 16px;">
            <input [(ngModel)]="fnaAssessAdvice.EVALUATION_02_B_addition"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   [placeholder]="('PleaseSpecify' | translate)"
                   class="value-box-input"
                   nz-input/>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
