<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'EvaluationsAndSuggestions' | translate}}
    </div>

    <div class="section-title flex-row" style="margin-top: 20px;">
      <div style="margin-left: 8px; flex: 1">{{'RecommendedProduct' | translate}}</div>
      <div *ngIf="canSave">
        <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;" appThrottleClick (throttleClick)="onRecommendedProductSelect()">
          {{'SelectProduct' | translate}} <span style="color: #999999; margin-left: 3px">></span>
        </a>
      </div>
    </div>

    <div *ngFor="let product of recommendedProducts; let i = index;">
      <app-fna-product-item [product]="product" [index]="i" [showEditButton]="canSave" (editEvent)="onRecommendedProductEdit(product, i)"></app-fna-product-item>
    </div>

    <div class="section-title flex-row" style="margin-top: 20px;">
      <div style="margin-left: 8px; flex: 1">{{'ProductIntroductionAndSelectionResults' | translate}}</div>
    </div>

    <div *ngFor="let fnaQuestion of fnaQuestions; let i = index">
      <app-fna-question [disabled]="!canSave" [fnaQuestion]="fnaQuestion" (fnaQuestionChange)="onFnaQuestionChange($event, i)" (subQuestionEvent)="onSubQuestionEvent($event)">
      </app-fna-question>
    </div>

    <div class="section-title flex-row" style="margin-top: 20px;">
      <div style="margin-left: 8px; flex: 1">{{'FinalProductChoice' | translate}}</div>
      <div *ngIf="canSave">
        <a nz-button nzType="link" nzSize="small" style="color: #1D64C4; font-size: 14px;" appThrottleClick (throttleClick)="onFinalProductSelect()">
          {{'SelectProduct' | translate}} <span style="color: #999999; margin-left: 3px">></span>
        </a>
      </div>
    </div>

    <div *ngFor="let product of fna.endProduct; let i = index;">
      <app-fna-product-item [product]="product" [index]="i"></app-fna-product-item>
    </div>

  </nz-spin>

  <div *ngIf="canSave" class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onTemporarySave()">
          <span>{{'TemporarySave' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" [disabled]="loading"
           appThrottleClick (throttleClick)="onSave()"
           [style]="loading ? 'opacity: 0.5' : ''">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
