<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px ;font-weight: bold">
    {{'TicketsDetail' | translate}}
  </div>
  <!--  内容回填-->
  <div class="ticket-reply">
    <div *ngFor="let item of info.replies" class="list-item">
      <div class="list-top">
        <nz-avatar *ngIf="item.role === 'CENTRE'" [nzSize]="36" nzIcon="user"
                   style="background-color: #2C7BE5; color: white;margin-right: 20px;"></nz-avatar>
        <nz-avatar *ngIf="item.role === 'USER'" class="list-top-avatar"
                   style="margin-right: 20px;" [nzSize]="36" [nzSrc]="accountInfo.avatar"></nz-avatar>
        <div class="list-top-right">
          <div style="margin-right: 10px;display: flex;align-items: center;">
            <span class="list-name" *ngIf="item.role === 'CENTRE'">{{ 'CENTRE' }}</span>
            <span class="list-name" *ngIf="item.role === 'USER'">{{ accountInfo.name }}</span>
            <span class="list-top-my-reply" *ngIf="item.role === 'USER'">{{ 'Oneself' | translate }}</span>
          </div>
          <div class="list-top-name" style="margin-right: 10px;">{{ item.createTime }}</div>
        </div>
      </div>
      <div class="list-bottom">
        <div class="ticket-reply-content" style="white-space: pre-wrap">{{item.content}}</div>
        <app-attachments *ngIf="item.files?.length > 0"
                         [attachments]="item.files"
                         [readonly]="true"></app-attachments>
      </div>
    </div>
  </div>
  <!--  回复内容-->
  <div *ngIf="info.status !== 'CLOSED'" class="">
    <div nz-row style="width: 100%;">
      <div nz-col nzSpan="24">
        <div class="section-wrapper" style="margin: 15px 0 10px">
          <app-property-input-area [bottomLineVisible]="false"
                                   [title]="'ReplyContent' | translate"
                                   [required]="true"
                                   [placeholder]="('PleaseEnter' | translate) + ('ReplyContent' | translate)"
                                   [(value)]="ticketReply.content"></app-property-input-area>
<!--          <app-property-editor [bottomLineVisible]="false"-->
<!--                               [required]="true"-->
<!--                               [title]="'Content' | translate"-->
<!--                               [placeholder]="('PleaseEnter' | translate) + ('ReplyContent' | translate)"-->
<!--                               [(value)]="ticketReply.content"></app-property-editor>-->
        </div>
      </div>
      <div nz-col nzSpan="24" style="padding: 0px 0px">
        <app-attachments [attachments]="ticketReply.files"
                         [uploadTokenReq]="uploadTokenReq"
                         [isPreview]="true"
                         [apiType]="'TICKET_LIFEBEE'"
                         (valueChange)="replyAttachmentsChange($event)"
                         [showEmpty]="false"></app-attachments>
      </div>
      <div nz-col nzSpan="24" class="footer-button-primary" style="height: 50px; border-radius: 10px; margin-top: 15px">
        <a nz-button nzType="link" nzBlock nzSize="large" nzShape="round"
           appThrottleClick (throttleClick)="reply()"
           [disabled]="!canReply"
           [style]="!canReply ? 'opacity: 0.5' : ''">
          <span>{{ 'Reply' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
