import { EventEmitter, Injectable } from '@angular/core';
import { HttpService } from '../../core/http.service';
import { Observable } from 'rxjs/internal/Observable';
import { ApiResponse, Instance } from '../../api/types';
import { API } from '../../api/api';
import { map } from 'rxjs/operators';
import { BaseService } from '../../base/base.service';
import { LocaleService } from '../../core/locale.service';
import { Constant } from '../../base/constant';

@Injectable({
  providedIn: 'root'
})
export class MetadataService extends BaseService {

  metadata: Map<string, MetadataKeyValue[]> = new Map<string, MetadataKeyValue[]>();

  metadataChanged: EventEmitter<Map<string, MetadataKeyValue[]>> = new EventEmitter<Map<string, MetadataKeyValue[]>>();
  groupFiles: EventEmitter<any> = new EventEmitter<any>(); // 文件分组监听刷新

  constructor(private http: HttpService, private localeService: LocaleService) {
    super();

    this.metadata = this.metadataCache;
    /// 监听多语言切换，重新加载metadata
    this.localeService.localeChanged
      .subscribe(
        val => {
          this.getMetadata()
            .subscribe(
              data => {
                this.metadataChanged.emit(data);
              },
              error => {
              });
        }
      );
  }

  getMetadata(): Observable<Map<string, MetadataKeyValue[]>> {
    return this.http.get<ApiResponse<Map<string, MetadataKeyValue[]>>>(API.systemV3 + '/metadata', {
      observe: 'response'
    }).pipe(map(data => {
      this.metadata = data.body.data;
      this.metadataCache = this.metadata;
      return this.metadata;
    }));
  }

  get metadataCache(): Map<any, any> {
    const value: string = localStorage.getItem(Constant.LOCAL_STORAGE_KEY_METADATA);
    return JSON.parse(value);
  }

  set metadataCache(val: Map<any, any>) {
    localStorage.setItem(Constant.LOCAL_STORAGE_KEY_METADATA, JSON.stringify(val));
  }

  values(category: string): MetadataKeyValue[] {
    return this.metadata[category];
  }

  translate(category: string, key: string): string {
    const values = this.metadata[category];
    let result = 'N/A';
    if (values) {
      for (const value of values) {
        if (key === value.key) {
          result = value.value;
          break;
        }
      }
    }
    return result;
  }
}

export class MetadataKeyValue {
  key: string;
  value: string;
  text: string;
}
