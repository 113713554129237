import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MemberList, MemberListRes} from '../contact-types';
import {ContactService} from '../contact.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {ContactAddMemberComponent} from '../contact-add-member/contact-add-member.component';

@Component({
  selector: 'app-contact-family',
  templateUrl: './contact-family.component.html',
  styleUrls: ['./contact-family.component.less']
})
export class ContactFamilyComponent implements OnInit {

  spinning = false;
  isVisible = false;
  memberList: MemberListRes[];
  id: number;
  memberReload: MemberList = new MemberList();
  @Output()
  Close: EventEmitter<any> = new EventEmitter<any>();

  constructor(private contactService: ContactService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
  }

  onDel(val, type): void {
    if (type !== 'OWN') {
      this.spinning = true;
      const req = {id: val};
      this.contactService.del(this.id, req)
        .subscribe(
          data => {
            console.log(data);
            this.onReload();
            this.spinning = false;
          },
          error => {
            this.spinning = false;
          }
        );
    }
  }

  onReload(): void {
    this.memberReload.familyId = this.id;
    this.contactService.memberList(this.memberReload)
      .subscribe(
        data => {
          this.memberList = data;
        },
        error => {
        }
      );
  }

  onAdd(): void {
    const drawerRef = this.drawerService.create<ContactAddMemberComponent, { value: any }, string>({
      nzWidth: 600,
      nzContent: ContactAddMemberComponent,
      nzContentParams: {
        id: this.id
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const addMemberComponent = drawerRef.getContentComponent();
      addMemberComponent.afterSave
        .subscribe(
          data => {
            drawerRef.close();
            this.onReload();
          }
        );
    });
  }

  onDisband(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
    this.contactService.dissolution(this.id)
      .subscribe(
        data => {
          // console.log(data);
          this.Close.emit(data);
        },
        error => {
        }
      );
  }

  handleCancel(): void {
    this.isVisible = false;
  }
}
