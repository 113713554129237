import { KeyValue, Material, PagedReq, Phone } from '../api/types';
import { Expose, plainToInstance, Transform, Type } from 'class-transformer';
import { AssessAdvice, Assets, RecommendProduct, RecommendProductCode } from '../fna/fna-types';
import { MetadataKeyValue } from '../shared/service/metadata.service';
import { plainToArray } from '../shared/utils/class-transform';

// 新fna列表查询
export class NewFnaSearch extends PagedReq {
  @Expose() accountName: string; // 销售渠道
  @Expose() customerName: string; // 客户姓名
  @Expose() likeBookingNo: string; // 预约单号(模糊查询)
  @Expose() likeUnderwritingNo: string; // 新单号(模糊查询)
  @Expose() likePolicyNo: string; // 保单号(模糊查询)
  @Expose() createDateBegin: string; // 创建开始日期
  @Expose() createDateEnd: string; // 创建结束日期
  @Expose() productCode: string; // 产品码
  @Expose() productName: string;
  @Expose() companyCode: string; // 公司码
  @Expose() policyIds: string; // 保单ID列表
  @Expose() orderColumn: string; // 排序字段   createTime、customerName、status、
  @Expose() orderAspect: string; // 排序类型 DESC/ASC
}

// 列表返回
export class NewFnaList {
  @Expose() id: number; // FNA id
  @Expose() accountName: string; // 销售渠道
  @Expose() name: string; // 销售昵称
  @Expose() bookingNo: string; // 预约编号
  @Expose() underwritingNo: string; // 新单编号
  @Expose() policyNo: string; // 保单编号
  @Expose() policyId: string;
  @Expose() customerName: string; // 客户姓名
  @Expose() customerNameEn: string; // 客户姓名英文
  @Expose() productCodes: string[]; // 产品码
  @Type(() => ProductListInfo)
  @Expose() productList: ProductListInfo[]; // 产品信息列表
  @Expose() status: string; // 状态:UNCOMPLETED-未完成;COMPLETED-已完成
  @Expose() signatureStatus: string; // 所有的签名状态:已签名-SIGNED;未签名-UNSIGNED
  @Expose() createTime: string; // 创建时间
  @Expose() version: string; // 版本
}

export class ProductListInfo {
  @Expose() productCode: string;
  @Expose() productName: string;
  @Expose() companyCode: string;
  @Expose() companyName: string;
  @Expose() companyLogo: string;
}

export class NewFnaDetail {
  @Expose() id: number;
  @Expose() accountName: string;
  @Expose() additionalDataAnalysisStatus: string;
  @Type(() => KeyValue)
  @Expose() additionalDataAnalysis: KeyValue[];
  @Expose() fnaBusinessType: string;
  @Expose() consultantAccountName: string;
  @Expose() consultantName: string;
  @Expose() nickName: string;
  @Expose() bookingNo: string;
  @Expose() underwritingNo: string;
  @Expose() createTime: string;
  @Expose() policyId: string;
  @Expose() policyNo: string;
  @Expose() proposalId: string;
  @Expose() customerName: string;
  @Expose() customerNameEn: string;
  @Expose() trAccountName: string; // TR账号
  @Expose() customerType = 'INDIVIDUAL'; // 客戶類型 INDIVIDUAL-個人 CORPORATE-公司
  @Expose() customerSource: string; // 客户来源
  @Expose() systemStatus: string; // 系统信息状态
  @Type(() => BasicInformation)
  @Transform((value) => plainToInstance(BasicInformation, Object.assign(new BasicInformation(), value.value)))
  @Expose() basicInformation: BasicInformation = new BasicInformation(); // 基础信息
  @Expose() basicStatus: string; // 基础信息状态
  @Type(() => KeyValue)
  @Transform((value) => plainToArray(KeyValue, value.value))
  @Expose() financialAnalysis: KeyValue[]; // 财务需求分析
  @Expose() fnaStatus: string; // fnaForm状态
  @Expose() productStatus: string; // 产品选择状态
  @Type(() => KeyValue)
  @Expose() assessAdvice: KeyValue[]; // 评估建议
  @Type(() => KeyValue)
  @Expose() medicalAssessment: KeyValue[]; // 醫療保險產品合適性評估問卷
  @Expose() showMedicalAssessment: boolean; // 是否显示 醫療保險產品合適性評估問卷
  @Expose() productCodes: string[]; // 产品选择列表
  @Type(() => RecommendProduct)
  @Transform((value) => plainToArray(RecommendProduct, value.value))
  @Expose() products: RecommendProduct[]; // 选择的产品信息
  @Type(() => RecommendProduct)
  @Expose() customize: RecommendProduct[]; // 自选推荐产品
  @Type(() => RecommendProduct)
  @Expose() recommend: RecommendProduct[]; // 系统推荐产品
  @Type(() => RecommendProduct)
  @Expose() history: RecommendProduct[]; // 历史推荐产品
  @Type(() => SignatureInformation)
  @Expose() signatureInformation: SignatureInformation[]; // 签名信息
  @Expose() signatureStatus: string; // 所有的签名状态:已签名-SIGNED;未签名-UNSIGNED
  @Expose() status: string; // 状态:UNCOMPLETED-未完成;COMPLETED-已完成
  @Expose() version: string; // 版本号
  @Expose() inputPolicyNo: string; // 输入保单号
  @Type(() => KeyValue)
  @Expose() declaration: KeyValue[]; // 特别客户声明
  @Expose() declarationStatus: string; // 特别客户声明状态
  @Expose() formLanguage: string; // 表单语言
  @Expose() categoryNos: string[] = []; // 表单语言 新字段
  @Expose() showDeclaration: boolean; // 是否显示 特别客户声明
  @Type(() => ChooseProduct)
  @Expose() chooseProduct: ChooseProduct[] = []; // 自选推荐产品
}

export class SignatureInformation {
  @Expose() signatureType: string; // 签名人员类型
  @Expose() signatureTime: string; // 签名时间
  @Expose() signatureUrl: Material; // 签名文件地址
  @Expose() signatureStatus: string; // 签名状态
}

export class BasicInformation {
  @Expose() companyName: string; // 公司名稱中文
  @Expose() companyNameEn: string; // 公司名稱英文
  @Expose() companyRegistrationNumber: string; // 公司注册号码
  @Expose() companyBusinessRegistrationNumber: string; // 公司商业登记号码
  @Expose() companyRegistrationDate: string; // 公司成立日期
  @Expose() companyRegistrationCountry: string; // 公司成立地点
  @Expose() companyBusinessNature: string; // 公司业务性质
  @Expose() companyTelephoneArea: string; // 公司电话号码区号
  @Expose() companyTelephoneCountry: string; // 公司电话号码区号
  @Expose() companyTelephone: string; // 公司电话号码
  @Expose() companyEmail: string; // 公司邮箱
  @Expose() companyLegalPersonFirstName: string; // 授权代表姓名中文-名
  @Expose() companyLegalPersonLastName: string; // 授权代表姓名中文-姓
  @Expose() companyLegalPersonFirstNameEn: string; // 授权代表姓名英文-名
  @Expose() companyLegalPersonLastNameEn: string; // 授权代表姓名英文-姓
  @Expose() companyLegalPersonPosition: string; // 授权代表职称
  @Expose() companyLegalPersonTelephoneArea: string; // 授权代表电话区号
  @Expose() companyLegalPersonTelephoneCountry: string; // 授权代表电话国家
  @Expose() companyLegalPersonTelephone: string; // 授权代表电话号码
  @Expose() companyLegalPersonEmail: string; // 授权代表电子邮箱
  @Type(() => ResidentialAddressCMG)
  @Transform((value) => plainToInstance(ResidentialAddressCMG, Object.assign(new ResidentialAddressCMG(), value.value)))
  @Expose() companyRegisteredAddress: ResidentialAddressCMG = new ResidentialAddressCMG(); // 公司注册地址
  @Type(() => ResidentialAddressCMG)
  @Transform((value) => plainToInstance(ResidentialAddressCMG, Object.assign(new ResidentialAddressCMG(), value.value)))
  @Expose() companyContactAddress: ResidentialAddressCMG = new ResidentialAddressCMG(); // 公司通信地址

  @Expose() englishFirstName: string; // 英文名
  @Expose() englishLastName: string; // 英文姓
  @Expose() englishName: string; // 英文姓名
  @Expose() chineseFirstName: string; // 中文名
  @Expose() chineseLastName: string; // 中文姓
  @Expose() chineseName: string; // 中文姓名
  @Expose() appellation: string; // 称谓
  @Expose() accountName: string; // 销售渠道
  @Expose() gender: string; // 性别 MALE FEMALE
  @Expose() customerType: string; // 客戶類型 INDIVIDUAL-個人 CORPORATE-公司
  @Expose() dateOfBirth: string; // 出生日期（yyyy-MM-dd）
  @Expose() nationality: string; // 国籍
  @Expose() smoke: boolean; // 是否吸烟
  @Expose() countryOfBirth: string; // 出生国家
  @Expose() taxResidence: string; // 税务国家
  @Expose() idCard: string; // 身份證號碼/出世紙號碼
  @Expose() passport: string; // 護照號碼
  @Expose() mobile: string; // 手提（手机号）
  @Expose() homePhone: string; // 住宅（电话）
  @Expose() officeTelephone: string; // 办公室（电话）
  @Expose() maritalStatus: string; // 婚姻状况
  @Expose() emailAddress: string; // 电邮地址
  @Expose() education: string; // 教育程度
  @Expose() othersEducationAddition: string; // 教育程度 其他
  @Expose() employment: string; // 就業情況
  @Expose() position: string; // 職位
  @Expose() industry: string; // 行業
  @Expose() intendedRetirement: string; // 预期退休年龄
  @Expose() retirementAdditional: string; // 预期退休年龄(其他
  @Expose() familySituationFatherAge: number; // 家庭狀況（父亲年龄）
  @Expose() familySituationFatherDependents = false; // 家庭狀況（父亲需要供养）
  @Expose() familySituationMotherAge: number; // 家庭狀況（母亲年龄）
  @Expose() familySituationMotherDependents = false; // 家庭狀況（母亲需要供养）
  @Expose() familySituationSpouseAge: number; // 家庭狀況（配偶年龄）
  @Expose() familySituationSpouseDependents = false; // 家庭狀況（配偶需要供养）
  @Expose() familySituationChidrenTotal: number; // 家庭狀況（儿女总共）
  @Expose() familySituationChidrenAgeStart: number; // 家庭狀況（儿女年龄开始）
  @Expose() familySituationChidrenAgeEnd: number; // 家庭狀況（儿女年龄结束）
  @Expose() familySituationChidrenDependents = false; // 家庭狀況（儿女需要供养）
  @Expose() lifeInsuranceSumInsured: string; // 已有保障（人壽保險 總保額）
  @Expose() criticalIllnessInsuranceSumInsured: string; // 已有保障（危疾保險 總保額）
  @Expose() accidentalInsuranceSumInsured: string; // 已有保障（意外保險 總保額）
  @Expose() medicalInsuranceType: string; // 已有保障（醫療保險 種類）
  @Expose() otherExistingCoverage: string; // 已有保障（其他現有保障）
  @Expose() salaryCommissionBonusAmount: number; // 過去2年的平均每月收入（薪金/佣金/花紅）
  @Expose() rentalIncomeAmount: number; // 過去2年的平均每月收入（租金收入）
  @Expose() otherIncomesAdditional: string; // 過去2年的平均每月收入（其他收入 附加说明）
  @Expose() otherIncomesAmount: number; // 過去2年的平均每月收入（其他收入）
  @Expose() totalAverageMonthlyIncomes: number; // 過去2年的平均每月收入（每月總平均收入）
  @Expose() personalExpensesAmount: number; // 過去2年的平均每月支出（個人開支）
  @Expose() householdExpensesAmount: number; // 過去2年的平均每月支出（家庭開支）
  @Expose() mortgagesLoansRepaymentAmount: number; // 過去2年的平均每月支出（按揭/借貸還款）
  @Expose() insuranceAmount: number; // 過去2年的平均每月支出（保險）
  @Expose() otherExpensesAdditional: string; // 過去2年的平均每月支出（其他支出 附加说明）
  @Expose() otherExpensesAmount: number; // 過去2年的平均每月支出（其他支出）
  @Expose() totalAverageMonthlyExpenses: number; // 過去2年的平均每月支出（每月總平均支出）
  @Expose() estimatedMonthlySurplusDeficit: number; // 估計每月剩餘/(超支)
  @Expose() cashDepositAmount: number; // 流動資產（現金及存款）
  @Expose() currentInvestmentAmount: number; // 流動資產（變現能力高的投資 (例如:股票、債券)）
  @Expose() otherLiquidAssetsAdditional: string; // 流動資產（其他流動資產 附加说明）
  @Expose() otherLiquidAssetsAmount: number; // 流動資產（其他流動資產）
  @Expose() totalLiquidAssets: number; // 流動資產（流動資產總額）
  @Expose() propertiesAmount: number; // 非流通資產（物業）
  @Expose() otherNonLiquidAssetsAdditional: string; // 非流通資產（其他非流動資產 (例如:公司股份) 附加说明）
  @Expose() otherNonLiquidAssetsAmount: number; // 非流通資產（其他非流動資產 (例如:公司股份)）
  @Expose() totalNonLiquidAssets: number; // 非流通資產（非流動資產總額）
  @Expose() mortgageLoansAmount: number; // 負債（按揭貸款）
  @Expose() privateOrBankLiabilitiesAmount: number; // 私人或信用卡贷款/银行透支等(PIBA 短期负债)
  @Expose() existingPolicyLoanAmount: number; // 现有保单贷款(PIBA 短期负债)
  @Expose() personalCreditCardLoansAmount: number; // 負債（私人/信用卡貸款）
  @Expose() otherLiabilitiesAdditional: string; // 負債（其他債務 附加说明）
  @Expose() otherLiabilitiesAmount: number; // 負債（其他債務）
  @Expose() totalLiabilities: number; // 負債（總負債額）
  @Expose() estimatedNetAssets: number; // 估計淨資產
  @Expose() maximumAffordablePremium: number; // 可负担最高保费（估计净资产除以2）可负担最高保费（流动资产基础）
  @Expose() maximumAffordablePremiumIncome: number; // 可负担最高保费（收入基础）
  @Expose() financialAdditionalInformation: string; // 附加資料
  @Expose() witnessIdNo: string; // 见证人身份证/牌照号码
  @Expose() englishWitnessName: string; // 见证人英文姓名
  @Expose() consultantContactNo: string; // 理财顾问联络电话
  @Expose() englishConsultantLicenseNo: string; // 理财顾问牌照号码
  @Expose() englishConsultantName: string; // 理财顾问英文姓名
  @Expose() otherRelationship: string; // 理财顾问英文姓名
  @Expose() relationship = 'OWN'; // 與受保人關係
  @Type(() => ResidentialAddressCMG)
  @Transform((value) => plainToInstance(ResidentialAddressCMG, Object.assign(new ResidentialAddressCMG(), value.value)))
  @Expose() residentialAddress: ResidentialAddressCMG = new ResidentialAddressCMG(); // 居住地址
  @Type(() => ResidentialAddressCMG)
  @Transform((value) => plainToInstance(ResidentialAddressCMG, Object.assign(new ResidentialAddressCMG(), value.value)))
  @Expose() correspondenceAddress: ResidentialAddressCMG = new ResidentialAddressCMG(); // 通訊地址
  @Type(() => ResidentialAddressCMG)
  @Transform((value) => plainToInstance(ResidentialAddressCMG, Object.assign(new ResidentialAddressCMG(), value.value)))
  @Expose() permanentAddress: ResidentialAddressCMG = new ResidentialAddressCMG(); // 永久地址
  @Type(() => ResidentialAddressCMG)
  @Transform((value) => plainToInstance(ResidentialAddressCMG, Object.assign(new ResidentialAddressCMG(), value.value)))
  @Expose() employerAddress: ResidentialAddressCMG = new ResidentialAddressCMG(); // 公司地址
  @Expose() remark: string; // 备注
  @Expose() employerName: string; // 雇主
  @Expose() averageMonthlySalary: string; // 每月平均收入(HK$)
  @Expose() mobileArea: string; // 手提（手机号区号）
  @Expose() mobileCountry: string; // 手提（手机号区号）归属
  @Expose() homePhoneArea: string; // 住宅（电话）
  @Expose() homePhoneCountry: string; // 住宅（电话）归属
  @Expose() officeTelephoneArea: string; // 办公室（电话）
  @Expose() officeTelephoneCountry: string; // 办公室（电话）归属
  @Type(() => BasicInfoInsuredCMG)
  @Transform((value) => plainToInstance(BasicInfoInsuredCMG, Object.assign(new BasicInfoInsuredCMG(), value.value)))
  @Expose() insured: BasicInfoInsuredCMG = new BasicInfoInsuredCMG(); // 受保人
}

export class CMGBasicInfo {
  @Expose() nationality = 'HONGKONG';
  @Expose() countryOfBirth = 'HONGKONG';
  @Expose() taxResidence = 'HONGKONG';
  // @Expose() consultantContactNo = '31055950';
  @Expose() mobileArea = '852';
  @Expose() mobileCountry = 'HONGKONG';
  @Expose() homePhoneArea = '852';
  @Expose() homePhoneCountry = 'HONGKONG';
  @Expose() officeTelephoneArea = '852';
}

export class CMGBasicInfoOwner {
  @Expose() nationality = 'HONGKONG';
  @Expose() countryOfBirth = 'HONGKONG';
  @Expose() taxResidence = 'HONGKONG';
  @Expose() homePhoneCountry = 'HONGKONG';
  @Expose() mobileCountry = 'HONGKONG';
  @Expose() officeTelephoneCountry = 'HONGKONG';
  @Expose() consultantContactNo = '31055950';
  @Expose() mobileArea = '852';
  @Expose() homePhoneArea = '852';
  @Expose() officeTelephoneArea = '852';
  @Expose() relationship = 'OWN'; // 與受保人關係
  @Expose() smoke = false;
  @Expose() gender = 'FEMALE';
  @Expose() familySituationFatherDependents = false; // 家庭狀況（父亲需要供养）
  @Expose() familySituationMotherDependents = false; // 家庭狀況（母亲需要供养）
  @Expose() familySituationSpouseDependents = false; // 家庭狀況（配偶需要供养）
  @Expose() familySituationChidrenDependents = false; // 家庭狀況（儿女需要供养）
}

export class BasicInfoSave {
  @Expose() id: number;
  @Expose() save: boolean;
  @Expose() accountName: string;
  @Expose() fnaBusinessType: string;
  @Expose() customerType: string;
  @Type(() => BasicInformation)
  @Transform((value) => plainToInstance(BasicInformation, Object.assign(new BasicInformation(), value.value)))
  @Expose() basicInformation: BasicInformation; // 基础信息
}

export class FinancialSave {
  @Expose() id: number;
  @Type(() => KeyValue)
  @Transform((value) => plainToArray(KeyValue, value.value))
  @Expose() dataList: KeyValue[]; // 基础信息
}

export class FnaPDFQuery {
  @Expose() id: number;
  @Expose() merge: boolean;
  @Expose() formLanguage: string;
  @Expose() inputPolicyNo: string;
  @Expose() formNos: string | string[]; // 数组逗号分隔
  @Expose() categoryNos: string | string[]; // 数组逗号分隔
}

export class FnaSystemSave {
  @Expose() id: number;
  @Expose() accountName: string;
  @Expose() trAccountName: string;
  @Expose() customerType: string;
  @Expose() customerSource: string;
}

// .获取FNA表单配置
export class FnaFormConfig {
  @Expose() category: string;
  @Expose() name: string;
  @Expose() leader: string;
  @Expose() formNo: string;
  @Expose() tick: any;
  @Type(() => FnaFormConfig)
  @Transform((value) => plainToArray(FnaFormConfig, value.value))
  @Expose() vos: FnaFormConfig[] = [];
}


export class NewFnaAssessAdviceUpdateReq {
  @Expose() id: number; // 主键
  // @Expose() productCode: string; // 保险产品唯一标识
  @Type(() => KeyValue)
  @Transform((value) => plainToArray(KeyValue, value.value))
  @Expose() assessAdvice: KeyValue[] = []; // FNA 评估建议表
  @Type(() => RecommendProductCode)
  @Expose() customize: RecommendProductCode[] = []; // 自选推荐产品
  @Type(() => RecommendProductCode)
  @Expose() recommend: RecommendProductCode[] = []; // 系统推荐产品
  @Type(() => RecommendProductCode)
  @Expose() history: RecommendProductCode[] = []; // 历史推荐产品
  @Expose() productCodes: string[]; // 最终选择的产品
  @Type(() => KeyValue)
  @Transform((value) => plainToArray(KeyValue, value.value))
  @Expose() medicalAssessment: KeyValue[] = []; // 醫療保險產品合適性評估問卷
}

export class NewFnaChooseProductReq {
  @Expose() id: number; // 主键
  @Type(() => KeyValue)
  @Transform((value) => plainToArray(KeyValue, value.value))
  @Expose() assessAdvice: KeyValue[] = []; // FNA 评估建议表
  @Type(() => ChooseProduct)
  @Expose() chooseProduct: ChooseProduct[] = []; // 自选推荐产品
}

export class FnaAssessAdvice {
  @Expose() policyNo: string;
  @Expose() sumAssured: number;
  @Expose() premium: number;
  @Expose() 'EVALUATION_Reason': string;
  @Expose() 'EVALUATION_01': boolean;
  @Expose() 'EVALUATION_01_No': boolean;
  @Expose() 'EVALUATION_01_No_A': boolean;
  @Expose() 'EVALUATION_01_No_B': boolean;
  @Expose() 'EVALUATION_01_No_B_addition': string;
  @Expose() 'EVALUATION_01_A_No_B_addition': string;
  @Expose() 'EVALUATION_01_Yes': boolean;
  @Expose() 'EVALUATION_01_Yes_A': boolean;
  @Expose() 'EVALUATION_01_Yes_B': boolean;
  @Expose() 'EVALUATION_01_Yes_C': boolean;
  @Expose() 'EVALUATION_01_Yes_D': boolean;
  @Expose() 'EVALUATION_01_Yes_E': boolean;
  @Expose() 'EVALUATION_01_Yes_F': boolean;
  @Expose() 'EVALUATION_01_Yes_F_addition': string;
  @Expose() 'EVALUATION_02_A': boolean;
  @Expose() 'EVALUATION_02_B': boolean;
  @Expose() 'EVALUATION_02_B_addition': string;
}

// ZUU 附加资料
export class FnaFormInfo {
  @Expose() 'FNA_ADA_01_is_transfer_in': any;
  @Expose() 'FNA_ADA_02_is_new_customer': any;
  @Expose() 'FNA_ADA_03_insured': any;
  @Expose() 'FNA_ADA_04_policy_company': any;
  @Expose() 'FNA_ADA_05_policy_no': any;
  @Expose() 'FNA_ADA_06_term_of_payment': any;
  @Expose() 'FNA_ADA_07_frequency': any;
  @Expose() 'FNA_ADA_08_sum_assured': any;
  @Expose() 'FNA_ADA_08_sum_assured_currency' = 'HKD';
  @Expose() 'FNA_ADA_09_premium': any;
  @Expose() 'FNA_ADA_09_premium_currency' = 'HKD';
  @Expose() 'FNA_ADA_10_account_name': any;
  @Expose() 'FNA_ADA_11_first_year_commission_percentage': any;
  @Expose() 'FNA_ADA_12_first_year_commission_amount': any;
  @Expose() 'FNA_ADA_13': any;
  @Expose() 'FNA_ADA_13_2and_layer_account_name': any;
  @Expose() 'FNA_ADA_13_2and_layer_first_year_commission_percentage': any;
  @Expose() 'FNA_ADA_13_2and_layer_first_year_commission_amount': any;
  @Expose() 'FNA_ADA_13_3rd_layer_account_name': any;
  @Expose() 'FNA_ADA_13_3rd_layer_first_year_commission_percentage': any;
  @Expose() 'FNA_ADA_13_3rd_layer_first_year_commission_amount': any;
  @Expose() 'FNA_ADA_14': any;
  @Expose() 'FNA_ADA_15': any;
  @Expose() 'FNA_ADA_16_A': any;
  @Expose() 'FNA_ADA_16_B': any;
  @Expose() 'FNA_ADA_16_C': any;
  @Expose() 'FNA_ADA_16_D': any;
  @Expose() 'FNA_ADA_16_E': any;
  @Expose() 'FNA_ADA_16_F': any;
  @Expose() 'FNA_ADA_17': any;
  @Expose() 'FNA_ADA_18': any;
  @Expose() 'FNA_ADA_19_A': any;
  @Expose() 'FNA_ADA_19_B': any;
  @Expose() 'FNA_ADA_19_C': any;
  @Expose() 'FNA_ADA_19_D': any;
  @Expose() 'FNA_ADA_19_D_other_content': any;
  @Expose() 'FNA_ADA_20_i': any;
  @Expose() 'FNA_ADA_20_ii': any;
  @Expose() 'FNA_ADA_21_A': any;
  @Expose() 'FNA_ADA_21_A_name_of_lender': any;
  @Expose() 'FNA_ADA_21_A_loan_amount': any;
  @Expose() 'FNA_ADA_21_A_lending_rate': any;
  @Expose() 'FNA_ADA_21_A_loan_term': any;
  @Expose() 'FNA_ADA_21_A_monthly_repayment_amount': any;
  @Expose() 'FNA_ADA_21_B': any;
  @Expose() 'FNA_ADA_22_A': any;
  @Expose() 'FNA_ADA_22_B': any;
  @Expose() 'FNA_ADA_23_A': any;
  @Expose() 'FNA_ADA_23_B': any;
  @Expose() 'FNA_ADA_23_C': any;
  @Expose() 'FNA_ADA_24_A': any;
  @Expose() 'FNA_ADA_24_B': any;
  @Expose() 'FNA_ADA_24_C': any;
  @Expose() 'FNA_ADA_25_A': any;
  @Expose() 'FNA_ADA_25_B': any;
  @Expose() 'FNA_ADA_26_A': any;
  @Expose() 'FNA_ADA_26_B': any;
  @Expose() 'FNA_ADA_26_C': any;
  @Expose() 'FNA_ADA_26_D': any;
  @Expose() 'FNA_ADA_26_E': any;
  @Expose() 'FNA_ADA_26_F': any;
  @Expose() 'FNA_ADA_26_F_other_content': any;
  @Expose() 'FNA_ADA_27_A': any;
  @Expose() 'FNA_ADA_27_B': any;
  @Expose() 'FNA_ADA_27_C': any;
  @Expose() 'FNA_ADA_27_D': any;
  @Expose() 'FNA_ADA_27_E': any;
  @Expose() 'FNA_ADA_28_A': any;
  @Expose() 'FNA_ADA_28_B': any;
  @Expose() 'FNA_ADA_28_C': any;
  @Expose() 'FNA_ADA_28_D': any;
  @Expose() 'FNA_ADA_28_E': any;
  @Expose() 'FNA_ADA_29_A': any;
  @Expose() 'FNA_ADA_29_B': any;
  @Expose() 'FNA_ADA_29_C': any;
  @Expose() 'FNA_ADA_30_A': any;
  @Expose() 'FNA_ADA_30_B': any;
  @Expose() 'FNA_ADA_31_A': any;
  @Expose() 'FNA_ADA_31_B': any;
  @Expose() 'FNA_ADA_32_A': any;
  @Expose() 'FNA_ADA_32_B': any;
  @Expose() 'FNA_ADA_33_A': any;
  @Expose() 'FNA_ADA_33_B': any;
  @Expose() 'FNA_ADA_34_A': any;
  @Expose() 'FNA_ADA_34_B': any;
  @Expose() 'FNA_ADA_34_C': any;
  @Expose() 'FNA_ADA_35_A': any;
  @Expose() 'FNA_ADA_35_B': any;
  @Expose() 'FNA_ADA_35_C': any;
  @Expose() 'FNA_ADA_35_D': any;
  @Expose() 'FNA_ADA_36_A': any;
  @Expose() 'FNA_ADA_36_B': any;
  @Expose() 'FNA_ADA_36_C': any;
  @Expose() 'FNA_ADA_36_D': any;
  @Expose() 'FNA_ADA_37_A': any;
  @Expose() 'FNA_ADA_37_B': any;
  @Expose() 'FNA_ADA_38_A': any;
  @Expose() 'FNA_ADA_38_B': any;
  @Expose() 'FNA_ADA_38_C': any;
  @Expose() 'FNA_ADA_38_D': any;
  @Expose() 'FNA_ADA_39_A': any;
  @Expose() 'FNA_ADA_39_B': any;
  @Expose() 'FNA_ADA_39_C': any;
  @Expose() 'FNA_ADA_39_D': any;
  @Expose() 'FNA_ADA_40_A': any;
  @Expose() 'FNA_ADA_40_B': any;
  @Expose() 'FNA_ADA_40_C': any;
  @Expose() 'FNA_ADA_40_D': any;
  @Expose() 'FNA_ADA_41_A': any;
  @Expose() 'FNA_ADA_41_B': any;
  @Expose() 'FNA_ADA_41_C': any;
  @Expose() 'FNA_ADA_41_D': any;
  @Expose() 'FNA_ADA_42_individual_customers_A': any;
  @Expose() 'FNA_ADA_42_individual_customers_B': any;
  @Expose() 'FNA_ADA_42_individual_customers_C': any;
  @Expose() 'FNA_ADA_42_individual_customers_D': any;
  @Expose() 'FNA_ADA_42_individual_customers_E': any;
  @Expose() 'FNA_ADA_42_individual_customers_F': any;
  @Expose() 'FNA_ADA_42_individual_customers_F_other_content': any;
  @Expose() 'FNA_ADA_42_employer_client_A': any;
  @Expose() 'FNA_ADA_42_employer_client_B': any;
  @Expose() 'FNA_ADA_42_employer_client_C': any;
  @Expose() 'FNA_ADA_42_employer_client_C_other_content': any;
  @Expose() 'FNA_ADA_43_business_registration_certificate_number': any;
  @Expose() 'FNA_ADA_44_name_of_employer_representative': any;
  @Expose() 'FNA_ADA_45_A': any;
  @Expose() 'FNA_ADA_45_B': any;
  @Expose() 'FNA_ADA_46_A': any;
  @Expose() 'FNA_ADA_46_B': any;
  @Expose() 'FNA_ADA_46_C': any;
  @Expose() 'FNA_ADA_46_D': any;
  @Expose() 'FNA_ADA_46_E': any;
  @Expose() 'FNA_ADA_47_A': any;
  @Expose() 'FNA_ADA_47_B': any;
  @Expose() 'FNA_ADA_47_C': any;
  @Expose() 'FNA_ADA_47_D': any;
  @Expose() 'FNA_ADA_47_E': any;
  @Expose() 'FNA_ADA_47_F': any;
  @Expose() 'FNA_ADA_48_a_a': any;
  @Expose() 'FNA_ADA_48_a_b': any;
  @Expose() 'FNA_ADA_48_a_c': any;
  @Expose() 'FNA_ADA_48_b_a': any;
  @Expose() 'FNA_ADA_48_b_b': any;
  @Expose() 'FNA_ADA_48_b_c': any;
  @Expose() 'FNA_ADA_48_b_d': any;
  @Expose() 'FNA_ADA_48_b_d_other_content': any;
  @Expose() 'FNA_ADA_48_c_a': any;
  @Expose() 'FNA_ADA_48_c_b': any;
  @Expose() 'FNA_ADA_48_d_content': any;
  @Expose() 'FNA_ADA_49_intermediary_no': any;
  @Expose() 'FNA_ADA_50_A': any;
  @Expose() 'FNA_ADA_50_B': any;
  @Expose() 'FNA_ADA_50_C': any;
  @Expose() 'FNA_ADA_50_D': any;
  @Expose() 'FNA_ADA_51_A': any;
  @Expose() 'FNA_ADA_51_B': any;
  @Expose() 'FNA_ADA_51_C': any;
  @Expose() 'FNA_ADA_51_D': any;
  @Expose() 'FNA_ADA_51_D_other_content': any;
  @Expose() 'FNA_ADA_52': any;
  @Expose() 'FNA_ADA_53_referee': any;
  @Expose() 'FNA_ADA_54_A': any;
  @Expose() 'FNA_ADA_54_B': any;
  @Expose() 'FNA_ADA_54_B1_a': any;
  @Expose() 'FNA_ADA_54_B1_b': any;
  @Expose() 'FNA_ADA_54_B1_c': any;
  @Expose() 'FNA_ADA_54_B1_d': any;
  @Expose() 'FNA_ADA_54_B1_e': any;
  @Expose() 'FNA_ADA_54_B1_f': any;
  @Expose() 'FNA_ADA_54_B1_g': any;
  @Expose() 'FNA_ADA_54_B1_g_other_content': any;
  @Expose() 'FNA_ADA_54_B2_a': any;
  @Expose() 'FNA_ADA_54_B2_b': any;
  @Expose() 'FNA_ADA_54_B2_c': any;
  @Expose() 'FNA_ADA_54_B2_d': any;
  @Expose() 'FNA_ADA_54_B2_e': any;
  @Expose() 'FNA_ADA_54_B2_e_other_content': any;
}

export class ChooseProduct {
  @Expose() choose: boolean;
  @Type(() => ChooseProductInfo)
  @Transform((value) => plainToInstance(ChooseProductInfo, Object.assign(new ChooseProductInfo(), value.value)))
  @Expose() fnaProduct: ChooseProductInfo;
  @Type(() => ChooseProductInfo)
  @Expose() riderProduct: ChooseProductInfo[] = [];
}

export class ChooseProductInfo {
  @Expose() productCode: string;
  @Expose() companyName: string;
  @Expose() companyCode: string;
  @Expose() logo: string;
  @Expose() protectionPeriod: string;
  @Expose() premiumPaymentPeriod: string;
  @Expose() productName: string;
}

export class FnaMedicalAssessment {
  @Expose() 'MEDICAL_01_A': boolean;
  @Expose() 'MEDICAL_01_B': boolean;
  @Expose() 'MEDICAL_01_C': boolean;
  @Expose() 'MEDICAL_02_A': boolean;
  @Expose() 'MEDICAL_02_B': boolean;
  @Expose() 'MEDICAL_02_C': boolean;
}

export class FnaDeclaration {
  @Expose() 'DECLARATION_insurance_company': string;
  @Expose() 'DECLARATION_product_name': string;
  @Expose() 'DECLARATION_policy_number': string;
  @Expose() 'DECLARATION_policy_owner': string;
  @Expose() 'DECLARATION_policy_insured': string;
  @Expose() 'DECLARATION_premium': string;
  @Expose() 'DECLARATION_currency': string;
  @Expose() 'DECLARATION_payment_term': string;
  @Expose() 'DECLARATION_protection_period': string;
  @Expose() 'witnessName': string;
  @Expose() 'witnessNameEn': string;
  @Expose() 'witnessIdCard': string;
  @Expose() 'witnessRelationship': string;
}

export class BasicInfoInsuredCMG {
  @Expose() englishFirstName: string; // 英文名
  @Expose() englishLastName: string; // 英文姓
  @Expose() englishGivenName: string; // 英文名字
  @Expose() englishName: string; // 英文姓名
  @Expose() chineseFirstName: string; // 中文名
  @Expose() chineseGivenName: string; // 中文名字
  @Expose() chineseLastName: string; // 中文姓
  @Expose() chineseName: string; // 中文姓名
  @Expose() appellation: string; // 称谓
  @Expose() accountName: string; // 销售渠道
  @Expose() idCard: string; // 身份證號碼/出世紙號碼
  @Expose() passport: string; // 護照號碼
  @Expose() gender: string; // 性别 MALE FEMALE
  @Expose() customerType: string; // 客戶類型 INDIVIDUAL-個人 CORPORATE-公司
  @Expose() dateOfBirth: string; // 出生日期（yyyy-MM-dd）
  @Expose() nationality: string; // 国籍
  @Expose() smoke: boolean; // 是否吸烟
  @Expose() countryOfBirth: string; // 出生国家
  @Expose() taxResidence: string; // 税务国家
  @Expose() relationship: string; // 與受保人關係
  @Type(() => ResidentialAddressCMG)
  @Transform((value) => plainToInstance(ResidentialAddressCMG, Object.assign(new ResidentialAddressCMG(), value.value)))
  @Expose() residentialAddress: ResidentialAddressCMG = new ResidentialAddressCMG(); // 居住地址
  @Type(() => ResidentialAddressCMG)
  @Transform((value) => plainToInstance(ResidentialAddressCMG, Object.assign(new ResidentialAddressCMG(), value.value)))
  @Expose() correspondenceAddress: ResidentialAddressCMG = new ResidentialAddressCMG(); // 通訊地址
  @Type(() => ResidentialAddressCMG)
  @Transform((value) => plainToInstance(ResidentialAddressCMG, Object.assign(new ResidentialAddressCMG(), value.value)))
  @Expose() permanentAddress: ResidentialAddressCMG = new ResidentialAddressCMG(); // 永久地址
  @Type(() => ResidentialAddressCMG)
  @Transform((value) => plainToInstance(ResidentialAddressCMG, Object.assign(new ResidentialAddressCMG(), value.value)))
  @Expose() employerAddress: ResidentialAddressCMG = new ResidentialAddressCMG(); // 公司地址
  @Expose() remark: string; // 备注
  @Expose() employerName: string; // 雇主
  @Expose() averageMonthlySalary: string; // 每月平均收入(HK$)
  @Expose() mobileArea: string; // 手提（手机号区号）
  @Expose() mobileCountry: string; // 手提（手机号区号）
  @Expose() mobile: string; // 手提（手机号）
  @Expose() homePhoneArea: string; // 住宅（电话）
  @Expose() homePhoneCountry: string; // 住宅（电话）
  @Expose() homePhone: string; // 住宅（电话）
  @Expose() officeTelephoneArea: string; // 办公室（电话）
  @Expose() officeTelephone: string; // 办公室（电话）
  @Expose() maritalStatus: string; // 婚姻状况
  @Expose() emailAddress: string; // 电邮地址
  @Expose() education: string; // 教育程度
  @Expose() othersEducationAddition: string; // 教育程度 其他
  @Expose() employment: string; // 就業情況
  @Expose() position: string; // 職位
  @Expose() industry: string; // 行業
  @Expose() intendedRetirement: string; // 预期退休年龄
  @Expose() retirementAdditional: string; // 预期退休年龄(其他
}

export class ResidentialAddressCMG {
  @Expose() room: string; // 室
  @Expose() floor: string; // 楼
  @Expose() block: string; // 座數/大廈
  @Expose() court: string; // 屋苑名稱
  @Expose() road: string; // 街道名稱及號碼
  @Expose() district: string; // 地區
  @Expose() province: string; // #省
  @Expose() country: string; // #國家
  @Expose() hkDistrict: string; // #香港地区
  @Expose() postalCode: string; // #郵政編號
}

export class FnaQuestionNoAndResult {
  @Expose() questionNo: string;
  @Expose() result: string;
}

export class PiBaFinancialArr {
  @Expose() 'FNA_01_A': any;
  @Expose() 'FNA_01_A_addition': any;
  @Expose() 'FNA_01_B': any;
  @Expose() 'FNA_01_B_addition': any;
  @Expose() 'FNA_01_B_addition_i': any;
  @Expose() 'FNA_01_B_addition_ii': any;
  @Expose() 'FNA_01_B_addition_iii': any;
  @Expose() 'FNA_01_B_addition_iiii': any;
  @Expose() 'FNA_01_B_addition_iiii_addition': any;
  // @Expose() 'FNA_01_B_addition_indemnity': any;
  // @Expose() 'FNA_01_B_addition_non_indemnity': any;
  // @Expose() 'FNA_01_B_addition_combo_product': any;
  @Expose() 'FNA_01_C': any;
  @Expose() 'FNA_01_D': any;
  @Expose() 'FNA_01_E': any;
  @Expose() 'FNA_01_F': any;
  @Expose() 'FNA_01_F_addition': any;
  @Expose() 'FNA_02_A': any;
  @Expose() 'FNA_02_B': any;
  @Expose() 'FNA_02_C': any;
  @Expose() 'FNA_03_A': any;
  @Expose() 'FNA_03_B': any;
  @Expose() 'FNA_03_C': any;
  @Expose() 'FNA_03_D': any;
  @Expose() 'FNA_03_E': any;
  @Expose() 'FNA_03_F': any;
  @Expose() 'FNA_03_G': any;
  @Expose() 'FNA_04_A': any;
  @Expose() 'FNA_04_A_addition_money': any;
  @Expose() 'FNA_04_B': any;
  @Expose() 'FNA_04_B_addition_money': any;
  @Expose() 'FNA_04_C': any;
  @Expose() 'FNA_04_C_addition_money': any;
  @Expose() 'FNA_04_C_addition_ages': any;
  @Expose() 'FNA_04_D': any;
  @Expose() 'FNA_04_D_addition_ages': any;
  @Expose() 'FNA_04_D_addition_money': any;
  @Expose() 'FNA_04_E': any;
  @Expose() 'FNA_04_E_addition': any;
  @Expose() 'FNA_04_E_addition_money': any;
  @Expose() 'FNA_05_a_A': any;
  @Expose() 'FNA_05_a_A_addition': any;
  @Expose() 'FNA_05_a_B': any;
  @Expose() 'FNA_05_a_C': any;
  @Expose() 'FNA_05_a_D': any;
  @Expose() 'FNA_05_a_E': any;
  @Expose() 'FNA_05_a_F': any;
  @Expose() 'FNA_05_b': any;
  @Expose() 'FNA_05_c_A': any;
  @Expose() 'FNA_05_c_B': any;
  @Expose() 'FNA_05_c_C': any;
  @Expose() 'FNA_05_c_D': any;
  @Expose() 'FNA_05_c_E': any;
  @Expose() 'FNA_05_c_F': any;
  @Expose() 'FNA_05_c_F_percent': any;
  @Expose() 'FNA_05_c_H_addition': any;
  @Expose() 'FNA_05_d_A': any;
  @Expose() 'FNA_05_d_B': any;
  @Expose() 'FNA_05_d_C': any;
  @Expose() 'FNA_05_d_D': any;
  @Expose() 'FNA_05_d_E': any;
  @Expose() 'FNA_05_d_F': any;
  @Expose() 'FNA_05_d_F_percent': any;
  @Expose() 'FNA_05_d_H_addition': any;
  @Expose() 'FNA_05_e_basic': any;
  @Expose() 'FNA_05_e_basic_A': any;
  // @Expose() 'FNA_05_e_basic_A_year': any;
  @Expose() 'FNA_05_e_basic_B': any;
  @Expose() 'FNA_05_e_basic_C': any;
  @Expose() // 'FNA_05_e_basic_C_money': any;
  @Expose() 'FNA_05_e_basic_D': any;
  @Expose() 'FNA_05_e_basic_E': any;
  @Expose() 'FNA_05_e_basic_F': any;
  @Expose() 'FNA_05_e_basic_G': any;
  @Expose() 'FNA_05_e_basic_G_year': any;
  @Expose() 'FNA_05_e_basic_H': any;
  @Expose() 'FNA_05_e_basic_H_money': any;
  @Expose() 'FNA_05_e_rider': any;
  @Expose() 'FNA_05_e_rider_A': any;
  // @Expose() 'FNA_05_e_rider_A_year': any;
  @Expose() 'FNA_05_e_rider_B': any;
  @Expose() 'FNA_05_e_rider_C': any;
  @Expose() 'FNA_05_e_rider_D': any;
  @Expose() 'FNA_05_e_rider_E': any;
  @Expose() 'FNA_05_e_rider_F': any;
  @Expose() 'FNA_05_e_rider_G': any;
  @Expose() 'FNA_05_e_rider_G_year': any;
  @Expose() 'FNA_05_e_rider_H': any;
  @Expose() 'FNA_05_e_rider_H_money': any;
  @Expose() 'FNA_05_f_A': any;
  @Expose() 'FNA_05_f_B': any;
  @Expose() 'FNA_05_f_B_addition': any;
  @Expose() 'FNA_06_a_A': any;
  @Expose() 'FNA_06_a_Policy_Number': any;
  @Expose() 'FNA_06_a_Insurer_Name': any;
  @Expose() 'FNA_06_a_Lender_Name': any;
  @Expose() 'FNA_06_a_Loan_Amount': any;
  @Expose() 'FNA_06_a_Interest_Rate': any;
  @Expose() 'FNA_06_a_Repayment_Term': any;
  @Expose() 'FNA_06_a_Repayment_Amount': any;
  @Expose() 'FNA_06_a_Repayment_Amount_02': any;
  @Expose() 'FNA_06_a_B': any;
  @Expose() 'FNA_06_b_A': any;
  @Expose() 'FNA_06_b_Policy_Number_01': any;
  @Expose() 'FNA_06_b_Insurer_Name_01': any;
  @Expose() 'FNA_06_b_Lender_Name_01': any;
  @Expose() 'FNA_06_b_Loan_Amount_01': any;
  @Expose() 'FNA_06_b_Interest_Rate_01': any;
  @Expose() 'FNA_06_b_Repayment_Term_01': any;
  @Expose() 'FNA_06_b_Repayment_Amount_01': any;
  @Expose() 'FNA_06_b_Repayment_Amount_01_02': any;
  @Expose() 'FNA_06_b_Policy_Number_02': any;
  @Expose() 'FNA_06_b_Insurer_Name_02': any;
  @Expose() 'FNA_06_b_Lender_Name_02': any;
  @Expose() 'FNA_06_b_Loan_Amount_02': any;
  @Expose() 'FNA_06_b_Interest_Rate_02': any;
  @Expose() 'FNA_06_b_Repayment_Term_02': any;
  @Expose() 'FNA_06_b_Repayment_Amount_02': any;
  @Expose() 'FNA_06_b_Repayment_Amount_02_02': any;
  @Expose() 'FNA_06_b_B': any;
}

export const QuestionsArr = [
  {
    questionNo: '3001',
    title: 'question1',
    subStyle: 'border-bottom: none;border-radius: 8px 8px 0 0;padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_01_A',
        description: 'answer1',
        tick: false,
        inputBox: [
          {
            key: 'FNA_01_A_addition',
            placeholder: 'HKD',
            prefix: '',
            suffix: '',
            field: 'money',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_01_B',
        description: 'answer2',
        tick: false,
        inputBox: [
          {
            key: 'FNA_01_B_addition',
            placeholder: 'HKD',
            prefix: '',
            suffix: '',
            field: 'money',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX',
        key: 'FNA_01_C',
        description: 'answer3',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX',
        key: 'FNA_01_D',
        description: 'answer4',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX',
        key: 'FNA_01_E',
        description: 'answer5',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_01_F',
        description: 'answer6',
        tick: false,
        inputBox: [
          {
            key: 'FNA_01_F_addition',
            prefix: '',
            suffix: '',
            field: 'other',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: '3002',
    title: 'question2',
    subStyle: 'padding-top: 0px;border-top: none;margin-top: 0;border-radius: 0 0 8px 8px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO',
        key: 'FNA_02_A',
        description: 'answer7',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_02_B',
        description: 'answer8',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_02_C',
        description: 'answer9',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: '3003',
    title: 'question3',
    subStyle: 'padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO',
        key: 'FNA_03_A',
        description: 'answer10',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_B',
        description: 'answer11',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_C',
        description: 'answer12',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_D',
        description: 'answer13',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_E',
        description: 'answer14',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_F',
        description: 'answer15',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_G',
        description: 'answer16',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: '3004',
    title: 'question4',
    subStyle: 'padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_04_A',
        description: 'answer17',
        tick: false,
        inputBox: [
          {
            key: 'FNA_04_A_addition_01',
            prefix: 'answer18',
            suffix: 'answer19',
            field: 'years',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_A_addition_02',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_04_B',
        description: 'answer20',
        tick: false,
        inputBox: [
          {
            key: 'FNA_04_B_addition_01',
            prefix: 'answer18',
            suffix: 'answer19',
            field: 'years',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_B_addition_02',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: '3005',
    title: 'question5',
    subStyle: 'border-bottom: none;border-radius: 8px 8px 0 0;padding-top: 20px;',
    questionTitle: 'question6',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO_WITH_INPUT',
        key: 'FNA_05_a_A',
        description: 'answer21',
        tick: false,
        inputBox: [
          {
            key: 'FNA_05_a_A_addition',
            placeholder: 'HKD',
            prefix: '',
            suffix: '',
            field: 'money',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_a_B',
        description: 'answer22',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_a_C',
        description: 'answer23',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_a_D',
        description: 'answer24',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_a_E',
        description: 'answer25',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_a_F',
        description: 'answer26',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: '3006',
    questionTitle: 'question7',
    subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'INPUT_BOX',
        key: 'FNA_05_b',
        description: 'answer47',
        tick: false,
        inputBox: [
          {
            prefix: '',
            suffix: 'HKD',
            field: 'total',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: '3007',
    questionTitle: 'question8',
    subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO',
        key: 'FNA_05_c_A',
        description: '< 10%',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_c_B',
        description: '10% - 20%',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_c_C',
        description: '21% - 30%',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_c_D',
        description: '31% - 40% ',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_c_E',
        description: '41% - 50%',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_c_F',
        description: '> 50 %',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO_WITH_INPUT',
        key: 'FNA_05_c_G',
        description: 'answer27',
        tick: false,
        inputBox: [
          {
            key: 'FNA_05_c_G_addition',
            prefix: '',
            suffix: '',
            field: 'reason',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: '3008',
    questionTitle: 'question9',
    subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO',
        key: 'FNA_05_d_A',
        description: '< 10%',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_d_B',
        description: '10% - 20%',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_d_C',
        description: '21% - 30%',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_d_D',
        description: '31% - 40% ',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_d_E',
        description: '41% - 50%',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_d_F',
        description: '> 50 %',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: '3009',
    questionTitle: 'question10',
    subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO',
        key: 'FNA_05_e_A',
        description: 'answer28',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_e_B',
        description: 'answer29',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_e_C',
        description: 'answer30',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_e_D',
        description: 'answer31',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_e_E',
        description: 'answer32',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_05_e_F',
        description: 'answer33',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO_WITH_INPUT',
        key: 'FNA_05_e_G',
        description: 'answer34',
        tick: false,
        inputBox: [
          {
            key: 'FNA_05_e_G_addition',
            prefix: '',
            suffix: 'HKD',
            field: 'money',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: '3010',
    questionTitle: 'question11',
    subStyle: 'border-top: none;margin-top: 0;border-radius: 0 0 8px 8px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO',
        key: 'FNA_05_f_A',
        description: 'answer35',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO_WITH_INPUT',
        key: 'FNA_05_f_B',
        description: 'answer36',
        tick: false,
        inputBox: [
          {
            key: 'FNA_05_f_B_addition',
            prefix: '',
            suffix: '',
            field: 'other',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: '3011',
    title: 'question12',
    questionTitle: 'question13',
    subStyle: 'border-bottom: none;border-radius: 8px 8px 0 0;padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO_WITH_INPUT',
        key: 'FNA_06_a_A',
        description: 'answer37',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_06_a_B',
        description: 'answer38',
        tick: undefined,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: '3018',
    title: '',
    subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
    subKey: 'FNA_06_a_A_Sub',
    needModule: true,
    needFill: false,
    answers: [
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_a_Policy_Number',
            title: 'answer39',
            prefix: '',
            suffix: '',
            field: 'proposedPolicyNumber',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_a_Insurer_Name',
            title: 'answer40',
            prefix: '',
            suffix: '',
            field: 'insurerName',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_a_Lender_Name',
            title: 'answer41',
            prefix: '',
            suffix: '',
            field: 'lenderName',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_a_Loan_Amount',
            title: 'answer42',
            prefix: '',
            suffix: '',
            field: 'total',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_a_Interest_Rate',
            title: 'answer43',
            prefix: '',
            suffix: '',
            field: 'rate',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_a_Repayment_Term',
            title: 'answer44',
            prefix: '',
            suffix: '',
            field: 'limit',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_a_Repayment_Amount',
            title: 'answer45',
            prefix: '',
            suffix: '',
            field: 'repaymentAmount',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_a_Repayment_Amount_02',
            title: 'answer46',
            prefix: '',
            suffix: '',
            field: 'repaymentAmount3',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: '3020',
    questionTitle: 'question14',
    subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO',
        key: 'FNA_06_b_A',
        description: 'answer37',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_06_b_B',
        description: 'answer38',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: '3021',
    title: 'Policy1',
    needModule: true,
    subKey: 'FNA_06_b_A_Sub_a',
    subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
    needFill: false,
    answers: [
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Policy_Number_01',
            title: 'answer39',
            prefix: '',
            suffix: '',
            field: 'proposedPolicyNumber',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Insurer_Name_01',
            title: 'answer40',
            prefix: '',
            suffix: '',
            field: 'insurerName',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Lender_Name_01',
            title: 'answer41',
            prefix: '',
            suffix: '',
            field: 'lenderName',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Loan_Amount_01',
            title: 'answer42',
            prefix: '',
            suffix: '',
            field: 'total',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Interest_Rate_01',
            title: 'answer43',
            prefix: '',
            suffix: '',
            field: 'rate',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Repayment_Term_01',
            title: 'answer44',
            prefix: '',
            suffix: '',
            field: 'limit',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Repayment_Amount_01',
            title: 'answer45',
            prefix: '',
            suffix: '',
            field: 'repaymentAmount',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Repayment_Amount_01_02',
            title: 'answer46',
            prefix: '',
            suffix: '',
            field: 'repaymentAmount3',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: '3022',
    title: 'Policy2',
    needModule: true,
    subKey: 'FNA_06_b_A_Sub_b',
    subStyle: 'border-top: none;margin-top: 0;border-radius: 0 0 8px 8px;',
    needFill: false,
    answers: [
      {
        moduleType: 'INPUT_BOX',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Policy_Number_02',
            title: 'answer39',
            prefix: '',
            suffix: '',
            field: 'proposedPolicyNumber',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Insurer_Name_02',
            title: 'answer40',
            prefix: '',
            suffix: '',
            field: 'insurerName',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Lender_Name_02',
            title: 'answer41',
            prefix: '',
            suffix: '',
            field: 'lenderName',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Loan_Amount_02',
            title: 'answer42',
            prefix: '',
            suffix: '',
            field: 'total',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Interest_Rate_02',
            title: 'answer43',
            prefix: '',
            suffix: '',
            field: 'rate',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Repayment_Term_02',
            title: 'answer44',
            prefix: '',
            suffix: '',
            field: 'limit',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Repayment_Amount_02',
            title: 'answer45',
            inclass: '',
            prefix: '',
            suffix: '',
            field: 'repaymentAmount',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        description: '',
        tick: false,
        inputBox: [
          {
            key: 'FNA_06_b_Repayment_Amount_02_02',
            title: 'answer46',
            prefix: '',
            suffix: '',
            field: 'repaymentAmount3',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  }
];


export const QuestionsArrCMG = [
  {
    questionNo: 'FNA_01',
    title: 'question1',
    subStyle: 'border-bottom: none;border-radius: 8px 8px 0 0;padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_01_A',
        description: 'answer1',
        tick: false,
        inputBox: [
          {
            key: 'FNA_01_A_addition',
            placeholder: 'HKD',
            prefix: '',
            suffix: '',
            field: 'money',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_01_B',
        description: 'answer2',
        tick: false,
        inputBox: [
          {
            key: 'FNA_01_B_addition',
            placeholder: 'HKD',
            prefix: '',
            suffix: '',
            field: 'money',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: [
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_i',
            subKey: 'FNA_01_B_addition_i',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_ii',
            subKey: 'FNA_01_B_addition_ii',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_iii',
            subKey: 'FNA_01_B_addition_iii',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_indemnity',
            subKey: 'FNA_01_B_addition_indemnity',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_non_indemnity',
            subKey: 'FNA_01_B_addition_non_indemnity',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_combo_product',
            subKey: 'FNA_01_B_addition_combo_product',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
        ]
      },
      {
        moduleType: 'CHECKBOX',
        key: 'FNA_01_C',
        description: 'answer3',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX',
        key: 'FNA_01_D',
        description: 'answer4',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX',
        key: 'FNA_01_E',
        description: 'answer5',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_01_F',
        description: 'answer6',
        tick: false,
        inputBox: [
          {
            key: 'FNA_01_F_addition',
            prefix: '',
            suffix: '',
            field: 'other',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: 'FNA_02',
    title: 'question2',
    subStyle: 'padding-top: 0px;border-top: none;margin-top: 0;border-radius: 0 0 8px 8px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO',
        key: 'FNA_02_A',
        description: 'answer7',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_02_B',
        description: 'answer8',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_02_C',
        description: 'answer9',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: 'FNA_03',
    title: 'question3',
    subStyle: 'padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO',
        key: 'FNA_03_A',
        description: 'answer10',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_B',
        description: 'answer11',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_C',
        description: 'answer12',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_D',
        description: 'answer13',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_E',
        description: 'answer14',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_F',
        description: 'answer15',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_G',
        description: 'answer16',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: 'FNA_04',
    title: 'question4',
    subStyle: 'padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_04_A',
        description: 'answer17',
        tick: false,
        inputBox: [
          {
            key: 'FNA_04_A_addition_age',
            prefix: 'answer18',
            suffix: 'answer19',
            field: 'years',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_A_addition_years',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_A_addition_money',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_04_B',
        description: 'answer20',
        tick: false,
        inputBox: [
          {
            key: 'FNA_04_B_addition_age',
            prefix: 'answer18',
            suffix: 'answer19',
            field: 'years',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_B_addition_years',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_B_addition_money',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: 'FNA_05',
    title: 'question5',
    subStyle: 'border-bottom: none;border-radius: 8px 8px 0 0;padding-top: 20px;',
    questionTitle: 'question6',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO_WITH_INPUT',
        key: 'FNA_05_sub',
        description: 'answer37',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: 'FNA_05_sub',
        subQuestions: [
          {
            questionNo: 'FNA_05_a',
            title: '',
            subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
            subKey: 'FNA_05_a',
            needModule: true,
            needFill: false,
            answers: [
              {
                moduleType: 'RADIO_WITH_INPUT',
                key: 'FNA_05_a_A',
                description: 'answer21',
                tick: false,
                inputBox: [
                  {
                    key: 'FNA_05_a_A_addition',
                    placeholder: 'HKD',
                    prefix: '',
                    suffix: '',
                    field: 'money',
                    boxType: 'MONEY',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_a_B',
                description: 'answer22',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_a_C',
                description: 'answer23',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_a_D',
                description: 'answer24',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_a_E',
                description: 'answer25',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_a_F',
                description: 'answer26',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
          {
            questionNo: 'FNA_05_b',
            subKey: 'FNA_05_b',
            questionTitle: 'question7',
            subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
            needModule: true,
            needFill: true,
            answers: [
              {
                moduleType: 'INPUT_BOX',
                key: 'FNA_05_b',
                description: 'answer47',
                tick: false,
                inputBox: [
                  {
                    prefix: '',
                    suffix: 'HKD',
                    field: 'total',
                    boxType: 'MONEY',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
          {
            questionNo: 'FNA_05_c',
            subKey: 'FNA_05_c',
            questionTitle: 'question8',
            subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
            needModule: true,
            needFill: true,
            answers: [
              {
                moduleType: 'RADIO',
                key: 'FNA_05_c_A',
                description: '< 10%',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_c_B',
                description: '10% - 20%',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_c_C',
                description: '21% - 30%',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_c_D',
                description: '31% - 40% ',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_c_E',
                description: '41% - 50%',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_c_F',
                description: '> 50 %',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO_WITH_INPUT',
                key: 'FNA_05_c_G',
                description: 'answer27',
                tick: false,
                inputBox: [
                  {
                    key: 'FNA_05_c_G_addition',
                    prefix: '',
                    suffix: '',
                    field: 'reason',
                    boxType: 'TEXT',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
          {
            questionNo: 'FNA_05_d',
            subKey: 'FNA_05_d',
            questionTitle: 'question9',
            subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
            needModule: true,
            needFill: true,
            answers: [
              {
                moduleType: 'RADIO',
                key: 'FNA_05_d_A',
                description: '< 10%',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_d_B',
                description: '10% - 20%',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_d_C',
                description: '21% - 30%',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_d_D',
                description: '31% - 40% ',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_d_E',
                description: '41% - 50%',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_d_F',
                description: '> 50 %',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
          {
            questionNo: 'FNA_05_e',
            subKey: 'FNA_05_e',
            questionTitle: 'question10',
            subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
            needModule: true,
            needFill: true,
            answers: [
              {
                moduleType: 'RADIO',
                key: 'FNA_05_e_A',
                description: 'answer28',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_e_B',
                description: 'answer29',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_e_C',
                description: 'answer30',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_e_D',
                description: 'answer31',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_e_E',
                description: 'answer32',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_e_F',
                description: 'answer33',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO_WITH_INPUT',
                key: 'FNA_05_e_G',
                description: 'answer34',
                tick: false,
                inputBox: [
                  {
                    key: 'FNA_05_e_G_addition',
                    prefix: '',
                    suffix: 'HKD',
                    field: 'money',
                    boxType: 'MONEY',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
          {
            questionNo: 'FNA_05_f',
            subKey: 'FNA_05_f',
            questionTitle: 'question11',
            subStyle: 'border-top: none;margin-top: 0;border-radius: 0 0 8px 8px;',
            needModule: true,
            needFill: true,
            answers: [
              {
                moduleType: 'RADIO',
                key: 'FNA_05_f_A',
                description: 'answer35',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO_WITH_INPUT',
                key: 'FNA_05_f_B',
                description: 'answer36',
                tick: false,
                inputBox: [
                  {
                    key: 'FNA_05_f_B_addition',
                    prefix: '',
                    suffix: '',
                    field: 'other',
                    boxType: 'TEXT',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
        ]
      },
    ],
  },
  {
    questionNo: 'FNA_06',
    title: 'question12',
    questionTitle: 'question13',
    subStyle: 'border-bottom: none;border-radius: 8px 8px 0 0;padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        subQuestion: 'FNA_06_a',
        key: 'FNA_06_a',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestions: [
          {
            moduleType: 'RADIO_WITH_INPUT',
            key: 'FNA_06_a_A',
            subKey: 'FNA_06_a_A',
            description: 'answer37',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: [
              {
                questionNo: 'FNA_06_a_A_Sub',
                title: '',
                subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
                subKey: 'FNA_06_a_A_Sub',
                needModule: true,
                needFill: false,
                answers: [
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Policy_Number',
                        title: 'answer39',
                        prefix: '',
                        suffix: '',
                        field: 'proposedPolicyNumber',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Insurer_Name',
                        title: 'answer40',
                        prefix: '',
                        suffix: '',
                        field: 'insurerName',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Lender_Name',
                        title: 'answer41',
                        prefix: '',
                        suffix: '',
                        field: 'lenderName',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Loan_Amount',
                        title: 'answer42',
                        prefix: '',
                        suffix: '',
                        field: 'total',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Interest_Rate',
                        title: 'answer43',
                        prefix: '',
                        suffix: '',
                        field: 'rate',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Repayment_Term',
                        title: 'answer44',
                        prefix: '',
                        suffix: '',
                        field: 'limit',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Repayment_Amount',
                        title: 'answer45',
                        prefix: '',
                        suffix: '',
                        field: 'repaymentAmount',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Repayment_Amount_02',
                        title: 'answer46',
                        prefix: '',
                        suffix: '',
                        field: 'repaymentAmount3',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  }
                ]
              },
            ]
          },
          {
            moduleType: 'RADIO',
            key: 'FNA_06_a_B',
            subKey: 'FNA_06_a_B',
            description: 'answer38',
            tick: undefined,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          }
        ],
        moduleType: null,
        description: '',
        title: null,
      },
      {
        subQuestion: 'FNA_06_b',
        key: 'FNA_06_b',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestions: [
          {
            moduleType: 'RADIO',
            key: 'FNA_06_b_A',
            subKey: 'FNA_06_b_A',
            description: 'answer37',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: [
              {
                questionNo: 'FNA_06_b_A_Sub',
                title: 'Policy1',
                needModule: true,
                subKey: 'FNA_06_b_A_Sub_a',
                subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
                needFill: false,
                answers: [
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Policy_Number_01',
                        title: 'answer39',
                        prefix: '',
                        suffix: '',
                        field: 'proposedPolicyNumber',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Insurer_Name_01',
                        title: 'answer40',
                        prefix: '',
                        suffix: '',
                        field: 'insurerName',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Lender_Name_01',
                        title: 'answer41',
                        prefix: '',
                        suffix: '',
                        field: 'lenderName',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Loan_Amount_01',
                        title: 'answer42',
                        prefix: '',
                        suffix: '',
                        field: 'total',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Interest_Rate_01',
                        title: 'answer43',
                        prefix: '',
                        suffix: '',
                        field: 'rate',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Repayment_Term_01',
                        title: 'answer44',
                        prefix: '',
                        suffix: '',
                        field: 'limit',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Repayment_Amount_01',
                        title: 'answer45',
                        prefix: '',
                        suffix: '',
                        field: 'repaymentAmount',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Repayment_Amount_01_02',
                        title: 'answer46',
                        prefix: '',
                        suffix: '',
                        field: 'repaymentAmount3',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  }
                ]
              },
              {
                questionNo: 'FNA_06_b_A_Sub_b',
                title: 'Policy2',
                needModule: true,
                subKey: 'FNA_06_b_A_Sub_b',
                subStyle: 'border-top: none;margin-top: 0;border-radius: 0 0 8px 8px;',
                needFill: false,
                answers: [
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Policy_Number_02',
                        title: 'answer39',
                        prefix: '',
                        suffix: '',
                        field: 'proposedPolicyNumber',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Insurer_Name_02',
                        title: 'answer40',
                        prefix: '',
                        suffix: '',
                        field: 'insurerName',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Lender_Name_02',
                        title: 'answer41',
                        prefix: '',
                        suffix: '',
                        field: 'lenderName',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Loan_Amount_02',
                        title: 'answer42',
                        prefix: '',
                        suffix: '',
                        field: 'total',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Interest_Rate_02',
                        title: 'answer43',
                        prefix: '',
                        suffix: '',
                        field: 'rate',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Repayment_Term_02',
                        title: 'answer44',
                        prefix: '',
                        suffix: '',
                        field: 'limit',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Repayment_Amount_02',
                        title: 'answer45',
                        inclass: '',
                        prefix: '',
                        suffix: '',
                        field: 'repaymentAmount',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Repayment_Amount_02_02',
                        title: 'answer46',
                        prefix: '',
                        suffix: '',
                        field: 'repaymentAmount3',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  }
                ]
              }
            ]
          },
          {
            moduleType: 'RADIO',
            key: 'FNA_06_b_B',
            subKey: 'FNA_06_b_B',
            description: 'answer38',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          }
        ],
        moduleType: null,
        description: '',
        title: null,
      },
    ]
  }
];


export const QuestionsArrZUU = [
  {
    questionNo: 'FNA_01',
    title: 'question1',
    subStyle:
      'border-bottom: none;border-radius: 8px 8px 0 0;padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_01_A',
        description: 'answer1',
        tick: false,
        inputBox: [
          {
            key: 'FNA_01_A_addition',
            placeholder: 'HKD',
            prefix: '',
            suffix: '',
            field: 'money',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_01_A_addition_currency',
            placeholder: '',
            prefix: '',
            suffix: '',
            field: '',
            boxType: '',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_01_B',
        description: 'answer2',
        tick: false,
        inputBox: [
          {
            key: 'FNA_01_B_addition',
            placeholder: 'HKD',
            prefix: '',
            suffix: '',
            field: 'money',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_01_B_addition_currency',
            placeholder: 'HKD',
            prefix: '',
            suffix: '',
            field: 'money',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: [
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_i',
            subKey: 'FNA_01_B_addition_i',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_ii',
            subKey: 'FNA_01_B_addition_ii',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_iii',
            subKey: 'FNA_01_B_addition_iii',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_indemnity',
            subKey: 'FNA_01_B_addition_indemnity',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_non_indem nity',
            subKey: 'FNA_01_B_addition_non_indemnity',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_combo_product',
            subKey: 'FNA_01_B_addition_combo_product',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          }
        ]
      },
      {
        moduleType: 'CHECKBOX',
        key: 'FNA_01_C',
        description: 'answer3',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX',
        key: 'FNA_01_D',
        description: 'answer4',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX',
        key: 'FNA_01_E',
        description: 'answer5',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_01_F',
        description: 'answer6',
        tick: false,
        inputBox: [
          {
            key: 'FNA_01_F_v_addition',
            prefix: '',
            suffix: '',
            field: 'other',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: [
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_F_i',
            subKey: 'FNA_01_F_i',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_F_ii',
            subKey: 'FNA_01_F_ii',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_F_iii',
            subKey: 'FNA_01_F_iii',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_F_iv',
            subKey: 'FNA_01_F_iv',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_F_v',
            subKey: 'FNA_01_F_v',
            description: 'answer3',
            tick: false,
            inputBox: [
              {
                key: 'FNA_01_F_v_addition',
                prefix: '',
                suffix: '',
                field: 'other',
                boxType: 'TEXT',
                moreChoice: null,
                error: null,
                value: null
              }
            ],
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
        ]
      }
    ]
  },
  {
    questionNo: 'FNA_02',
    title: 'question2',
    subStyle:
      'padding-top: 0px;border-top: none;margin-top: 0;border-radius: 0 0 8px 8px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO',
        key: 'FNA_02_A',
        description: 'answer7',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_02_B',
        description: 'answer8',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_02_C',
        description: 'answer9',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: 'FNA_03',
    title: 'question3',
    subStyle: 'padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO',
        key: 'FNA_03_A',
        description: 'answer10',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_B',
        description: 'answer11',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_C',
        description: 'answer12',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_D',
        description: 'answer13',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_E',
        description: 'answer14',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_F',
        description: 'answer15',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_G',
        description: 'answer16',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: 'FNA_04',
    title: 'question4',
    subStyle: 'padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_04_A',
        description: 'answer17',
        tick: false,
        inputBox: [
          {
            key: 'FNA_04_A_addition_age',
            prefix: 'answer18',
            suffix: 'answer19',
            field: 'years',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_A_addition_years',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_A_addition_money',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_A_addition_money_currency',
            prefix: '',
            suffix: '',
            field: '',
            boxType: '',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_04_B',
        description: 'answer20',
        tick: false,
        inputBox: [
          {
            key: 'FNA_04_B_addition_age',
            prefix: 'answer18',
            suffix: 'answer19',
            field: 'years',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_B_addition_years',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_B_addition_money',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_B_addition_money_currency',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'INPUT_BOX',
        key: 'FNA_04_addition_anticipate_years',
        description: 'answer47',
        tick: false,
        inputBox: [
          {
            prefix: '',
            suffix: 'HKD',
            field: 'total',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: 'FNA_05',
    title: 'question5',
    subStyle:
      'border-bottom: none;border-radius: 8px 8px 0 0;padding-top: 20px;',
    questionTitle: 'question6',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO_WITH_INPUT',
        key: 'FNA_05_sub',
        description: 'answer37',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: 'FNA_05_sub',
        subQuestions: [
          {
            questionNo: 'FNA_05_a',
            title: '',
            subStyle:
              'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
            subKey: 'FNA_05_a',
            needModule: true,
            needFill: false,
            answers: [
              {
                moduleType: 'RADIO_WITH_INPUT',
                key: 'FNA_05_5a_A',
                description: 'answer21',
                tick: false,
                inputBox: [
                  {
                    key: 'FNA_05_5a_A_addition',
                    placeholder: 'HKD',
                    prefix: '',
                    suffix: '',
                    field: 'money',
                    boxType: 'MONEY',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5a_B',
                description: 'answer22',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5a_C',
                description: 'answer23',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5a_D',
                description: 'answer24',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5a_E',
                description: 'answer25',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5a_F',
                description: 'answer26',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
          {
            questionNo: 'FNA_05_b',
            subKey: 'FNA_05_5b',
            questionTitle: 'question7',
            subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
            needModule: true,
            needFill: true,
            answers: [
              {
                moduleType: 'INPUT_BOX',
                key: 'FNA_05_5b',
                description: 'answer47',
                tick: false,
                inputBox: [
                  {
                    prefix: '',
                    suffix: 'HKD',
                    field: 'total',
                    boxType: 'MONEY',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
          {
            questionNo: 'FNA_05_5c',
            subKey: 'FNA_05_5c',
            questionTitle: 'question10',
            subStyle:
              'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
            needModule: true,
            needFill: true,
            answers: [
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5c_A',
                description: 'answer28',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5c_B',
                description: 'answer29',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5c_C',
                description: 'answer30',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5c_D',
                description: 'answer31',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5c_E',
                description: 'answer32',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5c_F',
                description: 'answer33',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO_WITH_INPUT',
                key: 'FNA_05_5c_G',
                description: 'answer34',
                tick: false,
                inputBox: [
                  {
                    key: 'FNA_05_5c_G_addition',
                    prefix: '',
                    suffix: 'HKD',
                    field: 'money',
                    boxType: 'MONEY',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
          {
            questionNo: 'FNA_05_5d',
            subKey: 'FNA_05_5d',
            questionTitle: 'question11',
            subStyle:
              'border-top: none;margin-top: 0;border-radius: 0 0 8px 8px;',
            needModule: true,
            needFill: true,
            answers: [
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5d_A',
                description: 'answer35',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO_WITH_INPUT',
                key: 'FNA_05_5d_B',
                description: 'answer36',
                tick: false,
                inputBox: [
                  {
                    key: 'FNA_05_5d_B_addition',
                    prefix: '',
                    suffix: '',
                    field: 'other',
                    boxType: 'TEXT',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
          {
            questionNo: 'FNA_05_5e',
            subKey: 'FNA_05_5e',
            questionTitle: 'question10',
            subStyle:
              'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
            needModule: true,
            needFill: true,
            answers: [
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5e_A',
                description: 'answer28',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5e_B',
                description: 'answer29',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5e_C',
                description: 'answer30',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5e_D',
                description: 'answer31',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5e_E',
                description: 'answer32',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_5e_F',
                description: 'answer33',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
        ]
      }
    ]
  },
];


export const QuestionsArrPiBA = [
  {
    questionNo: 'FNA_01',
    title: 'question1',
    subStyle: 'border-bottom: none;border-radius: 8px 8px 0 0;padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_01_A',
        description: 'answer1',
        tick: false,
        inputBox: [
          {
            key: 'FNA_01_A_addition',
            placeholder: 'HKD',
            prefix: '',
            suffix: '',
            field: 'money',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_01_B',
        description: 'answer2',
        tick: false,
        inputBox: [
          {
            key: 'FNA_01_B_addition',
            placeholder: 'HKD',
            prefix: '',
            suffix: '',
            field: 'money',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: [
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_i',
            subKey: 'FNA_01_B_addition_i',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_ii',
            subKey: 'FNA_01_B_addition_ii',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_iii',
            subKey: 'FNA_01_B_addition_iii',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX_WITH_INPUT',
            key: 'FNA_01_B_addition_iiii',
            subKey: 'FNA_01_B_addition_iiii',
            description: 'answer3',
            tick: false,
            inputBox: [
              {
                key: 'FNA_01_B_addition_iiii_addition',
                prefix: '',
                suffix: '',
                field: 'other',
                boxType: 'TEXT',
                moreChoice: null,
                error: null,
                value: null
              }
            ],
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_indemnity',
            subKey: 'FNA_01_B_addition_indemnity',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_non_indemnity',
            subKey: 'FNA_01_B_addition_non_indemnity',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
          {
            moduleType: 'CHECKBOX',
            key: 'FNA_01_B_addition_combo_product',
            subKey: 'FNA_01_B_addition_combo_product',
            description: 'answer3',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          },
        ]
      },
      {
        moduleType: 'CHECKBOX',
        key: 'FNA_01_C',
        description: 'answer3',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX',
        key: 'FNA_01_D',
        description: 'answer4',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX',
        key: 'FNA_01_E',
        description: 'answer5',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_01_F',
        description: 'answer6',
        tick: false,
        inputBox: [
          {
            key: 'FNA_01_F_addition',
            prefix: '',
            suffix: '',
            field: 'other',
            boxType: 'TEXT',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: 'FNA_02',
    title: 'question2',
    subStyle: 'padding-top: 0px;border-top: none;margin-top: 0;border-radius: 0 0 8px 8px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO',
        key: 'FNA_02_A',
        description: 'answer7',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_02_B',
        description: 'answer8',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_02_C',
        description: 'answer9',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: 'FNA_03',
    title: 'question3',
    subStyle: 'padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO',
        key: 'FNA_03_A',
        description: 'answer10',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_B',
        description: 'answer11',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_C',
        description: 'answer12',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_D',
        description: 'answer13',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_E',
        description: 'answer14',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_F',
        description: 'answer15',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'RADIO',
        key: 'FNA_03_G',
        description: 'answer16',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: 'FNA_04',
    title: 'question4',
    subStyle: 'padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_04_A',
        description: 'answer17',
        tick: false,
        inputBox: [
          {
            key: 'FNA_04_A_addition_money',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_04_B',
        description: 'answer20',
        tick: false,
        inputBox: [
          {
            key: 'FNA_04_B_addition_money',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          }
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_04_C',
        description: 'answer20',
        tick: false,
        inputBox: [
          {
            key: 'FNA_04_C_addition_ages',
            prefix: 'answer18',
            suffix: 'answer19',
            field: 'years',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_C_addition_money',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          },
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_04_D',
        description: 'answer20',
        tick: false,
        inputBox: [
          {
            key: 'FNA_04_D_addition_ages',
            prefix: 'answer18',
            suffix: 'answer19',
            field: 'years',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_D_addition_money',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          },
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      },
      {
        moduleType: 'CHECKBOX_WITH_INPUT',
        key: 'FNA_04_E',
        description: 'answer20',
        tick: false,
        inputBox: [
          {
            key: 'FNA_04_E_addition',
            prefix: 'answer18',
            suffix: 'answer19',
            field: 'years',
            boxType: 'NUMBER',
            moreChoice: null,
            error: null,
            value: null
          },
          {
            key: 'FNA_04_E_addition_money',
            prefix: '',
            suffix: 'HKD',
            field: 'savings',
            boxType: 'MONEY',
            moreChoice: null,
            error: null,
            value: null
          },
        ],
        headline: null,
        defaultTick: false,
        subQuestion: null,
        subQuestions: null
      }
    ]
  },
  {
    questionNo: 'FNA_05',
    title: 'question5',
    subStyle: 'border-bottom: none;border-radius: 8px 8px 0 0;padding-top: 20px;',
    questionTitle: 'question6',
    needModule: true,
    needFill: true,
    answers: [
      {
        moduleType: 'RADIO_WITH_INPUT',
        key: 'FNA_05_sub',
        description: 'answer37',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestion: 'FNA_05_sub',
        subQuestions: [
          {
            questionNo: 'FNA_05_a',
            title: '',
            subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
            subKey: 'FNA_05_a',
            needModule: true,
            needFill: false,
            answers: [
              {
                moduleType: 'RADIO_WITH_INPUT',
                key: 'FNA_05_a_A',
                description: 'answer21',
                tick: false,
                inputBox: [
                  {
                    key: 'FNA_05_a_A_addition',
                    placeholder: 'HKD',
                    prefix: '',
                    suffix: '',
                    field: 'money',
                    boxType: 'MONEY',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_a_B',
                description: 'answer22',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_a_C',
                description: 'answer23',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_a_D',
                description: 'answer24',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_a_E',
                description: 'answer25',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_a_F',
                description: 'answer26',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
          {
            questionNo: 'FNA_05_b',
            subKey: 'FNA_05_b',
            questionTitle: 'question7',
            subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
            needModule: true,
            needFill: true,
            answers: [
              {
                moduleType: 'INPUT_BOX',
                key: 'FNA_05_b',
                description: 'answer47',
                tick: false,
                inputBox: [
                  {
                    prefix: '',
                    suffix: 'HKD',
                    field: 'total',
                    boxType: 'MONEY',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
          {
            questionNo: 'FNA_05_c',
            subKey: 'FNA_05_c',
            questionTitle: 'question8',
            subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
            needModule: true,
            needFill: true,
            answers: [
              {
                moduleType: 'INPUT_BOX',
                key: 'FNA_05_c',
                description: 'answer27',
                tick: false,
                inputBox: [
                  {
                    key: 'FNA_05_c_percent',
                    prefix: '',
                    suffix: '',
                    field: 'reason',
                    boxType: 'TEXT',
                    moreChoice: null,
                    error: null,
                    value: null
                  },
                  {
                    key: 'FNA_05_c_addition',
                    prefix: '',
                    suffix: '',
                    field: 'reason',
                    boxType: 'TEXT',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
            ]
          },
          {
            questionNo: 'FNA_05_d',
            subKey: 'FNA_05_d',
            questionTitle: 'question9',
            subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
            needModule: true,
            needFill: true,
            answers: [
              {
                moduleType: 'INPUT_BOX',
                key: 'FNA_05_d',
                description: 'answer27',
                tick: false,
                inputBox: [
                  {
                    key: 'FNA_05_d_percent',
                    prefix: '',
                    suffix: '',
                    field: 'reason',
                    boxType: 'TEXT',
                    moreChoice: null,
                    error: null,
                    value: null
                  },
                  {
                    key: 'FNA_05_d_addition',
                    prefix: '',
                    suffix: '',
                    field: 'reason',
                    boxType: 'TEXT',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
          {
            questionNo: 'FNA_05_e',
            subKey: 'FNA_05_e',
            questionTitle: 'question10',
            subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
            needModule: true,
            needFill: true,
            answers: [
              {
                moduleType: 'CHECKBOX',
                key: 'FNA_05_e_basic',
                description: 'answer28',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO_WITH_INPUT',
                key: 'FNA_05_e_basic_A',
                description: 'answer29',
                tick: false,
                inputBox: [
                  {
                    key: 'FNA_05_e_basic_A_year',
                    prefix: '',
                    suffix: 'HKD',
                    field: 'money',
                    boxType: 'MONEY',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_e_basic_B',
                description: 'answer30',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO_WITH_INPUT',
                key: 'FNA_05_e_basic_C',
                description: 'answer31',
                tick: false,
                inputBox: [
                  {
                    key: 'FNA_05_e_basic_C_money',
                    prefix: '',
                    suffix: 'HKD',
                    field: 'money',
                    boxType: 'MONEY',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'CHECKBOX',
                key: 'FNA_05_e_rider',
                description: 'answer32',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO_WITH_INPUT',
                key: 'FNA_05_e_rider_A',
                description: 'answer33',
                tick: false,
                inputBox: [
                  {
                    key: 'FNA_05_e_rider_A_year',
                    prefix: '',
                    suffix: 'HKD',
                    field: 'money',
                    boxType: 'MONEY',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO',
                key: 'FNA_05_e_rider_B',
                description: 'answer34',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
          {
            questionNo: 'FNA_05_f',
            subKey: 'FNA_05_f',
            questionTitle: 'question11',
            subStyle: 'border-top: none;margin-top: 0;border-radius: 0 0 8px 8px;',
            needModule: true,
            needFill: true,
            answers: [
              {
                moduleType: 'RADIO',
                key: 'FNA_05_f_A',
                description: 'answer35',
                tick: false,
                inputBox: null,
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              },
              {
                moduleType: 'RADIO_WITH_INPUT',
                key: 'FNA_05_f_B',
                description: 'answer36',
                tick: false,
                inputBox: [
                  {
                    key: 'FNA_05_f_B_addition',
                    prefix: '',
                    suffix: '',
                    field: 'other',
                    boxType: 'TEXT',
                    moreChoice: null,
                    error: null,
                    value: null
                  }
                ],
                headline: null,
                defaultTick: false,
                subQuestion: null,
                subQuestions: null
              }
            ]
          },
        ]
      },
    ],
  },
  {
    questionNo: 'FNA_06',
    title: 'question12',
    questionTitle: 'question13',
    subStyle: 'border-bottom: none;border-radius: 8px 8px 0 0;padding-top: 20px;',
    needModule: true,
    needFill: true,
    answers: [
      {
        subQuestion: 'FNA_06_a',
        key: 'FNA_06_a',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestions: [
          {
            moduleType: 'RADIO_WITH_INPUT',
            key: 'FNA_06_a_A',
            subKey: 'FNA_06_a_A',
            description: 'answer37',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: [
              {
                questionNo: 'FNA_06_a_A_Sub',
                title: '',
                subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
                subKey: 'FNA_06_a_A_Sub',
                needModule: true,
                needFill: false,
                answers: [
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Policy_Number',
                        title: 'answer39',
                        prefix: '',
                        suffix: '',
                        field: 'proposedPolicyNumber',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Insurer_Name',
                        title: 'answer40',
                        prefix: '',
                        suffix: '',
                        field: 'insurerName',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Lender_Name',
                        title: 'answer41',
                        prefix: '',
                        suffix: '',
                        field: 'lenderName',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Loan_Amount',
                        title: 'answer42',
                        prefix: '',
                        suffix: '',
                        field: 'total',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Interest_Rate',
                        title: 'answer43',
                        prefix: '',
                        suffix: '',
                        field: 'rate',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Repayment_Term',
                        title: 'answer44',
                        prefix: '',
                        suffix: '',
                        field: 'limit',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Repayment_Amount',
                        title: 'answer45',
                        prefix: '',
                        suffix: '',
                        field: 'repaymentAmount',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_a_Repayment_Amount_02',
                        title: 'answer46',
                        prefix: '',
                        suffix: '',
                        field: 'repaymentAmount3',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  }
                ]
              },
            ]
          },
          {
            moduleType: 'RADIO',
            key: 'FNA_06_a_B',
            subKey: 'FNA_06_a_B',
            description: 'answer38',
            tick: undefined,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          }
        ],
        moduleType: null,
        description: '',
        title: null,
      },
      {
        subQuestion: 'FNA_06_b',
        key: 'FNA_06_b',
        tick: false,
        inputBox: null,
        headline: null,
        defaultTick: false,
        subQuestions: [
          {
            moduleType: 'RADIO',
            key: 'FNA_06_b_A',
            subKey: 'FNA_06_b_A',
            description: 'answer37',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: [
              {
                questionNo: 'FNA_06_b_A_Sub',
                title: 'Policy1',
                needModule: true,
                subKey: 'FNA_06_b_A_Sub_a',
                subStyle: 'border-top: none;margin-top: 0;border-radius: 0;border-bottom: none;',
                needFill: false,
                answers: [
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Policy_Number_01',
                        title: 'answer39',
                        prefix: '',
                        suffix: '',
                        field: 'proposedPolicyNumber',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Insurer_Name_01',
                        title: 'answer40',
                        prefix: '',
                        suffix: '',
                        field: 'insurerName',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Lender_Name_01',
                        title: 'answer41',
                        prefix: '',
                        suffix: '',
                        field: 'lenderName',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Loan_Amount_01',
                        title: 'answer42',
                        prefix: '',
                        suffix: '',
                        field: 'total',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Interest_Rate_01',
                        title: 'answer43',
                        prefix: '',
                        suffix: '',
                        field: 'rate',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Repayment_Term_01',
                        title: 'answer44',
                        prefix: '',
                        suffix: '',
                        field: 'limit',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Repayment_Amount_01',
                        title: 'answer45',
                        prefix: '',
                        suffix: '',
                        field: 'repaymentAmount',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    // subclass: 'width: calc((100% - 20px) / 4);margin-right: 0px;',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Repayment_Amount_01_02',
                        title: 'answer46',
                        prefix: '',
                        suffix: '',
                        field: 'repaymentAmount3',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  }
                ]
              },
              {
                questionNo: 'FNA_06_b_A_Sub_b',
                title: 'Policy2',
                needModule: true,
                subKey: 'FNA_06_b_A_Sub_b',
                subStyle: 'border-top: none;margin-top: 0;border-radius: 0 0 8px 8px;',
                needFill: false,
                answers: [
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Policy_Number_02',
                        title: 'answer39',
                        prefix: '',
                        suffix: '',
                        field: 'proposedPolicyNumber',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Insurer_Name_02',
                        title: 'answer40',
                        prefix: '',
                        suffix: '',
                        field: 'insurerName',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Lender_Name_02',
                        title: 'answer41',
                        prefix: '',
                        suffix: '',
                        field: 'lenderName',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Loan_Amount_02',
                        title: 'answer42',
                        prefix: '',
                        suffix: '',
                        field: 'total',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Interest_Rate_02',
                        title: 'answer43',
                        prefix: '',
                        suffix: '',
                        field: 'rate',
                        boxType: 'TEXT',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Repayment_Term_02',
                        title: 'answer44',
                        prefix: '',
                        suffix: '',
                        field: 'limit',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Repayment_Amount_02',
                        title: 'answer45',
                        inclass: '',
                        prefix: '',
                        suffix: '',
                        field: 'repaymentAmount',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  },
                  {
                    moduleType: 'INPUT_BOX',
                    description: '',
                    tick: false,
                    inputBox: [
                      {
                        key: 'FNA_06_b_Repayment_Amount_02_02',
                        title: 'answer46',
                        prefix: '',
                        suffix: '',
                        field: 'repaymentAmount3',
                        boxType: 'NUMBER',
                        moreChoice: null,
                        error: null,
                        value: null
                      }
                    ],
                    headline: null,
                    defaultTick: false,
                    subQuestion: null,
                    subQuestions: null
                  }
                ]
              }
            ]
          },
          {
            moduleType: 'RADIO',
            key: 'FNA_06_b_B',
            subKey: 'FNA_06_b_B',
            description: 'answer38',
            tick: false,
            inputBox: null,
            headline: null,
            defaultTick: false,
            subQuestion: null,
            subQuestions: null
          }
        ],
        moduleType: null,
        description: '',
        title: null,
      },
    ]
  }
];

