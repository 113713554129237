<nz-tabset [nzSize]="'large'" [nzSelectedIndex]="1">
  <nz-tab [nzTitle]=personalFiles>
    <app-documents [mode]="dataService.dataModeOwn"></app-documents>
  </nz-tab>
  <ng-template #personalFiles>
    {{'PersonalFiles' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=companyDocuments>
    <app-documents [mode]="dataService.dataModeSubordinate"></app-documents>
  </nz-tab>
  <ng-template #companyDocuments>
    {{'CompanyDocuments' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=recentlyUsed>
    <app-document-used-list></app-document-used-list>
  </nz-tab>
  <ng-template #recentlyUsed>
    {{'RecentlyUsed' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=myCollection>
    <app-documents [mode]="dataService.dataModeSalesGroup"></app-documents>
  </nz-tab>
  <ng-template #myCollection>
    {{'MyCollection' | translate}}
  </ng-template>
</nz-tabset>
