import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommissionForecastComponent } from './commission-forecast.component';
import { CommissionForecastMainComponent } from './commission-forecast-main.component';
import { CommissionForecastsComponent } from './commission-forecasts/commission-forecasts.component';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzSliderModule} from 'ng-zorro-antd/slider';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import { ContractSelectComponent } from './contract-select/contract-select.component';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import { ContractDetailComponent } from './contract-detail/contract-detail.component';
import {NzTableModule} from 'ng-zorro-antd/table';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';



@NgModule({
  declarations: [
    CommissionForecastComponent,
    CommissionForecastMainComponent,
    CommissionForecastsComponent,
    ContractSelectComponent,
    ContractDetailComponent
  ],
    imports: [
        CommonModule,
        NzLayoutModule,
        SharedModule,
        RouterModule,
        TranslateModule,
        NzSpinModule,
        NzToolTipModule,
        NzGridModule,
        NzSliderModule,
        NzButtonModule,
        NzIconModule,
        NzAlertModule,
        NzEmptyModule,
        NzTableModule,
        NzCarouselModule
    ]
})
export class CommissionForecastModule { }
