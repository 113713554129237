<div class="result-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="drawer-title" style="margin-bottom: 30px">
      {{'ProductComparison' | translate}}
    </div>

    <div *ngIf="sectionGroup">
      <nz-table [nzBordered]="true"
                [nzOuterBordered]="false"
                [nzData]="sectionGroup"
                [nzTotal]="sectionGroup.length"
                [nzShowPagination]="false"
                [nzScroll]="tableScroll">
        <thead *ngFor="let group of sectionGroup; let i = index">
        <tr>
          <th nzLeft nzWidth="160px">{{'Insurer' | translate}}</th>
          <th *ngFor="let companyName of group.companyNames">{{companyName}}</th>
        </tr>
        <tr>
          <th nzLeft nzWidth="160px">{{'ProductName' | translate}}</th>
          <th *ngFor="let productName of group.products">{{productName}}</th>
        </tr>
        </thead>
        <tbody *ngFor="let group of sectionGroup; let i = index">
        <tr>
          <td class="group-title" [colSpan]="group.products.length + 1">
            <div>{{ group.name }}</div>
          </td>
          <!--          <td nzLeft class="header-title">{{'ProductName' | translate}}</td>-->
          <!--          <td *ngFor="let productName of group.products" class="header-title">{{productName}}</td>-->
        </tr>
        <!--        <tr *ngIf="i > 0">-->
        <!--          <td nzLeft class="header-title">{{'ProductName' | translate}}</td>-->
        <!--          <td *ngFor="let productName of group.products" class="header-title">{{productName}}</td>-->
        <!--        </tr>-->
        <tr *ngFor="let attribute of group.attributes; let j = index;">
          <td nzLeft class="attr">{{ attribute.name }}</td>
          <td *ngFor="let value of attribute.values; let k = index;">
            <div class="value" style="white-space: pre-line;">{{ value.value }}</div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>
