import { ClassConstructor, ClassTransformOptions } from 'class-transformer/types/interfaces';
import { plainToInstance } from 'class-transformer';
import { PagedResp } from '../../api/types';

export function plainToArray<T, V>(cls: ClassConstructor<T>, sources: V[], options?: ClassTransformOptions): T[] {
  const array: T[] = [];
  if (sources) {
    for (const source of sources) {
      array.push(plainToInstance(cls, source, options));
    }
  }
  return array;
}

export function plaintToPaged<T>(cls: ClassConstructor<T>, sources: PagedResp<T>, options?: ClassTransformOptions): PagedResp<T> {
  const pagedResp = plainToInstance(PagedResp, sources) as PagedResp<T>;
  for (let i = 0; i < pagedResp.list.length; i++) {
    pagedResp.list[i] = plainToInstance(cls, pagedResp.list[i]);
  }
  return pagedResp;
}
