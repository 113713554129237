import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NewFnaDetail, SignatureInformation } from '../../new-fna/new-fna-types';
import { SignaturePadOptions } from '../../fna/fna-signature-pad/fna-signature-pad.component';
import { SignaturePadComponent } from '../../shared/component/signature-pad/signature-pad.component';
import { StorageService } from '../../shared/service/storage.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NewFnaService } from '../../new-fna/new-fna.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { plainToInstance } from 'class-transformer';
import { Material } from '../../api/types';
import { FnaSignatureUpdateReq, FnaSignedStatus } from '../../fna/fna-types';
import { DatePipe, formatDate } from '@angular/common';

@Component({
  selector: 'app-piba-template-signature',
  templateUrl: './piba-template-signature.component.html',
  styleUrls: ['./piba-template-signature.component.less'],
  providers: [DatePipe]
})
export class PibaTemplateSignatureComponent implements OnInit {
  @Input()
  signatureInformation: SignatureInformation[] = [];
  @Input()
  fna: NewFnaDetail = new NewFnaDetail();

  isVisible = false;
  reSales: string;

  itemSignatureInfo: SignatureInformation = new SignatureInformation();

  signatureUrl: string;
  signaturePadOptions: SignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 960,
    canvasHeight: 550,
    backgroundColor: 'rgba(0,0,0,0)'
  };

  @Output()
  resignedInfo: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  signedInfo: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(SignaturePadComponent)
  signaturePad: SignaturePadComponent;
  constructor(private storageService: StorageService,
              private toastr: ToastrService,
              private translate: TranslateService,
              private newFnaService: NewFnaService,
              private modalService: NzModalService) { }

  ngOnInit(): void {
    this.setSignatureValue();
  }

  setSignatureValue(): void {
    const copy: SignatureInformation[] = [];
    if (!this.signatureInformation || this.signatureInformation?.length < 3) {
      const obj1 = plainToInstance(SignatureInformation, new SignatureInformation());
      obj1.signatureType = 'OWNER'; // 投保人
      obj1.signatureStatus = 'UNSIGNED';
      const obj2 = plainToInstance(SignatureInformation, new SignatureInformation());
      obj2.signatureType = 'SALES'; // 销售
      obj2.signatureStatus = 'UNSIGNED';
      const obj3 = plainToInstance(SignatureInformation, new SignatureInformation());
      obj3.signatureType = 'EYEWITNESS'; // 见证人
      obj3.signatureStatus = 'UNSIGNED';
      this.signatureInformation.push(obj1, obj2, obj3);
    }
    if (this.fna.signatureInformation && this.fna.signatureInformation.length) {
      this.signatureInformation.map(item => {
        const ite = this.fna.signatureInformation.find(info => info.signatureType === item.signatureType );
        console.log(ite, 'ite');
        if (ite) {
          copy.push(ite);
        } else {
          copy.push(item);
        }
      });
      this.signatureInformation = JSON.parse(JSON.stringify(copy));
    }
  }

  onConsultantSignature(signer: string): void {
    console.log(signer);
    console.log(this.signatureInformation);
    this.signatureInformation.map(item => {
      if (item.signatureType === signer) {
        this.itemSignatureInfo = JSON.parse(JSON.stringify(item));
        this.imgPath();
      }
    });
    this.isVisible = true;
  }

  handleOk(): void {
    if (this.canConfirm) {
      return;
    }
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  imgPath(): void {
    let material: Material = new Material();
    material = this.itemSignatureInfo.signatureUrl;

    if (material && material.filePath) {
      this.storageService.accessToken(material.filePath)
        .subscribe(
          data => {
            this.signatureUrl = data.accessToken;
          },
          error => {
          });
    } else {
      this.signatureUrl = '';
    }
  }

  onRewriteConfirm(): void {
    if (!this.canRewrite) {
      return;
    }
    this.itemSignatureInfo.signatureStatus = FnaSignedStatus.UNSIGNED;
    this.signaturePad?.clear();
  }

  get canRewrite(): boolean {
    if (this.fna.signatureStatus === FnaSignedStatus.SIGNED || !!this.fna.underwritingNo) {
      return false;
    }
    return true;
  }

  get canConfirm(): boolean {
    return !(this.itemSignatureInfo.signatureStatus === 'SIGNED' || !!this.fna.underwritingNo);
  }

  onConfirm(): void {
    if (!this.canConfirm) {
      return;
    }
    const updateReq = new FnaSignatureUpdateReq();
    updateReq.id = this.fna.id;
    updateReq.imageBase64 = this.signaturePad.toDataURL();
    updateReq.signatureType = this.itemSignatureInfo.signatureType;

    console.log(updateReq, 'updateReq');
    // console.log(this.signaturePad.toData());
    // console.log(this.signaturePad);
    if (!this.signaturePad.toData().length) {
      this.translate.get('UnsignedTip').subscribe(
        (res) => {
          this.toastr.warning(res);
        }
      );
      return;
    }
    this.signedInfo.emit(updateReq);
    this.isVisible = false;
  }

  onSignedInfo(type: string): SignatureInformation {
    let obj = new SignatureInformation();
    this.signatureInformation.map(item => {
      if (item.signatureType === type) {
        obj = item;
      }
    });
    return obj;
  }

  onSignedTime(num: string): string {
    let obj = formatDate(num, 'yyyy-MM-dd', 'zh-Hans-CN');
    const dateStr = new Date(obj);
    obj = formatDate(dateStr, 'dd/MM/yyyy', 'zh-Hans-CN');
    return obj;
  }

  onResign(tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>,  type: string = ''): void {
    this.reSales = type;

    if (this.fna.underwritingNo) {
      this.translate.get(['DisabledUnderwritingTips', 'Confirm']).subscribe(
        (res: any) => {
          this.modalService.info({
            nzCentered: true,
            nzTitle: res.DisabledUnderwritingTips,
            nzContent: null,
            nzOkText: res.Confirm,
            nzOnOk: () => {}
          });
        });
    } else {
      this.modalService.create({
        nzCentered: true,
        nzTitle: tplTitle,
        nzContent: tplContent,
        nzFooter: tplFooter,
        nzMaskClosable: false,
        nzClosable: false,
        nzComponentParams: this.fna,
        nzOnOk: () => {
        }
      });
    }
  }

  onReSignConfirm(ref: NzModalRef): void {
    if (this.reSales === 'sales') {
      this.newFnaService.reFnaSalesSign(this.fna.id).subscribe(
        fna => {
          this.resignedInfo.emit('');
          this.itemSignatureInfo = new SignatureInformation();
          ref.close();
          this.signatureInformation?.forEach(item => {
            if (item.signatureType === 'SALES') {
              item.signatureStatus = 'UNSIGNED';
              item.signatureUrl = undefined;
              item.signatureTime = undefined;
            }
          });
        },
        error => {
          ref.close();
        });
    } else {
      this.signatureInformation = [];
      this.newFnaService.reFnaSign(this.fna.id)
        .subscribe(
          fna => {
            this.resignedInfo.emit('');
            this.itemSignatureInfo = new SignatureInformation();
            ref.close();
            // this.loadDetail();
            // setTimeout(() => { this.setSignatureValue(); }, 200);
            if (!this.signatureInformation || this.signatureInformation?.length < 3) {
              const obj1 = plainToInstance(SignatureInformation, new SignatureInformation());
              obj1.signatureType = 'OWNER'; // 投保人
              obj1.signatureStatus = 'UNSIGNED';
              const obj2 = plainToInstance(SignatureInformation, new SignatureInformation());
              obj2.signatureType = 'SALES'; // 销售
              obj2.signatureStatus = 'UNSIGNED';
              const obj3 = plainToInstance(SignatureInformation, new SignatureInformation());
              obj3.signatureType = 'EYEWITNESS'; // 见证人
              obj3.signatureStatus = 'UNSIGNED';
              this.signatureInformation.push(obj1, obj2, obj3);
            }
          },
          error => {
            ref.close();
          });
    }
  }

}
