<div class="drawer-container" #withdrawalEditor>

  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">

    <div class="common-title">
      {{'ProposalApply' | translate}}
    </div>

    <!-- 產品計劃 -->
    <div class="proposal-apply-section">
      <div class="proposal-apply-section-title">
        <span style="margin-left: 8px">{{'ProductPlan' | translate}}</span>
      </div>
      <!-- 产品 -->
      <app-property-pick [title]="'InsuranceProduct' | translate"
                         [required]="true"
                         appThrottleClick (throttleClick)="onProductSelection()"
                         [(value)]="product.productName"></app-property-pick>
      <!-- 计划书语言 -->
      <app-property-select [title]="'ProposalLanguage' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('ProposalLanguage' | translate)"
                           [required]="true"
                           [options]="languageOptions"
                           [(value)]="applyReq.language"></app-property-select>
      <!-- 货币 -->
      <app-property-select [title]="'Currency' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Currency' | translate)"
                           [required]="true"
                           [options]="currencyOptions"
                           [(value)]="applyReq.currency" (valueChange)="onCurrencyChange($event)"></app-property-select>
      <!-- 供款年期 -->
      <app-property-select [title]="'PaymentTerm' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('PaymentTerm' | translate)"
                           [required]="true"
                           [options]="paymentTermOptions"
                           [(value)]="applyReq.paymentTerm"
                           (valueChange)="onPaymentTermChange($event)"></app-property-select>

      <!-- 付款频率 -->
      <app-property-select *ngIf="product && product?.proposalFrequencySupported"
                           [title]="'PaymentFrequency' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('PaymentFrequency' | translate)"
                           [required]="false"
                           [options]="paymentFrequencyOptions"
                           [(value)]="applyReq.frequency"
                           (valueChange)="onPaymentTermChange($event)"></app-property-select>
      <!-- 是否预缴 -->
      <app-property-select *ngIf="product && product?.proposalPrepaymentSupported"
                           [title]="'PrepaidOrNot' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('PrepaidOrNot' | translate)"
                           [required]="false"
                           [options]="prepayOptions"
                           [(value)]="applyReq.prepay"></app-property-select>

      <app-property-input-number *ngIf="applyReq.prepay"
                                 [title]="'PrepayYear' | translate"
                                 [placeholder]="'PrepayYear' | translate"
                                 [(value)]="applyReq.prepayYear"></app-property-input-number>

      <div *ngIf="product.dynamicFields && product.dynamicFields.length && applyReq.dynamicFields && applyReq.dynamicFields.length">
        <div *ngFor="let dynamicField of applyReq.dynamicFields; let i = index">
          <div [ngSwitch]="dynamicField.fieldType">
            <app-property-input *ngSwitchCase="'string'"
                                [title]="dynamicField.fieldName"
                                [placeholder]="dynamicField.fieldName"
                                [required]="dynamicField.required"
                                [disabled]="dynamicField.isDisabled"
                                [(value)]="dynamicField.value"
                                nz-tooltip [nzTooltipTitle]="dynamicField.remark"
                                nzTooltipPlacement="bottomRight"></app-property-input>
            <app-property-select *ngSwitchCase="'array'"
                                 [title]="dynamicField.fieldName"
                                 [placeholder]="dynamicField.fieldName"
                                 [options]="dynamicField.options"
                                 [disabled]="dynamicField.isDisabled"
                                 [required]="dynamicField.required"
                                 [(value)]="dynamicField.value"
                                 (valueChange)="onDynamicFieldChange($event, dynamicField)"
                                 nz-tooltip [nzTooltipTitle]="dynamicField.remark"
                                 nzTooltipPlacement="bottomRight"></app-property-select>
            <app-property-input-number *ngSwitchCase="'number'"
                                       [title]="dynamicField.fieldName"
                                       [required]="dynamicField.required"
                                       [disabled]="dynamicField.isDisabled"
                                       [placeholder]="dynamicField.fieldName"
                                       [(value)]="dynamicField.value"
                                       nz-tooltip [nzTooltipTitle]="dynamicField.remark"
                                       nzTooltipPlacement="bottomRight"></app-property-input-number>
          </div>
        </div>

      </div>
    </div>

    <!-- 金額 -->
    <div *ngIf="product?.categoryCode !== 'MEDICAL'" class="proposal-apply-section">
      <div class="proposal-apply-section-title">
        <span style="margin-left: 8px">{{'Amount' | translate}}</span>
      </div>
      <div *ngIf="product" [ngSwitch]="product.proposalAmountType" style="font-size: 11px; margin: 15px 0 0 0">
        <div *ngSwitchCase="'BOTH'">
          <div>
            <span style="color: #222222; font-weight: bold">{{product.productName}}</span>，
            {{'SupportAmountType' | translate}}：
            <span style="color: #1d64c4;">{{'Premium' | translate}}</span>,
            <span style="color: #1d64c4;">{{'SumAssured' | translate}}</span>
            <span style="color: #0d7246;" *ngIf="product.features?.proposalAmountTip"> | {{product.features?.proposalAmountTip | i18n}}</span>
          </div>
        </div>
        <div *ngSwitchCase="'PREMIUM'">
          <div>
            <span style="color: #222222; font-weight: bold">{{product.productName}}</span>，
            {{'SupportAmountType' | translate}}：
            <span style="color: #1d64c4;">{{'Premium' | translate}}</span>
            <span style="color: #0d7246;" *ngIf="product.features?.proposalAmountTip"> | {{product.features?.proposalAmountTip | i18n}}</span>
          </div>
        </div>
        <div *ngSwitchCase="'SUM_ASSURED'">
          <div>
            <span style="color: #222222; font-weight: bold">{{product.productName}}</span>，
            {{'SupportAmountType' | translate}}：
            <span style="color: #1d64c4;">{{'SumAssured' | translate}}</span>
            <span style="color: #0d7246;" *ngIf="product.features?.proposalAmountTip"> | {{product.features?.proposalAmountTip | i18n}}</span>
          </div>
        </div>
      </div>
      <div style="font-size: 11px">{{'PleaseFinishAllRequiredFieldAbove' | translate}}</div>
      <!-- 金额类型 -->
      <app-property-select [title]="'AmountType' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('AmountType' | translate)"
                           [options]="amountTypeOptions"
                           [disabled]="product.proposalAmountType != 'BOTH'"
                           [required]="true"
                           [value]="applyReq.proposalAmountType"
                           (valueChange)="onProposalAmountTypeChange($event)"></app-property-select>

      <div [ngSwitch]="applyReq.proposalAmountType">
        <app-property-input-number *ngSwitchCase="'PREMIUM'"
                                   [title]="'AnnualPremium' | translate"
                                   [placeholder]="'AnnualPremium' | translate"
                                   [(value)]="applyReq.premium"
                                   [min]="premiumMin" [max]="premiumMax"
                                   nz-tooltip [nzTooltipTitle]="amountRestrictionTitleTemplate"
                                   nzTooltipPlacement="topRight"></app-property-input-number>

        <app-property-input-number *ngSwitchCase="'SUM_ASSURED'"
                                   [title]="'SumAssured' | translate"
                                   [placeholder]="'SumAssured' | translate"
                                   [(value)]="applyReq.sumAssured"
                                   [min]="sumInsuredMin" [max]="sumInsuredMax"
                                   nz-tooltip [nzTooltipTitle]="amountRestrictionTitleTemplate"
                                   nzTooltipPlacement="topRight"></app-property-input-number>

        <ng-template #amountRestrictionTitleTemplate>
          <div *ngIf="amountRestrictions && amountRestrictions.length > 0; else amountRestrictionTitleElse">
            <div *ngFor="let restriction of amountRestrictions">
              {{restriction}}
            </div>
          </div>
          <ng-template #amountRestrictionTitleElse>
            <div>
              {{('PleaseEnter' | translate) + ('Amount' | translate)}}
            </div>
          </ng-template>
        </ng-template>

      </div>
    </div>

    <div *ngIf="product?.categoryCode === 'MEDICAL'" class="proposal-apply-section">
      <div class="proposal-apply-section-title">
        <span style="margin-left: 8px">{{'MedicalPlan' | translate}}</span>
      </div>

      <!-- 保障地區 -->
      <app-property-select [title]="'GuaranteedArea' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('GuaranteedArea' | translate)"
                           [required]="medicalRequiredField('regions')"
                           [options]="guaranteedAreaOptions"
                           [(value)]="applyReq.coverageRegion"></app-property-select>

      <!-- 医疗险等级 -->
      <app-property-select [title]="'MedicalLevel' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('MedicalLevel' | translate)"
                           [required]="medicalRequiredField('levels')"
                           [options]="medicalLevelOptions"
                           [(value)]="applyReq.medicalLevel"></app-property-select>

      <!-- 自付額 -->
      <app-property-select [title]="'Deductible' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Deductible' | translate)"
                           [required]="medicalRequiredField('deductibles')"
                           [options]="deductibleOptions"
                           [(value)]="applyReq.deductible"></app-property-select>

      <!-- 附加保障 -->
      <app-property-select [title]="'SupplementaryBenefits' | translate"
                           [mode]="'multiple'"
                           [placeholder]="('PleaseSelect' | translate) + ('SupplementaryBenefits' | translate)"
                           [required]="medicalRequiredField('supplementaryBenefits')"
                           [options]="supplementaryBenefitOptions"
                           [(value)]="applyReq.supplementaryBenefits"></app-property-select>

    </div>
<!--    附加险-->
    <div *ngIf="product.proposalRiders && product.proposalRiders.length">
      <div style="margin: 15px 0 0 0">{{'Rider' | translate}}</div>
      <div *ngIf="applyReq.riders && applyReq.riders.length">
        <div *ngFor="let rider of applyReq.riders; let i = index" class="riders-box">
          <div style="flex: 1" appThrottleClick (throttleClick)="onRidersDetail(rider, i)">
            <div style="font-size: 15px; color: black">
              <div>{{rider.productName || 'N/A'}}</div>
            </div>
            <div style="color: #857e7e; font-size: 12px" *ngIf="rider.categoryCode === 'MEDICAL'">
              <span>{{'Deductible' | translate}}:</span>
              <span *ngIf="rider.medicalPlan.deductible">
                {{rider.medicalPlan.deductible}}
                {{rider.medicalPlan.deductible && applyReq?.currency ? (applyReq?.currency | metadataTranslate: 'currency') : ''}}
              </span>
            </div>
            <div style="color: #857e7e; font-size: 12px" *ngIf="rider.categoryCode !== 'MEDICAL'">
              <span>{{'Premium' | translate}}: </span>
              <span *ngIf="rider.premium">
                {{rider.premium}}
                {{rider.premium && applyReq?.currency ? (applyReq?.currency | metadataTranslate: 'currency') : ''}}
              </span>
              <span style="margin-left: 5px;">{{'SumAssured' | translate}}: </span>
              <span *ngIf="rider.sumAssured">
                {{rider.sumAssured}}
                {{rider.sumAssured && applyReq?.currency ? (applyReq?.currency | metadataTranslate: 'currency') : ''}}
              </span>
            </div>
            <div style="color: #857e7e; font-size: 12px" *ngIf="rider.categoryCode !== 'MEDICAL'">
              <span>{{'PaymentTerm' | translate}}: </span>
              <span *ngIf="rider.productYear">
                {{rider.productYear}}
                {{rider.productYear ? ('Year' | translate) : ''}}
              </span>
            </div>
          </div>
          <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
             appThrottleClick (throttleClick)="onRidersDelete(i)">
            <img alt="" width="24" src="assets/images/ic_delete.svg">
          </a>
        </div>
      </div>

      <div class="proposal-withdrawal flex-column-center" appThrottleClick (throttleClick)="onAddRider()">
        <div><i nz-icon nzType="plus" style="color: #1d64c4" theme="outline"></i></div>
        <div style="color: #1d64c4; font-size: 12px">{{'AddRider' | translate}}</div>
      </div>
    </div>

    <!-- 受保人 -->
    <div class="proposal-apply-section">
      <div class="proposal-apply-section-title">
        <span style="margin-left: 8px">{{'Insured' | translate}}</span>
      </div>
      <!-- 姓氏 -->
      <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LastName' | translate"
                          [placeholder]="'LastName' | translate"
                          [(value)]="applyReq.lastName"></app-property-input>
      <!-- 名字 -->
      <app-property-input [title]="'FirstName' | translate"
                          [placeholder]="'FirstName' | translate"
                          [(value)]="applyReq.firstName"></app-property-input>
      <!-- 选择生日或年齡 -->
      <app-property-select [title]="product.companyCode === 'PRUDENTIAL' ? ('ChooseBirthdayOrNextYearAge' | translate) : ('ChooseBirthdayOrAge' | translate)"
                           [placeholder]="product.companyCode === 'PRUDENTIAL' ? ('ChooseBirthdayOrNextYearAge' | translate) : ('ChooseBirthdayOrAge' | translate)"
                           [tip]="'PleaseSelectProductFirst' | translate"
                           [tipVisible]="product.companyCode === undefined"
                           [options]="birthdayOrAgeOptions"
                           [required]="true"
                           [disabled]="product.companyCode === undefined"
                           (valueChange)="onBirthdayOrAgeOfInsuredChange($event)"
                           [value]="birthdayOrAgeOfInsured"></app-property-select>

      <!-- 生日 -->
      <app-property-datetime [title]="'Birthday' | translate"
                             [tip]="'PleaseSelectProductFirst' | translate"
                             [tipVisible]="product.companyCode === undefined"
                             [disabled]="birthdayOrAgeOfInsured !== 'birthday' || product.companyCode === undefined"
                             (valueChange)="onBirthdayOfInsuredChange($event)"
                             [value]="applyReq.birthday"></app-property-datetime>
      <!-- 年龄 -->
      <app-property-input-number [title]="product.companyCode === 'PRUDENTIAL' ? ('NextYearAge' | translate) : ('Age' | translate)"
                                 [tip]="'PleaseSelectProductFirst' | translate"
                                 [tipVisible]="product.companyCode === undefined"
                                 [placeholder]="product.companyCode === 'PRUDENTIAL' ? ('NextYearAge' | translate) : ('Age' | translate)"
                                 [disabled]="birthdayOrAgeOfInsured !== 'age' || product.companyCode === undefined"
                                 [max]="ageMax" [min]="ageMin"
                                 [required]="true"
                                 [value]="applyReq.age"
                                 (valueChange)="onAgeOfInsuredChange($event)"
                                 nz-tooltip [nzTooltipTitle]="ageRestrictionTitleTemplate"
                                 [nzTooltipVisible]="ageRestrictions && ageRestrictions.length > 0"
                                 nzTooltipPlacement="topRight"></app-property-input-number>

      <ng-template #ageRestrictionTitleTemplate>
        <div *ngIf="ageRestrictions && ageRestrictions.length > 0; else ageRestrictionTitleElse">
          <div *ngFor="let restriction of ageRestrictions">
            {{restriction}}
          </div>
        </div>
        <ng-template #ageRestrictionTitleElse>
          <div>
            {{('PleaseEnter' | translate) + ('Age' | translate)}}
          </div>
        </ng-template>
      </ng-template>

      <!-- 性别 -->
      <app-property-select [title]="'Gender' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Gender' | translate)"
                           [options]="genderOptions"
                           [required]="true"
                           [(value)]="applyReq.gender"></app-property-select>

      <!-- 是否吸烟 -->
      <app-property-select [title]="'SmokeCondition' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Smoking' | translate)"
                           [options]="smokeOptions"
                           [required]="true"
                           [(value)]="applyReq.smoke"></app-property-select>
      <!-- 吸烟量 -->
      <app-property-input *ngIf="applyReq.smoke === 'S'" [title]="'SmokingVolume' | translate"
                          [required]="true"
                          [placeholder]="('PleaseEnter' | translate) + ('SmokingVolume' | translate)"
                          nz-tooltip [nzTooltipTitle]="smokeQuantityTemplate"
                          nzTooltipPlacement="topRight"
                          [(value)]="applyReq.smokeQuantity"></app-property-input>
      <ng-template #smokeQuantityTemplate><div>{{'AD' | translate}}</div></ng-template>

      <!-- 国籍（国家地区） -->
      <app-property-pick [displayMode]="'horizontal'"
                         [title]="'Country' | translate"
                         [required]="true"
                         appThrottleClick (throttleClick)="onCountry('insured')"
                         [value]="countryString"></app-property-pick>

    </div>

    <!-- 投保人 -->
    <div class="proposal-apply-section">
      <div class="proposal-apply-section-title">
        <span style="margin-left: 8px">{{'Holder' | translate}}</span>
      </div>

      <!-- 关系 -->
      <app-property-select [title]="'RelationshipToInsured' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('RelationshipToInsured' | translate)"
                           [options]="relationshipOptions"
                           [required]="false"
                           [(value)]="applyReq.relationship"></app-property-select>

      <div *ngIf="applyReq.relationship !== 'OWN'">
        <!-- 姓氏 -->
        <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LastName' | translate"
                            [placeholder]="'LastName' | translate"
                            [(value)]="applyReq.owner.lastName"></app-property-input>
        <!-- 名字 -->
        <app-property-input [title]="'FirstName' | translate"
                            [placeholder]="'FirstName' | translate"
                            [(value)]="applyReq.owner.firstName"></app-property-input>
        <!-- 选择生日或年齡 -->
        <app-property-select [title]="product.companyCode === 'PRUDENTIAL' ? ('ChooseBirthdayOrNextYearAge' | translate) : ('ChooseBirthdayOrAge' | translate)"
                             [placeholder]="product.companyCode === 'PRUDENTIAL' ? ('ChooseBirthdayOrNextYearAge' | translate) : ('ChooseBirthdayOrAge' | translate)"
                             [tip]="'PleaseSelectProductFirst' | translate"
                             [tipVisible]="product.companyCode === undefined"
                             [options]="birthdayOrAgeOptions"
                             [required]="false"
                             [disabled]="product.companyCode === undefined"
                             [value]="birthdayOrAgeOfHolder"
                             (valueChange)="onBirthdayOrAgeOfHolderChange($event)"></app-property-select>
        <!-- 生日 -->
        <app-property-datetime [title]="'Birthday' | translate"
                               [tip]="'PleaseSelectProductFirst' | translate"
                               [tipVisible]="product.companyCode === undefined"
                               [disabled]="birthdayOrAgeOfHolder !== 'birthday' || product.companyCode === undefined"
                               (valueChange)="onBirthdayOfHolderChange($event)"
                               [value]="applyReq.owner.birthday"></app-property-datetime>
        <!-- 年龄 -->
        <app-property-input-number [title]="product.companyCode === 'PRUDENTIAL' ? ('NextYearAge' | translate) : ('Age' | translate)"
                                   [tip]="'PleaseSelectProductFirst' | translate"
                                   [tipVisible]="product.companyCode === undefined"
                                   [placeholder]="product.companyCode === 'PRUDENTIAL' ? ('NextYearAge' | translate) : ('Age' | translate)"
                                   [disabled]="birthdayOrAgeOfHolder !== 'age' || product.companyCode === undefined"
                                   [max]="138" [min]="18"
                                   [required]="false"
                                   [(value)]="applyReq.owner.age"></app-property-input-number>

        <!-- 性别 -->
        <app-property-select [title]="'Gender' | translate"
                             [placeholder]="('PleaseSelect' | translate) + ('Gender' | translate)"
                             [options]="genderOptions"
                             [required]="false"
                             [(value)]="applyReq.owner.gender"></app-property-select>

        <!-- 是否吸烟 -->
        <app-property-select [title]="'SmokeCondition' | translate"
                             [placeholder]="('PleaseSelect' | translate) + ('SmokeCondition' | translate)"
                             [options]="smokeOptions"
                             [required]="false"
                             [(value)]="applyReq.owner.smoke"></app-property-select>
        <!-- 吸烟量 -->
        <app-property-input *ngIf="applyReq.owner.smoke === 'S'" [title]="'SmokingVolume' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('SmokingVolume' | translate)"
                            [(value)]="applyReq.owner.smokeQuantity"></app-property-input>

<!--        &lt;!&ndash; 国籍（国家地区） &ndash;&gt;-->
<!--        <app-property-select [title]="'Country' | translate"-->
<!--                             [placeholder]="('PleaseSelect' | translate) + ('Country' | translate)"-->
<!--                             [options]="regionOptions"-->
<!--                             [required]="false"-->
<!--                             [(value)]="applyReq.owner.residenceRegion"></app-property-select>-->
        <!-- 国籍（国家地区） -->
        <app-property-pick [displayMode]="'horizontal'"
                           [title]="'Country' | translate"
                           appThrottleClick (throttleClick)="onCountry('owner')"
                           [value]="countryOwnerString"></app-property-pick>
      </div>

    </div>

    <!-- 对冲方案 -->
<!--    <div *ngIf="product.features && product.features.hedgeable" class="proposal-apply-section">-->
<!--      <div class="proposal-apply-section-title flex-row">-->
<!--        <span style="margin-left: 8px; flex: 1">{{'CreateHedgeProposal' | translate}}</span>-->
<!--        <div>-->
<!--          <nz-switch [(ngModel)]="applyReq.doHedge"></nz-switch>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div *ngIf="applyReq.doHedge">-->
<!--        <app-property-pick [title]="'InsuranceProduct' | translate"-->
<!--                           [required]="true"-->
<!--                           appThrottleClick (throttleClick)="onHedgePlan()"-->
<!--                           [(value)]="applyReq.hedgePlan.productName"></app-property-pick>-->
<!--      </div>-->
<!--    </div>-->

    <!-- 提取计划-->
    <div *ngIf="!applyReq.doHedge && product.withdrawalable" class="proposal-apply-section">
      <div class="proposal-apply-section-title flex-row">
        <span style="margin-left: 8px; flex: 1">{{'WithdrawalPlan' | translate}}</span>
        <div>
          <nz-switch [(ngModel)]="applyReq.doWithdrawals" (ngModelChange)="doWithdrawalsChange($event)"></nz-switch>
        </div>
      </div>

      <div *ngIf="applyReq.doWithdrawals">
        <div style="margin: 15px 0 0 0">{{'WithdrawalArrangement' | translate}}</div>

        <div *ngFor="let withdrawal of applyReq.withdrawals; let i = index" class="proposal-withdrawal">
          <div class="flex-row" appThrottleClick (throttleClick)="onWithdrawalEdit(i, withdrawal)">
            <div class="flex-column" style="flex: 1">
              <div style="color: #1d64c4; font-size: 18px">
                <div *ngIf="!withdrawal.max">{{withdrawal.amount | number: '.2'}}</div>
                <div *ngIf="withdrawal.max">MAX</div>
              </div>
              <div style="color: #555555; font-size: 13px">
                <div>{{withdrawal.str ?? ''}}</div>
              </div>
            </div>
            <div style="flex-shrink: 1; color: #AAAAAA; font-size: 28px; margin: 0 10px 0 0">
              {{(i + 1) | number: '2.0'}}
            </div>
            <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
               appThrottleClick (throttleClick)="onWithdrawalDelete(i)">
              <img alt="" width="20" src="assets/images/ic_delete.svg">
            </a>
          </div>
        </div>

        <div class="proposal-withdrawal flex-column-center" appThrottleClick (throttleClick)="onAddWithdrawal(null)">
          <div><i nz-icon nzType="plus" style="color: #1d64c4" theme="outline"></i></div>
          <div style="color: #1d64c4; font-size: 12px">{{'AddWithdrawalArrangement' | translate}}</div>
        </div>

        <app-property-select *ngIf="product.companyCode === 'AXA' || product.companyCode === 'AXAMACAU'"
                             [title]="('PleaseSelect' | translate) + ('WithdrawalArrangement' | translate)"
                             [placeholder]="('PleaseSelect' | translate) + ('WithdrawalArrangement' | translate)"
                             [options]="reduceBasicOptions"
                             [(value)]="applyReq.reduceBasic"
                             [bottomLineVisible]="false"></app-property-select>

        <app-property-yes-or-not *ngIf="product.companyCode !== 'AXA' && product.companyCode !== 'AXAMACAU'"
                                 [title]="'AllowReduceBasicAmount' | translate"
                                 [(value)]="applyReq.reduceBasic"
                                 [bottomLineVisible]="false"></app-property-yes-or-not>

        <div *ngIf="product.reduceAmountTips">
          <div *ngFor="let tip of product.reduceAmountTips">
            <span style="color: #444444; font-size: 11px">{{tip}}</span>，
          </div>
        </div>

      </div>
    </div>

    <!--保費扣除-->
    <div *ngIf="product.premiumOffsetSupported && applyReq.riders && applyReq.riders.length">
      <div class="proposal-apply-section-title flex-row">
        <span style="margin-left: 8px; flex: 1">{{'PremiumOffset' | translate}}</span>
        <div>
          <nz-switch [(ngModel)]="applyReq.doPremiumOffset"></nz-switch>
        </div>
      </div>

      <app-property-input-number *ngIf="applyReq.doPremiumOffset" [title]="'PolicyYear' | translate"
                                 [placeholder]="('PleaseEnter' | translate) + ('PolicyYear' | translate)"
                                 [(value)]="applyReq.premiumOffsetStartYear"></app-property-input-number>

      <app-property-input-number *ngIf="applyReq.doPremiumOffset" [title]="'PolicyEndYear' | translate"
                                 [placeholder]="('PleaseEnter' | translate) + ('PolicyEndYear' | translate)"
                                 [(value)]="applyReq.premiumOffsetEndYear"></app-property-input-number>
    </div>
    <!--    保费融资-->
<!--    <div *ngIf="product.premiumFinancingSupported" class="proposal-apply-section">-->
<!--      <div class="proposal-apply-section-title flex-row">-->
<!--        <span style="margin-left: 8px; flex: 1">{{'PremiumFinancing' | translate}}</span>-->
<!--        <div>-->
<!--          <nz-switch [(ngModel)]="applyReq.doPremiumFinancing"></nz-switch>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div *ngIf="applyReq.doPremiumFinancing">-->
<!--        <app-property-pick [title]="'BackInfo' | translate"-->
<!--                           appThrottleClick (throttleClick)="onPremiumFinancing('bank')"></app-property-pick>-->

<!--        <app-property-pick [title]="'DiscountInfo' | translate"-->
<!--                           appThrottleClick (throttleClick)="onPremiumFinancing('discount')"></app-property-pick>-->
<!--      </div>-->
<!--    </div>-->

    <!--    计划书清理-->
    <div *ngIf="workbench.proposalClean" class="proposal-apply-section">
      <div class="proposal-apply-section-title flex-row">
        <!--        <span style="margin-left: 8px; flex: 1">{{'WithdrawalPlan' | translate}}</span>-->
        <span style="margin-left: 8px; flex: 1">{{'ProposalCleanup' | translate}}</span>
        <div>
          <nz-switch [(ngModel)]="applyReq.clean"></nz-switch>
        </div>
      </div>
    </div>

    <!--    弹性保费-->
    <div *ngIf="isFlexPremiums" class="proposal-apply-section">
      <div class="proposal-apply-section-title flex-row">
        <span style="margin-left: 8px; flex: 1">{{'FlexiblePremiums' | translate}}</span>
        <div>
          <nz-switch [(ngModel)]="applyReq.doFlexiPremium"></nz-switch>
        </div>
      </div>
      <!-- 弹性保费选项（年） -->
      <app-property-select [title]="'FlexiblePremiums' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('FlexiblePremiums' | translate) + '(' + ('Year' | translate) + ')'"
                           [required]="true"
                           [disabled]="!applyReq.doFlexiPremium"
                           [options]="flexPremiumsOptions"
                           [(value)]="applyReq.flexiPremiumOption"></app-property-select>
    </div>

    <!-- 附加需求 -->
    <div class="proposal-apply-section">
      <div class="proposal-apply-section-title">
        <span style="margin-left: 8px">{{'AdditionalRequirements' | translate}}</span>
      </div>
      <app-property-input-area [title]="'AdditionalRequirements' | translate"
                               [placeholder]="'ProposalAreaTips' | translate"
                               [tip]="'PleaseSelectProductFirst' | translate"
                               [(value)]="applyReq.special"></app-property-input-area>

    </div>

  </nz-spin>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [disabled]="loading"
           [style]="loading ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary" style="position: relative;">
        <a nz-button nzType="link" nzBlock nzSize="large" [disabled]="!canApply"
           [style]="!canApply ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onApply()">
          <em nz-icon nzType="check" nzTheme="outline"></em>
          <span>{{'Generate' | translate}}</span>
          <span *ngIf="balance && balance.limitStatus">({{balance.alreadyUsed}}/{{balance.proposalLimit}} {{balance.unit | metadataTranslate: 'timeLimit'}})</span>
        </a>
        <i style="position: absolute;top: 0;right: 0;font-size: 16px;cursor: pointer"
           *ngIf="balanceLimit && balanceLimit.length"
           nz-icon nzType="question-circle" nzTheme="outline"
           appThrottleClick (throttleClick)="isVisible = true"></i>
      </div>
    </div>
  </div>

  <nz-modal [(nzVisible)]="isVisible"
            [nzClosable]="false" [nzFooter]="null"
            nzCentered [nzWidth]="400"
            (nzOnCancel)="isVisible = false">
    <ng-container *nzModalContent>
      <div style="text-align: center">
        <div *ngFor="let item of balanceLimit;" style="margin: 4px 0">
          {{item.unit | metadataTranslate: 'timeLimit'}}
          : {{ item.limitStatus ? item.quantityLimit : 'Unlimited' | translate}}
        </div>
        <button nz-button nzType="primary" appThrottleClick (throttleClick)="isVisible = false">{{ 'Confirm' | translate}}</button>
      </div>
    </ng-container>
  </nz-modal>

</div>

<!--<ng-container #withdrawalEditor></ng-container>-->
