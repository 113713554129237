<div class="from-info-container">
  <div class="from-info-card">
    <div style="color: rgba(64,64,64,0.85);text-align: justify">
      {{'CMGQuestion1' | translate}}
    </div>
  </div>

  <div>
    <div class="from-info-card">
      <div class="form-card-title question-title">
        {{'question1' | translate}}
      </div>
      <div class="form-card-check-box" style="justify-content: space-between;">
        <label nz-checkbox [(ngModel)]="financialObj.FNA_01_A"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
               style="margin-left: 16px;">
          <div class="check-text-label">
            {{'CMGQuestion2' | translate}}
          </div>
        </label>
        <div class="display-flex">
          <div class="check-text-label" style="margin-right: 4px;">{{'CMGQuestion61' | translate}} :</div>
          <div class="col-box" style="width: 220px;">
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [(ngModel)]="financialObj.FNA_01_A_addition">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-card-check-box" style="justify-content: space-between;">
        <label nz-checkbox [(ngModel)]="financialObj.FNA_01_B"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo" style="margin-left: 16px;">
          <div class="check-text-label">
            {{'CMGQuestion3' | translate}}
          </div>
        </label>
        <div class="display-flex">
          <div class="check-text-label" style="margin-right: 4px;">{{'CMGQuestion62' | translate}} :</div>
          <div class="col-box" style="width: 220px">
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [(ngModel)]="financialObj.FNA_01_B_addition">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-card-check-box">
        <label nz-checkbox [(ngModel)]="financialObj.FNA_01_B_addition_i"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo" style="margin-left: 54px;">
          <div class="check-text-label">
            {{'CMGQuestionPiba3_1' | translate}}
          </div>
        </label>
      </div>

      <div class="form-card-check-box">
        <label nz-checkbox [(ngModel)]="financialObj.FNA_01_B_addition_ii"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo" style="margin-left: 54px;">
          <div class="check-text-label">
            {{'CMGQuestionPiba3_2' | translate}}
          </div>
        </label>
      </div>

      <div class="form-card-check-box">
        <label nz-checkbox [(ngModel)]="financialObj.FNA_01_B_addition_iii"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo" style="margin-left: 54px;">
          <div class="check-text-label">
            {{'CMGQuestionPiba3_4' | translate}}
          </div>
        </label>
      </div>

      <div class="form-card-check-box">
        <label nz-checkbox [(ngModel)]="financialObj.FNA_01_B_addition_iiii"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo" style="margin-left: 54px;">
          <div class="check-text-label">
            {{'CMGQuestion3_5' | translate}}
          </div>
        </label>
        <div class="col-box">
        <textarea nz-input class="value-box-input" [placeholder]="'CMGQuestion8' | translate"
                  style="max-width: 620px;" [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                  [(ngModel)]="financialObj.FNA_01_B_addition_iiii_addition"
                  [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
        </div>
      </div>

      <div class="form-card-check-box">
        <label nz-checkbox [(ngModel)]="financialObj.FNA_01_C"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo" style="margin-left: 16px;">
          <div class="check-text-label">
            {{'CMGQuestion4' | translate}}
          </div>
        </label>
      </div>

      <div class="form-card-check-box">
        <label nz-checkbox [(ngModel)]="financialObj.FNA_01_D"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo" style="margin-left: 16px;">
          <div class="check-text-label">
            {{'CMGQuestion5' | translate}}
          </div>
        </label>
      </div>

      <div class="form-card-check-box">
        <label nz-checkbox [(ngModel)]="financialObj.FNA_01_E"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo" style="margin-left: 16px;">
          <div class="check-text-label">
            {{'CMGQuestion6' | translate}}
          </div>
        </label>
      </div>

      <div class="form-card-check-box" style="margin-bottom: 0;">
        <label nz-checkbox [(ngModel)]="financialObj.FNA_01_F"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo" style="margin-left: 16px;">
          <div class="check-text-label">
            {{'CMGQuestion7' | translate}}
          </div>
        </label>
        <div class="col-box">
        <textarea nz-input class="value-box-input" [placeholder]="'CMGQuestion8' | translate"
                  style="max-width: 620px;" [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                  [(ngModel)]="financialObj.FNA_01_F_addition"
                  [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
          <!--        <input nz-input class="value-box-input"-->
          <!--               style="width: 320px;"-->
          <!--               [placeholder]="'CMGQuestion8' | translate"-->
          <!--               [(ngModel)]="financialObj.answers[5].inputBox[0].value" />-->
        </div>
      </div>
    </div>

    <div class="from-info-card">
      <div class="form-card-title question-title">
        {{'CMGQuestion9' | translate}}
      </div>
      <nz-checkbox-wrapper class="form-card-radio-box"
                           style="width: 100%;">
        <div nz-row>
          <div nz-col nzSpan="24">
            <label nz-checkbox
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   [ngModel]="financialObj.FNA_02_A"
                   (ngModelChange)="FNA02log('FNA_02_A')">
              <div class="check-text-label" style="margin-bottom: 10px;">
                {{'CMGQuestion10' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="24">
            <label nz-checkbox
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   [ngModel]="financialObj.FNA_02_B"
                   (ngModelChange)="FNA02log('FNA_02_B')"
                   style="margin-bottom: 10px;">
              <div class="check-text-label">
                {{'CMGQuestion11' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="24">
            <label nz-checkbox
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   (ngModelChange)="FNA02log('FNA_02_C')"
                   [ngModel]="financialObj.FNA_02_C">
              <div class="check-text-label">
                {{'CMGQuestion12' | translate}}
              </div>
            </label>
          </div>
        </div>
      </nz-checkbox-wrapper>
    </div>

    <div class="from-info-card">
      <div class="form-card-title question-title">
        {{'CMGQuestion13' | translate}}
      </div>

      <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
        <div nz-row>
          <div nz-col nzSpan="4">
            <label nz-checkbox
                   [ngModel]="financialObj.FNA_03_A"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   (ngModelChange)="FNA03log('FNA_03_A')">
              <div class="check-text-label" style="margin-bottom: 10px;">
                {{'CMGQuestion14' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4">
            <label nz-checkbox
                   [ngModel]="financialObj.FNA_03_B"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   (ngModelChange)="FNA03log('FNA_03_B')"
                   style="margin-bottom: 10px;">
              <div class="check-text-label">
                {{'CMGQuestion15' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4">
            <label nz-checkbox
                   [ngModel]="financialObj.FNA_03_C"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   (ngModelChange)="FNA03log('FNA_03_C')">
              <div class="check-text-label">
                {{'CMGQuestion16' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4">
            <label nz-checkbox
                   [ngModel]="financialObj.FNA_03_D"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   (ngModelChange)="FNA03log('FNA_03_D')">
              <div class="check-text-label">
                {{'CMGQuestion17' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4">
            <label nz-checkbox
                   [ngModel]="financialObj.FNA_03_E"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   (ngModelChange)="FNA03log('FNA_03_E')">
              <div class="check-text-label">
                {{'CMGQuestion18' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4">
            <label nz-checkbox
                   [ngModel]="financialObj.FNA_03_F"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   (ngModelChange)="FNA03log('FNA_03_F')">
              <div class="check-text-label">
                {{'CMGQuestion19' | translate}}
              </div>
            </label>
          </div>
          <div nz-col nzSpan="4">
            <label nz-checkbox
                   [ngModel]="financialObj.FNA_03_G"
                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   (ngModelChange)="FNA03log('FNA_03_G')">
              <div class="check-text-label">
                {{'CMGQuestion20' | translate}}
              </div>
            </label>
          </div>
        </div>
      </nz-checkbox-wrapper>
    </div>

    <div class="from-info-card">
      <div class="form-card-title question-title">
        {{'CMGQuestion21' | translate}}</div>

      <div class="form-card-check-box" style="">
        <label nz-checkbox
               [ngModel]="financialObj.FNA_04_A"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
               style="margin-left: 16px;width: 120px;">
          <div class="check-text-label">
            {{'CMGQuestionPiba22' | translate}}
          </div>
        </label>
        <div class="display-flex" style="margin-left: 0;">
          <div class="col-box" style="width: 250px;">
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzParser]="parserValue"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="financialObj.FNA_04_A_addition_money">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-card-check-box" style="">
        <label nz-checkbox
               [ngModel]="financialObj.FNA_04_B"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
               style="margin-left: 16px;width: 120px;">
          <div class="check-text-label">
            {{'CMGQuestionPiba23' | translate}}
          </div>
        </label>
        <div class="display-flex" style="margin-left: 0;">
          <div class="col-box" style="width: 250px;">
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzParser]="parserValue"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="financialObj.FNA_04_B_addition_money">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-card-check-box" style="">
        <label nz-checkbox
               [ngModel]="financialObj.FNA_04_C"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
               style="margin-left: 16px;width: 120px;">
          <div class="check-text-label">
            {{'CMGQuestionPiba24' | translate}}
          </div>
        </label>
        <div class="display-flex" style="">
          <div class="check-text-label" style="margin-right: 6px;">{{'CMGQuestionPiba25' | translate}}</div>
          <div class="col-box" style="">
            <input nz-input class="value-box-input"
                   style="width: 130px;" [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   [placeholder]="'PleaseEnter' | translate"
                   [(ngModel)]="financialObj.FNA_04_C_addition_ages" />
          </div>
          <div class="check-text-label" style="margin-left: 8px;">{{'CMGQuestionPiba26' | translate}}</div>
        </div>

        <div class="display-flex" style="margin-left: 8px;">
          <div class="col-box" style="width: 250px;">
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzParser]="parserValue"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="financialObj.FNA_04_C_addition_money">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
          <div class="check-text-label" style="margin-left: 8px;">{{'CMGQuestionPiba27' | translate}}</div>
        </div>
      </div>

      <div class="form-card-check-box" style="">
        <label nz-checkbox
               [ngModel]="financialObj.FNA_04_D"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
               style="margin-left: 16px;width: 120px;">
          <div class="check-text-label">
            {{'CMGQuestion22' | translate}}
          </div>
        </label>
        <div class="display-flex" style="">
          <div class="check-text-label" style="margin-right: 6px;">{{'CMGQuestionPiba28' | translate}}</div>
          <div class="col-box" style="">
            <input nz-input class="value-box-input"
                   style="width: 130px;" [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   [placeholder]="'PleaseEnter' | translate"
                   [(ngModel)]="financialObj.FNA_04_D_addition_ages" />
          </div>
          <div class="check-text-label" style="margin-left: 8px;">{{'CMGQuestionPiba37' | translate}}</div>
        </div>

        <div class="display-flex" style="margin-left: 8px;">
          <div class="col-box" style="width: 250px;">
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzParser]="parserValue"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="financialObj.FNA_04_D_addition_money">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-card-check-box" style="">
        <label nz-checkbox
               [ngModel]="financialObj.FNA_04_E"
               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
               style="margin-left: 16px;width: 120px;">
          <div class="check-text-label">
            {{'answer36' | translate}}
          </div>
        </label>
        <div class="display-flex" style="">
          <div class="col-box" style="">
            <input nz-input class="value-box-input"
                   style="width: 222px;" [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                   [placeholder]="'PleaseEnter' | translate"
                   [(ngModel)]="financialObj.FNA_04_E_addition" />
          </div>
        </div>

        <div class="display-flex" style="margin-left: 8px;">
          <div class="col-box" style="width: 250px;">
            <div class="group-box"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzParser]="parserValue"
                               [nzPlaceHolder]="'PleaseEnter' | translate"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                               [nzFormatter]="formatterValue"
                               [(ngModel)]="financialObj.FNA_04_E_addition_money">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="from-info-card">
      <div class="form-card-title question-title">{{'CMGQuestion27' | translate}}</div>
      <div style="padding-left: 16px">
        <div class="question-title-span question-title" style="font-weight: bold;">
          {{'CMGQuestion28' | translate}}
        </div>
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
          <div nz-row style="margin-bottom: 10px;">
            <div nz-col nzSpan="24" class="display-flex" style="margin-bottom: 10px;">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_a_A"
                     (ngModelChange)="FNA05alog('FNA_05_a_A')"
              >
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion29' | translate}}
                </div>
              </label>
              <div class="col-box" style="margin-left: 4px;display: flex;align-items: center;justify-content: center;">
                <div class="group-box" style="width: 220px;flex: none;"
                     [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? '#FAFBFD' : ''">
                  <nz-input-number class="group-box-input-number"
                                   [nzParser]="parserValue"
                                   [nzFormatter]="formatterValue"
                                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                   [(ngModel)]="financialObj.FNA_05_a_A_addition">
                  </nz-input-number>
                  <span class="suffix-text">HK$</span>
                </div>
                <div class="check-text-label check-text-label-span" style="flex: 1;margin-left: 10px;">
                  {{'CMGQuestion63' | translate}}
                </div>
              </div>
            </div>
            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_a_B"
                     (ngModelChange)="FNA05alog('FNA_05_a_B')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion31' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_a_C"
                     (ngModelChange)="FNA05alog('FNA_05_a_C')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion32' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_a_D"
                     (ngModelChange)="FNA05alog('FNA_05_a_D')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion33' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_a_E"
                     (ngModelChange)="FNA05alog('FNA_05_a_E')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion34' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="4">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_a_F"
                     (ngModelChange)="FNA05alog('FNA_05_a_F')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion35' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>

        <div class="question-title-span question-title" style="font-weight: bold;">{{'CMGQuestion36' | translate}}</div>
        <div class="display-flex" style="margin-left: 16px;margin-bottom: 10px;">
          <div class="check-text-label" style="margin-right: 6px;">{{'CMGQuestion37' | translate}}：</div>
          <div class="col-box">
            <div class="group-box" style="width: 220px;"
                 [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? '#FAFBFD' : ''">
              <nz-input-number class="group-box-input-number"
                               [nzParser]="parserValue"
                               [nzFormatter]="formatterValue"
                               [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                               [(ngModel)]="financialObj.FNA_05_b">
              </nz-input-number>
              <span class="suffix-text">HK$</span>
            </div>
          </div>
        </div>

        <div class="question-title-span question-title" style="font-weight: bold;">
          {{'CMGQuestion38' | translate}}
        </div>
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
          <div nz-row style="margin-bottom: 10px;" [nzAlign]="'middle'">
            <div nz-col nzSpan="5" style="margin-bottom: 10px;">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_c_A"
                     (ngModelChange)="FNA05clog('FNA_05_c_A')">
                <div class="check-text-label">
                  {{'PiBaQuestion39' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_c_B"
                     (ngModelChange)="FNA05clog('FNA_05_c_B')">
                <div class="check-text-label">
                  {{'CMGQuestion40' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_c_C"
                     (ngModelChange)="FNA05clog('FNA_05_c_C')">
                <div class="check-text-label">
                  {{'CMGQuestion41' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_c_D"
                     (ngModelChange)="FNA05clog('FNA_05_c_D')">
                <div class="check-text-label">
                  {{'CMGQuestion42' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="4">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_c_E"
                     (ngModelChange)="FNA05clog('FNA_05_c_E')">
                <div class="check-text-label">
                  {{'CMGQuestion43' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="6" class="display-flex" style="">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_c_F"
                     (ngModelChange)="FNA05clog('FNA_05_c_F')">
                <div class="check-text-label check-text-label-span">
                  {{'Other' | translate}}
                </div>
              </label>
              <div class="col-box">
                <div class="group-box" style="width: 160px;"
                     [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? '#FAFBFD' : ''">
                  <nz-input-number class="group-box-input-number"
                                   [nzParser]="parserValue"
                                   [nzFormatter]="formatterValue"
                                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                   [(ngModel)]="financialObj.FNA_05_c_F_percent">
                  </nz-input-number>
                  <span class="suffix-text">%</span>
                </div>
              </div>
            </div>

            <div nz-col nzSpan="12" style="">
              <div class="display-flex">
                <div class="check-text-label" style="margin-right: 6px;">{{'CMGQuestionPiba29' | translate}}</div>
                <div class="col-box">
              <textarea nz-input class="value-box-input" [placeholder]="'PleaseEnter' | translate"
                        style="max-width: 620px;" [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                        [(ngModel)]="financialObj.FNA_05_c_H_addition"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
                </div>
              </div>
            </div>
          </div>
        </nz-checkbox-wrapper>

        <div class="question-title-span question-title" style="font-weight: bold;">
          {{'CMGQuestionPiba30' | translate}}
        </div>

        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
          <div nz-row style="margin-bottom: 10px;" [nzAlign]="'middle'">
            <div nz-col nzSpan="5" style="margin-bottom: 10px;">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_d_A"
                     (ngModelChange)="FNA05dlog('FNA_05_d_A')">
                <div class="check-text-label">
                  {{'PiBaQuestion39' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_d_B"
                     (ngModelChange)="FNA05dlog('FNA_05_d_B')">
                <div class="check-text-label">
                  {{'CMGQuestion40' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_d_C"
                     (ngModelChange)="FNA05dlog('FNA_05_d_C')">
                <div class="check-text-label">
                  {{'CMGQuestion41' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_d_D"
                     (ngModelChange)="FNA05dlog('FNA_05_d_D')">
                <div class="check-text-label">
                  {{'CMGQuestion42' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="4">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_d_E"
                     (ngModelChange)="FNA05dlog('FNA_05_d_E')">
                <div class="check-text-label">
                  {{'CMGQuestion43' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="6" class="display-flex" style="">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_d_F"
                     (ngModelChange)="FNA05dlog('FNA_05_d_F')">
                <div class="check-text-label check-text-label-span">
                  {{'Other' | translate}}
                </div>
              </label>
              <div class="col-box">
                <div class="group-box" style="width: 160px;"
                     [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? '#FAFBFD' : ''">
                  <nz-input-number class="group-box-input-number"
                                   [nzParser]="parserValue"
                                   [nzFormatter]="formatterValue"
                                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                   [(ngModel)]="financialObj.FNA_05_d_F_percent">
                  </nz-input-number>
                  <span class="suffix-text">%</span>
                </div>
              </div>
            </div>
            <div nz-col nzSpan="12" style="">
              <div class="display-flex">
                <div class="check-text-label" style="margin-right: 6px;">{{'CMGQuestionPiba29' | translate}}</div>
                <div class="col-box">
              <textarea nz-input class="value-box-input" [placeholder]="'PleaseEnter' | translate"
                        style="max-width: 620px;" [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                        [(ngModel)]="financialObj.FNA_05_d_H_addition"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
                </div>
              </div>
            </div>
          </div>
        </nz-checkbox-wrapper>

        <div class="question-title-span question-title" style="font-weight: bold;">{{'CMGQuestion47' | translate}}</div>

        <div class="form-card-check-box" style="">
          <label nz-checkbox
                 [ngModel]="financialObj.FNA_05_e_basic"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                 style="margin-left: 16px;">
            <div class="check-text-label">
              {{'CMGQuestionPiba31' | translate}}
            </div>
          </label>
        </div>

        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
          <div nz-row style="margin-bottom: 10px;padding-left: 24px;">
            <div nz-col nzSpan="5" style="margin-bottom: 10px;">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_basic_A"
                     (ngModelChange)="FNA05elog('FNA_05_e_basic_A')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion48' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_basic_B"
                     (ngModelChange)="FNA05elog('FNA_05_e_basic_B')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion49' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_basic_C"
                     (ngModelChange)="FNA05elog('FNA_05_e_basic_C')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion50' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_basic_D"
                     (ngModelChange)="FNA05elog('FNA_05_e_basic_D')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion51' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="4">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_basic_E"
                     (ngModelChange)="FNA05elog('FNA_05_e_basic_E')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion52' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_basic_F"
                     (ngModelChange)="FNA05elog('FNA_05_e_basic_F')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion53' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="10" class="display-flex">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_basic_G"
                     (ngModelChange)="FNA05elog('FNA_05_e_basic_G')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestionPiba32' | translate}}
                </div>
              </label>
              <div class="display-flex" style="">
                <div class="col-box" style="">
                  <input nz-input class="value-box-input"
                         style="width: 160px;" [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                         [placeholder]="'PleaseEnter' | translate"
                         [(ngModel)]="financialObj.FNA_05_e_basic_G_year" />
                </div>
                <div class="check-text-label" style="margin-left: 8px;">{{'CMGQuestionPiba33' | translate}}</div>
              </div>
            </div>

            <div nz-col nzSpan="9" class="display-flex">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_basic_H"
                     (ngModelChange)="FNA05elog('FNA_05_e_basic_H')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestionPiba35' | translate}}
                </div>
              </label>
              <div class="col-box" style="margin-left: 10px;">
                <div class="group-box" style="width: 160px;"
                     [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? '#FAFBFD' : ''">
                  <nz-input-number class="group-box-input-number"
                                   [nzParser]="parserValue"
                                   [nzFormatter]="formatterValue"
                                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                   [(ngModel)]="financialObj.FNA_05_e_basic_H_money">
                  </nz-input-number>
                  <span class="suffix-text">HK$</span>
                </div>
              </div>
            </div>
          </div>
        </nz-checkbox-wrapper>

        <div class="form-card-check-box" style="">
          <label nz-checkbox
                 [ngModel]="financialObj.FNA_05_e_rider"
                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                 style="margin-left: 16px;">
            <div class="check-text-label">
              {{'CMGQuestionPiba36' | translate}}
            </div>
          </label>
        </div>

        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
          <div nz-row style="margin-bottom: 10px;padding-left: 24px;">
            <div nz-col nzSpan="5" style="margin-bottom: 10px;">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_rider_A"
                     (ngModelChange)="FNA05erlog('FNA_05_e_rider_A')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion48' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_rider_B"
                     (ngModelChange)="FNA05erlog('FNA_05_e_rider_B')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion49' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_rider_C"
                     (ngModelChange)="FNA05erlog('FNA_05_e_rider_C')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion50' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_rider_D"
                     (ngModelChange)="FNA05erlog('FNA_05_e_rider_D')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion51' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="4">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_rider_E"
                     (ngModelChange)="FNA05erlog('FNA_05_e_rider_E')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion52' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="5">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_rider_F"
                     (ngModelChange)="FNA05erlog('FNA_05_e_rider_F')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion53' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="10" class="display-flex">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_rider_G"
                     (ngModelChange)="FNA05erlog('FNA_05_e_rider_G')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestionPiba32' | translate}}
                </div>
              </label>
              <div class="display-flex" style="">
                <div class="col-box" style="">
                  <input nz-input class="value-box-input"
                         style="width: 160px;" [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                         [placeholder]="'PleaseEnter' | translate"
                         [(ngModel)]="financialObj.FNA_05_e_rider_G_year" />
                </div>
                <div class="check-text-label" style="margin-left: 8px;">{{'CMGQuestionPiba33' | translate}}</div>
              </div>
            </div>

            <div nz-col nzSpan="9" class="display-flex">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_e_rider_H"
                     (ngModelChange)="FNA05erlog('FNA_05_e_rider_H')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestionPiba35' | translate}}
                </div>
              </label>
              <div class="col-box" style="margin-left: 10px;">
                <div class="group-box" style="width: 160px;"
                     [style.background-color]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo ? '#FAFBFD' : ''">
                  <nz-input-number class="group-box-input-number"
                                   [nzParser]="parserValue"
                                   [nzFormatter]="formatterValue"
                                   [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                   [(ngModel)]="financialObj.FNA_05_e_rider_H_money">
                  </nz-input-number>
                  <span class="suffix-text">HK$</span>
                </div>
              </div>
            </div>
          </div>
        </nz-checkbox-wrapper>

        <div class="question-title-span question-title" style="font-weight: bold;">
          {{'CMGQuestion55' | translate}}
        </div>
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
          <div nz-row style="">
            <div nz-col nzSpan="12" style="display: flex;align-items: center;">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_f_A"
                     (ngModelChange)="FNA05log('FNA_05_f_A')">
                <div class="check-text-label check-text-label-span">
                  {{'CMGQuestion56' | translate}}
                </div>
              </label>
            </div>

            <div nz-col nzSpan="12" class="display-flex">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_05_f_B"
                     (ngModelChange)="FNA05log('FNA_05_f_B')">
                <div class="check-text-label">
                  {{'answer36' | translate}}
                </div>
              </label>
              <div class="col-box">
              <textarea nz-input class="value-box-input" [placeholder]="'CMGQuestion8' | translate"
                        style="max-width: 620px;" [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                        [(ngModel)]="financialObj.FNA_05_f_B_addition"
                        [nzAutosize]="{ minRows: 1, maxRows: 1 }"></textarea>
                <!--              <input nz-input class="value-box-input"-->
                <!--                     style="width: 320px;"-->
                <!--                     [placeholder]="'CMGQuestion8' | translate"-->
                <!--                     [(ngModel)]="financialObj.answers[0].subQuestions[5].answers[1].inputBox[0].value" />-->
              </div>
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>

    <div class="from-info-card">
      <div class="form-card-title question-title">
        {{'CMGQuestion57' | translate}}
        <div class="icon-minus" (click)="otherExisting = !otherExisting">
          <img *ngIf="otherExisting" alt="" width="20"
               src="assets/images/icon_minus.svg">
          <img *ngIf="!otherExisting" alt="" width="20"
               src="assets/images/icon_plus.svg">
        </div>
      </div>
      <div *ngIf="otherExisting" style="padding-left: 16px;">
        <div class="question-title-span question-title" style="font-weight: bold;">{{'CMGQuestion58' | translate}}</div>
        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
          <div nz-row style="margin-bottom: 10px;">
            <div nz-col nzSpan="4">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_06_a_A"
                     (ngModelChange)="FNA06log('FNA_06_a_A')">
                <div class="check-text-label">
                  {{'answer37' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_06_a_B"
                     (ngModelChange)="FNA06log('FNA_06_a_B')">
                <div class="check-text-label">
                  {{'answer38' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
        <div *ngIf="financialObj.FNA_06_a_A" class="form-card-radio-box policy-box">
          <div nz-row [nzGutter]="16" style="padding-right: 8px;">
            <div nz-col [nzSpan]="6">
              <div class="label-text">{{'answer39' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_a_Policy_Number" />
              </div>
            </div>
            <div nz-col [nzSpan]="6">
              <div class="label-text">{{'answer40' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_a_Insurer_Name" />
              </div>
            </div>
            <div nz-col [nzSpan]="6">
              <div class="label-text">{{'answer41' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_a_Lender_Name" />
              </div>
            </div>
            <div nz-col [nzSpan]="6">
              <div class="label-text">{{'answer42' | translate}}</div>
              <div class="value-box">
                <nz-input-number class="value-box-input"
                                 style="padding: 0;"
                                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="financialObj.FNA_06_a_Loan_Amount">
                </nz-input-number>
              </div>
            </div>
            <div nz-col [nzSpan]="6" style="margin-top: 8px;">
              <div class="label-text">{{'answer43' | translate}}</div>
              <div class="value-box">
                <nz-input-number class="value-box-input"
                                 style="padding: 0;"
                                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="financialObj.FNA_06_a_Interest_Rate">
                </nz-input-number>
                <!--              <input class="value-box-input" nz-input-->
                <!--                     [placeholder]="'PleaseEnter' | translate"-->
                <!--                     [(ngModel)]="financialObj.answers[0].subQuestions[0].subQuestions[0].answers[4].inputBox[0].value" />-->
              </div>
            </div>
            <div nz-col [nzSpan]="6" style="margin-top: 8px;">
              <div class="label-text">{{'answer44' | translate}}</div>
              <div class="value-box">
                <nz-input-number class="value-box-input"
                                 style="padding: 0;"
                                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="financialObj.FNA_06_a_Repayment_Term">
                </nz-input-number>
                <!--              <input class="value-box-input" nz-input-->
                <!--                     [placeholder]="'PleaseEnter' | translate"-->
                <!--                     [(ngModel)]="financialObj.answers[0].subQuestions[0].subQuestions[0].answers[5].inputBox[0].value" />-->
              </div>
            </div>
            <div nz-col [nzSpan]="6" style="margin-top: 8px;">
              <div class="label-text">{{'answer45' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_a_Repayment_Amount" />
                <!--              <nz-input-number class="value-box-input"-->
                <!--                               style="padding: 0;"-->
                <!--                               [nzPlaceHolder]="'PleaseEnter' | translate"-->
                <!--                               [nzParser]="parserValue"-->
                <!--                               [nzFormatter]="formatterValue"-->
                <!--                               [(ngModel)]="financialObj.answers[0].subQuestions[0].subQuestions[0].answers[6].inputBox[0].value">-->
                <!--              </nz-input-number>-->
              </div>
            </div>
            <div nz-col [nzSpan]="6" style="margin-top: 8px;">
              <div class="label-text">{{'answer46' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_a_Repayment_Amount_02" />
                <!--              <nz-input-number class="value-box-input"-->
                <!--                               style="padding: 0;"-->
                <!--                               [nzPlaceHolder]="'PleaseEnter' | translate"-->
                <!--                               [nzParser]="parserValue"-->
                <!--                               [nzFormatter]="formatterValue"-->
                <!--                               [(ngModel)]="financialObj.answers[0].subQuestions[0].subQuestions[0].answers[7].inputBox[0].value">-->
                <!--              </nz-input-number>-->
              </div>
            </div>
          </div>

        </div>

        <div class="question-title-span question-title" style="font-weight: bold;">
          {{'CMGQuestion59' | translate}}
        </div>

        <nz-checkbox-wrapper class="form-card-radio-box" style="width: 100%;">
          <div nz-row style="margin-bottom: 10px;">
            <div nz-col nzSpan="4" style="">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_06_b_A"
                     (ngModelChange)="FNA062log('FNA_06_b_A')">
                <div class="check-text-label">
                  {{'answer37' | translate}}
                </div>
              </label>
            </div>
            <div nz-col nzSpan="4">
              <label nz-checkbox [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                     [ngModel]="financialObj.FNA_06_b_B"
                     (ngModelChange)="FNA062log('FNA_06_b_B')">
                <div class="check-text-label">
                  {{'answer38' | translate}}
                </div>
              </label>
            </div>
          </div>
        </nz-checkbox-wrapper>
        <div *ngIf="financialObj.FNA_06_b_A" class="form-card-radio-box policy-box">
          <div class="question-title-span question-title" style="margin-bottom: 8px;">POLICY  1</div>
          <div nz-row [nzGutter]="16" style="padding-right: 8px;">
            <div nz-col [nzSpan]="6">
              <div class="label-text">{{'PolicyNumber39' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_b_Policy_Number_01" />
              </div>
            </div>
            <div nz-col [nzSpan]="6">
              <div class="label-text">{{'answer40' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_b_Insurer_Name_01" />
              </div>
            </div>
            <div nz-col [nzSpan]="6">
              <div class="label-text">{{'answer41' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_b_Lender_Name_01" />
              </div>
            </div>
            <div nz-col [nzSpan]="6">
              <div class="label-text">{{'answer42' | translate}}</div>
              <div class="value-box">
                <nz-input-number class="value-box-input"
                                 style="padding: 0;" [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="financialObj.FNA_06_b_Loan_Amount_01">
                </nz-input-number>
              </div>
            </div>
            <div nz-col [nzSpan]="6" style="margin-top: 8px;">
              <div class="label-text">{{'answer43' | translate}}</div>
              <div class="value-box">
                <nz-input-number class="value-box-input"
                                 style="padding: 0;" [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="financialObj.FNA_06_b_Interest_Rate_01">
                </nz-input-number>
                <!--              <input class="value-box-input" nz-input-->
                <!--                     [placeholder]="'PleaseEnter' | translate"-->
                <!--                     [(ngModel)]="financialObj.answers[1].subQuestions[0].subQuestions[0].answers[4].inputBox[0].value" />-->
              </div>
            </div>
            <div nz-col [nzSpan]="6" style="margin-top: 8px;">
              <div class="label-text">{{'CMGQuestion64' | translate}}</div>
              <div class="value-box">
                <nz-input-number class="value-box-input"
                                 style="padding: 0;" [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="financialObj.FNA_06_b_Repayment_Term_01">
                </nz-input-number>
                <!--              <input class="value-box-input" nz-input-->
                <!--                     [placeholder]="'PleaseEnter' | translate"-->
                <!--                     [(ngModel)]="financialObj.answers[1].subQuestions[0].subQuestions[0].answers[5].inputBox[0].value" />-->
              </div>
            </div>
            <div nz-col [nzSpan]="6" style="margin-top: 8px;">
              <div class="label-text">{{'answer45' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_b_Repayment_Amount_01" />
                <!--              <nz-input-number class="value-box-input"-->
                <!--                               style="padding: 0;"-->
                <!--                               [nzPlaceHolder]="'PleaseEnter' | translate"-->
                <!--                               [nzParser]="parserValue"-->
                <!--                               [nzFormatter]="formatterValue"-->
                <!--                               [(ngModel)]="financialObj.answers[1].subQuestions[0].subQuestions[0].answers[6].inputBox[0].value">-->
                <!--              </nz-input-number>-->
              </div>
            </div>
            <div nz-col [nzSpan]="6" style="margin-top: 8px;">
              <div class="label-text">{{'answer46' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_b_Repayment_Amount_01_02" />
                <!--              <nz-input-number class="value-box-input"-->
                <!--                               style="padding: 0;"-->
                <!--                               [nzPlaceHolder]="'PleaseEnter' | translate"-->
                <!--                               [nzParser]="parserValue"-->
                <!--                               [nzFormatter]="formatterValue"-->
                <!--                               [(ngModel)]="financialObj.answers[1].subQuestions[0].subQuestions[0].answers[7].inputBox[0].value">-->
                <!--              </nz-input-number>-->
              </div>
            </div>
          </div>


        </div>
        <div *ngIf="financialObj.FNA_06_b_A" class="form-card-radio-box policy-box" style="margin-bottom: 0;">
          <div class="question-title-span question-title" [style]="policy2 ? '' : 'margin-bottom: 0;'">
            POLICY  2
            <div class="icon-minus" style="margin-left: 26px;" (click)="policy2 = !policy2">
              <img *ngIf="policy2" alt="" width="20"
                   src="assets/images/icon_minus.svg">
              <img *ngIf="!policy2" alt="" width="20"
                   src="assets/images/icon_plus.svg">
            </div>
          </div>
          <div *ngIf="policy2" nz-row [nzGutter]="16" style="padding-right: 8px;">
            <div nz-col [nzSpan]="6">
              <div class="label-text">{{'PolicyNumber39' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_b_Policy_Number_02" />
              </div>
            </div>
            <div nz-col [nzSpan]="6">
              <div class="label-text">{{'answer40' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_b_Insurer_Name_02" />
              </div>
            </div>
            <div nz-col [nzSpan]="6">
              <div class="label-text">{{'answer41' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_b_Lender_Name_02" />
              </div>
            </div>
            <div nz-col [nzSpan]="6">
              <div class="label-text">{{'answer42' | translate}}</div>
              <div class="value-box">
                <nz-input-number class="value-box-input"
                                 style="padding: 0;"
                                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="financialObj.FNA_06_b_Loan_Amount_02">
                </nz-input-number>
              </div>
            </div>
            <div nz-col [nzSpan]="6" style="margin-top: 8px;">
              <div class="label-text">{{'answer43' | translate}}</div>
              <div class="value-box">
                <nz-input-number class="value-box-input"
                                 style="padding: 0;"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="financialObj.FNA_06_b_Interest_Rate_02">
                </nz-input-number>
                <!--              <input class="value-box-input" nz-input-->
                <!--                     [placeholder]="'PleaseEnter' | translate"-->
                <!--                     [(ngModel)]="financialObj.answers[1].subQuestions[0].subQuestions[1].answers[4].inputBox[0].value" />-->
              </div>
            </div>
            <div nz-col [nzSpan]="6" style="margin-top: 8px;">
              <div class="label-text">{{'CMGQuestion64' | translate}}</div>
              <div class="value-box">
                <nz-input-number class="value-box-input"
                                 style="padding: 0;"
                                 [nzPlaceHolder]="'PleaseEnter' | translate"
                                 [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                                 [nzParser]="parserValue"
                                 [nzFormatter]="formatterValue"
                                 [(ngModel)]="financialObj.FNA_06_b_Repayment_Term_02">
                </nz-input-number>
                <!--              <input class="value-box-input" nz-input-->
                <!--                     [placeholder]="'PleaseEnter' | translate"-->
                <!--                     [(ngModel)]="financialObj.answers[1].subQuestions[0].subQuestions[1].answers[5].inputBox[0].value" />-->
              </div>
            </div>
            <div nz-col [nzSpan]="6" style="margin-top: 8px;">
              <div class="label-text">{{'answer45' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input
                       [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_b_Repayment_Amount_02" />
                <!--              <nz-input-number class="value-box-input"-->
                <!--                               style="padding: 0;"-->
                <!--                               [nzPlaceHolder]="'PleaseEnter' | translate"-->
                <!--                               [nzParser]="parserValue"-->
                <!--                               [nzFormatter]="formatterValue"-->
                <!--                               [(ngModel)]="financialObj.answers[1].subQuestions[0].subQuestions[1].answers[6].inputBox[0].value">-->
                <!--              </nz-input-number>-->
              </div>
            </div>
            <div nz-col [nzSpan]="6" style="margin-top: 8px;">
              <div class="label-text">{{'answer46' | translate}}</div>
              <div class="value-box">
                <input class="value-box-input" nz-input
                       [disabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                       [placeholder]="'PleaseEnter' | translate"
                       [(ngModel)]="financialObj.FNA_06_b_Repayment_Amount_02_02" />
                <!--              <nz-input-number class="value-box-input"-->
                <!--                               style="padding: 0;"-->
                <!--                               [nzPlaceHolder]="'PleaseEnter' | translate"-->
                <!--                               [nzParser]="parserValue"-->
                <!--                               [nzFormatter]="formatterValue"-->
                <!--                               [(ngModel)]="financialObj.answers[1].subQuestions[0].subQuestions[1].answers[7].inputBox[0].value">-->
                <!--              </nz-input-number>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="financialObj.FNA_06_a_A" class="from-info-card">
      <div class="form-card-radio-box">
        {{'CMGQuestion60' | translate}}
      </div>
    </div>
<!--    <app-piba-template-question [financialObj]="financialObj"-->
<!--                               [policy2]="policy2"-->
<!--                               [isDisabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"-->
<!--                               (fnaChange)="newFnaChange($event)">-->
<!--    </app-piba-template-question>-->
  </div>
</div>

