<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!listData">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="listData" style="margin-top: 30px;">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="spinning"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="listData.list"
                [nzPageIndex]="listData.pageNum"
                [nzPageSize]="listData.pageSize"
                [nzTotal]="listData.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{ x: '1415'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'CooperativeContract' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default" nzSize="large" nzShape="circle" style=" margin-left: 10px;"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight">
                  <img alt="" width="22" src="assets/images/ic_filter.svg">
                </button>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>

        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ listData.total }}</span>
          </div>
        </ng-template>
        <thead>
        <tr>
          <th nzLeft nzWidth="165px">
            <span nz-tooltip
                  [nzTooltipTitle]="'PactName' | translate">{{'PactName' | translate}}</span>
          </th>
          <th nzLeft nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="'EffectiveDate' | translate">{{'EffectiveDate' | translate}}</span>
          </th>
          <th nzLeft nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="'EndDate' | translate">{{'EndDate' | translate}}</span>
          </th>
          <th nzLeft nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="'SignedStatus' | translate">{{'SignedStatus' | translate}}</span>
          </th>
          <th nzLeft nzWidth="165px">
            <span nz-tooltip [nzTooltipTitle]="'SignatureTime' | translate">{{'SignatureTime' | translate}}</span>
          </th>

          <th nzRight nzWidth="160px">
            <span>{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let list of listData.list; let i = index">
          <td><span>{{ list.pactName }}</span></td>
          <td><span>{{ list.effectiveDate }}</span></td>
          <td><span>{{ list.expiryDate }}</span></td>
          <td>
            <div [ngSwitch]="list.signedStatus">
              <div>
                <span *ngSwitchCase="'SIGNED'" class="text-success">●</span>
                <span *ngSwitchCase="'UNSIGNED'" class="text-danger">●</span>
                <span> {{ list.signedStatus | metadataTranslate:'signedStatus' }}</span>
              </div>
            </div>
          </td>
          <td><span>{{ list.signDate }}</span></td>
          <td nzRight>
            <div class="functions">
              <a nz-button nzType="link" nzSize="small"
                 nz-tooltip [nzTooltipTitle]="'Detail' | translate"
                 appThrottleClick (throttleClick)="onDetail(list)">
                <img alt="" width="20" src="assets/images/ic_details.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>

<ng-template #filterTemplate>
  <div style="width: 300px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <app-property-select [title]="'SignedStatus' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('SignedStatus' | translate)"
                         [options]="typeOptions"
                         [allowClear]="false"
                         [(value)]="search.status"></app-property-select>
    <nz-row [nzGutter]="5">
<!--      <nz-col nzSpan="12">-->
<!--        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick-->
<!--                (throttleClick)="onReload()">-->
<!--          <span>{{'Reset' | translate}}</span>-->
<!--        </button>-->
<!--      </nz-col>-->
      <nz-col nzSpan="24">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
