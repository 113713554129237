<!--<ng-template #sheetTemplate>-->
  <div class="bottom-sheet"
    [class.bottom-sheet-open]="isOpen"
  >
    <div class="bottom-sheet-mask" (click)="onMask()"></div>
    <div class="bottom-sheet-content" *ngIf="content" >
<!--      <ng-container *ngComponentOutlet="contentOutlet"></ng-container>-->

      <ng-container #container></ng-container>

      <!--      <div style="background-color: white; height: fit-content; width: 100%" #content></div>-->
    </div>
<!--    <ng-template cdkPortalOutlet></ng-template>-->
<!--    <ng-container *ngIf="content">-->
<!--      <ng-container *ngIf="isTemplateRef(content)">-->
<!--        <ng-container *ngTemplateOutlet="$any(content); context: templateContext"></ng-container>-->
<!--      </ng-container>-->
<!--    </ng-container>-->
  </div>
<!--</ng-template>-->
