import {Expose} from 'class-transformer';
import {PagedReq} from '../api/types';

export enum ContractType {
  GENERAL = 'GENERAL',
  PROMOTION = 'PROMOTION',
}


export class ContractList {
  @Expose() accountName: string; // 账户名
  @Expose() contractSalesAllocationId: number; // bee_contract_sales_allocation_ --> id
  @Expose() contractMasterRateId: number;
  @Expose() source: string; // 合约来源
  @Expose() type: ContractType; // 合約类型:GENERAL-一般合约;PROMOTION-促销合约
  @Expose() invalidDate: string; // 失效时间
  @Expose() baseOn: string; // 计算基础:PREMIUM-保费;GROSS_COMMISSION-毛佣金
  @Expose() id: number; // 编号
  @Expose() salesContractViewId: number;
  @Expose() name: string; // 合約名称
  @Expose() effectiveDate: string; // 生效时间
  @Expose() remark: string; // 生效时间
  @Expose() contractMode: string; // 合约类型 TIER_COMMISSION_RATE || SALES_CHANNEL_RATE
}

export class ContractSearch extends PagedReq {
  @Expose() effectiveEndDate: string; // 生效结束时间
  @Expose() effectiveStartDate: string;
  @Expose() invalidStartDate: string; // 失效开始时间
  @Expose() invalidEndDate: string;
  @Expose() name: string;
  @Expose() type: string;
}

export class ContractQuery {
  @Expose() productCode: string;
  @Expose() contractSalesAllocationId: number;
  @Expose() contractMasterRateId: number;
  @Expose() baseOn: string;
  @Expose() id: number;
  @Expose() salesContractViewId: number;
}

export class ContractProduct {
  @Expose() productCode: string; // 产品码
  @Expose() productNameEn: string; // 产品英文名称
  @Expose() productName: string; // 产品名称
}

export class ContractConditions {
  @Expose() factor: string; // 因素
  @Expose() operation: string; // 比较符
  @Expose() value: string; // 值
}

export class ContractDetail {
  @Expose() companyCode: string; // 公司码
  @Expose() product: ContractProduct; // 产品信息
  @Expose() companyName: string; // 公司名称
  @Expose() id: number; // id
  @Expose() categoryCode: string; // id
  @Expose() conditions: ContractConditions[]; // 模板条件
  @Expose() grossRates: number[];
  @Expose() categoryName: string;
  @Expose() tierCommissionType: string;
  @Expose() tier: number;

}

export class ContractDetails {
  @Expose() details: ContractDetail[];

  maxIndexOfRates(): number {
    let maxIndex = 0;
    let maxNum = 0;
    if (this.details && this.details.length > 0) {
      for (let i = 0; i < this.details.length; i++) {
        const len = this.details[i].grossRates?.length ?? 0;
        if (len > maxNum) {
          maxNum = len;
          maxIndex = i;
        }
      }
    }
    return maxIndex;
  }
}

export class ExcelDownloadQuery {
  // @Expose() productCode: string; // 产品码 app用
  @Expose() contractSalesAllocationId: number; // Sales Allocation ID
  @Expose() contractMasterRateId: number; // Master Rate ID
  @Expose() id: number; // 自主上传合约ID
  @Expose() salesContractViewId: number;
}
