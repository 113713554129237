<div class="drawer-container">

  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 40px">
      {{'NewInspection' | translate}}
    </div>

    <!-- 類型 -->
    <app-property-select [title]="'Type' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Type' | translate)"
                         [required]="true"
                         [allowClear]="false"
                         [options]="typeOptions"
                         [(value)]="type"></app-property-select>

    <div [ngSwitch]="type">
      <div *ngSwitchCase="'PERSON'">

        <!-- 姓氏 -->
        <app-property-input [title]="'lastNameCN' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('lastNameCN' | translate)"
                            [required]="false"
                            [disabled]="workbench.amlNameConfig === 2"
                            [(value)]="inspectReq.surname"></app-property-input>
        <!-- 名字 -->
        <app-property-input [title]="'firstNameCN' | translate"
                            [disabled]="workbench.amlNameConfig === 2"
                            [placeholder]="('PleaseEnter' | translate) + ('firstNameCN' | translate)"
                            [required]="false"
                            [(value)]="inspectReq.forename"></app-property-input>
        <app-property-input [title]="'lastNameEN' | translate"
                            [disabled]="workbench.amlNameConfig === 1"
                            [placeholder]="('PleaseEnter' | translate) + ('lastNameEN' | translate)"
                            [required]="false"
                            [(value)]="inspectReq.englishSurname"></app-property-input>
        <app-property-input [title]="'firstNameEN' | translate"
                            [disabled]="workbench.amlNameConfig === 1"
                            [placeholder]="('PleaseEnter' | translate) + ('firstNameEN' | translate)"
                            [required]="false"
                            [(value)]="inspectReq.englishForename"></app-property-input>

        <!-- 生日 -->
        <app-property-datetime [title]="'Birthday' | translate"
                               [tip]="'PleaseSelectProductFirst' | translate"
                               (valueChange)="onBirthdayChange($event)"
                               [value]="birthday"></app-property-datetime>

        <!-- 国籍（国家地区） -->
        <app-property-pick [displayMode]="'horizontal'"
                           [title]="'Country' | translate"
                           appThrottleClick (throttleClick)="onCountry()"
                           [value]="countryOwnerString"></app-property-pick>
<!--        <app-property-select [title]="'Country' | translate"-->
<!--                             [placeholder]="('PleaseSelect' | translate) + ('Country' | translate)"-->
<!--                             [options]="regionOptions"-->
<!--                             [(value)]="inspectReq.country"></app-property-select>-->

        <app-property-select [title]="'Gender' | translate"
                             [placeholder]="('PleaseSelect' | translate) + ('Gender' | translate)"
                             [options]="genderOptions"
                             [(value)]="inspectReq.gender"></app-property-select>

      </div>
      <div *ngSwitchCase="'BUSINESS'">

        <!-- 姓氏 -->
        <app-property-input [title]="'CompanyName' | translate"
                            [placeholder]="'CompanyName' | translate"
                            [disabled]="workbench.amlNameConfig === 2"
                            [(value)]="inspectReq.businessName"></app-property-input>

        <app-property-input [title]="'CompanyNameEn' | translate"
                            [disabled]="workbench.amlNameConfig === 1"
                            [placeholder]="('PleaseSelect' | translate) + ('CompanyNameEn' | translate)"
                            [(value)]="inspectReq.englishName"></app-property-input>

        <!-- 国籍（国家地区） -->
        <app-property-pick [displayMode]="'horizontal'"
                           [title]="'Country' | translate"
                           appThrottleClick (throttleClick)="onCountry()"
                           [value]="countryOwnerString"></app-property-pick>
<!--        <app-property-select [title]="'Country' | translate"-->
<!--                             [placeholder]="('PleaseSelect' | translate) + ('Country' | translate)"-->
<!--                             [options]="regionOptions"-->
<!--                             [(value)]="inspectReq.country"></app-property-select>-->

      </div>
    </div>

  </nz-spin>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [disabled]="loading"
           [style]="loading ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" [disabled]="!canInspect"
           [style]="!canInspect ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onInspect()">
          <em nz-icon nzType="check" nzTheme="outline"></em>
          <span>{{'Inspect' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
