<div class="drawer-container">
  <div class="common-title">
    {{'SelectProposal' | translate}}
  </div>
  <nz-row [nzGutter]="12">
    <nz-col *ngIf="isNewFna" [nzSpan]="24">
      <app-property-input [title]="'ProposalID' | translate"
                          [displayMode]="'vertical'"
                          [placeholder]="('PleaseEnter' | translate) + ('ProposalID' | translate)"
                          [(value)]="search.proposalId"
                          (valueChange)="onSearchParamChange()"></app-property-input>
    </nz-col>
    <nz-col [nzSpan]="12">
      <app-property-select [disabled]="!enableOptions"
                           [displayMode]="'vertical'"
                           [title]="'ProductCategory' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('ProductCategory' | translate)"
                           [(value)]="search.categoryCode"
                           [options]="categoryOptions"
                           (valueChange)="onSearchParamChange()"></app-property-select>
    </nz-col>
    <nz-col [nzSpan]="12">
      <app-property-select [disabled]="!enableOptions"
                           [displayMode]="'vertical'"
                           [title]="'Insurer' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Insurer' | translate)"
                           [(value)]="search.companyCode"
                           [options]="companyOptions"
                           (valueChange)="onSearchParamChange()"></app-property-select>
    </nz-col>
  </nz-row>
  <div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
      <div *ngIf="!proposals">
        <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
      </div>
      <div *ngIf="proposals" style="margin-top: 30px; ">
        <nz-table [nzBordered]="false"
                  [nzOuterBordered]="true"
                  [nzShowPagination]="true"
                  [nzLoading]="loading"
                  [nzLoadingIndicator]="indicatorTemplate"
                  [nzFrontPagination]="false"
                  [nzData]="proposals.list"
                  [nzPageIndex]="proposals.pageNum"
                  [nzPageSize]="proposals.pageSize"
                  [nzTotal]="proposals.total"
                  [nzTitle]="tableHeader"
                  [nzFooter]="tableFooter"
                  (nzPageIndexChange)="onSearch($event)"
                  [nzScroll]="{ x: '1350px'}">

          <ng-template #tableHeader>
            <nz-row>
              <nz-col [nzSpan]="12">
                <div class="list-header-title">{{'ProposalList' | translate}}
                  <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                     style="margin-left: 5px"
                     appThrottleClick (throttleClick)="onReload()">
                    <em nz-icon nzType="reload" nzTheme="outline"></em>
                  </a>
                </div>
              </nz-col>
              <nz-col [nzSpan]="12">
                <div style="width: 100%; display: flex; justify-content: flex-end;">
                  <button *ngIf="isNewFna" nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                          [nzTooltipTitle]="'ProposalApply' | translate" style=" margin-left: 10px;"
                          nz-popover [nzPopoverContent]="productsForProposalTemplate"
                          [(nzPopoverVisible)]="productsForProposalVisible"
                          [nzPopoverTrigger]="null"
                          appThrottleClick (throttleClick)="onProposalApply()">
                    <img alt="" width="22" src="assets/images/ic_proposal_new.svg">
                  </button>
                </div>
              </nz-col>
            </nz-row>
          </ng-template>
          <ng-template #tableFooter>
            <div class="table-title">
              {{'TotalRows' | translate}} : <span
              style="color: #002140; font-weight: bold;">{{ proposals.total }}</span>
            </div>
          </ng-template>

          <thead>
          <tr>
            <th nzLeft nzWidth="150px">
              <span nz-tooltip
                    [nzTooltipTitle]="'ProposalID' | translate">{{'ProposalID' | translate}}</span>
            </th>
            <th><span nz-tooltip [nzTooltipTitle]="'ProductName' | translate">{{'ProductName' | translate}}</span></th>
            <th nzWidth="100px">
              <span nz-tooltip
                    [nzTooltipTitle]="'PaymentTerm' | translate">{{'PaymentTerm' | translate}}</span>
            </th>
            <th nzWidth="90px">
              <span nz-tooltip [nzTooltipTitle]="'SumAssured' | translate">{{'SumAssured' | translate}}</span>
            </th>
            <th nzWidth="90px">
              <span nz-tooltip [nzTooltipTitle]="'Premium' | translate">{{'Premium' | translate}}</span>
            </th>
            <th nzWidth="90px"><span nz-tooltip [nzTooltipTitle]="'Gender' | translate">{{'Gender' | translate}}</span>
            </th>
            <th nzWidth="60px"><span nz-tooltip [nzTooltipTitle]="'Age' | translate">{{'Age' | translate}}</span></th>
            <th nzWidth="100px"><span nz-tooltip
                                      [nzTooltipTitle]="'Currency' | translate">{{'Currency' | translate}}</span></th>
            <th nzWidth="100px"><span nz-tooltip
                                      [nzTooltipTitle]="'SmokeCondition' | translate">{{'SmokeCondition' | translate}}</span>
            </th>
            <th nzWidth="140px"><span nz-tooltip [nzTooltipTitle]="'Status' | translate">{{'Status' | translate}}</span></th>
            <th nzWidth="180px"><span nz-tooltip
                                      [nzTooltipTitle]="'ApplicationTime' | translate">{{'ApplicationTime' | translate}}</span>
            </th>
          </thead>
          <tbody>
          <tr *ngFor="let proposal of proposals.list; let i = index"
              appThrottleClick (throttleClick)="onProposalSelected(proposal.proposalId, i)">
            <td nzLeft>
              <nz-badge [nzDot]="proposal.reminder">
            <span style="font-weight: bold; font-size: 12px">
              {{ proposal.proposalId }}
            </span>
              </nz-badge>
            </td>
            <td>
              <div style="display: flex; align-items: center;">
                <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="proposal.companyLogo" alt=""/>
                <span>{{ proposal.productName }}</span>
              </div>
            </td>
            <td>{{ proposal.paymentTerm }}</td>
            <td>{{ (proposal.sumAssured | number) || 'N/A' }}</td>
            <td>{{ (proposal.premium | number) || 'N/A' }}</td>
            <td>{{ proposal.gender | metadataTranslate: 'gender' }}</td>
            <td>{{ proposal.age }}</td>
            <td>{{ proposal.currency }}</td>
            <td>{{ proposal.smoke | metadataTranslate: 'smoke'}}</td>
            <td>
              <div [ngSwitch]="proposal.status">
                <div>
                  <span *ngSwitchCase="'PENDING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'COMPLETED'" class="text-success">●</span>
                  <span *ngSwitchCase="'CANCELLED'" class="text-cancel">●</span>
                  <span> {{ proposal.status | proposalStatus | translate}}</span>
                </div>
              </div>
            </td>
            <td><span>{{ proposal.applicationTime | date:'yyyy-MM-dd HH:mm:ss' }}</span></td>
          </tr>
          </tbody>
        </nz-table>
      </div>
    </nz-spin>
  </div>
</div>

<ng-template #productsForProposalTemplate>
  <nz-list nzSize="small">
    <nz-list-item *ngFor="let product of newFna.products">
      <a nz-button nzType="link" nzBlock nzSize="default" appThrottleClick (throttleClick)="proposalApplyWithProduct(product)">
        <!--          <em nz-icon nzType="save" nzTheme="outline"></em>-->
        <span>{{product.productName}}</span>
      </a>
    </nz-list-item>
  </nz-list>
</ng-template>
