import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Beneficiary, Customer, DataDesensitization, DataMode, Instance, Relationship } from '../../../api/types';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {BeneficiaryEditComponent} from '../beneficiary-edit/beneficiary-edit.component';
import {BeneficiaryIndexEntity} from '../beneficiaries-edit/beneficiaries-edit.component';
import {PropertySelectOption} from '../property-select/property-select.component';
import { WorkbenchService } from '../../../workbench/workbench.service';
import { AccountService } from '../../../account/account.service';
import { Workbench } from '../../../workbench/workbench-types';

@Component({
  selector: 'app-beneficiaries-read',
  templateUrl: './beneficiaries-read.component.html',
  styleUrls: ['./beneficiaries-read.component.less']
})
export class BeneficiariesReadComponent implements OnInit {

  title = 'Beneficiaries';
  beneficiaries: Beneficiary[] = [];

  beneficiariesCopy: Beneficiary[] = [];

  insured: Customer;
  instance: Instance;
  workbench: Workbench;
  @Input()
   moduleSource: string;

  @Output()
  beneficiariesSaved: EventEmitter<Beneficiary[]> = new EventEmitter<Beneficiary[]>();

  dataDesensitization: DataDesensitization = new DataDesensitization();
  modeType = DataMode.OWN;
  constructor(private drawerService: NzDrawerService,
              private modalService: NzModalService,
              private accountService: AccountService,
              public workbenchService: WorkbenchService,
  ) {
  }

  ngOnInit(): void {
    this.instance = this.workbenchService.mockInstance;
    this.workbench = this.workbenchService.workbench;
    this.dataDesensitization = this.accountService.dataDesensitization;
  }

  onEdit(): void {
    console.log('onEdit');
    const drawerRef = this.drawerService.create<BeneficiaryEditComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: BeneficiaryEditComponent,
      nzContentParams: {
        beneficiary: this.beneficiaries[0],
        insured: this.insured,
        beneficiaries: this.beneficiaries
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.beneficiarySaved.subscribe(
        data => {
          console.log('beneficiarySaved', data);
          drawerRef.close();
          if (!this.beneficiaries) {
            this.beneficiaries = [];
          }
        }
      );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onAddBeneficiary(): void {
    const drawerRef = this.drawerService.create<BeneficiaryEditComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: BeneficiaryEditComponent,
      nzContentParams: {
        insured: this.insured
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.beneficiarySaved.subscribe(
        data => {
          drawerRef.close();
          if (!this.beneficiaries) {
            this.beneficiaries = [];
          }
          this.beneficiaries.push(data);
        }
      );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });

  }


  onEditBeneficiary(beneficiary: Beneficiary, index: number): void {
    const drawerRef = this.drawerService.create<BeneficiaryEditComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: BeneficiaryEditComponent,
      nzContentParams: {
        beneficiary,
        insured: this.insured
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.beneficiarySaved.subscribe(
        data => {
          drawerRef.close();
          if (!this.beneficiaries) {
            this.beneficiaries = [];
          }
          if (this.beneficiaries.length > index) {
            this.beneficiaries[index] = beneficiary;
          }
        }
      );
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onDelete(beneficiary: Beneficiary, index: number, tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {

    const entity = new BeneficiaryIndexEntity();
    entity.beneficiary = beneficiary;
    entity.index = index;

    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: entity,
      nzOnOk: () => {
      }
    });
  }

  onDeleteConfirm(ref: NzModalRef, entity: BeneficiaryIndexEntity): void {
    if (this.beneficiaries.length > entity.index) {
      this.beneficiaries.splice(entity.index, 1);
    }
    ref.close();
  }

  onSave(): void {
    this.beneficiariesSaved.emit(this.beneficiaries);
  }

  dynamicEncryption(keyName): boolean {
    let isShow = false;
    if (!this.dataDesensitization || !this.modeType) {
      return isShow;
    }
    if (this.modeType === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    }
  }
}
