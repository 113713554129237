<div class="login-container">

  <div nz-row nzJustify="center" style="width: 100%; height: 100%;">
    <!--        <div nz-col [nzSpan]="10" class="login-column">-->
    <!--            <img width="75%" src="./assets/images/login_illustration.svg">-->
    <!--        </div>-->
    <div nz-col [nzSpan]="12" class="login-column">
      <form (ngSubmit)="loginByCode()" #loginForm="ngForm" style="width: 100%" class="login-column">
        <img width="150" src="assets/images/logo.png" style="margin-bottom: 40px" alt="">
        <div class="login-title">{{ "LoginByCode" | translate }}</div>
        <div style="width: 50%; margin-top: 20px;">

          <nz-input-group [nzSuffix]="suffixIconAccount" class="input-group">
            <input required [(ngModel)]="loginReq.accountName" id="account" name="account" autofocus="autofocus"
                   [placeholder]="'Account' | translate" class="login-input"/>
          </nz-input-group>
          <ng-template #suffixIconAccount>
            <!--                        <i style="font-size: 20px" nz-icon nzType="user-switch"></i>-->
          </ng-template>

        </div>
        <div style="width: 50%; margin-top: 20px;">
          <nz-input-group [nzSuffix]="suffixIconLogin" class="input-group">
            <input required [(ngModel)]="loginReq.code" id="code" name="code"
                   [placeholder]="'VerificationCode' | translate" class="login-input"/>
          </nz-input-group>
          <ng-template #suffixIconLogin>
            <a class="login-button-code" nz-button nzType="link" nzSize="small">{{'SendVerificationCode' | translate}}{{leftSeconds}}</a>
          </ng-template>

        </div>
        <div style="margin-top: 30px;">
          <button style="width: 60px; height: 60px; color: #13263f" type="submit" [nzLoading]="logging"
                  nz-tooltip [nzTooltipTitle]="'Login' | translate"
                  nz-button nzType="default" nzShape="circle">
            <i nz-icon nzType="login"></i>
          </button>
        </div>
        <div style="width: 40%;">
          <nz-divider></nz-divider>
        </div>
        <div>
          <button style="width: 40px; height: 40px;" type="button"
                  nz-tooltip [nzTooltipTitle]="'LoginByAccount' | translate" [disabled]="logging"
                  nz-button nzType="default" nzShape="circle" appThrottleClick (throttleClick)="onLoginByAccount()">
            <i nz-icon nzType="user"></i>
          </button>
<!--          <button style="width: 40px; height: 40px; margin-left: 10px; margin-right: 10px;" type="button"-->
<!--                  [disabled]="logging"-->
<!--                  nz-tooltip [nzTooltipTitle]="'AccountSwitch' | translate"-->
<!--                  nz-button nzType="default" nzShape="circle" appThrottleClick (throttleClick)="onAccounts()">-->
<!--            <i nz-icon nzType="user-switch"></i>-->
<!--          </button>-->
        </div>
      </form>
    </div>
  </div>
</div>
