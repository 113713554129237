import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpService } from '../core/http.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NewFnaList, NewFnaSearch } from '../new-fna/new-fna-types';
import { ApiResponse, DataMode, PagedResp } from '../api/types';
import { Observable } from 'rxjs/internal/Observable';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { InviteInfo, InviteInfoRes, InviteListRes, InviteSearch } from './invitation-types';
import { ContactInfoRes } from '../contact/contact-types';

@Injectable({
  providedIn: 'root'
})
export class InvitationService extends BaseService {

  constructor(private http: HttpService, private httpClient: HttpClient) {
    super();
  }

  /**
   * 获取客户意向邀约列表
   * @param search 查询条件
   * @param type 类型
   */
  list(search: InviteSearch, type: DataMode): Observable<PagedResp<InviteListRes>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.accountName != null) {
      httpParams = httpParams.set('accountName', search.accountName.toString());
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status.toString());
    }
    if (search.customerName != null) {
      httpParams = httpParams.set('customerName', search.customerName.toString());
    }
    if (search.startMeetingDate != null) {
      httpParams = httpParams.set('startMeetingDate', search.startMeetingDate.toString());
    }
    if (search.endMeetingDate != null) {
      httpParams = httpParams.set('endMeetingDate', search.endMeetingDate.toString());
    }
    if (search.productCode != null) {
      httpParams = httpParams.set('productCode', search.productCode.toString());
    }
    if (search.sortField != null) {
      httpParams = httpParams.set('sortField', search.sortField.toString());
    }
    if (search.sortOrder != null) {
      httpParams = httpParams.set('sortOrder', search.sortOrder.toString());
    }
    let uri = '';
    switch (type) {
      case DataMode.OWN:
        uri = '/list';
        break;
      case DataMode.SUBORDINATE:
        uri = '/subordinate-list';
        break;
      // case DataMode.FOLLOWUP:
      //   uri = '/list-consultant';
      //   break;
      // case DataMode.SALESGROUP:
      //   uri = '/list-sales-group';
      //   break;
    }
    return this.http.get<ApiResponse<PagedResp<InviteListRes>>>(API.inviteV3 + uri, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 获取客户意向邀约详情
   * @param id 查询条件
   */
  info(id: any): Observable<InviteInfoRes> {
    return this.http.get<ApiResponse<InviteInfoRes>>(API.inviteV3 + '/info/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 复用
   * @param id 查询条件
   */
  reuse(id: any): Observable<InviteInfoRes> {
    return this.http.get<ApiResponse<InviteInfoRes>>(API.inviteV3 + '/reuse/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 新增客户意向邀约
   * @param req 传参
   */
  create(req: InviteInfo): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.inviteV3 + '/insert', req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 更新信息
   * @param req 传参
   */
  updateInfo(req: InviteInfo): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.inviteV3 + '/update', req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 删除客户意向邀约
   * @param id FNA Id
   */
  delete(id: number): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.inviteV3 + '/delete/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
