import { Component, OnInit } from '@angular/core';
import { HedgeRes } from '../../proposal-types';
import { MetadataService } from '../../../shared/service/metadata.service';

@Component({
  selector: 'app-hedge-preview',
  templateUrl: './hedge-preview.component.html',
  styleUrls: ['./hedge-preview.component.less']
})
export class HedgePreviewComponent implements OnInit {

  hedgeData: HedgeRes = new HedgeRes();

  constructor(private metadataService: MetadataService) { }

  ngOnInit(): void {
  }

}
