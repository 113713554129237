<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div class="common-title" style="margin-bottom: 30px">
      {{'ContactsList' | translate}}
    </div>
    <div *ngIf="!contactList">
      <nz-empty nzNotFoundImage="simple"></nz-empty>
    </div>
    <div *ngIf="contactList" style="margin-top: 30px; ">
      <div  style="margin-bottom: 15px">
      <app-property-input [displayMode]="'vertical'"
                          [title]="'ContactName' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('ContactName' | translate)"
                          [(value)]="search.name"
                          (valueChange)="onSearchParamChange()"></app-property-input>
      </div>
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="spinning"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="contactList.list"
                [nzPageIndex]="contactList.pageNum"
                [nzPageSize]="contactList.pageSize"
                [nzTotal]="contactList.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'ContactsList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ contactList.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzAlign="center" nzWidth="165px">
            <span nz-tooltip
                  [nzTooltipTitle]="'Name' | translate">{{'Name' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Gender' | translate">{{'Gender' | translate}}</span>
          </th>
          <th nzAlign="center" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Age' | translate">{{'Age' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let contacts of contactList.list; let i = index"
            appThrottleClick (throttleClick)="onDetail(contacts.id)"
        >
          <td nzLeft nzAlign="left">
            <div style="display: flex; align-items: center">
              <nz-avatar class="list-top-avatar" style="flex-shrink: 0; margin-right: 20px;" [nzSize]="36"
                         [nzSrc]="contacts.avatar"></nz-avatar>
              <span style="flex-shrink: 1;font-weight: bold; font-size: 12px">
              {{ contacts.name }}
            </span>
            </div>
          </td>
          <td nzAlign="center"><span>{{ contacts.gender | metadataTranslate: 'gender' }}</span></td>
          <td nzAlign="center"><span>{{ contacts.age }} {{'YearsOld' | translate}}</span></td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>

