import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { DomainMetadataVOS } from '../../../api/types';
import { PropertySelectOption } from '../property-select/property-select.component';
import { TranslateService } from '@ngx-translate/core';
import { BookingDetailResp, Other } from '../../../booking/booking-types';
import { MetadataService } from '../../service/metadata.service';

@Component({
  selector: 'app-reinsurance',
  templateUrl: './reinsurance.component.html',
  styleUrls: ['./reinsurance.component.less']
})
export class ReinsuranceComponent implements OnInit {
  dataCopy: Other = new Other();
  declarationCopy: string;
  @Output()
  reinsuranceSaved: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  bookingDetail: BookingDetailResp;
  declarationOptions = [];
  other: Other = new Other();
  replacementDeclaration: string;
  titleArr = [
    'ReinsuranceQuestion',
    'ReinsuranceQuestion1',
    'ReinsuranceQuestion2',
    'ReinsuranceQuestion3',
    'ReinsuranceQuestion4',
    'ReinsuranceQuestion5',
  ];
  selectOptions: PropertySelectOption[] = [];

  constructor(private translate: TranslateService,
              private metadataService: MetadataService) {
  }

  ngOnInit(): void {
    this.dataCopy = JSON.parse(JSON.stringify(this.other));
    this.declarationCopy = this.replacementDeclaration;
    this.translate.get(['Yes', 'No']).subscribe(
      (res: any) => {
        this.selectOptions.push(new PropertySelectOption(res.Yes, true));
        this.selectOptions.push(new PropertySelectOption(res.No, false));
      });

    this.declarationOptions = this.metadataService.values('replacementDeclaration');

  }

  onOptions(arr): PropertySelectOption[] {
    const optionsArr: PropertySelectOption[] = [];
    for (const item of arr) {
      optionsArr.push(new PropertySelectOption(item.name, item.id));
    }
    return optionsArr;
  }

  onMultiCheckBoxes(val: any): any {
    if (!val) {
      return null;
    }
    const paramArr = JSON.parse(val);
    if (paramArr.length) {
      // for (const item of paramArr) {
      //   newArr.push(item);
      // }
      return paramArr;
    } else {
      return [];
    }
  }

  onReset(): void {
    this.other = JSON.parse(JSON.stringify(this.dataCopy));
    this.replacementDeclaration = this.declarationCopy;
  }

  onSave(): void {
    if (this.bookingDetail.showReplacementDeclaration) {
      this.reinsuranceSaved.emit(this.replacementDeclaration);
    }else {
      this.reinsuranceSaved.emit(this.other);
    }
  }

}
