import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ApiResponse, DashboardTotal, Module, ModuleName, OverviewRes } from '../api/types';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { HttpService } from '../core/http.service';
import { BaseService } from '../base/base.service';
import { plainToClass } from 'class-transformer';
import { Workbench } from './workbench-types';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WorkbenchService extends BaseService {

  workbench: Workbench;
  dashboardTotal: DashboardTotal;

  moduleMap: Map<ModuleName, Module> = new Map();

  reloadWorkbench: EventEmitter<any> = new EventEmitter<any>();
  switchLocaleReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpService) {
    super();
  }

  private initModules(data: Workbench): void {
    for (const feature of data.features) {
      for (const module of feature.squares) {
        this.moduleMap.set(module.name, module);
      }
    }
  }

  /**
   * 工作台接口
   */
  getWorkbench(): Observable<Workbench> {
    return this.http.get<ApiResponse<Workbench>>(API.accountV3 + '/workbench', {
      observe: 'response'
    }).pipe(map(data => {
      const workbench = plainToClass(Workbench, data.body.data);

      for (let i = 0; i < workbench.features.length; i++) {
        const features = workbench.features[i];
        features.squares = this.filterModules(features.squares);
        workbench.features[i] = features;
      }

      this.workbench = workbench;
      this.initModules(workbench);
      return workbench;
    }));
  }

  /**
   * 年统计
   */
  getDashboardTotal(): Observable<DashboardTotal> {
    return this.http.get<ApiResponse<DashboardTotal>>(API.dashboardV3 + '/total', {
      observe: 'response'
    }).pipe(map(data => {
      this.dashboardTotal = data.body.data;
      return data.body.data;
    }));
  }

  /**
   * 月统计
   */
  getDashboardOverview(param): Observable<OverviewRes> {
    let httpParams = new HttpParams();
    if (param?.year) {
      httpParams = httpParams.set('year', param?.year?.toString());
    }
    return this.http.get<ApiResponse<OverviewRes>>(API.dashboardV3 + '/overview', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      // this.dashboardTotal = data.body.data;
      return data.body.data;
    }));
  }

  filterModules(modules: Module[]): Module[] {
    return modules === null ? [] : modules.filter(module => {
      return this.moduleVisible(module);
    });
  }

  moduleVisible(module: Module): boolean {
    return module.visible === true /* && (this.moduleDeveloped(module)|| this.moduleComingSoon(module)) */;
  }

  moduleEnabled(name: ModuleName): boolean {
    const module = this.moduleMap.get(name);
    return !(!module || !module.visible || !module.enable);
  }

}
