<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{ 'ScheduleDetail' | translate}}
  </div>
  <div *ngIf="scheduleParams.type == 'POLICY' || scheduleParams.type == 'BOOKING' || scheduleParams.type == 'CONTACT'" class="card-title">
      <span *ngIf="scheduleParams.type == 'POLICY'">
        {{'PolicyNumber' | translate}} # {{scheduleParams.title.split('「')[1].split('」')[0]}}
      </span>
    <span *ngIf="scheduleParams.type == 'BOOKING'">
        {{'BookingNo' | translate}} # {{scheduleParams.title.split('「')[1].split('」')[0]}}
      </span>
    <span *ngIf="scheduleParams.type == 'CONTACT'">
      {{'Contacts' | translate}} # {{scheduleParams.title.split('「')[1].split('」')[0]}}
      </span>
    <a nz-button nzType="link" nzSize="small" appThrottleClick (throttleClick)="onView()">
      {{'View' | translate}} >
    </a>
  </div>
  <div>
    <app-property-input [title]="'ScheduleTitle' | translate"
                        [displayMode]="'vertical'"
                        *ngIf="scheduleParams.type === 'PERSONAL'"
                        [placeholder]="('PleaseSelect' | translate) + ('ScheduleTitle' | translate)"
                        [(value)]="scheduleParams.title"
                        [required]="true"
    ></app-property-input>
    <app-property-text [title]="'ScheduleTitle' | translate"
                       [displayMode]="'vertical'"
                       *ngIf="scheduleParams.type !== 'PERSONAL'"
                       [value]="scheduleParams.title">

    </app-property-text>
    <!-- 生效時間 -->
    <app-property-datetime [title]="'StartTime' | translate"
                           [displayMode]="'vertical'"
                           [required]="true"
                           *ngIf="scheduleParams.type === 'PERSONAL'"
                           [showTime]="{nzFormat: 'HH:mm'}"
                           [formatter]="'yyyy-MM-dd HH:mm'"
                           [(value)]="scheduleParams.startTime"></app-property-datetime>
    <app-property-text [title]="'StartTime' | translate"
                       [displayMode]="'vertical'"
                       *ngIf="scheduleParams.type !== 'PERSONAL'"
                       [value]="scheduleParams.startTime | date: 'yyyy-MM-dd HH:mm'">
    </app-property-text>
    <!-- 失效時間 -->
    <app-property-datetime [title]="'EndTime' | translate"
                           [displayMode]="'vertical'"
                           *ngIf="scheduleParams.type === 'PERSONAL'"
                           [required]="true"
                           [showTime]="{nzFormat: 'HH:mm'}"
                           [formatter]="'yyyy-MM-dd HH:mm'"
                           [(value)]="scheduleParams.endTime"></app-property-datetime>
    <app-property-text [title]="'EndTime' | translate"
                       [displayMode]="'vertical'"
                       *ngIf="scheduleParams.type !== 'PERSONAL'"
                       [value]="scheduleParams.endTime | date: 'yyyy-MM-dd HH:mm'">
    </app-property-text>
    <!-- 地址-->
    <app-property-pick [title]="'ScheduleAddress' | translate"
                       [displayMode]="'vertical'"
                       *ngIf="scheduleParams.type === 'PERSONAL'"
                       appThrottleClick (throttleClick)="onScheduleAddress()"
                       [value]="scheduleParams?.place?.toAddressString()"></app-property-pick>
    <app-property-text [title]="'ScheduleAddress' | translate"
                       [displayMode]="'vertical'"
                       *ngIf="scheduleParams.type !== 'PERSONAL'"
                       [value]="scheduleParams?.place?.toAddressString() || ''">
    </app-property-text>
    <!--日程提醒-->
    <app-property-select [title]="'ScheduleRemind' | translate"
                         [displayMode]="'vertical'"
                         [required]="true"
                         *ngIf="scheduleParams.type === 'PERSONAL'"
                         [options]="typeOptions"
                         [(value)]="scheduleParams.remind">
    </app-property-select>
    <div *ngIf="scheduleParams.type !== 'PERSONAL'" [ngSwitch]="scheduleParams.remind">
      <app-property-text [title]="'ScheduleRemind' | translate"
                         [displayMode]="'vertical'"
                         *ngSwitchCase="'BEGIN'"
                         [value]="'Beginning'| translate">
      </app-property-text>
      <app-property-text [title]="'ScheduleRemind' | translate"
                         [displayMode]="'vertical'"
                         *ngSwitchCase="'FIVE_MINUTE'"
                         [value]="'FiveMinutesAgo'| translate">
      </app-property-text>
      <app-property-text [title]="'ScheduleRemind' | translate"
                         [displayMode]="'vertical'"
                         *ngSwitchCase="'FifteenMinutesAgo'"
                         [value]="'FIFTEEN_MINUTE'| translate">
      </app-property-text>
      <app-property-text [title]="'ScheduleRemind' | translate"
                         [displayMode]="'vertical'"
                         *ngSwitchCase="'ThirtyMinutesAgo'"
                         [value]="'THIRTY_MINUTE'| translate">
      </app-property-text>
      <app-property-text [title]="'ScheduleRemind' | translate"
                         [displayMode]="'vertical'"
                         *ngSwitchCase="'AnHourAgo'"
                         [value]="'ONE_HOUR'| translate">
      </app-property-text>
      <app-property-text [title]="'ScheduleRemind' | translate"
                         [displayMode]="'vertical'"
                         *ngSwitchCase="'DayEarlier'"
                         [value]="'ONE_DAY'| translate">
      </app-property-text>
    </div>

    <!--描述-->
    <app-property-input-area [required]="true"
                             *ngIf="scheduleParams.type === 'PERSONAL'"
                             [title]="'ScheduleDesc' | translate"
                             [placeholder]="('PleaseEnter' | translate) + ('ScheduleDesc' | translate)"
                             [(value)]="scheduleParams.description"></app-property-input-area>
    <app-property-text
      *ngIf="scheduleParams.type !== 'PERSONAL'"
      [displayMode]="'vertical'"
      [title]="'ScheduleDesc' | translate"
      [value]="scheduleParams.description">
    </app-property-text>
  </div>
  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [disabled]="!canReply"
           [style]="!canReply ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onSave()">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
