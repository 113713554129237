import {Component, OnInit} from '@angular/core';
import {ContactInfoRes, ContactListRes, ContactSearch, ContactsType, CreateContactsType} from '../contact-types';
import {ContactService} from '../contact.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {PagedResp} from '../../api/types';
import {ContactInfoComponent} from '../contact-info/contact-info.component';
import {plainToClass} from 'class-transformer';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.less']
})
export class ContactsComponent implements OnInit {

  spinning = false;
  loading = false;
  isVisible = false;
  CurrentID: number;
  search: ContactSearch = new ContactSearch();
  searchPopoverVisible = false;
  contactList: PagedResp<ContactListRes>;

  constructor(private contactService: ContactService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.contactService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.contactList = data;
        },
        error => {
          this.loading = false;
        }
      );
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.contactService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.contactList = data;
        },
        error => {
          this.loading = false;
        });
  }

  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search = new ContactSearch();
    }
    this.contactService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.contactList = data;
        },
        error => {
          this.loading = false;
        });
  }

  onResetSearch(): void {
    this.search = new ContactSearch();
    this.onReload();
  }

  onDetail(val): void {
    this.contactService.detail(val)
      .subscribe(data => {
          const contactInfo = data;
          const drawerRef = this.drawerService.create<ContactInfoComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: ContactInfoComponent,
            nzContentParams: {
              infoID: val,
              contactInfo,
              status: ContactsType.Edit
            }
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.ContactInfoSave.subscribe(
              info => {
                drawerRef.close();
                this.onReload();
              }
            );
          });
        },
        error => {
          console.log(error);
        });
  }

  onDelete(val): void {
    this.isVisible = true;
    // this.handleOk(val);
    this.CurrentID = val;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleOk(): void {
    this.isVisible = false;
    this.loading = true;
    this.contactService.deleteContact(this.CurrentID)
      .subscribe(
        data => {
          console.log(data);
          this.loading = false;
          this.onReload();
        },
        error => {
          this.loading = false;
          console.log(error);
        }
      );
  }

  onContactCreate(): void {
    const info = new ContactListRes();
    const drawerRef = this.drawerService.create<ContactInfoComponent, { value: any }, string>({
      nzWidth: 800,
      nzContent: ContactInfoComponent,
      nzContentParams: {
        contactInfo: plainToClass(ContactInfoRes, info),
        status: ContactsType.Create,
        contactsType: CreateContactsType.CONTACT
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.ContactInfoSummit.subscribe(
        data => {
          drawerRef.close();
          this.onReload();
        }
      );
    });
  }

  onImportContacts(): void {
    this.contactService.importContacts()
      .subscribe(
        data => {
          console.log(data);
        },
        error => {
          console.log(error);
        }
      );
  }
}
