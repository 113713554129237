import {Injectable} from '@angular/core';
import {HttpService} from '../core/http.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ApiResponse, DataMode, PagedResp} from '../api/types';
import {Observable} from 'rxjs/internal/Observable';
import {API} from '../api/api';
import {map} from 'rxjs/operators';
import {BorwseInfo, ConnectionsInfo, InformationData, InformationList, InformationSearch} from './share-type';
import {BaseService} from '../base/base.service';
import {plainToInstance} from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class ShareService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  list(search: InformationSearch): Observable<PagedResp<InformationList>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.shareSources != null) {
      httpParams = httpParams.set('shareSources', search.shareSources.toString());
    }
    if (search.inSystem != null) {
      httpParams = httpParams.set('inSystem', search.inSystem.toString());
    }
    return this.http.get<ApiResponse<PagedResp<InformationList>>>(API.shareV4 + '/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  forwardList(): Observable<InformationList[]> {
    return this.http.get<ApiResponse<InformationList[]>>(API.shareV3 + '/forward-list', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 浏览详情
   * @param shareCode 分享码
   */
  detail(shareCode: string): Observable<BorwseInfo> {
    return this.http.get<ApiResponse<BorwseInfo>>(API.shareV3 + '/browse-info/' + shareCode, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(BorwseInfo, data.body.data);
    }));
  }

  /**
   * 人脉详情
   * @param connectionsId 人脉ID
   */
  connectionsDetail(connectionsId: number): Observable<ConnectionsInfo> {
    return this.http.get<ApiResponse<ConnectionsInfo>>(API.shareV3 + '/connections-info/' + connectionsId, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(ConnectionsInfo, data.body.data);
    }));
  }


  /**
   * 浏览详情
   * @param shareCode 分享码
   */
  shareInfo(shareCode: string): Observable<InformationData> {
    return this.http.get<ApiResponse<InformationData>>(API.shareV3 + '/info/' + shareCode, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(InformationData, data.body.data);
    }));
  }
}
