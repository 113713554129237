import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PropertyDisplayMode} from '../../shared';

@Component({
  selector: 'app-property-input',
  templateUrl: './property-input.component.html',
  styleUrls: ['./property-input.component.less']
})
export class PropertyInputComponent implements OnInit {

  @Input() displayMode: PropertyDisplayMode = 'horizontal';
  @Input() title: string;
  @Input() placeholder: string;
  @Input() tip: string;
  @Input() tipColor = ''; // 提示文字颜色
  @Input() tipVisible = false;
  @Input() borderless = true;
  @Input() disabled = false;
  @Input() required = false;
  @Input() bottomLineVisible = true;
  @Input() isConvert = false;
  @Input() isPinYin = false;
  @Input() titleColor: string;

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  value: any;
  innerValue: any;

  // @Input()
  // get value(): any {
  //   return this.innerValue;
  // }
  //
  // set value(val: any) {
  //   this.innerValue = val;
  //   this.valueChange.emit(this.innerValue);
  // }

  constructor() {
  }

  ngOnInit(): void {
  }

  inputChange(val: any): void {
    if (this.isPinYin) {
      this.value = val.replace(/[^a-zA-Z]/g, '');
    } else {
      this.value = val;
    }
    this.innerValue = this.value;
    this.valueChange.emit(this.innerValue);
  }
}
