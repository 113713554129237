import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesComponent } from './sales.component';
import { RouterModule } from '@angular/router';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { SharedModule } from '../shared/shared.module';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTagModule } from 'ng-zorro-antd/tag';
import {QRCodeModule} from 'angular2-qrcode';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import { ProposalAccountListComponent } from './component/proposal-account-list/proposal-account-list.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormsModule } from '@angular/forms';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzModalModule } from 'ng-zorro-antd/modal';


@NgModule({
  declarations: [
    SalesComponent,
    ProposalAccountListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SharedModule,
    NzPopoverModule,
    NzButtonModule,
    NzIconModule,
    NzMenuModule,
    NzAffixModule,
    NzToolTipModule,
    NzAvatarModule,
    NzListModule,
    NzTypographyModule,
    NzGridModule,
    NzBadgeModule,
    NzTagModule,
    QRCodeModule,
    NzDividerModule,
    NzSpinModule,
    NzSwitchModule,
    FormsModule,
    NzPopconfirmModule,
    NzInputModule,
    NzBreadCrumbModule,
    NzModalModule,
  ]
})
export class SalesModule {
}
