import { Component, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { ProductCompany } from '../product-types';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.less']
})
export class CompanyDetailComponent implements OnInit {

  drawerRef: NzDrawerRef<CompanyDetailComponent, string>;

  company: ProductCompany;

  constructor() {
  }

  ngOnInit(): void {
  }

}
