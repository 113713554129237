import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookingVip } from '../../../api/types';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { MetadataService } from '../../../shared/service/metadata.service';

@Component({
  selector: 'app-booking-vip-edit-v2',
  templateUrl: './booking-vip-edit-v2.component.html',
  styleUrls: ['./booking-vip-edit-v2.component.less']
})
export class BookingVipEditV2Component implements OnInit, AfterViewChecked {

  @Input() vip: BookingVip; // VIP客戶

  @Input()
  isShow = true;
  @Input()
  disabled = false;

  @Output()
  vipChange: EventEmitter<BookingVip> = new EventEmitter<BookingVip>();

  transportDemandOptions: PropertySelectOption[] = [];

  constructor(private metadataService: MetadataService) {
  }

  ngOnInit(): void {
    const transportDemands = this.metadataService.values('transportDemand');
    this.transportDemandOptions = [];
    for (const transportDemand of transportDemands) {
      this.transportDemandOptions.push(new PropertySelectOption(transportDemand.value, transportDemand.key));
    }
    if (!this.vip.transportDemand && this.transportDemandOptions.length > 0) {
      this.vip.transportDemand = this.transportDemandOptions[0].value;
    }
  }

  ngAfterViewChecked(): void {
    this.vipChange.emit(this.vip);
  }

}
