<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{'ProposalCoverSelection' | translate}}
  </div>

  <div>
    <cdk-virtual-scroll-viewport itemSize="140" class="demo-infinite-container">
      <nz-list>
        <nz-list-item *cdkVirtualFor="let item of dataSource">
          <nz-empty *ngIf="!item" nzNotFoundImage="simple"></nz-empty>
          <div *ngIf="item" style="flex: 1; display: flex;justify-content: space-evenly;align-items: center;">
            <div style="position: relative;">
              <img
                nz-image
                height="200px"
                [nzSrc]="item.url"
                style="border-radius: 12px;cursor: pointer;"
                alt=""
              />
              <div class="cover-text">{{item.name}}</div>
            </div>
            <button nz-button nzType="primary"
                    appThrottleClick (throttleClick)="onConfirm(item)"
                    style="border-radius: 4px;">{{'Confirm' | translate}}</button>

          </div>
        </nz-list-item>
      </nz-list>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
