<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'E-Signature' | translate}}
    </div>

    <!--    <div class="section-wrapper" style="">-->
    <!--      <app-property-pick [title]="'ViewFna' | translate"-->
    <!--                         appThrottleClick (throttleClick)="onViewFna()"-->
    <!--                         [bottomLineVisible]="false"></app-property-pick>-->
    <!--    </div>-->

    <div *ngFor="let signature of signatureInformation">
      <div class="section-wrapper" style="">
        <div [ngSwitch]="signature.signatureType">
          <div *ngSwitchCase="'SALES'">
            <app-property-pick appThrottleClick (throttleClick)="onConsultantSignature(signature)"
                               [title]="'ConsultantSignature' | translate"
                               [valueColor]="signature.signatureStatus === 'SIGNED' ? '#1D64C4' : '#e63757'"
                               [value]="signature.signatureStatus | metadataTranslate:'fnaSignedStatus'"
                               ></app-property-pick>
            <app-property-text [bottomLineVisible]="false"
                               [title]="'SignatureTime' | translate"
                               [value]="signature.signatureTime | date:'yyyy-MM-dd'"></app-property-text>
          </div>
          <div *ngSwitchCase="'OWNER'">
            <app-property-pick
              (throttleClick)="onConsultantSignature(signature)"
              [title]="'ClientSignature' | translate"
              [valueColor]="signature.signatureStatus === 'SIGNED' ? '#1D64C4' : '#e63757'"
              [value]="signature.signatureStatus | metadataTranslate:'fnaSignedStatus'"
              appThrottleClick></app-property-pick>

            <app-property-text [bottomLineVisible]="false"
                               [title]="'SignatureTime' | translate"
                               [value]="signature.signatureTime | date:'yyyy-MM-dd'"></app-property-text>
          </div>

          <div *ngSwitchCase="'EYEWITNESS'">
            <app-property-pick (throttleClick)="onConsultantSignature(signature)"
                               [title]="'WitnessSignature' | translate"
                               [valueColor]="signature.signatureStatus === 'SIGNED' ? '#1D64C4' : '#e63757'"
                               [value]="signature.signatureStatus | metadataTranslate:'fnaSignedStatus'"
                               appThrottleClick
            ></app-property-pick>

            <app-property-text [bottomLineVisible]="false"
                               [title]="'SignatureTime' | translate"
                               [value]="signature.signatureTime | date:'yyyy-MM-dd'"></app-property-text>
          </div>

        </div>
      </div>
    </div>
    <div class="section-wrapper" style="">
      <app-property-text [bottomLineVisible]="false"
                         [title]="'Status' | translate"
                         [valueColor]="fna.signatureStatus === 'SIGNED' ? '#1D64C4' : '#e63757'"
                         [value]="fna.signatureStatus | metadataTranslate:'fnaSignedStatus'"></app-property-text>
    </div>
  </nz-spin>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div *ngIf="fna.signatureStatus === 'UNSIGNED'" class="footer-button-primary" nz-col nzSpan="24">
        <a (throttleClick)="onSubmit()" [disabled]="!canSubmit" [style]="!canSubmit ? 'opacity: 0.5' : ''" appThrottleClick nz-button
           nzBlock nzSize="large"
           nzType="link">
          <span>{{'SubmitSignature' | translate}}</span>
        </a>
      </div>
      <div *ngIf="fna.signatureStatus === 'SIGNED'" class="footer-button-primary" nz-col nzSpan="24">
        <a (throttleClick)="onResign(tplTitle, tplContent, tplFooter)" [disabled]="fna.signatureStatus === 'UNSIGNED'" [style]="fna.signatureStatus === 'UNSIGNED' ? 'opacity: 0.5' : ''" appThrottleClick nz-button
           nzBlock nzSize="large"
           nzType="link">
          <span>{{'Re-Sign' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>

<ng-template #tplTitle>
  <span>{{'Re-Sign' | translate}}</span>
</ng-template>
<ng-template #tplContent let-params>
  <p>{{'DoYouWantToReSignFna' | translate}}?</p>
</ng-template>
<ng-template #tplFooter let-ref="modalRef">
  <button (throttleClick)="ref.destroy()" [disabled]="loading" appThrottleClick
          nz-button>{{'Cancel' | translate}}</button>
  <button (throttleClick)="onReSignConfirm(ref)" [nzLoading]="loading" appThrottleClick nz-button nzType="primary">
    {{'Re-Sign' | translate}}
  </button>
</ng-template>
