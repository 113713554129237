import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { AuthService } from '../auth.service';
import { Instance, InstanceAccount, LoginByAccountReq, Passport } from '../../api/types';
import { ToastrService } from 'ngx-toastr';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { AccountService } from '../../account/account.service';

@Component({
  selector: 'app-account-add',
  templateUrl: './account-add.component.html',
  styleUrls: ['./account-add.component.less']
})
export class AccountAddComponent implements OnInit, AfterViewInit {

  drawerRef: NzDrawerRef<AccountAddComponent, string>;
  index = 0;

  instance: Instance = new Instance();
  passport: Passport;

  code = '';
  instanceChecking = false;

  loginReq: LoginByAccountReq = {accountName: '', password: ''};

  logging = false;

  @ViewChild('disclaimerTitle')
  disclaimerTitle: TemplateRef<{}>;

  @ViewChild('disclaimerContent')
  disclaimerContent: TemplateRef<{}>;

  @ViewChild('disclaimerFooter')
  disclaimerFooter: TemplateRef<{}>;

  @ViewChild('agreeButton')
  agreeButton: TemplateRef<any>;
  passwordVisible = false;
  isBack = true;

  constructor(private authService: AuthService,
              private accountService: AccountService,
              private toastr: ToastrService,
              private modal: NzModalService,
              private router: Router) { }

  ngOnInit(): void {
    if (window.location.href.includes('sales.hkbaylight.com')) {
      this.isBack = false;
      this.index = 1;
      this.code = 'BIB';
      this.checkInstance();
    }
  }

  ngAfterViewInit(): void {
    // this.authService
    //   .disclaimer()
    //   .subscribe(
    //     data => {
    //       this.createDisclaimerModal(data, this.disclaimerTitle, this.disclaimerContent, this.disclaimerFooter);
    //     },
    //     () => {
    //     });
  }

  onIndexChange(event: number): void {
    this.index = event;
  }

  checkInstance(): void {
    this.instanceChecking = true;
    this.authService.checkInstance(this.code)
      .subscribe(
        data => {
          this.instanceChecking = false;
          this.instance = data;
          this.index = 1;
        },
        error => {
          // 组织代码错误，提示错误
          this.instanceChecking = false;
        });
  }

  loginByAccount(): void {
    this.logging = true;
    this.authService
      .loginByAccountAndInstance(this.loginReq, this.instance, this.passport)
      .subscribe(
        data => {
          this.logging = false;
          this.onLoginSuccess(data);
        },
        () => {
          this.logging = false;
        });
  }

  onLoginSuccess(passport: Passport): void {
    this.passport = passport;

    this.authService.instance = this.instance;
    this.authService.passport = passport;

    // this.accountService.addAccount()
    window.location.reload();

    const account = new InstanceAccount();
    account.instance = this.instance;
    account.passport = passport;
    this.accountService.addAccount(account);
  }

  createDisclaimerModal(disclaimerText: string, tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>): void {
    const ref = this.modal.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: {
        value: disclaimerText
      }
    });
    ref.afterOpen.subscribe(() => {
        this.agreeButton.elementRef.nativeElement.focus();
      }
    );
  }

  onDisagree(modelRef: NzModalRef): void {
    modelRef.destroy();
  }

  onAgree(modelRef: NzModalRef): void {
    modelRef.destroy();
  }

}
