import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Material,
  PendingDetailReply,
  PendingReply,
  PendingDetail,
  TimeLine,
  UploadTokenReq,
  Instance, DataMode, DataDesensitization
} from '../../../api/types';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { WorkbenchService } from '../../../workbench/workbench.service';
import { AccountService } from '../../../account/account.service';
import { Workbench } from '../../../workbench/workbench-types';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.less']
})
export class TimeLineComponent implements OnInit {
  @Output()
  pendingChange: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  underPendingChange: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  pendingReplyChange: EventEmitter<PendingDetailReply> = new EventEmitter<PendingDetailReply>();
  @Output()
  underwritingPendingReply: EventEmitter<PendingDetailReply> = new EventEmitter<PendingDetailReply>();
  @Input()
  timeLineVOS: TimeLine[] = [];
  @Input()
  pendings: any [] = [];
  @Input()
  type: string;   // timeLine-状态, pending-事项
  @Input()
  pendingDetail: PendingDetail = new PendingDetail();
  @Input()
  uploadTokenReq: UploadTokenReq;
  @Input()
  pendingReply: PendingReply[] = [];
  @Input()
  showAttachments = true;
  @Input()
  mode = DataMode.OWN;
  pendingDetailReply: PendingDetailReply = new PendingDetailReply();
  visible = false;
  statusDetail: TimeLine;
  pendingVisible = false;
  pendingId: number;
  detailVisible = false;
  workbench: Workbench;
  accountName: string;
  instance: Instance;
  underwritingReplyData: any;
  @Input()
  modeType: string;
  detailType: string;
  dataDesensitization: DataDesensitization = new DataDesensitization();

  constructor(private drawerService: NzDrawerService,
              private workbenchService: WorkbenchService,
              private accountService: AccountService) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.accountName = this.accountService.passport.accountName;
    this.instance = this.workbenchService.mockInstance;
    this.dataDesensitization = this.accountService.dataDesensitization;
  }

  openStatus(row: TimeLine): void {
    this.visible = true;
    this.statusDetail = row;
  }

  close(): void {
    // this.statusDetail = new TimeLine();
    this.visible = false;
  }


  onPendingDetail(item: any, type: any): void {
    console.log(item, type);
    this.pendingId = item.id;
    this.detailType = type;
    if (type === 'SERVICE') {
      this.pendingDetailReply.serviceId = item.id;
    } else {
      this.pendingDetailReply.pendingId = item.id;
    }
    this.underwritingReplyData = item;
    this.pendingDetail = new PendingDetail();
    console.log(item, type);
    this.pendingReply = [];
    if (type && type === 'PENDING' && this.modeType === 'policy') {
      this.underPendingChange.emit(item.id);
    } else {
      this.pendingChange.emit(item.id);
    }
    this.pendingVisible = true;
  }

  pendClose(): void {
    this.pendingVisible = false;
  }

  pendingAttachmentsChange($event: Material[]): void {
    this.pendingDetailReply.attachments = $event;
  }

  get canReply(): boolean {
    return !!this.pendingDetailReply.content;
  }

  reply(): void {
    // this.pendingDetailReply.pendingId = this.pendingReply;
    console.log(this.pendingDetailReply);
    if (this.pendingDetailReply.serviceId) {
      this.pendingReplyChange.emit(this.pendingDetailReply);
    } else {
      const pending = new PendingDetailReply();
      pending.attachments = this.pendingDetailReply.attachments;
      pending.content = this.pendingDetailReply.content;
      pending.pendingId = this.pendingDetailReply.pendingId;
      pending.underwritingNo = this.underwritingReplyData.underwritingNo;
      this.underwritingPendingReply.emit(pending);
    }
  }

  reast(): void {
    this.pendingChange.emit(this.pendingId);
    this.pendingDetailReply = new PendingDetailReply();
    if (this.detailType === 'SERVICE') {
      this.pendingDetailReply.serviceId = this.pendingId;
    } else {
      this.pendingDetailReply.pendingId = this.pendingId;
    }
  }

  detailOpen(): void {
    this.detailVisible = true;
  }

  detailClose(): void {
    this.detailVisible = false;
  }

  isDynamicEncryption(keyName): boolean {
    let isShow = false;
    if (!this.dataDesensitization || !this.mode) {
      return isShow;
    }
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    }
  }
}
