<div [ngSwitch]="displayMode">
  <div *ngSwitchCase="'horizontal'">
    <div [class]="disabled ? 'property-row property-disable' : 'property-row'">
      <div class="property-title-row" [style]="titleColor ? ('color:' + titleColor) : ''" *ngIf="title">{{ title }}</div>
      <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
      <div class="property-value-row">
        <nz-input-number style="width: 100%;"
                         [(ngModel)]="value" [disabled]="disabled"
                         [nzMax]="max" [nzMin]="min"
                         [nzPlaceHolder]="placeholder ?? ''"></nz-input-number>
      </div>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>
  <div *ngSwitchCase="'vertical'">
    <div [class]="disabled ? 'property-column property-disable' : 'property-column'">
      <div class="property-title-column">
        <span *ngIf="title" [style]="titleColor ? ('color:' + titleColor) : ''">{{ title }}</span>
        <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
      </div>
      <div class="property-value-column">
        <nz-input-number style="width: 100%; text-align: left;"
                         [(ngModel)]="value" [disabled]="disabled"
                         [nzMax]="max" [nzMin]="min"
                         [nzPlaceHolder]="placeholder ?? ''"></nz-input-number>
      </div>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>
</div>
