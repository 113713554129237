<div class="drawer-container">
  <div class="common-title">
    {{'ProductSelect' | translate}}
  </div>
  <nz-row [nzGutter]="12">
    <nz-col [nzSpan]="24">
      <app-property-input [displayMode]="'vertical'"
                           [title]="'ProductName' | translate"
                           [placeholder]="('PleaseEnter' | translate) + ('ProductName' | translate)"
                           [(value)]="search.productName"
                           (valueChange)="onSearchParamChange()"></app-property-input>
    </nz-col>
  </nz-row>
  <nz-row [nzGutter]="12">
    <nz-col [nzSpan]="12">
      <app-property-select [displayMode]="'vertical'"
                           [title]="'ProductCategory' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('ProductCategory' | translate)"
                           [(value)]="search.categoryCode"
                           [options]="categoryOptions"
                           [disabled]="isNewFna"
                           (valueChange)="onSearchParamChange()"></app-property-select>
    </nz-col>
    <nz-col [nzSpan]="12">
      <app-property-select [displayMode]="'vertical'"
                           [title]="'Insurer' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Insurer' | translate)"
                           [(value)]="search.companyCode"
                           [supportSearch]="true"
                           [options]="companyOptions"
                           [disabled]="isNewFna"
                           (valueChange)="onSearchParamChange()"></app-property-select>
    </nz-col>
  </nz-row>
  <div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
      <div *ngIf="!products">
        <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
      </div>
      <div *ngIf="products" style="margin-top: 30px; ">
        <nz-table [nzBordered]="false"
                  [nzOuterBordered]="true"
                  [nzShowPagination]="true"
                  [nzLoading]="loading"
                  [nzLoadingIndicator]="indicatorTemplate"
                  [nzFrontPagination]="false"
                  [nzData]="products.list"
                  [nzPageIndex]="products.pageNum"
                  [nzPageSize]="products.pageSize"
                  [nzTotal]="products.total"
                  [nzTitle]="tableHeader"
                  [nzFooter]="tableFooter"
                  (nzPageIndexChange)="onSearch($event)">
          <ng-template #tableHeader>
            <nz-row>
              <nz-col [nzSpan]="12">
                <div class="list-header-title">{{'ProductList' | translate}}
                  <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                     style="margin-left: 5px"
                     appThrottleClick (throttleClick)="onReload()">
                    <em nz-icon nzType="reload" nzTheme="outline"></em>
                  </a>
                </div>
              </nz-col>
              <nz-col [nzSpan]="12">
                <div style="width: 100%; display: flex; justify-content: flex-end;">
                </div>
              </nz-col>
            </nz-row>
          </ng-template>
          <ng-template #tableFooter>
            <div class="table-title">
              {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ products.total }}</span>
            </div>
          </ng-template>

          <thead>
          <tr>
            <th><span nz-tooltip
                      [nzTooltipTitle]="'ProductCategory' | translate">{{'ProductCategory' | translate}}</span></th>
            <th><span nz-tooltip [nzTooltipTitle]="'Insurer' | translate">{{'Insurer' | translate}}</span></th>
            <th><span nz-tooltip [nzTooltipTitle]="'ProductName' | translate">{{'ProductName' | translate}}</span></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let product of products.list; let i = index" appThrottleClick (throttleClick)="onProductSelected(product.productCode, i, product)">
            <td>
              <img width="20" style="border-radius: 3px; margin-right: 5px" [src]="product.logo" alt=""/>
              {{ product.categoryName }}
            </td>
            <td>
              {{ product.companyName }}
              <nz-tag *ngIf="product.region" style="margin-left: 5px">{{ product.region }}</nz-tag>
            </td>
            <td>
              <div style="display: flex; align-items: center;">
                <img *ngIf="product.proposalRPASupported && type === 'PROPOSAL'" alt="" style="margin: 0 10px 0 0px" width="16"
                     src="../../../../assets/images/ic_robot.svg">
                <div [ngSwitch]="product.sellStatus">
                  <nz-tag *ngSwitchCase="'DISCONTINUED'" [nzColor]="'red'">{{ product.sellStatus | metadataTranslate: 'sellStatus' }}</nz-tag>
                  <nz-tag *ngSwitchCase="'SELLING'" [nzColor]="'green'">{{ product.sellStatus | metadataTranslate: 'sellStatus' }}</nz-tag>
                </div>
                <span>{{ product.productName }}</span>
              </div>
            </td>
          </tr>
          </tbody>
        </nz-table>
      </div>
    </nz-spin>
  </div>
</div>
