<div class="drawer-container" [style]="status == 'Edit' && !isDisabled() ? 'padding: 32px 32px 170px;' : ''">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'ContactsInfo' | translate}}
    </div>
    <div *ngIf="sourceType == 'PRE_SALES'" class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'BusinessSource' | translate}}</span>
    </div>
    <div *ngIf="sourceType == 'PRE_SALES'" class="section-wrapper">
      <app-property-select [title]="'BusinessSource' | translate"
                           [disabled]="isDisabled()"
                           [placeholder]="('PleaseSelect' | translate) + ('BusinessSource' | translate)"
                           [options]="businessSourceOptions"
                           [bottomLineVisible]="false"
                           [(value)]="contactInfo.businessSource"></app-property-select>
    </div>
    <div class="section-title" style="margin-top: 20px;display: flex;justify-content: space-between;align-items: center">
      <span style="margin-left: 8px">{{'PersonalBasicInfo' | translate}}</span>
      <span *ngIf="status === 'Create'" appThrottleClick (throttleClick)="importVisible = true">
        <a nz-button nzType="link">{{'importContacts' | translate}}</a>
      </span>
    </div>
    <div class="section-wrapper">
      <!-- 头像 -->
      <div>
        <div style="padding: 5px 0; display: flex; justify-content: space-between; align-items: center;">
          <span style="color: #999">{{'HeadPortrait' | translate }}</span>
          <nz-avatar [nzSize]="40" [nzSrc]="contactInfo.avatar"
                     appThrottleClick (throttleClick)="onUploaderImg()"></nz-avatar>
          <input #fileUpload hidden=true type="file" ng2FileSelect [uploader]="uploader"
                 (change)="selectedAvatarChanged($event)" accept=".png, .jpeg, .jpg" multiple/>
        </div>
        <div class="property-bottom-line"></div>
      </div>
      <!-- 姓氏 -->
      <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LastName' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('LastName' | translate)"
                          [required]="true"
                          [disabled]="isInputName || isDisabled()"
                          [(value)]="contactInfo.lastName"></app-property-input>
      <!-- 名字 -->
      <app-property-input [title]="'FirstName' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('FirstName' | translate)"
                          [required]="true"
                          [disabled]="isDisabled()"
                          [(value)]="contactInfo.firstName"></app-property-input>
      <!-- 姓氏-英文 -->
      <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LastNameInEnglish' | translate"
                          [disabled]="isInputName || isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('LastNameInEnglish' | translate)"
                          [(value)]="contactInfo.lastNamePinyin"></app-property-input>
      <!-- 名字-英文 -->
      <app-property-input [title]="'FirstNameInEnglish' | translate"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('FirstNameInEnglish' | translate)"
                          [(value)]="contactInfo.firstNamePinyin"></app-property-input>
      <!-- 性别 -->
      <app-property-select [title]="'Gender' | translate"
                           [disabled]="isDisabled()"
                           [placeholder]="('PleaseSelect' | translate) + ('Gender' | translate)"
                           [options]="genderOptions"
                           [required]="true"
                           [(value)]="contactInfo.gender"></app-property-select>
      <!-- 身份證號 -->
      <app-property-input [title]="'IdentityCardNumber' | translate"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('IdentityCardNumber' | translate)"
                          [(value)]="contactInfo.idCard"></app-property-input>
      <!-- 生日 -->
<!--      <app-property-datetime [title]="'DateOfBirth' | translate"-->
<!--                             [disabled]="isDisabled()"-->
<!--                             [placeholder]="('PleaseSelect' | translate) + ('DateOfBirth' | translate)"-->
<!--                             (valueChange)="onBirthdayChange($event)"-->
<!--                             [(value)]="contactInfo.birthday"></app-property-datetime>-->

      <app-property-datetime-string [title]="'DateOfBirth' | translate"
                                    [displayMode]="'horizontal'"
                                    [placeholder]="('PleaseSelect' | translate) + ('DateOfBirth' | translate)"
                                    [disabled]="isDisabled()"
                                    [formatter]="'yyyy-MM-dd'"
                                    [showTime]="null"
                                    (valueChange)="onBirthdayChange($event)"
                                    [(value)]="contactInfo.birthdate">
      </app-property-datetime-string>
      <!-- 年龄 -->
      <app-property-input-number [title]="'Age' | translate"
                                 [disabled]="isDisabled()"
                                 [placeholder]="('PleaseSelect' | translate) + ('Age' | translate)"
                                 [(value)]="contactInfo.age"></app-property-input-number>
      <!-- 是否吸烟 -->
      <app-property-select [title]="'SmokeCondition' | translate"
                           [disabled]="isDisabled()"
                           [placeholder]="('PleaseSelect' | translate) + ('SmokeCondition' | translate)"
                           [options]="smokeOptions"
                           [(value)]="contactInfo.smoke"></app-property-select>
      <!-- 吸烟量 -->
      <app-property-input *ngIf="contactInfo.smoke === 'S'" [title]="'SmokingVolume' | translate"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('SmokingVolume' | translate)"
                          [(value)]="contactInfo.smokeQuantity"></app-property-input>
      <!-- 国籍（国家地区） -->
      <app-property-pick [displayMode]="'horizontal'"
                         [title]="'Country' | translate"
                         [required]="true"
                         appThrottleClick (throttleClick)="onCountry()"
                         [value]="countryString"></app-property-pick>
<!--      <app-property-select [title]="'Country' | translate"-->
<!--                           [placeholder]="('PleaseSelect' | translate) + ('Country' | translate)"-->
<!--                           [options]="regionOptions"-->
<!--                           [(value)]="contactInfo.country"></app-property-select>-->
      <!-- 其他国家身份 -->
      <app-property-select [title]="'OtherNationalID' | translate"
                           [disabled]="isDisabled()"
                           [placeholder]="('PleaseSelect' | translate) + ('OtherNationalID' | translate)"
                           [options]="longtimeAbroadOptions"
                           [(value)]="contactInfo.citizenship"></app-property-select>
      <!-- 居住地址 -->
      <app-property-pick [title]="'ResidentialAddress' | translate"
                         [valueCursor]="isDisabled() ? 'no-drop' : 'auto'"
                         appThrottleClick (throttleClick)="onAddress()"
                         [value]="contactInfo.address?.toAddressString()"></app-property-pick>
      <!-- 戶籍地址 -->
      <app-property-pick *ngIf="!instance.isFoneOrDev()"
                         [title]="'LegalAddress' | translate"
                         [valueCursor]="isDisabled() ? 'no-drop' : 'auto'"
                         appThrottleClick (throttleClick)="onResidenceAddress()"
                         [value]="contactInfo.residenceAddress?.toAddressString()"></app-property-pick>
      <!-- 通訊地址 -->
      <app-property-pick [title]="'PostalAddress' | translate"
                         appThrottleClick (throttleClick)="onPostalAddress()"
                         [valueCursor]="isDisabled() ? 'no-drop' : 'auto'"
                         [value]="contactInfo.postalAddress?.toAddressString()"></app-property-pick>
      <!-- 手机号码 -->
      <app-property-pick [title]="'MobileNumber' | translate"
                         appThrottleClick (throttleClick)="onMobileNumber()"
                         [valueCursor]="isDisabled() ? 'no-drop' : 'auto'"
                         [value]="contactInfo.phoneString()"></app-property-pick>
      <!-- 固定電話 -->
      <app-property-pick [title]="'ResidentialPhoneNumber' | translate"
                         appThrottleClick (throttleClick)="onResidentialPhoneNumber()"
                         [valueCursor]="isDisabled() ? 'no-drop' : 'auto'"
                         [value]="contactInfo.residentialPhoneNumberString()"></app-property-pick>
      <!-- 其他電話 -->
      <app-property-pick [title]="'OtherPhones' | translate"
                         [valueCursor]="isDisabled() ? 'no-drop' : 'auto'"
                         appThrottleClick (throttleClick)="onOtherPhones()"></app-property-pick>
      <!-- 郵箱 -->
      <app-property-input [title]="'Email' | translate"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('Email' | translate)"
                          [(value)]="contactInfo.email"></app-property-input>
      <!--VIP-->
      <app-property-select [title]="'VIP'"
                           [disabled]="isDisabled()"
                           [placeholder]="('PleaseSelect' | translate) + 'VIP'"
                           [options]="longtimeAbroadOptions"
                           [(value)]="contactInfo.vip.vip"></app-property-select>
      <!-- VIP备注 -->
      <app-property-input-area *ngIf="contactInfo.vip.vip" [title]="'Remark' | translate"
                               [disabled]="isDisabled()"
                               [placeholder]="('PleaseEnter' | translate) + ('Remark' | translate)"
                               [(value)]="contactInfo.vip.remark"></app-property-input-area>
      <!-- 微信号 -->
      <app-property-input [title]="'WechatID' | translate"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('WechatID' | translate)"
                          [(value)]="contactInfo.wechat"></app-property-input>
      <!-- Facebook -->
      <app-property-input [title]="'Facebook'"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('Facebook' | translate)"
                          [(value)]="contactInfo.facebook"></app-property-input>
      <!-- Whatsapp -->
      <app-property-input [title]="'Whatsapp'"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('Whatsapp' | translate)"
                          [(value)]="contactInfo.whatsapp"
                          [bottomLineVisible]="false"></app-property-input>
    </div>

    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'PersonalDetailInfo' | translate}}</span>
    </div>
    <div class="section-wrapper">
      <!-- 稱謂 -->
      <app-property-select [title]="'Title' | translate"
                           [disabled]="isDisabled()"
                           [placeholder]="('PleaseSelect' | translate) + ('Title' | translate)"
                           [options]="titleOptions"
                           [(value)]="contactInfo.title"></app-property-select>
      <!-- 出生地（省市） -->
      <app-property-input [title]="'Birthplace' | translate"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('Birthplace' | translate)"
                          [(value)]="contactInfo.birthplace"></app-property-input>
      <!-- 护照 -->
      <app-property-input [title]="'PassportNo' | translate"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('PassportNo' | translate)"
                          [(value)]="contactInfo.passport"></app-property-input>
      <!-- 港澳通行证 -->
      <app-property-input [title]="'HongKongPass' | translate"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('HongKongPass' | translate)"
                          [(value)]="contactInfo.eepCode"></app-property-input>
      <!-- 身高 -->
      <!--      <app-property-input [title]="'Height' | translate"-->
      <!--                          [placeholder]="('PleaseEnter' | translate) + ('Height' | translate)"-->
      <!--                          [(value)]="contactInfo.height"></app-property-input>-->
      <app-property-input-currency [title]="'Height' | translate"
                                   [disabled]="isDisabled()"
                                   [placeholder]="('PleaseEnter' | translate) + ('Height' | translate)"
                                   [(value)]="contactInfo.height"
                                   nz-tooltip [nzTooltipTitle]="contactHeight"
                                   nzTooltipPlacement="topRight"></app-property-input-currency>
      <ng-template #contactHeight><div>cm</div></ng-template>
      <!-- 体重 -->
      <!--    <app-property-input [title]="'Weight' | translate"-->
      <!--                        [placeholder]="('PleaseEnter' | translate) + ('Weight' | translate)"-->
      <!--                        [(value)]="contactInfo.weight"></app-property-input>-->
      <app-property-input-currency [title]="'Weight' | translate"
                                   [disabled]="isDisabled()"
                                   [placeholder]="('PleaseEnter' | translate) + ('Weight' | translate)"
                                   [(value)]="contactInfo.weight"
                                   nz-tooltip [nzTooltipTitle]="contactWeight"
                                   nzTooltipPlacement="topRight"></app-property-input-currency>
      <ng-template #contactWeight><div>kg</div></ng-template>
      <!-- 平均每月支出 -->
      <app-property-input-number [title]="'AverageMonthlyExpenses' | translate"
                                 [disabled]="isDisabled()"
                                 [placeholder]="('PleaseEnter' | translate) + ('AverageMonthlyExpenses' | translate)"
                                 [(value)]="contactInfo.averageMonthlyExpenses"></app-property-input-number>
      <!-- 平均每月收入 -->
      <app-property-input-number [title]="'AverageMonthlyIncome' | translate"
                                 [disabled]="isDisabled()"
                                 [placeholder]="('PleaseEnter' | translate) + ('AverageMonthlyIncome' | translate)"
                                 [(value)]="contactInfo.averageMonthlyIncome"></app-property-input-number>
      <!-- 总流动资产 -->
      <app-property-input-number [title]="'TotalLiquidAssets' | translate"
                                 [disabled]="isDisabled()"
                                 [placeholder]="('PleaseEnter' | translate) + ('TotalLiquidAssets' | translate)"
                                 [(value)]="contactInfo.totalLiquidAssets"></app-property-input-number>
      <!-- 总债务 -->
      <app-property-input-number [title]="'TotalLiabilities' | translate"
                                 [disabled]="isDisabled()"
                                 [placeholder]="('PleaseEnter' | translate) + ('TotalLiabilities' | translate)"
                                 [(value)]="contactInfo.totalLiabilities"></app-property-input-number>
      <!-- 受供养人数目 -->
      <app-property-input-number [title]="'NoOfDependents' | translate"
                                 [disabled]="isDisabled()"
                                 [placeholder]="('PleaseEnter' | translate) + ('NoOfDependents' | translate)"
                                 [(value)]="contactInfo.noOfDependents"></app-property-input-number>
      <!-- 風險偏好 -->
      <app-property-select [title]="'RiskAppetite' | translate"
                           [disabled]="isDisabled()"
                           [placeholder]="('PleaseSelect' | translate) + ('RiskAppetite' | translate)"
                           [options]="riskAppetiteOptions"
                           [(value)]="contactInfo.riskAppetite"></app-property-select>
      <!-- 婚姻状态 -->
      <app-property-select [title]="'Marriage' | translate"
                           [disabled]="isDisabled()"
                           [placeholder]="('PleaseSelect' | translate) + ('Marriage' | translate)"
                           [options]="marriageOptions"
                           [(value)]="contactInfo.marriage"></app-property-select>
      <!-- 教育程度 -->
      <app-property-select [title]="'Education' | translate"
                           [disabled]="isDisabled()"
                           [placeholder]="('PleaseSelect' | translate) + ('Education' | translate)"
                           [options]="educationOptions"
                           [(value)]="contactInfo.education"></app-property-select>
      <!-- 過往一年是否所屬國家以外地區居住超過182日 -->
      <app-property-select [title]="'LongtimeAbroad' | translate"
                           [disabled]="isDisabled()"
                           [placeholder]="('PleaseSelect' | translate) + ('LongtimeAbroad' | translate)"
                           [options]="longtimeAbroadOptions"
                           [(value)]="contactInfo.longtimeAbroad"></app-property-select>
      <!-- 旅行 -->
      <app-property-input [title]="'Travel' | translate"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('Travel' | translate)"
                          [(value)]="contactInfo.travel"></app-property-input>
      <!-- 運動 -->
      <app-property-select *ngIf="!instance.isFoneOrDev()" [title]="'Sports' | translate"
                           [disabled]="isDisabled()"
                           [placeholder]="('PleaseSelect' | translate) + ('Sports' | translate)"
                           [mode]="'multiple'"
                           [options]="sportsOptions"
                           [(value)]="contactInfo.sports"></app-property-select>
      <!-- 運動（其他） -->
      <app-property-input *ngIf="(!instance.isFoneOrDev() && containsOther(contactInfo.sports))"
                          [title]="'SportsOther' | translate"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('SportsOther' | translate)"
                          [(value)]="contactInfo.sportsOther"></app-property-input>
      <!-- 游戏 -->
      <app-property-select *ngIf="!instance.isFoneOrDev()" [title]="'Games' | translate"
                           [disabled]="isDisabled()"
                           [placeholder]="('PleaseSelect' | translate) + ('Games' | translate)"
                           [mode]="'multiple'"
                           [options]="gamesOptions"
                           [(value)]="contactInfo.games"></app-property-select>
      <!-- 游戏（其他） -->
      <div>
        <app-property-input *ngIf="(!instance.isFoneOrDev() && containsOther(contactInfo.games))"
                            [disabled]="isDisabled()"
                            [title]="'GamesOther' | translate"
                            [placeholder]="('PleaseEnter' | translate) + ('GamesOther' | translate)"
                            [(value)]="contactInfo.gamesOther"></app-property-input>
      </div>
      <!-- 電影 -->
      <app-property-select *ngIf="!instance.isFoneOrDev()" [title]="'Movie' | translate"
                           [disabled]="isDisabled()"
                           [placeholder]="('PleaseSelect' | translate) + ('Movie' | translate)"
                           [mode]="'multiple'"
                           [options]="movieOptions"
                           [(value)]="contactInfo.movie"></app-property-select>
      <!-- 電影（其他） -->
      <app-property-input *ngIf="(!instance.isFoneOrDev() && containsOther(contactInfo.movie))"
                          [disabled]="isDisabled()"
                          [title]="'MovieOther' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('MovieOther' | translate)"
                          [(value)]="contactInfo.movieOther"></app-property-input>
      <!-- 美食 -->
      <app-property-select *ngIf="!instance.isFoneOrDev()" [title]="'Cuisine' | translate"
                           [disabled]="isDisabled()"
                           [placeholder]="('PleaseSelect' | translate) + ('Cuisine' | translate)"
                           [mode]="'multiple'"
                           [options]="cuisineOptions"
                           [(value)]="contactInfo.cuisine"
                           [bottomLineVisible]="containsOther(contactInfo.cuisine)"></app-property-select>
      <!-- 美食（其他） -->
      <app-property-input *ngIf="(!instance.isFoneOrDev() && containsOther(contactInfo.cuisine))"
                          [disabled]="isDisabled()"
                          [title]="'CuisineOther' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('CuisineOther' | translate)"
                          [(value)]="contactInfo.cuisineOther"
                          [bottomLineVisible]="false"></app-property-input>
    </div>
    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{ sourceType != 'PRE_SALES' ? ('JobInformation' | translate) : ('JobInfo' | translate)}}</span>
    </div>
    <div class="section-wrapper">
      <!-- 公司名称 -->
      <app-property-input [title]="'CompanyName' | translate"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('CompanyName' | translate)"
                          [(value)]="contactInfo.companyName"></app-property-input>
      <!-- 公司地址 -->
      <app-property-pick [title]="'CompanyAddress' | translate"
                         [valueCursor]="isDisabled() ? 'no-drop' : 'auto'"
                         appThrottleClick (throttleClick)="onCompanyAddress()"
                         [value]="contactInfo.companyAddress?.toAddressString()"></app-property-pick>
      <!-- 公司电话 -->
      <app-property-pick [title]="'OfficePhoneNumber' | translate"
                         [valueCursor]="isDisabled() ? 'no-drop' : 'auto'"
                         appThrottleClick (throttleClick)="onOfficePhoneNumber()"
                         [value]="contactInfo?.officePhoneNumberString()"></app-property-pick>
      <!-- 行业 -->
      <app-property-input [title]="'Occupation' | translate"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('Occupation' | translate)"
                          [(value)]="contactInfo.companyType"></app-property-input>
      <!-- 职位 -->
      <app-property-input [title]="'Position' | translate"
                          [disabled]="isDisabled()"
                          [placeholder]="('PleaseEnter' | translate) + ('Position' | translate)"
                          [(value)]="contactInfo.position"></app-property-input>
      <!-- 受雇于现职年期 -->
      <app-property-input-number [title]="'YearInCurrentJob' | translate"
                                 [disabled]="isDisabled()"
                                 [placeholder]="('PleaseEnter' | translate) + ('YearInCurrentJob' | translate)"
                                 [(value)]="contactInfo.yearInCurrentJob"></app-property-input-number>
      <!-- 月薪 -->
      <app-property-input-number [title]="'Salary' | translate"
                                 [disabled]="isDisabled()"
                                 [placeholder]="('PleaseEnter' | translate) + ('Salary' | translate)"
                                 [(value)]="contactInfo.salary"></app-property-input-number>
      <!-- 总工作年期 -->
      <app-property-input-number [title]="'WorkYear' | translate"
                                 [disabled]="isDisabled()"
                                 [placeholder]="('PleaseEnter' | translate) + ('WorkYear' | translate)"
                                 [(value)]="contactInfo.workYear"
                                 [bottomLineVisible]="false"></app-property-input-number>
    </div>
    <div>
      <div class="section-title" style="margin-top: 20px">
        <span style="margin-left: 8px">{{'IntroducerInformation' | translate}}</span>
      </div>
      <div class="section-wrapper">
        <!-- 公司名称 -->
        <app-property-input [title]="'CompanyName' | translate"
                            [disabled]="isDisabled()"
                            [placeholder]="('PleaseEnter' | translate) + ('CompanyName' | translate)"
                            [(value)]="contactInfo.introducer.companyName"></app-property-input>

        <div *ngIf="instance.isCMGHK()">
          <!-- Sales Leader -->
          <app-property-input [title]="'Sales Leader'"
                              [disabled]="isDisabled()"
                              [placeholder]="('PleaseEnter' | translate) + 'Sales Leader'"
                              [(value)]="contactInfo.introducer.salesLeader"></app-property-input>
          <!-- Sub Sales Leader	 -->
          <app-property-input [title]="'Sub Sales Leader'"
                              [disabled]="isDisabled()"
                              [placeholder]="('PleaseEnter' | translate) + 'Sub Sales Leader'"
                              [(value)]="contactInfo.introducer.subSalesLeader"></app-property-input>
          <!-- Team Head -->
          <app-property-input [title]="'Team Head'"
                              [disabled]="isDisabled()"
                              [placeholder]="('PleaseEnter' | translate) + 'Team Head'"
                              [(value)]="contactInfo.introducer.teamHead"></app-property-input>
          <!-- Relationship Manager -->
          <app-property-input [title]="'Relationship Manager'"
                              [disabled]="isDisabled()"
                              [placeholder]="('PleaseEnter' | translate) + 'Relationship Manager'"
                              [(value)]="contactInfo.introducer.relationshipManager"></app-property-input>
        </div>
        <!-- 組織碼 -->
        <app-property-input [title]="'OrganizationCode' | translate"
                            [disabled]="isDisabled()"
                            [placeholder]="('PleaseEnter' | translate) + ('OrganizationCode' | translate)"
                            [(value)]="contactInfo.introducer.organizationCode"></app-property-input>
        <!-- 銷售主管 -->
        <app-property-input [title]="'SalesDirector' | translate"
                            [disabled]="isDisabled()"
                            [placeholder]="('PleaseEnter' | translate) + ('SalesDirector' | translate)"
                            [(value)]="contactInfo.introducer.salesDirector"></app-property-input>
        <!-- 業務人員 -->
        <app-property-input [title]="'BusinessPeople' | translate"
                            [disabled]="isDisabled()"
                            [bottomLineVisible]="false"
                            [placeholder]="('PleaseEnter' | translate) + ('BusinessPeople' | translate)"
                            [(value)]="contactInfo.introducer.businessPeople"></app-property-input>
      </div>
    </div>
    <div class="section-title" style="margin-top: 20px">
      <span style="margin-left: 8px">{{'Remark' | translate}}</span>
    </div>
    <div class="section-wrapper">
      <!-- 备注 -->
      <app-property-input-area [title]="'Remark' | translate"
                               [disabled]="isDisabled()"
                               [placeholder]="('PleaseEnter' | translate) + ('Remark' | translate)"
                               [bottomLineVisible]="false"
                               [(value)]="contactInfo.remark"></app-property-input-area>
    </div>
  </nz-spin>
  <div class="contact-footer" *ngIf="status == 'Edit' && !isDisabled()">
    <div class="contact-button" appThrottleClick (throttleClick)="onFamilyMember(contactInfo.familyId)">
      <div class="button-img"><img alt="" width="90" src="assets/images/ic_contact_family.svg"></div>
      <span>{{'FamilyMember' | translate}}</span>
    </div>
    <div class="contact-button"
         appThrottleClick (throttleClick)="onMeetingRecord(contactInfo.id)">
      <div class="button-img"><img alt="" width="90" src="assets/images/ic_contact_meeting.svg"></div>
      <span>{{'ActivityRecord' | translate}}</span>
    </div>
    <div class="contact-button" appThrottleClick (throttleClick)="onProposal()">
      <div class="button-img"><img alt="" width="90" src="assets/images/ic_contact_proposal.svg"></div>
      <span>{{'ProposalApplication' | translate}}</span>
    </div>
    <div class="contact-button" appThrottleClick (throttleClick)="openBooking()">
      <div class="button-img"><img alt="" width="90" src="assets/images/ic_contact_booking.svg"></div>
      <span>{{'InitiateReservation' | translate}}</span>
    </div>
  </div>
  <div *ngIf="!isDisabled()" class="footer">
    <div *ngIf="status == 'Create'" nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSummit()">
          <span>{{ 'Create' | translate }}</span>
        </a>
      </div>
    </div>
    <div *ngIf="status == 'Edit'" nz-row style="width: 100%; height: 100%;">
<!--      <div nz-col nzSpan="12" class="footer-button">-->
<!--        <a nz-button nzType="link" nzBlock nzSize="large"-->
<!--           appThrottleClick (throttleClick)="onReset()">-->
<!--          <em nz-icon nzType="reload" nzTheme="outline"></em>-->
<!--          <span>{{'Reset' | translate}}</span>-->
<!--        </a>-->
<!--      </div>-->
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

  <nz-modal
    [(nzVisible)]="isVisible"
    [nzTitle]="modalTitle"
    [nzContent]="modalContent"
    [nzFooter]="null"
    nzCentered
    [nzWidth]="'400px'"
    [nzClosable]="false"
    [nzMaskClosable]="false"
    (nzOnCancel)="handleCancel()"
  >
    <ng-template #modalTitle style="text-align: center">{{'Tips' | translate}}</ng-template>

    <ng-template #modalContent>
      <p style="text-align: center; margin-bottom: 30px">{{'CreatFamilyMemberTips' | translate}}</p>
      <div nz-row style="width: 100%; height: 100%; justify-content: space-around;">
        <button nz-col nzSpan="10" nz-button nzType="default" appThrottleClick (throttleClick)="handleCancel()">{{'Cancel' | translate}}</button>
        <button nz-col nzSpan="10" nz-button nzType="primary" appThrottleClick (throttleClick)="handleOk()">{{'Confirm' | translate}}</button>
      </div>
    </ng-template>
  </nz-modal>

  <nz-modal
    [(nzVisible)]="isBookingVisible"
    [nzTitle]="modalTitle1"
    [nzContent]="modalContent1"
    [nzFooter]="null"
    nzCentered
    [nzWidth]="'400px'"
    [nzClosable]="false"
    [nzMaskClosable]="false"
    (nzOnCancel)="handleClose()"
  >
    <ng-template #modalTitle1 style="text-align: center">{{'SelectRelationship' | translate}}</ng-template>
    <ng-template #modalContent1>
      <div style="display: flex; justify-content: center; padding-bottom: 20px;">
        <nz-radio-group [(ngModel)]="radioValue" (ngModelChange)="onSelect($event)">
          <label nz-radio [nzValue]="holder"><span>{{'Holder' | translate}}</span></label>
          <label nz-radio [nzValue]="insured"><span>{{'Insured' | translate}}</span></label>
        </nz-radio-group>
      </div>
      <div nz-row style="width: 100%; height: 100%; justify-content: space-around;">
        <button nz-col nzSpan="10" nz-button nzType="default" appThrottleClick (throttleClick)="handleClose()">{{'Cancel' | translate}}</button>
        <button nz-col nzSpan="10" nz-button nzType="primary" appThrottleClick (throttleClick)="onDetermine()">{{'Confirm' | translate}}</button>
      </div>
    </ng-template>
  </nz-modal>

  <nz-modal
    [(nzVisible)]="importVisible"
    [nzTitle]="importModalTit"
    [nzContent]="importModalContent"
    [nzFooter]="null"
    nzCentered
    [nzWidth]="'400px'"
    [nzClosable]="false"
    [nzMaskClosable]="false"
    (nzOnCancel)="importVisible = false"
  >
    <ng-template #importModalTit style="text-align: center">{{'Import' | translate}}</ng-template>
    <ng-template #importModalContent>
      <div style="display: flex; justify-content: center; padding-bottom: 20px;">
        <nz-radio-group [(ngModel)]="importInfoValue">
          <label *ngIf="isShow('BOOKING')" nz-radio [nzValue]="'BOOKING'"><span>{{'BookingImport' | translate}}</span></label>
          <label *ngIf="isShow('POLICY')" nz-radio [nzValue]="'POLICY'"><span>{{'PolicyImport' | translate}}</span></label>
        </nz-radio-group>
      </div>
      <div nz-row style="width: 100%; height: 100%; justify-content: space-around;">
        <button nz-col nzSpan="10" nz-button nzType="default"
                appThrottleClick (throttleClick)="importVisible = false"
        >{{'Cancel' | translate}}</button>
        <button nz-col nzSpan="10" nz-button nzType="primary"
                appThrottleClick (throttleClick)="openImportDrawer()"
        >{{'Confirm' | translate}}</button>
      </div>
    </ng-template>
  </nz-modal>

</div>
