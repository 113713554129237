import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProposalCustomComponent } from './proposal-custom.component';
import { ProposalCustomMainComponent } from './proposal-custom-main.component';
import { ProposalCustomsComponent } from './proposal-customs/proposal-customs.component';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzButtonModule} from 'ng-zorro-antd/button';
import { ProposalCustomCreateComponent } from './proposal-custom-create/proposal-custom-create.component';
import { ProposalCustomMultiSelectionComponent } from './proposal-custom-selected/proposal-custom-multi-selection.component';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import { ProposalCustomSelectionComponent } from './proposal-custom-selection/proposal-custom-selection.component';



@NgModule({
  declarations: [
    ProposalCustomComponent,
    ProposalCustomMainComponent,
    ProposalCustomsComponent,
    ProposalCustomCreateComponent,
    ProposalCustomMultiSelectionComponent,
    ProposalCustomSelectionComponent
  ],
  imports: [
    CommonModule,
    NzLayoutModule,
    TranslateModule,
    SharedModule,
    RouterModule,
    NzTabsModule,
    NzSpinModule,
    NzSkeletonModule,
    NzTableModule,
    NzGridModule,
    NzToolTipModule,
    NzIconModule,
    NzButtonModule,
    NzEmptyModule,
    NzBadgeModule
  ]
})
export class ProposalCustomModule { }
