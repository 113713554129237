import { Component, OnInit } from '@angular/core';
import { Information } from '../../information/information-types';

@Component({
  selector: 'app-product-promotion-detail',
  templateUrl: './product-promotion-detail.component.html',
  styleUrls: ['./product-promotion-detail.component.less']
})
export class ProductPromotionDetailComponent implements OnInit {

  information: Information;
  isEllipsis = false;
  constructor() { }

  ngOnInit(): void {
  }

}
