<div class="drawer-container">
  <div class="drawer-title-small" style="margin-bottom: 30px">
    {{ title ?? 'N/A' }}
  </div>

  <div *ngIf="type === 'text'">
      <div>
        <input [nzBorderless]="true" nz-input [placeholder]="placeholder" [(ngModel)]="value"
               style="text-align: left;" />
      </div>
  </div>
  <div *ngIf="type === 'textarea'">
    <div class="property-value-row">
    <textarea style="padding: 0!important;"
              [nzBorderless]="true"
              nz-input [placeholder]="placeholder"
              [(ngModel)]="value"
              [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
    </div>
  </div>
  <div class="property-bottom-line"></div>


  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [disabled]="!canSave"
           [style]="!canSave ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
