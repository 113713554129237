<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{'OtherPhones' | translate}}
  </div>
  <div style="display: flex; flex-direction: row; align-items: center">
    <div style="flex: 1"></div>
    <div>
      <a nz-button nzType="link" style="color: #4B84E5; font-size: 14px;" appThrottleClick
         (throttleClick)="onAddPhone()">
        + {{'AddPhone' | translate}}
      </a>
    </div>
  </div>
  <div class="section-wrapper" *ngFor="let phone of phones; let i = index"
       style="display: flex; flex-direction: row; padding: 15px; align-items: center">
    <div style="margin-right: 5px" class="tag-outline-sm">+{{phone.areaCode}}</div>
    <div style="color: #222222; font-weight: bold; margin-right: 5px">{{phone.phone}}</div>
    <div *ngIf="phone.type" style="color: #999999">{{phone.type}}</div>
    <div style="flex: 1"></div>
    <div class="functions">
      <a nz-button nzType="link" nzSize="small"
         appThrottleClick (throttleClick)="onEdit(phone, i);">
        <img alt="" width="20" src="assets/images/ic_edit.svg">
      </a>
      <a nz-button nzType="link" nzSize="small"
         appThrottleClick
         (throttleClick)="onDelete(phone, i);">
        <img alt="" width="20" src="assets/images/ic_delete.svg">
      </a>
    </div>
  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>

