<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{'Beneficiary' | translate}}
  </div>
  <div style="display: flex">
    <div *ngIf="relationFlag() && beneficiary.beneficiaryType === 'INDIVIDUAL' && beneficiary.relation != 'OWN'"
         appThrottleClick (throttleClick)="onContacts()">
      <a nz-button nzType="link" style="padding-left: 0;">{{'importContacts' | translate}}</a>
    </div>
    <div>
      <a nz-button nzType="link" style="padding-left: 5px;"
         appThrottleClick (throttleClick)="onOwner()">
        <i nz-icon nzType="sync"></i>
        {{ 'SynchronousOwner' | translate }}
      </a>
    </div>
    <div>
      <a nz-button nzType="link" style="padding-left: 0;"
         appThrottleClick (throttleClick)="onInsured()">
        <i nz-icon nzType="sync"></i>
        {{ 'SynchronousInsured' | translate }}
      </a>
    </div>
  </div>
  <div class="section-wrapper" style="margin-top: 10px;">
    <!-- 客户类型 -->
    <app-property-select [title]="'TypeOfCustomer' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('TypeOfCustomer' | translate)"
                         [options]="relationTypeOptions"
                         [required]="true"
                         [bottomLineVisible]="false"
                         [(value)]="beneficiary.beneficiaryType"></app-property-select>
  </div>
  <div class="section-wrapper">
    <!-- 与受保人关系 -->
    <app-property-select [title]="'RelationshipToInsured' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('RelationshipToInsured' | translate)"
                         [options]="relationshipOptions"
                         [required]="true"
                         [bottomLineVisible]="true"
                         [(value)]="beneficiary.relation"></app-property-select>
    <app-property-input *ngIf="beneficiary.relation === 'OTHER'"
                        [title]="'Other' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('Other' | translate)"
                        [(value)]="beneficiary.otherRelationship">
    </app-property-input>
    <div *ngIf="beneficiary.relation != 'OWN'">
      <div [ngSwitch]="beneficiary.beneficiaryType">
        <div *ngSwitchCase="'INDIVIDUAL'">
          <!-- 姓氏 -->
          <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LastName' | translate"
                              [placeholder]="('PleaseEnter' | translate) + ('LastName' | translate)"
                              [required]="true"
                              [disabled]="isInputName"
                              [(value)]="beneficiary.lastName"></app-property-input>
          <!-- 名字 -->
          <app-property-input [title]="'FirstName' | translate"
                              [placeholder]="('PleaseEnter' | translate) + ('FirstName' | translate)"
                              [required]="true"
                              [(value)]="beneficiary.firstName"></app-property-input>
          <!-- 姓氏-英文 -->
          <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LastNameInEnglish' | translate"
                              [required]="true"
                              [disabled]="isInputName"
                              [placeholder]="('PleaseEnter' | translate) + ('LastNameInEnglish' | translate)"
                              [(value)]="beneficiary.lastNamePinyin"></app-property-input>
          <!-- 名字-英文 -->
          <app-property-input [title]="'FirstNameInEnglish' | translate"
                              [required]="true"
                              [placeholder]="('PleaseEnter' | translate) + ('FirstNameInEnglish' | translate)"
                              [(value)]="beneficiary.firstNamePinyin"></app-property-input>
          <div *ngIf="!(instance.isWDF() && type === 'underwriting')">
            <!-- 性别 -->
            <app-property-select [title]="'Gender' | translate"
                                 [placeholder]="('PleaseSelect' | translate) + ('Gender' | translate)"
                                 [options]="genderOptions"
                                 [required]="true"
                                 [(value)]="beneficiary.gender"></app-property-select>
            <!-- 身份證號 -->
            <app-property-input [title]="'IdentityCardNumber' | translate"
                                [placeholder]="('PleaseEnter' | translate) + ('IdentityCardNumber' | translate)"
                                [required]="true"
                                (valueChange)="getIdCardChange($event)"
                                [(value)]="beneficiary.idCard"></app-property-input>
            <!-- 护照号码 -->
            <app-property-input [title]="'Passport' | translate"
                                [placeholder]="('PleaseEnter' | translate) + ('Passport' | translate)"
                                [(value)]="beneficiary.passport"></app-property-input>
            <!-- 生日 -->
            <app-property-datetime [title]="'DateOfBirth' | translate"
                                   (valueChange)="getAge($event)"
                                   [(value)]="beneficiary.birthday"></app-property-datetime>
          </div>
        </div>
        <!-- 公司 -->
        <div *ngSwitchCase="'CORPORATE'">
          <div class="section-wrapper">
            <!-- 公司名称 -->
            <app-property-input [title]="'CompanyName' | translate"
                                [placeholder]="('PleaseEnter' | translate) + ('CompanyName' | translate)"
                                [required]="true"
                                [(value)]="beneficiary.companyName"></app-property-input>
            <!-- 公司名称英文 -->
            <app-property-input [title]="'CompanyNameEn' | translate"
                                [placeholder]="('PleaseEnter' | translate) + ('CompanyNameEn' | translate)"
                                [required]="true"
                                [(value)]="beneficiary.companyNameEn"></app-property-input>
            <div *ngIf="!(instance.isWDF() && type === 'underwriting')">
              <!-- 公司商业登记号码 -->
              <app-property-input [title]="'BusinessRegistrationNumber' | translate"
                                  [placeholder]="('PleaseEnter' | translate) + ('BusinessRegistrationNumber' | translate)"
                                  [required]="true"
                                  [(value)]="beneficiary.businessRegistrationNumber"></app-property-input>
              <!-- 公司注册日期 -->
              <app-property-datetime [title]="'RegistrationDate' | translate"
                                     [required]="true"
                                     [(value)]="beneficiary.registrationDate"></app-property-datetime>
              <!-- 公司注册地区 -->
              <app-property-select [title]="'RegionOfIncorporation' | translate"
                                   [required]="true"
                                   [placeholder]="('PleaseSelect' | translate) + ('RegionOfIncorporation' | translate)"
                                   [options]="registrationCountryOptions"
                                   [(value)]="beneficiary.registrationCountry"></app-property-select>
              <!-- 公司电话 -->
              <app-property-pick [title]="'OfficePhoneNumber' | translate"
                                 [required]="true"
                                 appThrottleClick (throttleClick)="onCorporateOfficePhoneNumber()"
                                 [value]="beneficiary?.officePhoneNumberString()"></app-property-pick>
              <!-- 公司邮箱 -->
              <app-property-input [title]="'CompanyEmail' | translate"
                                  [placeholder]="('PleaseEnter' | translate) + ('CompanyEmail' | translate)"
                                  [(value)]="beneficiary.email"></app-property-input>
              <!-- 公司登记地址 -->
              <app-property-pick [title]="'RegisteredAddress' | translate"
                                 appThrottleClick (throttleClick)="onCorporateResidentialAddress()"
                                 [value]="beneficiary.registeredAddress?.toAddressString()"></app-property-pick>
              <!-- 通讯地址 -->
              <app-property-pick [title]="'PostalAddress' | translate"
                                 appThrottleClick (throttleClick)="onCorporateContactAddress()"
                                 [value]="beneficiary.contactAddress?.toAddressString()"></app-property-pick>
              <!-- 授权代表姓名中文-名字 -->
              <app-property-input [title]="'LegalPersonFirstName' | translate"
                                  [placeholder]="('PleaseEnter' | translate) + ('LegalPersonFirstName' | translate)"
                                  [(value)]="beneficiary.legalPersonFirstName"></app-property-input>
              <!-- 授权代表姓名中文-姓氏 -->
              <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LegalPersonLastName' | translate"
                                  [placeholder]="('PleaseEnter' | translate) + ('LegalPersonLastName' | translate)"
                                  [(value)]="beneficiary.legalPersonLastName"></app-property-input>
              <!-- 授权代表姓名中文-名字 -->
              <app-property-input [title]="'LegalPersonFirstNameEn' | translate"
                                  [placeholder]="('PleaseEnter' | translate) + ('LegalPersonFirstNameEn' | translate)"
                                  [(value)]="beneficiary.legalPersonFirstNameEn"></app-property-input>
              <!-- 授权代表姓名中文-姓氏 -->
              <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LegalPersonLastNameEn' | translate"
                                  [placeholder]="('PleaseEnter' | translate) + ('LegalPersonLastNameEn' | translate)"
                                  [(value)]="beneficiary.legalPersonLastNameEn"></app-property-input>
              <!-- 授权代表职称 -->
              <app-property-input [title]="'LegalPersonPosition' | translate"
                                  [placeholder]="('PleaseEnter' | translate) + ('LegalPersonPosition' | translate)"
                                  [(value)]="beneficiary.legalPersonPosition"></app-property-input>
              <!-- 授权代表电话 -->
              <app-property-pick [title]="'LegalPersonPhone' | translate"
                                 [bottomLineVisible]="false"
                                 appThrottleClick (throttleClick)="onCorporateLegalPersonPhoneNumber()"
                                 [value]="beneficiary?.legalPersonPhoneNumberString()"></app-property-pick>
            </div>

            <div style="height: 10px"></div>
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
    <div >
    </div>
    <div *ngIf="!(instance.isWDF() && type === 'underwriting')">
      <app-property-input-area [title]="'Remark' |  translate" [(value)]="beneficiary.remark" placeholder="">
      </app-property-input-area>
      <!-- 受益比例 -->
      <app-property-input-number [title]="'PercentageOfBeneficiary' | translate"
                                 [placeholder]="('PleaseEnter' | translate) + ('PercentageOfBeneficiary' | translate)"
                                 [(value)]="beneficiary.percentage"></app-property-input-number>
    </div>

  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [disabled]="!canSave"
           [style]="!canSave ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
