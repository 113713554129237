import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Proposal } from '../../proposal/proposal-types';
import { PagedResp } from '../../api/types';
import { PremiumFinancingService } from '../premium-financing.service';
import { PremiumFinancingProposalSearch } from '../premium-financing-types';

@Component({
  selector: 'app-premium-financing-proposal-select',
  templateUrl: './premium-financing-proposal-select.component.html',
  styleUrls: ['./premium-financing-proposal-select.component.less']
})
export class PremiumFinancingProposalSelectComponent implements OnInit {

  @Output()
  proposalSelected: EventEmitter<Proposal> = new EventEmitter<Proposal>();

  spinning = false;
  loading = false;
  search: PremiumFinancingProposalSearch = new PremiumFinancingProposalSearch();
  proposals: PagedResp<Proposal>;

  constructor(private premiumFinancingService: PremiumFinancingService) {
  }

  ngOnInit(): void {
    this.onSearch(1);
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.premiumFinancingService.proposalList(this.search).subscribe(
      data => {
        this.proposals = data;
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }

  onProposal(proposal: Proposal): void {
    this.proposalSelected.emit(proposal);
  }

}
