import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-open-auth',
  templateUrl: './open-auth.component.html',
  styleUrls: ['./open-auth.component.less']
})
export class OpenAuthComponent implements OnInit {

  newsUrl = null;
  errorText = null;

  constructor(private route: ActivatedRoute, private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(p => {
      this.newsUrl = p;
      console.log(this.newsUrl);
      if (this.newsUrl) {
        this.authService.checkInstance(this.newsUrl.instance)
          .subscribe(
            code => {
              this.authService.instance = code;
              this.authService.authLogin(this.newsUrl['authorization_code']).subscribe(data => {
                this.authService.passport = data;
                this.router.navigate(['/user/workbench']).then();
              }, error => {
                console.log(error);
                this.errorText = error.body.message;
                console.log(this.errorText);
              });

            },
            error => {
              // 组织代码错误，提示错误
            });

      }
    });
  }

}
