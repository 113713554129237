<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{'PolicyOverview' | translate}}
  </div>

  <div class="section-wrapper">
    <!-- 保单号码 -->
    <app-property-text [title]="'PolicyNumber' | translate"
                       [value]="policy.policyNo"></app-property-text>
    <!-- 保单日期-->
    <app-property-text [title]="instance.isFone() ? ('PolicyEffectiveDate' | translate) : ('PolicyDate' | translate)"
                       [value]="policy.policyDate | date: 'yyyy-MM-dd'"></app-property-text>
    <!-- 保单截止日期-->
    <app-property-text [title]="'PolicyDeadline' | translate"
                       [value]="policy.policyDeadline | date: 'yyyy-MM-dd'"></app-property-text>

    <app-property-text [title]="'UserSignDate' | translate"
                       [value]="policy.signDate | date:'yyyy-MM-dd'"></app-property-text>
    <app-property-text [title]="'ApprovalDate' | translate"
                       [value]="policy.approvalDate | date:'yyyy-MM-dd'"></app-property-text>
    <app-property-text *ngIf="!instance.isFoneOrDev()"
                       [title]="'CoolingOffDate' | translate"
                       [bottomLineVisible]="false"
                       [value]="policy.coolingOffDate | date:'yyyy-MM-dd'"></app-property-text>
  </div>
  <div class="section-wrapper">
    <app-property-text [title]="'Referee' | translate"
                       [isEncryption]="isDynamicEncryption('accountName')"
                       [value]="policy.accountName"></app-property-text>
    <!-- 渠道名称 -->
    <app-property-text [title]="'RefereeName' | translate"
                       [isEncryption]="isDynamicEncryption('salesName')"
                       [value]="policy.nickName"></app-property-text>

    <app-property-text *ngIf="workbench.secondAccountName && policy.secondAccountName"
                       [title]="('Referee' | translate) + '2'"
                       [isEncryption]="isDynamicEncryption('accountName')"
                       [value]="policy.secondAccountName "></app-property-text>
    <!-- 渠道名称 -->
    <app-property-text *ngIf="workbench.secondAccountName && policy.secondNickName"
                       [title]="('Referee' | translate) + '2' + ('FullName' | translate)"
                       [isEncryption]="isDynamicEncryption('salesName')"
                       [bottomLineVisible]="!policy.hideTr"
                       [value]="policy.secondNickName"></app-property-text>

    <!-- 服务代表 -->
    <app-property-text *ngIf="!policy.hideTr" [title]="('ServiceAgent' | translate) + '1'"
                       [value]="policy.serviceAgentName"></app-property-text>
    <!-- 服务代表 -->
    <app-property-text *ngIf="!policy.hideTr" [title]="('ServiceAgent' | translate) + '2'"
                       [value]="policy.serviceAgent2Name"></app-property-text>
    <!-- 签单员1 -->
    <app-property-text *ngIf="!policy.hideTr" [title]="('TechnicalRepresentative' | translate) +'1 '+ ('Account' | translate)"
                       [value]="policy.trAccountName"></app-property-text>
    <!-- 签单员2 -->
    <app-property-text *ngIf="!policy.hideTr" [title]="('TechnicalRepresentative' | translate) +'2 '+ ('Account' | translate)"
                       [value]="policy.tr2AccountName"></app-property-text>
    <!-- 签单员3 -->
    <app-property-text *ngIf="!policy.hideTr" [title]="('TechnicalRepresentative' | translate) +'3 '+ ('Account' | translate)"
                       [bottomLineVisible]="false"
                       [value]="policy.tr3AccountName"></app-property-text>
  </div>
  <div class="section-wrapper">
    <!--  主险名称  -->
    <app-property-text [title]="'InsuranceName' | translate"
                       [value]="policy.productName"></app-property-text>
    <!-- 险种 -->
    <app-property-text [title]="'PolicyNumber' | translate"
                       [value]="policy.policyNo"></app-property-text>

    <!-- 保险种类 -->
    <app-property-text [title]="'InsuranceType' | translate"
                       [value]="policy.categoryName"></app-property-text>

    <!-- 保险公司 -->
    <app-property-text [title]="'Insurer' | translate"
                       [value]="policy.companyName"></app-property-text>
    <!--  供款年期  -->
    <app-property-text *ngIf="!policy.custom" [title]="'PaymentTerm' | translate"
                       [value]="policy.productPlan.productYear"></app-property-text>
    <!-- 资管产品供款年期 -->
    <app-property-text *ngIf="policy.custom" [title]="'CustomPaymentTerm' | translate"
                       [value]="policy.productPlan?.customPaymentTerm | yearConversion"></app-property-text>
    <!-- 保額 -->
    <app-property-text [title]="'SumAssured' | translate"
                       [value]="(policy.productPlan.sumAssured | number) +(policy.productPlan.currency | metadataTranslate:'currency')"></app-property-text>
    <!-- 保費 -->
    <app-property-text [title]="'ModalPremium' | translate"
                       [value]="(policy.productPlan.premium | number)+(policy.productPlan.currency | metadataTranslate:'currency') "></app-property-text>

    <app-property-text *ngIf="!instance.isCMGHK()" [title]="'Target Premium' | translate"
                       [value]="policy.productPlan.targetPremium | number "></app-property-text>

    <app-property-text *ngIf="!instance.isCMGHK()" [title]="'Excess Premium' | translate"
                       [bottomLineVisible]="false"
                       [value]="policy.productPlan.excessPremium | number "></app-property-text>
  </div>
  <div class="section-wrapper">

    <!-- Branch -->
    <app-property-text [title]="'Branch'" [value]="policy.branch"></app-property-text>
    <!-- Consultant -->
    <app-property-text [title]="'Consultant'" [value]="policy.consultant"></app-property-text>
    <!-- RM -->
    <app-property-text [title]="'RM'" [value]="policy.relationshipManager"></app-property-text>
    <!-- BPO -->
    <app-property-text [title]="'BPO' | translate"
                       [value]="policy.guarantee ==null ? '' :  policy.guarantee?('Yes' | translate) : ('No' | translate)"></app-property-text>
    <!-- 是否轉入 -->
    <app-property-text [title]="'IsTransferIn'| translate"
                       [value]="policy.isTransferIn  ? ('Yes' | translate) : ('No' | translate)"></app-property-text>

    <app-property-text *ngIf="!instance.isFone()"
                       [title]="'EnjoyPolicyDiscounts' | translate" [value]="policy.enjoyPolicyDiscount ? ('Yes' | translate) : ('No' | translate)">
    </app-property-text>

    <app-property-text *ngIf="!instance.isFone()"
                       [title]="'PromotionExpirationDate' | translate" [bottomLineVisible]="false" [value]="policy.discountEventsDeadlinesDate"></app-property-text>
  </div>
  <div *ngIf="policy.riders?.length" class="section-title" style="margin-top: 20px">
    <span style="margin-left: 8px">{{'Rider' | translate}}</span>
  </div>
  <div *ngFor="let item of policy.riders" class="section-wrapper">
    <app-property-text [title]="'RiderName'| translate"
                       [value]="item.productName"></app-property-text>
    <app-property-text [title]="'Premium'| translate"
                       [value]="(item.premium | number) + policy.productPlan.currency"></app-property-text>
    <app-property-text [title]="'SumAssured'| translate"
                       [value]="(item.sumAssured | number )+ policy.productPlan.currency"></app-property-text>
    <app-property-text [title]="'ProductYear'| translate"
                       [value]="(item.productYear | yearConversion )"></app-property-text>
    <div *ngIf="item.categoryCode === 'MEDICAL'">
      <app-property-text [title]="'Deductible'| translate"
                         [value]="(item?.medicalPlan?.deductible || 0  | number)+ policy.productPlan.currency"></app-property-text>
      <app-property-text [title]="'MedicalLevel'| translate"
                         [value]="(item.medicalPlan?.medicalLevel | metadataTranslate: 'medicalLevel')"></app-property-text>
      <app-property-text [title]="'GuaranteedArea'| translate"
                         [value]="(item.medicalPlan?.region | metadataTranslate: 'medicalRegion')"></app-property-text>
      <app-property-text [title]="'SupplementaryBenefits'| translate"
                         [value]="addSafe(item.medicalPlan?.supplementaryBenefits)"></app-property-text>
    </div>
  </div>
  <div class="section-title" style="margin-top: 20px">
    <span style="margin-left: 8px">{{'PaymentMethod' | translate}}</span>
  </div>
  <div class="section-wrapper">
    <!-- 首期付款方式 -->
    <app-property-text [title]="'InitialPaymentMethod' | translate"
                       [value]="policy.productPlan.firstPaymentType | metadataTranslate:'paymentType'">
    </app-property-text>

    <app-property-text [title]="'RenewalPaymentMethod' | translate"
                       [value]="policy.productPlan.restPaymentType | metadataTranslate:'paymentType'">
    </app-property-text>

    <app-property-text [title]="'DividendDistributionMethod' | translate"
                       [bottomLineVisible]="false"
                       [value]="policy.productPlan.dividends | metadataTranslate:'dividends'">
    </app-property-text>

<!--    <app-property-text [title]="'SubmissionDate' | translate"-->
<!--                       [value]="policy.signDate | date:'yyyy-MM-dd'"></app-property-text>-->
<!--    <app-property-text [title]="'ApprovalDate' | translate"-->
<!--                       [value]="policy.approvalDate | date:'yyyy-MM-dd'"></app-property-text>-->
<!--    <app-property-text *ngIf="!instance.isFoneOrDev()"-->
<!--                       [title]="'CoolingOffDate' | translate"-->
<!--                       [bottomLineVisible]="false"-->
<!--                       [value]="policy.coolingOffDate | date:'yyyy-MM-dd'"></app-property-text>-->

  </div>


</div>
