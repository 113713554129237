import { Expose } from 'class-transformer';
import { Product } from '../product/product-types';
import { Material, PagedReq, UnderwritingStatus } from '../api/types';
import { PolicyStatus } from '../policy/policy-types';

export class UnderwritingSearch extends PagedReq {
  @Expose() status: UnderwritingStatus; // 新单状态：CHECKING-检查文件;UNDERWRITING-核保中;SENDING-寄出;FINISHED-完成;DECLINED-拒保;CANCELLED-取消;DELETED-删除
  @Expose() accountName: string; // 账户名
  @Expose() accountNames: string[]; // 账户名s
  @Expose() groupNo: string; // 分组编号
  @Expose() name: string; // 客户姓名
  @Expose() customCode: string;
  @Expose() appointmentTimeConfirmStart: number; // 预约开始时间
  @Expose() appointmentTimeConfirmEnd: number; // 预约结束时间
  @Expose() signDate: number; // 客戶签署日期
  @Expose() signDateStart: number;
  @Expose() signDateEnd: number;
  @Expose() underwritingNo: string; // 新单号
  @Expose() policyNo: string;
  @Expose() productCode: string; // 保险产品码
  @Expose() organizer: string; // 发起人
  @Expose() trAccountName: string; // 参与人
  @Expose() newBusinessNumber: number; // 自定义业务编号
  @Expose() product: Product = new Product(); // 產品
}

export class UnderwritingPendingSearch extends PagedReq {
  @Expose() underwritingNo: string; // 新单编号
  @Expose() pendingNo: string; // 服务件编号
  @Expose() status: string; // 状态
  @Expose() project: string; // 变更项目
  @Expose() deliveryDate: number; // 送件日期
  @Expose() deadlinesDate: number; // 变更项目
}

export class UnderwritingPendingStatus {
  @Expose() id: number; // 主键
  @Expose() status: string; // status
}

/**
 * 用于Pending列表
 */
export class UnderwritingPending {
  @Expose() id: number; // 主键
  @Expose() pendingNo: string; // Pending编号
  @Expose() status: string; // Pending状态
  @Expose() project: string; // Pending主题
  @Expose() remark: string; // 备注
  @Expose() applyTime: number; // 创建时间
  @Expose() organizer: string; // 发起人
  @Expose() deadlines: number; // 截止时间
  @Expose() messageCount: number; // 留言总数
  @Expose() unReadMessage: number; // 未读留言数
  @Expose() finish: boolean; // 是否完成Pending标志:false-未完成;true-完成
  @Expose() underwritingNo: string; // 新单编号
  @Expose() policyNo: string; // 保单号
  @Expose() deliveryDate: number; // 送件日期
}

/**
 * 用于详情
 */
export class UnderwritingPendingDetail {
  @Expose() id: number; // 主键
  @Expose() pendingNo: number; // Pending编号
  @Expose() status: string; // Pending状态
  @Expose() underwritingStatus: UnderwritingStatus; // 新单状态：CHECKING-检查文件;UNDERWRITING-核保中;SENDING-寄出;FINISHED-完成;DECLINED-拒保;CANCELLED-取消;DELETED-删除
  @Expose() project: string; // Pending主题
  @Expose() remark: string; // 备注
  @Expose() applyTime: number; // 创建时间
  @Expose() organizer: string; // 发起人
  @Expose() deadlines: number; // 截止时间
  @Expose() messageCount: number; // 留言总数
  @Expose() unReadMessage: number; // 未读留言数
  @Expose() finish: boolean; // 是否完成Pending标志
  @Expose() attachments: Material[]; // 附件
  @Expose() receiver: string; // 接收人
  @Expose() companyName: string; // 接收人
  @Expose() operatingTime: number; // 操作人最后操作时间
  @Expose() underwritingNo: string; // 操作人最后操作时间
  @Expose() operator: string; // 操作人
  @Expose() ownerName: string; // 投保人姓名
  @Expose() archiveDate: number; // 建档日期
  @Expose() finishDate: number; // 建档日期
  @Expose() deliveryDate: number; // 送件日期
  @Expose() insuredName: number; // 受保人姓名
  @Expose() ultimate: boolean;

}

export class UnderwritingPendingReply {
  @Expose() replyTime: number; // 回复时间
  @Expose() lastName: number; // 姓
  @Expose() attachments: Material[]; // 附件
  @Expose() accountName: string; // 当前登录回复账户
  @Expose() accountType: string; // 账户类型: STAFF-职工;SALES-销售
  @Expose() myReply: boolean; // 用于区别回复是不是本人
  @Expose() avatar: string; // 头像
  @Expose() content: string; // 回复内容
  @Expose() time: number; // 对话时间：计划书留言列表
}

export class UnderwritingPendingReplyReq {
  @Expose() pendingId: number; // Pending主键
  @Expose() attachments: Material[]; // 附件
  @Expose() content: string; // 回复内容
}
