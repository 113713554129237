<div [ngSwitch]="displayMode">
  <div *ngSwitchCase="'horizontal'">
    <div [class]="disabled ? 'property-row property-disable' : 'property-row'">
      <div class="property-title-row" *ngIf="title" [style]="titleColor ? ('color:' + titleColor) : ''">
        {{ title }}
      </div>
      <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
      <div class="property-value-row">
        <nz-date-picker class="date-picker-row"
                        nzBorderless [ngModel]="date" (ngModelChange)="onDateChange($event)"
                        [nzMode]="mode"
                        [nzFormat]="formatter"
                        [nzShowTime]="showTime"
                        [nzPlaceHolder] = "placeholder"
                        [disabled]="disabled"></nz-date-picker>
      </div>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>

  <div *ngSwitchCase="'vertical'">
    <div [class]="disabled ? 'property-column property-disable' : 'property-column'">
      <div class="property-title-column">
        <span *ngIf="title" [style]="titleColor ? ('color:' + titleColor) : ''">{{ title }}</span>
        <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
      </div>
      <div class="property-value-column">
        <nz-date-picker class="date-picker-column"
                        nzBorderless [ngModel]="date" (ngModelChange)="onDateChange($event)"
                        [nzFormat]="formatter"
                        [nzMode]="mode"
                        [nzShowTime]="showTime"
                        [nzPlaceHolder] = "placeholder"
                        [disabled]="disabled"></nz-date-picker>
      </div>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>

</div>
