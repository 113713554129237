<div class="from-info-container">
  <div class="from-info-card">
    <div class="form-card-title question-title">
      {{'CustomerDeclaration' | translate}}
    </div>
    <div class="question-title-span">
      {{'ESignatureTitle' | translate}}
    </div>

    <div style="padding-left: 1em;">
      <div class="question-title-span question-title">
        {{'ESignatureText1' | translate}}
      </div>

      <div class="question-title-span question-title" style="display: block;">
        {{'ESignatureText2' | translate}}
        <div class="question-title">
          {{'ESignatureText2_1' | translate}}
        </div>
        <div class="question-title">
          {{'ESignatureText2_2' | translate}}
        </div>
      </div>
      <div class="question-title-span question-title">
        {{'ESignatureText3' | translate}}
      </div>
      <div class="question-title-span question-title">
        {{'ESignatureText4' | translate}}
      </div>
    </div>
  </div>

  <div class="display-flex" style="flex-direction: column;justify-content: center;margin-top: 32px;">
    <div class="display-flex sign-box" style="">
      <div style="color: #333333;">{{'PolicyOwnerSignature' | translate}}</div>
      <div class="display-flex pointer" style="color: #1D64C4;"
           appThrottleClick (throttleClick)="onConsultantSignature('OWNER')">
        <div *ngIf="onSignedInfo('OWNER').signatureStatus === 'SIGNED'"
             style="margin-right: 10px;">{{'SignedOn' | translate}}：{{onSignedInfo('OWNER').signatureTime | date: 'dd/MM/yyyy'}}</div>
        <div *ngIf="onSignedInfo('OWNER').signatureStatus !== 'SIGNED'"
             style="margin-right: 10px;color: #F66667;">{{ onSignedInfo('OWNER').signatureStatus | metadataTranslate: 'fnaSignedStatus'}}</div>
        <i nz-icon nzType="right" nzTheme="outline"></i>
      </div>
    </div>

    <div class="display-flex sign-box" style="">
      <div style="color: #333333;">{{'ConsultantSignature1' | translate}}</div>
      <div class="display-flex pointer" style="color: #1D64C4;"
           appThrottleClick (throttleClick)="onConsultantSignature('SALES')">
        <div *ngIf="onSignedInfo('SALES').signatureStatus === 'SIGNED'"
             style="margin-right: 10px;">{{'SignedOn' | translate}}：{{onSignedTime(onSignedInfo('SALES').signatureTime)}}</div>
        <div *ngIf="onSignedInfo('SALES').signatureStatus !== 'SIGNED'"
             style="margin-right: 10px;color: #F66667;">{{ onSignedInfo('SALES').signatureStatus | metadataTranslate: 'fnaSignedStatus'}}</div>
        <i nz-icon nzType="right" nzTheme="outline"></i>
      </div>
    </div>

    <div class="display-flex sign-box" style="">
      <div style="color: #333333;">{{'WitnessSignature' | translate}}</div>
      <div class="display-flex pointer" style="color: #1D64C4;"
           appThrottleClick (throttleClick)="onConsultantSignature('EYEWITNESS')">
        <div *ngIf="onSignedInfo('EYEWITNESS').signatureStatus === 'SIGNED'"
             style="margin-right: 10px;">{{'SignedOn' | translate}}：{{onSignedInfo('EYEWITNESS').signatureTime | date: 'dd/MM/yyyy'}}</div>
        <div *ngIf="onSignedInfo('EYEWITNESS').signatureStatus !== 'SIGNED'"
             style="margin-right: 10px;color: #F66667;">{{ onSignedInfo('EYEWITNESS').signatureStatus | metadataTranslate: 'fnaSignedStatus'}}</div>
        <i nz-icon nzType="right" nzTheme="outline"></i>
      </div>
    </div>

    <div class="sign-button" style="width: 70vw;margin-top: 16px;">
      <button nz-button nzType="primary"
              appThrottleClick (throttleClick)="onResign(tplTitle, tplContent, tplFooter)">
        {{'ResetAllSignatures' | translate}}
      </button>
    </div>

  </div>

  <ng-template #tplTitle>
    <span>{{'Re-Sign' | translate}}</span>
  </ng-template>
  <ng-template #tplContent let-params>
    <p>{{'DoYouWantToReSignFna' | translate}}?</p>
  </ng-template>
  <ng-template #tplFooter let-ref="modalRef">
    <button (throttleClick)="ref.destroy()" appThrottleClick
            nz-button>{{'Cancel' | translate}}</button>
    <button (throttleClick)="onReSignConfirm(ref)" appThrottleClick nz-button nzType="primary">
      {{'Re-Sign' | translate}}
    </button>
  </ng-template>


  <nz-modal [(nzVisible)]="isVisible"
            nzCentered [nzTitle]="modalTitle"
            [nzFooter]="null"
            [nzWidth]="'100%'"
            [nzMaskClosable]="false"
            (nzOnCancel)="handleCancel()">
    <ng-template #modalTitle>
      <div *ngIf="itemSignatureInfo.signatureType === 'OWNER'">{{'PolicyOwnerSignature' | translate}}</div>
      <div *ngIf="itemSignatureInfo.signatureType === 'SALES'">{{'ConsultantSignature1' | translate}}</div>
      <div *ngIf="itemSignatureInfo.signatureType === 'EYEWITNESS'">{{'WitnessSignature' | translate}}</div>
    </ng-template>
    <ng-container *nzModalContent>
      <div class="display-flex" style="justify-content: center;margin-bottom: 20px;">
        <div class="signature-area">
          <app-signature-pad *ngIf="itemSignatureInfo.signatureStatus !== 'SIGNED'"
                             [options]="signaturePadOptions"></app-signature-pad>
          <img *ngIf="itemSignatureInfo.signatureStatus === 'SIGNED'"
               [width]="signaturePadOptions.canvasWidth"
               [height]="signaturePadOptions.canvasHeight"
               [src]="signatureUrl" alt=""/>
        </div>
      </div>
      <div class="modal-footer display-flex" style="justify-content: center;">
        <div class="button-default">
          <button nz-button nzType="default"
                  [style]="!canRewrite ? 'opacity: 0.5' : ''"
                  appThrottleClick (throttleClick)="onRewriteConfirm()">{{'Clean' | translate}}</button>
        </div>
        <div class="button-primary" style="margin-left: 22px;">
          <button nz-button nzType="primary"
                  [style]="!canConfirm ? 'opacity: 0.5' : ''"
                  appThrottleClick (throttleClick)="onConfirm()">{{'Confirm' | translate}}</button>
        </div>
      </div>
    </ng-container>
  </nz-modal>

</div>
