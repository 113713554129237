<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 40px">
    {{'ProposalSearch' | translate}}
  </div>

  <!-- 状态 -->
  <app-property-select [displayMode]="'vertical'"
                       [placeholder]="('PleaseSelect' | translate) + ('Status' | translate)"
                       [title]="'Status' | translate"
                       [options]="statusOptions"
                       [(value)]="search.status"></app-property-select>

  <!-- 编号 -->
  <app-property-input [displayMode]="'vertical'"
                      [title]="'ProposalID' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('ProposalID' | translate)"
                      [(value)]="search.proposalId"></app-property-input>

  <!-- 销售渠道 -->
  <app-property-select *ngIf="mode === 'SUBORDINATE'" [displayMode]="'vertical'"
                       [title]="'Referee' | translate"
                       [placeholder]="('PleaseSelect' | translate) + ('Referee' | translate)"
                       [(value)]="search.accountName"
                       [supportSearch]="true"
                       [options]="subOptions"></app-property-select>
  <!-- 保险险种 -->
  <app-property-select [displayMode]="'vertical'"
                       [title]="'ProductCategory' | translate"
                       [placeholder]="('PleaseSelect' | translate) + ('ProductCategory' | translate)"
                       [(value)]="search.categoryCode"
                       [options]="categoryOptions"
                       (valueChange)="onProductCategoryChange($event)"></app-property-select>
  <!-- 保险公司 -->
  <app-property-select [displayMode]="'vertical'"
                       [title]="'Insurer' | translate"
                       [placeholder]="('PleaseSelect' | translate) + ('Insurer' | translate)"
                       [(value)]="search.companyCode"
                       [options]="companyOptions"
                       (valueChange)="onProductCompanyChange($event)"></app-property-select>
  <!-- 产品 -->
  <app-property-pick [displayMode]="'vertical'"
                     [title]="'InsuranceProduct' | translate"
                     appThrottleClick (throttleClick)="onProductSelection()"
                     [placeholder]="'ProductSelect' | translate"
                     [(value)]="search.product.productName"></app-property-pick>
  <!-- 制作时间 -->
  <app-property-datetime [displayMode]="'vertical'"
                         [title]="'ProductionTime' | translate"
                         [(value)]="search.applicationTime"></app-property-datetime>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="onSearch()">
          <em nz-icon nzType="search" nzTheme="outline"></em>
          <span>{{'Search' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
