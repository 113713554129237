import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-premium-financing',
  templateUrl: './premium-financing.component.html',
  styleUrls: ['./premium-financing.component.less']
})
export class PremiumFinancingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
