import { Injectable } from '@angular/core';
import { HttpService } from '../core/http.service';
import { Observable } from 'rxjs/internal/Observable';
import {
  IntroductionFeeDetails,
} from '../commission/commission-types';
import { ApiResponse, PagedResp } from '../api/types';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { BaseService } from '../base/base.service';
import {
  NewSaleReportReq,
  SaleList,
  SaleListReq,
  SaleReportDetail,
  SaleReportList, SaleReportListItem,
  SaleReportReq
} from './sale-report-type';

@Injectable({
  providedIn: 'root'
})
export class SaleReportService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }
  /**
   * 查询介绍费详情
   * @param id 介绍费ID
   */
  searchInfo(id: number): Observable<IntroductionFeeDetails> {
    return this.http.get<ApiResponse<IntroductionFeeDetails>>(API.commissionV3 + '/bill-detail/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * .佣金估算列表
   * @param list 请求参数
   */
  list(list: SaleReportReq): Observable<SaleReportList[]> {
    let httpParams = new HttpParams();
    if (list.policyNo != null) {
      httpParams = httpParams.set('policyNo', list.policyNo.toString());
    }
    if (list.ownerName != null) {
      httpParams = httpParams.set('ownerName', list.ownerName.toString());
    }
    if (list.type != null) {
      httpParams = httpParams.set('type', list.type.toString());
    }
    return this.http.get<ApiResponse<SaleReportList[]>>(API.policyCommissionV3 + '/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 佣金估算佣金列表
   * @param list 请求参数
   */
  salesList(list: SaleListReq): Observable<SaleList[]> {
    let httpParams = new HttpParams();
    if (list.policyId != null) {
      httpParams = httpParams.set('policyId', list.policyId.toString());
    }
    if (list.productType != null) {
      httpParams = httpParams.set('productType', list.productType.toString());
    }
    return this.http.get<ApiResponse<SaleList[]>>(API.policyCommissionV3 + '/sales-list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 佣金估算佣金列表 新单
   * @param list 请求参数 UNDERWRITING
   */
  salesListUnderwriting(list: SaleListReq): Observable<SaleList[]> {
    let httpParams = new HttpParams();
    if (list.underwritingNo != null) {
      httpParams = httpParams.set('underwritingNo', list.underwritingNo.toString());
    }
    if (list.productType != null) {
      httpParams = httpParams.set('productType', list.productType.toString());
    }
    return this.http.get<ApiResponse<SaleList[]>>(API.policyCommissionV3 + '/underwriting-sales-list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 佣金详情
   * @param policyCommissionId 请求参数
   */
  detailInfo(policyCommissionId: any): Observable<SaleReportDetail> {
    let httpParams = new HttpParams();
    if (policyCommissionId != null) {
      httpParams = httpParams.set('policyCommissionId', policyCommissionId.toString());
    }

    return this.http.get<ApiResponse<SaleReportDetail>>(API.policyCommissionV3 + '/detail', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * .佣金估算列表
   * @param list 请求参数
   */
  newList(list: NewSaleReportReq): Observable<PagedResp<SaleReportListItem>> {
    let httpParams = new HttpParams();
    if (list.policyNo != null) {
      httpParams = httpParams.set('policyNo', list.policyNo.toString());
    }
    if (list.underwritingNo != null) {
      httpParams = httpParams.set('underwritingNo', list.underwritingNo.toString());
    }
    if (list.companyCode != null) {
      httpParams = httpParams.set('companyCode', list.companyCode.toString());
    }
    if (list.productCode != null) {
      httpParams = httpParams.set('productCode', list.productCode.toString());
    }
    if (list.policyDate != null) {
      httpParams = httpParams.set('policyDate', list.policyDate.toString());
    }
    if (list.pageNum != null) {
      httpParams = httpParams.set('pageNum', list.pageNum.toString());
    }
    if (list.pageSize != null) {
      httpParams = httpParams.set('pageSize', list.pageSize.toString());
    }
    if (list.type != null) {
      httpParams = httpParams.set('type', list.type.toString());
    }
    if (list.module != null) {
      httpParams = httpParams.set('module', list.module.toString());
    }
    return this.http.get<ApiResponse<PagedResp<SaleReportListItem>>>(API.policyCommissionV4 + '/page', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

}
