import { Component, Input, OnInit } from '@angular/core';
import {
  ContactInfoRes,
  ContactListRes,
  ContactSearch,
  ContactsType,
  CreateContactsType
} from '../../contact/contact-types';
import { DataMode, PagedResp } from '../../api/types';
import {ContactService} from '../../contact/contact.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {ContactInfoComponent} from '../../contact/contact-info/contact-info.component';
import {plainToClass} from 'class-transformer';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-pre-sales-list',
  templateUrl: './pre-sales-list.component.html',
  styleUrls: ['./pre-sales-list.component.less']
})
export class PreSalesListComponent implements OnInit {
  @Input()
  mode = DataMode.OWN;

  spinning = false;
  loading = false;
  isVisible = false;
  CurrentID: number;
  search: ContactSearch = new ContactSearch();
  listArr: ContactListRes[] = [];
  searchPopoverVisible = false;
  contactList: PagedResp<ContactListRes>;

  constructor(private contactService: ContactService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
    this.getListAll();
  }

  onSearch(pageNum: number): void {
    // this.listArr = [];
    this.search.pageNum = pageNum;
    this.loading = true;
    this.contactService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          console.log(data);
          this.contactList = data;
        },
        error => {
          this.loading = false;
        });
  }

  onReload(resetPageNum = true): void {
    // if (resetPageNum) {
    //   this.search = new ContactSearch();
    // }
    this.listArr = [];
    this.getListAll();
  }

  getList(): void {
    this.loading = true;
    this.contactService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          console.log(data);
          this.contactList = data;
        },
        error => {
          this.loading = false;
        });
  }

  getListAll(): void {
    let observable: Observable<any>;
    this.search.type = 'PRE_SALES';
    this.loading = true;
    if (this.mode === DataMode.OWN) {
      observable = this.contactService.list(this.search);
    } else {
      observable = this.contactService.preSubList(this.search);
    }

    observable.subscribe(
        data => {
          this.contactList = data;
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  onResetSearch(): void {
    this.search = new ContactSearch();
    this.contactList = new PagedResp<ContactListRes>();
    this.getListAll();
  }

  onDetail(val): void {
    this.contactService.detail(val)
      .subscribe(data => {
          const contactInfo = data;
          const drawerRef = this.drawerService.create<ContactInfoComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: ContactInfoComponent,
            nzContentParams: {
              infoID: val,
              contactInfo,
              status: ContactsType.Edit,
              mode: this.mode,
              sourceType: 'PRE_SALES'
            }
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.ContactInfoSave.subscribe(
              info => {
                drawerRef.close();
                this.onResetSearch();
              }
            );
          });
        },
        error => {
          console.log(error);
        });
  }

  onDelete(val): void {
    this.isVisible = true;
    // this.handleOk(val);
    this.CurrentID = val;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleOk(): void {
    this.isVisible = false;
    this.loading = true;
    this.contactService.deleteContact(this.CurrentID)
      .subscribe(
        data => {
          // console.log(data);
          this.loading = false;
          this.onReload();
        },
        error => {
          this.loading = false;
          console.log(error);
        }
      );
  }

  onContactCreate(): void {
    const info = new ContactListRes();
    const drawerRef = this.drawerService.create<ContactInfoComponent, { value: any }, string>({
      nzWidth: 800,
      nzContent: ContactInfoComponent,
      nzContentParams: {
        contactInfo: plainToClass(ContactInfoRes, info),
        status: ContactsType.Create,
        contactsType: CreateContactsType.PRE_SALES,
        sourceType: 'PRE_SALES'
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.ContactInfoSummit.subscribe(
        data => {
          drawerRef.close();
          this.onReload();
        }
      );
    });
  }

  onImportContacts(): void {
    this.contactService.importContacts()
      .subscribe(
        data => {
          console.log(data);
        },
        error => {
          console.log(error);
        }
      );
  }
}
