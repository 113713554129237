import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  AccountInformation,
  ApiResponse,
  DataDesensitization,
  Instance,
  InstanceAccount,
  Passport
} from '../api/types';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import { HttpService } from '../core/http.service';
import { BaseService } from '../base/base.service';
import { deduplication } from '../shared/utils/collections';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {

  accountInfo: AccountInformation;
  dataDesensitization: DataDesensitization;

  constructor(private http: HttpService) {
    super();
  }

  info(): Observable<AccountInformation> {
    return this.http.get<ApiResponse<AccountInformation>>(API.accountV3 + '/info', {
      observe: 'response'
    }).pipe(map(data => {
      this.accountInfo = data.body.data;
      return this.accountInfo;
    }));
  }

  currentAccount(): InstanceAccount {
    if (!this.instance) {
      return null;
    }
    if (!this.passport) {
      return null;
    }
    const account = new InstanceAccount();
    account.instance = this.instance;
    account.passport = this.passport;
    return account;
  }

  addAccount(account: InstanceAccount): void {
    if (!account) {
      return;
    }
    if (!account.instance) {
      return;
    }
    if (!account.passport) {
      return;
    }
    let accounts = this.accounts;
    accounts.push(account);
    accounts = deduplication(accounts, a => {
      return a.instance.key + '-' + a.passport.accountName;
    });
    this.accounts = accounts;
  }

  removeAccountByIndex(index: number): void {
    const accounts = this.accounts;
    accounts.splice(index, 1);
    this.accounts = accounts;
  }

  removeAccount(account: InstanceAccount): void {
    if (!account) {
      return;
    }
    if (!account.instance) {
      return;
    }
    if (!account.passport) {
      return;
    }
    const accounts = this.accounts;
    const index = accounts.findIndex((value, i, obj) => {
      return value.instance.key === account.instance.key && value.passport.accountName === account.passport.accountName;
    });
    accounts.splice(index, 1);
    this.accounts = accounts;
  }

  removeCurrentAccount(): void {
    this.removeAccount(this.currentAccount());
  }

  /**
   * 脱敏字典
   */
  desensitization(): Observable<DataDesensitization> {
    return this.http.get<ApiResponse<DataDesensitization>>(API.systemV3 + '/data-desensitization', {
      observe: 'response'
    }).pipe(map(data => {
      console.log(data.body.data, 'data.body.data');
      this.dataDesensitization = data.body.data;
      return data.body.data;
    }));
  }

}
