import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZuuFnaTemplateComponent } from './zuu-fna-template.component';
import { ZuuTemplateSignatureComponent } from './zuu-template-signature/zuu-template-signature.component';
import { ZuuTemplateQuestionComponent } from './zuu-template-question/zuu-template-question.component';
import { ZuuTemplateOverviewComponent } from './zuu-template-overview/zuu-template-overview.component';
import { ZuuTemplateIntroductionComponent } from './zuu-template-introduction/zuu-template-introduction.component';
import { ZuuFormTemplateComponent } from './zuu-form-template/zuu-form-template.component';
import { ZuuFormSelectedComponent } from './zuu-form-selected/zuu-form-selected.component';
import { ZuuBasicInfoComponent } from './zuu-basic-info/zuu-basic-info.component';
import { ZuuAdditionalInfoComponent } from './zuu-additional-info/zuu-additional-info.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NewFnaModule } from '../new-fna/new-fna.module';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NewFnaTemplateModule} from '../new-fna-template/new-fna-template.module';
import {NzAlertModule} from 'ng-zorro-antd/alert';

@NgModule({
  declarations: [
    ZuuFnaTemplateComponent,
    ZuuTemplateSignatureComponent,
    ZuuTemplateQuestionComponent,
    ZuuTemplateOverviewComponent,
    ZuuTemplateIntroductionComponent,
    ZuuFormTemplateComponent,
    ZuuFormSelectedComponent,
    ZuuBasicInfoComponent,
    ZuuAdditionalInfoComponent
  ],
    imports: [
        CommonModule,
        NzIconModule,
        NzButtonModule,
        NzGridModule,
        NzRadioModule,
        FormsModule,
        NzSelectModule,
        TranslateModule,
        SharedModule,
        NzDatePickerModule,
        NzInputModule,
        NzPopoverModule,
        NzSpinModule,
        NzAnchorModule,
        NzInputNumberModule,
        NzCheckboxModule,
        NzModalModule,
        NzLayoutModule,
        NzTabsModule,
        NzDropDownModule,
        NewFnaTemplateModule,
        NzAlertModule,
    ]
})
export class ZuuFnaTemplateModule { }
