<nz-tabset [nzSize]="'large'" >
  <nz-tab [nzTitle]=UnderwritingMatter>
    <app-underwriting-to-do></app-underwriting-to-do>
  </nz-tab>
  <ng-template #UnderwritingMatter>
    {{instance.isFone() ? ('UnderwritingMatter' | translate) : 'Pending'}}
    <nz-badge [nzCount]="workbench.pendingCount"
              style="bottom: 5px; right: 13px"></nz-badge>
  </ng-template>
  <nz-tab [nzTitle]=PolicyMatters>
    <app-policy-to-do></app-policy-to-do>
  </nz-tab>
  <ng-template #PolicyMatters>
    {{instance.isFone() ? ('PolicyMatters' | translate) : 'Policy Service'}}
    <nz-badge [nzCount]="workbench.serviceCount"
              style="bottom: 5px; right: 13px"></nz-badge>
  </ng-template>
  <nz-tab [nzTitle]=LeaveMatters>
    <app-message-to-do></app-message-to-do>
  </nz-tab>
  <ng-template #LeaveMatters>
    {{'LeaveMessageToDo' | translate}}
    <nz-badge [nzCount]="workbench.bookingDialogCount + workbench.policyDialogCount + workbench.underwritingDialogCount"
              style="bottom: 5px; right: 13px"></nz-badge>
  </ng-template>
</nz-tabset>
