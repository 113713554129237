import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomSheetComponent } from './bottom-sheet.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { BottomSheetServiceModule } from './bottom-sheet.service.module';

@NgModule({
  imports: [
    CommonModule,
    CommonModule,
    OverlayModule,
    PortalModule,
    BottomSheetServiceModule
  ],
  exports: [
    BottomSheetComponent
  ],
  declarations: [
    BottomSheetComponent
  ],
  entryComponents: [BottomSheetComponent]
})
export class BottomSheetModule {

  constructor() {
  }

}
