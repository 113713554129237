<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!ticketTable">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="ticketTable" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="ticketTable.list"
                [nzPageIndex]="ticketTable.pageNum"
                [nzPageSize]="ticketTable.pageSize"
                [nzTotal]="ticketTable?.totalCount"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)"
                [nzScroll]="{x: '1450px'}">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'TicketsList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default"
                        nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'TicketsCreate' | translate" style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onTicketCreate()">
                  <img alt="" width="22" src="assets/images/ic_proposal_new.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'Search' | translate" style=" margin-left: 10px;"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight"
                        >
                  <img alt="" width="22" src="assets/images/ic_search.svg">
                </button>

              </div>
            </nz-col>
          </nz-row>
        </ng-template>

        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ ticketTable?.totalCount }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="165px" nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'TicketsNo' | translate">{{'TicketsNo' | translate}}</span>
          </th>
          <th nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'TicketTitle' | translate">{{'TicketTitle' | translate}}</span>
          </th>
          <th nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'Status' | translate">{{'Status' | translate}}</span>
          </th>
          <th nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'CreateTime' | translate">{{'CreateTime' | translate}}</span>
          </th>
          <th nzAlign="center">
            <span nz-tooltip [nzTooltipTitle]="'RecentResponseTime' | translate">{{'RecentResponseTime' | translate}}</span>
          </th>
          <th nzRight nzWidth="160px" nzAlign="center">
            <span>{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let ticket of ticketTable.list; let i = index"
            appThrottleClick (throttleClick)="onTicketDetail(ticket.id)">
          <td nzLeft nzAlign="center">
            <span style="font-weight: bold; font-size: 12px">
              {{ ticket.id }}
            </span>
          </td>
          <td nzEllipsis nzAlign="center"><span class="">{{ ticket.title }}</span></td>
          <td nzAlign="center">
            <div>
              <span> {{ ticket.status | ticketOrder | translate}}</span>
            </div>
          </td>
          <td nzAlign="center">
            <span>{{ ticket.createTime }}</span>
          </td>
          <td nzAlign="center">{{ ticket.lastReplyTime }}</td>
          <td nzRight nzAlign="center">
            <div class="functions">
<!--              <a nz-button nzType="link" nzSize="small" nz-tooltip-->
<!--                 [nzTooltipTitle]="'Detail' | translate">-->
<!--                <img alt="" width="20" src="../../../assets/images/ic_details.svg">-->
<!--              </a>-->

              <a *ngIf="ticket.status !== 'CLOSED'" nz-button nzType="link" nzSize="small" nz-tooltip
                 [nzTooltipTitle]="'CloseWorkOrder' | translate"
                 appThrottleClick (throttleClick)="onClose(ticket.id);$event.stopPropagation();">
                <i nz-icon nzType="close-circle" nzTheme="outline"
                   style="font-size: 17px;color: #404040;margin-top: 4px;"></i>
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>

<ng-template #filterTemplate>
  <div style="width: 400px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <!-- 名字 -->
    <app-property-input [title]="'TicketsNo' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('TicketsNo' | translate)"
                        [(value)]="search.id"></app-property-input>

    <app-property-select [title]="'Status' | translate"
                         [options]="statusOptions"
                         [placeholder]="('PleaseSelect' | translate) + ('Status' | translate)"
                         [(value)]="search.status"></app-property-select>

    <app-property-datetime-range [title]="'CreatedTime' | translate"
                                 [value]="createdDates"
                                 (valueChange)="onDateChange($event, 'startCreateDate')">
    </app-property-datetime-range>


    <app-property-datetime-range [title]="'RecentResponseTime' | translate"
                                 [value]="replyDates"
                                 (valueChange)="onDateChange($event, 'endReplyDate')">
    </app-property-datetime-range>

    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
