import { Component, OnInit } from '@angular/core';
import { SaleReportService } from './sale-report.service';
import { NewSaleReportReq, SaleListReq, SaleReportList, SaleReportListItem, SaleReportReq } from './sale-report-type';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { SaleReportListComponent } from './sale-report-list/sale-report-list.component';
import { PagedResp, TicketListResp } from '../api/types';

@Component({
  selector: 'app-sale-report-main',
  templateUrl: './sale-report-main.component.html',
  styleUrls: ['./sale-report-main.component.less']
})
export class SaleReportMainComponent implements OnInit {

  search: NewSaleReportReq = new NewSaleReportReq();
  spinning: boolean;
  loading: boolean;
  loadingShare: boolean;
  searchPopoverVisible: boolean;
  searchSharePopoverVisible: boolean;
  radioValue: string;

  listArr: PagedResp<SaleReportListItem>;
  listShareArr: SaleReportList[] = [];
  selectIndex: 0;

  constructor(private saleReportService: SaleReportService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
    // this.getListData();
    // this.getShareListData();
    this.search.module = 'POLICY';
    this.search.type = 'self';
    this.newGetList();
  }

  getListData(falg = false): void {
    this.loading = true;
    let search = new SaleReportReq();
    search = JSON.parse(JSON.stringify(this.search));
    search.type = 'self';
    this.saleReportService.list(search)
      .subscribe(
        data => {
          if (data.length) {
            data.forEach((item, i) => {
              item.expand = !!falg;
            });
          }
          console.log(data);
          this.listArr = data && data.length ? JSON.parse(JSON.stringify(data)) : [];
          this.loading = false;
          this.searchPopoverVisible = false;
        }, () => {
          this.loading = false;
        }
      );
  }

  newGetList(): void {
    this.spinning = true;
    this.saleReportService.newList(this.search)
      .subscribe(
        data => {
          this.listArr = JSON.parse(JSON.stringify(data));
          console.log(this.listArr, 'this.listShareArr');
          this.spinning = false;
        }, () => {
          this.spinning = false;
        }
      );
  }

  getShareListData(falg = false): void {
    this.loadingShare = true;
    let search = new SaleReportReq();
    search = JSON.parse(JSON.stringify(this.search));
    search.type = 'share';
    this.saleReportService.list(search)
      .subscribe(
        data => {
          data.forEach((item, i) => {
            item.expand = !!falg;
          });
          console.log(data);
          this.listShareArr = data && data.length ? JSON.parse(JSON.stringify(data)) : [];
          console.log(this.listShareArr, 'this.listShareArr');
          this.loadingShare = false;
          this.searchSharePopoverVisible = false;
        }, () => {
          this.loadingShare = false;
        }
      );
  }

  onReload(type: string): void {
    this.searchPopoverVisible = false;
    this.searchSharePopoverVisible = false;
    this.search = new NewSaleReportReq();
    if (type === 'self') {
      this.getListData();
    } else {
      this.getShareListData();
    }
  }
  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    if (!this.selectIndex) {
      this.getListData(true);
    } else {
      this.getShareListData(true);
    }
  }


  onSearchReload(): void {
    this.searchPopoverVisible = false;
    this.searchSharePopoverVisible = false;
    this.search = new NewSaleReportReq();
    if (this.selectIndex === 0) {
      this.getListData();
    } else {
      this.getShareListData();
    }
  }

  onShowExpand(val: SaleReportList): boolean {
    return val.expand = !val.expand;
  }

  onDetail(id: string): void {
    const params = new SaleListReq();
    params.policyId = id;
    params.productType = 'BASIC';
    const drawerRef = this.drawerService.create<SaleReportListComponent, { value: any }, string>({
      nzWidth: 600,
      nzContent: SaleReportListComponent,
      nzContentParams: {
        filterData: params
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
    });
    drawerRef.afterClose.subscribe(() => {
      // const component = drawerRef.getContentComponent();
    });
  }

  tabsIndex(e): void {
    console.log(e);
    this.selectIndex = e.index;
  }

}
