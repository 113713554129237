<div class="time-line" #timeline>
  <nz-timeline *ngIf="type == 'timeLine'">
    <nz-timeline-item *ngFor="let item of timeLineVOS; let i = index" nzColor="gray">
      <div>
        <p>
          {{ item.time | date:'yyyy-MM-dd HH:mm:ss'}}
        </p>
        <div *ngIf="item.type === 'PENDING' || item.type === 'SERVICE'; else elseBlock" class="time-line-detail-item">
          <div appThrottleClick (throttleClick)="onPendingDetail(item.data, item.type); $event.stopPropagation();">
            <div class="flex">

              <div>{{instance.isFone()
                ? ('ChangeProject' | translate)
                : modeType === 'underwriting'
                  ? 'Pending'
                  : item.type === 'PENDING' ? 'Pending' : ('PolicyService' | translate)}}: {{ item.data.project }}</div>
              <div style="color: #1d64c4"> {{item.data.status}}</div>
            </div>
            <div style="border-top: 1px solid #EDF2F9;border-bottom: 1px solid #EDF2F9;padding: 10px 0 5px;margin: 10px 0;">{{
                instance.isFone()
                  ? ('DeliveryDate' | translate)
                  : modeType === 'underwriting'
                    ? ('PendingDate' | translate)
                    : item.type === 'PENDING' ? ('PendingDate' | translate) : ('ServiceDate' | translate)}}: {{(item.data.deliveryDate | date:'yyyy-MM-dd') || 'N/A'}}</div>
            <div class="flex">
              <div>
<!--                <img width="20" style="border-radius: 50%; margin-right: 5px" [src]="item.data.avatar" alt="">-->
<!--                <nz-avatar *ngIf="!item.data.avatar" nzIcon="user" [nzSize]="'small'"></nz-avatar>-->
                <div *ngIf="!item.data.avatar">
                  <nz-avatar *ngIf="!workbench.hiddenLastName" [nzSize]="'small'" [nzText]="(item.data.lastName.charAt(0))"></nz-avatar>
                  <nz-avatar *ngIf="workbench.hiddenLastName" [nzSize]="'small'" nzIcon="user"></nz-avatar>
                </div>
                <nz-avatar *ngIf="item.data.avatar" [nzSrc]="item.data.avatar" [nzSize]="'small'"></nz-avatar>
                {{item.data?.lastName}}
              </div>
              <div>{{'Deadline' | translate}}: {{(item.data.deadlines | date:'yyyy-MM-dd') || 'N/A' }} </div>
            </div>
          </div>
          <nz-divider *ngIf="item.data.attachments && item.data.attachments.length"></nz-divider>
          <div *ngIf="showAttachments">
            <app-attachments *ngIf="item.data.attachments && item.data.attachments.length" [readonly]="true"
                             [attachments]="item.data.attachments"></app-attachments>
          </div>
        </div>
        <ng-template #elseBlock>
          <div class="time-line-detail-item">
            <div appThrottleClick (throttleClick)="openStatus(item); $event.stopPropagation();">
              <div>{{item.data.title }}</div>
              <div style="color: #999999;font-size: 12px;margin-top: 8px"
                   *ngIf="item.data.message">{{item.data.message}}</div>
            </div>
            <nz-divider *ngIf="item.data.attachments && item.data.attachments.length"></nz-divider>
            <div *ngIf="showAttachments">
              <app-attachments *ngIf="item.data.attachments && item.data.attachments.length" [readonly]="true"
                               [attachments]="item.data.attachments"></app-attachments>
            </div>
          </div>
        </ng-template>
      </div>

    </nz-timeline-item>
  </nz-timeline>
  <div *ngIf="type == 'pendings' || type == 'service'">
    <div *ngFor="let item of pendings; let i = index" nzColor="gray">
      <div>
        <div class="pend-line-detail-item" appThrottleClick
             (throttleClick)="onPendingDetail(item, item.type); $event.stopPropagation();">
          <div class="flex">
            <div [style]="item.finish ? 'color: #1d64c4' : 'color:#e63757'"> {{item.status}}</div>
            <p>
              #{{ item.pendingNo || item.serviceNo }}
            </p>
          </div>

          <div class="property-bottom-line-blue"></div>

          <div style="margin-top: 8px"> {{item.project}}</div>
          <div style="color:#999999;font-size: 14px">
            <span
              style="margin-right: 4px">{{'ApplicationTime' | translate }} {{ item.applyTime | date:'yyyy-MM-dd HH:mm:ss'}}</span>
            <span>
             <img alt="" style="color: #999999; margin-right:2px" width="14"
                  src="assets/images/ic_leave_message.svg">{{ item.messageCount }}
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- 状态详情 -->
  <nz-drawer [(nzVisible)]="visible" [nzWidth]="600" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
      <div class="common-title" style="margin:20px 0px 40px 20px;">
        {{'StatusDetail' | translate}}
      </div>
      <div *ngIf="statusDetail" class="status-detail">
        <div class="status-detail-head">
          {{statusDetail.time | date:'yyyy-MM-dd HH:mm:ss'}}
        </div>
        <div class="status-detail-context">
          <div>{{statusDetail.data.title}}</div>
          <div style="color: #999999;font-size: 12px;margin-top: 8px">{{statusDetail.data.message}}</div>
        </div>
        <div *ngIf="showAttachments" style="margin-bottom: 5px">
          <app-attachments [attachments]="statusDetail.data.attachments" [readonly]="true"
                           *ngIf="statusDetail.data.attachments && statusDetail.data.attachments.length">
          </app-attachments>
        </div>
      </div>
    </ng-container>
  </nz-drawer>

  <!---->
  <nz-drawer [(nzVisible)]="pendingVisible" [nzWidth]="800" (nzOnClose)="pendClose()">
    <ng-container *nzDrawerContent>
      <div style="padding: 15px">
        <div class="common-title" style="margin:20px 0 40px 20px;">
          {{instance.isFone()
          ? ('MatterRecord' | translate)
          : modeType === 'underwriting'
            ? ('PendingRecord' | translate)
            : detailType === 'PENDING' ? ('PendingRecord' | translate) : ('ServiceRecord' | translate)}}
        </div>
        <div class="flex" style="margin: 0 15px 0">
          <div [ngSwitch]="pendingDetail.underwritingStatus">
            <div>
              <span *ngSwitchCase="'UNDERWRITING'" class="text-danger">
                ● <span style="font-size: 16px;font-weight:bold">  {{ pendingDetail.status }}  </span>
              </span>
              <span *ngSwitchCase="'CHECKING'" class="text-processing">
                ● <span style="font-size: 16px;font-weight:bold">  {{ pendingDetail.status }}  </span>
              </span>
              <span *ngSwitchDefault>
                <span style="font-size: 16px;font-weight:bold"> {{ pendingDetail.status }} </span>
              </span>
            </div>
          </div>
          <div
            style="color: #999999;font-size: 12px">{{'Deadline' | translate}}: {{(pendingDetail.deadlines | date:'yyyy-MM-dd') || 'N/A' }}</div>
        </div>
        <div class="status-detail" appThrottleClick (throttleClick)="detailOpen()">
          <div style="padding: 10px 0px 10px 0px">
            <div
              style="font-weight: bold; font-size: 16px">{{instance.isFone()
              ? ('ChangeProject' | translate)
              : modeType === 'underwriting'
                ? 'Pending'
                : detailType === 'PENDING' ? 'Pending' : ('PolicyService' | translate) }}{{ ':' + pendingDetail.project}}</div>
            <div
              style="color: #999999;font-size: 12px">{{instance.isFone()
              ? ('DeliveryDate' | translate)
              : modeType === 'underwriting'
                ? ('PendingDate' | translate)
                : detailType === 'PENDING' ? ('PendingDate' | translate) : ('ServiceDate' | translate)}} {{pendingDetail.deliveryDate | date:'yyyy-MM-dd'}}</div>
            <div style="display: flex;align-items: center;">
              <span style="color: #999999;font-size: 12px;white-space: nowrap;margin-right: 4px;">{{instance.isFone()
                ? ('TodoContent' | translate)
                : modeType === 'underwriting'
                  ? ('PendingContent' | translate)
                  : detailType === 'PENDING' ? ('PendingContent' | translate) : ('ServiceContent' | translate)}}</span>
              <span class="inner-remark" [innerHTML]="pendingDetail.remark"></span>
            </div>
          </div>
        </div>
        <div class="status-detail" *ngIf="pendingReply && pendingReply.length > 0">
          <div class="status-detail-context">
            <div *ngFor="let item of pendingReply" class="list-item">
              <div class="list-top">
                <nz-avatar class="list-top-avatar" style="margin-right: 20px;" [nzSize]="36"
                           [nzSrc]="item.avatar"></nz-avatar>
                <div>
                  <div style="display: flex;justify-content: flex-start;align-items: center;">
                    <div class="list-top-name" style="margin-right: 10px;">{{ item.accountName }}</div>
                    <span class="list-top-myReply"
                          *ngIf="item.accountName === accountName"
                          style="color: #4B84E5; font-size: 12px; border: 1px solid #4B84E5; border-radius: 6px">{{ 'Oneself' | translate }}</span>
                  </div>
                  <div>{{item.time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
                </div>
              </div>
              <div class="list-bottom">
                <div class="ticket-reply-content" [innerHTML]="item.content | html"></div>
                <app-attachments *ngIf="item.attachments?.length > 0"
                                 [attachments]="item.attachments"
                                 [readonly]="true"></app-attachments>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="instance.isFoneOrDev() ? !pendingDetail.ultimate: true">
          <div style="width: 100%;">
            <div class="section-wrapper" style="margin: 15px 15px 10px 15px">
              <app-property-editor [bottomLineVisible]="false"
                                   [required]="true"
                                   [title]="'Content' | translate"
                                   [placeholder]="('PleaseEnter' | translate) + ('ReplyContent' | translate)"
                                   [(value)]="pendingDetailReply.content"></app-property-editor>
            </div>
            <div style="margin: 15px 15px 10px 15px">
              <app-attachments [attachments]="pendingDetailReply.attachments"
                               [uploadTokenReq]="uploadTokenReq"
                               [isPreview]="true"
                               (valueChange)="pendingAttachmentsChange($event)"
                               [showEmpty]="false"></app-attachments>
            </div>
            <div style="margin: 15px 15px 10px 15px">
              <div nz-col nzSpan="24" class="footer-button-primary">
                <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="reply()"
                   [disabled]="!canReply"
                   [style]="!canReply ? 'opacity: 0.5' : ''">
                  <span>{{ 'Reply' | translate }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </nz-drawer>


  <!-- 事项详情 -->
  <nz-drawer [(nzVisible)]="detailVisible" [nzWidth]="600" (nzOnClose)="detailClose()">
    <ng-container *nzDrawerContent>
      <div class="common-title" style="margin:20px 0px 40px 20px;">
        {{instance.isFone()
        ? ('MatterDetail' | translate)
        : modeType === 'underwriting'
          ? ('PendingDetail' | translate)
          : detailType === 'PENDING' ? ('PendingDetail' | translate) : ('ServiceDetail' | translate)}}
      </div>
      <div class="section-title" style="margin:10px 15px 10px 15px">
        <span style="margin-left: 8px"> {{ 'IDNumber' | translate }}: {{pendingDetail.pendingNo}} </span>
      </div>
      <div class="status-detail">
        <div class="status-detail-context">
          <nz-row [nzGutter]="12">
            <nz-col [nzSpan]="24">
              <app-property-text [title]="'Insurer' | translate"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.companyName"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="'Insured' | translate"
                                 [displayMode]="'vertical'"
                                 [isEncryption]="isDynamicEncryption('insuredName')"
                                 [value]="pendingDetail.insuredName"></app-property-text>
            </nz-col>

            <nz-col [nzSpan]="12">
              <app-property-text [title]="'Holder' | translate"
                                 [displayMode]="'vertical'"
                                 [isEncryption]="isDynamicEncryption('ownerName')"
                                 [value]="pendingDetail.ownerName"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="'Status' | translate"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.status"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="(instance.isFone()
              ? ('DeliveryDate' | translate)
              : modeType === 'underwriting'
              ? ('PendingDate' | translate)
              : detailType === 'PENDING' ? ('PendingDate' | translate) : ('ServiceDate' | translate))"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.deliveryDate | date: 'yyyy-MM-dd' "></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="'ArchiveDate' | translate"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.archiveDate | date: 'yyyy-MM-dd' "></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="'ExpirationDate' | translate"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.deadlines | date: 'yyyy-MM-dd' "></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="'CaseCloseDate' | translate"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.finishDate | date: 'yyyy-MM-dd' "></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="(instance.isFone()
              ? ('ChangeProject' | translate)
              : modeType === 'underwriting'
              ? 'Pending'
              : detailType === 'PENDING' ? 'Pending' :('PolicyService' | translate))"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.project"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="'CreateUserName' | translate"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.organizer"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="12">
              <app-property-text [title]="'Administrator' | translate"
                                 [displayMode]="'vertical'"
                                 [value]="pendingDetail.receiver"></app-property-text>
            </nz-col>
            <nz-col [nzSpan]="24">
              <app-property-text [title]="(instance.isFone()
              ? ('TodoContent' | translate)
              : modeType === 'underwriting'
              ? ('PendingContent' | translate)
              : detailType === 'PENDING' ? ('PendingContent' | translate) : ('ServiceContent' | translate))"
                                 [displayMode]="'vertical'"
                                 [textType]="'html'"
                                 [value]="pendingDetail.remark"></app-property-text>
            </nz-col>
          </nz-row>
        </div>
      </div>
      <div class="section-title" style="margin:10px 15px 10px 15px"
           *ngIf="pendingDetail.attachments && pendingDetail.attachments.length">
        <span style="margin-left: 8px"> {{ 'File' | translate }}</span>
      </div>

      <app-attachments [attachments]="pendingDetail.attachments" [readonly]="true"
                       *ngIf="pendingDetail.attachments && pendingDetail.attachments.length">
      </app-attachments>

    </ng-container>
  </nz-drawer>
</div>
