<!--<div class="container">-->

<!--  <div style="font-size: 18px; margin: 0 0 20px 0">-->
<!--    {{'ProductComparison' | translate}}-->
<!--  </div>-->

<!--</div>-->

<nz-tabset [nzSize]="'large'" [nzSelectedIndex]="0">
  <nz-tab [nzTitle]=ProductComparison>
    <div class="comparison-container">
      <nz-row>
        <nz-col [nzSpan]="10">
          <div class="comparison-column" style="margin-right: 10px">
            <div class="comparison-column-header">
              <div class="comparison-title" style="flex: 1;">{{'PrimaryProduct' | translate}}</div>
              <button *ngIf="primaryProduct" nz-button nzType="default" nzSize="default" nzShape="circle"
                      style="margin-right: 10px" nz-tooltip
                      [nzTooltipTitle]="'Reset' | translate"
                      appThrottleClick (throttleClick)="onClearPrimaryProduct()">
                <i nz-icon nzType="clear" nzTheme="outline"></i>
              </button>
              <button nz-button nzType="default" nzSize="default" nzShape="circle" nz-tooltip
                      [nzTooltipTitle]="'SelectPrimaryProduct' | translate"
                      appThrottleClick (throttleClick)="onSelectPrimaryProduct()">
                <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
              </button>
            </div>
            <div class="comparison-column-content">
              <div *ngIf="primaryProduct; else primaryEmpty">
                <app-product-list-item [product]="primaryProduct"></app-product-list-item>
              </div>
              <ng-template #primaryEmpty>
                <div class="centre-container" style="height: 100%">
                  <app-empty [text]="('PleaseSelect' | translate) + ('PrimaryProduct' | translate)"></app-empty>
                </div>
              </ng-template>
            </div>
          </div>
        </nz-col>
        <nz-col [nzSpan]="14">
          <div class="comparison-column" style="margin-right: 10px">
            <div class="comparison-column-header">
              <div style="flex: 1; font-size: 16px">
                <span class="comparison-title" style="margin: 0 10px 0 0;">{{'SecondaryProducts' | translate}}</span>
                <nz-badge *ngIf="secondaryProducts && secondaryProducts.length > 0"
                          nzStandalone [nzCount]="secondaryProducts.length"
                          [nzStyle]="{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }"
                ></nz-badge>
              </div>
              <button *ngIf="secondaryProducts && secondaryProducts.length > 0" nz-button nzType="default"
                      nzSize="default" nzShape="circle" style="margin-right: 10px" nz-tooltip
                      [nzTooltipTitle]="'Reset' | translate"
                      appThrottleClick (throttleClick)="onClearSecondaryProducts()">
                <i nz-icon nzType="clear" nzTheme="outline"></i>
              </button>
              <button *ngIf="primaryProduct" nz-button nzType="default" nzSize="default" nzShape="circle" nz-tooltip
                      [nzTooltipTitle]="'SelectSecondaryProducts' | translate"
                      appThrottleClick (throttleClick)="onAddSecondaryProducts()">
                <i nz-icon nzType="plus" nzTheme="outline"></i>
              </button>
              <button nz-button nzType="primary" nzSize="default" nzShape="round"
                      [disabled]="!canCompare"
                      [style]="!canCompare ? 'opacity: 0.5' : ''"
                      appThrottleClick (throttleClick)="onCompare()">
                <span>{{'GoCompare' | translate}}</span>
              </button>
            </div>
            <div class="comparison-column-content">
              <div *ngIf="secondaryProducts && secondaryProducts.length > 0; else secondaryEmpty">
                <div *ngFor="let product of secondaryProducts; let i = index">
                  <app-product-list-item [product]="product" [showDeleteButton]="true"
                                         (deleteEvent)="onSecondaryProductDelete(product, i)"></app-product-list-item>
                </div>
              </div>
              <ng-template #secondaryEmpty>
                <div class="centre-container" style="height: 100%">
                  <app-empty [text]="('PleaseSelect' | translate) + ('SecondaryProducts' | translate)"></app-empty>
                </div>
              </ng-template>
            </div>
          </div>
        </nz-col>
      </nz-row>
      <!--    <nz-row [nzGutter]="16">-->
      <!--      <div class="comparison-footer">-->
      <!--        <button nz-button nzType="primary" nzSize="large" nzShape="round"-->
      <!--                [disabled]="!canCompare"-->
      <!--                [style]="!canCompare ? 'opacity: 0.5' : ''"-->
      <!--                style="margin-top: 20px; height: 60px"-->
      <!--                appThrottleClick (throttleClick)="onCompare()">-->
      <!--          <span>{{'GoCompare' | translate}}</span>-->
      <!--        </button>-->
      <!--      </div>-->
      <!--    </nz-row>-->
    </div>
  </nz-tab>
  <ng-template #ProductComparison>
    {{'ProductComparison' | translate}}
  </ng-template>
  <nz-tab [nzTitle]=CompareHistory>
    <div *ngIf="companyHistoryData" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="spinning"
                [nzFrontPagination]="false"
                [nzData]="companyHistoryData.list"
                [nzPageIndex]="companyHistoryData.pageNum"
                [nzPageSize]="companyHistoryData.pageSize"
                [nzTotal]="companyHistoryData.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'ProductComparisonHistory' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black;" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ companyHistoryData.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzWidth="50px"></th>
          <th>{{'ComparisonNumber' | translate}}</th>
          <th>{{'CreateTime' | translate}}</th>
          <th>{{'State' | translate}}</th>
          <th>{{'Functions' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <ng-template ngFor let-contacts [ngForOf]="companyHistoryData.list">
<!--          appThrottleClick (throttleClick)="onDetail(contacts.id)"-->
          <tr >
            <td nzWidth="50px" [(nzExpand)]="contacts.expand" appThrottleClick
                (throttleClick)="$event.stopPropagation();"></td>
            <td>{{contacts.id}}</td>
            <td>{{(contacts.time | date: 'yyyy-MM-dd HH:mm') || 'N/A'}}</td>
            <td>
              <div [ngSwitch]="contacts.status">
                <div>
                  <span *ngSwitchCase="'DRAFT'" class="text-warning">●</span>
                  <span *ngSwitchCase="'PENDING'" class="text-warning">●</span>
                  <span *ngSwitchCase="'APPLIED'" class="text-processing">●</span>
                  <span *ngSwitchCase="'CONFIRMED'" class="text-processing">●</span>
                  <span *ngSwitchCase="'COMPLETED'" class="text-success">●</span>
                  <span *ngSwitchCase="'CANCELLED'" class="text-cancel">●</span>
                  <span> {{ contacts.status | metadataTranslate: 'proposalStatus' }}</span>
                </div>
              </div>
            </td>
            <td>
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate"
                 appThrottleClick (throttleClick)="onDetail(contacts.id); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_details.svg">
              </a>
              <a *ngIf="contacts.status == 'COMPLETED'" nz-tooltip [nzTooltipTitle]="'Download' | translate" nz-dropdown
                 appThrottleClick (throttleClick)="$event.stopPropagation();"
                 [nzDropdownMenu]="menu" nzTrigger="click">
                <img alt="" width="20" src="assets/images/ic_download.svg">
              </a>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                  <li nz-menu-item appThrottleClick
                      (throttleClick)="onDownload(contacts.urlCn)">{{ 'CN' | translate}}</li>
                  <li nz-menu-item appThrottleClick
                      (throttleClick)="onDownload(contacts.urlHk)">{{ 'HK' | translate}}</li>
                  <li nz-menu-item appThrottleClick
                      (throttleClick)="onDownload(contacts.urlUs)">{{ 'EN' | translate}}</li>
                </ul>
              </nz-dropdown-menu>
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
                 appThrottleClick
                 (throttleClick)="onDelete(contacts, tplTitle, tplContent, tplFooter); $event.stopPropagation();">
                <img alt="" width="20" src="assets/images/ic_delete.svg">
              </a>
            </td>
          </tr>
          <tr [nzExpand]="contacts.expand" appThrottleClick (throttleClick)="$event.stopPropagation();">
            <nz-table #innerTable [nzData]="contacts.rightProductEntities" nzSize="middle" [nzShowPagination]="false">
        <thead>
        <tr>
          <th nzWidth="200px">{{'ProductName' | translate}}</th>
          <th nzWidth="200px">{{'InsuranceType' | translate}}</th>
          <th nzWidth="200px">{{'Insurer' | translate}}</th>
          <th>{{'ProductIntroduction' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            {{productIntro(contacts?.leftProductCode)?.productName}}
          </td>
          <td>{{productIntro(contacts?.leftProductCode)?.categoryName}}</td>
          <td>
            <img width="42" style="border-radius: 3px; margin-right: 5px" [src]="contacts.leftProductEntity.logo" alt=""/>
            <span>{{ companieInfo(contacts.leftProductCode) }}</span>
          </td>
          <td>
            <div>{{ productIntro(contacts?.leftProductCode)?.desc }}</div>
          </td>
        </tr>
        <tr *ngFor="let data of contacts.rightProductEntities">
          <td>
            {{productIntro(data.productCode)?.productName || data?.productName}}
          </td>
          <td>{{productIntro(data.productCode)?.categoryName}}</td>
          <td>
            <img width="42" style="border-radius: 3px; margin-right: 5px"
                 [src]="data.logo"
                 alt=""/>
            {{companieInfo(data.productCode)}}
          </td>
          <td>{{productIntro(data.productCode)?.desc}}</td>
        </tr>
        </tbody>
      </nz-table>
      </tr>
      </ng-template>
      </tbody>
      </nz-table>
    </div>
  </nz-tab>
  <ng-template #CompareHistory>
    {{'History' | translate}}
  </ng-template>
</nz-tabset>

<ng-template #tplTitle>
  <span>{{'Delete' | translate}}</span>
</ng-template>
<ng-template #tplContent let-params>
  <p>{{'DeleteProductComparison' | translate}} :「{{ params.id }}」?</p>
</ng-template>
<ng-template #tplFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()"
          [disabled]="deleting">{{'Cancel' | translate}}</button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onDeleteConfirm(ref, params)"
          [nzLoading]="deleting">
    {{'Delete' | translate}}
  </button>
</ng-template>
