import { Component, OnDestroy } from '@angular/core';
import { DisposeBag } from '../shared/types/dispose-bag';

@Component({
  template: ''
})
export abstract class BaseComponent implements OnDestroy {

  disposeBag: DisposeBag;

  protected constructor() {
    this.disposeBag = new DisposeBag();
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

}
