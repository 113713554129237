import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FnaQuestion } from '../../fna/fna-types';
import { plainToArray } from '../../shared/utils/class-transform';
import {
  FinancialSave,
  NewFnaDetail,
  QuestionsArrCMG
} from '../../new-fna/new-fna-types';
import { KeyValue } from '../../api/types';
import { ActivatedRoute } from '@angular/router';
import { NewFnaService } from '../../new-fna/new-fna.service';
import { FnaTemplateQuestionComponent } from '../fna-template-question/fna-template-question.component';

@Component({
  selector: 'app-fna-form-template',
  templateUrl: './fna-form-template.component.html',
  styleUrls: ['./fna-form-template.component.less']
})
export class FnaFormTemplateComponent implements OnInit {
  fnaQuestions: FnaQuestion[] = [];
  fnaQuestionsCopy: FnaQuestion[] = [];
  checked = true;
  otherExisting = true;
  policy2 = false;
  rentalIncomeAmount: string;
  fnaForm: KeyValue[] = [];
  @Input()
  fnaInfo: NewFnaDetail = new NewFnaDetail();

  @ViewChild(FnaTemplateQuestionComponent)
  questionTemplate: FnaTemplateQuestionComponent;


  constructor(private router: ActivatedRoute,
              private newFnaService: NewFnaService) { }

  ngOnInit(): void {
    // this.router
    //   .queryParams
    //   .subscribe(params => {
    //     console.log(params);
    //     this.onDetail(params['id']);
    //   });
    this.fnaQuestions = plainToArray(FnaQuestion, QuestionsArrCMG);
  }

  setForms(fnaInfo: NewFnaDetail): void {
    let formObj = {};
    if (fnaInfo.financialAnalysis && fnaInfo.financialAnalysis.length) {
      formObj = fnaInfo.financialAnalysis.reduce((prev, c) => ({...prev, [c.key]: c.value}), {});
    }
    this.fnaQuestions.forEach(question => {
      this.setQuestions(question, formObj);
    });
    const arr = ['FNA_06_b_Insurer_Name_02', 'FNA_06_b_Interest_Rate_02',
      'FNA_06_b_Lender_Name_02', 'FNA_06_b_Loan_Amount_02', 'FNA_06_b_Policy_Number_02',
      'FNA_06_b_Repayment_Amount_01_02',
      'FNA_06_b_Repayment_Amount_02_02', 'FNA_06_b_Repayment_Term_02'];
    this.policy2 = arr.some(item => !!formObj[item]);
    this.fnaQuestionsCopy = JSON.parse(JSON.stringify(this.fnaQuestions));
  }
  // value回填form
  setQuestions(question: FnaQuestion, formObj): void {
    if (question.answers) {
      question.answers.forEach(answer => {
        switch (answer.moduleType) {
          case 'CHECKBOX_WITH_INPUT':
            answer.tick = formObj[answer.key];
            answer.inputBox?.forEach(item => {
              item.value = formObj[item.key];
            });
            if (answer.key === 'FNA_01_B') {
              answer.subQuestions.map(item => {
                item.tick = formObj[item.subKey];
              });
            }
            break;
          case 'RADIO_WITH_INPUT':
            if (answer.key == 'FNA_06_a_A') {
              // answer.tick = formObj[answer.key];
              // answer.subQuestions.forEach(item => {
              //   this.setQuestions(item, formObj);
              // });
            } else if (answer.key == 'FNA_05_sub') {
              answer.subQuestions.forEach(item => {
                this.setQuestions(item, formObj);
              });
            } else {
              answer.tick = formObj[answer.key];
              answer.inputBox?.forEach(item => {
                item.value = formObj[item.key];
              });
            }
            break;
          case 'RADIO':
            if (answer.key == 'FNA_06_b_A') {
              console.log(33333333);
              // answer.tick = formObj[answer.key];
              // answer.subQuestions.forEach(item => {
              //   item.answers.forEach(answerItem => {
              //     answerItem.inputBox?.forEach(inputBoxItem => {
              //       inputBoxItem.value = formObj[inputBoxItem.key];
              //     });
              //   });
              // });
            } else {
              answer.tick = formObj[answer.key];
            }
            break;
          case 'CHECKBOX':
            answer.tick = formObj[answer.key];
            break;
          case 'INPUT_BOX':
            answer.inputBox.forEach(item => {
              if (answer.key == 'FNA_05_b') {
                if (formObj[answer.key]) {
                  item.value = formObj[answer.key];
                } else {
                  item.value = this.fnaInfo.basicInformation?.totalLiquidAssets;
                }
              }
            });
            break;
          default:
            answer.subQuestions.forEach(item => {
              item.tick = formObj[item.subKey];
              if (item.subKey === 'FNA_06_a_A' || item.subKey === 'FNA_06_b_A') {
                item.subQuestions.forEach(info => {
                  info.answers.forEach(answerInfo => {
                    answerInfo.inputBox.forEach(inputBoxItem => {
                      inputBoxItem.value = formObj[inputBoxItem.key];
                    });
                  });
                });
              }
            });
            break;
        }
      });
    }
  }

  onSave(): void {
    console.log(this.fnaQuestions);
    const arr2 = [];
    console.log(this.fnaQuestions, 'sdjfiosdjfjsdifjsdiofjiods');
    this.fnaQuestions.forEach(question => {
      this.saveFormObj(question, arr2);
    });
    console.log(arr2, 'arr2arr2arr2arr2arr2');
    const param = new FinancialSave();
    param.id = this.fnaInfo.id;
    param.dataList = JSON.parse(JSON.stringify(arr2));
    console.log(param, 'basicInformation');
    // this.newFnaService.updateFinancial(param)
    //   .subscribe(data => {
    //     console.log(data, 'basicInfo');
    //     this.formSaveChange.emit(data);
    //   });
  }

  saveFormObj(question: FnaQuestion, arr2: KeyValue[]): void {
    if (question.answers) {
      question.answers.forEach(answer => {
        // console.log(answer);
        switch (answer.moduleType) {
          case 'CHECKBOX_WITH_INPUT':
            arr2.push({key: answer.key, value: answer.tick});
            answer.inputBox?.forEach(item => {
              arr2.push({key: item.key, value: item.value});
            });

            if (answer.key === 'FNA_01_B') {
              answer.subQuestions.map(item => {
                arr2.push({key: item.subKey, value: item.tick});
              });
            }
            break;
          case 'RADIO_WITH_INPUT':
            if (answer.key == 'FNA_06_a_A') {
              console.log(answer.key, 'FNA_06_a_A');
              // arr2.push({key: answer.key, value: answer.tick});
            } else if (answer.key == 'FNA_05_sub') {
              answer.subQuestions.forEach(item => {
                this.saveFormObj(item, arr2);
              });
            } else {
              arr2.push({key: answer.key, value: answer.tick});
              answer.inputBox?.forEach(item => {
                arr2.push({key: item.key, value: item.value});
              });
            }
            break;
          case 'RADIO':
            if (answer.key == 'FNA_06_b_A') {
              arr2.push({key: answer.key, value: answer.tick});
              console.log(answer, '000000');
            } else {
              arr2.push({key: answer.key, value: answer.tick});
            }
            break;
          case 'CHECKBOX':
            arr2.push({key: answer.key, value: answer.tick});
            break;
          case 'INPUT_BOX':
            answer.inputBox.forEach(item => {
              if (answer.key == 'FNA_05_b') {
                arr2.push({key: answer.key, value: item.value});
              }
            });
            break;
          default:
            answer.subQuestions.forEach(item => {
              arr2.push({key: item.subKey, value: item.tick});
              if (item.subKey === 'FNA_06_a_A' || item.subKey === 'FNA_06_b_A') {
                item.subQuestions.forEach(info => {
                  info.answers.forEach(answerInfo => {
                    answerInfo.inputBox.forEach(inputBoxItem => {
                      arr2.push({key: inputBoxItem.key, value: inputBoxItem.value});
                    });
                  });
                });
              }
            });
            break;
        }
      });
    }

  }

  onCancel(): void {
    console.log('onCancel');
  }

  onDetail(id: number): void {
    this.newFnaService.info(id)
      .subscribe(
        fna => {
          this.setForms(fna);
        },
        error => {
        });
  }

  log(value: string[]): void {
    console.log(value);
  }

  newFnaChange(info: FnaQuestion): void {
    this.fnaQuestions.forEach(item => {
      if (item.questionNo === info.questionNo) {
        item = JSON.parse(JSON.stringify(info));
      }
    });
    console.log(this.fnaQuestions);
  }

}
