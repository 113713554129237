import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/service/data.service';
import { WorkbenchService } from '../workbench/workbench.service';
import { Workbench } from '../workbench/workbench-types';

@Component({
  selector: 'app-policy-reminder-main',
  templateUrl: './policy-reminder-main.component.html',
  styleUrls: ['./policy-reminder-main.component.less']
})
export class PolicyReminderMainComponent implements OnInit {

  workbench: Workbench;

  constructor(public dataService: DataService,
              private workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
  }

}
