import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PolicySearch } from '../policy-reminder-types';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { MetadataService } from '../../shared/service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ProductSelectionComponent, ProductSelectionType } from '../../shared/component/product-selection/product-selection.component';
import { formatDate } from '@angular/common';
import { DataMode } from '../../api/types';
import { Observable } from 'rxjs/internal/Observable';
import { ContactService } from '../../contact/contact.service';
import { SubAccountListRes } from '../../contact/contact-types';
import { NzOptionComponent } from 'ng-zorro-antd/select';
import { Tool } from '../../shared/utils/tool';

@Component({
  selector: 'app-policy-reminder-search',
  templateUrl: './policy-reminder-search.component.html',
  styleUrls: ['./policy-reminder-search.component.less']
})
export class PolicyReminderSearchComponent implements OnInit {
  @Input()
  search: PolicySearch;
  @Output()
  policySearch: EventEmitter<PolicySearch> = new EventEmitter<PolicySearch>();
  statusOptions: PropertySelectOption[] = [];
  policyDateMonth: number;
  @Input()
  mode = DataMode.OWN;
  accountNameOptions: SubAccountListRes[] = [];
  monthOptions: PropertySelectOption[] = [];
  ddaRequestOptions: PropertySelectOption[] = [];

  constructor(private metadataService: MetadataService,
              private translate: TranslateService,
              private drawerService: NzDrawerService,
              private contactService: ContactService) {
  }

  ngOnInit(): void {
    const list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    // tslint:disable-next-line:forin
    for (const listKey in list) {
      this.monthOptions.push(new PropertySelectOption(list[listKey].toString(), list[listKey]));
    }

    this.translate.get(['Yes', 'No']).subscribe(
      (res: any) => {
        this.ddaRequestOptions.push(new PropertySelectOption(res.Yes, true));
        this.ddaRequestOptions.push(new PropertySelectOption(res.No, false));
      });

    const statusList = this.metadataService.values('paymentStatus');
    for (const status of statusList) {
      this.statusOptions.push(new PropertySelectOption(status.value, status.key));
    }
    this.getAccountList();
  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.DEFAULT,
        copyProduct: this.search.product
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.search.product = product;
            this.search.productCode = this.search.product.productCode;
            drawerRef.close();
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }
  onBirthdayChange(timestamp: number): void {
    if (!timestamp) {
      return;
    }
    this.policyDateMonth = timestamp;
    const date = new Date(timestamp);
    this.search.policyDateMonth = formatDate(date, 'yyyy-MM', 'en-US');
  }
  onReset(): void {
    this.search = new PolicySearch();
    this.policySearch.emit(this.search);
  }

  onSearch(): void {
    this.policySearch.emit(this.search);
  }

  getAccountList(): void {
    let observable: Observable<any>;
    switch (this.mode) {
      case 'SUBORDINATE':
        observable = this.contactService.getSubList();
        break;
      case 'SALESGROUP':
        observable = this.contactService.getTeamList();
        break;
    }
    observable?.subscribe(
      data => {
        console.log(data);
        this.accountNameOptions = JSON.parse(JSON.stringify(data)) || [];
      },
      error => {
      }
    );
  }

  filterOption(input?: string, option?: NzOptionComponent): boolean {
    return Tool.filterOption(input, option);
  }

}
