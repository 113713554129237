import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ProposalService} from '../../proposal.service';
import { BankInfoSetting, BankInfoSearch } from '../../proposal-types';
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-back-info',
  templateUrl: './back-info.component.html',
  styleUrls: ['./back-info.component.less']
})
export class BackInfoComponent implements OnInit {
  drawerRef: NzDrawerRef<BackInfoComponent, string>;
  search: BankInfoSearch = new BankInfoSearch();
  dataList: BankInfoSetting[] = [];
  loading = false;
  searchParamChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  backInfoSelected: EventEmitter<BankInfoSetting> = new EventEmitter<BankInfoSetting>();
  constructor(private proposalService: ProposalService) { }

  ngOnInit(): void {
    this.getList();
    this.searchParamChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          this.getList();
        }
      );
  }

  onSearchParamChange(): void {
    this.searchParamChange.emit('');
  }


  getList(): void {
    this.loading = true;
    this.proposalService.allBankInfo(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.dataList = data;
        },
        error => {}
      );
  }

  onInfoSelected(val): void {
    this.drawerRef?.close();
    this.backInfoSelected.emit(val);
  }
}
