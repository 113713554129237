<nz-spin [nzSpinning]="spinning">
  <nz-tabset [nzSize]="'large'" >
    <nz-tab [nzTitle]=myDeals>
      <app-pre-sales-list [mode]="dataService.dataModeOwn"></app-pre-sales-list>
    </nz-tab>
    <ng-template #myDeals>
      {{'MyDeals' | translate}}
    </ng-template>
    <nz-tab [nzTitle]=channelDeals *ngIf="workbench.pivot !== dataService.pivotOwn">
      <app-pre-sales-list [mode]="dataService.dataModeSubordinate"></app-pre-sales-list>
    </nz-tab>
    <ng-template #channelDeals>
      {{'MySubordinates' | translate}}
    </ng-template>
  </nz-tabset>
</nz-spin>
