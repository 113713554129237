<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'系统信息' | translate}}
    </div>

    <div class="section-wrapper">
      <app-property-input [title]="'Insurer' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('Insurer' | translate)"
                          [required]="true"
                          [(value)]="declarationFrom.DECLARATION_insurance_company"></app-property-input>

      <app-property-input [title]="'ProductName' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('ProductName' | translate)"
                          [required]="true"
                          [(value)]="declarationFrom.DECLARATION_product_name"></app-property-input>

      <app-property-input [title]="'PolicyNumber' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('PolicyNumber' | translate)"
                          [required]="true"
                          [(value)]="declarationFrom.DECLARATION_policy_number"></app-property-input>

      <app-property-input [title]="'保單持有人' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('保單持有人' | translate)"
                          [required]="true"
                          [(value)]="declarationFrom.DECLARATION_policy_owner"></app-property-input>

      <app-property-input [title]="'保單受保人' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('保單持有人' | translate)"
                          [required]="true"
                          [(value)]="declarationFrom.DECLARATION_policy_insured"></app-property-input>

      <app-property-input [title]="'Premium' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('Premium' | translate)"
                          [required]="true"
                          [(value)]="declarationFrom.DECLARATION_premium"></app-property-input>

      <app-property-input [title]="'Currency' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('Currency' | translate)"
                          [required]="true"
                          [(value)]="declarationFrom.DECLARATION_currency"></app-property-input>

      <app-property-input [title]="('Premium' | translate) + ('PaymentTerm' | translate)"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('Premium' | translate) + ('PaymentTerm' | translate)"
                          [required]="true"
                          [(value)]="declarationFrom.DECLARATION_payment_term"></app-property-input>

      <app-property-input [title]="'GuaranteePeriod' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('GuaranteePeriod' | translate)"
                          [required]="true"
                          [(value)]="declarationFrom.DECLARATION_protection_period"></app-property-input>

      <app-property-input [title]="'WitnessNameCN' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('WitnessNameCN' | translate)"
                          [required]="true"
                          [(value)]="declarationFrom.witnessName"></app-property-input>

      <app-property-input [title]="'WitnessNameEN' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('WitnessNameEN' | translate)"
                          [required]="true"
                          [(value)]="declarationFrom.witnessNameEn"></app-property-input>

      <app-property-input [title]="'WitnessIDPassport' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('WitnessIDPassport' | translate)"
                          [required]="true"
                          [(value)]="declarationFrom.witnessIdCard"></app-property-input>

      <app-property-input [title]="'RelationshipWitnessPolicyholder' | translate"
                          [titleColor]="'#404040'"
                          [placeholder]="('PleaseEnter' | translate) + ('RelationshipWitnessPolicyholder' | translate)"
                          [required]="true"
                          [bottomLineVisible]="false"
                          [(value)]="declarationFrom.witnessRelationship"></app-property-input>

    </div>
  </nz-spin>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onCancel()">
          <span>{{'Cancel' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
