import { Component, EventEmitter, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { BookingCheckMerge } from '../../booking-types';
import { BookingService } from "../../booking.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-booking-merge',
  templateUrl: './booking-merge.component.html',
  styleUrls: ['./booking-merge.component.less']
})
export class BookingMergeComponent implements OnInit {

  modalRef: NzModalRef<BookingMergeComponent, any>;

  bookingNo: string;
  checkMerge: BookingCheckMerge;

  mergedEvent: EventEmitter<any> = new EventEmitter<any>();
  doNotMergeEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private bookingService: BookingService,
              private translate: TranslateService,
              private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
  }

  onDoNotMerge(): void {
    this.doNotMergeEvent.emit('');
    this.modalRef?.close();
  }

  onMerge(): void {
    this.mergeBooking(this.bookingNo, this.checkMerge);
  }

  mergeBooking(bookingNo: string, checkMerge: BookingCheckMerge): void {
    this.bookingService.merge(bookingNo, checkMerge.mergeBookingNo)
      .subscribe(
        async merged => {
          if (merged) {
            const text = await this.translate.get('MergedSuccessfully').toPromise();
            this.toastr.info(text);
            this.mergedEvent.emit('');
            this.modalRef?.close();
          } else {
            const text = await this.translate.get('MergeFailed').toPromise();
            this.toastr.info(text);
          }
        },
        async error => {
          const text = await this.translate.get('MergeFailed').toPromise();
          this.toastr.info(text);
        },
        () => {
        }
      );
  }
}
