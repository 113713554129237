import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CustomerShot,
  Eyewitness,
  Fna,
  FnaCreateReq,
  FnaCustomerUpdateReq,
  FnaDraft,
  FnaSourceData
} from '../fna-types';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { MetadataService } from '../../shared/service/metadata.service';
import { Customer, DataMode, Instance, Phone, SmokeCondition } from '../../api/types';
import { Phones } from '../../shared/utils/phones';
import { PhoneEditComponent } from '../../shared/component/phone-edit/phone-edit.component';
import { TranslateService } from '@ngx-translate/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { Addresses } from '../../shared/utils/addresses';
import { AddressEditComponent } from '../../shared/component/address-edit/address-edit.component';
import { FnaService } from '../fna.service';
import { plainToClass } from 'class-transformer';
import { FnaSourcesComponent } from '../fna-sources/fna-sources.component';
import { RegionSelectionComponent } from '../../shared/component/region-selection/region-selection.component';
import { AccountService } from '../../account/account.service';
import { SubSalesChannelComponent } from '../../shared/component/sub-sales-channel/sub-sales-channel.component';
import { ToastrService } from 'ngx-toastr';
import { Workbench } from '../../workbench/workbench-types';
import { WorkbenchService } from '../../workbench/workbench.service';

@Component({
  selector: 'app-fna-client',
  templateUrl: './fna-client.component.html',
  styleUrls: ['./fna-client.component.less']
})
export class FnaClientComponent implements OnInit {

  // TODO 从联系人中导入

  loading = false;
  @Input()
  mode = DataMode.OWN;

  fna: Fna;

  customerShot: CustomerShot = new CustomerShot();

  eyewitness: Eyewitness = new Eyewitness();

  titleOptions: PropertySelectOption[] = [];
  genderOptions: PropertySelectOption[] = [];
  smokeOptions: PropertySelectOption[] = [];
  marriageOptions: PropertySelectOption[] = [];
  regionOptions: PropertySelectOption[] = [];
  educationOptions: PropertySelectOption[] = [];
  longtimeAbroadOptions: PropertySelectOption[] = [];
  isInputName = false;
  isCreate = false;
  showName: string;
  instance: Instance;
  sourceData: FnaSourceData = new FnaSourceData();
  workbench: Workbench;

  @Output()
  fnaCreated: EventEmitter<FnaDraft> = new EventEmitter<FnaDraft>();

  drawerRef: NzDrawerRef<FnaClientComponent, string>;

  constructor(private metadataService: MetadataService,
              private translate: TranslateService,
              private workbenchService: WorkbenchService,
              private drawerService: NzDrawerService,
              private fnaService: FnaService,
              private accountService: AccountService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.instance = this.metadataService.mockInstance;
    this.isDefaultFill();
    if (this.isCreate) {
      this.customerShot.accountName = this.accountService.passport.accountName;
      this.customerShot.nickName = this.accountService.passport.name;
    } else {
      this.customerShot.accountName = this.fna.accountName;
      this.customerShot.nickName = this.fna.nickName;
    }
    const titles = this.metadataService.values('title');
    for (const title of titles) {
      this.titleOptions.push(new PropertySelectOption(title.value, title.key));
    }

    const genders = this.metadataService.values('gender');
    for (const gender of genders) {
      this.genderOptions.push(new PropertySelectOption(gender.value, gender.key));
    }

    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'S'), SmokeCondition.S));
    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'NS'), SmokeCondition.NS));

    const marriageStatus = this.metadataService.values('marriageStatus');
    for (const marriage of marriageStatus) {
      this.marriageOptions.push(new PropertySelectOption(marriage.value, marriage.key));
    }

    const regions = this.metadataService.values('residenceRegion');
    for (const region of regions) {
      this.regionOptions.push(new PropertySelectOption(region.value, region.key));
    }

    const educations = this.metadataService.values('education');
    for (const education of educations) {
      this.educationOptions.push(new PropertySelectOption(education.value, education.key));
    }

    this.translate.get(['Yes', 'No']).subscribe(
      (res: any) => {
        this.longtimeAbroadOptions.push(new PropertySelectOption(res.Yes, true));
        this.longtimeAbroadOptions.push(new PropertySelectOption(res.No, false));
      });

    this.fnaService.sourceList()
      .subscribe(
        sources => {
        }
      );

    if (this.customerShot.source) {
      const data = JSON.parse(this.customerShot.source);
      this.sourceData = plainToClass(FnaSourceData, data, {excludeExtraneousValues: true});
    }
  }

  onBirthdayChange(timestamp: number): void {
    this.customerShot.birthday = timestamp;
  }

  onMobileNumber(): void {
    if (!this.canSave) {
      return;
    }
    this.translate.get('MobileNumber').subscribe(
      (title: any) => {

        const quickPhones = Phones.getIndividualQuickPhones([this.customerShot.toCustomer()]);

        const phone = new Phone();
        phone.areaCode = this.customerShot.areaCode;
        phone.phone = this.customerShot.phone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerShot.areaCode = data?.areaCode;
              this.customerShot.phone = data?.phone;
            }
          );
        });
      });
  }


  onCountry(): void {
    if (!this.canSave) {
      return;
    }
    const drawerRef = this.drawerService.create<RegionSelectionComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: RegionSelectionComponent,
      nzContentParams: {},
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.regionSelected.subscribe(
        data => {
          this.customerShot.country = data.countryCode;
          drawerRef.close();
        }
      );
    });
  }

  onResidentialPhoneNumber(): void {
    if (!this.canSave) {
      return;
    }
    this.translate.get('ResidentialPhoneNumber').subscribe(
      (title: any) => {
        const quickPhones = Phones.getIndividualQuickPhones([this.customerShot.toCustomer()]);

        const phone = new Phone();
        phone.areaCode = this.customerShot.residenceAreaCode;
        phone.phone = this.customerShot.residenceTelephone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerShot.residenceAreaCode = data?.areaCode;
              this.customerShot.residenceTelephone = data?.phone;
            }
          );
        });
      });
  }

  onResidenceAddress(): void {
    if (!this.canSave) {
      return;
    }
    this.translate.get('LegalAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getIndividualQuickAddresses([this.customerShot.toCustomer()]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customerShot.residenceAddress,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerShot.residenceAddress = data;
            }
          );
        });
      });
  }

  mailingAddress(): void {
    if (!this.canSave) {
      return;
    }
    this.translate.get('PostalAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getIndividualQuickAddresses([this.customerShot.toCustomer()]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customerShot.mailingAddress,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerShot.mailingAddress = data;
            }
          );
        });
      });
  }

  residentialAddress(): void {
    if (!this.canSave) {
      return;
    }
    this.translate.get('UserAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getIndividualQuickAddresses([this.customerShot.toCustomer()]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customerShot.residentialAddress,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerShot.residentialAddress = data;
            }
          );
        });
      });
  }

  onOfficePhoneNumber(): void {
    if (!this.canSave) {
      return;
    }
    this.translate.get('OfficePhoneNumber').subscribe(
      (title: any) => {
        const quickPhones = Phones.getIndividualQuickPhones([this.customerShot.toCustomer()]);

        const phone = new Phone();
        phone.areaCode = this.customerShot.companyAreaCode;
        phone.phone = this.customerShot.companyTelephone;

        const drawerRef = this.drawerService.create<PhoneEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: PhoneEditComponent,
          nzContentParams: {
            title,
            phone,
            quickPhones
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.phoneSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerShot.companyAreaCode = data?.areaCode;
              this.customerShot.companyTelephone = data?.phone;
            }
          );
        });
      });
  }


  onCompanyAddress(): void {
    if (!this.canSave) {
      return;
    }
    this.translate.get('CompanyAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getIndividualQuickAddresses([this.customerShot.toCustomer()]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.customerShot.companyAddress,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.customerShot.companyAddress = data;
            }
          );
        });
      });
  }

  onWitnessBirthdayChange(timestamp: number): void {
    this.eyewitness.birthday = timestamp;
  }

  onSource(): void {
    if (!this.canSave) {
      return;
    }
    const drawerRef = this.drawerService.create<FnaSourcesComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: FnaSourcesComponent,
      nzContentParams: {
        selectedSource: this.sourceData
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.sourceSelected.subscribe(
        data => {
          drawerRef.close();
          this.sourceData = data;
          this.customerShot.source = JSON.stringify(data);
        }
      );
    });
  }

  onSave(): void {
    if (!!this.fna) {
      this.customerUpdate();
    } else {
      this.fnaCreate();
    }
  }

  get canSave(): boolean {
    if (!this.fna) {
      return true;
    }
    return this.fna.clientCanSave;
  }

  get accountNameSave(): boolean {
    if (!this.canSave) {
      return false;
    }
    if (this.mode === DataMode.SALESGROUP) {
      return false;
    }
    return this.isCreate ? true : this.fna.accountNameEdit;
  }

  customerUpdate(): void {
    const updateReq = new FnaCustomerUpdateReq();
    updateReq.id = this.fna.id;
    updateReq.customerShot = this.customerShot;
    updateReq.eyewitness = this.eyewitness;
    updateReq.accountName = this.customerShot.accountName;
    this.loading = true;
    this.fnaService.updateCustomer(updateReq)
      .subscribe(data => {
        this.loading = false;
        this.drawerRef?.close();
      }, error => {
        this.loading = false;
      });
  }

  fnaCreate(): void {
    const createReq = plainToClass(FnaCreateReq, this.customerShot);
    createReq.eyewitness = this.eyewitness;
    this.loading = true;
    this.fnaService.create(createReq)
      .subscribe(data => {
        this.loading = false;
        this.fnaCreated.emit(data);
      }, error => {
        this.loading = false;
      });
  }

  get countryString(): string {
    const country = this.metadataService.translate('countries', this.customerShot.country);
    return country;
  }

  get isFone(): boolean {
    const instance = this.fnaService.mockInstance;
    return instance.isFone();
  }

  isDefaultFill(): void {
    if (this.fnaService.mockInstance.isFoneOrDev() || this.fnaService.mockInstance.isWDF()) {
      this.isInputName = true;
      if (!this.customerShot.lastName) {
        this.customerShot.lastName = '-';
      }
      if (!this.customerShot.lastNamePinyin) {
        this.customerShot.lastNamePinyin = '-';
      }
    }
  }

  onSub(): void {
    if (!this.accountNameSave) {
      if (this.fna.bookingStatusTip) {
        this.toastr.warning(this.fna.bookingStatusTip);
      }
      return;
    }
    const drawerRef = this.drawerService.create<SubSalesChannelComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: SubSalesChannelComponent,
      nzContentParams: {
        accountName: [this.customerShot.accountName]
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.strSaved.subscribe(
        data => {
          drawerRef.close();
          console.log(data);
          if (data) {
            this.customerShot.accountName = data.accountName;
            this.customerShot.nickName = data.name;
          }
        }
      );
    });
  }

}
