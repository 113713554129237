import { Component, OnInit } from '@angular/core';
import { ProposalService } from '../../../proposal/proposal.service';
import { AccountList, CreateAccount } from '../../../proposal/proposal-types';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { ProductService } from '../../../product/product.service';
import { ProductCompany } from '../../../product/product-types';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-proposal-account-list',
  templateUrl: './proposal-account-list.component.html',
  styleUrls: ['./proposal-account-list.component.less']
})
export class ProposalAccountListComponent implements OnInit {

  listData: AccountList[] = [];
  spinning = false;

  createData: CreateAccount = new CreateAccount();
  edit = false;
  companyOptions: PropertySelectOption[] = [];
  companies: ProductCompany[];
  newPassword: string;
  passwordVisible = false;
  newPasswordVisible = false;
  isUpdate = false;
  isVisible = false;
  proposalCode: string;
  id: number;


  constructor(private proposalService: ProposalService,
              private productService: ProductService,
              private translate: TranslateService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.getListData();

    this.productService.companies()
      .subscribe(
        data => {
          this.companies = data;
          data.forEach(company => {
            this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
          });

        },
        error => {
        });
  }

  getListData(): void {
    this.spinning = true;
    this.proposalService.proposalAccount()
      .subscribe(
        data => {
          console.log(data);
          this.listData = JSON.parse(JSON.stringify(data));
          this.spinning = false;
        }, error => {
          this.spinning = false;
        }
      );
  }

  onEnableChange(en, info): void {
    const param = {
      id: info.id,
      enable: info.enable,
    };
    this.proposalService.putProposalAccount(param)
      .subscribe(
        data => {
          // this.getListData();
          if (data) {
            this.translate.get('ModifySuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              });
          }
        }, () => {
        }
      );
  }

  onUpdate(info): void {
    this.createData = new CreateAccount();
    this.newPassword = '';
    this.isUpdate = true;
    this.edit = true;
    this.createData.id = info.id;
    this.createData.companyCode = info.companyCode;
    this.createData.userName = info.userName;
    this.createData.enable = info.enable;
  }

  cancel(): void {
  }

  confirm(item): void {
    this.proposalService.deleteProposalAccount(item.id)
      .subscribe(
        data => {
          if (data) {
            this.getListData();
          }
        });
  }

  onAdd(): void {
    this.createData = new CreateAccount();
    this.newPassword = '';
    this.edit = true;
  }

  onCancel(): void {
    this.createData = new CreateAccount();
    this.edit = false;
    this.isUpdate = false;
  }

  onCreate(): void {
    if (!this.canReply) {
      return;
    }
    if (this.createData.password !== this.newPassword) {
      this.translate.get('TwoPasswordsInconsistent').subscribe(
        (res) => {
          this.toastr.warning(res);
        });
      return;
    }
    console.log(this.createData);
    if (this.isUpdate) {
      this.proposalService.putProposalAccount(this.createData)
        .subscribe(
          data => {
            console.log(data);
            if (data) {
              this.translate.get('ModifySuccess').subscribe(
                (res) => {
                  this.toastr.success(res);
                });
              this.edit = false;
              this.getListData();
              this.isUpdate = false;
            }
          }
        );
    } else {
      this.proposalService.createProposalAccount(this.createData)
        .subscribe(
          data => {
            console.log(data);
            if (data) {
              this.translate.get('AddSuccess').subscribe(
                (res) => {
                  this.toastr.success(res);
                });
              this.edit = false;
              this.getListData();
            }
          }
        );
    }
  }

  get canReply(): boolean {
    if (!this.createData.companyCode) {
      return false;
    }
    if (!this.createData.userName) {
      return false;
    }
    if (!this.createData.password) {
      return false;
    }
    if (!this.newPassword) {
      return false;
    }
    return true;
  }

  codeModal(info): void {
    this.isVisible = true;
    this.id = info.id;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleOk(): void {
    if (this.proposalCode) {
      this.proposalService.sendCode({id: this.id, code: this.proposalCode})
        .subscribe(
          data => {
            console.log(data);
            if (data) {
              this.isVisible = false;
              this.getListData();
            }
          }, () => {}
        );
    }
  }
}
