<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate"  [nzSpinning]="spinning">
    <div *ngIf="!contactsTable">
      <nz-empty nzNotFoundImage="simple"></nz-empty>
    </div>
    <div *ngIf="contactsTable" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="contactsTable.list"
                [nzPageIndex]="contactsTable.pageNum"
                [nzPageSize]="contactsTable.pageSize"
                [nzTotal]="contactsTable.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                (nzPageIndexChange)="onSearch($event)">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'ContactList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight"
                        style=" margin-left: 10px;">
                  <img alt="" width="24" src="assets/images/ic_filter.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        [nzTooltipTitle]="'ExportContactsExcel' | translate"  style=" margin-left: 20px;"
                        appThrottleClick (throttleClick)="onExportExcel()">
                  <img style="margin-bottom: 4px;" alt="" width="22" src="assets/images/ic_export_excel.svg">
                </button>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ contactsTable.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzAlign="left" nzWidth="165px">
            <span nz-tooltip
                  style="margin-left: 40px"
                  [nzTooltipTitle]="'Name' | translate">{{'Name' | translate}}</span>
          </th>
          <th nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Contacts2' | translate">{{'Contacts2' | translate}}</span>
          </th>
          <th nzAlign="center"  nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Gender' | translate">{{'Gender' | translate}}</span>
          </th>
<!--          <th nzAlign="center" nzWidth="160px">-->
<!--            <span nz-tooltip [nzTooltipTitle]="'Age' | translate">{{'Age' | translate}}</span>-->
<!--          </th>-->
          <th nzAlign="center" nzWidth="160px">
            <span>{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let contacts of contactsTable.list; let i = index"
            appThrottleClick (throttleClick)="onConnectionsDetail(contacts.id)"
        >
          <td>
            <nz-avatar class="list-top-avatar" style="margin-right: 20px;" [nzSize]="36" [nzSrc]="contacts.headImgUrl"></nz-avatar>
            <span style="font-weight: bold; font-size: 12px">
              {{ contacts.nickname }}
            </span>
          </td>
          <td><span>{{ contacts.type | metadataTranslate: 'connectionsType' }}</span></td>
          <td nzAlign="center" ><span>{{ contacts.gender | metadataTranslate: 'gender' }}</span></td>
<!--          <td nzAlign="center" ><span>{{ contacts.age }} {{'YearsOld' | translate}}</span></td>-->
          <td nzRight nzAlign="center" >
            <div class="functions">
              <a nz-button nzType="link" nzSize="small"
                 nz-tooltip [nzTooltipTitle]="'Detail' | translate">
                <img alt="" width="20" src="../../../assets/images/ic_details.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>

<!-- 气泡Template -->
<ng-template #filterTemplate>
  <div style="width: 400px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <app-property-select [title]="'Contacts2' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Contacts2' | translate)"
                         [options]="levelOptions"
                         [(value)]="search.type"></app-property-select>
    <app-property-input [title]="'Name' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('Name' | translate)"
                        [(value)]="search.nickname"></app-property-input>

    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default"
                style="width: 100%; margin: 30px 0 0 0;"
                appThrottleClick (throttleClick)="onResetSearch()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary"
                style="width: 100%; margin: 30px 0 0 0;"
                appThrottleClick (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>
