import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InfoDetails, InfoList, IntroductionFeeDetails, UploadList } from '../commission-types';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {CommissionService} from '../commission.service';
import { BookingAttachmentReq, DataMode, Instance, Material, UploadTokenReq, UploadType } from '../../api/types';
import { MetadataService } from '../../shared/service/metadata.service';
import { Workbench } from '../../workbench/workbench-types';
import { WorkbenchService } from '../../workbench/workbench.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-commission-detail',
  templateUrl: './commission-detail.component.html',
  styleUrls: ['./commission-detail.component.less']
})
export class CommissionDetailComponent implements OnInit {

  uploadPopoverVisible = false;
  loading = false;
  detail: InfoDetails;
  @Output()
  infoDetailsClose: EventEmitter<any> = new EventEmitter<any>();
  uploadTokenReq: UploadTokenReq = new UploadTokenReq();
  fliesCopy: Material[] = [];
  workbench: Workbench;
  instance: Instance;
  @Input()
  mode = DataMode.OWN;

  constructor(private drawerService: NzDrawerService,
              private commissionService: CommissionService,
              private metadataService: MetadataService,
              private toastr: ToastrService,
              private translate: TranslateService,
              private workbenchService: WorkbenchService) { }

  ngOnInit(): void {
    this.uploadTokenReq.uploadType = UploadType.BILL;
    this.uploadTokenReq.middleName = this.detail.id;
    this.fliesCopy = JSON.parse(JSON.stringify(this.detail.attachments));
    this.instance = this.workbenchService.mockInstance;
    this.workbench = this.workbenchService.workbench;

  }

  clickMe(): void {
    this.uploadPopoverVisible = false;
  }

  onClose(): void {
    this.infoDetailsClose.emit();
  }

  toDecimal(val): any {
    if (val && val.indexOf('.') === -1) {
      val = val + '.00';
      return val;
    } else {
      return val;
    }
  }

  onConfirm(): void {
    this.commissionService.billConfirm(this.detail.id)
      .subscribe(
        data => {
          if (data) {
            this.onReload();
            this.toastr.success(this.translate.instant('ModifySuccess'));
          }
        },
        error => {
        });
  }

  onReload(): void {
    this.commissionService.detailInfo(Number(this.detail.id))
      .subscribe(
        data => {
          this.detail = JSON.parse(JSON.stringify(data));
          this.fliesCopy = JSON.parse(JSON.stringify(data.attachments));
          this.metadataService.groupFiles.emit('');
        },
        error => {
        });
  }

  reloadDetail(flag): void {
    const arr = [];
    this.detail.attachments?.map(item => {
      if (!this.fliesCopy.some(ite => item.filePath === ite.filePath)) {
        arr.push(item);
      }
    });
    const attachmentReq = new UploadList();
    attachmentReq.billId = this.detail.id;
    attachmentReq.material = arr;
    this.loading = true;
    this.commissionService.billUpload(attachmentReq)
      .subscribe(
        data => {
          this.loading = false;
          if (flag) {
            setTimeout(() => { this.onReload(); }, 500);
          }
        },
        error => {
          this.loading = false;
        });
  }

  onAttachmentsChange(attachments: Material[]): void {
  }

  fileEdit(info: Material): void {
    console.log(info);
    const attachmentReq = new UploadList();
    attachmentReq.billId = this.detail.id;
    attachmentReq.material = info;
    this.commissionService.billEditFile(attachmentReq)
      .subscribe(
        data => {
          setTimeout(() => { this.onReload(); }, 500);
        },
        error => {
        });
  }

  fileDelete(info: Material): void {
    console.log(info);
    const attachmentReq = new UploadList();
    attachmentReq.billId = this.detail.id;
    attachmentReq.material = info;
    this.commissionService.billDeleteFile(attachmentReq)
      .subscribe(
        data => {
          setTimeout(() => { this.onReload(); }, 500);
        },
        error => {
        });
  }
}
