<div>

  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <!-- 新单总览 -->
    <div class="booking-section-wrapper">
      <div class="property-row" appThrottleClick (throttleClick)="onProductPlan()">
        <div class="property-title-row">{{ 'ProductPlan' | translate }}</div>
        <div class="property-value-row">
          <div style="display: flex; align-items: center; flex-direction: row;">

            <span style="flex: 1;"></span>
            <div><i nz-icon nzType="right" nzTheme="outline"></i></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 投保人 -->
    <div class="booking-section-wrapper">
      <div class="property-row" appThrottleClick (throttleClick)="onUnderwritingOwner()">
        <div class="property-title-row">{{ 'Holder' | translate }}</div>
        <div class="property-value-row">
          <div class="property-pick-value">
            <!--            <span style="flex: 1;">{{booking.ownerNameString()}}</span>-->
            <span style="flex: 1;"></span>
            <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!instance.isWDF()">
      <!-- 受保人 -->
      <div class="booking-section-wrapper">
        <div class="property-row" appThrottleClick (throttleClick)="onBookingInsured()">
          <div class="property-title-row">{{ 'Insured' | translate }}</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <!--            <span style="flex: 1;">{{booking.insuredNameString()}}</span>-->
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>

      <!-- 受益人 -->
      <div class="booking-section-wrapper">
        <div class="property-row" appThrottleClick (throttleClick)="onUnderwritingBeneficiaries()">
          <div class="property-title-row">{{ 'Beneficiary' | translate }}</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>

      <!-- 第二持有人 -->
      <div *ngIf="!instance.isFoneOrDev()" class="booking-section-wrapper">
        <div class="property-row">
          <div class="property-title-row">{{ 'SecondHolder' | translate }}</div>
          <div class="property-value-row" appThrottleClick (throttleClick)="onSecondHolder()">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>

      <!-- 第二受保人 -->
      <div *ngIf="instance.isWanson()" class="booking-section-wrapper">
        <div class="property-row">
          <div class="property-title-row">{{ 'SecondInsured' | translate }}</div>
          <div class="property-value-row" appThrottleClick (throttleClick)="onSecondInsured()">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>

      <!--核保信息-->
      <div class="booking-section-wrapper">
        <div class="property-row" appThrottleClick (throttleClick)="onUnderwritingInformation()">
          <div class="property-title-row">{{ 'UnderwritingInformation' | translate }}</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>
      <!--銀行卡信息-->
      <div *ngIf="isShowBeneficiary" class="booking-section-wrapper">
        <div class="property-row" appThrottleClick (throttleClick)="onBankDetail()">
          <div class="property-title-row">{{ 'BankDetail' | translate }}</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>

      <!-- Policy Delivery -->
      <div class="booking-section-wrapper">
        <div class="property-row" appThrottleClick (throttleClick)="onPolicyDelivery()">
          <div class="property-title-row">{{ 'PolicyDelivery'  | translate }}</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>
      <!--健康信息-->
      <div class="booking-section-wrapper">
        <div class="property-row" appThrottleClick (throttleClick)="onHealthInfo()">
          <div class="property-title-row">{{ 'HealthInformation' | translate }}</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>

      <!--  其他  -->
      <div class="booking-section-wrapper">
        <div class="property-row" appThrottleClick (throttleClick)="onOther()">
          <div class="property-title-row">{{ 'Other' | translate }}</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>

      <!--  备注  -->
      <div class="booking-section-wrapper" *ngIf="!instance.isFoneOrDev()">
        <div class="property-row" appThrottleClick (throttleClick)="onRemark()">
          <div class="property-title-row">{{ 'Remark' | translate }}</div>
          <div class="property-value-row">
            <div class="property-pick-value">
              <span style="flex: 1;"></span>
              <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isShowItem">
        <!--  New BUSINESS Form  -->
        <div
          *ngIf="underwritingDetail.applicationType !== 'FUND_SWITCHING' && underwritingDetail.applicationType !== 'MPF_APPLICATION' && underwritingDetail.applicationType !== 'GENERAL_INSURANCE_APPLICATION'"
          class="booking-section-wrapper">
          <div class="property-row" appThrottleClick (throttleClick)="openBusinessForm('NEW_BUSINESS_FORM')">
            <div class="property-title-row">New Business Form</div>
            <div class="property-value-row">
              <div class="property-pick-value">
                <span style="flex: 1;"></span>
                <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
              </div>
            </div>
          </div>
        </div>

        <!-- Account Opening Form -->
        <div
          *ngIf="underwritingDetail.applicationType !== 'FUND_SWITCHING' && underwritingDetail.applicationType !== 'MPF_APPLICATION' && underwritingDetail.applicationType !== 'GENERAL_INSURANCE_APPLICATION'"
          class="booking-section-wrapper">
          <div class="property-row" appThrottleClick (throttleClick)="openBusinessForm('ACCOUNT_OPENING_FORM')">
            <div class="property-title-row">Account Opening Form</div>
            <div class="property-value-row">
              <div class="property-pick-value">
                <span style="flex: 1;"></span>
                <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
              </div>
            </div>
          </div>
        </div>

        <!-- Confirmation Letter(non-ILAS)-->
        <div
          *ngIf="underwritingDetail.applicationType !== 'FUND_SWITCHING' && underwritingDetail.applicationType !== 'MPF_APPLICATION' && underwritingDetail.applicationType !== 'GENERAL_INSURANCE_APPLICATION'"
          class="booking-section-wrapper">
          <div class="property-row" appThrottleClick (throttleClick)="openBusinessForm('CONFIRMATION_LETTER_NON_ILAS')">
            <div class="property-title-row">Confirmation Letter(non-ILAS)</div>
            <div class="property-value-row">
              <div class="property-pick-value">
                <span style="flex: 1;"></span>
                <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
              </div>
            </div>
          </div>
        </div>

        <!-- Checklist-->
        <div
          *ngIf="underwritingDetail.applicationType !== 'FUND_SWITCHING' && underwritingDetail.applicationType !== 'MPF_APPLICATION' && underwritingDetail.applicationType !== 'GENERAL_INSURANCE_APPLICATION'"
          class="booking-section-wrapper">
          <div class="property-row" appThrottleClick (throttleClick)="openBusinessForm('CHECKLIST')">
            <div class="property-title-row">Checklist</div>
            <div class="property-value-row">
              <div class="property-pick-value">
                <span style="flex: 1;"></span>
                <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
              </div>
            </div>
          </div>
        </div>

        <!-- RISK_PROFILE_QUESTIONNAIRE-->
        <div *ngIf="underwritingDetail.applicationType === 'FUND_SWITCHING'" class="booking-section-wrapper">
          <div class="property-row" appThrottleClick (throttleClick)="openBusinessForm('RISK_PROFILE_QUESTIONNAIRE')">
            <div class="property-title-row">Risk Profile Questionnaire</div>
            <div class="property-value-row">
              <div class="property-pick-value">
                <span style="flex: 1;"></span>
                <span><i nz-icon nzType="right" nzTheme="outline"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="instance.isEmpire()" style="margin: 20px 0;">
        <label nz-checkbox [(ngModel)]="underwritingDetail.authorizeCollect" [disabled]="true">{{'AuthorizeCollect' | translate}}</label>
      </div>
    </div>


  </nz-spin>

  <!--  <div class="footer" *ngIf="underwriting.status === 'DRAFT'">-->
  <!--    <div nz-row style="width: 100%; height: 100%;">-->
  <!--      <div nz-col nzSpan="24" class="footer-button-primary">-->
  <!--        <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="onSummit()">-->
  <!--          &lt;!&ndash;          <em nz-icon nzType="check" nzTheme="outline"></em>&ndash;&gt;-->
  <!--          <span>{{'Summit' | translate}}</span>-->
  <!--        </a>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</div>

<nz-drawer [nzWidth]="800" [nzMaskClosable]="true" [nzVisible]="policyVisible"
           (nzOnClose)="close()">
  <div class="drawer-container" *nzDrawerContent>
    <div class="common-title" style="margin-bottom: 40px">
      {{ 'PolicyDelivery' | translate}}    </div>
    <div class="section-wrapper">
      <app-property-text [title]="'DeliveryWay' | translate"
                         [value]="underwriting.delivery?.insurerToClient == null? 'N/A'  : underwriting.delivery?.insurerToClient ? ('InsurerSendToClient' | translate) : ('InsurerSendToBroker'  | translate) "></app-property-text>
      <app-property-text [title]="'BrokerReceiveDate' | translate"
                         *ngIf="!underwriting.delivery?.insurerToClient"
                         [value]="underwriting.delivery?.receiveDate | date: 'yyyy-MM-dd'"></app-property-text>
      <app-property-text [title]="'InsurerSendOutDate' | translate"
                         [value]="underwriting.delivery?.sendOutDate | date: 'yyyy-MM-dd'"></app-property-text>
      <app-property-text [title]="'ClientReceiptSignOffDate' | translate"
                         [bottomLineVisible]="false"
                         [value]="underwriting.delivery?.receiptDate | date: 'yyyy-MM-dd'"></app-property-text>
    </div>
  </div>
</nz-drawer>

<nz-drawer [nzWidth]="800" [nzMaskClosable]="true" [nzVisible]="remarkVisible"
           (nzOnClose)="remarkClose()">
  <div class="drawer-container" *nzDrawerContent>
    <div class="common-title" style="margin-bottom: 40px">
      {{'Remark' | translate}}
    </div>
    <div class="section-wrapper" style="min-height: 400px;padding: 20px">
      <span [innerHTML]="underwriting.special ? (underwriting.special | html ) : 'N/A'"></span>
    </div>
  </div>
</nz-drawer>
