<div [class]="disabled ? 'property-disable' : ''">
  <div class="property-title-row" *ngIf="title" style="margin: 15px 0 5px 0">
    <span>{{ title }}</span>
    <img *ngIf="required" alt="" width="24" src="assets/images/ic_required.svg">
  </div>

  <div class="property-value-row">
    <textarea style="padding: 0!important;"
              [nzBorderless]="borderless"
              nz-input [placeholder]="placeholder"
              [disabled]="disabled"
              [(ngModel)]="value"
              [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
  </div>
</div>
<div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
<div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
