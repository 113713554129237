import { Component, OnInit, TemplateRef } from '@angular/core';
import { AmlMonitorArtificialStatusUpdateReq, AmlInfo, AmlMonitorResultItem, AmlThirdPartPDFReq, AmlMonitorInfo } from '../../../api/types';
import { MetadataKeyValue, MetadataService } from '../../../shared/service/metadata.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AmlService } from '../../aml.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Workbench } from '../../../workbench/workbench-types';
import { WorkbenchService } from '../../../workbench/workbench.service';

@Component({
  selector: 'app-aml-monitor-entry-detail',
  templateUrl: './aml-monitor-entry-detail.component.html',
  styleUrls: ['./aml-monitor-entry-detail.component.less']
})
export class AmlMonitorEntryDetailComponent implements OnInit {


  loading = false;

  info: AmlMonitorInfo;

  statusKeyValues: MetadataKeyValue[];

  stateOfArtificialLoading = false;

  normalPdf: Uint8Array;

  stateModified = false;

  filterPopoverVisible = false;
  workbench: Workbench;

  constructor(private metadataService: MetadataService,
              private modalService: NzModalService,
              private drawerService: NzDrawerService,
              private workbenchService: WorkbenchService,
              private amlService: AmlService) {

  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    this.statusKeyValues = this.metadataService.values('amlMonitorMatchStatus');
    console.log(this.statusKeyValues);
    this.statusKeyValues.map(item => {
      switch (item.key) {
        case 'Match - Risk':
          item.text = 'true-positive';
          break;
        case 'No Match':
          item.text = 'false-positive';
          break;
        case 'Match - No Risk':
          item.text = 'discarded';
          break;
      }
    });
    if (!this.info.entryMatchList || this.info.entryMatchList.length === 0) {
      this.loading = true;
      this.amlService.downloadMonitorNormalProfile(this.info.entry.id)
        .subscribe(
          byteArray => {
            this.loading = false;
            this.normalPdf = byteArray;
          },
          () => {
            this.loading = false;
          }
        );
    }
  }

  loadDetail(id: number): void {
    this.loading = true;
    this.amlService.entryDetail(id)
      .subscribe(info => {
          this.info = info;
          this.loading = false;
        },
        () => {
          this.loading = false;
        });
  }

  onPreview(result: AmlMonitorResultItem): void {
    this.loading = true;
    const req = new AmlThirdPartPDFReq();
    req.type = this.info.entry.type;
    req.thirdPartId = result.qrCode;
    this.amlService.downloadMonitorProfile(req)
      .subscribe(
        byteArray => {
          this.loading = false;
          const url = window.URL.createObjectURL(new Blob([byteArray], {type: 'application/pdf'}));
          window.open(url);

        },
        () => {
          this.loading = false;
        }
      );
  }

  onArtificialStatusUpdate(result: AmlMonitorResultItem, status: string, tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    if (result.matchComments && result.matchComments.length > 0 && result.matchComments[0].status === status) {
      return;
    }

    const req = new AmlMonitorArtificialStatusUpdateReq();
    req.entryId = this.info.entry.id;
    req.matchId = result.matchId;
    req.status = status;

    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: req,
      nzOnOk: () => {
      }
    });
  }

  onArtificialStatusUpdateConfirm(ref: NzModalRef, req: AmlMonitorArtificialStatusUpdateReq): void {
    if (this.stateOfArtificialLoading) {
      return;
    }
    this.stateOfArtificialLoading = true;
    this.amlService.updateMonitorArtificialStatus(req, this.info.entry.type)
      .subscribe(
        data => {
          ref.close();
          this.stateOfArtificialLoading = false;
          this.stateModified = true;
          this.loadDetail(this.info.entry.id);
        },
        () => {
                  ref.close();
                  this.stateOfArtificialLoading = false;
        }
      );
  }

  onPreviewNormalResult(): void {
    const url = window.URL.createObjectURL(new Blob([this.normalPdf], {type: 'application/pdf'}));
    window.open(url);
  }

  onDownloadNormalResult(): void {
    const a = document.createElement('a');
    a.download = this.info.entry.id + '.pdf';
    a.href = window.URL.createObjectURL(new Blob([this.normalPdf], {type: 'application/pdf'}));
    a.click();
  }

}
