<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{'PrimaryContact' | translate}}
  </div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div *ngIf="listData.contactPerson && listData.contactPerson.length">
      <div *ngFor="let item of listData.contactPerson; let i = index"
           style="display: flex; align-items: center; cursor: pointer">
        <div class="section-wrapper meeting-card"
             appThrottleClick (throttleClick)="onPrimary(item, i)">
          <div style="font-size: 16px">
            {{item.name}}
          </div>
        </div>
        <a nz-button nzType="link" nzSize="small"
           style="margin-top: 10px"
           nz-tooltip [nzTooltipTitle]="'Delete' | translate"
           appThrottleClick (throttleClick)="onDelete(i)">
          <img alt="" width="28" src="assets/images/ic_delete.svg">
        </a>
      </div>
    </div>
    <div *ngIf="!listData.contactPerson || listData.contactPerson.length < 1">
      <nz-empty nzNotFoundImage="simple"></nz-empty>
    </div>
  </nz-spin>

  <div *ngIf="listData.signedStatus == 'UNSIGNED'" class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onCreate()">
          <span>{{'Add' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

  <nz-modal
    [(nzVisible)]="isVisible"
    [nzTitle]="modalTitle"
    [nzContent]="modalContent"
    [nzFooter]="null"
    nzCentered
    [nzWidth]="'400px'"
    [nzClosable]="false"
    [nzMaskClosable]="false"
    (nzOnCancel)="handleCancel()"
  >
    <ng-template #modalTitle style="text-align: center">{{'Tips' | translate}}</ng-template>

    <ng-template #modalContent>
      <p style="text-align: center; margin-bottom: 30px">{{'DoYouWantToDeleteContacts' | translate}}</p>
      <div nz-row style="width: 100%; height: 100%; justify-content: space-around;">
        <button nz-col nzSpan="10" nz-button nzType="default"
                appThrottleClick (throttleClick)="handleCancel()">{{'Cancel' | translate}}</button>
        <button nz-col nzSpan="10" nz-button nzType="primary"
                appThrottleClick (throttleClick)="handleOk()">{{'Confirm' | translate}}</button>
      </div>
    </ng-template>
  </nz-modal>
</div>
