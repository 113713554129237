import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketOrderComponent } from './ticket-order.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { TicketOrderListComponent } from './ticket-order-list/ticket-order-list.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { TicketOrderStatusPipe } from '../shared/pipes/ticket-order-status.pipe';
import { TicketOrderDetailComponent } from './ticket-order-detail/ticket-order-detail.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { TicketOrderCreateComponent } from './ticket-order-create/ticket-order-create.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPopoverModule } from 'ng-zorro-antd/popover';



@NgModule({
  declarations: [
    TicketOrderComponent,
    TicketOrderListComponent,
    TicketOrderStatusPipe,
    TicketOrderDetailComponent,
    TicketOrderCreateComponent
  ],
  imports: [
    CommonModule,
    NzLayoutModule,
    RouterModule,
    SharedModule,
    TranslateModule,
    NzSpinModule,
    NzTableModule,
    NzSkeletonModule,
    NzGridModule,
    NzToolTipModule,
    NzButtonModule,
    NzIconModule,
    NzAvatarModule,
    NzTagModule,
    NzPopoverModule
  ]
})
export class TicketOrderModule { }
