import {Component, ElementRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Workbench} from '../workbench/workbench-types';
import {
  BasicInfoSave,
  FinancialSave, FnaAssessAdvice, FnaFormConfig, FnaFormInfo,
  FnaPDFQuery,
  NewFnaChooseProductReq,
  NewFnaDetail
} from '../new-fna/new-fna-types';
import {DataMode, Instance} from '../api/types';
import {ActivatedRoute, Router} from '@angular/router';
import {NzModalService} from 'ng-zorro-antd/modal';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {AccountService} from '../account/account.service';
import {NewFnaService} from '../new-fna/new-fna.service';
import {WorkbenchService} from '../workbench/workbench.service';
import {plainToInstance} from 'class-transformer';
import {FnaSignatureUpdateReq} from '../fna/fna-types';
import {ZuuBasicInfoComponent} from './zuu-basic-info/zuu-basic-info.component';
import {ZuuFormTemplateComponent} from './zuu-form-template/zuu-form-template.component';
import {ZuuTemplateIntroductionComponent} from './zuu-template-introduction/zuu-template-introduction.component';
import {ZuuTemplateSignatureComponent} from './zuu-template-signature/zuu-template-signature.component';
import {ZuuFormSelectedComponent} from './zuu-form-selected/zuu-form-selected.component';
import {ZuuAdditionalInfoComponent} from './zuu-additional-info/zuu-additional-info.component';

@Component({
  selector: 'app-zuu-fna-template',
  templateUrl: './zuu-fna-template.component.html',
  styleUrls: ['./zuu-fna-template.component.less']
})
export class ZuuFnaTemplateComponent implements OnInit {
  currentIndex = 1;
  id: number;
  loading: boolean;
  viewForm = false;
  accountNameStr: string;
  customerTypeStr: string;
  fnaBusinessTypeStr: string;
  workbench: Workbench;

  fnaInfo: NewFnaDetail = new NewFnaDetail();
  num = 0; // 签名计数

  @Output()
  detailInfo: NewFnaDetail = new NewFnaDetail();
  @Input()
  mode = DataMode.OWN;

  @ViewChild(ZuuBasicInfoComponent)
  basicInfoCom: ZuuBasicInfoComponent;

  @ViewChild(ZuuFormTemplateComponent)
  questionInfoCom: ZuuFormTemplateComponent;

  @ViewChild(ZuuAdditionalInfoComponent)
  analysisInfoCom: ZuuAdditionalInfoComponent;

  @ViewChild(ZuuTemplateIntroductionComponent)
  introductionInfoCom: ZuuTemplateIntroductionComponent;

  @ViewChild(ZuuTemplateSignatureComponent)
  signatureInfoCom: ZuuTemplateSignatureComponent;

  @ViewChild(ZuuFormSelectedComponent)
  formSelectInfoCom: ZuuFormSelectedComponent;
  instance: Instance;
  constructor(private routerService: ActivatedRoute,
              private router: Router,
              private modalService: NzModalService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private accountService: AccountService,
              private newFnaService: NewFnaService,
              private el: ElementRef,
              private workbenchService: WorkbenchService) { }

  ngOnInit(): void {
    this.routerService
      .queryParams
      .subscribe(params => {
        console.log(params);
        if (params.id) {
          this.onDetail(params['id']);
        } else {
          if (JSON.parse(localStorage.getItem('BASIC_INFO'))) {
            const obj1 = plainToInstance(NewFnaDetail, JSON.parse(localStorage.getItem('BASIC_INFO')));
            this.fnaInfo = JSON.parse(JSON.stringify(obj1));
          }
        }
        if (params.index) {
          this.currentIndex = Number(params.index);
          this.loading = true;
          switch (this.currentIndex) {
            case 1:
              setTimeout(() => {
                this.basicInfoCom.setBasicInfo();
              }, 800);
              this.loading = false;
              break;
            case 2:
              setTimeout(() => {
                this.questionInfoCom.setForms(this.fnaInfo);
              }, 800);
              this.loading = false;
              break;
            case 3:
              setTimeout(() => {
                this.analysisInfoCom.setArr();
              }, 800);
              this.loading = false;
              break;
            case 4:
              setTimeout(() => {
                this.introductionInfoCom.setArr();
              }, 800);
              this.loading = false;
              break;
            case 5:
              setTimeout(() => {
                this.signatureInfoCom.setSignatureValue();
              }, 800);
              this.loading = false;
              break;
            case 6:
              setTimeout(() => {
                this.formSelectInfoCom.getFormConfig();
              }, 800);
              this.loading = false;
              break;
            default:
              this.loading = false;
              break;
          }
          this.loading = false;
        }
        if (params.viewForm) {
          this.currentIndex = 6;
          this.viewForm = true;
        }

      });

    this.workbench = this.workbenchService.workbench;
    this.instance = this.workbenchService.mockInstance;
  }

  scrollToBottom(): void {
    try {
      this.el.nativeElement.querySelector('#content').scrollTo(0, 0);
    } catch (err) {}
  }

  onDetail(id: number): void {
    if (!id) {
      return;
    }
    this.loading = true;
    this.newFnaService.info(id)
      .subscribe(
        fna => {
          this.fnaInfo = JSON.parse(JSON.stringify(fna));
          this.basicInfoChange();

          this.loading = false;
          // this.introductionInfoCom.fnaAssessAdvice = JSON.parse(JSON.stringify(this.fnaInfo.financialAnalysis));
        },
        error => {
          this.loading = false;
        });
  }

  basicInfoChange(): void {
    this.accountNameStr = this.fnaInfo.accountName;
    this.customerTypeStr = this.fnaInfo.customerType;
    this.fnaBusinessTypeStr = this.fnaInfo.fnaBusinessType;
    if (this.basicInfoCom) {
      this.basicInfoCom.basicInfo = JSON.parse(JSON.stringify(this.fnaInfo.basicInformation));
      if (this.fnaInfo.basicInformation.dateOfBirth) {
        const date = this.fnaInfo.basicInformation.dateOfBirth;
        this.basicInfoCom.basicInfo.dateOfBirth = date.split('-').reverse().join('/');
      }
      if (this.fnaInfo.basicInformation.insured.dateOfBirth) {
        const dateInsured = this.fnaInfo.basicInformation.insured.dateOfBirth;
        this.basicInfoCom.basicInfo.insured.dateOfBirth = dateInsured.split('-').reverse().join('/');
      }
      // this.basicInfoCom.isDeepEmptyObject(this.fnaInfo.basicInformation.insured);
    }
  }

  indexChange(index: number): void {
    console.log('indexChange');
    if (!this.fnaInfo.id) {
      return;
    }
    this.loading = true;
    this.newFnaService.info(this.fnaInfo.id)
      .subscribe(
        fna => {
          this.fnaInfo = JSON.parse(JSON.stringify(fna));
          this.accountNameStr = this.fnaInfo.accountName;
          this.customerTypeStr = this.fnaInfo.customerType;
          this.fnaBusinessTypeStr = this.fnaInfo.fnaBusinessType;
          this.currentIndex = index;
          switch (index) {
            case 1:
              setTimeout(() => {
                this.basicInfoCom.setBasicInfo();
                // this.basicInfoCom.isDeepEmptyObject(this.fnaInfo.basicInformation.insured);
              }, 300);
              break;
            case 2:
              setTimeout(() => {
                this.questionInfoCom.setForms(this.fnaInfo);
              }, 200);
              break;
            case 3:
              console.log('附加资料');
              setTimeout(() => {
                this.analysisInfoCom.setArr();
              }, 200);
              break;
            case 4:
              setTimeout(() => {
                this.introductionInfoCom.setArr();
              }, 300);
              break;
          }
          this.scrollToBottom();
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  onSave(): void {
    if (this.viewForm) {
      // 预览
      this.onViewSaveFormNos();
      console.log('预览');
    } else if (this.fnaInfo.signatureStatus !== 'SIGNED') {
      if (this.currentIndex === 1) {
        this.onSaveBasicInfo();
        localStorage.removeItem('BASIC_INFO');
      } else if (this.currentIndex === 2) {
        this.onSaveQuestion();
      } else if (this.currentIndex === 3) {
        this.onSaveAnalysis();
      } else if (this.currentIndex === 4) {
        this.onSaveIntro();
      } else if (this.currentIndex === 5) {
        this.onSaveSign();
      } else if (this.currentIndex === 6) {
        this.onSaveFormNos();
      }
    }
  }

  onSave6(): void {
    this.onSaveFormNos();
  }

  onViewSaveFormNos(): void {
    const param = new FnaPDFQuery();
    param.id = this.fnaInfo.id;
    const copyArr = [];
    console.log(this.formSelectInfoCom.formConfigArr, copyArr);
    this.formSelectInfoCom.formConfigArr?.map(item => {
      this.viewVo(item.vos, copyArr);
    });
    param.formLanguage = this.formSelectInfoCom.formLanguage;
    param.categoryNos = copyArr;
    param.merge = true;
    this.fnaInfo.categoryNos = JSON.parse(JSON.stringify(param.categoryNos));
    this.onSaveToastr();
  }

  onSaveBasicInfo(isTip: boolean = true): void {
    const param = new BasicInfoSave();
    param.id = this.fnaInfo.id || null;
    param.save = true;
    param.accountName = this.fnaInfo.accountName;
    param.fnaBusinessType = this.fnaInfo.fnaBusinessType;
    param.customerType = this.fnaInfo.customerType;
    param.basicInformation = JSON.parse(JSON.stringify(this.basicInfoCom.basicInfo));
    if (param.basicInformation.dateOfBirth) {
      const date = param.basicInformation.dateOfBirth;
      param.basicInformation.dateOfBirth = date.split('/').reverse().join('-');
    }
    if (param.basicInformation.insured.dateOfBirth) {
      const dateInsured = param.basicInformation.insured.dateOfBirth;
      param.basicInformation.insured.dateOfBirth = dateInsured.split('/').reverse().join('-');
    }

    this.loading = true;
    this.newFnaService.basicInfo(param)
      .subscribe(data => {
        this.routerService
          .queryParams
          .subscribe(params => {
            if (!params.id) {
              this.router.navigate(['/user/zuu-fna-template'], { queryParams: { id: data } }).then();
            } else {
              this.onDetail(data);
            }
          });
        if (isTip) {
          this.onSaveToastr();
        }
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }

  // 提示
  onSaveToastr(): void {
    this.translate.get('SavedSuccessfully').subscribe(
      (res) => {
        this.toastr.success(res);
      }
    );
  }


  onSaveQuestion(isTip: boolean = true): void {
    const param = new FinancialSave();
    const arr = [];
    this.questionInfoCom.fnaQuestions.forEach(question => {
      this.questionInfoCom.saveFormObj(question, arr);
    });
    console.log(arr);
    this.loading = true;
    param.dataList = JSON.parse(JSON.stringify(arr));
    param.id = this.fnaInfo.id;
    this.newFnaService.updateFinancial(param)
      .subscribe(data => {
        console.log(data, 'basicInfo');
        this.onDetail(this.fnaInfo.id);
        this.loading = false;
        if (this.questionInfoCom) {
          this.questionInfoCom.fnaQuestionsCopy = JSON.parse(JSON.stringify(this.questionInfoCom.fnaQuestions));
        }
        if (isTip) {
          this.onSaveToastr();
        }
      }, error => {
        this.loading = false;
      });
  }

  onSaveSign(isTip: boolean = true): void {
    this.loading = true;
    this.newFnaService.fnaEnd(this.fnaInfo.id)
      .subscribe(
        fna => {
          this.loading = false;
          if (fna) {
            this.onDetail(this.fnaInfo.id);
          }
          if (isTip) {
            this.onSaveToastr();
          }
        },
        error => {
          this.loading = false;
        });
  }

  onSaveAnalysis(isTip: boolean = true): void {
    const updateReq = new FinancialSave();
    updateReq.id = this.fnaInfo.id;
    const obj1 = plainToInstance(FnaFormInfo, this.analysisInfoCom.formInfo);
    const arr1 = Object.keys(obj1);
    const map1 = arr1.map(item => {
      return {key: item, value: this.analysisInfoCom.formInfo[item] || null};
    });
    this.loading = true;
    map1?.forEach(item => {
      if (item.key === 'FNA_ADA_01_is_transfer_in' || item.key === 'FNA_ADA_02_is_new_customer') {
        item.value = item.value === null ? null : item.value === '1';
      }
    });
    updateReq.dataList = JSON.parse(JSON.stringify(map1));

    this.newFnaService.updateAnalysis(updateReq)
      .subscribe(
        data => {
          this.loading = false;
          this.onDetail(this.fnaInfo.id);
          if (isTip) {
            this.onSaveToastr();
          }
        }, error => {
          this.loading = false;
        });
  }

  onSaveIntro(isTip: boolean = true): void {
    const updateReq = new NewFnaChooseProductReq();
    updateReq.id = this.fnaInfo.id;

    updateReq.chooseProduct = this.introductionInfoCom.chooseProduct;
    // updateReq.assessAdvice.questions = FnaQuestionNoAndResult.getQuestionNoAndResults(this.fnaQuestions);
    const obj1 = plainToInstance(FnaAssessAdvice, this.introductionInfoCom.fnaAssessAdvice);
    const arr1 = Object.keys(obj1);
    const map1 = arr1.map(item => {
      return {key: item, value: this.introductionInfoCom.fnaAssessAdvice[item] || null};
    });
    this.loading = true;
    updateReq.assessAdvice = JSON.parse(JSON.stringify(map1));
    this.newFnaService.updateFnaProduct(updateReq)
      .subscribe(
        data => {
          this.loading = false;
          this.onDetail(this.fnaInfo.id);
          if (isTip) {
            this.onSaveToastr();
          }
        }, error => {
          this.loading = false;
        });
  }

  onSaveNext(): void {
    if (this.currentIndex === 1) {
      this.onSaveBasicInfo(false);
      localStorage.removeItem('BASIC_INFO');
      setTimeout(() => {
        console.log('sdsdsdsdsdf');
        this.questionInfoCom.setForms(this.fnaInfo);
      }, 300);
    } else if (this.currentIndex === 2) {
      this.onSaveQuestion(false);
      setTimeout(() => {
        this.analysisInfoCom.setArr();
      }, 300);
    } else if (this.currentIndex === 3) {
      this.onSaveAnalysis(false);
      setTimeout(() => {
        this.introductionInfoCom.setArr();
      }, 300);
    } else if (this.currentIndex === 4) {
      this.onSaveIntro(false);
    } else if (this.currentIndex === 5) {
      this.onSaveSign(false);
    }
    this.currentIndex += 1;
    this.scrollToBottom();
  }

  onBack(type: string): void {
    if (!this.viewForm) { // 单独表格选择
      // this.router.navigate(['/sales/new-fna-view'], {queryParams: {id: this.fnaInfo.id}}).then();
      if (this.currentIndex === 1) {
        console.log(this.routerService.url);
        this.routerService
          .queryParams
          .subscribe(params => {
            console.log(params.id);
            if (params.id) {
              this.editOk(type);
              // this.onSave();
              // if (type === 'Back') {
              //   this.router.navigate(['/sales/new-fna-view'], {queryParams: {id: params.id}}).then();
              // } else {
              //   this.router.navigate(['/sales/new-fna']).then();
              // }
            } else {
              console.log('暂存');
              this.translate.get(['StagingTips', 'Cancel', 'Confirm']).subscribe(
                (res: any) => {
                  this.modalService.confirm({
                    nzCentered: true,
                    nzTitle: res.StagingTips,
                    nzKeyboard: false,
                    nzClosable: false,
                    nzContent: null,
                    nzOkText: res.Confirm,
                    nzOkType: 'primary',
                    nzOnOk: () => this.confirmOnOkl(type),
                    nzCancelText: res.Cancel,
                    nzOnCancel: () => this.confirmOnCancel(type)
                  });
                });
            }
          });
      } else {
        // this.onSave();
        this.editOk(type);
      }
    } else {
      this.router.navigate(['/user/new-fna']).then();
    }
  }

  // 修改确认
  editOk(type: string): void {
    if (this.saveInterception()) {
      this.translate.get(['ConfirmEditTips', 'Cancel', 'Confirm']).subscribe(
        (res: any) => {
          this.modalService.confirm({
            nzCentered: true,
            nzTitle: res.ConfirmEditTips,
            nzKeyboard: false,
            nzClosable: false,
            nzContent: null,
            nzOkText: res.Confirm,
            nzOkType: 'primary',
            nzOnOk: () => {
              this.onSave();
              if (type === 'Back') {
                this.router.navigate(['/user/zuu-fna-view'], {queryParams: {id: this.fnaInfo.id}}).then();
              } else {
                this.router.navigate(['/user/new-fna']).then();
              }
            },
            nzCancelText: res.Cancel,
            nzOnCancel: () => {
              if (type === 'Back') {
                this.router.navigate(['/user/zuu-fna-view'], {queryParams: {id: this.fnaInfo.id}}).then();
              } else {
                this.router.navigate(['/user/new-fna']).then();
              }
            }
          });
        });
    } else {
      if (type === 'Back') {
        this.router.navigate(['/user/zuu-fna-view'], {queryParams: {id: this.fnaInfo.id}}).then();
      } else {
        this.router.navigate(['/user/new-fna']).then();
      }
    }
  }

  confirmOnCancel(type: string): void {
    console.log('取消暂存', type);
    if (type === 'Back') {
      this.router.navigate(['/user/zuu-fna-view']).then();
    } else {
      this.router.navigate(['/user/new-fna']).then();
    }
  }

  confirmOnOkl(type: string): void {
    console.log('确定暂存', type);
    if (type === 'Back') {
      this.router.navigate(['/user/zuu-fna-view']).then();
    } else {
      this.router.navigate(['/user/new-fna']).then();
    }
    this.onStaging();
  }

  // 修改保存 -- 页面是否修改
  saveInterception(): boolean {
    let flag = false;
    if (this.fnaInfo.signatureStatus !== 'SIGNED') {
      switch (this.currentIndex) {
        case 1:
          if (this.fnaInfo.accountName !== this.accountNameStr) {
            console.log('有修改accountName');
            flag = true;
          }
          if (this.fnaInfo.customerType !== this.customerTypeStr) {
            console.log('有修改customerType');
            flag = true;
          }
          if (this.fnaInfo.fnaBusinessType !== this.fnaBusinessTypeStr) {
            console.log('有修改fnaBusinessType');
            flag = true;
          }
          if (JSON.stringify(this.basicInfoCom.basicInfo) !== JSON.stringify(this.basicInfoCom.basicInfoCopy)) {
            console.log('有修改basicInfo');
            flag = true;
          }
          break;
        case 2:
          const arr = [];
          const arrCopy = [];
          this.questionInfoCom.fnaQuestions.forEach(question => {
            this.questionInfoCom.saveFormObj(question, arr);
          });
          this.questionInfoCom.fnaQuestionsCopy.forEach(question => {
            this.questionInfoCom.saveFormObj(question, arrCopy);
          });
          console.log(arr);
          console.log(arrCopy);
          if (JSON.stringify(arrCopy) !== JSON.stringify(arr)) {
            console.log('有修改');
            flag = true;
          }
          break;
        case 3:
          console.log('shifouxiugai');
          const objData = plainToInstance(FnaFormInfo, this.analysisInfoCom.formInfo);
          const arrStr = Object.keys(objData);
          arrStr.forEach(item => {
            if (this.analysisInfoCom.formInfo[item] != this.analysisInfoCom.formInfoCopy[item]) {
              flag = true;
              console.log(3333);
            }
          });
          break;
        case 4:
          if (JSON.stringify(this.introductionInfoCom.chooseProductCopy) !== JSON.stringify(this.introductionInfoCom.chooseProduct)) {
            flag = true;
            console.log(111);
          }
          const obj1 = plainToInstance(FnaAssessAdvice, this.introductionInfoCom.fnaAssessAdvice);
          const arr1 = Object.keys(obj1);
          arr1.forEach(item => {
            if (this.introductionInfoCom.fnaAssessAdvice[item] != this.introductionInfoCom.fnaAssessAdviceCopy[item]) {
              flag = true;
              console.log(3333);
            }
          });
          break;
        case 5:
          if (this.num !== 0) {
            flag = true;
            console.log(2222);
          }
          break;
        case 6:
          if (this.fnaInfo.formLanguage !== this.formSelectInfoCom.formLanguage) {
            flag = true;
          }
          const copyArr = [];
          const dataArr = [];
          this.formSelectInfoCom.formConfigArr?.map(item => {
            this.viewVo(item.vos, copyArr);
          });
          this.formSelectInfoCom.formConfigArrCopy?.map(item => {
            this.viewVo(item.vos, dataArr);
          });
          console.log(copyArr);
          console.log(dataArr);
          if (JSON.stringify(copyArr) !== JSON.stringify(dataArr)) {
            flag = true;
            console.log(444);
          }
          break;
      }
    }

    return flag;
  }

  onStaging(): void {
    console.log('zancun');
    const obj1 = plainToInstance(NewFnaDetail, this.fnaInfo);
    console.log(obj1);
    localStorage.setItem('BASIC_INFO', JSON.stringify(obj1));
  }

  // onRewriteConfirm(modelRef: NzModalRef, params: any): void {
  //   modelRef.destroy();
  //   this.signatureInfo.signatureStatus = FnaSignedStatus.UNSIGNED;
  //   this.signaturePad?.clear();
  // }

  onSaveSignature(updateReq: FnaSignatureUpdateReq): void {
    this.loading = true;
    this.newFnaService.updateFnaSignature(updateReq)
      .subscribe(
        data => {
          this.loading = false;
          this.num += 1;
          this.newFnaService.info(updateReq.id)
            .subscribe(
              fna => {
                this.fnaInfo = JSON.parse(JSON.stringify(fna));
                setTimeout(() => {
                  this.signatureInfoCom.setSignatureValue();
                }, 200);
              },
              error => {
              });
        },
        error => {
          this.loading = false;
        }
      );
  }

  onSaveFormNos(): void {
    const param = new FnaPDFQuery();
    param.id = this.fnaInfo.id;
    const copyArr = [];
    console.log(this.formSelectInfoCom.formConfigArr, copyArr);
    this.formSelectInfoCom.formConfigArr?.map(item => {
      this.viewVo(item.vos, copyArr);
    });
    // param.formLanguage = this.formSelectInfoCom.formLanguage;
    param.categoryNos = copyArr;
    param.merge = true;
    this.loading = true;
    this.newFnaService.updateFnaFormNos(param)
      .subscribe(
        data => {
          this.loading = false;
          this.onDetail(param.id);
          this.onSaveToastr();
          this.formSelectInfoCom.formConfigArrCopy = JSON.parse(JSON.stringify(this.formSelectInfoCom.formConfigArr));
          // this.newFnaService.info(param.id)
          //   .subscribe(
          //     fna => {
          //       this.fnaInfo = JSON.parse(JSON.stringify(fna));
          //       setTimeout(() => {
          //         this.signatureInfoCom.setSignatureValue();
          //       }, 200);
          //     },
          //     error => {
          //     });
        },
        error => {
          this.loading = false;
        }
      );
  }

  viewVo(arr: FnaFormConfig[] = [], dataArr: any[] = []): void {
    console.log(arr);
    arr.map(item => {
      // if (item.tick && item.formNo) {
      //   dataArr.push(item.formNo);
      // }
      if (item.tick) {
        dataArr.push(item.category);
      }
      if (item.vos && item.vos.length) {
        this.viewVo(item.vos, dataArr);
      }
    });
  }

  onView(type: string): void {
    const param = new FnaPDFQuery();
    param.id = this.fnaInfo.id;
    const copyArr = [];
    console.log(this.formSelectInfoCom.formConfigArr, copyArr);
    this.formSelectInfoCom.formConfigArr?.map(item => {
      this.viewVo(item.vos, copyArr);
    });
    param.formLanguage = this.formSelectInfoCom.formLanguage;
    param.categoryNos = copyArr.toString();
    param.merge = type !== 'zip';

    this.loading = true;
    console.log(param, 'paramparamparam');
    this.newFnaService.viewFnaPDF(param)
      .subscribe(
        data => {
          const link = document.createElement('a');
          let blob = null;
          if (param.merge) {
            blob = new Blob([data.body], {type: 'application/pdf'});
          } else {
            blob = new Blob([data.body], {type: 'application/zip'});
          }
          console.log(data.headers, 'sdhjuhfsudhfd');
          let fileName = data.headers.get('Content-Disposition')?.split(';')[1].split('filename=')[1];
          if (fileName) {
            // 当存在 filename* 时，取filename* 并进行解码（为了解决中文乱码问题）
            fileName = decodeURIComponent(fileName.replace( /\+/g, '%20' ));
          }
          console.log(fileName);
          if (type === 'download' || type === 'zip') {
            link.setAttribute('href', window.URL.createObjectURL(blob));
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          }

          // const a = document.createElement('a');
          // a.download = this.info.searchRecordId + '.pdf';
          // a.href = window.URL.createObjectURL(new Blob([this.normalPdf], {type: 'application/pdf'}));
          // a.click();
          this.loading = false;
        }, error => {
          console.log(error);
          const reader = new FileReader();
          reader.readAsText(error.error);
          reader.onload = e => {
            const msg = JSON.parse(e.target['result'] as string);
            this.toastr.warning(msg.message ?? '');
          };
          this.loading = false;
        });
  }

}
