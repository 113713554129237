import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {

  transform(target: string, maxLength: number = 300): string {
    if (!target) {
      return 'N/A';
    }
    if (target.length >= maxLength) {
      return `${target.slice(0, maxLength)}...`;
    }
    return target;
  }

}
