import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ScheduleParams} from '../schedule-type';
import {Addresses} from '../../shared/utils/addresses';
import {AddressEditComponent} from '../../shared/component/address-edit/address-edit.component';
import {TranslateService} from '@ngx-translate/core';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {ScheduleService} from '../schedule.service';

@Component({
  selector: 'app-schedule-created',
  templateUrl: './schedule-created.component.html',
  styleUrls: ['./schedule-created.component.less']
})
export class ScheduleCreatedComponent implements OnInit {
  scheduleParams: ScheduleParams = new ScheduleParams();
  typeOptions: PropertySelectOption[] = [];
  @Output()
  scheduleCompareSave: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translate: TranslateService,
              private drawerService: NzDrawerService,
              private scheduleService: ScheduleService) {
  }

  ngOnInit(): void {
    this.translate.get(['Beginning', 'FiveMinutesAgo', 'FifteenMinutesAgo', 'ThirtyMinutesAgo', 'AnHourAgo', 'DayEarlier']).subscribe(
      datas => {

        for (const datasKey of Object.keys(datas)) {
          let value = 'BEGIN';
          if (datasKey === 'Beginning') {
            value = 'BEGIN';
          }
          if (datasKey === 'FiveMinutesAgo') {
            value = 'FIVE_MINUTE';
          }
          if (datasKey === 'FifteenMinutesAgo') {
            value = 'FIFTEEN_MINUTE';
          }
          if (datasKey === 'ThirtyMinutesAgo') {
            value = 'THIRTY_MINUTE';
          }
          if (datasKey === 'AnHourAgo') {
            value = 'ONE_HOUR';
          }
          if (datasKey === 'DayEarlier') {
            value = 'ONE_DAY';
          }
          this.typeOptions.push(new PropertySelectOption(datas[datasKey], value));
        }
      }
    );
  }

  get canReply(): boolean {
    if (!this.scheduleParams.title) {
      return false;
    }
    if (!this.scheduleParams.description) {
      return false;
    }
    if (!this.scheduleParams.startTime) {
      return false;
    }
    if (!this.scheduleParams.endTime) {
      return false;
    }
    if (!this.scheduleParams.remind) {
      return false;
    }
    return true;
  }

  onResidenceAddress(): void {
    this.translate.get('ScheduleAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getScheduleQuickAddresses([this.scheduleParams]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.scheduleParams.place,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.scheduleParams.place = data;
            }
          );
        });
      });
  }

  onSave(): void {
    if (!this.canReply) {
      return;
    }
    this.scheduleService.create(this.scheduleParams).subscribe(
      data => {
        console.log(data);
        this.scheduleCompareSave.emit();
      }
    );
  }
}
