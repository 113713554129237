import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FnaModuleAndAnswer, FnaQuestion } from '../../fna/fna-types';
import {NewFnaDetail} from '../../new-fna/new-fna-types';

@Component({
  selector: 'app-fna-template-question',
  templateUrl: './fna-template-question.component.html',
  styleUrls: ['./fna-template-question.component.less']
})
export class FnaTemplateQuestionComponent implements OnInit {
  @Input()
  fnaQuestion: FnaQuestion;
  checked = true;
  otherExisting = true;
  @Input()
  policy2 = false;
  rentalIncomeAmount: string;
  @Input()
  isDisabled: boolean;

  @Output()
  subEvent: EventEmitter<FnaModuleAndAnswer> = new EventEmitter<FnaModuleAndAnswer>();

  @Output()
  fnaChange: EventEmitter<FnaQuestion> = new EventEmitter<FnaQuestion>();
  constructor() { }

  ngOnInit(): void {
  }

  log(value: string[]): void {
    console.log(value);
  }

  isPolicy2(): void {
    if (this.fnaQuestion.questionNo === 'FNA_06') {
      console.log(this.fnaQuestion.answers[1].subQuestions[0].tick);
      const res = this.fnaQuestion.answers[1].subQuestions[0].subQuestions[1].answers?.some(item => !!item.inputBox[0].value);
      this.policy2 = res;
      console.log(this.policy2, res);
    }
  }

  FNA02log(value: boolean, info: FnaQuestion, infoNo: string, ids: string): void {
    if (!value) {
      info.answers.forEach(answer => {
        answer.tick = false;
      });
    } else {
      info.answers.forEach(answer => {
        if (ids === answer.key) {
          answer.tick = true;
        } else {
          answer.tick = false;
        }
      });
    }
    console.log(info);

    setTimeout(() => {
      this.fnaChange.emit(info);
    }, 200);
  }

  FNA04log(value: boolean, info: FnaQuestion, infoNo: string): void {
    info.answers.forEach(answer => {
      if (infoNo === answer.key) {
        answer.tick = value;
      }
    });

    setTimeout(() => {
      this.fnaChange.emit(info);
    }, 200);
  }

  FNA05log(value: boolean, info: FnaQuestion, infoNo: string, ids: string): void {
    console.log(info);
    console.log(infoNo);
    console.log(ids);
    if (!value) {
      info.answers.forEach(answer => {
        answer.subQuestions.map(item => {
          if (item.subKey === infoNo) {
            item.answers.map(subInfo => {
              subInfo.tick = false;
              if (subInfo.inputBox && subInfo.inputBox.length) {
                subInfo.inputBox.forEach(subItem => {
                  subItem.value = '';
                });
              }
            });
          }
        });
      });
    } else {
      info.answers.forEach(answer => {
        answer.subQuestions.map(item => {
          if (item.subKey === infoNo) {
            console.log(item);
            item.answers.map(subInfo => {
              console.log(subInfo.key);
              if (ids === subInfo.key) {
                subInfo.tick = true;
              } else {
                subInfo.tick = false;
                if (subInfo.inputBox && subInfo.inputBox.length) {
                  subInfo.inputBox.forEach(subItem => {
                    subItem.value = '';
                  });
                }
              }
            });
          }
        });
      });
    }
    console.log(info);

    setTimeout(() => {
      this.fnaChange.emit(info);
    }, 200);
  }

  FNA06log(value: boolean, info: FnaQuestion, infoNo: string, ids: string): void {
    console.log(info);
    console.log(infoNo);
    console.log(ids);
    if (!value) {
      info.answers.forEach(answer => {
        if (answer.key === infoNo) {
          answer.subQuestions.map(item => {
            item.tick = false;
            if (item.subKey === ids) {
              console.log(item);
              if (item.subQuestions && item.subQuestions.length) {
                item.subQuestions.map(subInfo => {
                  subInfo.answers.map(subItem => {
                    subItem.tick = false;
                    if (subItem.inputBox && subItem.inputBox.length) {
                      subItem.inputBox.forEach(subSubItem => {
                        subSubItem.value = '';
                      });
                    }
                  });
                });
              }
            }
          });
        }
      });
    } else {
      info.answers.forEach(answer => {
        if (answer.key === infoNo) {
          answer.subQuestions.map(item => {
            if (item.subKey === ids) {
              console.log(item);
              item.tick = true;
            } else {
              item.tick = false;
              if (item.subQuestions && item.subQuestions.length) {
                item.subQuestions.map(subInfo => {
                  subInfo.answers.map(subItem => {
                    subItem.tick = false;
                    if (subItem.inputBox && subItem.inputBox.length) {
                      subItem.inputBox.forEach(subSubItem => {
                        subSubItem.value = '';
                      });
                    }
                  });
                });
              }
            }
          });
        }
      });
    }
    console.log(info);

    setTimeout(() => {
      this.fnaChange.emit(info);
    }, 200);
  }

  parserValue(value: string): string {
    return value.replace(/\$\s?|(,*)/g, '');
  }

  formatterValue(value: number): string {
    if (parseFloat(String(value)).toString() === 'NaN') {
      return '';
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

}
