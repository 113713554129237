import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ScheduleItem, ScheduleParams} from '../schedule-type';
import {Addresses} from '../../shared/utils/addresses';
import {AddressEditComponent} from '../../shared/component/address-edit/address-edit.component';
import {TranslateService} from '@ngx-translate/core';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {ScheduleService} from '../schedule.service';
import {BookingDetailComponent} from '../../booking/booking-detail/booking-detail.component';
import {Booking} from '../../booking/booking-types';
import {BookingService} from '../../booking/booking.service';
import {PolicyService} from '../../policy/policy.service';
import {PolicyDetailComponent} from '../../policy/policy-detail/policy-detail.component';
import {Policy} from '../../policy/policy-types';
import {ContactInfoComponent} from '../../contact/contact-info/contact-info.component';
import {ContactService} from '../../contact/contact.service';

@Component({
  selector: 'app-schedule-edit',
  templateUrl: './schedule-edit.component.html',
  styleUrls: ['./schedule-edit.component.less']
})
export class ScheduleEditComponent implements OnInit {
  @Input()
  scheduleParams: ScheduleItem = new ScheduleItem();
  typeOptions: PropertySelectOption[] = [];
  @Output()
  scheduleCompareSave: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translate: TranslateService,
              private drawerService: NzDrawerService,
              private bookingService: BookingService,
              private policyService: PolicyService,
              private schedule: ScheduleService,
              private contactService: ContactService) {
  }

  get canReply(): boolean {
    if (this.scheduleParams.type !== 'PERSONAL') {
      return false;
    }
    if (!this.scheduleParams.title) {
      return false;
    }
    if (!this.scheduleParams.description) {
      return false;
    }
    if (!this.scheduleParams.startTime) {
      return false;
    }
    if (!this.scheduleParams.endTime) {
      return false;
    }
    if (!this.scheduleParams.remind) {
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.translate.get(['Beginning', 'FiveMinutesAgo', 'FifteenMinutesAgo', 'ThirtyMinutesAgo', 'AnHourAgo', 'DayEarlier']).subscribe(
      datas => {

        for (const datasKey of Object.keys(datas)) {
          let value = 'BEGIN';
          if (datasKey === 'Beginning') {
            value = 'BEGIN';
          }
          if (datasKey === 'FiveMinutesAgo') {
            value = 'FIVE_MINUTE';
          }
          if (datasKey === 'FifteenMinutesAgo') {
            value = 'FIFTEEN_MINUTE';
          }
          if (datasKey === 'ThirtyMinutesAgo') {
            value = 'THIRTY_MINUTE';
          }
          if (datasKey === 'AnHourAgo') {
            value = 'ONE_HOUR';
          }
          if (datasKey === 'DayEarlier') {
            value = 'ONE_DAY';
          }
          this.typeOptions.push(new PropertySelectOption(datas[datasKey], value));
        }
      }
    );
  }

  onScheduleAddress(): void {
    this.translate.get('ScheduleAddress').subscribe(
      (title: any) => {
        const quickAddresses = Addresses.getScheduleQuickAddresses([this.scheduleParams]);

        const drawerRef = this.drawerService.create<AddressEditComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: AddressEditComponent,
          nzContentParams: {
            title,
            address: this.scheduleParams.place,
            quickAddresses
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
          component.addressSaved.subscribe(
            data => {
              drawerRef.close();
              this.scheduleParams.place = data;
            }
          );
        });
      });
  }

  onSave(): void {
    if (!this.canReply) {
      return;
    }
    this.schedule.update(this.scheduleParams).subscribe(
      data => {
        this.scheduleCompareSave.emit();
      }
    );
  }

  onView(): void {
    if (this.scheduleParams.type === 'POLICY') {
      this.policyService.detail(this.scheduleParams.linkId)
        .subscribe(
          policyDetail => {
            const policy = policyDetail.toPolicy();
            const drawerRef = this.drawerService.create<PolicyDetailComponent, { value: Policy }, string>({
              nzWidth: 800,
              nzContent: PolicyDetailComponent,
              nzContentParams: {
                policyDetail,
                policy
              }
            });

            drawerRef.afterOpen.subscribe(() => {
              const component = drawerRef.getContentComponent();
            });

            drawerRef.afterClose.subscribe(data => {
              if (typeof data === 'string') {
              }
            });
          },
          error => {
          });
    }
    if (this.scheduleParams.type === 'BOOKING') {
      this.bookingService.detail(this.scheduleParams.linkId)
        .subscribe(
          bookingDetail => {
            const booking = bookingDetail.toBooking();
            const drawerRef = this.drawerService.create<BookingDetailComponent, { value: Booking }, string>({
              nzWidth: 800,
              nzContent: BookingDetailComponent,
              nzContentParams: {
                bookingDetail,
                booking
              }
            });

            drawerRef.afterOpen.subscribe(() => {
              const component = drawerRef.getContentComponent();
              component.drawerRef = drawerRef;
            });

            drawerRef.afterClose.subscribe(data => {
              if (typeof data === 'string') {
              }
            });
          },
          error => {
          });

    }
    if (this.scheduleParams.type === 'CONTACT') {
      this.contactService.detail(Number(this.scheduleParams.linkId))
        .subscribe(data => {
            const contactInfo = data;
            const drawerRef = this.drawerService.create<ContactInfoComponent, { value: any }, string>({
              nzWidth: 800,
              nzContent: ContactInfoComponent,
              nzContentParams: {
                infoID: Number(this.scheduleParams.linkId),
                contactInfo,
                // status: ContactsType.Edit
              }
            });
          },
          error => {
            console.log(error);
          });
    }
  }
}
