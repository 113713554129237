
export class Times {

  public static getAge(timestamp: number): number {
    let age = 0;

    if (!timestamp) {
      return null;
    }
    if (timestamp > new Date().getTime()) {
      age = 0;
      return ;
    }
    // 出生时间 毫秒
    const birthDayTime = new Date(timestamp).getTime();
    // 当前时间 毫秒
    const nowTime = new Date().getTime();
    // 一年毫秒数(365 * 86400000 = 31536000000)
    age = Math.floor((nowTime - birthDayTime) / 31536000000);
    return age;
  }

  public static getDateStrAge(birthDateString: string): number {
    // 将输入的字符串转换为Date对象
    const birthDate = new Date(birthDateString.replace(/-/g, '/'));

    // 获取今天的日期
    const today = new Date();

    // 计算两个日期之间的差值（以年为单位）
    const age = today.getFullYear() - birthDate.getFullYear();

    // 如果当前月份小于出生月份或同月且生日未过，则年龄减一
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())
    ) {
      return age - 1;
    }

    return age;
  }

}
