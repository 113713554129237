<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!amls">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="amls">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="amls.list"
                [nzPageIndex]="amls.pageNum"
                [nzPageSize]="amls.pageSize"
                [nzTotal]="amls.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                [nzScroll]="{ x: '1150px'}"
                (nzPageIndexChange)="onSearch($event)">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'AmlOpenAlerts' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">

                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight"
                        style=" margin-left: 10px;">
                  <img alt="" width="24" src="assets/images/ic_filter.svg">
                </button>

                <!-- <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        style=" margin-left: 10px;"
                        appThrottleClick (throttleClick)="onAmlNewInspection()">
                  <img alt="" width="22" src="assets/images/ic_add_customer.svg">
                </button> -->

              </div>
            </nz-col>
          </nz-row>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ amls.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzLeft nzWidth="165px">
            <span>{{'CustomCode' | translate}}</span>
          </th>
          <th nzWidth="160px">
            <span>{{'Name' | translate }}</span>
          </th>
          <th nzWidth="160px">
            <span>{{'NameInEnglish' | translate }}</span>
          </th>
          <th nzWidth="200px">
            <span>{{'StartMonitorTime' | translate}}</span>
          </th>
          <th nzWidth="130px">
            <span>{{'Type' | translate}}</span>
          </th>
          <th  nzWidth="200px"><span>{{'LatestMonitorTime' | translate}}</span>
          </th>
          <th nzRight nzWidth="160px">
            <span>{{'Action' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let aml of amls.list; let i = index" appThrottleClick (throttleClick)="onAmlInspectDetail(aml.id)">
          <td nzLeft>
            <span style="font-weight: bold; font-size: 13px;">
              {{aml.customCode || '-'}}
            </span>
          </td>
          <td>
            <span style="font-weight: bold; font-size: 13px;">
              {{ aml.name }}
            </span>
          </td>
          <td>
            <span style="font-weight: bold; font-size: 13px;">
              {{ aml.englishName || 'N/A'}}
            </span>
          </td>
          <td>
            <div style="display: flex; align-items: center;">
              <span>{{ aml.startMonitorDate | date:'yyyy-MM-dd HH:mm:ss' }}</span>
            </div>
          </td>
          <td>{{ aml.type | metadataTranslate: 'amlRecordType' }}</td>


          <td>
            <div style="display: flex; align-items: center;">
              <span>{{ aml.newestMonitorDate | date:'yyyy-MM-dd HH:mm:ss' }}</span>
            </div>
          </td>
          <td nzRight>
            <div class="functions">
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate" appThrottleClick (throttleClick)="onAmlInspectDetail(aml.id); $event.stopPropagation();">
                         <img alt="" width="20" src="assets/images/ic_details.svg">
                </a>
                <a nz-dropdown [nzDropdownMenu]="menu" (click)='$event.stopPropagation();'>
                  <i nz-icon nzType="ellipsis" nzTheme="outline"></i>
                </a>
                <nz-dropdown-menu #menu='nzDropdownMenu'>
                  <ul nz-menu nzSelectable>
                    <li nz-menu-item>
                      <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate" appThrottleClick (throttleClick)="onAmlDelete(aml,tplTitle,tplContent); $event.stopPropagation();">
                          <img alt="" style='vertical-align:middle'  width="18" src="assets/images/ic_delete.svg"> <span style='color:#333333'> {{'Delete' | translate}}</span>
                      </a>
                    </li>
                  </ul>
                </nz-dropdown-menu>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>

<!-- 多语言气泡Template -->
<ng-template #filterTemplate>
  <div style="width: 255px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <!-- 類型 -->
    <app-property-select [title]="'Type' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('Type' | translate)"
                         [options]="typeOptions"
                         [(value)]="search.type"></app-property-select>
    <!-- 姓氏 -->
    <app-property-input [title]="'LastName' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('LastName' | translate)"
                        [(value)]="search.surname"></app-property-input>
    <!-- 名字 -->
    <app-property-input [title]="'FirstName' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('FirstName' | translate)"
                        [(value)]="search.forename"></app-property-input>
    <!-- 客户编号 -->
    <app-property-input [title]="'CustomCode' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('CustomCode' | translate)"
                        [(value)]="search.customCode"></app-property-input>
    <!-- 創建時間 -->
    <app-property-datetime [title]="'CreateTime' | translate"
                           [(value)]="search.createTime"></app-property-datetime>

    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>  </div>
</ng-template>
<!-- 删除弹出框 -->
<ng-template #tplTitle>
  <span>
    {{ 'OperationReminder' | translate }}
  </span>
</ng-template>
<ng-template #tplContent>
  <span>
    {{ 'RemoveCustomer' | translate }}
  </span>
</ng-template>
<!-- <ng-template #tplFooter>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()">{{ Cancel | translate }}</button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="destroyTplModal(ref)" [nzLoading]="tplModalButtonLoading">
    {{ Confirm | translate}}
  </button>
</ng-template> -->
