import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class InstancesService {

  titles = {
    dev: 'LifeBee User Dev',
    lifebee: 'LifeBee User Uat',
    baylight: '湾区之光 User',
    quanbao: '圈住你的幸福~',
  };

  constructor(private titleService: Title,
              private dataService: DataService,
  ) {
  }

  // 客户定制化标题图标等
  setUp(): void {
    const instance = this.dataService.instance;
    if (instance) {
      this.titleService.setTitle(this.titles[instance.key] ?? 'LifeBee Sales');
      let link: any;
      link = document.querySelector('link[rel*="icon"]');
      if (instance.key === 'quanbao' || instance.key === 'baylight') {
        if (link) {
          link.href = this.dataService.instance.logo;
        } else {
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          link.href = this.dataService.instance.logo;
          document.getElementsByTagName('head')[0].appendChild(link);
        }
      } else {
        link.href = 'favicon.ico';
      }
    }
  }

}
