<div class="login-container">

  <div nz-row nzJustify="center" style="width: 100%; height: 100%;">
    <!--        <div nz-col [nzSpan]="10" class="login-column">-->
    <!--            <img width="75%" src="./assets/images/login_illustration.svg">-->
    <!--        </div>-->
    <div nz-col [nzSpan]="12" class="login-column">
      <form (ngSubmit)="loginByAccount()" #loginForm="ngForm" style="width: 100%" class="login-column">
        <img width="150" [src]="instance?.logo || 'assets/images/logo.png'" style="margin-bottom: 40px" alt="">
        <div class="login-title">{{ "LoginByAccount" | translate }}</div>
        <div style="width: 50%; margin-top: 20px">

          <nz-input-group [nzSuffix]="suffixAccount" class="input-group">
            <input required [(ngModel)]="loginReq.accountName" id="account" name="account" autofocus="autofocus"
                   [placeholder]="'Account' | translate" class="login-input"/>
          </nz-input-group>
          <ng-template #suffixAccount>
            <i nz-tooltip [nzTooltipTitle]="'DeviceLimitDescription' | translate" style="margin-top: 10px; font-size: 13px" nz-icon nzType="info-circle"></i>

          </ng-template>

        </div>
        <div style="width: 50%; margin-top: 20px;">

          <nz-input-group [nzSuffix]="suffixPassword" class="input-group">
            <input required [(ngModel)]="loginReq.password" id="password" name="password" [type]="passwordVisible ? 'text' : 'password'"
                   [placeholder]="'Password' | translate" class="login-input"/>
          </nz-input-group>
          <ng-template #suffixPassword>
            <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" appThrottleClick (throttleClick)="passwordVisible = !passwordVisible"></i>
          </ng-template>

        </div>
        <div style="width:50%;display: flex;margin: 10px 0;justify-content: space-between;">
          <div>
            <label nz-checkbox [(ngModel)]="checked" id="checked" name="checked" (nzCheckedChange)="checkedChange($event)">{{ "Remember" | translate }}</label>
          </div>
          <div>
            <span *ngIf="!isFone" style="cursor: pointer;" appThrottleClick (throttleClick)="onChangePwd()">{{ "ForgetPassword" | translate }}</span>
          </div>
        </div>
        <div  style="margin-bottom: 20px">
          <a nz-button nzType="link" appThrottleClick (throttleClick)="onPhoneLogin()">
            {{'PhoneNumberLogin' | translate}}<i nz-icon nzType="right" nzTheme="outline"></i>
          </a>
        </div>
        <div style="display: flex;align-items: center">
          <div *ngIf="isBack">
            <button style="width: 60px; height: 60px; margin-right: 60px;" type="button"
                    nz-tooltip [nzTooltipTitle]="'Back' | translate" [disabled]="logging"
                    nz-button nzType="default" nzShape="circle" appThrottleClick (throttleClick)="onBack()">
              <i nz-icon nzType="arrow-left"></i>
            </button>
            <!--          <button style="width: 40px; height: 40px; margin-left: 10px; margin-right: 10px;" type="button"-->
            <!--                  [disabled]="logging"-->
            <!--                  nz-tooltip [nzTooltipTitle]="'AccountSwitch' | translate"-->
            <!--                  nz-button nzType="default" nzShape="circle" appThrottleClick (throttleClick)="onAccounts()">-->
            <!--            <i nz-icon nzType="user-switch"></i>-->
            <!--          </button>-->
            <!--          <button style="width: 40px; height: 40px; margin-left: 10px;" type="button"-->
            <!--                  nz-tooltip [nzTooltipTitle]="'LoginByCode' | translate" [disabled]="logging"-->
            <!--                  nz-button nzType="default" nzShape="circle" appThrottleClick (throttleClick)="onLoginByCode()">-->
            <!--            <i nz-icon nzType="mobile"></i>-->
            <!--          </button>-->
          </div>

          <div style="margin-top: 0px;">
            <button style="width: 60px; height: 60px; color: #13263f" type="submit" [nzLoading]="logging"
                    nz-tooltip [nzTooltipTitle]="'Login' | translate"
                    nz-button nzType="default" nzShape="circle">
              <i nz-icon nzType="login"></i>
            </button>
          </div>
        </div>


      </form>
    </div>
  </div>

  <div style="position: absolute; right: 60px; top: 50px">
    <a *ngIf="accounts && accounts.length > 0" nz-button nzType="link" nzSize="small"
       style="color: #666666; font-size: 30px;"
       nz-tooltip [nzTooltipTitle]="'AccountSwitch' | translate"
       appThrottleClick (throttleClick)="onAccountSwitch(); $event.stopPropagation();">
      <i nz-icon nzType="user-switch" style="color: #666666; font-size: 30px;" nzTheme="outline"></i>
    </a>
  </div>

</div>

<ng-template #disclaimerTitle>
  <span style="font-size: 24px">{{'Disclaimer' | translate}}</span>
</ng-template>
<ng-template #disclaimerContent let-params>
  <div style="overflow-y: scroll; height: 580px">
    <p>{{ params.value }}</p>
  </div>
</ng-template>
<ng-template #disclaimerFooter let-ref="modalRef">
  <button nz-button class="disclaimer-button" nzType="dashed" nzShape="round" appThrottleClick (throttleClick)="onDisagree(ref)">
    {{'Disagree' | translate}}
  </button>
  <button #agreeButton id="agreeButton" nz-button class="disclaimer-button disclaimer-agree-button" nzType="default"  nzShape="round" appThrottleClick (throttleClick)="onAgree(ref)" autofocus="autofocus">
    {{'Agree' | translate}}
  </button>
</ng-template>
