import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { FnaModuleAndAnswer, FnaQuestion, RecommendProduct, RecommendProductCode } from '../../../fna/fna-types';
import { FnaService } from '../../../fna/fna.service';
import { deduplication } from '../../../shared/utils/collections';
import { plainToArray } from '../../../shared/utils/class-transform';
import { FnaProductListComponent } from '../../../fna/fna-product-list/fna-product-list.component';
import { FnaProductEditComponent } from '../../../fna/fna-product-edit/fna-product-edit.component';
import { FnaAssessAdvice, FnaMedicalAssessment, NewFnaAssessAdviceUpdateReq, NewFnaDetail } from '../../new-fna-types';
import { NewFnaService } from '../../new-fna.service';
import { NewFnaProductSelectionComponent } from '../new-fna-product-selection/new-fna-product-selection.component';
import { plainToInstance } from 'class-transformer';
import { RiderMode } from '../../../shared/component/product-riders-edit/product-riders-edit.component';

@Component({
  selector: 'app-new-fna-recommended-product',
  templateUrl: './new-fna-recommended-product.component.html',
  styleUrls: ['./new-fna-recommended-product.component.less']
})
export class NewFnaRecommendedProductComponent implements OnInit {
  drawerRef: NzDrawerRef<NewFnaRecommendedProductComponent, string>;

  loading: boolean;

  fna: NewFnaDetail;

  @Input()
  fnaQuestions: FnaQuestion[] = [];

  fnaQuestionMap = new Map<string, FnaQuestion>();

  recommendedProducts: RecommendProduct[];

  @Output()
  saved: EventEmitter<any> = new EventEmitter<any>();
  fnaAssessAdvice = new FnaAssessAdvice();
  fnaMedicalAssessment = new FnaMedicalAssessment();

  constructor(private drawerService: NzDrawerService,
              private newFnaService: NewFnaService,
              private fnaService: FnaService) {
  }

  ngOnInit(): void {
    this.loadDetail();
    this.fna.assessAdvice?.forEach(item => {
      this.fnaAssessAdvice[item.key] = item.value;
    });
    if (this.fnaAssessAdvice.EVALUATION_01_Yes === true) {
      this.fnaAssessAdvice.EVALUATION_01 = true;
    } else if (this.fnaAssessAdvice.EVALUATION_01_No === true) {
      this.fnaAssessAdvice.EVALUATION_01 = false;
    }
    this.fna.medicalAssessment?.forEach(item => {
      this.fnaMedicalAssessment[item.key] = item.value;
    });
  }

  loadDetail(): void {
    this.loading = true;
    this.newFnaService.info(this.fna.id)
      .subscribe(
        fna => {
          this.loading = false;
          this.fna = fna;
          this.setupRecommendedProducts();
        },
        error => {
          this.loading = false;
        });
  }

  onRecommendedProductSelect(): void {
    const drawerRef = this.drawerService.create<NewFnaProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: NewFnaProductSelectionComponent,
      nzContentParams: {
        fna: this.fna
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.recommendedSaved
        .subscribe(data => {
          this.loadDetail();
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  setupRecommendedProducts(): void {
    this.recommendedProducts = [];
    this.recommendedProducts = this.recommendedProducts
      .concat(this.fna.customize)
      .concat(this.fna.recommend)
      .concat(this.fna.history);
    this.recommendedProducts = deduplication(this.recommendedProducts, v => {
      return v.productCode;
    });
  }

  onFnaQuestionChange(fnaQuestion: FnaQuestion, i: number): void {
    this.fnaQuestions[i] = fnaQuestion;
  }

  onSubQuestionEvent(answer: FnaModuleAndAnswer): void {
    for (const subQuestion of answer.subQuestions) {
      if (answer.tick) {
        this.fnaQuestionMap.set(subQuestion.questionNo, subQuestion);
      } else {
        this.fnaQuestionMap.delete(subQuestion.questionNo);
      }
    }
    this.fnaQuestions = [...this.fnaQuestionMap.values()];
    this.fnaQuestions = this.fnaQuestions.sort((v1: FnaQuestion, v2: FnaQuestion) => {
      return Number(v1.questionNo) > Number(v2.questionNo) ? 1 : -1;
    });
  }

  onFinalProductSelect(): void {

    const products = plainToArray(RecommendProduct, this.recommendedProducts);
    for (const product of products) {
      for (const recommendProduct of this.fna.products) {
        if (product.productCode === recommendProduct.productCode) {
          product.tick = true;
        }
      }
    }

    const drawerRef = this.drawerService.create<FnaProductListComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: FnaProductListComponent,
      nzContentParams: {
        products
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.productsSaved
        .subscribe(data => {
          const endProducts = data.filter(value => {
            return value.tick;
          });
          for (const product of endProducts) {
            product.tick = false;
          }
          this.fna.products = endProducts;
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  recommendProductMapFunc = (value: RecommendProduct): RecommendProductCode => {
    const recommendProductCode = new RecommendProductCode();
    recommendProductCode.productCode = value.productCode;
    recommendProductCode.riders = value.riders?.map(r => r.productCode);
    return recommendProductCode;
  }

  onTemporarySave(): void {
    this.drawerRef?.close();
  }

  onSave(): void {
    // this.loading = true;
    const updateReq = new NewFnaAssessAdviceUpdateReq();
    updateReq.id = this.fna.id;
    updateReq.customize = this.fna.customize?.map(this.recommendProductMapFunc);
    updateReq.recommend = this.fna.recommend?.map(this.recommendProductMapFunc);
    updateReq.history = this.fna.history?.map(this.recommendProductMapFunc);
    // updateReq.assessAdvice.questions = FnaQuestionNoAndResult.getQuestionNoAndResults(this.fnaQuestions);
    updateReq.productCodes = this.fna.products?.map(value => {
      return value.productCode;
    });
    const obj1 = plainToInstance(FnaAssessAdvice, this.fnaAssessAdvice);
    const obj2 = plainToInstance(FnaMedicalAssessment, this.fnaMedicalAssessment);
    const arr1 = Object.keys(obj1);
    const arr2 = Object.keys(obj2);
    if (this.fnaAssessAdvice.EVALUATION_01 === true) {
      this.fnaAssessAdvice.EVALUATION_01_Yes = true;
      this.fnaAssessAdvice.EVALUATION_01_No = false;
    } else if (this.fnaAssessAdvice.EVALUATION_01 === false) {
      this.fnaAssessAdvice.EVALUATION_01_Yes = false;
      this.fnaAssessAdvice.EVALUATION_01_No = true;
    }
    const map1 = arr1.map(item => {
      return { key: item, value: this.fnaAssessAdvice[item] || null };
    });
    const map2 = arr2.map(item => {
      return { key: item, value: this.fnaMedicalAssessment[item] || null };
    });
    console.log(map1, 'map1map1map1');
    updateReq.assessAdvice = JSON.parse(JSON.stringify(map1));
    updateReq.medicalAssessment = JSON.parse(JSON.stringify(map2));
    this.newFnaService.updateFnaAdvice(updateReq)
      .subscribe(
        data => {
          this.loading = false;
          this.saved.emit('');
          this.drawerRef?.close();
        }, error => {
          this.loading = false;
        });
  }

  onRecommendedProductEdit(product: RecommendProduct, index: number): void {

    const drawerRef = this.drawerService.create<FnaProductEditComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: FnaProductEditComponent,
      nzContentParams: {
        product,
        isNewFna: true
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.productSaved
        .subscribe(data => {
          this.recommendedProducts[index] = data;
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  get canSave(): boolean {
    // return this.fna.productCanSave;
    return true;
  }

}
