<div class="drawer-container">
  <div class="drawer-title" style="margin-bottom: 40px">
    {{'CommissionsDetail' | translate}}
  </div>

  <div>
    <div class="commissionDetailTop">
      <div>
        <img width="80" style="border-radius: 3px; margin-right: 5px" [src]="detailInfo.companyLogo" alt=""/>
      </div>
      <div>{{detailInfo.productName}}</div>
      <div class="cardMoney">HKD {{detailInfo.commission | number: '.2'}}</div>
    </div>
    <nz-divider></nz-divider>
    <nz-row [nzGutter]="15">
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'Referee' | translate"
                           [bottomLineVisible]="false" [value]="(detailInfo.accountName + ' ' + detailInfo.nickName)"></app-property-text>
      </nz-col>

      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'PolicyNumber' | translate "
                           [bottomLineVisible]="false" [value]="detailInfo.policyNo"></app-property-text>
      </nz-col>

      <!--    投保人-->
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'PolicyHolder' | translate"
                           [bottomLineVisible]="false" [value]="detailInfo.ownerName"></app-property-text>
      </nz-col>

      <!--    受保人-->
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'Insured' | translate"
                           [bottomLineVisible]="false" [value]="detailInfo.insuredName"></app-property-text>
      </nz-col>

      <!--    供款年期-->
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'PaymentTerm' | translate"
                           [bottomLineVisible]="false" [value]="detailInfo.productYear"></app-property-text>
      </nz-col>

      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'PaymentDates' | translate"
                           [bottomLineVisible]="false" [value]="detailInfo.premiumDate"></app-property-text>
      </nz-col>

      <!--    货币-->
      <nz-col [nzSpan]="12">
        <app-property-text [displayMode]="'vertical'" [title]="'Currency' | translate"
                           [bottomLineVisible]="false" [value]="(detailInfo.currency)"></app-property-text>
      </nz-col>
      <!--    保費-->
      <nz-col [nzSpan]="12">
        <div style="position: relative">
          <app-property-text [displayMode]="'vertical'" [title]="'Premium' | translate"
                             [bottomLineVisible]="false" [value]="toDecimal(detailInfo.premium | number)"></app-property-text>
          <a nz-icon nzType="info-circle"
             nzTheme="outline"
             nz-tooltip [nzTooltipTitle]="'CommissionDetailTips' | translate"
             style="position: absolute; top: 19px; left: -18px;"></a>
        </div>
      </nz-col>
    </nz-row>
  </div>
</div>
