import {Component, Input, OnInit} from '@angular/core';
import {BorwseInfo, ConnectionsInfo, InformationData} from '../share-type';
import {ShareService} from '../share.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {ShareConnectionsComponent} from '../share-connections/share-connections.component';
import {ShareBrowseInfoComponent} from '../share-browse-info/share-browse-info.component';
import {KnowledgeDetailComponent} from '../../knowledge/knowledge-detail/knowledge-detail.component';
import {KnowledgeService} from '../../knowledge/knowledge.service';
import {TranslateService} from '@ngx-translate/core';
import {ProductDetailComponent} from '../../product/product-detail/product-detail.component';
import {Product} from '../../product/product-types';
import {ProductService} from '../../product/product.service';
import {ProductComparisonResultComponent} from '../../product/product-comparison-result/product-comparison-result.component';

@Component({
  selector: 'app-share-detail',
  templateUrl: './share-detail.component.html',
  styleUrls: ['./share-detail.component.less']
})
export class ShareDetailComponent implements OnInit {
  shareDetail: BorwseInfo;
  loading: false;

  constructor(private shareService: ShareService,
              private knowledgeService: KnowledgeService,
              private productService: ProductService,
              private translate: TranslateService,
              private drawerService: NzDrawerService) {
  }

  ngOnInit(): void {
  }

  onMinute(i: number): number {
    return Math.ceil(i / 60);
  }


  onConnectionsDetail(id: number): void {
    this.shareService.connectionsDetail(id).subscribe(
      connectionsInfo => {
        const drawerRef = this.drawerService.create<ShareConnectionsComponent, { value: any }, string>({
          nzWidth: 600,
          nzMaskClosable: true,
          nzContent: ShareConnectionsComponent,
          nzContentParams: {
            connectionsInfo
          },
        });

        drawerRef.afterOpen.subscribe(() => {
          const component = drawerRef.getContentComponent();
        });

        drawerRef.afterClose.subscribe(data => {
          const component = drawerRef.getContentComponent();
        });
      }
    );
  }

  onBrowseInfo(shareDetail: BorwseInfo): void {
    if (shareDetail.shareSources === 'KNOWLEDGE') {
      this.knowledgeService.detail(Number(shareDetail.key))
        .subscribe(
          knowledge => {
            this.loading = false;
            const drawerRef = this.drawerService.create<KnowledgeDetailComponent, { value: any }, string>({
              nzWidth: 800,
              nzMaskClosable: true,
              nzContent: KnowledgeDetailComponent,
              nzContentParams: {
                knowledge
              },
            });

            drawerRef.afterOpen.subscribe(() => {
              const component = drawerRef.getContentComponent();
            });

            drawerRef.afterClose.subscribe(data => {
              const component = drawerRef.getContentComponent();
            });
          },
          error => {
            this.loading = false;
          }
        );
    }
    if (shareDetail.shareSources === 'INFORMATION' || shareDetail.shareSources === 'ARTICLE') {
      this.translate.get('Browsing').subscribe(res => {
        this.shareService.shareInfo(shareDetail.shareCode).subscribe(
          browseInfo => {
            const drawerRef = this.drawerService.create<ShareBrowseInfoComponent, { value: any }, string>({
              nzWidth: 600,
              nzTitle: res,
              nzMaskClosable: true,
              nzContent: ShareBrowseInfoComponent,
              nzContentParams: {
                browseInfo
              },
            });

            drawerRef.afterOpen.subscribe(() => {
              const component = drawerRef.getContentComponent();
            });

            drawerRef.afterClose.subscribe(data => {
              const component = drawerRef.getContentComponent();
            });
          }
        );
      });
    }
    if (shareDetail.shareSources === 'PRODUCT_COMPARE') {
      const primaryProduct: Product = new Product();
      const secondaryProducts: Product[] = [];
      const key = shareDetail.key.split('&');
      primaryProduct.productCode = key[1].split('leftProductCode=')[1];
      key[2].split('=')[1].split(',').forEach(e => {
        const item: Product = new Product();
        if (e) {
          item.productCode = e;
          secondaryProducts.push(item);
        }
      });

      const drawerRef = this.drawerService.create<ProductComparisonResultComponent, { value: string }, string>({
        nzWidth: '100vw',
        nzContent: ProductComparisonResultComponent,
        nzContentParams: {
          primaryProduct,
          secondaryProducts
        }
      });

      drawerRef.afterOpen.subscribe(() => {
        const component = drawerRef.getContentComponent();
      });

      drawerRef.afterClose.subscribe(data => {
      });
    }
    if (shareDetail.shareSources === 'QUOTATION') {

    }
    if (shareDetail.shareSources === 'PRODUCT_DETAIL') {
      const key = shareDetail.key.split('&productCode=');
      this.productService.detail(key[1])
        .subscribe(
          product => {
            const drawerRef = this.drawerService.create<ProductDetailComponent, { value: Product }, string>({
              nzWidth: 800,
              nzContent: ProductDetailComponent,
              nzContentParams: {
                product
              }
            });

            drawerRef.afterOpen.subscribe(() => {
              const component = drawerRef.getContentComponent();
              component.drawerRef = drawerRef;
            });

            drawerRef.afterClose.subscribe(data => {
            });
          },
          error => {
          });
    }
  }

}
