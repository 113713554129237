import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-property-editor',
  templateUrl: './property-editor.component.html',
  styleUrls: ['./property-editor.component.less']
})
export class PropertyEditorComponent implements OnInit {

  @Input() title: string;
  @Input() placeholder: string;
  @Input() tip: string;
  @Input() tipVisible = false;
  @Input() borderless = true;
  @Input() disabled = false;
  @Input() required = false;
  @Input() bottomLineVisible = true;

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  innerValue: any;

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }],               // custom button values
      [{ list: 'ordered'}, { list: 'bullet' }],
      [{ script: 'sub'}, { script: 'super' }],      // superscript/subscript
      [{ indent: '-1'}, { indent: '+1' }],          // outdent/indent
      [{ direction: 'rtl' }],                         // text direction

      [{ size: ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'],                                         // remove formatting button
    ]
  };

  @Input()
  get value(): any {
    return this.innerValue;
  }

  set value(val: any) {
    this.innerValue = val;
    this.valueChange.emit(this.innerValue);
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
