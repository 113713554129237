<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px ;font-weight: bold">
    {{ Title }}
  </div>

  <!--  内容回填-->
  <div class="dialog-reply" *ngIf="list && list.length > 0;">
    <div *ngFor="let item of list" class="list-item">
      <div class="list-top">
<!--        <nz-avatar class="list-top-avatar" style="margin-right: 20px;" [nzSize]="40" [nzSrc]="item.avatar"></nz-avatar>-->
<!--        <div style="display: flex; flex-direction: column">-->
<!--          <div style="display: flex; align-items: center">-->
<!--            <div class="list-top-name" style="margin-right: 10px; font-size: 14px">{{ item.accountName }}</div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="list-bottom">
        <div *ngIf="!isAnnex" class="ticket-reply-content" [innerHTML]="item.content | html"></div>
        <app-attachments *ngIf="item.attachments?.length > 0"
                         [attachments]="item.attachments"
                         [readonly]="true"></app-attachments>
      </div>
    </div>
  </div>
<!--  <ng-template #secondaryEmpty>-->
<!--    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="contentTpl">-->
<!--      <ng-template #contentTpl>-->
<!--        <span>-->
<!--          {{ 'NoMessage' | translate }}-->
<!--        </span>-->
<!--      </ng-template>-->
<!--    </nz-empty>-->
<!--  </ng-template>-->
  <!--  回复内容-->
  <div class="">
    <div nz-row style="width: 100%;">
      <div nz-col nzSpan="24">
        <div *ngIf="!isAnnex" style="margin: 15px 0px 10px 0px">
          <app-property-editor [bottomLineVisible]="false"
                               [required]="false"
                               [title]="'Content' | translate"
                               [placeholder]="('PleaseEnter' | translate) + ('ReplyContent' | translate)"
                               [(value)]="dialogReply.content"></app-property-editor>
        </div>
      </div>
      <div nz-col nzSpan="24" style="padding: 0px">
        <app-attachments [attachments]="dialogReply.attachments"
                         [uploadTokenReq]="uploadTokenReq"
                         [isPreview]="true"
                         (valueChange)="replyAttachmentsChange($event)"
                         [showEmpty]="false"></app-attachments>
      </div>
      <div nz-col nzSpan="24" class="footer-button-primary" style="height: 50px; border-radius: 10px; margin: 15px 0 0 0">
        <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="reply()" [disabled]="!canReply"
           [style]="!canReply ? 'opacity: 0.5' : ''">
          <span>{{ isAnnex ? ('BatchUpload' | translate) : ('BatchReply' | translate) }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
