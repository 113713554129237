import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ApiResponse, UploadTokenReq} from '../../api/types';
import {DatePipe} from '@angular/common';
import {DocumentService} from '../document.service';
import {FileItem, FileUploader, Headers, ParsedResponseHeaders} from 'ng2-file-upload';
import {API} from '../../api/api';
import {HttpService} from '../../core/http.service';
import {FileLikeObject} from 'ng2-file-upload/file-upload/file-like-object.class';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.less'],
  providers: [DatePipe]
})
export class DocumentUploadComponent implements OnInit {

  // fileUpload: FileUpload = new FileUpload();
  // uploadTokenReq = new UploadTokenReq();

  uploader: FileUploader;
  @ViewChild('fileUpload')
  fileUpload: ElementRef;


  spinning = false;
  @Input()
  leader: number;

  @Output()
  value: EventEmitter<number> = new EventEmitter<number>();

  // @Output()
  // fileUploadRes: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private datePipe: DatePipe,
              private httpService: HttpService,
              private toastr: ToastrService,
              private translate: TranslateService,
              private documentService: DocumentService) {
  }

  ngOnInit(): void {
    // this.uploadTokenReq.uploadType = UploadType.DOCUMENT;
    // console.log(this.leader);
    // this.uploadTokenReq.middleName = 'temp' + this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    if (!this.leader) {
      this.leader = 0;
    }
    this.uploader = new FileUploader({
      // headers: headers,
      // allowedMimeType: ["application/vnd.ms-excel"],
      disableMultipart: false,
      autoUpload: false,
      removeAfterUpload: false,
      // maxFileSize: this.fileAlert.maxFileSize
    });
    this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);
    this.uploader.onSuccessItem = this.onUploadSuccess.bind(this);
    this.uploader.onErrorItem = this.onUploadError.bind(this);
    this.uploader.onWhenAddingFileFailed = this.onWhenAddingFileFailed.bind(this);
  }

  selectedImportFileOnChanged(event): void {
  }

  onAddAttachment(): void {
    if (this.fileUpload) {
      this.fileUpload.nativeElement.click();
    }
  }

  onAfterAddingFile(fileItem: FileItem): any {
    fileItem.withCredentials = false;
    if (this.fileUpload) {
      // 重置文件选择，否则无法重复上传同一个文件
      this.fileUpload.nativeElement.value = '';
    }
    this.uploadFileItem(fileItem);
  }

  uploadFileItem(fileItem: FileItem): void {
    console.log(fileItem);
    fileItem.method = 'POST';
    fileItem.url = API.documentV3 + '/upload';
    fileItem.alias = 'files';
    this.spinning = true;
    const headers: Headers[] = [];
    const headersMap = this.httpService.getApiHttpHeaders(fileItem.url);
    headersMap.forEach((value, key, map) => {
      headers.push({name: key, value});
    });
    fileItem.headers = headers;
    fileItem.onBuildForm = (form) => {
      form.append('leader', this.leader);
    };
    fileItem.upload();
  }

  // 文件上传成功
  onUploadSuccess(item: FileItem, response: any, status: number, headers: ParsedResponseHeaders): any {
    this.spinning = false;
    response = JSON.parse(response);
    console.log(response);
    if (response.data) {
      this.translate.get('UploadSuccess').subscribe(
        (res) => {
          this.toastr.success(res);
        }
      );
    }
    if (response.message) {
      this.toastr.info(response.message);
    }
    this.uploader.removeFromQueue(item);
    this.value.emit(status);
  }

  onUploadError(item: FileItem, response: any, status: number, headers: ParsedResponseHeaders): any {
    this.spinning = false;
    response = JSON.parse(response);
    if (response.message !== 'null') {
      this.toastr.warning(response.message);
    }
    this.uploader.removeFromQueue(item);
    this.value.emit(status);
    console.log(response + ' for ' + item.file.name + ' status ' + status, '===========');
  }

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any): any {
    this.spinning = false;
    console.log(item, filter, options, '----------------');
    // if (filter.name === 'fileSize') {
    //   this.fileAlert.showAlert = true;
    //   this.fileAlert.fileSize = item.size;
    //   this.translate.get('ExceedsTheLimitsOfFileSize',
    //     {maxLength: this.fileAlert.maxFileSizeStr, length: this.fileAlert.fileSizeStr})
    //     .subscribe(
    //       text => {
    //         this.notificationService.create(
    //           'warning',
    //           '',
    //           text
    //         );
    //       }
    //     );
    // }
  }

  // onAdd(attachments: Material): void {
  //   this.fileUpload.files = attachments;
  //   console.log(attachments);
  //   console.log(this.leader);
  //   console.log(this.fileUpload);
  // }
  //
  // upload(): void {
  //   if (!this.canReply) {
  //     return;
  //   }
  //   if (!this.leader) {
  //     this.leader = 0;
  //   }
  //   this.fileUpload.leader = this.leader;
  //   this.spinning = true;
  //   console.log('eeeeeeeee', this.fileUpload);
  //   this.documentService.upload(this.fileUpload)
  //     .subscribe(data => {
  //       this.spinning = false;
  //       this.fileUploadRes.emit(data);
  //     }, error => {
  //       this.spinning = false;
  //     });
  // }
  //
  // get canReply(): boolean {
  //   return !!this.fileUpload.files;
  // }
}
