<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div *ngIf="!documentTable">
      <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    </div>
    <div *ngIf="documentTable" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="true"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="documentTable.list"
                [nzPageIndex]="documentTable.pageNum"
                [nzPageSize]="documentTable.pageSize"
                [nzTotal]="documentTable.total"
                [nzTitle]="tableHeader"
                [nzFooter]="tableFooter"
                [nzScroll]="{ x: '1315'}"
                (nzPageIndexChange)="onSearch($event,'Turn')">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="flex-row">
                <div class="list-header-title">
                  {{'FileList' | translate}}
                  <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                     style="margin-left: 5px"
                     appThrottleClick (throttleClick)="onReload()">
                    <em nz-icon nzType="reload" nzTheme="outline"></em>
                  </a>
                </div>
              </div>
            </nz-col>
            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <div *ngIf="mode === 'OWN' && space">
                  <app-progress-bar [width]="'180px'" [progress]="(space.currentUsedSpace * 100 / space.totalSpace) " [showPercentage]="false" [text]="space.toString()" [borderRadius]="'2px'">
                  </app-progress-bar>
                </div>
                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        *ngIf="mode == 'OWN'"
                        nz-tooltip [nzTooltipTitle]="'UploadData' | translate"
                        nz-popover [nzPopoverContent]="uploadTemplate"
                        [(nzPopoverVisible)]="uploadPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight"
                        style=" margin-left: 10px;">
                  <img alt="" width="22" src="../../../../assets/images/ic_add.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        *ngIf="mode == 'OWN' || mode === 'SUBORDINATE'"
                        nz-tooltip [nzTooltipTitle]="'FileSearch' | translate"
                        nz-popover [nzPopoverContent]="searchTemplate"
                        [(nzPopoverVisible)]="searchPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight"
                        style=" margin-left: 10px;">
                  <img alt="" width="24" src="assets/images/ic_search.svg">
                </button>
              </div>
            </nz-col>
          </nz-row>
          <div *ngIf="fileNav.length > 1">
            <!-- 面包屑 -->
            <nz-breadcrumb nzSeparator=">">
              <nz-breadcrumb-item *ngFor="let item of fileNav; let i = index">
                <a [style]="i === fileNav.length - 1 ? 'cursor: default;' : ''" appThrottleClick
                   (throttleClick)="breadcrumb(item, i)">{{ item.label }}</a>
              </nz-breadcrumb-item>
            </nz-breadcrumb>
          </div>
        </ng-template>
        <ng-template #tableFooter>
          <div class="table-title">
            {{'TotalRows' | translate}} : <span
            style="color: #002140; font-weight: bold;">{{ documentTable.total }}</span>
          </div>
        </ng-template>

        <thead>
        <tr>
          <th nzWidth="390px">
            <span>{{'DocumentName' | translate}}</span>
          </th>
          <th nzWidth="200px" *ngIf="!workbench.hideUploader">
            <span>{{'Uploader' | translate}}</span>
          </th>
          <th nzWidth="150px">
            <span>{{'FileSize' | translate}}</span>
          </th>
          <th nzWidth="200px">
            <span>{{'UpdateTime' | translate}}</span>
          </th>
          <th>
            <span>{{'Remark' | translate}}</span>
          </th>
          <th nzRight [nzWidth]="mode === 'OWN' ? '250px' : '170px'" nzAlign="left"><span style="margin-left: 10px">{{'Functions' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let document of documentTable.list; let i = index" appThrottleClick
            (throttleClick)="enterFolder(document)">
          <td nzWidth="390px" style="display: flex; align-items: center"
              [style]="document.type === 'DIRECTORY' ? 'cursor: pointer;' : ''">
            <img *ngIf="document.type === 'FILE'" alt="" width="30" [src]="iconOfFileName(document.name)">
            <img *ngIf="document.type === 'DIRECTORY'" alt="" width="30"
                 src="../../../assets/images/ic_collection_folder.svg">
            <span style="width: 390px;font-weight: bold; font-size: 12px; margin-left: 5px;word-break: break-all;">
              {{ document.name }}
            </span>
          </td>
          <td *ngIf="!workbench.hideUploader">{{ document.accountName }}</td>
          <td>{{ document.fileSizeText }}</td>
          <td>
            <div style="display: flex; align-items: center;">
              <span>{{ (document.updateTime | date:'yyyy-MM-dd HH:mm:ss') || 'N/A' }}</span>
            </div>
          </td>
          <td>
            <div style="display: flex; align-items: center;">
              <span>{{ document.remark }}</span>
            </div>
          </td>
          <td nzRight nzAlign="left">
            <div class="functions" style="display: flex;">
              <a *ngIf="document.type != 'DIRECTORY' && !document.isCollected"
                 nz-button nzType="link"
                 nzSize="small"
                 appThrottleClick (throttleClick)="isCollection(document); $event.stopPropagation();"
                 nz-tooltip [nzTooltipTitle]="'Collection' | translate">
                <img alt="" width="20" src="../../../assets/images/ic_collection_def.svg">
              </a>
              <a *ngIf="document.type != 'DIRECTORY' && document.isCollected"
                 nz-button nzType="link"
                 nzSize="small"
                 appThrottleClick (throttleClick)="isCollection(document); $event.stopPropagation();"
                 nz-tooltip [nzTooltipTitle]="'CancelCollection' | translate">
                <img alt="" width="20" src="../../../assets/images/ic_collection_sel.svg">
              </a>

              <!--              預覽-->
              <a *ngIf="document.type === 'FILE' && previewFilter(document.url)"
                 appThrottleClick (throttleClick)="onPreview(document); $event.stopPropagation();"
                 nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Preview' | translate">
                <img alt="" width="20" src="../../../assets/images/ic_view.svg">
              </a>
              <!--              詳情-->
              <a *ngIf="document.type === 'DIRECTORY'"
                 nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Detail' | translate">
                <img alt="" width="20" src="../../../assets/images/ic_details.svg">
              </a>
              <!--              下載-->
              <a *ngIf="document.type != 'DIRECTORY' && document.download"
                 [nzLoading]="btnLoading"
                 appThrottleClick (throttleClick)="onDownload(document, $event); $event.stopPropagation();"
                 nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Download' | translate">
                <img *ngIf="!btnLoading" alt="" width="20" src="../../../assets/images/ic_download.svg">
              </a>

              <a appThrottleClick *ngIf="mode === 'OWN'"
                 (throttleClick)="onEdit(document, tplEditTitle, tplEditContent, tplEditFooter); $event.stopPropagation();"
                 nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Edit' | translate">
                <img *ngIf="!btnLoading" alt="" width="20" src="../../../assets/images/ic_edit.svg">
              </a>

              <a appThrottleClick (throttleClick)="onRemark(document, tplRemarkTitle, tplRemarkContent, tplRemarkFooter); $event.stopPropagation();"
                 nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Remark' | translate">
                <img *ngIf="!btnLoading" alt="" width="20" src="../../../assets/images/ic_remark.svg">
              </a>

              <a nz-button nzType="link"
                 nzSize="small"
                 *ngIf="mode == 'OWN'"
                 appThrottleClick (throttleClick)="deleteFile(document,tplTitle,tplContent); $event.stopPropagation();"
                 nz-tooltip [nzTooltipTitle]="'Delete' | translate">
                <img alt="" width="20" src="../../../assets/images/ic_delete.svg">
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>
<!--预览-->
<nz-modal [nzContent]="modalContent"
          [(nzVisible)]="isVisible"
          [nzWidth]="preview== 'video'? '840px' : '520px'"
          [nzTitle]="'Preview' | translate"
          [nzFooter]="null"
          (nzOnCancel)="handleCancel()">
  <ng-template #modalContent>
    <div style="text-align: center" *ngIf="preview == 'audio'">
      <audio controls *ngIf="audio">
        <source [src]="audio">
      </audio>
    </div>
    <div *ngIf="preview == 'video'">
            <video width="800" height="600" [src]='video' controls poster>
            </video>
    </div>
  </ng-template>

</nz-modal>
<!-- 搜索气泡Template -->
<ng-template #searchTemplate>
  <div style="width: 300px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'FileSearch' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <!-- 名字 -->
    <app-property-input [title]="'DocumentName' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('DocumentName' | translate)"
                        [(value)]="search.name"></app-property-input>
    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onResetSearch()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                (throttleClick)="onSearch(1, 'Search')">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>


<!-- 上传气泡Template -->
<ng-template #uploadTemplate>
  <div style="width: 170px; padding: 5px;">
    <button nz-button style="width: 100%; margin-bottom: 10px"
            nzType="default"
            appThrottleClick (throttleClick)="onFolderCreate()">
      {{'NewFolder' | translate}}
    </button>
    <!--    <button nz-button style="width: 100%;"-->
    <!--            nzType="default"-->
    <!--            appThrottleClick (throttleClick)="onUpload()">-->
    <!--      {{'UploadFile' | translate}}-->
    <!--    </button>-->
    <app-document-upload [leader]="currFolderId"
                         (value)="afterUploading($event)"></app-document-upload>
  </div>
</ng-template>

<!-- 删除弹出框 -->
<ng-template #tplTitle>
  <span>
    {{ 'OperationReminder' | translate }}
  </span>
</ng-template>
<ng-template #tplContent>
  <span>
    {{ 'RemoveFileTip' | translate }}
  </span>
</ng-template>

<!-- 删除备注框 -->
<ng-template #tplRemarkTitle>
  <i nz-icon nzType="edit" nzTheme="outline"
     style="color: #07367c; font-size: 17px; margin-right: 5px"></i><span>{{'Remark' | translate}}</span>
</ng-template>
<ng-template #tplRemarkContent let-params>
  <textarea class="remark-input" style="width: 100%;" [(ngModel)]="params.remark" placeholder=""></textarea>
</ng-template>
<ng-template #tplRemarkFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()">
    <!--    <i nz-icon nzType="close" nzTheme="outline" style="font-size: 13px;"></i>-->
    <span>{{'Close' | translate}}</span>
  </button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onRemarkConfirm(ref, params)">
    <!--    <i nz-icon nzType="save" nzTheme="outline" style="font-size: 13px;"></i>-->
    <span>{{'Confirm' | translate}}</span>
  </button>
</ng-template>

<!-- 编辑 -->
<ng-template #tplEditTitle>
  <i nz-icon nzType="edit" nzTheme="outline"
     style="color: #07367c; font-size: 17px; margin-right: 5px"></i><span>{{'Edit' | translate}}</span>
</ng-template>
<ng-template #tplEditContent let-params>
  <textarea class="remark-input" style="width: 100%;" [(ngModel)]="params.name" placeholder=""></textarea>
</ng-template>
<ng-template #tplEditFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()">
    <!--    <i nz-icon nzType="close" nzTheme="outline" style="font-size: 13px;"></i>-->
    <span>{{'Close' | translate}}</span>
  </button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onEditConfirm(ref, params)">
    <!--    <i nz-icon nzType="save" nzTheme="outline" style="font-size: 13px;"></i>-->
    <span>{{'Confirm' | translate}}</span>
  </button>
</ng-template>
