import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/service/data.service';
import { WorkbenchService } from '../workbench/workbench.service';
import { Workbench } from '../workbench/workbench-types';

@Component({
  selector: 'app-underwriting-main',
  templateUrl: './underwriting-main.component.html',
  styleUrls: ['./underwriting-main.component.less']
})
export class UnderwritingMainComponent implements OnInit {
  workbench: Workbench;

  constructor(public dataService: DataService,
              private workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
  }
}
