import {Component, OnInit} from '@angular/core';
import { InformationData} from '../share-type';

@Component({
  selector: 'app-share-browse-info',
  templateUrl: './share-browse-info.component.html',
  styleUrls: ['./share-browse-info.component.less']
})
export class ShareBrowseInfoComponent implements OnInit {
  browseInfo: InformationData;
  loading: false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
