<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 30px">
    {{title}}
  </div>

  <div *ngIf="relationFlag()">
    <div *ngIf="customerType === 'INDIVIDUAL' && relationship != 'OWN'" appThrottleClick (throttleClick)="onContacts()">
      <a nz-button nzType="link">{{'importContacts' | translate}}</a>
    </div>
  </div>
  <div class="section-wrapper">
    <!-- 客户类型 -->
    <app-property-select [title]="'TypeOfCustomer' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('TypeOfCustomer' | translate)"
                         [options]="customerTypeOptions"
                         [required]="true"
                         [bottomLineVisible]="!instance.isFone() && mode === 'holder'"
                         [(value)]="customerType"
                         (valueChange)="isDefaultFill()"></app-property-select>
    <app-property-select *ngIf="!instance.isFone() && mode === 'holder'"
                         [title]="'BusinessSource' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('BusinessSource' | translate)"
                         [options]="businessSourceOptions"
                         [bottomLineVisible]="false"
                         [(value)]="customer.businessSource"></app-property-select>
  </div>
  <div class="section-wrapper" *ngIf="mode === 'insured'">
    <!-- 与投保人关系 -->
    <app-property-select [title]="'RelationshipToHolder' | translate"
                         [placeholder]="('PleaseSelect' | translate) + ('RelationshipToHolder' | translate)"
                         [options]="relationshipOptions"
                         [required]="true"
                         [bottomLineVisible]="false"
                         [(value)]="relationship"></app-property-select>
  </div>

  <div *ngIf="mode === 'holder' || (mode === 'insured' && relationship != 'OWN')">
    <div [ngSwitch]="customerType">
      <!-- 個人 -->
      <div *ngSwitchCase="'INDIVIDUAL'">
        <div class="section-wrapper">
          <!-- 姓氏 -->
          <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LastName' | translate"
                              [placeholder]="('PleaseEnter' | translate) + ('LastName' | translate)"
                              [required]="isLastName()"
                              [disabled]="isInputName"
                              [(value)]="customer.lastName"></app-property-input>

          <!-- 名字 -->
          <app-property-input [title]="'FirstName' | translate"
                              [placeholder]="('PleaseEnter' | translate) + ('FirstName' | translate)"
                              [required]="isLastName()"
                              [(value)]="customer.firstName"></app-property-input>
          <!-- 姓氏-英文 -->
          <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LastNameInEnglish' | translate"
                              [placeholder]="('PleaseEnter' | translate) + ('LastNameInEnglish' | translate)"
                              [required]="isEnglishName()"
                              [disabled]="isInputName"
                              [tipVisible]="!instance.isFone() && isCreate" [tip]="'nameTips' | translate"
                              [tipColor]="'red'"
                              [(value)]="customer.lastNamePinyin"></app-property-input>
          <!-- 名字-英文 -->
          <app-property-input [title]="'FirstNameInEnglish' | translate"
                              [placeholder]="('PleaseEnter' | translate) + ('FirstNameInEnglish' | translate)"
                              [required]="isEnglishName()"
                              [tipVisible]="!instance.isFone() && isCreate" [tip]="'nameTips' | translate"
                              [tipColor]="'red'"
                              [(value)]="customer.firstNamePinyin"></app-property-input>

          <div *ngIf="!(instance.isWDF() && modeType === 'underwriting')">
            <!-- 性别 -->
            <app-property-select [title]="'Gender' | translate"
                                 [placeholder]="('PleaseSelect' | translate) + ('Gender' | translate)"
                                 [options]="genderOptions"
                                 [required]="isGender()"
                                 [(value)]="customer.gender"></app-property-select>
            <!-- 身份證號 -->
            <app-property-input [title]="(instance.isIAM() && modeType === 'booking' ? ('IDNo' | translate) : ('IdentityCardNumber' | translate))"
                                [placeholder]="('PleaseEnter' | translate) + (instance.isIAM() && modeType === 'booking' ? ('IDNo' | translate) : ('IdentityCardNumber' | translate))"
                                [required]="IdCard"
                                (valueChange)="getIdCardChange($event)"
                                [(value)]="customer.idCard"></app-property-input>

            <app-property-input *ngIf="instance.isIAM() && modeType === 'booking'" [title]="'BirthCertNo' | translate"
                                [placeholder]="('PleaseEnter' | translate) + ('BirthCertNo' | translate)"
                                [required]="isHkIdCard()"
                                [(value)]="customer.hkIdCard"></app-property-input>
            <!-- 生日 -->
            <app-property-datetime [title]="'DateOfBirth' | translate"
                                   [required]="isBirthday"
                                   (valueChange)="getAge($event)"
                                   [(value)]="customer.birthday"></app-property-datetime>
            <!-- 年龄 -->
            <app-property-text [title]="'Age' | translate"
                               [value]="birthdayDate"></app-property-text>

            <!-- 居住地址 -->
            <app-property-pick [title]="instance.isIAM() ? ('PleaseAddress' | translate ) : ('ResidentialAddress' | translate)"
                               [required]="isResidentialAddress()"
                               [tipVisible]="true" [tip]="'PleaseAddressCompletely' | translate"
                               appThrottleClick (throttleClick)="onAddress()"
                               [value]="customer.address?.toAddressString()"></app-property-pick>
            <!-- 戶籍地址 -->
            <app-property-pick *ngIf="!instance.isFoneOrDev()"
                               [title]="'LegalAddress' | translate"
                               [required]="isLegalAddress()"
                               appThrottleClick (throttleClick)="onResidenceAddress()"
                               [value]="customer.residenceAddress?.toAddressString()"></app-property-pick>
            <!-- 通訊地址 -->
            <app-property-pick [title]="'PostalAddress' | translate"
                               [required]="isPostalAddress()"
                               [tipVisible]="true" [tip]="'TheAddressInsuranceDocuments' | translate"
                               appThrottleClick (throttleClick)="onPostalAddress()"
                               [value]="customer.postalAddress?.toAddressString()"></app-property-pick>
            <!-- 移动号码 -->
            <app-property-pick [title]="'MobileNumber' | translate"
                               [required]="MobileNumber()"
                               [tipVisible]="true" [tip]="'PleaseContactNumber' | translate"
                               appThrottleClick (throttleClick)="onMobileNumber()"
                               [value]="customer.phoneString()"></app-property-pick>

            <!-- 邮箱 -->
            <app-property-input [title]="'Email' | translate"
                                [required]="isEmail()"
                                [placeholder]="('PleaseEnter' | translate) + ('Email' | translate)"
                                [(value)]="customer.email"></app-property-input>

            <!-- 职业信息 -->
            <app-property-select *ngIf="instance.isHarvest() && modeType === 'booking' && mode !== 'insured'"
                                 [title]="'JobInformation' | translate"
                                 [placeholder]="('PleaseSelect' | translate) + ('JobInformation' | translate)"
                                 [options]="jobOptions"
                                 [required]="true"
                                 [(value)]="customer.careerInformation"></app-property-select>

            <!-- 公司名称 -->
            <app-property-input  [title]="'CompanyName' | translate"
                                 [placeholder]="('PleaseEnter' | translate) + ('CompanyName' | translate)"
                                 [required]="isCompanyName()"
                                 [(value)]="customer.companyName"></app-property-input>

            <!-- 公司地址 -->
            <app-property-pick [title]="'CompanyAddress' | translate"
                               [required]="isCompanyName()"
                               appThrottleClick (throttleClick)="onCompanyAddress()"
                               [value]="customer.companyAddress?.toAddressString()"></app-property-pick>
            <!-- 行业 -->
            <app-property-input  [title]="'Occupation' | translate"
                                 [required]="isOccupation()"
                                 [placeholder]="('PleaseEnter' | translate) + ('Occupation' | translate)"
                                 [(value)]="customer.companyType"></app-property-input>
            <!-- 职位 -->
            <app-property-input [title]="'Position' | translate"
                                [required]="isOccupation()"
                                [placeholder]="('PleaseEnter' | translate) + ('Position' | translate)"
                                [(value)]="customer.position"></app-property-input>
            <!-- 風險偏好 -->
            <app-property-select *ngIf="!instance.isBetterOrDev()" [title]="'RiskAppetite' | translate"
                                 [placeholder]="('PleaseSelect' | translate) + ('RiskAppetite' | translate)"
                                 [options]="riskAppetiteOptions"
                                 [(value)]="customer.riskAppetite"></app-property-select>

            <!--VIP-->
            <app-property-select [title]="'VIP'"
                                 [placeholder]="('PleaseSelect' | translate) + 'VIP'"
                                 [options]="longtimeAbroadOptions"
                                 [(value)]="customer.vip.vip"></app-property-select>
            <!-- VIP备注 -->
            <app-property-input-area *ngIf="customer.vip.vip" [title]="'Remark' | translate"
                                     [placeholder]="('PleaseEnter' | translate) + ('Remark' | translate)"
                                     [(value)]="customer.vip.remark"></app-property-input-area>
          </div>

          <div style="height: 10px"></div>
        </div>
        <div *ngIf="!(instance.isWDF() && modeType === 'underwriting')" class="section-wrapper">
          <!-- 稱謂 -->
          <app-property-select [title]="'Title' | translate"
                               [placeholder]="('PleaseSelect' | translate) + ('Title' | translate)"
                               [options]="titleOptions"
                               [(value)]="customer.title"></app-property-select>
          <!-- 是否吸烟 -->
          <app-property-select [title]="'SmokeCondition' | translate"
                               [required]="isSmokeRequired()"
                               [placeholder]="('PleaseSelect' | translate) + ('SmokeCondition' | translate)"
                               [options]="smokeOptions"
                               [(value)]="customer.smoke"></app-property-select>

          <!-- 吸烟量 -->
          <app-property-input *ngIf="customer.smoke === 'S'" [title]="'SmokingVolume' | translate"
                              [placeholder]="('PleaseEnter' | translate) + ('SmokingVolume' | translate)"
                              [required]="instance.isWanson()"
                              nz-tooltip [nzTooltipTitle]="smokeQuantityTemplate"
                              nzTooltipPlacement="topRight"
                              [(value)]="customer.smokeQuantity"></app-property-input>
          <ng-template #smokeQuantityTemplate><div>{{'AD' | translate}}</div></ng-template>
          <!-- 出生地（省市） -->
          <app-property-input [title]="'Birthplace' | translate"
                              [placeholder]="('PleaseEnter' | translate) + ('Birthplace' | translate)"
                              [required]="isBirthplace()"
                              [(value)]="customer.birthplace"></app-property-input>
          <!-- 国籍（国家地区） -->
          <app-property-pick [displayMode]="'horizontal'"
                             [title]="isFone ? ('Nationality' | translate) : ('Country' | translate)"
                             [required]="isCountry()"
                             appThrottleClick (throttleClick)="onCountry()"
                             [value]="countryString"></app-property-pick>
<!--          <app-property-select [title]="isFone ? ('Nationality' | translate) : ('Country' | translate)"-->
<!--                               [placeholder]="('PleaseSelect' | translate) + (isFone ? ('Nationality' | translate) : ('Country' | translate))"-->
<!--                               [options]="regionOptions"-->
<!--                               [(value)]="customer.country"></app-property-select>-->
          <!-- 是否香港永居 -->
          <app-property-select *ngIf="instance.isBaylightOrDev() && modeType == 'booking' && mode === 'holder'"
                               [title]="'HaveHongKongIdentityCard' | translate"
                               [placeholder]="('PleaseSelect' | translate) + ('HaveHongKongIdentityCard' | translate)"
                               [options]="longtimeAbroadOptions"
                               [(value)]="customer.haveHKIdCard"></app-property-select>

          <!-- 是否香港永居 -->
          <app-property-select *ngIf="customer.haveHKIdCard"
                               [title]="'HongKongPermanentIdentity' | translate"
                               [placeholder]="('PleaseSelect' | translate) + ('HongKongPermanentIdentity' | translate)"
                               [options]="permanentResidentHKOptions"
                               [(value)]="customer.permanentResidentHK"></app-property-select>

          <!-- 护照号码 -->
          <app-property-input [title]="'PassportNo' | translate"
                              [placeholder]="('PleaseEnter' | translate) + ('PassportNo' | translate)"
                              [(value)]="customer.passport"></app-property-input>

          <!-- 通行证号码 -->
          <app-property-input [title]="'EepCode' | translate"
                              [placeholder]="('PleaseEnter' | translate) + ('EepCode' | translate)"
                              [required]="isEepCode()"
                              [(value)]="customer.eepCode"></app-property-input>
          <!-- 身高 -->
          <app-property-input-currency [title]="'Height' | translate"
                                       [placeholder]="('PleaseEnter' | translate) + ('Height' | translate)"
                                       [required]="isBirthplace()"
                                       [(value)]="customer.height"
                                       nz-tooltip [nzTooltipTitle]="contactHeight"
                                       nzTooltipPlacement="topRight"></app-property-input-currency>
          <ng-template #contactHeight><div>cm</div></ng-template>
          <!-- 体重 -->
          <app-property-input-currency [title]="'Weight' | translate"
                                       [placeholder]="('PleaseEnter' | translate) + ('Weight' | translate)"
                                       [required]="isBirthplace()"
                                       [(value)]="customer.weight"
                                       nz-tooltip [nzTooltipTitle]="contactWeight"
                                       nzTooltipPlacement="topRight"></app-property-input-currency>
          <ng-template #contactWeight><div>kg</div></ng-template>

          <!-- 平均每月支出 -->
          <app-property-input-number *ngIf="mode === 'holder'" [title]="'AverageMonthlyExpenses' | translate"
                                     [required]="isAverageMonthlyIncome()"
                                     [placeholder]="('PleaseEnter' | translate) + ('AverageMonthlyExpenses' | translate)"
                                     [(value)]="customer.averageMonthlyExpenses"></app-property-input-number>
          <!-- 平均每月收入 -->
          <app-property-input-number *ngIf="mode === 'holder'" [title]="'AverageMonthlyIncome' | translate"
                                     [required]="isAverageMonthlyIncome()"
                                     [placeholder]="('PleaseEnter' | translate) + ('AverageMonthlyIncome' | translate)"
                                     [(value)]="customer.averageMonthlyIncome"></app-property-input-number>

          <!-- 受雇于现职年期 -->
          <app-property-input-number [title]="'YearInCurrentJob' | translate"
                                     [placeholder]="('PleaseEnter' | translate) + ('YearInCurrentJob' | translate)"
                                     [(value)]="customer.yearInCurrentJob"></app-property-input-number>

          <!-- 总流动资产 -->
          <app-property-input-number *ngIf="mode === 'holder'" [title]="'TotalLiquidAssets' | translate"
                                     [required]="isAverageMonthlyIncome()"
                                     [placeholder]="('PleaseEnter' | translate) + ('TotalLiquidAssets' | translate)"
                                     [(value)]="customer.totalLiquidAssets"></app-property-input-number>

          <!-- 总债务 -->
          <app-property-input-number [title]="'TotalLiabilities' | translate"
                                     [required]="instance.isWanson()"
                                     [placeholder]="('PleaseEnter' | translate) + ('TotalLiabilities' | translate)"
                                     [(value)]="customer.totalLiabilities"></app-property-input-number>
          <!-- 受供养人数目 -->
          <app-property-input-number [title]="'NoOfDependents' | translate"
                                     [required]="isNoOfDependents()"
                                     [placeholder]="('PleaseEnter' | translate) + ('NoOfDependents' | translate)"
                                     [(value)]="customer.noOfDependents"></app-property-input-number>
          <!-- 婚姻状态 -->
          <app-property-select [title]="'Marriage' | translate"
                               [placeholder]="('PleaseSelect' | translate) + ('Marriage' | translate)"
                               [required]="isMarriage()"
                               [options]="marriageOptions"
                               [(value)]="customer.marriage"></app-property-select>
          <!-- 教育程度 -->
          <app-property-select [title]="'Education' | translate"
                               [placeholder]="('PleaseSelect' | translate) + ('Education' | translate)"
                               [required]="isEducation()"
                               [options]="educationOptions"
                               [(value)]="customer.education"></app-property-select>

          <!-- 总工作年期 -->
          <app-property-input-number [title]="'WorkYear' | translate"
                                     [placeholder]="('PleaseEnter' | translate) + ('WorkYear' | translate)"
                                     [(value)]="customer.workYear"></app-property-input-number>
          <!-- 月薪 -->
          <app-property-input-number [title]="'Salary' | translate"
                                     [required]="isSalary()"
                                     [placeholder]="('PleaseEnter' | translate) + ('Salary' | translate)"
                                     [(value)]="customer.salary"></app-property-input-number>

          <!-- 公司電話 -->
          <app-property-pick [title]="'OfficePhoneNumber' | translate"
                             [required]="isOfficePhoneNumber()"
                             appThrottleClick (throttleClick)="onOfficePhoneNumber()"
                             [value]="customer.officePhoneNumberString()"></app-property-pick>
          <!-- 固定電話 -->
          <app-property-pick [title]="'ResidentialPhoneNumber' | translate"
                             appThrottleClick (throttleClick)="onResidentialPhoneNumber()"
                             [value]="customer.residentialPhoneNumberString()"></app-property-pick>
          <!-- 其他電話 -->
          <app-property-pick [title]="'OtherPhones' | translate"
                             appThrottleClick (throttleClick)="onOtherPhones()"></app-property-pick>
          <!-- 郵箱 -->
<!--          <app-property-input [title]="'Email' | translate"-->
<!--                              [placeholder]="('PleaseEnter' | translate) + ('Email' | translate)"-->
<!--                              [(value)]="customer.email"></app-property-input>-->
          <!-- 過往一年是否所屬國家以外地區居住超過182日 -->
          <app-property-select [title]="'LongtimeAbroad' | translate"
                               [placeholder]="('PleaseSelect' | translate) + ('LongtimeAbroad' | translate)"
                               [options]="longtimeAbroadOptions"
                               [(value)]="customer.longtimeAbroad"></app-property-select>

          <!-- 其他国家身份 -->
          <app-property-select [title]="'OtherNationalID' | translate"
                               [required]="isOtherNationalID()"
                               [placeholder]="('PleaseSelect' | translate) + ('OtherNationalID' | translate)"
                               [options]="longtimeAbroadOptions"
                               [(value)]="customer.citizenship"></app-property-select>
          <!-- OptOutRequest -->
          <app-property-select *ngIf="!instance.isBetterOrDev()" [title]="'OptOutRequest' | translate"
                               [placeholder]="('PleaseSelect' | translate) + ('OptOutRequest' | translate)"
                               [options]="optOutRequestOptions"
                               [(value)]="customer.optOut"></app-property-select>

          <!-- 投保人邮编 -->
          <app-property-input *ngIf="modeType === 'booking' && mode === 'holder'" [title]="'PostCode' | translate"
                              [placeholder]="('PleaseEnter' | translate) + ('PostCode' | translate)"
                              [required]="isPostCode()"
                              [(value)]="customer.postcode"></app-property-input>
          <div style="height: 10px"></div>
        </div>
        <div *ngIf="!(instance.isFoneOrDev() || (instance.isWDF() && modeType === 'underwriting'))" class="section-wrapper">
          <!-- 备注 -->
          <app-property-input-area [title]="'Remark' | translate"
                                   [placeholder]="('PleaseEnter' | translate) + ('Remark' | translate)"
                                   [(value)]="customer.remark"></app-property-input-area>
          <div style="height: 10px"></div>
        </div>
        <div *ngIf="isBetterOrWDF" class="section-wrapper">
          <div
            *ngIf="!instance.isFone()"
            style="display: flex;justify-content: space-between;align-items: center;padding: 10px 0;border-bottom: 1px solid #E2EBF6;cursor: pointer;"
            (click)="showBtn = !showBtn"
          >
            <div>
              {{'auxiliary_information' | translate}}
            </div>
            <div style="color: #0078D4;">
              <i nz-icon [nzType]="showBtn ? 'up' : 'down'" nzTheme="outline"></i>
            </div>
          </div>
          <div *ngIf="!instance.isFone() ? showBtn : true">
            <!-- 旅行 -->
            <app-property-input *ngIf="!instance.isWanson()" [title]="'Travel' | translate"
                                [placeholder]="('PleaseEnter' | translate) + ('Travel' | translate)"
                                [(value)]="customer.travel"></app-property-input>
            <div *ngIf="!instance.isWanson()">
              <!-- 運動 -->
              <app-property-select [title]="'Sports' | translate"
                                   [placeholder]="('PleaseSelect' | translate) + ('Sports' | translate)"
                                   [mode]="'multiple'"
                                   [options]="sportsOptions"
                                   [(value)]="customer.sports"></app-property-select>
              <!-- 運動（其他） -->
              <app-property-input *ngIf="containsOther(customer.sports)" [title]="'SportsOther' | translate"
                                  [placeholder]="('PleaseEnter' | translate) + ('SportsOther' | translate)"
                                  [(value)]="customer.sportsOther"></app-property-input>
            </div>
            <!-- 游戏 -->
            <div *ngIf="!instance.isWanson()">
              <app-property-select [title]="'Games' | translate"
                                   [placeholder]="('PleaseSelect' | translate) + ('Games' | translate)"
                                   [mode]="'multiple'"
                                   [options]="gamesOptions"
                                   [(value)]="customer.games"></app-property-select>
              <!-- 游戏（其他） -->
              <app-property-input *ngIf="containsOther(customer.games)" [title]="'GamesOther' | translate"
                                  [placeholder]="('PleaseEnter' | translate) + ('GamesOther' | translate)"
                                  [(value)]="customer.gamesOther"></app-property-input>
            </div>
            <!-- 電影 -->
            <div *ngIf="!instance.isWanson()">
              <app-property-select [title]="'Movie' | translate"
                                   [placeholder]="('PleaseSelect' | translate) + ('Movie' | translate)"
                                   [mode]="'multiple'"
                                   [options]="movieOptions"
                                   [(value)]="customer.movie"></app-property-select>
              <!-- 電影（其他） -->
              <app-property-input *ngIf="containsOther(customer.movie)" [title]="'MovieOther' | translate"
                                  [placeholder]="('PleaseEnter' | translate) + ('MovieOther' | translate)"
                                  [(value)]="customer.movieOther"></app-property-input>
            </div>
            <!-- 美食 -->
            <div *ngIf="!instance.isWanson()">
              <app-property-select [title]="'Cuisine' | translate"
                                   [placeholder]="('PleaseSelect' | translate) + ('Cuisine' | translate)"
                                   [mode]="'multiple'"
                                   [options]="cuisineOptions"
                                   [(value)]="customer.cuisine"></app-property-select>
              <!-- 美食（其他） -->
              <app-property-input *ngIf="containsOther(customer.cuisine)" [title]="'CuisineOther' | translate"
                                  [placeholder]="('PleaseEnter' | translate) + ('CuisineOther' | translate)"
                                  [(value)]="customer.cuisineOther"></app-property-input>
            </div>
          </div>
          <div style="height: 10px"></div>
        </div>
      </div>
      <!-- 公司 -->
      <div *ngSwitchCase="'CORPORATE'">
        <div class="section-wrapper">
          <!-- 公司名称 -->
          <app-property-input [title]="'CorporateName' | translate"
                              [placeholder]="('PleaseEnter' | translate) + ('CorporateName' | translate)"
                              [required]="isCorporateName"
                              [(value)]="customerCorporate.companyName"></app-property-input>
          <!-- 公司名称英文 -->
          <app-property-input [title]="'CorporateNameEn' | translate"
                              [placeholder]="('PleaseEnter' | translate) + ('CorporateNameEn' | translate)"
                              [required]="true"
                              [(value)]="customerCorporate.companyNameEn"></app-property-input>
          <div *ngIf="!(instance.isWDF() && modeType === 'underwriting')">
            <!-- 公司商业登记号码 -->
            <app-property-input [title]="'BusinessRegistrationNumber' | translate"
                                [placeholder]="('PleaseEnter' | translate) + ('BusinessRegistrationNumber' | translate)"
                                [required]="true"
                                [(value)]="customerCorporate.businessRegistrationNumber"></app-property-input>
            <!-- 公司注册日期 -->
            <app-property-datetime [title]="'RegistrationDate' | translate"
                                   [required]="true"
                                   [(value)]="customerCorporate.registrationDate"></app-property-datetime>
            <!-- 公司电话 -->
            <app-property-pick [title]="'OfficePhoneNumber' | translate"
                               [required]="isCorporatePhone()"
                               appThrottleClick (throttleClick)="onCorporateOfficePhoneNumber()"
                               [value]="customerCorporate?.officePhoneNumberString()"></app-property-pick>
            <!-- 公司注册地区 -->
            <app-property-select [title]="'RegionOfIncorporation' | translate"
                                 [required]="true"
                                 [placeholder]="('PleaseSelect' | translate) + ('RegionOfIncorporation' | translate)"
                                 [options]="registrationCountryOptions"
                                 [(value)]="customerCorporate.registrationCountry"></app-property-select>
            <!-- 公司邮箱 -->
            <app-property-input [title]="'CompanyEmail' | translate"
                                [required]="isCompanyEmail()"
                                [placeholder]="('PleaseEnter' | translate) + ('CompanyEmail' | translate)"
                                [(value)]="customerCorporate.email"></app-property-input>
            <!-- 公司登记地址 -->
            <app-property-pick [title]="'RegisteredAddress' | translate"
                               appThrottleClick (throttleClick)="onCorporateResidentialAddress()"
                               [value]="customerCorporate.registeredAddress?.toAddressString()"></app-property-pick>
            <!-- 通讯地址 -->
            <app-property-pick [title]="'PostalAddress' | translate"
                               appThrottleClick (throttleClick)="onCorporateContactAddress()"
                               [value]="customerCorporate.contactAddress?.toAddressString()"></app-property-pick>
            <!-- 授权代表姓名中文-名字 -->
            <app-property-input [title]="'LegalPersonFirstName' | translate"
                                [placeholder]="('PleaseEnter' | translate) + ('LegalPersonFirstName' | translate)"
                                [(value)]="customerCorporate.legalPersonFirstName"></app-property-input>
            <!-- 授权代表姓名中文-姓氏 -->
            <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LegalPersonLastName' | translate"
                                [placeholder]="('PleaseEnter' | translate) + ('LegalPersonLastName' | translate)"
                                [(value)]="customerCorporate.legalPersonLastName"></app-property-input>
            <!-- 授权代表姓名中文-名字 -->
            <app-property-input [title]="'LegalPersonFirstNameEn' | translate"
                                [placeholder]="('PleaseEnter' | translate) + ('LegalPersonFirstNameEn' | translate)"
                                [(value)]="customerCorporate.legalPersonFirstNameEn"></app-property-input>
            <!-- 授权代表姓名中文-姓氏 -->
            <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LegalPersonLastNameEn' | translate"
                                [placeholder]="('PleaseEnter' | translate) + ('LegalPersonLastNameEn' | translate)"
                                [(value)]="customerCorporate.legalPersonLastNameEn"></app-property-input>
            <!-- 授权代表职称 -->
            <app-property-input [title]="'LegalPersonPosition' | translate"
                                [placeholder]="('PleaseEnter' | translate) + ('LegalPersonPosition' | translate)"
                                [(value)]="customerCorporate.legalPersonPosition"></app-property-input>
            <!-- 授权代表电话 -->
            <app-property-pick [title]="'LegalPersonPhone' | translate"
                               appThrottleClick (throttleClick)="onCorporateLegalPersonPhoneNumber()"
                               [value]="customerCorporate?.legalPersonPhoneNumberString()"></app-property-pick>
            <!-- 其他電話 -->
            <app-property-pick [title]="'OtherPhones' | translate"
                               appThrottleClick (throttleClick)="onOtherPhones()"></app-property-pick>

            <!-- OptOutRequest -->
            <app-property-select [title]="'OptOutRequest' | translate"
                                 [placeholder]="('PleaseSelect' | translate) + ('OptOutRequest' | translate)"
                                 [options]="optOutRequestOptions"
                                 [bottomLineVisible]="false"
                                 [(value)]="customerCorporate.optOut"></app-property-select>
          </div>

        </div>
        <div style="height: 10px"></div>
      </div>
    </div>
  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onReset()">
          <em nz-icon nzType="reload" nzTheme="outline"></em>
          <span>{{'Reset' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
