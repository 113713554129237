import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sale-report',
  templateUrl: './sale-report.component.html',
  styleUrls: ['./sale-report.component.less']
})
export class SaleReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
