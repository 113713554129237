import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PagedResp} from '../../../api/types';
import {
  ContactInfoRes,
  ContactListRes,
  ContactSearch,
  ContactsType,
  CreateContactsType
} from '../../../contact/contact-types';
import {ContactService} from '../../../contact/contact.service';
import {ContactInfoComponent} from '../../../contact/contact-info/contact-info.component';
import {plainToClass} from 'class-transformer';
import {debounceTime} from 'rxjs/operators';
import {Product} from '../../../product/product-types';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.less']
})
export class ContactsListComponent implements OnInit {
  search: ContactSearch = new ContactSearch();
  searchParamChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  detailSelected: EventEmitter<ContactInfoRes> = new EventEmitter<ContactInfoRes>();
  contactList: PagedResp<ContactListRes>;
  spinning = false;


  constructor(private contactService: ContactService) {

  }

  ngOnInit(): void {
    this.spinning = true;
    this.contactService.list(this.search)
      .subscribe(
        data => {
          this.spinning = false;
          this.contactList = data;
        },
        error => {
          this.spinning = false;
        }
      );
    this.searchParamChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          this.onSearch(1);
        }
      );
  }


  onDetail(item): void {
    console.log(item);
    this.spinning = true;

    this.contactService.detail(item).subscribe(data => {
        this.spinning = false;
        this.detailSelected.emit(data);
      },
      error => {
        this.spinning = false;
      });
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;

    this.spinning = true;
    this.contactService.list(this.search)
      .subscribe(
        data => {
          this.spinning = false;
          this.contactList = data;
        },
        error => {
          this.spinning = false;
        }
      );

  }

  onReload(): void {

  }

  onDelete(id: number): void {

  }

  onSearchParamChange(): void {
    this.searchParamChange.emit('');
  }
}
