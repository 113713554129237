<div>
  <div class="applied-title">
    <span>{{'BookingApplied' | translate}}</span>
  </div>
  <div class="applied-content">
    <span>{{'BookingAppliedTip' | translate}}</span>
  </div>

  <div>
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           (click)="onNotNowClick()">
          <span>{{'NotNow' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" (click)="onGoCompleteClick()">
          <span>{{'GoComplete' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
