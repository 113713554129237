<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{title | translate}}
  </div>

  <div class="section-wrapper" *ngFor="let beneficiary of beneficiaries; let i = index"
       style=" padding: 15px">
    <app-property-text [title]="'BeneficiaryType' | translate"
                       [value]="beneficiary.beneficiaryType | metadataTranslate:'ownerType'"></app-property-text>

    <app-property-text [title]="'RelationshipToInsured' | translate"
                       [value]="beneficiary.relation | metadataTranslate:'relationship'"></app-property-text>

    <app-property-text [title]="'Other' | translate"
                       [value]="beneficiary.otherRelationship"></app-property-text>
    <div *ngIf="beneficiary.relation != 'OWN'">
      <div [ngSwitch]="beneficiary.beneficiaryType">
        <div *ngSwitchCase="'INDIVIDUAL'">
          <app-property-text *ngIf="!workbench.hiddenLastName" [title]="'LastName' | translate"
                             [isEncryption]="dynamicEncryption('lastName')"
                             [value]="beneficiary.lastName "></app-property-text>
          <app-property-text [title]="'FirstName' | translate"
                             [isEncryption]="dynamicEncryption('firstName')"
                             [value]="beneficiary.firstName "></app-property-text>
          <app-property-text *ngIf="!workbench.hiddenLastName" [title]="'LastNameInEnglish' | translate"
                             [isEncryption]="dynamicEncryption('lastNamePinyin')"
                             [value]="beneficiary.lastNamePinyin "></app-property-text>
          <app-property-text [title]="'FirstNameInEnglish' | translate"
                             [isEncryption]="dynamicEncryption('firstNamePinyin')"
                             [value]="beneficiary.firstNamePinyin "></app-property-text>
          <div *ngIf="!instance.isWDF()">
            <app-property-text [title]="'Gender' | translate"
                               [isEncryption]="dynamicEncryption('gender')"
                               [value]="beneficiary.gender | metadataTranslate:'gender'"></app-property-text>
            <app-property-text [title]="'IdentityCardNumber' | translate"
                               [isEncryption]="dynamicEncryption('idCard')"
                               [value]="beneficiary.idCard"></app-property-text>
            <app-property-text [title]="'Passport' | translate"
                               [isEncryption]="dynamicEncryption('passport')"
                               [value]="beneficiary.passport "></app-property-text>
            <app-property-text [title]="'Birthday' | translate"
                               [isEncryption]="dynamicEncryption('birthday')"
                               [value]="beneficiary.birthdate"></app-property-text>
            <app-property-text [title]="'PercentageOfBeneficiary' | translate"
                               [isEncryption]="dynamicEncryption('percentage')"
                               [value]="(beneficiary.percentage ? beneficiary.percentage + '%' : beneficiary.percentage)"></app-property-text>
          </div>
        </div>
        <div *ngSwitchCase="'CORPORATE'">
          <!-- 公司名称 -->
          <app-property-text [title]="'CompanyName' | translate"
                             [isEncryption]="dynamicEncryption('companyName')"
                             [value]="beneficiary.companyName "></app-property-text>
          <!-- 公司名称英文 -->
          <app-property-text [title]="'CompanyNameEn' | translate"
                             [isEncryption]="dynamicEncryption('companyNameEn')"
                             [value]="beneficiary.companyNameEn "></app-property-text>
          <div *ngIf="!instance.isWDF()">
            <!-- 公司商业登记号码 -->
            <app-property-text [title]="'BusinessRegistrationNumber' | translate"
                               [isEncryption]="dynamicEncryption('businessRegistrationNumber')"
                               [value]="beneficiary.businessRegistrationNumber "></app-property-text>
            <!-- 公司注册日期 -->

            <app-property-text [title]="'RegistrationDate' | translate"
                               [isEncryption]="dynamicEncryption('registrationDate')"
                               [value]="beneficiary.registrationDate | date: 'yyyy-MM-dd'"></app-property-text>
            <!-- 公司注册地区 -->
            <app-property-text [title]="'RegionOfIncorporation' | translate"
                               [isEncryption]="dynamicEncryption('registrationCountry')"
                               [value]="beneficiary.registrationCountry | metadataTranslate:'nationality'"></app-property-text>
            <!-- 公司电话 -->
            <app-property-text [title]="'OfficePhoneNumber' | translate"
                               [isEncryption]="dynamicEncryption('telephone')"
                               [value]="beneficiary?.officePhoneNumberString()"></app-property-text>
            <!-- 公司邮箱 -->
            <app-property-text [title]="'CompanyEmail' | translate"
                               [isEncryption]="dynamicEncryption('email')"
                               [value]="beneficiary.email "></app-property-text>
            <!-- 公司登记地址 -->
            <app-property-text [title]="'RegisteredAddress' | translate"
                               [isEncryption]="dynamicEncryption('location')"
                               [value]="beneficiary.registeredAddress?.toAddressString() "></app-property-text>
            <!-- 通讯地址 -->
            <app-property-text [title]="'PostalAddress' | translate"
                               [isEncryption]="dynamicEncryption('location')"
                               [value]="beneficiary.contactAddress?.toAddressString()"></app-property-text>
            <!-- 授权代表姓名中文-名字 -->
            <app-property-text [title]="'LegalPersonFirstName' | translate"
                               [isEncryption]="dynamicEncryption('legalPersonFirstName')"
                               [value]="beneficiary.legalPersonFirstName"></app-property-text>
            <!-- 授权代表姓名中文-姓氏 -->
            <app-property-text *ngIf="!workbench.hiddenLastName" [title]="'LegalPersonLastName' | translate"
                               [isEncryption]="dynamicEncryption('legalPersonLastName')"
                               [value]="beneficiary.legalPersonLastName"></app-property-text>
            <!-- 授权代表姓名中文-名字 -->
            <app-property-text [title]="'LegalPersonFirstNameEn' | translate"
                               [isEncryption]="dynamicEncryption('legalPersonFirstNameEn')"
                               [value]="beneficiary.legalPersonFirstNameEn"></app-property-text>
            <!-- 授权代表姓名中文-姓氏 -->
            <app-property-text *ngIf="!workbench.hiddenLastName" [title]="'LegalPersonLastNameEn' | translate"
                               [isEncryption]="dynamicEncryption('legalPersonLastNameEn')"
                               [value]="beneficiary.legalPersonLastNameEn"></app-property-text>
            <!-- 授权代表职称 -->
            <app-property-text [title]="'LegalPersonPosition' | translate"
                               [isEncryption]="dynamicEncryption('legalPersonPosition')"
                               [value]="beneficiary.legalPersonPosition"></app-property-text>
            <!-- 授权代表电话 -->
            <app-property-text [title]="'LegalPersonPhone' | translate"
                               [isEncryption]="dynamicEncryption('legalPersonPhone')"
                               [value]="beneficiary?.legalPersonPhoneNumberString()"></app-property-text>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!instance.isWDF()">
      <app-property-text *ngIf="beneficiary.relation == 'OWN'"
                         [title]="'PercentageOfBeneficiary' | translate"
                         [value]="'100%'"></app-property-text>

      <app-property-text [title]="'Remark' | translate"
                         [textType]="'html'"
                         [value]="beneficiary.remark"></app-property-text>
    </div>

  </div>


  <div *ngIf="moduleSource" class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onEdit()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Edit' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>



