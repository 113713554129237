import {Component, OnInit} from '@angular/core';
import {ContactPerson, PactDetails, PactSearch} from '../sales-pact-types';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {PrimaryContactInfoComponent} from '../components/primary-contact-info/primary-contact-info.component';
import {plainToInstance} from 'class-transformer';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {SalesPactService} from '../sales-pact.service';

@Component({
  selector: 'app-sales-primary-contact',
  templateUrl: './sales-primary-contact.component.html',
  styleUrls: ['./sales-primary-contact.component.less']
})
export class SalesPrimaryContactComponent implements OnInit {

  listData: PactDetails = new PactDetails();
  loading = false;
  id: number; // 合同id
  infoIndex: number; // 下标 主要联系人
  isVisible = false;

  constructor(private drawerService: NzDrawerService,
              private salesPactService: SalesPactService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  onRefresh(): void{
    const param = new PactSearch();
    param.id = this.id.toString();
    this.loading = true;
    this.salesPactService.info(param)
      .subscribe(
        data => {
          this.listData = data;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      );
  }

  onPrimary(val, i): void {
    const drawerRef = this.drawerService.create<PrimaryContactInfoComponent, { value: any }, string>({
      nzWidth: 600,
      nzContent: PrimaryContactInfoComponent,
      nzMask: true,
      nzMaskClosable: false,
      nzContentParams: {
        signatureStatus: this.listData.signedStatus,
        data: plainToInstance(ContactPerson, val),
        id: this.id,
        infoIndex: i,
        infoData: this.listData,
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const primaryContactInfoComponent = drawerRef.getContentComponent();
      primaryContactInfoComponent.afterRecord
        .subscribe(
          data => {
            drawerRef.close();
            this.onRefresh();
          }
        );
    });
  }

  onDelete(index): void {
    if (this.listData.signedStatus === 'SIGNED') {
      this.translate.get('PactDeleteTips').subscribe(
        (res) => {
          this.toastr.info(res);
        }
      );
    } else {
      this.infoIndex = index;
      this.isVisible = true;
    }
  }

  onCreate(): void {
    const drawerRef = this.drawerService.create<PrimaryContactInfoComponent, { value: any }, string>({
      nzWidth: 600,
      nzContent: PrimaryContactInfoComponent,
      nzMask: true,
      nzMaskClosable: false,
      nzContentParams: {
        modifyState: 'Create',
        id: this.id,
        infoData: this.listData,
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const primaryContactInfoComponent = drawerRef.getContentComponent();
      primaryContactInfoComponent.afterRecord
        .subscribe(
          data => {
            drawerRef.close();
            this.onRefresh();
          }
        );
    });
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleOk(): void {
    this.loading = true;
    this.listData.contactPerson.splice(this.infoIndex, 1);
    const param = {
      id: this.id,
      contactPerson: this.listData.contactPerson
    };
    this.salesPactService.updateInfo(param)
      .subscribe(
        data => {
          if (data) {
            this.translate.get('DeleteSuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          }
          this.isVisible = false;
          this.loading = false;
        },
        error => {
          this.isVisible = false;
          this.loading = false;
        }
      );
  }


}
