<div class="drawer-container">

  <div class="common-title" style="margin-bottom: 20px">
<!--    {{'Referee' | translate}}-->
    {{title}}
  </div>
  <div style="margin-bottom: 20px">
<!--    <app-property-input [displayMode]="'vertical'"-->
<!--                        [title]="'Search' | translate"-->
<!--                        [placeholder]="'PleaseEnter' | translate"-->
<!--                        [(value)]="search"></app-property-input>-->
    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
      <input type="text" nz-input [(ngModel)]="search"
             [placeholder]="'PleaseEnter' | translate"
             (keyup.enter)="onSearch()"/>
    </nz-input-group>
    <ng-template #suffixIconButton>
      <button nz-button nzType="primary" appThrottleClick
              (throttleClick)="onSearch()" nzSearch><i nz-icon nzType="search"></i></button>
    </ng-template>
<!--    <nz-cascader-->
<!--      nzChangeOnSelect-->
<!--      [nzOptionRender]="renderTpl"-->
<!--      [nzOptions]="nzOptions"-->
<!--      [(ngModel)]="accountName"-->
<!--      [nzLabelProperty]="'name'"-->
<!--      [nzValueProperty]="'accountName'"-->
<!--      [nzShowSearch]="NzShowSearchOptions"-->
<!--      (ngModelChange)="onChanges($event)"-->
<!--      style="width: 100%;border: none"-->
<!--    ></nz-cascader>-->
<!--    <ng-template #renderTpl let-option let-index="index">{{ option.accountName }}-{{ option.name }}</ng-template>-->
  </div>
  <div>
    <div *ngFor="let info of returnData" class="country-item" appThrottleClick
         (throttleClick)="onSave(info)">
      <div>{{info.name}}</div>
    </div>
  </div>

<!--  <div class="footer">-->
<!--    <div nz-row style="width: 100%; height: 100%;">-->
<!--      <div nz-col nzSpan="12" class="footer-button">-->
<!--        <a nz-button nzType="link" nzBlock nzSize="large"-->
<!--           appThrottleClick (throttleClick)="onReset()">-->
<!--          <em nz-icon nzType="reload" nzTheme="outline"></em>-->
<!--          <span>{{'Reset' | translate}}</span>-->
<!--        </a>-->
<!--      </div>-->
<!--      <div nz-col nzSpan="24" class="footer-button-primary">-->
<!--        <a nz-button nzType="link" nzBlock nzSize="large"-->
<!--           appThrottleClick (throttleClick)="onSave(null)">-->
<!--          <em nz-icon nzType="check" nzTheme="outline"></em>-->
<!--          <span>{{'Save' | translate}}</span>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

</div>
