import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { BaseComponent } from '../../../base/base.component';
import { PolicyService } from '../../policy.service';
import { Policy, PolicyPending, PolicyPendingSearch, PolicyPendingStatus } from '../../policy-types';
import { PolicyPendingDetailComponent } from '../policy-pending-detail/policy-pending-detail.component';
import { DataMode, Instance, MessageSubType, MessageType } from '../../../api/types';
import { MessageService } from '../../../message/message.service';

@Component({
  selector: 'app-policy-pending',
  templateUrl: './policy-pending.component.html',
  styleUrls: ['./policy-pending.component.less']
})
export class PolicyPendingComponent extends BaseComponent implements OnInit {

  loading = false;

  @Input()
  policy: Policy;
  @Input()
  mode = DataMode.OWN;
  search = new PolicyPendingSearch();

  pendings: PolicyPending[];

  statusList: PolicyPendingStatus[];

  statusOptions: PropertySelectOption[] = [];

  filterPopoverVisible = false;
  @Output()
  putSendMessage: EventEmitter<any> = new EventEmitter<any>();
  instance: Instance = new Instance();
  constructor(private drawerService: NzDrawerService,
              private policyService: PolicyService,
              private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    this.search.policyId = this.policy.policyId;
    this.loadPendingStatusList();
    this.loadPendingList();
  }

  loadPendingStatusList(): void {
    this.policyService.pendingStatusList()
      .subscribe(
        list => {
          this.statusList = list;
          for (const status of this.statusList) {
            this.statusOptions.push(new PropertySelectOption(status.status, status.status));
          }
        },
        error => {
        }
      )
      .disposedBy(this.disposeBag);
  }

  loadPendingList(): void {
    this.loading = true;
    this.policyService.pendingList(this.search)
      .subscribe(
        list => {
          this.loading = false;
          this.pendings = list;
        },
        error => {
          this.loading = false;
        }
      )
      .disposedBy(this.disposeBag);
  }

  onPendingDetail(pending: PolicyPending): void {
    this.sendMessage(pending.id);
    this.loading = true;
    this.policyService.pendingDetail(pending.id)
      .subscribe(pendingDetail => {
          this.loading = false;
          const drawerRef = this.drawerService.create<PolicyPendingDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: PolicyPendingDetailComponent,
            nzContentParams: {
              pendingDetail,
              mode: this.mode,
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.pendingChanged.subscribe(data => {
              this.loadPendingList();
            });
          });

          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();
            this.putSendMessage.emit();
          });
        },
        error => {
          this.loading = false;
        });
  }

  onResetSearch(): void {
    this.search = new PolicyPendingSearch();
    this.search.policyId = this.policy.policyId;
    this.filterPopoverVisible = false;
    this.loadPendingList();
  }

  onSearch(): void {
    this.filterPopoverVisible = false;
    this.loadPendingList();
  }

  sendMessage(num): void{
    const params = {
      key: this.policy.policyId,
      subKey: num,
      type: MessageType.POLICY,
      subType: MessageSubType.SERVICE_REPLY,
    };
    this.messageService.putMessageReads(params).subscribe();
  }


}
