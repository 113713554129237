import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy-reminder',
  templateUrl: './policy-reminder.component.html',
  styleUrls: ['./policy-reminder.component.less']
})
export class PolicyReminderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
