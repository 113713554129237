<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div style="width: 100%; display: flex; justify-content: flex-end;">
      <button nz-button nzType="default" nzSize="large" nzShape="circle" style=" margin-left: 10px;"
              nz-popover [nzPopoverContent]="filterTemplate"
              [(nzPopoverVisible)]="filterPopoverVisible"
              nzPopoverTrigger="click"
              nzPopoverPlacement="bottomRight">
        <img alt="" width="22" src="assets/images/ic_filter.svg">
      </button>
    </div>

    <div *ngIf="pendings && pendings.length > 0">
      <div *ngFor="let pending of pendings; let i = index" nzColor="gray">
        <div>
          <div class="pending-item" appThrottleClick (throttleClick)="onPendingDetail(pending); $event.stopPropagation();">
            <div class="pending-item-header">
              <div [style]="pending.finish ? 'color: #1d64c4' : 'color:#e63757'"> {{pending.status}}</div>
              <p>
                #{{ pending.pendingNo }}
              </p>
            </div>

            <div class="property-bottom-line-blue"></div>

            <div class="pending-item-content"> {{pending.project}}</div>
            <div *ngIf="pending.remark" style="flex: 1; margin-right: 4px">{{instance.isFone() ? ('TodoContent' | translate) : ('PendingContent' | translate)}}: <span [innerHTML]="pending.remark"></span></div>
            <div class="pending-item-footer flex-row" style="color:#999999;font-size: 14px">
              <div style="flex: 1; margin-right: 4px">{{instance.isFone() ? ('DeliveryDate' | translate) : ('PendingDate' | translate)}} {{ pending.applyTime | date:'yyyy-MM-dd HH:mm:ss'}}</div>
              <div class="flex-row">
                <img alt="" style="color: #999999; margin-right:2px" width="14" src="assets/images/ic_leave_message.svg">
                <span>{{ pending.messageCount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-spin>


  <ng-template #filterTemplate>
    <div style="width: 400px; padding: 5px;">
      <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
      <div class="property-bottom-line-blue"></div>

      <!-- 编号 -->
      <app-property-input [title]="'PendingNumber' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('PendingNumber' | translate)"
                          [(value)]="search.pendingNo"></app-property-input>

      <!-- 编号 -->
      <app-property-input [title]="instance.isFone() ? ('ChangeProject' | translate) : 'Pending'"
                          [placeholder]="('PleaseEnter' | translate) + (instance.isFone() ? ('ChangeProject' | translate) : 'Pending')"
                          [(value)]="search.project"></app-property-input>

      <!-- 類型 -->
      <app-property-select [title]="'Status' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Status' | translate)"
                           [options]="statusOptions"
                           [(value)]="search.status"></app-property-select>

      <!-- 送件日期 -->
      <app-property-datetime [title]="instance.isFone() ? ('DeliveryDate' | translate) : ('PendingDate' | translate)"
                             [tip]="('PleaseSelect' | translate) + (instance.isFone() ? ('DeliveryDate' | translate) : ('PendingDate' | translate))"
                             [(value)]="search.deliveryDate"></app-property-datetime>

      <!-- 截止日期 -->
      <app-property-datetime [title]="'ExpirationDate' | translate"
                             [tip]="('PleaseSelect' | translate) + ('ExpirationDate' | translate)"
                             [(value)]="search.deadlinesDate"></app-property-datetime>

      <nz-row [nzGutter]="5">
        <nz-col nzSpan="12">
          <button nz-button nzType="default" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onResetSearch()">
            <span>{{'Reset' | translate}}</span>
          </button>
        </nz-col>
        <nz-col nzSpan="12">
          <button nz-button nzType="primary" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick (throttleClick)="onSearch()">
            <span>{{'Confirm' | translate}}</span>
          </button>
        </nz-col>
      </nz-row>
    </div>
  </ng-template>

</div>
