import { Component, Input, OnInit, Output } from '@angular/core';
import { KnowledgeService } from '../knowledge.service';
import { PagedResp } from '../../api/types';
import { KnowledgeListResp, KnowledgeSearch, KnowledgeType } from '../knowledge-types';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { KnowledgeDetailComponent } from '../knowledge-detail/knowledge-detail.component';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { ProductService } from '../../product/product.service';
import { ProductCompany } from '../../product/product-types';

@Component({
  selector: 'app-knowledge-list',
  templateUrl: './knowledge-list.component.html',
  styleUrls: ['./knowledge-list.component.less']
})
export class KnowledgeListComponent implements OnInit {

  loading = false;

  spinning = false;
  @Input()
  mode = 'GeneralKnowledge';

  knowledges: KnowledgeListResp[];

  search: KnowledgeSearch = new KnowledgeSearch();

  filterPopoverVisible = false;

  typeOptions: PropertySelectOption[] = [];
  companyOptions: PropertySelectOption[] = [];

  typeNameOptions: KnowledgeType[];
  companies: ProductCompany[];

  constructor(private knowledgeService: KnowledgeService,
              private drawerService: NzDrawerService,
              private productService: ProductService) {
  }

  ngOnInit(): void {

    this.knowledgeService.types()
      .subscribe(
        types => {
          this.typeNameOptions = types;
          for (const type of types) {
            this.typeOptions.push(new PropertySelectOption(type.name, type.id));
          }
        },
        error => {
        }
      );

    this.productService.companies()
      .subscribe(
        data => {
          this.companies = data;
          data.forEach(company => {
            this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
          });
        },
        error => {
        });

    this.load();
  }

  load(): void {
    this.loading = true;
    this.search.pageSize = 999999999;
    this.knowledgeService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          let res = data.list;
          if (this.mode === 'GeneralKnowledge' && data.list) {
            res = res.filter(item => item.typeId);
            this.knowledges = res;
          } else {
            res = res.filter(item => item.companyCode);
            this.knowledges = res;
          }
        },
        error => {
          this.loading = false;
        });
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.load();
  }

  onReload(resetPageNum = true): void {
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.load();
  }

  onResetSearch(): void {
    this.search = new KnowledgeSearch();
    this.onReload();
  }

  onDetail(id: number): void {
    this.loading = true;
    this.knowledgeService.detail(id)
      .subscribe(
        knowledge => {
          this.loading = false;
          const drawerRef = this.drawerService.create<KnowledgeDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: KnowledgeDetailComponent,
            nzContentParams: {
              knowledge
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();
          });
        },
        error => {
          this.loading = false;
        }
      );
  }

  typeName(typeId): string {
    const res = this.typeNameOptions.find(item => item.id === typeId) || null;
    return res?.name || 'N/A';
  }

  companyName(companyCode): string {
    const res = this.companies.find(item => item.code === companyCode) || null;
    return res?.name || 'N/A';
  }
}
