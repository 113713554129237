import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../base/base.component';
import { Withdrawal, WithdrawalRestriction, WithdrawalRestrictionItem, WithdrawalRestrictionType } from '../../proposal-types';

@Component({
  selector: 'app-proposal-withdrawal-edit',
  templateUrl: './proposal-withdrawal-edit.component.html',
  styleUrls: ['./proposal-withdrawal-edit.component.less']
})
export class ProposalWithdrawalEditComponent extends BaseComponent implements OnInit {

  readonly maxTo: number = 138;
  readonly minFrom: number = 1;

  @Input()
  maxWithdrawal = false;

  @Input()
  descriptions: string[];
  @Input()
  withdrawals: Withdrawal[] = []; // 提取计划
  @Input()
  editInfo: any; // 提取计划
  @Input()
  withdrawalIndex: any;

  withdrawalRestriction: WithdrawalRestriction = new WithdrawalRestriction(); // 现金提取限制 - 當code、premiumTerm、type參數都傳時，才會有數據（如果有錄入數據）

  @Input()
  age: number;

  @Input() withdrawal = new Withdrawal();

  fromOptions: PropertySelectOption[] = [];
  toOptions: PropertySelectOption[] = [];

  tip: string;

  @Output()
  withdrawalConfirmed: EventEmitter<Withdrawal> = new EventEmitter<Withdrawal>();

  constructor(private modalRef: NzModalRef,
              private translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
    // this.withdrawal.from = this.from;
    // this.withdrawal.to = this.from;
    this.setupOptions().then();
  }

  async setupOptions(): Promise<void> {
    const res: any = await this.translate.get(['Year', 'YearsOld', 'Di']).toPromise();
    this.fromOptions = [];
    this.toOptions = [];
    for (let i = this.from; i <= this.to; i++) {
      this.fromOptions.push(new PropertySelectOption(`${res.Di}${i}${res.Year}(${i + this.add(this.age)}${res.YearsOld})`, i));
      this.toOptions.push(new PropertySelectOption(`${res.Di}${i}${res.Year}(${i + this.add(this.age)}${res.YearsOld})`, i));
    }
    if (this.withdrawals && this.withdrawals.length && !this.editInfo) {
      console.log(this.withdrawals[this.withdrawals.length - 1], '145145151451');
      const too = this.withdrawals[this.withdrawals.length - 1].to;
      const index = this.toOptions.findIndex((item) => {
        return item.value === too;
      });
      if (index > -1) {
        this.fromOptions.splice(0, index + 1);
        this.toOptions.splice(0, index + 1);
      }
    }

    if (this.withdrawals && this.withdrawals.length && this.editInfo) {
      let start = 0;
      let end = this.fromOptions.length;

      if (this.withdrawalIndex > 0) {
        console.log(this.withdrawals[this.withdrawalIndex - 1].to);
        if (this.withdrawals[this.withdrawalIndex - 1].to) {
          start = this.withdrawals[this.withdrawalIndex - 1].to;
        }
      }
      if (this.withdrawals.length - 1 > this.withdrawalIndex) {
        if (this.withdrawals[this.withdrawalIndex + 1].from) {
          end = this.withdrawals[this.withdrawalIndex + 1].from - 1;
        }
      }
      console.log(start, end);
      this.fromOptions = this.fromOptions.slice(start, end);
      this.toOptions = this.toOptions.slice(start, end);
    }
  }

  get canConfirm(): boolean {
    if (!this.withdrawal) {
      return false;
    }
    if (!this.withdrawal.from) {
      return false;
    }
    if (!this.withdrawal.to) {
      return false;
    }
    if (this.withdrawal.to < this.withdrawal.from) {
      return false;
    }
    if (!this.withdrawal.max && !this.withdrawal.amount) {
      return false;
    }
    return true;
  }

  onFromChange($event: any): void {
    const index = this.toOptions.findIndex((item) => {
      return item.value === this.withdrawal.from;
    });
    if (index > -1) {
      this.toOptions.splice(0, index);
    }
    this.withdrawal.to = undefined;
    // if (this.withdrawal.to < this.withdrawal.from) {
    //   this.tip = 'StartAgeCanNotBeGreaterThanEndAge';
    // } else {
    //   this.tip = null;
    // }
  }

  onToChange($event: any): void {
    if (this.withdrawal.to < this.withdrawal.from) {
      this.tip = 'StartAgeCanNotBeGreaterThanEndAge';
    } else {
      this.tip = null;
    }
  }

  onCancel(): void {
    this.modalRef?.destroy();
  }

  onConfirm(): void {
    if (!this.canConfirm) {
      return;
    }
    this.withdrawalConfirmed.emit(this.withdrawal);
    this.modalRef?.destroy();
  }

  get from(): number {
    if (this.withdrawalRestriction) {
      const from = this.withdrawalRestriction.from;
      if (from) {
        switch (from.type) {
          case WithdrawalRestrictionType.AGE:
            return Math.max(from.value - this.age, 1);
          case WithdrawalRestrictionType.YEAR:
            return from.value;
        }
      }
    }
    return this.minFrom; // 返回默认
  }

  get to(): number {
    if (this.withdrawalRestriction) {
      const to = this.withdrawalRestriction.to;
      if (to) {
        switch (to.type) {
          case WithdrawalRestrictionType.AGE:
            return to.value - this.age;
          case WithdrawalRestrictionType.YEAR:
            return to.value;
        }
      }
    }
    return this.maxTo - this.age; // 返回默认
  }

  add(num: number): number {
    if (!this.withdrawalRestriction?.calculation) {
      return num;
    }
    switch (this.withdrawalRestriction.calculation) {
      case '+':
        num += this.withdrawalRestriction.calculationValue;
        break;
      case '-':
        num -= this.withdrawalRestriction.calculationValue;
        break;
    }
    return num;
  }
}
