import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BookingService } from '../../booking.service';
import { BookingConcurrentlyGroupListResp, BookingDraftResp, BookingSearch } from '../../booking-types';
import { PagedResp } from '../../../api/types';
import { BaseComponent } from '../../../base/base.component';

@Component({
  selector: 'app-booking-group-selection',
  templateUrl: './booking-group-selection.component.html',
  styleUrls: ['./booking-group-selection.component.less']
})
export class BookingGroupSelectionComponent extends BaseComponent implements OnInit {

  groupNo: string;

  search: BookingSearch = new BookingSearch();

  bookings: PagedResp<BookingConcurrentlyGroupListResp>;

  loading = false;

  setOfCheckedId = new Set<string>();

  selectedGroupNo: string = null;

  @Output()
  cancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  confirm: EventEmitter<string> = new EventEmitter<string>();

  constructor(private bookingService: BookingService) {
    super();
  }

  ngOnInit(): void {
    this.bookingService.groupListConcurrently(this.search)
      .subscribe(
        data => {
          this.bookings = data;
        },
        () => {
        }
      );
  }

  load(): void {
    this.loading = true;
    this.bookingService.groupListConcurrently(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.bookings = data;
        },
        () => {
          this.loading = false;
        }
      ).disposedBy(this.disposeBag);
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.load();
  }

  onBookingGroupSelected(groupNo: string): void {
    this.setOfCheckedId.clear();
    this.selectedGroupNo = groupNo;
    if (groupNo) {
      this.setOfCheckedId.add(groupNo);
    }
  }

  onCancel(): void {
    this.cancel.emit();
  }

  onConfirm(): void {
    this.confirm.emit(this.selectedGroupNo);
  }
}
