<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
    <div class="common-title" style="margin-bottom: 30px">
      {{'SelectProposal' | translate}}
    </div>
  </nz-spin>
  <div *ngIf="!proposals">
    <nz-empty nzNotFoundImage="simple"></nz-empty>
  </div>
  <div *ngIf="proposals">
    <nz-table [nzBordered]="false"
              [nzOuterBordered]="true"
              [nzShowPagination]="true"
              [nzLoading]="loading"
              [nzLoadingIndicator]="indicatorTemplate"
              [nzTitle]="tableHeader"
              [nzPageIndex]="proposals.pageNum"
              [nzPageSize]="proposals.pageSize"
              [nzTotal]="proposals.total"
              [nzFooter]="tableFooter"
              [nzFrontPagination]="false"
              (nzPageIndexChange)="onSearch($event)"
              [nzData]="proposals.list"
              [nzScroll]="{ x: '1000px'}">
      <ng-template #tableHeader>
        <nz-row>
          <nz-col [nzSpan]="12">
            <div class="list-header-title">{{'ProposalList' | translate}}
              <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                 style="margin-left: 5px"
                 appThrottleClick (throttleClick)="onSearch(1)">
                <em nz-icon style="color: black;font-size:14px" nzType="reload" nzTheme="outline"></em>
              </a>
            </div>
          </nz-col>
        </nz-row>
      </ng-template>
      <ng-template #tableFooter>
        <div class="table-title">
          {{'TotalRows' | translate}} : <span
          style="color: #002140; font-weight: bold;">{{ proposals.total }}</span>
        </div>
      </ng-template>
      <thead>
      <tr>
        <th nzLeft nzWidth="160px">{{'ProposalID' | translate}}</th>
        <th>{{'ProposalName' | translate}}</th>
        <th nzWidth="100px">{{'PaymentTerm' | translate}}</th>
        <th nzWidth="90px">{{'Gender' | translate}}</th>
        <th nzWidth="60px">{{'Age' | translate}}</th>
        <th nzWidth="100px">{{'Smoker' | translate}}</th>
        <th nzWidth="100px">{{'Withdrawal' | translate}}</th>
        <th nzWidth="140px">{{'Premium' | translate}}(USD)</th>
        <!--          <th>{{'CreateTime' | translate}}</th>-->
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let proposal of proposals.list" (click)="onProposal(proposal)">
        <td nzLeft>{{proposal.proposalId}}</td>
        <td><img width="20" style="border-radius: 3px; margin-right: 5px" [src]="proposal.companyLogo"
        >{{proposal.productName}}</td>
        <td>{{proposal.paymentTerm}}</td>
        <td>{{proposal.gender | metadataTranslate:'gender'}}</td>
        <td>{{proposal.age}}</td>
        <td>{{proposal.smoke | metadataTranslate:'smoke'}}</td>
        <td>
          {{proposal.isWithdrawal ? ('Yes' | translate) : ('No' | translate)}}</td>
        <td>{{proposal.premium | number }}</td>
        <!--          <td>{{data.applicationTime | date:'yyyy-MM-dd'}}</td>-->
      </tr>
      </tbody>
    </nz-table>
  </div>
</div>
