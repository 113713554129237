import { Pipe, PipeTransform } from '@angular/core';
import { Locale } from '../../api/types';

@Pipe({
  name: 'locale'
})
export class LocalePipe implements PipeTransform {

  transform(value: Locale, ...args: any[]): string {
    let locale = '';
    switch (value) {
      case Locale.cn:
        locale = 'CN';
        break;
      case Locale.hk:
        locale = 'HK';
        break;
      case Locale.en:
        locale = 'EN';
        break;
      case Locale.jp:
        locale = 'JP';
        break;
      case Locale.ko:
        locale = 'KO';
        break;
    }
    return locale;
  }
}
