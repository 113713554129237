import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewFnaTemplateComponent } from './new-fna-template.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { BasicInfoComponent } from './basic-info/basic-info.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { FormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { FilterPopoverComponent } from './filter-popover/filter-popover.component';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { FnaFormTemplateComponent } from './fna-form-template/fna-form-template.component';
import { NewFnaModule } from '../new-fna/new-fna.module';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FnaTemplateQuestionComponent } from './fna-template-question/fna-template-question.component';
import { FnaTemplateIntroductionComponent } from './fna-template-introduction/fna-template-introduction.component';
import { FnaTemplateSignatureComponent } from './fna-template-signature/fna-template-signature.component';
import { FnaFormSelectedComponent } from './fna-form-selected/fna-form-selected.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FnaTemplateOverviewComponent } from './fna-template-overview/fna-template-overview.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTabsModule } from 'ng-zorro-antd/tabs';


@NgModule({
  declarations: [
    BasicInfoComponent,
    FilterPopoverComponent,
    FnaFormTemplateComponent,
    FnaTemplateQuestionComponent,
    FnaTemplateIntroductionComponent,
    FnaTemplateSignatureComponent,
    FnaFormSelectedComponent,
    FnaTemplateOverviewComponent,
  ],
  exports: [
    BasicInfoComponent,
    FnaFormTemplateComponent,
    FnaTemplateIntroductionComponent,
    FnaTemplateSignatureComponent,
    FnaFormSelectedComponent,
    FilterPopoverComponent
  ],
  imports: [
    CommonModule,
    NzIconModule,
    NzButtonModule,
    NzGridModule,
    NzRadioModule,
    FormsModule,
    NzSelectModule,
    TranslateModule,
    SharedModule,
    NzDatePickerModule,
    NzInputModule,
    NzPopoverModule,
    NzSpinModule,
    NzAnchorModule,
    NzInputNumberModule,
    NewFnaModule,
    NzCheckboxModule,
    NzModalModule,
    NzLayoutModule,
    NzTabsModule,
  ]
})

export class NewFnaTemplateModule { }
