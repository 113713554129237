import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommissionService} from '../commission.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {CommissionDetailComponent} from '../commission-detail/commission-detail.component';
import {DataMode} from '../../api/types';
import {BillDetailsRes, BillPageResp, InfoList} from '../commission-types';
import {DatePipe} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import { Workbench } from '../../workbench/workbench-types';
import { WorkbenchService } from '../../workbench/workbench.service';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { ProductService } from '../../product/product.service';
import { MetadataService } from '../../shared/service/metadata.service';

@Component({
  selector: 'app-commission-list',
  templateUrl: './commission-list.component.html',
  styleUrls: ['./commission-list.component.less'],
  providers: [DatePipe]
})
export class CommissionListComponent implements OnInit {

  commissionBillDate: string;
  info: BillDetailsRes = new BillDetailsRes();
  @Input()
  mode = DataMode.OWN;
  @Output()
  referralFee: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  listInfo: BillPageResp; // 列表信息
  filterPopoverVisible = false;
  filterData: InfoList = new InfoList();
  filterDate = false;
  workbench: Workbench;
  edit = false;
  ids = [];
  isExpands = [];

  companyOptions: PropertySelectOption[] = [];
  statusOptions: PropertySelectOption[] = [];

  constructor(private commissionService: CommissionService,
              private toastr: ToastrService,
              private translate: TranslateService,
              private drawerService: NzDrawerService,
              private datePipe: DatePipe,
              private productService: ProductService,
              private metadataService: MetadataService,
              private workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    // if (this.mode === DataMode.OWN) {
    //   this.filterData.status = 'PUBLISH';
    // }
    this.filterData.status = '';
    this.workbench = this.workbenchService.workbench;
    this.productService.companies()
      .subscribe(
        data => {
          data.forEach(company => {
            this.companyOptions?.push(new PropertySelectOption(company.name, company.code));
          });
        },
        error => {
        });
    this.translate.get('All').subscribe(
      (res) => {
        this.statusOptions = [new PropertySelectOption(res, '')];
      }
    );
    const statusList = this.metadataService.values('billStatus');
    for (const status of statusList) {
      if (status.key !== 'PRIMED') {
        this.statusOptions.push(new PropertySelectOption(status.value, status.key));
      }
    }
    this.onSearch();
  }

  onCommissionDetail(id: number): void {
    if (this.edit) {
      const index = this.ids.indexOf(id);
      if (index > -1) {
        this.ids.splice(index, 1);
      } else {
        this.ids.push(id);
      }
    } else {
      this.commissionService.detailInfo(id)
        .subscribe(
          data => {
            const drawerRef = this.drawerService.create<CommissionDetailComponent, { value: any }, string>({
              nzWidth: 600,
              nzContent: CommissionDetailComponent,
              nzContentParams: {
                detail: data,
                mode: this.mode
              }
            });
            drawerRef.afterOpen.subscribe(() => {
              const component = drawerRef.getContentComponent();
              component.infoDetailsClose
                .subscribe(
                  () => {
                    // console.log(data);
                    drawerRef.close();
                  }
                );
            });
            drawerRef.afterClose.subscribe(() => {
              // const component = drawerRef.getContentComponent();
              this.onReload();
            });
          }
        );
    }
  }

  toDecimal(val): any {
    if (val && val.indexOf('.') === -1) {
      val = val + '.00';
      return val;
    } else {
      return val;
    }
  }

  showBtn(): void {
    this.edit = true;
    this.ids = [];
  }

  onCancel(): void {
    this.edit = false;
    this.ids = [];
  }

  confirm(): void {
    const param = {
      billDate: this.commissionBillDate,
      type: this.mode === DataMode.OWN ? 'MY' : 'SUBORDINATE'
    };
    if (this.mode === DataMode.OWN) {
      param['ids'] = this.ids;
    }
    this.commissionService.billBatchConfirm(param)
      .subscribe(
        data => {
          if (data) {
            this.translate.get(['commissionConfirmTip']).subscribe(
              (res) => {
                this.toastr.success(res.commissionConfirmTip);
              }
            );
            this.onCancel();
            if (this.mode === DataMode.OWN) {
              this.filterData.status = 'PUBLISH';
            }
            this.onSearch();
            // this.onReload();
          }
        },
        error => {
        });
  }

  onSearch(): void {
    this.isExpands = [];
    this.filterDate = true;
    this.filterData.billDate = this.commissionBillDate;
    this.filterPopoverVisible = false;
    this.filterData.paidDate = this.datePipe.transform(this.filterData.paidDate, 'yyyy-MM-dd');
    this.commissionService.infoList(this.filterData, this.mode === DataMode.OWN)
      .subscribe(
        data => {
          this.info = data;
          if (data.classifications && data.classifications.length) {
            data.classifications.map(item => {
              this.isExpands.push(false);
            });
          }
          if (data.items && data.items.length) {
            this.isExpands.push(false);
          }
        },
        error => {
        });
  }

  onReload(): void {
    this.isExpands = [];
    this.filterDate = false;
    this.filterData = new InfoList();
    this.filterData.billDate = this.commissionBillDate;
    this.filterPopoverVisible = false;
    this.commissionService.infoList(this.filterData, this.mode === DataMode.OWN)
      .subscribe(
        data => {
          this.info = data;
          if (data.classifications && data.classifications.length) {
            data.classifications.map(item => {
              this.isExpands.push(false);
            });
          }
          if (data.items && data.items.length) {
            this.isExpands.push(false);
          }
        },
        error => {
        });
  }

  styleBorder(id): boolean {
    return this.ids.some((info) => info === id);
  }

  retract(i): void {
    this.isExpands[i] = !this.isExpands[i];
  }

  retractLast(): void {
    this.isExpands[this.isExpands.length - 1] = !this.isExpands[this.isExpands.length - 1];
  }
}
