<div class="drawer-container">
  <div class="drawer-title" style="margin-bottom: 40px">
    <div *ngIf="!edit">{{ 'ProposalAccount' | translate }}</div>
    <nz-breadcrumb *ngIf="edit" nzSeparator=">" style="font-size: 20px;line-height: 22px">
      <nz-breadcrumb-item appThrottleClick (throttleClick)="onCancel()"
                          style="cursor: pointer"
      >{{'ProposalAccount' | translate}}</nz-breadcrumb-item>
      <nz-breadcrumb-item *ngIf="!isUpdate">
        {{'AddProposalAccount' | translate}}
      </nz-breadcrumb-item>
      <nz-breadcrumb-item *ngIf="isUpdate">
        {{'EditProposalAccount' | translate}}
      </nz-breadcrumb-item>
    </nz-breadcrumb>
  </div>
  <div *ngIf="!edit">
    <nz-spin [nzSpinning]="spinning">
      <div *ngFor="let item of listData; let i = index">
        <div class="member-content" style="margin-bottom: 20px">
          <div class="card-top">
            <span>#{{ item.id }}</span>
            <nz-switch [(ngModel)]="item.enable" (ngModelChange)="onEnableChange($event, item)"></nz-switch>
          </div>
          <div style="display: flex; align-items: center;">
            <div class="member-card" appThrottleClick (throttleClick)="onUpdate(item)">
              <div class="member-card-item">
                <span>{{ 'Insurer' | translate }}</span>
                <span style="color: #C4C8C9">{{item.companyName}}</span>
              </div>
              <div class="member-card-item">
                <span>{{ 'Account' | translate }}</span>
                <span style="color: #C4C8C9">{{item.userName}}</span>
              </div>
              <div style="margin-bottom: 4px">
                <div style="color: #C4C8C9;margin-bottom: 5px">{{ 'StatusNote' | translate }}</div>
                <div>{{item.salesStatusNote}}</div>
              </div>
              <div *ngIf="item.verifyStatus">
                <span [class]="item.verifyStatus == 'WAIT_VERIFY' ? 'orange' : 'blue'"
                      appThrottleClick (throttleClick)="codeModal(item)"
                >
                  {{item.verifyStatus | metadataTranslate: 'verifyStatus'}}
                </span>
              </div>
            </div>
            <a nz-popconfirm
               [nzPopconfirmTitle]="('DeleteProposalAccount' | translate)"
               nzPopconfirmPlacement="bottom"
               [nzOkText]="('Confirm' | translate)"
               [nzCancelText]="('Cancel' | translate)"
               (nzOnConfirm)="confirm(item)"
               (nzOnCancel)="cancel()"
               nz-tooltip [nzTooltipTitle]="'Delete' | translate">
              <img alt="" width="28" src="assets/images/ic_delete.svg">
            </a>
          </div>

        </div>
      </div>
    </nz-spin>

    <nz-modal [(nzVisible)]="isVisible" [nzTitle]="'VerificationCode' | translate"
              [nzOkText]="'Confirm' | translate"
              [nzWidth]="'380px'"
              [nzCancelText]="'Cancel' | translate"
              (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
      <ng-container *nzModalContent>
        <input nz-input [placeholder]="('PleaseEnter' | translate) + ('VerificationCode' | translate)"
               [(ngModel)]="proposalCode" />
      </ng-container>
    </nz-modal>
  </div>
  <div *ngIf="edit">
    <!--    保险公司-->
    <app-property-select [displayMode]="'vertical'"
                         [title]="'Insurer' | translate"
                         [required]="true"
                         [disabled]="isUpdate"
                         [placeholder]="('PleaseSelect' | translate) + ('Insurer' | translate)"
                         [(value)]="createData.companyCode"
                         [options]="companyOptions"></app-property-select>
    <!--    账户名称-->
    <app-property-input [displayMode]="'vertical'"
                        [title]="'Account' | translate"
                        [required]="true"
                        [disabled]="isUpdate"
                        [placeholder]="('PleaseEnter' | translate) + ('AccountName' | translate)"
                        [(value)]="createData.userName"></app-property-input>

    <!--    <app-property-input [displayMode]="'vertical'"-->
    <!--                        [title]="'Password' | translate"-->
    <!--                        [required]="true"-->
    <!--                        [placeholder]="('PleaseEnter' | translate) + ('Password' | translate)"-->
    <!--                        [(value)]="createData.password"></app-property-input>-->
    <div style="margin-top: 5px">
      <div>
        {{'Password' | translate}}
        <img alt="" width="24" src="assets/images/ic_required.svg"></div>
      <div>
        <nz-input-group [nzSuffix]="suffixPassword" class="input-group">
          <input [(ngModel)]="createData.password"
                 id="phonePwd" name="phonePwd"
                 [type]="passwordVisible ? 'text' : 'password'"
                 autocomplete="off"
                 style="border: none;outline: none;width: 100%"
                 [placeholder]="('PleaseEnter' | translate) + ('Password' | translate)"
                 class="login-input-change"/>
        </nz-input-group>
        <ng-template #suffixPassword>
          <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
             (click)="passwordVisible = !passwordVisible"></i>
        </ng-template>
      </div>
    </div>

    <!--    <app-property-input [displayMode]="'vertical'"-->
    <!--                        [title]="'ConfirmPassword' | translate"-->
    <!--                        [required]="true"-->
    <!--                        [placeholder]="'PleaseConfirmPasswordAgain' | translate"-->
    <!--                        [(value)]="newPassword"></app-property-input>-->
    <div style="margin-top: 5px">
      <div>
        {{'ConfirmPassword' | translate}}
        <img alt="" width="24" src="assets/images/ic_required.svg">
      </div>
      <div>
        <nz-input-group [nzSuffix]="newSuffixPassword" class="input-group">
          <input [(ngModel)]="newPassword"
                 id="newPwd" name="newPwd"
                 [type]="newPasswordVisible ? 'text' : 'password'"
                 autocomplete="off"
                 style="border: none;outline: none;width: 100%"
                 [placeholder]="'PleaseConfirmPasswordAgain' | translate" class="login-input-change"/>
        </nz-input-group>
        <ng-template #newSuffixPassword>
          <i nz-icon [nzType]="newPasswordVisible ? 'eye-invisible' : 'eye'"
             (click)="newPasswordVisible = !newPasswordVisible"></i>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="footer">
    <div *ngIf="!edit" nz-row style="width: 100%; height: 100%;">
      <!--      <div nz-col nzSpan="12" class="footer-button">-->
      <!--        <a nz-button nzType="link" nzBlock nzSize="large">-->
      <!--          <span>{{'Disband' | translate}}</span>-->
      <!--        </a>-->
      <!--      </div>-->
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onAdd()">
          <span>{{'AddAccount' | translate}}</span>
        </a>
      </div>
    </div>
    <div *ngIf="edit" nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onCancel()">
          <span>{{'Cancel' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onCreate()"
           [disabled]="!canReply"
           [style]="!canReply ? 'opacity: 0.5' : ''">
          <span>{{'Confirm' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>

