import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { plainToInstance } from 'class-transformer';
import { Message, MessageType } from '../api/types';
import { MessageService } from '../message/message.service';
import { ProposalService } from '../proposal/proposal.service';
import { BookingService } from '../booking/booking.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService extends BaseService {

  // readonly webSocketUrl = 'ws://localhost:8080/socket/';
  readonly webSocketUrl = 'wss://api.lifebee.tech/socket/';

  client: WebSocketSubject<any>;

  constructor(private messageService: MessageService,
              private proposalService: ProposalService,
              private bookingService: BookingService) {
    super();
  }

  connect(): void {
    try {
      const token = this.passport?.token;
      const instance = this.instance?.key;
      if (token && instance) {
        const url = `${(this.webSocketUrl)}${token}?instance=${instance}`;

        this.client = webSocket({
          url,
          openObserver: {
            next: () => {
              console.log('connection ok');
            }
          },
        });

        this.client.subscribe(
          data => {
            const message = plainToInstance(Message, data);
            console.log(message);
            this.messageService.messageReceived.emit(message); // 全局消息，用于弹出通知弹窗
            this.messageService.messageUnreadEvent.emit(''); // 消息通知 提示数量刷新
            // switch (message.type) {
            //   case MessageType.BOOKING:
            //     this.bookingService.messageReceived.emit(message); // 预约消息，用于自动刷新列表
            //     break;
            //   case MessageType.PROPOSAL:
            //     this.proposalService.messageReceived.emit(message); // 计划书消息，用于自动刷新列表
            //     break;
            //   case MessageType.UNDERWRITING:
            //     break;
            //   case MessageType.POLICY:
            //     break;
            // }
          }
        );
      }
    } catch (e) {
    }
  }

}
