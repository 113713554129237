<div class="drawer-container">
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="loading">
    <div class="common-title" style="margin-bottom: 30px">
      {{'ProductInfo' | translate}}
    </div>

    <div class="section-title flex-row" style="margin-top: 30px;">
      <div style="margin-left: 8px; flex: 1">{{'OptionalRecommendedProducts' | translate}}</div>
    </div>

    <div>

      <div *ngFor="let product of recommended.customize; let i = index;">
        <app-fna-product-item [product]="product"
                              [index]="i" [showDeleteButton]="true"
                              (deleteEvent)="onOptionalRecommendedProductDelete($event)"></app-fna-product-item>
      </div>

      <div class="section-wrapper">
        <app-property-pick [title]="'SelectProduct' | translate"
                           [bottomLineVisible]="false"
                           appThrottleClick (throttleClick)="onOptionalRecommendedProductSelect()"></app-property-pick>
      </div>
    </div>

    <div class="section-title flex-row" style="margin-top: 30px;">
      <div style="margin-left: 8px; flex: 1">{{'SystemRecommendedProducts' | translate}}</div>
    </div>

    <div *ngFor="let product of recommended.recommend; let i = index;">
      <app-fna-product-item [product]="product"
                            [index]="i"
                            [showDetailButton]="true"
                            (tickEvent)="onSystemRecommendedProductTick($event)"
                            (detailEvent)="onSystemRecommendedProductDetail($event)"></app-fna-product-item>
    </div>

    <div class="section-title flex-row" style="margin-top: 30px;">
      <div style="margin-left: 8px; flex: 1">{{'HistoryRecommendedProducts' | translate}}</div>
    </div>

    <div *ngFor="let product of recommended.history; let i = index;">
      <app-fna-product-item [product]="product"
                            [index]="i"
                            [showDetailButton]="true"
                            (tickEvent)="onHistoryRecommendedProductTick($event)"
                            (detailEvent)="onHistoryRecommendedProductDetail($event)"></app-fna-product-item>
    </div>

  </nz-spin>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" [disabled]="loading"
           appThrottleClick (throttleClick)="onSave()"
           [style]="loading ? 'opacity: 0.5' : ''">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
