<div *ngIf="product.details">
  <div *ngFor="let attributeGroup of product.details">
    <div class="section-title" style="margin: 20px 0 15px 0">
      <span style="margin: 0 0 0 10px">{{attributeGroup.name}}</span>
    </div>
    <div *ngFor="let attribute of attributeGroup.attributes">
      <app-property-text [title]="attribute.name" [value]="attribute.value"></app-property-text>
    </div>
  </div>
</div>
<div *ngIf="!product.details">
  <nz-empty nzNotFoundImage="simple"></nz-empty>
</div>
