import {EventEmitter, Injectable} from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpService } from '../core/http.service';
import {
  AmlArtificialStatusUpdateReq,
  AmlInfo,
  AmlInspectReq,
  AmlListResp,
  AmlMonitorArtificialStatusUpdateReq,
  AmlMonitorInfo,
  AmlMonitorSearch,
  AmlMonitorSummary,
  AmlRecordType,
  AmlSearch,
  AmlSummary,
  AMLSummaryDetail,
  AmlSummaryList,
  AmlThirdPartPDFReq,
  ApiResponse,
  PagedResp
} from '../api/types';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../api/api';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AmlService extends BaseService {

  monitorReload: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpService, private httpClient: HttpClient) {
    super();
  }

  /**
   * AMLSearch列表
   * @param search 查询条件
   */
  list(search: AmlSearch): Observable<PagedResp<AmlListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status.toString());
    }
    if (search.type != null) {
      httpParams = httpParams.set('type', search.type.toString());
    }
    if (search.gender != null) {
      httpParams = httpParams.set('gender', search.gender.toString());
    }
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    if (search.status != null) {
      httpParams = httpParams.set('status', search.status.toString());
    }
    if (search.createTime != null) {
      httpParams = httpParams.set('createTime', search.createTime.toString());
    }
    if (search.beginTime != null) {
      httpParams = httpParams.set('beginTime', search.beginTime.toString());
    }
    if (search.endTime != null) {
      httpParams = httpParams.set('endTime', search.endTime.toString());
    }
    return this.http.get<ApiResponse<PagedResp<AmlListResp>>>(API.amlSearchV3 + '/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * AML檢查詳情
   * @param id 編號
   */
  detail(id: number): Observable<AmlInfo> {
    return this.http.get<ApiResponse<AmlInfo>>(API.amlSearchV3 + '/info/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 新增AML检查
   * @param req 请求参数
   * @param type 检查类型
   */
  createInspect(req: AmlInspectReq, type: AmlRecordType): Observable<boolean> {
    let url = '';
    switch (type) {
      case AmlRecordType.PERSON:
        url = API.amlSearchV3 + '/person';
        break;
      case AmlRecordType.BUSINESS:
        url = API.amlSearchV3 + '/business';
        break;
      default:
        url = API.amlSearchV3 + '/person';
        break;
    }
    return this.http.post<ApiResponse<boolean>>(url, req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 人工确认状态
   * @param req 请求参数
   */
  updateArtificialStatus(req: AmlArtificialStatusUpdateReq): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.amlSearchV3 + '/artificial-status', req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 查看第三方PDF
   * @param req 请求参数
   */
  downloadProfile(req: AmlThirdPartPDFReq): Observable<Uint8Array> {
    let httpParams = new HttpParams();
    if (req.type != null) {
      httpParams = httpParams.set('type', req.type.toString());
    }
    if (req.thirdPartId != null) {
      httpParams = httpParams.set('thirdPartyId', req.thirdPartId.toString());
    }
    return this.httpClient
      .get(API.amlSearchV3 + '/download/profile', {
        params: httpParams,
        responseType: 'arraybuffer'
      }).pipe(map((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer)));
  }

  /**
   * 检查结果正常pdf
   * @param id id
   */
  downloadNormalProfile(id: number): Observable<Uint8Array> {
    return this.httpClient
      .get(API.amlSearchV3 + '/download/normal-profile/' + id, {
        responseType: 'arraybuffer'
      }).pipe(map((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer)));
  }

  /**
   * 获取描述pdf(自制的条件页面 + 第三方)
   * @param ids ids Query传参
   */
  downloadAbnormalProfile(ids: any): Observable<Uint8Array> {
    let httpParams = new HttpParams();
    if (ids.id !== null) {
      httpParams = httpParams.set('id', ids.id.toString());
    }
    if (ids.thirdPartId !== null) {
      httpParams = httpParams.set('thirdPartId', ids.thirdPartId.toString());
    }
    return this.httpClient
      .get(API.amlSearchV4 + '/download/abnormal-profile', {
        params: httpParams,
        responseType: 'arraybuffer'
      }).pipe(map((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer)));
  }

  /**
   * 获取 entry 列表
   * @param search 搜索条件
   * @returns entry 列表
   */
  entryList(search: AmlMonitorSearch): Observable<PagedResp<AmlListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.monitorStatus != null) {
      httpParams = httpParams.set('monitorStatus', search.monitorStatus.toString());
    }
    if (search.type != null) {
      httpParams = httpParams.set('type', search.type.toString());
    }
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    if (search.surname != null) {
      httpParams = httpParams.set('surname', search.surname.toString());
    }
    if (search.forename != null) {
      httpParams = httpParams.set('forename', search.forename.toString());
    }
    if (search.createTime != null) {
      httpParams = httpParams.set('createTime', search.createTime.toString());
    }
    if (search.customCode != null) {
      httpParams = httpParams.set('customCode', search.customCode.toString());
    }
    // if (search.endTime != null) {
    //   httpParams = httpParams.set('endTime', search.endTime.toString());
    // }
    return this.http.get<ApiResponse<PagedResp<AmlListResp>>>(API.amlMonitorV3 + '/entry/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 获取 entry 详情
   * @param id entry Id
   * @returns entry 详情
   */
  entryDetail(id: number): Observable<AmlMonitorInfo> {
    return this.http.get<ApiResponse<AmlMonitorInfo>>(API.amlMonitorV3 + '/entry/info/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 添加 person/business 到监控列表
   * @param req 请求参数
   * @param type 类型
   * @returns true/false
   */
  addMonitorEntry(req: AmlInspectReq, type: AmlRecordType): Observable<boolean> {
    let url = '';
    switch (type) {
      case AmlRecordType.PERSON:
        url = API.amlMonitorV3 + '/add-person';
        break;
      case AmlRecordType.BUSINESS:
        url = API.amlMonitorV3 + '/add-business';
        break;
      default:
        url = API.amlMonitorV3 + '/add-person';
        break;
    }
    return this.http.post<ApiResponse<boolean>>(url, req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 删除Entry
   * @param id id
   * @param type 类型
   * @returns true/false
   */
  deleteMonitorEntry(id: number, type: AmlRecordType): Observable<boolean> {
    let url = '';
    switch (type) {
      case AmlRecordType.PERSON:
        url = API.amlMonitorV3 + '/remove-person/' + id;
        break;
      case AmlRecordType.BUSINESS:
        url = API.amlMonitorV3 + '/remove-business/' + id;
        break;
      default:
        url = API.amlMonitorV3 + '/remove-person/' + id;
        break;
    }
    return this.http.delete<ApiResponse<boolean>>(url, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 人工修改状态(第三方)
   * @param req 请求
   * @param type 类型
   * @returns true/false
   */
  updateMonitorArtificialStatus(req: AmlMonitorArtificialStatusUpdateReq, type: AmlRecordType): Observable<boolean> {
    let url = '';
    switch (type) {
      case AmlRecordType.PERSON:
        url = API.amlMonitorV3 + '/add-comment/person';
        break;
      case AmlRecordType.BUSINESS:
        url = API.amlMonitorV3 + '/add-comment/business';
        break;
      default:
        url = API.amlMonitorV3 + '/add-comment/person';
        break;
    }
    return this.http.post<ApiResponse<boolean>>(url, req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 检查结果正常pdf
   * @param id id
   * @returns Uint8Array 文件流
   */
  downloadMonitorNormalProfile(id: number): Observable<Uint8Array> {
    return this.httpClient
      .get(API.amlMonitorV3 + '/download/pdf/normal/' + id, {
        responseType: 'arraybuffer'
      }).pipe(map((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer)));
  }

  /**
   * 获取描述pdf(第三方)
   * @param req 请求参数
   * @returns Uint8Array 文件流
   */
  downloadMonitorProfile(req: AmlThirdPartPDFReq): Observable<Uint8Array> {
    let httpParams = new HttpParams();
    if (req.type != null) {
      httpParams = httpParams.set('type', req.type.toString());
    }
    if (req.thirdPartId != null) {
      httpParams = httpParams.set('thirdPartId', req.thirdPartId.toString());
    }
    return this.httpClient
      .get(API.amlMonitorV3 + '/download/profile', {
        params: httpParams,
        responseType: 'arraybuffer'
      }).pipe(map((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer)));
  }

  //  open alert
  /**
   * 获取 open alert 列表
   * @param search 搜索参数
   * @returns AmlListResp 搜索结果列表
   */
  openAlertList(search: AmlMonitorSearch): Observable<PagedResp<AmlListResp>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }
    if (search.monitorStatus != null) {
      httpParams = httpParams.set('monitorStatus', search.monitorStatus.toString());
    }
    if (search.type != null) {
      httpParams = httpParams.set('type', search.type.toString());
    }
    if (search.surname != null) {
      httpParams = httpParams.set('surname', search.surname.toString());
    }
    if (search.name != null) {
      httpParams = httpParams.set('name', search.name.toString());
    }
    if (search.forename != null) {
      httpParams = httpParams.set('forename', search.forename.toString());
    }
    if (search.createTime != null) {
      httpParams = httpParams.set('createTime', search.createTime.toString());
    }
    if (search.customCode != null) {
      httpParams = httpParams.set('customCode', search.customCode.toString());
    }
    // if (search.endTime != null) {
    //   httpParams = httpParams.set('endTime', search.endTime.toString());
    // }
    return this.http.get<ApiResponse<PagedResp<AmlListResp>>>(API.amlMonitorV3 + '/open-alert/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * open-alert 详情
   * @param id id
   * @returns AmlMonitorInfo open-alert 详情
   */
  openAlertDetail(id: number): Observable<AmlMonitorInfo> {
    return this.http.get<ApiResponse<AmlMonitorInfo>>(API.amlMonitorV3 + '/open-alert/info/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * summary 列表
   * @param search 搜索参数
   * @returns AmlSummaryList 搜索结果列表
   */
  summaryList(search: AmlMonitorSummary): Observable<PagedResp<AmlSummaryList>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }

    if (search.createTime != null) {
      httpParams = httpParams.set('createTime', search.createTime.toString());
    }
    return this.http.get<ApiResponse<PagedResp<AmlSummaryList>>>(API.amlMonitorV3 + '/summary/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * summary 详情
   * @param search 搜索条件
   * @returns AMLSummaryDetail 详情
   */
  summaryDetail(search: AmlSummary): Observable<PagedResp<AMLSummaryDetail>> {
    let httpParams = new HttpParams();
    if (search.pageNum != null) {
      httpParams = httpParams.set('pageNum', search.pageNum.toString());
    }
    if (search.pageSize != null) {
      httpParams = httpParams.set('pageSize', search.pageSize.toString());
    }

    if (search.summaryId != null) {
      httpParams = httpParams.set('summaryId', search.summaryId.toString());
    }
    return this.http.get<ApiResponse<PagedResp<AMLSummaryDetail>>>(API.amlMonitorV3 + '/summary/info', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 添加到 monitor entry 列表
   * @param id 编号
   */
  addMonitorEntryList(id: number): Observable<boolean>{
      return this.http.post<ApiResponse<boolean>>(API.amlSearchV3 + '/add-entry/' + id, null, {
        observe: 'response'
      }).pipe(map(data => {
        return data.body.data;
      }));
  }

}



