import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import { BankInfoSetting, DiscountInfo, DiscountInfoSearch, DiscountInfoSetting } from '../../proposal-types';
import {ProposalService} from '../../proposal.service';
import {debounceTime} from 'rxjs/operators';
import {plainToInstance} from 'class-transformer';

@Component({
  selector: 'app-discount-info',
  templateUrl: './discount-info.component.html',
  styleUrls: ['./discount-info.component.less']
})
export class DiscountInfoComponent implements OnInit {
  drawerRef: NzDrawerRef<DiscountInfoComponent, string>;
  search: DiscountInfoSearch = new DiscountInfoSearch();
  dataList: DiscountInfoSetting[] = [];
  loading = false;
  searchParamChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  discountInfoSelected: EventEmitter<DiscountInfoSetting> = new EventEmitter<DiscountInfoSetting>();

  constructor(private proposalService: ProposalService) { }

  ngOnInit(): void {
    this.getList();
    this.searchParamChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          this.getList();
        }
      );
  }

  onSearchParamChange(): void {
    this.searchParamChange.emit('');
  }

  getList(): void {
    this.loading = true;
    this.proposalService.allDiscountInfo(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.dataList = data;
        },
        error => {}
      );
  }

  onInfoSelected(val): void {
    this.drawerRef?.close();
    this.discountInfoSelected.emit(val);
  }
}
