import {Expose, plainToInstance, Transform, Type} from 'class-transformer';
import { Material, PagedReq } from '../api/types';

export class CommissionList extends PagedReq {
  @Expose() accountName: string; // 账号
  @Expose() static: string; // 账单状态s
}

export class NewCommissionList extends PagedReq {
  @Expose() billDate: any; // 账单日期
  @Expose() policyNo: string;
}

export class InfoList {
  @Expose() billDate: any; // 账单日期
  @Expose() paidDate: any; // 发放日期
  @Expose() policyNo: string;
  @Expose() companyCode: string; // 保险公司
  @Expose() status: string; // 介绍费状态啊
}

export class BillPageResp {
  @Expose() billDate: string; // 账单日期
  @Expose() amount: number; // 账单总金额
  @Expose() billCount: number; // 账单数量
  @Expose() pendingCount: number; // 待确认账单数量
}

export class BillDetailsRes {
  @Expose() accountName: string; // 账户名
  @Expose() billDate: string; // 账单日期
  @Type(() => Classifications)
  @Expose() classifications: Classifications[]; // 保险公司和佣金类型分类
  @Type(() => Items)
  @Expose() items: Items[]; // 自定义账单项
  @Expose() itemsAmount: number; // 自定义账单项金额
  @Expose() pendingCount: number; // 待确认账单数量
}

// 保险公司和佣金类型分类
export class Classifications {
  @Expose() name: string; // 账户名
  @Type(() => Details)
  @Expose() details: Details[]; // 账单明细
  @Expose() paidDate: number; // 实际发放日期
  @Expose() subTotal: number; // 小计
}

// 账单明细
export class Details {
  @Expose() id: number; // 账单明细编号
  @Expose() policyId: string; // 保单编号
  @Expose() policyNo: string; // 保单号
  @Expose() commissionBillId: number; // 账单编号
  @Expose() commissionType: string; // 佣金类型
  @Expose() companyCode: string; // 保险公司码
  @Expose() companyName: string; // 保险公司名称
  @Expose() companyLogo: string; // 保险公司logo
  @Expose() productCode: string; // 产品码
  @Expose() productName: string; // 产品名称
  @Expose() finalCommission: number; // 实发佣金
  @Expose() ownerName: string; // 投保人名称
  @Expose() paidDate: string; // 实际发放日期
  @Expose() status: string; // 发放状态:PRIMED-待发布;PUBLISH-发布;CONFIRMED-已确认;PAID-已发放;DELETED-已删除
  @Expose() remark: string; // 备注
  @Expose() exchangeRate: string; // 结算汇率
}

// 自定义账单项
export class Items {
  @Expose() id: number; // id
  @Expose() policyNo: string; // 账户名
  @Expose() remark: string; // 备注
  @Expose() billName: string; // 自定义项目名称
  @Expose() paidDate: string; // 实际发放日期
  @Expose() finalCommission: number; // 实发佣金
  @Expose() status: string; // 发放状态:PRIMED-待发布;PUBLISH-发布;CONFIRMED-已确认;PAID-已发放;DELETED-已删除
}
// 查询介绍费详情
export class IntroductionFeeDetails {
  @Expose() policyNo: string; // 保单号
  @Expose() paymentDate: number; // 缴费日期
  @Expose() premium: number; // 保费
  @Expose() commissionType: string; // 佣金类型
  @Expose() salesAccountName: string; // 销售渠道
  @Expose() companyName: string; // 保险公司名称
  @Expose() companyLogo: string; // 保险公司logo
  @Expose() productName: string; // 产品名称
  @Expose() finalCommission: number; // 实发佣金
  @Expose() currency: string; // 币种
  @Expose() billDetailType: string; // 账单明细类型
  @Expose() remark: string; // 备注
  @Expose() ownerName: string; // 投保人名称
  @Expose() policyYear: number; // 供款年期 「已废弃」
  @Expose() contributionPeriod: number; // 供款年期
  @Expose() insuredName: string; // 受保人名称
  @Expose() nickName: string; // 介绍人名称
}

// 查询介绍费详情
export class InfoDetails {
  @Expose() id: string; // 账单ID
  @Expose() policyNo: string; // 保单号
  @Expose() paymentDate: number; // 缴费日期
  @Expose() premium: number; // 保费
  @Expose() commissionType: string; // 佣金类型:INITIAL;RENEWAL;TRAIL;OTHER
  @Expose() accountName: string; // 销售渠道
  @Expose() companyName: string; // 保险公司名称
  @Expose() companyLogo: string; // 保险公司logo
  @Expose() productName: string; // 产品名称
  @Expose() approvalDate: string;
  @Expose() policyDate: string;
  @Expose() finalCommission: number; // 实发佣金
  @Expose() currency: string; // 币种
  @Expose() type: string; // 账单明细类型:GENERAL-一般账单;CUSTOM_ITEM-自定义项
  @Expose() remark: string; // 备注
  @Expose() ownerName: string; // 投保人名称
  // @Expose() policyYear: number; // 供款年期 「已废弃」
  @Expose() contributionPeriod: number; // 供款年期
  @Expose() insuredName: string; // 受保人名称
  @Expose() nickName: string; // 介绍人名称
  @Expose() status: string; // 发放状态:PRIMED-待发布;PUBLISH-发布;CONFIRMED-已确认;PAID-已发放;DELETED-已删除
  @Expose() attachments: Material[]; // 发放状态:PRIMED-待发布;PUBLISH-发布;CONFIRMED-已确认;PAID-已发放;DELETED-已删除
  @Expose() exchangeRate: string; // 结算汇率
  @Expose() aibvIncrement: number;
  @Expose() bvFactor: number;
  @Expose() productFactor: number;
  @Expose() subordinateChannelBills: SubordinateChannelBills[]; // 下级介绍费信息
}

export class SubordinateChannelBills {
  @Expose() accountName: string; // 销售账号
  @Expose() nickName: string; //
  @Expose() rate: string; // 佣金率
  @Expose() finalCommission: string; // 实发佣金
}

export class Calculate {
  @Expose() productCode: string; // 产品码
  @Expose() paymentTerm: string; // 供款年期
  @Expose() premium: string; // 保费
  @Expose() age: string; // 年龄
  @Expose() currency: string; // 货币
}

export class CalculateRes {
  @Expose() contractName: string; // 合约名称
  @Expose() contractType: string; // 合約类型:GENERAL-一般合约;PROMOTION_PLUS-促销合约(加);PROMOTION_MULTIPLY-促销合约(乘)
  @Expose() baseOn: string; // 计算基础:PREMIUM-保费;GROSS_COMMISSION-毛佣金
  @Expose() effectiveDate: string; // 生效时间
  @Expose() invalidDate: string; // 失效时间
  @Expose() createTime: string; // 创建时间
  @Expose() promotionContractName: string; // 促销合约名称
  @Expose() promotionContractType: string; // 促销合約类型:GENERAL-一般合约;PROMOTION_PLUS-促销合约(加);PROMOTION_MULTIPLY-促销合约(乘)
  @Expose() promotionEffectiveDate: string; // 促销生效时间
  @Expose() promotionInvalidDate: string; // 促销失效时间
  @Expose() promotionCreateTime: string; // 促销创建时间
  @Type(() => ListVO)
  @Transform((value) => plainToInstance(ListVO, Object.assign(new ListVO(), value.value)))
  @Expose() listVO: ListVO = new ListVO(); // 详情
}

export class ListVO {
  @Expose() productCode: string; // 产品码
  @Expose() productName: string; // 产品名称
  @Expose() logo: string; // LOGO
  @Expose() paymentTerm: string; // 供款年期
  @Expose() premium: string; // 保费
  @Expose() age: string; // 年龄
  @Expose() currency: string; // 货币
  @Expose() exchangeRate: string; // 汇率
  @Type(() => List)
  @Expose() list: List[]; // 计算详情
}

export class List {
  @Expose() policyYear: string; // 年度
  @Expose() rate: string; // 比例
  @Expose() amount: string; // 金额
}

export class UploadList {
  @Expose() billId: string; // 渠道账单ID
  @Expose() material: Material[] | Material; // 附件
}



