<div class="drawer-container" style="padding-bottom: 0">
  <div class="drawer-title" style="margin-bottom: 40px; font-size: 20px;">
    {{'CommissionsList' | translate}}
  </div>
  <nz-spin [nzSpinning]="spinning"></nz-spin>
  <nz-tabset nzCentered (nzSelectedIndexChange)="tabsChange($event)">
    <nz-tab [nzTitle]="'MainInsurance' | translate">
      <nz-empty *ngIf="info && !info.length" nzNotFoundImage="simple"></nz-empty>
      <div *ngIf="info && info.length">
        <div style="font-size: 16px;margin-bottom: 10px;">{{info[0]?.productName}}</div>
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div>
            <div><span style="color: #909090;margin-bottom: 10px;">{{'Referee' | translate}}：</span>{{info[0]?.appPolicyCommissionDetailVO?.nickName}}</div>
            <div><span style="color: #909090;margin-bottom: 10px;">{{'PolicyNumber' | translate}}：</span>{{info[0]?.policyNo}}</div>
            <div><span style="color: #909090;margin-bottom: 10px;">{{'Holder' | translate}}：</span>{{info[0]?.appPolicyCommissionDetailVO?.ownerName}}</div>
            <div><span style="color: #909090;margin-bottom: 10px;">{{'Insured' | translate}}：</span>{{info[0]?.appPolicyCommissionDetailVO?.insuredName}}</div>
            <div><span style="color: #909090;margin-bottom: 10px;">{{'PaymentTerm' | translate}}：</span>{{info[0]?.appPolicyCommissionDetailVO?.productYear}}</div>
          </div>
          <div>
            <img width="80" style="border-radius: 3px; margin-right: 5px" [src]="info[0]?.appPolicyCommissionDetailVO?.companyLogo" alt=""/>
          </div>
        </div>

        <div style="margin-top: 20px">
          <nz-table #basicTable nzBordered [nzData]="info"
                    [nzShowPagination]="false">
            <thead>
            <tr>
              <th>{{'PaymentDates' | translate}}</th>
              <th>{{'Premiums' | translate}}</th>
<!--              <th>{{'Currency' | translate}}</th>-->
              <th>{{'PayInCommission' | translate}}</th>
              <th *ngIf="info[0].commissionMode === 'BV_MODE'">{{'Product Factor'}}</th>
              <th *ngIf="info[0].commissionMode === 'BV_MODE'">{{'AIBV'}}</th>
              <th *ngIf="info[0].commissionMode === 'BV_MODE'">{{'BV Factor'}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of info">
              <td>{{ data.premiumDate }}</td>
              <td>{{ data.appPolicyCommissionDetailVO.premium | number: '.2' }} {{data.appPolicyCommissionDetailVO.currency}}</td>
<!--              <td>{{ data.appPolicyCommissionDetailVO.currency | metadataTranslate: 'currency'}}</td>-->
              <td>{{ data.commission | number: '.2' }} HKD</td>
              <td *ngIf="data.commissionMode === 'BV_MODE'">{{ data.productFactor }}</td>
              <td *ngIf="data.commissionMode === 'BV_MODE'"><span *ngIf="data.aibvIncrement">HKD {{data.aibvIncrement}}</span></td>
              <td *ngIf="data.commissionMode === 'BV_MODE'">{{ data.bvFactor }}</td>
            </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
<!--      <div *ngFor="let list of info" let i=index>-->
<!--        <div class="card pointer"-->
<!--             appThrottleClick (throttleClick)="onCommissionDetail(list.policyCommissionId)">-->
<!--          <div class="card-top">-->
<!--            <div>-->
<!--              <span>{{'PolicyNo' | translate}}：</span>{{ list.policyNo }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="card-middle">-->
<!--            <div class="cardMiddle" style="flex: 1">-->
<!--              <div><span style="color: #909090">{{'AnnualPremium' | translate}}：</span>HKD {{list.yearPremium | number: '.2'}}</div>-->
<!--              <div><span style="color: #909090">{{'PayInCommission' | translate}}：</span>HKD {{list.commission | number: '.2'}}</div>-->
<!--              <div><span style="color: #909090">{{'Insurer' | translate}}：</span>{{list.companyName}}</div>-->
<!--              <div><span style="color: #909090">{{'ProductName' | translate}}：</span>{{list.productName}}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="card-top" style="border-bottom: none;border-top: 1px solid #ebf0f7;">-->
<!--            <div><span>{{'PaymentDates' | translate}}：</span>{{list.premiumDate}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </nz-tab>
    <nz-tab [nzTitle]="'Rider' | translate">
      <nz-empty *ngIf="info && !info.length" nzNotFoundImage="simple"></nz-empty>
      <div *ngIf="info && info.length">
        <div style="font-size: 16px;margin-bottom: 10px;">{{info[0]?.productName}}</div>
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div>
            <div><span style="color: #909090;margin-bottom: 10px;">{{'Referee' | translate}}：</span>{{info[0]?.appPolicyCommissionDetailVO?.nickName}}</div>
            <div><span style="color: #909090;margin-bottom: 10px;">{{'PolicyNumber' | translate}}：</span>{{info[0]?.policyNo}}</div>
            <div><span style="color: #909090;margin-bottom: 10px;">{{'Holder' | translate}}：</span>{{info[0]?.appPolicyCommissionDetailVO?.ownerName}}</div>
            <div><span style="color: #909090;margin-bottom: 10px;">{{'Insured' | translate}}：</span>{{info[0]?.appPolicyCommissionDetailVO?.insuredName}}</div>
            <div><span style="color: #909090;margin-bottom: 10px;">{{'PaymentTerm' | translate}}：</span>{{info[0]?.appPolicyCommissionDetailVO?.productYear}}</div>
          </div>
          <div>
            <img width="80" style="border-radius: 3px; margin-right: 5px" [src]="info[0]?.appPolicyCommissionDetailVO?.companyLogo" alt=""/>
          </div>
        </div>

        <div style="margin-top: 20px">
          <nz-table #basicTable nzBordered [nzData]="info"
                    [nzShowPagination]="false">
            <thead>
            <tr>
              <th>{{'PaymentDates' | translate}}</th>
              <th>{{'Premiums' | translate}}</th>
<!--              <th>{{'Currency' | translate}}</th>-->
              <th>{{'PayInCommission' | translate}}</th>
              <th *ngIf="info[0].commissionMode === 'BV_MODE'">{{'Product Factor'}}</th>
              <th *ngIf="info[0].commissionMode === 'BV_MODE'">{{'AIBV'}}</th>
              <th *ngIf="info[0].commissionMode === 'BV_MODE'">{{'BV Factor'}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of info">
              <td>{{ data.premiumDate }}</td>
              <td>{{ data.appPolicyCommissionDetailVO.premium | number: '.2' }} {{data.appPolicyCommissionDetailVO.currency}}</td>
<!--              <td>{{ data.appPolicyCommissionDetailVO.currency | metadataTranslate: 'currency'}}</td>-->
              <td>{{ data.commission | number: '.2' }} HKD</td>
              <td *ngIf="data.commissionMode === 'BV_MODE'">{{ data.productFactor }}</td>
              <td *ngIf="data.commissionMode === 'BV_MODE'"><span *ngIf="data.aibvIncrement">HKD {{data.aibvIncrement}}</span></td>
              <td *ngIf="data.commissionMode === 'BV_MODE'">{{ data.bvFactor }}</td>
            </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
<!--      <div *ngFor="let list of info" let i=index>-->
<!--        <div class="card pointer"-->
<!--             appThrottleClick (throttleClick)="onCommissionDetail(list.policyCommissionId)">-->
<!--          <div class="card-top">-->
<!--            <div>-->
<!--              <span>{{'PolicyNo' | translate}}：</span>{{ list.policyNo }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="card-middle">-->
<!--            <div class="cardMiddle" style="flex: 1">-->
<!--              <div><span style="color: #909090">{{'AnnualPremium' | translate}}：</span>HKD {{list.yearPremium | number: '.2'}}</div>-->
<!--              <div><span style="color: #909090">{{'PayInCommission' | translate}}：</span>$ {{list.commission | number: '.2'}}</div>-->
<!--              <div><span style="color: #909090">{{'Insurer' | translate}}：</span>{{list.companyName}}</div>-->
<!--              <div><span style="color: #909090">{{'ProductName' | translate}}：</span>{{list.productName}}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="card-top" style="border-bottom: none;border-top: 1px solid #ebf0f7;">-->
<!--            <div><span>{{'保費日期' | translate}}：</span>{{list.premiumDate}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </nz-tab>
  </nz-tabset>

</div>
