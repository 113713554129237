import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasicInfoSave, FnaSystemSave, NewFnaDetail } from '../../new-fna-types';
import { SubSalesChannelComponent } from '../../../shared/component/sub-sales-channel/sub-sales-channel.component';
import { MetadataService } from '../../../shared/service/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { FnaService } from '../../../fna/fna.service';
import { AccountService } from '../../../account/account.service';
import { ToastrService } from 'ngx-toastr';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { DataMode } from '../../../api/types';
import { NewFnaService } from '../../new-fna.service';

@Component({
  selector: 'app-new-fna-system-info',
  templateUrl: './new-fna-system-info.component.html',
  styleUrls: ['./new-fna-system-info.component.less']
})
export class NewFnaSystemInfoComponent implements OnInit {
  drawerRef: NzDrawerRef<NewFnaSystemInfoComponent, string>;

  loading = false;
  fnaInfo: NewFnaDetail = new NewFnaDetail();
  @Input()
  mode = DataMode.OWN;
  @Output()
  systemSaveChange: EventEmitter<any> = new EventEmitter<any>();
  customerTypeOptions: PropertySelectOption[] = [];
  customerSourceOptions: PropertySelectOption[] = [];


  constructor(private metadataService: MetadataService,
              private translate: TranslateService,
              private drawerService: NzDrawerService,
              private fnaService: FnaService,
              private newFnaService: NewFnaService,
              private accountService: AccountService,
              private toastr: ToastrService) { }

  ngOnInit(): void {

    const customerTypes = this.metadataService.values('ownerType');
    for (const customerType of customerTypes) {
      this.customerTypeOptions.push(new PropertySelectOption(customerType.value, customerType.key));
    }

    this.getSources();
  }


  onSub(): void {
    if (!this.accountNameSave) {
      return;
    }
    const drawerRef = this.drawerService.create<SubSalesChannelComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: SubSalesChannelComponent,
      nzContentParams: {
        accountName: [this.fnaInfo.accountName]
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.strSaved.subscribe(
        data => {
          drawerRef.close();
          console.log(data);
          if (data) {
            this.fnaInfo.accountName = data.accountName;
            this.fnaInfo.nickName = data.name;
          }
        }
      );
    });
  }

  getSources(): void {
    this.fnaService.sourceList()
      .subscribe(
        sources => {
          for (const source of sources) {
            this.customerSourceOptions.push(new PropertySelectOption((source.sourceAccount + ' - ' + source.sourceName), source.sourceAccount));
          }
        }
      );
  }

  get accountNameSave(): boolean {
    return this.mode === DataMode.SALESGROUP;
  }

  onCancel(): void {
    this.drawerRef?.close();
  }

  onSave(): void {
    console.log('onSave');
    const param = new FnaSystemSave();
    param.id = this.fnaInfo.id;
    param.accountName = this.fnaInfo.accountName;
    param.trAccountName = this.fnaInfo.trAccountName;
    param.customerType = this.fnaInfo.customerType;
    param.customerSource = this.fnaInfo.customerSource;
    console.log(param, 'basicInformation');
    this.newFnaService.updateFnaSystem(param)
      .subscribe(data => {
        this.systemSaveChange.emit(data);
      });
  }
}
