import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {TimeLineComponent} from '../../shared/component/time-line/time-line.component';
import {DecimalPipe} from '@angular/common';
import {
  DataDesensitization,
  DataMode,
  Forms,
  Material, PendingDetailReply,
  PendingReply,
  PendingDetail,
  SignatureList,
  Underwriting, UnderwritingAttachmentReq,
  UnderwritingDetailResp,
  UploadTokenReq, UploadType, MessageType, MessageSubType, Instance
} from '../../api/types';
import {UnderwritingService} from '../../underwriting/underwriting.service';
import {Policy, PolicyInfo} from '../policy-types';
import {PolicyService} from '../policy.service';
import {MetadataService} from '../../shared/service/metadata.service';
import { WorkbenchService } from '../../workbench/workbench.service';
import { Workbench } from '../../workbench/workbench-types';
import { Tool } from '../../shared/utils/tool';
import { MessageService } from '../../message/message.service';
import { MessageRemind } from '../../message/message-types';

@Component({
  selector: 'app-policy-detail',
  templateUrl: './policy-detail.component.html',
  styleUrls: ['./policy-detail.component.less'],
  providers: [DecimalPipe]
})
export class PolicyDetailComponent implements OnInit {

  @ViewChild('timeLint')
  timeLine: TimeLineComponent;
  @ViewChild('pendTimeLint')
  pendTimeLint: TimeLineComponent;

  formPdf: Uint8Array;
  loading = false;
  formVisible = false;
  policyDetail: PolicyInfo;
  policy: Policy;
  messageRemind: MessageRemind = new MessageRemind();

  // 预约附件上传参数
  uploadTokenReq = new UploadTokenReq();
  penddingDetail: PendingDetail = new PendingDetail();
  pendingReply: PendingReply[] = [];
  formItem: SignatureList;
  formList: SignatureList[];
  time: Date = new Date();
  premiumAndLevy: string;
  workbench: Workbench;
  @Input()
  mode = DataMode.OWN;
  selectIndex = 0;
  attachmentAuth = {
    display: true,
    readonly: true
  };
  instance: Instance = new Instance();
  dataDesensitization: DataDesensitization = new DataDesensitization();
  constructor(private policyService: PolicyService,
              private currencyPipe: DecimalPipe,
              private metadataService: MetadataService,
              private workbenchService: WorkbenchService,
              private messageService: MessageService,
              private underwritingService: UnderwritingService) {
  }

  ngOnInit(): void {
    this.instance = this.workbenchService.mockInstance;
    this.uploadTokenReq.uploadType = UploadType.POLICY;
    this.uploadTokenReq.middleName = this.policy.policyId;
    const statusList = this.metadataService.values('currency');
    for (const i in statusList) {
      if (this.policyDetail.productPlan.currency === statusList[i].key) {
        this.premiumAndLevy = this.currencyPipe.transform(this.policyDetail.initialPremium || 0) + statusList[i].value;
      }
    }
    this.workbench = this.workbenchService.workbench;
    this.viewAttachments();
    this.sendMesseage();
  }

  // onAttachmentsChange(attachments: Material[]): void {
  //   const attachmentReq = new UnderwritingAttachmentReq();
  //   attachmentReq.underwritingNo = this.policy.policyId;
  //   attachmentReq.attachments = attachments;
  //   this.loading = true;
  //   this.policyService.updateAttachments(attachmentReq)
  //     .subscribe(
  //       data => {
  //         this.loading = false;
  //         this.loadUnderwritingDetail(this.policy.policyId);
  //       },
  //       error => {
  //         this.loading = false;
  //       });
  //
  // }

  loadUnderwritingDetail(UnderwritingNo: string): void {
    this.loading = true;
    // this.underwritingService.detail(UnderwritingNo)
    //   .subscribe(
    //     underwritingDetail => {
    //       this.loading = false;
    //       const underwriting = underwritingDetail.toUnderwriting();
    //       this.underwritingDetail = underwritingDetail;
    //       this.underwriting = underwriting;
    //     },
    //     error => {
    //       this.loading = false;
    //     });
  }


  onPendingDetail($event: number): void {
    console.log($event);
    const id = $event;
    this.policyService.serviceDetail(id)
      .subscribe(
        pendDetail => {
          this.penddingDetail = pendDetail;
        });
    this.policyService.serviceReply(id)
      .subscribe(
        pendReply => {
          this.pendingReply = pendReply;
        });
  }

  onUnderwritingPendingDetail($event: number): void {
    console.log($event);
    const id = $event;
    this.underwritingService.pendingDetail(id)
      .subscribe(
        pendDetail => {
          this.penddingDetail = pendDetail;
        });
    this.underwritingService.pendingReply(id)
      .subscribe(
        pendReply => {
          this.pendingReply = pendReply;
        });
  }

  LoadingFormList(item: Forms): void {
    this.policyService.fromList(this.policy.policyId, this.policy.applicationType).subscribe(
      list => {
        console.log(list);
        list.forEach(e => {
          if (e.bookingFormId === item.formId) {
            this.formItem = e;
          }
        });

      }
    );

  }

  onPenddingReplyDetail($event: PendingDetailReply): void {
    this.policyService.pendingDetailReply($event)
      .subscribe(
        data => {
          this.timeLine.reast();
        }
      );
  }

  underwritingReply($event): void {
    this.underwritingService.pendingDetailReply($event)
      .subscribe(
        data => {
          this.onUnderwritingPendingDetail($event.pendingId);

        }
      );
  }

  onPendReplyDetail($event: PendingDetailReply): void {
    this.policyService.pendingDetailReply($event)
      .subscribe(
        data => {
          this.pendTimeLint.reast();
        }
      );
  }

  onFormDetail(i: Forms): void {
    this.LoadingFormList(i);
    this.policyService.downloadPdf(this.policy.policyId, i.formId).subscribe(
      byteArray => {
        this.formPdf = byteArray;
      }
    );
    this.formVisible = true;
  }

  formClose(): void {
    this.formVisible = false;
  }

  onPreviewNormalResult(): void {
    const url = window.URL.createObjectURL(new Blob([this.formPdf], {type: 'application/pdf'}));
    window.open(url);
  }

  onDownloadNormalResult(): void {
    const a = document.createElement('a');
    a.download = this.formItem.bookingFormName + '.pdf';
    a.href = window.URL.createObjectURL(new Blob([this.formPdf], {type: 'application/pdf'}));
    a.click();
  }

  get isShowBeneficiary(): boolean {
    const instance = this.policyService.mockInstance;
    return !(instance && instance.groupKey === 'fone');
  }

  // 权限方法 先别删
  // firstLevelAuthority(): void {
  //   switch (this.mode) {
  //     case DataMode.SUBORDINATE:
  //       if (this.workbench.childAttachmentUpload === 0) {
  //         this.secondPower.display = false;
  //       }
  //       break;
  //     case DataMode.FOLLOWUP:
  //       if (this.workbench.followAttachmentUpload === 0) {
  //         this.secondPower.display = false;
  //       }
  //       break;
  //     case DataMode.SALESGROUP:
  //       if (this.workbench.teamAttachmentUpload === 0) {
  //         this.secondPower.display = false;
  //       }
  //       break;
  //   }
  // }
  // accessories(): void {
  //   if (this.workbench.policyAttachmentUploadNew === 0) {
  //     // 全部禁止
  //     this.secondPower.display = false;
  //     return ;
  //   }
  //   if (this.workbench.policyAttachmentUploadNew === 2) {
  //     this.secondPower.display = true;
  //     this.secondPower.readonly = true;
  //     // 附件只读
  //     // 二级权限
  //     this.firstLevelAuthority();
  //     return ;
  //   }
  //   if (this.workbench.policyAttachmentUploadNew === 1) {
  //     switch (this.mode) {
  //       case DataMode.SUBORDINATE:
  //         if (this.workbench.childAttachmentUpload === 0) {
  //           this.secondPower.display = false;
  //         }
  //         if (this.workbench.childAttachmentUpload === 2) {
  //           this.secondPower.display = true;
  //           this.secondPower.readonly = true;
  //         }
  //         if (this.workbench.childAttachmentUpload === 1) {
  //           this.secondPower.display = true;
  //           this.secondPower.readonly = true;
  //         }
  //         break;
  //       case DataMode.FOLLOWUP:
  //         if (this.workbench.followAttachmentUpload === 0) {
  //           this.secondPower.display = false;
  //         }
  //         if (this.workbench.followAttachmentUpload === 2) {
  //           this.secondPower.display = true;
  //           this.secondPower.readonly = true;
  //         }
  //         if (this.workbench.followAttachmentUpload === 1) {
  //           this.secondPower.display = true;
  //           this.secondPower.readonly = true;
  //         }
  //         break;
  //       case DataMode.SALESGROUP:
  //         if (this.workbench.teamAttachmentUpload === 0) {
  //           this.secondPower.display = false;
  //         }
  //         if (this.workbench.teamAttachmentUpload === 2) {
  //           this.secondPower.display = true;
  //           this.secondPower.readonly = true;
  //         }
  //         if (this.workbench.teamAttachmentUpload === 1) {
  //           this.secondPower.display = true;
  //           this.secondPower.readonly = true;
  //         }
  //         break;
  //     }
  //   }
  // }

  viewAttachments(): void {

    this.attachmentAuth = {
      display: true,
      readonly: true
    };

    switch (this.mode) {
      case DataMode.OWN:
        if (this.workbench.policyAttachmentUploadNew === 0) {
          this.attachmentAuth.display = false;
        }
        if (this.workbench.policyAttachmentUploadNew === 2) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = true;
        }
        if (this.workbench.policyAttachmentUploadNew === 1) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = true;
        }
        break;
      case DataMode.SUBORDINATE:
        if (this.workbench.childAttachmentUpload === 0) {
          this.attachmentAuth.display = false;
        }
        if (this.workbench.childAttachmentUpload === 2) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = true;
        }
        if (this.workbench.childAttachmentUpload === 1) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = true;
        }
        break;
      case DataMode.FOLLOWUP:
        if (this.workbench.followAttachmentUpload === 0) {
          this.attachmentAuth.display = false;
        }
        if (this.workbench.followAttachmentUpload === 2) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = true;
        }
        if (this.workbench.followAttachmentUpload === 1) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = true;
        }
        break;
      case DataMode.SALESGROUP:
        if (this.workbench.teamAttachmentUpload === 0) {
          this.attachmentAuth.display = false;
        }
        if (this.workbench.teamAttachmentUpload === 2) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = true;
        }
        if (this.workbench.teamAttachmentUpload === 1) {
          this.attachmentAuth.display = true;
          this.attachmentAuth.readonly = true;
        }
        break;
    }
  }

  isDynamicEncryption(keyName): boolean {
    let isShow = false;
    if (!this.dataDesensitization || !this.mode) {
      return isShow;
    }
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    }
  }

  dynamicEncryptionText(keyName, val): string {
    let text = val;
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName && item.mask) {
          text = Tool.dataMasking(val);
        }
      });
      return text;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName && item.mask) {
          text = Tool.dataMasking(val);
        }
      });
    }
    return text;
  }

  get isShowItem(): boolean {
    const instance = this.policyService.mockInstance;
    return instance?.groupKey === 'cash' || instance?.groupKey === 'dev';
  }


  tabsIndex(e): void {
    this.selectIndex = e.index;
    this.sendMesseage();
  }

  sendMesseage(): void{
    const params = {
      key: this.policy.policyId,
      type: MessageType.POLICY,
      subType: undefined,
    };
    switch (this.selectIndex) {
      case 0:
        params.subType = MessageSubType.STATUS;
        break;
      case 1:
        params.subType = MessageSubType.INFO;
        break;
      case 2:
        params.subType = MessageSubType.ATTACHMENT;
        break;
      case 3:
        params.subType = MessageSubType.SERVICE;
        break;
    }
    this.messageService.putMessageReads(params)
      .subscribe(
        () => {
          this.getMessageRemind();
        }
      );
  }

  getMessageRemind(): void {
    const param = {
      type: MessageType.POLICY,
      key: this.policy.policyId
    };
    this.messageService.getMessageRemind(param)
      .subscribe(
        data => {
          this.messageRemind = data;
        },
        error => {}
      );
  }
}
