import {Injectable} from '@angular/core';
import {HttpService} from '../core/http.service';
import {
  BillDetailsRes,
  BillPageResp,
  CommissionList, InfoDetails,
  InfoList,
  IntroductionFeeDetails,
  NewCommissionList, UploadList
} from './commission-types';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResponse, PagedResp} from '../api/types';
import {HttpParams} from '@angular/common/http';
import {BaseService} from '../base/base.service';
import {API} from '../api/api';
import {map} from 'rxjs/operators';
import { GroupDialog } from '../booking/booking-types';

@Injectable({
  providedIn: 'root'
})
export class CommissionService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  /**
   * commission列表
   * @param list 请求参数
   * @param isSubordinate 是否我的下线数据
   */
  // list(list: CommissionList, isSubordinate: boolean): Observable<PagedResp<CommissionListResp>> {
  //   let httpParams = new HttpParams();
  //   if (list.pageNum != null) {
  //     httpParams = httpParams.set('pageNum', list.pageNum.toString());
  //   }
  //   if (list.pageSize != null) {
  //     httpParams = httpParams.set('pageSize', list.pageSize.toString());
  //   }
  //   const uri = isSubordinate ? '/bill-mine-list' : '/bill-subordinate-list';
  //   return this.http.get<ApiResponse<PagedResp<CommissionListResp>>>(API.commissionV3 + uri, {
  //     params: httpParams,
  //     observe: 'response'
  //   }).pipe(map(data => {
  //     return data.body.data;
  //   }));
  // }

  /**
   * 介绍费详情
   * @param id 介绍费ID
   */
  // info(id: number): Observable<CommissionDetailsRes> {
  //   return this.http.get<ApiResponse<CommissionDetailsRes>>(API.commissionV3 + '/bill-mine-info/' + id, {
  //     observe: 'response'
  //   }).pipe(map(data => {
  //     return data.body.data;
  //   }));
  // }

  /**
   * 介绍费确认
   * @param id 介绍费ID
   */
  // commissionConfirm(id: number): Observable<boolean> {
  //   return this.http.put<ApiResponse<boolean>>(API.commissionV3 + '/bill-confirm/' + id, null, {
  //     observe: 'response'
  //   }).pipe(map(data => {
  //     return data.body.data;
  //   }));
  // }

  /**
   * 查询介绍费详情
   * @param id 介绍费ID
   */
  searchInfo(id: number): Observable<IntroductionFeeDetails> {
    return this.http.get<ApiResponse<IntroductionFeeDetails>>(API.commissionV3 + '/bill-detail/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * .查询我的介绍费列表
   * @param list 请求参数
   * @param isSubordinate 是否我的下线数据
   */
  newList(list: NewCommissionList, isSubordinate: boolean): Observable<PagedResp<BillPageResp>> {
    let httpParams = new HttpParams();
    if (list.pageNum != null) {
      httpParams = httpParams.set('pageNum', list.pageNum.toString());
    }
    if (list.pageSize != null) {
      httpParams = httpParams.set('pageSize', list.pageSize.toString());
    }
    if (list.billDate != null) {
      httpParams = httpParams.set('billDate', list.billDate.toString());
    }
    if (list.policyNo != null) {
      httpParams = httpParams.set('policyNo', list.policyNo.toString());
    }
    const uri = isSubordinate ? '/bill-page' : '/bill-subordinate-page';
    return this.http.get<ApiResponse<PagedResp<BillPageResp>>>(API.newCommissionV3 + uri, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * ..查询介绍费详情列表
   * @param list 请求参数
   * @param isSubordinate 是否我的下线数据
   */
  infoList(list: InfoList, isSubordinate: boolean): Observable<BillDetailsRes> {
    let httpParams = new HttpParams();
    if (list.billDate != null) {
      httpParams = httpParams.set('billDate', list.billDate.toString());
    }
    if (list.paidDate != null) {
      httpParams = httpParams.set('paidDate', list.paidDate.toString());
    }
    if (list.policyNo != null) {
      httpParams = httpParams.set('policyNo', list.policyNo.toString());
    }
    if (list.companyCode != null) {
      httpParams = httpParams.set('companyCode', list.companyCode.toString());
    }
    if (list.status != null) {
      httpParams = httpParams.set('status', list.status.toString());
    }
    const uri = isSubordinate ? '/bill-info' : '/bill-subordinate-info';
    return this.http.get<ApiResponse<BillDetailsRes>>(API.newCommissionV3 + uri, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 查询介绍费详情
   * @param id 介绍费ID
   */
  detailInfo(id: number): Observable<InfoDetails> {
    return this.http.get<ApiResponse<InfoDetails>>(API.newCommissionV3 + '/bill-detail/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 批量介绍费确认
   * @param param ->
   * billDate: 账单日期
   * type: 类型 MY：我的；SUBORDINATE：我下线的
   */
  billBatchConfirm(param): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newCommissionV3 + '/bill-batch-confirm', param, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 介绍费确认
   * @param id -> billId 介绍费ID
   */
  billConfirm(id): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newCommissionV3 + '/bill-confirm/' + id, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * .上传附件
   * @param dialogRep 参数
   */
  billUpload(dialogRep: UploadList): Observable<boolean> {
    return this.http.post<ApiResponse<boolean>>(API.newCommissionV3 + '/bill-upload', dialogRep, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * .修改附件
   * @param dialogRep 参数
   */
  billEditFile(dialogRep: UploadList): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newCommissionV3 + '/bill-material-update', dialogRep, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * .修改附件
   * @param dialogRep 参数
   */
  billDeleteFile(dialogRep: UploadList): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.newCommissionV3 + '/bill-material-del', dialogRep, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
