<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px ;font-weight: bold">
    {{'TicketDetail' | translate}}
  </div>
  <div style="display: flex; flex-direction: row; align-items: end; margin-bottom: 10px">
    <div
      style="flex: 1; font-weight: bold; font-size: 18px; color: #404040; overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
      {{ 'TicketTitle' | translate}}: {{info.title}}</div>
    <button *ngIf="info.status == 'PROCESSED'"
            nz-button nzType="default"
            appThrottleClick (throttleClick)="restartProblem()">{{'RestartProblem' | translate}}</button>
  </div>
  <!--  内容回填-->
  <div class="ticket-reply">
    <div *ngFor="let item of info.replies" class="list-item">
      <div class="list-top">
        <nz-avatar class="list-top-avatar" style="margin-right: 20px;" [nzSize]="36" [nzSrc]="item.avatar"></nz-avatar>
        <div class="list-top-right">
            <div style="margin-right: 10px;display: flex;align-items: center;">
              <span class="list-name">{{ item.accountName }}</span>
              <span class="list-top-my-reply" *ngIf="item.myReply">{{ 'Oneself' | translate }}</span>
            </div>
          <div class="list-top-name" style="margin-right: 10px;">{{ item.addTime | date:'yyyy-MM-dd HH:mm:ss' }}</div>
        </div>
      </div>
      <div class="list-bottom">
        <div class="ticket-reply-content" [innerHTML]="item.content | html"></div>
        <app-attachments *ngIf="item.attachments?.length > 0"
                         [attachments]="item.attachments"
                         [readonly]="true"></app-attachments>
      </div>
    </div>
  </div>
  <!--  回复内容-->
  <div class="" >
    <div nz-row style="width: 100%;">
      <div nz-col nzSpan="24">
        <div class="section-wrapper" style="margin: 15px 0px 10px 0px">
          <app-property-editor [bottomLineVisible]="false"
                               [required]="true"
                               [title]="'Content' | translate"
                               [placeholder]="('PleaseEnter' | translate) + ('ReplyContent' | translate)"
                               [(value)]="ticketReply.content"></app-property-editor>
<!--          <app-property-input-area [bottomLineVisible]="false"-->
<!--                                   [title]="'Content' | translate"-->
<!--                                   [required]="true"-->
<!--                                   [placeholder]="('PleaseEnter' | translate) + ('ReplyContent' | translate)"-->
<!--                                   [(value)]="ticketReply.content"></app-property-input-area>-->
        </div>
      </div>
      <div nz-col nzSpan="24" style="padding: 0px 0px">
        <app-attachments [attachments]="ticketReply.attachments"
                         [uploadTokenReq]="uploadTokenReq"
                         [isPreview]="true"
                         (valueChange)="replyAttachmentsChange($event)"
                         [showEmpty]="false"></app-attachments>
      </div>
      <div nz-col nzSpan="24" class="footer-button-primary" style="height: 50px; border-radius: 10px; margin-top: 15px">
        <a nz-button nzType="link" nzBlock nzSize="large" nzShape="round"
           appThrottleClick (throttleClick)="reply()"
           [disabled]="!canReply"
           [style]="!canReply ? 'opacity: 0.5' : ''">
          <span>{{ 'Reply' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
