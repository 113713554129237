<nz-layout>
  <nz-content>
    <div class="inner-content">
      <div class="inner-content-header">
        <button nz-button nzType="default"
                appThrottleClick (throttleClick)="onBack('Back')">
          <img alt="" width="20" src="assets/images/ic_left.svg">
          {{'ReturnBtn' | translate}}
        </button>
        <div *ngIf="!viewForm" class="steps-text">
          <div class="steps-box"
               appThrottleClick (throttleClick)="indexChange(1)">
            <div class="steps-item"
                 [class]="fnaInfo.basicStatus === 'COMPLETED' ? 'green' : ''"
                 [style]="currentIndex === 1 ? 'background: #0078D4;' : ''">1</div>
            <div class="span-text"
                 [style]="currentIndex === 1 ? 'opacity: 1' : ''">{{'BasicInformationCMG' | translate}}</div>
          </div>
          <div class="item-title">
            <div class="item-title-right">
              <i nz-icon nzType="right" nzTheme="outline"></i>
            </div>
          </div>
          <div class="steps-box"
               appThrottleClick (throttleClick)="indexChange(2)">
            <div class="steps-item"
                 [class]="fnaInfo.fnaStatus === 'COMPLETED' ? 'green' : ''"
                 [style]="currentIndex === 2 ? 'background: #0078D4;' : ''">2</div>
            <div class="span-text"
                 [style]="currentIndex === 2 ? 'opacity: 1' : ''">{{('FNAQuestions' | translate)}}</div>
          </div>
          <div class="item-title">
            <div class="item-title-right">
              <i nz-icon nzType="right" nzTheme="outline"></i>
            </div>
          </div>
          <div class="steps-box"
               appThrottleClick (throttleClick)="indexChange(3)">
            <div class="steps-item"
                 [class]="fnaInfo.productStatus === 'COMPLETED' ? 'green' : ''"
                 [style]="currentIndex === 3 ? 'background: #0078D4;' : ''">3</div>
            <div class="span-text"
                 [style]="currentIndex === 3 ? 'opacity: 1' : ''">{{'ProductsIntroduction' | translate}}</div>
          </div>
          <div class="item-title">
            <div class="item-title-right">
              <i nz-icon nzType="right" nzTheme="outline"></i>
            </div>
          </div>
          <div class="steps-box"
               appThrottleClick (throttleClick)="indexChange(4)">
            <div class="steps-item"
                 [class]="fnaInfo.signatureStatus === 'SIGNED' ? 'green' : ''"
                 [style]="currentIndex === 4 ? 'background: #0078D4;' : ''">4</div>
            <div class="span-text"
                 [style]="currentIndex === 4 ? 'opacity: 1' : ''">{{'ESignatureCMG' | translate}}</div>
          </div>
          <div class="item-title">
            <div class="item-title-right">
              <i nz-icon nzType="right" nzTheme="outline"></i>
            </div>
          </div>
          <div class="steps-box"
               appThrottleClick (throttleClick)="indexChange(5)">
            <div class="steps-item"
                 [class]="fnaInfo.categoryNos && fnaInfo.categoryNos.length ? 'green' : ''"
                 [style]="currentIndex === 5 ? 'background: #0078D4;' : ''">5</div>
            <div class="span-text"
                 [style]="currentIndex === 5 ? 'opacity: 1' : ''">{{'FormsSelection' | translate}}</div>
          </div>
        </div>
        <button nz-button nzType="default"
                appThrottleClick (throttleClick)="onBack('Close')">{{'Close' | translate}}</button>
      </div>
      <nz-alert nzType="warning"
                style="margin: 0 32px;"
                *ngIf="!!fnaInfo.underwritingNo && currentIndex < 5"
                [nzMessage]="messageText">
        <ng-template #messageText>
          <div style="text-align: center;font-size: clamp(12px, 1.4vw, 14px);color: #333333;font-weight: 600;">
            {{ 'DisabledUnderwritingWarningTips' | translate }}
          </div>
        </ng-template>
      </nz-alert>

      <nz-alert nzType="warning"
                style="margin: 0 32px;"
                *ngIf="(!fnaInfo.underwritingNo && fnaInfo.signatureStatus === 'SIGNED') && currentIndex < 4"
                [nzMessage]="messageText">
        <ng-template #messageText>
          <div style="text-align: center;font-size: clamp(12px, 1.4vw, 14px);color: #333333;font-weight: 600;">
            {{ ('DisabledTips' | translate) }}
          </div>
        </ng-template>
      </nz-alert>

      <div class="inner-scrollbar" id="content" style="margin-top: 14px;height: calc(100vh - 240px);overflow: auto;">
        <nz-spin [nzSpinning]="loading">
          <app-piba-basic-info *ngIf="currentIndex === 1" [fnaInfo]="fnaInfo"></app-piba-basic-info>
          <app-piba-form-template *ngIf="currentIndex === 2"
                                 [fnaInfo]="fnaInfo"></app-piba-form-template>
          <app-piba-template-introduction *ngIf="currentIndex === 3"
                                         [fnaInfo]="fnaInfo"></app-piba-template-introduction>
          <app-piba-template-signature *ngIf="currentIndex === 4"
                                      [fna]="fnaInfo"
                                      (signedInfo)="onSaveSignature($event)"
                                      (resignedInfo)="onDetail(fnaInfo.id)">
          </app-piba-template-signature>
          <app-piba-form-selected *ngIf="currentIndex === 5"
                                 [fnaInfo]="fnaInfo"></app-piba-form-selected>
        </nz-spin>
      </div>

      <div class="modal-footer">
        <button *ngIf="viewForm ? false : !(fnaInfo.signatureStatus == 'SIGNED' || fnaInfo.underwritingNo)" nz-button
                nzType="primary" appThrottleClick (throttleClick)="onSave()"
                style="">{{'Save' | translate}}</button>

        <button *ngIf="currentIndex === 5 && (fnaInfo.signatureStatus == 'SIGNED' || fnaInfo.underwritingNo)" nz-button
                nzType="primary" appThrottleClick (throttleClick)="onSave6()"
                style="">{{'Save' | translate}}</button>

        <button *ngIf="currentIndex === 5"  nz-button nzType="primary" appThrottleClick
                (throttleClick)="onView('view')"
                style="margin-left: 20px;">{{'Preview' | translate}}</button>

        <button *ngIf="currentIndex === 5" nz-button nz-dropdown
                [nzDropdownMenu]="menu4"
                style="margin-left: 20px;">
          {{'Download' | translate}}
          <i nz-icon nzType="caret-up" nzTheme="outline"></i>
        </button>
        <nz-dropdown-menu #menu4="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item appThrottleClick (throttleClick)="onView('download')">{{'SinglePDF' | translate}}</li>
            <li nz-menu-item appThrottleClick (throttleClick)="onView('zip')">{{'MultiplePDFinOne' | translate}}</li>
          </ul>
        </nz-dropdown-menu>

        <button *ngIf="(!(fnaInfo.signatureStatus == 'SIGNED' || fnaInfo.underwritingNo) ? currentIndex != 5 : false)" style="margin-left: 20px;" nz-button nzType="primary"
                appThrottleClick (throttleClick)="onSaveNext()">{{'SaveAndContinue' | translate}}</button>
      </div>
    </div>
  </nz-content>
</nz-layout>
