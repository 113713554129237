import { Component, OnInit } from '@angular/core';
import { PagedResp, TicketDetail } from '../../api/types';
import { TicketOrderListResp, TicketOrderListSearch } from '../ticket-order-types';
import { TicketOrderService } from '../ticket-order.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { TicketOrderDetailComponent } from '../ticket-order-detail/ticket-order-detail.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TicketOrderCreateComponent } from '../ticket-order-create/ticket-order-create.component';
import { NewFnaSearch } from '../../new-fna/new-fna-types';
import { formatDate } from '@angular/common';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ticket-order-list',
  templateUrl: './ticket-order-list.component.html',
  styleUrls: ['./ticket-order-list.component.less']
})
export class TicketOrderListComponent implements OnInit {
  spinning = false;
  loading = false;
  filterPopoverVisible = false;
  ticketTable: PagedResp<TicketOrderListResp>;

  createdDates: null;
  replyDates: null;
  statusOptions: PropertySelectOption[] = [];

  search: TicketOrderListSearch = new TicketOrderListSearch();

  constructor(private ticketOrderService: TicketOrderService,
              private modalService: NzModalService,
              private translate: TranslateService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
    this.translate.get(['All', 'TicketListStatusProcessing', 'Replied', 'Close']).subscribe(
      (res: any) => {
        this.statusOptions?.push(new PropertySelectOption(res.All, null));
        this.statusOptions?.push(new PropertySelectOption(res.TicketListStatusProcessing, 'PENDING'));
        this.statusOptions?.push(new PropertySelectOption(res.Replied, 'REPLIED'));
        this.statusOptions?.push(new PropertySelectOption(res.Close, 'CLOSED'));
      });


    this.ticketOrderService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.ticketTable = data;
        },
        error => {
          this.loading = false;
        });
  }

  onResetSearch(): void {
    this.search = new TicketOrderListSearch();
    this.createdDates = null;
    this.replyDates = null;
    this.filterPopoverVisible = false;
    this.onReload();
  }

  onTicketCreate(): void {
    const drawerRef = this.drawerService.create<TicketOrderCreateComponent, { value: any }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: TicketOrderCreateComponent,
      nzContentParams: {},
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.ticketCreateRes.subscribe(
        data => {
          drawerRef.close();
          this.onReload();
        }
      );
    });
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.filterPopoverVisible = false;
    this.ticketOrderService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.ticketTable = data;
        },
        error => {
          this.loading = false;
        });
  }

  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.ticketOrderService.list(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.ticketTable = data;
        },
        error => {
          this.loading = false;
        });
  }


  onTicketDetail(id: number): void {
    this.loading = true;
    this.ticketOrderService.detail(id)
      .subscribe(DetailInfo => {
          this.loading = false;
          const drawerRef = this.drawerService.create<TicketOrderDetailComponent, { value: TicketDetail }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: TicketOrderDetailComponent,
            nzContentParams: {
              info: DetailInfo,
              id
            },
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });
          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();
            this.onReload(true);
          });
        },
        error => {
          this.loading = false;
        });
  }

  onClose(id: number): void {
    this.translate.get('CloseWorkOrderTips').subscribe(
      (res) => {
        this.modalService.warning({
          nzCentered: true,
          nzTitle: res,
          nzMaskClosable: false,
          nzClosable: false,
          nzOnOk: () => {
            this.ticketOrderService.close(id)
              .subscribe(x => {
                this.onSearch(this.search.pageNum);
              });
          }
        });
      }
    );
  }

  onDateChange(date, type): void {
    console.log(date);
    if (type === 'startCreateDate') {
      this.search.startCreateDate = date && date.length ? formatDate(date[0], 'yyyy-MM-dd', 'en-US') : null;
      this.search.endCreateDate = date && date.length ? formatDate(date[1], 'yyyy-MM-dd', 'en-US') : null;
    } else {
      this.search.startReplyDate = date && date.length ? formatDate(date[0], 'yyyy-MM-dd', 'en-US') : null;
      this.search.endReplyDate = date && date.length ? formatDate(date[1], 'yyyy-MM-dd', 'en-US') : null;
    }
  }

}
