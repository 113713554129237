import {Component, OnInit} from '@angular/core';
import {Frequency, Instance, Underwriting} from '../../../api/types';
import {PropertySelectOption} from '../../../shared/component/property-select/property-select.component';
import {MetadataService} from '../../../shared/service/metadata.service';
import {TranslateService} from '@ngx-translate/core';
import { Product } from '../../../product/product-types';

@Component({
  selector: 'app-underwriting-product-plan',
  templateUrl: './underwriting-product-plan.component.html',
  styleUrls: ['./underwriting-product-plan.component.less']
})
export class UnderwritingProductPlanComponent implements OnInit {
  underwriting: Underwriting;
  product: Product;
  instance: Instance = new Instance();


  currencyOptions: PropertySelectOption[] = [];
  paymentTermOptions: PropertySelectOption[] = [];
  paymentFrequencyOptions: PropertySelectOption[] = [];
  paymentTypeOptions: PropertySelectOption[] = [];
  dividendOptions: PropertySelectOption[] = [];

  constructor(private metadataService: MetadataService, private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.instance = this.metadataService.mockInstance;

    setTimeout(() => {
        if (this.product) {
          this.productSelected(this.product);
        }
      },
      100);

  }

  productSelected(product: Product): void {
    this.product = product;

    this.currencyOptions = [];
    this.product.currencies?.forEach(currency => {
      this.currencyOptions.push(new PropertySelectOption(currency, currency));
    });
    if (!this.underwriting.productPlan.currency) {
      this.underwriting.productPlan.currency = this.product.currencies ? this.product.currencies[0] : null;
    }

    this.paymentTermOptions = [];
    this.product.paymentTerm?.forEach(term => {
      this.paymentTermOptions.push(new PropertySelectOption(term, term));
    });
    if (!this.underwriting.productPlan.productYear) {
      this.underwriting.productPlan.productYear = this.product.paymentTerm ? this.product.paymentTerm[0] : null;
    }

    this.paymentFrequencyOptions = [];
    this.product.frequencies?.forEach(freuency => {
      this.paymentFrequencyOptions.push(new PropertySelectOption(this.paymentFrequencyTranslate(freuency), freuency));
    });
    if (!this.underwriting.productPlan.frequency) {
      this.underwriting.productPlan.frequency = this.product.frequencies ? this.product.frequencies[0] : null;
    }

    this.underwriting.productCode = product.productCode;
  }

  paymentFrequencyTranslate(frequency: Frequency): string {
    return this.metadataService.translate('frequency', frequency);
  }

  onProductSelection(): void {}

  addSafe(agm): string {
    if (!agm || !agm.length) {
      return 'N/A';
    } else {
      const res = agm.map(item => this.metadataService.translate('supplementaryBenefit', item));
      return res.join('');
    }
  }
}
