import {Expose, plainToClass, plainToInstance, Transform, Type} from 'class-transformer';
import {Credentials, PagedReq} from '../api/types';


export class InformationSearch extends PagedReq {
  @Expose() shareSources: string;
  @Expose() inSystem: boolean;

}

export class InformationList {
  @Expose() shareCode: string; // 分享唯一code
  @Expose() shareSources: string; // 分享来源
  @Expose() cover: string; // 封面
  @Expose() addTime: number; // 创建时间
  @Expose() accountName: string; // 账户名
  @Expose() browseNumber: string; // 浏览人数
  @Expose() title: string; // 标题
  @Expose() key: string; // 分享来源主键
  @Expose() tags: string[]; // 标签
  @Expose() frequency: number; // 分享次数
}

export class AppShareRecord {
  @Expose() shareCode: string; // 分享记录信息
  @Expose() shareSources: string;
  @Expose() frequency: number;
  @Expose() title: string;
  @Expose() accountName: string;
  @Expose() type: string;
  @Expose() addTime: string;
  @Expose() updateTime: string;
  @Expose() tags: string[];
  @Expose() key: string;
  @Expose() cover: string;
  @Expose() content: string;
  @Expose() attachments: Credentials[];
}

export class MessageVoList {
  @Expose() nickname: string;
  @Expose() headImgUrl: string;
  @Expose() content: string;
  @Expose() addTime: number;

}

class BrowseDynamics {
  @Expose() id: number;
  @Expose() nickname: string;
  @Expose() type: string;
  @Expose() headImgUrl: string;
  @Expose() platform: string;
  @Expose() addTime: number;
  @Expose() browseFrequency: number;
  @Expose() browseDuration: number;
  @Expose() shareFrequency: number;
  @Expose() browseLength: number;
}

export class BorwseInfo {
  @Expose() shareCode: string;
  @Expose() shareSources: string;
  @Expose() key: string;
  @Expose() title: string;
  @Expose() tags: string[];
  @Expose() cover: string;
  @Expose() nickname: number;
  @Expose() addTime: number;
  @Expose() frequency: number;
  @Expose() browseNumber: number;
  @Expose() browseFrequency: string;
  @Expose() browseDynamics: BrowseDynamics[];

}

/**
 * 分享记录详情
 */
export class InformationData {
  @Expose() appShareRecordVO: AppShareRecord;
  @Expose() messageVOList: MessageVoList[];
}

/**
 * 人脉详情
 */
export class ConnectionsInfo {
  @Expose() id: number;
  @Expose() nickname: string;
  @Expose() gender: string;
  @Expose() headImgUrl: string;
  @Expose() province: string;
  @Expose() city: string;
  @Expose() country: string;
  @Expose() type: string;
  @Expose() browseArticle: number;
  @Expose() browseFrequency: number;
  @Expose() shareFrequency: number;
  @Expose() articleTypeInfos: ArticleTypeInfos[];
  @Expose() browseHabitInfo: BrowseHabitInfo;
  @Expose() tagsInfos: TagsInfos[];
  @Expose() connectionsDynamics: ConnectionsDynamics[];
}

/**
 * 文章类型阅读详情
 */
export class ArticleTypeInfos {
  @Expose() type: string;
  @Expose() browseFrequency: number;
  @Expose() browseDuration: number;
}

/**
 * 阅读习惯
 */
export class BrowseHabitInfo {
  @Expose() morning: number;
  @Expose() forenoon: number;
  @Expose() noon: number;
  @Expose() afternoon: number;
  @Expose() evening: number;
  @Expose() night: number;
}

/**
 * 文章标签阅读详情
 */
export class TagsInfos {
  @Expose() duration: number;
  @Expose() frequency: number;
  @Expose() tag: string;
}

export class ConnectionsDynamics {
  @Expose() id: number;
  @Expose() shareCode: string;
  @Expose() connectionsId: number;
  @Expose() action: string;
  @Expose() frequency: number;
  @Expose() addTime: number;
  @Expose() updateTime: number;
  @Expose() browseLength: number;
  @Expose() content: string;

}

export class SeriesOption {
  @Expose() name: string;
  @Expose() symbolSize: number;
  @Expose() data: number[];
  @Expose() type: string;

  constructor(name: string, symbolSize: number, data: number[], type: string) {
    this.name = name;
    this.symbolSize = symbolSize;
    this.data = data;
    this.type = type;
  }
}
