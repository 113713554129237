import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { SignaturePadComponent } from '../signature-pad/signature-pad.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { PactSign } from '../../../sales-pact/sales-pact-types';
import { FnaSignedStatus } from '../../../fna/fna-types';
import { SignedData } from '../../../api/types';

export class SignaturePadOptions {
  minWidth: number;
  canvasWidth: number;
  canvasHeight: number;
  backgroundColor: string;
}
@Component({
  selector: 'app-form-signature',
  templateUrl: './form-signature.component.html',
  styleUrls: ['./form-signature.component.less'],
  providers: [DatePipe]
})
export class FormSignatureComponent implements OnInit {

  loading = false;
  id: number; // 合同id
  flag = true;
  imageBase64: string;
  agreed = false;
  signData: SignedData = new SignedData();

  @ViewChild(SignaturePadComponent)
  signaturePad: SignaturePadComponent;

  @Output()
  signed: EventEmitter<any> = new EventEmitter<any>();

  signaturePadOptions: SignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 728,
    canvasHeight: 340,
    backgroundColor: 'rgba(0,0,0,0)'
  };
  constructor(private modalService: NzModalService,
              private toastr: ToastrService,
              private translate: TranslateService,
              private datePipe: DatePipe) { }

  ngOnInit(): void {
  }
  onRewrite(tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzOnOk: () => {
      }
    });
  }

  get canConfirm(): boolean {
    if (this.signData.signedStatus === FnaSignedStatus.SIGNED) {
      return false;
    }
    if (this.loading) {
      return false;
    }
    return this.agreed;
  }

  get canRewrite(): boolean {
    if (this.signData.signedStatus === FnaSignedStatus.SIGNED) {
      return false;
    }
    return true;
  }

  // 声明
  showDisclaimerModal(disclaimerText: string, tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>): void {
    const ref = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: {
        value: disclaimerText
      }
    });
    ref.afterOpen.subscribe(() => {
      }
    );
  }

  onConfirm(): void {
    if (this.signData.signedStatus !== 'UNSIGNED') {
      return;
    }
    if (!this.canConfirm) {
      return;
    }
    this.loading = true;
    const imageBase = this.signaturePad.toDataURL();
    if (!this.signaturePad.toData().length) {
      this.loading = false;
      this.translate.get('UnsignedTip').subscribe(
        (res) => {
          this.toastr.warning(res);
        }
      );
      return;
    }
    this.loading = false;
    this.signed.emit(imageBase);
  }

  onRewriteConfirm(modelRef: NzModalRef, params: any): void {
    modelRef.destroy();
    this.signaturePad?.clear();
  }

}
