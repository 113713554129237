import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../product-types';
import { InformationDetailComponent } from '../../information/information-detail/information-detail.component';
import { InformationService } from '../../information/information.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-product-news',
  templateUrl: './product-news.component.html',
  styleUrls: ['./product-news.component.less']
})
export class ProductNewsComponent implements OnInit {

  @Input()
  product: Product;
  loading: boolean;

  constructor(private drawerService: NzDrawerService,
              private informationService: InformationService) { }

  ngOnInit(): void {
  }

  onInformationDetail(id: number): void {
    this.loading = true;
    this.informationService.info(id)
      .subscribe(
        information => {
          this.loading = false;
          const drawerRef = this.drawerService.create<InformationDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: InformationDetailComponent,
            nzContentParams: {
              information
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

}
