<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{ title }}
  </div>

  <div *ngIf="contingentOwner.relation != 'OWN' && objectType != 'SecondInsured'"
       appThrottleClick (throttleClick)="onContacts()">
    <a nz-button nzType="link">{{'importContacts' | translate}}</a>
  </div>
  <div class="section-wrapper">
    <!-- 与受保人关系 -->
    <app-property-select [title]="objectType === 'SecondInsured' ? ('RelationshipToHolder' | translate) : ('RelationshipToInsured' | translate)"
                         [placeholder]="('PleaseSelect' | translate) + (objectType === 'SecondInsured' ? ('RelationshipToHolder' | translate) : ('RelationshipToInsured' | translate))"
                         [options]="relationshipOptions"
                         [bottomLineVisible]="false"
                         [(value)]="contingentOwner.relation"></app-property-select>
  </div>

  <div *ngIf="contingentOwner.relation != 'OWN'" class="section-wrapper">
    <!-- 姓氏 -->
    <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LastName' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('LastName' | translate)"
                        [(value)]="contingentOwner.lastName"></app-property-input>
    <!-- 名字 -->
    <app-property-input [title]="'FirstName' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('FirstName' | translate)"
                        [(value)]="contingentOwner.firstName"></app-property-input>
    <!-- 姓氏-英文 -->
    <app-property-input *ngIf="!workbench.hiddenLastName" [title]="'LastNameInEnglish' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('LastNameInEnglish' | translate)"
                        [(value)]="contingentOwner.lastNamePinyin"></app-property-input>
    <!-- 名字-英文 -->
    <app-property-input [title]="'FirstNameInEnglish' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('FirstNameInEnglish' | translate)"
                        [(value)]="contingentOwner.firstNamePinyin"></app-property-input>
    <div *ngIf="!instance.isWDF() ? true : modeType !== 'booking'">
      <!-- 性别 -->
      <app-property-select [title]="'Gender' | translate"
                           [placeholder]="('PleaseSelect' | translate) + ('Gender' | translate)"
                           [options]="genderOptions"
                           [(value)]="contingentOwner.gender"></app-property-select>
      <!-- 生日 -->
      <app-property-datetime [title]="'DateOfBirth' | translate"
                             (valueChange)="getAge($event)"
                             [(value)]="contingentOwner.birthday"></app-property-datetime>
      <!-- 年龄 -->
      <app-property-text [title]="'Age' | translate"
                         [value]="birthdayDate"></app-property-text>
      <!-- 身份證號 -->
      <app-property-input [title]="'IdentityCardNumber' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('IdentityCardNumber' | translate)"
                          (valueChange)="getIdCardChange($event)"
                          [(value)]="contingentOwner.idCard"></app-property-input>
      <!-- 护照号码 -->
      <app-property-input [title]="'PassportNo' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('PassportNo' | translate)"
                          [bottomLineVisible]="false"
                          [(value)]="contingentOwner.passport"></app-property-input>
    </div>

  </div>
  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
<!--      <div nz-col nzSpan="12" class="footer-button">-->
<!--        <a nz-button nzType="link" nzBlock nzSize="large"-->
<!--           appThrottleClick (throttleClick)="onReset()">-->
<!--          <em nz-icon nzType="reload" nzTheme="outline"></em>-->
<!--          <span>{{'Reset' | translate}}</span>-->
<!--        </a>-->
<!--      </div>-->
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
