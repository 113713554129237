import { Component, OnInit } from '@angular/core';
import { Instance, Underwriting } from '../../../api/types';
import { MetadataService } from '../../../shared/service/metadata.service';

@Component({
  selector: 'app-underwriting-information',
  templateUrl: './underwriting-information.component.html',
  styleUrls: ['./underwriting-information.component.less']
})
export class UnderwritingInformationComponent implements OnInit {
  underwriting: Underwriting;
  instance: Instance;
  constructor(private metadataService: MetadataService) { }

  ngOnInit(): void {
    this.instance = this.metadataService.mockInstance;
  }

  isShowDDA(info): boolean {
    let res = true;
    if (this.instance.isFone()) {
      res = info.autoTransfer;
    }
    if (this.instance.isQuanBao() || this.instance.isBaylightOrDev()) {
      res = false;
    }
    return res;
  }

}
