<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{'Beneficiaries' | translate}}
  </div>
  <div style="display: flex; flex-direction: row; align-items: center">
    <div style="flex: 1"></div>
    <div>
      <a nz-button nzType="link" style="color: #4B84E5; font-size: 14px;" appThrottleClick (throttleClick)="onAddBeneficiary()">
        + {{'AddBeneficiary' | translate}}
      </a>
    </div>
  </div>
  <div class="section-wrapper" *ngFor="let beneficiary of beneficiaries; let i = index"
       style="display: flex; flex-direction: row; padding: 15px">
    <div *ngIf="!workbench.hiddenLastName" style="font-weight: bold">{{dynamicEncryption('name',beneficiary.lastName + '' + beneficiary.firstName)}}</div>
    <div *ngIf="workbench.hiddenLastName" style="font-weight: bold">{{dynamicEncryption('name', beneficiary.firstName)}}</div>
    <div style="flex: 1"></div>
    <div style="margin-left: 5px">
      <nz-tag *ngIf="(!instance.isWDF() && type !== 'underwriting') && beneficiary.gender">{{beneficiary.gender | metadataTranslate:'gender'}}</nz-tag>
      <nz-tag *ngIf="beneficiary.relation">{{beneficiary.relation | metadataTranslate:'relationship'}}</nz-tag>
      <nz-tag *ngIf="beneficiary.relation ==='OTHER'">{{beneficiary.otherRelationship}}</nz-tag>
      <nz-tag *ngIf="beneficiary.beneficiaryType">{{beneficiary.beneficiaryType | metadataTranslate: 'ownerType'}}</nz-tag>
      <nz-tag *ngIf="beneficiary.percentage">{{beneficiary.percentage}}%</nz-tag>
    </div>
    <div style="flex: 1"></div>
    <div class="functions">
      <a nz-button nzType="link" nzSize="small"
         appThrottleClick (throttleClick)="onEditBeneficiary(beneficiary, i); $event.stopPropagation();">
        <img alt="" width="20" src="assets/images/ic_details.svg">
      </a>
      <a nz-button nzType="link" nzSize="small"
         appThrottleClick (throttleClick)="onDelete(beneficiary, i, tplTitle, tplContent, tplFooter); $event.stopPropagation();">
        <img alt="" width="20" src="assets/images/ic_delete.svg">
      </a>
    </div>
  </div>

  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onSave()">
          <em nz-icon nzType="save" nzTheme="outline"></em>
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

</div>


<ng-template #tplTitle>
  <span>{{'DeleteBeneficiary' | translate}}</span>
</ng-template>
<ng-template #tplContent let-params>
  <p *ngIf="!workbench.hiddenLastName">{{'DoYouWantToDeleteBeneficiary' | translate}} :「{{ params.beneficiary?.lastName }} {{ params.beneficiary?.firstName }}」?</p>
  <p *ngIf="workbench.hiddenLastName">{{'DoYouWantToDeleteBeneficiary' | translate}} :「{{ params.beneficiary?.firstName }}」?</p>
</ng-template>
<ng-template #tplFooter let-ref="modalRef" let-params>
  <button nz-button appThrottleClick (throttleClick)="ref.destroy()">{{'Cancel' | translate}}</button>
  <button nz-button nzType="primary" appThrottleClick (throttleClick)="onDeleteConfirm(ref, params)">
    {{'Delete' | translate}}
  </button>
</ng-template>
