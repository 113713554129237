import { Component, OnInit } from '@angular/core';
import {ContractDetails, ContractList, ContractSearch} from '../../contract/contract-type';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {ContractService} from '../../contract/contract.service';
import {MetadataService} from '../../shared/service/metadata.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {formatDate} from '@angular/common';
import {ContractDetailComponent} from '../../contract/contract-detail/contract-detail.component';
import {SalesPactService} from '../sales-pact.service';
import {PactSearch, PactSearchRes} from '../sales-pact-types';
import {PagedResp} from '../../api/types';
import {SalesPactDetailsComponent} from '../sales-pact-details/sales-pact-details.component';

@Component({
  selector: 'app-sales-pact-table',
  templateUrl: './sales-pact-table.component.html',
  styleUrls: ['./sales-pact-table.component.less']
})
export class SalesPactTableComponent implements OnInit {
  spinning = false;
  listData: PagedResp<PactSearchRes>;
  search: PactSearch = new PactSearch();

  contractList: ContractList[];
  filterPopoverVisible = false;
  typeOptions: PropertySelectOption[] = [];
  EffectDates: number[];
  deadline: number[];

  constructor(private salesPactService: SalesPactService,
              private metadataService: MetadataService,
              private nz: NzDrawerService) {
  }

  ngOnInit(): void {
    this.typeOptions = [];
    const contractType = this.metadataService.values('signedStatus');

    for (const contract of contractType) {
      this.typeOptions.push(new PropertySelectOption(contract.value, contract.key));
    }
    this.spinning = true;
    this.salesPactService.pactList(this.search).subscribe(
      data => {
        this.listData = data;
        this.spinning = false;
      },
      error => {
        this.spinning = false;
      }
    );

    this.getList();

  }

  getList(): void {
    this.salesPactService.pactList(this.search)
      .subscribe(
        data => {
          this.listData = data;
        },
        error => {}
      );
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.openSearch();
  }

  onReload(resetPageNum = true): void {
    this.search = new PactSearch();
    this.spinning = true;
    this.salesPactService.pactList(this.search).subscribe(
      data => {
        this.listData = data;
        this.spinning = false;
      },
      error => {
        this.spinning = false;
      }
    );
  }

  openSearch(): void {
    this.spinning = true;
    this.salesPactService.pactList(this.search).subscribe(
      data => {
        this.listData = data;
        this.spinning = false;
      },
      error => {
        this.spinning = false;
      }
    );
  }

  // onSearchDateChange(time: number[]): void {
  //   console.log(time);
  //   if (time) {
  //     this.deadline = time;
  //     this.search.effectiveStartDate = formatDate(time[0], 'yyyy-MM-dd', 'en-US');
  //     this.search.effectiveEndDate = formatDate(time[1], 'yyyy-MM-dd', 'en-US');
  //   }
  //
  // }

  // onInspectDateChange(time: number[]): void {
  //   if (time) {
  //     this.EffectDates = time;
  //     this.search.invalidStartDate = formatDate(time[0], 'yyyy-MM-dd', 'en-US');
  //     this.search.invalidEndDate = formatDate(time[1], 'yyyy-MM-dd', 'en-US');
  //   }
  // }

  onDetail(list): void {
    this.salesPactService.info(list)
      .subscribe(
        data => {
          const drawerRef = this.nz.create<SalesPactDetailsComponent, { value: any }, string>({
            nzWidth: 800,
            nzContent: SalesPactDetailsComponent,
            nzContentParams: {
              info: data,
              id: list.id
            }
          });
          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
            component.afterRecord
                .subscribe(
                  value => {
                    drawerRef.close();
                    this.onReload();
                  }
                );
          });
          drawerRef.afterClose.subscribe(() => {
            this.onReload();
          });
        },
        error => {}
      );

  }
}
