<div [ngSwitch]="displayMode">
  <div *ngSwitchCase="'horizontal'">
    <div [class]="'property-row'">
      <span class="property-title-row" *ngIf="title">{{ title }}</span>
      <span class="property-value-row">
        <nz-tag *ngFor="let v of value">{{v}}</nz-tag>
      </span>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>
  <div *ngSwitchCase="'vertical'">
    <div [class]="'property-column'">
      <div class="property-title-column">
        <span *ngIf="title">{{ title }}</span>
      </div>
      <div class="property-value-column">
        <nz-tag *ngFor="let v of value">{{v}}</nz-tag>
      </div>
    </div>
    <div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
    <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
  </div>

</div>
