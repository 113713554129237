import {Injectable} from '@angular/core';

import {BaseService} from '../base/base.service';
import {HttpService} from '../core/http.service';
import {Observable} from 'rxjs/internal/Observable';
import {
  ApiResponse,
  ChangePassword, ChangePasswordRes, Country,
  GuideContent,
  Instance,
  LoginByAccountReq,
  LoginByCodeReq,
  Passport
} from '../api/types';
import {API} from '../api/api';
import {map} from 'rxjs/operators';
import { HttpContext, HttpParams } from '@angular/common/http';
import { plainToInstance } from 'class-transformer';
import { Product } from '../product/product-types';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  logout(): void {
    this.passport = null;
  }

  /**
   * 检查组织代码
   * @param orgCode 组织代码
   */
  checkInstance(orgCode: string): Observable<Instance> {
    return this.http.post<ApiResponse<Instance>>(API.gatewayV3 + '/check-instance', {code: orgCode}, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(Instance, data.body.data);
    }));
  }

  getGuideContent(): Observable<GuideContent[]> {
    return this.http.get<ApiResponse<GuideContent[]>>(API.accountV3 + '/get-guide-content', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  loginByAccount(req: LoginByAccountReq): Observable<Passport> {
    return this.http.post<ApiResponse<Passport>>(API.accountV3 + '/login', req, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(Passport, data.body.data);
    }));
  }

  loginByAccountAndInstance(req: LoginByAccountReq, instance: Instance, passport: Passport): Observable<Passport> {
    const context = this.http.memoryContext(instance, passport);
    return this.http.post<ApiResponse<Passport>>(API.accountV3 + '/login', req, {
      context,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  loginByCode(req: LoginByCodeReq): Observable<Passport> {
    return this.http.post<ApiResponse<Passport>>(API.accountV4 + '/login-by-code', req, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(Passport, data.body.data);
    }));
  }

  disclaimer(): Observable<string> {
    return this.http.get<ApiResponse<string>>(API.accountV3 + '/get-disclaimer', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 获取账号电话邮箱
   * @param change 账号
   */
  getPhoneEmail(change: ChangePassword): Observable<ChangePasswordRes> {
    let httpParams = new HttpParams();
    if (change.accountName != null) {
      httpParams = httpParams.set('accountName', change.accountName.toString());
    }
    return this.http.get<ApiResponse<ChangePasswordRes>>(API.accountV3 + '/get-phone-email', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 验证码
   * @param change 传参
   */
  getCode(change: ChangePassword): Observable<boolean> {
    let httpParams = new HttpParams();
    if (change.accountName != null) {
      httpParams = httpParams.set('accountName', change.accountName.toString());
    }
    if (change.type != null) {
      httpParams = httpParams.set('type', change.type.toString());
    }
    return this.http.get<ApiResponse<boolean>>(API.accountV3 + '/send-code', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 修改密码
   * @param change 传参
   */
  updatePwd(change: ChangePassword): Observable<boolean> {
    return this.http.put<ApiResponse<boolean>>(API.accountV3 + '/update-password', change, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  authLogin(authorizationCode: string): Observable<any> {
    return this.http.post<ApiResponse<any>>(API.open + '/auth/login', {authorizationCode}, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 修改密码
   * @param change 传参
   */
  changePwd(change: any): Observable<string> {
    return this.http.put<ApiResponse<string>>(API.accountV3 + '/change-password', change, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 手机号登录发送验证码
   * @param change 账号
   */
  getPhoneCode(change: any): Observable<string> {
    let httpParams = new HttpParams();
    if (change.areaCode != null) {
      httpParams = httpParams.set('areaCode', change.areaCode.toString());
    }
    if (change.phone != null) {
      httpParams = httpParams.set('phone', change.phone.toString());
    }
    return this.http.get<ApiResponse<string>>(API.accountV4 + '/code', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 检查组织代码
   * @param param // {areaCode phone code}
   */
  getPhoneLoginList(param: any): Observable<string[]> {
    return this.http.post<ApiResponse<string[]>>(API.accountV4 + '/login-list', param, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 通过账号和手机号码和验证码登录
   * @param param // {areaCode phone code accountName}
   */
  getPhoneLogin(param: any): Observable<Passport> {
    return this.http.post<ApiResponse<Passport>>(API.accountV4 + '/login-by-code-name', param, {
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(Passport, data.body.data);
    }));
  }


  /**
   * 根据国家名获取国家列表s
   */
  getCountryAll(): Observable<Country[]> {
    return this.http.get<ApiResponse<Country[]>>(API.accountV3 + '/country/all', {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 验证PIN码
   */
  verifyPin(code: any): Observable<boolean> {
    let httpParams = new HttpParams();
    if (code != null) {
      httpParams = httpParams.set('pinCode', code.toString());
    }
    return this.http.get<ApiResponse<boolean>>(API.accountV3 + '/verify-pin-code', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 设置PIN码发送验证码
   * @param change 账号
   */
  pinPhoneCode(change: any): Observable<boolean> {
    let httpParams = new HttpParams();
    if (change.areaCode != null) {
      httpParams = httpParams.set('areaCode', change.areaCode.toString());
    }
    if (change.phone != null) {
      httpParams = httpParams.set('phone', change.phone.toString());
    }
    return this.http.put<ApiResponse<boolean>>(API.accountV3 + '/pin-send-code', null, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 设置PIN码 校验 验证码
   * @param change code
   */
  pinVerifyCode(change: any): Observable<boolean> {
    let httpParams = new HttpParams();
    if (change != null) {
      httpParams = httpParams.set('code', change.toString());
    }
    return this.http.put<ApiResponse<boolean>>(API.accountV3 + '/verify-pin-phone-code', null, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 修改PIN码
   * @param change code
   */
  pinUpdateCode(change: any): Observable<boolean> {
    let httpParams = new HttpParams();
    if (change.code != null) {
      httpParams = httpParams.set('code', change.code.toString());
    }
    if (change.pinCode != null) {
      httpParams = httpParams.set('pinCode', change.pinCode.toString());
    }
    return this.http.put<ApiResponse<boolean>>(API.accountV3 + '/update-pin-code', null, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 设置PIN码
   * @param change code
   */
  pinSettingCode(change: any): Observable<boolean> {
    let httpParams = new HttpParams();
    if (change != null) {
      httpParams = httpParams.set('pinCode', change.toString());
    }
    return this.http.put<ApiResponse<boolean>>(API.accountV3 + '/pin-code-setting', null, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }
}
