<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{
      instance.isFone() ? ('MatterRecord' | translate)
        : type === 'underwriting' ? ('PendingRecord' | translate) : ('ServiceRecord' | translate)
    }}
  </div>
  <div class="flex" style="border-bottom: 1px solid #e3e3e3; padding-bottom: 10px">
    <div>
      <span class="text-processing">●</span>
      <span style="font-size: 16px;font-weight:bold"> {{ pendingDetail.status }}</span>
    </div>
    <div
      style="color: #999999;font-size: 12px">{{ 'Deadline' | translate }} {{ pendingDetail.deadlines | date:'yyyy-MM-dd' }}
    </div>
  </div>

  <div class="status-detail" style="border-bottom: 1px solid #e3e3e3">
    <div style="padding: 10px 15px 10px 15px">
      <div style="font-weight: bold; font-size: 16px; margin-bottom: 8px;">
        {{
          instance.isFone() ? ('ChangeProject' | translate)
            : type === 'underwriting' ? 'Pending' : 'Policy Service'
        }}{{ ' · ' + pendingDetail.project }}
      </div>
      <div class="flex" style="color: #999999;font-size: 12px">
        <span>{{
            instance.isFone() ? ('DeliveryDate' | translate)
              : type === 'underwriting' ? ('PendingDate' | translate) : ('ServiceDate' | translate)
          }} : {{ pendingDetail.deliveryDate | date:'yyyy-MM-dd' }}</span>
        <a nz-button nzType="link" nzSize="small"
           appThrottleClick (throttleClick)="openTitle()"
           style="color: #4B84E5; font-size: 14px;">
          {{ 'View' | translate }} >
        </a>
      </div>
      <div>
        <span>{{
            instance.isFone() ? ('TodoContent' | translate)
              : type === 'underwriting' ? ('PendingContent' | translate) : ('ServiceContent' | translate)
          }} : </span>
        <span [innerHTML]="pendingDetail.remark | html"></span>
      </div>
    </div>
  </div>
  <div class="status-detail">
    <div class="status-detail-context">
      <div *ngFor="let item of pendingReply" class="list-item">
        <div class="list-top">
          <nz-avatar class="list-top-avatar" style="margin-right: 20px;" [nzSize]="36"
                     [nzSrc]="item.avatar"></nz-avatar>
          <div class="list-top-right">
            <div style="margin-right: 10px;">
              <span class="list-top-name" style="margin-right: 10px;">{{ item.accountName }}</span>
              <span class="list-top-myReply"
                    *ngIf="item.accountName === accountName"
                    style="color: #4B84E5; font-size: 12px; border: 1px solid #4B84E5; border-radius: 6px">{{ 'Oneself' | translate }}</span>
            </div>
            <div class="list-top-name" style="margin-right: 10px;">{{ item.time | date:'yyyy-MM-dd HH:mm:ss' }}</div>
          </div>
        </div>
        <div class="list-bottom">
          <div class="ticket-reply-content" [innerHTML]="item.content | html"></div>
          <app-attachments *ngIf="item.attachments?.length > 0"
                           [attachments]="item.attachments"
                           [readonly]="true"></app-attachments>
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <div nz-row style="width: 100%;">
      <div nz-col nzSpan="24">
        <div class="section-wrapper" style="margin: 15px 0px 10px 0px">
          <!--          <app-property-input-area [bottomLineVisible]="false"-->
          <!--                                   [title]="'Content' | translate"-->
          <!--                                   [placeholder]="('PleaseEnter' | translate) + ('ReplyContent' | translate)"-->
          <!--                                   [(value)]="pendingDetailReply.content"></app-property-input-area>-->
          <app-property-editor [bottomLineVisible]="false"
                               [title]="'Content' | translate"
                               [placeholder]="('PleaseEnter' | translate) + ('ReplyContent' | translate)"
                               [(value)]="pendingDetailReply.content"></app-property-editor>
        </div>
      </div>
      <div nz-col nzSpan="24" style="padding: 0px 0px">
        <app-attachments [attachments]="pendingDetailReply.attachments"
                         [uploadTokenReq]="uploadTokenReq"
                         [isPreview]="true"
                         (valueChange)="pendingAttachmentsChange($event)"
                         [showEmpty]="false"></app-attachments>
      </div>
      <div nz-col nzSpan="24" class="footer-button-primary" style="height: 50px; border-radius: 10px; margin-top: 15px">
        <a nz-button nzType="link" nzBlock nzSize="large" nzShape="round" appThrottleClick (throttleClick)="reply()"
           [disabled]="!canReply"
           [style]="!canReply ? 'opacity: 0.5' : ''">
          <span>{{ 'Reply' | translate }}</span>
        </a>
      </div>
    </div>
  </div>

  <nz-drawer [(nzVisible)]="detailVisible" [nzWidth]="700" (nzOnClose)="detailClose()">
    <ng-container *nzDrawerContent>
      <div class="drawer-container">
        <div class="common-title" style="margin-bottom: 40px">
          {{
            instance.isFone() ? ('MatterDetail' | translate)
              : type === 'underwriting' ? ('PendingDetail' | translate) : ('ServiceDetail' | translate)
          }}
        </div>
        <div class="section-title">
          <span style="margin-left: 8px"> {{ 'IDNumber' | translate }}: {{ pendingDetail.pendingNo }} </span>
        </div>
        <div class="status-detail">
          <div class="status-detail-context">
            <nz-row [nzGutter]="12">
              <nz-col [nzSpan]="24">
                <app-property-text [title]="'Insurer' | translate"
                                   [displayMode]="'vertical'"
                                   [value]="pendingDetail.companyName"></app-property-text>
              </nz-col>
              <nz-col [nzSpan]="12">
                <app-property-text [title]="'Insured' | translate"
                                   [displayMode]="'vertical'"
                                   [isEncryption]="isDynamicEncryption('insuredName')"
                                   [value]="pendingDetail.insuredName"></app-property-text>
              </nz-col>

              <nz-col [nzSpan]="12">
                <app-property-text [title]="'Holder' | translate"
                                   [displayMode]="'vertical'"
                                   [isEncryption]="isDynamicEncryption('ownerName')"
                                   [value]="pendingDetail.ownerName"></app-property-text>
              </nz-col>
              <nz-col [nzSpan]="12">
                <app-property-text [title]="'Status' | translate"
                                   [displayMode]="'vertical'"
                                   [value]="pendingDetail.status"></app-property-text>
              </nz-col>
              <nz-col [nzSpan]="12">
                <app-property-text [title]="(instance.isFone() ? ('DeliveryDate' | translate)
              : type === 'underwriting' ? ('PendingDate' | translate) : ('ServiceDate' | translate))"
                                   [displayMode]="'vertical'"
                                   [value]="pendingDetail.deliveryDate | date: 'yyyy-MM-dd' "></app-property-text>
              </nz-col>
              <nz-col [nzSpan]="12">
                <app-property-text [title]="'ArchiveDate' | translate"
                                   [displayMode]="'vertical'"
                                   [value]="pendingDetail.archiveDate | date: 'yyyy-MM-dd' "></app-property-text>
              </nz-col>
              <nz-col [nzSpan]="12">
                <app-property-text [title]="'ExpirationDate' | translate"
                                   [displayMode]="'vertical'"
                                   [value]="pendingDetail.deadlines | date: 'yyyy-MM-dd' "></app-property-text>
              </nz-col>
              <nz-col [nzSpan]="12">
                <app-property-text [title]="'CaseCloseDate' | translate"
                                   [displayMode]="'vertical'"
                                   [value]="pendingDetail.finishDate | date: 'yyyy-MM-dd' "></app-property-text>
              </nz-col>
              <nz-col [nzSpan]="12">
                <app-property-text [title]="(instance.isFone() ? ('ChangeProject' | translate)
            : type === 'underwriting' ? 'Pending' : 'Policy Service')"
                                   [displayMode]="'vertical'"
                                   [value]="pendingDetail.project"></app-property-text>
              </nz-col>
              <nz-col [nzSpan]="12">
                <app-property-text [title]="'CreateUserName' | translate"
                                   [displayMode]="'vertical'"
                                   [value]="pendingDetail.organizer"></app-property-text>
              </nz-col>
              <nz-col [nzSpan]="12">
                <app-property-text [title]="'Administrator' | translate"
                                   [displayMode]="'vertical'"
                                   [value]="pendingDetail.receiver"></app-property-text>
              </nz-col>
              <nz-col [nzSpan]="24">
                <app-property-text [title]="(instance.isFone() ? ('TodoContent' | translate)
              : type === 'underwriting' ? ('PendingContent' | translate) : ('ServiceContent' | translate))"
                                   [displayMode]="'vertical'"
                                   [textType]="'html'"
                                   [bottomLineVisible]="false"
                                   [value]="pendingDetail.remark"></app-property-text>
              </nz-col>
            </nz-row>
          </div>
        </div>
        <div class="section-title"
             *ngIf="pendingDetail.attachments && pendingDetail.attachments.length">
          <span style="margin-left: 8px"> {{ 'File' | translate }}</span>
        </div>

        <app-attachments [attachments]="pendingDetail.attachments" [readonly]="true"
                         *ngIf="pendingDetail.attachments && pendingDetail.attachments.length">
        </app-attachments>
      </div>

    </ng-container>
  </nz-drawer>
</div>
