import {Component, TemplateRef, OnInit} from '@angular/core';
import {AmlSummaryList, AmlSearch, PagedResp, AmlSummary, AmlMonitorSummary} from '../../../api/types';
import {MetadataService} from '../../../shared/service/metadata.service';
import {PropertySelectOption} from '../../../shared/component/property-select/property-select.component';
import {AmlService} from '../../aml.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {NzModalService} from 'ng-zorro-antd/modal';
import {AmlMonitorEntryDetailComponent} from '../aml-monitor-entry-detail/aml-monitor-entry-detail.component';
import {AmlMonitorSummaryDetailComponent} from '../aml-monitor-summary-detail/aml-monitor-summary-detail.component';
import * as _ from 'lodash';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-aml-monitor-summary',
  templateUrl: './aml-monitor-summary.component.html',
  styleUrls: ['./aml-monitor-summary.component.less']
})
export class AmlMonitorSummaryComponent implements OnInit {

  loading = false;

  spinning = false;

  createTime: number;

  childrenVisible = false;

  filterPopoverVisible = false;


  constructor(
    private metadataService: MetadataService,
    private drawerService: NzDrawerService,
    private amlService: AmlService,
    private modalService: NzModalService
  ) {
  }

  amls: PagedResp<AmlSummaryList>;

  search: AmlMonitorSummary = new AmlMonitorSummary();

  typeOptions: PropertySelectOption[] = [];
  statusOptions: PropertySelectOption[] = [];

  ngOnInit(): void {
    this.loading = true;
    this.amlService.summaryList(this.search).subscribe(
      data => {
        this.loading = false;
        this.amls = data;

      },
      error => {
        this.loading = false;
      }
    );
    const amlRecordTypes = this.metadataService.values('amlRecordType');
    for (const amlRecordType of amlRecordTypes) {
      this.typeOptions.push(new PropertySelectOption(amlRecordType.value, amlRecordType.key));
    }
    const amlMonitorStatus = this.metadataService.values('amlMonitorStatus');

    for (const status of amlMonitorStatus) {
      this.statusOptions.push(new PropertySelectOption(status.value, status.key));
    }
  }

  onResetSearch(): void {
    this.search = new AmlMonitorSummary();
    this.createTime = null;
    this.onReload();
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.loading = true;
    this.amlService.summaryList(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.amls = data;
        },
        error => {
          this.loading = false;
        });
  }

  onTimeChange(timestamp: number): void {
    if (!timestamp) {
      return;
    }
    this.createTime = timestamp;
    const date = new Date(timestamp);
    this.search.createTime = formatDate(date, 'yyyy-MM-dd', 'en-US');
  }

  onAmlInspectDetail(id: number): void {
    this.loading = true;
    const params = new AmlSummary();
    params.summaryId = id;
    this.amlService.summaryDetail(params)
      .subscribe(info => {
          this.loading = false;
          const drawerRef = this.drawerService.create<AmlMonitorSummaryDetailComponent, { value: any }, string>({
            nzWidth: 1024,
            nzMaskClosable: true,
            nzOffsetX: this.childrenVisible ? 180 : 0,
            nzContent: AmlMonitorSummaryDetailComponent,
            nzContentParams: {
              info
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();

            this.childrenVisible = component.childrenVisible;

          });

          drawerRef.afterClose.subscribe(data => {
            const component = drawerRef.getContentComponent();

            this.childrenVisible = component.childrenVisible;
            if (component.stateModified) {
              this.onReload(false);
            }
          });
        },
        error => {
          this.loading = false;
        });
  }


  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search = new AmlMonitorSummary();
    }
    this.amlService.summaryList(this.search)
      .subscribe(
        data => {
          this.loading = false;
          this.amls = data;
        },
        error => {
          this.loading = false;
        });

  }

  onAmlDelete(row: any, tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>): void {
    this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzClosable: false,
      nzOnOk: () => {
        this.loading = true;
        this.amlService.deleteMonitorEntry(row.id, row.type).subscribe(
          data => {
            this.amlService.summaryList(this.search).subscribe(
              // tslint:disable-next-line:no-shadowed-variable
              data => {
                this.loading = false;
                this.amls = data;

              },
              error => {
                this.loading = false;
              }
            );
          },
          error => {
            this.loading = false;
          }
        );
      }
    });
  }

}
