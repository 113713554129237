import {Expose, Transform, Type} from 'class-transformer';
import {
  Currency,
  Frequency,
  Gender,
  I18n,
  Link,
  Material,
  MedicalLevel,
  MedicalOptions,
  PagedReq, Phone,
  ProductType,
  ProposalAmountType,
  Region,
  SellStatus,
  SmokeCondition, SumRangeResp,
  SupplementaryBenefit
} from '../api/types';
import {AgeRestriction, AmountRestriction, WithdrawalRestriction} from '../proposal/proposal-types';
import {plainToArray} from '../shared/utils/class-transform';
import {Information} from '../information/information-types';

export class ProductSearch extends PagedReq {
  categoryCode: string; // 保险分类唯一识别
  companyCode: string; // 所属公司唯一识别
  hot: boolean; // 是否热门
  productType: ProductType; // 产品类型: 基本计划-BASIC;附加计划-RIDER
  mainProductCode: string; // 主产品产品码
  comparable: boolean; // 是否可比对
  quotable: boolean; // 是否可试算
  productName: string; // 产品名称
  financialPlanSupported: boolean; // 是否支持規劃書
  sellStatus: SellStatus; // 销售状态: DISCONTINUED-停售;SELLING-在售'
  region: string; // 地区
}

export class RiderDynamicField {
  @Expose() productCode: string;
  @Transform((value) => plainToArray(DynamicField, value.value))
  @Expose() dynamicFields: DynamicField[];
}
export class DynamicField {
  @Expose() field: string;
  @Expose() fieldName: string;
  @Expose() fieldType: string;
  @Expose() fieldValue: string;
  @Expose() required: boolean; // 是否必填
  @Expose() isDisabled: boolean;
  @Expose() value: string;
  @Expose() remark: string;
  @Expose() options: any[]; // 下拉框数据fieldValue 前端自己处理
}

export class ProductListResp {
  @Expose() hot: boolean;
  @Expose() categoryCode: string;
  @Expose() categoryName: string;
  @Expose() companyCode: string;
  @Expose() companyName: string;
  @Expose() type: ProductType;
  @Expose() currencies: Currency[];
  @Expose() desc: string;
  @Expose() discontinuationDate: number;
  @Expose() features: ProductFeatures;
  @Expose() financialPlanSupported: boolean;
  @Expose() custom: boolean;
  @Expose() premiumOffsetSupported: boolean; // 是否保费扣除
  @Expose() frequencies: Frequency[];
  @Expose() launchDate: number;
  @Expose() logo: string;
  @Expose() medicalOptions: MedicalOptions;
  @Expose() medicalOptionsRequiredField: string[]; // 必填的医疗险选项
  @Expose() paymentTerm: string[];
  @Expose() policyPeriod: string;
  @Expose() productCode: string;
  @Expose() productName: string;
  @Expose() proposalLanguages: string[]; // 计划书支持语言
  @Expose() proposalRPASupported: boolean; // 计划书是否支持RPA
  @Expose() proposalFrequencySupported: boolean;  // 计划书是否支持付款频率
  @Expose() proposalPrepaymentSupported: boolean; // 是否支持预缴
  @Expose() proposalRiders: string[];
  @Transform((value) => plainToArray(QuotationFieldRequirement, value.value))
  @Expose() quotationFieldRequirements: QuotationFieldRequirement[];
  @Expose() sellStatus: SellStatus;
  @Expose() withdrawalable: boolean;
  @Expose() tags: string[];
  @Type(() => Material)
  @Expose() files: Material[];
  @Type(() => Material)
  @Expose() customFiles: Material[];
  @Expose() rank: number;
  @Expose() dynamicFields: DynamicField[] = [];
  @Expose() flexiPremiumSupported: boolean; // 是否支持弹性保费
  @Expose() flexiPremiumOptions: number[]; // 弹性保费选项（年）
  @Expose() region: string;

  @Expose() customPaymentTerm: string[];
  @Expose() comparable: boolean;
  @Expose() quotable: boolean;
  @Expose() details: ProductAttributeGroup[];
  @Expose() highlights: string[];
  @Expose() information: Information[];
  @Expose() proposalAmountType: ProposalAmountType;
  @Expose() ageRange: SumRangeResp = new SumRangeResp();

  @Expose() reduceAmountTips: string[]; // 是否允许减少基本金额提示文案
  @Expose() premiumFinancingSupported: boolean; // 是否支持保费融资
}

export class ProductCategory {
  @Expose() name: string;
  @Expose() code: string;
}

export class Product {
  @Expose() hot: boolean;
  @Expose() categoryCode: string;
  @Expose() categoryName: string;
  @Expose() companyCode: string;
  @Expose() companyName: string;
  @Expose() type: ProductType;
  @Expose() currencies: Currency[];
  @Expose() desc: string;
  @Expose() discontinuationDate: number;
  @Expose() features: ProductFeatures;
  @Expose() financialPlanSupported: boolean;
  @Expose() custom: boolean;
  @Expose() premiumOffsetSupported: boolean; // 是否保费扣除
  @Expose() frequencies: Frequency[];
  @Expose() launchDate: number;
  @Expose() logo: string;
  @Expose() medicalOptions: MedicalOptions;
  @Expose() medicalOptionsRequiredField: string[];
  @Expose() paymentTerm: string[];
  @Expose() customPaymentTerm: string[];
  @Expose() policyPeriod: string;
  @Expose() productCode: string;
  @Expose() productName: string;
  @Expose() proposalLanguages: string[]; // 计划书支持语言
  @Expose() proposalRPASupported: boolean; // 计划书是否支持RPA
  @Expose() proposalFrequencySupported: boolean;  // 计划书是否支持付款频率
  @Expose() proposalRiders: string[];
  @Expose() quotationFieldRequirements: QuotationFieldRequirement[];
  @Expose() sellStatus: SellStatus;
  @Expose() comparable: boolean;
  @Expose() proposalPrepaymentSupported: boolean; // 是否支持预缴
  @Expose() quotable: boolean;
  @Expose() withdrawalable: boolean;
  @Expose() tags: string[];
  @Type(() => Material)
  @Expose() files: Material[];
  @Type(() => Material)
  @Expose() customFiles: Material[];
  @Expose() details: ProductAttributeGroup[];
  @Expose() highlights: string[];
  @Expose() information: Information[];
  @Expose() dynamicFields: DynamicField[]; // 动态字段
  @Expose() proposalAmountType: ProposalAmountType;
  @Expose() ageRange: SumRangeResp = new SumRangeResp();

  @Expose() reduceAmountTips: string[]; // 是否允许减少基本金额提示文案
  @Expose() premiumFinancingSupported: boolean; // 是否支持保费融资

  @Expose() flexiPremiumSupported: boolean; // 是否支持弹性保费
  @Expose() flexiPremiumOptions: number[]; // 弹性保费选项（年）
  @Expose() region: string;
}

export class ProductFeatures {
  @Expose() maxWithdrawal: boolean; // 是否支持最大提取
  @Expose() hedgeable: boolean; // 是否支持作为对冲产品
  @Expose() hedgeProducts: string[]; // 支持对冲的产品列表
  @Expose() highlights: I18n[]; // 特色亮点
  @Expose() proposalAmountType: string; // 计划书金额类型
  @Expose() proposalAmountTip: I18n; // 计划书金额类型
}

export class ProductAttributeGroup {
  @Expose() key: string; // key
  @Expose() name: string; // 名称
  @Expose() nameI18n: I18n; // 名称多语言
  @Expose() rank: number; // 排序
  @Transform((value) => plainToArray(ProductAttribute, value.value))
  @Expose() attributes: ProductAttribute[]; // 屬性列表
}

export class ProductAttribute {
  @Expose() attribute: string; // 屬性
  @Expose() name: string; // 名称
  @Expose() nameI18n: I18n; // 名称多语言
  @Expose() value: string; // 值
  @Expose() valueI18n: I18n; // 值多语言
  @Expose() rank: number; // 排序
}

export class ProductAttributeValues {
  @Expose() attribute: string; // 屬性
  @Expose() name: string; // 名称
  @Expose() nameI18n: I18n; // 名称多语言
  @Expose() values: ProductAttribute[] = []; // 值
}

export class ProductAttributeGroupVO {

  @Expose() key: string; // key
  @Expose() name: string; // 名称
  @Expose() nameI18n: I18n; // 名称多语言
  @Expose() rank: number; // 排序
  @Expose() attributes: ProductAttributeValues[] = []; // 屬性列表

  companies: string[] = [];
  products: string[] = [];
  companyNames: string[] = [];
}

export class CompanySearch extends PagedReq {
  name: string; // 保险分类唯一识别
  region: string; // 地区
}

export class CompanyListRes {
  @Expose() name: string;
  @Expose() code: string;
  @Expose() logo: string;
  @Expose() desc: string;
  @Expose() rank: number;
  @Expose() website: string;
  @Expose() tags: string[];
}

export class CreditRating {
  @Expose() rating: string;
  @Expose() project: string;
  @Expose() ratingAgency: string;
  @Expose() time: number;
}

export class ProductCompany {
  @Expose() name: string;
  @Expose() code: string;
  @Expose() logo: string;
  @Expose() desc: string;
  @Expose() rank: number;
  @Expose() region: string;
  @Expose() website: string;
  @Type(() => Link)
  @Expose() links: Link[];
  @Type(() => Material)
  @Expose() files: Material[];
  @Type(() => Material)
  @Expose() customFiles: Material[];
  @Expose() tags: string[];
  @Type(() => CreditRating)
  @Expose() creditRatings: CreditRating[];
}

export enum ProductRestrictionType {
  AGE = 'AGE',
  PREMIUM = 'PREMIUM',
  SUM_INSURED = 'SUM_INSURED',
  WITHDRAWAL = 'WITHDRAWAL'
}

export class ProductRestrictionReq {
  @Expose() code: string;
  @Expose() premiumTerm: string;
  @Expose() frequency: string;
  @Expose() type: ProductRestrictionType;
  @Expose() currency: Currency;
}

export class ProductRestriction {
  @Expose() code: string; // 产品码
  @Expose() premiumTerm: string; // 产品年期
  @Expose() type: ProductRestrictionType; // 限制类型：AGE、PREMIUM、SUM_INSURED、WITHDRAWAL
  @Expose() restrictions: string[]; // 限制JSON对象
  @Expose() descriptions: string[]; // 限制描述

  @Type(() => AmountRestriction)
  @Expose() sumInsuredRestriction: AmountRestriction; // 保額限制 - 當code、premiumTerm、type、currency參數都傳時，才會有數據（如果有錄入數據）
  @Type(() => AmountRestriction)
  @Expose() premiumRestriction: AmountRestriction; // 保費限制 - 當code、premiumTerm、type、currency參數都傳時，才會有數據（如果有錄入數據）
  @Type(() => AgeRestriction)
  @Expose() ageRestriction: AgeRestriction; // 年齡限制 - 當code、premiumTerm、type參數都傳時，才會有數據（如果有錄入數據）
  @Type(() => WithdrawalRestriction)
  @Expose() withdrawalRestriction: WithdrawalRestriction; // 现金提取限制 - 當code、premiumTerm、type參數都傳時，才會有數據（如果有錄入數據）
}

export class ProductCompareReq {
  @Expose() leftProductCode: string; // 左边产品码
  @Expose() rightProductCodes: string[]; // 右边产品码
  @Expose() withQuotation: boolean; // 是否包含保费试算对比
  @Expose() premiumTerm: string; // 产品年期
  @Expose() gender: Gender; // 性别
  @Expose() smokeCondition: SmokeCondition; // 吸烟情况
  @Expose() age: number; // 年龄
  @Expose() currency: Currency; // 年龄
  @Expose() amount: number; // 金额
  @Expose() region: Region; // 医疗险-保障地区
  @Expose() deductible: number; // 医疗险-自费额
  @Expose() level: MedicalLevel; // 医疗险-病房等级
  @Expose() supplementaryBenefits: SupplementaryBenefit[]; // 附加保障
  @Expose() historyId: number;
}

export class ProductCompareRes {
  @Expose() historyId: number; // 历史id
  @Expose() left: ProductCompareDetail; // 主产品
  @Expose() rights: ProductCompareDetail[]; // 副产品列表
}

export class ProductCompareDetail {
  @Expose() productCode: string; // 产品码
  @Expose() categoryCode: string; // 保险分类唯一识别
  @Expose() companyCode: string; // 保险公司唯一识别
  @Expose() productName: string; // 名称
  @Expose() companyLogo: string; // LOGO
  @Expose() companyName: string; // 公司名称
  @Expose() details: ProductAttributeGroup[]; // 产品细节
}

export enum QuotationField {
  Age = 'Age',
  /**
   * 性別
   */
  Gender = 'Gender',
  /**
   * 吸煙狀態
   */
  SmokeCondition = 'SmokeCondition',
  /**
   * 貨幣
   */
  Currency = 'Currency',
  /**
   * 供款年期
   */
  PremiumTerm = 'PremiumTerm',
  /**
   * 病房等級
   */
  MedicalLevel = 'MedicalLevel',
  /**
   * 保障地區
   */
  MedicalRegion = 'MedicalRegion',
  /**
   * 自付額
   */
  MedicalDeductible = 'MedicalDeductible',
}

export class QuotationFieldRequirement {
  field: QuotationField;
  required: boolean;
}

export class CompanyHistoryRes {
  @Expose() id: number; // 编号
  @Expose() leftProductCode: string; // 左边产品码
  @Expose() rightProductCodes: string[]; // 右边产品码
  @Expose() status: string; // 右边产品码
  @Expose() time: number; // 时间
  @Expose() leftProductEntity: ProductEntity; // 左边产品实体
  @Expose() rightProductEntities: ProductEntity[]; // 右边产品实体列表
  @Expose() expand = false;
  @Expose() urlHk: Material; // 繁体pdf地址
  @Expose() urlCn: Material; // 简体pdf地址
  @Expose() urlUs: Material; // 英文pdf地址
}

export class ProductEntity {
  @Expose() productCode: string; // 产品码
  @Expose() productName: string; // 产品名称
  @Expose() logo: string; // 产品名称
}

export class CompanyHistoryDetailRes {
  @Expose() id: number; // 编号
  @Expose() categoryCode: string; // 险种
  @Expose() categoryName: string; // 险种
  @Expose() leftProductCode: string; // 左边产品码
  @Expose() rightProductCodes: string[]; // 右边产品码
  @Expose() productCodes: string[]; // 产品码数组
  @Expose() accountName: string; // 账户名
  @Expose() status: string; // 状态
  @Expose() time: number; // 时间
  @Expose() quotation: Quotation; // 保费试算参数
  @Expose() urlHk: Material; // 繁体pdf地址
  @Expose() urlCn: Material; // 简体pdf地址
  @Expose() urlUs: Material; // 英文pdf地址
  @Transform((value) => plainToArray(Groups, value.value))
  @Expose() groups: Groups[]; // 副产品s
}

export class Quotation {
  @Expose() withQuotation: boolean; // 是否包含保费试算对比
  @Expose() premiumTerm: string; // 产品年期
  @Expose() gender: string; // 性别
  @Expose() smokeCondition: string; // 吸烟情况
  @Expose() age: number; // 年龄
  @Expose() currency: string; // 货币
  @Expose() amount: number; // 金额
  @Expose() region: string; // 医疗险-保障地区
  @Expose() deductible: number; // 医疗险-自费额
  @Expose() level: string; // 医疗险-病房等级
  @Expose() supplementaryBenefits: SupplementaryBenefit[]; // 附加保障
}

export class Groups {
  @Expose() name: string; // 分组名称
  @Expose() key: string; // 分组key
  @Expose() companyNames: string[]; // 公司名称列表
  @Expose() productNames: string[]; // 产品名称列表
  @Transform((value) => plainToArray(Attributes, value.value))
  @Expose() attributes: Attributes[]; // 对比项列表
  @Expose() checked: boolean; // 是否选中
}

export class Attributes {
  @Expose() attribute: string; // 对比项
  @Expose() name: string;
  @Expose() checked: boolean; // 是否选中
  @Transform((value) => plainToArray(Values, value.value))
  @Expose() values: Values[]; // 对比项值列表
}

export class Values {
  @Expose() attribute: string; // 对比项
  @Expose() name: string;
  @Expose() score: number; // 评分
  @Expose() value: string; // 值
}

export class CreateProductCompare {
  @Expose() id: number; // 编号
  @Expose() attributes: Attributes[]; // 隐藏对比项
  @Expose() groups: Groups[]; // 隐藏的对比分组
  @Expose() withQuotation: boolean; // 是否包含保费试算对比
  @Expose() premiumTerm: string; // 产品年期
  @Expose() gender: string; // 性别
  @Expose() smokeCondition: string; // 吸烟情况
  @Expose() age: number; // 年龄
  @Expose() currency: string; // 货币
  @Expose() amount: number; // 金额
  @Expose() region: string; // 医疗险-保障地区
  @Expose() deductible: number; // 医疗险-自费额
  @Expose() level: string; // 医疗险-病房等级
  @Expose() supplementaryBenefits: SupplementaryBenefit[]; // 医疗险-附加保障
}

export class AttributeScore {
  @Expose() id: number; // 编号
  @Expose() productCode: string; // 产品码
  @Expose() attribute: string; // 对比项key
  @Expose() score: string; // 分数
}
