import {Expose} from 'class-transformer';
import {Material, PagedReq} from '../api/types';

export class ListQuery extends PagedReq {
  @Expose() accountName: string; // 账户名
  @Expose() search: string; // 搜索值
  @Expose() age: string; // 年龄
}

export class SelectListQuery extends PagedReq {
  @Expose() accountName: string; // 账户名
  @Expose() search: string; // 搜索值
  @Expose() age: string; // 年龄
  @Expose() accountNames: string; // 账户名
  @Expose() productCodes: string; // 产品码
}

export class ListRes {
  @Expose() id: number; // 主键
  @Expose() name: string; // 客户姓名
  @Expose() status: string; // 状态
  @Expose() createTime: number; // 创建时间
  @Expose() url: Material; // 文件地址
  @Expose() proposalList: ProposalList[]; // 文件地址
  @Expose() expand: boolean;
  @Expose() key: number;
  @Expose() category: string; // 类别
}

export class ProposalList {
  @Expose() id: number; // 主键
  @Expose() proposalId: string; // 计划书编号
  @Expose() productName: string; // 产品名称
  @Expose() productCode: string; // 产品码
  @Expose() paymentTerm: string; // 年期
  @Expose() currency: string; // 货币: HKD-港币;USD-美元;EUR-欧元;JPY-日元;CAD-加拿大币; GBP-英镑; AUD-澳大利亚币; CNY-人民币
  @Expose() age: number; // 年龄
  @Expose() gender: string; // 性别: FEMALE-女性;MALE-男性
  @Expose() smoke: string; // 是否吸烟: S-吸烟;NS-不吸烟
  @Expose() premium: number; // 保费
  @Expose() sumAssured: string; // 保额
  @Expose() applicationTime: number; // 申请时间
  @Expose() isWithdrawal: boolean; // 是否有提取
  @Expose() deductible: number; // 医疗险特有条件:自付额
  @Expose() companyLogo: string; // 保险公司LOGO
  @Expose() categoryCode: string; // * 产品分类
}

export class CreateParam {
  @Expose() proposalIds: string[];
  @Expose() name: string;
  @Expose() asterisk: boolean; // 加星
}
