import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { BaseComponent } from '../../../base/base.component';

export abstract class BottomSheetRef<T = NzSafeAny, R = NzSafeAny> extends BaseComponent {
  // abstract afterClose: Observable<R>;
  // abstract afterOpen: Observable<void>;
  // abstract close(result?: R): void;
  // abstract open(): void;
  // abstract getContentComponent(): T | null;
  //
  // abstract nzClosable?: boolean;
  // abstract nzNoAnimation?: boolean;
  // abstract nzMaskClosable?: boolean;
  // abstract nzKeyboard?: boolean;
  // abstract nzMask?: boolean;
  // abstract nzTitle?: string | TemplateRef<{}>;
  // abstract nzPlacement?: NzDrawerPlacement;
  // abstract nzMaskStyle?: object;
  // abstract nzBodyStyle?: object;
  // abstract nzWrapClassName?: string;
  // abstract nzWidth?: number | string;
  // abstract nzHeight?: number | string;
  // abstract nzZIndex?: number | string;
  // abstract nzOffsetX?: number | string;
  // abstract nzOffsetY?: number | string;
}
