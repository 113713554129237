<div class="drawer-container">
  <div class="drawer-title" style="margin-bottom: 40px">
    <nz-breadcrumb nzSeparator=">" style="font-size: 20px;line-height: 22px">
      <nz-breadcrumb-item>{{'ContactsInfo' | translate}}</nz-breadcrumb-item>
      <nz-breadcrumb-item>{{'CustomerRecords' | translate}}</nz-breadcrumb-item>
      <nz-breadcrumb-item *ngIf="type == 'Create'">{{'AddActivityRecord' | translate}}</nz-breadcrumb-item>
      <nz-breadcrumb-item *ngIf="type == 'Edit'">{{'UpdateCustomerRecords' | translate}}</nz-breadcrumb-item>
    </nz-breadcrumb>
  </div>
  <div class="section-title" style="margin-top: 20px">
    <span *ngIf="type == 'Create'" style="margin-left: 8px">{{'AddActivityRecord' | translate}}</span>
    <span *ngIf="type == 'Edit'" style="margin-left: 8px">{{'UpdateCustomerRecords' | translate}}</span>
  </div>
  <!-- 时间 -->
  <app-property-datetime-string [title]="'Time' | translate"
                                [required]="true"
                                [placeholder]="('PleaseSelect' | translate) + ('Time' | translate)"
                                [(value)]="follow.followDate"></app-property-datetime-string>
  <!-- 事项 -->
  <app-property-input [title]="'Matter' | translate"
                      [required]="true"
                      [placeholder]="('PleaseEnter' | translate) + ('Matter' | translate)"
                      [(value)]="follow.matter"></app-property-input>
  <!-- 状态 -->
  <app-property-select [title]="'Status' | translate"
                       [required]="true"
                       [placeholder]="('PleaseSelect' | translate) + ('Status' | translate)"
                       [options]="statusOptions"
                       [(value)]="follow.status"></app-property-select>
  <!-- 地点 -->
  <app-property-input [title]="'Place' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('Place' | translate)"
                      [(value)]="follow.place"></app-property-input>
  <!-- 产品 -->
  <app-property-pick [title]="'Product' | translate"
                     [placeholder]="('PleaseSelect' | translate) + ('Product' | translate)"
                     appThrottleClick (throttleClick)="onAddProducts()"
                     [value]="productName"></app-property-pick>
  <!-- 备注 -->
  <app-property-input [title]="'Remark' | translate"
                      [placeholder]="('PleaseEnter' | translate) + ('Remark' | translate)"
                      [(value)]="follow.remark"></app-property-input>
  <div class="section-title" style="margin-top: 20px">
    <span style="margin-left: 8px">{{'Attachments' | translate}}</span>
  </div>
  <div nz-row style="width: 100%;">
    <div nz-col nzSpan="24" style="padding: 0px 0px">
      <app-attachments [attachments]="follow.attachments"
                       [uploadTokenReq]="uploadTokenReq"
                       [isPreview]="true"
                       (valueChange)="addAttachmentsChange($event)"
                       [showEmpty]="true"></app-attachments>
    </div>
  </div>
  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           [disabled]="!canReply"
           [style]="!canReply ? 'opacity: 0.5' : ''"
           appThrottleClick (throttleClick)="onSave()">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
