import { Pipe, PipeTransform } from '@angular/core';
import { ProposalStatus } from '../../proposal/proposal-types';

@Pipe({
  name: 'proposalStatus'
})
export class ProposalStatusPipe implements PipeTransform {

  transform(value: ProposalStatus, ...args: any[]): string {
    let result = '';
    switch (value) {
      case ProposalStatus.PENDING:
        result = 'ProposalStatusPending';
        break;
      case ProposalStatus.COMPLETED:
        result = 'ProposalStatusCompleted';
        break;
      case ProposalStatus.CANCELLED:
        result = 'ProposalStatusCancelled';
        break;
    }
    return result;
  }

}
