<div>
  <div [class]="'property-row'">
    <span class="property-title-row" *ngIf="title">{{ title }}</span>
    <span class="property-value-row">
      <nz-switch [(ngModel)]="value" [disabled]="disabled"></nz-switch>
    </span>
  </div>
  <div class="property-tip" *ngIf="tip && tipVisible">{{tip}}</div>
  <div class="property-bottom-line" *ngIf="bottomLineVisible"></div>
</div>
