<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{'TicketsCreate' | translate}}
  </div>

  <!--  标题-->
  <div class="container-box" style="margin-top: 15px">
    <app-property-input [title]="'TicketTitle' | translate"
                        [required]="true"
                        [bottomLineVisible]="false"
                        [placeholder]="('PleaseEnter' | translate) + ('TicketTitle' | translate)"
                        [(value)]="ticketCreateResp.title"></app-property-input>
  </div>
  <!--  内容-->
  <div>
    <div class="section-wrapper" style="margin: 15px 0 10px">
<!--      <app-property-editor [bottomLineVisible]="false"-->
<!--                           [required]="true"-->
<!--                           [title]="'Content' | translate"-->
<!--                           [placeholder]="('PleaseEnter' | translate) + ('ReplyContent' | translate)"-->
<!--                           [(value)]="ticketCreateResp.content"></app-property-editor>-->
      <app-property-input-area [bottomLineVisible]="false"
                               [required]="true"
                               [title]="'Content' | translate"
                               [placeholder]="('PleaseEnter' | translate) + ('ReplyContent' | translate)"
                               [(value)]="ticketCreateResp.content"></app-property-input-area>
    </div>
  </div>
  <!--  文件-->
  <div class="" style="margin: 0px 0px;">
    <app-attachments [attachments]="ticketCreateResp.files"
                     [uploadTokenReq]="uploadTokenReq"
                     [isPreview]="true"
                     [apiType]="'TICKET_LIFEBEE'"
                     (valueChange)="replyAttachmentsChange($event)"
                     [showEmpty]="false"></app-attachments>
  </div>

  <div class="ticket-footer">
    <div nz-col nzSpan="24" class="footer-button-primary" style="height: 50px">
      <a nz-button nzType="link" nzBlock nzSize="large"
         appThrottleClick (throttleClick)="create()" [disabled]="!canReply"
         [style]="!canReply ? 'opacity: 0.5' : ''">
        <span>{{ 'Create' | translate }}</span>
      </a>
    </div>
  </div>
</div>
