import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BankInfo, BankInfoSetting, DiscountInfo, ProposalPremiumFinancing, UpdateFinancing} from '../../proposal-types';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {BackInfoComponent} from '../back-info/back-info.component';
import {PropertySelectOption} from '../../../shared/component/property-select/property-select.component';
import {DiscountInfoComponent} from '../discount-info/discount-info.component';
import {ProposalService} from '../../proposal.service';

@Component({
  selector: 'app-premium-financing-info',
  templateUrl: './premium-financing-info.component.html',
  styleUrls: ['./premium-financing-info.component.less']
})
export class PremiumFinancingInfoComponent implements OnInit {
  @Input()
  title: string;
  @Input()
  proposalId: string; // 计划书编号 Edit
  bankInfo: BankInfo = new BankInfo();
  discountInfo: DiscountInfo = new DiscountInfo();
  yearOptions: PropertySelectOption[] = [];
  saveBackInfo: BankInfoSetting = new BankInfoSetting();
  @Input()
  financingType: string;
  @Output()
  backInfoSaved: EventEmitter<BankInfo> = new EventEmitter<BankInfo>();
  @Output()
  discountSaved: EventEmitter<DiscountInfo> = new EventEmitter<DiscountInfo>();
  @Input()
  modifyState: boolean;
  @Input()
  editFinancing: ProposalPremiumFinancing;
  @Output()
  editInfoSaved: EventEmitter<any> = new EventEmitter<any>();

  constructor(private drawerService: NzDrawerService,
              private proposalService: ProposalService) { }

  ngOnInit(): void {
    // console.log(this.financingType);
    // let res = new BankInfo();
    // this.proposalService.infoTransmit.subscribe(
    //   data => {
    //     console.log(data);
    //     res = data;
    //     console.log(this.bankInfo);
    //   }
    // );
    // this.bankInfo = res;
  }


  onBackInfoSelection(): void {
    const drawerRef = this.drawerService.create<BackInfoComponent, { value: any }, string>({
      nzWidth: 400,
      nzMask: false,
      nzContent: BackInfoComponent,
      nzContentParams: {
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.backInfoSelected
        .subscribe(
          data => {
            this.infoSelection(data);
            drawerRef.close();
          }
        );
    });
  }

  infoSelection(info): void {
    if (this.financingType === 'bank') {
      this.saveBackInfo = info;
      this.bankInfo.name = info.name;
      this.bankInfo.loanRatio = info.loanRatio;
      this.bankInfo.prepaymentInterestRate = info.prepaymentInterestRate;
      this.bankInfo.policyLoanHandlingFeeRate = info.policyLoanHandlingFeeRate;
      // if (info.loanYears.length === 1) {
      //   info.loanYears.forEach(item => {
      //     this.bankInfo.year = item.year;
      //     this.bankInfo.annualInterestRate = item.annualInterestRate;
      //   });
      // }

      // const proposalAmountTypes = this.metadataService.values('proposalAmountType');
      for (const item of info.loanYears) {
        this.yearOptions.push(new PropertySelectOption(item.year, item.year));
      }
    } else {
      this.discountInfo = info;
    }
  }

  onYearOptionsChange(val): void {
    this.saveBackInfo.loanYears.map(item => {
      if (item.year === val) {
        this.bankInfo.annualInterestRate = item.annualInterestRate;
      }
    });
  }

  onDiscountInfoSelection(): void {
    const drawerRef = this.drawerService.create<DiscountInfoComponent, { value: any }, string>({
      nzWidth: 400,
      nzMask: false,
      nzContent: DiscountInfoComponent,
      nzContentParams: {
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.discountInfoSelected
        .subscribe(
          data => {
            this.infoSelection(data);
            drawerRef.close();
          }
        );
    });
  }

  onReset(): void {
    if (this.financingType === 'back') {
      this.bankInfo = new BankInfo();
      this.saveBackInfo = new BankInfoSetting();
      this.yearOptions = [];
    } else {
      this.discountInfo = new DiscountInfo();
    }
  }

  onSave(): void {
    if (this.financingType === 'back') {
      this.bankInfo.year = this.bankInfo.year ? this.bankInfo.year : 0;
      this.bankInfo.annualInterestRate = this.bankInfo.annualInterestRate ? this.bankInfo.annualInterestRate : 0;
      this.bankInfo.loanRatio = this.bankInfo.loanRatio ? this.bankInfo.loanRatio : 0;
      this.bankInfo.prepaymentInterestRate = this.bankInfo.prepaymentInterestRate ? this.bankInfo.prepaymentInterestRate : 0;
      this.bankInfo.policyLoanHandlingFeeRate = this.bankInfo.policyLoanHandlingFeeRate ? this.bankInfo.policyLoanHandlingFeeRate : 0;
      this.backInfoSaved.emit(this.bankInfo);
    } else {
      this.discountInfo.premiumCashback = this.discountInfo.premiumCashback ? this.discountInfo.premiumCashback : 0;
      this.discountInfo.firstYearPremiumDiscount = this.discountInfo.firstYearPremiumDiscount ? this.discountInfo.firstYearPremiumDiscount : 0;
      this.discountInfo.annualPremiumDiscount = this.discountInfo.annualPremiumDiscount ? this.discountInfo.annualPremiumDiscount : 0;
      this.discountSaved.emit(this.discountInfo);
    }
  }

  onEdit(): void {
    const param = new UpdateFinancing();
    param.proposalId = this.proposalId;
    if (this.financingType === 'back') {
      param.premiumFinancing.bankInfo = this.bankInfo;
      param.premiumFinancing.discountInfo = this.editFinancing.discountInfo;
    } else {
      param.premiumFinancing.discountInfo = this.discountInfo;
      param.premiumFinancing.bankInfo = this.editFinancing.bankInfo;
    }
    // param.premiumFinancing.bankInfo = !!Object.keys(param.premiumFinancing.bankInfo).length ? param.premiumFinancing.bankInfo : undefined;
    // param.premiumFinancing.discountInfo = !!Object.keys(param.premiumFinancing.discountInfo).length ? param.premiumFinancing.discountInfo : undefined;
    this.proposalService.putFinancing(param)
      .subscribe(
        data => {
          if (data) {
            this.editInfoSaved.emit(data);
          }
        },
        error => {}
      );
  }

}
