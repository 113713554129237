import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-aml-main',
  templateUrl: './aml-main.component.html',
  styleUrls: ['./aml-main.component.less']
})
export class AmlMainComponent implements OnInit {

  typeHide: string;
  constructor(private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.router
      .queryParams
      .subscribe(params => {
        console.log(params['type']);
        this.typeHide = params['type'];
      });
  }

}
