<div class="from-info-container">
  <div class="from-info-card">
    <div style="color: rgba(64,64,64,0.85);text-align: justify">
      {{'CMGQuestion1' | translate}}
    </div>
  </div>

  <div *ngFor="let fnaQuestion of fnaQuestions; let i = index">
    <app-zuu-template-question [fnaQuestion]="fnaQuestion"
                               [isDisabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                               [isCMGQuestion6]="isCMGQuestion6()"
                               [fnaInfo]="fnaInfo"
                               (fnaChange)="newFnaChange($event)"
                               (questionEChange)="questionE($event)">
    </app-zuu-template-question>
  </div>
</div>
