import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aml-monitor',
  templateUrl: './aml-monitor.component.html',
  styleUrls: ['./aml-monitor.component.less']
})
export class AmlMonitorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
