import { Component, OnInit } from '@angular/core';
import { DocumentService } from '../document.service';
import { DocumentListResp } from '../document-types';
import { Icons } from '../../shared/utils/icons';
import { StorageService } from '../../shared/service/storage.service';
import { NzImageService } from 'ng-zorro-antd/image';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { WorkbenchService } from '../../workbench/workbench.service';
import { Workbench } from '../../workbench/workbench-types';


@Component({
  selector: 'app-document-used-list',
  templateUrl: './document-used-list.component.html',
  styleUrls: ['./document-used-list.component.less']
})
export class DocumentUsedListComponent implements OnInit {
  loading = false;
  spinning = false;

  documentTable: DocumentListResp[];

  workbench: Workbench;

  constructor(private documentService: DocumentService,
              private storageService: StorageService,
              private drawerService: NzDrawerService,
              private nzImageService: NzImageService,
              private workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    this.documentService.usedList()
      .subscribe(
        data => {
          this.documentTable = data;
        },
        error => {
        });

    this.workbench = this.workbenchService.workbench;

  }

  onReload(): void {
    this.loading = true;
    this.documentService.usedList()
      .subscribe(
        data => {
          this.documentTable = data;
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  iconOfFileName(fileName: string): string {
    if (fileName) {
      return Icons.iconOfFileName(fileName);
    } else {
      fileName = '.';
      return Icons.iconOfFileName(fileName);
    }
  }

  // 查看
  onPreview(attachment: DocumentListResp): void {
    this.storageService.accessToken(attachment.url)
      .subscribe(
        data => {
          const src = data.accessToken;
          const filePath = attachment.url ?? '';
          const ext = filePath.substr(filePath.lastIndexOf('.') + 1).toLowerCase();
          console.log(ext);
          switch (ext) {
            case 'png':
            case 'jpg':
            case 'jpeg':
              this.onImagePreview(attachment, src);
              break;
            case 'pdf':
              this.onPDFPreview(attachment, src);
              break;
            default:
              window.open(src);
              break;
          }
        },
        err => {

        });
  }

  onImagePreview(material: DocumentListResp, src: string): void {
    const images = [
      {
        src,
        width: 'auto',
        height: 'auto',
        alt: material.name
      }
    ];
    this.nzImageService.preview(images, {nzZoom: 1.0, nzRotate: 0});
  }

  onPDFPreview(material: DocumentListResp, src: string): void {

    const drawerRef = this.drawerService.create<PdfViewerComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: PdfViewerComponent,
      nzContentParams: {
        src
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const pdfViewerComponent = drawerRef.getContentComponent();
      pdfViewerComponent.autoresize = true;
      pdfViewerComponent.showAll = true;
      pdfViewerComponent.originalSize = false;
      pdfViewerComponent.fitToPage = true;
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }
}
