import { Component, OnInit } from '@angular/core';
import { DataMode, Instance, Underwriting } from '../../api/types';
import { Workbench } from '../../workbench/workbench-types';
import { ChooseProductInfo, NewFnaDetail } from '../../new-fna/new-fna-types';
import { ActivatedRoute, Router } from '@angular/router';
import { NewFnaService } from '../../new-fna/new-fna.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { BookingService } from '../../booking/booking.service';
import { UnderwritingService } from '../../underwriting/underwriting.service';
import { PolicyService } from '../../policy/policy.service';
import { ProposalService } from '../../proposal/proposal.service';
import { WorkbenchService } from '../../workbench/workbench.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { plainToInstance } from 'class-transformer';
import { Proposal, ProposalSearch, ProposalStatus } from '../../proposal/proposal-types';
import { ProposalSelectionComponent } from '../../proposal/proposal-selection/proposal-selection.component';
import { ProposalDetailComponent } from '../../proposal/proposal-detail/proposal-detail.component';
import { UnderwritingDetailComponent } from '../../underwriting/underwriting-detail/underwriting-detail.component';
import { PolicyDetailComponent } from '../../policy/policy-detail/policy-detail.component';
import { Policy } from '../../policy/policy-types';

@Component({
  selector: 'app-piba-template-overview',
  templateUrl: './piba-template-overview.component.html',
  styleUrls: ['./piba-template-overview.component.less']
})
export class PibaTemplateOverviewComponent implements OnInit {

  iconStyle = {
    fontSize: '15px',
    color: '#4B84E5'
  };
  fnaId: any;
  mode = DataMode.OWN;
  workbench: Workbench;

  loading = false;
  fnaInfo: NewFnaDetail = new NewFnaDetail();

  selectedIndex = 0;
  record = {
    signatureStatus: null,
    logo: null
  };
  instance: Instance;

  constructor(private router: Router,
              private routerService: ActivatedRoute,
              private newFnaService: NewFnaService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private drawerService: NzDrawerService,
              private bookingService: BookingService,
              private underwritingService: UnderwritingService,
              private policyService: PolicyService,
              private proposalService: ProposalService,
              private workbenchService: WorkbenchService,
              private modalService: NzModalService) { }

  ngOnInit(): void {
    this.routerService
      .queryParams
      .subscribe(params => {
        console.log(params);
        this.fnaId = params['id'];
        if (params['mode']) {
          this.mode = params['mode'];
        }
        if (!params['id']) {
          if (JSON.parse(localStorage.getItem('BASIC_INFO'))) {
            const obj1 = plainToInstance(NewFnaDetail, JSON.parse(localStorage.getItem('BASIC_INFO')));
            this.fnaInfo = JSON.parse(JSON.stringify(obj1));
          }
        }
        this.onDetail(this.fnaId);
      });

    this.workbench = this.workbenchService.workbench;
    this.instance = this.workbenchService.mockInstance;
  }

  reuse(): void {
    console.log('reuse');
  }

  onDetail(id: number): void {
    if (!id) {
      return;
    }
    this.newFnaService.info(id)
      .subscribe(
        fna => {
          this.fnaInfo = JSON.parse(JSON.stringify(fna));
        },
        error => {
        });
  }

  close(): void {
    console.log('close');
    this.router.navigate(['/user/new-fna']).then();
  }

  toDetail(num): void {
    console.log(`toDetail`);
    if (this.fnaId) {
      this.router.navigate(['/user/new-fna-piba'], { queryParams: { index: num, id: this.fnaId } }).then();
    } else {
      if (num !== 1) {
        return;
      } else {
        this.router.navigate(['/user/new-fna-piba'], { queryParams: { index: num, id: this.fnaId } }).then();
      }
    }
  }

  showProposalSelection(): void {
    if (!this.fnaInfo.id) {
      this.translate.get(['PleaseCompleteFirst', 'FnaInfo']).subscribe(
        (res: any) => {
          this.toastr.warning(res.PleaseCompleteFirst + res.FnaInfo);
        });
      return;
    }
    if (this.fnaInfo.proposalId) {
      this.onProposalDetail();
    } else {
      // 只能关联已完成的计划书
      const search = new ProposalSearch();
      search.status = ProposalStatus.COMPLETED;
      // // 2022-12-05 17：00 修改需求 客户要求放开限制
      // // search.productCode = this.booking.productCode;
      // search.categoryCode = this.product?.categoryCode;
      // search.companyCode = this.product?.companyCode;
      const drawerRef = this.drawerService.create<ProposalSelectionComponent, { value: any }, string>({
        nzWidth: 800,
        nzMaskClosable: true,
        nzContent: ProposalSelectionComponent,
        nzContentParams: {
          search,
          enableOptions: true,
          isNewFna: true,
          newFna: JSON.parse(JSON.stringify(this.fnaInfo))
        },
      });
      drawerRef.afterOpen.subscribe(() => {
        const component = drawerRef.getContentComponent();
        component.proposalSelected
          .subscribe(
            value => {
              const proposal: Proposal = value as Proposal;
              this.associateProposal(proposal.proposalId, drawerRef);
            });
        component.proposalApplyFna
          .subscribe(
            value => {
              drawerRef.close();
              this.onDetail(this.fnaInfo.id);
            });
      });
      drawerRef.afterClose.subscribe(data => {
        if (typeof data === 'string') {
        }
      });
    }
  }

  onProposalDetail(): void {
    if (!this.fnaInfo.proposalId) {
      return;
    }
    this.loading = true;
    this.proposalService.info(this.fnaInfo.proposalId)
      .subscribe(
        proposal => {
          this.loading = false;
          const drawerRef = this.drawerService.create<ProposalDetailComponent, { value: any }, string>({
            nzWidth: 800,
            nzMaskClosable: true,
            nzContent: ProposalDetailComponent,
            nzContentParams: {
              proposal
            },
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
          });
        },
        error => {
          this.loading = false;
        }
      );
  }

  associateProposal(proposalId: string, drawerRef: NzDrawerRef<ProposalSelectionComponent, string>): void {
    this.loading = true;
    this.newFnaService.relatedProposalNewFna({id: this.fnaId, proposalId})
      .subscribe(
        progress => {
          drawerRef.close();
          this.loading = false;
          this.onDetail(this.fnaId);
        },
        error => {
          this.loading = false;
        });
  }


  onReuse(): void {
    this.translate.get(['FNAReuseTips', 'Confirm', 'Cancel']).subscribe(
      (res: any) => {
        this.modalService.confirm({
          nzCentered: true,
          nzTitle: res.FNAReuseTips,
          nzContent: null,
          nzCancelText: res.Cancel,
          nzOkText: res.Confirm,
          nzOnOk: () => {
            this.loading = true;
            this.newFnaService.reuseNewFna(this.fnaId)
              .subscribe(data => {
                this.loading = false;
                this.router.navigate(['/user/piba-fna-view'], {queryParams: {id: data.id}}).then();
              }, error => {
                this.loading = false;
              });
          }
        });
      });

  }


  onUnderwritingDetail(): void {
    if (!this.fnaInfo.underwritingNo) {
      return;
    }
    this.loading = true;
    this.underwritingService.detail(this.fnaInfo.underwritingNo)
      .subscribe(
        underwritingDetail => {

          this.loading = false;
          const underwriting = underwritingDetail.toUnderwriting();

          const drawerRef = this.drawerService.create<UnderwritingDetailComponent, { value: Underwriting }, string>({
            nzWidth: 800,
            nzContent: UnderwritingDetailComponent,
            nzContentParams: {
              underwritingDetail,
              underwriting,
              mode: this.mode,
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  onPolicyDetail(): void {
    if (!this.fnaInfo.policyId) {
      return;
    }
    this.loading = true;
    this.policyService.detail(this.fnaInfo.policyId)
      .subscribe(
        policyDetail => {

          this.loading = false;
          const policy = policyDetail.toPolicy();

          const drawerRef = this.drawerService.create<PolicyDetailComponent, { value: Policy }, string>({
            nzWidth: 800,
            nzContent: PolicyDetailComponent,
            nzContentParams: {
              policyDetail,
              policy,
              mode: this.mode,
            }
          });

          drawerRef.afterOpen.subscribe(() => {
            const component = drawerRef.getContentComponent();
          });

          drawerRef.afterClose.subscribe(data => {
            if (typeof data === 'string') {
            }
          });
        },
        error => {
          this.loading = false;
        });
  }

  returnProduct(): ChooseProductInfo {
    let product: ChooseProductInfo = new ChooseProductInfo();
    if (this.fnaInfo.chooseProduct && this.fnaInfo.chooseProduct.length) {
      this.fnaInfo.chooseProduct.map(item => {
        if (item.choose) {
          product = item.fnaProduct;
        }
      });
    }
    return product;
  }


}
