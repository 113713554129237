<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 30px">
    {{'TeamMembers' | translate}}
  </div>

  <div>
    <div>
      <div style="display: flex; align-items: center; height: 50px;">
        <i *ngIf="treeData.high > 1"
          nz-icon
          style="font-size: 20px; align-self: center; cursor: pointer"
          [nzType]="isShow ? 'minus-square' : 'plus-square'"
          class="ant-tree-switcher-line-icon"
          appThrottleClick (throttleClick)="onEdit()"
        ></i>
        <div class="custom-node" style="padding-left: 6px" [style]="treeData.status === 'FORBID' ? 'opacity: 0.7;' : ''">
          <div>
            <nz-avatar class="list-top-avatar" style="margin-right: 12px;" [nzSize]="36" [nzSrc]="treeData.avatar"></nz-avatar>
            <span *ngIf="treeData.status === 'FORBID'"
                  style="position: absolute;left: 6px;top: 6px;font-size: 24px;color: #ffffff"
                  nz-icon nzType="stop" nzTheme="outline"></span>
            <span style="color: #000000d9">{{instance.isFoneOrDev() ? treeData.accountName + '&emsp;' + treeData.personName  : treeData.personName }}</span>
            <nz-tag *ngIf="instance.isBaylightOrDev()" nzColor="processing" style="margin-left: 5px">{{ treeData.salesRank ? treeData.salesRank : ('NewRich' | translate) }}</nz-tag>
          </div>
          <nz-tag *ngIf="treeData.status === 'FORBID'">{{ 'Disable' | translate }}</nz-tag>
        </div>
      </div>
      <nz-tree
        *ngIf="isShow"
        style="margin-left: 24px"
        nzBlockNode
        nzShowIcon
        [nzShowExpand]="true"
        [nzData]="treeData.children"
        [nzExpandedIcon]="multiExpandedIconTpl"
        [nzTreeTemplate]="nzTreeTemplate"
      ></nz-tree>
      <ng-template #multiExpandedIconTpl let-node let-origin="origin">
        <i
          *ngIf="origin.high > 1"
          nz-icon
          style="font-size: 20px;"
          [nzType]="node.isExpanded ? 'minus-square' : 'plus-square'"
          class="ant-tree-switcher-line-icon"
        ></i>
      </ng-template>
      <ng-template #nzTreeTemplate let-origin="origin">
        <div class="custom-node" [style]="origin.status === 'FORBID' ? 'opacity: 0.7;' : ''">
          <div style="position: relative;">
            <nz-avatar class="list-top-avatar" style="margin-right: 12px;" [nzSize]="36" [nzSrc]="origin.avatar"></nz-avatar>
            <span *ngIf="origin.status === 'FORBID'"
                  style="position: absolute;left: 6px;top: 6px;font-size: 24px;color: #ffffff"
                  nz-icon nzType="stop" nzTheme="outline"></span>
            <span>{{ instance.isFoneOrDev() ? origin.accountName + '&emsp;' + origin.personName  : origin.personName }}</span>
            <nz-tag *ngIf="instance.isBaylightOrDev()" style="margin-left: 5px" nzColor="processing">{{ origin.salesRank ? origin.salesRank : ('NewRich' | translate) }}</nz-tag>
          </div>
          <nz-tag *ngIf="origin.status === 'FORBID'">{{ 'Disable' | translate }}</nz-tag>
        </div>
      </ng-template>
    </div>
  </div>
</div>
