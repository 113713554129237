import { NgModule } from '@angular/core';
import { RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';
import { SaleReportComponent } from './sale-report.component';
import { SaleReportMainComponent } from './sale-report-main.component';
import { SharedModule } from '../shared/shared.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TranslateModule} from '@ngx-translate/core';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SaleReportListComponent } from './sale-report-list/sale-report-list.component';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { SaleReportDetailComponent } from './sale-report-detail/sale-report-detail.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { SaleReportTableComponent } from './sale-report-table/sale-report-table.component';
import { FormsModule } from '@angular/forms';
import { SaleReportSearchComponent } from './sale-report-search/sale-report-search.component';



@NgModule({
  declarations: [
    SaleReportComponent,
    SaleReportMainComponent,
    SaleReportListComponent,
    SaleReportDetailComponent,
    SaleReportTableComponent,
    SaleReportSearchComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NzLayoutModule,
    RouterModule,
    TranslateModule,
    NzSpinModule,
    NzSkeletonModule,
    NzTableModule,
    NzGridModule,
    NzToolTipModule,
    NzPopoverModule,
    NzButtonModule,
    NzIconModule,
    NzEmptyModule,
    NzTabsModule,
    NzDividerModule,
    NzRadioModule,
    FormsModule,
  ]
})
export class SaleReportModule { }
