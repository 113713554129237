import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Classify, Document, DocumentListResp, DocumentSearch, DocumentSpace } from '../document-types';
import { DataMode, PagedReq, PagedResp, UploadTokenReq } from '../../api/types';
import { DocumentService } from '../document.service';
import { Icons } from '../../shared/utils/icons';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DocumentCreateComponent } from '../document-create/document-create.component';
import { StorageService } from '../../shared/service/storage.service';
import { NzImageService } from 'ng-zorro-antd/image';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { DownloadService } from '../../shared/service/download.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { WorkbenchService } from '../../workbench/workbench.service';
import { plainToClass } from 'class-transformer';
import { Workbench } from '../../workbench/workbench-types';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.less']
})
export class DocumentsComponent implements OnInit {
  @Input()
  mode = DataMode.OWN;
  state = Classify.PERSONAL;

  loading = false;
  spinning = false;
  btnLoading = false;
  searchPopoverVisible = false;
  uploadPopoverVisible = false;

  audio: string;
  preview: string;
  video: any;
  isVisible = false;
  isConfirmLoading = false;
  currFolderId: 0;

  searchCondition: {};
  fileNav: Array<any> = [{id: 0, label: 'ALL'}];
  page: PagedReq = new PagedReq();

  search: DocumentSearch = new DocumentSearch();

  uploadTokenReq = new UploadTokenReq();

  workbench: Workbench;

  @Input()
  documentTable: PagedResp<DocumentListResp>;

  space: DocumentSpace;

  constructor(private documentService: DocumentService,
              private drawerService: NzDrawerService,
              private storageService: StorageService,
              private downloadService: DownloadService,
              private nzImageService: NzImageService,
              private modalService: NzModalService,
              private toastr: ToastrService,
              private sanitizer: DomSanitizer,
              private translate: TranslateService,
              private workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {

    this.workbench = this.workbenchService.workbench;

    this.search.classify = this.isClassify();

    this.documentService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.documentTable = data;
        },
        error => {
        }
      );
    this.documentService.fileCollection
      .subscribe(
        data => {
          this.onReload();
        }
      );

    if (this.mode === DataMode.OWN) {
      this.initSpace();
    }
  }

  initSpace(): void {
    this.documentService.space()
      .subscribe(
        data => {
          this.space = data;
        },
        error => {
        }
      );
  }

  iconOfFileName(fileName: string): string {
    if (fileName) {
      return Icons.iconOfFileName(fileName);
    } else {
      fileName = '.';
      return Icons.iconOfFileName(fileName);
    }
  }

  // 过滤查看按钮
  previewFilter(fileName: string): boolean {
    let isShow;
    const ext = fileName.substr(fileName.lastIndexOf('.') + 1)?.toLowerCase();
    switch (ext) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        isShow = true;
        break;
      case 'pdf':
        isShow = true;
        break;
      case 'mp3':
      case 'wav':
        isShow = true;
        break;
      // case 'wmv':
      // case 'asf':
      // case 'asx':
      // case 'rm':
      // case 'rmvb':
      case 'webm':
      case 'ogg':
      case 'mp4':
      // case 'avi':
        isShow = true;
        break;
      default:
        isShow = false;
        break;
    }
    return isShow;
  }

  isClassify(): string {
    let res = '';
    if (this.mode === 'SUBORDINATE') {
      res = Classify.COMPANY;
    } else if (this.mode === 'OWN') {
      res = Classify.PERSONAL;
    } else {
      res = '';
    }
    return res;
  }

  // 获取数据
  documentList(val: any): void {
    if (val.type === 'FILE') {
      return;
    }
    val.classify = this.isClassify();
    this.documentService.list(val, this.mode)
      .subscribe(
        data => {
          this.documentTable = data;
        },
        error => {
        }
      );
  }

  onSearch(pageNum: number, type: string): void {
    this.search.pageNum = pageNum;
    if (type === 'Turn') {
      this.search.id = this.currFolderId;
      // console.log(this.currFolderId, '---------');
      this.fileNav.map(item => {
        if (item.id === this.currFolderId) {
          item.pageNum = pageNum;
        }
      });
    } else {
      this.resetFileNav();
    }
    this.loading = true;
    // console.log(this.search, '-----', this.currFolderId);
    this.documentService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.documentTable = data;
        },
        error => {
          this.loading = false;
        });
  }

  onResetSearch(): void {
    this.search = new DocumentSearch();
    this.search.classify = this.isClassify();
    this.onReload();
    this.resetFileNav();
  }

  onReload(resetPageNum = true): void {
    this.loading = true;
    if (resetPageNum) {
      this.search.pageNum = 1;
    }
    this.search.id = this.currFolderId;
    // this.resetFileNav();
    this.documentService.list(this.search, this.mode)
      .subscribe(
        data => {
          this.loading = false;
          this.documentTable = data;
        },
        error => {
          this.loading = false;
        });
  }

  // 删除功能
  deleteFile(val, tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>): void {
    this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzClosable: false,
      nzOnOk: () => {
        this.loading = true;
        this.documentService.deleteFile(val.id)
          .subscribe(
            data => {
              if (data) {
                this.translate.get('DeleteSuccess').subscribe(
                  (res) => {
                    this.toastr.success(res);
                  }
                );
              }
              this.onSearch(1, 'Turn');
              this.loading = false;
            },
            error => {
              this.loading = false;
            });
      }
    });
  }

  isCollection(val): void {
    this.documentService.isCollection(val.id, val.isCollected)
      .subscribe(
        data => {
          this.documentService.fileCollection.emit();
        },
        error => {
        });
  }


  // 新建文件夹
  onFolderCreate(): void {
    const drawerRef = this.drawerService.create<DocumentCreateComponent, { value: any }, string>({
      nzWidth: 500,
      nzMaskClosable: false,
      nzContent: DocumentCreateComponent,
      nzContentParams: {
        id: this.currFolderId
      },
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.createFolderRes.subscribe(
        data => {
          drawerRef.close();
          this.onSearch(1, 'Turn');
        }
      );
    });
  }

  // 文件上传之后触发页面刷新
  afterUploading(val: number): void {
    if (val) {
      this.onSearch(1, 'Turn');
    }
    // console.log(val);
  }

  // 查看
  onPreview(attachment: DocumentListResp): void {
    this.storageService.accessToken(attachment.url)
      .subscribe(
        data => {
          const src = data.accessToken;
          const filePath = attachment.url ?? '';
          const ext = filePath.substr(filePath.lastIndexOf('.') + 1).toLowerCase();
          console.log(ext);
          switch (ext) {
            case 'png':
            case 'jpg':
            case 'jpeg':
              this.onImagePreview(attachment, src);
              break;
            case 'pdf':
              this.onPDFPreview(attachment, src);
              break;
            case 'mp3':
            case 'ogg':
            case 'wav':
              this.onAudioPreview(src);
              break;
            // case 'wmv':
            // case 'asf':
            // case 'asx':
            // case 'rm':
            // case 'rmvb':
            case 'webm':
            case 'ogg':
            case 'mp4':
              // case 'avi':
              this.onVideoPreview(src);
              break;
            default:
              window.open(src);
              break;
          }
        },
        err => {

        });
  }

  onAudioPreview(src: string): void {
    this.isVisible = true;
    this.preview = 'audio';
    this.audio = src;
  }

  onVideoPreview(src: string): void {
    this.isVisible = true;
    this.preview = 'video';
    this.video = src;
  }

  onImagePreview(material: DocumentListResp, src: string): void {
    const images = [
      {
        src,
        width: 'auto',
        height: 'auto',
        alt: material.name
      }
    ];
    this.nzImageService.preview(images, {nzZoom: 1.0, nzRotate: 0});
  }

  onPDFPreview(material: DocumentListResp, src: string): void {

    const drawerRef = this.drawerService.create<PdfViewerComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: PdfViewerComponent,
      nzContentParams: {
        src
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const pdfViewerComponent = drawerRef.getContentComponent();
      pdfViewerComponent.autoresize = true;
      pdfViewerComponent.showAll = true;
      pdfViewerComponent.originalSize = false;
      pdfViewerComponent.fitToPage = true;
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  // 下载
  onDownload(attachment: DocumentListResp, type): void {
    // this.btnLoading = true;
    this.loading = true;
    // console.log('-', type);
    this.storageService.accessToken(attachment.url)
      .subscribe(
        data => {
          // console.log(data);
          this.downloadService.download(data.accessToken, attachment.name);
          this.loading = false;
        },
        err => {
          this.loading = false;
        });
  }

  onRemark(document: DocumentListResp, tplRemarkTitle: TemplateRef<any>, tplRemarkContent: TemplateRef<any>, tplRemarkFooter: TemplateRef<any>): void {
    const params = JSON.parse(JSON.stringify(document));
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplRemarkTitle,
      nzContent: tplRemarkContent,
      nzFooter: tplRemarkFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: params,
      nzOnOk: () => console.log('Click ok')
    });
  }

  onRemarkConfirm(ref: NzModalRef, document: DocumentListResp): void {
    // const params = plainToClass(DocumentListResp, document);
    const params = new Document();
    params.id = document.id;
    params.remark = document.remark;
    this.documentService.update(params)
      .subscribe(
        data => {
          this.onReload(false);
        },
        error => {
        },
        () => {
          ref.close();
        }
      );
  }

  onEdit(document: DocumentListResp, tplEditTitle: TemplateRef<any>, tplEditContent: TemplateRef<any>, tplEditFooter: TemplateRef<any>): void {
    const params = JSON.parse(JSON.stringify(document));
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplEditTitle,
      nzContent: tplEditContent,
      nzFooter: tplEditFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: params,
      nzOnOk: () => console.log('Click ok')
    });
  }

  onEditConfirm(ref: NzModalRef, document: DocumentListResp): void {
    // const params = plainToClass(DocumentListResp, document);
    const params = {
      id: document.id,
      name: document.name
    };
    this.documentService.updateName(params)
      .subscribe(
        data => {
          this.onReload(false);
        },
        error => {
        },
        () => {
          ref.close();
        }
      );
  }


  // 点击面包屑导航
  breadcrumb(data, i): void {
    if (i === this.fileNav.length - 1) {
      return;
    }
    // console.log(this.fileNav);
    // console.log(this.fileNav.length, '----------', i);
    // console.log(data);
    let fileIndex = 0;
    this.fileNav.forEach((item, index) => {
      // console.log(item);
      if (item.id === data.id) {
        fileIndex = index;
      }
    });
    // console.log(this.fileNav[fileIndex].pageNum);
    // this.page.pageNum = this.fileNav[fileIndex + 1].pageNum;
    this.page.pageNum = this.fileNav[fileIndex].pageNum;
    const params = Object.assign({}, this.searchCondition, {
      id: data.id,
      pageNum: this.page.pageNum,
      pageSize: this.page.pageSize
    });

    this.fileNav.splice(fileIndex + 1);
    this.currFolderId = this.fileNav[fileIndex].id;
    this.documentList(params);
  }

  // 面包屑
  enterFolder(record): void {
    if (record.type === 'DIRECTORY') {
      const params = Object.assign({}, this.searchCondition, {
        id: record.id,
        pageNum: 1,
        pageSize: this.page.pageSize
      });
      this.documentList(params);
      this.currFolderId = record.id;
      this.fileNav.push({
        label: record.name,
        id: record.id,
        pageNum: this.page.pageNum
      });
      this.page.pageNum = 1;
    }
  }

  // 清空面包屑导航
  resetFileNav(): void {
    this.fileNav = [
      {
        label: 'ALL',
        id: 0
      }
    ];
  }

  handleCancel(): void {
    this.isVisible = false;
  }

}
