<div class="drawer-container" style="height: 100%;">

  <div class="drawer-title" style="margin-bottom: 30px">
    {{'AddAccount' | translate}}
  </div>

  <nz-steps nzType="navigation" nzSize="small" [nzCurrent]="index" (nzIndexChange)="onIndexChange($event)">
    <nz-step
      *ngIf="isBack"
      [nzTitle]="'OrganizationCode' | translate"
      [nzStatus]="index === 0 ? 'process' : 'finish'">

    </nz-step>

    <nz-step
      [nzTitle]="'LoginByAccount' | translate"
      [nzStatus]="index === 1 ? 'process' : 'wait'"
      [nzDisabled]="index !== 1">

    </nz-step>

  </nz-steps>

  <div *ngIf="index === 0" style="width: 100%; height: 100%">
    <div class="instance-code-container">
      <div nz-row nzJustify="center" style="width: 100%; height: 100%;">
        <div nz-col [nzSpan]="12" class="instance-code-column">
          <form (ngSubmit)="checkInstance()" style="width: 100%" class="instance-code-column">
            <img width="150" [src]="instance.logo || 'assets/images/logo.png'" style="margin-bottom: 40px">
            <div class="instance-code-sub-title" style="margin-bottom: 10px">{{ 'OrgCode' | translate
              }}
            </div>
            <div style="width: 50%;">
              <nz-input-group [nzSuffix]="suffixIconAccount" class="input-group">
                <input required [(ngModel)]="code" id="code" name="code" autofocus="autofocus"
                       class="instance-code-input"/>
              </nz-input-group>
              <ng-template #suffixIconAccount>
              </ng-template>
            </div>
            <div style="margin-top: 30px;">
              <button style="width: 60px; height: 60px; margin-left: 10px;" type="submit" [nzLoading]="instanceChecking"
                      [disabled]="code.length === 0"
                      nz-button nzType="default" nzShape="circle">
                <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="index === 1" style="width: 100%; height: 100%">
    <div class="login-container">

      <div nz-row nzJustify="center" style="width: 100%; height: 100%;">
        <!--        <div nz-col [nzSpan]="10" class="login-column">-->
        <!--            <img width="75%" src="./assets/images/login_illustration.svg">-->
        <!--        </div>-->
        <div nz-col [nzSpan]="12" class="login-column">
          <form (ngSubmit)="loginByAccount()" #loginForm="ngForm" style="width: 100%" class="login-column">
            <img width="150" [src]="instance?.logo || 'assets/images/logo.png'" style="margin-bottom: 40px" alt="">
            <div class="login-title">{{ "LoginByAccount" | translate }}</div>
            <div style="width: 50%; margin-top: 20px;">

              <nz-input-group [nzSuffix]="suffixAccount" class="input-group">
                <input required [(ngModel)]="loginReq.accountName" id="account" name="account" autofocus="autofocus"
                       [placeholder]="'Account' | translate" class="login-input"/>
              </nz-input-group>
              <ng-template #suffixAccount>
                <i nz-tooltip [nzTooltipTitle]="'DeviceLimitDescription' | translate" style="margin-top: 10px; font-size: 13px" nz-icon nzType="info-circle"></i>
              </ng-template>

            </div>
            <div style="width: 50%; margin-top: 20px;">

              <nz-input-group [nzSuffix]="suffixPassword" class="input-group">
                <input required [(ngModel)]="loginReq.password" id="password" name="password" [type]="passwordVisible ? 'text' : 'password'"
                       [placeholder]="'Password' | translate" class="login-input"/>
              </nz-input-group>
              <ng-template #suffixPassword>
                <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" appThrottleClick (throttleClick)="passwordVisible = !passwordVisible"></i>
              </ng-template>

            </div>
            <div style="width: 50%; text-align: left; margin: 10px 0;">
            </div>
            <div style="margin-top: 30px;">
              <button style="width: 60px; height: 60px; color: #13263f" type="submit" [nzLoading]="logging"
                      nz-tooltip [nzTooltipTitle]="'Login' | translate"
                      nz-button nzType="default" nzShape="circle">
                <i nz-icon nzType="login"></i>
              </button>
            </div>
            <div style="width: 40%;">
              <nz-divider></nz-divider>
            </div>
            <div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #disclaimerTitle>
  <span style="font-size: 24px">{{'Disclaimer' | translate}}</span>
</ng-template>
<ng-template #disclaimerContent let-params>
  <div style="overflow-y: scroll; height: 580px">
    <p>{{ params.value }}</p>
  </div>
</ng-template>
<ng-template #disclaimerFooter let-ref="modalRef">
  <button nz-button class="disclaimer-button" nzType="dashed" nzShape="round" appThrottleClick (throttleClick)="onDisagree(ref)">
    {{'Disagree' | translate}}
  </button>
  <button #agreeButton id="agreeButton" nz-button class="disclaimer-button disclaimer-agree-button" nzType="default" nzShape="round" appThrottleClick (throttleClick)="onAgree(ref)" autofocus="autofocus">
    {{'Agree' | translate}}
  </button>
</ng-template>

