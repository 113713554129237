import { Component, OnInit } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { ProposalApplyComponent } from '../../proposal/proposal-apply/proposal-apply.component';
import { Router } from '@angular/router';
import { BookingCreateComponent } from '../../booking/booking-create/booking-create.component';
import { Product } from '../product-types';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MetadataService } from '../../shared/service/metadata.service';
import { ProductService } from '../product.service';
import {
  PremiumFinancingInfoComponent
} from '../../proposal/components/premium-financing-info/premium-financing-info.component';
import { plainToInstance } from 'class-transformer';
import { BankInfo } from '../../proposal/proposal-types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.less']
})
export class ProductDetailComponent implements OnInit {

  loading = false;

  drawerRef: NzDrawerRef<ProductDetailComponent, string>;

  product: Product;

  constructor(private drawerService: NzDrawerService,
              private router: Router,
              private productService: ProductService,
              private metadataService: MetadataService,
              private translate: TranslateService,
              private modalService: NzModalService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.productService.detail(this.product.productCode)
      .subscribe(
        data => {
          this.loading = false;
          this.product = data;
        },
        error => {
          this.loading = false;
        });
  }

  onProposalApply(): void {
    const drawerRef = this.drawerService.create<ProposalApplyComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: false,
      nzContent: ProposalApplyComponent,
      nzContentParams: {}
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.productSelected(this.product);
      this.drawerRef.close();
      component.proposalApplied
        .subscribe(data => {
          this.router.navigate(['/user/proposal']).then();
        });
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onReservation(): void {
    const instance = this.metadataService.mockInstance;
    if (instance.isFoneOrDev()) {
      this.translate.get(['FNAToBooking', 'Tips']).subscribe(
        res => {
          this.modalService.info({
            nzCentered: true,
            nzTitle: res.Tips,
            nzContent: res.FNAToBooking,
            nzClosable: true,
            nzOnOk: () => {},
          });
        }
      );
      return;
    }
    const drawerRef = this.drawerService.create<BookingCreateComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: BookingCreateComponent,
      nzContentParams: {}
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.assignFromProduct(this.product);
      this.drawerRef.close();
      component.bookingDraftSaved.subscribe(
        data => {
          this.router.navigate(['/user/booking']).then();
        }
      );
      component.bookingApplied.subscribe(
        data => {
          this.router.navigate(['/user/booking']).then();
        }
      );
    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onComparison(): void {
    if (!this.product.comparable) { return; }
    this.drawerRef?.close();
    this.router.navigate(['/user/product/comparison'], {state: {product: this.product}}).then();
  }

  onQuotation(): void {
    if (!this.product.quotable) { return; }
    this.drawerRef?.close();
    this.router.navigate(['user/quotation'], {state: {product: this.product}}).then();
  }
}
