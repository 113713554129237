import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leave-word-main',
  templateUrl: './leave-word-main.component.html',
  styleUrls: ['./leave-word-main.component.less']
})
export class LeaveWordMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
