import {EventEmitter, Injectable} from '@angular/core';
import {HttpService} from '../core/http.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BaseService} from '../base/base.service';
import { CreateFolder, Document, DocumentListResp, DocumentSearch, DocumentSpace, FileUpload } from './document-types';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResponse, DataMode, PagedReq, PagedResp} from '../api/types';
import {map} from 'rxjs/operators';
import {API} from '../api/api';
import { plainToInstance } from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends BaseService {

  fileCollection: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpService, private httpClient: HttpClient) {
    super();
  }

  /**
   * 文件列表
   * @param documentSearch 获取
   * @param type 数据类型
   */
  list(documentSearch: DocumentSearch, type: DataMode): Observable<PagedResp<DocumentListResp>> {
    let httpParams = new HttpParams();
    if (documentSearch.pageNum != null) {
      httpParams = httpParams.set('pageNum', documentSearch.pageNum.toString());
    }
    if (documentSearch.pageSize != null) {
      httpParams = httpParams.set('pageSize', documentSearch.pageSize.toString());
    }
    if (documentSearch.classify != null && documentSearch.classify !== '' ) {
      httpParams = httpParams.set('classify', documentSearch.classify.toString());
    }
    if (documentSearch.id != null) {
      httpParams = httpParams.set('id', documentSearch.id.toString());
    }
    if (documentSearch.name != null) {
      httpParams = httpParams.set('name', documentSearch.name.toString());
    }
    let uri = '';
    switch (type) {
      case DataMode.OWN:
        uri = API.documentV3 + '/search';
        break;
      case DataMode.SUBORDINATE:
        uri = API.documentV4 + '/search';
        break;
      case DataMode.SALESGROUP:
        uri = API.documentV3 + '/collect-list';
        break;
    }
    return this.http.get<ApiResponse<PagedResp<DocumentListResp>>>(uri, {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 创建文件夹
   * @param createFolder 创建文件夹
   */
  create(createFolder: CreateFolder): Observable<any> {
    return this.http.post<ApiResponse<CreateFolder>>(API.documentV3, createFolder, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 删除文件&文件夹
   * @param id 资料ID
   */
  deleteFile(id: number): Observable<boolean> {
    return this.http.delete<ApiResponse<boolean>>(API.documentV3 + '/' + id, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 收藏 || 取消收藏
   * @param id 资料ID
   * @param type 是否收藏
   */
  isCollection(id: number, type: boolean): Observable<boolean> {
    let uri = '';
    if (!type) {
      uri = '/collect/'; // 收藏
    }else {
      uri = '/collect-cancel/'; // 取消收藏
    }
    return this.http.put<ApiResponse<boolean>>(API.documentV3 + uri + id, null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 最近使用列表
   * @param req 页数&数据条
   */
  usedList(): Observable<any> {
    return this.http.get<ApiResponse<DocumentListResp>>(API.documentV3 + '/used-list', {
      params: null,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  /**
   * 文件上传
   * @param req 参数
   */
  upload(req: FileUpload): Observable<any> {
    return this.http.post<ApiResponse<FileUpload>>(API.documentV3 + '/upload', req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  // /**
  //  * 收藏列表
  //  * @param req 获取
  //  */
  favoriteList(req: PagedReq): Observable<PagedResp<DocumentListResp>> {
    let httpParams = new HttpParams();
    if (req.pageNum != null) {
      httpParams = httpParams.set('pageNum', req.pageNum.toString());
    }
    if (req.pageSize != null) {
      httpParams = httpParams.set('pageSize', req.pageSize.toString());
    }
    return this.http.get<ApiResponse<PagedResp<DocumentListResp>>>(API.documentV3 + '/collect-list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

  space(): Observable<DocumentSpace> {
    return this.http.get<ApiResponse<DocumentSpace>>(API.documentV3 + '/space', {
      params: null,
      observe: 'response'
    }).pipe(map(data => {
      return plainToInstance(DocumentSpace, data.body.data);
    }));
  }

  /**
   * 创建文件夹
   * @param document 创建文件夹
   */
  update(document: Document): Observable<any> {
    return this.http.put<ApiResponse<Document>>(API.documentV3 + '/update', document, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 个人文件查重命名
   * @param document: { id, name }
   */
  updateName(document): Observable<any> {
    return this.http.put<ApiResponse<Document>>(API.documentV3 + '/name/update', document, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }

}
