<div style="padding: 10px 0 10px 0">
  <div>{{'Rider' | translate}}</div>

  <div class="riders">
    <div *ngIf="riders">
      <div *ngFor="let rider of riders; let i = index">
        <div class="rider">
          <div style="flex: 1">
            <div>{{rider.productName}}</div>
            <div *ngIf="isNewFna">
              <span>{{'GuaranteePeriod' | translate}}: {{rider.policyPeriod}}</span>
            </div>
            <div [ngSwitch]="mode">
              <div *ngSwitchCase="'DEFAULT'">
                <div>
                  <span>{{'Premium' | translate}}
                    : {{rider.premium ?? '0' | number}}{{currency | metadataTranslate:'currency'}}</span>
                  <span style="margin-left: 5px">{{'SumAssured' | translate}}
                    : {{rider.sumAssured ?? '0' | number}}{{currency | metadataTranslate:'currency'}}</span>
                </div>
                <div *ngIf="rider.categoryCode === 'MEDICAL'">
                  <span>{{'Deductible' | translate}}
                    : {{rider.medicalPlan.deductible ?? '0' | number}}{{currency | metadataTranslate:'currency'}}</span>
                </div>
              </div>
              <div *ngSwitchCase="'FNA'">
                <div>
<!--                  <span>{{'GuaranteePeriod' | translate}}: {{rider.policyPeriod}}</span>-->
                </div>
              </div>
            </div>
          </div>
          <div>
            <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Edit' | translate"
               appThrottleClick (throttleClick)="onEdit(i)">
              <img alt="" width="20" src="assets/images/ic_details.svg">
            </a>
            <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Delete' | translate"
               appThrottleClick (throttleClick)="onDelete(i)">
              <img alt="" width="20" src="assets/images/ic_delete.svg">
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="rider-box" appThrottleClick (throttleClick)="onAdd()">
      <a class="rider-button" nz-button nzType="link"> + </a>
      <span style="margin-left: 10px">{{ 'AddRider' | translate }}</span>
    </div>

  </div>
</div>
