import {Component, EventEmitter, OnInit} from '@angular/core';
import {Product} from '../../product/product-types';
import {Premium, SumRange} from '../../quotation/quotation-types';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {MetadataService} from '../../shared/service/metadata.service';
import {QuotationService} from '../../quotation/quotation.service';
import {CommissionService} from '../../commission/commission.service';
import {ProductSelectionComponent} from '../../shared/component/product-selection/product-selection.component';
import {SellStatus, SumRangeResp} from '../../api/types';
import {Calculate, CalculateRes, ListVO} from '../../commission/commission-types';
import {CommissionForecastService} from '../commission-forecast.service';
import {debounceTime} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ContractSelectComponent} from '../contract-select/contract-select.component';
import {ContractDetailComponent} from '../contract-detail/contract-detail.component';

@Component({
  selector: 'app-commission-forecasts',
  templateUrl: './commission-forecasts.component.html',
  styleUrls: ['./commission-forecasts.component.less']
})
export class CommissionForecastsComponent implements OnInit {
  spinning = false;
  product: Product = new Product();
  premium: Calculate = new Calculate(); // 试算参数
  ageValue: SumRangeResp = new SumRangeResp();
  calculationRes: any;
  proportionRes: any;
  currency: string;
  selectIndex = 0;

  contractDetail: ListVO = new ListVO();

  contractData: CalculateRes[] = [];

  searchParamChange: EventEmitter<any> = new EventEmitter<any>();

  currencyOptions: PropertySelectOption[] = [];
  paymentTermOptions: PropertySelectOption[] = [];
  constructor(private drawerService: NzDrawerService,
              private metadataService: MetadataService,
              private commissionForecastService: CommissionForecastService,
              private toastr: ToastrService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.searchParamChange.pipe(debounceTime(1000))
      .subscribe(
        data => {
          this.onCalculate();
        }
      );
  }

  onReload(): void {
    this.product = new Product();
    this.premium = new Calculate(); // 试算参数
    this.ageValue = new SumRangeResp();
    this.calculationRes = undefined;
    this.proportionRes = undefined;
    this.currency = undefined;
    this.contractData = [];
    this.contractDetail = new ListVO();

  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: any }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        copyProduct: this.product
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.search.sellStatus = SellStatus.SELLING;
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.productSelected(product);
            drawerRef.close();
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onContractSelection(): void {
    const drawerRef = this.drawerService.create<ContractSelectComponent, { value: any }, string>({
      nzWidth: 600,
      nzContent: ContractSelectComponent,
      nzContentParams: {
        info: this.contractData,
        selectIndex: this.selectIndex
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.contractSelected
        .subscribe(
          listVO => {
            this.contractDetail = listVO.info;
            if (listVO.info.list.length) {
              this.calculationRes = this.hasDot(listVO.info?.list[0]?.amount);
              this.proportionRes = Number(listVO.info?.list[0]?.rate) + '%';
              this.currency = listVO.info?.currency;
              this.selectIndex = listVO.index;
            } else {
              this.calculationRes = undefined;
              this.proportionRes = undefined;
              this.currency = undefined;
            }
            drawerRef.close();
          }
        );
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onContractDetail(): void {
    const drawerRef = this.drawerService.create<ContractDetailComponent, { value: any }, string>({
      nzWidth: 600,
      nzContent: ContractDetailComponent,
      nzContentParams: {
        contractDetail: this.contractDetail
      }
    });
  }

  productSelected(product: Product): void {
    this.premium = new Calculate();
    this.product = product;
    this.ageValue = product.ageRange;
    this.premium.productCode = this.product.productCode;

    this.currencyOptions = [];
    this.product.currencies?.forEach(currency => {
      this.currencyOptions.push(new PropertySelectOption(currency, currency));
    });
    this.premium.currency = this.product.currencies ? this.product.currencies[0] : null;

    this.paymentTermOptions = [];
    this.product.paymentTerm?.forEach(term => {
      this.paymentTermOptions.push(new PropertySelectOption(term, term));
    });
    this.premium.paymentTerm = null;
  }

  get ageMin(): number {
    return this.ageValue?.min;
  }

  get ageMax(): number {
    return this.ageValue?.max;
  }

  hasDot(num): string {
    if (num){
      return ( (num + '').indexOf('.') !== -1 ) ? num : num.toFixed(2);
    }
  }

  get canResult(): boolean {
    if (this.spinning) {
      return false;
    }
    if (this.premium.productCode === undefined) {
      return false;
    }
    if (this.premium.paymentTerm === null) {
      return false;
    }
    if (this.premium.premium === undefined) {
      return false;
    }
    return true;
  }

  // 请求
  onCalculate(): void {
    if (!this.canResult) {
      return ;
    }
    this.spinning = true;
    this.translate.get('CommissionLoadingTip').subscribe(
      (res) => {
        this.toastr.warning(res);
      }
    );
    this.commissionForecastService.calculate(this.premium)
      .subscribe(data => {
        if (data[0].listVO.list.length) {
          this.calculationRes = this.hasDot(data[0].listVO?.list[0]?.amount);
          this.proportionRes = Number(data[0].listVO?.list[0]?.rate) + '%';
          this.currency = data[0].listVO?.currency;
        } else {
          this.calculationRes = undefined;
          this.proportionRes = undefined;
          this.currency = undefined;
        }
        this.contractDetail = data[0].listVO;
        this.contractData = data;
        this.spinning = false;
      },
        () => {
          this.spinning = false;
        });
  }

  premiumChange(): void {
    this.searchParamChange.emit('');
  }

}
