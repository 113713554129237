import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import {
  ProductSelectionComponent,
  ProductSelectionType
} from '../../shared/component/product-selection/product-selection.component';
import { Currency, Gender, ProposalAmountType, Relationship, SmokeCondition } from '../../api/types';
import { PropertySelectOption } from '../../shared/component/property-select/property-select.component';
import { TranslateService } from '@ngx-translate/core';
import { MetadataService } from '../../shared/service/metadata.service';
import { ProductService } from '../../product/product.service';
import { ProposalService } from '../proposal.service';
import { BottomSheetService } from '../../shared/modules/bottom-sheet/bottom-sheet.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  ProposalWithdrawalEditComponent
} from '../components/proposal-withdrawal-edit/proposal-withdrawal-edit.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  DynamicField,
  Product,
  ProductListResp,
  ProductRestrictionReq,
  ProductRestrictionType, RiderDynamicField
} from '../../product/product-types';
import { BaseComponent } from '../../base/base.component';
import {
  AgeRestriction,
  AmountRestriction,
  BankInfo,
  DiscountInfo, HedgePlan,
  Proposal,
  ProposalBalance, ProposalBalanceLimit,
  Riders,
  Withdrawal, WithdrawalRestriction
} from '../proposal-types';
import { ToastrService } from 'ngx-toastr';
import { PremiumFinancingInfoComponent } from '../components/premium-financing-info/premium-financing-info.component';
import { WorkbenchService } from '../../workbench/workbench.service';
import { Workbench } from '../../workbench/workbench-types';
import { RegionSelectionComponent } from '../../shared/component/region-selection/region-selection.component';
import { ProposalAdditionaComponent } from '../components/proposal-additiona/proposal-additiona.component';
import { ProposalHedgePlanComponent } from '../components/proposal-hedge-plan/proposal-hedge-plan.component';
import { debounceTime } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Policy } from '../../policy/policy-types';

declare type BirthdayOrAge = 'birthday' | 'age';

@Component({
  selector: 'app-proposal-apply',
  templateUrl: './proposal-apply.component.html',
  styleUrls: ['./proposal-apply.component.less']
})
export class ProposalApplyComponent extends BaseComponent implements OnInit {

  loading = false;

  drawerRef: NzDrawerRef<ProposalApplyComponent, string>;

  applyReq: Proposal = new Proposal();

  workbench: Workbench;

  birthdayOrAgeOfInsured: BirthdayOrAge = 'age';
  birthdayOrAgeOfHolder: BirthdayOrAge = 'age';
  isFlexPremiums: boolean; // 是否支持弹性保费
  flexPremiumsOptions: PropertySelectOption[] = []; // 弹性保费选项（年）
  copyDynamicFields: any[] = [];
  flexPremiumsOptionsData: number[]; // 弹性保费选项数据

  product: Product = new Product();
  balance: ProposalBalance;
  balanceLimit: ProposalBalanceLimit[] = [];
  isVisible = false;
  editWithdrawalIndex: number = null;

  languageOptions: PropertySelectOption[] = [];
  currencyOptions: PropertySelectOption[] = [];
  paymentTermOptions: PropertySelectOption[] = [];
  paymentFrequencyOptions: PropertySelectOption[] = [];
  prepayOptions: PropertySelectOption[] = [];
  birthdayOrAgeOptions: PropertySelectOption[] = [];
  genderOptions: PropertySelectOption[] = [];
  smokeOptions: PropertySelectOption[] = [];
  reduceBasicOptions: PropertySelectOption[] = [];
  regionOptions: PropertySelectOption[] = [];
  relationshipOptions: PropertySelectOption[] = [];
  amountTypeOptions: PropertySelectOption[] = [];

  guaranteedAreaOptions: PropertySelectOption[] = [];
  medicalLevelOptions: PropertySelectOption[] = [];
  deductibleOptions: PropertySelectOption[] = [];
  supplementaryBenefitOptions: PropertySelectOption[] = [];

  ageRestrictions: string[] = [];
  medicalOptionsRequiredFields: string[] = [];
  amountRestrictions: string[] = [];

  sumInsuredRestriction: AmountRestriction; // 保額限制 - 當code、premiumTerm、type、currency參數都傳時，才會有數據（如果有錄入數據）
  premiumRestriction: AmountRestriction; // 保費限制 - 當code、premiumTerm、type、currency參數都傳時，才會有數據（如果有錄入數據）
  ageRestriction: AgeRestriction; // 年齡限制 - 當code、premiumTerm、type參數都傳時，才會有數據（如果有錄入數據）
  withdrawalRestriction: WithdrawalRestriction = new WithdrawalRestriction(); // 现金提取限制 - 當code、premiumTerm、type參數都傳時，才會有數據（如果有錄入數據）

  ridersArrData: ProductListResp[] = [];
  hedgeArrData: ProductListResp[] = [];

  @Output()
  proposalApplied: EventEmitter<any> = new EventEmitter<any>();

  ageLinkageChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('withdrawalEditor', {read: ElementRef})
  withdrawalEditorRef: ElementRef;

  constructor(private router: Router,
              private translate: TranslateService,
              private metadataService: MetadataService,
              private productService: ProductService,
              private proposalService: ProposalService,
              private drawerService: NzDrawerService,
              private modalService: NzModalService,
              private bottomSheetService: BottomSheetService,
              private notificationService: NzNotificationService,
              private workbenchService: WorkbenchService,
              private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.translate.get('Age').subscribe(
      (age: string) => {
        this.birthdayOrAgeOptions.push(new PropertySelectOption(age, 'age'));
      });
    this.translate.get('Birthday').subscribe(
      (birthday: string) => {
        this.birthdayOrAgeOptions.push(new PropertySelectOption(birthday, 'birthday'));
      });

    this.translate.get('Yes').subscribe(
      (age: string) => {
        this.prepayOptions.push(new PropertySelectOption(age, true));
      });
    this.translate.get('No').subscribe(
      (birthday: string) => {
        this.prepayOptions.push(new PropertySelectOption(birthday, false));
      });

    const genders = this.metadataService.values('gender');
    for (const gender of genders) {
      this.genderOptions.push(new PropertySelectOption(gender.value, gender.key));
    }

    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'S'), SmokeCondition.S));
    this.smokeOptions.push(new PropertySelectOption(this.metadataService.translate('smoke', 'NS'), SmokeCondition.NS));

    this.reduceBasicOptions.push(new PropertySelectOption(this.metadataService.translate('withdrawalType', 'PARTIAL_WITHDRAWAL'), true));
    this.reduceBasicOptions.push(new PropertySelectOption(this.metadataService.translate('withdrawalType', 'PERIODIC_WITHDRAWAL'), false));

    const regions = this.metadataService.values('residenceRegion');
    for (const region of regions) {
      this.regionOptions.push(new PropertySelectOption(region.value, region.key));
    }

    const relationships = this.metadataService.values('proposalRelationship');
    for (const relationship of relationships) {
      this.relationshipOptions.push(new PropertySelectOption(relationship.value, relationship.key));
    }

    const proposalAmountTypes = this.metadataService.values('proposalAmountType');
    for (const proposalAmountType of proposalAmountTypes) {
      this.amountTypeOptions.push(new PropertySelectOption(proposalAmountType.value, proposalAmountType.key));
    }
    if (proposalAmountTypes && proposalAmountTypes.length > 0 && !this.applyReq.proposalAmountType) {
      this.applyReq.proposalAmountType = proposalAmountTypes[0].key as ProposalAmountType;
    }

    this.proposalService.balance()
      .subscribe(
        balance => {
          this.balance = balance;
        }
      );

    this.proposalService.balanceLimit()
      .subscribe(
        balance => {
          this.balanceLimit = balance;
        }
      );
    this.workbench = this.workbenchService.workbench;

    this.ageLinkageChange.pipe(debounceTime(500))
      .subscribe(
        data => {
          if (parseFloat(String(this.applyReq.age)).toString() !== 'NaN' && this.applyReq.age < 18) {
            this.applyReq.relationship = Relationship.PARENT;
            this.applyReq.owner.age = this.applyReq.age + 30;
            this.applyReq.owner.gender = Gender.MALE;
            this.applyReq.owner.smoke = SmokeCondition.NS;
            this.applyReq.owner.residenceRegion = this.applyReq.residenceRegion;
          }
        }
      );

  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.PROPOSAL,
        copyProduct: this.product
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      // selectionComponent.productSelected
      //   .subscribe(
      //     product => {
      //       this.productSelected(product);
      //       drawerRef.close();
      //     }
      //   );
      selectionComponent.productInfo
        .subscribe(
          info => {
            // console.error(info);
            drawerRef.close();
            this.productSelected(info);
            this.medicalOptionsRequiredFields = info.medicalOptionsRequiredField;
            this.applyReq.reduceBasic = !(info.companyCode === 'AXA' || info.companyCode === 'AXAMACAU');
            if (info.proposalRiders && info.proposalRiders.length) {
              this.proposalService.getRidersList({riderProductCodes: info.proposalRiders.join(',')})
                .subscribe(data => {
                  data.map(rider => {
                    if (rider.dynamicFields) {
                      if (rider.dynamicFields.length) {
                        rider.dynamicFields.map(item => {
                          const res: PropertySelectOption[] = [];
                          if (item.fieldType === 'array') {
                            const copyArr = item.fieldValue.split(',');
                            for (const arr of copyArr) {
                              res.push(new PropertySelectOption(arr, arr));
                            }
                            item.options = res;
                          }
                        });
                      }
                    } else {
                      rider.dynamicFields = [];
                    }
                  });
                  this.ridersArrData = data;
                });
            }
            if (info.features && info.features.hedgeable) {
              this.proposalService.getHedgeList({productCodes: info.features.hedgeProducts.join(',')})
                .subscribe(data => {
                  this.hedgeArrData = data;
                });
            }
            if (info.dynamicFields) {
              if (info.dynamicFields.length) {
                info.dynamicFields.map(item => {
                  const res: PropertySelectOption[] = [];
                  if (item.fieldType === 'array') {
                    const copyArr = item.fieldValue.split(',');
                    for (const arr of copyArr) {
                      res.push(new PropertySelectOption(arr, arr));
                    }
                    item.options = res;
                  }
                });
              }
              this.applyReq.dynamicFields = info.dynamicFields;
            } else {
              this.applyReq.dynamicFields = [];
            }
            this.isFlexPremiums = info.flexiPremiumSupported;
            if (info.flexiPremiumOptions) {
              for (const item of info.flexiPremiumOptions) {
                this.flexPremiumsOptions.push(new PropertySelectOption(item.toString(), item));
              }
            }
            if (info.proposalPrepaymentSupported === true) {
              this.applyReq.prepay = false;
            }
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  medicalRequiredField(str: string): boolean {
    if (str) {
      return this.product?.medicalOptionsRequiredField?.includes(str);
    } else {
      return false;
    }
  }

  // 保费融资
  onPremiumFinancing(val): void {
    let str = '';
    if (val === 'bank') {
      this.translate.get('BackInfo').subscribe(
        title => {
          str = title;
        }
      );
    } else {
      this.translate.get('DiscountInfo').subscribe(
        title => {
          str = title;
        }
      );
    }
    const drawerRef = this.drawerService.create<PremiumFinancingInfoComponent, { value: any }, string>({
      nzWidth: 600,
      nzContent: PremiumFinancingInfoComponent,
      nzContentParams: {
        financingType: val,
        title: str,
        modifyState: false,
        bankInfo: !!Object.keys(this.applyReq.premiumFinancing.bankInfo).length ? this.applyReq.premiumFinancing.bankInfo : new BankInfo(),
        discountInfo: !!Object.keys(this.applyReq.premiumFinancing.discountInfo).length ? this.applyReq.premiumFinancing.discountInfo : new DiscountInfo()
      }
    });
    // plainToClass(BankInfo, {}, {excludeExtraneousValues: true})
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      if (val === 'back') {
        component.backInfoSaved.subscribe(
          data => {
            this.applyReq.premiumFinancing.bankInfo = data;
            console.log(this.applyReq.premiumFinancing);
            drawerRef.close();
          }
        );
      } else {
        component.discountSaved.subscribe(
          data => {
            this.applyReq.premiumFinancing.discountInfo = data;
            console.log(this.applyReq.premiumFinancing);
            drawerRef.close();
          }
        );
      }
    });
  }

  reuse(product: ProductListResp, proposal: Proposal): void {
    console.log(product);
    console.log(proposal);
    this.isFlexPremiums = product.flexiPremiumSupported;
    if (product.flexiPremiumSupported) {
      for (const item of product.flexiPremiumOptions) {
        this.flexPremiumsOptions.push(new PropertySelectOption(item.toString(), item));
      }
    }
    if (product.features && product.features.hedgeable) {
      this.proposalService.getHedgeList({productCodes: product.features.hedgeProducts.join(',')})
        .subscribe(data => {
          this.hedgeArrData = data;
        });
    }
    if (product.proposalRiders && product.proposalRiders.length) {
      this.proposalService.getRidersList({riderProductCodes: product.proposalRiders.join(',')})
        .subscribe(data => {
          data.map(rider => {
            if (rider.dynamicFields) {
              if (rider.dynamicFields.length) {
                rider.dynamicFields.map(item => {
                  const res: PropertySelectOption[] = [];
                  if (item.fieldType === 'array') {
                    const copyArr = item.fieldValue.split(',');
                    for (const arr of copyArr) {
                      res.push(new PropertySelectOption(arr, arr));
                    }
                    item.options = res;
                  }
                });
              }
            } else {
              rider.dynamicFields = [];
            }
          });
          this.ridersArrData = data;
          if (proposal.riders && proposal.riders.length && this.ridersArrData && this.ridersArrData.length) {
            proposal.riders.forEach((rider, j) => {
              rider.dynamicFields.forEach((item, index) => {
                item.value = null;
                if (item.fieldType === 'array') {
                  this.ridersArrData.find(dyArr => dyArr.productCode === rider.productCode)
                    ?.dynamicFields.forEach(dyItem => { if (dyItem.fieldName === item.fieldName) { item.options = dyItem.options; } });
                }
              });
            });
          }
        });
    }

    if (proposal.riders && proposal.riders.length) {
      proposal.riders.forEach(item => {
        if (item.categoryCode === 'MEDICAL') {
          if (item.medicalPlan.currency) {
            const str = item.medicalPlan.deductible + ' ' + item.medicalPlan.currency;
            item.medicalPlan.deductible = str;
          }
        }
      });
    }
    this.product = product;
    this.applyReq = proposal;
    this.applyReq.productCode = this.product.productCode;
    if (product.dynamicFields && product.dynamicFields.length && proposal.dynamicFields && proposal.dynamicFields.length) {
      proposal.dynamicFields.map((item, index) => {
        const res: PropertySelectOption[] = [];
        item.value = null;
        if (item.fieldType === 'array') {
          const copyArr = this.product.dynamicFields[index].fieldValue.split(',');
          for (const arr of copyArr) {
            res.push(new PropertySelectOption(arr, arr));
          }
          item.options = res;
        }
      });
    }

    this.languageOptions = [];
    this.product.proposalLanguages?.forEach(language => {
      this.languageOptions.push(new PropertySelectOption(language, language));
    });

    this.currencyOptions = [];
    this.product.currencies?.forEach(currency => {
      this.currencyOptions.push(new PropertySelectOption(currency, currency));
    });

    this.paymentTermOptions = [];
    this.product.paymentTerm?.forEach(term => {
      this.paymentTermOptions.push(new PropertySelectOption(term, term));
    });

    this.paymentFrequencyOptions = [];
    this.product.frequencies?.forEach(frequency => {
      this.paymentFrequencyOptions.push(new PropertySelectOption(this.metadataService.translate('frequency', frequency), frequency));
    });

    if (this.product.categoryCode === 'MEDICAL') {
      this.guaranteedAreaOptions = [];
      if (this.product.medicalOptions?.regions) {
        for (const region of this.product.medicalOptions?.regions) {
          this.guaranteedAreaOptions.push(new PropertySelectOption(this.metadataService.translate('medicalRegion', region), region));
        }
      }

      if (this.product.medicalOptions?.levels) {
        this.medicalLevelOptions = [];
        for (const level of this.product.medicalOptions?.levels) {
          this.medicalLevelOptions.push(new PropertySelectOption(this.metadataService.translate('medicalLevel', level), level));
        }
      }

      if (this.product.medicalOptions?.deductibles) {
        this.deductibleOptions = [];
        this.product.medicalOptions?.deductibles.forEach((deductible, index, array) => {
          if (deductible.currency === this.applyReq.currency) {
            for (const amount of deductible.amounts) {
              this.deductibleOptions.push(new PropertySelectOption(amount.toString(), amount));
            }
          }
        });
      }

      if (this.product.medicalOptions?.supplementaryBenefits) {
        this.supplementaryBenefitOptions = [];
        for (const supplementaryBenefit of this.product.medicalOptions?.supplementaryBenefits) {
          this.supplementaryBenefitOptions.push(new PropertySelectOption(this.metadataService.translate('supplementaryBenefit', supplementaryBenefit), supplementaryBenefit));
        }
      }

    } else { // not medical
      this.applyReq.coverageRegion = null;
      this.applyReq.medicalLevel = null;
      this.applyReq.deductible = null;
      this.applyReq.supplementaryBenefits = [];
    }

    switch (product.proposalAmountType) {
      case ProposalAmountType.BOTH:
        break;
      case ProposalAmountType.PREMIUM:
        this.applyReq.proposalAmountType = ProposalAmountType.PREMIUM;
        break;
      case ProposalAmountType.SUM_ASSURED:
        this.applyReq.proposalAmountType = ProposalAmountType.SUM_ASSURED;
        break;
    }

    if (!product.withdrawalable) {
      this.applyReq.doWithdrawals = false;
      this.applyReq.withdrawals = null;
    }

    // reset age
    this.onBirthdayOfInsuredChange(this.applyReq.birthday);
    this.onBirthdayOfHolderChange(this.applyReq.owner.birthday);

    this.getAgeRestriction();
    this.getAmountRestriction();

  }

  productSelected(product: Product): void {
    if (product.companyCode === 'PRUDENTIAL') {
      this.birthdayOrAgeOptions = [];
      this.translate.get('NextYearAge').subscribe(
        (age: string) => {
          this.birthdayOrAgeOptions.push(new PropertySelectOption(age, 'age'));
        });
      this.translate.get('Birthday').subscribe(
        (birthday: string) => {
          this.birthdayOrAgeOptions.push(new PropertySelectOption(birthday, 'birthday'));
        });
    }


    this.applyReq.age = undefined;
    this.applyReq.birthday = undefined;
    this.applyReq.owner.age = undefined;
    this.applyReq.owner.birthday = undefined;
    console.log(product);
    this.product = product;
    this.applyReq.productCode = this.product.productCode;

    if (product.dynamicFields) {
      if (product.dynamicFields.length) {
        product.dynamicFields.map(item => {
          const res: PropertySelectOption[] = [];
          if (item.fieldType === 'array') {
            const copyArr = item.fieldValue.split(',');
            for (const arr of copyArr) {
              res.push(new PropertySelectOption(arr, arr));
            }
            item.options = res;
          }
        });
      }
      this.applyReq.dynamicFields = product.dynamicFields;
    } else {
      this.applyReq.dynamicFields = [];
    }
    this.languageOptions = [];
    this.product.proposalLanguages?.forEach(language => {
      this.languageOptions.push(new PropertySelectOption(language, language));
    });

    this.applyReq.language = this.product.proposalLanguages ? this.product.proposalLanguages[0] : null;

    this.currencyOptions = [];
    this.product.currencies?.forEach(currency => {
      this.currencyOptions.push(new PropertySelectOption(currency, currency));
    });
    this.applyReq.currency = this.product.currencies ? this.product.currencies[0] : null;

    this.paymentTermOptions = [];
    this.product.paymentTerm?.forEach(term => {
      this.paymentTermOptions.push(new PropertySelectOption(term, term));
    });
    this.applyReq.paymentTerm = null;

    this.paymentFrequencyOptions = [];
    this.product.frequencies?.forEach(frequency => {
      this.paymentFrequencyOptions.push(new PropertySelectOption(this.metadataService.translate('frequency', frequency), frequency));
    });
    this.applyReq.frequency = null;

    if (this.product.categoryCode === 'MEDICAL') {

      this.guaranteedAreaOptions = [];
      if (this.product.medicalOptions?.regions) {
        for (const region of this.product.medicalOptions?.regions) {
          this.guaranteedAreaOptions.push(new PropertySelectOption(this.metadataService.translate('medicalRegion', region), region));
        }
      }

      if (this.product.medicalOptions?.levels) {
        this.medicalLevelOptions = [];
        for (const level of this.product.medicalOptions?.levels) {
          this.medicalLevelOptions.push(new PropertySelectOption(this.metadataService.translate('medicalLevel', level), level));
        }
      }

      if (this.product.medicalOptions?.deductibles) {
        this.deductibleOptions = [];
        this.product.medicalOptions?.deductibles.forEach((deductible, index, array) => {
          if (deductible.currency === this.applyReq.currency) {
            for (const amount of deductible.amounts) {
              this.deductibleOptions.push(new PropertySelectOption(amount.toString(), amount));
            }
          }
        });
      }

      if (this.product.medicalOptions?.supplementaryBenefits) {
        this.supplementaryBenefitOptions = [];
        for (const supplementaryBenefit of this.product.medicalOptions?.supplementaryBenefits) {
          this.supplementaryBenefitOptions.push(new PropertySelectOption(this.metadataService.translate('supplementaryBenefit', supplementaryBenefit), supplementaryBenefit));
        }
      }

    } else { // not medical
      this.applyReq.residenceRegion = null;
      this.applyReq.medicalLevel = null;
      this.applyReq.deductible = null;
      this.applyReq.supplementaryBenefits = [];
    }

    switch (product.proposalAmountType) {
      case ProposalAmountType.BOTH:
        break;
      case ProposalAmountType.PREMIUM:
        this.applyReq.proposalAmountType = ProposalAmountType.PREMIUM;
        break;
      case ProposalAmountType.SUM_ASSURED:
        this.applyReq.proposalAmountType = ProposalAmountType.SUM_ASSURED;
        break;
    }

    if (!product.withdrawalable) {
      this.applyReq.doWithdrawals = false;
      this.applyReq.withdrawals = null;
    }

    // reset age
    this.onBirthdayOfInsuredChange(this.applyReq.birthday);
    this.onBirthdayOfHolderChange(this.applyReq.owner.birthday);

    this.getAgeRestriction();
    this.getAmountRestriction();
  }

  onCurrencyChange(currency: Currency): void {
    this.deductibleOptions = [];
    this.product?.medicalOptions?.deductibles.forEach((deductible, index, array) => {
      if (deductible.currency === currency) {
        for (const amount of deductible.amounts) {
          this.applyReq.deductible = null;
          this.deductibleOptions.push(new PropertySelectOption(amount.toString(), amount));
        }
      }
    });
    this.getAmountRestriction();
  }

  onFrequencyChange(frequency: Currency): void {
    this.deductibleOptions = [];
    this.product?.medicalOptions?.deductibles.forEach((deductible, index, array) => {
      if (deductible.currency === frequency) {
        for (const amount of deductible.amounts) {
          this.deductibleOptions.push(new PropertySelectOption(amount.toString(), amount));
        }
      }
    });
    this.getAmountRestriction();
  }

  onBirthdayOrAgeOfInsuredChange(value: BirthdayOrAge): void {
    this.birthdayOrAgeOfInsured = value;
    switch (value) {
      case 'age':
        this.applyReq.birthday = null;
        break;
      case 'birthday':
        this.applyReq.age = null;
        break;
    }
  }

  onBirthdayOfInsuredChange(timestamp: number): void {
    if (!timestamp) {
      return;
    }
    if (!this.product.companyCode) {
      return;
    }
    this.productService.age(timestamp, this.product.companyCode)
      .subscribe(
        age => {
          this.applyReq.age = age;
        },
        error => {
        }
      )
      .disposedBy(this.disposeBag);
  }

  onBirthdayOrAgeOfHolderChange(value: BirthdayOrAge): void {
    this.birthdayOrAgeOfHolder = value;
    switch (value) {
      case 'age':
        this.applyReq.owner.birthday = null;
        break;
      case 'birthday':
        this.applyReq.owner.age = null;
        break;
    }
  }

  onBirthdayOfHolderChange(timestamp: number): void {
    if (!timestamp) {
      return;
    }
    if (!this.product.companyCode) {
      return;
    }
    this.productService.age(timestamp, this.product.companyCode)
      .subscribe(
        age => {
          this.applyReq.owner.age = age;
        },
        error => {
        }
      );
  }

  onProposalAmountTypeChange(amountType: ProposalAmountType): void {
    this.applyReq.proposalAmountType = amountType;
    switch (amountType) {
      case ProposalAmountType.PREMIUM:
        this.applyReq.sumAssured = null;
        break;
      case ProposalAmountType.SUM_ASSURED:
        this.applyReq.premium = null;
        break;
    }
    this.getAmountRestriction();
  }

  async onAddWithdrawal(info): Promise<void> {
    if (!info) {
      this.editWithdrawalIndex = null;
    }

    if (parseFloat(String(this.applyReq.age)).toString() === 'NaN') {
      this.translate.get('ChooseBirthdayOrAgeFirst')
        .subscribe(
          text => {
            this.notificationService.info(null, text);
          }
        );
      return;
    }

    const req = new ProductRestrictionReq();
    req.code = this.applyReq.productCode;
    req.premiumTerm = this.applyReq.paymentTerm;
    req.frequency = this.applyReq.frequency;
    req.type = ProductRestrictionType.WITHDRAWAL;
    this.productService.restriction(req)
      .subscribe(
        data => {
          this.withdrawalRestriction = data?.withdrawalRestriction;
          const modalRef = this.modalService.create({
            nzContent: ProposalWithdrawalEditComponent,
            nzComponentParams: {
              descriptions: data?.descriptions,
              withdrawalRestriction: data?.withdrawalRestriction,
              maxWithdrawal: this.product.features?.maxWithdrawal ?? false,
              age: this.applyReq.age,
              withdrawals: this.applyReq.withdrawals || [],
              editInfo: info ? JSON.parse(JSON.stringify(info)) : info,
              withdrawalIndex: this.editWithdrawalIndex,
              withdrawal: info ? JSON.parse(JSON.stringify(info)) : new Withdrawal()
            },
            nzFooter: null
          });

          modalRef.afterOpen.subscribe(
            () => {
              const component = modalRef.getContentComponent();
              component.withdrawalConfirmed.subscribe(
                withdrawal => {
                  this.setupWithdrawalString(withdrawal).then(() => {
                    if (this.editWithdrawalIndex !== null) {
                      this.applyReq.withdrawals[this.editWithdrawalIndex] = JSON.parse(JSON.stringify(withdrawal));
                    } else {
                      this.applyReq.withdrawals.push(withdrawal);
                    }
                  });
                });
            });
        },
        error => {

        }
      )
      .disposedBy(this.disposeBag);
  }

  async setupWithdrawalString(withdrawal: Withdrawal): Promise<void> {
    const res: any = await this.translate.get(['From', 'To', 'Year', 'YearsOld', 'Di', 'FixedWithdrawal', 'MaximumWithdrawals']).toPromise();
    const from = `${res.From}${res.Di}${withdrawal.from}${res.Year}(${(this.add(this.applyReq.age) ?? 0) + withdrawal.from}${res.YearsOld})`;
    const to = `${res.To}${res.Di}${withdrawal.to}${res.Year}(${(this.add(this.applyReq.age) ?? 0) + withdrawal.to}${res.YearsOld})`;
    const withdrawalWay = withdrawal.max ? res.MaximumWithdrawals : res.FixedWithdrawal;
    withdrawal.str = `${from}-${to}:${withdrawalWay}`;
  }

  add(num: number): number {
    if (!this.withdrawalRestriction?.calculation) {
      return num;
    }
    switch (this.withdrawalRestriction.calculation) {
      case '+':
        num += this.withdrawalRestriction.calculationValue;
        break;
      case '-':
        num -= this.withdrawalRestriction.calculationValue;
        break;
    }
    return num;
  }

  onWithdrawalDelete(i: number): void {
    if (this.applyReq.withdrawals?.length > i) {
      this.applyReq.withdrawals?.splice(i, 1);
    }
  }

  onWithdrawalEdit(i: number, info): void {
    this.editWithdrawalIndex = i;
    this.onAddWithdrawal(info);
  }

  //// 实验性
  showWithdrawalEditor(): void {
    const sheetRef = this.bottomSheetService.create<ProductSelectionComponent, {}, string>({
      elementRef: this.withdrawalEditorRef,
      content: ProductSelectionComponent,
      attribute: {}
    });
  }

  getAgeRestriction(): void {

    if (!this.applyReq.productCode) {
      return;
    }

    const req = new ProductRestrictionReq();
    req.code = this.applyReq.productCode;
    req.premiumTerm = this.applyReq.paymentTerm;
    req.frequency = this.applyReq.frequency;
    req.type = ProductRestrictionType.AGE;
    this.productService.restriction(req)
      .subscribe(
        data => {
          this.ageRestrictions = data?.descriptions;
          this.ageRestriction = data?.ageRestriction;
        },
        error => {
        }
      )
      .disposedBy(this.disposeBag);
  }

  get ageMin(): number {
    return this.ageRestriction?.ageMin ?? 0;
  }

  get ageMax(): number {
    return this.ageRestriction?.ageMax ?? 138;
  }

  get premiumMin(): number {
    return this.premiumRestriction?.min ?? Number.MIN_VALUE;
  }

  get premiumMax(): number {
    return this.premiumRestriction?.max ?? Number.MAX_VALUE;
  }

  get sumInsuredMin(): number {
    return this.sumInsuredRestriction?.min ?? Number.MIN_VALUE;
  }

  get sumInsuredMax(): number {
    return this.sumInsuredRestriction?.max ?? Number.MAX_VALUE;
  }

  getAmountRestriction(): void {

    if (!this.applyReq.productCode) {
      return;
    }

    const req = new ProductRestrictionReq();
    req.code = this.applyReq.productCode;
    req.premiumTerm = this.applyReq.paymentTerm;
    req.type = this.applyReq.proposalAmountType === ProposalAmountType.PREMIUM ? ProductRestrictionType.PREMIUM : ProductRestrictionType.SUM_INSURED;
    req.currency = this.applyReq.currency;
    req.frequency = this.applyReq.frequency;
    this.productService.restriction(req)
      .subscribe(
        data => {
          this.amountRestrictions = data?.descriptions;
          this.premiumRestriction = data?.premiumRestriction;
          this.sumInsuredRestriction = data?.sumInsuredRestriction;
        },
        error => {
        }
      )
      .disposedBy(this.disposeBag);
  }

  onReset(): void {
    if (this.loading) {
      return;
    }
    this.applyReq = new Proposal();
    this.product = new Product();
    this.birthdayOrAgeOfInsured = 'age';
    this.birthdayOrAgeOfHolder = 'age';
  }

  get canApply(): boolean {
    if (this.loading) {
      return false;
    }
    if (this.applyReq.productCode === undefined) {
      return false;
    }
    if (this.applyReq.language === undefined) {
      return false;
    }
    if (this.applyReq.currency === undefined) {
      return false;
    }
    if (this.applyReq.paymentTerm === undefined) {
      return false;
    }
    if (parseFloat(String(this.applyReq.age)).toString() === 'NaN') {
      return false;
    }
    if (this.applyReq.gender === undefined) {
      return false;
    }
    if (this.applyReq.smoke === undefined) {
      return false;
    }
    if (this.applyReq.residenceRegion === undefined) {
      return false;
    }
    if (this.product.categoryCode !== 'MEDICAL') {
      if (this.applyReq.proposalAmountType === undefined) {
        return false;
      }
      if (this.applyReq.proposalAmountType === ProposalAmountType.PREMIUM) {
        if (this.applyReq.premium === undefined) {
          return false;
        }
      }
      if (this.applyReq.proposalAmountType === ProposalAmountType.SUM_ASSURED) {
        if (this.applyReq.sumAssured === undefined) {
          return false;
        }
      }
    }

    if (this.balance && this.balance.proposalLimit && this.balance.alreadyUsed >= this.balance.proposalLimit) {
      return false;
    }

    return true;
  }

  onApply(): void {
    if (!this.canApply) {
      return;
    }
    if (this.applyReq.clean === undefined) {
      this.applyReq.clean = null;
    }
    if (this.applyReq.owner.age && this.applyReq.owner.age < 18) {
      this.applyReq.owner.age = 18;
    }
    if (this.applyReq.doWithdrawals === false) {
      this.applyReq.withdrawals = [];
    }
    if (!this.product.proposalPrepaymentSupported) {
      this.applyReq.prepay = null;
    }

    if (this.applyReq.riders && this.applyReq.riders.length) {
      this.applyReq.riderDynamicFields = [];
      this.applyReq.riders.forEach(item => {
        if (item.categoryCode === 'MEDICAL') {
          if (item.medicalPlan.deductible) {
            if (item.medicalPlan.deductible.toString().includes(' ')) {
              const arr = item.medicalPlan.deductible.split(' ');
              item.medicalPlan.deductible = arr[0];
              item.medicalPlan.currency = arr[1];
            }
          }
        }
        const obj: RiderDynamicField = {
          dynamicFields: item.dynamicFields,
          productCode: item.productCode
        };
        this.applyReq.riderDynamicFields.push(obj);
      });
    }
    this.loading = true;
    this.proposalService.apply(this.applyReq)
      .subscribe(
        data => {
          this.proposalApplied.emit(data);
          if (data) {
            this.translate.get('CreateSuccess').subscribe(
              (res) => {
                this.toastr.success(res);
              }
            );
          }
          this.drawerRef?.close();
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      );
  }

  onPaymentTermChange($event: any): void {
    this.getAgeRestriction();
    this.getAmountRestriction();
  }


  onCountry(type: string): void {

    const drawerRef = this.drawerService.create<RegionSelectionComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: RegionSelectionComponent,
      nzContentParams: {},
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.regionSelected.subscribe(
        data => {
          if (type === 'owner') {
            this.applyReq.owner.residenceRegion = data.countryCode;
          } else {
            this.applyReq.residenceRegion = data.countryCode;
            this.ageLinkage();
          }
          drawerRef.close();
        }
      );
    });
  }

  get countryString(): string {
    const country = this.metadataService.translate('countries', this.applyReq.residenceRegion);
    return country;
  }

  get countryOwnerString(): string {
    const country = this.metadataService.translate('countries', this.applyReq.owner.residenceRegion);
    return country;
  }

  onAgeOfInsuredChange(age: any): void {
    console.log(2222, '年龄');
    this.applyReq.age = age;
    if (age && this.birthdayOrAgeOfInsured === 'age') {
      setTimeout(() => {
        this.applyReq.birthday = null;
      }, 100);
    }
    this.ageLinkage();
  }

  onAddRider(): void {
    console.log(this.ridersArrData, 'ridersArrData');
    const drawerRef = this.drawerService.create<ProposalAdditionaComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProposalAdditionaComponent,
      nzContentParams: {
        ridersArrData: this.ridersArrData,
        currency: this.applyReq.currency
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.saveRiders.subscribe(
        data => {
          this.applyReq.riders.push(data);
          drawerRef.close();
        }
      );
    });
  }

  onHedgePlan(): void {
    // const re = /^[0-9]+[0-9]*]*$/; // 判断字符串是否为正整数包含0 //判断数字  /^[0-9]+.?[0-9]*$/
    console.log(parseFloat(String(this.applyReq.age)));
    if (parseFloat(String(this.applyReq.age)).toString() === 'NaN') {
      this.translate.get('NotAgeTips').subscribe(
        data => {
          this.toastr.warning(data);
        });
      return;
    }
    const drawerRef = this.drawerService.create<ProposalHedgePlanComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProposalHedgePlanComponent,
      nzContentParams: {
        applyReq: JSON.parse(JSON.stringify(this.applyReq.hedgePlan)) || new HedgePlan(),
        hedgeArrData: this.hedgeArrData,
        proposal: JSON.parse(JSON.stringify(this.applyReq))
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.saveHedge.subscribe(
        data => {
          console.log(data);
          this.applyReq.hedgePlan = JSON.parse(JSON.stringify(data));
          drawerRef.close();
        }
      );
    });
  }

  onRidersDetail(info: Riders, i: number): void {
    console.log(info);
    const drawerRef = this.drawerService.create<ProposalAdditionaComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProposalAdditionaComponent,
      nzContentParams: {
        ridersArrData: this.ridersArrData,
        currency: this.applyReq.currency,
        applyReq: JSON.parse(JSON.stringify(info)),
        isDetail: true
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.editSaveRiders.subscribe(
        data => {
          this.applyReq.riders[i] = JSON.parse(JSON.stringify(data));
          drawerRef.close();
        }
      );
    });
    // if (this.applyReq.riders?.length) {
    //   this.applyReq.riders?.splice(i, 1);
    // }
  }

  onRidersDelete(i: number): void {
    if (this.applyReq.riders?.length) {
      this.applyReq.riders?.splice(i, 1);
    }
  }

  doWithdrawalsChange(e): void {
    // if (e) {
    //   this.applyReq.reduceBasic = false;
    // }
    this.applyReq.reduceBasic = !(this.product.companyCode === 'AXA' || this.product.companyCode === 'AXAMACAU');

  }

  handleCancel(): void {
    this.isVisible = false;
  }

  ageLinkage(): void {
    console.log(this.applyReq.age);
    this.ageLinkageChange.emit('');
  }

  onDynamicFieldChange(e, info: DynamicField): void {
    if (info.field === 'worryFreeSelection') {
      if (e === '是') {
        this.applyReq.dynamicFields.map(item => {
          if (item.field === 'annualIncomeStart' ||  item.field === 'incomePaymentMethod' || item.field === 'incomePaymentOptions') {
            item.required = true;
            item.isDisabled = false;
          }
        });
      } else {
        this.applyReq.dynamicFields.map(item => {
          if (item.field === 'annualIncomeStart' ||  item.field === 'incomePaymentMethod' || item.field === 'incomePaymentOptions') {
            item.required = false;
            item.isDisabled = true;
            item.value = null;
          }
        });
      }
    }
  }

}
