import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CustomerType,
  HeathInfo,
  QuestionInfo,
  Relationship,
  Underwriting,
  FoList,
  HealthType
} from '../../../api/types';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-health-info',
  templateUrl: './health-info.component.html',
  styleUrls: ['./health-info.component.less']
})
export class HealthInfoComponent implements OnInit {
  heathInformation: HeathInfo;
  healthType: HealthType;
  isRequired = true;
  errorText = '';
  @Input()
  modeType: string;

  @Output()
  healthSaved: EventEmitter<any> = new EventEmitter<any>();

  constructor(private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  onValue(val, item): void {
    console.log(val);
    console.log(item);
  }

  isOwnerCorporate(): boolean {
    return this.healthType.ownerType !== CustomerType.CORPORATE;
  }

  isRelationship(): boolean {
    return this.healthType.relationship !== Relationship.OWN;
  }

  onSave(): void {
    this.heathInformation.questionInsured?.forEach(value => {
      value.questionnaire.forEach(key => {
        key.answer.forEach(answerItem => {
          if (answerItem.attachment && answerItem.attachment.headers) {
            answerItem.attachment.headers.forEach(header => {
              if (header.type === 'date') {
                if (header.value === 'date') {
                  header.inputValue = header.inputValue ? formatDate(header.inputValue, 'yyyy-MM-dd', 'en-US') : null;
                }
                if (header.value === 'range') {
                  header.startTime = header.startTime ? formatDate(header.startTime, 'yyyy-MM-dd', 'en-US') : null;
                  header.endTime = header.endTime ? formatDate(header.endTime, 'yyyy-MM-dd', 'en-US') : null;
                }
              }
            });
          }
        });
      });
    });
    this.heathInformation.questionOwner?.forEach(value => {
      value.questionnaire.forEach(key => {
        key.answer.forEach(answerItem => {
          if (answerItem.attachment && answerItem.attachment.headers) {
            answerItem.attachment.headers.forEach(header => {
              if (header.type === 'date') {
                if (header.value === 'date') {
                  header.inputValue = header.inputValue ? formatDate(header.inputValue, 'yyyy-MM-dd', 'en-US') : null;
                }
                if (header.value === 'range') {
                  header.startTime = header.startTime ? formatDate(header.startTime, 'yyyy-MM-dd', 'en-US') : null;
                  header.endTime = header.endTime ? formatDate(header.endTime, 'yyyy-MM-dd', 'en-US') : null;
                }
              }
            });
          }
        });
      });
    });
    const params = JSON.parse(JSON.stringify(this.heathInformation));
    if (params.questionOwner) {
      params.questionOwner = this.getHealthAnswerEntity(params.questionOwner);
    }
    if (params.questionInsured) {
      params.questionInsured = this.getHealthAnswerEntity(params.questionInsured);
    }
    console.log(this.heathInformation);
    if (!this.isRequired) {
      this.translate.get('ValidateEmpty').subscribe(
        (res) => {
          this.toastr.warning(this.errorText + ': ' + res);
        }
      );
      return;
    }
    console.log('ddd');

    this.healthSaved.emit(params);
  }

  getHealthAnswerEntity(valueDate: QuestionInfo[]): any[] {
    const that = this;
    const list = [];
    this.isRequired = true;
    this.errorText = '';
    const entity = valueDate;
    console.log(entity);
    entity.forEach((item) => {
      item.questionnaire.forEach((questionnaireItem) => {
        const valuequestion = {
          questionId: questionnaireItem.questionId,
          value: questionnaireItem.value,
          attachment: null
        };
        questionnaireItem.answer.forEach((answerItem) => {
          if (questionnaireItem.value === answerItem.answerId && answerItem
            .attachment != null) {
            valuequestion.attachment = {};
            valuequestion.attachment.type = answerItem.attachment.type;
            if (answerItem.attachment.type === 'form') {
              const headers = [];
              answerItem.attachment.headers.forEach((headerItem) => {
                console.log(headerItem);
                if (headerItem.required && !headerItem.inputValue) {
                  console.log(`${questionnaireItem.question} — ${headerItem.label}: null`);
                  this.isRequired = false;
                  this.errorText = `${questionnaireItem.question} — ${headerItem.label}`;
                }
                const params = that.foListParams(headerItem);

                headers.push(params);
              });
              valuequestion.attachment.headers = headers;
            } else {
              const values = [];
              answerItem.attachment.values.forEach((valuesItem) => {
                const valuesSub = [];
                valuesItem.forEach((e) => {
                  console.log(e);
                  if (e.required && !e.inputValue) {
                    this.isRequired = false;
                    this.errorText = `${questionnaireItem.question} — ${valuesItem.label}`;
                  }
                  const params = that.foListParams(e);
                  valuesSub.push(params);
                });
                values.push(valuesSub);
              });
              valuequestion.attachment.values = values;
            }
          }
        });
        list.push(valuequestion);
      });
    });
    return list;
  }

  foListParams(value): { inputValue: any; attachmentId: any } {
    const params = {
      attachmentId: value.attachmentId,
      inputValue: value.inputValue
    };
    return params;
  }

  onRadioOwnerChange(info, idx, index, id, heathInfo): void {
    // console.log(info, '====', id);
    // if (info === id) {
    //   console.log(info, '====////', id);
    //   console.log(this.heathInformation.questionOwner, '---------------[idx].questionnaire[index].value', idx, index);
    //   console.log(this.heathInformation.questionOwner[idx].questionnaire[index].value, '---------------');
    //   heathInfo[idx].questionnaire[index].value = null;
    //   console.log(this.heathInformation.questionOwner[idx].questionnaire[index].value, '+++++++++++++++++++++', info);
    // } else {
    //   console.log('null---------');
    //   heathInfo[idx].questionnaire[index].value = id;
    // }
  }

  onRadioInsuredChange(info, idx, index, id): void {
    // console.log(info, '====', id);
    // if (info === id) {
    //   setTimeout(() => {
    //     console.log(info, '====', id);
    //     console.log(this.heathInformation.questionInsured[idx].questionnaire[index].value, '---------------');
    //     this.heathInformation.questionInsured[idx].questionnaire[index].value = null;
    //   }, 200);
    // } else {
    //   this.heathInformation.questionInsured[idx].questionnaire[index].value = id;
    // }
  }

  addOtherAnswer(type): void {
    if (!this.healthType.edit) {
      return;
    }
    console.log(type);
    if (type === 'owner') {
      this.heathInformation.ownerOtherAnswer.otherDiseases.push('');
    }
    if (type === 'insured') {
      this.heathInformation.insuredOtherAnswer.otherDiseases.push('');
    }
  }

  dleOtherAnswer(type, index): void {
    if (!this.healthType.edit) {
      return;
    }
    if (type === 'owner') {
      const copy = JSON.parse(JSON.stringify(this.heathInformation.ownerOtherAnswer.otherDiseases));
      copy.splice(index, 1);
      this.heathInformation.ownerOtherAnswer.otherDiseases = JSON.parse(JSON.stringify(copy));
    }
    if (type === 'insured') {
      const copy = JSON.parse(JSON.stringify(this.heathInformation.insuredOtherAnswer.otherDiseases));
      copy.splice(index, 1);
      this.heathInformation.insuredOtherAnswer.otherDiseases = JSON.parse(JSON.stringify(copy));
    }
  }

  trackByFn(index: any, item: any): void {
    return index;
  }
}
