import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpService } from '../core/http.service';
import { ApiResponse, PagedResp, TicketDetailReplyResp } from '../api/types';
import { Observable } from 'rxjs/internal/Observable';
import { HttpParams } from '@angular/common/http';
import { API } from '../api/api';
import { map } from 'rxjs/operators';
import {
  TicketOrderCreate,
  TicketOrderDetailResp,
  TicketOrderListResp,
  TicketOrderListSearch,
  TicketOrderReply
} from './ticket-order-types';

@Injectable({
  providedIn: 'root'
})
export class TicketOrderService extends BaseService {

  constructor(private http: HttpService) {
    super();
  }

  /**
   * 工单列表
   */
  list(ticketSearch: TicketOrderListSearch): Observable<PagedResp<TicketOrderListResp>> {
    let httpParams = new HttpParams();
    if (ticketSearch.pageNum != null) {
      httpParams = httpParams.set('pageNum', ticketSearch.pageNum.toString());
    }
    if (ticketSearch.pageSize != null) {
      httpParams = httpParams.set('pageSize', ticketSearch.pageSize.toString());
    }
    if (ticketSearch.status != null) {
      httpParams = httpParams.set('status', ticketSearch.status.toString());
    }
    if (ticketSearch.id != null) {
      httpParams = httpParams.set('id', ticketSearch.id.toString());
    }
    if (ticketSearch.startCreateDate != null) {
      httpParams = httpParams.set('startCreateDate', ticketSearch.startCreateDate.toString());
    }
    if (ticketSearch.endCreateDate != null) {
      httpParams = httpParams.set('endCreateDate', ticketSearch.endCreateDate.toString());
    }
    if (ticketSearch.startReplyDate != null) {
      httpParams = httpParams.set('startReplyDate', ticketSearch.startReplyDate.toString());
    }
    if (ticketSearch.endReplyDate != null) {
      httpParams = httpParams.set('endReplyDate', ticketSearch.endReplyDate.toString());
    }
    return this.http.get<ApiResponse<PagedResp<TicketOrderListResp>>>(API.ticketCentreV3 + '/list', {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * Ticket详情
   * @param id 編號
   */
  detail(id: number): Observable<TicketOrderDetailResp> {
    let httpParams = new HttpParams();
    if (id != null) {
      httpParams = httpParams.set('id', id.toString());
    }
    return this.http.get<ApiResponse<TicketOrderDetailResp>>(API.ticketCentreV3 + `/info` , {
      params: httpParams,
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 详情回复
   * @param req 回复内容
   */
  reply(req: TicketOrderReply): Observable<any> {
    return this.http.post<ApiResponse<TicketOrderReply>>(API.ticketCentreV3 + '/reply' , req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 关闭工单
   */
  close(id: any): Observable<any> {
    return this.http.post<ApiResponse<TicketOrderReply>>(API.ticketCentreV3 + '/close/' + id , null, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }


  /**
   * 创建工单
   * @param req 回复内容
   */
  create(req: TicketOrderCreate): Observable<any> {
    return this.http.post<ApiResponse<TicketOrderCreate>>(API.ticketCentreV3 + '/create' , req, {
      observe: 'response'
    }).pipe(map(data => {
      return data.body.data;
    }));
  }





}
