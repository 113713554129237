import {Component, OnInit} from '@angular/core';
import {ProposalCompare, ProposalCompareSearch, ProposalList} from '../proposal-compare';
import {PagedResp, SmokeCondition} from '../../api/types';
import {ProposalCompareService} from '../proposal-compare.service';
import {PropertySelectOption} from '../../shared/component/property-select/property-select.component';
import {MetadataService} from '../../shared/service/metadata.service';
import {ProductService} from '../../product/product.service';
import {TranslateService} from '@ngx-translate/core';
import {ProposalSearch} from '../../proposal/proposal-types';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {ProposalCompareSelectedComponent} from '../proposal-compare-selected/proposal-compare-selected.component';
import {ProposalCompareDetailComponent} from '../proposal-compare-detail/proposal-compare-detail.component';
import {ProposalCompareSelectDetailComponent} from '../proposal-compare-select-detail/proposal-compare-select-detail.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import {StorageService} from '../../shared/service/storage.service';
import {DownloadService} from '../../shared/service/download.service';

@Component({
  selector: 'app-proposal-compare-list',
  templateUrl: './proposal-compare-list.component.html',
  styleUrls: ['./proposal-compare-list.component.less']
})
export class ProposalCompareListComponent implements OnInit {
  spinning = false;
  listArr: PagedResp<ProposalCompare>;
  search: ProposalCompareSearch = new ProposalCompareSearch();
  searchPopoverVisible = false;
  categoryOptions: PropertySelectOption[] = [];
  statusOptions: PropertySelectOption[] = [];
  deleting = false;

  constructor(private proposalCompareService: ProposalCompareService,
              private storageService: StorageService,
              private downloadService: DownloadService,
              private metadataService: MetadataService,
              private drawerService: NzDrawerService,
              private translate: TranslateService,
              private modalService: NzModalService,
              private productService: ProductService) {
  }

  ngOnInit(): void {

    this.proposalCompareService.list(this.search).subscribe(
      data => {
        data.list.forEach(e => {
          e.expand = false;
        });
        this.listArr = data;
        console.log(this.listArr);
      }
    );

    this.productService.categories()
      .subscribe(
        data => {
          data.forEach(category => {
            this.categoryOptions?.push(new PropertySelectOption(category.name, category.code));
          });
        },
        error => {
        });
    const statusOption = this.metadataService.values('proposalCompareStatus');
    this.translate.get('All').subscribe(
      data => {
        const option: PropertySelectOption = new PropertySelectOption(data, '');
        this.statusOptions = [option];
        for (const status of statusOption) {
          this.statusOptions.push(new PropertySelectOption(status.value, status.key));
        }
      }
    );

  }

  onReload(): void {
    this.search = new ProposalCompareSearch();
    this.spinning = true;

    this.proposalCompareService.list(this.search).subscribe(
      data => {
        data.list.forEach(e => {
          e.expand = false;
        });
        this.listArr = data;
        console.log(this.listArr);
        this.spinning = false;
      }, e => {
        this.spinning = false;
      }
    );

  }

  onCreate(): void {
    const drawerRef = this.drawerService.create<ProposalCompareSelectDetailComponent, { value: ProposalSearch }, string>({
      nzWidth: 800,
      nzContent: ProposalCompareSelectDetailComponent,
      nzContentParams: {}
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.proposalCompareSave.subscribe(data => {
        this.proposalCompareService.list(this.search).subscribe(
          listArr => {
            listArr.list.forEach(e => {
              e.expand = false;
            });
            this.listArr = listArr;
            console.log(this.listArr);
          }
        );
        drawerRef.close();
      });
    });

    drawerRef.afterClose.subscribe(data => {
      if (typeof data === 'string') {
      }
    });
  }

  onSearch(pageNum: number): void {
    this.search.pageNum = pageNum;
    this.spinning = true;
    this.proposalCompareService.list(this.search).subscribe(
      data => {
        data.list.forEach(e => {
          e.expand = false;
        });
        this.listArr = data;
        this.spinning = false;
        console.log(this.listArr);
      }, error => {
        this.spinning = false;
      }
    );
  }

  onDownload(type: any): void {
    this.storageService.accessToken(type.filePath)
      .subscribe(
        data => {
          // console.log(data);
          this.downloadService.download(data.accessToken, type.fileName);
        },
        err => {
        });
  }

  onAddProposal(): void {

  }

  onDetail(id: number): void {

    this.proposalCompareService.details(id).subscribe(proposalDetail => {
      console.log(proposalDetail);
      const drawerRef = this.drawerService.create<ProposalCompareDetailComponent, {}, string>({
        nzWidth: 800,
        nzContent: ProposalCompareDetailComponent,
        nzContentParams: {
          proposalDetail
        }
      });
      drawerRef.afterOpen.subscribe(() => {
        const component = drawerRef.getContentComponent();
      });

      drawerRef.afterClose.subscribe(data => {
        if (typeof data === 'string') {
        }
      });
    });

  }

  onDelete(contacts: ProposalCompare, tplTitle: any, tplContent: any, tplFooter: any): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: contacts,
      nzOnOk: () => {

      }
    });

  }

  onDeleteConfirm(ref: any, contacts: ProposalCompare): void {
    this.spinning = true;
    this.proposalCompareService.delete(contacts.id).subscribe(
      data => {
        this.proposalCompareService.list(this.search).subscribe(
          listArr => {
            listArr.list.forEach(e => {
              e.expand = false;
            });
            this.listArr = listArr;
            console.log(this.listArr);
            this.spinning = false;
            ref.close();
          },
          error => {
            this.spinning = false;
            ref.close();
          }
        );
      },
      e => {
        this.spinning = false;
        ref.close();
      }
    );
  }
}
