<div>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
  <nz-spin [nzIndicator]="indicatorTemplate"  [nzSpinning]="spinning">
    <div *ngIf="!contactsTable">
      <nz-empty nzNotFoundImage="simple"></nz-empty>
    </div>
    <div *ngIf="contactsTable" style="margin-top: 30px; ">
      <nz-table [nzBordered]="false"
                [nzOuterBordered]="true"
                [nzShowPagination]="false"
                [nzLoading]="loading"
                [nzLoadingIndicator]="indicatorTemplate"
                [nzFrontPagination]="false"
                [nzData]="contactsTable"
                [nzTitle]="tableHeader"
                (nzPageIndexChange)="onSearch($event)">
        <ng-template #tableHeader>
          <nz-row>
            <nz-col [nzSpan]="12">
              <div class="list-header-title">{{'ReferralsList' | translate}}
                <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate"
                   style="margin-left: 5px"
                   appThrottleClick (throttleClick)="onReload()">
                  <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                </a>
              </div>
            </nz-col>

            <nz-col [nzSpan]="12">
              <div style="width: 100%; display: flex; justify-content: flex-end;">
                <button nz-button nzType="default" nzSize="large" nzShape="circle"
                        nz-popover [nzPopoverContent]="filterTemplate"
                        [(nzPopoverVisible)]="filterPopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottomRight"
                        style=" margin-left: 10px;">
                  <img alt="" width="24" src="assets/images/ic_filter.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        *ngIf="isLimit"
                        nz-popover [nzPopoverContent]="qrCodeTemplate"
                        [(nzPopoverVisible)]="qrCodePopoverVisible"
                        nzPopoverTrigger="click"
                        nzPopoverPlacement="bottom"
                        [nzTooltipTitle]="'QRCodeSharing' | translate"  style=" margin-left: 20px;">
                  <img style="margin-bottom: 4px;" alt="" width="22" src="assets/images/ic_qr_code.svg">
                </button>

                <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
                        appThrottleClick (throttleClick)="onStructure()"
                        [nzTooltipTitle]="'TeamMembers' | translate"  style=" margin-left: 20px;">
                  <img style="margin-bottom: 4px;" alt="" width="22" src="assets/images/ic_tree.svg">
                </button>
              </div>
            </nz-col>
          </nz-row>
        </ng-template>
<!--        <ng-template #tableFooter>-->
<!--          <div class="table-title">-->
<!--            {{'TotalRows' | translate}} : <span-->
<!--            style="color: #002140; font-weight: bold;">{{ contactsTable.total }}</span>-->
<!--          </div>-->
<!--        </ng-template>-->

        <thead>
        <tr>
          <th nzAlign="left" nzWidth="300px">
            <span nz-tooltip
                  style="margin-left: 40px"
                  [nzTooltipTitle]="'Name' | translate">{{'Name' | translate}}</span>
          </th>
          <th nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'RefereeAccount' | translate">{{'RefereeAccount' | translate}}</span>
          </th>
          <th  *ngIf="!instance.isFoneOrDev()" nzWidth="160px">
            <span nz-tooltip [nzTooltipTitle]="'Gender' | translate">{{'Gender' | translate}}</span>
          </th>
          <th>
            <span nz-tooltip [nzTooltipTitle]="'PhoneNumber' | translate">{{'PhoneNumber' | translate}}</span>
          </th>
          <th>
            <span nz-tooltip [nzTooltipTitle]="'Email' | translate">{{'Email' | translate}}</span>
          </th>
          <th nzWidth="">
            <span nz-tooltip [nzTooltipTitle]="'LastLoginTime' | translate">{{'LastLoginTime' | translate}}</span>
          </th>
          <th nzWidth="">
            <span nz-tooltip [nzTooltipTitle]="'AccountOpeningTime' | translate">{{'AccountOpeningTime' | translate}}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let contacts of contactsTable; let i = index">
          <td>
            <nz-avatar class="list-top-avatar" style="margin-right: 20px;" [nzSize]="36" [nzSrc]="contacts.avatar"></nz-avatar>
            <span *ngIf="!instance.isFoneOrDev()" style="font-weight: bold; font-size: 12px">
              {{ contacts.nickName || 'N/A' }}
            </span>
            <span *ngIf="instance.isFoneOrDev()" style="font-weight: bold; font-size: 12px">
              {{ contacts.firstName || 'N/A' }}
            </span>
            <nz-tag *ngIf="instance.isBaylightOrDev()" nzColor="processing" style="margin-left: 5px">{{ contacts.salesRank ? contacts.salesRank : ('NewRich' | translate) }}</nz-tag>
          </td>
          <td><span>{{ contacts.accountName }}</span></td>
          <td *ngIf="!instance.isFoneOrDev()"><span>{{ contacts.gender | metadataTranslate: 'gender' }}</span></td>
          <td>
            <span>
            <span *ngIf="contacts.areaCode">+{{contacts.areaCode}}</span>
            {{ contacts.phone }}
            </span>
          </td>
          <td>
            <span *ngIf="!instance.isFoneOrDev()">{{ contacts.email }}</span>
            <span *ngIf="instance.isFoneOrDev()">{{ contacts.administrationEmail }}</span>
          </td>
          <td>
            <span>{{ contacts.lastOnlineTime | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
          </td>
          <td>
            <span>{{ contacts.addTime | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-spin>
</div>

<ng-template #qrCodeTemplate>
  <div style="padding: 5px;">
    <p style="text-align: center">{{'QRCode' | translate}}</p>
    <nz-divider></nz-divider>
    <qr-code [value]="codeImgUrl" [level]="'M'" [size]="180"></qr-code>
  </div>
</ng-template>

<!-- 气泡Template -->
<ng-template #filterTemplate>
  <div style="width: 400px; padding: 5px;">
    <div style="padding: 0 0 15px 0">{{'Filter' | translate}}</div>
    <div class="property-bottom-line-blue"></div>
    <app-property-input [title]="'Name' | translate"
                        [placeholder]="('PleaseEnter' | translate) + ('Name' | translate)"
                        [(value)]="search.subordinateName"></app-property-input>

    <nz-row [nzGutter]="5">
      <nz-col nzSpan="12">
        <button nz-button nzType="default"
                style="width: 100%; margin: 30px 0 0 0;"
                appThrottleClick (throttleClick)="onReload()">
          <span>{{'Reset' | translate}}</span>
        </button>
      </nz-col>
      <nz-col nzSpan="12">
        <button nz-button nzType="primary"
                style="width: 100%; margin: 30px 0 0 0;"
                appThrottleClick (throttleClick)="onSearch(1)">
          <span>{{'Confirm' | translate}}</span>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-template>

