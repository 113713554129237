import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductPromotionComponent } from './product-promotion.component';
import { ProductPromotionMainComponent } from './product-promotion-main.component';
import { ProductPromotionListComponent } from './product-promotion-list/product-promotion-list.component';
import { ProductPromotionDetailComponent } from './product-promotion-detail/product-promotion-detail.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTabsModule } from 'ng-zorro-antd/tabs';



@NgModule({
  declarations: [
    ProductPromotionComponent,
    ProductPromotionMainComponent,
    ProductPromotionListComponent,
    ProductPromotionDetailComponent
  ],
  imports: [
    CommonModule,
    NzLayoutModule,
    SharedModule,
    RouterModule,
    TranslateModule,
    NzSpinModule,
    NzIconModule,
    NzSkeletonModule,
    NzTableModule,
    NzGridModule,
    NzButtonModule,
    NzToolTipModule,
    NzPopoverModule,
    NzTagModule,
    NzTabsModule
  ]
})
export class ProductPromotionModule { }
