import {Component, Input, OnInit} from '@angular/core';
import {
  Material,
  PendingDetailReply,
  PendingReply,
  PendingDetail,
  UploadTokenReq,
  UploadType, Instance, DataMode, DataDesensitization
} from '../../../api/types';
import {UnderwritingToDoComponent} from '../../underwriting-to-do/underwriting-to-do.component';
import {PolicyToDoRes, UnderwritingToDoRes} from '../../to-do-types';
import {WorkbenchService} from '../../../workbench/workbench.service';
import {AccountService} from '../../../account/account.service';
import {UnderwritingService} from '../../../underwriting/underwriting.service';
import {PolicyService} from '../../../policy/policy.service';

@Component({
  selector: 'app-event-record',
  templateUrl: './event-record.component.html',
  styleUrls: ['./event-record.component.less']
})
export class EventRecordComponent implements OnInit {
  @Input()
  pendingDetail: PendingDetail = new PendingDetail();
  @Input()
  pendingReply: PendingReply[] = [];
  @Input()
  type: string;
  @Input()
  mode = DataMode.OWN;
  basicInfo: UnderwritingToDoRes = new UnderwritingToDoRes();
  policyInfo: PolicyToDoRes = new PolicyToDoRes();
  accountName: string;
  pendingDetailReply: PendingDetailReply = new PendingDetailReply();
  uploadTokenReq = new UploadTokenReq();
  detailVisible = false;
  instance: Instance = new Instance();
  dataDesensitization: DataDesensitization = new DataDesensitization();

  constructor(private accountService: AccountService,
              private underwritingService: UnderwritingService,
              private policyService: PolicyService) { }

  ngOnInit(): void {
    this.instance = this.accountService.mockInstance;
    this.dataDesensitization = this.accountService.dataDesensitization;
    this.accountName = this.accountService.passport.accountName;
    if (this.type === 'underwriting') {
      this.uploadTokenReq.uploadType = UploadType.UNDERWRITING;
      this.uploadTokenReq.middleName = this.basicInfo.underwritingNo;
    }else {
      this.uploadTokenReq.uploadType = UploadType.POLICY;
      this.uploadTokenReq.middleName = this.policyInfo.policyId;
    }
  }


  pendingAttachmentsChange($event: Material[]): void {
    this.pendingDetailReply.attachments = $event;
  }

  reply(): void {
    if (!this.canReply) {
      return;
    }
    if (this.type === 'underwriting') {
      this.pendingDetailReply.pendingId = this.basicInfo.id;
      this.underwritingService.pendingDetailReply(this.pendingDetailReply)
        .subscribe(
          data => {
            this.refresh();
          }
        );
    } else {
      this.pendingDetailReply.serviceId = this.policyInfo.id;
      this.policyService.pendingDetailReply(this.pendingDetailReply)
        .subscribe(
          data => {
            this.refresh();
          }
        );
    }
  }

  refresh(): void {
    if (this.type === 'underwriting') {
      this.underwritingService.pendingReply(this.basicInfo.id)
        .subscribe(
          pendReply => {
            this.pendingReply = pendReply;
            if (this.pendingReply && this.pendingReply.length) {
              this.pendingReply.map(item => item.content = item.content ? item.content : '');
            }
          });
    } else {
      this.policyService.serviceReply(this.policyInfo.id)
        .subscribe(
          pendReply => {
            this.pendingReply = pendReply;
            if (this.pendingReply && this.pendingReply.length) {
              this.pendingReply.map(item => item.content = item.content ? item.content : '');
            }
          });
    }
    this.pendingDetailReply = new PendingDetailReply();
  }


  get canReply(): boolean {
    return !!this.pendingDetailReply.content || (!!this.pendingDetailReply.attachments && this.pendingDetailReply.attachments.length > 0);
  }


  openTitle(): void {
    this.detailVisible = true;
  }

  detailClose(): void {
    this.detailVisible = false;
  }

  isDynamicEncryption(keyName): boolean {
    let isShow = false;
    if (!this.dataDesensitization || !this.mode) {
      return isShow;
    }
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    }
  }
}
