<div class="drawer-container">
  <div class="common-title">
    {{'SelectFNA' | translate}}
  </div>

  <nz-row [nzGutter]="12">
    <nz-col [nzSpan]="24">
      <app-property-input [displayMode]="'vertical'"
                          [title]="'ClientName' | translate"
                          [placeholder]="('PleaseEnter' | translate) + ('ClientName' | translate)"
                          [(value)]="search.name"
                          (valueChange)="onSearchParamChange()"></app-property-input>
    </nz-col>
  </nz-row>

  <div>
    <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
    <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="spinning">
      <div *ngIf="!fnas">
        <nz-skeleton [nzParagraph]="{ rows: 10 }"></nz-skeleton>
      </div>
      <div *ngIf="fnas" style="margin-top: 30px; ">
        <nz-table [nzBordered]="false"
                  [nzOuterBordered]="true"
                  [nzShowPagination]="true"
                  [nzLoading]="loading"
                  [nzLoadingIndicator]="indicatorTemplate"
                  [nzFrontPagination]="false"
                  [nzData]="fnas.list"
                  [nzPageIndex]="fnas.pageNum"
                  [nzPageSize]="fnas.pageSize"
                  [nzTotal]="fnas.total"
                  [nzTitle]="tableHeader"
                  [nzFooter]="tableFooter"
                  (nzPageIndexChange)="onSearch($event)">
          <ng-template #tableHeader>
            <nz-row>
              <nz-col [nzSpan]="12">
                <div class="list-header-title">{{('FnaList' | translate)}}
                  <a nz-button nzType="link" nzSize="small" nz-tooltip [nzTooltipTitle]="'Refresh' | translate" style="margin-left: 5px"
                     appThrottleClick (throttleClick)="onReload()">
                    <em nz-icon style="color: black" nzType="reload" nzTheme="outline"></em>
                  </a>
                </div>
              </nz-col>
            </nz-row>
          </ng-template>
          <ng-template #tableFooter>
            <div class="table-title">
              {{'TotalRows' | translate}} : <span style="color: #002140; font-weight: bold;">{{ fnas.total }}</span>
            </div>
          </ng-template>

          <thead>
          <tr>
            <th nzLeft nzWidth="150px"><span>{{'FnaId' | translate}}</span></th>
            <th><span>{{'ClientName' | translate}}</span></th>
            <th><span>{{'ProductName' | translate}}</span></th>
            <th><span>{{'Status' | translate}}</span></th>
            <th><span>{{'CreateTime' | translate}}</span></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let fna of fnas.list; let i = index" appThrottleClick (throttleClick)="onFnaSelected(fna.id, i)">
            <td nzLeft>
            <span style="font-weight: bold; font-size: 12px">
              {{ fna.id }}
            </span>
            </td>
            <td><span>{{ fna.name }}</span></td>
            <td>
              <div style="display: flex; align-items: center;">
                <span>{{ fna.productName }}</span>
              </div>
            </td>
            <td>
              <div [ngSwitch]="fna.status">
                <div>
                  <span *ngSwitchCase="'UNCOMPLETED'" class="text-warning">●</span>
                  <span *ngSwitchCase="'COMPLETED'" class="text-success">●</span>
                  <span> {{ fna.status | metadataTranslate:'fnaStatus'}}</span>
                </div>
              </div>
            </td>
            <td><span>{{ fna.addTime | date:'yyyy-MM-dd HH:mm:ss' }}</span></td>
          </tr>
          </tbody>
        </nz-table>
      </div>
    </nz-spin>
  </div>

</div>
