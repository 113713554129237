<div class="from-info-container">
  <div class="from-info-card">
    <div style="color: rgba(64,64,64,0.85);text-align: justify">
      {{'CMGQuestion1' | translate}}
    </div>
  </div>

  <div *ngFor="let fnaQuestion of fnaQuestions; let i = index">
    <app-fna-template-question [fnaQuestion]="fnaQuestion"
                               [policy2]="policy2"
                               [isDisabled]="fnaInfo.signatureStatus === 'SIGNED' || !!fnaInfo.underwritingNo"
                               (fnaChange)="newFnaChange($event)">
    </app-fna-template-question>
  </div>
</div>

