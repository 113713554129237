import { Component, OnInit } from '@angular/core';
import { ContactsType, FollowReplyRes, InsertReply, PreSaleFollow, UpdateFollow } from '../../contact-types';
import {Material, UploadTokenReq, UploadType} from '../../../api/types';
import {ContactService} from '../../contact.service';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {ContactsCreateRecordComponent} from '../contacts-create-record/contacts-create-record.component';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-contacts-record-details',
  templateUrl: './contacts-record-details.component.html',
  styleUrls: ['./contacts-record-details.component.less'],
  providers: [DatePipe]
})
export class ContactsRecordDetailsComponent implements OnInit {

  dataInfo: FollowReplyRes = new FollowReplyRes(); // 回填信息
  uploadTokenReq = new UploadTokenReq();
  addReplyFollow: InsertReply = new InsertReply(); // 回复内容
  followId: number; // 客户记录ID
  id: number; // 联系人ID
  spinning = false;
  constructor(private contactService: ContactService,
              private drawerService: NzDrawerService,
              private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.uploadTokenReq.uploadType = UploadType.CONTACT;
    this.uploadTokenReq.middleName = this.followId.toString();
  }

  replyAttachmentsChange(attachments: Material[]): void {
    this.addReplyFollow.attachments = attachments;
    console.log(attachments);
  }

  get canReply(): boolean {
    return !!this.addReplyFollow.content;
  }

  reply(): void {
    if (!this.canReply) {
      return;
    }
    this.spinning = true;
    this.addReplyFollow.followId = this.followId;
    this.contactService.replyFollow(this.addReplyFollow)
      .subscribe(data => {
          this.loadDetail(this.followId);
          this.spinning = false;
          this.addReplyFollow = new InsertReply();
        },
        error => {
          this.spinning = false;
        }
      );
  }

  loadDetail(id): void {
    this.contactService.meetingDetails(id)
      .subscribe(
        data => {
          this.dataInfo = data;
        },
        error => {
          console.log(error);
        }
      );

  }

  onEdit(): void {
    const follow = new PreSaleFollow();
    follow.id = this.followId;
    follow.preSalesId = this.id;
    follow.followDate = this.dataInfo.followDate;
    follow.place = this.dataInfo.place;
    follow.matter = this.dataInfo.matter;
    follow.status = this.dataInfo.status;
    follow.attachments = this.dataInfo.attachments;
    follow.productCodes = this.dataInfo.productCodes;
    follow.productNames = this.dataInfo.productNames;
    follow.remark = this.dataInfo.remark;
    const drawerRef = this.drawerService.create<ContactsCreateRecordComponent, { value: any }, string>({
      nzWidth: 800,
      nzContent: ContactsCreateRecordComponent,
      nzMask: false,
      nzContentParams: {
        id: this.id,
        type: ContactsType.Edit,
        follow
      }
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.recordUpdated
        .subscribe(
          data => {
            drawerRef.close();
            this.loadDetail(this.followId);
          }
        );
    });
  }
}
