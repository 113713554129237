<div>
  <ng-template #shareTemplate><i nz-icon nzType="spinning"></i></ng-template>
  <nz-spin [nzIndicator]="shareTemplate" [nzSpinning]="spinning">
    <div class="calender-content">
      <button nz-button nzType="default" nzSize="large" nzShape="circle" nz-tooltip
              [nzTooltipTitle]="'CreateSchedule' | translate" style=" margin-left: 10px;float: right;"
              appThrottleClick (throttleClick)="onAdd()">
        <img alt="" width="22" src="../../../../assets/images/ic_add.svg">
      </button>
      <nz-calendar
        [nzMode]="'month'"
        (nzPanelChange)="panelChange($event)"
        (nzSelectChange)="selectChange($event)">
        <div *nzDateCell="let date" class="events">
          <ng-container *ngFor="let key of objectKeys(scheduleList)">
            <div *ngIf="key == date.getTime()"
                 style="height: 100%"
                 appThrottleClick
                 (throttleClick)="onDetail(key)">
              <ng-container *ngFor="let item of scheduleList[key]">
                <nz-badge
                  class="through"
                  *ngIf="item.endTime < newData || item.status == 'COMPLETED';else elseBlock"
                  nzStatus="success"
                  [nzText]="item.title"></nz-badge>
                <ng-template #elseBlock>
                  <div *ngIf="item.status == 'WAIT'">
                    <nz-badge
                      *ngIf="newData > item.startTime  &&newData < item.endTime ;else elseTemple"
                      nzStatus="processing"
                      [nzText]="item.title"></nz-badge>
                    <ng-template #elseTemple>
                      <nz-badge
                        nzStatus="processing"
                        [nzText]="item.title"></nz-badge>
                    </ng-template>
                  </div>
                </ng-template>

              </ng-container>
            </div>

          </ng-container>
        </div>
      </nz-calendar>
      <!--      <ng-template #dateCellTpl let-date >-->
      <!--        <div >-->
      <!--          <div class="events">-->
      <!--            <ng-container >-->

      <!--            </ng-container>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </ng-template>-->

    </div>
  </nz-spin>

</div>
