import {Component, Input, OnInit} from '@angular/core';
import {ContractDetails, ContractList, ContractQuery, ExcelDownloadQuery} from '../contract-type';
import {ContractService} from '../contract.service';
import {
  ProductSelectionComponent,
  ProductSelectionType
} from '../../shared/component/product-selection/product-selection.component';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {Product} from '../../product/product-types';
import { Instance } from '../../api/types';
import { Workbench } from '../../workbench/workbench-types';
import { WorkbenchService } from '../../workbench/workbench.service';

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.less']
})
export class ContractDetailComponent implements OnInit {
  primaryProduct: Product = new Product();

  @Input()
  title: string;

  contractMode: string;
  contractDetails: ContractDetails;
  contractList: ContractList;
  search: ContractQuery = new ContractQuery();
  loading = false;
  filterPopoverVisible = false;
  instance: Instance;
  workbench: Workbench = new Workbench();

  constructor(private contractService: ContractService,
              private drawerService: NzDrawerService,
              public workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    this.instance = this.contractService.mockInstance;
    this.loading = true;
    console.log(this.contractList);
    this.workbench = this.workbenchService.workbench;
    if (this.contractList.salesContractViewId) {
      this.search.salesContractViewId = this.contractList.salesContractViewId;
    } else {
      this.search.id = this.contractList.id;
      this.search.contractMasterRateId = this.contractList.contractMasterRateId;
      this.search.contractSalesAllocationId = this.contractList.contractSalesAllocationId;
      this.search.baseOn = this.contractList.baseOn;
    }
    this.contractService.detail(this.search).subscribe(
      data => {
        console.log(data);
        this.contractDetails = data;
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );

  }

  onReload(): void {
    this.search = new ContractQuery();
    this.primaryProduct = new Product();
    this.onSearch();
  }

  onSearch(): void {
    this.loading = true;
    this.search.id = this.contractList.id;
    this.search.contractMasterRateId = this.contractList.contractMasterRateId;
    this.search.contractSalesAllocationId = this.contractList.contractSalesAllocationId;
    this.search.baseOn = this.contractList.baseOn;
    this.search.productCode = this.primaryProduct.productCode;
    this.contractService.detail(this.search).subscribe(
      data => {
        console.log(data);
        this.contractDetails = data;
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );

  }

  onProductSelection(): void {
    const drawerRef = this.drawerService.create<ProductSelectionComponent, { value: string }, string>({
      nzWidth: 800,
      nzContent: ProductSelectionComponent,
      nzContentParams: {
        type: ProductSelectionType.DEFAULT,
        copyProduct: this.primaryProduct
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const selectionComponent = drawerRef.getContentComponent();
      selectionComponent.productSelected
        .subscribe(
          product => {
            this.primaryProduct = product;
            this.search.productCode = this.primaryProduct.productCode;
            this.filterPopoverVisible = true;
            drawerRef.close();
          }
        );

    });

    drawerRef.afterClose.subscribe(data => {
    });
  }

  onExportExcel(): void {
    const param = new ExcelDownloadQuery();
    if (this.contractList.salesContractViewId) {
      param.salesContractViewId = this.contractList.salesContractViewId;
    } else {
      param.contractMasterRateId = this.contractList.contractMasterRateId;
      param.id = this.contractList.id;
      param.contractSalesAllocationId = this.contractList.contractSalesAllocationId;
    }
    this.contractService.downloadExcel(param)
      .subscribe(
        data => {
          console.log(data);
          const link = document.createElement('a');
          const blob = new Blob([data.body], {type: 'application/vnd.ms-excel'});
          let fileName = data.headers.get('Content-Disposition').split(';')[1].split('filename=')[1];
          // const fileNameUnicode = data.headers.get('Content-Disposition').split('filename*=')[1];
          // 当存在 filename* 时，取filename* 并进行解码（为了解决中文乱码问题）
          if (fileName) {
            fileName = decodeURIComponent(fileName.replace( /\+/g, '%20' ));
          }
          console.log(fileName);

          link.setAttribute('href', window.URL.createObjectURL(blob));
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // const url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
          // window.open(url);
          this.loading = false;
        }, error => {
          this.loading = false;
        });
  }

}
