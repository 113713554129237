<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{ 'PolicyPaymentDetails' | translate }}
  </div>
  <div style="font-size: 12px">
    {{'PolicyNumber' | translate}}
  </div>
  <div style="font-size: 16px;color:#1D64C4">
    {{policy.policyNo || 'N/A'}}
  </div>

  <div class="section-wrapper" style="margin-bottom: 10px">

    <app-property-text [title]="'DDA' | translate"
                       *ngIf="isShowDDA(policy)"
                       [value]="policy.autoTransfer == null ? '' : policy.autoTransfer?('Yes'| translate):('No' | translate)"></app-property-text>

    <!-- 递交日期-->
    <app-property-text [title]="'Frequencies' | translate"
                       [value]="policy.productPlan.frequency  | metadataTranslate:'frequency'"></app-property-text>
    <!--  付清日期  -->
    <app-property-text [title]="'PaidUpDate' | translate"
                       [value]="policy.paidUpDate | date: 'yyyy-MM-dd'"></app-property-text>
    <!--  保单日期  -->
    <!--    <app-property-text [title]="'PolicyDate' | translate"-->
    <!--                       [value]="policy.policyDate | date: 'yyyy-MM-dd'"></app-property-text>-->
    <!--缴费编号-->
    <app-property-text [title]="'PaymentNo' | translate"
                       [value]="policy.paymentNumber"></app-property-text>
    <!--保费到期日-->
    <app-property-text [title]="'PremiumsDueDate' | translate"
                       [value]="policy.nextPaymentDate | date: 'yyyy-MM-dd'"></app-property-text>
    <!-- 保费-->
    <app-property-text [title]="'Premium' | translate"
                       [value]="(policy.premium | number)+(policy.productPlan.currency | metadataTranslate: 'currency' )"></app-property-text>
    <!-- 附加险保费-->
    <app-property-text [title]="'RiderPremiums' | translate"
                       [value]="(policy.riderPremiums	 | number)+(policy.productPlan.currency | metadataTranslate: 'currency' )"></app-property-text>
    <!-- 征费-->
    <app-property-text [title]="'Levy' | translate"
                       [value]="(policy.levy | number)+(policy.productPlan.currency | metadataTranslate: 'currency' )"></app-property-text>
    <!-- 总保费及征费-->
    <app-property-text [title]="'TotalPremiumLevy' | translate"
                       [tip]="'TotalPremiumLevyTips' | translate"
                       [tipVisible]="true"
                       [value]="((policy.premiumAndLevy || 0) | number) + (policy.productPlan.currency | metadataTranslate: 'currency' )"></app-property-text>
    <!-- 保费回赠-->
    <app-property-text *ngIf="isShowItem" [title]="'PremiumRebate'| translate"
                       [value]="policy.refundBalanceAmount"></app-property-text>

    <!-- 缴费状态-->
    <app-property-text *ngIf="!instance.isFone()" [title]="'PDA'| translate"
                       [value]="(policy.premiumTemporaryAccount | number) + (policy.premiumTemporaryAccountCurrency ? (policy.premiumTemporaryAccountCurrency | metadataTranslate: 'currency') : '')">
    </app-property-text>

    <app-property-text *ngIf="!instance.isFone()" [title]="'FuturePremiumDepositAmount'| translate"
                       [value]="((policy.futurePremiumDepositAmount || 0) | number) + (policy.productPlan.currency ? (policy.productPlan.currency | metadataTranslate: 'currency') : '')">
    </app-property-text>

    <app-property-text *ngIf="!instance.isFone()" [title]="'PremiumPayable'| translate"
                       [value]="(policy.premiumPayable | number) + (policy.productPlan.currency ? (policy.productPlan.currency | metadataTranslate: 'currency') : '')">
    </app-property-text>
<!--    <app-property-text *ngIf="isShowItem" [title]="'PaymentStatus'| translate"-->
<!--                       [bottomLineVisible]="false"-->
<!--                       [value]="policy.paymentStatus | metadataTranslate:'paymentStatus'"></app-property-text>-->

  </div>
  <div class="property-tip"><span style="color: red;font-weight: bolder">*</span>{{ 'ExpenseTip'| translate }}</div>
  <div class="property-tip"><span style="color: red;font-weight: bolder">*</span>{{ 'ExpenseTips'| translate }}</div>

</div>
