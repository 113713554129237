import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UnderwritingPendingDetail, UnderwritingPendingReply, UnderwritingPendingReplyReq } from '../../underwriting-types';
import { AccountService } from '../../../account/account.service';
import { UnderwritingService } from '../../underwriting.service';
import { DataDesensitization, DataMode, Instance, Material, UploadTokenReq, UploadType } from '../../../api/types';

@Component({
  selector: 'app-underwriting-pending-detail',
  templateUrl: './underwriting-pending-detail.component.html',
  styleUrls: ['./underwriting-pending-detail.component.less']
})
export class UnderwritingPendingDetailComponent implements OnInit {

  @Input()
  mode = DataMode.OWN;
  loading = false;

  pendingDetail: UnderwritingPendingDetail;

  accountName: string;

  replies: UnderwritingPendingReply[] = [];

  uploadTokenReq = new UploadTokenReq();

  replyReq: UnderwritingPendingReplyReq = new UnderwritingPendingReplyReq();

  detailVisible = false;
  instance: Instance;

  @Output()
  pendingChanged: EventEmitter<string> = new EventEmitter<string>();

  dataDesensitization: DataDesensitization = new DataDesensitization();
  constructor(private accountService: AccountService,
              private underwritingService: UnderwritingService) {
  }

  ngOnInit(): void {
    this.instance = this.accountService.mockInstance;
    this.accountName = this.accountService.passport.accountName;
    this.dataDesensitization = this.accountService.dataDesensitization;

    this.uploadTokenReq.uploadType = UploadType.UNDERWRITING;
    this.uploadTokenReq.middleName = this.pendingDetail.underwritingNo;

    this.replyReq.pendingId = this.pendingDetail.id;

    this.loadReplies();
  }


  loadReplies(): void {
    this.loading = true;
    this.underwritingService.pendingReplies(this.pendingDetail.id)
      .subscribe(
        list => {
          this.loading = false;
          this.replies = list;
          if (this.replies && this.replies.length) {
            this.replies.map(item => item.content = item.content ? item.content : '');
          }
        },
        error => {
          this.loading = false;
        }
      );
  }

  attachmentsChange($event: Material[]): void {
    this.replyReq.attachments = $event;
  }

  get canReply(): boolean {
    return !!this.replyReq.content || !!this.replyReq.attachments;
  }

  /**
   * 提交回复
   */
  reply(): void {

    if (!this.canReply) {
      return;
    }

    this.loading = true;
    this.underwritingService.pendingDetailReplyV2(this.replyReq)
      .subscribe(
        result => {
          this.loadReplies();
          this.resetReply();
          this.loading = false;
          this.pendingChanged.emit('');
        },
        error => {
          this.loading = false;
        }
      );
  }

  /**
   * 重置回复数据
   */
  resetReply(): void {
    this.replyReq = new UnderwritingPendingReplyReq();
    this.replyReq.pendingId = this.pendingDetail.id;
  }

  openDetail(): void {
    this.detailVisible = true;
  }

  closeDetail(): void {
    this.detailVisible = false;
  }

  isDynamicEncryption(keyName): boolean {
    let isShow = false;
    if (!this.dataDesensitization || !this.mode) {
      return isShow;
    }
    if (this.mode === 'SUBORDINATE') {
      this.dataDesensitization?.subordinates?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    } else {
      this.dataDesensitization?.mines?.map(item => {
        if (item.name === keyName) {
          isShow = item.mask;
        }
      });
      return isShow;
    }
  }
}
