import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AmlInspectReq, AmlRecordType } from '../../../api/types';
import { MetadataService } from '../../../shared/service/metadata.service';
import { PropertySelectOption } from '../../../shared/component/property-select/property-select.component';
import { formatDate } from '@angular/common';
import { AmlService } from '../../aml.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Workbench } from '../../../workbench/workbench-types';
import { WorkbenchService } from '../../../workbench/workbench.service';
import { RegionSelectionComponent } from '../../../shared/component/region-selection/region-selection.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-aml-inspect',
  templateUrl: './aml-inspect.component.html',
  styleUrls: ['./aml-inspect.component.less']
})
export class AmlInspectComponent implements OnInit {

  loading = false;

  inspectReq = new AmlInspectReq();

  typeOptions: PropertySelectOption[] = [];
  regionOptions: PropertySelectOption[] = [];
  genderOptions: PropertySelectOption[] = [];

  type: AmlRecordType = AmlRecordType.PERSON;
  birthday: number;
  workbench: Workbench;

  @Output()
  amlInspectCreated: EventEmitter<any> = new EventEmitter<any>();

  constructor(private metadataService: MetadataService,
              private amlService: AmlService,
              private toastr: ToastrService,
              private drawerService: NzDrawerService,
              private workbenchService: WorkbenchService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.workbench = this.workbenchService.workbench;
    const amlRecordTypes = this.metadataService.values('amlRecordType');
    for (const amlRecordType of amlRecordTypes) {
      this.typeOptions.push(new PropertySelectOption(amlRecordType.value, amlRecordType.key));
    }
    const countries = this.metadataService.values('amlCountries');
    for (const country of countries) {
      this.regionOptions.push(new PropertySelectOption(country.value, country.key));
    }
    const genders = this.metadataService.values('gender');
    for (const gender of genders) {
      this.genderOptions.push(new PropertySelectOption(gender.value, gender.key));
    }
  }

  onBirthdayChange(timestamp: number): void {
    if (!timestamp) {
      return;
    }
    this.birthday = timestamp;
    const date = new Date(timestamp);
    this.inspectReq.dateOfBirth = formatDate(date, 'yyyy-MM-dd', 'en-US');
  }

  get canInspect(): boolean {
    if (this.loading) {
      return false;
    }
    // if (this.type === AmlRecordType.PERSON) {
    //   if (!this.inspectReq.surname) {
    //     return false;
    //   }
    //   if (!this.inspectReq.forename) {
    //     return false;
    //   }
    // }
    // if (this.type === AmlRecordType.BUSINESS) {
    //   if (!this.inspectReq.businessName) {
    //     return false;
    //   }
    // }
    return true;
  }

  onReset(): void {
    // this.type = AmlRecordType.PERSON;
    this.birthday = null;
    this.inspectReq = new AmlInspectReq();
  }

  onInspect(): void {
    if (!this.canInspect) {
      return;
    }
    if (this.type === AmlRecordType.PERSON) {
      if (this.validatorFullName() || this.validatorFullNameEn()) {
        this.loading = true;
        this.amlService.createInspect(this.inspectReq, this.type)
          .subscribe(
            data => {
              this.loading = false;
              if (data) {
                this.translate.get('CreateSuccess').subscribe(
                  (res) => {
                    this.toastr.success(res);
                  }
                );
              }
              this.amlInspectCreated.emit('');
            },
            error => {
              this.loading = false;
            }
          );
      } else {
        this.translate.get(['PleaseEnter', 'validatorFullNameTip']).subscribe(
          (res) => {
            this.toastr.warning(res.PleaseEnter + res.validatorFullNameTip);
          }
        );
      }
    } else {
      if (this.inspectReq.businessName || this.inspectReq.englishName) {
        this.loading = true;
        this.amlService.createInspect(this.inspectReq, this.type)
          .subscribe(
            data => {
              this.loading = false;
              if (data) {
                this.translate.get('CreateSuccess').subscribe(
                  (res) => {
                    this.toastr.success(res);
                  }
                );
              }
              this.amlInspectCreated.emit('');
            },
            error => {
              this.loading = false;
            }
          );
      } else {
        this.translate.get(['PleaseEnter', 'ValidatorCorporateNameTip']).subscribe(
          (res) => {
            this.toastr.warning(res.PleaseEnter + res.ValidatorCorporateNameTip);
          }
        );
      }
    }
  }

  validatorFullName(): boolean {
    return !!this.inspectReq.surname && !!this.inspectReq.forename;
  }

  validatorFullNameEn(): boolean {
    return !!this.inspectReq.englishForename && !!this.inspectReq.englishSurname;
  }

  onCountry(): void {
    const drawerRef = this.drawerService.create<RegionSelectionComponent, { value: any }, string>({
      nzWidth: 600,
      nzMaskClosable: true,
      nzContent: RegionSelectionComponent,
      nzContentParams: {},
    });
    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.regionSelected.subscribe(
        data => {
          this.inspectReq.country = data.countryCode;
          drawerRef.close();
        }
      );
    });
  }

  get countryOwnerString(): string {
    return this.metadataService.translate('countries', this.inspectReq.country);
  }
}
