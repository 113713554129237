import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { Fna, FnaSignedStatus, FnaSigner } from '../../../fna/fna-types';
import { Workbench } from '../../../workbench/workbench-types';
import { FnaService } from '../../../fna/fna.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { WorkbenchService } from '../../../workbench/workbench.service';
import { FnaSignaturePadComponent } from '../../../fna/fna-signature-pad/fna-signature-pad.component';
import { FnaAssessAdvice, NewFnaDetail, SignatureInformation } from '../../new-fna-types';
import { NewFnaSignaturePadComponent } from '../new-fna-signature-pad/new-fna-signature-pad.component';
import { NewFnaService } from '../../new-fna.service';
import { plainToInstance } from 'class-transformer';

@Component({
  selector: 'app-new-fna-signature',
  templateUrl: './new-fna-signature.component.html',
  styleUrls: ['./new-fna-signature.component.less']
})
export class NewFnaSignatureComponent implements OnInit {
  drawerRef: NzDrawerRef<NewFnaSignatureComponent, string>;

  fna: NewFnaDetail;
  loading = false;
  workbench: Workbench;
  signatureInformation: SignatureInformation[] = [];

  @Output()
  submitted: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  resigned: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fnaService: FnaService,
              private modalService: NzModalService,
              private drawerService: NzDrawerService,
              private newFnaService: NewFnaService,
              private workbenchService: WorkbenchService) {
  }

  ngOnInit(): void {
    this.getSignedInfo();
    this.workbench = this.workbenchService.workbench;
  }


  getSignedInfo(): void {
    const copy: SignatureInformation[] = [];
    if (this.signatureInformation?.length < 3) {
      const obj1 = plainToInstance(SignatureInformation, new SignatureInformation());
      obj1.signatureType = 'OWNER'; // 投保人
      const obj2 = plainToInstance(SignatureInformation, new SignatureInformation());
      obj2.signatureType = 'SALES'; // 销售
      const obj3 = plainToInstance(SignatureInformation, new SignatureInformation());
      obj3.signatureType = 'EYEWITNESS'; // 见证人
      this.signatureInformation.push(obj1, obj2, obj3);
    }
    if (this.fna.signatureInformation && this.fna.signatureInformation.length) {
      this.signatureInformation.map(item => {
        const ite = this.fna.signatureInformation.find(info => info.signatureType === item.signatureType );
        if (ite) {
          copy.push(ite);
        } else {
          copy.push(item);
        }
      });
      this.signatureInformation = JSON.parse(JSON.stringify(copy));
    }
  }

  onViewFna(): void {
    this.loading = true;
    this.fnaService.viewFna(this.fna.id)
      .subscribe(
        data => {
          const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
          window.open(url);
          this.loading = false;
        }, error => {
          this.loading = false;
        });
  }

  onConsultantSignature(signer: SignatureInformation): void {
    const drawerRef = this.drawerService.create<NewFnaSignaturePadComponent, { value: string }, string>({
      nzWidth: 800,
      nzMaskClosable: true,
      nzContent: NewFnaSignaturePadComponent,
      nzContentParams: {
        fna: this.fna,
        signer: FnaSigner.SALES,
        signatureInfo: JSON.parse(JSON.stringify(signer))
      }
    });

    drawerRef.afterOpen.subscribe(() => {
      const component = drawerRef.getContentComponent();
      component.drawerRef = drawerRef;
      component.signed.subscribe(
        data => {
          this.loadDetail();
        });
    });

    drawerRef.afterClose.subscribe(data => {
      this.loadDetail();
    });
  }

  loadDetail(): void {
    this.loading = true;
    this.newFnaService.info(this.fna.id)
      .subscribe(
        fna => {
          this.loading = false;
          this.fna = fna;
          this.getSignedInfo();
        },
        error => {
          this.loading = false;
        });
  }

  get canSubmit(): boolean {
    return this.signatureInformation.every(item => item.signatureStatus === 'SIGNED');
  }

  onSubmit(): void {
    if (!this.canSubmit) {
      return;
    }
    this.loading = true;
    this.newFnaService.fnaEnd(this.fna.id)
      .subscribe(
        fna => {
          this.loading = false;
          this.drawerRef.close();
          this.submitted.emit('');
        },
        error => {
          this.loading = false;
        });
  }

  onResign(tplTitle: TemplateRef<any>, tplContent: TemplateRef<any>, tplFooter: TemplateRef<any>): void {
    this.modalService.create({
      nzCentered: true,
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: this.fna,
      nzOnOk: () => {
      }
    });
  }

  onReSignConfirm(ref: NzModalRef): void {
    this.loading = true;
    this.signatureInformation = [];
    this.newFnaService.reFnaSign(this.fna.id)
      .subscribe(
        fna => {
          this.loading = false;
          this.resigned.emit('');
          this.loadDetail();
          ref.close();
        },
        error => {
          this.loading = false;
          ref.close();
        });
  }
}
