<div class="drawer-container">
  <div class="drawer-title" style="margin-bottom: 40px">
    <nz-breadcrumb nzSeparator=">" style="font-size: 20px;line-height: 22px">
      <nz-breadcrumb-item>{{'ContactsInfo' | translate}}</nz-breadcrumb-item>
      <nz-breadcrumb-item>
        {{'FamilyMember' | translate}}
      </nz-breadcrumb-item>
    </nz-breadcrumb>
  </div>
  <nz-spin [nzSpinning]="spinning">
    <div class="member-content">
    <div *ngFor="let member of memberList; let i = index" style="display: flex; align-items: center;">
      <a nz-button nzType="link" nzSize="small"
         [disabled]="member.relationship === 'OWN'"
         appThrottleClick (throttleClick)="onDel(member.id, member.relationship)"
         nz-tooltip [nzTooltipTitle]="'Delete' | translate">
        <img alt="" width="28" src="assets/images/ic_delete.svg">
      </a>
      <div class="section-wrapper member-card">
        <div style="display: flex; justify-content: space-between; font-size: 16px; color: #333333">
          <span>{{member.name}}</span><span *ngIf="member.relationship === 'OWN'">{{'HeadOfHousehold' | translate}}</span>
        </div>
        <div style="color: #C4C8C9">
          <span>{{'Household' | translate}}</span>: <span>{{member.relationship | metadataTranslate: 'relationship'}}</span>
        </div>
      </div>
    </div>

<!--    <div style="display: flex; align-items: center;">-->
<!--      <a nz-button nzType="link" nzSize="small"-->
<!--         nz-tooltip [nzTooltipTitle]="'Delete' | translate">-->
<!--        <img alt="" width="28" src="assets/images/ic_delete.svg">-->
<!--      </a>-->
<!--      <div class="section-wrapper member-card">-->
<!--        <div style="display: flex; justify-content: space-between; font-size: 16px"><span>李斯</span></div>-->
<!--        <div><span>与户主关系</span>: <span>配偶</span></div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  </nz-spin>
  <div class="footer">
    <div nz-row style="width: 100%; height: 100%;">
      <div nz-col nzSpan="12" class="footer-button">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onDisband()">
<!--          <em nz-icon nzType="reload" nzTheme="outline"></em>-->
          <span>{{'Disband' | translate}}</span>
        </a>
      </div>
      <div nz-col nzSpan="12" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large"
           appThrottleClick (throttleClick)="onAdd()">
<!--          <em nz-icon nzType="save" nzTheme="outline"></em>-->
          <span>{{'AddMember' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

  <nz-modal
    [(nzVisible)]="isVisible"
    [nzTitle]="modalTitle"
    [nzContent]="modalContent"
    [nzFooter]="null"
    [nzWidth]="'400px'"
    nzCentered
    [nzClosable]="false"
    [nzMaskClosable]="false"
    (nzOnCancel)="handleCancel()"
  >
    <ng-template #modalTitle style="text-align: center">{{'Tips' | translate}}</ng-template>

    <ng-template #modalContent>
      <p style="text-align: center; margin-bottom: 30px">{{'DisbandFamily' | translate}}</p>
      <div nz-row style="width: 100%; height: 100%; justify-content: space-around;">
        <button nz-col nzSpan="10" nz-button nzType="default" (click)="handleCancel()">{{'Cancel' | translate}}</button>
        <button nz-col nzSpan="10" nz-button nzType="primary" (click)="handleOk()">{{'Confirm' | translate}}</button>
      </div>
    </ng-template>

  </nz-modal>
</div>

