<div class="drawer-container">
  <div class="common-title" style="margin-bottom: 40px">
    {{ 'HealthInformation' | translate}}
  </div>
  <div *ngIf="isOwnerCorporate()" class="section-wrapper " style="padding-top: 20px" >
    <div class="common-title mt-8" style="font-size: 22px">
      {{ 'Holder' | translate}}
    </div>
    <div *ngIf="isOwnerCorporate()">
      <div>
        <div *ngFor="let item of heathInformation.questionOwner let index = index">
          <div [class]="index === 0 ? 'question-title': 'question-title mt-8'">
            {{item.title}}
          </div>
          <div *ngFor="let questionnaire of item.questionnaire let idx = index">
            <div class="question-box">
              <div class="question-box-left">{{questionnaire.index }}/{{heathInformation.ownerTotal}}</div>
              <div class="question-box-right">
                <p>{{questionnaire.question}}
                  <span ng-bind-html="questionnaire.tip"></span>
                </p>
                <div class="question-radio-group">
                  <nz-radio-group [(ngModel)]="questionnaire.value" [disabled]="!healthType.edit">
                    <label nz-radio *ngFor="let answer of questionnaire.answer" [nzValue]="answer.answerId"
                      (click)="onRadioOwnerChange(questionnaire.value, index, idx, answer.answerId, heathInformation.questionOwner)"
                    >
                      {{answer.label }}
                    </label>
                  </nz-radio-group>
                </div>
                <div *ngFor="let answer of questionnaire.answer let answerIdx = index" class="question-extra-box">
                  <div *ngIf="answer.answerId === questionnaire.value && !!answer.attachment">
                    <div *ngIf="answer.attachment.type === 'form'" class="question-extra-box">
                      <div *ngFor="let headerItem of answer.attachment.headers let headerIdx = index">
                        <!--日期范围-->
                        <app-property-datetime *ngIf="headerItem.type == 'date' && headerItem.value == 'range'"
                                               [disabled]="!healthType.edit"
                                               [title]="headerItem.label +'(' + ('StartTime' | translate) + ')'"
                                               [required]="headerItem.required"
                                               [(value)]="headerItem.startTime">
                        </app-property-datetime>
                        <app-property-datetime *ngIf="headerItem.type == 'date' && headerItem.value == 'range'"
                                               [disabled]="!healthType.edit"
                                               [required]="headerItem.required"
                                               [title]="headerItem.label +'(' + ('EndTime' | translate) + ')'"
                                               [(value)]="headerItem.endTime">
                        </app-property-datetime>
                        <!--日期选择-->
                        <app-property-datetime *ngIf="headerItem.type == 'date' && headerItem.value == 'date'"
                                               [disabled]="!healthType.edit"
                                               [required]="headerItem.required"
                                               [title]="headerItem.label"  [(value)]="headerItem.inputValue">
                        </app-property-datetime>
                        <!--输入框 -->
                        <app-property-input *ngIf="headerItem.type == 'input' && headerItem.value == 'text'"
                                            [disabled]="!healthType.edit"
                                            [placeholder]="'PleaseEnter' | translate"
                                            [required]="headerItem.required"
                                            [title]="headerItem.label" [(value)]="headerItem.inputValue">
                        </app-property-input>
                        <!--多行 -->
                        <app-property-input-area *ngIf="headerItem.type == 'input' && headerItem.value == 'textarea'"
                                                 [disabled]="!healthType.edit"
                                                 [title]="headerItem.label"
                                                 [required]="headerItem.required"
                                                 [placeholder]="'PleaseEnter' | translate"
                                                 [(value)]="headerItem.inputValue">
                        </app-property-input-area>
                        <!--数字-->
                        <app-property-input-number
                          *ngIf="headerItem.type == 'input' && headerItem.value == 'number'"
                          [disabled]="!healthType.edit"
                          [placeholder]="'PleaseEnter' | translate"
                          [required]="headerItem.required"
                          [title]="headerItem.label" [(value)]="headerItem.inputValue"></app-property-input-number>
                      </div>
                    </div>
                    <div *ngIf="answer.attachment.type === 'table'; else elseBlock" class="question-extra-box">
                      <div *ngFor="let valueItem of answer.attachment.values let valueIdx = index">
                        <p>{{answer.attachment.name}} {{valueIdx + 1}}</p>
                        <div *ngFor="let headerItem of valueItem">

                          <!--日期范围-->
                          <app-property-datetime *ngIf="headerItem.type == 'date' && headerItem.value == 'range'"
                                                 [disabled]="!healthType.edit"
                                                 [required]="headerItem.required"
                                                 [title]="headerItem.label +'(' + ('StartTime' | translate) + ')'"
                                                 [(value)]="headerItem.startTime">
                          </app-property-datetime>
                          <app-property-datetime *ngIf="headerItem.type == 'date' && headerItem.value == 'range'"
                                                 [disabled]="!healthType.edit"
                                                 [required]="headerItem.required"
                                                 [title]="headerItem.label +'(' + ('EndTime' | translate) + ')'"
                                                 [(value)]="headerItem.endTime">
                          </app-property-datetime>
                          <!--日期选择-->
                          <app-property-datetime *ngIf="headerItem.type == 'date' && headerItem.value == 'date'"
                                                 [disabled]="!healthType.edit"
                                                 [required]="headerItem.required"
                                                 [title]="headerItem.label" [(value)]="headerItem.inputValue">
                          </app-property-datetime>
                          <!--输入框 -->
                          <app-property-input *ngIf="headerItem.type == 'input' && headerItem.value == 'text'"
                                              [placeholder]="'PleaseEnter' | translate"
                                              [required]="headerItem.required"
                                              [disabled]="!healthType.edit"
                                              [title]="headerItem.label" [(value)]="headerItem.inputValue">
                          </app-property-input>
                          <!--多行 -->
                          <app-property-input-area *ngIf="headerItem.type == 'input' && headerItem.value == 'textarea'"
                                                   [disabled]="!healthType.edit"
                                                   [required]="headerItem.required"
                                                   [title]="headerItem.label"
                                                   [placeholder]="'PleaseEnter' | translate"
                                                   [(value)]="headerItem.inputValue">
                          </app-property-input-area>
                          <!--数字-->
                          <app-property-input-number
                            *ngIf="headerItem.type == 'input' && headerItem.value == 'number'"
                            [disabled]="!healthType.edit"
                            [required]="headerItem.required"
                            [placeholder]="'PleaseEnter' | translate"
                            [title]="headerItem.label" [(value)]="headerItem.inputValue"></app-property-input-number>
                        </div>
                        <i nz-icon nzType="plus" nzTheme="outline"
                           appThrottleClick (throttleClick)="answer.attachment.values.push(answer.attachment.headers)"></i>
                      </div>
                    </div>
                    <ng-template #elseBlock></ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="modeType === 'booking' && heathInformation.questionOwner && heathInformation.questionOwner.length"
           style="padding-bottom: 10px;">
        <div style="font-size: 16px;margin-bottom: 14px;">{{'DeclareMedicalConditions' | translate}}</div>
        <div *ngIf="heathInformation.ownerOtherAnswer.otherDiseases && heathInformation.ownerOtherAnswer.otherDiseases.length">
          <div *ngFor="let item of heathInformation.ownerOtherAnswer.otherDiseases let index = index;trackBy: trackByFn"
               style="display: flex;align-items: center;margin: 8px 0;"
          >
            <nz-tag [nzColor]="'blue'">{{'QueCondition' | translate}}{{ index + 1 }}</nz-tag>
            <input nz-input [placeholder]="'PleaseEnter' | translate"
                   [(ngModel)]="heathInformation.ownerOtherAnswer.otherDiseases[index]" />
            <i nz-icon style="color: red;font-size: 16px;margin-left: 10px;cursor: pointer" nzType="minus-circle" nzTheme="outline"
               appThrottleClick (throttleClick)="dleOtherAnswer('owner', index)"></i>
          </div>
        </div>
        <div style="display: flex;align-items: center;cursor: pointer;"
             appThrottleClick (throttleClick)="addOtherAnswer('owner')">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          <div style="margin-left: 10px;">{{'ContinueToAdd' | translate}}</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isRelationship()" class="section-wrapper" style="padding-top: 20px" >
    <div class="common-title mt-8" style="font-size: 22px">
      {{ 'Insured' | translate}}
    </div>
    <div *ngIf="isRelationship()">
      <div>
        <div *ngFor="let item of heathInformation.questionInsured let index = index">
          <div [class]="index === 0 ? 'question-title': 'question-title mt-8'">
            {{item.title}}
          </div>
          <div *ngFor="let questionnaire of item.questionnaire let idx = index">
            <div class="question-box">
              <div class="question-box-left">{{questionnaire.index }}/{{heathInformation.insuredTotal}}</div>
              <div class="question-box-right">
                <p>{{questionnaire.question}}
                  <span ng-bind-html="questionnaire.tip"></span>
                </p>
                <div class="question-radio-group">
                  <nz-radio-group [(ngModel)]="questionnaire.value" [disabled]="!healthType.edit">
                    <label nz-radio *ngFor="let answer of questionnaire.answer" [nzValue]="answer.answerId"
                           (click)="onRadioInsuredChange(questionnaire.value, index, idx, answer.answerId)"
                    >
                      {{answer.label }}
                    </label>
                  </nz-radio-group>
                </div>
                <div *ngFor="let answer of questionnaire.answer let answerIdx = index" class="question-extra-box">
                  <div *ngIf="answer.answerId === questionnaire.value && !!answer.attachment">
                    <div *ngIf="answer.attachment.type === 'form'" class="question-extra-box">
                      <div *ngFor="let headerItem of answer.attachment.headers let headerIdx = index">
                        <!--日期范围-->
                        <app-property-datetime *ngIf="headerItem.type == 'date' && headerItem.value == 'range'"
                                               [disabled]="!healthType.edit"
                                               [required]="headerItem.required"
                                               [title]="headerItem.label +'(' + ('StartTime' | translate) + ')'"
                                               [(value)]="headerItem.startTime">
                        </app-property-datetime>
                        <app-property-datetime *ngIf="headerItem.type == 'date' && headerItem.value == 'range'"
                                               [disabled]="!healthType.edit"
                                               [required]="headerItem.required"
                                               [title]="headerItem.label +'(' + ('EndTime' | translate) + ')'"
                                               [(value)]="headerItem.endTime">
                        </app-property-datetime>
                        <!--日期选择-->
                        <app-property-datetime *ngIf="headerItem.type == 'date' && headerItem.value == 'date'"
                                               [disabled]="!healthType.edit"
                                               [required]="headerItem.required"
                                               [title]="headerItem.label" [(value)]="headerItem.inputValue">
                        </app-property-datetime>
                        <!--输入框 -->
                        <app-property-input *ngIf="headerItem.type == 'input' && headerItem.value == 'text'"
                                            [disabled]="!healthType.edit"
                                            [required]="headerItem.required"
                                            [placeholder]="'PleaseEnter' | translate"
                                            [title]="headerItem.label" [(value)]="headerItem.inputValue">
                        </app-property-input>
                        <!--多行 -->
                        <app-property-input-area *ngIf="headerItem.type == 'input' && headerItem.value == 'textarea'"
                                                 [disabled]="!healthType.edit"
                                                 [title]="headerItem.label"
                                                 [required]="headerItem.required"
                                                 [placeholder]="'PleaseEnter' | translate"
                                                 [(value)]="headerItem.inputValue">
                        </app-property-input-area>
                        <!--数字-->
                        <app-property-input-number
                          *ngIf="headerItem.type == 'input' && headerItem.value == 'number'"
                          [disabled]="!healthType.edit"
                          [required]="headerItem.required"
                          [placeholder]="'PleaseEnter' | translate"
                          [title]="headerItem.label" [(value)]="headerItem.inputValue"></app-property-input-number>
                      </div>
                    </div>
                    <div *ngIf="answer.attachment.type === 'table'; else elseBlock" class="question-extra-box">
                      <div *ngFor="let valueItem of answer.attachment.values let valueIdx = index">
                        <p>{{answer.attachment.name}} {{valueIdx + 1}}</p>
                        <div *ngFor="let headerItem of valueItem">

                          <!--日期范围-->
                          <app-property-datetime *ngIf="headerItem.type == 'date' && headerItem.value == 'range'"
                                                 [disabled]="!healthType.edit"
                                                 [required]="headerItem.required"
                                                 [title]="headerItem.label +'(' + ('StartTime' | translate) + ')'"
                                                 [(value)]="headerItem.startTime">
                          </app-property-datetime>
                          <app-property-datetime *ngIf="headerItem.type == 'date' && headerItem.value == 'range'"
                                                 [disabled]="!healthType.edit"
                                                 [required]="headerItem.required"
                                                 [title]="headerItem.label +'(' + ('EndTime' | translate) + ')'"
                                                 [(value)]="headerItem.endTime">
                          </app-property-datetime>
                          <!--日期选择-->
                          <app-property-datetime *ngIf="headerItem.type == 'date' && headerItem.value == 'date'"
                                                 [disabled]="!healthType.edit"
                                                 [required]="headerItem.required"
                                                 [title]="headerItem.label" [(value)]="headerItem.inputValue">
                          </app-property-datetime>
                          <!--输入框 -->
                          <app-property-input *ngIf="headerItem.type == 'input' && headerItem.value == 'text'"
                                              [placeholder]="'PleaseEnter' | translate"
                                              [disabled]="!healthType.edit"
                                              [required]="headerItem.required"
                                              [title]="headerItem.label" [(value)]="headerItem.inputValue">
                          </app-property-input>
                          <!--多行 -->
                          <app-property-input-area *ngIf="headerItem.type == 'input' && headerItem.value == 'textarea'"
                                                   [disabled]="!healthType.edit"
                                                   [title]="headerItem.label"
                                                   [required]="headerItem.required"
                                                   [placeholder]="'PleaseEnter' | translate"
                                                   [(value)]="headerItem.inputValue">
                          </app-property-input-area>
                          <!--数字-->
                          <app-property-input-number
                            *ngIf="headerItem.type == 'input' && headerItem.value == 'number'"
                            [disabled]="!healthType.edit"
                            [required]="headerItem.required"
                            [placeholder]="'PleaseEnter' | translate"
                            [title]="headerItem.label" [(value)]="headerItem.inputValue"></app-property-input-number>
                        </div>
                        <i nz-icon nzType="plus" nzTheme="outline"
                           appThrottleClick (throttleClick)="answer.attachment.values.push(answer.attachment.headers)"></i>
                      </div>
                    </div>
                    <ng-template #elseBlock></ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div *ngIf="modeType === 'booking' && heathInformation.questionInsured && heathInformation.questionInsured.length"
           style="padding-bottom: 10px;">
        <div style="font-size: 16px;margin-bottom: 14px;">{{'DeclareMedicalConditions' | translate}}</div>
        <div *ngIf="heathInformation.insuredOtherAnswer.otherDiseases && heathInformation.insuredOtherAnswer.otherDiseases.length">
          <div *ngFor="let item of heathInformation.insuredOtherAnswer.otherDiseases let index = index;trackBy: trackByFn"
               style="display: flex;align-items: center;margin: 8px 0;"
          >
            <nz-tag [nzColor]="'blue'">{{'QueCondition' | translate}}{{ index + 1 }}</nz-tag>
            <input nz-input [placeholder]="'PleaseEnter' | translate"
                   [(ngModel)]="heathInformation.insuredOtherAnswer.otherDiseases[index]" />
            <i nz-icon style="color: red;font-size: 16px;margin-left: 10px;cursor: pointer" nzType="minus-circle" nzTheme="outline"
               appThrottleClick (throttleClick)="dleOtherAnswer('insured', index)"></i>
          </div>
        </div>
        <div style="display: flex;align-items: center;cursor: pointer;"
             appThrottleClick (throttleClick)="addOtherAnswer('insured')">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          <div style="margin-left: 10px;">{{'ContinueToAdd' | translate}}</div>
        </div>
      </div>
    </div>

  </div>
  <div class="footer" *ngIf="healthType.edit">
    <div nz-row style="width: 100%; height: 100%;">

      <div nz-col nzSpan="24" class="footer-button-primary">
        <a nz-button nzType="link" nzBlock nzSize="large" appThrottleClick (throttleClick)="onSave()">
          <span>{{'Save' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
